import { FC } from 'react';
import { useMatch, useNavigate } from '@tanstack/react-location';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormsSubmission } from '@frontend/api-forms';
import { PersonHelpers } from '@frontend/api-person';
import { Icon } from '@frontend/icons';
import { Photos } from '@frontend/photos';
import { useBreakpoint } from '@frontend/responsiveness';
import { useContactPanelShallowStore } from '@frontend/shared';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { Heading, Text, useTooltip, IconButton, useAlert, ModalControlResponse } from '@frontend/design-system';
import { PmsPatientIcon } from '../../../../../assets/icons/small';
import { getFormattedPhoneNumber } from '../../../../../shared/utils';
import ContactBadge from '../../contact-badge/contact-badge.component';
import FormStatusBadge from '../../form-status-badge/form-status-badge.component';
import { WritebackWidget, WritebackWidgetTypes } from '../writeback-widget-v2';
import DateOfBirth from './date-of-birth/date-of-birth.component';
import {
  containerStyle,
  innerContainerStyle,
  patientMetaStyle,
  patientSubInfoStyle,
  patientSubInfoTextStyle,
  providerDetailsStyle,
  writebackDetailsContainerStyle,
} from './patient-info.styles';
import { ProviderInfo } from './provider-info.component';

dayjs.extend(relativeTime);

interface PatientInfoProps {
  submission: FormsSubmission.Types.NormalizedSubmissionDetailResponse;
  submissionId: string;
  locationId: string;
  writebackWidgetModalControls: ModalControlResponse;
  approveOrRejectMode: WritebackWidgetTypes.ApproveOrRejectMode;
  resetApproveOrRejectMode: () => void;
  showBadge?: boolean;
  hasPMSIntegration?: boolean;
}

function getTimeInWord(time: string) {
  return dayjs().to(dayjs(time));
}

const PatientInfo: FC<React.PropsWithChildren<PatientInfoProps>> = ({
  submission,
  hasPMSIntegration,
  locationId,
  submissionId,
  writebackWidgetModalControls,
  approveOrRejectMode,
  resetApproveOrRejectMode,
}) => {
  const {
    Tooltip: AppointmentHoverLabel,
    tooltipProps: appointmentLabelProps,
    triggerProps: appointmentTriggerProps,
  } = useTooltip({
    placement: 'bottom',
  });
  const alert = useAlert();

  const breakpoints = useBreakpoint();
  const navigate = useNavigate();
  const { pathname } = useMatch();

  const {
    Tooltip: PmsIdHoverLabel,
    tooltipProps: pmsIdLabelProps,
    triggerProps: pmsIdtriggerProps,
  } = useTooltip({
    placement: 'top',
  });

  const {
    birthdate,
    mobilePhone,
    reasonPhrase,
    firstName,
    lastName,
    reviewStatus,
    providerName,
    patientPmsId,
    appointmentDetails,
    writebackStatusCode,
    isArchive,
    formSubmittedAt,
  } = submission;

  const isReviewRequired = !!(reviewStatus && reviewStatus !== 'Not Applicable');
  const fullname = PersonHelpers.getFullName({ FirstName: firstName, LastName: lastName });

  const { setPersonId } = useContactPanelShallowStore('setPersonId', 'personId');
  const { setShow } = useSlidePanelShallowStore('setShow');

  //e.g:  could be 12_123 or 123. where 123 is the pmsId
  const pmsIdMeta = patientPmsId?.split('_');

  const onPmsIdCopied = async () => {
    if (pmsIdMeta) {
      alert.info('Patient PMS ID copied to clipboard');
    }
  };

  const apptDate = appointmentDetails?.appointmentDate;
  const apptStatus = appointmentDetails?.appointmentStatus;

  function getStatusBadge() {
    if (!!isArchive || !writebackStatusCode || !hasPMSIntegration) {
      return null;
    }

    return (
      <FormStatusBadge
        writebackStatusCode={writebackStatusCode}
        submittionDate={formSubmittedAt}
        reviewStatus={reviewStatus}
        needsReview={reviewStatus === 'Pending'}
        reason={reasonPhrase}
      />
    );
  }

  const onBackClick = () => {
    const splittedPath = pathname.split('/');
    splittedPath.splice(-2);
    navigate({ to: splittedPath.join('/') });
  };

  return (
    <div css={containerStyle}>
      <div css={innerContainerStyle}>
        {breakpoints !== 'xsmall' && (
          <Photos.ContactProfilePhoto
            locationId={locationId}
            personId={''}
            name={fullname}
            onClick={() => {
              if (!!submission.personDetails?.id) {
                setPersonId(submission.personDetails.id);
                setShow(true, 'contact');
              }
            }}
          ></Photos.ContactProfilePhoto>
        )}
        {['xsmall', 'small'].includes(breakpoints) && (
          <IconButton label='' onClick={onBackClick}>
            <Icon name='back' />
          </IconButton>
        )}
        <div css={patientSubInfoStyle}>
          <Heading className='fs-mask patient-name' level={2}>
            {`${firstName} ${lastName}`}
            <ContactBadge reason={reasonPhrase} />
          </Heading>

          <div css={patientMetaStyle}>
            {pmsIdMeta && !reasonPhrase && (
              <div className='patient-meta-item pms-id'>
                <PmsPatientIcon className='pms-id-icon' />
                <CopyToClipboard text={pmsIdMeta[pmsIdMeta.length - 1]} onCopy={onPmsIdCopied}>
                  <Text
                    size={breakpoints === 'xsmall' ? 'medium' : 'large'}
                    css={patientSubInfoTextStyle}
                    className='fs-mask pms-id-text'
                    {...pmsIdtriggerProps}
                  >
                    {pmsIdMeta[pmsIdMeta.length - 1]}
                  </Text>
                </CopyToClipboard>
                {['medium', 'large'].includes(breakpoints) && (
                  <PmsIdHoverLabel {...pmsIdLabelProps}>
                    Click to <strong>copy patient&apos;s PMS ID</strong>
                  </PmsIdHoverLabel>
                )}
              </div>
            )}

            <div className='patient-meta-item'>
              <Icon name='phone' size={breakpoints === 'xsmall' ? 16 : 18} color='light' />
              <Text
                size={breakpoints === 'xsmall' ? 'medium' : 'large'}
                css={patientSubInfoTextStyle}
                className='fs-mask'
              >
                {mobilePhone ? getFormattedPhoneNumber(mobilePhone) : 'Phone number not provided'}
              </Text>
            </div>

            <div className='patient-meta-item'>
              <DateOfBirth date={birthdate} />
            </div>
          </div>

          <div css={providerDetailsStyle}>
            {apptDate ? (
              <Text
                size={breakpoints === 'xsmall' ? 'medium' : 'large'}
                className='provide-detail-item'
                color={'light'}
                {...(reviewStatus !== 'Rejected' && appointmentTriggerProps)}
              >
                {reviewStatus === 'Rejected' ? (
                  <Icon name='x' color='error' />
                ) : (
                  <>
                    {apptStatus === 'Completed' && <Icon name='calendar-confirmed' color={'success'} />}
                    {apptStatus === 'Confirmed' && <Icon name='calendar-confirmed' />}
                    {apptStatus === 'Unconfirmed' && <Icon name='pending' />}
                  </>
                )}
                {reviewStatus === 'Pending' && getTimeInWord(apptDate)}
                {reviewStatus === 'Approved' && getTimeInWord(apptDate)}
                {reviewStatus === 'Not Applicable' && getTimeInWord(apptDate)}
                {reviewStatus === 'Rejected' && 'Rejected'}

                <AppointmentHoverLabel {...appointmentLabelProps}>Appt. {apptStatus}</AppointmentHoverLabel>
              </Text>
            ) : (
              <Text
                className='provide-detail-item'
                color={'light'}
                size={breakpoints === 'xsmall' ? 'medium' : 'large'}
                {...(reviewStatus !== 'Rejected' && appointmentTriggerProps)}
              >
                <Icon name='calendar' size={breakpoints === 'xsmall' ? 16 : 18} />
                No Appointment
              </Text>
            )}
            {isReviewRequired && (
              <ProviderInfo providerName={providerName} reviewStatus={reviewStatus} locationId={locationId} />
            )}
          </div>
        </div>

        <div css={writebackDetailsContainerStyle}>
          {getStatusBadge()}

          <WritebackWidget
            modalControls={writebackWidgetModalControls}
            submission={submission}
            submissionId={submissionId}
            locationId={locationId}
            approveOrRejectMode={approveOrRejectMode}
            resetApproveOrRejectMode={resetApproveOrRejectMode}
          />
        </div>
      </div>
    </div>
  );
};

export default PatientInfo;
