import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { useTranslation } from '@frontend/i18n';
import { PaymentsFeatureFlags, useMerchantsInfo, useMultiQueryUtils } from '@frontend/payments-hooks';
import { StaticSettingsRoutes, useSettingsNavigate } from '@frontend/settings-routing';
import { IndexRow } from '@frontend/design-system';
import { useFeatureFlag } from '../../../hooks';
import * as settingStyles from '../styles';

const styles = {
  indexRow: css`
    & > article {
      max-width: none;
      width: 100%;
    }
  `,
};

export const MultiPaymentSettings = () => {
  const { t } = useTranslation('payments', { keyPrefix: 'settings' });
  const { anyLocationHasPayments, anyLocationHasTerminalPayments } = useMerchantsInfo();
  const { enabled: hasPayments2Flow } = useFeatureFlag(PaymentsFeatureFlags.payments2Flow);
  const { isWeaveApp } = useMultiQueryUtils();
  const portalNavigate = useNavigate();
  const { navigate: settingsNavigate } = useSettingsNavigate();
  const navigate = (to: StaticSettingsRoutes) =>
    isWeaveApp
      ? settingsNavigate({ to })
      : portalNavigate({ to: `/portal/payments/settings${to.replace('/payments', '')}` });

  return (
    <>
      <section css={[settingStyles.groupMaxWidth, styles.indexRow]}>
        <IndexRow>
          <IndexRow.Item onClick={() => navigate(`/payments/account-info`)}>
            <IndexRow.Item.Title>{t('Account Info and Status')}</IndexRow.Item.Title>
          </IndexRow.Item>
          {anyLocationHasPayments && (
            <>
              <IndexRow.Item onClick={() => navigate(`/payments/general-settings`)}>
                <IndexRow.Item.Title>{t('General Settings')}</IndexRow.Item.Title>
              </IndexRow.Item>
              {hasPayments2Flow && anyLocationHasTerminalPayments && (
                <IndexRow.Item onClick={() => navigate(`/payments/terminals`)}>
                  <IndexRow.Item.Title>{t('Terminals')}</IndexRow.Item.Title>
                </IndexRow.Item>
              )}
              <IndexRow.Item onClick={() => navigate(`/payments/payment-methods`)}>
                <IndexRow.Item.Title>{t('Payment Methods')}</IndexRow.Item.Title>
              </IndexRow.Item>
              <IndexRow.Item onClick={() => navigate(`/payments/public-business-information`)}>
                <IndexRow.Item.Title>{t('Public Business Information')}</IndexRow.Item.Title>
              </IndexRow.Item>
              <IndexRow.Item onClick={() => navigate(`/payments/online-bill-pay`)}>
                <IndexRow.Item.Title>{t('Online Bill Pay')}</IndexRow.Item.Title>
              </IndexRow.Item>
            </>
          )}
        </IndexRow>
      </section>
    </>
  );
};
