import { useMemo } from 'react';
import { ScheduledSmsThread } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { ThreadDraft } from '@weave/schema-gen-ts/dist/schemas/sms/draft/v1/draft_service.pb';
import { ClientSettingsApi } from '@frontend/api-client-settings';
import { InboxQueries, MessagesTypes, SchemaSMSSharedEnums } from '@frontend/api-messaging';
import { InboxType } from '@frontend/inbox-navigation';
import { MultiSettingsHooks } from '@frontend/multi-settings';
import { MessageStatus } from '../components';

type UseThreadStatusProps = {
  thread: MessagesTypes.InboxListItem | ScheduledSmsThread | ThreadDraft;
  drafts?: ThreadDraft[];
  inboxType: InboxType;
  scheduledThreadsMap?: Record<string, ScheduledSmsThread[]>;
};
export const useThreadStatus = ({ thread, drafts, inboxType, scheduledThreadsMap }: UseThreadStatusProps) => {
  const draft = drafts?.find((draft) => draft.threadId === thread.threadId);
  const hasError = 'status' in thread && thread.status === MessagesTypes.KnownThreadStatuses.ERROR;
  const isRead = 'status' in thread && thread.status === MessagesTypes.KnownThreadStatuses.READ;
  const isNew = 'status' in thread && thread.status === MessagesTypes.KnownThreadStatuses.NEW;
  const isOutbound = 'direction' in thread && thread.direction === SchemaSMSSharedEnums.Direction.DIRECTION_OUTBOUND;

  const isBlockedQuery = InboxQueries.useIsBlocked(
    { locationId: thread.locationId, personPhone: thread.personPhone },
    { enabled: inboxType === InboxType.SCHEDULED || inboxType === InboxType.DRAFTS }
  );
  const isBlocked =
    'isBlocked' in thread ? !!thread.isBlocked : isBlockedQuery.data ? !!isBlockedQuery.data?.isBlocked : false;

  const showUnrepliedStatusSetting = MultiSettingsHooks.useBooleanClientSetting({
    set: ClientSettingsApi.querySetKeys.sets.showUnrepliedStatus,
    key: ClientSettingsApi.querySetKeys.keys.showUnrepliedStatus,
    defaultValue: 'false',
  });

  const status = useMemo(() => {
    if (isNew) return MessageStatus.UNREAD;
    if (!!draft) return MessageStatus.DRAFT;
    if (isBlocked) return MessageStatus.BLOCKED;
    if (hasError) return MessageStatus.ERROR;
    if (scheduledThreadsMap?.[thread.threadId]) {
      const scheduledThreads = scheduledThreadsMap[thread.threadId];
      if (scheduledThreads?.length === 1) {
        if (scheduledThreads[0]?.status !== OutboundMessageStatus.PAUSED) {
          return MessageStatus.SCHEDULED;
        }
        return MessageStatus.PAUSED_SCHEDULED;
      }
      return MessageStatus.MULTIPLE_SCHEDULED;
    }
    if ('status' in thread && thread.status === OutboundMessageStatus.PAUSED) return MessageStatus.PAUSED_SCHEDULED;
    if ('status' in thread && thread.status === OutboundMessageStatus.SCHEDULED) return MessageStatus.SCHEDULED;
    if (inboxType === InboxType.ARCHIVED) return MessageStatus.ARCHIVED;
    if (isOutbound) return MessageStatus.OUTGOING_SENT;
    if (showUnrepliedStatusSetting.aggregatedValue) return MessageStatus.UNREPLIED;
    return MessageStatus.READ;
  }, [
    isNew,
    !!draft,
    isBlocked,
    hasError,
    !!scheduledThreadsMap?.[thread.threadId],
    isOutbound,
    inboxType,
    showUnrepliedStatusSetting.aggregatedValue,
  ]);

  return {
    status,
    isDraft: !!draft,
    isRead,
    isNew,
    isBlocked,
    isOutbound,
    hasError,
    isBlockedQuery,
  };
};
