import { AnimatePresence } from 'framer-motion';
import { AsidePanel } from '@frontend/components';
import { useMatchMedia, breakpoints } from '@frontend/responsiveness';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { Tray } from '@frontend/design-system';
import { slidePanels } from './slide-panels';

export const ResponsiveSlidePanel = () => {
  const { show, mode = 'auto', panelType, setShow } = useSlidePanelShallowStore('show', 'mode', 'setShow', 'panelType');
  const mediumMax = useMatchMedia({ maxWidth: breakpoints.medium.max });
  const customizedModalControlProps = {
    show,
    onClose: () => {
      slidePanels[panelType]?.cleanupFn?.();

      setShow(false);
    },
    closeModal: () => {
      slidePanels[panelType]?.cleanupFn?.();

      setShow(false);
    },
  };

  return (
    <>
      {mode === 'modal' || (mode === 'auto' && mediumMax) ? (
        show && (
          <Tray css={{ padding: 0 }} width={slidePanels[panelType]?.size} {...customizedModalControlProps}>
            {slidePanels[panelType]?.modal}
          </Tray>
        )
      ) : (
        /*AnimatePresence component requires that any conditional rendering
      be placed inside of it in order to support exit animations.
      That's the main reason why "show" is inside of it.*/
        <AnimatePresence>
          {show && <AsidePanel width={slidePanels[panelType]?.size}>{slidePanels[panelType]?.panel}</AsidePanel>}
        </AnimatePresence>
      )}
    </>
  );
};
