export const queryKeys = {
  base: ['insurance-verification'] as const,
  userProfile: (id: string, isWeaveUser: boolean) => [
    ...queryKeys.base,
    'users',
    id,
    `${isWeaveUser ? 'weave-user' : 'non-weave-user'}`,
  ],
  invoiceBalance: (locationId?: string) => [...queryKeys.base, 'invoice-balance', locationId ?? ''],
  optOutFromWIVTrial: (locationId: string, userId: string) => ['trial-opt-out', locationId, userId, ...queryKeys.base],
  feedback: (locationId: string, userId: string) => ['feedback', locationId, userId, ...queryKeys.base],
};
