import { css } from '@emotion/react';
import { isFunction } from 'lodash-es';
import { useMultiQueryUtils } from '@frontend/payments-hooks';
import { Chip, useTooltip } from '@frontend/design-system';

export type LocationChipProps = {
  showLocationCount?: boolean;
  showLocationChip?: boolean;
};
export function LocationChip({ showLocationChip = false, showLocationCount = false }: LocationChipProps) {
  const { locationIds, locationId, getLocationName, isMultiLocationFeature } = useMultiQueryUtils();

  if (!showLocationChip) {
    return null;
  }

  return showLocationCount ? (
    <LocationCountChip locationIds={locationIds} />
  ) : (
    isMultiLocationFeature && (
      <Chip.SingleChip
        variant='eggplant'
        css={css`
          width: auto;
          max-width: none;
        `}
      >
        {getLocationName(locationId)}
      </Chip.SingleChip>
    )
  );
}

const LocationCountChip = ({
  locationIds,
  getLocationName,
}: {
  locationIds: string[];
  getLocationName?: (locationId: string) => string | undefined;
}) => {
  const { Tooltip, tooltipProps, triggerProps } = useTooltip();
  return (
    <div {...triggerProps}>
      <Chip.MultiChip variant='warningDark'>{locationIds.length} location(s)</Chip.MultiChip>

      {isFunction(getLocationName) && (
        <Tooltip {...tooltipProps}>
          {locationIds.map((locationId) => getLocationName?.(locationId) ?? '').join(', ')}
        </Tooltip>
      )}
    </div>
  );
};
