import { motion } from 'framer-motion';
import { Photos } from '@frontend/photos';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { ListRow } from '@frontend/design-system';
import { BulkActionCheckbox } from './bulk-action-checkbox';

const LEAD_CONTAINER = {
  LARGE: 44,
  SMALL: 32,
} as const;

type InboxListItemLeadProps = {
  isBulkSelect: boolean;
  locationId?: string;
  personId?: string;
  firstName?: string;
  lastName?: string;
  isCheckboxSelected: boolean;
  setCheckboxSelected: (value: boolean) => void;
};

export const InboxListItemLead = ({
  isBulkSelect,
  firstName,
  lastName,
  locationId,
  personId = '',
  isCheckboxSelected,
  setCheckboxSelected,
}: InboxListItemLeadProps) => {
  return (
    <ListRow.Lead>
      <div
        css={{
          display: 'flex',
          overflow: 'hidden',
          alignItems: 'center',
          width: LEAD_CONTAINER.LARGE,
          padding: theme.spacing(0.25),
        }}
      >
        <motion.div
          initial={false}
          animate={{ x: isBulkSelect ? -LEAD_CONTAINER.LARGE : 0 }}
          transition={{ ease: 'easeInOut' }}
        >
          <Photos.ContactProfilePhoto
            firstName={firstName}
            lastName={lastName}
            personId={personId}
            disableClick={!personId}
            locationId={locationId}
            onClick={(e) => e?.stopPropagation()}
            data-trackingid={`${InboxPrefixes.List}-avatar`}
          />
        </motion.div>
        <motion.div
          initial={false}
          animate={{
            x: isBulkSelect ? -LEAD_CONTAINER.SMALL : theme.spacing(1),
          }}
          transition={{ ease: 'easeInOut' }}
        >
          <BulkActionCheckbox
            isCheckboxSelected={isCheckboxSelected}
            onSelect={(value) => setCheckboxSelected(value)}
            bulkSelectionIsActive={isBulkSelect}
          />
        </motion.div>
      </div>
    </ListRow.Lead>
  );
};
