import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { DevicesApi } from '@frontend/api-devices';
import { i18next } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { SkeletonLoader } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { trackingId } from '../../../tracking';
import { InstructionFieldsProps, ForwardDevicePayload } from '../types';
import { InstructionFields } from './base';

export const ForwardDeviceInstructionFields = ({
  onChange,
  option,
}: {
  onChange: InstructionFieldsProps<ForwardDevicePayload>['onChange'];
  option: ForwardDevicePayload;
}) => {
  const { singleLocationId, accessibleLocationData } = useAppScopeStore();
  const location = accessibleLocationData[singleLocationId];

  const { data = [], isLoading } = useQuery({
    queryKey: [...queryKeys.devicesWithAddresses(), location.id],
    queryFn: () => DevicesApi.listDevicesWithHeaders(location.id),
    select: (data) => {
      return data
        .sort((a, b) => a.sipDevice.name.localeCompare(b.sipDevice.name))
        .map((device) => ({
          value: device.sipDevice.ID,
          label: device.sipDevice.name,
        }));
    },
    staleTime: 0,
  });

  useEffect(() => {
    if (!option?.deviceId && data.length > 0) {
      onChange({ deviceId: data[0].value ?? '' });
    }
  }, [option?.deviceId, data]);

  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <InstructionFields<ForwardDevicePayload>
      onChange={onChange}
      schema={schema}
      customization={{
        optionGroups: {
          deviceId: data,
        },
        value: {
          deviceId: option.deviceId || (data.length > 0 ? data[0].value : ''),
          callerName: option.deviceId ? option.callerName : '',
        },
      }}
    />
  );
};

const schema = {
  type: 'forwardDevice',
  fields: {
    deviceId: {
      label: i18next.t('Device', { ns: 'phone' }),
      name: 'deviceId',
      type: 'dropdown',
    },
    callerName: {
      label: i18next.t('Caller Label (Optional)', { ns: 'phone' }),
      name: 'callerName',
      type: 'text',
      helperText: i18next.t('Label displays on Weave phone screen while call is ringing', { ns: 'phone' }),
      trackingId: trackingId({ context: 'setting', feature: 'phone-trees', details: 'caller-label' }),
    },
  },
} as const;
