import { FC } from 'react';
import { FormsSubmission } from '@frontend/api-forms';
import { Text, ModalControlTriggerProps } from '@frontend/design-system';
import AttachmentItem from './attachment-item/attachment-item.component';
import { containerStyle, headerTextStyle, innerContainerStyle } from './attachment-list.styles';

interface AttachmentListProps {
  submission: FormsSubmission.Types.NormalizedSubmissionDetailResponse;
  modalTriggerProps: ModalControlTriggerProps;
  onSelectAttachment: (attachments: FormsSubmission.Types.AttachmentData[]) => void;
}

const AttachmentList: FC<React.PropsWithChildren<AttachmentListProps>> = ({
  submission,
  modalTriggerProps,
  onSelectAttachment,
}) => {
  if (!submission.attachments) {
    return null;
  }

  function selectAttachmentHandler(attachment: FormsSubmission.Types.AttachmentData) {
    onSelectAttachment([attachment]);
    modalTriggerProps.onClick();
  }

  return (
    <div css={containerStyle}>
      <div css={innerContainerStyle}>
        <Text size='large' css={headerTextStyle}>
          Attachments
        </Text>

        {submission.attachments.map((attachment) => (
          <AttachmentItem key={attachment.id} attachment={attachment} onSelectAttachment={selectAttachmentHandler} />
        ))}
      </div>
    </div>
  );
};

export default AttachmentList;
