import { FC, useMemo } from 'react';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartData, Chart } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { useLocations } from '../../hooks';
import {
  calculateArrayAverage,
  calculateNumberAverage,
  ExportUtils,
  getHourlyInsightsLabel,
  hourlyInsightsLimiter,
  toHHmmss,
} from '../../utils';
import { DemoChip } from '../demo-chip';
import { InfoTipPopover } from '../info-tip-popover';
import { usePhoneAnalyticsShallowStore } from './hooks';

interface Props {
  colors: Record<string, string>;
  data: PhoneAnalyticsTypes.ChartsData;
  isHourlyInsights?: boolean;
  isLoading?: boolean;
}

const defaultBarChartAppearance = {
  collectiveTooltip: true,
  customYAxisTickFormat: toHHmmss,
  margin: { left: 20 },
  showGridLines: true,
  showXAxis: true,
  showYAxis: true,
};

const labels: Record<string, string> = {
  abandoned: i18next.t('Abandoned', { ns: 'analytics' }),
  answered: i18next.t('Answered', { ns: 'analytics' }),
  incoming: i18next.t('Incoming', { ns: 'analytics' }),
  missed: i18next.t('Missed', { ns: 'analytics' }),
  outgoing: i18next.t('Outgoing', { ns: 'analytics' }),
  patient: i18next.t('Patient', { ns: 'analytics' }),
  total: i18next.t('Total', { ns: 'analytics' }),
  unknown: i18next.t('Unknown', { ns: 'analytics' }),
  weaveAverage: i18next.t('Weave Average', { ns: 'analytics' }),
};

export const DurationAnalyticsPanel: FC<React.PropsWithChildren<Props>> = ({
  colors,
  data,
  isHourlyInsights,
  isLoading,
}) => {
  const { t } = useTranslation('analytics');
  const { filterHintText, filters, isDemoAccount } = usePhoneAnalyticsShallowStore(
    'filterHintText',
    'filters',
    'isDemoAccount'
  );
  const { locations } = useLocations();

  const totalCallsDuration: BarChartData = useMemo(() => {
    if (isHourlyInsights) {
      // In case of hourly insights, there will be only one object for the given date
      const { incoming, outgoing, total } = Object.values(data.totalCallsDuration)[0] || {};
      const limitedIncoming = hourlyInsightsLimiter(incoming as number[]);
      const limitedOutgoing = hourlyInsightsLimiter(outgoing as number[]);
      const limitedTotal = hourlyInsightsLimiter(total as number[]);
      return {
        groups: limitedTotal.map((value, i) => ({
          name: getHourlyInsightsLabel(i),
          values: {
            incoming: limitedIncoming[i],
            outgoing: limitedOutgoing[i],
            total: value,
          },
        })),
      };
    } else {
      return {
        groups: Object.entries(data.totalCallsDuration).map(([key, { incoming, outgoing, total }]) => ({
          name: key,
          values: { incoming: incoming as number, outgoing: outgoing as number, total: total as number },
        })),
      };
    }
  }, [data.totalCallsDuration]);

  const patientVsUnknownCallsDuration: BarChartData = useMemo(() => {
    if (isHourlyInsights) {
      // In case of hourly insights, there will be only one object for the given date
      const { patient, unknown } = Object.values(data.totalUnknownVsPatientCallsDuration)[0] || {};
      const limitedPatient = hourlyInsightsLimiter(patient as number[]);
      const limitedUnknown = hourlyInsightsLimiter(unknown as number[]);
      return {
        groups: limitedPatient.map((value, i) => ({
          name: getHourlyInsightsLabel(i),
          values: {
            unknown: limitedUnknown[i],
            patient: value,
          },
        })),
      };
    } else {
      return {
        groups: Object.entries(data.totalUnknownVsPatientCallsDuration).map(([key, { patient, unknown }]) => ({
          name: key,
          values: { patient: patient as number, unknown: unknown as number },
        })),
      };
    }
  }, [data.totalUnknownVsPatientCallsDuration]);

  const averageCallsDuration: BarChartData = useMemo(() => {
    if (isHourlyInsights) {
      // In case of hourly insights, there will be only one object for the given date
      const { incoming: incomingDuration, outgoing: outgoingDuration } =
        Object.values(data.totalCallsDuration)[0] || {};
      const { incoming: incomingCalls, outgoing: outgoingCalls } = Object.values(data.totalCalls)[0] || {};
      const averageIncoming = calculateArrayAverage(
        hourlyInsightsLimiter(incomingDuration as number[]),
        hourlyInsightsLimiter(incomingCalls as number[])
      );
      const averageOutgoing = calculateArrayAverage(
        hourlyInsightsLimiter(outgoingDuration as number[]),
        hourlyInsightsLimiter(outgoingCalls as number[])
      );
      return {
        groups: averageIncoming.map((value, i) => ({
          name: getHourlyInsightsLabel(i),
          values: {
            incoming: value,
            outgoing: averageOutgoing[i],
          },
        })),
      };
    } else {
      return {
        groups: Object.entries(data.totalCallsDuration).map(([key, value]) => ({
          name: key,
          values: {
            incoming: calculateNumberAverage(value.incoming as number, data.totalCalls[key]?.incoming as number),
            outgoing: calculateNumberAverage(value.outgoing as number, data.totalCalls[key]?.outgoing as number),
          },
        })),
      };
    }
  }, [data.totalCallsDuration]);

  const averageIncomingCallsDuration: BarChartData = useMemo(() => {
    if (isHourlyInsights) {
      // In case of hourly insights, there will be only one object for the given date
      const {
        abandoned: abandonedDuration,
        answered: answeredDuration,
        missed: missedDuration,
      } = Object.values(data.incomingCallsDuration)[0] || {};
      const {
        abandoned: abandonedCalls,
        answered: answeredCalls,
        missed: missedCalls,
      } = Object.values(data.totalIncomingCalls)[0] || {};
      const averageAbandoned = calculateArrayAverage(
        hourlyInsightsLimiter(abandonedDuration as number[]),
        hourlyInsightsLimiter(abandonedCalls as number[])
      );
      const averageAnswered = calculateArrayAverage(
        hourlyInsightsLimiter(answeredDuration as number[]),
        hourlyInsightsLimiter(answeredCalls as number[])
      );
      const averageMissed = calculateArrayAverage(
        hourlyInsightsLimiter(missedDuration as number[]),
        hourlyInsightsLimiter(missedCalls as number[])
      );
      return {
        groups: averageAbandoned.map((value, i) => ({
          name: getHourlyInsightsLabel(i),
          values: {
            abandoned: value,
            answered: averageAnswered[i],
            missed: averageMissed[i],
          },
        })),
      };
    } else {
      return {
        groups: Object.entries(data.incomingCallsDuration).map(([key, value]) => ({
          name: key,
          values: {
            abandoned: calculateNumberAverage(
              value.abandoned as number,
              data.totalIncomingCalls[key]?.abandoned as number
            ),
            answered: calculateNumberAverage(
              value.answered as number,
              data.totalIncomingCalls[key]?.answered as number
            ),
            missed: calculateNumberAverage(value.missed as number, data.totalIncomingCalls[key]?.missed as number),
          },
        })),
      };
    }
  }, [data.incomingCallsDuration, data.totalIncomingCalls]);

  const longDurationCalls: BarChartData = useMemo(() => {
    if (isHourlyInsights) {
      // In case of hourly insights, there will be only one object for the given date
      const { incoming, outgoing } = Object.values(data.longDurationCalls)[0] || {};
      const limitedIncoming = hourlyInsightsLimiter(incoming as number[]);
      const limitedOutgoing = hourlyInsightsLimiter(outgoing as number[]);
      return {
        groups: limitedIncoming.map((value, i) => ({
          name: getHourlyInsightsLabel(i),
          values: {
            incoming: value,
            outgoing: limitedOutgoing[i],
          },
        })),
      };
    } else {
      return {
        groups: Object.entries(data.longDurationCalls).map(([key, { incoming, outgoing }]) => ({
          name: key,
          values: { incoming: incoming as number, outgoing: outgoing as number },
        })),
      };
    }
  }, [data.longDurationCalls]);

  const exportPdfProps = useMemo(
    () => ExportUtils.exportChartToPdfProps(filters.LocationID || [], locations),
    [filters.LocationID, locations]
  );

  return (
    <>
      <Chart colors={colors} isLoading={isLoading} labels={labels}>
        <Chart.Header
          {...exportPdfProps}
          infoTip={
            <InfoTipPopover>{t('Total duration of incoming and outgoing calls. Value is in hh:mm:ss')}</InfoTipPopover>
          }
          leftElement={isDemoAccount && <DemoChip />}
          subtitle={filterHintText}
          title={t('Total Call Duration')}
        />
        <Chart.Legends />
        <Chart.BarChart appearance={defaultBarChartAppearance} data={totalCallsDuration} formatValue={toHHmmss} />
      </Chart>

      <Chart colors={colors} isLoading={isLoading} labels={labels}>
        <Chart.Header
          {...exportPdfProps}
          infoTip={
            <InfoTipPopover>
              {t(
                'Refers to total time duration office spent while talking to patients vs unknown phone numbers. Value is in hh:mm:ss'
              )}
            </InfoTipPopover>
          }
          leftElement={isDemoAccount && <DemoChip />}
          subtitle={filterHintText}
          title={t('Patient vs Unknown')}
        />
        <Chart.Legends />
        <Chart.BarChart
          appearance={defaultBarChartAppearance}
          data={patientVsUnknownCallsDuration}
          formatValue={toHHmmss}
        />
      </Chart>

      <Chart colors={colors} isLoading={isLoading} labels={labels}>
        <Chart.Header
          {...exportPdfProps}
          infoTip={
            <InfoTipPopover>
              {t(
                'Incoming vs Outgoing: Refers to the average duration of the incoming and outgoing calls. Value is in hh:mm:ss'
              )}
            </InfoTipPopover>
          }
          leftElement={isDemoAccount && <DemoChip />}
          subtitle={filterHintText}
          title={t('Average Call Duration')}
        />
        <Chart.Legends />
        <Chart.BarChart appearance={defaultBarChartAppearance} data={averageCallsDuration} formatValue={toHHmmss} />
      </Chart>

      <Chart colors={colors} isLoading={isLoading} labels={labels}>
        <Chart.Header
          {...exportPdfProps}
          infoTip={
            <InfoTipPopover>
              {t(
                'Incoming: Refers to the average time on Answered, Missed and Abandoned calls. It only includes Incoming calls. Value is in hh:mm:ss'
              )}
            </InfoTipPopover>
          }
          leftElement={isDemoAccount && <DemoChip />}
          subtitle={filterHintText}
          title={t('Average Call Duration - Incoming')}
        />
        <Chart.Legends />
        <Chart.BarChart
          appearance={defaultBarChartAppearance}
          data={averageIncomingCallsDuration}
          formatValue={toHHmmss}
        />
      </Chart>

      <Chart colors={colors} isLoading={isLoading} labels={labels}>
        <Chart.Header
          {...exportPdfProps}
          infoTip={<InfoTipPopover>{t('Number of calls with duration greater than 10 mins')}</InfoTipPopover>}
          leftElement={isDemoAccount && <DemoChip />}
          subtitle={filterHintText}
          title={t('Long Duration Calls')}
        />
        <Chart.Legends />
        <Chart.BarChart appearance={defaultBarChartAppearance} data={longDurationCalls} formatValue={toHHmmss} />
      </Chart>
    </>
  );
};
