import { Dispatch, useReducer, useRef } from 'react';
import { debounce } from 'lodash-es';
import { useTranslation, i18next } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { useFeatureFlagShallowStore } from '@frontend/shared';
import { theme } from '@frontend/theme';
import {
  DropdownField,
  IconButton,
  PrimaryButton,
  TextLink,
  useFormField,
  Text,
  SwitchField,
  MultiselectField,
  useForm,
  FormFieldActionTypes,
  useAlert,
  Button,
  Heading,
} from '@frontend/design-system';
import { usePhoneSettingsShallowStore } from '../../../store/settings';
import { trackingId } from '../../../tracking';
import { AudioPicker } from '../../audio-picker/audio-picker';
import { SettingsCard, SettingsSection } from '../../common/settings-card';
import {
  Instruction,
  PhoneTreeReducerAction,
  PhoneTreeReducerState,
  PhoneTreeDialOption,
  PhoneTreeRouteType,
  ForwardNumberPayload,
  PlayMessagePayload,
  CallGroupPayload,
  CallQueuePayload,
  ForwardDevicePayload,
  DepartmentPayload,
  SubTreePayload,
  VoicemailPromptPayload,
} from '../types';
import { usePhoneTreeMutations } from '../use-phone-tree-mutations';
import { CallGroupInstructionField } from './call-group';
import { CallQueueInstructionField } from './call-queue';
import { DepartmentInstructionField } from './department';
import { ForwardDeviceInstructionFields } from './forward-device';
import { ForwardNumberInstructionFields } from './forward-number';
import { NavigateInstructionFields } from './navigate';
import { PlayMessageInstructionFields } from './play-message';
import { SubTreeInstructionField } from './subtree';
import { VoicemailPromptInstructionFields } from './voicemail-prompt';

/**
 * We're are using fields (useForm/useFormField) solely for the state that it provides to the form components.
 * `value` and `onChange` control are ceded to the parent state setter/getters that ultimately call the reducer.
 *
 * The only time we should be using values from the fields is when extracting changed values to then set the parent state.
 */
export const reducer = (state: PhoneTreeReducerState, action: PhoneTreeReducerAction) => {
  const { dialOptions } = state;
  switch (action.type) {
    case 'ADD_FALLBACK': {
      const targetIndex = dialOptions.findIndex((o) => o.number === action.payload.number);
      const targetOption = dialOptions[targetIndex];
      const newOption = {
        ...targetOption,
        routes: [
          ...targetOption.routes,
          {
            type: PhoneTreeRouteType.ROUTE_TYPE_CALL_GROUP,
            payload: {
              callGroupId: '',
            },
          },
        ],
      };
      const newOptions = [...dialOptions.slice(0, targetIndex), newOption, ...dialOptions.slice(targetIndex + 1)];
      return { ...state, dialOptions: newOptions };
    }
    case 'ADD_OPTION': {
      const newOption = {
        number: action.payload.number,
        routes: [
          {
            type: PhoneTreeRouteType.ROUTE_TYPE_CALL_GROUP,
            payload: {
              callGroupId: '',
            },
          },
        ],
      };
      return { ...state, dialOptions: [...dialOptions, newOption].sort((a, b) => a.number - b.number) };
    }
    case 'UPDATE_OPTION': {
      const targetIndex = dialOptions.findIndex((o) => o.number === action.payload.outgoing.number);
      const newOptions = [
        ...dialOptions.slice(0, targetIndex),
        action.payload?.incoming,
        ...dialOptions.slice(targetIndex + 1),
      ]
        .filter(Boolean)
        .sort((a, b) => a.number - b.number);
      return { ...state, dialOptions: newOptions };
    }
    case 'DELETE_OPTION': {
      return { ...state, dialOptions: dialOptions.filter((o) => o.number !== action.payload.outgoing.number) };
    }
    case 'UPDATE': {
      return { ...state, ...action.payload };
    }
    case 'RESET': {
      return {
        ...state,
        phoneTreeId: '',
        locationIds: [],
        name: '',
        dialOptions: [],
        promptMediaFileId: '',
        extensionDialing: false,
      };
    }
    default:
      return state;
  }
};

/**
 * This component contains a list of MenuOptions. It does not do much beyond rendering the menu options
 */
export const PhoneTreeFields = ({
  options,
  dispatch,
  state,
  condensed,
}: {
  options: PhoneTreeDialOption[];
  dispatch: Dispatch<PhoneTreeReducerAction>;
  state?: PhoneTreeReducerState;
  condensed?: boolean;
}) => {
  const { t } = useTranslation('phone');
  const updateOptions = debounce((outgoingOption: PhoneTreeDialOption, option: PhoneTreeDialOption) => {
    dispatch({ type: 'UPDATE_OPTION', payload: { outgoing: outgoingOption, incoming: option } });
  }, 300);
  const switchField = useFormField({ type: 'switch', value: state?.extensionDialing });

  const usedNumbers = options.map((option) => option.number);
  const availableNumbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0].filter((n) => !usedNumbers.includes(n));

  return (
    <SettingsCard
      condensed={condensed}
      title={t('Phone Tree')}
      description={t(
        'Add a dial number and choose from the drop down where you want calls to go when someone selects that number.'
      )}
    >
      <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
        <section>
          <SwitchField
            {...switchField}
            onChange={(e) => {
              switchField.onChange(e);
              // @ts-ignore - event types are not correct
              dispatch({ type: 'UPDATE', payload: { extensionDialing: e.value } });
            }}
            css={{
              width: 'max-content',
              label: {
                marginRight: theme.spacing(1),
              },
            }}
            labelPlacement='left'
            name='dial-by-extension'
            label={<Text weight={'bold'}>{t('Dial by Extension')}</Text>}
            trackingId={trackingId({ context: 'setting', feature: 'phone-trees', details: 'dial-by-extension' })}
          />
          <Text size='medium'>
            {t('Activating Dial by Extension allows callers in this phone tree to dial office device extensions.')}
          </Text>
        </section>
        {!options.length && condensed && (
          <>
            <Text size='large' weight={'bold'}>
              {t('Dial Numbers')}
            </Text>
            <section
              css={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: theme.spacing(3),
                borderRadius: theme.borderRadius.medium,
                padding: theme.spacing(3),
                textAlign: 'center',
                backgroundColor: theme.colors.neutral5,
              }}
            >
              <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: theme.spacing(1) }}>
                <Heading level={3} css={{ color: theme.colors.neutral50 }}>
                  {t('No Items to Display')}
                </Heading>
                <Text color='light' style={{ width: theme.spacing(55) }}>
                  {t("You'll find the devices you can connect to here.")}
                </Text>
              </div>
              <Button
                variant='primary'
                size='large'
                onClick={() => {
                  if (availableNumbers.length > 0) {
                    dispatch({ type: 'ADD_OPTION', payload: { number: availableNumbers[0] } });
                    focusOption(availableNumbers[0].toString());
                  }
                }}
              >
                <Icon name='plus' />
                {t('Add Dial Number')}
              </Button>
            </section>
          </>
        )}
        {options.map((option) => (
          <MenuOption
            usedNumbers={usedNumbers}
            option={option}
            phonetreeId={state?.phoneTreeId}
            updateOptions={(incomingOption) => updateOptions(option, incomingOption)}
            key={option.number}
            deleteOption={(option) => {
              dispatch({ type: 'DELETE_OPTION', payload: { outgoing: option } });
            }}
            addFallback={(number) => {
              dispatch({ type: 'ADD_FALLBACK', payload: { number } });
            }}
          />
        ))}
        {(!condensed || (condensed && !!options.length)) && (
          <TextLink
            css={{
              gridArea: 'add-instruction',
              display: 'flex',
              gap: theme.spacing(1),
              alignItems: 'center',
              width: 'fit-content',
            }}
            weight='bold'
            size='large'
            onClick={() => {
              if (availableNumbers.length > 0) {
                dispatch({ type: 'ADD_OPTION', payload: { number: availableNumbers[0] } });
                focusOption(availableNumbers[0].toString());
              }
            }}
            trackingId={trackingId({ context: 'setting', feature: 'phone-trees', details: 'add-dial-number' })}
          >
            <Icon name='plus' />
            {t('Add Dial Number')}
          </TextLink>
        )}
      </div>
    </SettingsCard>
  );
};

export const LabelsCard = ({
  dispatch,
  labels = [],
}: {
  dispatch: Dispatch<PhoneTreeReducerAction>;
  labels: string[];
}) => {
  const { t } = useTranslation('phone');
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');

  const { getFieldProps } = useForm({
    fields: {
      locations: {
        type: 'multiselect',
        value: labels,
      },
    },
    fieldStateReducer: (state, action) => {
      if (action.type === FormFieldActionTypes.Update) {
        dispatch({ type: 'UPDATE', payload: { locationIds: state.locations.value } });
      }

      return state;
    },
  });

  const options = settingsTenantLocation?.children?.map((item) => {
    return {
      locationId: item.locationId,
      name: item.name,
    };
  });

  return (
    <SettingsCard
      title={t('Locations (Optional)')}
      description={t('Label this Phone Tree with locations for reference and filtering.')}
      condensed
    >
      <div data-trackingId={trackingId({ context: 'setting', feature: 'phone-trees', details: 'location-select' })}>
        <MultiselectField
          css={{ width: '600px' }}
          {...getFieldProps('locations')}
          label={t('Locations')}
          name='locations'
          helperText={t('Selecting none will auto-label all locations to this Phone Tree.')}
          data-trackingid={trackingId({ context: 'setting', feature: 'phone-trees', details: 'location-select' })}
        >
          {options?.map((option) => (
            <MultiselectField.Option key={option.locationId} value={option.locationId ?? ''}>
              {option.name}
            </MultiselectField.Option>
          ))}
        </MultiselectField>
      </div>
    </SettingsCard>
  );
};

export const PromptCard = ({
  dispatch,
  tenantId,
  greetingId,
  condensed,
}: {
  dispatch: Dispatch<PhoneTreeReducerAction>;
  tenantId: string;
  greetingId: string;
  condensed?: boolean;
}) => {
  const { t } = useTranslation('phone');
  const greetingField = useFormField({ type: 'dropdown', value: greetingId });

  return (
    <SettingsCard
      condensed={condensed}
      title={t('Phone Tree Prompt')}
      description={t(
        'Select a prompt with the available options. This message will play when callers reach your phone tree.'
      )}
    >
      <SettingsSection css={{ width: 'auto' }}>
        <AudioPicker
          widths={{ field: 320, scrubber: 320 }}
          field={greetingField}
          onSelect={(e) => {
            greetingField.onChange({ name: e.name, value: e.id });
            if (greetingId !== e.id) {
              dispatch({ type: 'UPDATE', payload: { promptMediaItemId: e.id } });
            }
          }}
          tenantId={tenantId}
          allowedOptions={{ add: true, standard: false, custom: true }}
          name='greetingMediaId'
          labels={{
            placeholder: t('Select Greeting'),
          }}
        />
      </SettingsSection>
    </SettingsCard>
  );
};

export const PhoneTreePage = ({
  phoneTree,
  tenantId,
  name,
}: {
  name: string;
  phoneTree: PhoneTreeReducerState;
  tenantId: string;
}) => {
  const { t } = useTranslation('phone');
  const [state, dispatch] = useReducer(reducer, phoneTree);
  const { selectedParentsIds } = useAppScopeStore();

  const dialOptionState = {
    phoneTreeId: state.phoneTreeId,
    dialOptions: state.dialOptions,
  };

  const noDialOptionState = {
    phoneTreeId: state.phoneTreeId,
    tenantId: state.tenantId,
    locationIds: state.locationIds,
    name: name,
    promptMediaItemId: state.promptMediaItemId,
    extensionDialing: state.extensionDialing,
  };

  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const alerts = useAlert();
  const previousDialOptionState = useRef(dialOptionState);
  const previousNoDialOptionState = useRef(noDialOptionState);

  const { updateDialOptions, update } = usePhoneTreeMutations(settingsTenantLocation?.phoneTenantId ?? '');

  const handleSave = () => {
    const dialOptionsChanged = JSON.stringify(previousDialOptionState.current) !== JSON.stringify(dialOptionState);
    const noDialOptionsChanged =
      JSON.stringify(previousNoDialOptionState.current) !== JSON.stringify(noDialOptionState);

    if (dialOptionsChanged) {
      updateDialOptions.mutate(dialOptionState, {
        onSuccess: () => {
          alerts.success(t('Phone Tree updated successfully.'));
        },
        onError: () => {
          alerts.error(t('System failed to update Phone Tree dial options. Please try again.'));
        },
      });
    }

    if (noDialOptionsChanged) {
      update.mutate(noDialOptionState, {
        onSuccess: () => {
          alerts.success(t('Phone Tree updated successfully.'));
        },
        onError: () => {
          alerts.error(t('System failed to update Phone Tree. Please try again.'));
        },
      });
    }

    previousDialOptionState.current = dialOptionState;
    previousNoDialOptionState.current = noDialOptionState;
  };

  return (
    <div css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
      <PromptCard dispatch={dispatch} tenantId={tenantId} greetingId={state.promptMediaItemId} />
      <PhoneTreeFields options={state.dialOptions} dispatch={dispatch} state={state} />
      {!!selectedParentsIds.length && <LabelsCard dispatch={dispatch} labels={state.locationIds} />}
      <div>
        <PrimaryButton
          onClick={handleSave}
          css={{ width: 'min-content' }}
          trackingId={trackingId({ context: 'setting', feature: 'phone-trees', details: 'save' })}
        >
          {t('Save')}
        </PrimaryButton>
      </div>
    </div>
  );
};

/**
 * This is the component that represents a single menu option in the phone tree. It is visually surrounded by a border.
 * It contains a dropdown for the number, and a list of InstructionRows.
 *
 * This component controls the option number, option removal, and instruction addition or removal.
 * Instruction detail changes are handled by the InstructionRow component.
 */
const MenuOption = ({
  usedNumbers,
  option,
  phonetreeId,
  updateOptions,
  deleteOption,
  addFallback,
}: {
  usedNumbers: number[];
  option: PhoneTreeDialOption;
  phonetreeId?: string;
  updateOptions: (option: PhoneTreeDialOption) => void;
  deleteOption: (option: PhoneTreeDialOption) => void;
  addFallback: (number: number) => void;
}) => {
  const { t } = useTranslation('phone');
  const numberField = useFormField({ type: 'dropdown', value: option.number.toString() });

  const onNumberChange = (num: number) => {
    updateOptions({ ...option, number: num });
  };

  const onInstructionChange = (instruction: Instruction, index: number) => {
    // if (instruction.type === 'delete') {
    if (instruction.type === 'ROUTE_TYPE_BACK') {
      const newInstructions = [...option.routes.slice(0, index), ...option.routes.slice(index + 1)];
      if (newInstructions.length === 0) {
        deleteOption(option);
      } else {
        updateOptions({
          ...option,
          routes: newInstructions,
        });
      }
    } else {
      updateOptions({
        ...option,
        routes: [...option.routes.slice(0, index), instruction, ...option.routes.slice(index + 1)],
      });
    }
  };

  const disableFallback = option.routes.some(
    (route) =>
      route.type === 'ROUTE_TYPE_REPEAT' ||
      route.type === 'ROUTE_TYPE_VOICEMAIL' ||
      route.type === 'ROUTE_TYPE_CALL_ROUTE' ||
      route.type === 'ROUTE_TYPE_TOP' ||
      route.type === 'ROUTE_TYPE_BACK' ||
      route.type === 'ROUTE_TYPE_EXIT'
  );

  return (
    <section
      css={{
        display: 'grid',
        gridTemplateColumns: 'minmax(70px, 100px) auto',
        gridTemplateAreas:
          option.routes.length === 0
            ? '"number button" "hr hr" "instruction instruction" "add-instruction add-instruction"'
            : '"number button" "hr hr" "instruction instruction"',
        alignItems: 'center',
        gap: theme.spacing(2),
        border: `1px solid ${theme.colors.neutral20}`,
        borderRadius: theme.borderRadius.medium,
        padding: theme.spacing(3),
        ':focus': {
          outline: `2px solid ${theme.colors.primary50}`,
          borderColor: 'transparent',
        },
      }}
      tabIndex={0}
      data-number={option.number}
    >
      <div style={{ gridArea: 'number' }}>
        <DropdownField
          {...numberField}
          onChange={(e) => {
            numberField.onChange(e);
            onNumberChange(parseInt(e.value));
            focusOption(e.value);
          }}
          value={option.number.toString() ?? numberField.value}
          name='number'
          label={t('Dial #')}
        >
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((n) => (
            <DropdownField.Option key={n} disabled={usedNumbers.includes(n)} value={n.toString()}>
              {n.toString()}
            </DropdownField.Option>
          ))}
        </DropdownField>
      </div>
      <IconButton
        css={{ gridArea: 'button', justifySelf: 'end' }}
        onClick={() => {
          deleteOption(option);
        }}
        label='delete'
        trackingId={trackingId({ context: 'setting', feature: 'phone-trees', details: 'delete-option' })}
      >
        <Icon name='x-small' />
      </IconButton>
      <div style={{ gridArea: 'hr', height: 1, background: theme.colors.neutral20 }} />
      <div style={{ gridArea: 'instruction', display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
        {option.routes[0] && (
          <InstructionRow
            key={`${option.routes[0].type}-${1}`}
            instruction={option.routes[0]}
            phonetreeId={phonetreeId}
            onChange={(incomingInstruction) => onInstructionChange(incomingInstruction, 0)}
          />
        )}
        {option.routes.slice(1).map((instruction, index) => (
          <div
            style={{
              border: `1px solid ${theme.colors.neutral20}`,
              borderRadius: theme.borderRadius.medium,
              padding: theme.spacing(2),

              display: 'grid',
              gap: theme.spacing(2),
              gridTemplateColumns: '1fr auto',
            }}
          >
            <InstructionRow
              key={`${instruction.type}-${index + 1}`}
              instruction={instruction}
              onChange={(incomingInstruction) => onInstructionChange(incomingInstruction, index + 1)}
            />
            <IconButton
              css={{ justifySelf: 'end', alignSelf: 'start' }}
              onClick={() => {
                onInstructionChange({ type: PhoneTreeRouteType.ROUTE_TYPE_BACK, payload: {} }, index + 1);
              }}
              label='delete'
              trackingId={trackingId({ context: 'setting', feature: 'phone-trees', details: 'route-back' })}
            >
              <Icon name='x-small' />
            </IconButton>
          </div>
        ))}
        {/* This condition is not accurate - not every instruction allows you to add up to 4 fallbacks - we need to compile a list of rules for this */}
        {option.routes.length > 0 && option.routes.length < 4 && (
          <TextLink
            css={{ display: 'flex', gap: theme.spacing(1), alignItems: 'center', width: 'fit-content' }}
            disabled={disableFallback}
            weight='bold'
            size='medium'
            onClick={() => {
              addFallback(option.number);
            }}
            trackingId={trackingId({ context: 'setting', feature: 'phone-trees', details: 'add-fallback-option' })}
          >
            <Icon name='plus-small' />
            {t('Add Fallback Option')}
          </TextLink>
        )}
      </div>
      {option.routes.length === 0 && (
        <TextLink
          css={{ gridArea: 'add-instruction', display: 'flex', gap: theme.spacing(1), alignItems: 'center' }}
          weight='bold'
          size='medium'
          onClick={() => {
            addFallback(option.number);
          }}
          trackingId={trackingId({ context: 'setting', feature: 'phone-trees', details: 'add-fallback-option' })}
        >
          <Icon name='plus-small' />
          {t('Add Fallback Option')}
        </TextLink>
      )}
    </section>
  );
};

/**
 * This component handles the details of a single instruction. It always has a type, and a corresponding payload.
 */
const InstructionRow = ({
  instruction,
  onChange,
  phonetreeId,
}: {
  instruction: Instruction;
  onChange: (instruction: Instruction) => void;
  phonetreeId?: string;
}) => {
  const typeField = useFormField({ type: 'dropdown', value: instruction.type });

  const onPayloadChange = (payload: Record<string, any>) => {
    onChange({ ...instruction, payload } as Instruction);
  };

  const { flagValues } = useFeatureFlagShallowStore('flagValues');
  const hasDepartmentsFlag = flagValues['departments'];

  const getOptionLabel = (type: PhoneTreeRouteType) => {
    const matchingOption = instructionOptions.find((option) => option.value === type);
    return matchingOption ? matchingOption.label : type;
  };

  const filteredOptions = instructionOptions.filter((option) => {
    if (
      option.value === PhoneTreeRouteType.ROUTE_TYPE_EXIT ||
      option.value === PhoneTreeRouteType.ROUTE_TYPE_BACK ||
      option.value === PhoneTreeRouteType.ROUTE_TYPE_TOP
    ) {
      return false;
    }
    if (option.value === PhoneTreeRouteType.ROUTE_TYPE_CALL_ROUTE) {
      return hasDepartmentsFlag;
    }
    return true;
  });

  const mappedValue = getOptionLabel(instruction.type) || typeField.value;

  const isDisabled = [
    PhoneTreeRouteType.ROUTE_TYPE_EXIT,
    PhoneTreeRouteType.ROUTE_TYPE_BACK,
    PhoneTreeRouteType.ROUTE_TYPE_TOP,
  ].includes(typeField.value);

  let content;
  switch (instruction.type) {
    case PhoneTreeRouteType.ROUTE_TYPE_CALL_ROUTE:
      content = (
        <DepartmentInstructionField option={instruction.payload as DepartmentPayload} onChange={onPayloadChange} />
      );
      break;
    case PhoneTreeRouteType.ROUTE_TYPE_DEVICE:
      content = (
        <ForwardDeviceInstructionFields
          option={instruction.payload as ForwardDevicePayload}
          onChange={onPayloadChange}
        />
      );
      break;
    case PhoneTreeRouteType.ROUTE_TYPE_FORWARDING_NUMBER:
      content = (
        <ForwardNumberInstructionFields
          option={instruction.payload as ForwardNumberPayload}
          onChange={onPayloadChange}
        />
      );
      break;
    case PhoneTreeRouteType.ROUTE_TYPE_PLAY_MESSAGE:
      content = (
        <PlayMessageInstructionFields option={instruction.payload as PlayMessagePayload} onChange={onPayloadChange} />
      );
      break;
    case PhoneTreeRouteType.ROUTE_TYPE_REPEAT:
      content = <NavigateInstructionFields onChange={onPayloadChange} />;
      break;
    case PhoneTreeRouteType.ROUTE_TYPE_VOICEMAIL:
      content = (
        <VoicemailPromptInstructionFields
          option={instruction.payload as VoicemailPromptPayload}
          onChange={onPayloadChange}
        />
      );
      break;
    case PhoneTreeRouteType.ROUTE_TYPE_CALL_GROUP:
      content = (
        <CallGroupInstructionField option={instruction.payload as CallGroupPayload} onChange={onPayloadChange} />
      );
      break;
    case PhoneTreeRouteType.ROUTE_TYPE_CALL_QUEUE:
      content = (
        <CallQueueInstructionField option={instruction.payload as CallQueuePayload} onChange={onPayloadChange} />
      );
      break;
    case PhoneTreeRouteType.ROUTE_TYPE_IVR_MENU:
      content = (
        <SubTreeInstructionField
          option={instruction.payload as SubTreePayload}
          onChange={onPayloadChange}
          phonetreeId={phonetreeId}
        />
      );
      break;
    default:
      content = null;
  }

  return (
    <div
      css={{
        display: 'grid',
        gridTemplateColumns: '200px 1fr',
        gridTemplateRows: 'auto',
        gap: theme.spacing(2),
        maxWidth: instruction.type === PhoneTreeRouteType.ROUTE_TYPE_VOICEMAIL ? '90%' : '100%',
      }}
    >
      <DropdownField
        {...typeField}
        onChange={(e) => {
          typeField.onChange(e);
          /**
           * Make sure there's a payload object to avoid undefined errors using `?? {}`.
           *
           * The payload does not reset here, which means that field names match between incoming and outgoing instructions, those fields will
           * be populated with the previously entered values.
           */
          onChange({ ...instruction, payload: instruction.payload ?? {}, type: e.value } as Instruction);
        }}
        value={mappedValue}
        name='instruction'
        label='Routing Option'
        disabled={isDisabled}
      >
        {filteredOptions.map((option) => (
          <DropdownField.Option key={option.value} value={option.value}>
            {option.label}
          </DropdownField.Option>
        ))}
      </DropdownField>
      {content}
    </div>
  );
};

const instructionOptions = [
  { value: PhoneTreeRouteType.ROUTE_TYPE_CALL_GROUP, label: i18next.t('Call Group', { ns: 'phone' }) },
  { value: PhoneTreeRouteType.ROUTE_TYPE_CALL_QUEUE, label: i18next.t('Call Queue', { ns: 'phone' }) },
  { value: PhoneTreeRouteType.ROUTE_TYPE_CALL_ROUTE, label: i18next.t('Call Route', { ns: 'phone' }) },
  { value: PhoneTreeRouteType.ROUTE_TYPE_DEVICE, label: i18next.t('Device', { ns: 'phone' }) },
  { value: PhoneTreeRouteType.ROUTE_TYPE_FORWARDING_NUMBER, label: i18next.t('Forwarding Number', { ns: 'phone' }) },
  { value: PhoneTreeRouteType.ROUTE_TYPE_IVR_MENU, label: i18next.t('Phone Tree', { ns: 'phone' }) },
  { value: PhoneTreeRouteType.ROUTE_TYPE_PLAY_MESSAGE, label: i18next.t('Play Message', { ns: 'phone' }) },
  { value: PhoneTreeRouteType.ROUTE_TYPE_REPEAT, label: i18next.t('Repeat Prompt', { ns: 'phone' }) },
  { value: PhoneTreeRouteType.ROUTE_TYPE_VOICEMAIL, label: i18next.t('Voicemail', { ns: 'phone' }) },
  { value: PhoneTreeRouteType.ROUTE_TYPE_TOP, label: i18next.t('Top', { ns: 'phone' }) },
  { value: PhoneTreeRouteType.ROUTE_TYPE_BACK, label: i18next.t('Back', { ns: 'phone' }) },
  { value: PhoneTreeRouteType.ROUTE_TYPE_EXIT, label: i18next.t('Exit', { ns: 'phone' }) },
] as const;

const focusOption = (number: string) => {
  setTimeout(() => {
    const element = document.querySelector<HTMLElement>(`section[data-number="${number}"]`);
    element?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    element?.focus({ preventScroll: true });
  }, 500);
};
