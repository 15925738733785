import { useTranslation } from '@frontend/i18n';
import { FaxPrefixes } from '@frontend/tracking-prefixes';
import { PopoverMenu, PopoverMenuItem, UsePopoverMenuResponse } from '@frontend/design-system';
import { useFaxActions } from '../../hooks';

type Props = {
  closeParentMenu: () => void;
  previewPopoverProps?: UsePopoverMenuResponse<HTMLButtonElement, false, HTMLMenuElement, HTMLButtonElement>;
  faxId: string;
  locationId: string;
};

export const ListRowPreviewPopover = ({ closeParentMenu, previewPopoverProps, faxId, locationId }: Props) => {
  const { t } = useTranslation('fax');

  const { previewFax, previewDeliveryReport, previewFaxAndDeliveryReport } = useFaxActions();

  if (!previewPopoverProps) {
    return null;
  }

  return (
    <PopoverMenu {...previewPopoverProps.getMenuProps()}>
      <PopoverMenuItem
        onClick={() => {
          previewFax(faxId ?? '', `fax-preview`, locationId, true), closeParentMenu();
          previewPopoverProps.close();
        }}
        trackingId={`${FaxPrefixes.Thread}-preview-fax-message-button`}
      >
        {t('Fax Message')}
      </PopoverMenuItem>
      <PopoverMenuItem
        onClick={() => {
          previewDeliveryReport(faxId, locationId);
          previewPopoverProps.close();
        }}
        trackingId={`${FaxPrefixes.Thread}-preview-fax-report-button`}
      >
        {t('Fax Confirmation')}
      </PopoverMenuItem>
      <PopoverMenuItem
        onClick={() => {
          previewFaxAndDeliveryReport(faxId ?? '', `fax-preview`, locationId), closeParentMenu();
          previewPopoverProps.close();
        }}
        trackingId={`${FaxPrefixes.Thread}-preview-fax-message-and-report-button`}
      >
        {t('Fax Message & Confirmation')}
      </PopoverMenuItem>
    </PopoverMenu>
  );
};
