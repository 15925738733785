import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  border-bottom: 1px solid ${theme.colors.neutral20};
  flex: 1;
`;

export const iconStyle = css`
  margin-inline-start: auto;
  flex-shrink: 0;
`;
