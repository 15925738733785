import { FC, useState } from 'react';
import { css } from '@emotion/react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { InfoBadgeIcon, NakedButton, Text, XIcon } from '@frontend/design-system';
import { SelfUpgradeFeatureNameMap } from '../constants/self-upgrade';
import { getFeatureWiseTrackingId, SelfUpgradeLandingPageTrackingIds } from '../constants/tracking-ids';

interface FeatureMultiLocationUpgradeAlertProps {
  feature: Feature;
  onUpgradeActionClick: () => void;
}

export const FeatureMultiLocationUpgradeAlert: FC<FeatureMultiLocationUpgradeAlertProps> = ({
  feature,
  onUpgradeActionClick,
}) => {
  const [hideAlertBanner, setHideAlertBanner] = useState(false);
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'multi-location-upgrade-alert' });

  if (hideAlertBanner) {
    return null;
  }
  return (
    <section css={bannerStyles}>
      <div css={bannerContentStyles}>
        <InfoBadgeIcon />
        <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'self-start' }}>
          <Text size='large' weight='bold'>
            {t('{{feature}} is only available for some locations', {
              feature: SelfUpgradeFeatureNameMap[feature],
            })}
          </Text>
          <Text size='medium'>
            {t("Some locations you're viewing need to be upgraded to access {{feature}}.", {
              feature: SelfUpgradeFeatureNameMap[feature],
            })}
          </Text>
        </div>
      </div>
      <div css={bannerActionStyles}>
        <NakedButton
          onClick={onUpgradeActionClick}
          trackingId={getFeatureWiseTrackingId(
            feature,
            SelfUpgradeLandingPageTrackingIds.multiLocationBannerUpgradeAction
          )}
        >
          <Text color='primary' weight='bold' as='span'>
            {t('Upgrade Locations')}
          </Text>
        </NakedButton>
        <XIcon size={16} color='light' css={{ cursor: 'pointer' }} onClick={() => setHideAlertBanner(true)} />
      </div>
    </section>
  );
};

const bannerStyles = css({
  width: '100%',
  backgroundColor: theme.colors.primary5,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  borderTopLeftRadius: theme.borderRadius.medium,
  border: `1px solid ${theme.colors.neutral20}`,
  boxShadow: '0px 4px 8px 3px rgba(0, 33, 82, 0.15)',
});

const bannerContentStyles = css({
  display: 'flex',
  alignItems: 'center',
  flex: 1,
  gap: theme.spacing(2),
});

const bannerActionStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
});
