import { memo, useCallback, useRef, useState } from 'react';
import { css } from '@emotion/react';
import DOMPurify from 'dompurify';
import { Attachment } from 'stream-chat';
import { useTranslation } from '@frontend/i18n';
import { MediaUploadPreview } from '@frontend/media-upload-preview';
import { useAppScopeStore } from '@frontend/scope';
import { SuperTextarea } from '@frontend/super-textarea';
import { theme } from '@frontend/theme';
import { useAlert, useModalControl, ContentLoader } from '@frontend/design-system';
import { useImagesUpload } from '../../hooks';
import { useTeamChatStore } from '../../providers';
import { Message } from '../../types';
import { createChannel } from '../../utils';
import { ImageUploadModal } from './image-upload-modal';
import { SubmitButton } from './submit-button';

interface MessageComposerEditProps {
  closeComposer: () => void;
  message: Message;
}

interface MessageComposerNewProps {
  closeComposer?: never;
  message?: never;
}

type MessageComposerProps = MessageComposerEditProps | MessageComposerNewProps;

export const MessageComposer = memo(({ closeComposer, message }: MessageComposerProps) => {
  const { t } = useTranslation('team-chat');
  const alert = useAlert();
  const { selectedOrgId } = useAppScopeStore();
  const { activeConversation, completeNewConversation, isNewConversation, setActiveConversation, streamClient } =
    useTeamChatStore([
      'activeConversation',
      'completeNewConversation',
      'isNewConversation',
      'setActiveConversation',
      'streamClient',
    ]);
  const imageUploadModalControl = useModalControl();
  const { hideUploadingImagesEffect, images, removeImage, setImages, showUploadingImagesEffect, upload } =
    useImagesUpload();
  const [value, setValue] = useState<string>(message?.text ?? '');
  const [isUploadingImages, setIsUploadingImages] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const isEditing = !!message;
  const attachmentsRef = useRef<Attachment[]>([]);

  const afterImageUploadEffects = () => {
    setIsUploadingImages(false);
    hideUploadingImagesEffect();
  };

  const handleSubmit = useCallback(
    async (value: string) => {
      if (!streamClient || !activeConversation) {
        return;
      }

      const hasImages = !!images.length;
      let channelId = activeConversation.channelId;

      try {
        /* 
            We are checking isNewConversation because we need to create a new channel if it is a new conversation
            But we are also checking channelId.length because if it is a new conversation and channelId is already set
            then it means we have already set a conversation and we don't need to create a new one.
            When we select a user ewe make an API call to check if there exist a channel with the selected users.
            If yes then we display the conversation otherwise we create a new conversation.
        */
        if (isNewConversation && !channelId.length) {
          setIsLoading(true);
          const newConversation = await createChannel({
            streamClient,
            orgId: selectedOrgId,
            members: activeConversation.members,
            name: activeConversation.name,
            isDm: !activeConversation.name.length,
          });
          setActiveConversation(newConversation);
          channelId = newConversation.channelId;
        }

        const channel = streamClient.getChannelById('team', channelId, {});

        // For a failed send message case, we won't upload images again if they are already uploaded
        // They will be directly read from the ref and sent to the receiver
        // The ref is cleared when the message is sent successfully
        if (hasImages && attachmentsRef.current.length !== images.length) {
          setIsUploadingImages(true);
          showUploadingImagesEffect();

          // Using reduce to minimize loops otherwise we will need to use filter and map together
          const nonEmptyFiles = images.reduce<File[]>((acc, image) => {
            if (image.file) {
              acc.push(image.file);
            }
            return acc;
          }, []);

          const uploadedAttachments = await upload(nonEmptyFiles, channel);
          attachmentsRef.current.push(...uploadedAttachments);
        }

        if (isEditing) {
          // While editing a message, make sure to include any previous attachments if they exist
          if (message.attachments?.length) {
            message.attachments.forEach((attachment) => {
              attachmentsRef.current.push({
                asset_url: attachment,
                image_url: attachment,
                thumb_url: attachment,
                type: 'image',
              });
            });
          }

          await streamClient.updateMessage({
            attachments: attachmentsRef.current,
            id: message.id,
            text: DOMPurify.sanitize(value),
          });
        } else {
          await channel.sendMessage({
            attachments: attachmentsRef.current,
            text: DOMPurify.sanitize(value),
          });
        }

        if (hasImages) {
          afterImageUploadEffects();
          setImages([]);
          attachmentsRef.current = [];
        }

        if (isNewConversation) {
          completeNewConversation();
        }

        setIsLoading(false);
        closeComposer?.();
      } catch (error) {
        if (isNewConversation) {
          alert.error(t('Failed to start a new conversation'));
        } else if (isEditing) {
          alert.error(t('Failed to edit message'));
        } else {
          alert.error(t('Failed to send message'));
        }

        setIsLoading(false);
        if (hasImages) {
          afterImageUploadEffects();
        }
        setValue(value);
      }
    },
    [activeConversation, images, isEditing, isNewConversation, streamClient]
  );

  const conditionalSuperTextAreaProps = isEditing
    ? {
        isEdit: true,
        onCancel: closeComposer,
      }
    : {
        SubmitComponent: SubmitButton,
      };

  let placeholder = t("Type here, if you'd like....");
  if (activeConversation?.name && activeConversation.type === 'DM') {
    placeholder = t('Message {{name}}', { name: activeConversation.name });
  } else if (activeConversation?.name && activeConversation.type === 'Group') {
    placeholder = t('Message #{{name}}', { name: activeConversation.name });
  }

  return (
    <div className={isEditing ? 'edit-message' : 'new-message'} css={styles.wrapper}>
      {!!images.length && (
        <MediaUploadPreview
          media={images}
          removeImageTrackingId='team-chat-v2-remove-image-upload'
          removeMediaItem={removeImage}
          inPopout
        />
      )}

      <SuperTextarea
        {...conditionalSuperTextAreaProps}
        disableSend={(isNewConversation && !activeConversation?.members.length) || isUploadingImages}
        hasSubmit
        minRows={3}
        onChange={setValue}
        onImageClick={imageUploadModalControl.openModal}
        onSubmit={handleSubmit}
        placeholder={placeholder}
        sendOnEmpty={!!images.length}
        trackingIds={{
          emoji: 'team-chat-v2-emoji-button-click',
          image: 'team-chat-v2-image-button-click',
        }}
        value={value}
        css={styles.textAreaStyle}
      />

      <ImageUploadModal modalProps={imageUploadModalControl.modalProps} onImageUpload={setImages} />
      <ContentLoader show={isLoading} message={t('Starting a new conversation')} />
    </div>
  );
});

MessageComposer.displayName = 'MessageComposer';

const styles = {
  wrapper: css`
    > ul {
      height: 100px;
    }

    &.edit-message {
      form {
        border: 1px solid ${theme.colors.warning50};
        border-radius: ${theme.borderRadius.medium};
        background-color: ${theme.colors.white};

        > div:last-of-type {
          background-color: ${theme.colors.white};
        }
      }
    }
  `,
  textAreaStyle: css({
    borderColor: theme.colors.neutral10,
  }),
};
