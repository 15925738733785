import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import {
  Batch,
  BatchStatus,
  GetMessagesRequest_Category,
  MonthlyBatches,
} from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/batch/v1/batch.pb';
import { BulkMessagesQueries } from '@frontend/api-messaging';
import { getUser } from '@frontend/auth-helpers';
import { Chips } from '@frontend/chips';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { useFeatureFlagStore } from '@frontend/shared';
import { BulkPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  BlockIcon,
  Button,
  CalendarConfirmedIcon,
  CheckIcon,
  CopyIcon,
  DeleteIcon,
  EditIcon,
  EditSimpleIcon,
  FamilyIcon,
  Info,
  PendingIcon,
  PreviewIcon,
  SpinningLoader,
  Table,
  TableColumnConfig,
  Text,
  useModalControl,
  useAlert,
} from '@frontend/design-system';
import { MessagesFlags } from '../..';
import { useBulkNavigation } from '../../hooks/use-bulk-navigation';
import { getDirectionStorageKey } from '../../utils';
import { TooltipWrapper } from '../tooltip-wrapper';
import { BulkMessageDeleteModal } from './bulk-message-delete-modal';
import { BulkMessageGraph } from './bulk-message-graph';
import { BulkMessageButtonBar, BulkMessageModal, PersonModal } from './bulk-message-modal';

type ContainerSize = 'small' | 'large';

type BulkMessageTableProps = {
  monthlyBatches: MonthlyBatches;
  graphSize: ContainerSize;
  isCanceled: boolean;
  year: number;
  isApp: boolean;
};

export const BulkMessageTable = ({
  monthlyBatches,
  graphSize,
  isCanceled,
  year,
  isApp,
  ...rest
}: BulkMessageTableProps) => {
  const { t } = useTranslation('messages');
  const navigate = useNavigate();
  const alerts = useAlert();
  const { getFlag } = useFeatureFlagStore();
  const bulkMessageFlagEnabled = getFlag(MessagesFlags.emailMarketingV2);
  const [batchToCancel, setBatchToCancel] = useState<Batch>();
  const [batchToDelete, setBatchToDelete] = useState<Batch>();
  const { modalProps: cancelModalProps, openModal: openCancelModal } = useModalControl();
  const { modalProps: deleteModalProps, openModal: openDeleteModal } = useModalControl();
  const { modalProps: recipientsModalProps, triggerProps: recipientsTriggerProps } = useModalControl();
  const cancelBatch = BulkMessagesQueries.useCancelBatch();
  const deleteBatch = BulkMessagesQueries.useDeleteBatch();
  const { isLoading, expandBatch } = BulkMessagesQueries.useExpandGetBatches({
    onError: () => {
      alerts.error(t('An error occurred while loading your bulk messages. Please try again later.'));
    },
  });
  const [selectedBatchId, setSelectedBatchId] = useState<string>('');
  const [selectedType, setSelectedType] = useState<GetMessagesRequest_Category>(
    GetMessagesRequest_Category.CATEGORY_ALL
  );
  const [modalTitle, setModalTitle] = useState<string>('');
  const usage = monthlyBatches.usage;
  const direction = getDirectionStorageKey();

  useEffect(() => {
    let title = t('Recipients');
    if (selectedType === GetMessagesRequest_Category.CATEGORY_UNSUBSCRIBED) {
      title = t('Unsubscribers');
    }
    setModalTitle(title);
  }, [selectedType]);

  const { accessibleLocationData } = useAppScopeStore();

  const { data } = BulkMessagesQueries.useGetPeople(selectedBatchId, selectedType);
  const recipients = data?.messages ?? [];

  const showCancelDialog = (batch: Batch) => {
    setBatchToCancel(batch);
    openCancelModal();
  };

  const closeCancelDialog = () => {
    setBatchToCancel(undefined);
    cancelModalProps.onClose();
  };

  const handleCancelBatch = () => {
    if (!batchToCancel || !batchToCancel.id) return;
    const user = getUser();
    cancelBatch(batchToCancel.id, user?.userID ?? '', direction);
    setBatchToCancel(undefined);
    cancelModalProps.onClose();
  };

  const showDeleteDialog = (batch: Batch) => {
    setBatchToDelete(batch);
    openDeleteModal();
  };

  const closeDeleteDialog = () => {
    setBatchToDelete(undefined);
    deleteModalProps.onClose();
  };

  const handleDeleteBatch = () => {
    if (!batchToDelete || !batchToDelete.id) return;
    const user = getUser();
    deleteBatch(batchToDelete.id, user?.userID ?? '', direction);
    setBatchToDelete(undefined);
    deleteModalProps.onClose();
  };

  const triggerRecipientModal = (id = '', type: GetMessagesRequest_Category) => {
    recipientsTriggerProps.onClick();
    setSelectedBatchId(id);
    setSelectedType(type);
  };

  const onExpand = () => {
    const month = monthlyBatches.month;
    if (!month) return;
    expandBatch(year, month, direction);
  };

  return (
    <article
      css={css`
        display: flex;
        flex-direction: column;
        margin-bottom: ${theme.spacing(2)};
      `}
      {...rest}
    >
      <BulkMessageGraph
        month={monthlyBatches.month}
        sent={(usage?.sent ?? 0) + (usage?.failed ?? 0)}
        pending={usage?.pending}
        quota={usage?.quota}
        size={graphSize}
        year={year}
      />
      {(monthlyBatches.batches?.length ?? 0) > 0 && (
        <Table<Batch>
          data={monthlyBatches.batches ?? []}
          collapsedTableConfig={
            (monthlyBatches.batches?.length ?? 0) > 4
              ? {
                  rowsToShow: 4,
                  collapseLabel: t('Show Less...'),
                  expandLabel: t('Show More...'),
                  startingValue: false,
                  onExpand,
                }
              : undefined
          }
          colConfig={(
            [
              {
                Header: t('Date'),
                id: 'startSendAt',
                width: 90,
                disableSortBy: true,
                accessor: (batch) => formatDate(batch.startSendAt, 'Do'),
              },
              {
                Header: t('Name'),
                id: 'title',
                width: 250,
                disableSortBy: true,
                accessor: (batch) => batch.title ?? t('Untitled Bulk Message'),
                cellConfig: {
                  element: 'TextLink',
                  onClick: (_, batch) => {
                    navigate({ to: useBulkNavigation({ isApp, id: batch.id }).edit });
                  },
                  trackingId: `${BulkPrefixes.Page}-message-table-name-link`,
                },
              },
              {
                Header: t('Status'),
                id: 'status',
                width: 100,
                disableSortBy: true,
                accessor: (batch) => batch.status,
                cellRenderer: (status: BatchStatus) => {
                  switch (status) {
                    case BatchStatus.SCHEDULED:
                      return (
                        <TooltipWrapper label={t('Scheduled')} testId='status-icon-scheduled'>
                          <CalendarConfirmedIcon css={statusIconStyle} />
                        </TooltipWrapper>
                      );
                    case BatchStatus.PROCESSING:
                      return (
                        <TooltipWrapper label={t('Processing')} testId='status-icon-processing'>
                          <PendingIcon css={statusIconStyle} />
                        </TooltipWrapper>
                      );
                    case BatchStatus.COMPLETED:
                      return (
                        <TooltipWrapper label={t('Completed')} testId='status-icon-completed'>
                          <CheckIcon css={statusIconStyle} />
                        </TooltipWrapper>
                      );
                    case BatchStatus.DRAFT:
                      return (
                        <TooltipWrapper label={t('Draft')} testId='status-icon-draft'>
                          <EditIcon css={statusIconStyle} />
                        </TooltipWrapper>
                      );
                    default:
                      return (
                        <TooltipWrapper label={t('Canceled')} testId='status-icon-canceled'>
                          <BlockIcon css={statusIconStyle} />
                        </TooltipWrapper>
                      );
                  }
                },
              },
              {
                Header: t('Total Messages'),
                id: 'segments',
                disableSortBy: true,
                width: 160,
                accessor: (batch) => <CellValue value={batch.segments} />,
              },
              {
                Header: t('Recipients'),
                id: 'recipients',
                disableSortBy: true,
                width: 120,
                accessor: (batch) => (
                  <CellValue
                    value={batch.recipients}
                    onClick={() => triggerRecipientModal(batch.id, GetMessagesRequest_Category.CATEGORY_ALL)}
                    trackingId={`${BulkPrefixes.Page}-message-table-recipient-link`}
                  />
                ),
              },
              {
                Header: t('Delivered To'),
                id: 'delivered',
                disableSortBy: true,
                width: 140,
                accessor: (batch) => (
                  <CellValue
                    value={batch.delivered}
                    status={batch.status}
                    onClick={() => triggerRecipientModal(batch.id, GetMessagesRequest_Category.CATEGORY_DELIVERED)}
                    trackingId={`${BulkPrefixes.Page}-message-table-delivered-to-link`}
                  />
                ),
              },
              {
                Header: t('Failed'),
                id: 'failed',
                disableSortBy: true,
                width: 110,
                accessor: (batch) => (
                  <CellValue
                    value={batch.failed}
                    status={batch.status}
                    onClick={() => triggerRecipientModal(batch.id, GetMessagesRequest_Category.CATEGORY_FAILED)}
                    trackingId={`${BulkPrefixes.Page}-message-table-failed-link`}
                  />
                ),
              },
              {
                Header: (
                  <span
                    css={css`
                      display: inline-flex;
                      align-items: center;
                    `}
                  >
                    {t('Engagement')}
                    <Info
                      css={css`
                        color: ${theme.colors.neutral30};
                        margin-left: ${theme.spacing(1)};
                      `}
                      data-trackingid={`${BulkPrefixes.Page}-message-table-engagement-tooltip`}
                    >
                      {t('Replies / Unsubscribes')}
                    </Info>
                  </span>
                ),
                id: 'engaged',
                disableSortBy: true,
                width: 140,
                accessor: (batch) =>
                  batch.status === BatchStatus.COMPLETED || batch.status === BatchStatus.PROCESSING ? (
                    <Text
                      css={css`
                        display: flex;
                        align-items: baseline;
                        margin: 0;
                        font-size: ${theme.fontSize(14)};
                      `}
                    >
                      <CellValue
                        value={batch.engaged}
                        onClick={() => triggerRecipientModal(batch.id, GetMessagesRequest_Category.CATEGORY_ENGAGED)}
                        trackingId={`${BulkPrefixes.Page}-message-table-engaged-link`}
                      />
                      <span
                        css={css`
                          margin: ${theme.spacing(0, 0.25)};
                        `}
                      >
                        /
                      </span>
                      <CellValue
                        value={batch.unsubscribed}
                        onClick={() =>
                          triggerRecipientModal(batch.id, GetMessagesRequest_Category.CATEGORY_UNSUBSCRIBED)
                        }
                        trackingId={`${BulkPrefixes.Page}-message-table-unsubscribed-link`}
                      />
                    </Text>
                  ) : (
                    <CellValue status={batch.status} />
                  ),
              },
              bulkMessageFlagEnabled
                ? {
                    Header: t('Locations'),
                    id: 'replies',
                    disableSortBy: true,
                    width: 140,
                    // TODO: need to make this multi-location ready (i.e. currently there's only the one location id). Logic below will need to handle multiple locations (reference the AutoMessageTable)
                    accessor: (batch) => <CellValue status={batch.status} />,
                    cellRenderer: (_cellVal, batch) =>
                      batch?.locationId && accessibleLocationData[batch.locationId]?.name ? (
                        <Chips.LocationChip
                          css={css`
                            fontsize: ${theme.fontSize(12)};
                          `}
                        >
                          {accessibleLocationData[batch.locationId].name}
                        </Chips.LocationChip>
                      ) : null,
                  }
                : undefined,
              // TODO: this is only until we have moved it officially out of the portal so we wouldn't need the conditional rendering of `isApp` specific columns
              // Because of the undefined this type casting is needed so that we don't have to explicitly type each "batch" in the accessor function
            ] as TableColumnConfig<Batch>[]
          ).filter(Boolean)}
          rowActions={{
            rowActionMenuLabel: '',
            actions: [
              {
                hide: (batch) =>
                  !(batch.id && batch.status !== BatchStatus.SCHEDULED && batch.status !== BatchStatus.DRAFT),
                label: t('View Message'),
                Icon: PreviewIcon,
                onClick: (batch) => navigate({ to: useBulkNavigation({ isApp, id: batch.id }).edit }),
                trackingId: `${BulkPrefixes.Page}-table-view`,
              },
              {
                hide: (batch) =>
                  !(batch.id && (batch.status === BatchStatus.SCHEDULED || batch.status === BatchStatus.DRAFT)),
                label: t('Edit Message'),
                Icon: EditSimpleIcon,
                onClick: (batch) => navigate({ to: useBulkNavigation({ isApp, id: batch.id }).edit }),
                trackingId: `${BulkPrefixes.Page}-table-edit`,
              },
              {
                hide: (batch) => !batch.id || isCanceled,
                label: t('Duplicate Message'),
                Icon: CopyIcon,
                onClick: (batch) =>
                  navigate({ to: useBulkNavigation({ isApp, id: batch.id }).new, search: { seedId: batch.id } }),
                trackingId: `${BulkPrefixes.Page}-table-duplicate`,
              },
              {
                hide: (batch) => !batch.id || isCanceled,
                label: t('Duplicate with Recipients'),
                Icon: FamilyIcon,
                onClick: (batch) =>
                  navigate({
                    to: useBulkNavigation({ isApp, id: batch.id }).new,
                    search: { seedId: batch.id, copyRecipients: true },
                  }),
                trackingId: `${BulkPrefixes.Page}-table-duplicate-with-recipients`,
              },
              {
                hide: ({ status }) =>
                  [BatchStatus.CANCELED, BatchStatus.COMPLETED, BatchStatus.DELETED].includes(status!) || isCanceled,
                label: t('Cancel Message'),
                Icon: BlockIcon,
                onClick: (batch) => showCancelDialog(batch),
                trackingId: `${BulkPrefixes.Page}-table-cancel`,
              },
              {
                hide: (batch) => !batch.id,
                label: t('Delete Message'),
                Icon: DeleteIcon,
                onClick: (batch) => showDeleteDialog(batch),
                trackingId: `${BulkPrefixes.Page}-table-delete`,
              },
            ],
          }}
        />
      )}
      {isLoading && (
        <div
          css={css`
            text-align: right;
            margin-top: ${theme.spacing(-5.5)};
            margin-right: ${theme.spacing(16)};
          `}
        >
          <SpinningLoader size='xs' />
        </div>
      )}
      <BulkMessageModal {...cancelModalProps} onClose={closeCancelDialog} title={t('Cancel Bulk Message')}>
        <Text
          css={css`
            text-align: left;
          `}
        >
          {t('Are you sure you want to cancel {{bulkMessageName}}? This action cannot be undone.', {
            bulkMessageName: batchToCancel?.title || t('this bulk message'),
          })}
        </Text>
        <BulkMessageModal.ButtonContainer>
          <BulkMessageButtonBar
            onAction={handleCancelBatch}
            onCancel={closeCancelDialog}
            cancelText={t('Cancel')}
            actionText={t('Cancel Message')}
          />
        </BulkMessageModal.ButtonContainer>
      </BulkMessageModal>

      <BulkMessageDeleteModal
        campaign={batchToDelete}
        modalProps={deleteModalProps}
        onCancel={closeDeleteDialog}
        onDelete={handleDeleteBatch}
      />

      <PersonModal {...recipientsModalProps} recipients={recipients} title={modalTitle} />
    </article>
  );
};

const statusIconStyle = css`
  color: ${theme.colors.neutral60};
`;

type CellValueProps = {
  value?: number;
  status?: BatchStatus;
  onClick?: () => void;
  trackingId?: string;
};

const CellValue = ({ value = 0, status, onClick, trackingId }: CellValueProps) => {
  const showValue = status === undefined || status === BatchStatus.COMPLETED || status === BatchStatus.PROCESSING;

  if (showValue && value > 0 && onClick) {
    return (
      <Button variant='tertiary' css={numberTextStyle} onClick={onClick} trackingId={trackingId}>
        {value}
      </Button>
    );
  }

  return (
    <Text as='span' css={numberTextStyle}>
      {showValue ? value : <>&mdash;</>}
    </Text>
  );
};

const numberTextStyle = {
  fontSize: theme.fontSize(14),
  padding: theme.spacing(0.5, 1),
  color: theme.colors.neutral90,
  fontWeight: theme.font.weight.regular,
};
