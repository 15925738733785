import { useQuery } from 'react-query';
import { FormsQueryKeys } from '@frontend/api-forms';
import { PersonAPI } from '@frontend/api-person';
import { ContactNumberType } from '../types';

interface ContactNumber {
  type: ContactNumberType;
  value: string;
}

export const useContactDetails = (personId: string, locationId: string) => {
  const { data: selectedContact, isLoading: isLoadingContact } = useQuery({
    queryKey: [FormsQueryKeys.sendForms.contact, personId, locationId],
    queryFn: () => PersonAPI.getPersonExtended(personId, locationId),
    enabled: !!personId,
  });

  const getContactNumbers = (): ContactNumber[] => {
    if (!selectedContact) {
      return [];
    }

    const contactNumbers: ContactNumber[] = [];

    if (selectedContact.MobilePhone) {
      contactNumbers.push({
        type: ContactNumberType.MOBILE,
        value: selectedContact.MobilePhone,
      });
    }

    if (selectedContact.HomePhone) {
      contactNumbers.push({
        type: ContactNumberType.HOME,
        value: selectedContact.HomePhone,
      });
    }

    if (selectedContact.WorkPhone) {
      contactNumbers.push({
        type: ContactNumberType.WORK,
        value: selectedContact.WorkPhone,
      });
    }

    return contactNumbers;
  };

  return {
    selectedContact,
    isLoadingContact,
    getContactNumbers,
  };
};
