import { useEffect, useState } from 'react';
import { ScheduleAvailabilityTypes } from '@frontend/api-schedule-availability';
import { i18next } from '@frontend/i18n';
import { ScheduleAvailabilityHours } from '../components/ScheduleAvailabilityHours';
import { daysOfWeek } from '../constants';
import { DayOfWeek, ScheduleAvailabilityByDayOfWeek } from '../types';

type UseScheduleAvailabilityHoursTypes = {
  initialScheduleAvailability: ScheduleAvailabilityByDayOfWeek | ScheduleAvailabilityTypes.Schedule;
  isLoading?: boolean;
  timeRangeJoiningText?: string;
  closedOfficeText?: string;
  daysOfTheWeek?: DayOfWeek[];
};

const defaultClosedOfficeText = i18next.t('Close', {
  ns: 'schedule',
});

export const useScheduleAvailabilityHours = ({
  initialScheduleAvailability,
  isLoading,
  closedOfficeText = defaultClosedOfficeText,
  daysOfTheWeek = daysOfWeek,
  timeRangeJoiningText = '-',
}: UseScheduleAvailabilityHoursTypes) => {
  const [schedule, setSchedule] = useState<ScheduleAvailabilityByDayOfWeek | null>(initialScheduleAvailability);
  const [isError, setIsError] = useState(false);

  // Set initial schedule availability
  useEffect(() => {
    const hasInitialScheduleAvailability = Object.keys(initialScheduleAvailability).length > 0;
    if (hasInitialScheduleAvailability) {
      setSchedule(initialScheduleAvailability);
    }
  }, [initialScheduleAvailability]);

  const resetScheduleAvailability = () => {
    setSchedule(initialScheduleAvailability);
  };

  return {
    updatedScheduleAvailability: schedule || {},
    isError,
    resetScheduleAvailability,
    ScheduleAvailabilityHours: (
      <ScheduleAvailabilityHours
        isLoading={isLoading}
        updateScheduleAvailabilityOfWeek={setSchedule}
        scheduleAvailabilityOfWeek={schedule || {}}
        closedOfficeText={closedOfficeText}
        daysOfTheWeek={daysOfTheWeek}
        setIsError={setIsError}
        timeRangeJoiningText={timeRangeJoiningText}
      />
    ),
  };
};
