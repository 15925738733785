import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import dayjs from 'dayjs';
import { ServiceProvidersApi } from '@frontend/api-service-providers';
import { EmptyStates } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  Accordion,
  ButtonBar,
  Checkbox,
  ChecklistField,
  Text,
  DatePickerField,
  Heading,
  IconButton,
  PrimaryButton,
  SecondaryButton,
  useControlledField,
  useFormField,
  NakedUl,
  ContentLoader,
  Tray,
} from '@frontend/design-system';
import { queryKeys } from '../../../../../../query-keys';
import { useScheduleActionsContainerMethod } from '../../../../../context/ScheduleActionsContainerContext';
import { useScheduleMassMessageMethod } from '../../../../../context/ScheduleMassMessageContext';
import {
  useGetCalendarEventsV3Data,
  useGetSchedulerV3FeatureFlagDetails,
  useGetV3CalendarStartAndEndDateTime,
} from '../../../../../hooks';
import { useScheduleActionsAppointmentListInfoShallowStore } from '../../../../../stores/use-schedule-actions-appointment-list-store';
import { convertV3CalendarEventToAppointmentDetails } from '../../../../CalendarV3/helpers';
import { PatientListRow } from '../../../components/PatientListRow';
import { ScheduleMassMessageStepEnum } from '../../../types';
import { FiltersContainer } from './Filters/FilterContainer';
import { SendScheduledMessageToPatients } from './SendScheduledMessageToPatients';
import { ScheduleMassMessageTrackingIds } from './trackingIds';
import { getUniqueListByKey } from './utils';

const { listAppointments } = ServiceProvidersApi;

export const SchedulePatientsToContact = () => {
  const { selectedLocationIds, selectedParentsIds } = useAppScopeStore();
  const { t } = useTranslation('scheduleCalendarActions');

  const { isScheduleV3FlagEnabled } = useGetSchedulerV3FeatureFlagDetails();

  const { setHideTabs, showTrayHeader, selectedDate, selectedLocationId } = useScheduleActionsContainerMethod();

  const { setCurrStep, currStep, onClose } = useScheduleMassMessageMethod();

  const [selectedAppointmentIds, setSelectedAppointmentIds] = useState<string[]>([]);
  const {
    setStatusFilter: filteredStatusView,
    appointmentList,
    setAppointmentList,
    setDefaultAppointmentList,
    defaultAppointmentList,
    providerList,
  } = useScheduleActionsAppointmentListInfoShallowStore(
    'setStatusFilter',
    'appointmentList',
    'setAppointmentList',
    'setDefaultAppointmentList',
    'defaultAppointmentList',
    'providerList'
  );

  const todayDate = dayjs().format('MM/DD/YYYY').toString();

  const calendarProps = useFormField({
    type: 'datePicker',
    value: selectedDate || (todayDate as string),
  });

  const isValidDate = calendarProps?.value && !calendarProps.error;

  const [startDate, endDate] = useMemo(() => {
    if (isValidDate) {
      const startDateInUTC = dayjs(String(calendarProps.value)).utc().toISOString();
      const endDateInUTC = dayjs(String(calendarProps.value)).add(1, 'day').utc().toISOString();
      return [startDateInUTC, endDateInUTC];
    }
    return ['', ''];
  }, [calendarProps?.value, isValidDate]);

  const {
    data: appointments,
    refetch: refetchAppointments,
    isFetching,
  } = useScopedQuery({
    queryKey: queryKeys.appointments(
      !!selectedLocationId ? [selectedLocationId] : selectedLocationIds,
      startDate,
      endDate
    ),
    queryFn: () =>
      listAppointments({
        between: { start: startDate, end: endDate },
        equals: { locationIds: !!selectedLocationId ? [selectedLocationId] : selectedLocationIds },
      }),
    enabled: false,
  });

  const { startDateTime: calendarEventsStartDate, endDateTime: calendarEventsEndDate } =
    useGetV3CalendarStartAndEndDateTime(dayjs(String(calendarProps.value)).toString());

  const {
    data: calendarEventsData,
    isLoading: isLoadingCalendarEvents,
    refetch: refetchCalendarEvents,
  } = useGetCalendarEventsV3Data({
    parentLocationId: selectedParentsIds[0],
    isMultiLocation: !!selectedLocationIds.length,
    calendarEventsParams: {
      startDateTime: calendarEventsStartDate,
      endDateTime: calendarEventsEndDate,
      page: 1,
      limit: 50,
    },
    selectedLocationIds,
    selectedLocationId: selectedLocationIds[0],
    enabled: isScheduleV3FlagEnabled,
  });

  // Convert calendarEvents to appointments format to display in appointments list and store in ScheduleActionsAppointmentListInfo store
  useEffect(() => {
    const isScheduleV3 = !!calendarEventsData?.events?.length && isScheduleV3FlagEnabled;
    const appointmentsList = isScheduleV3
      ? convertV3CalendarEventToAppointmentDetails(calendarEventsData?.events)
      : appointments?.appointments;

    !filteredStatusView && setAppointmentList(appointmentsList ?? []);
    setDefaultAppointmentList(appointmentsList ?? []);
  }, [appointments?.appointments, calendarEventsData?.events, isScheduleV3FlagEnabled]);

  useEffect(() => {
    !filteredStatusView && setAppointmentList(appointments?.appointments ?? []);
    setDefaultAppointmentList(appointments?.appointments ?? []);
  }, [appointments?.appointments]);

  useEffect(() => {
    if (isValidDate) {
      if (isScheduleV3FlagEnabled) {
        refetchCalendarEvents();
      } else {
        refetchAppointments();
      }
    }
  }, [isValidDate, isScheduleV3FlagEnabled]);

  const filteredAppointments = useMemo(() => {
    const list = !!appointmentList.length || !!providerList.length ? appointmentList : defaultAppointmentList;
    return list?.reduce(
      (acc, obj) => {
        let appointmentStatus = obj.statusOfficeView?.toLowerCase();

        if (appointmentStatus === 'unknown') {
          appointmentStatus = 'unconfirmed';
        }

        const isTextOptedIn = obj.person?.personId && obj.person?.textOptedIn && obj?.person?.mobilePhone;
        if (isTextOptedIn) {
          acc.textOptedIn?.push(obj);
        }
        if (!obj.person?.textOptedIn && obj.person?.personId && obj?.person?.mobilePhone) {
          acc.textOptedOut?.push(obj);
        }
        return acc;
      },
      { textOptedIn: [] as Appointment[], textOptedOut: [] as Appointment[] }
    );
  }, [filteredStatusView, appointmentList, defaultAppointmentList]);

  const startingValue =
    filteredAppointments?.textOptedIn?.length ?? 0 > 0
      ? 'message-list'
      : filteredAppointments?.textOptedOut?.length ?? 0 > 0
      ? 'phone-call-list'
      : 'first';

  const checklistFieldProps = useFormField(
    {
      type: 'checklist',
      value: selectedAppointmentIds,
    },
    [selectedAppointmentIds]
  );

  const [filteredTextOptInAppointmentList, filteredTextOptOutAppointmentList] = useMemo(() => {
    return [
      getUniqueListByKey<Appointment>(filteredAppointments?.textOptedIn ?? [], 'id'),
      getUniqueListByKey<Appointment>(filteredAppointments?.textOptedOut ?? [], 'id'),
    ];
  }, [filteredAppointments?.textOptedIn, filteredAppointments?.textOptedOut]);

  const selectAllProps = useControlledField({
    type: 'checkbox',
    value: checklistFieldProps.value.length === filteredTextOptInAppointmentList?.length,
    onChange: () => {
      if (checklistFieldProps.value.length === filteredTextOptInAppointmentList?.length) {
        setSelectedAppointmentIds([]);
      } else {
        setSelectedAppointmentIds(filteredTextOptInAppointmentList?.map((appt) => appt.id ?? '') ?? []);
      }
    },
  });

  const selectedAppointments = useMemo(() => {
    return (
      checklistFieldProps.value?.map((apptId) => {
        return filteredTextOptInAppointmentList?.find((appt) => appt.id === apptId) ?? {};
      }) ?? []
    );
  }, [checklistFieldProps.value, filteredTextOptInAppointmentList]);

  if (currStep === ScheduleMassMessageStepEnum.SEND_MASS_MESSAGE && isValidDate) {
    return <SendScheduledMessageToPatients selectedAppointments={selectedAppointments} date={calendarProps.value} />;
  }

  return (
    <>
      <ContentLoader show={isFetching || isLoadingCalendarEvents} />
      <div
        css={css`
          flex: 1;
        `}
      >
        <div
          css={{
            backgroundColor: theme.colors.white,
            marginBottom: theme.spacing(2),
            padding: showTrayHeader ? theme.spacing(3) : theme.spacing(0, 3),
          }}
        >
          {showTrayHeader ? (
            <Tray.Header
              css={{ marginBottom: theme.spacing(1) }}
              Buttons={
                <IconButton
                  trackingId={ScheduleMassMessageTrackingIds.closeModalBtn}
                  onClick={onClose}
                  label={t('Close')}
                >
                  <Icon name='x' />
                </IconButton>
              }
            >
              {t('Select Patients to Contact')}
            </Tray.Header>
          ) : (
            <Heading level={2}>{t('Select Patients to Contact')}</Heading>
          )}
          <div css={{ display: 'flex', gap: theme.spacing(2), alignItems: 'flex-start' }}>
            <DatePickerField
              data-trackingid={ScheduleMassMessageTrackingIds.datePickerField}
              {...calendarProps}
              css={{ width: '190px' }}
              name=''
              label={''}
            />
            <FiltersContainer />
          </div>
        </div>
        <div css={{ background: theme.colors.neutral5, height: '100%' }}>
          {!isFetching && (
            <Accordion
              distance={3}
              css={{
                background: theme.colors.neutral5,
                padding: theme.spacing(2, 4, 3, 4),
                overflow: 'auto',
                maxHeight: '570px',
                minHeight: '560px',
              }}
              showBoxShadow
              variant='location'
              chevronSize={16}
              size='small'
              startingValue={startingValue}
            >
              <Accordion.Item
                trackingId={ScheduleMassMessageTrackingIds.messageListAccordion}
                key='message-list'
                value='message-list'
              >
                <Accordion.Header disabled={!filteredTextOptInAppointmentList?.length}>
                  <Icon name='messaging' />
                  <Text css={{ marginLeft: theme.spacing(1) }}>{t('Message List')}</Text>
                  <Text css={{ marginLeft: 'auto' }}>{filteredTextOptInAppointmentList?.length ?? 0}</Text>
                </Accordion.Header>

                <Accordion.Body css={{ padding: 0 }}>
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: theme.spacing(1),
                      padding: theme.spacing(1, 2),
                      margin: theme.spacing(0, 1),
                    }}
                  >
                    <Checkbox
                      data-trackingid={ScheduleMassMessageTrackingIds.selectAllMessageListCheckbox}
                      name='select-all-rows'
                      {...selectAllProps}
                      error={false}
                    ></Checkbox>
                    <Heading level={3}>
                      {t('{{count}} Patients', { count: filteredTextOptInAppointmentList?.length })}
                    </Heading>
                  </div>
                  <hr />
                  <section css={{ padding: theme.spacing(0, 2) }}>
                    {!!filteredTextOptInAppointmentList?.length ? (
                      <NakedUl
                        css={css`
                          overflow: auto;
                          max-height: 400px;
                        `}
                      >
                        <ChecklistField {...checklistFieldProps} name='message-list-checklist' label=''>
                          {filteredTextOptInAppointmentList?.map((appt) => {
                            return (
                              <ChecklistField.Option
                                key={appt.id ?? ''}
                                name={appt.id ?? ''}
                                css={css`
                                  align-items: center;
                                  margin: 0 8px;
                                  & > label {
                                    width: 100%;
                                  }
                                `}
                              >
                                <PatientListRow key={appt.id} appt={appt} locationId={appt?.locationId || ''} />
                              </ChecklistField.Option>
                            );
                          })}
                        </ChecklistField>
                      </NakedUl>
                    ) : (
                      <EmptyStates
                        emptyStateConfig={{ type: 'schedule', header: '', description: t('No Message List') }}
                      />
                    )}
                  </section>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item
                trackingId={ScheduleMassMessageTrackingIds.phoneListAccordion}
                key='phone-call-list'
                value='phone-call-list'
              >
                <Accordion.Header disabled={!filteredTextOptOutAppointmentList?.length}>
                  <Icon name='phone-outgoing' />
                  <Text css={{ marginLeft: theme.spacing(1) }}>{t('Phone Call List')}</Text>
                  <Text css={{ marginLeft: 'auto' }}>{filteredTextOptOutAppointmentList?.length ?? 0}</Text>
                </Accordion.Header>

                <Accordion.Body css={{ padding: 0 }}>
                  <Text css={{ padding: theme.spacing(1, 2) }} color='light' size='medium'>
                    {t(
                      'These patients have either opted out of receiving text messages or do not have a textable number saved, and must be called'
                    )}
                  </Text>
                  <hr />
                  {!!filteredTextOptOutAppointmentList ? (
                    <NakedUl
                      css={css`
                        overflow: auto;
                        max-height: 400px;
                      `}
                    >
                      {filteredTextOptOutAppointmentList?.map((appt) => {
                        return (
                          <PatientListRow key={appt.id} optOutText appt={appt} locationId={appt?.locationId || ''} />
                        );
                      })}
                    </NakedUl>
                  ) : (
                    <Text css={noListItemStyles}>{t('No Phone Call List')}</Text>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
        </div>
      </div>
      <ButtonBar
        css={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: theme.spacing(4),
        }}
      >
        <IconButton
          css={css`
            color: ${theme.colors.primary50};
          `}
          label={t('Back')}
          showLabelAlways
          onClick={() => setHideTabs(false)}
          trackingId={ScheduleMassMessageTrackingIds.backBtn}
        >
          <Icon color='primary' name='back' />
        </IconButton>
        <div css={{ display: 'flex', gap: theme.spacing(1) }}>
          <SecondaryButton trackingId={ScheduleMassMessageTrackingIds.cancelBtn} onClick={onClose}>
            {t('Cancel')}
          </SecondaryButton>
          <PrimaryButton
            disabled={selectedAppointments.length === 0}
            onClick={() => setCurrStep(ScheduleMassMessageStepEnum.SEND_MASS_MESSAGE)}
            trackingId={ScheduleMassMessageTrackingIds.nextBtn}
          >
            {t('Next')}
          </PrimaryButton>
        </div>
      </ButtonBar>
    </>
  );
};

const noListItemStyles = css({
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.colors.neutral50,
});
