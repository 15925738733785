import { useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { useInvoiceShallowStore } from '@frontend/payments-invoice-controller';
import { QuickFillBar, quickRangeConfig } from '../../QuickFill';

interface InvoicesQuickFillBarProps {
  showAllTimeButton?: boolean;
  enableDropDownFilter?: boolean;
}

export const InvoicesQuickFillBar = ({
  showAllTimeButton = false,
  enableDropDownFilter = false,
}: InvoicesQuickFillBarProps) => {
  const { t } = useTranslation('payments');
  const { filter, setFilter } = useInvoiceShallowStore('filter', 'setFilter');
  const config = useMemo(() => quickRangeConfig(t, showAllTimeButton), []);

  const handleSetFilterRange = ([start, end]: string[]) => {
    const { dateRange: _, ...newFilter } = filter;
    setFilter({
      ...newFilter,
      ...(start && end ? { dateRange: { start, end } } : {}),
    });
  };

  return (
    <QuickFillBar
      trackingId='pay-portal-invoices-quickfillbar'
      config={config}
      onSelect={handleSetFilterRange}
      filter={filter}
      enableDropDownFilter={enableDropDownFilter}
    />
  );
};
