import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Avatar, Text } from '@frontend/design-system';
import { CallDuration } from '../../hooks/use-call-duration';
import { useCallerInfo } from '../../hooks/use-caller-info';
import { useSoftphoneCallState } from '../../providers/softphone-call-state-provider';
import { useSoftphoneDirectory } from '../../providers/softphone-directory-provider';
import { isEstablishedCall, isOutgoingCall } from '../../types';

export const CallBarHead = () => {
  const { t } = useTranslation('softphone');
  const primaryCall = useSoftphoneCallState((ctx) => ctx.primaryCall);
  const mergedCallGroup = useSoftphoneCallState((ctx) => ctx.mergedCallGroup);
  const isMergedCallPrimaryCall = useSoftphoneCallState((ctx) => ctx.isMergedCallPrimaryCall);
  const useGetPersonImageQuery = useSoftphoneDirectory((ctx) => ctx.queries.useGetPersonImageQuery);
  const firstMergedCall =
    isMergedCallPrimaryCall &&
    mergedCallGroup?.reduce((first, call) => {
      if (!first) {
        return call;
      }
      return isEstablishedCall(call) && isEstablishedCall(first) && call.establishedAt < first.establishedAt
        ? call
        : first;
    }, mergedCallGroup[0]);
  const callWithTimer = firstMergedCall ? firstMergedCall : primaryCall;
  const startTime = callWithTimer
    ? isOutgoingCall(callWithTimer)
      ? callWithTimer.placedAt
      : isEstablishedCall(callWithTimer)
      ? callWithTimer?.establishedAt
      : undefined
    : undefined;

  const { contact, title } = useCallerInfo({
    uri: primaryCall?.remoteParty.uri ?? '',
    displayName: primaryCall?.remoteParty.displayName,
    enabled: !mergedCallGroup,
  });

  const { data: image } = useGetPersonImageQuery(contact?.PersonID ?? '', {
    enabled: !!contact && !mergedCallGroup,
  });
  const initials = contact ? (contact?.FirstName ?? '') + ' ' + (contact?.LastName ?? '') : undefined;
  const connected = primaryCall && isEstablishedCall(primaryCall);
  const showMergedCallInfo = mergedCallGroup && mergedCallGroup.length > 1 && isMergedCallPrimaryCall;
  const isOnHold = showMergedCallInfo ? mergedCallGroup.every((call) => !!call.onHoldSince) : primaryCall?.onHoldSince;

  return (
    <>
      {!showMergedCallInfo && <Avatar name={initials} size='small' title={title} src={image} />}
      {connected && (
        <>
          {showMergedCallInfo && (
            <Text
              css={css`
                margin-left: ${theme.spacing(1)};
              `}
              color='white'
              as={'span'}
              weight='bold'
            >
              {t('Group Call')}
            </Text>
          )}
          {isOnHold && (
            <Text
              css={css`
                margin-left: ${theme.spacing(1)};
              `}
              color='white'
              as={'span'}
              weight='bold'
            >
              {t('HOLD')}
            </Text>
          )}
          {!isOnHold && startTime && (
            <Text
              as='span'
              css={css`
                margin-left: ${theme.spacing(1)};
              `}
              color='white'
            >
              <CallDuration connectedAt={startTime ?? undefined} />
            </Text>
          )}
        </>
      )}
    </>
  );
};
