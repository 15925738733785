import { useQueryClient } from 'react-query';
import {
  InvoiceModel,
  deleteInvoice,
  InvoiceStatus,
  cancelInvoiceQueryCache,
  updateInvoiceQueryCache,
  getInvoiceQueryKey,
} from '@frontend/api-invoices';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { useMutation } from '@frontend/react-query-helpers';
import { useAlert } from '@frontend/design-system';

export const useCancelInvoice = () => {
  const { paymentsUrl } = useMerchant();
  const queryClient = useQueryClient();
  const alerts = useAlert();
  const { locationId } = useMultiQueryUtils();

  const { mutate, status, reset } = useMutation(
    async (invoice: InvoiceModel) => {
      if (paymentsUrl) {
        return deleteInvoice(paymentsUrl, invoice);
      }
      return undefined;
    },
    {
      onMutate: async (invoice: InvoiceModel) => {
        queryClient.cancelQueries(getInvoiceQueryKey(locationId, invoice.id));

        const newInvoice: InvoiceModel = {
          ...invoice,
          status: InvoiceStatus.Canceled,
        };
        cancelInvoiceQueryCache(queryClient, newInvoice, getInvoiceQueryKey(locationId, newInvoice.id));
        return () => updateInvoiceQueryCache(queryClient, invoice, getInvoiceQueryKey(locationId, invoice.id));
      },
      onSuccess: () => {
        alerts.success('Invoice Canceled');
      },
      onError: (error, invoice, rollback) => {
        alerts.error('Failed to cancel invoice');
        console.error(`Tried to cancel invoice: ${invoice.id}`, error);
        rollback?.();
      },
    }
  );

  return {
    cancelInvoice: mutate,
    status: status,
    reset: reset,
  };
};
