import { useState } from 'react';
import { css } from '@emotion/react';
import { useQueryClient } from 'react-query';
import { InvoiceModel, PaymentModel } from '@frontend/api-invoices';
import { formatCentsToCurrency } from '@frontend/currency';
import { getTodaysDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { useInvoiceShallowStore, useQueryPaginatedInvoices } from '@frontend/payments-invoice-controller';
import { useCreatePaymentRequest } from '@frontend/person-invoice-create';
import { pendo } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import { ConfirmationModal, ModalControlModalProps, Text } from '@frontend/design-system';
import { useNavigateWithAlert } from '../../hooks';
import { PaymentsNavAlerts } from '../../utils';

type ConfirmInvoiceModalProps = ModalControlModalProps & {
  invoice: InvoiceModel;
};

export const calculateRefunds = (payment: PaymentModel) => {
  return payment?.refunds?.reduce((refAcc, refund) => refAcc + refund.amount, 0) ?? 0;
};

export const calculateInvoicePayments = (invoice: InvoiceModel) => {
  return (
    invoice.payments?.reduce(
      (acc, payment) =>
        acc +
        (payment?.refunds
          ? payment?.paidAmount - calculateRefunds(payment)
          : payment.status === 'SUCCEEDED'
          ? payment.paidAmount
          : 0),
      0
    ) ?? 0
  );
};

export const calcRemainingBalance = (invoice: InvoiceModel) => {
  return invoice.payments ? invoice.billedAmount - calculateInvoicePayments(invoice) : invoice.billedAmount;
};

export const ConfirmInvoiceCreateModal = ({ invoice, ...rest }: ConfirmInvoiceModalProps) => {
  const { t } = useTranslation('payments');
  const { createPaymentRequest, creatingPaymentRequest } = useCreatePaymentRequest({ locationId: invoice.locationId });
  const { setSelectedInvoiceId } = useInvoiceShallowStore('setSelectedInvoiceId');
  const { invoicesQueryKey } = useQueryPaginatedInvoices();
  const queryClient = useQueryClient();
  const [invoiceId, setInvoiceId] = useState<string>();

  useNavigateWithAlert({
    enable: !creatingPaymentRequest && !!invoiceId,
    key: PaymentsNavAlerts.CreatePaymentRequest,
    to: `/payments/invoices/${invoiceId}`,
    type: 'success',
  });

  const handleCreateInvoice = async () => {
    await createPaymentRequest({
      personId: invoice.person.id!,
      amount: Number(calcRemainingBalance(invoice)),
      mobileNumber: invoice.person.mobilePhone,
      memo: `Remainder requested from: ${invoice.id}`,
      providerName: invoice.providerName,
    }).then((invoice) => {
      pendo.track('payments-create-invoice', {
        date: getTodaysDate('MM/DD/YYYY'),
        amount: Number(calcRemainingBalance(invoice)),
      });
      setSelectedInvoiceId(invoice.id);
      setInvoiceId(invoice.id);
      queryClient.invalidateQueries(invoicesQueryKey);
    });
  };

  return (
    <ConfirmationModal
      {...rest}
      onConfirm={handleCreateInvoice}
      title={'Request Remainder'}
      confirmLabel={t('Create Request')}
    >
      <div
        css={css`
          margin-top: ${theme.spacing(2)};
          margin-bottom: ${theme.spacing(2)};
        `}
      >
        <Text>{t('Request payment for the remainder of the original request?')}</Text>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            gap: ${theme.spacing(1)};
            margin-top: ${theme.spacing(2)};
          `}
        >
          <Text>{t('Remainder amount: ')}</Text>
          <Text weight='semibold'>{formatCentsToCurrency(calcRemainingBalance(invoice))}</Text>
        </div>
      </div>
    </ConfirmationModal>
  );
};
