import { useTranslation } from '@frontend/i18n';
import { usePopoverMenu } from '@frontend/design-system';
import { InboxFiltersPopoverSubmenuItem } from '../inbox-filters-popover-submenu-item';
import { TagsFilterPopoverMenu } from './tags-filter-popover';

type TagsFilterPopoverSubmenuItemProps = {
  toggleItems: (tagIds: string[]) => void;
  selectedTags: string[];
  groupIds?: string[];
  divider?: 'top' | 'bottom';
  closeParentMenu: () => void;
  trackingId?: string;
};
export const TagsFilterPopoverSubmenuItem = ({
  toggleItems,
  selectedTags,
  groupIds,
  divider,
  closeParentMenu,
  trackingId,
}: TagsFilterPopoverSubmenuItemProps) => {
  const { t } = useTranslation('inbox');
  const tagsPopover = usePopoverMenu<HTMLButtonElement>({
    placement: 'right-start',
    middlewareOptions: {
      offset: {
        mainAxis: 0,
        crossAxis: -8,
      },
    },
  });

  return (
    <>
      <InboxFiltersPopoverSubmenuItem
        getSubmenuTriggerProps={tagsPopover.getTriggerProps}
        iconName='label-small'
        label={t('Tags')}
        count={selectedTags.length}
        divider={divider}
        trackingId={trackingId}
      />
      <TagsFilterPopoverMenu
        getMenuProps={tagsPopover.getMenuProps}
        getItemProps={tagsPopover.getItemProps}
        groupIds={groupIds}
        toggleItems={toggleItems}
        selectedTags={selectedTags}
        closeParentMenu={closeParentMenu}
      />
    </>
  );
};
