import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyles = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderBottom: `1px solid ${theme.colors.neutral10}`,
  padding: theme.spacing(1.5, 2),
});

export const textContainerStyle = css({ display: 'flex', alignItems: 'center', gap: theme.spacing(1) });

export const textStyle = css({
  fontWeight: theme.font.weight.bold,
  whiteSpace: 'nowrap',
});
