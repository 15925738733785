import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { FinanceQueries } from '@frontend/api-finance';
import { FreeTrialSubscriptionQueries, FreeTrialSubscriptionTypes } from '@frontend/api-free-trial-subscription';
import { getUser } from '@frontend/auth-helpers';
import { Trans, useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Text, useAlert, useModalControl } from '@frontend/design-system';
import { getFeatureWiseTrackingId, SelfUpgradeModalTrackingIds } from '../../../constants/tracking-ids';
import { checkCanSelfSubscribe } from '../../../self-upgrade.util';
import { FeatureSelfSubscribeModalProps } from '../../../types';
import { SelfUpgradeBaseModal } from '../../feature-upgrade-action-modal/self-upgrade-modal/SelfUpgradeBaseModal';
import { SelfUpgradeFailModal } from '../../feature-upgrade-action-modal/self-upgrade-modal/SelfUpgradeFailModal';
import { SelfUpgradeLocationSelectionTable } from '../../feature-upgrade-action-modal/self-upgrade-modal/SelfUpgradeLocationSelectionTable';
import { SelfUpgradeOverdueBalance } from '../../feature-upgrade-action-modal/self-upgrade-modal/SelfUpgradeOverdueBalanceModal';
import { SelfUpgradeSuccessModal } from '../../feature-upgrade-action-modal/self-upgrade-modal/SelfUpgradeSuccessModal';
import { SubscribeTermsModal } from '../../subscribe-terms-modal/SubscribeTermsModal';
import { FORMS_PAGE_URL, FORMS_PRICE } from './constants';
import { FormsDigitizationOptionModal } from './FormsDigitizationOptionModal';
import { FormsSubscriptionAdditionalCheckboxField } from './FormsSubscriptionAdditionalCheckboxField';

const ONBOARDER_SCHEDULING_URL = 'https://getweave.chilipiper.com/book/upsell?type=64ff89461c5178020e8bb181&back=true';
const FEATURE_ENUM = Feature.FORMS;

export const FormsSelfSubscribeModal: FC<FeatureSelfSubscribeModalProps> = ({
  selfSubscribeEligibilityData,
  onUpgradeSuccess,
  ...modalProps
}) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'forms-self-upgrade-modal' });
  const { accessibleLocationData } = useAppScopeStore();
  const alerts = useAlert();
  const navigate = useNavigate();

  const [selectedLocationIds, setSelectedLocationIds] = useState<string[]>([]);
  const [addonChecked, setAddonChecked] = useState(false);

  const digitizationLocationModalControls = useModalControl();

  const [partialFailedLocationIds, setPartialFailedLocationIds] = useState<string[]>([]);
  const selfUpgradeSuccessModalControls = useModalControl();
  const selfUpgradeFailModalControls = useModalControl();
  const overdueBalanceModalControls = useModalControl();
  const subscribeTermsModalControls = useModalControl();

  const locationIds = selfSubscribeEligibilityData.map(({ locationId }) => locationId);
  const { data: multiInvoiceBalanceData, isLoading: isMultiInvoiceBalanceDataLoading } =
    FinanceQueries.useMultiInvoiceBalanceDetails(
      { locationIds },
      { enabled: modalProps.show && locationIds.length > 0 }
    );

  const locationWiseOverdueBalance = useMemo<Record<string, boolean>>(() => {
    if (multiInvoiceBalanceData?.response?.length) {
      return multiInvoiceBalanceData.response.reduce<Record<string, boolean>>((acc, current) => {
        if (current.locationId && !current.error) {
          acc[current.locationId] = Number(current.balanceStatus?.current) > 0;
        }
        return acc;
      }, {});
    }
    return {};
  }, [multiInvoiceBalanceData]);

  const modifiedSelfUpgradeEligibilityData = useMemo(() => {
    return selfSubscribeEligibilityData.map((data) => ({
      ...data,
      hasOverdueBalance: !!locationWiseOverdueBalance[data.locationId],
    }));
  }, [selfSubscribeEligibilityData, locationWiseOverdueBalance]);

  const showFailedAlert = (partialFailedLocations: string[] = []) => {
    modalProps.onClose();
    alerts.error({
      message: partialFailedLocations.length
        ? t('Upgrade failed for {{count}} location', { count: partialFailedLocations.length })
        : t('Upgrade failed'),
      action: {
        label: partialFailedLocations.length ? t('See Details') : t('Contact Support'),
        onClick: () => {
          setPartialFailedLocationIds(partialFailedLocations);
          selfUpgradeFailModalControls.openModal();
        },
      },
    });
  };

  const { mutateAsync: subscribe, isLoading: isSubscriptionInProgress } =
    FreeTrialSubscriptionQueries.useFeatureMultiSubscribe({
      onSettled: digitizationLocationModalControls.closeModal,
      onSuccess: (response) => {
        const failedToUpgradeLocationIds: string[] =
          response.subscriptionResponse?.filter(({ error }) => !!error).map(({ locationId }) => locationId || '') ||
          ([] as string[]);
        if (!failedToUpgradeLocationIds.length) {
          onUpgradeSuccess();
          alerts.success({
            message: t('Success! You now have access to Digital Forms'),
            action: {
              label: t('Go to'),
              onClick: () => {
                navigate({
                  to: FORMS_PAGE_URL,
                });
              },
            },
          });
          selfUpgradeSuccessModalControls.openModal();
        } else if (failedToUpgradeLocationIds.length === selectedLocationIds.length) {
          showFailedAlert();
        } else {
          onUpgradeSuccess();
          showFailedAlert(failedToUpgradeLocationIds);
        }
      },
      onError: () => {
        showFailedAlert();
      },
    });

  const isMultiLocation = modifiedSelfUpgradeEligibilityData.length > 1;

  const renderActionPrefix = useMemo(() => {
    if (isMultiLocation && selectedLocationIds.length > 0) {
      return (
        <Text weight='bold' size='medium'>
          ${FORMS_PRICE * selectedLocationIds.length}/{t('mo')}
        </Text>
      );
    } else if (selectedLocationIds.length === 0) {
      return (
        <Text color='light' size='medium'>
          {t('You must select atleast one location to continue')}
        </Text>
      );
    }
    return null;
  }, [selectedLocationIds, isMultiLocation]);

  const handleUpgrade = useCallback(
    (digitizationLocationIds: string[] = []) => {
      const digitizationAdditionalLine: FreeTrialSubscriptionTypes.AdditionalLines[] = [
        { productLine: FreeTrialSubscriptionTypes.AddOnLineItems.ADD_ON_LINE_ITEM_DIGITISATION_FEE, quantity: 1 },
      ];
      const user = getUser();
      const requestData: FreeTrialSubscriptionTypes.MultiSubscribeRequest = {
        coreFeatureEnum: FEATURE_ENUM,
        userDetails: {
          firstName: user?.firstName ?? '',
          lastName: user?.lastName ?? '',
          email: user?.username ?? '',
          phone: user?.mobileNumber ?? '',
        },
        locations: selectedLocationIds.map((locationId) => {
          const shouldAddDigitization = isMultiLocation ? digitizationLocationIds.includes(locationId) : addonChecked;
          return {
            slug: accessibleLocationData[locationId]?.slug || '',
            additionalLines: shouldAddDigitization ? digitizationAdditionalLine : [],
          };
        }),
      };
      subscribe(requestData);
    },
    [selectedLocationIds, addonChecked, isMultiLocation, accessibleLocationData]
  );

  useEffect(() => {
    if (modalProps.show && modifiedSelfUpgradeEligibilityData.length === 1) {
      if (modifiedSelfUpgradeEligibilityData[0].hasOverdueBalance) {
        overdueBalanceModalControls.openModal();
        modalProps.onClose();
      } else {
        setSelectedLocationIds(
          modifiedSelfUpgradeEligibilityData.filter(checkCanSelfSubscribe).map(({ locationId }) => locationId)
        );
      }
    }
  }, [modifiedSelfUpgradeEligibilityData, modalProps.show]);

  return (
    <>
      {isMultiLocation ? (
        <SelfUpgradeBaseModal
          featureEnum={FEATURE_ENUM}
          maxWidth={800}
          onSecondaryAction={() => {
            modalProps.onClose();
            digitizationLocationModalControls.openModal();
          }}
          secondaryActionLabel={t('Next')}
          secondaryActionTrackingId={getFeatureWiseTrackingId(FEATURE_ENUM, SelfUpgradeModalTrackingIds.nextAction)}
          isSecondaryActionDisabled={selectedLocationIds.length === 0}
          actionPrefixText={renderActionPrefix}
          isLoading={isSubscriptionInProgress || isMultiInvoiceBalanceDataLoading}
          {...modalProps}
        >
          <Trans t={t}>
            <Text size='large'>
              You are adding <strong>Digital Forms</strong> to these locations. All users on your account will have
              access to this feature.
            </Text>
          </Trans>
          {modalProps.show && !isMultiInvoiceBalanceDataLoading && (
            <SelfUpgradeLocationSelectionTable
              initialSelectedData={modifiedSelfUpgradeEligibilityData}
              data={modifiedSelfUpgradeEligibilityData}
              onChangeSelection={setSelectedLocationIds}
              price={FORMS_PRICE}
              subscriptionTerm='monthly'
            />
          )}
        </SelfUpgradeBaseModal>
      ) : (
        <SelfUpgradeBaseModal
          featureEnum={FEATURE_ENUM}
          maxWidth={600}
          onTermsClick={subscribeTermsModalControls.openModal}
          primaryActionLabel={t('Upgrade')}
          onPrimaryAction={handleUpgrade}
          primaryActionTrackingId={getFeatureWiseTrackingId(FEATURE_ENUM, SelfUpgradeModalTrackingIds.upgradeAction)}
          isLoading={isSubscriptionInProgress || isMultiInvoiceBalanceDataLoading}
          {...modalProps}
        >
          <Trans t={t}>
            <Text size='large'>
              You are adding <strong>Digital Forms</strong> to your subscriptions for an additional ${{ FORMS_PRICE }}
              /mo.
            </Text>
          </Trans>
          <div css={{ display: 'flex', flexDirection: 'column' }}>
            <Text weight='bold' css={{ marginBottom: theme.spacing(1) }}>
              {t('Subscription Options')}
            </Text>
            <div css={dividerStyles} />
            <FormsSubscriptionAdditionalCheckboxField checked={addonChecked} onChange={setAddonChecked} />
            <div css={dividerStyles} />
          </div>
          <Text size='large'>{t('Please read and agree to the terms and conditions below.')}</Text>
        </SelfUpgradeBaseModal>
      )}
      <FormsDigitizationOptionModal
        locationIds={selectedLocationIds}
        onTermsClick={subscribeTermsModalControls.openModal}
        isLoading={isSubscriptionInProgress}
        onUpgradeClick={handleUpgrade}
        {...digitizationLocationModalControls.modalProps}
      />
      <SubscribeTermsModal
        productServiceName={t('Digital Forms')}
        additionalTerms={[
          t(
            'In the case of a digitization add-on, the digitization fee covers the cost of building up to 20 forms. The fee is non-refundable after the forms install. Any forms built by Weave beyond 20 or after the onboarding stage will be billed at $20/form'
          ),
        ]}
        {...subscribeTermsModalControls.modalProps}
      />
      <SelfUpgradeSuccessModal
        featureEnum={FEATURE_ENUM}
        featureName={t('Digital Forms')}
        onboardingCallUrl={ONBOARDER_SCHEDULING_URL}
        {...selfUpgradeSuccessModalControls.modalProps}
        onClose={selfUpgradeSuccessModalControls.closeModal}
      />
      <SelfUpgradeFailModal
        featureEnum={FEATURE_ENUM}
        partialFailedLocationIds={partialFailedLocationIds}
        {...selfUpgradeFailModalControls.modalProps}
        onClose={selfUpgradeFailModalControls.closeModal}
      />
      <SelfUpgradeOverdueBalance
        featureName={t('Digital Forms')}
        featureEnum={FEATURE_ENUM}
        {...overdueBalanceModalControls.modalProps}
      />
    </>
  );
};

const dividerStyles = css({
  borderBottom: `1px solid ${theme.colors.neutral20}`,
});
