import { IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';

export const colorSelectionObject = [
  {
    selectionColor: theme.colors.critical50,
    color: theme.colors.critical20,
  },
  {
    selectionColor: theme.colors.rose50,
    color: theme.colors.rose20,
  },
  {
    selectionColor: theme.colors.secondary.eggplant50,
    color: theme.colors.secondary.eggplant20,
  },
  {
    selectionColor: theme.colors.indigo50,
    color: theme.colors.indigo20,
  },
  {
    selectionColor: theme.colors.primary50,
    color: theme.colors.primary20,
  },
  {
    selectionColor: theme.colors.secondary.seaweed50,
    color: theme.colors.secondary.seaweed20,
  },
  {
    selectionColor: theme.colors.warning50,
    color: theme.colors.warning10,
  },
  {
    selectionColor: theme.colors.tangerine50,
    color: theme.colors.tangerine20,
  },
  {
    selectionColor: theme.colors.neutral50,
    color: theme.colors.neutral20,
  },
];

export const iconsList: IconName[] = [
  'computer',
  'building',
  'star',
  'moon',
  'like',
  'crown',
  'analytics-info',
  'wellness',
  'vet-bird',
  'vet-horse-small',
  'vet-reptile',
  'vet-rodent',
  'vet-cat',
  'vet-dog',
  'scissors',
  'bone',
  'broken-bone',
  'paw',
  'medical-stroke',
  'medical-filled',
  'heart-monitor',
  'tooth',
  'glasses',
  'camera',
  'emoji',
  'emoji-sad',
  'count-one',
  'count-two',
  'count-three',
  'count-four',
  'count-five',
  'count-six',
  'count-seven',
  'count-eight',
  'count-nine',
  'count-zero',
];
