import { useCallback, useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { ListFieldProps, useFormField } from '@frontend/design-system';
import { useCalendarViewV3HeaderContext } from '../../../context/CalendarViewV3HeaderContext';
import { CalendarViewV3HeaderFilterSelector } from './CalendarViewV3HeaderFilterSelector';

type CalendarViewV3HeaderAppointmentTypeFilterProps = {
  locationIds: string[];
  checkListProps: ListFieldProps;
  hasOnlyOneLocation: boolean;
};

export const CalendarViewV3HeaderAppointmentTypeFilter = ({
  locationIds,
  checkListProps,
  hasOnlyOneLocation,
}: CalendarViewV3HeaderAppointmentTypeFilterProps) => {
  const { t } = useTranslation('scheduleCalendarFilters');
  const searchAppointmentTypesFieldProps = useFormField({ type: 'text' });

  const { appointmentTypeList } = useCalendarViewV3HeaderContext();

  const filteredAppointmentTypesListByLocation = useMemo(() => {
    if (locationIds.length === 0) {
      return appointmentTypeList;
    }
    return appointmentTypeList.filter((appointmentType) => {
      return locationIds.includes(appointmentType.locationId);
    });
  }, [appointmentTypeList, locationIds]);

  const handleClear = () => {
    checkListProps.onChange({ value: [], name: 'provider filter' });
  };

  const handleSelectAll = useCallback(() => {
    const filteredValueBySearch = searchAppointmentTypesFieldProps.value
      ? filteredAppointmentTypesListByLocation
          .filter((appointmentType) =>
            appointmentType.label?.toLowerCase()?.includes(searchAppointmentTypesFieldProps.value?.toLowerCase())
          )
          .map((appointmentType) => appointmentType.id)
      : [];

    const allAppointmentTypesList = !searchAppointmentTypesFieldProps.value
      ? [
          ...checkListProps.value,
          ...filteredAppointmentTypesListByLocation.map((appointmentType) => appointmentType.id),
        ]
      : [];

    const filteredValue = searchAppointmentTypesFieldProps.value ? filteredValueBySearch : allAppointmentTypesList;

    checkListProps.onChange({
      value: filteredValue,
      name: 'appointment type filter',
    });
  }, [searchAppointmentTypesFieldProps.value]);

  return (
    <CalendarViewV3HeaderFilterSelector
      checkListProps={checkListProps}
      renderList={filteredAppointmentTypesListByLocation}
      title={t('Appointment Types')}
      emptyStatePlaceholder={t('No appointment types found')}
      handleClear={handleClear}
      searchFieldProps={searchAppointmentTypesFieldProps}
      handleSelectAll={handleSelectAll}
      hasOnlyOneLocation={hasOnlyOneLocation}
      numberOfSelections={checkListProps.value.length}
      totalNumber={filteredAppointmentTypesListByLocation.length}
      selectedLocations={locationIds}
    />
  );
};
