import { FormPacket } from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';
import { FormPacketSelectorTypes } from '@frontend/form-packet-selector';
import { createProvider } from '@frontend/store';
import { PacketDetailsStore, PacketDetailsStoreState } from './types';
import { checkForUnsavedChanges } from './utils';

const INITIAL_STATE: PacketDetailsStoreState = {
  packetName: '',
  packetNameRef: '',
  isPacketReady: false,
  formsInPacket: [],
  formsInPacketRef: [],
  lastUsedIndexForTileMap: -1,
  formIdToTileMap: {},
  locationIds: [],
  isSavingPacket: false,
  assetId: '',
  packetToSend: {
    id: '',
    type: FormPacketSelectorTypes.Category.PACKETS,
    reviewRequired: false,
    name: '',
    isNonEditable: false,
    formIds: [],
  },
  isReviewRequired: false,
  hasUnsavedChanges: false,
};

export const { Provider: PacketDetailsStoreProvider, useStore: usePacketDetailsStore } =
  createProvider<PacketDetailsStore>()((set, get) => ({
    ...INITIAL_STATE,
    initializeState: (stateUpdate) => set({ ...stateUpdate }),
    resetState: (stateUpdate = {}) => set({ ...INITIAL_STATE, ...stateUpdate }),
    setIsPacketReady: (isPacketReady: boolean) => set({ isPacketReady }),
    setLocationIds: (locationIds: string[]) => set({ locationIds }),
    setIsSavingPacket: (isSavingPacket: boolean) => set({ isSavingPacket }),
    setPacketToSend: (packetToSend: FormPacketSelectorTypes.FormOrPacketToSend) => set({ packetToSend }),
    setIsReviewRequired: (isReviewRequired: boolean) => set({ isReviewRequired }),

    setPacketName: (newPacketName: string) =>
      set((state) => {
        const { packetNameRef, formsInPacket, formsInPacketRef } = state;
        return {
          packetName: newPacketName,
          hasUnsavedChanges: checkForUnsavedChanges({
            packetName: newPacketName,
            packetNameRef,
            formsInPacket,
            formsInPacketRef,
          }),
        };
      }),

    setFormsInPacket: (newFormsInPacket: FormPacket[]) =>
      set((state) => {
        const { packetName, packetNameRef, formsInPacketRef } = state;
        let index = state.lastUsedIndexForTileMap;
        const formIdToTileMap = { ...state.formIdToTileMap };
        for (const form of newFormsInPacket) {
          const formId = form.id!;
          if (formIdToTileMap[formId] === undefined) {
            index += 1;
            formIdToTileMap[formId] = index;
          }
        }

        return {
          formsInPacket: newFormsInPacket,
          lastUsedIndexForTileMap: index,
          formIdToTileMap,
          hasUnsavedChanges: checkForUnsavedChanges({
            packetName,
            packetNameRef,
            formsInPacket: newFormsInPacket,
            formsInPacketRef,
          }),
        };
      }),

    removeFormFromPacket: (formId: string) =>
      set((state) => {
        const formsInPacket = state.formsInPacket.filter((form) => form.id !== formId);
        return { formsInPacket };
      }),

    getPacketState: () => {
      const { formsInPacket, packetName, locationIds } = get();
      return {
        formsInPacket,
        packetName,
        locationIds,
      };
    },
  }));
