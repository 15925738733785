import { useMatch } from '@tanstack/react-location';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { FormsQueryKeys, FormsSubmission, ProviderReview } from '@frontend/api-forms';
import { useAlert } from '@frontend/design-system';
import { useScopeLocation } from '../../../shared/hooks';

export const useFormSubmission = (autoFetch = true) => {
  const queryClient = useQueryClient();
  const alert = useAlert();
  const { params } = useMatch();

  const { submissionid } = params;

  const { locationId } = useScopeLocation();

  const { data, ...otherUseQueryProps } = useQuery(
    [FormsQueryKeys.formSubmission, submissionid],
    () => (submissionid ? FormsSubmission.API.fetchSubmission(submissionid, locationId) : Promise.resolve(undefined)),
    { refetchOnMount: true, enabled: autoFetch, retry: 1 }
  );

  const { mutate: updateProvider, isLoading: isUpdatingProvider } = useMutation(
    ProviderReview.API.updateSubmissionProvider,
    {
      onSuccess: (_, { name }) => {
        const submissionData = data as FormsSubmission.Types.NormalizedSubmissionDetailResponse;
        submissionData.providerName = name;
        queryClient.setQueryData([FormsQueryKeys.formSubmission, submissionid], submissionData);
        alert.success('Successfully changed provider');
      },
      onError: () => {
        alert.info('There are no other providers to choose from');
      },
    }
  );

  return {
    formSubmission: data,
    isUpdatingProvider,
    updateProvider,
    ...otherUseQueryProps,
  };
};
