export const pendoTags = {
  activitiesPage: 'app-forms-client-pg-activities',
  builderPage: 'app-forms-client-pg-builder',
  trainingPage: 'app-forms-client-pg-training',
  kioskPage: {
    _: 'app-forms-client-pg-kiosk',
    staffCheckIn: {
      start: 'app-forms-kiosk-staff-check-in-start',
      appointmentDate: {
        selected: 'app-forms-kiosk-appointment-date-selected',
        revisited: 'app-forms-kiosk-appointment-date-revisited',
        skippped: 'app-forms-kiosk-appointment-date-skipped',
      },
      recipient: {
        selected: 'app-forms-kiosk-recipient-selected',
        revisited: 'app-forms-kiosk-recipient-revisited',
      },
      form: {
        selected: 'app-forms-kiosk-form-or-packet-selected',
      },
      kioskOpened: 'app-forms-kiosk-solicited-link-opened',
    },
    patronCheckIn: {
      start: 'app-forms-kiosk-patron-check-in-start',
      kioskOpened: 'app-forms-client-btn-launchkiosk',
      downloadKioskQr: 'app-forms-kiosk-download-qr',
      confirmGoToMyForms: 'app-forms-kiosk-go-to-my-forms-confirm',
      cancelGoToMyForms: 'app-forms-kiosk-go-to-my-forms-cancel',
    },
    customiseOrder: {
      start: 'app-forms-kiosk-customise-kiosk-form',
      sort: 'app-forms-kiosk-customise-kiosk-form-sort',
      save: 'app-forms-kiosk-customise-kiosk-form-save',
      togglePin: 'app-forms-kiosk-customise-kiosk-form-toggle-pin',
      toggleVisibility: 'app-forms-kiosk-customise-kiosk-form-toggle-visibility',
    },
  },
  // submissions page starts
  submissionCategories: {
    inbox: 'app-forms-client-btn-inbox-tab',
    sent: 'app-forms-client-btn-sent-tab',
    archive: 'app-forms-client-btn-archived-tab',
  },
  submissionSubCategories: {
    inbox: {
      all: 'app-forms-client-btn-allinprog',
      needsReview: 'app-forms-client-btn-needsreview',
      retrySync: 'app-forms-client-btn-failedsync',
      syncManually: 'app-forms-client-btn-approvalneeded',
      reviewedSubmissions: 'app-forms-btn-reviewed',
      completed: 'app-forms-client-btn-completed',
    },
  },
  sortOrder: 'app-forms-client-btn-sort-order',
  searchbarField: 'app-forms-client-btn-searchbar',
  bulkSelection: 'app-forms-client-btn-bulk-selection',
  submissionFilters: {
    filterBtn: 'app-forms-client-btn-filter',
    applyFilters: 'app-forms-client-submissions-applyfilters',
    allSubmissions: 'app-forms-client-btn-allsubmissions-filter',
    last7Days: 'app-forms-client-btn-last7days-filter',
    newestFirst: 'app-forms-client-btn-newestfirst-filter',
    oldestFirst: 'app-forms-client-btn-oldestfirst-filter',
  },
  // submissions page ends
  settingsPage: 'app-forms-client-pg-settings',
  profileButton: 'app-forms-client-btn-profile',
  companyChangeButton: 'app-forms-client-btn-changecompany',
  recentSubmitButton: 'app-forms-client-btn-recentsubmit',
  pendingButton: 'app-forms-client-btn-pending',
  eaglesoftMedicalHistorySyncButton: 'app-forms-client-btn-custom-med-history-form',
  viewFormData: 'app-forms-client-btn-viewformdata',
  nextForm: 'app-forms-client-btn-viewright',
  previousForm: 'app-forms-client-btn-viewleft',
  viewFormPreview: 'app-forms-client-btn-viewform',
  viewAttachmentsPreview: 'app-forms-client-btn-viewattach',
  attachmentDownload: 'app-forms-client-btn-viewdownload',
  formApproveButton: 'app-forms-client-btn-viewapprove',
  modalApproveButton: 'app-forms-client-btn-modalsubmitwb',
  modalCancelButton: 'app-forms-client-btn-modalcancel',
  modalViewFormButton: 'app-forms-client-btn-modalform',
  modalViewAttachmentsButton: 'app-forms-client-btn-modalattach',
  modalDownloadButton: 'app-forms-client-btn-modaldownload',
  modalPrintButton: 'app-forms-client-btn-modalprint',
  modalEditButton: 'app-forms-client-btn-modaledit',
  modalExitButton: 'app-forms-client-btn-modalexit',
  formNavigator: 'app-forms-client-btn-form-navigator',
  searchFilterButton: 'app-forms-client-btn-searchfilter',
  templatesButton: 'app-forms-client-btn-templates',
  packetsButton: 'app-forms-client-btn-packets',
  legalButton: 'app-forms-client-btn-legaldocs',
  templateSearch: 'app-forms-client-btn-templatesearchbar',
  templateSearchFilter: 'app-forms-client-btn-templatesearchfilter',
  viewTemplate: 'app-forms-client-btn-viewtemplate',
  templateMenuButton: 'app-forms-client-btn-templatemenu',
  templateCopyButton: 'app-forms-client-btn-templatecopy',
  templateEditButton: 'app-forms-client-btn-templateedit',
  templateDeleteButton: 'app-forms-client-btn-templatedelete',
  viewPacket: 'app-forms-client-btn-viewpacket',
  packetSearch: 'app-forms-client-btn-packetsearch',
  packetRearrange: 'app-forms-client-btn-rearrange',
  packetViewFormButton: 'app-forms-client-btn-viewincludedform',
  packetRemoveFormButton: 'app-forms-client-btn-remove',
  savePacketButton: 'app-forms-client-btn-savepacket',
  addPacketButton: 'app-forms-client-btn-newpacket',
  packetMenuButton: 'app-forms-client-btn-packetmenu',
  packetNameEditButton: 'app-forms-client-btn-editpacketname',
  packetCopyButton: 'app-forms-client-btn-copypacket',
  packetDeleteButton: 'app-forms-client-btn-deletepacket',
  brandingButton: 'app-forms-client-btn-branding',
  writebacksButton: 'app-forms-client-btn-wbsettings',
  medicalButton: 'app-forms-client-btn-medical',
  patientCreationSetting: 'app-forms-client-btn-patientcreation',
  patientUpdateSetting: 'app-forms-client-btn-patientupdate',
  pdfUploadSetting: 'app-forms-client-btn-PDF',
  packetPDFDownloadButton: 'app-forms-client-btn-packetPDF',
  markAsUnread: 'app-forms-client-btn-markasunread-web',
  writebackOnOff: 'app-forms-client-btn-onoff',
  writebackAuto: 'app-forms-client-btn-automatic',
  writebackManual: 'app-forms-client-btn-manual',
  writebackRetry: 'app-forms-client-btn-retrywb',
  changeWritebackSyncApp: 'app-forms-client-btn-changePMSforsubmission',
  medicalHistory: {
    syncButton: 'app-forms-client-btn-medhistorysync',
    optionToggle: 'app-forms-client-btn-medhistorytoggle',
    eagleSoft: 'app-forms-client-btn-template-ESmedhistory',
  },
  manualWriteback: {
    syncAll: 'app-forms-client-btn-manualwb-all',
    syncSelected: 'app-forms-client-btn-manualwb-selected',
  },
  archive: {
    category: 'app-forms-client-btn-archived',
    action: {
      singleArchive: 'app-forms-client-btn-menu-archiveCTA',
      singleUnarchive: 'app-forms-client-btn-menu-unarchiveCTA',
    },
  },
  bulkActions: {
    archive: 'app-forms-client-btn-archiveCTA',
    unarchive: 'app-forms-client-btn-unarchiveCTA',
    read: 'app-forms-client-btn-markasreadCTA',
    unread: 'app-forms-client-btn-markasunreadCTA',
  },
  reminderModals: {
    writebackAuthorization: {
      settingsPageLink: 'app-forms-client-btn-dialog-authwb-UI',
      weaveHelpLink: 'app-forms-client-btn-dialog-authwb-help',
    },
  },
  settings: {
    writebacks: {
      authorize: 'app-forms-client-tab-authorizewb',
      deauthorize: 'app-forms-client-tab-deauthorizewb',
      confirmAuthorize: 'app-forms-client-btn-modalconfirmauthorize',
      confirmDeauthorize: 'app-forms-client-btn-modalconfirmdeauthorize',
      changeDefaultPMS: 'app-forms-client-btn-changedefaultsyncapp',
      confirmDefaultPMSChange: 'app-forms-client-btn-confirmdefaultsyncapp',
      cancelDefaultPMSChange: 'app-forms-client-btn-canceldefaultsyncappchange',
      saveUnifySettings: 'app-forms-client-btn-saveunifysettings',
    },
    page: {
      writeback: {
        links: {
          root: 'app-forms-client-link-writeback',
          sources: 'app-forms-client-link-writeback-sources',
          settings: 'app-forms-client-link-writeback-settings',
        },
        newPatientCreation: {
          off: 'app-forms-client-btn-patientcreation-off',
          manual: 'app-forms-client-btn-patientcreation-manual',
          automatic: 'app-forms-client-btn-patientcreation-automatic',
        },
        patientRecord: {
          off: 'app-forms-client-btn-patientupdate-off',
          manual: 'app-forms-client-btn-patientupdate-manual',
          automatic: 'app-forms-client-btn-patientupdate-automatic',
        },
        pdfDocument: {
          off: 'app-forms-client-btn-PDF-off',
          manual: 'app-forms-client-btn-PDF-manual',
          automatic: 'app-forms-client-btn-PDF-automatic',
        },
      },
      medicalHistory: {
        link: 'app-forms-client-link-medicalhistory',
        allergies: {
          link: 'app-forms-client-medicalhistory-allergies',
          sync: 'app-forms-client-allergies-sync',
          save: 'app-forms-client-allergies-save',
        },
        medicalConditions: {
          link: 'app-forms-client-medicalhistory-medicalconditions',
          sync: 'app-forms-client-medicalconditions-sync',
          save: 'app-forms-client-medicalconditions-save',
        },
        medications: {
          link: 'app-forms-client-medicalhistory-medications',
          sync: 'app-forms-client-medications-sync',
          save: 'app-forms-client-medications-save',
        },
      },
      terms: {
        link: 'app-forms-client-link-termsconditions',
        preview: 'app-forms-client-preview-terms',
        save: 'app-forms-client-save-terms',
      },
      privacy: {
        link: 'app-forms-client-link-privacypolicy',
        preview: 'app-forms-client-preview-privacy',
        save: 'app-forms-client-save-privacy',
      },
      providerMapping: {
        link: 'app-forms-client-link-providermapping',
        changeMapping: 'app-forms-client-btn-changeprovidermapping',
        save: 'app-forms-client-btn-saveprovidermapping',
      },
      customizeKiosk: {
        link: 'app-forms-client-link-customizekiosk',
      },
    },
  },
  newFormEditor: {
    editorPage: 'app-form-builder-page',
    previewForm: 'app-form-builder-preview',
    generatePDF: 'app-form-builder-generate-pdf',
    saveForm: 'app-form-builder-save-form',
    newSection: 'app-form-builder-new-section',
    reorderField: 'app-form-builder-reorder-field',
    searchFields: 'app-form-builder-search-fields',
    fieldLabelUpdate: 'app-form-builder-field-label-update',
    sectionLabelUpdate: 'app-form-builder-section-label-update',

    sectionTemplate: {
      allergies: 'app-form-builder-allergies-template',
      covid: 'app-form-builder-covid-template',
      femaleMedicalConditions: 'app-form-builder-female-medical-condition-template',
      medicalConditions: 'app-form-builder-medical-condition-template',
      patient: 'app-form-builder-personal-information-template',
      primaryInsurance: 'app-form-builder-primary-insurance-template',
      secondaryInsurance: 'app-form-builder-secondary-insurance-template',
    },
  },
  beta: {
    unifyOptOut: 'app-form-client-btn-unify-beta-optout',
  },
  templateGallery: {
    navLink: 'app-template-gallery-nav',
    termsAndConditions: {
      accepted: 'app-template-gallery-terms-and-conditions-accepted',
      declined: 'app-template-gallery-terms-and-conditions-declined',
    },
    views: {
      grid: 'app-template-gallery-grid-view',
      list: 'app-template-gallery-list-view',
    },
    location: {
      save: 'app-template-gallery-save-location',
      cancel: 'app-template-gallery-cancel-location',
    },
    copyTemplate: 'app-template-gallery-copy-template',
    previewButton: 'app-template-gallery-preview-btn',
    preview: {
      desktop: 'app-template-gallery-desktop-preview',
      mobile: 'app-template-gallery-mobile-preview',
    },
    markAs: {
      favourite: 'app-template-gallery-favourite-the-template',
      unfavourite: 'app-template-gallery-unfavourite-the-template',
    },
    sort: {
      aToZ: 'app-template-gallery-sort-a-z',
      zToA: 'app-template-gallery-sort-z-a',
      oldToNew: 'app-template-gallery-sort-old-to-new',
      newToOld: 'app-template-gallery-sort-new-to-old',
      mostPopular: 'app-template-gallery-sort-by-most-popular',
    },
    search: 'app-template-gallery-search',
    usageTerms: {
      accept: 'template-gallery-usage-terms-accept',
      decline: 'template-gallery-usage-terms-decline',
      select: 'template-gallery-usage-terms-select',
    },
  },
  // TODO: Clean up the tags under 'providerReview'
  providerReview: {
    toggleReviewForm: 'app-forms-toggle-provider-review',
    toggleReviewPacket: 'app-packet-toggle-provider-review',
    cancelForm: 'app-form-provider-review-cancel',
    cancelPacket: 'app-packet-provider-review-cancel',
    confirmForm: 'app-form-provider-review-confirm',
    confirmPacket: 'app-packet-provider-review-confirm',
    modal: {
      approve: 'app-forms-provider-review-approve',
      reject: 'app-forms-provider-review-reject',
    },
    signature: {
      saveDefaultSignature: 'app-forms-provider-review-save-default-signature',
      wetSignature: 'app-forms-provider-review-wet-signature',
      image: 'app-forms-provider-review-signature-image',
      typed: 'app-forms-provider-review-signature-typed',
    },
    approveNotes: {
      approveBtn: 'app-forms-provider-review-approve-notes-approve',
      continueToSync: 'app-forms-provider-review-approve-notes-continue-to-sync',
    },
    rejectSubmission: 'app-forms-provider-review-reject-submission',
    writebackModal: {
      sync: 'app-forms-provider-review-writeback-modal-sync',
      approve: 'app-forms-provider-review-writeback-modal-approve',
    },
    changeProvider: 'app-forms-provider-review-change-provider',
  },
  // TODO: Clean up the tags under 'writebackWidget'
  writebackWidget: {
    open: 'app-forms-wb-widget-open',
    close: 'app-forms-wb-widget-close',
    backdropClose: 'app-forms-wb-widget-close-using-backdrop',
    approve: 'app-forms-wb-widget-approve',
    showRejectUI: 'app-forms-wb-widget-show-reject-ui',
    rejectConfirm: 'app-forms-wb-widget-reject-confirm',
    cancelReject: 'app-forms-wb-widget-cancel-reject',
    retrySync: 'app-forms-wb-widget-retry-sync',
    sync: 'app-forms-wb-widget-sync',
  },
  writbackWidgetV2: {
    openModal: 'app-forms-wb-widget-v2-open-modal',
    closeModal: 'app-forms-wb-widget-v2-close-modal',
    cancel: 'app-forms-wb-widget-v2-cancel',
    sync: 'app-forms-wb-widget-v2-sync',
    retrySync: 'app-forms-wb-widget-v2-retry-sync',
    turnCreatePersonOn: 'app-forms-wb-widget-v2-turn-create-person-on',
    turnCreatePersonOff: 'app-forms-wb-widget-v2-turn-create-person-off',
    turnUpdatePersonOn: 'app-forms-wb-widget-v2-turn-update-person-on',
    turnUpdatePersonOff: 'app-forms-wb-widget-v2-turn-update-person-off',
    turnUploadDocumentOn: 'app-forms-wb-widget-v2-turn-upload-document-on',
    turnUploadDocumentOff: 'app-forms-wb-widget-v2-turn-upload-document-off',
    openAvailableSourcesList: 'app-forms-wb-widget-v2-open-available-sources-list',
    openAvailableSourceTenantsList: 'app-forms-wb-widget-v2-open-available-source-tenants-list',
    changeSource: 'app-forms-wb-widget-v2-change-source',
  },
  providerReviewV2: {
    approvalDisclaimer: 'app-provider-review-approval-disclaimer',
    approvalSignature: 'app-provider-review-approval-signature',
    approvalNote: 'app-provider-review-approval-note',
    rejectionNote: 'app-provider-review-rejection-note',
    sync: 'app-provider-review-sync',
    openModal: 'app-forms-provider-review-v2-open-modal',
    closeModal: 'app-forms-provider-review-v2-close-modal',
  },
  // TODO: Clean up the tags under 'writebackAuthSetup'
  writebackAuthSetup: {
    termsOfUse: 'app-forms-wb-setup-terms-of-use',
    getStarted: 'app-forms-wb-setup-get-started',
    cancelSourceSelection: 'app-forms-wb-setup-cancel-source-selection',
    selectAnotherSource: 'app-forms-wb-setup-select-another-source',
    reviewSourceSelection: 'app-forms-wb-setup-review-source-selection',
    goToDashboard: 'app-forms-wb-setup-go-to-dashboard',
    goToWBSettings: 'app-forms-wb-setup-go-to-wb-settings',
    skipSetup: 'app-forms-wb-setup-skip',
    contactSupport: 'app-forms-wb-setup-contact-support',
    continueSourceSelection: 'app-forms-wb-setup-continue-source-selection',
  },
  writebackSetup: {
    skipWelcomeSetup: 'app-forms-wb-setup-skip-welcome',
    skipNewLocationSetup: 'app-forms-wb-setup-skip-new-location',
    skipOutdatedSourceTenantSetup: 'app-forms-wb-setup-skip-outdated-source-tenant',
    remindWelcomeSetupLater: 'app-forms-wb-setup-remind-welcome-later',
    remindNewLocationSetupLater: 'app-forms-wb-setup-remind-new-location-later',
    remindOutdatedSourceTenantSetupLater: 'app-forms-wb-setup-remind-outdated-source-tenant-later',
    goToWelcomeSettings: 'app-forms-wb-setup-go-to-welcome-settings',
    goToNewLocationSettings: 'app-forms-wb-setup-go-to-new-location-settings',
    goToOutdatedSourceTenantSettings: 'app-forms-wb-setup-go-to-outdated-source-tenant-settings',
  },
  preferredSourceCorrection: {
    cancelNotSure: 'app-forms-wb-source-correction-not-sure',
    updatePreferredMultiPMS: 'app-forms-wb-source-correction-save-for-multi-pms',
    updatePreferredSinglePMS: 'app-forms-wb-source-correction-save-for-single-pms',
    navigateToWBSettings: 'app-forms-wb-source-correction-navigate-to-settings',
  },
  library: {
    formsTab: 'app-forms-library-forms-list',
    packetsTab: 'app-forms-library-packets-list',
    importedForms: 'app-forms-library-imported-forms-list',
    form: 'app-forms-library-form',
    packet: 'app-forms-library-packet',
    importedForm: 'app-forms-library-imported-form',
    initiateFormSending: 'app-forms-library-initiate-form-sending',
    initiatePacketSending: 'app-forms-library-initiate-packet-sending',
    forms: {
      copyFormLink: 'app-forms-library-copy-form-link',
      deleteForm: 'app-forms-library-delete-form',
      confirmDeleteForm: 'app-forms-library-confirm-delete-form',
      cancelDeleteForm: 'app-forms-library-cancel-delete-form',
      editForm: 'app-forms-library-edit-form',
      cloneFormToOtherLocations: 'app-forms-library-open-clone-form-to-other-locations',
      cloneFormToSameLocation: 'app-forms-library-open-clone-form-to-same-location',
    },
    packets: {
      createPacket: 'app-forms-library-create-packet',
      createPacketLocationSelector: 'app-forms-library-create-packet-location-selector',
      addFormToPacket: 'app-forms-library-add-form-to-packet',
      addMoreFormsToPacket: 'app-forms-library-add-more-forms-to-packet',
      savePacket: 'app-forms-library-save-packet',
      changeProviderReview: 'app-forms-library-change-provider-review',
      previewPacket: 'app-forms-library-preview-packet',
      copyPacketLink: 'app-forms-library-copy-packet-link',
      deletePacket: 'app-forms-library-delete-packet',
      confirmDeletePacket: 'app-forms-library-confirm-delete-packet',
      cancelDeletePacket: 'app-forms-library-cancel-delete-packet',
      deleteFormTile: 'app-forms-library-delete-packet-form-tile',
      deleteFormFromPreview: 'app-forms-library-delete-packet-form-from-preview',
      deleteFormConfirmation: 'app-forms-library-delete-form-confirmation',
      editPacketName: 'app-forms-library-edit-packet-name',
      previewPacketForm: 'app-forms-library-preview-packet-form',
      rearrangePacketForms: 'app-forms-library-rearrange-packet-forms',
      backToPacketList: 'app-forms-library-back-to-packet-list',
    },
  },
  sendFormsExperience: {
    close: 'app-forms-send-exp-close',
    steps: {
      appointments: {
        back: 'app-forms-send-exp-appointments-back',
        next: 'app-forms-send-exp-appointments-next',
        select: 'app-forms-send-exp-appointments-select',
        deselect: 'app-forms-send-exp-appointments-deselect',
      },
      contacts: {
        select: 'app-forms-send-exp-select-contact',
        search: 'app-forms-send-exp-search-contact',
      },
      deliveryMethod: {
        direct: {
          select: 'app-forms-send-exp-delivery-method-direct-select',
          via: {
            sms: 'app-forms-send-exp-delivery-method-direct-via-sms',
            email: 'app-forms-send-exp-delivery-method-direct-via-email',
          },
        },
        kiosk: {
          select: 'app-forms-send-exp-delivery-method-kiosk-select',
        },
        back: 'app-forms-send-exp-delivery-method-back',
      },
      composeMessage: {
        back: 'app-forms-send-exp-compose-message-back',
      },
      attachments: {
        clickToUpload: 'app-forms-send-exp-attachments-click-to-upload',
      },
      documentSelection: {
        select: {
          packet: 'app-forms-send-exp-document-selection-select-packet',
          form: 'app-forms-send-exp-document-selection-select-form',
        },
        tab: {
          packets: 'app-forms-send-exp-document-selection-tab-packets',
          forms: 'app-forms-send-exp-document-selection-tab-forms',
        },
        next: 'app-forms-send-exp-document-selection-next',
      },
    },
    send: 'app-forms-send-exp-send',
    cancel: 'app-forms-send-exp-cancel',
  },
  sideNav: {
    myForms: 'app-forms-side-nav-my-forms',
    submissions: 'app-forms-side-nav-submissions',
    builder: 'app-forms-side-nav-builder',
    kiosk: 'app-forms-side-nav-kiosk',
    templates: 'app-forms-side-nav-templates',
  },
  formsCloner: {
    confirmCopyToSameLocation: 'app-forms-cloner-confirm-copy-to-same-location',
    closeCopyToSameLocationModal: 'app-forms-cloner-close-copy-to-same-location-modal',
    confirmCloneToOtherAccessibleLocations: 'app-forms-cloner-confirm-clone-to-other-accessible-locations',
    confirmCloneToOtherPrivilegedLocations: 'app-forms-cloner-confirm-clone-to-other-privileged-locations',
    closeCloneToOtherLocationsModal: 'app-forms-cloner-close-clone-to-other-locations-modal',
    selectLocation: 'app-forms-cloner-select-location',
    deselectLocation: 'app-forms-cloner-deselect-location',
    removeLocation: 'app-forms-cloner-remove-location',
  },
};
