import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const headerStyle = css`
  margin-bottom: ${theme.spacing(2)};

  > * {
    display: flex;
    justify-content: space-between;
    gap: ${theme.spacing(2)};
  }
`;

export const buttonsContainerStyle = css`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: ${theme.spacing(3)};
  margin-top: ${theme.spacing(2)};
`;

export const plusIconContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${theme.colors.primary40};
  width: 50px;
  height: 50px;
`;
