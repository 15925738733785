import { useState } from 'react';
import { Provider } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { ScheduleType } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/schedule.pb';
import { SchedulerV3API } from '@frontend/api-scheduler-v3';
import { useTranslation } from '@frontend/i18n';
import { useAlert } from '@frontend/design-system';
import { useCalendarEventV3Context } from '../../../../context/CalendarEventsV3Context';
import { useCalendarViewV3HeaderFilterShallowStore } from '../../../../stores/use-calendar-view-v3-header-filter-store';
import {
  calculateDuration,
  createRRruleForException,
  findOverrideIndexById,
  generateExceptionId,
  transformV3ResponsesForReviewRequest,
} from '../../../../utils';
import { CalendarEventsEnums } from '../../types';
import { OutOfOfficeEventsForm } from './OutofOfficeEventForm';
import { ExceptionFormValues, OnDeleteExceptionParams, OnSaveExceptionV3PayloadParams } from './types';

type OutOfOfficeEventFormContainerProps = {
  eventType: CalendarEventsEnums;
  closeModal: () => void;
};

export const OutOfOfficeEventFormContainerV3 = ({ eventType, closeModal }: OutOfOfficeEventFormContainerProps) => {
  const alert = useAlert();
  const { t } = useTranslation('schedule');

  const [formError, setFormError] = useState<string>('');
  const [isFormSaving, setIsFormSaving] = useState<boolean>(false);

  const { practitioners, selectedMultiLocationIds, refetchScheduleEntriesData, refetchScheduleEntriesOfficeData } =
    useCalendarEventV3Context();

  const { selectedCalendarDate } = useCalendarViewV3HeaderFilterShallowStore('selectedCalendarDate');

  const providersListData = transformV3ResponsesForReviewRequest({
    practitionerDataV3: practitioners,
  }).transformedPractitioners as Provider[];

  // Refetches the schedule entries based on the entity type
  const refetchEntitySchedule = (isProviderException: boolean) => {
    if (isProviderException) {
      refetchScheduleEntriesData();
    } else {
      refetchScheduleEntriesOfficeData();
    }
  };

  // handle delete exception for V3
  const handleDeleteExceptionV3 = async ({ eventId, locationId, providerId, eventType }: OnDeleteExceptionParams) => {
    setIsFormSaving(true);
    try {
      const isProviderException = eventType === CalendarEventsEnums.PROVIDER_OUT_OF_OFFICE_EVENT;
      const entityId = isProviderException ? providerId : locationId;
      const entitySchedule = await SchedulerV3API.listScheduleEntries({ locationId, ids: [entityId] });

      const overrideUnAvailabilities = entitySchedule?.schedules[0].recurrenceRules?.override?.unAvailabilities || [];
      const overrideIndex = findOverrideIndexById(overrideUnAvailabilities, eventId);
      if (overrideIndex !== -1) {
        overrideUnAvailabilities.splice(overrideIndex, 1);
      }

      if (entitySchedule?.schedules?.length) {
        const entityType = isProviderException ? ScheduleType.PRACTITIONER : ScheduleType.LOCATION;

        const entityTypeName = isProviderException ? 'Provider' : 'Location';

        const newOverrides = {
          ...entitySchedule?.schedules[0].recurrenceRules?.override,
          unAvailabilities: overrideUnAvailabilities,
        };
        SchedulerV3API.updateSchedulerEntries({
          id: entitySchedule?.schedules[0].id,
          locationId,
          type: entitySchedule?.schedules[0].type || entityType,
          recurrenceRules: {
            ...entitySchedule?.schedules[0].recurrenceRules,
            override: newOverrides,
          },
        })
          .then(() => {
            alert.success(t('{{entityTypeName}} exceptions updated successfully', { entityTypeName }));
            refetchEntitySchedule(isProviderException);
            closeModal();
          })
          .catch(() => {
            alert.error(t('Failed to update {{entityTypeName}} exceptions', { entityTypeName }));
          });
      }
    } catch (err) {
      console.error(err);
      if (err) {
        setFormError('Something went wrong while deleting the exception');
        alert.error(t('Something went wrong while deleting the exception'));
      }
    } finally {
      setIsFormSaving(false);
    }
  };

  const createPayload = ({
    formValues,
    entityId,
    entityType,
    isEdit,
    locationId,
    entitySchedule,
  }: OnSaveExceptionV3PayloadParams) => {
    const { recurrenceRules } = entitySchedule?.schedules[0] || {};

    const dateRange = [formValues.startDate, formValues.endDate];
    let timeRange = [formValues.startTime, formValues.endTime];

    const newOverride = {
      availabilities: recurrenceRules?.override?.availabilities || [],
      un_availabilities: recurrenceRules?.override?.unAvailabilities || [],
    };

    if (formValues.isAllDay) {
      timeRange = ['00:00:00', '23:59:00'];
    }

    const rrule = createRRruleForException({
      dateRange: dateRange as unknown as [string, string],
      timeRange: timeRange as unknown as [string, string],
    });
    const [startTime, endTime] = timeRange;
    const duration = calculateDuration(startTime, endTime);

    // Generate IDs only for new exceptions
    const exceptionEntry = {
      id: isEdit ? entityId : generateExceptionId(),
      name: formValues.name,
      rrule,
      duration,
    };

    if (isEdit) {
      const unAvailabilityIndex = findOverrideIndexById(newOverride.un_availabilities, entityId);
      if (unAvailabilityIndex !== -1) {
        newOverride.un_availabilities.splice(unAvailabilityIndex, 1, exceptionEntry);
      }
    } else {
      newOverride.un_availabilities.push(exceptionEntry);
    }

    return {
      id: entityId ? entityId : '',
      locationId,
      type: entityType,
      recurrenceRules: {
        ...recurrenceRules,
        override: newOverride,
      },
    };
  };

  const handleOnSaveExceptionV3 = async (formValues: ExceptionFormValues) => {
    const isProviderException = formValues.eventType === CalendarEventsEnums.PROVIDER_OUT_OF_OFFICE_EVENT;
    const entityTypeName = isProviderException ? 'Provider' : 'Location';
    const entityType = isProviderException ? ScheduleType.PRACTITIONER : ScheduleType.LOCATION;
    const isEdit = !!formValues.eventId;
    const entityId = isProviderException ? formValues.providerId : formValues.locationId;

    setIsFormSaving(true);
    try {
      const entitySchedule = await SchedulerV3API.listScheduleEntries({
        locationId: formValues.locationId,
        ids: [entityId],
      });

      const payload = createPayload({
        entityId: formValues.eventId || entityId || '',
        entityType,
        formValues,
        isEdit,
        locationId: formValues.locationId,
        entitySchedule,
      });

      if (isEdit) {
        SchedulerV3API.updateSchedulerEntries({
          ...payload,
          id: entityId,
        })
          .then(() => {
            alert.success(t('{{entityTypeName}} exception updated successfully', { entityTypeName }));
            refetchEntitySchedule(isProviderException);
            closeModal();
          })
          .catch(() => {
            alert.error(t('Failed to update {{entityTypeName}} exception', { entityTypeName }));
          });
      } else {
        SchedulerV3API.createSchedulerEntries(payload)
          .then(() => {
            alert.success(t('{{entityTypeName}} exception created successfully', { entityTypeName }));
            refetchEntitySchedule(isProviderException);
            closeModal();
          })
          .catch(() => {
            alert.error(t('Failed to create {{entityTypeName}} exception', { entityTypeName }));
          });
      }
    } catch (err) {
      console.error(err);
      if (err) {
        setFormError('Something went wrong while saving the exception');
        alert.error(t('Something went wrong while saving the exception'));
      }
    }
  };

  const handleOnDelete = ({ eventId, locationId, providerId, eventType }: OnDeleteExceptionParams) => {
    if (eventId) {
      handleDeleteExceptionV3({ eventId, locationId, providerId, eventType });
    }
  };

  const handleOnSave = (formValues: ExceptionFormValues) => {
    handleOnSaveExceptionV3(formValues);
  };

  return (
    <OutOfOfficeEventsForm
      closeModal={closeModal}
      eventType={eventType}
      providerList={providersListData}
      selectedLocationIds={selectedMultiLocationIds}
      selectedDate={selectedCalendarDate as string}
      isLoading={isFormSaving}
      formError={formError}
      onDelete={handleOnDelete}
      onSave={handleOnSave}
    />
  );
};
