import { FC } from 'react';
import { FormsWritebacks } from '@frontend/api-forms';
import { useTooltip } from '@frontend/design-system';
import { csvStyle, ccStyle, ccHoverLabelStyle, csvHoverLabelStyle } from './contact-badge.styles';

interface ContactBadgeProps {
  reason?: FormsWritebacks.Types.WritebackNAReason;
}

const ContactBadge: FC<React.PropsWithChildren<ContactBadgeProps>> = ({ reason, ...otherProps }) => {
  const { Tooltip: HoverLabel, tooltipProps: labelProps, triggerProps } = useTooltip({ placement: 'top' });

  let badge: JSX.Element | null = null;
  let hoverLabel: JSX.Element | null = null;
  let hoverLabelStyle = ccHoverLabelStyle;

  switch (reason) {
    case 'WRITEBACK_NA_CSV':
      hoverLabelStyle = csvHoverLabelStyle;
      badge = (
        <span css={csvStyle} {...triggerProps} {...otherProps}>
          CSV
        </span>
      );
      hoverLabel = (
        <>
          <b>CSV Contact</b> Does not sync to your system of record.
        </>
      );
      break;

    case 'WRITEBACK_NA_CC':
      hoverLabelStyle = ccHoverLabelStyle;
      badge = (
        <span css={ccStyle} {...triggerProps} {...otherProps}>
          CC
        </span>
      );
      hoverLabel = (
        <>
          <b>Custom Contact</b>. Does not sync to your system of record.
        </>
      );
      break;

    default:
      return null;
  }

  return (
    <>
      {badge}
      <HoverLabel {...labelProps} css={hoverLabelStyle}>
        {hoverLabel}
      </HoverLabel>
    </>
  );
};

export default ContactBadge;
