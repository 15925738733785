import React, { ElementType, forwardRef } from 'react';
import { css } from '@emotion/react';
import composeRefs from '@seznam/compose-react-refs';
import { PolymorphicComponentPropWithoutRef, PolymorphicRef } from '@frontend/design-system';

type SmartHoverProps = {
  useSmartHover: true;
  isActive: boolean | undefined;
  registerActiveLink: (r: HTMLElement) => void;
  isMouseInTriangle: (coords: { x: number; y: number }) => boolean;
};

type NoSmartHoverProps = {
  useSmartHover?: false;
  registerActiveLink?: never;
  isActive?: never;
  isMouseInTriangle?: never;
};

type NavLinkCustomProps = SmartHoverProps | NoSmartHoverProps;

type NavLinkComponentProps<C extends ElementType> = PolymorphicComponentPropWithoutRef<C> & NavLinkCustomProps;

const plainButtonStyles = css`
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
`;

const plainLinkStyles = css`
  text-decoration: none;
`;

type CustomMouseEvent<Handler = React.HTMLAttributes<HTMLAnchorElement>['onMouseEnter']> = Handler extends (
  e: infer E
) => void
  ? E
  : never;

export const NavLink = forwardRef(
  <C extends ElementType = 'a'>(
    {
      as,
      children,
      trackingId,
      onMouseEnter,
      registerActiveLink,
      isMouseInTriangle,
      useSmartHover,
      isActive,
      ...rest
    }: NavLinkComponentProps<C>,
    ref: PolymorphicRef<C>
  ) => {
    const Component = as || 'a';

    /**
     * ref might be null
     * So need a reliable ref to the root element
     */
    const internalRef = React.useRef<PolymorphicRef<C>>(null);

    /**
     * When triggering the onMouseEnter callback from onMouseMove, make sure
     * it only fires once.
     */
    const hoverHasTriggeredRef = React.useRef(false);

    /**
     * If someone pauses the mouse inside the "safe" triangle for 300ms, then
     * we can assume they want to hover.
     */
    const timeoutId = React.useRef<number>(0);

    const onMouseMove = (e: CustomMouseEvent) => {
      if (!useSmartHover) {
        return;
      }

      if (!hoverHasTriggeredRef.current && !isMouseInTriangle({ x: e.clientX, y: e.clientY })) {
        hoverHasTriggeredRef.current = true;
        window.clearTimeout(timeoutId.current);

        /**
         * Mouse move picks up svg, span, and other child nodes of root element.
         * But internalRef is always right.
         */
        onMouseEnter?.({ ...e, target: internalRef.current });
      }
    };

    const handleMouseEnter = (e: CustomMouseEvent) => {
      if (!useSmartHover) {
        return onMouseEnter?.(e);
      }
      timeoutId.current = window.setTimeout(() => {
        onMouseEnter?.(e);
      }, 300);

      /**
       * Always reset "has triggered" when the mouse enters, so we never get a
       * stranded link that won't trigger.
       */
      hoverHasTriggeredRef.current = false;
    };

    const handleMouseLeave = () => {
      if (!useSmartHover) {
        return;
      }
      window.clearTimeout(timeoutId.current);
    };

    React.useEffect(() => {
      if (!(isActive && useSmartHover)) {
        return;
      }

      if (internalRef.current) {
        registerActiveLink(internalRef.current);
      }

      return () => {
        /**
         * Mouse may hover on another link, and change this link's
         * active state. So we need to clear the timeout.
         */
        window.clearTimeout(timeoutId.current);
      };
    }, [isActive, useSmartHover]);

    // TODO: Add preloading of the links
    return (
      <Component
        css={[Component === 'button' ? plainButtonStyles : plainLinkStyles]}
        data-trackingid={trackingId}
        {...rest}
        ref={composeRefs(internalRef, ref)}
        onMouseMove={onMouseMove}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {children}
      </Component>
    );
  }
);

NavLink.displayName = 'NavLink';
