import { LocationService } from '@weave/schema-gen-ts/dist/schemas/location/v2/data-location/data_location.pb';
import type {
  Location,
  LocationsInOrgResponse,
  OrgsFromTokenResponse,
  Location as DataLocation,
} from '@weave/schema-gen-ts/dist/schemas/location/v2/data-location/messages.pb';
import { OrgService, type Organization } from '@weave/schema-gen-ts/dist/schemas/organization/v1/org.pb';
import { http } from '@frontend/fetch';
import { bindHTTP } from '@frontend/schema';

export * from './query-keys';
export type { Location };

export const SchemaLocationService = bindHTTP(LocationService);
export const SchemaOrgService = bindHTTP(OrgService);

export async function getLocationOrgIds(): Promise<OrgsFromTokenResponse> {
  return SchemaLocationService.OrgsFromToken({}, { skipValidation: true });
}

export async function getLocationData({ locationId }: { locationId: string }): Promise<DataLocation> {
  return http.get(`/location/v2/data-location/locations/${locationId}`);
}

export async function getLocationsForOrgId(id: string): Promise<LocationsInOrgResponse> {
  return SchemaLocationService.LocationsInOrg({ orgId: id }, { skipValidation: true });
}

export async function getLocationsForOrgIds(orgIds: string[]): Promise<LocationsInOrgResponse[]> {
  return Promise.all(orgIds.map(getLocationsForOrgId));
}

export async function getOrg(orgId: string): Promise<Organization> {
  return SchemaOrgService.GetOrg({ id: orgId });
}
export async function getOrgsData(orgIds: string[]): Promise<Organization[]> {
  const results = await Promise.allSettled(orgIds.map(getOrg));
  return results
    .filter((result) => result.status === 'fulfilled')
    .map((result) => (result as PromiseFulfilledResult<Organization>).value);
}
