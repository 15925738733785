import { useState } from 'react';
import { css, keyframes } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  AlertInvertIconSmall,
  MoonIcon,
  NakedButton,
  SoftphoneTrayIcon,
  SpinningLoader,
  Text,
} from '@frontend/design-system';
import { useSoftphoneClient } from '../providers/softphone-client-provider';
import { useSoftphoneEventSubscription } from '../providers/softphone-events-provider';
import { isOccupiedParkSlot, useSoftphoneParkSlots } from '../providers/softphone-park-slots-provider';
import { useSoftphoneSettings } from '../providers/softphone-settings-provider';
import { useSoftphoneWidgetControl } from '../providers/widget-control-provider';

type Props = {
  error?: Error;
  onClick?: () => void;
};

export const SoftphoneIconButton = ({ onClick, error }: Props) => {
  const isOpen = useSoftphoneWidgetControl((ctx) => ctx?.isOpen);
  const open = useSoftphoneWidgetControl((ctx) => ctx?.open);
  const close = useSoftphoneWidgetControl((ctx) => ctx?.close);
  const dnd = useSoftphoneSettings((ctx) => ctx?.isDoNotDisturb);
  const status = useSoftphoneClient((ctx) => ctx?.status);
  const parkSlots = useSoftphoneParkSlots((ctx) => ctx?.parkSlotsWithPresence);
  const occupiedParkSlots = parkSlots?.filter(isOccupiedParkSlot) ?? [];
  const { t } = useTranslation('phone');
  const [isPulsing, setIsPulsing] = useState(false);
  //TODO: this might be correct. Verify
  // const isPulsing = !!occupiedParkSlots?.length;

  useSoftphoneEventSubscription(
    'park-slot.ringback',
    () => {
      setIsPulsing(true);
      setTimeout(() => {
        setIsPulsing(false);
      }, 3000);
    },
    []
  );

  return (
    <NakedButton
      aria-label={t('Softphone Dialpad')}
      trackingId='softphone-icon'
      onClick={() => (onClick ? onClick() : isOpen ? close?.() : open?.())}
      css={css`
        cursor: pointer;
        position: relative;
        z-index: ${theme.zIndex.modal};
        border: 1px solid ${theme.colors.white};
        border-radius: ${theme.borderRadius.medium};
        svg rect {
          fill: ${status === 'success' && dnd ? theme.colors.secondary.eggplant70 : theme.colors.neutral90};
        }
        &:hover {
          svg rect {
            fill: ${status === 'success' && dnd ? theme.colors.secondary.eggplant80 : theme.colors.neutral80};
          }
        }
      `}
    >
      <SoftphoneTrayIcon
        css={[
          css`
            border-radius: ${theme.borderRadius.medium};
          `,
          isPulsing ? pulseGlowStyle : null,
        ]}
      />

      {!!(error || status === 'error') && (
        <AlertInvertIconSmall
          css={[
            trayIconStyles,
            css`
              color: ${theme.colors.critical30};
            `,
          ]}
        />
      )}
      {!error && status === 'loading' && (
        <SpinningLoader
          size='xs'
          css={[
            trayIconStyles,
            css`
              top: 0;
              right: 6px;
              & > div {
                border-width: 3px;
                border-top-color: ${theme.colors.warning50};
              }
            `,
          ]}
        />
      )}
      {!error && status === 'success' && dnd && (
        <MoonIcon
          size={10}
          css={[
            trayIconStyles,
            css`
              border-radius: 42px;
              padding: 2px;
              background: ${theme.colors.secondary.eggplant50};
              color: ${theme.colors.white};
            `,
          ]}
        />
      )}
      {!error && status === 'success' && !!occupiedParkSlots?.length && (
        <Text as='span' weight='bold' color='white' css={parkedCallIconStyles}>
          {occupiedParkSlots?.length}
        </Text>
      )}
    </NakedButton>
  );
};

const trayIconStyles = css`
  position: absolute;
  top: 2px;
  right: 2px;
  width: ${theme.spacing(2)};
  height: ${theme.spacing(2)};
`;

const pulseGlow = keyframes`
    0% {
      box-shadow: 0 0 ${theme.spacing(1)} ${theme.colors.critical50};
    }
    50% {
      box-shadow: 0 0 ${theme.spacing(2)} ${theme.colors.critical50};
    }
    100% {
      box-shadow: 0 0 ${theme.spacing(1)} ${theme.colors.critical50};
    }
`;

const pulseGlowStyle = css`
  animation: ${pulseGlow} 2s ease infinite;
`;

const parkedCallIconStyles = css`
  width: ${theme.spacing(2)};
  height: ${theme.spacing(2)};
  font-size: ${theme.fontSize(14)};
  border-radius: 50%;
  background-color: ${theme.colors.critical50};
  position: absolute;
  top: 3px;
  right: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
