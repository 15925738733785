import { useMemo } from 'react';
import { FormsSubmission } from '@frontend/api-forms';
import { ModalControlResponse } from '@frontend/design-system';
import { ApproveOrRejectMode } from '../../writeback-widget.types';
import { useProviderReviewConfig } from './useProviderReviewConfig';
import { useWritebackDestinationConfig } from './useWritebackDestinationConfig';
import { useWritebackSettingsConfig } from './useWritebackSettingsConfig';

export interface UseWritebackWidgetProvidersProps {
  submission: FormsSubmission.Types.NormalizedSubmissionDetailResponse;
  submissionId: string;
  locationId: string;
  modalControls: ModalControlResponse;
  approveOrRejectMode: ApproveOrRejectMode;
  resetApproveOrRejectMode: () => void;
}

export type UseWritebackWidgetProvidersResults = UseWritebackWidgetProvidersProps;

export const useWritebackWidgetProviders = ({
  locationId,
  modalControls,
  submission,
  submissionId,
  approveOrRejectMode,
  resetApproveOrRejectMode,
}: UseWritebackWidgetProvidersProps): UseWritebackWidgetProvidersResults => {
  const results = useMemo<UseWritebackWidgetProvidersResults>(
    () => ({
      locationId,
      modalControls,
      submission,
      submissionId,
      approveOrRejectMode,
      resetApproveOrRejectMode,
    }),
    [locationId, modalControls, submission, submissionId, approveOrRejectMode, resetApproveOrRejectMode]
  );

  useProviderReviewConfig({ approveOrRejectMode: results.approveOrRejectMode });

  useWritebackDestinationConfig({
    submission: results.submission,
    locationId: results.locationId,
  });

  useWritebackSettingsConfig({
    submission: results.submission,
    locationId: results.locationId,
  });

  return results;
};
