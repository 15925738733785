import { useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useCallDuration } from '../../hooks/use-call-duration';
import { useSoftphoneCallState } from '../../providers/softphone-call-state-provider';
import { useSoftphoneCallActions } from '../../providers/softphone-call-state-provider/softphone-call-actions.provider';
import { useSoftphoneWidgetControl } from '../../providers/widget-control-provider';
import { Row } from '../generic/row/row';

export const MergedCallGroupHeldCall = () => {
  const { t } = useTranslation('softphone');
  const mergedCallGroupIds = useSoftphoneCallState((ctx) => ctx.mergedCallIds);
  const [isHoverActive, setIsHoverActive] = useState(false);
  const establishedCalls = useSoftphoneCallState((ctx) => ctx.establishedCalls);
  const heldMergedCallGroupCall = establishedCalls.filter(
    (call) => !!call.onHoldSince && !!mergedCallGroupIds?.includes(call.id)
  );
  const duration = useCallDuration(heldMergedCallGroupCall?.[0]?.establishedAt);
  const isMobile = useSoftphoneWidgetControl((ctx) => ctx.isMobile);
  const { toggleHoldMergedCalls } = useSoftphoneCallActions((ctx) => ctx);

  return (
    <Row
      css={css`
        margin-bottom: ${theme.spacing(1)};
      `}
      onClick={() => toggleHoldMergedCalls(false)}
      onMouseLeave={() => setIsHoverActive(false)}
      onMouseEnter={() => setIsHoverActive(true)}
    >
      <Row.StatusDot status='warning' />
      <Row.Title title={'Group Call'} />
      {!isHoverActive && <Row.Subtitle subtitle={duration ?? ''} />}
      {(isHoverActive || isMobile) && (
        <Row.Subtitle
          css={css`
            margin-left: auto;
          `}
          subtitle={t('Swap Call')}
        />
      )}
    </Row>
  );
};
