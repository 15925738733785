import { createContext, useContext, useState } from 'react';
import { PhoneOverride } from '@weave/schema-gen-ts/dist/schemas/phone/override/v1/override.pb';
import { DepartmentsApi, DepartmentsTypes } from '@frontend/api-departments';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { OverrideApi } from '@frontend/api-overrides';
import { useTranslation } from '@frontend/i18n';
import { useQuery } from '@frontend/react-query-helpers';
import { PickerLocation } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Accordion, Chip, useModalControl, SpinningLoader, SearchField, useFormField } from '@frontend/design-system';
import { EmptyOverrideSection } from '../../components/override/override-setting';
import { NewOverrideSettingTray } from '../../components/override/override-settings-modal.new';
import { NewOverrideSettingRemoveModal } from '../../components/override/override-settings-remove-modal.new';
import { OverrideSettingContent } from '../../components/override/override-settings.new';
import { PhoneSettingsLocationPicker } from '../../components/settings/location-picker';
import { PhoneSettingsPage } from '../../components/settings/settings-page';
import { queryKeys } from '../../query-keys';
import { usePhoneSettingsShallowStore } from '../../store/settings';
import { locationSectionStyle } from './overrides-styles';

type OverrideData = {
  override?: PhoneOverride;
  department?: DepartmentsTypes.DepartmentModel;
  locationId?: string;
};
type OverrideHandlerContextType = {
  openSettingTray: () => void;
  openRemoveModal: () => void;
  setOverrideData: (overrideData: OverrideData | null) => void;
};
const OverrideHandlerContext = createContext<OverrideHandlerContextType>({
  openSettingTray: () => {},
  openRemoveModal: () => {},
  setOverrideData: () => {},
});

export const OverrideSettings = () => {
  const { t } = useTranslation('phone');
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const [overrideData, setOverrideData] = useState<OverrideData | null>(null);

  const locations =
    settingsTenantLocation?.children && settingsTenantLocation.children.length > 0
      ? settingsTenantLocation?.children
      : [settingsTenantLocation].filter(Boolean);

  const locationIds = locations.map((loc: { locationID: string }) => loc.locationID);

  const { data: overrides = [] } = useQuery({
    queryKey: [locationIds, ...queryKeys.overrideList()],
    queryFn: async () => {
      const listOverridesResponse = await OverrideApi.listOverride(
        { locationIds: locationIds },
        { locationId: locationIds[0] }
      );
      return listOverridesResponse.phoneOverrides ?? [];
    },
  });

  const removeOverride = useModalControl();
  const sideModal = useModalControl();

  return (
    <PhoneSettingsPage
      title={t('Overrides')}
      subtitle={t(
        'Overrides enable you to quickly and easily reroute your call configuration to secure phone coverage during breaks and holidays.'
      )}
      breadcrumbs={[
        { label: t('Phone'), to: '/phone/main' },
        { label: t('Override'), to: '/phone/overrides' },
      ]}
      renderContainer={false}
      maxWidth={700}
    >
      <OverrideHandlerContext.Provider
        value={{ openSettingTray: sideModal.openModal, openRemoveModal: removeOverride.openModal, setOverrideData }}
      >
        {settingsTenantLocation?.locationType === 'unify' && settingsTenantLocation?.children?.length ? (
          <MultiLocationView tenantLocation={settingsTenantLocation} locations={locations} overrides={overrides} />
        ) : (
          <SingleLocationScopedOverrides locationId={locationIds[0]} overrides={overrides} />
        )}
      </OverrideHandlerContext.Provider>

      <NewOverrideSettingTray
        override={overrideData?.override}
        modalControls={sideModal}
        key={overrideData?.override?.id}
        locationId={overrideData?.locationId}
        departmentId={overrideData?.department?.id}
        departmentName={overrideData?.department?.name}
      />

      {overrideData?.override && (
        <NewOverrideSettingRemoveModal
          override={overrideData.override}
          department={overrideData.department}
          modalProps={removeOverride.modalProps}
        />
      )}
    </PhoneSettingsPage>
  );
};

const MultiLocationView = ({
  tenantLocation,
  locations,
  overrides,
}: {
  tenantLocation: PickerLocation;
  locations: PickerLocation[];
  overrides: PhoneOverride[];
}) => {
  const { t } = useTranslation('phone');
  const [selectedLocations, setSelectedLocations] = useState<string[]>([]);
  const searchField = useFormField({ type: 'text' });
  const filteredLocations = locations.filter(
    (location) =>
      selectedLocations.includes(location.locationID) &&
      location.name?.toLowerCase().includes(searchField.value.toLowerCase())
  );

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
      <div css={locationSectionStyle}>
        <SearchField
          {...searchField}
          label={t('Search Locations')}
          name='location-search'
          css={{ background: theme.colors.white }}
        />
        <PhoneSettingsLocationPicker
          filteredLocations={selectedLocations}
          tenantLocation={tenantLocation}
          setFilteredLocations={setSelectedLocations}
        />
      </div>
      <MultiLocationScopedOverrides locations={filteredLocations} overrides={overrides} />
    </div>
  );
};

const MultiLocationScopedOverrides = ({
  locations,
  overrides,
}: {
  locations: PickerLocation[];
  overrides: PhoneOverride[];
}) => {
  const locationScopedOverrides = overrides
    ? locations.reduce((acc, location) => {
        const locationOverride = overrides?.find((override) => override.locationId === location.locationID);
        if (locationOverride) {
          return {
            ...acc,
            [location.locationID]: locationOverride,
          };
        }

        return acc;
      }, {} as Record<string, PhoneOverride>)
    : {};

  const startingValue = locations.map((location) => location.locationID);
  const accordionKey = startingValue.join('-');

  return (
    <Accordion variant='location' isMulti key={accordionKey} startingValue={startingValue}>
      {locations.map((location) => {
        return (
          <MultiLocationOverrideContainer
            key={location.locationID}
            location={location}
            override={locationScopedOverrides[location.locationID]}
          />
        );
      })}
    </Accordion>
  );
};

const SingleLocationScopedOverrides = ({
  locationId,
  overrides,
}: {
  locationId: string;
  overrides: PhoneOverride[];
}) => {
  const { data: departments, isLoading } = useQuery({
    queryKey: [locationId, ...queryKeys.listDepartments()],
    queryFn: () => DepartmentsApi.listDept({ locationId }),
    select: (data) => data.departments,
  });

  const { aggregateValue: departmentsFlag } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'departments',
    locationIds: [locationId],
  });

  // Even with the departments feature flag off, queries may return mainline departments.
  // Therefore, check the feature flag state to correctly identify non-department locations.
  if (departments?.length === 0 || !departmentsFlag) {
    return <LocationOverrideContainer override={overrides[0]} locationId={locationId} />;
  }

  if (isLoading) {
    return <SpinningLoader />;
  }

  return <DepartmentOverrideContainer departments={departments ?? []} locationId={locationId} overrides={overrides} />;
};

const LocationOverrideContainer = ({ override, locationId }: { override: PhoneOverride; locationId: string }) => {
  const { setOverrideData } = useContext(OverrideHandlerContext);

  return (
    <section
      onFocus={() => setOverrideData({ override, locationId })}
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: theme.spacing(3),
        background: theme.colors.white,
        boxShadow: theme.shadows.light,
        border: `1px solid ${theme.colors.neutral20}`,
        borderRadius: theme.borderRadius.medium,
      }}
    >
      {override ? <SettingContent override={override} /> : <EmptyOverrideSetting locationId={locationId} />}
    </section>
  );
};

const DepartmentOverrideContainer = ({
  departments,
  locationId,
  overrides,
}: {
  departments: DepartmentsTypes.DepartmentModel[];
  locationId: string;
  overrides: PhoneOverride[];
}) => {
  const departmentScopedOverrides = overrides
    ? departments?.reduce((acc, dept) => {
        const deptOverride = overrides?.find((override) => override.departmentId === dept.id);
        if (deptOverride) {
          return {
            ...acc,
            [dept.id!]: deptOverride,
          };
        }

        return acc;
      }, {} as Record<string, PhoneOverride>)
    : {};

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
      {departments?.map((dept) => (
        <DepartmentOverrideSetting
          key={dept.id}
          locationId={locationId}
          override={departmentScopedOverrides?.[dept.id!]}
          department={dept}
        />
      ))}
    </div>
  );
};

const MultiLocationOverrideContainer = ({
  location,
  override,
}: {
  location: PickerLocation;
  override: PhoneOverride;
}) => {
  return (
    <Accordion.Item title={location.name} value={location.locationID}>
      <Accordion.Header>
        <Accordion.Header.Location title={location.name ?? ''} />
      </Accordion.Header>
      <Accordion.Body css={{ padding: 0 }}>
        {override ? (
          <MultiLocationOverrideSetting key={override.id} override={override} />
        ) : (
          <section
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(2),
              padding: theme.spacing(3),
              ':not(:last-child)': {
                borderBottom: `1px solid ${theme.colors.neutral20}`,
              },
            }}
          >
            <EmptyOverrideSetting locationId={location.locationID} />
          </section>
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

const EmptyOverrideSetting = ({
  department,
  locationId,
}: {
  department?: DepartmentsTypes.DepartmentModel;
  locationId: string;
}) => {
  const { openSettingTray, setOverrideData } = useContext(OverrideHandlerContext);

  return (
    <section
      onFocus={() =>
        setOverrideData({
          department,
          locationId,
        })
      }
    >
      <EmptyOverrideSection openSettingTray={openSettingTray} />
    </section>
  );
};

const DepartmentOverrideSetting = ({
  department,
  locationId,
  override,
}: {
  department: DepartmentsTypes.DepartmentModel;
  locationId: string;
  override?: PhoneOverride;
}) => {
  const { setOverrideData } = useContext(OverrideHandlerContext);

  return (
    <section
      onFocus={() => setOverrideData({ override, department, locationId })}
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: theme.spacing(3),
        background: theme.colors.white,
        boxShadow: theme.shadows.light,
        border: `1px solid ${theme.colors.neutral20}`,
        borderRadius: theme.borderRadius.medium,
      }}
    >
      <div style={{ display: 'flex', gap: theme.spacing(1) }}>
        <Chip.Department css={{ maxWidth: '115px' }}>{department.name}</Chip.Department>
      </div>
      {override ? (
        <SettingContent override={override} />
      ) : (
        <EmptyOverrideSetting department={department} locationId={locationId} />
      )}
    </section>
  );
};

const MultiLocationOverrideSetting = ({ override }: { override: PhoneOverride }) => {
  const { setOverrideData } = useContext(OverrideHandlerContext);

  return (
    <section
      onFocus={() => setOverrideData({ override, locationId: override.locationId })}
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: theme.spacing(3),
        ':not(:last-child)': {
          borderBottom: `1px solid ${theme.colors.neutral20}`,
        },
      }}
    >
      <SettingContent override={override} />
    </section>
  );
};

const SettingContent = ({ override }: { override: PhoneOverride }) => {
  const { openSettingTray, openRemoveModal } = useContext(OverrideHandlerContext);

  return (
    <OverrideSettingContent override={override} openSettingTray={openSettingTray} openRemoveModal={openRemoveModal} />
  );
};
