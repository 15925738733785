import { css } from '@emotion/react';
import { AnalyticsCommonTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { StatusIcon } from '../status-icon';

type Props = {
  isFailedCall?: boolean;
  isPoorAudio?: boolean;
  isSkippedCall?: boolean;
  type: 'analysis' | 'summary' | 'task';
};

type ContentProps = {
  message: string;
  statusIcon: AnalyticsCommonTypes.StatusIcon;
};

const RenderContent = ({ message, statusIcon }: ContentProps) => (
  <div css={styles}>
    <StatusIcon statusIcon={statusIcon} />
    <Text color='subdued' size='small'>
      {message}
    </Text>
  </div>
);

export const CallAnalyzeStatus = ({ isFailedCall, isPoorAudio, isSkippedCall, type }: Props) => {
  const { t } = useTranslation('analytics');
  const isSummaryData = type === 'summary';
  const isTaskType = type === 'task';

  if (isFailedCall) {
    return (
      <RenderContent
        message={
          isTaskType
            ? t('We are unable to analyze this call for Tasks.')
            : isSummaryData
            ? t('We are unable to summarize and transcribe this call.')
            : t('We are unable to analyze this call.')
        }
        statusIcon='error'
      />
    );
  }

  if (isSkippedCall && isPoorAudio) {
    return (
      <RenderContent
        message={
          isTaskType
            ? t('Tasks may be inaccurate due to poor audio quality. Please review the recording.')
            : isSummaryData
            ? t('This summary and transcript may be inaccurate due to poor audio quality. Please review the recording.')
            : t("We didn't analyze this call because the short audio length resulted in inaccurate outputs.")
        }
        statusIcon={isSummaryData ? 'warning' : 'undetermined'}
      />
    );
  }

  if (isSkippedCall) {
    return isSummaryData ? null : (
      <RenderContent
        message={t("We didn't analyze this call because the short audio length resulted in inaccurate outputs.")}
        statusIcon='undetermined'
      />
    );
  }

  if (isPoorAudio) {
    return (
      <RenderContent
        message={
          isTaskType
            ? t('Tasks may be inaccurate due to poor audio quality. Please review the recording.')
            : isSummaryData
            ? t('This summary and transcript may be inaccurate due to poor audio quality. Please review the recording.')
            : t('This analysis may be inaccurate due to poor audio quality. Please review the recording.')
        }
        statusIcon='warning'
      />
    );
  }

  return null;
};

const styles = css`
  align-items: center;
  display: flex;
  gap: ${theme.spacing(1)};
  margin-bottom: ${theme.spacing(4)};
`;
