import { GetWeavePopNotificationByType } from '@frontend/types';
import { useCommonActionSubscriptions } from './actions/use-common-actions-subscriptions';
import { useNotificationActionSubscription } from './actions/use-notification-action-subscription';
import { useNotification } from './use-notification';

type Props = {
  onView?: (payload: GetWeavePopNotificationByType<'sms-tag'>) => void;
};

export const useSmsTagNotification = ({ onView }: Props = {}) => {
  useCommonActionSubscriptions('sms-tag');
  const { create, remove } = useNotification<'sms-tag'>();
  useNotificationActionSubscription('sms-tag', 'ignore', (e) => {
    remove(e.notification.id);
  });

  useNotificationActionSubscription('sms-tag', 'timed-out', (e) => {
    remove(e.notification.id);
  });

  useNotificationActionSubscription(
    'sms-tag',
    'view',
    ({ notification }) => {
      onView?.(notification);
      remove(notification.id);
    },
    [onView]
  );

  return {
    create,
    remove,
  };
};
