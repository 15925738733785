import { useState } from 'react';
import { useTranslation } from '@frontend/i18n';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { PrimaryButton, usePopoverDialog, PopoverDialog } from '@frontend/design-system';
import { useInboxFiltersShallowStore, inboxFiltersAreEqual, DEFAULT_INBOX_FILTER_VALUE } from '../../stores';
import { HiddenFilterKeys, InboxArrayFilters, InboxFilter, InboxFilters, InboxStatus } from '../../types';
import { DateFilters } from './date-filters';
import { InboxFilterPopoverMenuItem } from './inbox-filter-popover-menu-item';
import { InboxFiltersPopoverHeadingItem } from './inbox-filters-popover-heading-item';
import { TimeFilters } from './time-filters';
import { DateTimeSelectionHandlerKeys } from './types';

type InboxFiltersPopoverProps = Pick<
  ReturnType<typeof usePopoverDialog<HTMLButtonElement>>,
  'getDialogProps' | 'close'
> & {
  groupIds?: string[];
  hideFilters?: HiddenFilterKeys;
};

export const InboxFiltersPopover = ({ getDialogProps, close, groupIds, hideFilters }: InboxFiltersPopoverProps) => {
  const { t } = useTranslation('inbox');
  const { inboxFilters, setInboxFilters } = useInboxFiltersShallowStore('inboxFilters', 'setInboxFilters');
  const shownInboxFilters = Object.fromEntries(
    Object.entries(inboxFilters).filter(([key]) => !hideFilters?.includes(key as InboxFilter))
  ) as Partial<InboxFilters>;
  const [newSelection, setNewSelection] = useState<typeof shownInboxFilters>();
  const currentSelection = newSelection ?? shownInboxFilters;
  const hasChangedSelection = !!newSelection && !inboxFiltersAreEqual(currentSelection, shownInboxFilters);

  const showDateOrTimeFilters = ['date', 'time'].filter((filter) => !hideFilters?.includes(filter as InboxFilter));
  const shownFiltersOrder: InboxArrayFilters[] = ['tags', 'departments'].filter(
    (filter) => !hideFilters?.includes(filter as InboxArrayFilters)
  ) as InboxArrayFilters[];

  const handleToggle = <K extends InboxFilter>(key: K, values: InboxFilters[K]) => {
    setNewSelection((prev) => {
      const currentValues = prev ?? shownInboxFilters;
      const prevValues = currentValues[key];
      if (!Array.isArray(prevValues)) return currentValues; // for TS safety
      const { toRemove, toAdd } = (values as string[]).reduce<{ toRemove: string[]; toAdd: string[] }>(
        (acc, value) => {
          if (prevValues?.includes(value as InboxStatus)) {
            acc.toRemove.push(value);
          } else {
            acc.toAdd.push(value);
          }
          return acc;
        },
        { toRemove: [], toAdd: [] }
      ) as { toRemove: InboxFilters[K]; toAdd: InboxFilters[K] };
      return {
        ...currentValues,
        [key]: prevValues ? prevValues.filter((v) => !toRemove.includes(v as InboxStatus)).concat(toAdd) : toAdd,
      };
    });
  };

  const handleApply = () => {
    setInboxFilters({ ...inboxFilters, ...newSelection });
    setNewSelection(undefined);
    close();
  };

  const handleClear = (filter?: string) => {
    setNewSelection((oldSelection) => {
      const oldValues = oldSelection || currentSelection;

      let valueToUpdate;
      if (filter === 'date') {
        valueToUpdate = { startDate: '', endDate: '' };
      } else if (filter === 'time') {
        valueToUpdate = { startTime: '', endTime: '' };
      } else {
        const { startDate, endDate, startTime, endTime, ...rest } = DEFAULT_INBOX_FILTER_VALUE;
        valueToUpdate = { ...rest };
      }

      return { ...oldValues, ...valueToUpdate };
    });
  };

  const handleDateTimeSelection = (key: DateTimeSelectionHandlerKeys, value: string) => {
    setNewSelection((prev) => {
      const currentValues = prev ?? shownInboxFilters;
      return {
        ...currentValues,
        [key]: value,
      };
    });
  };

  return (
    <PopoverDialog
      {...getDialogProps()}
      css={{
        width: showDateOrTimeFilters.length ? 300 : 240,
        padding: theme.spacing(1, 0),
        borderRadius: theme.borderRadius.medium,
      }}
    >
      {!!showDateOrTimeFilters.includes('date') && (
        <>
          <InboxFiltersPopoverHeadingItem
            label={t('Scheduled Date')}
            handleClear={() => handleClear('date')}
            hasSelection={!!(currentSelection?.startDate || currentSelection?.endDate)}
            clearButtonId='date-clear-button'
            trackingId={`${InboxPrefixes.List}-filter-popover-date-clear`}
          />
          <div css={{ paddingTop: theme.spacing(1) }}>
            <DateFilters
              onDateTimeSelection={handleDateTimeSelection}
              initialValue={{
                startDate: currentSelection?.startDate ?? '',
                endDate: currentSelection?.endDate ?? '',
              }}
            />
          </div>
        </>
      )}
      {!!showDateOrTimeFilters.includes('time') && (
        <>
          <InboxFiltersPopoverHeadingItem
            label={t('Send Time')}
            handleClear={() => handleClear('time')}
            hasSelection={!!(currentSelection?.startTime || currentSelection?.endTime)}
            clearButtonId='time-clear-button'
            trackingId={`${InboxPrefixes.List}-filter-popover-time-clear`}
          />
          <div css={{ padding: theme.spacing(1, 0, 2, 0), borderBottom: `1px solid ${theme.colors.neutral20}` }}>
            <TimeFilters
              onDateTimeSelection={handleDateTimeSelection}
              initialValue={{
                startTime: currentSelection?.startTime ?? '',
                endTime: currentSelection?.endTime ?? '',
              }}
            />
          </div>
        </>
      )}
      <InboxFiltersPopoverHeadingItem
        label={!!showDateOrTimeFilters.length ? t('Other Filters') : t('Filter Inbox')}
        handleClear={handleClear}
        hasSelection={!!shownFiltersOrder.find((filter) => !!currentSelection[filter]?.length)}
        clearButtonId='clear-inbox-filters-btn'
        largeLabel={!showDateOrTimeFilters.length}
        trackingId={`${InboxPrefixes.List}-filter-popover-clear`}
      />
      {shownFiltersOrder.map((filter, index) => (
        <InboxFilterPopoverMenuItem
          filter={filter}
          key={filter + index}
          selectedItems={currentSelection[filter] ?? []}
          toggleItems={(values) => handleToggle(filter, values)}
          divider={index > 0 ? 'top' : undefined}
          groupIds={groupIds}
          closeMenu={close}
        />
      ))}
      <div
        css={{
          padding: theme.spacing(1, 2),
        }}
      >
        <PrimaryButton
          disabled={!hasChangedSelection}
          onClick={handleApply}
          trackingId={`${InboxPrefixes.List}-filter-popover-apply`}
        >
          {t('Apply')}
        </PrimaryButton>
      </div>
    </PopoverDialog>
  );
};
