import { css } from '@emotion/react';
import { DialApi, DialpadTypes } from '@frontend/api-dialpad';
import { Button, DialerInput, Divider, KeypadButtons, Page } from '@frontend/generic-dialpad-accessories';
import { useTranslation } from '@frontend/i18n';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import { removeNonDigits } from '@frontend/phone-numbers';
import { useMutation } from '@frontend/react-query-helpers';
import { theme } from '@frontend/theme';
import { IconButton, Text, useAlert, XIcon } from '@frontend/design-system';
import { useDialer } from '../hooks/use-dialer';
import { useDialpadDialer } from '../providers/dialpad-dialer-provider';
import { useDialpadManagerClient } from '../providers/dialpad-manager-provider';
import { useDialpadWidgetControl } from '../providers/dialpad-widget-control-provider';
import { DeviceInformationWrapper } from './device-information-wrap';
import { DialpadInfoRow } from './dialpad-info-row';

export const DialpadPage = () => {
  const alerts = useAlert();
  const { t } = useTranslation('dialpad');

  const currentDial = useDialpadDialer((ctx) => ctx.currentDial);
  const setCurrentDial = useDialpadDialer((ctx) => ctx.setCurrentDial);

  const close = useDialpadWidgetControl((ctx) => ctx.close);
  const currentOutboundPhoneNumber = useDialpadManagerClient((ctx) => ctx.currentOutboundPhoneNumber);
  const { phoneConfig } = usePhoneConfigShallowStore('phoneConfig');

  const { mutate: makeACall } = useMutation({
    mutationFn: (req: DialpadTypes.Dial['input']) => DialApi.dialUser(req),
    onSuccess: () => {
      alerts.success(t(`Call sent to desk phone.`));
    },
  });

  const handleCall = () => {
    const toNumber = removeNonDigits(currentDial);
    makeACall({
      fromName: currentOutboundPhoneNumber?.locationName ?? '',
      fromNumber: currentOutboundPhoneNumber?.number ?? '',
      toNumber,
      sipProfileId: phoneConfig?.sipProfileId ?? '',
    });
    return Promise.resolve();
  };

  const placeCall = (_direct?: string) => {
    return handleCall();
  };

  const dialer = useDialer({
    onSubmit: placeCall,
  });

  const dialerInputProps = {
    currentDial,
    setCurrentDial,
    isDirectoryLoading: false,
    ...dialer,
  };

  return (
    <Page>
      <Page.Header
        mode='widget'
        locationName={currentOutboundPhoneNumber?.locationName ?? ''}
        css={css`
          display: flex;
          justify-content: space-between;
        `}
      >
        <section css={{ paddingTop: '8px' }}>
          <Text size='medium'> {t('New Call')}</Text>
          <DeviceInformationWrapper hideExtension />
        </section>
        <IconButton
          trackingId={'dialpad-modal-close-btn'}
          label='close'
          onClick={close}
          css={{ alignSelf: 'baseline', padding: 0 }}
        >
          <XIcon color='default' />
        </IconButton>
      </Page.Header>
      <Divider customStyles={{ border: `0.5px solid ${theme.colors.neutral20}`, margin: theme.spacing(1, 0, 2, 0) }} />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          placeCall();
        }}
      >
        <DialerInput
          customStyles={dialerInputStyles}
          focusOnMount={false}
          placeholderLabel={t('Dial Number')}
          {...dialerInputProps}
        ></DialerInput>
        <KeypadButtons
          onDialCharClick={(char) => dialer.dispatch({ type: 'add', payload: `${char}` })}
          invertedButtonStyles
        />
        <Button.Round
          trackingId='dialpad-modal-greencall'
          disabled={!currentDial}
          iconName='phone-small'
          iconSize={24}
          css={css`
            margin: auto;
          `}
          type='submit'
          color='green'
          useDisabledColor={true}
        ></Button.Round>
      </form>
      <Divider customStyles={{ border: `0.5px solid ${theme.colors.neutral20}`, margin: theme.spacing(2, 0) }} />
      <DialpadInfoRow />
    </Page>
  );
};

const dialerInputStyles = css`
  margin: auto;
  display: block;
  text-align: left;
  width: 90%;
  input {
    text-align: center;
    width: 100%;
    height: ${theme.spacing(6)};
    padding: ${theme.spacing(1.5)} ${theme.spacing(3)};
    box-sizing: border-box;
    box-shadow: none;
    border-radius: ${theme.borderRadius.small};
    outline: none;
    font-size: ${theme.fontSize(20)};
    font-weight: ${theme.font.weight.bold};
    color: ${theme.colors.text.default};
    margin-top: 2px;
    border: 1px solid ${theme.colors.neutral30};
    &:focus {
      border: 1px solid ${theme.colors.primary50};
      box-shadow: none;
    }
    &[aria-invalid='true'] {
      border: 1px solid ${theme.colors.critical30}; //the theme critical color doesn't show up well on dark
    }
    &.large {
      font-size: 1.8em;
    }
    &.medium {
      font-size: 1.5em;
    }
    &.small {
      font-size: 1.2em;
    }
    &::placeholder {
      color: ${theme.colors.neutral50};
      font-weight: bold;
    }
  }
  .input-wrap {
    position: relative;
    .x-icon {
      box-shadow: none;
      position: absolute;
      right: ${theme.spacing(1.25)};
      top: ${theme.spacing(2.25)};
      padding: 1px;
      cursor: pointer;
      color: ${theme.colors.neutral20};
      &:hover {
        color: ${theme.colors.white};
      }
    }
  }
  .error-message {
    color: ${theme.colors.critical30};
    margin-top: ${theme.spacing(0.5)};
    font-size: ${theme.fontSize(12)};
  }
`;
