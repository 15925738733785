import { memo } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { CallIntelligenceTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { theme } from '@frontend/theme';
import { Accordion, NakedUl, Text } from '@frontend/design-system';
import { callIntelligenceUtils } from '../../utils';
import { LocationChip } from '../location-chip';
import { CallIntelMockData } from './demo-data';
import { useCallIntelLocations } from './hooks';
import { OfficeUserName } from '.';

type Props = {
  call: CallIntelligenceTypes.Call;
  isDemoAccount?: boolean;
};

const demoLocations = CallIntelMockData.dummyLocationNames();

export const CallDetails = memo(({ call, isDemoAccount }: Props) => {
  const { locationId = '', officeNumber, officeUser, startTime } = call || {};
  const isDataUnavailable = callIntelligenceUtils.isOfficeUserDataUnavailable(startTime);

  const { t } = useTranslation('analytics');
  const { isMultiLocation, locations } = useCallIntelLocations({
    demoLocations: isDemoAccount ? demoLocations : undefined,
  });

  return (
    <>
      <Accordion.Header title={t('Call Details')} />
      <Accordion.Body>
        <NakedUl css={styles.list}>
          {isMultiLocation && (
            <li>
              <Text className='data-label'>{t('Location')}</Text>
              <LocationChip locationName={locations[locationId] || locationId} maxWidth={300} />
            </li>
          )}
          <li>
            <Text className='data-label'>{t('Time')}</Text>
            <Text>{dayjs(startTime).format('MMM D, YYYY, h:mm A')}</Text>
          </li>
          <li>
            <Text className='data-label'>{t('Office User')}</Text>
            <OfficeUserName
              {...officeUser}
              isDataUnavailable={isDataUnavailable}
              isInactive={callIntelligenceUtils.isUserInactive(officeUser)}
              isWeaveUser
              showAvatar
              textAlign='right'
            />
          </li>
          <li>
            <Text className='data-label'>{t('Office Number')}</Text>
            <Text style={isDataUnavailable ? { color: theme.colors.neutral50 } : {}}>
              {isDataUnavailable
                ? t('Unavailable Data')
                : officeNumber?.nationalNumber
                ? formatPhoneNumber(officeNumber.nationalNumber)
                : '-'}
            </Text>
          </li>
        </NakedUl>
      </Accordion.Body>
    </>
  );
});

CallDetails.displayName = 'CallDetails';

const styles = {
  list: css`
    li {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: ${theme.spacing(2)};

      .data-label {
        color: ${theme.colors.neutral50};
        text-wrap: nowrap;
      }
    }

    li:last-child {
      margin-bottom: ${theme.spacing(0)};
    }
  `,
};
