import { memo, useCallback, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { gql } from 'graphql-request';
import { PracticeAnalyticsAggregations, PracticeAnalyticsApi, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { Chart, CustomLegendsData, FormatValue, PieChartData } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { useAlert } from '@frontend/design-system';
import { usePaNavigate } from '../../hooks';
import { queryKeys } from '../../query-keys';
import { trackingIds } from '../../tracking-ids';
import { formatters } from '../../utils';
import { CompareLocationsButton } from '../compare-locations-button';
import { DemoChip } from '../demo-chip';
import { PracticeAnalyticsURLs } from './constants';
import { getPracticeAnalyticsQueryVariables } from './helpers';
import { usePracticeAnalyticsShallowStore } from './hooks';

type ChartData = {
  acceptedPercent: PieChartData;
  diagnosedPercent: PieChartData;
  unscheduledTreatment: PieChartData;
};

type RenderChart = {
  commonTooltipLabel?: string;
  customLegends: CustomLegendsData;
  data?: PieChartData;
  formatter: FormatValue;
  tooltipTitle?: string;
};

const totals = `
  accepted
  acceptedPercent
  diagnosed
  diagnosedPercent
  qualifiedVisits
  unscheduledTreatment
`;

const query = gql`
  query ($start: Int!, $end: Int!, $step: TimeStep!) {
    location {
      hygieneTreatmentPlan: treatmentPlan(treatmentPlanType: HYGIENE) {
        totals(start: $start, end: $end, step: $step) {
          ${totals}
        }
      }
      restorativeTreatmentPlan: treatmentPlan(treatmentPlanType: RESTORATIVE) {
        totals(start: $start, end: $end, step: $step) {
          ${totals}
        }
      }
    }
  }
`;

const colors = {
  accepted: theme.colors.primary20,
  diagnosed: theme.colors.secondary.seaweed30,
  hygiene: theme.colors.warning50,
  restorative: theme.colors.secondary.eggplant30,
  skipTipRemaining: theme.colors.neutral20,
};

const customLegendsIds = Object.keys(colors);

const defaultChartData: PieChartData = {
  groups: [],
};

export const PractitionerAnalysisMetric = memo(
  ({ clickNoop, onFetchStateChange }: PracticeAnalyticsTypes.MetricProps) => {
    const { t } = useTranslation('analytics');
    const alert = useAlert();
    const navigate = usePaNavigate();
    const { demoData, filters, isDemoAccount, showDemoChipAndBanner } = usePracticeAnalyticsShallowStore(
      'demoData',
      'filters',
      'isDemoAccount',
      'showDemoChipAndBanner'
    );

    const multipleLocationsSelected = (filters.locations?.length || 0) > 1;

    const { data, isLoading } = useScopedQuery({
      queryKey: queryKeys.practiceAnalyticsCharts(
        `practitioner-analysis-overview-${isDemoAccount}-${JSON.stringify(filters)}`
      ),
      queryFn: () =>
        isDemoAccount || !filters.locations?.length
          ? null
          : PracticeAnalyticsApi.getPracticeAnalyticsRecords<PracticeAnalyticsTypes.PractitionerAnalysisResponse>({
              locationIds: filters.locations,
              queries: [query],
              variables: getPracticeAnalyticsQueryVariables(filters),
            }),
      onError: () => {
        alert.error(t("Couldn't load the dashboard data. Please try again."));
      },
      select: (data) => (isDemoAccount ? demoData?.practitionerAnalysisSummary : data),
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    });

    const chartData: ChartData | null = useMemo(() => {
      const { aggregatedData } = PracticeAnalyticsAggregations.pratitionerAnalysisSummary(data);

      if (!aggregatedData) {
        return null;
      }

      const { hygieneTreatmentPlan, restorativeTreatmentPlan } = aggregatedData;

      const acceptedPercent = Math.round(
        ((hygieneTreatmentPlan.totals.accepted + restorativeTreatmentPlan.totals.accepted) /
          (hygieneTreatmentPlan.totals.diagnosed + restorativeTreatmentPlan.totals.diagnosed)) *
          100
      );
      const averageDiagnosed = Math.round(
        ((hygieneTreatmentPlan.totals.diagnosedPercent + restorativeTreatmentPlan.totals.diagnosedPercent) / 2) * 100
      );
      const totalUnscheduledTreatment =
        hygieneTreatmentPlan.totals.unscheduledTreatment + restorativeTreatmentPlan.totals.unscheduledTreatment;

      return {
        acceptedPercent: {
          centerMetric: formatters.percent.appendPercent(acceptedPercent),
          groups: [
            {
              name: 'accepted',
              value: acceptedPercent,
            },
            {
              name: 'skipTipRemaining',
              value: 100 - acceptedPercent,
            },
          ],
        },
        diagnosedPercent: {
          centerMetric: formatters.percent.appendPercent(averageDiagnosed),
          groups: [
            {
              name: 'diagnosed',
              value: averageDiagnosed,
            },
            {
              name: 'skipTipRemaining',
              value: 100 - averageDiagnosed,
            },
          ],
        },
        unscheduledTreatment: {
          centerMetric: `$${formatters.value.shortenNumber(totalUnscheduledTreatment)}`,
          groups: [
            {
              name: 'hygiene',
              value: hygieneTreatmentPlan.totals.unscheduledTreatment,
            },
            {
              name: 'restorative',
              value: restorativeTreatmentPlan.totals.unscheduledTreatment,
            },
          ],
        },
      };
    }, [data]);

    const labels = useMemo(
      () => ({
        accepted: t('Patients Accepted'),
        diagnosed: t('Patients Diagnosed '),
        hygiene: t('Hygiene unscheduled treatment'),
        restorative: t('Restorative unscheduled treatment'),
      }),
      []
    );

    const renderChart = useCallback(
      ({ commonTooltipLabel, customLegends, data, formatter, tooltipTitle }: RenderChart) => {
        return (
          <div css={styles.chartWrapper}>
            <Chart.PieChart
              appearance={{
                collectiveTooltip: true,
                customTooltipTitle: () => tooltipTitle,
              }}
              commonTooltipLabel={commonTooltipLabel}
              customLegendsIds={customLegendsIds}
              data={data ?? defaultChartData}
              formatValue={formatter}
            />
            <Chart.Legends customData={customLegends} layout='vertical' />
          </div>
        );
      },
      []
    );

    useEffect(() => {
      onFetchStateChange?.(isLoading);
    }, [isLoading]);

    return (
      <Chart
        colors={colors}
        isLoading={isLoading}
        labels={labels}
        onClick={
          clickNoop
            ? undefined
            : () => {
                navigate({
                  to: `${PracticeAnalyticsURLs.BASE}/practitioner-analysis`,
                });
              }
        }
        trackingId={trackingIds.practiceAnalytics.practitionerAnalysisChart}
      >
        <Chart.Header
          bottomElement={multipleLocationsSelected ? <CompareLocationsButton /> : null}
          leftElement={showDemoChipAndBanner ? <DemoChip /> : null}
          title={t('Practitioner Analysis')}
        />
        <Chart.HorizontalContainer style={{ marginBottom: 0 }}>
          {renderChart({
            commonTooltipLabel: t('Patients'),
            customLegends: {
              diagnosed: {
                label: t('Patients Diagnosed'),
              },
            },
            data: chartData?.diagnosedPercent,
            formatter: formatters.percent.appendPercent,
            tooltipTitle: labels.diagnosed,
          })}

          {renderChart({
            commonTooltipLabel: t('Patients'),
            customLegends: {
              accepted: {
                label: t('Patients Accepted'),
              },
            },
            data: chartData?.acceptedPercent,
            formatter: formatters.percent.appendPercent,
            tooltipTitle: labels.accepted,
          })}

          {renderChart({
            customLegends: {
              hygiene: {
                label: labels['hygiene'],
              },
              restorative: {
                label: labels['restorative'],
              },
            },
            data: chartData?.unscheduledTreatment,
            formatter: (value) => `$${formatters.value.shortenNumber(value)}`,
            tooltipTitle: t('Unscheduled Treatment'),
          })}
        </Chart.HorizontalContainer>
      </Chart>
    );
  }
);

PractitionerAnalysisMetric.displayName = 'PractitionerAnalysisMetric';

const styles = {
  chartWrapper: css`
    align-items: center;
    display: flex;
    flex-direction: column;

    .sneak-peak-item.vertical {
      align-items: flex-start;
      gap: ${theme.spacing(1)};
    }
  `,
};
