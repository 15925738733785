import { theme as originalTheme } from '@frontend/theme-original';
import { theme } from '@frontend/theme';
import { WeaveThemeValues } from '../../component-theme/theme-types';
import type { LegacyButtonSize } from './button-type';

type SizeVariant<TKey extends LegacyButtonSize> = {
  [key in TKey]: {
    height: number;
    padding: string;
    radius?: number;
    lineHeight: number;
    fontSize: number;
  };
};

export type ButtonStyleValues = {
  backgroundColor: WeaveThemeValues['Color'];
  borderRadius: WeaveThemeValues['BorderRadius'];
  boxShadow: WeaveThemeValues['Color'];
  color: WeaveThemeValues['Color'];
  destructive: {
    backgroundColor: WeaveThemeValues['Color'];
    pressedColor: WeaveThemeValues['Color'];
    hoverColor: WeaveThemeValues['Color'];
    fontColor: WeaveThemeValues['Color'];
  };
  disabled: {
    backgroundColor: WeaveThemeValues['Color'];
    color: WeaveThemeValues['Color'];
    secondaryColor: WeaveThemeValues['Color'];
    borderColor: WeaveThemeValues['Color'];
  };
  focusRingColor: WeaveThemeValues['Color'];
  fontFamily: WeaveThemeValues['FontFamily'];
  fontWeight: WeaveThemeValues['FontWeight'];
  hoverColor: WeaveThemeValues['Color'];
  pressedColor: WeaveThemeValues['Color'];
  spacing: WeaveThemeValues['Spacing'];
} & SizeVariant<LegacyButtonSize>;

export const buttonTheme: ButtonStyleValues = {
  backgroundColor: theme.colors.primary50,
  borderRadius: theme.borderRadius.small,
  boxShadow: theme.shadows.heavy,
  color: theme.colors.white,
  destructive: {
    backgroundColor: theme.colors.status.critical,
    pressedColor: theme.colors.status.criticalPressed,
    hoverColor: theme.colors.status.criticalHover,
    fontColor: theme.colors.status.critical,
  },
  disabled: {
    backgroundColor: theme.colors.status.disabled,
    color: theme.colors.white,
    secondaryColor: theme.colors.neutral20,
    borderColor: theme.colors.status.disabled,
  },
  focusRingColor: theme.colors.primary50,
  fontFamily: theme.font.family,
  fontWeight: theme.font.weight.bold,
  hoverColor: theme.colors.primary70,
  pressedColor: theme.colors.primary80,
  spacing: theme.spacing,
  large: {
    padding: '8px 12px',
    lineHeight: 1.375,
    fontSize: 16,
    height: 40,
  },
  small: {
    padding: '8px 16px',
    lineHeight: 1.375,
    fontSize: 14,
    height: 32,
  },
  // Tiny is same as small, tiny is not supported in webapp theme
  tiny: {
    padding: '8px 16px',
    lineHeight: 1.375,
    fontSize: 14,
    height: 40,
  },
};

export const originalButtonTheme: ButtonStyleValues = {
  backgroundColor: originalTheme.colors.weaveBlue,
  borderRadius: '999px',
  boxShadow: originalTheme.shadows.heavy,
  color: originalTheme.colors.white,
  destructive: {
    backgroundColor: originalTheme.colors.error,
    pressedColor: originalTheme.colors.errorPressed,
    hoverColor: originalTheme.colors.errorHovered,
    fontColor: originalTheme.colors.error,
  },
  disabled: {
    backgroundColor: originalTheme.colors.gray200,
    color: originalTheme.colors.gray500,
    secondaryColor: originalTheme.colors.gray500,
    borderColor: originalTheme.colors.gray200,
  },
  focusRingColor: originalTheme.colors.weaveBlue,
  fontFamily: originalTheme.font.family,
  fontWeight: theme.font.weight.bold,
  hoverColor: originalTheme.colors.hoverBlue,
  pressedColor: originalTheme.colors.pressedBlue,
  spacing: originalTheme.spacing,
  large: {
    padding: '8px 16px',
    lineHeight: 1.375,
    fontSize: 16,
    height: 48,
  },
  small: {
    padding: '8px 16px',
    lineHeight: 1.375,
    fontSize: 14,
    height: 40,
  },
  tiny: {
    padding: '2px 12px',
    lineHeight: 1,
    fontSize: 14,
    height: 24,
  },
};
