import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import useResizeObserver from '@react-hook/resize-observer';
import { useNavigate, useSearch } from '@tanstack/react-location';
import dayjs from 'dayjs';
import { debounce } from 'lodash-es';
import { InfiniteData, useMutation } from 'react-query';
import { AnalyticsCommonTypes, CallIntelligenceApi, CallIntelligenceTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { breakpoints } from '@frontend/responsiveness';
import { useScopedInfiniteQuery, useScopedQuery } from '@frontend/scope';
import { useContactPanelStore } from '@frontend/shared';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import {
  Accordion,
  Chip,
  Text,
  useAlert,
  styles as designSystemStyles,
  SkeletonLoaders,
  Pagination,
  emptyStateGraphics,
  IconButton,
} from '@frontend/design-system';
import { queryKeys } from '../../query-keys';
import { trackingIds } from '../../tracking-ids';
import { callIntelligenceUtils, generateContactId } from '../../utils';
import { LocationChip } from '../location-chip';
import { UserCard } from '../user-card';
import { CallIntelInfoTips } from './call-intel-info-tips';
import { CallIntelSearch } from './call-intel-search-field';
import { CallIntelSortFilter } from './call-intel-sort-filter';
import { CallIntelMockData } from './demo-data';
import {
  useCallIntelFollowUpsShallowStore,
  useCallIntelLocations,
  useCallIntelShallowStore,
  useCallTakeawayPanelShallowStore,
} from './hooks';
import { OfficeUserName } from './office-user-name';
import { TaskStatusSwitcher } from './task-status-switcher';

type FollowUpCardProps = {
  drillDownTaskType: CallIntelligenceTypes.TaskTypeEnum;
  followUpItem: CallIntelligenceTypes.FollowUpItem;
  isDemoAccount?: boolean;
  isDrillDown: boolean;
  searchedContact: string;
  setSearchedContact: (contactId: string) => void;
  value: string;
};

type FollowUpCardLoaderProps = {
  totalCalls: number;
};

type FollowUpLoaderProps = {
  count: number;
};

type FollowUpStats = {
  taskStats: CallIntelligenceTypes.TaskStats;
  totalPatients: number;
};

type TaskPayload = {
  callId: string;
  id: string;
  oldStatus: CallIntelligenceTypes.TaskStatusEnum;
  status: CallIntelligenceTypes.TaskStatusEnum;
  taskType: CallIntelligenceTypes.TaskTypeEnum;
};

type EmptyStateProps = {
  label: string;
};

type Props = {
  isDemoAccount?: boolean;
  drillDownTaskType: CallIntelligenceTypes.TaskTypeEnum;
  isDrillDown: boolean;
};

const processFollowUpsData = (data: InfiniteData<CallIntelligenceTypes.FollowUpResponse>) => {
  const allFollowUps: CallIntelligenceTypes.FollowUpItem[] = [];
  const allCalls: CallIntelligenceTypes.Call[] = [];

  let totalFollowUps = 0;

  const currentPage = data.pages[0];
  if (!currentPage) return { allFollowUps, allCalls, totalFollowUps };

  totalFollowUps = currentPage.totalFollowUps;

  currentPage.followUps.forEach((followUp) => {
    const followUpItem = {
      contact: { ...followUp.calls[0], contactId: generateContactId(followUp.calls[0]) },
      allCalls: followUp.calls,
      callTaskMapping: {},
      totalCalls: followUp.totalCalls,
      totalTasks: followUp.totalTasks,
    };

    allFollowUps.push(followUpItem);
    allCalls.push(...followUp.calls);
  });

  return { allFollowUps, allCalls, totalFollowUps };
};

const demoLocations = CallIntelMockData.dummyLocationNames();

const MIN_SEARCH_LENGTH = 3;

// Loader component for follow-up cards
const FollowUpCardLoader = ({ totalCalls }: FollowUpCardLoaderProps) => {
  return (
    <div css={styles.loaderWrapper}>
      {Array.from({ length: totalCalls }).map((_, callIndex) => (
        <div className='row' key={callIndex}>
          <div className='task'>
            <SkeletonLoaders.Loader width='100%' height='36px' />
            <SkeletonLoaders.Loader width='40%' height='36px' />
          </div>
          <SkeletonLoaders.Loader width='20%' height='36px' />
        </div>
      ))}
    </div>
  );
};

// Loader component for follow-ups
const FollowUpLoader = ({ count }: FollowUpLoaderProps) => {
  return (
    <div css={styles.followUpLoaderWrapper}>
      {Array.from({ length: count }).map((_, idx) => (
        <SkeletonLoaders.Loader className='row' key={idx} height={theme.spacing(10.5)} />
      ))}
    </div>
  );
};

// Empty state component for follow-ups
const EmptyState = ({ label }: EmptyStateProps) => {
  const EmptyStateGraphicNoFollowUps = emptyStateGraphics.noCallFollowUps;

  return (
    <div css={styles.emptyStateWrapper}>
      <EmptyStateGraphicNoFollowUps height={300} width={300} />
      <Text css={styles.emptyStateText}>{label}</Text>
    </div>
  );
};

// Stats component for follow-ups
const FollowUpStats = ({ taskStats, totalPatients }: FollowUpStats) => {
  const { t } = useTranslation('analytics');

  const totalTasks =
    (taskStats?.STATUS_TODO ?? 0) + (taskStats?.STATUS_IN_PROGRESS ?? 0) + (taskStats?.STATUS_COMPLETED ?? 0);

  const taskCompletionRate = totalTasks
    ? `${Math.round(((taskStats?.STATUS_COMPLETED ?? 0) / totalTasks) * 100)}%`
    : '0%';

  return (
    <div css={styles.statsWrapper}>
      {[
        { label: t('Patients'), value: totalPatients },
        { label: t('Total Tasks'), value: totalTasks },
        { label: t('To-Do'), value: taskStats.STATUS_TODO },
        { label: t('In Progress'), value: taskStats.STATUS_IN_PROGRESS },
        { label: t('Completed'), value: taskStats.STATUS_COMPLETED },
        { label: t('Task Completion Rate'), value: taskCompletionRate },
      ].map(({ label, value }) => (
        <div className='info-wrapper' key={label}>
          <Text color='subdued'>{label}</Text>
          <Text className='value' weight='bold'>
            {value}
          </Text>
        </div>
      ))}
    </div>
  );
};

const FollowUpCard = ({
  drillDownTaskType,
  followUpItem,
  isDemoAccount,
  isDrillDown,
  searchedContact,
  setSearchedContact,
  value,
}: FollowUpCardProps) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const ref = useRef<HTMLDivElement | null>(null);

  const { locations, isMultiLocation } = useCallIntelLocations({
    demoLocations: isDemoAccount ? demoLocations : undefined,
  });
  const { chipVariants, dataLabels, filters } = useCallIntelShallowStore('chipVariants', 'dataLabels', 'filters');
  const { setPersonId } = useContactPanelStore();
  const {
    selectedCall,
    selectedCallMetaData,
    setSelectedCall,
    setSelectedCallMetaData,
    setDefaultAccordion,
    setSelectedDemoTasks,
  } = useCallTakeawayPanelShallowStore(
    'selectedCall',
    'selectedCallMetaData',
    'setSelectedCall',
    'selectedCallMetaData',
    'setDefaultAccordion',
    'setSelectedCallMetaData',
    'setSelectedDemoTasks'
  );
  const { updateTaskStatus, updateCallTaskMapping } = useCallIntelFollowUpsShallowStore(
    'updateTaskStatus',
    'updateCallTaskMapping'
  );
  const { panelType } = useSlidePanelShallowStore('panelType');
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [size, setSize] = useState({
    isSmall: false,
    isMedium: false,
  });

  const callIds = useMemo(() => followUpItem.allCalls.map((call) => call.id), [followUpItem.allCalls]);

  const taskLabel = dataLabels?.taskTypes?.[drillDownTaskType] ?? drillDownTaskType;
  const populate = searchedContact === value;

  const queryString = useMemo(
    () => `task-lists-${callIds}-${JSON.stringify(filters.locations)}-include-metadata-${true}-${isDemoAccount}`,
    [filters.locations, callIds, isDemoAccount]
  );

  const { data: taskDataRes, isFetching: isTaskLoading } = useScopedQuery<
    CallIntelligenceTypes.CallTaskListResponse,
    unknown
  >({
    queryKey: queryKeys.callIntelligence(queryString),
    queryFn: () =>
      isDemoAccount
        ? CallIntelligenceApi.noopMutationFn(CallIntelMockData.generateTasksForCalls(followUpItem.allCalls))
        : CallIntelligenceApi.getCallTasks({
            callIds,
            locations: filters.locations || [],
            includeMetaData: true,
          }),
    enabled: isDemoAccount || populate || isAccordionOpen,
    onError: () => {
      alert.error(t('Failed to fetch tasks'));
    },
    onSettled: () => {
      setSearchedContact('');
    },
    select: (data) => data,
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { mutate: updateTask } = useMutation({
    mutationFn: !isDemoAccount ? CallIntelligenceApi.updateTask : CallIntelligenceApi.noopMutationFn,
    onSuccess: (_, taskPayload: TaskPayload) => {
      const shouldUpdateTaskStatus = !isDrillDown || taskPayload.taskType === drillDownTaskType;

      if (shouldUpdateTaskStatus) {
        updateTaskStatus({
          callId: taskPayload.callId,
          followUpId: followUpItem.contact.contactId,
          newStatus: taskPayload.status,
          oldStatus: taskPayload.oldStatus,
          taskId: taskPayload.id,
        });
      }

      if (selectedCallMetaData) {
        const updatedTasks = selectedCallMetaData.tasks.map((task) =>
          task.id === taskPayload.id ? { ...task, status: taskPayload.status } : task
        );
        setSelectedCallMetaData({ ...selectedCallMetaData, tasks: updatedTasks });
      }

      alert.success(t('Task updated successfully'));
    },
    onError: () => {
      alert.error(t('Unable to update task. Please try again.'));
    },
  });

  useEffect(() => {
    if (!Object.keys(followUpItem.callTaskMapping).length && taskDataRes?.tasks) {
      const tasksByCallId: Record<string, CallIntelligenceTypes.Task[]> = {};

      taskDataRes.tasks.forEach((task) => {
        const callId = task.sourceId;

        if (!tasksByCallId[callId]) {
          tasksByCallId[callId] = [];
        }
        tasksByCallId[callId].push(task);
      });

      const updatedCallTaskMapping = { ...followUpItem.callTaskMapping };

      Object.keys(tasksByCallId).forEach((callId) => {
        updatedCallTaskMapping[callId] = tasksByCallId[callId];
      });

      updateCallTaskMapping({
        followUpId: followUpItem.contact.contactId,
        callTaskMapping: updatedCallTaskMapping,
        calculate: isDemoAccount ? true : false,
        ...(drillDownTaskType && !!isDemoAccount && { taskType: drillDownTaskType }),
      });
    }
  }, [isDemoAccount, followUpItem?.callTaskMapping, taskDataRes?.tasks]);

  useEffect(() => {
    if (selectedCall && selectedCallMetaData) {
      const isCallInFollowUp = followUpItem.callTaskMapping[selectedCall.id];

      if (isCallInFollowUp) {
        updateCallTaskMapping({
          followUpId: followUpItem.contact.contactId,
          callTaskMapping: {
            [selectedCall.id]: selectedCallMetaData.tasks,
          },
          calculate: false,
          shouldCleanUp: true,
          ...(drillDownTaskType && { taskType: drillDownTaskType }),
        });
      }
    }
  }, [selectedCall, selectedCallMetaData, drillDownTaskType]);

  const handleResize = useCallback(
    debounce((entry: ResizeObserverEntry) => {
      const width = entry.contentRect.width;

      setSize((prevState) => {
        const newIsSmall = width < 451; // 451 is width of accordion for small
        const newIsMedium = width < 630; // 630 is width of accordion for medium

        if (newIsSmall !== prevState.isSmall || newIsMedium !== prevState.isMedium) {
          return {
            isSmall: newIsSmall,
            isMedium: newIsMedium,
          };
        }

        return prevState;
      });
    }, 200),
    []
  );

  useResizeObserver(ref, handleResize);

  const handleAccordionChange = useCallback(() => {
    setIsAccordionOpen((prev) => !prev);
  }, []);

  const handleStatusChange = useCallback(
    (
      taskId: string,
      newStatus: CallIntelligenceTypes.TaskStatusEnum,
      oldStatus: CallIntelligenceTypes.TaskStatusEnum,
      callId: string
    ) => {
      const tasks = followUpItem.callTaskMapping[callId] || [];
      const oldTask = tasks.find((task) => task.id === taskId);

      if (oldTask) {
        updateTask({ id: taskId, status: newStatus, oldStatus, callId, taskType: oldTask.type });
      }
    },
    [followUpItem, updateTaskStatus, setSelectedCallMetaData, selectedCallMetaData]
  );

  return (
    <Accordion.Item value={value} ref={ref} trackingId={trackingIds.callIntel.followUpPageAccordion}>
      <Accordion.Header css={styles.header} onClick={handleAccordionChange}>
        <UserCard
          css={styles.userCard}
          firstName={followUpItem.contact.person?.firstName}
          lastName={followUpItem.contact.person?.lastName}
          locationId={followUpItem.contact.locationId}
          openProfileOnClick={false}
          phoneNumber={callIntelligenceUtils.getPhoneNumber(followUpItem.contact.phoneNumber)}
          rightElement={
            <Chip variant={chipVariants[followUpItem.contact.contactType ?? 'neutral']}>
              {dataLabels.contactType?.[followUpItem.contact.contactType ?? 'Unknown']}
            </Chip>
          }
          showOnlyName
          userId={followUpItem.contact.person?.id}
        />

        <div css={styles.detailsWrapper}>
          <div className='info-wrapper'>
            <div className='task-wrapper'>
              <Icon color='subdued' name='task-small' />
              {drillDownTaskType ? (
                <Text size='medium'>
                  {t(`{{ totalTasks }} {{taskLabel}} ${followUpItem.totalTasks > 1 ? 'Tasks' : 'Task'}`, {
                    totalTasks: followUpItem.totalTasks,
                    taskLabel,
                  })}
                </Text>
              ) : (
                <Text size='medium'>
                  {t(`{{ totalTasks }} ${followUpItem.totalTasks > 1 ? 'Tasks' : 'Task'}`, {
                    totalTasks: followUpItem.totalTasks,
                  })}
                </Text>
              )}
            </div>

            <div className='calls-wrapper'>
              <Icon color='subdued' name='phone-small' />
              <Text size='medium'>
                {t(`{{ totalCalls }} ${followUpItem.totalCalls > 1 ? 'Calls' : 'Call'}`, {
                  totalCalls: followUpItem.totalCalls,
                })}
              </Text>
            </div>
          </div>

          <div className='time-stamp-wrapper'>
            <Text color='subdued' size='medium'>
              {t('Last Call')}
            </Text>
            <Text size='medium'>{dayjs(followUpItem.allCalls[0]?.startTime).format('MMM D, YYYY, h:mm A')}</Text>
          </div>
        </div>
      </Accordion.Header>

      <Accordion.Body css={styles.body}>
        {isTaskLoading ? (
          <FollowUpCardLoader totalCalls={followUpItem.totalCalls} />
        ) : (
          followUpItem.allCalls.map((call) => (
            <div key={call.id} css={[styles.taskWrapper, size.isSmall && styles.taskWrapperSmall]}>
              <div css={[styles.taskInfo, size.isMedium && styles.taskInforMedium]}>
                <div className='left-stack'>
                  <div className='task-header-wrapper'>
                    <Text color='subdued' size='small'>
                      {t('Tasks')}
                    </Text>

                    <CallIntelInfoTips tip='tasks' />
                  </div>

                  {(followUpItem.callTaskMapping[call.id] || []).map((task) => (
                    <div key={task.id} css={styles.taskContent}>
                      <div css={styles.taskText}>
                        <Text
                          css={[
                            designSystemStyles.truncate,
                            task.status === CallIntelligenceTypes.TaskStatusEnum.STATUS_COMPLETED && styles.strike,
                          ]}
                          size='medium'
                          weight={drillDownTaskType === task.type ? 'bold' : 'regular'}
                        >
                          {task.title}
                        </Text>
                      </div>

                      <div css={styles.taskActionWrapper}>
                        <TaskStatusSwitcher
                          defaultOptionValue={task.status}
                          onStatusChange={(newStatus) => handleStatusChange(task.id, newStatus, task.status, call.id)}
                          trackingId={trackingIds.callIntel.followUpTaskCardStatusSwitcher}
                          value={task.status}
                        />

                        <Chip key={task.type} variant={chipVariants[task.type] || 'neutral'}>
                          {dataLabels.taskTypes?.[task.type] || task.type}
                        </Chip>
                      </div>
                    </div>
                  ))}
                </div>

                <div className='right-stack'>
                  <div className='office-user-wrapper'>
                    <Text color='subdued' size='small'>
                      {t('Office User')}
                    </Text>
                    <Text size='medium'>
                      <OfficeUserName
                        {...call.officeUser}
                        isDataUnavailable={callIntelligenceUtils.isOfficeUserDataUnavailable(call.startTime)}
                        isInactive={callIntelligenceUtils.isUserInactive(call.officeUser)}
                      />
                    </Text>
                  </div>

                  {isMultiLocation && (
                    <div className='location-wrapper'>
                      <Text size='small' color='subdued'>
                        {t('Location')}
                      </Text>
                      <LocationChip locationName={locations[call.locationId] || call.locationId} maxWidth={300} />
                    </div>
                  )}

                  <div className='time-stamp-wrapper'>
                    <Text color='subdued' size='small'>
                      {t('Time')}
                    </Text>
                    <Text>{dayjs(call.startTime).format('MMM D, YYYY, h:mm A')}</Text>
                  </div>
                </div>
              </div>

              <IconButton
                css={styles.viewButtonWrapper}
                label={t('View')}
                labelPlacement='right'
                onClick={() => {
                  if (call.id !== selectedCall?.id || panelType !== 'callTakeaway') {
                    setPersonId('', false);
                    setSelectedCall(call);
                    setSelectedCallMetaData(null);
                    setDefaultAccordion('call-tasks');

                    if (isDemoAccount) {
                      setSelectedDemoTasks(followUpItem.callTaskMapping[call.id]);
                    }
                  }
                }}
                showLabelAlways
                trackingId={trackingIds.callIntel.followUpPageAccordionViewBtn}
              >
                <Icon className='view-btn-icon' name='document-filled' />
              </IconButton>
            </div>
          ))
        )}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export const FollowUpsDashboard = ({ isDemoAccount, isDrillDown, drillDownTaskType }: Props) => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');

  const search = useSearch();
  const navigate = useNavigate();

  const [accordions, setAccordions] = useState<string | null>('');
  const [searchQuery, setSearchQuery] = useState<CallIntelligenceTypes.SearchState>({ query: '', value: '' });
  const [pageConfig, setPageConfig] = useState<AnalyticsCommonTypes.PageConfig>({
    pageNumber: 1,
    pageSize: 10,
  });
  const [sortType, setSortType] = useState<CallIntelligenceTypes.SortTypeEnum>(
    CallIntelligenceTypes.SortTypeEnum.SORT_DESC
  );
  const [sortBy, setSortBy] = useState<CallIntelligenceTypes.FollowupSortFieldEnum>(
    CallIntelligenceTypes.FollowupSortFieldEnum.SORT_LAST_CALL
  );
  const [searchedContact, setSearchedContact] = useState('');

  const accordionRef = useRef<HTMLDivElement>(null);

  const { followUps, totalPatients, taskStats, setFollowUps, setTotalPatients, setTaskStats } =
    useCallIntelFollowUpsShallowStore(
      'followUps',
      'totalPatients',
      'taskStats',
      'setFollowUps',
      'setTotalPatients',
      'setTaskStats'
    );
  const { dataLabels, filters } = useCallIntelShallowStore('dataLabels', 'filters');
  const { setAllCalls, setIsDemoAccount } = useCallTakeawayPanelShallowStore('setAllCalls', 'setIsDemoAccount');

  const followupTaskTypeFilters: CallIntelligenceTypes.FollowupTaskTypeFilters = {
    startDate: filters.startDate,
    endDate: filters.endDate,
    locations: filters.locations,
    officeUsers: filters.officeUsers,
    contactTypes: filters.contactTypes,
    taskTypes: isDrillDown ? [drillDownTaskType] : [],
    taskStatus: [],
  };

  const queryString = useMemo(
    () =>
      `calls-followups-${JSON.stringify(followupTaskTypeFilters)}-${searchQuery.query}-${pageConfig.pageSize}-${
        pageConfig.pageNumber
      }-${sortBy}-${sortType}-isDemoAccount-${isDemoAccount}`,
    [
      followupTaskTypeFilters,
      pageConfig.pageSize,
      pageConfig.pageNumber,
      searchQuery.query,
      sortBy,
      sortType,
      isDemoAccount,
    ]
  );

  const statsQueryString = useMemo(
    () =>
      `calls-followups-stats-${JSON.stringify(followupTaskTypeFilters)}-${pageConfig.pageSize}-${
        pageConfig.pageNumber
      }-${sortBy}-${sortType}-isDemoAccount-${isDemoAccount}`,
    [followupTaskTypeFilters, pageConfig.pageSize, pageConfig.pageNumber, sortBy, sortType, isDemoAccount]
  );

  const {
    data,
    hasPreviousPage,
    isFetching: isFollowUpLoading,
  } = useScopedInfiniteQuery<CallIntelligenceTypes.FollowUpResponse, unknown>({
    queryKey: queryKeys.callIntelligence(queryString),
    queryFn: () =>
      isDemoAccount
        ? CallIntelligenceApi.noopMutationFn(
            CallIntelMockData.getFollowUps({
              filters: followupTaskTypeFilters,
              pageConfig,
              sortBy: CallIntelligenceTypes.FollowupSortFieldEnum.SORT_LAST_CALL,
              sortType: CallIntelligenceTypes.SortTypeEnum.SORT_DESC,
              randomFollowUps: false,
            })
          )
        : CallIntelligenceApi.getFollowUps({
            filters: followupTaskTypeFilters,
            pageConfig,
            searchQuery: searchQuery.value,
            sortBy,
            sortType,
          }),
    getNextPageParam: (data) => ({
      pageNumber: (data?.pageConfig?.pageNumber || 0) + 1,
      pageSize: data?.pageConfig?.pageSize || 10,
    }),
    getPreviousPageParam: (data) => ({
      pageNumber: (data?.pageConfig?.pageNumber || 2) - 1,
      pageSize: data?.pageConfig?.pageSize || 10,
    }),
    onError: () => {
      alert.error(t('Failed to fetch follow-ups'));
    },
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  const { data: statsRes } = useScopedQuery<CallIntelligenceTypes.FollowUpStatsResponse | undefined, unknown>({
    queryKey: queryKeys.callIntelligence(statsQueryString),
    queryFn: () =>
      isDemoAccount
        ? CallIntelligenceApi.noopMutationFn(null)
        : CallIntelligenceApi.getAllStats({
            filters: followupTaskTypeFilters,
          }),
    onError: () => {
      alert.error(t('Failed to fetch stats'));
    },
    staleTime: 0,
    cacheTime: 0,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data) {
      const { allFollowUps, allCalls, totalFollowUps } = processFollowUpsData(data);
      setAllCalls(allCalls);
      setFollowUps(allFollowUps);
      setTotalPatients(totalFollowUps);
    }
  }, [data, isDemoAccount]);

  useEffect(() => {
    if (statsRes?.stats) {
      setTaskStats({
        STATUS_TODO: statsRes.stats?.STATUS_TODO || 0,
        STATUS_IN_PROGRESS: statsRes.stats?.STATUS_IN_PROGRESS || 0,
        STATUS_COMPLETED: statsRes.stats?.STATUS_COMPLETED || 0,
      });
    }
  }, [statsRes?.stats]);

  useEffect(() => {
    if (search?.id) {
      const searchedId = search?.id?.toString();
      setSearchedContact(searchedId);
      setAccordions(searchedId);
      const { id: _, ...newSearch } = search;
      navigate({ search: newSearch, replace: true });
    }
  }, [search]);

  useEffect(() => {
    setIsDemoAccount(isDemoAccount);
  }, [isDemoAccount]);

  const handleAccordionChange = useCallback((value: string | null) => {
    setAccordions(value);
  }, []);

  const handlePaginationAfterApplyingFilters = useCallback(() => {
    setPageConfig((pageConfig) => ({ ...pageConfig, pageNumber: 1 }));
  }, []);

  const handleSearchOnChange = useCallback(
    (value: string, debounced?: boolean) => {
      if (!debounced) {
        if (value == '') {
          setSearchQuery({ query: '', value: '' });
        } else {
          setSearchQuery((prev) => ({ ...prev, value }));
        }
      } else {
        if (value.length >= MIN_SEARCH_LENGTH) {
          setSearchQuery({ value, query: value });
          handlePaginationAfterApplyingFilters();
        } else {
          setSearchQuery((prev) => ({ ...prev, value }));
        }
      }
    },
    [handlePaginationAfterApplyingFilters]
  );

  const handleSortChange = useCallback(
    (sortBy: CallIntelligenceTypes.FollowupSortFieldEnum, sortType: CallIntelligenceTypes.SortTypeEnum) => {
      setSortBy(sortBy);
      setSortType(sortType);
    },
    []
  );

  return (
    <>
      <FollowUpStats totalPatients={totalPatients} taskStats={taskStats} />
      <div css={styles.wrapper}>
        <div css={styles.filterContainer}>
          <CallIntelSortFilter
            selectedSortOption='lastCallLatest'
            onSortChange={handleSortChange}
            trackingId={trackingIds.callIntel.followUpSortFilter}
          />
          <CallIntelSearch
            minSearchLength={MIN_SEARCH_LENGTH}
            onChange={handleSearchOnChange}
            value={searchQuery.value}
            trackingId={trackingIds.callIntel.followUpPageSearch}
            css={styles.searchInput}
          />
        </div>

        {isFollowUpLoading ? (
          <FollowUpLoader count={pageConfig.pageSize} />
        ) : (
          <>
            {!followUps.length ? (
              <EmptyState
                label={t(`No {{taskTypeLabel}} Follow-Ups`, {
                  taskTypeLabel: dataLabels?.taskTypes?.[drillDownTaskType] ?? drillDownTaskType,
                })}
              />
            ) : (
              <Accordion
                css={styles.accordion}
                controlledValue={accordions}
                onChange={handleAccordionChange}
                ref={accordionRef}
              >
                {followUps.map((followUp) => (
                  <FollowUpCard
                    drillDownTaskType={drillDownTaskType}
                    followUpItem={followUp}
                    isDemoAccount={isDemoAccount}
                    isDrillDown={isDrillDown}
                    key={followUp.contact.contactId}
                    searchedContact={searchedContact}
                    setSearchedContact={setSearchedContact}
                    value={followUp.contact.contactId}
                  />
                ))}
              </Accordion>
            )}
          </>
        )}
      </div>

      <Pagination
        handleChange={(action: 'prev' | 'next'): void => {
          if (action === 'next') {
            setPageConfig({ ...pageConfig, pageNumber: pageConfig.pageNumber + 1 });
          } else {
            setPageConfig({ ...pageConfig, pageNumber: pageConfig.pageNumber - 1 });
          }
        }}
        hasNext={data?.pages[pageConfig.pageNumber - 1]?.hasNextPage}
        hasPrevious={hasPreviousPage}
        onNumRowsChange={(num) => {
          setPageConfig({ pageNumber: 1, pageSize: num });
        }}
        page={pageConfig.pageNumber}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
    </>
  );
};

const styles = {
  accordion: css`
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-wrap: wrap;
    gap: ${theme.spacing(2)};

    > div {
      box-shadow: none;
      border: 1px solid ${theme.colors.neutral20};
      margin-bottom: 0px;
    }

    > div h3 button section div svg {
      fill: ${theme.colors.neutral70};
    }

    div[data-active='true'] h3 button {
      background-color: ${theme.colors.neutral5};
      border-bottom: 1px solid ${theme.colors.neutral10};

      section div svg {
        fill: ${theme.colors.neutral90};
      }
    }
  `,

  body: css`
    padding: ${theme.spacing(0, 2)};

    > div:not(:last-child) {
      border-bottom: ${theme.spacing(0.125)} solid ${theme.colors.neutral20};
    }
  `,

  detailsWrapper: css`
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    gap: ${theme.spacing(1)};

    .info-wrapper {
      display: flex;
      gap: ${theme.spacing(4)};
      flex: 1;

      .task-wrapper {
        display: flex;
        align-items: center;
        gap: ${theme.spacing(0.5)};
        width: 160px;
      }

      .calls-wrapper {
        display: flex;
        align-items: center;
        gap: ${theme.spacing(0.5)};
        width: 85px;
      }
    }

    .time-stamp-wrapper {
      display: flex;
      gap: ${theme.spacing(0.5)};
      width: 220px;
      flex: 1;

      p {
        white-space: nowrap;
      }
    }
  `,

  userCard: css`
    flex: 1;
    width: 270px;

    > div {
      cursor: pointer !important;
      > figure {
        height: ${theme.spacing(6)};
        width: ${theme.spacing(6)};
        min-height: ${theme.spacing(6)};
        min-width: ${theme.spacing(6)};
        font-size: ${theme.font.size.large};
      }
      > div {
        width: auto;
        flex: 1;
      }

      .name-wrapper {
        flex-wrap: wrap;

        > p {
          font-size: ${theme.font.size.h3};
          line-height: ${theme.spacing(3)};
          overflow: visible;

          span {
            font-size: ${theme.font.size.h3};
          }
        }
      }
    }
  `,

  emptyStateWrapper: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `,

  emptyStateText: css`
    color: ${theme.colors.neutral50};
  `,

  followUpLoaderWrapper: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};

    .row {
      border-radius: ${theme.spacing(1)};
    }
  `,

  filterContainer: css`
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: ${theme.spacing(2)};
    flex-wrap: wrap;
  `,

  header: css`
    section {
      width: ${theme.spacing(4)};
      height: ${theme.spacing(4)};
      margin-right: ${theme.spacing(1)};
    }
    .header-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: ${theme.spacing(2, 0, 2, 2)};
      width: 100%;
      gap: ${theme.spacing(2)};
      flex-wrap: wrap;
      overflow: hidden;
    }
  `,

  searchInput: css`
    @media screen and (max-width: 475px) {
      width: 285px;
      > input {
        font-size: ${theme.font.size.large};
      }
    }
  `,

  statsWrapper: css`
    width: 100%;
    display: flex;
    gap: ${theme.spacing(2)};
    padding: ${theme.spacing(3)};
    border: ${theme.spacing(0.125)} solid ${theme.font.colors.disabled};
    border-radius: ${theme.borderRadius.medium};
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;

    @media screen and (max-width: ${breakpoints.medium.min}px) {
      .info-wrapper:nth-of-type(1),
      .info-wrapper:nth-of-type(2),
      .info-wrapper:nth-of-type(3),
      .info-wrapper:nth-of-type(4) {
        flex: 1 1 22%;
      }

      .info-wrapper:nth-of-type(5),
      .info-wrapper:nth-of-type(6) {
        flex: 1 1 45%;
      }
    }

    @media screen and (max-width: ${breakpoints.small.min}px) {
      gap: ${theme.spacing(1)};
      .info-wrapper:nth-of-type(1),
      .info-wrapper:nth-of-type(2),
      .info-wrapper:nth-of-type(3) {
        flex: 1 1 30%;
      }

      .info-wrapper:nth-of-type(4),
      .info-wrapper:nth-of-type(5) {
        flex: 1 1 45%;
      }

      .info-wrapper:nth-of-type(6) {
        flex: 1 1 100%;
      }
    }

    .info-wrapper {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(1)};
      align-items: center;

      .value {
        font-size: ${theme.spacing(4.5)};
        line-height: ${theme.spacing(5)};
      }
    }
  `,

  strike: css`
    text-decoration: line-through;
  `,

  taskActionWrapper: css`
    display: flex;
    gap: ${theme.spacing(1)};
    width: 230px;
  `,

  taskContent: css`
    display: flex;
    gap: ${theme.spacing(1)};
    flex-wrap: wrap;
    flex: 1 0 auto;
  `,

  taskText: css`
    width: 200px;
  `,

  taskInfo: css`
    display: flex;
    align-items: flex-start;
    gap: ${theme.spacing(1)};
    justify-content: space-between;
    flex: 1 1 auto;

    .left-stack {
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(1)};
      flex: 1 0.1 50%;

      .task-header-wrapper {
        display: flex;
        gap: ${theme.spacing(0.5)};
        align-items: center;
      }
    }

    .right-stack {
      display: flex;
      gap: ${theme.spacing(2)};
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      flex: 1 1 44%;

      .office-user-wrapper {
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing(1)};
        justify-content: flex-start;
        width: 200px;
      }

      .location-wrapper {
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing(1)};
        justify-content: flex-start;
        width: 300px;
      }

      .time-stamp-wrapper {
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing(1)};
        justify-content: flex-start;
        width: 175px;
      }

      .office-user-wrapper,
      .location-wrapper,
      .time-stamp-wrapper {
        flex: 1 1 auto;
      }
    }
  `,

  taskInforMedium: css`
    flex-direction: column;
  `,

  taskWrapper: css`
    display: flex;
    gap: ${theme.spacing(0)};
    padding: ${theme.spacing(2, 0)};
    align-items: flex-start;
  `,

  taskWrapperSmall: css`
    flex-direction: column-reverse;
    gap: ${theme.spacing(2)};
  `,

  viewButtonWrapper: css`
    color: ${theme.colors.text.interactive};
    flex: 0 0 auto;
    font-weight: ${theme.font.weight.bold};
    gap: ${theme.spacing(0.5)};
  `,

  loaderWrapper: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    padding: ${theme.spacing(2, 2)};

    .row {
      display: flex;
      width: 100%;
      gap: ${theme.spacing(4)};

      .task {
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing(2)};
        width: 100%;
      }
    }
  `,

  wrapper: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    margin: ${theme.spacing(5, 0)};
  `,
};
