import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Accordion, BaseFieldProps, SwitchField, Text } from '@frontend/design-system';

type CalendarViewV3HeaderConfirmationStatusFilterType = {
  confirmationStatusField: BaseFieldProps<boolean, '', HTMLInputElement>;
};

export const CalendarViewV3HeaderConfirmationStatusFilter = ({
  confirmationStatusField,
}: CalendarViewV3HeaderConfirmationStatusFilterType) => {
  const { t } = useTranslation('scheduleCalendarFilters');

  return (
    <Accordion shouldRenderBody size='large' showBoxShadow chevronSize={16}>
      <Accordion.Item value={'appointment status'} css={{ border: `1px solid ${theme.colors.neutral10}` }}>
        <Accordion.Header css={{ border: `1px solid ${theme.colors.neutral10}` }}>
          <Text size='medium' weight='bold' textAlign='left'>
            {t('Confirmation Status')}
          </Text>
          {confirmationStatusField.value && (
            <div css={iconContainerStyles}>
              <Icon name='check-small' size={12} color='white' />
            </div>
          )}
        </Accordion.Header>
        <Accordion.Body css={{ border: `1px solid ${theme.colors.neutral10}` }}>
          <SwitchField
            {...confirmationStatusField}
            name='appointmentStatus'
            labelPlacement='left'
            label={t('Unconfirmed Only')}
            css={{ height: theme.spacing(5) }}
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

const iconContainerStyles = css({
  marginLeft: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.colors.primary50,
  borderRadius: theme.borderRadius.full,
  width: theme.spacing(2.5),
  height: theme.spacing(2.5),
});
