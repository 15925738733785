import { MouseEvent, ReactNode, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import { AnimatePresence, motion } from 'framer-motion';
import { PersonHelpers, PersonQueries } from '@frontend/api-person';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  Accordion,
  ConfirmationModal,
  ContentLoader,
  Heading,
  IconButton,
  NakedUl,
  Note,
  Text,
  TextButton,
  useModalControl,
  NotificationBadge,
  useAlert,
} from '@frontend/design-system';

dayjs.extend(calendar);

const NOTES_ACCORDION_ID = 'schedule-event-notes-section';
const DATE_FORMAT = 'MMM D, YYYY';
const formatDate = (date: string | number) =>
  dayjs(date).calendar(null, { lastDay: DATE_FORMAT, lastWeek: DATE_FORMAT, sameElse: DATE_FORMAT });

interface NoteInfo {
  id: string;
  author: string;
  content: string;
  date: string;
}

interface Props {
  author: string;
  onCancel: () => void;
  onCreate: (note: string) => void;
}

const CreateNote = ({ author, onCancel, onCreate }: Props) => {
  return (
    <Note
      editMode
      fallbackText=''
      author={author}
      onCancel={onCancel}
      dateCreated={formatDate(Date.now())}
      onUpdate={onCreate}
    />
  );
};

const MotionDiv = ({ key, children }: { key: string; children: ReactNode }) => (
  <motion.div
    key={key}
    layout='position'
    animate={{ opacity: 1, height: 'auto' }}
    initial={{ opacity: 0, height: 0 }}
    exit={{ opacity: 0, height: 0 }}
    transition={{ duration: 0.3 }}
  >
    {children}
  </motion.div>
);

export const NotesSection = ({ householdId }: { householdId: string }) => {
  const { t } = useTranslation('scheduleCalendarEvents');
  const alert = useAlert();
  const { modalProps, openModal } = useModalControl();
  const [selectedNoteId, setSelectedNoteId] = useState<string | null>(null);
  const [isCreatingNote, setIsCreatingNote] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedAccordionId, setSelectedAccordionId] = useState<string | null>(null);

  const user = getUser();
  const currentUserFullName = PersonHelpers.getFullName({
    FirstName: user?.firstName,
    LastName: user?.lastName,
  });

  const createNoteMutation = PersonQueries.useCreateHouseholdNote(householdId, {
    onSuccess: () => setIsCreatingNote(false),
    onError: () => alert.error(t('Failed to add note.')),
  });
  const updateMutation = PersonQueries.useUpdateHouseholdNote(householdId, {
    onError: () => alert.error(t('Failed to update note.')),
  });
  const deleteMutation = PersonQueries.useDeleteHouseholdNote(householdId, {
    onSuccess: () => setSelectedNoteId(null),
    onError: () => alert.error(t('Failed to delete note.')),
  });

  const { data: householdNotes, isFetching } = PersonQueries.useGetHouseholdNotes(householdId);
  const notes = useMemo<NoteInfo[]>(() => {
    if (!householdNotes) return [];
    return householdNotes.map((note) => ({
      id: note.id,
      author: PersonHelpers.getFullName({
        FirstName: note.created_by.first_name,
        LastName: note.created_by.last_name,
      }),
      content: note.text,
      date: formatDate(note.created_at),
    }));
  }, [householdNotes]);

  const handleAddNote = (e: MouseEvent) => {
    e.stopPropagation();
    setSelectedAccordionId(NOTES_ACCORDION_ID);
    setIsCreatingNote(true);
  };
  const handleCancelCreateNote = () => setIsCreatingNote(false);
  const handleCreateNote = (note: string) => {
    createNoteMutation.mutate({
      author: '',
      date: Date.now().toString(),
      household_id: householdId,
      text: note,
    });
  };
  const handleUpdateNote = (id: string, newValue: string) => {
    const note = householdNotes?.find((note) => note.id === id);
    if (!note) return;

    updateMutation.mutate({ noteId: note.id, note: { ...note, text: newValue } });
  };
  const handleDeleteNote = () => {
    if (!selectedNoteId) return;
    deleteMutation.mutate(selectedNoteId);
  };

  const visibleNotes = isExpanded ? notes : notes.slice(0, 3);
  const shouldShowMoreButtonVisible = notes.length > 3;
  const isLoading = isFetching || createNoteMutation.isLoading || updateMutation.isLoading || deleteMutation.isLoading;

  return (
    <Accordion
      variant='blank'
      css={{ position: 'relative' }}
      controlledValue={selectedAccordionId}
      onChange={(value) => setSelectedAccordionId(value)}
    >
      <ContentLoader show={isLoading} size='small' />
      <Accordion.Item value={NOTES_ACCORDION_ID}>
        <Accordion.Header css={accordionHeaderStyle}>
          <Heading level={2}>{t('Notes')}</Heading>
          <div css={{ marginRight: 'auto', marginLeft: theme.spacing(1), fontWeight: 'bold' }}>
            {!!notes.length && (
              <NotificationBadge css={{ backgroundColor: theme.colors.primary10, color: theme.colors.primary50 }}>
                {notes.length}
              </NotificationBadge>
            )}
          </div>
          <IconButton label='add' onClick={handleAddNote} disabled={isCreatingNote}>
            <Icon name='plus' css={{ color: 'currentColor' }} />
          </IconButton>
        </Accordion.Header>
        <Accordion.Body css={{ padding: 0 }}>
          <AnimatePresence initial={false}>
            {isCreatingNote && (
              <MotionDiv key='create-note'>
                <CreateNote
                  author={currentUserFullName}
                  onCancel={handleCancelCreateNote}
                  onCreate={handleCreateNote}
                />
              </MotionDiv>
            )}
            {!notes.length && !isCreatingNote && (
              <MotionDiv key='no-record'>
                <Text color='light' textAlign='center' css={{ padding: theme.spacing(4, 1) }}>
                  {t('No notes found')}
                </Text>
              </MotionDiv>
            )}
          </AnimatePresence>
          {!!visibleNotes.length && (
            <NakedUl css={{ maxHeight: 350, overflow: 'auto' }}>
              {visibleNotes?.map((note) => (
                <li key={note.id}>
                  <Note
                    content={note.content}
                    author={note.author}
                    dateCreated={note.date}
                    onUpdate={(newValue) => handleUpdateNote(note.id, newValue)}
                    onDelete={() => {
                      setSelectedNoteId(note.id);
                      openModal();
                    }}
                  />
                </li>
              ))}
            </NakedUl>
          )}
          {shouldShowMoreButtonVisible && (
            <TextButton
              css={[{ width: '100%', color: theme.colors.primary50, fontWeight: theme.font.weight.bold }]}
              onClick={() => setIsExpanded(!isExpanded)}
            >
              {isExpanded ? t('See Less') : t('See More')}
            </TextButton>
          )}
          <ConfirmationModal
            destructive
            title={t('Are you sure you want to delete this note?')}
            {...modalProps}
            onConfirm={handleDeleteNote}
          />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

const accordionHeaderStyle = css({
  borderBottom: `1px solid ${theme.colors.neutral20}`,
  '> div': { padding: 0, display: 'flex', justifyContent: 'space-between', alignItems: 'center' },
});
