import { ComponentProps } from 'react';
import { Actions } from '@frontend/contact-actions';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { useThreadActionsContext } from '../thread-actions';

type CallActionProps = Omit<ComponentProps<typeof Actions.Call>, 'label' | 'context'>;

export const CallAction = (props: CallActionProps) => {
  const { t } = useTranslation('thread-actions');
  const { personPhone, outboundPhone } = useThreadActionsContext();

  return (
    <Actions.Call
      label={t('Call {{phoneNumber}}', { phoneNumber: formatPhoneNumber(personPhone) })}
      context={{
        phoneNumber: personPhone,
        outboundNumber: outboundPhone?.locationPhone,
      }}
      {...props}
    />
  );
};
