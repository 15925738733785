import { useQueryClient } from 'react-query';
import { FaxHooks, FaxTypes } from '@frontend/api-fax';
import { FaxDraftQueries } from '@frontend/api-fax-draft';
import { useTranslation } from '@frontend/i18n';
import { SchemaFaxService } from '@frontend/schema';
import { useAlert } from '@frontend/design-system';
import { downloadPDF, previewPDF, downloadDeliveryReport, previewReport } from '../utils/download-fax-media';
import { useFaxNavigation } from './use-fax-navigation';

export const useFaxActions = () => {
  const alert = useAlert();
  const { t } = useTranslation('fax');
  const queryClient = useQueryClient();
  const invalidateFaxInboxList = FaxHooks.useInvalidateFaxInboxList();
  const invalidateFaxCount = FaxHooks.useInvalidateFaxCount();
  const { closeThread } = useFaxNavigation();

  const { mutateAsync: mutateDeleteDraft } = FaxDraftQueries.useDeleteDraft({
    onSuccess: () => {
      alert.success(t('Draft Deleted'));
      queryClient.invalidateQueries(['fax-drafts', 'list']);
    },
    onError: () => {
      alert.error(t('Error Deleting Draft'));
    },
  });

  const archiveFax = async (faxIds: string[], locationId: string, isArchived: boolean) => {
    await SchemaFaxService.UpdateFaxArchiveStatus({
      faxIds,
      locationId,
      isArchived,
    })
      .then(() => {
        alert.success(isArchived ? t('Fax Archived') : t('Fax Unarchived'));
        invalidateFaxInboxList();
        invalidateFaxCount();
      })
      .catch(() => {
        alert.error(isArchived ? t('Error Archiving Fax') : t('Error Unarchiving Fax'));
      });
  };

  const blockFax = async (locationId: string, faxNumber: string) => {
    await SchemaFaxService.BlockContact({
      locationId,
      faxNumber,
    })
      .then(() => {
        alert.success(t('Contact Blocked'));
        invalidateFaxInboxList();
        invalidateFaxCount();
      })
      .catch(() => {
        alert.error(t('Error Blocking Contact'));
      });
  };

  const deleteFax = async (locationId: string, faxIds: string[], type: FaxTypes.InboxType) => {
    await SchemaFaxService.DeleteFaxes({
      locationId,
      faxIds,
    })
      .then(() => {
        alert.success(t('Fax Deleted'));
        closeThread({ type });
        invalidateFaxInboxList();
        invalidateFaxCount();
      })
      .catch(() => {
        alert.error(t('Error Deleting Fax'));
      });
  };

  const deleteDraft = async (draftId: string, locationId: string) => {
    await mutateDeleteDraft({ draftId, locationId });
  };

  const downloadFax = async (faxId: string, filename: string, locationId: string, isFax?: boolean) => {
    const mediaItem = {
      faxId,
      filename,
    };
    await downloadPDF(mediaItem, locationId, !!isFax)
      .then(() => {
        alert.success(t('Fax Downloaded'));
      })
      .catch(() => {
        alert.error(t('Error Downloading Fax'));
      });
  };

  const downloadFaxDeliveryReport = async (faxId: string, locationId: string) => {
    await downloadDeliveryReport(faxId, locationId)
      .then(() => {
        alert.success(t('Delivery Report Downloaded'));
      })
      .catch(() => {
        alert.error(t('Error Downloading Delivery Report'));
      });
  };

  const downloadFaxAndDeliveryReport = async (faxId: string, filename: string, locationId: string, isFax: boolean) => {
    const mediaItem = {
      faxId,
      filename,
    };
    await downloadPDF(mediaItem, locationId, !!isFax, true)
      .then(() => {
        alert.success(t('Fax and Report Downloaded'));
      })
      .catch(() => {
        alert.error(t('Error Downloading Fax and Report'));
      });
  };

  const markReadFax = async (isRead: boolean, locationId: string, faxIds: string[]) => {
    const readString = isRead ? 'Unread' : 'Read';
    await SchemaFaxService.UpdateFaxes({
      hasBeenRead: !isRead,
      locationId,
      faxIds,
    })
      .then(() => {
        alert.success(t(`Marked as ${readString}`, { readString }));
        invalidateFaxInboxList();
        invalidateFaxCount();
      })
      .catch(() => {
        alert.error(t(`Error marking as ${readString}`, { readString }));
      });
  };

  const previewFax = (faxId: string, filename: string, locationId: string, isFax?: boolean) => {
    const mediaItem = {
      faxId,
      filename,
    };
    previewPDF(mediaItem, locationId, !!isFax);
  };

  const previewDeliveryReport = async (faxId: string, locationId: string) => {
    previewReport(faxId, locationId);
  };

  const previewFaxAndDeliveryReport = async (faxId: string, filename: string, locationId: string) => {
    const mediaItem = {
      faxId,
      filename,
    };

    previewPDF(mediaItem, locationId, true, true);
  };

  const tagFax = async (locationId: string, faxIds: string[], tagIds: string[]) => {
    await SchemaFaxService.TagFaxes({
      locationId,
      faxIds,
      tagIds,
    })
      .then(() => {
        alert.success(t(`Fax Tagged`));
        invalidateFaxInboxList();
      })
      .catch(() => {
        alert.error(t('Error Tagging Fax'));
      });
  };

  const retryFax = async (faxId: string, locationId: string) => {
    await SchemaFaxService.Retry({ faxId, locationId })
      .then(() => {
        alert.success(t('Sending Fax'));
        invalidateFaxInboxList();
      })
      .catch(() => {
        alert.error(t('Error Sending Fax'));
      });
  };

  const unblockFax = async (locationId: string, faxNumber: string) => {
    await SchemaFaxService.UnblockContact({
      locationId,
      faxNumber,
    })
      .then(() => {
        alert.success(t('Contact Unblocked'));
        invalidateFaxInboxList();
        invalidateFaxCount();
      })
      .catch(() => {
        alert.error(t('Error Unblocking Contact'));
      });
  };

  const untagFax = async (locationId: string, faxId: string, tagIds: string[]) => {
    await SchemaFaxService.UntagFaxes({
      locationId,
      faxId,
      tagIds,
    })
      .then(() => {
        alert.success(t(`Fax Untagged`));
        invalidateFaxInboxList();
      })
      .catch(() => {
        alert.error(t('Error Untagging Fax'));
      });
  };

  return {
    archiveFax,
    blockFax,
    deleteFax,
    deleteDraft,
    downloadFax,
    downloadFaxAndDeliveryReport,
    downloadFaxDeliveryReport,
    markReadFax,
    previewFax,
    previewDeliveryReport,
    previewFaxAndDeliveryReport,
    retryFax,
    tagFax,
    unblockFax,
    untagFax,
  };
};
