import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { URLs } from '../../constants';
import { trackingIds } from '../../tracking-ids';
import { DemoDataBanner } from '../demo-data-banner';
import { useCallIntelDemoFlags } from './hooks';

export const CallIntelDemoBanner = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('analytics');
  const { showDemoChipAndBanner } = useCallIntelDemoFlags();

  if (!showDemoChipAndBanner) {
    return null;
  }
  return (
    <DemoDataBanner
      css={css`
        margin-bottom: ${theme.spacing(1)};
      `}
      ctaText={t('Subscribe Today')}
      onCTAClick={() => navigate({ to: URLs.CALL_INTEL_SUBSCRIBE })}
      startTrialTrackingId={trackingIds.callIntel.demoDataBannerSelfSubscribe}
      subtitle={t(
        "As a subscriber, you will gain access to insights that will help uncover your patients' specific needs and identify missed revenue opportunities for your practice."
      )}
      title={t("You're exploring the power of Call Intelligence with demo data.")}
    />
  );
};
