import { i18next } from '@frontend/i18n';
import { SchemaManualSmsScheduledV1Service } from './service';
import { QueryEndpointName } from './types';

export const STANDARD_QUERY_ENDPOINT_NAMES = [
  'GetThread',
  'ListThreadsCount',
] as const satisfies (keyof typeof SchemaManualSmsScheduledV1Service)[];
export const INFINITE_QUERY_ENDPOINT_NAMES = ['ListThreads'] as const satisfies (keyof Omit<
  typeof SchemaManualSmsScheduledV1Service,
  (typeof STANDARD_QUERY_ENDPOINT_NAMES)[number]
>)[];
export const QUERY_ENDPOINT_NAMES = [...STANDARD_QUERY_ENDPOINT_NAMES, ...INFINITE_QUERY_ENDPOINT_NAMES] as const;

export const MUTATION_ENDPOINT_NAMES = ['Schedule', 'Delete'] as const satisfies (keyof Omit<
  typeof SchemaManualSmsScheduledV1Service,
  QueryEndpointName
>)[];

export const OPTIMISTIC_STATUS_DETAILS = {
  scheduling: i18next.t('Scheduling...', { ns: 'api-manual-scheduled-sms' }),
  deleting: i18next.t('Deleting...', { ns: 'api-manual-scheduled-sms' }),
} as const;
