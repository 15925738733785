import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text, TextField } from '@frontend/design-system';

type PractitionerNameFieldProps = {
  value: string;
  label: string;
  props: any;
  helperText?: string;
  disabled?: boolean;
  isStepperComponent?: boolean;
};

export const PractitionerTextField = ({
  value,
  helperText,
  label,
  props,
  disabled = false,
  isStepperComponent = false,
}: PractitionerNameFieldProps) => {
  return (
    <section css={practitionerTextFieldContainerStyles}>
      <TextField {...props} disabled={disabled} label={label} value={value} />
      <Text size={isStepperComponent ? 'small' : 'medium'} color='light' css={{ marginTop: theme.spacing(1) }}>
        {helperText}
      </Text>
    </section>
  );
};

const practitionerTextFieldContainerStyles = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
});
