import { i18next } from '@frontend/i18n';
import { IconName } from '@frontend/icons';
import { ChipProps, ChipVariants } from '@frontend/design-system';

export type AppointmentStatusType = {
  key?: string;
  value?: string;
  text: string;
  iconName: IconName;
  variant: ChipProps['variant'];
  id?: string;
  updatedAt?: string;
};

// No type is defined for these statuses in schema
export enum WeaveAppointmentStatus {
  UNCONFIRMED = 'Unconfirmed',
  ATTEMPTED = 'Attempted',
  CONFIRMED = 'Confirmed',
  IN_OFFICE = 'In Office',
  COMPLETED = 'Completed',
  CANCELED = 'Canceled',
  CHECKED_OUT = 'Checked out',
  NO_SHOW = 'No Show',
  UNKNOWN_EVENT_STATUS = 'Unknown',
}

export enum AppointmentStatusV3Enum {
  UNCONFIRMED = 'UNCONFIRMED',
  ATTEMPTED = 'ATTEMPTED',
  CONFIRMED = 'CONFIRMED',
  IN_OFFICE = 'IN_OFFICE',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  NO_SHOW = 'NO_SHOW',
  UNKNOWN_EVENT_STATUS = 'UNKNOWN_EVENT_STATUS',
}

export enum AppointmentStatusMapping {
  'Unconfirmed' = AppointmentStatusV3Enum.UNCONFIRMED,
  'Attempted' = AppointmentStatusV3Enum.ATTEMPTED,
  'Confirmed' = AppointmentStatusV3Enum.CONFIRMED,
  'Completed' = AppointmentStatusV3Enum.COMPLETED,
  'Canceled' = AppointmentStatusV3Enum.CANCELED,
  'In Office' = AppointmentStatusV3Enum.IN_OFFICE,
  'No Show' = AppointmentStatusV3Enum.NO_SHOW,
  'Unknown' = AppointmentStatusV3Enum.UNKNOWN_EVENT_STATUS,
}

export const APPOINTMENT_STATUS_MAPPING_V3 = new Map<
  AppointmentStatusV3Enum,
  { variant: ChipVariants; iconName: IconName; text: string }
>([
  [
    AppointmentStatusV3Enum.UNCONFIRMED,
    { variant: 'warn', iconName: 'pending-small', text: i18next.t('Unconfirmed', { ns: 'scheduleCalendarEvents' }) },
  ],
  [
    AppointmentStatusV3Enum.ATTEMPTED,
    { variant: 'warn', iconName: 'phone-missed-small', text: i18next.t('Attempted', { ns: 'scheduleCalendarEvents' }) },
  ],
  [
    AppointmentStatusV3Enum.CONFIRMED,
    {
      variant: 'primary',
      iconName: 'calendar-confirmed-small',
      text: i18next.t('Confirmed', { ns: 'scheduleCalendarEvents' }),
    },
  ],
  [
    AppointmentStatusV3Enum.IN_OFFICE,
    {
      variant: 'seaweed',
      iconName: 'in-progress-small',
      text: i18next.t('In Progress', { ns: 'scheduleCalendarEvents' }),
    },
  ],
  [
    AppointmentStatusV3Enum.COMPLETED,
    { variant: 'success', iconName: 'check-small', text: i18next.t('Completed', { ns: 'scheduleCalendarEvents' }) },
  ],

  [
    AppointmentStatusV3Enum.CANCELED,
    { variant: 'critical', iconName: 'block-small', text: i18next.t('Cancelled', { ns: 'scheduleCalendarEvents' }) },
  ],
  [
    AppointmentStatusV3Enum.NO_SHOW,
    { variant: 'critical', iconName: 'user-x-small', text: i18next.t('No Show', { ns: 'scheduleCalendarEvents' }) },
  ],
  [
    AppointmentStatusV3Enum.UNKNOWN_EVENT_STATUS,
    { variant: 'neutral', iconName: 'info-small', text: i18next.t('Unknown', { ns: 'scheduleCalendarEvents' }) },
  ],
]);
