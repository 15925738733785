import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { CallQueueApi, CallQueueTypes } from '@frontend/api-call-queue-legacy';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import { Heading, IconButton, XIcon, Text, SecondaryButton, PrimaryButton, useAlert } from '@frontend/design-system';
import { queryKeys } from '../../query-keys';
import { DeviceList } from './device-list';
import { CallQueueDevicesContext, createPostPayload } from './utils';

export const ManageDevices = () => {
  const alert = useAlert();
  const { t } = useTranslation('calls', { keyPrefix: 'devices' });
  const { selectedLocationIds } = useAppScopeStore();
  const { show, setShow, context, panelType } = useSlidePanelShallowStore<CallQueueDevicesContext>(
    'show',
    'setShow',
    'context',
    'panelType'
  );
  const callQueue = context?.callQueue;
  const callQueueName = callQueue?.Name ?? '';
  const assignedDevices = context?.assignedDevices?.map((itm) => itm.SipProfileID) ?? [];

  const queryClient = useQueryClient();

  const initialSelectedProfiles: Record<string, boolean> = useMemo(() => {
    return assignedDevices.reduce((acc, profile) => {
      acc[profile] = true;
      return acc;
    }, {} as Record<string, boolean>);
  }, [assignedDevices]);

  const [assignedSipProfiles, setAssignedSipProfiles] = useState<Record<string, boolean>>(initialSelectedProfiles);

  useEffect(() => {
    const tempDevices = Object.keys(assignedSipProfiles).map((item) => ({ SipProfileID: item }));
    if (show && panelType === 'manageDevices') {
      setShow(true, 'manageDevices', {
        allDevices: context?.allDevices ?? [],
        callQueue: callQueue,
        assignedDevices: tempDevices,
        shouldUpdate: false,
      });
    }
  }, [assignedSipProfiles]);

  const { mutate: updateDevices } = useMutation(
    (payload: CallQueueTypes.CallQueue) => {
      if (!payload.ID) {
        throw new Error(t('No id passed to call queue PUT request'));
      }
      return CallQueueApi.updateDevices(payload.ID, { Devices: payload.Devices ?? [] });
    },
    {
      onSuccess: (data: CallQueueTypes.CallQueue) => {
        if (show && panelType === 'manageDevices') {
          setShow(false, 'manageDevices', {
            allDevices: context?.allDevices ?? [],
            callQueue: callQueue,
            assignedDevices: data.Devices,
            shouldUpdate: true,
          });
        }
        queryClient.invalidateQueries([...selectedLocationIds, ...queryKeys.callQueue(data.ID)]);
        alert.success(t('Successfully updated the devices in the call queue'));
      },
      onError: () => {
        alert.error(t('Failed to update the devices in the call queue'));
      },
    }
  );

  const onSaveDevices = (devices: string[]) => {
    if (!!callQueue?.ID) {
      updateDevices(createPostPayload(callQueue, devices));
    }
  };

  return (
    <div
      style={{
        display: 'grid',
        gridTemplateRows: 'auto 1fr auto',
        height: '100%',
        padding: theme.spacing(4, 4, 2, 4),
      }}
    >
      <header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: theme.spacing(0, 0, 2, 0),
        }}
      >
        <div>
          <Heading level={2}>{t('Manage Devices')}</Heading>
          <Text
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              width: '200px',
            }}
          >
            {callQueueName}
          </Text>
        </div>
        <IconButton
          label={t('Close panel')}
          onClick={() => {
            setShow(false);
          }}
        >
          <XIcon />
        </IconButton>
      </header>

      <section>
        <DeviceList assignedSipProfiles={assignedSipProfiles} setAssignedSipProfiles={setAssignedSipProfiles} />
      </section>

      <footer
        css={{
          display: 'flex',
          gap: theme.spacing(2),
          padding: theme.spacing(2, 0, 3),
          justifyContent: 'flex-end',
        }}
      >
        <SecondaryButton
          size='large'
          css={{ width: 'auto' }}
          onClick={() => {
            setShow(false, 'manageDevices', undefined);
          }}
        >
          {t('Cancel')}
        </SecondaryButton>
        <PrimaryButton
          size='large'
          css={{ width: 'auto' }}
          trackingId='call-queue-stats-manage-devices-save-btn'
          disabled={!callQueue?.ID}
          onClick={() => {
            onSaveDevices(Object.keys(assignedSipProfiles).filter((key) => assignedSipProfiles[key]));
          }}
        >
          {t('Save')}
        </PrimaryButton>
      </footer>
    </div>
  );
};
