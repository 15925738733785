import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Dot, Text, NakedButton } from '@frontend/design-system';
import { useCalendarViewV3HeaderFilterShallowStore } from '../../../stores/use-calendar-view-v3-header-filter-store';

type CalendarViewV3HeaderFilterButtonProps = {
  openModal: () => void;
};

export const CalendarViewV3HeaderFilterButton = ({ openModal }: CalendarViewV3HeaderFilterButtonProps) => {
  const { t } = useTranslation('scheduleCalendarFilters');
  const isMedium = useMatchMedia({ minWidth: breakpoints.medium.min, maxWidth: breakpoints.medium.max });

  const { hasActiveFilters } = useCalendarViewV3HeaderFilterShallowStore('hasActiveFilters');

  return (
    <NakedButton onClick={openModal} css={filterButtonStyles(isMedium)}>
      <div>
        {hasActiveFilters && <Dot color='primary' css={dotStyles} />}
        <Icon name='filter-alt' size={24} color='subdued' />
      </div>
      {!isMedium && (
        <Text size='large' weight='bold' css={{ marginLeft: theme.spacing(0.5) }} color='subdued'>
          {t('Filter')}
        </Text>
      )}
    </NakedButton>
  );
};

const filterButtonStyles = (isMedium: boolean) =>
  css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.borderRadius.small,
    border: `1px solid ${theme.colors.neutral30}`,
    width: !isMedium ? theme.spacing(12) : theme.spacing(5),
    height: theme.spacing(5),
  });

const dotStyles = css({
  width: theme.spacing(1),
  height: theme.spacing(1),
  transform: 'translate(200%, 25%)',
  border: 'none',
});
