import { Text } from '@frontend/design-system';
import { useCallIntelShallowStore } from './hooks';

type Props = {
  suffix?: string;
};

export const CustomTooltipTitle = ({ suffix }: Props) => {
  const { dataLabels, subView } = useCallIntelShallowStore('dataLabels', 'subView');

  return (
    <Text color='subdued' size='small'>
      {`${
        {
          ...dataLabels.appointmentTypes,
          ...dataLabels.categories,
          ...dataLabels.sentimentsWithEmoji,
        }[subView?.id || '']
      }: `}
      {suffix}
    </Text>
  );
};
