import { PropsWithChildren } from 'react';
import { breakpoints } from '@frontend/responsiveness';

export type ActionProps = PropsWithChildren<object>;

function Action({ children }: ActionProps) {
  return (
    <div
      css={{
        gridArea: 'actions',
        width: 'fit-content',
        justifySelf: 'end',
        [`@container (max-width:  ${breakpoints.small.max}px)`]: {
          alignSelf: 'flex-start',
        },
        [`@container (max-width:  ${breakpoints.xsmall.max}px)`]: {
          justifySelf: 'start',
        },
      }}
      className='page-action'
    >
      {children}
    </div>
  );
}

Action.displayName = 'Action';

export { Action };
