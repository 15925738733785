import { FC } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button } from '@frontend/design-system';
import { FeatureUpgradeModalTrackingIds, getFeatureWiseTrackingId } from '../../../constants/tracking-ids';
import { useFeatureSubscription } from '../../../providers/FeatureSubscriptionProvider';
import { useFeatureUpgradeDetailsModalContext } from '../context';

const ACTION_BOX_HEIGHT = 36;

export const ActionFooter: FC = () => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'feature-upgrade-details-modal' });
  const { feature, isUpgradeEligible, isFreeTrialEligible, hasAdminAccessInAtleastOne, canSelfSubscribe } =
    useFeatureSubscription();

  const {
    actionPosition,
    isMultiUpgrade,
    onDismiss,
    onScheduleCallClick,
    openFreeTrial,
    openRequestUpgrade,
    openSelfSubscribe,
  } = useFeatureUpgradeDetailsModalContext();

  return (
    <div css={[actionContainerStyles, { minHeight: actionPosition === 'raised' ? 0 : ACTION_BOX_HEIGHT }]}>
      <Button
        variant='tertiary'
        color='primary'
        trackingId={getFeatureWiseTrackingId(feature, FeatureUpgradeModalTrackingIds.dismissAction)}
        css={[dismissButtonStyles, actionPosition === 'raised' && dismissButtonRaisedStyles]}
        onClick={onDismiss}
      >
        {t('Maybe Later')}
      </Button>
      <div css={[actionButtonsStyles, actionPosition === 'raised' && actionButtonRaisedStyles]}>
        {hasAdminAccessInAtleastOne || isMultiUpgrade ? (
          <>
            {isFreeTrialEligible && (
              <Button
                variant='secondary'
                onClick={openFreeTrial}
                trackingId={getFeatureWiseTrackingId(feature, FeatureUpgradeModalTrackingIds.freeTrialAction)}
              >
                {t('Start 30-Day free trial')}
              </Button>
            )}
            {isUpgradeEligible && canSelfSubscribe ? (
              <Button
                variant='primary'
                onClick={openSelfSubscribe}
                trackingId={getFeatureWiseTrackingId(feature, FeatureUpgradeModalTrackingIds.upgradeAction)}
              >
                {t('Upgrade Now')}
              </Button>
            ) : (
              <Button
                variant='primary'
                onClick={onScheduleCallClick}
                trackingId={getFeatureWiseTrackingId(feature, FeatureUpgradeModalTrackingIds.scheduleCallAction)}
              >
                {t('Call to Upgrade')}
              </Button>
            )}
          </>
        ) : (
          <Button
            variant='primary'
            onClick={openRequestUpgrade}
            trackingId={getFeatureWiseTrackingId(feature, FeatureUpgradeModalTrackingIds.requestUpgradeAction)}
          >
            {t('Request Upgrade')}
          </Button>
        )}
      </div>
    </div>
  );
};

const actionContainerStyles = css({
  transition: 'min-height 0.5s ease',
  backgroundColor: theme.colors.white,
});

const dismissButtonStyles = css({
  position: 'absolute',
  cursor: 'pointer',
  left: theme.spacing(3),
  bottom: 0,
  transition: 'all 0.5s ease',
});

const dismissButtonRaisedStyles = css({
  left: '50%',
  transform: 'translateX(-50%)',
  bottom: 'calc(12% - 48px)',
});

const actionButtonsStyles = css({
  position: 'absolute',
  right: theme.spacing(3),
  bottom: 0,
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  transition: 'all 0.5s ease',
});

const actionButtonRaisedStyles = css({
  right: '50%',
  transform: 'translateX(50%)',
  bottom: '12%',
});
