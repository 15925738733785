import { SchedulerV3Queries, SchedulerV3Types } from '@frontend/api-scheduler-v3';

type UseGetPractitionersV3DataParams = {
  isMultiLocation: boolean;
  selectedLocationId: string;
  parentLocationId: string;
  selectedLocationIds?: string[];
  debouncedSearchValue?: string;
  pageConfig?: SchedulerV3Types.PageConfig;
  isEnabled?: boolean;
};

export const useGetPractitionersV3Data = ({
  isMultiLocation,
  selectedLocationId,
  parentLocationId,
  selectedLocationIds,
  debouncedSearchValue = '',
  pageConfig,
  isEnabled = true,
}: UseGetPractitionersV3DataParams) => {
  const isMultiLocationApiCall = !!parentLocationId && isMultiLocation;
  const request: SchedulerV3Types.ListPractitionersApiType['input'] = {
    locationId: selectedLocationId,
    ...(pageConfig?.limit && { limit: pageConfig?.limit }),
    ...(pageConfig?.page && { page: pageConfig?.page }),
    ...(debouncedSearchValue && { name: debouncedSearchValue }),
  };

  const singleLocationGetPractitionerQuery = SchedulerV3Queries.useGetPractitioners(request, {
    enabled: !isMultiLocationApiCall && isEnabled,
  });

  const requestForMulti: SchedulerV3Types.ListPractitionersMultiLocationApiType['input'] = {
    ...(pageConfig?.limit && { limit: pageConfig?.limit }),
    ...(pageConfig?.page && { page: pageConfig?.page }),
    ...(debouncedSearchValue && { name: debouncedSearchValue }),
    locationIds: selectedLocationIds || [selectedLocationId],
    parentLocationId,
  };

  const multiLocationGetPractitionerQuery = SchedulerV3Queries.useGetPractitionersForMulti(requestForMulti, {
    enabled: isMultiLocationApiCall && isEnabled,
  });

  return isMultiLocationApiCall ? multiLocationGetPractitionerQuery : singleLocationGetPractitionerQuery;
};
