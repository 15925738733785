import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { SchemaScheduleService } from '@frontend/schema';
import {
  AppointmentType,
  AppointmentTypeResponse,
  ExternalAppointmentType,
  ApptRequestConfig,
  GetAppointmentTypesCountType,
} from './types';

const LOCATION_ID_HEADER = 'Location-Id';
const getLocationIdHeader = (locationId?: string) => ({
  headers: locationId ? { [LOCATION_ID_HEADER]: locationId } : undefined,
});

const baseUrl = 'schedule/api/v2/appointment-types';

export const getExternalAppointmentTypes = async (locationId?: string): Promise<ExternalAppointmentType[]> => {
  if (!locationId) {
    return Promise.reject('requires location id');
  }
  const response = await SchemaScheduleService.GetIntegrationAppointmentTypes({
    locationId: locationId,
  });

  return (response.appointmentTypes ?? [])
    .map<ExternalAppointmentType>((appointmentType) => ({
      externalId: appointmentType.externalId,
      id: appointmentType.id ?? '',
      locationId: appointmentType.locationId ?? '',
      name: appointmentType.name ?? '',
    }))
    .sort((a, b) => a.name.localeCompare(b.name));
};

export const getAppointmentTypes = async (locationId: string): Promise<AppointmentType[]> => {
  if (!locationId) {
    return Promise.reject('requires location id');
  }
  const { data: appointmentTypes } = await http.get<AppointmentTypeResponse>(
    `${baseUrl}?locationId=${locationId}`,
    getLocationIdHeader(locationId)
  );
  return (appointmentTypes || []).sort((a, b) => a.name.localeCompare(b.name));
};

export const getAppointmentType = async (locationId: string, appointmentTypeId?: string): Promise<AppointmentType> => {
  const { t } = useTranslation('schedule');
  if (!locationId) {
    return Promise.reject(t('requires location id'));
  }
  if (!appointmentTypeId) {
    return Promise.reject(t('requires appointment id'));
  }
  return http.get<AppointmentType>(`${baseUrl}/${appointmentTypeId}?locationId=${locationId}`);
};

export const upsertAppointmentType = async (
  appointmentType: Partial<AppointmentType>,
  locationId: string
): Promise<AppointmentType | undefined> => {
  const url = appointmentType.id ? `${baseUrl}/${appointmentType.id}` : baseUrl;
  const method = appointmentType.id ? 'put' : 'post';

  const savedAppointment = await http[method]<AppointmentType, Partial<AppointmentType>>(url, appointmentType, {
    ...getLocationIdHeader(locationId),
  });
  return savedAppointment;
};

export const deleteAppointmentType = async (id: string): Promise<AppointmentType[]> => {
  if (!id) {
    return Promise.reject('requires id');
  }
  return await http.delete<AppointmentType[]>(`${baseUrl}/${id}`);
};

export const updateApptRequestConfig = async (appointmentTypeIDs: string[], key: string, value: any) => {
  const { data: appointmentTypes } = await http.get<AppointmentTypeResponse>(baseUrl);
  const values = {
    appointmentTypeIDs: appointmentTypeIDs,
    requestBufferDuration: appointmentTypes?.[0].requestBufferDuration,
    minOpeningsPermitted: appointmentTypes?.[0].minOpeningsPermitted,
    hasGlobalBookingDeposit: appointmentTypes?.[0]?.hasGlobalBookingDeposit,
    globalBookingDepositAmount: appointmentTypes?.[0]?.globalBookingDepositAmount,
  };
  await http.put(baseUrl, {
    ...values,
    [key]: value,
  });
};

export const updateApptRequestConfigData = (updatedApptRequestConfigData: ApptRequestConfig) =>
  http.put(baseUrl, updatedApptRequestConfigData);

export const getApptRequestConfig = async (locationId: string): Promise<ApptRequestConfig> => {
  const { data: appointmentTypes } = await http.get<AppointmentTypeResponse>(`${baseUrl}?locationId=${locationId}`);
  const requestBufferDuration = appointmentTypes?.[0].requestBufferDuration || 0;
  const minOpeningsPermitted = appointmentTypes?.[0]?.minOpeningsPermitted || 0;
  const appointmentTypeIDs = appointmentTypes?.map((apptType: AppointmentType) => apptType.id) || [];
  return {
    appointmentTypeIDs,
    requestBufferDuration: requestBufferDuration,
    minOpeningsPermitted: minOpeningsPermitted,
    hasBookingDeposit: appointmentTypes?.[0]?.hasBookingDeposit || false,
    globalBookingDepositAmount: appointmentTypes?.[0]?.globalBookingDepositAmount || 0,
    hasGlobalBookingDeposit: appointmentTypes?.[0]?.hasGlobalBookingDeposit || false,
  };
};

export const getAppointmentTypesCount = (req: GetAppointmentTypesCountType['input']) =>
  SchemaScheduleService.GetAppointmentTypesCount(req);
