import { ReactNode, memo, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { CallIntelligenceApi, CallIntelligenceTypes } from '@frontend/api-analytics';
import { LoadingSkeleton } from '@frontend/assets';
import { Chart } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useScopedQuery } from '@frontend/scope';
import { useHasFeatureFlag } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { Chip, Heading, Text, useAlert } from '@frontend/design-system';
import { featureFlags } from '../../feature-flags';
import { queryKeys } from '../../query-keys';
import { callIntelligenceUtils, fillTaskTypes, formatters } from '../../utils';
import { cardStyle } from '../../views/common-styles';
import { DemoChip } from '../demo-chip';
import { CallIntelInfoTips } from './call-intel-info-tips';
import { OpportunitiesByTypeChart, SentimentsChart } from './charts';
import { CallIntelMockData } from './demo-data';
import { FollowUpSummary } from './follow-up-summary';
import { useCallIntelDemoFlags, useCallIntelShallowStore } from './hooks';

export type SummaryCard = 'conversionRate' | 'unscheduled' | 'callsAnalyzed';

export type SummaryCardClickHandler = (card: SummaryCard, locationName?: string) => void;

export type CallIntelSummaryChartsProps = {
  onChangeLoadingState?: (isLoading: boolean) => void;
  onClickSummaryCard?: SummaryCardClickHandler;
};

const taskTypeOrder: Exclude<CallIntelligenceTypes.TaskTypeEnum, CallIntelligenceTypes.TaskTypeEnum.TYPE_UNKNOWN>[] = [
  CallIntelligenceTypes.TaskTypeEnum.TYPE_SCHEDULING,
  CallIntelligenceTypes.TaskTypeEnum.TYPE_WAITLIST,
  CallIntelligenceTypes.TaskTypeEnum.TYPE_PATIENT_CARE,
  CallIntelligenceTypes.TaskTypeEnum.TYPE_INSURANCE,
  CallIntelligenceTypes.TaskTypeEnum.TYPE_BILLING,
  CallIntelligenceTypes.TaskTypeEnum.TYPE_OTHER,
];

export const CallIntelSummaryCharts = memo(
  ({ onChangeLoadingState, onClickSummaryCard }: CallIntelSummaryChartsProps) => {
    const alert = useAlert();
    const { t } = useTranslation('analytics');
    const isFollowUpsEnabled = useHasFeatureFlag(featureFlags.enableCallIntelFollowUps);
    const { filters, isDemoAccount } = useCallIntelShallowStore('filters', 'isDemoAccount');
    const [isMounted, setIsMounted] = useState<boolean>(false);

    const [conversionRates, setConversionRates] = useState({
      scheduled: 0,
      unscheduled: 0,
    });

    const filteredOverviewData = useMemo(() => {
      return CallIntelMockData.getFilteredOverviewData(CallIntelMockData.overview, filters);
    }, [filters]);

    const { data, isLoading } = useScopedQuery({
      queryKey: queryKeys.callIntelligence(`overview-${JSON.stringify(filters)}-${isMounted}`),
      queryFn: () => (!isMounted || isDemoAccount ? null : CallIntelligenceApi.getOverviewDetails({ filters })),
      onError: (err) => {
        alert.error(t('Failed to fetch overview data'));
        console.error(err);
      },
      refetchOnWindowFocus: false,
      select: (data) => (isDemoAccount ? filteredOverviewData : data),
      staleTime: 1000 * 60 * 5,
    });

    const filledTaskTypes = fillTaskTypes(data?.taskTypes ?? {}, taskTypeOrder);

    useEffect(() => {
      if (data) {
        const scheduledOpportunities = data.summary.scheduledOpportunities || 0;
        const totalOpportunities = data.summary.totalOpportunities || 0;
        const unScheduledOpportunities = totalOpportunities - scheduledOpportunities;

        setConversionRates({
          scheduled: scheduledOpportunities / totalOpportunities,
          unscheduled: unScheduledOpportunities / totalOpportunities,
        });
      }
    }, [data]);

    useEffect(() => {
      onChangeLoadingState?.(isLoading);
    }, [isLoading]);

    useEffect(() => {
      setIsMounted(true);

      return () => {
        setIsMounted(false);
      };
    }, []);

    return (
      <>
        <section css={styles.summaryCardsWrapper}>
          <SummaryCard
            infoTip={<CallIntelInfoTips tip='conversionRate' />}
            isLoading={isLoading}
            label={t('Opportunities')}
            onClick={() => onClickSummaryCard?.('conversionRate')}
            ratingLabel={t('Scheduled Rate')}
            ratingValue={formatters.percent.format(conversionRates.scheduled)}
            secondaryValue={formatters.value.format(data?.summary.totalOpportunities)}
            title={t('Scheduled')}
            titleIcon={
              <Chip style={{ width: theme.spacing(4) }} variant='success'>
                <Icon name='check-small' />
              </Chip>
            }
            value={formatters.value.format(data?.summary.scheduledOpportunities || 0)}
          />

          <SummaryCard
            infoTip={<CallIntelInfoTips tip='schedulingOpportunities' />}
            isLoading={isLoading}
            label={t('Opportunities')}
            onClick={() => onClickSummaryCard?.('unscheduled')}
            ratingLabel={t('Unscheduled Rate')}
            ratingValue={formatters.percent.format(conversionRates.unscheduled)}
            secondaryValue={formatters.value.format(data?.summary.totalOpportunities)}
            title={t('Unscheduled')}
            titleIcon={
              <Chip style={{ width: theme.spacing(4) }} variant='warn'>
                <Icon name='x-small' />
              </Chip>
            }
            value={formatters.value.format(
              (data?.summary.totalOpportunities || 0) - (data?.summary.scheduledOpportunities || 0)
            )}
          />

          <SummaryCard
            infoTip={<CallIntelInfoTips tip='callsAnalyzed' />}
            isLoading={isLoading}
            label={t('Total')}
            onClick={() => onClickSummaryCard?.('callsAnalyzed')}
            title={t('Calls Analyzed')}
            value={formatters.value.format(data ? callIntelligenceUtils.getCallsCount(data) : 0)}
          />
        </section>

        {isFollowUpsEnabled && (
          <FollowUpSummary
            data={filledTaskTypes}
            infoTipId='followUpSummary'
            isLoading={isLoading}
            title={t('Follow-up Tasks by Reason')}
          />
        )}

        <Chart.HorizontalContainer>
          <OpportunitiesByTypeChart
            focusLabel={t('Calls')}
            infoTipId='callsByCategory'
            isLoading={isLoading}
            subViewType='category'
            title={t('Calls by Category')}
            types={data?.categories ?? {}}
          />
          <OpportunitiesByTypeChart
            focusLabel={t('Calls')}
            infoTipId='callsByAppointmentType'
            isLoading={isLoading}
            subViewType='appointment-type'
            title={t('Calls by Appointment Type')}
            types={data?.appointmentTypes ?? {}}
          />
        </Chart.HorizontalContainer>

        <SentimentsChart css={styles.sentimentChart} isLoading={isLoading} sentiments={data?.sentiments} />
      </>
    );
  }
);

CallIntelSummaryCharts.displayName = 'CallIntelSummaryCharts';

type SummaryCardProps = {
  infoTip?: ReactNode;
  isLoading?: boolean;
  label: string;
  onClick?: () => void;
  ratingLabel?: string;
  ratingValue?: number | string;
  secondaryValue?: number | string;
  title: string;
  titleIcon?: ReactNode;
  value: number | string;
};

const SummaryCard = memo(
  ({
    infoTip,
    isLoading,
    label,
    onClick,
    ratingLabel,
    ratingValue,
    secondaryValue,
    title,
    titleIcon,
    value,
  }: SummaryCardProps) => {
    const { showDemoChipAndBanner } = useCallIntelDemoFlags();

    return (
      <div
        css={[cardStyle, styles.summaryCard]}
        onClick={onClick}
        {...(!!onClick
          ? {
              className: 'clickable',
              role: 'button',
              tabIndex: 0,
            }
          : {})}
      >
        <div className='title-wrapper'>
          {showDemoChipAndBanner && <DemoChip />}
          <div className='title'>
            {titleIcon}
            <Heading level={3}>{title}</Heading>
            {infoTip}
          </div>
        </div>

        <div style={{ position: 'relative' }}>
          <div css={styles.statsWrapper}>
            <div>
              <Text className='value' weight='bold'>
                {value}
                {secondaryValue ? <span className='secondary-value'>{`/${secondaryValue}`}</span> : null}
              </Text>
              <Text color='subdued'>{label}</Text>
            </div>

            {!!ratingLabel && (
              <div>
                <Text className='value' weight='bold'>
                  {ratingValue}
                </Text>
                <Text color='subdued'>{ratingLabel}</Text>
              </div>
            )}
          </div>
          {isLoading && <LoadingSkeleton css={styles.loadingSummary} />}
        </div>
      </div>
    );
  }
);

SummaryCard.displayName = 'SummaryCard';

const styles = {
  sentimentChart: css`
    margin-top: ${theme.spacing(3)};
  `,

  statsWrapper: css`
    display: flex;
    gap: ${theme.spacing(4)};
  `,

  summaryCardsWrapper: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(3)};
    width: 100%;
  `,

  summaryCard: css`
    flex-grow: 1;
    flex-direction: column;
    width: 280px;

    &.clickable {
      cursor: pointer;

      &:hover {
        background-color: ${theme.colors.neutral5};
      }
    }

    .title-wrapper {
      align-items: center;
      display: flex;
      flex-wrap: wrap;
      gap: ${theme.spacing(0.5)};
      margin-bottom: ${theme.spacing(1)};
    }

    .title {
      align-items: center;
      display: flex;
      gap: ${theme.spacing(0.5)};
      margin-bottom: ${theme.spacing(1)};

      h3 {
        line-height: 1;
      }
    }

    .value {
      font-size: ${theme.font.size.h1};

      .secondary-value {
        color: ${theme.colors.text.subdued};
        font-size: ${theme.font.size.large};
        font-weight: ${theme.font.weight.regular};
      }
    }
  `,

  loadingSummary: css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  `,
};
