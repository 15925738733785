import { AudioLibraryApi } from '@frontend/api-audio-library';
import HoldMusicAPI from '@frontend/api-hold-music';
import { VoicemailConfigAPI } from '@frontend/api-voicemail-boxes';
import { useQuery } from '@frontend/react-query-helpers';
import { AudioItem, GreetingItem } from '../components/audio-picker/types';
import { queryKeys } from '../query-keys';
import { buildVoicemailGreetingPath } from './media-path';
import { alphabeticalSort } from './phone-utils';

export const useAudioQuery = ({
  tenantId,
  allowedOptions,
  mailboxId,
}: {
  tenantId: string;
  allowedOptions: {
    standard?: boolean;
    custom?: boolean;
    mailbox?: boolean;
  };
  mailboxId?: string;
}) => {
  return useQuery<{
    customMedia: AudioItem[];
    standardMedia: AudioItem[];
    mailboxGreetings: GreetingItem[];
    allMedia: AudioItem[];
  }>({
    queryKey: [tenantId, ...queryKeys.audioPicker({ ...allowedOptions, tenantId, mailboxId })],
    queryFn: async () => {
      const data = await Promise.allSettled([
        allowedOptions.custom ? AudioLibraryApi.listAudioLibrary({ tenantId }) : { audioFiles: [] },
        allowedOptions.standard
          ? HoldMusicAPI.ListSystem({})
          : {
              systemMedia: [],
            },
        allowedOptions.mailbox && mailboxId
          ? VoicemailConfigAPI.ListGreetings({ mailboxId: mailboxId })
          : { greetings: [] },
      ]);

      const customMedia =
        data[0].status === 'fulfilled' && data[0].value.audioFiles
          ? data[0].value.audioFiles
              .map((media) => ({
                id: media.mediaItemId,
                name: media.name,
                isGlobal: false,
                path: media.downloadUrl,
                type: 'custom' as const,
              }))
              .sort((a, b) => alphabeticalSort(a.name, b.name))
          : [];

      const standardMedia =
        data[1].status === 'fulfilled' && data[1].value.systemMedia
          ? data[1].value.systemMedia
              .map((media) => ({
                id: media.mediaItemId,
                name: media.name,
                isGlobal: true,
                path: media.downloadUrl,
                type: 'standard' as const,
              }))
              .sort((a, b) => alphabeticalSort(a.name, b.name))
          : [];

      const mailboxGreetings =
        data[2].status === 'fulfilled' && data[2].value.greetings
          ? data[2].value.greetings
              .map((media) => ({
                id: media.greetingId,
                name: media.greetingName ?? '',
                number: media.greetingNumber,
                isGlobal: false,
                path: buildVoicemailGreetingPath(media.greetingId),
                type: 'mailbox' as const,
              }))
              .sort((a, b) => a.number - b.number)
          : [];

      return {
        customMedia,
        standardMedia,
        mailboxGreetings,
        allMedia: [...customMedia, ...standardMedia, ...mailboxGreetings],
      };
    },
  });
};
