import { useMemo } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { useQueryClient } from 'react-query';
import {
  CustomizationFlagQueries,
  CustomizationFlagUtils,
  useCustomizationFlagShallowStore,
} from '@frontend/api-customization-flags';
import { FreeTrialSubscriptionQueries, FreeTrialSubscriptionTypes } from '@frontend/api-free-trial-subscription';
import { useAppScopeStore } from '@frontend/scope';

interface UseWivTeaserHandlerProps {
  locationIds: string[];
}
export const useWivTeaserHandler = ({ locationIds }: UseWivTeaserHandlerProps) => {
  const queryClient = useQueryClient();
  const { selectedLocationIds } = useAppScopeStore();

  const { setFlags } = useCustomizationFlagShallowStore('setFlags');

  const { isLoading, data: locationWiseCustomizationFlags } = CustomizationFlagQueries.useMultiCustomizationFlags(
    locationIds,
    {
      onSuccess: (data) => {
        const selectedLocationId = selectedLocationIds?.[0];
        const selectedLocationFeatures = data.locationsFeatures?.[selectedLocationId]?.features;
        if (!!selectedLocationId && !!selectedLocationFeatures) {
          setFlags(selectedLocationFeatures);
        }
      },
    }
  );

  const isWivActive = useMemo(() => {
    if (!isLoading && locationWiseCustomizationFlags?.locationsFeatures) {
      return Object.values(locationWiseCustomizationFlags.locationsFeatures).every(({ features }) =>
        features?.some(
          ({ featureEnum, state }) =>
            featureEnum === Feature.WEAVE_VERIFY && CustomizationFlagUtils.isCustomizationFlagActive({ state })
        )
      );
    }
    return false;
  }, [isLoading, locationWiseCustomizationFlags]);

  const {
    data: wivUpgradeEligibility,
    isLoading: isLoadingWivEligibility,
    isRefetching: isRefetchingWivEligibility,
  } = FreeTrialSubscriptionQueries.useSelfUpgradeOrFreeTrialEligibility(
    {
      locationIds,
      featureEnum: Feature.WEAVE_VERIFY,
    },
    {
      enabled: !isWivActive && !!locationIds.length && !isLoading,
    }
  );

  const isWivEligible = useMemo(() => {
    return (
      !isWivActive &&
      wivUpgradeEligibility?.eligibility?.some(({ trialStatus, subscriptionStatus }) => {
        return (
          trialStatus === FreeTrialSubscriptionTypes.TrialStatus.TRIAL_ELIGIBLE ||
          subscriptionStatus !==
            FreeTrialSubscriptionTypes.SubscriptionStatusForSelfUpgrade.SUBSCRIPTION_STATUS_UNSPECIFIED
        );
      })
    );
  }, [wivUpgradeEligibility, isWivActive]);

  const handleWivUpgradeSuccess = () => {
    queryClient.invalidateQueries({
      predicate: ({ queryKey }) => {
        return (
          queryKey.includes('customization-flags') ||
          (locationIds.some((locationId) => queryKey.includes(locationId)) &&
            FreeTrialSubscriptionQueries.freeTrialSubscriptionQueryKeys.subscriptionBaseQuery.every((part) =>
              queryKey.includes(part)
            ))
        );
      },
    });
  };

  return {
    isLoading: isLoading || isLoadingWivEligibility || isRefetchingWivEligibility,
    showWeaveVerify: isWivActive,
    showWeaveVerifyPromotion: !isWivActive && isWivEligible,
    handleWivUpgradeSuccess,
  };
};
