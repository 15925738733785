import { CalendarEvent } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/calendar_event.pb';
import { Source } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/source_tenant.pb';
import { ListMappingsResponse } from '@weave/schema-gen-ts/dist/schemas/syncapp/mappings/v1/service.pb';
import dayjs from 'dayjs';
import {
  APPOINTMENT_STATUS_MAPPING_V3,
  AppointmentStatusMapping,
  AppointmentStatusType,
  AppointmentStatusV3Enum,
} from './types';

const APPOINTMENT_STATUS_CACHE_TIME_IN_MINUTES = 2;

type GetAppointmentStatusListArgs = {
  appointmentStatusData?: ListMappingsResponse;
  appointmentDetails?: CalendarEvent;
  sourceTenants?: Source[];
  isIntegrated?: boolean;
};

type GetIntegratedAppointmentStatusListArgs = Omit<GetAppointmentStatusListArgs, 'isIntegrated'>;

const getIntegratedAppointmentStatusList = ({
  appointmentStatusData,
  appointmentDetails,
  sourceTenants,
}: GetIntegratedAppointmentStatusListArgs) => {
  if (!appointmentStatusData || !appointmentDetails || !sourceTenants) return [];

  const getSourceAppointmentStatusMapping = () => {
    const appointmentSourceTenantDetails = sourceTenants.find(
      ({ sourceTenantId }) => sourceTenantId === appointmentDetails.sourceTenantId
    );

    const appointmentSourceDetails = appointmentStatusData?.mappings?.find(
      (mapping) => appointmentSourceTenantDetails?.id && appointmentSourceTenantDetails.id === mapping.sourceId
    );

    let mappings = appointmentSourceDetails?.mappings.filter((mapping) => mapping.value.length);

    if (mappings?.length === 0 && appointmentSourceDetails?.externalValues) {
      mappings = appointmentSourceDetails?.externalValues.map((status) => {
        return {
          key: status.label,
          value: [{ label: status.label, value: status.value }],
        };
      });
    }

    return mappings;
  };

  const sourceAppointmentStatusMapping = getSourceAppointmentStatusMapping();

  const appointmentStatuses = sourceAppointmentStatusMapping?.reduce((acc: AppointmentStatusType[], curr) => {
    const statusKey = curr.key as keyof typeof AppointmentStatusMapping;
    const statusValue = AppointmentStatusMapping[statusKey];
    const statusMap = APPOINTMENT_STATUS_MAPPING_V3.get(statusValue as unknown as AppointmentStatusV3Enum);

    curr.value.forEach((assignedStatus) => {
      const statusObject: AppointmentStatusType = {
        key: statusValue,
        value: assignedStatus.value,
        text: assignedStatus.label,
        iconName: statusMap ? statusMap.iconName : 'pending-small',
        variant: statusMap && statusMap.variant,
      };
      acc.push(statusObject);
    });
    return acc;
  }, []);

  return appointmentStatuses || [];
};

const getNonIntegratedAppointmentStatusList = () => {
  // For Non integrated office, return all mapping options
  const entries = Array.from(APPOINTMENT_STATUS_MAPPING_V3.entries());
  const appointmentStatusListLocal = entries?.map(([key, value]) => ({
    key,
    text: value.text,
    iconName: value.iconName,
    variant: value.variant,
  }));

  return appointmentStatusListLocal || [];
};

export const getAppointmentStatusList = ({ isIntegrated, ...rest }: GetAppointmentStatusListArgs) => {
  return isIntegrated ? getIntegratedAppointmentStatusList({ ...rest }) : getNonIntegratedAppointmentStatusList();
};

export const getLatestUpdatedAppointmentStatus = (appointmentStatus: AppointmentStatusType) => {
  return appointmentStatus.updatedAt
    ? dayjs().diff(dayjs(appointmentStatus.updatedAt), 'minutes') > APPOINTMENT_STATUS_CACHE_TIME_IN_MINUTES
    : false;
};
