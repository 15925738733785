import { FC } from 'react';
import { css } from '@emotion/react';
import { FormsDigitization } from '@frontend/api-forms';
import appConfig from '@frontend/env';
import { useFetch } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { CheckboxField, Modal, Text, useModalLoadingState, useForm, useAlert } from '@frontend/design-system';

interface Props {
  onClose: (formsSubmitted?: boolean) => void;
}

const FormsSubmissionConfirmation: FC<React.PropsWithChildren<Props>> = ({ onClose }) => {
  const alert = useAlert();
  useFetch({ baseUrl: appConfig.BACKEND_API });
  const { setLoading } = useModalLoadingState();
  const { formProps, getFieldProps, isComplete } = useForm({
    fields: {
      condition1: { type: 'checkbox', required: true },
      condition2: { type: 'checkbox', required: true },
      condition3: { type: 'checkbox', required: true },
      condition4: { type: 'checkbox', required: true },
      condition5: { type: 'checkbox', required: true },
      condition6: { type: 'checkbox', required: true },
      condition7: { type: 'checkbox', required: true },
    },
  });
  const { t } = useTranslation('forms');

  const onFinishFormsUploadConfirmed = async () => {
    try {
      setLoading(true, t('modal submitting text'));
      const formUploadResponse = await FormsDigitization.API.finishFormsUpload();

      if (formUploadResponse.success) {
        onClose(true);
      } else {
        alert.error(t('forms upload failed'));
      }
    } catch (error: any) {
      alert.error(t('something wrong'));
    }

    setLoading(false);
  };

  return (
    <>
      <Modal.Header>{t('modal confirm submission')}</Modal.Header>
      <Modal.Body>
        <Text
          css={css`
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          {t('modal submission note')}
        </Text>

        <Text
          css={css`
            margin-bottom: ${theme.spacing(1)};
          `}
        >
          {t('modal acknowledgement note 1')} <b>{t('submit')}</b>, {t('modal acknowledgement note 2')}
        </Text>

        <form {...formProps}>
          <CheckboxField {...getFieldProps('condition1')} label={t('submission term 1')} />

          <CheckboxField {...getFieldProps('condition2')} label={t('submission term 2')} />

          <CheckboxField {...getFieldProps('condition3')} label={t('submission term 3')} />

          <CheckboxField {...getFieldProps('condition4')} label={t('submission term 4')} />

          <CheckboxField {...getFieldProps('condition5')} label={t('submission term 5')} />
          <CheckboxField {...getFieldProps('condition6')} label={t('submission term 6')} />
          <CheckboxField {...getFieldProps('condition7')} label={t('submission term 7')} />
        </form>
      </Modal.Body>
      <Modal.Actions
        onPrimaryClick={onFinishFormsUploadConfirmed}
        onSecondaryClick={() => onClose()}
        primaryLabel={t('submit')}
        secondaryLabel={t('cancel')}
        disablePrimary={!isComplete}
      />
    </>
  );
};

export default FormsSubmissionConfirmation;
