import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

const reviewRequiredContainerStyle = css`
  margin-right: ${theme.spacing(1)};
  border-right: 1px solid;
  padding-right: ${theme.spacing(2)};
`;

interface ReviewRequiredStyleConfig {
  isDisabled: boolean;
}

export function getReviewRequiredStyle({ isDisabled }: ReviewRequiredStyleConfig) {
  return [
    reviewRequiredContainerStyle,
    css`
      color: ${isDisabled ? theme.colors.neutral20 : theme.colors.black};
    `,
  ];
}

export const switchFieldStyle = css`
  label {
    margin-right: ${theme.spacing(1)};
  }
`;

export const listStyle = css`
  margin: ${theme.spacing(2, 0)};

  li {
    line-height: 1.5;
    :not(:last-child) {
      margin-bottom: ${theme.spacing(1)};
    }
  }
`;
