import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const modalStyle = css`
  min-width: min(780px, 95%);
`;

export const headerStyle = css`
  padding: ${theme.spacing(0, 3)};
`;

export const bannerStyle = css`
  margin: ${theme.spacing(2, 0)};
`;

export const chipStyle = css`
  max-width: 150px !important;
`;
