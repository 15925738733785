import { useMemo } from 'react';
import { css } from '@emotion/react';
import { useMatch, Params } from '@tanstack/react-location';
import { pick } from 'lodash-es';
import { useQuery } from 'react-query';
import {
  SettingsLink,
  settingsRouteLookup,
  useSettingsPathLabelMap,
  StaticSettingsRoutes,
  constructRoutePath,
  SettingsRoutes,
} from '@frontend/settings-routing';
import { extractAndFormatPathName } from '@frontend/string';
import { theme } from '@frontend/theme';
import { Breadcrumbs, SkeletonLoader } from '@frontend/design-system';
import { SettingsBreadcrumbsObj } from '../../types';

export type SettingsBreadcrumbsProps = { breadcrumbs?: SettingsBreadcrumbsObj[] };

export function SettingsBreadcrumbs({ breadcrumbs }: SettingsBreadcrumbsProps) {
  return breadcrumbs ? (
    <Breadcrumbs
      css={css`
        margin-bottom: ${theme.spacing(1)};
      `}
    >
      {breadcrumbs.map(({ to, label, params, ...rest }) => (
        <Breadcrumbs.Path key={label} as={SettingsLink} to={to as SettingsRoutes} params={params as any} {...rest}>
          {label}
        </Breadcrumbs.Path>
      ))}
    </Breadcrumbs>
  ) : (
    <AutoSettingsBreadcrumbs />
  );
}

const AutoSettingsBreadcrumbs = () => {
  const { params, route } = useMatch();
  const breadcrumbs = useMemo(() => settingsRouteLookup.searchPath(('/settings' + route.path) as string), [route.path]);
  if (breadcrumbs.length === 1) return null;

  return (
    <Breadcrumbs
      css={css`
        margin-bottom: ${theme.spacing(1)};
      `}
    >
      {breadcrumbs.map((crumb) => (
        <SettingsBreadcrumb crumb={crumb.replace('/settings', '')} params={params} key={crumb} />
      ))}
    </Breadcrumbs>
  );
};

type SettingsBreadcrumbProps = { params: Params<string>; crumb: string };

const SettingsBreadcrumb = ({ params, crumb, currentPage }: SettingsBreadcrumbProps & { currentPage?: boolean }) => {
  const pathLabelMap = useSettingsPathLabelMap();

  const labelContent = pathLabelMap[crumb as keyof typeof pathLabelMap]?.label;
  const isLabelFunction = typeof labelContent === 'function';

  return (
    <Breadcrumbs.Path
      key={crumb as StaticSettingsRoutes}
      as={SettingsLink}
      to={crumb as StaticSettingsRoutes}
      params={extractParams(crumb, params) as any}
      currentPage={currentPage}
    >
      {isLabelFunction ? (
        <SettingsBreadcrumbLabel params={params} crumb={crumb} />
      ) : (
        labelContent || extractAndFormatPathName(crumb)
      )}
    </Breadcrumbs.Path>
  );
};

SettingsBreadcrumb.displayName = 'BreadcrumbsPath';

const SettingsBreadcrumbLabel = ({ params, crumb }: SettingsBreadcrumbProps) => {
  const pathLabelMap = useSettingsPathLabelMap();

  const fetchSettingsLabel = async () => {
    const mappedLabel = pathLabelMap[crumb as keyof typeof pathLabelMap]?.label;
    if (typeof mappedLabel === 'function') {
      try {
        return await mappedLabel(params as any);
      } catch (error) {
        const label = extractAndFormatPathName(crumb);
        return label.includes(':') ? params[label.slice(1)] : label;
      }
    }

    return '';
  };

  const { data: asyncLabel, isLoading } = useQuery(
    ['auto-settings-label', constructRoutePath(crumb, params)],
    fetchSettingsLabel,
    {
      cacheTime: 5 * 60 * 1000,
      staleTime: 0,
    }
  );

  return isLoading ? (
    <SkeletonLoader
      css={css`
        background-color: ${theme.colors.neutral10};
      `}
      animation='shimmer'
      height={14}
      width={50}
    />
  ) : (
    <>{asyncLabel}</>
  );
};

function extractParams(path: string, params: Record<string, string>): Record<string, string> | undefined {
  if (!path.includes(':')) {
    return undefined;
  }

  const extractedKeys = path.split('/').reduce((keys, segment) => {
    if (segment.startsWith(':')) {
      keys.push(segment.substring(1));
    }
    return keys;
  }, [] as string[]);

  return pick(params, extractedKeys);
}
