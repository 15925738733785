import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { useDevToolsStore } from '@frontend/devtools';
import { useAppScopeStore } from '@frontend/scope';

export const useGetSchedulerV3FeatureFlagDetails = () => {
  const { selectedLocationIds } = useAppScopeStore();

  // TODO: Remove the debug mode check when scheduler-v3 is ready to release
  // TODO: Do not remove this logic until the scheduler v3 is ready to release to the customers
  const isDebugModeOn = useDevToolsStore((state) => state.options.isDebugModeOn);

  const { aggregateValue, isLoading } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'scheduler-v3-ui',
    aggregationStrategy: FeatureFlagQueries.AggregationStrategy.ALL,
    locationIds: selectedLocationIds,
  });

  // TODO: Remove the debug mode check and dev env check when scheduler-v3 is ready to release
  const aggregateFeatureFlagValue = aggregateValue && isDebugModeOn;

  return { isScheduleV3FlagEnabled: aggregateFeatureFlagValue, isLoading };
};
