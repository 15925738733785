import { getInitialParams } from '@frontend/env';

export const buildAudioLibraryPath = ({
  media,
}: {
  media: { id: string; path: string; isGlobal: boolean };
}): string => {
  if (media.path === '') {
    return '';
  }

  const mediaUrl = new URL(media.path);
  if (media.isGlobal) {
    mediaUrl.searchParams.append('isGlobalMedia', 'true');
  }
  return mediaUrl.toString();
};

export const buildVoicemailGreetingPath = (greetingId: string) => {
  const currentEnv = getInitialParams().backendApi;
  const mediaPath = `${currentEnv}/phone/voicemail-config/v2/${greetingId}/download-greeting`;
  return mediaPath;
};
