import { useRef, useEffect } from 'react';
import { useQuery } from 'react-query';
import { FormsQueryKeys, FormsSubmission } from '@frontend/api-forms';
import { useAlert } from '@frontend/design-system';
import { openPDFInNewWindow, b64toBlob, savePDF, removeDownloadLink, generateFileName } from '../../../shared/helpers';
import { useScopeLocation } from '../../../shared/hooks';

interface UseSubmissionPDFProps {
  submissionId: string;
  pdfName: string;
  formId?: string;
  autoFetch?: boolean;
}

export const useSubmissionPDF = ({ submissionId, formId, autoFetch = false, pdfName }: UseSubmissionPDFProps) => {
  const QUERY_KEY = [FormsQueryKeys.submissionPDF, submissionId];
  const alert = useAlert();
  const { locationId } = useScopeLocation();
  const downloadLinkRef = useRef<HTMLAnchorElement>();

  if (formId) {
    QUERY_KEY.push(formId);
  }

  const { data, refetch, isLoading, ...otherUseQueryProps } = useQuery(
    QUERY_KEY,
    () => FormsSubmission.API.fetchSubmissionPDF({ submissionId, formId, companyId: locationId }),
    {
      refetchOnMount: true,
      enabled: autoFetch,
      onError: () => {
        alert.error('Failed to generate the PDF!');
      },
    }
  );

  useEffect(() => {
    return () => {
      cleanUpDownloadLink();
    };
  }, []);

  async function downloadPDF() {
    const base64Data = await getBase64Data();

    if (!base64Data) {
      return;
    }

    cleanUpDownloadLink();
    downloadLinkRef.current = savePDF({
      base64Data,
      fileName: generateFileName(pdfName),
    });
  }

  async function printPDF() {
    const base64Data = await getBase64Data();

    if (!base64Data) {
      return;
    }

    const blob = b64toBlob(base64Data, 'application/pdf');
    openPDFInNewWindow(blob);
  }

  async function getBase64Data() {
    if (isLoading) {
      return;
    }

    let base64Data = data;

    if (!data) {
      const response = await refetch();
      base64Data = response.data;
    }

    return base64Data;
  }

  function cleanUpDownloadLink() {
    if (downloadLinkRef.current) {
      removeDownloadLink(downloadLinkRef.current);
      downloadLinkRef.current = undefined;
    }
  }

  return {
    pdf: data,
    downloadPDF,
    printPDF,
    isLoadingPDF: isLoading,
    ...otherUseQueryProps,
  };
};
