import { useDepartmentSelector } from '../../hooks';
import { DepartmentSelector as StandaloneDepartmentSelector } from '../department-selector';
import { useThreadHeaderContext } from '../thread-header';

export const DepartmentSelector = () => {
  const { groupId, personPhone, outboundPhone, onOutboundPhoneChange } = useThreadHeaderContext();

  const departmentSelectorState = useDepartmentSelector({
    groupId,
    departmentId: outboundPhone?.departmentId,
    alwaysShow: false,
    personPhone,
  });

  if (!onOutboundPhoneChange) return null;

  return <StandaloneDepartmentSelector {...departmentSelectorState} onSelectDepartment={onOutboundPhoneChange} />;
};
