import { InvoiceStatus, InvoiceRefundStatus, PaymentStatus } from '@frontend/api-invoices';
import { prettify } from '@frontend/string';
import { Chip, ChipVariants, ChipProps } from '@frontend/design-system';

type InvoiceStatusChipProps = ChipProps & {
  status: InvoiceStatus | InvoiceRefundStatus | PaymentStatus;
};

const mapStatus = (status: InvoiceStatus | InvoiceRefundStatus | PaymentStatus): ChipVariants => {
  switch (status) {
    case InvoiceStatus.Paid:
    case InvoiceStatus.PartiallyPaid:
      return 'success';
    case InvoiceStatus.Unpaid:
      return 'warn';
    case InvoiceStatus.Canceled:
    case InvoiceStatus.Expired:
    case InvoiceStatus.Locked:
    case InvoiceStatus.StatusUnknown:
    case InvoiceRefundStatus.PartialRefund:
    case InvoiceRefundStatus.Refunded:
      return 'disabled';
    case 'FAILED':
      return 'critical';
    case 'PENDING':
      return 'primary';
    default:
      return 'disabled';
  }
};

export const StatusChip = ({ status, ...rest }: InvoiceStatusChipProps) => (
  <>
    {status && (
      <Chip variant={mapStatus(status)} {...rest}>
        {prettify(status, { split: '_' })}
      </Chip>
    )}
  </>
);
