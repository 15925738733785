import { FC } from 'react';
import { BuilderFieldDivisionCategories } from '../../../constants';
import { useFormBuilderStore } from '../../../provider';
import CategoriesLayout from './categories-layout/categories-layout.component';
import { useFilteredCategories } from './hooks';

/**
 * These are the medical history fields that are not synced to the PMS.
 */
export const NonSyncableMedicalHistoryFields: FC = () => {
  const { filteredCategories } = useFilteredCategories({
    categories: BuilderFieldDivisionCategories.GENERIC_MEDICAL_HISTORY_FIELD_CATEGORIES,
  });
  const { otherFieldElements } = useFormBuilderStore(['otherFieldElements']);

  if (!otherFieldElements) {
    return null;
  }

  return <CategoriesLayout categories={filteredCategories} fields={otherFieldElements} type='other' />;
};
