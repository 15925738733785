import { ElementType } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { PolymorphicComponentPropWithoutRef, Text, TextProps } from '@frontend/design-system';

type DividerProps = {
  color?: string;
  margin?: string | number;
};

type PolymorphicDividerProps<E extends ElementType = 'hr'> = PolymorphicComponentPropWithoutRef<E, DividerProps>;

export const hrStyle = (color: string = theme.colors.neutral10) => css`
  width: auto;
  border: 0;
  border-top: 1px solid ${color};
  margin-top: 0;
  margin-bottom: 0;
`;

export const dividerStyle = (color: string | undefined, margin: string | number | undefined) => css`
  ${hrStyle(color)}
  margin-top: ${margin ?? theme.spacing(2)};
  margin-bottom: ${margin ?? theme.spacing(2)};
  width: 100%;
`;

export const Divider = <E extends ElementType = 'hr'>({ as, color, margin, ...rest }: PolymorphicDividerProps<E>) => {
  const Component = as ?? 'hr';
  return <Component css={dividerStyle(color, margin)} {...rest} />;
};

type DividerWithLabelProps = DividerProps & {
  label: string;
  labelSize?: TextProps['size'];
};

type PolymorphicDividerWithLabelProps<E extends ElementType = 'span'> = PolymorphicComponentPropWithoutRef<
  E,
  DividerWithLabelProps
>;

export const DividerWithLabel = <E extends ElementType = 'span'>({
  as,
  color,
  margin,
  label,
  labelSize = 'medium',
  ...rest
}: PolymorphicDividerWithLabelProps<E>) => {
  const Component = as ?? 'span';
  return (
    <Component
      css={{
        display: 'flex',
        gap: theme.spacing(1),
        alignItems: 'center',
        width: '100%',
        margin: `${margin ?? theme.spacing(2)} 0`,
      }}
      {...rest}
    >
      <Divider color={color} css={[hrStyle(color), { flexGrow: 1 }]} />
      <Text css={{ color }} size={labelSize}>
        {label}
      </Text>
      <Divider color={color} css={[hrStyle(color), { flexGrow: 1 }]} />
    </Component>
  );
};
