import { useEffect } from 'react';
import { css } from '@emotion/react';
import { InvoiceModel } from '@frontend/api-invoices';
import { PaymentQueries } from '@frontend/api-payments';
import { PrintDialog } from '@frontend/assets';
import { useTranslation } from '@frontend/i18n';
import { useQueryAllInvoices, useQueryPaginatedInvoices } from '@frontend/payments-invoice-controller';
import { theme } from '@frontend/theme';
import { Table } from '@frontend/design-system';
import {
  InvoiceList,
  InvoicePageActions,
  PrintHeader,
  PaymentsPage,
  InvoiceSummary,
  DateFilters,
  NewInvoiceAction,
  InvoiceSummaryWithActions,
} from '../../../components';
import { generateColumns } from '../../../components/Invoices/InvoiceList/generate-columns';
import { FilterHeader } from '../../../components/PaymentRequests';
import { useGenerateColumns, usePrintDialogue } from '../../../hooks';
import { PaymentsTableInstances } from '../../../utils';

const styles = {
  marginBottom: css`
    margin-bottom: ${theme.spacing(3)};
  `,
};

export const PaymentRequests = () => {
  const { t } = useTranslation('payments');
  const { summary, invoices, loading, refetch, hasMore, fetchNextPage, fetchPreviousPage } =
    useQueryPaginatedInvoices();

  const { invalidateUnrecordedCount } = PaymentQueries.usePaymentsInvalidation();

  const { showPrint, setShowPrint, handlePrint } = usePrintDialogue();

  useEffect(() => {
    // Revalidate unrecorded on mount
    invalidateUnrecordedCount();
  }, []);

  return (
    <PaymentsPage
      title={t('invoiceHeaderTitle')}
      maxWidth={1420}
      printDialogue={PrintFriendly}
      showPrint={showPrint}
      setShowPrint={setShowPrint}
      variant='payments-app'
      action={<NewInvoiceAction trackingId='pay-portal-invoices-button-new' />}
    >
      <FilterHeader />
      <DateFilters />
      <InvoiceSummaryWithActions summary={summary} loading={loading} style={styles.marginBottom}>
        <InvoicePageActions
          onRefreshClick={() => {
            refetch();
            invalidateUnrecordedCount();
          }}
          showPrint={showPrint}
          handlePrint={handlePrint}
          css={css`
            width: max-content;
          `}
        />
      </InvoiceSummaryWithActions>
      <InvoiceList
        invoices={invoices}
        loading={loading}
        hasMore={hasMore}
        fetchPreviousPage={fetchPreviousPage}
        fetchNextPage={fetchNextPage}
        variant='payments-app'
      />
    </PaymentsPage>
  );
};

const PrintFriendly = ({ onClose }: { onClose: () => void }) => {
  const { invoices, summary } = useQueryAllInvoices();
  const columns = useGenerateColumns<InvoiceModel>(generateColumns, true, PaymentsTableInstances.PaymentRequests);

  if (!invoices.length) {
    return null;
  }
  return (
    <PrintDialog show={true} onClose={onClose} landscape>
      <div
        css={css`
          margin: ${theme.spacing(2)};
        `}
      >
        <PrintHeader css={styles.marginBottom} />
        <DateFilters />
        <InvoiceSummary summary={summary} loading={false} style={styles.marginBottom} />
        <Table
          wrapperStyle={css`
            height: fit-content;
            width: 100%;
          `}
          colConfig={columns}
          data={invoices}
          tableInstanceId={PaymentsTableInstances.PaymentRequests}
        />
      </div>
    </PrintDialog>
  );
};
