import { useTranslation } from '@frontend/i18n';
import { Icon, IconColorType } from '@frontend/icons';
import { InboxType } from '@frontend/inbox-navigation';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { Dot, IconButton, IconButtonProps, usePopoverDialog } from '@frontend/design-system';
import { useInboxTypeAndFiltersMapping } from '../stores';
import { HiddenFilterKeys } from '../types';
import { InboxFiltersPopover } from './filters-popover';

type InboxFiltersButtonProps = {
  iconButtonProps?: Partial<IconButtonProps>;
  iconSize?: number;
  iconColor?: IconColorType;
  hideFilters?: HiddenFilterKeys;
  groupIds?: string[];
  inboxType?: InboxType;
};

export const InboxFiltersButton = ({
  iconButtonProps,
  iconSize,
  iconColor,
  hideFilters = [],
  groupIds,
  inboxType,
}: InboxFiltersButtonProps) => {
  const { t } = useTranslation('inbox');
  const { hasFilters } = useInboxTypeAndFiltersMapping(inboxType);
  const { getTriggerProps, getDialogProps, close } = usePopoverDialog<HTMLButtonElement | HTMLAnchorElement>({
    placement: 'bottom-end',
  });

  return (
    <>
      <IconButton
        showLabelOnHover
        label={t('Filter messages')}
        trackingId={`${InboxPrefixes.List}-filter-button`}
        css={{
          position: 'relative',
          padding: theme.spacing(0.75),
        }}
        {...iconButtonProps}
        {...getTriggerProps()}
      >
        <Icon name='filter-alt' color={iconColor} size={iconSize} />
        {hasFilters && (
          <Dot
            color='primary'
            css={{
              width: theme.spacing(1.5),
              height: theme.spacing(1.5),
              top: 2,
              right: 0,
              border: `1.5px solid ${theme.colors.white}`,
            }}
          />
        )}
      </IconButton>
      <InboxFiltersPopover
        getDialogProps={getDialogProps}
        close={close}
        groupIds={groupIds}
        hideFilters={hideFilters}
      />
    </>
  );
};
