import { SchedulerV3API, SchedulerV3QueryKeys, SchedulerV3Types } from '@frontend/api-scheduler-v3';
import { ServiceProvidersConstants } from '@frontend/api-service-providers';
import { useLocalizedQuery } from '@frontend/location-helpers';

const { ONE_MINUTE_IN_MILLISECONDS } = ServiceProvidersConstants;

type UseGetCalendarEventsV3DataParamsType = {
  isMultiLocation: boolean;
  selectedLocationId: string;
  parentLocationId: string;
  selectedLocationIds: string[];
  calendarEventsParams: SchedulerV3Types.ListCalendarEventsParamsType;
  enabled?: boolean;
};

export const useGetCalendarEventsV3Data = ({
  parentLocationId,
  isMultiLocation,
  calendarEventsParams,
  selectedLocationIds,
  selectedLocationId,
  enabled = true,
}: UseGetCalendarEventsV3DataParamsType) => {
  const isMultiLocationApiCall = !!parentLocationId && isMultiLocation;

  const {
    data: calendarEventsData,
    isLoading: isCalendarEventsLoading,
    refetch: refetchCalendarEvents,
  } = useLocalizedQuery({
    queryKey: SchedulerV3QueryKeys.CalendarEventsQueryKeys.listCalendarEvents(calendarEventsParams),
    queryFn: () =>
      SchedulerV3API.listCalendarEvents({
        endDateTime: calendarEventsParams.endDateTime,
        locationId: selectedLocationId,
        startDateTime: calendarEventsParams.startDateTime,
        includeAttendeeDetails: true,
        ...(calendarEventsParams.limit && { limit: calendarEventsParams.limit }),
        ...(calendarEventsParams.page && { page: calendarEventsParams.page }),
      }),
    retry: 1,
    cacheTime: 10 * ONE_MINUTE_IN_MILLISECONDS, // 10 mins
    staleTime: 10 * ONE_MINUTE_IN_MILLISECONDS, // 10 mins
    enabled:
      !isMultiLocationApiCall &&
      !!selectedLocationId &&
      !!calendarEventsParams.startDateTime &&
      !!calendarEventsParams.endDateTime &&
      enabled,
  });

  const {
    data: calendarEventsMultiData,
    isLoading: isCalendarEventsMultiLoading,
    refetch: refetchCalendarEventsMulti,
  } = useLocalizedQuery({
    queryKey: SchedulerV3QueryKeys.CalendarEventsQueryKeys.listCalendarEventsMulti({
      ...calendarEventsParams,
      locationIds: selectedLocationIds,
    }),
    queryFn: () =>
      SchedulerV3API.listCalendarEventsMulti({
        endDateTime: calendarEventsParams.endDateTime,
        locationsIds: selectedLocationIds,
        startDateTime: calendarEventsParams.startDateTime,
        parentLocationId,
        includeAttendeeDetails: true,
        ...(calendarEventsParams.limit && { limit: calendarEventsParams.limit }),
        ...(calendarEventsParams.page && { page: calendarEventsParams.page }),
      }),
    retry: 1,
    cacheTime: 10 * ONE_MINUTE_IN_MILLISECONDS, // 10 mins
    staleTime: 10 * ONE_MINUTE_IN_MILLISECONDS, // 10 mins
    enabled:
      isMultiLocationApiCall &&
      !!selectedLocationIds.length &&
      !!calendarEventsParams.startDateTime &&
      !!calendarEventsParams.endDateTime &&
      enabled,
  });

  if (isMultiLocationApiCall) {
    return {
      data: calendarEventsMultiData,
      isLoading: isCalendarEventsMultiLoading,
      refetch: refetchCalendarEventsMulti,
    };
  }

  return {
    data: calendarEventsData,
    isLoading: isCalendarEventsLoading,
    refetch: refetchCalendarEvents,
  };
};
