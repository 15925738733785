import { FC, useCallback, useMemo } from 'react';
import { FormsWritebacks } from '@frontend/api-forms';
import {
  useDigitalFormsWritebackAuthContext,
  useDigitalFormsWritebackSettingsContext,
} from '@frontend/digital-forms-scope';
import { useTranslation } from '@frontend/i18n';
import { Text } from '@frontend/design-system';
import { useWritebackWidgetContext } from '../../../../providers';
import { STATUS_CODE_FOR_SHOWING_SWITCH_UI } from './constants';
import CreatePersonSetting from './settings/create-person-setting/create-person-setting.component';
import UpdatePersonSetting from './settings/update-person-setting/update-person-setting.component';
import UploadDocumentSetting from './settings/upload-document-setting/upload-document-setting.component';
import { containerStyle } from './writeback-control.styles';

interface WritebackControlProps {
  setting: FormsWritebacks.Types.WritebackStatus;
}

const WritebackControl: FC<WritebackControlProps> = ({ setting }) => {
  const { settingName, settingStatus } = setting;
  const { t } = useTranslation('forms', { keyPrefix: 'WRITEBACK_WIDGET_V2' });
  const { locationId, submission } = useWritebackWidgetContext();

  const { hasValidWritebackAuth } = useDigitalFormsWritebackAuthContext();
  const { hasAtLeastOneActiveWritebackSetting } = useDigitalFormsWritebackSettingsContext();

  const { writebackStatusCode } = submission;

  const showSwitch = useMemo<boolean>(() => {
    if (!writebackStatusCode) {
      return false;
    }

    return (
      hasValidWritebackAuth(locationId) &&
      hasAtLeastOneActiveWritebackSetting(locationId) &&
      writebackStatusCode !== 'Fulfilled'
    );
  }, [hasValidWritebackAuth, hasAtLeastOneActiveWritebackSetting, writebackStatusCode]);

  const label = useMemo(() => {
    switch (settingName) {
      case 'Create Person':
        return t('Create Patient Record');

      case 'Update Person':
        return t('Update Patient Record');

      case 'Upload Document':
        return t('Upload Document');
    }
  }, [settingName, t]);

  const getSettingComponent = useCallback(() => {
    const shouldShowSwitch = STATUS_CODE_FOR_SHOWING_SWITCH_UI.includes(settingStatus) && showSwitch;

    switch (settingName) {
      case 'Create Person':
        return <CreatePersonSetting settingStatus={settingStatus} showSwitch={shouldShowSwitch} />;

      case 'Update Person':
        return <UpdatePersonSetting settingStatus={settingStatus} showSwitch={shouldShowSwitch} />;

      case 'Upload Document':
        return <UploadDocumentSetting settingStatus={settingStatus} showSwitch={shouldShowSwitch} />;
    }
  }, [settingName, settingStatus, showSwitch]);

  return (
    <div css={containerStyle}>
      <Text as='span'>{label}</Text>
      {getSettingComponent()}
    </div>
  );
};

export default WritebackControl;
