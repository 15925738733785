import { FieldTypes } from '../types';
import { genUID } from './uuid.utils';

/**
 * Generate a field to add to the form with a new ID.
 */
export function generateFieldToAdd(field: FieldTypes.Field): FieldTypes.Field {
  const newFieldId = genUID();
  const meta = { ...field.meta };

  // isEmpty and isError are added in medical history fields after data fetching
  if (Object.prototype.hasOwnProperty.call(meta, 'isEmpty')) {
    delete meta.isEmpty;
  }

  if (Object.prototype.hasOwnProperty.call(meta, 'isError')) {
    delete meta.isError;
  }

  return {
    ...field,
    meta,
    id: newFieldId,
  };
}
