import { css } from '@emotion/react';
import { Icon, IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';

type OperatoryAvatarProps = {
  color: string;
  iconName: IconName;
  width?: number;
  height?: number;
  disabled?: boolean;
};

const defaultWidthAndHeight = Number(theme.spacing(5));

export const OperatoryAvatar = ({
  iconName,
  color,
  width = defaultWidthAndHeight,
  height = defaultWidthAndHeight,
  disabled = false,
}: OperatoryAvatarProps) => {
  return (
    <div css={operatoryAvatarStyles(color, width, height, disabled)}>
      <Icon size={24} color='default' name={iconName} />
    </div>
  );
};

const operatoryAvatarStyles = (color: string, width: number, height: number, disabled: boolean) =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width,
    height,
    borderRadius: theme.borderRadius.medium,
    backgroundColor: color,
    padding: theme.spacing(0.5),
    opacity: disabled ? 0.5 : 1,
  });
