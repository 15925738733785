import { CalendarEvent } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/calendar_event.pb';
import { noop } from 'lodash-es';
import { getTodaysDate } from '@frontend/date';
import { createShallowStore, createStore } from '@frontend/store';
import { SelectedFiltersType } from '../types';

export type CalendarViewV3HeaderFilterStore = {
  filteredCalendarEvents: CalendarEvent[];
  selectedFilters: SelectedFiltersType;
  hasActiveFilters: boolean;
  selectedCalendarDate: string;
  selectedFilteredLocationIds: string[];
  setSelectedFilteredLocationIds: (selectedFilteredLocationIds: string[]) => void;
  setSelectedCalendarDate: (selectedCalendarDate: string) => void;
  setSelectedFilters: (selectedFilters: SelectedFiltersType) => void;
  setHasActiveFilters: (hasActiveFilters: boolean) => void;
  setFilteredCalendarEvents: (filteredCalendarEvents: CalendarEvent[]) => void;
  refetchCalendarEvents?: () => void;
  setRefetchCalendarEvents?: (refetchCalendarEvents: () => void) => void;
};

export const useCalendarViewV3HeaderFilterStore = createStore<CalendarViewV3HeaderFilterStore>(
  (set) => ({
    filteredCalendarEvents: [],
    hasActiveFilters: false,
    selectedFilters: {} as SelectedFiltersType,
    selectedCalendarDate: getTodaysDate('MM/DD/YYYY'),
    selectedFilteredLocationIds: [],
    refetchCalendarEvents: noop,
    setSelectedFilters: (selectedFilters: SelectedFiltersType) => {
      set({ selectedFilters });
    },
    setHasActiveFilters: (hasActiveFilters: boolean) => {
      set({ hasActiveFilters });
    },
    setFilteredCalendarEvents: (filteredCalendarEvents: CalendarEvent[]) => {
      set({ filteredCalendarEvents });
    },
    setSelectedCalendarDate: (selectedCalendarDate: string) => {
      set({ selectedCalendarDate });
    },
    setSelectedFilteredLocationIds: (selectedFilteredLocationIds: string[]) => {
      set({ selectedFilteredLocationIds });
    },
    setRefetchCalendarEvents: (refetchCalendarEvents: () => void) => {
      set({ refetchCalendarEvents });
    },
  }),
  {
    name: 'useCalendarViewV3HeaderFilterStore',
  }
);

export const useCalendarViewV3HeaderFilterShallowStore = createShallowStore<CalendarViewV3HeaderFilterStore>(
  useCalendarViewV3HeaderFilterStore
);
