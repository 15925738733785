import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const copyPublicLinkButtonStyle = css`
  padding: 0;

  > span {
    display: flex;
    gap: ${theme.spacing(1)};
  }
`;
