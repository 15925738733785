import { PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { demoDataUtils } from '../../../utils';
import { requiredPatientInfoFields } from './shared-helpers';

const classificationBreakpoints = [0, 12, 20, 32, 40, 50];
const patientsCount = classificationBreakpoints[classificationBreakpoints.length - 1];

const procedures = Array.from({ length: patientsCount }, () => ({
  adaCode: `D${demoDataUtils.generateRandomInt(1000, 9999)}`,
  procedureAmount: demoDataUtils.generateRandomInt(100, 2000),
}));

const patients: PracticeAnalyticsTypes.PatientInfo[] = demoDataUtils
  .generateRandomUserNames(patientsCount)
  .map(({ firstName, lastName }, index) => {
    const mockProcedures: PracticeAnalyticsTypes.Procedure[] = [procedures[index]];

    let classification = null;

    if (index < classificationBreakpoints[1]) {
      classification = PracticeAnalyticsTypes.OpportunityPatientClassificationEnum.CANCELLED_PATIENTS;
    } else if (index < classificationBreakpoints[2]) {
      classification = PracticeAnalyticsTypes.OpportunityPatientClassificationEnum.HYGIENE_FOLLOW_UP;
    } else if (index < classificationBreakpoints[3]) {
      classification = PracticeAnalyticsTypes.OpportunityPatientClassificationEnum.HYGIENE_TREATMENT_PLAN;
    } else if (index < classificationBreakpoints[4]) {
      classification = PracticeAnalyticsTypes.OpportunityPatientClassificationEnum.NEW_PATIENTS;
    } else {
      classification = PracticeAnalyticsTypes.OpportunityPatientClassificationEnum.RESTORATIVE_TREATMENT_PLAN;
    }

    return {
      ...requiredPatientInfoFields,
      classification,
      FirstName: firstName,
      LastName: lastName,
      id: `demo-${index}`,
      MobilePhone: demoDataUtils.generateRandomPhoneNumber(),
      procedures:
        classification !== PracticeAnalyticsTypes.OpportunityPatientClassificationEnum.CANCELLED_PATIENTS
          ? mockProcedures
          : [],
      productionAmount: demoDataUtils.generateRandomInt(200, 500),
    };
  });

const missedAppointments = {
  details: {
    patients: patients.slice(0, classificationBreakpoints[1]),
  },
};

const hygieneReappointment = {
  details: {
    patients: patients.slice(classificationBreakpoints[1], classificationBreakpoints[2]),
  },
};

const hygieneTreatmentPlan = {
  details: {
    patients: patients.slice(classificationBreakpoints[2], classificationBreakpoints[3]),
  },
};

const newPatients = {
  details: {
    patients: patients.slice(classificationBreakpoints[3], classificationBreakpoints[4]),
  },
};

const restorativeTreatmentPlan = {
  details: {
    patients: patients.slice(classificationBreakpoints[4], classificationBreakpoints[5]),
  },
};

export const opportunityList = (): PracticeAnalyticsTypes.OpportunityList => ({
  location: {
    hygieneReappointment,
    hygieneTreatmentPlan,
    missedAppointments,
    newPatients,
    restorativeTreatmentPlan,
  },
});
