import { FC } from 'react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, Text } from '@frontend/design-system';
import { useFeatureUpgradeDetailsModalContext } from '../context';

interface TitleContentProps {
  title: string;
  subTitle?: string;
  price?: number;
  term?: 'monthly' | 'annually';
}

export const TitleContent: FC<TitleContentProps> = ({ title, price, subTitle, term = 'monthly' }) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'feature-upgrade-details-modal' });
  const { isTitleSectionVisible } = useFeatureUpgradeDetailsModalContext();

  return (
    <section
      style={{
        opacity: isTitleSectionVisible ? 1 : 0,
        maxHeight: isTitleSectionVisible ? '80px' : 0,
        transition: 'opacity 0.5s ease, max-height 0.5s ease',
      }}
    >
      <div css={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Heading level={1}>{title}</Heading>
        {!!price && (
          <div>
            <Text css={{ fontSize: theme.fontSize(24) }} weight='bold' as='span' size='large'>
              ${price}
            </Text>
            <Text as='span'>{term === 'monthly' ? t('/mo') : t('/year')}</Text>
          </div>
        )}
      </div>
      <Text size='large'>{subTitle}</Text>
    </section>
  );
};
