import { FC } from 'react';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { FormsSubmission, FormsWritebacks } from '@frontend/api-forms';
import { Icon } from '@frontend/icons';
import { Text, useTooltip, IconButton, SpinningLoader } from '@frontend/design-system';
import { pendoTags } from '../../../../../../shared/constants';
import { convertUTCtoISO, isDefaultUTCDate } from '../../../../../../shared/utils';
import { useSubmissionPDF } from '../../../../hooks/useSubmissionPDF';
import {
  formListItemStyle,
  formIconAndNameContainerStyle,
  formNameStyle,
  downloadAndCompletedIconsContainerStyle,
  downloadIconButtonStyle,
  dateTextHoverLabelStyle,
  checkIconStyle,
  formNotSubmittedStyles,
} from './form-list-item.styles';

dayjs.extend(relativeTime);

export interface FormListCommonProps {
  onFormClick: (formIndex: number) => void;
  submissionId: string;
  writebackStatusCode?: FormsWritebacks.Types.WritebackStatusCode;
  submittedAt?: string;
  hasPMSIntegration?: boolean;
}

interface FormListItemProps extends FormListCommonProps {
  form: FormsSubmission.Types.NormalizedFormDetails;
  isSubmitted: boolean;
  onFormClick: () => void;
}

const FormListItem: FC<React.PropsWithChildren<FormListItemProps>> = ({
  form,
  isSubmitted,
  submissionId,
  onFormClick,
  submittedAt,
}) => {
  const {
    triggerProps: hoverTriggerProps,
    tooltipProps: labelProps,
    Tooltip: HoverLabel,
  } = useTooltip({ placement: 'top' });

  const {
    triggerProps: hoverTriggerPropsForDownloadPDFHover,
    tooltipProps: labelPropsForDownloadPDFHover,
    Tooltip: HoverLabelForDownloadPDF,
  } = useTooltip({ placement: 'top' });

  const { isLoadingPDF, downloadPDF } = useSubmissionPDF({
    submissionId,
    formId: form.formId,
    pdfName: form.formName ?? '',
  });

  function getSubmittedDateAsText() {
    if (!submittedAt || isDefaultUTCDate(submittedAt)) {
      return '';
    }

    const date = new Date(convertUTCtoISO(submittedAt));
    return dayjs().to(dayjs(date));
  }

  return (
    <div css={formListItemStyle}>
      <div
        css={formIconAndNameContainerStyle}
        style={{ cursor: isSubmitted ? 'pointer' : 'default' }}
        onClick={isSubmitted ? onFormClick : undefined}
        data-trackingid={pendoTags.viewFormPreview}
      >
        <Icon name='forms' color='subdued' />
        <Text size='medium' css={formNameStyle} title={form.formName}>
          {form.formName}
        </Text>
      </div>

      {isSubmitted ? (
        <div css={downloadAndCompletedIconsContainerStyle}>
          <IconButton
            label=''
            {...hoverTriggerPropsForDownloadPDFHover}
            onClick={downloadPDF}
            css={downloadIconButtonStyle}
            trackingId={pendoTags.attachmentDownload}
          >
            {isLoadingPDF ? <SpinningLoader size='small' /> : <Icon name='download' />}
          </IconButton>

          <HoverLabelForDownloadPDF {...labelPropsForDownloadPDFHover}>Download PDF</HoverLabelForDownloadPDF>

          <span {...hoverTriggerProps}>
            <Icon name='check' css={checkIconStyle} />
          </span>

          <HoverLabel {...labelProps}>
            {'Submitted'} <Text css={dateTextHoverLabelStyle}>{getSubmittedDateAsText()}</Text>
          </HoverLabel>
        </div>
      ) : (
        <Text css={formNotSubmittedStyles} color='light' size='small'>
          Not Submitted
        </Text>
      )}
    </div>
  );
};

export default FormListItem;
