import { Schedule } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/schedule.pb';
import { EventData } from '../../components/calendar-view/types';
import { useGetCalendarEventsV3 } from './use-get-calendar-events-v3';

type UseGetOfficeHoursDetailsForCalendarV3ParamsType = {
  schedules: Schedule[];
};

export const useGetOfficeHoursDetailsForCalendarV3 = ({
  schedules,
}: UseGetOfficeHoursDetailsForCalendarV3ParamsType) => {
  const { getEntityBreakEvents, getEntityExceptionEvents } = useGetCalendarEventsV3();

  const getOfficeHoursBreaksAndExceptionEventsForCalendarObject = (locationId: string): EventData[] => {
    const breakEventList = getEntityBreakEvents({
      locationId,
      schedules,
      entityId: locationId,
    });

    const exceptionEventList = getEntityExceptionEvents({
      locationId,
      schedules,
      entityId: locationId,
    });

    return [...breakEventList, ...exceptionEventList];
  };

  return {
    getOfficeHoursBreaksAndExceptionEventsForCalendarObject,
  };
};
