import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  align-items: baseline;
  gap: ${theme.spacing(1)};
`;

export const iconStyle = css`
  margin-inline-start: auto;
  flex-shrink: 0;
`;
