import { Dispatch, SetStateAction, useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { OutboundSelectedPhoneNumberProps } from '@frontend/generic-dialpad-accessories';
import { useSoftphoneClient } from './softphone-client-provider';
import { useSoftphoneEventsEmitter } from './softphone-events-provider';

type SoftphoneSettingsContextValue = {
  showSettings: () => void;
  hideSettings: () => void;
  isShowingSettings: boolean;
  isDoNotDisturb: boolean;
  enableDoNotDisturb: () => void;
  disableDoNotDisturb: () => void;
  turnOffSoftphone: () => void;
  restart: () => void;
  availablePhoneNumbers: OutboundSelectedPhoneNumberProps[];
  setCurrentPhoneNumber: Dispatch<SetStateAction<OutboundSelectedPhoneNumberProps | undefined>>;
  selectedOutboundPhoneNumber: OutboundSelectedPhoneNumberProps | undefined;
  setIsCallWaitingIndicatorBeepEnabled: (toggle: boolean) => void;
  isCallWaitingIndicatorBeepEnabled: boolean;
};

const SoftphoneSettingsContext = createContext({} as SoftphoneSettingsContextValue);

type Props = {
  onTurnOffSoftphone: () => void;
  children: React.ReactNode;
  onRestart: () => void;
  setCurrentPhoneNumber: Dispatch<SetStateAction<OutboundSelectedPhoneNumberProps | undefined>>;
  availablePhoneNumbers: OutboundSelectedPhoneNumberProps[];
  selectedOutboundPhoneNumber: OutboundSelectedPhoneNumberProps | undefined;
  setIsCallWaitingIndicatorBeepEnabled: (toggle: boolean) => void;
  isCallWaitingIndicatorBeepEnabled: boolean;
};

export const SoftphoneSettingsProvider = ({
  onRestart,
  onTurnOffSoftphone,
  availablePhoneNumbers,
  setCurrentPhoneNumber,
  selectedOutboundPhoneNumber,
  setIsCallWaitingIndicatorBeepEnabled,
  isCallWaitingIndicatorBeepEnabled,
  children,
}: Props) => {
  const [isShowingSettings, setIsShowingSettings] = useState(false);
  const [isDoNotDisturb, setIsDoNotDisturb] = useState(false);
  const emitter = useSoftphoneEventsEmitter();
  const registerCallId = useSoftphoneClient((ctx) => ctx.registerCallId);

  const showSettings = () => {
    setIsShowingSettings(true);
  };

  const hideSettings = () => {
    setIsShowingSettings(false);
  };

  const enableDoNotDisturb = () => {
    emitter.emit('do-not-disturb.enabled', null);
    setIsDoNotDisturb(true);
  };

  const disableDoNotDisturb = () => {
    emitter.emit('do-not-disturb.disabled', null);
    setIsDoNotDisturb(false);
  };

  const restart = () => {
    onRestart();
    if (registerCallId) emitter.emit('softphone.restart', { callID: registerCallId });
  };

  const value = {
    showSettings,
    hideSettings,
    isShowingSettings,
    turnOffSoftphone: onTurnOffSoftphone,
    isDoNotDisturb,
    enableDoNotDisturb,
    disableDoNotDisturb,
    restart,
    availablePhoneNumbers,
    setCurrentPhoneNumber,
    selectedOutboundPhoneNumber,
    setIsCallWaitingIndicatorBeepEnabled,
    isCallWaitingIndicatorBeepEnabled,
  } satisfies SoftphoneSettingsContextValue;

  return <SoftphoneSettingsContext.Provider value={value}>{children}</SoftphoneSettingsContext.Provider>;
};

export const useSoftphoneSettings = <T extends any>(selector: (value: SoftphoneSettingsContextValue) => T) => {
  return useContextSelector(SoftphoneSettingsContext, selector);
};
