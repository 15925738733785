import { useAppScopeStore } from '@frontend/scope';

export const useSchedulingLocationInfo = () => {
  const state = useAppScopeStore();

  return {
    isMultiLocation: state.selectedParentsIds.length > 0,
    parentLocationId: state.selectedParentsIds[0] || '',
    ...state,
  };
};
