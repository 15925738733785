import { FC, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { FaxApi, FaxTypes } from '@frontend/api-fax-legacy';
import { useTranslation } from '@frontend/i18n';
import { downloadCSV } from '@frontend/media-helpers';
import { theme } from '@frontend/theme';
import {
  DateRangeField,
  PrimaryButton,
  SpinningLoader,
  TextField,
  useDebouncedValue,
  useForm,
  useAlert,
} from '@frontend/design-system';

interface Props {
  filters: FaxTypes.FaxFilters;
  onChange: (values: FaxTypes.FaxFilters) => void;
  query?: string;
}

export const FaxFilters: FC<React.PropsWithChildren<Props>> = ({ filters, onChange, query }) => {
  const { t } = useTranslation('fax');
  const [isExporting, setIsExporting] = useState<boolean>();
  const alert = useAlert();

  const { getFieldProps, values } = useForm({
    fields: {
      dateRange: { type: 'dateRange', value: filters.dateRange },
      recipientNumber: { type: 'phone', value: filters.recipientNumber },
      senderNumber: { type: 'phone', value: filters.senderNumber },
    },
  });

  const senderChanged = useDebouncedValue(values.senderNumber, 500);
  const recipientChanged = useDebouncedValue(values.recipientNumber, 500);

  const exportCSV = async () => {
    setIsExporting(true);

    try {
      const data = await FaxApi.exportFaxes(query);
      downloadCSV(data, 'fax-history');
      setIsExporting(false);
    } catch (e) {
      alert.error(t('Unable to export fax history!'));
      setIsExporting(false);
    }
  };

  useEffect(() => {
    onChange(values);
  }, [senderChanged, recipientChanged, values.dateRange]);

  return (
    <form css={formStyles}>
      <TextField {...getFieldProps('recipientNumber')} label={t('Recipient number')} placeholder={t('Optional')} />
      <TextField {...getFieldProps('senderNumber')} label={t('Sender number')} placeholder={t('Optional')} />
      <DateRangeField {...getFieldProps('dateRange')} label={t('Faxes Date Range')} />
      <PrimaryButton disabled={isExporting} onClick={exportCSV}>
        {isExporting ? <SpinningLoader size='small' /> : t('Export')}
      </PrimaryButton>
    </form>
  );
};

const formStyles = css`
  display: flex;
  gap: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(2)};

  > button {
    width: 100px;
    margin-top: ${theme.spacing(0.5)};
  }
`;
