import { forwardRef } from 'react';
import { useEffect } from 'react';
import { Avatar } from '@frontend/design-system';
import { usePhotoStore } from './use-photo-store';

type ProfilePhotoTypes = { personId: string; name?: string } & React.ComponentProps<typeof Avatar>;

export const ProfilePhoto = forwardRef<HTMLElement, ProfilePhotoTypes>(
  ({ personId, children, firstName, lastName, name, src, ...rest }: ProfilePhotoTypes, ref) => {
    const handleGetPicture = usePhotoStore((state) => state.handleGetPicture);
    const photoId = usePhotoStore((state) => state.photoRecord[personId]);

    useEffect(() => {
      if (personId) {
        handleGetPicture(personId);
      }
    }, [personId]);

    return (
      <Avatar src={photoId || src} ref={ref} firstName={firstName} lastName={lastName} name={name} {...rest}>
        {children}
      </Avatar>
    );
  }
);
