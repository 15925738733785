import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { ForwardingNumberApi } from '@frontend/api-forwarding-number';
import { i18next } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { SkeletonLoader } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { InstructionFieldsProps, ForwardNumberPayload } from '../types';
import { InstructionFields } from './base';

export const ForwardNumberInstructionFields = ({
  onChange,
  option,
}: {
  onChange: InstructionFieldsProps<ForwardNumberPayload>['onChange'];
  option: ForwardNumberPayload;
}) => {
  const { singleLocationId } = useAppScopeStore();

  const { data = [], isLoading } = useQuery({
    queryKey: queryKeys.forwardingNumbers(),
    queryFn: () => ForwardingNumberApi.list({ locationId: singleLocationId }),
    select: (data) => {
      return data
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((number) => ({
          value: number.id,
          label: number.name,
        }));
    },
    staleTime: 0,
  });

  useEffect(() => {
    if (!option.forwardingNumberId && data.length > 0) {
      onChange({ forwardingNumberId: data[0].value ?? '' });
    }
  }, [option.forwardingNumberId, data]);
  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <InstructionFields<ForwardNumberPayload>
      onChange={onChange}
      schema={schema}
      customization={{
        optionGroups: {
          forwardingNumberId: data,
        },
        value: {
          forwardingNumberId: option.forwardingNumberId || (data.length > 0 ? data[0].value : ''),
        },
        meta: {
          forwardingNumberId: {
            locationId: singleLocationId,
          },
        },
      }}
    />
  );
};

const schema = {
  type: 'forwardNumber',
  fields: {
    forwardingNumberId: {
      label: i18next.t('Forwarding Number', { ns: 'phone' }),
      name: 'forwardingNumberId',
      type: 'dropdown',
      kind: 'forwardingNumber',
      meta: {
        locationId: '',
      },
    },
  },
} as const;
