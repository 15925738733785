import { memo, useMemo } from 'react';
import { useLocation } from '@tanstack/react-location';
import dayjs from 'dayjs';
import {
  AnalyticsCommonTypes,
  PracticeAnalyticsAggregations,
  PracticeAnalyticsApi,
  PracticeAnalyticsTypes,
} from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { TableLoadingSkeleton, emptyStateGraphics } from '@frontend/design-system';
import { useAnalyticsOrgLocations, useShowBulkMessageButton } from '../../hooks';
import { trackingIds } from '../../tracking-ids';
import { ExportUtils, formatters } from '../../utils';
import { commonStyles } from '../../views';
import { ColumnHeaderInfoTip } from '../column-header-info-tip';
import { InfoTipPopover } from '../info-tip-popover';
import { LocationChip } from '../location-chip';
import { TabbedTable } from '../tabbed-table';
import { TableActions } from '../table-actions';
import { UserCard } from '../user-card';
import { BulkMessageButton } from './bulk-message-button';
import { PatientsHelpers, getLast24WeeksDates } from './helpers';
import { usePatientsLastContacted, usePracticeAnalyticsShallowStore } from './hooks';

type Props = {
  data?: ReturnType<typeof PracticeAnalyticsAggregations.activePatientsScheduledDetails>;
  isExportEnabled?: boolean;
  isLoading?: boolean;
};

export const ActivePatientsUnscheduledTable = memo(({ data, isExportEnabled, isLoading }: Props) => {
  const { t } = useTranslation('analytics');
  const { current } = useLocation();
  const isWeaveApp = !current.pathname.startsWith('/portal');
  const { filters, isDemoAccount } = usePracticeAnalyticsShallowStore('filters', 'isDemoAccount');
  const { locationNames } = useAnalyticsOrgLocations({ module: 'PA' });
  const isBulkMessagingEnabled = useShowBulkMessageButton();

  const multipleLocationsSelected = (filters.locations?.length || 0) > 1;

  // Collect all patients from all buckets and separate them into all and perio categories
  const patients = useMemo(() => {
    const perio: PracticeAnalyticsTypes.PatientInfo[] = [];
    const overduePerio: PracticeAnalyticsTypes.PatientInfo[] = [];

    const allPatients = [
      ...(data?.aggregatedData.unscheduledHygienePatients.buckets || []),
      ...(data?.aggregatedData.unscheduledNonhygienePatients.buckets || []),
    ]
      .map(({ persons }) => {
        if (persons) {
          persons.forEach((person) => {
            if (person.isPerio) {
              perio.push(person);

              if (person.dueDate && dayjs(person.dueDate).isBefore(dayjs(), 'day')) {
                overduePerio.push(person);
              }
            }
          });
        }
        return persons;
      })
      .flat() as PracticeAnalyticsTypes.PatientInfo[];

    return {
      all: allPatients,
      overduePerio,
      perio,
    };
  }, [data?.aggregatedData]);

  const lastContactedParams = useMemo(() => {
    const { startDate, endDate } = getLast24WeeksDates();

    return {
      endTime: endDate,
      patientIds: PatientsHelpers.collectPatientIds(patients.all),
      startTime: startDate,
    };
  }, [patients.all]);

  const { isLoading: isLoadingLastContacted, lastContactedDates } = usePatientsLastContacted(lastContactedParams);

  const colConfig = useMemo(() => {
    return [
      {
        Header: t('Patient Name'),
        headerLabel: t('Patient Name'),
        accessor: ({ FirstName, LastName, id }: PracticeAnalyticsTypes.PatientInfo) =>
          JSON.stringify({
            firstName: FirstName,
            lastName: LastName,
            userId: id,
          }),
        cellRenderer: (value: string) => {
          const props = JSON.parse(value);
          return <UserCard {...props} key={props.userId} openProfileOnClick={!isDemoAccount} showOnlyName />;
        },
        id: 'name',
        sticky: 'left' as AnalyticsCommonTypes.Alignment,
        width: 250,
      },
      {
        accessor: ({ locationId }: PracticeAnalyticsTypes.PatientInfo) => locationId,
        cellRenderer: (locationId: string) => {
          return locationId ? (
            <LocationChip locationName={locationNames[locationId] || locationId} maxWidth={180} />
          ) : (
            '-'
          );
        },
        disableSortBy: true,
        Header: t('Location'),
        id: 'locationName',
        omit: !multipleLocationsSelected,
        width: 220,
      },
      {
        Header: t('Phone Number'),
        headerLabel: t('Phone Number'),
        accessor: ({ HomePhone, MobilePhone }: PracticeAnalyticsTypes.PatientInfo) => MobilePhone || HomePhone,
        cellRenderer: (value: string) => (value ? formatPhoneNumber(value) : '-'),
        id: 'phone-number',
        width: 180,
      },
      {
        Header: t('Gender'),
        headerLabel: t('Gender'),
        accessor: ({ Gender }: PracticeAnalyticsTypes.PatientInfo) => Gender || '-',
        id: 'gender',
        width: 130,
      },
      {
        Header: t('Last Visit'),
        headerLabel: t('Last Visit'),
        accessor: ({ lastVisitDate }: PracticeAnalyticsTypes.PatientInfo) => lastVisitDate,
        cellRenderer: (date: string) => (date ? formatters.date.format(date) : '-'),
        id: 'lastVisitDate',
        width: 150,
      },
      {
        Header: (
          <ColumnHeaderInfoTip
            columnTitle={t('Last Contacted')}
            infoTip={
              <InfoTipPopover>
                {t('Date and Time of last outbound answered call to patient phone number')}
              </InfoTipPopover>
            }
          />
        ),
        headerLabel: t('Last Contacted'),
        accessor: ({ id }: PracticeAnalyticsTypes.PatientInfo) => lastContactedDates?.[id],
        cellRenderer: (lastContacted: string) =>
          isLoadingLastContacted ? (
            <TableLoadingSkeleton />
          ) : lastContacted ? (
            formatters.date.format(lastContacted)
          ) : (
            '-'
          ),
        id: 'lastContacted',
        width: 180,
      },
      {
        Header: t('Due Date'),
        headerLabel: t('Due Date'),
        accessor: ({ dueDate }: PracticeAnalyticsTypes.PatientInfo) => dueDate,
        cellRenderer: (date: string) => (date ? formatters.date.format(date) : '-'),
        id: 'dueDate',
        width: 200,
      },
      {
        disableSortBy: true,
        Header: t('Actions'),
        headerLabel: t('Actions'),
        headerAlign: 'center' as AnalyticsCommonTypes.Alignment | 'center',
        accessor: (patient: PracticeAnalyticsTypes.PatientInfo) => patient,
        cellRenderer: (patient: PracticeAnalyticsTypes.PatientInfo) => {
          const phoneNumber = patient.MobilePhone || patient.HomePhone;
          return (
            <TableActions
              isDemoAccount={isDemoAccount}
              personId={patient.id}
              personName={patient.FirstName}
              phoneNumber={phoneNumber}
              trackingIdBase='active-unscheduled-patient'
            />
          );
        },
        id: 'actions',
        omit: !isWeaveApp,
        sticky: 'right' as AnalyticsCommonTypes.Alignment,
        width: 128,
      },
    ];
  }, [patients, isLoadingLastContacted, lastContactedDates]);

  const tabsCommonConfig = {
    colConfig,
    emptyStateConfig: 'users' as keyof typeof emptyStateGraphics,
  };

  return (
    <TabbedTable
      actions={BulkMessageButton}
      autorizedToExport={PracticeAnalyticsApi.auditPracticeAnalyticsExport}
      isDemoAccount={isDemoAccount}
      exportFileName='Active Unscheduled Patients' // Translation not needed
      initialTab='all'
      isExportReady={!isLoading && !isLoadingLastContacted}
      isLoading={isLoading}
      isSelectable={isBulkMessagingEnabled}
      processExportableData={(data) =>
        // Translation is not needed as this is for export purpose
        ExportUtils.processExportableData({
          columns: [
            'Patient Name',
            ...(multipleLocationsSelected ? ['Location Name'] : []),
            'Phone Number',
            'Email',
            'Gender',
            'Last Visit',
            'Last Contacted',
            'Smart Due Date',
          ],
          data,
          deriveExportValue: (params) =>
            PatientsHelpers.deriveExportValue({
              ...params,
              lastContactedDates,
              locationNames,
            }),
        })
      }
      showExportIcon={isExportEnabled}
      tabs={{
        all: {
          ...tabsCommonConfig,
          data: patients?.all || [],
          label: t('All'),
          tableInstanceId: 'all-unscheduled-patients',
          trackingId: trackingIds.practiceAnalytics.allUnscheduledPatientsTab,
        },
        perio: {
          ...tabsCommonConfig,
          data: patients?.perio || [],
          label: t('Perio'),
          tableInstanceId: 'perio-patients',
          trackingId: trackingIds.practiceAnalytics.perioPatientsTab,
        },
        overduePerio: {
          ...tabsCommonConfig,
          data: patients?.overduePerio || [],
          label: t('Overdue Perio'),
          tableInstanceId: 'overdue-perio-patients',
          trackingId: trackingIds.practiceAnalytics.overduePerioPatientsTab,
        },
      }}
      trackingIdBase='unscheduled-patients'
      wrapperStyle={commonStyles.drillDownTableSize}
    />
  );
});

ActivePatientsUnscheduledTable.displayName = 'ActivePatientsUnscheduledTable';
