import { useCallback, useEffect, useMemo, useState } from 'react';
import { SchedulerV3Queries } from '@frontend/api-scheduler-v3';
import { useTranslation } from '@frontend/i18n';
import { FormRow, useDebouncedValue, useFormField } from '@frontend/design-system';
import { useGetAppointmentTypeInfiniteListForV3 } from '../../hooks';
import {
  CustomMultiSelectInfiniteDropdown,
  CustomMultiSelectInfiniteDropdownOptionType,
} from '../CustomMultiSelectInfiniteDropdown';

interface Props {
  locationId: string;
  practitionerId: string;
}

export const useAppointmentTypeCheckListDropdown = ({ locationId, practitionerId }: Props) => {
  const { t } = useTranslation('schedule');
  const [selectedItems, setSelectedItems] = useState<CustomMultiSelectInfiniteDropdownOptionType[]>([]);
  const searchFieldProps = useFormField({ type: 'text' });
  const searchText = useDebouncedValue(searchFieldProps.value);
  const appointmentTypeInfiniteListQuery = useGetAppointmentTypeInfiniteListForV3({
    locationId,
    searchText: searchText.length > 1 ? searchText : '',
  });
  const [hasError, setHasError] = useState(false);

  const practitionerAppointmentTypesQuery = SchedulerV3Queries.useGetAppointmentTypesPractitioners({
    locationIds: [locationId],
    practitionerId: practitionerId,
  });

  const initialSelectedItems = useMemo(
    () =>
      (practitionerAppointmentTypesQuery.data?.appointmentTypesPractitioners || []).reduce<
        CustomMultiSelectInfiniteDropdownOptionType[]
      >((acc, item) => {
        if (item.practitionerMeta.practitionerId === practitionerId) {
          acc.push({
            label: item.appointmentTypeMeta.appointmentTypeName,
            value: item.appointmentTypeMeta.appointmentTypeId,
          });
        }
        return acc;
      }, []),
    [practitionerAppointmentTypesQuery.data, practitionerId]
  );

  useEffect(() => {
    setSelectedItems(initialSelectedItems);
  }, [initialSelectedItems]);

  // hide dropdown if no appointment types loaded and no search text
  const shouldHideDropdown = !searchText && appointmentTypeInfiniteListQuery.data?.pages[0].rows.length === 0;
  return {
    hasAppointmentTypeCheckListDropdownError: hasError,
    getAppointmentTypeSelectedUnselectedValues: useCallback(() => {
      const selectedValues = selectedItems.map((item) => item.value);
      const initialValues = initialSelectedItems.map((item) => item.value);
      return {
        selected: selectedValues.filter((value) => !initialValues.includes(value)),
        unselected: initialValues.filter((value) => !selectedValues.includes(value)),
      };
    }, [selectedItems]),
    AppointmentTypeCheckListDropdownElement: !shouldHideDropdown && (
      <FormRow>
        <CustomMultiSelectInfiniteDropdown
          label={t('Appointment Types')}
          placeholder={t('Select appointment types')}
          searchFieldProps={searchFieldProps}
          infiniteQueryResult={appointmentTypeInfiniteListQuery}
          getOptionInfo={(appointment) => ({
            label: appointment?.displayName || appointment?.externalName || '',
            value: appointment?.id || '',
          })}
          setHasError={setHasError}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          isLoading={practitionerAppointmentTypesQuery.isFetching}
        />
      </FormRow>
    ),
  };
};
