import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

const containerStyle = css`
  background-color: ${theme.colors.neutral5};
  padding: ${theme.spacing(2)};
  border: 1px solid ${theme.colors.neutral20};
  border-radius: ${theme.borderRadius.small};
  position: relative;
  margin-bottom: ${theme.spacing(6)};
  max-width: 900px;

  &:last-of-type {
    margin-bottom: ${theme.spacing(2)};
  }
`;

interface ContainerStyleConfig {
  isInvalid: boolean;
}

export function getContainerStyle({ isInvalid }: ContainerStyleConfig) {
  if (isInvalid) {
    return [
      containerStyle,
      css`
        border-color: ${theme.colors.critical50};
        border-width: 2px;
      `,
    ];
  }

  return containerStyle;
}
