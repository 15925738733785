import { FC } from 'react';
import { AnimatePresence } from 'framer-motion';
import { isWeaveUser } from '@frontend/auth-helpers';
import { PaddedContent } from '@frontend/chat';
import { AsidePanel } from '@frontend/components';
import { ContentLoader, Button, Text } from '@frontend/design-system';
import { useTeamChatStore } from '../providers';
import { ChatListView } from './chat-list/chat-list-view/chat-list-view';
import { paddedStyle, panelStyle, weaveUserContainerStyle, chatsContainerStyle } from './chat-tray.styles';
import { Conversation } from './conversation';
import { TrayTopBar } from './tray-top-bar/tray-top-bar';

export const ChatPanel: FC = () => {
  const { isInitializing, isTrayOpen, setTrayOpen } = useTeamChatStore(['setTrayOpen', 'isInitializing', 'isTrayOpen']);

  if (isWeaveUser()) {
    return (
      <AnimatePresence>
        {isTrayOpen && (
          <AsidePanel width='xlarge' css={panelStyle}>
            <PaddedContent css={paddedStyle}>
              <div css={weaveUserContainerStyle}>
                <Button iconName='x' aria-label='close' variant='secondary' onClick={() => setTrayOpen(!isTrayOpen)} />
              </div>
              <Text>Weave users do not have access to Team Chat</Text>
            </PaddedContent>
          </AsidePanel>
        )}
      </AnimatePresence>
    );
  }

  return (
    <AnimatePresence>
      {isTrayOpen && (
        <AsidePanel width='xlarge' css={panelStyle}>
          <TrayTopBar onClose={() => setTrayOpen(!isTrayOpen)} />
          <div css={chatsContainerStyle}>
            <ChatListView />
            <Conversation />
          </div>
          <ContentLoader show={isInitializing} />
        </AsidePanel>
      )}
    </AnimatePresence>
  );
};
