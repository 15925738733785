import { CustomizationFlagTypes } from '@frontend/api-customization-flags';
import type { DecodedToken, PortalUser } from '@frontend/auth-helpers';

export const initThirdPartyScriptReady = createSubject();
export const userIdentityChanged = createSubject<{
  user: PortalUser;
  decodedWeaveToken?: DecodedToken;
  isWeaveUser: boolean;
  locationId: string;
  customizationFlags?: CustomizationFlagTypes.CustomizationFlag[];
}>();

// Reactive Subject Utility

type ListenerWithOptionalData<TData> = TData extends undefined ? () => void : (data: TData) => void;
function createSubject<
  TData = undefined,
  TListener extends ListenerWithOptionalData<TData> = ListenerWithOptionalData<TData>
>() {
  const listeners = new Set<TListener>();

  const notify: TListener = ((data: TData) => {
    listeners.forEach((fn) => fn(data));
  }) as unknown as TListener;

  return {
    addListener: (fn: TListener) => {
      listeners.add(fn);
    },
    notify,
    removeListener: (fn: TListener) => {
      listeners.delete(fn);
    },
  };
}
