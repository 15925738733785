import { css } from '@emotion/react';
import { Batch } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/batch/v1/batch.pb';
import { Campaign } from '@weave/schema-gen-ts/dist/schemas/messaging/bulk/v2';
import { useTranslation } from '@frontend/i18n';
import { ModalControlModalProps, Text } from '@frontend/design-system';
import { BulkMessageModal, BulkMessageButtonBar } from './bulk-message-modal';

export const BulkMessageDeleteModal = ({
  campaign,
  modalProps,
  onDelete,
  onCancel,
}: {
  campaign?: Batch | Campaign;
  modalProps: ModalControlModalProps;
  onDelete: () => void;
  onCancel: () => void;
}) => {
  const { t } = useTranslation('messages');
  return (
    <BulkMessageModal {...modalProps} onClose={onCancel} title={t('Cancel Bulk Message')}>
      <Text
        css={css`
          text-align: left;
        `}
      >
        {t('Are you sure you want to delete {{bulkMessageName}}? This action cannot be undone.', {
          bulkMessageName: campaign?.title || t('this bulk message'),
        })}
      </Text>
      <BulkMessageModal.ButtonContainer>
        <BulkMessageButtonBar
          onAction={onDelete}
          onCancel={onCancel}
          cancelText={t('Cancel')}
          actionText={t('Delete Message')}
        />
      </BulkMessageModal.ButtonContainer>
    </BulkMessageModal>
  );
};
