import { css } from '@emotion/react';
import { Button, Page } from '@frontend/generic-dialpad-accessories';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { CallInformation } from '../components/call-information';
import { PageHeaderWraps } from '../components/generic/page-header-wraps';
import { Row } from '../components/generic/row/row';
import { useSoftphoneCallState } from '../providers/softphone-call-state-provider';
import { useSoftphoneCallActions } from '../providers/softphone-call-state-provider/softphone-call-actions.provider';
import { EstablishedCall, TerminatedCall, isEstablishedCall } from '../types';

export const PageMergedCallList = () => {
  const { t } = useTranslation('softphone');
  const mergedCallGroup = useSoftphoneCallState((ctx) => ctx.mergedCallGroup);

  return (
    <Page>
      <PageHeaderWraps hasUnderline={false} hasBackButton={true}>
        <Text color='white'>{t('Back to Call')}</Text>
      </PageHeaderWraps>
      <Page.Body>
        <Text
          color='white'
          css={css`
            margin: ${theme.spacing(2, 0)};
          `}
        >
          {t('Manage Participants')}
        </Text>
        <ul
          css={css`
            padding: 0;
            list-style-type: none;
          `}
        >
          {mergedCallGroup?.map((call) => (
            <Call key={call.id} call={call} />
          ))}
        </ul>
      </Page.Body>
    </Page>
  );
};

type Props = {
  call: EstablishedCall | TerminatedCall;
};
const Call = ({ call }: Props) => {
  const hangup = useSoftphoneCallActions((ctx) => ctx.endCall);

  return (
    <Row
      css={css`
        margin-bottom: ${theme.spacing(3)};
        justify-content: space-between;
      `}
      transparentBackground
      disableHover
    >
      <CallInformation textAlign='left' hideContactLink {...call.remoteParty} />
      <Button.Round
        size='medium'
        disabled={!isEstablishedCall(call)}
        iconName={'phone-hangup'}
        color='red'
        trackingId='softphone-multicall-endone'
        onClick={() => {
          hangup(call);
        }}
      />
    </Row>
  );
};
