import { ComponentProps, useEffect, useState } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { IconButton } from '@frontend/design-system';
import { ThreadHeaderSize } from '../types';
import { ContactListItem } from './contact-list-item';

type ContextContactListItemProps = Omit<ComponentProps<typeof ContactListItem>, 'children'> & {
  onClose: () => void;
  size?: ThreadHeaderSize;
};

export const ContextContactListItem = ({ onClose, ...rest }: ContextContactListItemProps) => {
  const { t } = useTranslation('thread-header');
  const [highlight, setHighlight] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setHighlight(false);
    }, 1500);
  }, []);

  return (
    <ContactListItem
      css={[
        { transition: 'background-color 300ms ease-in-out' },
        highlight && {
          backgroundColor: theme.colors.warning5,
        },
      ]}
      {...rest}
    >
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          onClose();
        }}
        label={t('Close Context Contact View')}
        size='small'
      >
        <Icon
          name='collapse-right-small'
          css={{
            rotate: '-90deg',
          }}
        />
      </IconButton>
    </ContactListItem>
  );
};
