import { GetSoftphoneSettingsRequest } from '@weave/schema-gen-ts/dist/schemas/phone/softphones/softphones.pb';
import { UseQueryOptions } from 'react-query';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useQuery } from '@frontend/react-query-helpers';
import { getCurrentUserSoftphoneData, getUserSoftphone } from './api';

export const queryKeys = {
  getUserSoftphone: (userId: string) => ['softphone', userId],
  getCurrentUserSoftphone: ['softphone', 'settings', 'current'],
};

export const useGetUserSoftphoneQuery = (
  userId: string,
  opts?: UseQueryOptions<Awaited<ReturnType<typeof getUserSoftphone>>>
) =>
  useLocalizedQuery({
    queryKey: queryKeys.getUserSoftphone(userId),
    queryFn: () => getUserSoftphone(userId),
    ...opts,
  });

export const useGetCurrentUserSoftphoneQuery = ({
  req,
  opts,
}: {
  req?: GetSoftphoneSettingsRequest | undefined;
  opts?: UseQueryOptions<Awaited<ReturnType<typeof getCurrentUserSoftphoneData>>> & {
    tenantId: string;
  };
}) =>
  useQuery({
    queryKey: [opts?.tenantId, ...queryKeys.getCurrentUserSoftphone],
    queryFn: () => getCurrentUserSoftphoneData({ req }),
    ...opts,
  });
