import { FC, memo, useMemo } from 'react';
import { FormsWritebacks } from '@frontend/api-forms';
import { useTranslation } from '@frontend/i18n';
import { Chip } from '@frontend/design-system';
import { containerStyle } from './setting-status.styles';
import { SettingWritebackStatusCode } from './setting-status.types';

interface SettingStatusProps {
  settingWritebackStatusCode: SettingWritebackStatusCode;
  reason?: FormsWritebacks.Types.WritebackNAReason;
}

const SettingStatus: FC<SettingStatusProps> = ({ settingWritebackStatusCode, reason }) => {
  const { t } = useTranslation('forms', { keyPrefix: 'WRITEBACK_WIDGET_V2' });

  const chipToDisplay = useMemo(() => {
    if (reason === 'WRITEBACK_INCAPABLE_SRC' || reason === 'WRITEBACK_NA_CC' || reason === 'WRITEBACK_NA_CSV') {
      return <Chip variant='disabled'>{t('Not Applicable')}</Chip>;
    }

    switch (settingWritebackStatusCode) {
      case 'Failed':
        return <Chip variant='critical'>{t('Failed Sync')}</Chip>;

      case 'Fulfilled':
        return <Chip variant='success'>{t('Synced')}</Chip>;

      case 'Pending':
        return <Chip variant='eggplant'>{t('Not Synced')}</Chip>;

      case 'Not Applicable':
        return <Chip variant='disabled'>{t('Not Applicable')}</Chip>;

      case 'Writeback disabled':
        return <Chip variant='disabled'>{t('Writeback disabled')}</Chip>;

      case 'Manual':
        return <Chip variant='warn'>{t('Needs Manual Sync')}</Chip>;

      default:
        return <></>;
    }
  }, [settingWritebackStatusCode, reason, t]);

  return <div css={containerStyle}>{chipToDisplay}</div>;
};

export default memo(SettingStatus);
