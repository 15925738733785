import { useRef } from 'react';
import { getUser, getWeaveToken, isWeaveTokenActive, onWeaveTokenChange } from '@frontend/auth-helpers';
import { pendo } from '@frontend/tracking';
import { isValidWebsocketUrl } from '@frontend/uri';
import { WebsocketEventPayload } from './events';
import { HeartbeatState, WebsocketState, WebsocketWithLocations } from './types';
import {
  sendPing,
  INITIAL_RECONNECT_WAIT,
  MAX_RECONNECT_WAIT_INTERVAL,
  HEARTBEAT_INTERVAL,
  subscribeLocations,
} from './utils';
import { connect, getWebsocketState } from './websocket-connection';

// These are "global" to this file because they should only be initialized once, there
// *should* only ever be one websocket connection at a time in any given app that uses this service.
let heartbeatCheckInterval: number | undefined;
const heartbeatState: HeartbeatState = {
  lastHeartbeatAt: null,
  lastPingSentAt: null,
  connectAttemptStartedAt: null,
  reconnectWaitInterval: INITIAL_RECONNECT_WAIT,
  inReconnectLoop: false,
  refreshTokenStartedAt: null,
};
let currentSelectedLocations: string[] = [];

function getWebsocketUrl(websocketApi: string) {
  const weaveToken = getWeaveToken();
  if (!isWeaveTokenActive()) {
    console.warn("Weave token isn't active, return empty string");
    return '';
  }
  const params = new URLSearchParams({ token: weaveToken || '' }).toString();
  return weaveToken ? `${websocketApi}?${params}` : '';
}

/**
 * This is the main hook that builds up wrappers around the websocket connection to handle
 *   connection state, reconnection, and heartbeats. It is a Websocket Client Manager of sorts.
 * @param param0
 * @returns
 */
export const useWebSocket = ({
  onMessage,
  selectedLocationIds,
  refreshAuthToken,
  websocketApi,
}: {
  onMessage: (payload: WebsocketEventPayload) => void;
  selectedLocationIds: string[];
  refreshAuthToken: () => Promise<string>;
  websocketApi: string;
}) => {
  const initialUrl = getWebsocketUrl(websocketApi);
  // Use state so that socket updates will be emitted to consuming components
  const socket = useRef<WebsocketWithLocations>();

  /**
   * This should be used to set a new websocket connection in order be sure that the old websocket
   *   connection is closed. This is necessary to prevent duplicate messages being received and
   *   delivered to subscribers.
   */
  const replaceCurrentWs = (ws: WebsocketWithLocations) => {
    if (socket.current) {
      socket.current.onmessage = null;
      socket.current.onerror = null;
      socket.current.onclose = null;
      socket.current.close();
    }
    socket.current = ws;
  };

  const sendWsTrackEvent = (eventName: string) => {
    pendo?.track(eventName, {
      networkOnline: navigator.onLine,
      visitorId: getUser()?.userID || '',
      locationIds: selectedLocationIds,
      userAgent: navigator.userAgent,
      location: window.location.href,
    });
  };

  const setupForNewToken = () => {
    if (socket.current) {
      socket.current.onmessage = null;
      socket.current.onerror = null;
      socket.current.onclose = null;
      socket.current.close();
    }
    heartbeatState.refreshTokenStartedAt = null;
    heartbeatState.lastHeartbeatAt = null;
    heartbeatState.lastPingSentAt = null;
    heartbeatState.connectAttemptStartedAt = null;
    heartbeatState.inReconnectLoop = false;
    reevaluateCurrentState();
  };

  const initiateTokenRefresh = () => {
    // Immediately upon successful refresh of the user's token, reevaluate the current state
    heartbeatState.refreshTokenStartedAt = Date.now();
    refreshAuthToken().then(setupForNewToken);
  };

  const reevaluateCurrentState = () => {
    // Check in on the state of the websocket connection and determine if any actions need to be taken
    const state = getWebsocketState(getWebsocketUrl(websocketApi), heartbeatState);
    // Exponential backoff for reconnecting, only used if NEED_TO_RECONNECT
    const newReconnectWaitInterval = Math.min(heartbeatState.reconnectWaitInterval * 2, MAX_RECONNECT_WAIT_INTERVAL);
    switch (state) {
      // The following states are ordered in the general order you might expect a connection to go through
      case WebsocketState.WAITING_FOR_URL:
        break;
      case WebsocketState.CONNECTING:
        break;
      case WebsocketState.HEALTHY_CONNECTION:
        break;
      case WebsocketState.NEED_TO_SEND_PING:
        // This is when we have not received any server sent messages and we want to proactively check the connection
        if (socket.current) {
          sendPing(socket.current);
          heartbeatState.lastPingSentAt = Date.now();
          sendWsTrackEvent('websocket-ping-sent');
        }
        break;
      case WebsocketState.WAITING_FOR_PONG:
        break;
      case WebsocketState.NEED_TO_REFRESH_TOKEN:
        initiateTokenRefresh();
        break;
      case WebsocketState.NEED_TO_RECONNECT:
        getNewWebsocket(getWebsocketUrl(websocketApi)).then(replaceCurrentWs);
        heartbeatState.inReconnectLoop = true;
        if (heartbeatState.reconnectWaitInterval === INITIAL_RECONNECT_WAIT) {
          sendWsTrackEvent('websocket-started-reconnect-loop');
        }
        if (
          newReconnectWaitInterval !== heartbeatState.reconnectWaitInterval &&
          newReconnectWaitInterval === MAX_RECONNECT_WAIT_INTERVAL
        ) {
          sendWsTrackEvent('websocket-reconnect-max-wait-reached');
        }
        heartbeatState.reconnectWaitInterval = newReconnectWaitInterval;
        break;
      case WebsocketState.RECONNECTING:
        break;
      default:
        break;
    }
  };

  if (!heartbeatCheckInterval) {
    heartbeatCheckInterval = window.setInterval(reevaluateCurrentState, HEARTBEAT_INTERVAL);
  }

  const getNewWebsocket = async (url: string) => {
    return await connect({
      url,
      heartbeatState,
      selectedLocationIds: currentSelectedLocations,
      onMessage,
      reevaluateCurrentState,
      userId: getUser()?.userID || '',
    });
  };

  if (!isValidWebsocketUrl(initialUrl)) {
    return;
  }
  if (heartbeatState.connectAttemptStartedAt !== null) {
    // There's already a connection attempt in progress, if it fails the heartbeat state will trigger a reconnect
    console.info('Already connecting to websocket');
    return;
  }
  if (!!socket.current && getWebsocketState(initialUrl, heartbeatState) === WebsocketState.HEALTHY_CONNECTION) {
    if (currentSelectedLocations.toString() !== selectedLocationIds.toString()) {
      subscribeLocations({ locationIds: selectedLocationIds, ws: socket.current });
    }
    currentSelectedLocations = selectedLocationIds;
    return;
  }
  if (getWebsocketState(initialUrl, heartbeatState) === WebsocketState.HEALTHY_CONNECTION) {
    return;
  }
  // Else if we don't have a healthy connection yet, let's get a new one!
  getNewWebsocket(initialUrl).then(replaceCurrentWs);

  // This is a side effect that will trigger a new websocket connection when the token changes externally from WS
  // There is a cleanup function that is returned from this effect that will remove the listener when the component is unmounted
  // I'm not sure where any cleanup is being done for this component, so I ignored it
  onWeaveTokenChange(setupForNewToken);

  return socket;
};
