import { FC, useEffect, useMemo } from 'react';
import { useLocation } from '@tanstack/react-location';
import { FormsSubmission } from '@frontend/api-forms';
import { Icon } from '@frontend/icons';
import { Text, Heading, useAlert } from '@frontend/design-system';
import { InvertedArchiveIcon } from '../../../../../shared/components';
import { pendoTags } from '../../../../../shared/constants';
import { useScopeLocation, useShowReadUnreadStatus } from '../../../../../shared/hooks';
import { isDefaultUTCDate } from '../../../../../shared/utils';
import { useSubmissionPDF, useFormArchive } from '../../../hooks';
import { getCurrentCategoryFromPath } from '../../../submissions.utils';
import FormListItem, { FormListCommonProps } from './form-list-item/form-list-item.component';
import { ActionType, FormsListAction } from './forms-list-actions';
import {
  headerContainerStyle,
  containerStyle,
  innerContainerStyle,
  packetHeadingStyle,
  packetHeadingHelperTextStyle,
} from './forms-list.styles';

interface FormsListProps extends FormListCommonProps {
  submission: FormsSubmission.Types.NormalizedSubmissionDetailResponse;
  isModalView?: boolean;
}

const DownloadIcon = () => <Icon name='download' size={20} />;
const ArchiveIcon = () => <Icon name='archive' size={20} />;
const MarkUnreadIcon = () => <Icon name='mark-unread' size={20} />;

const FormsList: FC<React.PropsWithChildren<FormsListProps>> = ({
  submission,
  submissionId,
  isModalView,
  onFormClick,
  ...otherProps
}) => {
  const packetName = submission.packetName;
  const isPacket = !!packetName;
  const { isLoadingPDF, downloadPDF } = useSubmissionPDF({
    submissionId,
    pdfName: packetName || '',
  });
  const alert = useAlert();
  const {
    current: { pathname },
  } = useLocation();
  const category = getCurrentCategoryFromPath(pathname);

  const showReadUnreadStatus = useShowReadUnreadStatus(category);

  const toArchive = !submission.isArchive;
  const submissionType = isPacket ? 'Packet' : 'Form';
  const { updateArchiveStatus } = useFormArchive();

  const { locationId } = useScopeLocation();
  const { mutateAsync: mutateIsReadFlag, isLoading: isUpdateIsReadFlag } = FormsSubmission.useUpdateSubmissionIsRead({
    companyId: locationId,
  });

  function isSubmitted() {
    if (!submission.formSubmittedAt) {
      return false;
    }

    return !isDefaultUTCDate(submission.formSubmittedAt);
  }

  const isActionLoading = isUpdateIsReadFlag || isLoadingPDF;

  const updateIsReadFlag = (isRead: boolean) => {
    mutateIsReadFlag({
      submissionId,
      isRead,
    });
  };

  const popOverMenuActions = useMemo<ActionType[]>(() => {
    const actions: ActionType[] = [];
    const isCombinedDownloadPDF = submission.data.length > 1 && isSubmitted();

    const markAsUnreadAction: ActionType = {
      Icon: MarkUnreadIcon,
      label: 'Mark as Unread',
      disabled: !submission.isRead,
      dataTrackingId: pendoTags.markAsUnread,
      onClick: () => {
        if (submission.isRead) {
          updateIsReadFlag(false);
        }
      },
    };

    const downloadCombinedPDF: ActionType = {
      Icon: DownloadIcon,
      label: 'Download combined PDF',
      onClick: () => downloadPDF(),
      dataTrackingId: pendoTags.packetPDFDownloadButton,
    };

    const archiveAction: ActionType = {
      Icon: toArchive ? ArchiveIcon : InvertedArchiveIcon,
      label: toArchive ? 'Archive' : 'Unarchive',
      dataTrackingId: toArchive ? pendoTags.archive.action.singleArchive : pendoTags.archive.action.singleUnarchive,
      onClick: () => {
        const archiveActionName = toArchive ? 'archive' : 'unarchive';

        updateArchiveStatus(
          { submissionId, archive: toArchive },
          {
            onSuccess: () => {
              alert.success(`${submissionType} ${archiveActionName}d successfully!`);
            },
            onError: () => {
              alert.error(`Failed to ${archiveActionName} the ${submissionType}`);
            },
          }
        );
      },
    };

    if (isCombinedDownloadPDF) {
      actions.push(downloadCombinedPDF);
    }

    if (showReadUnreadStatus) {
      actions.push(markAsUnreadAction);
    }

    actions.push(archiveAction);

    return actions;
  }, [submission, showReadUnreadStatus, toArchive]);

  useEffect(() => {
    if (isModalView && !submission.isRead) {
      updateIsReadFlag(true);
    }
  }, [isModalView]);

  return (
    <div css={containerStyle}>
      <div css={innerContainerStyle}>
        <div css={headerContainerStyle}>
          {isPacket ? (
            <div>
              <Text css={packetHeadingHelperTextStyle}>Packet Name</Text>
              <Heading level={2} css={packetHeadingStyle}>
                {submission?.packetName}
              </Heading>
            </div>
          ) : (
            <Heading level={2}>Form</Heading>
          )}

          {popOverMenuActions.length > 0 && (
            <FormsListAction actions={popOverMenuActions} isLoading={isActionLoading} />
          )}
        </div>

        {submission.data.map((form, formIndex) => (
          <FormListItem
            key={form.formId}
            form={form}
            isSubmitted={isSubmitted()}
            submissionId={submissionId}
            {...otherProps}
            onFormClick={() => onFormClick(formIndex)}
          />
        ))}
      </div>
    </div>
  );
};

export default FormsList;
