import { useCallback, useEffect, useMemo, useState } from 'react';
import { SchemaSMSSharedModels } from '@frontend/api-messaging';
import { TagsV2 } from '@frontend/api-tag';
import { useAppScopeStore } from '@frontend/scope';
import { OnlineSchedulingMessagingPromoCard } from '@frontend/self-upgrade';

interface useThreadFeatureTeaserProps {
  groupId: string;
  threadId: string;
  messages: SchemaSMSSharedModels.SMS[]; // Expect latest message at last index
  messageCheckLimit?: number; // Number of messages to check for feature teaser
}
export const useThreadFeatureTeaser = ({
  groupId,
  threadId,
  messages,
  messageCheckLimit = 20,
}: useThreadFeatureTeaserProps) => {
  const { selectedOrgId } = useAppScopeStore();
  const [latestSmartTagName, setLatestSmartTagName] = useState<string>();

  const { data: tagsData } = TagsV2.Queries.useListTagsQuery({
    request: { orgId: selectedOrgId, groupIds: [groupId] },
  });

  const sortedMessages = useMemo(() => {
    return messages.slice(-messageCheckLimit).reverse();
  }, [messages, messageCheckLimit]);

  const smartTagIdNameMap = useMemo(() => {
    return (tagsData?.tags ?? [])?.reduce((acc, { id, smartTagId, name }) => {
      if (smartTagId) {
        acc.set(id, name);
      }
      return acc;
    }, new Map<string, string>());
  }, [tagsData]);

  useEffect(() => {
    if (latestSmartTagName || !smartTagIdNameMap.size) {
      return;
    }
    const latestTags = sortedMessages.find((message) => !!message.tags?.length)?.tags;

    // If multiple tags are found on message get the first matching smart tag
    const matchingSmartTagId = latestTags?.find((tag) => smartTagIdNameMap.has(tag));
    if (matchingSmartTagId) {
      setLatestSmartTagName(smartTagIdNameMap.get(matchingSmartTagId));
    }
  }, [latestSmartTagName, sortedMessages, smartTagIdNameMap]);

  useEffect(() => {
    // clears the latest smart tag name when thread changes
    setLatestSmartTagName('');
  }, [threadId]);

  const renderFeatureTeaser = useCallback(
    ({ patientName }: { patientName?: string }) => {
      switch (latestSmartTagName) {
        case 'Schedule':
          return <OnlineSchedulingMessagingPromoCard locationId={groupId} patientName={patientName} />;
        default:
          return null;
      }
    },
    [latestSmartTagName, groupId]
  );

  return {
    renderFeatureTeaser,
  };
};
