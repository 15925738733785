import { useState } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';
import { breakpoints, useWindowWidth } from '@frontend/responsiveness';
import { useSoftphoneEventSubscription } from './softphone-events-provider';

type WidgetControlContextValue = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  isMobile: boolean;
  isPhone: boolean;
  setIsFeedbackOpen: (val: boolean) => void;
  isFeedbackOpen: boolean;
};
const WidgetControlContext = createContext({} as WidgetControlContextValue);

type Props = {
  mobileBreakpoint?: number;
  children: React.ReactNode;
};
export const SoftphoneWidgetControlProvider = ({ mobileBreakpoint, children }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  const windowWidth = useWindowWidth({ tolerance: 5 });
  const isMobile = windowWidth <= (mobileBreakpoint ?? breakpoints.small.max);
  const isPhone = windowWidth <= breakpoints.xsmall.max;

  /**
   * close softphone when call is initated but remain open if isOpen is true after the call is initated
   */

  useSoftphoneEventSubscription(
    ['blind-transfer.sent', 'attended-transfer.after-established.transferred'],
    () => {
      close();
    },
    []
  );

  const value = {
    isOpen,
    open,
    close,
    isMobile,
    isPhone,
    setIsFeedbackOpen,
    isFeedbackOpen,
  } satisfies WidgetControlContextValue;
  return <WidgetControlContext.Provider value={value}>{children}</WidgetControlContext.Provider>;
};

export const useSoftphoneWidgetControl = <T extends any>(selector: (value: WidgetControlContextValue) => T) => {
  return useContextSelector(WidgetControlContext, selector);
};
