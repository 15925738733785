import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const shareLocationAccessStyles = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};

  .chip {
    margin-left: ${theme.spacing(2)};
  }
`;
