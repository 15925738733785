import { useState, useEffect, MouseEvent } from 'react';
import { OverrideType, PhoneOverride } from '@weave/schema-gen-ts/dist/schemas/phone/override/v1/override.pb';
import { CreateRequest, UpdateRequest } from '@weave/schema-gen-ts/dist/schemas/phone/override/v1/override_service.pb';
import dayjs from 'dayjs';
import { useMutation } from 'react-query';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { LocationsApi } from '@frontend/api-locations';
import { OverrideApi } from '@frontend/api-overrides';
import { useTranslation } from '@frontend/i18n';
import { useInvalidateQueries } from '@frontend/location-helpers';
import { useQuery } from '@frontend/react-query-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  useModalControl,
  UseFormReturnType,
  useForm,
  Tray,
  IconButton,
  XIcon,
  Heading,
  RadioField,
  CheckboxField,
  DatePickerField,
  TimeField,
  SecondaryButton,
  PrimaryButton,
  Text,
  Chip,
  LocationIcon,
  CubeIconSmall,
  useAlert,
} from '@frontend/design-system';
import { queryKeys } from '../../query-keys';
import { defaultMediaID } from '../../utils/phone-utils';
import {
  overrideButtonSectionStyle,
  overrideRadioFieldStyle,
  overrideSettingsStyle,
} from '../../views/settings/overrides-styles';
import { getMediaValues } from '../department/phone-routing-settings';
import { ForwardingNumberPicker } from '../forwarding-number-picker';
import { MediaPicker } from '../media-picker';

const formConfigBase = {
  voicemailMediaId: { type: 'dropdown' },
  forwardingNumberId: { type: 'dropdown' },
  selectOverride: { type: 'radio' },
  addSchedule: { type: 'checkbox' },
  startDate: { type: 'datePicker' },
  startTime: { type: 'time' },
  endDate: { type: 'datePicker' },
  endTime: { type: 'time' },
} as const;

interface IOverrideSettingTray {
  override?: PhoneOverride;
  locationId?: string;
  departmentId?: string;
  departmentName?: string;
  modalControls: ReturnType<typeof useModalControl>;
}

// We need to calculate the time difference between userTime and officeTime. This difference will be applied to the payload to ensure that the time in the timeField aligns with the office timezone, ensuring consistency between the selected time and the expected response.
const getTimezoneDifference = (officeTimezone: string | undefined) => {
  const userTime = new Date(dayjs().format('MM/DD/YY HH:mm'));
  const officeTime = new Date(dayjs().tz(officeTimezone).format('MM/DD/YY HH:mm'));
  return (officeTime.getTime() - userTime.getTime()) / (1000 * 60 * 60);
};

const timeToSeconds = (timeStr: string) => {
  const [hours, minutes, seconds] = timeStr.split(':').map(Number);
  return hours * 3600 + minutes * 60 + seconds;
};

export const NewOverrideSettingTray = ({
  override,
  locationId: iLID,
  departmentId: iDID,
  departmentName,
  modalControls,
}: IOverrideSettingTray) => {
  const { t } = useTranslation('phone', { keyPrefix: 'overrides' });
  const invalidateQueries = useInvalidateQueries();
  const alerts = useAlert();
  const { getLocationName } = useAppScopeStore();
  const locationId = override?.locationId || (iLID ?? '');
  const departmentId = override?.departmentId || (iDID ?? '');

  const { aggregateValue: departmentsFlag } = FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'departments',
    locationIds: [locationId],
  });

  const { data: officeTimezone } = useQuery({
    queryKey: [locationId, ...queryKeys.timezone(locationId ?? '')],
    queryFn: () =>
      LocationsApi.getOfficeTimezone(
        {},
        {
          locationId,
        }
      ),
    select: (data) => data.timezone,
  });

  const currentDate = dayjs();
  const currentDay = dayjs().startOf('day');
  const currentDateFormatted = dayjs().format('MM/DD/YYYY');
  const roundedTime = currentDate.minute(Math.ceil(currentDate.minute() / 15) * 15);

  /// we know that if there is a matching override, then the override is being edited
  const isEdit = !!override;

  const timezoneDiff = getTimezoneDifference(officeTimezone);
  const currentTimeOffice = roundedTime.add(timezoneDiff, 'hour').format('HH:mm');

  const { mutate: createOverride } = useMutation(
    (payload: CreateRequest) => {
      return OverrideApi.createOverride(payload, { locationId: payload.phoneOverride?.locationId });
    },
    {
      onSuccess: (resp) => {
        invalidateQueries([...queryKeys.overrideList()]);
        invalidateQueries([...queryKeys.listDepartmentsWithSchedules()]);
        alerts.success(
          resp.phoneOverride?.startAt
            ? t('Override will start on {{startAt}}', {
                startAt: dayjs(resp.phoneOverride.startAt).tz(officeTimezone).format('MMM D YYYY h:mma z'),
              })
            : t('Override turned on')
        );
      },
      onError: () => {
        alerts.error(t('Override create failed'));
      },
    }
  );

  const { mutate: updateOverride } = useMutation(
    (payload: UpdateRequest) => {
      return OverrideApi.UpdateOverride(payload, { locationId: payload.phoneOverride?.locationId });
    },
    {
      onSuccess: () => {
        invalidateQueries([...queryKeys.overrideList()]);
        invalidateQueries([...queryKeys.listDepartmentsWithSchedules()]);
        alerts.success(t('Override updated successfully'));
      },
      onError: () => {
        alerts.error(t('Override update failed'));
      },
    }
  );

  const createPostPayload = (
    // use the `values` prop of the value returned by useForm
    values: UseFormReturnType<typeof formConfigBase, keyof typeof formConfigBase>['values'] & { id?: string }
  ): CreateRequest | UpdateRequest => {
    const formattedStartDateTime = dayjs(`${values.startDate} ${values.startTime}`)
      .subtract(timezoneDiff, 'hour')
      .format();
    const formattedEndDateTime = dayjs(`${values.endDate} ${values.endTime}`).subtract(timezoneDiff, 'hour').format();

    return {
      phoneOverride: {
        locationId: locationId,
        departmentId: departmentId,
        overrideType: values.selectOverride as OverrideType,
        ...(values.voicemailMediaId !== ''
          ? { mediaItemId: values.voicemailMediaId && getMediaValues(values.voicemailMediaId) }
          : {}),
        ...(values.forwardingNumberId !== '' ? { forwardingNumberId: values.forwardingNumberId } : {}),
        ...(values.addSchedule && values.startDate !== '' && values.startTime !== ''
          ? { startAt: formattedStartDateTime }
          : {}),
        ...(values.addSchedule && values.endDate !== '' && values.endTime !== ''
          ? { endAt: formattedEndDateTime }
          : {}),
        ...(override?.id !== '' ? { id: override?.id } : {}),
      },
    };
  };

  const { formProps, getFieldProps, seedValues, validate } = useForm({
    computeChangedValues: true,
    fields: {
      voicemailMediaId: { type: 'dropdown', value: override?.mediaItemId },
      forwardingNumberId: { type: 'dropdown' },
      selectOverride: { type: 'radio' },
      addSchedule: { type: 'checkbox' },
      startDate: {
        type: 'datePicker',
        validator: ({ value }, formValues) => {
          const startDate = dayjs(value, 'MM/DD/YYYY').startOf('day');
          const endDate = dayjs(formValues.endDate.value, 'MM/DD/YYYY').startOf('day');
          // Skip start date validation for an active override (indicated by override?.enabled) if the start date and time have passed. This specifically applies when editing an override.
          if (formValues.endDate.value === '' && !override?.enabled) {
            return startDate.isBefore(currentDate, 'day') ? t('Start date must be in the future') : '';
          }

          return startDate.isAfter(endDate) ? t('Start date must be before the end date') : '';
        },
      },
      startTime: {
        type: 'time',
        validator: ({ value }, formValues) => {
          const startTime = timeToSeconds(value);
          const endTime = timeToSeconds(formValues.endTime.value);
          const startDate = dayjs(formValues.startDate.value, 'MM/DD/YYYY').startOf('day');
          const endDate = dayjs(formValues.endDate.value, 'MM/DD/YYYY').startOf('day');
          const currentTime = timeToSeconds(dayjs().add(timezoneDiff, 'hour').format('HH:mm:ss'));
          // Skip start time validation for an active override (indicated by override?.enabled) if the start date and time have passed. This specifically applies when editing an override.
          if (startDate.isSame(currentDate, 'day') && !startDate.isSame(endDate) && !override?.enabled) {
            return startTime < currentTime ? t('Start time must be in the future') : '';
          }

          return startDate.isSame(endDate) && startTime >= endTime ? t('Start time must be before the end time') : '';
        },
      },
      endDate: {
        type: 'datePicker',
        validator: ({ value }, formValues) => {
          const startDate = dayjs(formValues.startDate.value, 'MM/DD/YYYY').startOf('day');
          const endDate = dayjs(value, 'MM/DD/YYYY').startOf('day');

          if (!formValues.startDate.value) {
            return endDate.isBefore(currentDay) ? t('End date must be in the future') : '';
          }

          return endDate.isBefore(startDate) ? t('End date must be after the start date') : '';
        },
      },
      endTime: {
        type: 'time',
        validator: ({ value }, formValues) => {
          const startTime = timeToSeconds(formValues.startTime.value);
          const endTime = timeToSeconds(value);
          const startDate = dayjs(formValues.startDate.value, 'MM/DD/YYYY').startOf('day');
          const endDate = dayjs(formValues.endDate.value, 'MM/DD/YYYY').startOf('day');
          const currentTime = timeToSeconds(dayjs().add(timezoneDiff, 'hour').format('HH:mm:ss'));

          if (endDate.isSame(currentDate, 'day') && !startDate.isSame(endDate)) {
            return endTime < currentTime ? 'End time must be in the future' : '';
          }
          return startDate.isSame(endDate) && startTime >= endTime ? t('End time must be after the start time') : '';
        },
      },
    },
    onSubmit: (formValues) => {
      override ? updateOverride(createPostPayload(formValues)) : createOverride(createPostPayload(formValues));
    },
  });

  const vmOverrideMediaProps = getFieldProps('voicemailMediaId');
  const callForwardingNumberProps = getFieldProps('forwardingNumberId');
  const overrideSelectProps = getFieldProps('selectOverride');
  const optionalFieldProps = getFieldProps('addSchedule');
  const startDateFieldProps = getFieldProps('startDate');
  const startTimeFieldProps = getFieldProps('startTime');
  const endDateFieldProps = getFieldProps('endDate');
  const endTimeFieldProps = getFieldProps('endTime');
  const startDate = startDateFieldProps?.value;
  const endDate = endDateFieldProps?.value;
  const isStartToday = dayjs(startDate).isSame(currentDate, 'day');
  const isEndToday = dayjs(endDate).isSame(currentDate, 'day');
  const [defaultTime, setDefaultTime] = useState('');

  useEffect(() => {
    validate();
  }, [startDateFieldProps.value, endDateFieldProps.value, startTimeFieldProps.value, endTimeFieldProps.value]);

  useEffect(() => {
    if (!!override && timezoneDiff !== undefined) {
      seedValues({
        selectOverride: override.overrideType,
        voicemailMediaId: override.mediaItemId || getMediaValues(defaultMediaID),
        forwardingNumberId: override.forwardingNumberId,
        addSchedule: !!override.startAt || !!override.endAt,
        startDate: !!override.startAt ? dayjs(override.startAt).format('MM/DD/YYYY') : '',
        startTime: !!override.startAt ? dayjs(override.startAt).add(timezoneDiff, 'hour').format('h:mma') : '',
        endDate: !!override.endAt ? dayjs(override.endAt).format('MM/DD/YYYY') : '',
        endTime: !!override.endAt ? dayjs(override.endAt).add(timezoneDiff, 'hour').format('h:mma') : '',
      });
    } else
      seedValues({
        selectOverride: OverrideType.OVERRIDE_TYPE_VMO,
        voicemailMediaId: '',
        forwardingNumberId: '',
        addSchedule: false,
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
      });
  }, [override, timezoneDiff]);

  const hasValidationError =
    !!startDateFieldProps.error.length ||
    !!startTimeFieldProps.error.length ||
    !!endDateFieldProps.error.length ||
    !!endTimeFieldProps.error.length;

  const canUpdateOverride =
    (overrideSelectProps.value === OverrideType.OVERRIDE_TYPE_VMO &&
      !hasValidationError &&
      !!vmOverrideMediaProps.value) ||
    (overrideSelectProps.value === OverrideType.OVERRIDE_TYPE_FWD &&
      !hasValidationError &&
      !!callForwardingNumberProps.value);

  const needTimeField =
    !!optionalFieldProps.value &&
    ((!!startDateFieldProps.value && !startTimeFieldProps.value) ||
      (!!endDateFieldProps.value && !endTimeFieldProps.value));

  /// Radio Field handles this for us, but we override the default behavior to let the entire div be clickable. W/o this, only the radio button & the label are clickable.
  const handleSelectOverrideType = (val: OverrideType) => (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    overrideSelectProps.onChange({ name: overrideSelectProps.name, value: val });
  };

  const handleCancel = () => {
    if (!override) {
      seedValues({
        selectOverride: OverrideType.OVERRIDE_TYPE_VMO,
        voicemailMediaId: '',
        forwardingNumberId: '',
        addSchedule: false,
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
      });
    } else {
      seedValues({
        selectOverride: override.overrideType,
        voicemailMediaId: override.mediaItemId || getMediaValues(defaultMediaID),
        forwardingNumberId: override.forwardingNumberId,
        addSchedule: !!override.startAt || !!override.endAt,
        startDate: !!override.startAt ? dayjs(override.startAt).format('MM/DD/YYYY') : '',
        startTime: !!override.startAt ? dayjs(override.startAt).format('h:mma') : '',
        endDate: !!override.endAt ? dayjs(override.endAt).format('MM/DD/YYYY') : '',
        endTime: !!override.endAt ? dayjs(override.endAt).format('h:mma') : '',
      });
    }
  };

  return (
    <>
      <Tray
        width='xlarge'
        css={{ padding: theme.spacing(4), borderTop: `1px solid ${theme.colors.neutral20}`, overflowY: 'auto' }}
        {...modalControls.modalProps}
      >
        <form {...formProps}>
          <Tray.Header
            Buttons={
              <IconButton
                label='close'
                onClick={() => {
                  modalControls.closeModal();
                  // setTimeout for form to reset after closing the modal
                  setTimeout(() => {
                    handleCancel();
                  }, 300);
                }}
              >
                <XIcon />
              </IconButton>
            }
          >
            <Heading css={{ marginBottom: 0 }}>{isEdit ? t('Edit Override') : t('Create Override')}</Heading>
          </Tray.Header>
          {override && (
            <Chip
              variant='default'
              leftElement={
                departmentId ? (
                  <CubeIconSmall css={{ height: theme.spacing(2) }} />
                ) : (
                  <LocationIcon css={{ height: theme.spacing(2) }} />
                )
              }
              css={{ maxWidth: '135px' }}
            >
              {departmentId ? departmentName : getLocationName(override?.locationId ?? '')}
            </Chip>
          )}
          <Text color='light'>
            {t(
              'Choose the override type and, if you wish to plan ahead, select the start and end dates for the override.'
            )}
          </Text>

          <div css={overrideSettingsStyle}>
            <Heading level={2}>{t('Select Override Type')}</Heading>
            <RadioField {...overrideSelectProps} css={overrideRadioFieldStyle}>
              <div
                css={{
                  paddingLeft: theme.spacing(2),
                  borderRadius: theme.spacing(1),
                  border: `1px solid ${theme.colors.neutral20}`,
                  flex: 1,
                  cursor: 'pointer',
                  label: { cursor: 'pointer' },
                  input: { cursor: 'pointer' },
                }}
                onClick={handleSelectOverrideType(OverrideType.OVERRIDE_TYPE_VMO)}
              >
                <RadioField.Radio value={OverrideType.OVERRIDE_TYPE_VMO} trackingId='phone-portal-radio-vmo'>
                  <div css={{ marginLeft: theme.spacing(1) }}>
                    <Text weight='bold' css={{ margin: theme.spacing(2, 0, 0.5) }}>
                      {t('Send to Voicemail')}
                    </Text>
                    <Text size='medium' color='light'>
                      {t('All calls are automatically sent to voicemail')}
                    </Text>
                  </div>
                </RadioField.Radio>
              </div>
              <div
                css={{
                  paddingLeft: theme.spacing(2),
                  borderRadius: theme.spacing(1),
                  border: `1px solid ${theme.colors.neutral20}`,
                  flex: 1,
                  cursor: 'pointer',
                  label: { cursor: 'pointer' },
                  input: { cursor: 'pointer' },
                }}
                onClick={handleSelectOverrideType(OverrideType.OVERRIDE_TYPE_FWD)}
              >
                <RadioField.Radio value={OverrideType.OVERRIDE_TYPE_FWD} trackingId='phone-portal-radio-fwd'>
                  <div css={{ marginLeft: theme.spacing(1) }}>
                    <Text weight='bold' css={{ margin: theme.spacing(2, 0, 0.5) }}>
                      {t('Forward Call')}
                    </Text>
                    <Text size='medium' color='light'>
                      {t('All calls are forwarded to a predetermined phone number')}
                    </Text>
                  </div>
                </RadioField.Radio>
              </div>
            </RadioField>
            {overrideSelectProps.value === OverrideType.OVERRIDE_TYPE_VMO && (
              <MediaPicker
                {...vmOverrideMediaProps}
                locationId={locationId}
                allowAddOption
                allowDefaultOption={departmentsFlag}
                requestedTypes={{ custom: true, standard: false }}
                label={t('Select Voicemail Greeting')}
                name='voicemailMediaId'
              />
            )}

            {overrideSelectProps.value === OverrideType.OVERRIDE_TYPE_FWD && (
              <ForwardingNumberPicker
                {...callForwardingNumberProps}
                name='forwardingNumberId'
                label={t('Select Forwarding Number')}
                locationId={locationId}
                departmentId={departmentId}
              />
            )}

            <CheckboxField
              {...optionalFieldProps}
              label={
                <>
                  <Text>{t('Add Start/End Time (Optional)')}</Text>
                  <Text color='light'>{t('Schedule when your override starts, ends, or both.')}</Text>
                </>
              }
              data-trackingid='phone-portal-checkBox-addStartEndTime'
            />
            {optionalFieldProps.value && (
              <div css={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(3) }}>
                <div>
                  <div css={{ display: 'flex', gap: theme.spacing(1) }}>
                    <DatePickerField
                      label={t('Start Date')}
                      {...startDateFieldProps}
                      css={{ width: '155px' }}
                      minDate={currentDateFormatted}
                      data-trackingid='phone-portal-dateField-startDate'
                    />
                    <TimeField
                      {...startTimeFieldProps}
                      showAdornment
                      readOnly
                      label={t('Start Time')}
                      interval={15}
                      disabled={!startDateFieldProps.value}
                      minTime={isStartToday ? currentTimeOffice : '0:00'}
                      maxTime='24:00'
                      css={{ width: '155px' }}
                      onFocus={(e) => {
                        startTimeFieldProps.onFocus(e);
                        setDefaultTime('07:00:00');
                      }}
                      value={startTimeFieldProps.value ? startTimeFieldProps.value : defaultTime}
                      data-trackingid='phone-portal-timeField-startTime'
                    />
                  </div>
                  <Text color='light' size='small'>
                    {t('Override will automatically start on this date and time')}
                  </Text>
                </div>
                <div>
                  <div css={{ display: 'flex', gap: theme.spacing(1) }}>
                    <DatePickerField
                      label={t('End Date')}
                      {...endDateFieldProps}
                      css={{ width: '155px' }}
                      minDate={startDate ? startDate : currentDateFormatted}
                      data-trackingid='phone-portal-dateField-endDate'
                    />
                    <TimeField
                      {...endTimeFieldProps}
                      showAdornment
                      readOnly
                      label={t('End Time')}
                      interval={15}
                      disabled={!endDateFieldProps.value}
                      minTime={isEndToday ? currentTimeOffice : '0:00'}
                      maxTime='24:00'
                      css={{ width: '155px' }}
                      onFocus={(e) => {
                        endTimeFieldProps.onFocus(e);
                        setDefaultTime('07:00:00');
                      }}
                      value={endTimeFieldProps.value ? endTimeFieldProps.value : defaultTime}
                      data-trackingid='phone-portal-timeField-endTime'
                    />
                  </div>
                  <Text color='light' size='small'>
                    {t('Override will automatically stop on this date and time')}
                  </Text>
                </div>
              </div>
            )}
          </div>
          <section css={overrideButtonSectionStyle}>
            <SecondaryButton
              size='large'
              css={{ width: 'auto' }}
              onClick={() => {
                modalControls.closeModal();
                // setTimeout for form to reset after closing the modal
                setTimeout(() => {
                  handleCancel();
                }, 300);
              }}
              trackingId='phone-portal-button-cancelOverride'
            >
              {t('Cancel')}
            </SecondaryButton>
            <PrimaryButton
              size='large'
              css={{ width: 'auto' }}
              // Disable button if update conditions aren't met OR if the modal is closing, preventing multiple submissions.
              disabled={!canUpdateOverride || needTimeField || !modalControls.modalProps.show}
              onClick={() => {
                // Use a slight delay to ensure form initiates submission before closing the modal.
                setTimeout(() => {
                  modalControls.closeModal();
                }, 100);
              }}
              type='submit'
              trackingId='phone-portal-button-createEditOverride'
            >
              {isEdit ? t('Save') : t('Create Override')}
            </PrimaryButton>
          </section>
        </form>
      </Tray>
    </>
  );
};
