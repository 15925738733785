import { useMemo } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { FreeTrialSubscriptionQueries, FreeTrialSubscriptionTypes } from '@frontend/api-free-trial-subscription';
import { useAppScopeStore } from '@frontend/scope';

type UseIsCIEligibleAccount = {
  isCallIntelEligibleAccount?: boolean;
  isFreeTrialEligible?: boolean;
  isLoading?: boolean;
};

// This is used in the Analytics app to determine if the location is eligible for the call intel feature.
export const useIsCallIntelEligibleAccount = (): UseIsCIEligibleAccount => {
  const { selectedLocationIds } = useAppScopeStore();

  const { data: selfUpgradeEligibility, isLoading } = FreeTrialSubscriptionQueries.useSelfUpgradeOrFreeTrialEligibility(
    {
      locationIds: selectedLocationIds,
      featureEnum: Feature.CALL_INTELLIGENCE,
    }
  );

  const { isFreeTrialEligible, isCallIntelEligibleAccount } = useMemo(() => {
    if (isLoading) {
      return { isPAEligibleAccount: false, isFreeTrialEligible: false };
    }

    return {
      isCallIntelEligibleAccount: selfUpgradeEligibility?.eligibility?.some((subscriptionData) => {
        return (
          subscriptionData?.isStateActive ||
          subscriptionData?.trialStatus === FreeTrialSubscriptionTypes.TrialStatus.TRIAL_ELIGIBLE ||
          subscriptionData?.subscriptionStatus !==
            FreeTrialSubscriptionTypes.SubscriptionStatusForSelfUpgrade.SUBSCRIPTION_STATUS_UNSPECIFIED
        );
      }),
    };
  }, [isLoading, selfUpgradeEligibility]);

  return {
    isCallIntelEligibleAccount,
    isLoading: isLoading,
    isFreeTrialEligible,
  };
};
