import {
  CreateTaskEventRequest,
  GetTaskEventsRequest,
  GetUserTasksByUserIDResponseRow,
  ModuleRow,
  TaskEvent,
  TaskStep,
} from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-tasks/onboarding_tasks.pb';
import {
  OnboardingEvent,
  OnboardingProgressStatus,
} from '@weave/schema-gen-ts/dist/shared/insys/activation/onboarding.pb';
import { http } from '@frontend/fetch';
import { SchemaOnboardingProgressService, SchemaOnboardingTasksService } from '@frontend/schema';
import { sentry } from '@frontend/tracking';
import { UsersTypes } from '@frontend/user-helpers';
import { TaskCompletionType } from './types';

export const getModulesAndTasksByLocationId = async (locationId: string): Promise<ModuleRow[]> => {
  const res = await SchemaOnboardingTasksService.GetModulesAndTasksByLocationID(
    {},
    { headers: { 'Location-Id': locationId } }
  );
  return res?.modules || [];
};

export const getModulesAndTasksForAllLocations = async ({ selectedIds }: { selectedIds: string[] }) => {
  const allLocationsModules = await Promise.all(
    selectedIds.map(async (locationId) => {
      return SchemaOnboardingTasksService.GetModulesAndTasksByLocationID(
        {},
        {
          headers: {
            'Location-Id': locationId,
          },
        }
      );
    })
  );
  return allLocationsModules;
};

export const getSteps = async (taskId: number): Promise<TaskStep[]> => {
  const res = await SchemaOnboardingTasksService.GetTaskStepsByTaskID({ taskId });
  return res?.taskSteps || [];
};

export const sendTaskEvent = async (eventInfo: CreateTaskEventRequest) => {
  try {
    await SchemaOnboardingTasksService.CreateTaskEvent(eventInfo);
  } catch (error) {
    if (http.isHttpError(error) && error.status !== 401) {
      sentry.warn({
        error,
        topic: 'onboarding',
        addContext: {
          name: 'eventInfo',
          context: { ...eventInfo, errMessage: 'Error occurred while sending task event' },
        },
      });
    }
  }
};

export const getTasksByUserId = async (userId: string): Promise<GetUserTasksByUserIDResponseRow[]> => {
  const res = await SchemaOnboardingTasksService.GetUserTasksByUserID({
    userId,
  });

  return res?.userTasks || [];
};

export const getTaskEventsByUserIDTaskId = async (request: GetTaskEventsRequest): Promise<TaskEvent[]> => {
  const res = await SchemaOnboardingTasksService.GetTaskEvents(request);
  return res?.taskEvents || [];
};

export const markUserTaskComplete = (userId: string, taskId: number) => {
  return SchemaOnboardingTasksService.CompleteUserTask({
    userId,
    taskId,
  });
};

export const markLocationTaskCompleted = async (taskId: number, completionType: TaskCompletionType): Promise<void> => {
  try {
    await SchemaOnboardingTasksService.MarkLocationTaskCompleted({
      completionType,
      taskId,
    });
  } catch (error) {
    if (http.isHttpError(error) && error.status !== 401) {
      sentry.warn({
        error,
        topic: 'onboarding',
        addContext: {
          name: 'request',
          context: { taskId, completionType, errMessage: 'Error occurred while updating task' },
        },
      });
    }
  }
};

export const getLatestOnboardingProgressByLocationID = async (
  locationId: string
): Promise<OnboardingProgressStatus> => {
  try {
    const res = await SchemaOnboardingProgressService.GetLatestOnboardingProgressByLocationID(
      { locationId },
      { headers: { 'Location-Id': locationId } }
    );
    return res?.onboardingProgressStatus || OnboardingProgressStatus.ONBOARDING_PROGRESS_STATUS_UNSPECIFIED;
  } catch (error) {
    if (http.isHttpError(error) && error.status !== 401) {
      sentry.warn({ error, topic: 'onboarding' });
    }
  }
  return OnboardingProgressStatus.ONBOARDING_PROGRESS_STATUS_UNSPECIFIED;
};

export const updateUserProfile = (payload: UsersTypes.PutUserPayload) => {
  return http.put('/insys/onboarding/v1/onboarding-progress/user-profile', payload, {
    skipValidation: true,
  });
};

interface PublishOnboardingEventRequest {
  slug: string;
  locationId: string;
  onboardingEvent: OnboardingEvent;
}

export const publishOnboardingEvent = async (req: PublishOnboardingEventRequest): Promise<void> => {
  try {
    const res = await getLatestOnboardingProgressByLocationID(req.locationId);
    const isSelfActivationLocation = !!res && res !== OnboardingProgressStatus.ONBOARDING_PROGRESS_STATUS_UNSPECIFIED;

    if (!isSelfActivationLocation) {
      return;
    }
    await http.post('insys/onboarding/v1/onboarding_history/publish', req);
  } catch (error) {
    if (http.isHttpError(error) && error.status !== 401) {
      sentry.error({
        error,
        topic: 'onboarding',
        addContext: {
          name: 'request',
          context: { ...req, errMessage: 'Error occurred while publishing onboarding event' },
        },
      });
    }
  }
};
