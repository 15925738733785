import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const modalBodyStyle = css`
  margin-top: ${theme.spacing(1)};
`;

export const textStyle = css`
  margin-bottom: ${theme.spacing(1)};
`;
