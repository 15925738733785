import { useNavigate } from '@tanstack/react-location';
import { BulkMessagingSVG } from '@frontend/assets';
import { FreeTrialModal } from '@frontend/free-trials';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ModalControlResponse, Text } from '@frontend/design-system';
import { PracticeAnalyticsURLs } from '../practice/constants';

export const PracticeAnalyticsFreeTrialSuccessModal = ({ modalProps, closeModal }: ModalControlResponse) => {
  const { t } = useTranslation('analytics');
  const navigate = useNavigate();

  const handleModalActionClick = () => {
    navigate({ to: PracticeAnalyticsURLs.MAIN, replace: true });
  };

  return (
    <FreeTrialModal
      title={t('Your Practice Analytics free trial has been activated')}
      imageSrc={BulkMessagingSVG}
      onModalCloseClick={closeModal}
      actionButtonLabel={t('Go to Practice Analytics')}
      onModalActionClick={handleModalActionClick}
      modalProps={modalProps}
      renderContent={
        <>
          <Text>
            {t(
              'Get the most out of your free trial by setting up your Practice Analytics now. Visit Practice Analytics page for setup and training.'
            )}
          </Text>
          <Text size='small' color='light' css={{ marginTop: theme.spacing(4) }}>
            {t(
              'Your trial will end in 30 days. Once it ends your access to practice analytics will be removed and you will need to upgrade.'
            )}
          </Text>
        </>
      }
      actionButtonTrackingId='practice-analytics-free-trial-subscribed-modal-go-to-pa-button'
      modalCloseIconTrackingId='practice-analytics-free-trial-subscribed-modal-close-button'
    />
  );
};
