import { useRef, useState } from 'react';
import { css } from '@emotion/react';
import useResizeObserver from '@react-hook/resize-observer';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { emptyStateGraphics, Text } from '@frontend/design-system';
import { useGlobalSearch } from '../provider';

type GlobalSearchContentProps = {
  children: React.ReactNode;
};

export const GlobalSearchContent = ({ children }: GlobalSearchContentProps) => {
  const { searchFor } = useGlobalSearch(['searchFor']);
  const targetRef = useRef(null);
  const [isZeroHeight, setIsZeroHeight] = useState(false);

  useResizeObserver(targetRef, (entry) => {
    if (targetRef.current) {
      const { height } = entry.contentRect;
      setIsZeroHeight(height !== 0);
    }
  });

  const isAll = searchFor === 'all';
  const showEmptyState = !isZeroHeight && isAll;
  return (
    <>
      <section
        ref={targetRef}
        css={css`
          overflow-y: auto;
          flex: 1;
        `}
      >
        {children}
      </section>
      {showEmptyState && isAll && <EmptyStateGraphic />}
    </>
  );
};

const EmptyState = emptyStateGraphics.search;

const EmptyStateGraphic = () => {
  const { t } = useTranslation();
  const { debouncedSearchTerm } = useGlobalSearch(['debouncedSearchTerm']);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        gap: ${theme.spacing(2)};
        align-items: center;
        padding: ${theme.spacing(3, 5)};
      `}
    >
      <EmptyState
        css={css`
          max-width: 300px;
          max-height: 300px;
        `}
      />
      <Text css={{ width: '100%' }} textAlign='center' color='light' size='large'>
        {t(`Sorry, we couldn't find any results for `)}
        <span
          css={css`
            font-weight: ${theme.font.weight.bold};
          `}
        >
          "{debouncedSearchTerm}"
        </span>
      </Text>
    </div>
  );
};
