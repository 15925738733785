import { OutgoingReferRequest } from 'sip.js/lib/core';
import { IncomingResponse } from 'sip.js/lib/core/messages/incoming-response';
import { SessionManager, SessionManagerDelegate } from 'sip.js/lib/platform/web';
import { createContext, useContextSelector } from 'use-context-selector';
import { DialChar } from '@frontend/generic-dialpad-accessories';
import { AnyCall, ContextualCallInfo, EstablishedCall, IncomingCall, OutgoingCall, TerminatedCall } from '../../types';
import { useSoftphoneClient } from '../softphone-client-provider';
import { OccupiedParkSlot, VacantParkSlot } from '../softphone-park-slots-provider';
import { useAnswerIncomingCall } from './actions/use-answer-incoming-call';
import { useAnswerParkedCall } from './actions/use-answer-parked-call';
import { useEndCall } from './actions/use-end-call';
import { useToggleHold } from './actions/use-hold-call';
import { useMergeCalls } from './actions/use-merge-calls';
import { useParkCall } from './actions/use-park-call';
import { usePlaceCall } from './actions/use-place-call';
import { useReInvite } from './actions/use-reinvite';
import { useSendDTMF } from './actions/use-send-dtmf';
import { useToggleMute } from './actions/use-toggle-mute';
import { useTransfer } from './actions/use-transfer';
import { useHandleCallAnswered } from './handlers/use-handle-call-answered';
import { useHandleHangup } from './handlers/use-handle-hangup';
import { useHandleIncomingCall } from './handlers/use-handle-incoming-call';

export type SoftphoneCallActionsContextValue = {
  placeCallDirect: (numOrURI: string, info?: ContextualCallInfo) => Promise<OutgoingCall>;
  placeCallFromDialer: (info?: ContextualCallInfo) => Promise<OutgoingCall>;
  mergeCalls: (calls: EstablishedCall[]) => Promise<EstablishedCall[]>;
  answerIncomingCall: (incomingCall: IncomingCall) => Promise<IncomingCall>;
  blindTransfer: (call: EstablishedCall, name: string) => Promise<TerminatedCall>;
  startWarmTransfer: (existingCall: EstablishedCall) => Promise<OutgoingCall>;
  confirmWarmTransfer: (name: string) => Promise<OutgoingReferRequest>;
  parkCall: (call: EstablishedCall, slot: VacantParkSlot) => Promise<TerminatedCall>;
  answerParkedCall: (slot: OccupiedParkSlot) => Promise<OutgoingCall>;
  sendDTMF: (call: EstablishedCall, char: DialChar) => Promise<EstablishedCall>;
  toggleHold: (call: EstablishedCall, hold: boolean) => Promise<EstablishedCall>;
  toggleHoldAll: (hold: boolean, calls?: EstablishedCall[]) => Promise<EstablishedCall[]>;
  toggleHoldMergedCalls: (hold: boolean) => Promise<void>;
  unHoldAndSetAsPrimary: (call: EstablishedCall) => void;
  endCall: (call: EstablishedCall | OutgoingCall | IncomingCall) => Promise<TerminatedCall>;
  endAllCalls: () => Promise<AnyCall[]>;
  toggleMute: (mute: boolean) => boolean;
  reInviteCall: (call: EstablishedCall) => Promise<IncomingResponse>;
  reInviteCalls: (calls: EstablishedCall[]) => Promise<IncomingResponse[]>;
};
const SoftphoneCallActionsContext = createContext({} as SoftphoneCallActionsContextValue);

type Props = {
  children: React.ReactNode;
};
export const SoftphoneCallActionsProvider = ({ children }: Props) => {
  const client = useSoftphoneClient((ctx) => ctx.client);

  /**
   * Delegate handlers are responsbile for state updates and side affects from call actions
   */
  useDelegateHandlerSync(client, 'onCallAnswered', useHandleCallAnswered());
  useDelegateHandlerSync(client, 'onCallReceived', useHandleIncomingCall());
  useDelegateHandlerSync(client, 'onCallHangup', useHandleHangup());

  /**
   * Call Actions are responsible for sending sip requests.
   * The sip.js SessionManager has handlers for some of these.
   * In those cases, state changes and side affects should be deffered to the handlers.
   * In the cases where there is not a SessionManager handler,
   * the side affects and state changes are handled in the action itself
   */
  const { endCall, endAllCalls } = useEndCall();
  const answerIncomingCall = useAnswerIncomingCall();
  const parkCall = useParkCall();
  const mergeCalls = useMergeCalls();
  const sendDTMF = useSendDTMF();
  const toggleMute = useToggleMute();
  const { placeCallDirect, placeCallFromDialer } = usePlaceCall();
  const { reInviteCall, reInviteCalls } = useReInvite();
  const { toggleHold, toggleHoldAll, toggleHoldMergedCalls, unHoldAndSetAsPrimary } = useToggleHold();
  const { blindTransfer, startWarmTransfer, confirmWarmTransfer } = useTransfer();
  const answerParkedCall = useAnswerParkedCall();

  const value = {
    placeCallDirect,
    placeCallFromDialer,
    mergeCalls,
    answerIncomingCall,
    blindTransfer,
    startWarmTransfer,
    confirmWarmTransfer,
    answerParkedCall,
    parkCall,
    sendDTMF,
    endCall,
    endAllCalls,
    toggleMute,
    toggleHold,
    toggleHoldAll,
    toggleHoldMergedCalls,
    unHoldAndSetAsPrimary,
    reInviteCall,
    reInviteCalls,
  } satisfies SoftphoneCallActionsContextValue;

  return <SoftphoneCallActionsContext.Provider value={value}>{children}</SoftphoneCallActionsContext.Provider>;
};

export const useSoftphoneCallActions = <T extends any>(selector: (value: SoftphoneCallActionsContextValue) => T) => {
  return useContextSelector(SoftphoneCallActionsContext, selector);
};

/**
 *
 * Provides a way to keep the client delegate up to date, so it retains access to the latest state
 */
const useDelegateHandlerSync = <T extends keyof SessionManagerDelegate, Fn extends SessionManagerDelegate[T]>(
  client: SessionManager | undefined,
  fn: T,
  handler: Fn
) => {
  if (client?.delegate) {
    client.delegate[fn] = handler;
  }
};
