import { FC } from 'react';
import { FormsSourceTenants } from '@frontend/api-forms';
import { useControlledField, RadioField } from '@frontend/design-system';
import { pendoTags } from '../../../../../../../../../shared/constants';
import { useWritebackWidgetStore } from '../../../../providers';
import { radioFieldStyle } from '../writeback-destination.styles';
import ParentListItem from './parent-list-item/parent-list-item.component';

interface ParentListProps {
  sourceTenants: FormsSourceTenants.Types.ModifiedSourceTenant[];
}

const ParentList: FC<ParentListProps> = ({ sourceTenants }) => {
  const { setSourceId, sourceId, setSourceTenantId } = useWritebackWidgetStore([
    'setSourceId',
    'sourceId',
    'setSourceTenantId',
  ]);

  const radioFieldProps = useControlledField({
    type: 'radio',
    value: sourceId,
    onChange: (newSourceId: string) => {
      const sourceTenant = sourceTenants.find(({ id }) => id === newSourceId);

      if (!sourceTenant) {
        return;
      }

      if ((sourceTenant.sites ?? []).length === 0) {
        setSourceId(newSourceId);
        setSourceTenantId(sourceTenant.sourceTenantId);
      }
    },
  });

  return (
    <RadioField {...radioFieldProps} name='available-sources'>
      {sourceTenants.map((sourceTenant) => {
        const { id, sites = [] } = sourceTenant;
        const trackingId =
          sites.length === 0
            ? pendoTags.writbackWidgetV2.changeSource
            : pendoTags.writbackWidgetV2.openAvailableSourceTenantsList;

        return (
          <RadioField.Radio key={id} value={id} css={radioFieldStyle} trackingId={trackingId}>
            <ParentListItem sourceTenant={sourceTenant} />
          </RadioField.Radio>
        );
      })}
    </RadioField>
  );
};

export default ParentList;
