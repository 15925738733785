import { useCallback } from 'react';
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { SchemaIO, SchemaPersonV3Service } from '@frontend/schema';
import { queryKeys } from './queries';

export const mutationKeys = {
  baseKey: ['persons-v3'],
  upsertPrimaryContactLegacy: () => [...mutationKeys.baseKey, 'upsert-primary-contact-legacy'],
};

type UpsertPrimaryContactLegacyIO = SchemaIO<(typeof SchemaPersonV3Service)['UpsertPrimaryContactLegacy']>;
type GetPrimaryContactLegacyIO = SchemaIO<(typeof SchemaPersonV3Service)['GetPrimaryContactLegacy']>;
type UpsertPrimaryContactLegacyContext<C = unknown> = {
  optimisticUpdateContext:
    | {
        didOptimisticUpdate: true;
        previousData: GetPrimaryContactLegacyIO['output'];
      }
    | {
        didOptimisticUpdate: false;
        previousData?: never;
      };
  optionContext?: C;
};
export const useUpsertPrimaryContactLegacyMutation = <E = unknown, C = unknown>(
  options?: UseMutationOptions<
    UpsertPrimaryContactLegacyIO['output'],
    E,
    UpsertPrimaryContactLegacyIO['input'],
    C | undefined
  >
) => {
  const queryClient = useQueryClient();

  const updateQueryCache = useCallback(
    ({
      locationId,
      phoneNumber: providedPhoneNumber,
      personId,
      updatedAt,
    }: {
      locationId: string;
      phoneNumber: string;
      personId: string;
      updatedAt?: string;
    }): UpsertPrimaryContactLegacyContext['optimisticUpdateContext'] => {
      const phoneNumber = formatPhoneNumberE164(providedPhoneNumber);
      let context: UpsertPrimaryContactLegacyContext['optimisticUpdateContext'] | undefined;

      queryClient.setQueriesData<GetPrimaryContactLegacyIO['output'] | undefined>(
        {
          queryKey: queryKeys.getPrimaryContactLegacy({
            locationId,
            phoneNumber,
          }),
          exact: true,
        },
        (oldData) => {
          if (!oldData) return oldData;

          if (!context) context = { didOptimisticUpdate: true, previousData: oldData };
          return {
            ...oldData,
            personId,
            updatedAt: updatedAt || new Date().toISOString(),
          };
        }
      );

      if (context) return context;
      return {
        didOptimisticUpdate: false,
      };
    },
    [queryClient.setQueriesData, queryKeys.getPrimaryContactLegacy]
  );

  return useMutation<
    UpsertPrimaryContactLegacyIO['output'],
    E,
    UpsertPrimaryContactLegacyIO['input'],
    UpsertPrimaryContactLegacyContext<C>
  >({
    mutationKey: mutationKeys.upsertPrimaryContactLegacy(),
    mutationFn: (req) =>
      SchemaPersonV3Service.UpsertPrimaryContactLegacy({
        ...req,
        phoneNumber: formatPhoneNumberE164(req.phoneNumber),
      }),
    ...options,
    onMutate: async (req) => {
      return {
        optimisticUpdateContext: updateQueryCache(req),
        optionContext: await options?.onMutate?.(req),
      };
    },
    onSuccess: (res, req, context) => {
      if (!context?.optimisticUpdateContext.didOptimisticUpdate) {
        updateQueryCache(req);
      }
      options?.onSuccess?.(res, req, context?.optionContext);
    },
    onError: (_err, _req, context) => {
      if (context?.optimisticUpdateContext.didOptimisticUpdate) {
        updateQueryCache({
          ...context.optimisticUpdateContext.previousData,
        });
      }
    },
    onSettled: (res, err, req, context) => {
      options?.onSettled?.(res, err, req, context?.optionContext);
    },
  });
};
