import { useQueryClient } from 'react-query';
import { NotificationQueries } from '@frontend/api-notifications';
import { useTranslation } from '@frontend/i18n';
import { useAlert } from '@frontend/design-system';

export const usePopupNotificationQuery = () => {
  const { t } = useTranslation('chat');
  const queryClient = useQueryClient();
  const { error } = useAlert();
  const { data, refetch } = NotificationQueries.useNotificationSettingsQuery();
  const { mutate: updateNotificationSetting } = NotificationQueries.useNotificationSettingsMutation({
    onSuccess: () => {
      refetch();
    },
    onError: () => {
      error(t('Failed to update settings.'));
      refetch();
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        predicate(query) {
          return query.queryKey[1] === 'notification-settings';
        },
      });
    },
  });
  const chatNotification = data?.find(({ name }) => name === 'Chat Message');

  return {
    popup: !!chatNotification?.sendNotification,
    updateNotificationSetting: (value: boolean) => {
      updateNotificationSetting({ id: chatNotification?.notificationType, value });
    },
  };
};
