import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, TextButton } from '@frontend/design-system';

type InboxFiltersPopoverHeadingItemProps = {
  label: string;
  handleClear: () => void;
  hasSelection: boolean;
  clearButtonId: string;
  largeLabel?: boolean;
  trackingId?: string;
};
export const InboxFiltersPopoverHeadingItem = ({
  label,
  handleClear,
  hasSelection,
  clearButtonId,
  largeLabel,
  trackingId,
}: InboxFiltersPopoverHeadingItemProps) => {
  const { t } = useTranslation('inbox');

  return (
    <div
      css={[
        {
          padding: theme.spacing(0, 2),
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          ':hover': {
            backgroundColor: 'inherit',
          },
          ':focus': {
            backgroundColor: 'inherit',
          },
        },
      ]}
      tabIndex={hasSelection ? undefined : -1}
    >
      {largeLabel ? (
        <Text weight='semibold'>{label}</Text>
      ) : (
        <Text size='small' color='subdued' weight='bold'>
          {label}
        </Text>
      )}
      <TextButton
        id={clearButtonId}
        onClick={handleClear}
        css={[
          {
            color: hasSelection ? theme.font.colors.primary : theme.font.colors.disabled,
            fontWeight: theme.font.weight.semibold,
          },
        ]}
        tabIndex={hasSelection ? 0 : -1}
        disabled={!hasSelection}
        trackingId={trackingId}
      >
        {t('Clear')}
      </TextButton>
    </div>
  );
};
