import { useEffect } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery } from 'react-query';
import { FormsQueryKeys, ProviderReview } from '@frontend/api-forms';
import { getUser } from '@frontend/auth-helpers';
import { useWritebackWidgetStore } from '../../../providers';

interface UseDefaultSignatureResults {
  refetchDefaultSignature: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<QueryObserverResult<ProviderReview.Types.ProviderSignatureResponse, unknown>>;
}

export const useDefaultSignature = (autoFetch = true): UseDefaultSignatureResults => {
  const { setSignature, setSavedSignature } = useWritebackWidgetStore(['setSignature', 'setSavedSignature']);
  const user = getUser();
  const providerEmail = user?.username ?? '';

  const { data, isLoading, refetch } = useQuery(
    [FormsQueryKeys.providerReview.signature, providerEmail],
    () => ProviderReview.API.getDefaultSignature(providerEmail),
    {
      enabled: autoFetch,
      retry: false,
    }
  );

  useEffect(() => {
    if (!data || !data.provider_esign || isLoading || !autoFetch) {
      return;
    }

    // TODO: fix "any"
    const signatureField = JSON.parse(data.provider_esign as any);
    const signatureFieldId = Object.keys(signatureField)[0];
    const savedSign = JSON.parse(signatureField[signatureFieldId].value);

    setSignature({
      provider_esign: {
        ...signatureField,
      },
    });

    setSavedSignature(savedSign);
  }, [data, isLoading, autoFetch, setSignature, setSavedSignature]);

  return {
    refetchDefaultSignature: refetch,
  };
};
