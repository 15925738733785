import { ComponentProps, forwardRef } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { TextButton, useTooltip } from '@frontend/design-system';

type AssociatedContactsButtonProps = {
  count: number;
  onClick: NonNullable<ComponentProps<typeof TextButton>['onClick']>;
};

/**
 * `AssociatedContactsButton` is a button meant to open the associated contacts drawer when clicked.
 * It is best used as a child of the `ThreadTitle` component.
 * If there are no associated contacts, the button should not be displayed.
 *
 * @param count - The number of associated contacts. This should not include the current primary contact.
 * @param onClick - A callback function that will be called when the button is clicked. If not provided,
 * the button will not do anything.
 */
export const AssociatedContactsButton = forwardRef<HTMLButtonElement, AssociatedContactsButtonProps>(
  ({ count, onClick }, ref) => {
    const { t } = useTranslation('thread-header');

    const { Tooltip, tooltipProps, triggerProps } = useTooltip({
      placement: 'top',
      trigger: 'hover',
    });

    return (
      <>
        <TextButton
          {...triggerProps}
          onClick={onClick}
          css={{
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(0.25),
            fontSize: theme.fontSize(12),
            padding: theme.spacing(0.5),
            borderRadius: theme.borderRadius.small,
            color: theme.colors.neutral70,
            ':hover': {
              backgroundColor: theme.colors.neutral5,
            },
          }}
          ref={ref}
          trackingId={`${InboxPrefixes.Thread}-header-associated-contacts-button`}
        >
          <Icon name='family-small' />
          {`+${count}`}
        </TextButton>
        <Tooltip {...tooltipProps}>{t('View Associated Contacts')}</Tooltip>
      </>
    );
  }
);
AssociatedContactsButton.displayName = 'AssociatedContactsButton';
