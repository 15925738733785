import { useEffect, useState } from 'react';
import { SubmissionMode } from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';
import { Template, TemplateType_Slug } from '@weave/schema-gen-ts/dist/schemas/messaging/templator/v2/model.pb';
import { ContactType_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import { AppointmentsApiQueries, AppointmentsApiTypes } from '@frontend/api-appointments';
import { DigitalFormsMutations } from '@frontend/api-digital-forms';
import { PersonQueries, PersonsV3 } from '@frontend/api-person';
import { AppointmentSelectorComponents } from '@frontend/appointment-selector';
import {
  FormPacketSelectorComponents,
  FormPacketSelectorHooks,
  FormPacketSelectorTypes,
} from '@frontend/form-packet-selector';
import { useTranslation } from '@frontend/i18n';
import { TemplateSelector, ModalControlWithKeepState } from '@frontend/integrated-messaging';
import { ContentLoader, Modal } from '@frontend/design-system';

type AppointmentSelection = AppointmentsApiTypes.ListAppointmentsIO['output']['appointments'][number];
type InsertArgs = { appointmentId?: string; formOrPacketId: string; formOrPacketLink: string; template?: Template };
type FormsTemplateModalProps = ModalControlWithKeepState & {
  groupId: string;
  personId?: string;
  personPhone: string;
  onInsert: (args: InsertArgs) => void;
  onOpenSettings?: () => void;
  settingsOpenDelay?: number;
};

export const FormsTemplateModal = ({
  groupId,
  personId,
  personPhone,
  onInsert,
  onOpenSettings,
  show,
  settingsOpenDelay,
  ...rest
}: FormsTemplateModalProps) => {
  const { t } = useTranslation('inbox-templates');
  const personQuery = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    {
      personId: personId ?? '',
      locationIds: [groupId],
    },
    {
      enabled: !!personId,
    }
  );
  const appointmentsQuery = AppointmentsApiQueries.useListPersonAppointments(
    {
      locationId: groupId,
      personId: personId ?? '',
    },
    {
      enabled: !!personId,
      placeholderData: {
        appointments: [],
      },
      retry: 1,
    }
  );
  const householdQuery = PersonQueries.useGetPersonHouseholds(personQuery.data?.householdId ?? '', {
    disabled: !personId || !personQuery.data?.householdId,
    locationId: groupId,
  });
  const allowAppointmentSelection =
    !!personId && (appointmentsQuery.isFetched ? !!appointmentsQuery.data?.appointments.length : true);
  const [currentStep, setCurrentStep] = useState<'appointment' | 'forms' | 'template'>(
    allowAppointmentSelection ? 'appointment' : 'forms'
  );
  const [selectedAppointment, setSelectedAppointment] = useState<AppointmentSelection>();
  const [selectedFormOrPacket, setSelectedFormOrPacket] = useState<FormPacketSelectorTypes.FormOrPacket>();
  const {
    selectorProps,
    selectedItem,
    selectedCategory,
    reset: resetFormPacketList,
  } = FormPacketSelectorHooks.useFormPacketSelector({
    groupId,
  });

  const handleAppointmentSelection = (appt: AppointmentSelection) => {
    setSelectedAppointment(appt);
    setCurrentStep('forms');
  };

  const handleFormOrPacketSelection = (
    formOrPacket: FormPacketSelectorTypes.FormOrPacket,
    selectTemplate?: boolean
  ) => {
    setSelectedFormOrPacket(formOrPacket);
    if (selectTemplate) {
      setCurrentStep('template');
      return;
    }

    handleInsert(formOrPacket);
  };

  const handleFormsBack = allowAppointmentSelection
    ? () => {
        setSelectedAppointment(undefined);
        setCurrentStep('appointment');
      }
    : undefined;
  const handleTemplatesBack = () => {
    setSelectedFormOrPacket(undefined);
    setCurrentStep('forms');
  };

  const { isLoading, mutateAsync } = DigitalFormsMutations.useCreateFormLinks();

  const handleInsert = async (
    formOrPacket: FormPacketSelectorTypes.FormOrPacket | undefined = selectedFormOrPacket,
    template?: Template
  ) => {
    if (!formOrPacket) return;
    const linkRes = await mutateAsync({
      companyId: formOrPacket.companyId || groupId,
      patients: personId
        ? [
            {
              id: personId,
              firstName: personQuery.data?.preferredName || personQuery.data?.firstName,
              lastName: personQuery.data?.lastName,
              birthdate: personQuery.data?.birthdate,
              email: personQuery.data?.contactInfo?.find((c) => c.type === ContactType_Enum.EMAIL)?.destination,
              appointmentId: selectedAppointment?.appointmentId,
              phoneNumber: personPhone,
            },
          ]
        : [],
      sendId: formOrPacket.id,
      type: selectedCategory === FormPacketSelectorTypes.Category.FORMS ? 'form' : 'packet',
      familyHead: householdQuery.data?.find((h) => h.is_guardian)?.id,
      mode: SubmissionMode.SOLICITED_DIRECT,
    });
    const linkToSend = linkRes.data?.find((link) => !!link.submitUrl)?.submitUrl ?? '';
    if (formOrPacket.id && linkToSend)
      onInsert({
        appointmentId: selectedAppointment?.appointmentId,
        formOrPacketId: formOrPacket.id ?? '',
        formOrPacketLink: linkToSend,
        template,
      });
  };

  useEffect(() => {
    if (currentStep === 'appointment' && !allowAppointmentSelection) {
      setCurrentStep('forms');
    }
  }, [allowAppointmentSelection]);

  useEffect(() => {
    if (show === false) {
      setSelectedAppointment(undefined);
      setSelectedFormOrPacket(undefined);
      setCurrentStep(allowAppointmentSelection ? 'appointment' : 'forms');
      resetFormPacketList();
    }
  }, [show]);
  const booleanShow = show === 'hidden' ? false : show;

  return (
    <Modal
      css={{
        minWidth: 'min(500px, 90vw)',
        minHeight: 'min(600px, 90vh)',
        maxHeight: 'min(600px, 90vh)',
      }}
      {...rest}
      show={booleanShow}
    >
      <ContentLoader show={isLoading} />
      {currentStep === 'appointment' && (
        <AppointmentSelectorComponents.AppointmentSelectorModalContents
          groupId={groupId}
          personId={personId!}
          onSelect={handleAppointmentSelection}
          onSkip={() => setCurrentStep('forms')}
          onClose={rest.onClose}
        />
      )}
      {currentStep === 'forms' && (
        <>
          <Modal.Header onClose={rest.onClose}>{t('Select Packet or Form')}</Modal.Header>
          <FormPacketSelectorComponents.FormPacketSelectorModalContents {...selectorProps} />
          <Modal.Actions
            primaryLabel={t('Insert')}
            disablePrimary={!selectedItem}
            onPrimaryClick={() => {
              if (selectedItem) handleFormOrPacketSelection(selectedItem);
            }}
            secondaryLabel={t('Insert & Select Message Template')}
            disableSecondary={!selectedItem}
            onSecondaryClick={() => {
              if (selectedItem) handleFormOrPacketSelection(selectedItem, true);
            }}
            backLabel={allowAppointmentSelection ? t('Back') : undefined}
            onBackClick={handleFormsBack}
          />
        </>
      )}
      {currentStep === 'template' && (
        <>
          <Modal.Header>{t('Select a Forms Template')}</Modal.Header>
          <Modal.Body>
            <TemplateSelector
              groupId={groupId}
              templateTypes={[TemplateType_Slug.MANUAL_FORMS_MESSAGE]}
              onSelectTemplate={(template) => handleInsert(selectedFormOrPacket, template)}
              onOpenSettings={onOpenSettings}
              settingsOpenDelay={settingsOpenDelay}
            />
          </Modal.Body>
          <Modal.Actions showPrimary={false} onBackClick={handleTemplatesBack} backLabel={t('Back')} />
        </>
      )}
    </Modal>
  );
};
