import { ReactNode, memo, useLayoutEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { FloatingPortal } from '@floating-ui/react';
import { animated, useSpring } from 'react-spring';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { NakedUl, Text } from '@frontend/design-system';
import { useChartContext } from '../chart.provider';

export type TooltipData = {
  color: string;
  formattedValue: number | string;
  id: string;
  label: string;
  subLabel?: string;
};

export type CustomTooltipTitle = {
  groupName: string;
  hoveredSegment?: string | null;
};

export type TooltipProps = {
  applyMargin?: boolean;
  data: TooltipData[];
  isClickable?: boolean;
  itemType?: 'bar' | 'segment';
  name?: string | ReactNode;
  xPos?: number;
  yPos?: number;
};

const pagePadding = 24;
const xMargin = 16;

export const Tooltip = memo(
  ({ applyMargin, data = [], isClickable, itemType, name, xPos = 0, yPos = 0 }: TooltipProps) => {
    const { t } = useTranslation('analytics');
    const { activeLegends, labels } = useChartContext();
    const contentRef = useRef<HTMLDivElement>(null);
    const [x, setX] = useState<number | undefined>();
    const [y, setY] = useState<number | undefined>();

    const [animStyles] = useSpring(() => ({
      config: {
        duration: 300,
      },
      delay: 100,
      from: { opacity: 0 },
      to: { opacity: 1 },
    }));

    useLayoutEffect(() => {
      if (!applyMargin && (!xPos || !yPos)) {
        return;
      }

      const height = contentRef.current?.clientHeight || 0;
      const width = contentRef.current?.clientWidth || 0;

      const rightSpace = window.innerWidth - (xPos + xMargin) - pagePadding;
      const bottomSpace = window.innerHeight - yPos - pagePadding * 2;

      setX(rightSpace > width ? xPos + xMargin : xPos - width - xMargin);
      setY(bottomSpace > height / 2 ? yPos - height / 2 : yPos - height);
    }, [xPos, yPos]);

    if (!x || !y) {
      return null;
    }

    return (
      <FloatingPortal>
        <animated.div
          css={styles.wrapper}
          ref={contentRef}
          style={{
            left: x,
            top: y,
            ...animStyles,
          }}
        >
          <NakedUl css={styles.list}>
            {name && <li className='tip-name'>{typeof name === 'string' ? labels[name] || name : name}</li>}
            {data.map(
              ({ color, formattedValue, id, label, subLabel }, idx) =>
                activeLegends.includes(id) && (
                  <li className='chart-tooltip-list' key={label || `${id}-${idx}`}>
                    <div className='tooltip-main'>
                      <Text as='span' weight='bold' size='medium' style={{ color }}>
                        {formattedValue}
                      </Text>
                      <Text as='span' size='medium' style={{ color: label ? 'inherit' : theme.colors.status.critical }}>
                        {label || id}
                      </Text>
                    </div>
                    {subLabel && (
                      <Text as='span' size='small' color='subdued'>
                        {subLabel}
                      </Text>
                    )}
                  </li>
                )
            )}
            {isClickable && (
              <li className='chart-tooltip-list' key='clickable' style={{ minWidth: '150px' }}>
                <Text as='i' color='subdued' size='small'>
                  {itemType ? t('Click the {{itemType}} to learn more', { itemType }) : t('Click to learn more')}
                </Text>
              </li>
            )}
          </NakedUl>
        </animated.div>
      </FloatingPortal>
    );
  }
);

Tooltip.displayName = 'Tooltip';

const styles = {
  wrapper: css`
    border-radius: ${theme.borderRadius.medium};
    border: 1px solid ${theme.colors.neutral20};
    overflow: hidden;
    pointer-events: none;
    position: fixed;
    z-index: ${theme.zIndex.modals + 1}; // set to place above modals
  `,

  list: css`
    background-color: ${theme.colors.white};
    box-shadow: ${theme.shadows.light};
    min-width: ${theme.spacing(12.5)};
    padding: ${theme.spacing(2, 2, 1.5, 2)}};

    .tip-name {
      background-color: ${theme.colors.white};
      border-bottom: 1px solid ${theme.colors.neutral5};
      color: ${theme.colors.neutral40};
      font-size: ${theme.font.size.small};
      padding: ${theme.spacing(0, 0, 1)};
      white-space: nowrap;
    }

    .chart-tooltip-list {
      border-bottom: 1px solid ${theme.colors.neutral5};
      padding: ${theme.spacing(0.5, 0)};
    
      .tooltip-main {
        align-items: center;
        color: ${theme.colors.neutral70};
        display: flex;
        flex-wrap: no-wrap;
        gap: ${theme.spacing(1)};
      
        > span {
          white-space: nowrap;
        }
      }

      &:last-of-type {
        border-bottom: none;
      }
    }
  `,
};
