import { BuilderTypes } from '@frontend/forms-builder-engine';

const draggablePrefixes = {
  primaryField: 'primary',
  otherField: 'elements',
  sectionTemplate: 'section-template',
  fieldInSection: 'field-in-section',
  sectionInForm: 'section-in-form',
};

const droppablePrefixes = {
  section: 'section',
  form: 'form',
  fieldCategory: 'field-category',
  sectionTemplates: 'section-templates',
};

const SEPARATOR = '__';

// ===================== Draggable Utils =====================

/**
 * @param fieldKey - The key of the primary field.
 * @returns The draggable ID for the primary field.
 */
export function getPrimaryFieldDraggableId(
  fieldKey: keyof BuilderTypes.BuilderFormElementTypes.PrimaryFields.ParsedFields
): string {
  return [draggablePrefixes.primaryField, fieldKey].join(SEPARATOR);
}

/**
 * @param fieldKey - The key of the other field.
 * @returns The draggable ID for the other field.
 */
export function getOtherFieldDraggableId(
  fieldKey: keyof BuilderTypes.BuilderFormElementTypes.OtherFields.CombinedOtherFields
): string {
  return [draggablePrefixes.otherField, fieldKey].join(SEPARATOR);
}

type FieldInfoFromDraggableId =
  | {
      isPrimaryField: true;
      fieldKey: keyof BuilderTypes.BuilderFormElementTypes.PrimaryFields.ParsedFields;
    }
  | {
      isPrimaryField: false;
      fieldKey: keyof BuilderTypes.BuilderFormElementTypes.OtherFields.CombinedOtherFields;
    };

/**
 * @param draggableId - The draggable ID.
 * @returns The field key and whether it is a primary field or not.
 */
export function getFieldInfoFromDraggableId(draggableId: string): FieldInfoFromDraggableId {
  const [fieldType, fieldKey] = draggableId.split(SEPARATOR);

  if (fieldType === draggablePrefixes.primaryField) {
    return {
      isPrimaryField: true,
      fieldKey: fieldKey as keyof BuilderTypes.BuilderFormElementTypes.PrimaryFields.ParsedFields,
    };
  }

  return {
    isPrimaryField: false,
    fieldKey: fieldKey as keyof BuilderTypes.BuilderFormElementTypes.OtherFields.CombinedOtherFields,
  };
}

/**
 * @param sectionTemplateKey - The key of the section template.
 * @returns The draggable ID for the section template.
 */
export function getSectionTemplateDraggableId(
  sectionTemplateKey: BuilderTypes.BuilderFormElementTypes.SectionTemplates.TemplateKey
): string {
  return [draggablePrefixes.sectionTemplate, sectionTemplateKey].join(SEPARATOR);
}

/**
 * @param draggableId - The draggable ID.
 * @returns The section template key.
 */
export function getSectionTemplateKeyFromDraggableId(
  draggableId: string
): BuilderTypes.BuilderFormElementTypes.SectionTemplates.TemplateKey {
  return draggableId.split(SEPARATOR)[1] as BuilderTypes.BuilderFormElementTypes.SectionTemplates.TemplateKey;
}

/**
 * @param fieldId - The ID of the field.
 * @returns The draggable ID for the field in a section.
 */
export function getFieldInSectionDraggableId(fieldId: string): string {
  return [draggablePrefixes.fieldInSection, fieldId].join(SEPARATOR);
}

/**
 * This should only be used for fields in a section.
 * @param draggableId - The draggable ID.
 * @returns The field ID.
 */
export function getFieldIdFromDraggableId(draggableId: string): string {
  return draggableId.split(SEPARATOR)[1];
}

/**
 * @param sectionId - The ID of the section.
 * @returns The draggable ID for the section in the form.
 */
export function getSectionInFormDraggableId(sectionId: string): string {
  return [draggablePrefixes.sectionInForm, sectionId].join(SEPARATOR);
}

/**
 * This should only be used for sections in the form.
 * @param draggableId - The draggable ID.
 * @returns The section ID.
 */
export function getSectionIdFromDraggableId(draggableId: string): string {
  return draggableId.split(SEPARATOR)[1];
}

// ===================== Droppable Utils =====================

/**
 * @param sectionId - The ID of the section.
 * @returns The droppable ID for the section (to drop fields).
 */
export function getSectionDroppableId(sectionId: string): string {
  return [droppablePrefixes.section, sectionId].join(SEPARATOR);
}

/**
 * @param droppableId - The droppable ID.
 * @returns The section ID.
 */
export function getSectionIdFromDroppableId(droppableId: string): string {
  return droppableId.split(SEPARATOR)[1];
}

/**
 * @returns The droppable ID for the form (to drop sections).
 */
export function getFormDroppableId(): string {
  return droppablePrefixes.form;
}

/**
 * @param categoryLabel - The label of the field category.
 * @returns The droppable ID for the field category (to hold the fields to drag from).
 */
export function getFieldCategoryDroppableId(categoryLabel: string): string {
  return [droppablePrefixes.fieldCategory, categoryLabel].join(SEPARATOR);
}

/**
 * @returns The droppable ID for the section templates (to hold the sections to drag from).
 */
export function getSectionTemplatesDroppableId(): string {
  return droppablePrefixes.sectionTemplates;
}

/**
 * @param sourceDroppableId - The droppable ID of the source.
 * @returns Whether the item was dropped from another section.
 */
export function isDroppedFromAnotherSection(sourceDroppableId: string): boolean {
  return sourceDroppableId.startsWith(droppablePrefixes.section);
}
