import { useCallback } from 'react';
import { InfiniteData, QueryKey, useQueryClient } from 'react-query';
import { InboxQueries, MessagesHooks, MessagesTypes, SchemaSMSSharedEnums } from '@frontend/api-messaging';
import { PersonHelpers } from '@frontend/api-person';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { useNotificationSettingsShallowStore, useSmsMessageNotification } from '@frontend/notifications';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import {
  MessagePopupThreadStatus,
  convertStringToMessagePopupThreadStatus,
  useMessagePopupBarManager,
} from '@frontend/popup-bar';
import { SchemaIO, SchemaSMSService } from '@frontend/schema';
import { useHasFeatureFlag } from '@frontend/shared';
import { GetWeavePopNotificationByType } from '@frontend/types';
import {
  GetWebsocketEventHandler,
  SmsMessageEventPayload,
  SmsMessageStatusUpdatePayload,
  SmsNewMessageEventPayload,
  SmsTag2EventPayload,
  SmsTypingEventPayload,
  useWebsocketEventSubscription,
} from '@frontend/websocket';
import { useTypingIndicatorStore } from '../stores';
import { convertStringToStatus, getSMSFromWebsocketEvent } from '../utils';

type ThreadQueryData = InfiniteData<SchemaIO<(typeof SchemaSMSService)['GetThread']>['output']> | undefined;
type CategorizedThreadQueries = {
  taggedThreads: [QueryKey, ThreadQueryData][];
  standardThreads: [QueryKey, ThreadQueryData][];
};

export const useSmsMessageWSSubscription = () => {
  const { t } = useTranslation('notifications');
  // This still needs to use `useLocationDataShallowStore` because it's used for WebSockets, which only works with the
  // primary location.
  const user = getUser();
  const client = useQueryClient();
  const { locationId } = useLocationDataShallowStore('locationId');
  const { addPopup, updateThreadStatus } = useMessagePopupBarManager();
  const threadUpdates = MessagesHooks.useUpdateThread();
  const scheduledThreadUpdates = MessagesHooks.useUpdateScheduledMessages();
  const { updateMessageTags } = MessagesHooks.useUpdateSmsTags();
  const invalidateInboxList = MessagesHooks.useInvalidateInboxList();
  const { setThreadTypingStatus } = useTypingIndicatorStore();
  const { notificationSettings } = useNotificationSettingsShallowStore('notificationSettings');

  const hasSmsReply = useHasFeatureFlag('nwx-notification:sms-reply');
  const { mutate: sendMessage } = MessagesHooks.useSendMessageMutation({ locationId });

  const respond: Parameters<typeof useSmsMessageNotification>[0]['onRespond'] = useCallback(
    (notification, response) => {
      return sendMessage({
        threadId: notification.payload.threadId,
        targetSmsId: undefined,
        body: response,
        media: [],
        createdBy: user?.userID ?? '',
        departmentId: notification.payload.departmentId,
        locationId: notification.payload.locationId,
        personPhone: notification.payload.number,
        programSlugId: MessagesTypes.KnownProgramSlugIds.MANUAL_MESSAGES,
        personId: notification.payload.personId,
      });
    },
    [sendMessage]
  );

  const view: Parameters<typeof useSmsMessageNotification>[0]['onView'] = useCallback(
    ({ payload }) => {
      addPopup({
        id: payload.threadId,
        name:
          PersonHelpers.getFullName({ FirstName: payload.firstName, LastName: payload.lastName }) ||
          formatPhoneNumber(payload.number),
        type: 'message',
        meta: {
          groupId: payload.locationId,
          threadId: payload.threadId,
          personPhone: payload.number,
          personId: payload.personId,
          isNew: false,
          departmentId: payload.departmentId,
          status: MessagePopupThreadStatus.READ,
        },
      });
    },
    [addPopup]
  );

  const { create } = useSmsMessageNotification({
    onRespond: respond,
    onView: view,
  });

  const getThreadQueries = useCallback(
    (threadId: string, locationId: string): CategorizedThreadQueries => {
      const queryKey = InboxQueries.queryKeys.getThread({
        locationId,
        threadId,
      });
      const queries = client.getQueriesData<ThreadQueryData>({ queryKey, exact: false });
      return queries.reduce<CategorizedThreadQueries>(
        (acc, [queryKey, queryData]) => {
          if (!Array.isArray(queryKey)) return acc;
          const lastKeyEntry = queryKey.at(-1);
          if (lastKeyEntry && typeof lastKeyEntry === 'object' && 'taggedSmsId' in lastKeyEntry) {
            acc.taggedThreads.push([queryKey, queryData]);
          } else {
            acc.standardThreads.push([queryKey, queryData]);
          }
          return acc;
        },
        { taggedThreads: [], standardThreads: [] }
      );
    },
    [client]
  );

  // Keeping this here for now. Might still be useful in a pinch with WS fragility
  // const invalidateThreadQueries = useCallback(
  //   (threadId: string, locationId: string) => {
  //     const { taggedThreads, standardThreads } = getThreadQueries(threadId, locationId);
  //     [...taggedThreads, ...standardThreads].forEach(([queryKey]) =>
  //       client.invalidateQueries({ queryKey, exact: true })
  //     );
  //   },
  //   [client, getThreadQueries]
  // );

  const invalidateTaggedThreadQueries = useCallback(
    (threadId: string, locationId: string) => {
      const { taggedThreads } = getThreadQueries(threadId, locationId);
      taggedThreads.forEach(([queryKey]) => client.invalidateQueries({ queryKey, exact: true }));
    },
    [client, getThreadQueries]
  );

  const handleNewMessage = useCallback(
    (params: SmsNewMessageEventPayload['params']) => {
      if (!params.thread_ids.length) return;
      const threadId = params.thread_ids[0];
      const locationId = params.location_id;

      if (!threadId) return;
      invalidateTaggedThreadQueries(threadId, params.location_id);
      if (params.action === 'inbound') updateThreadStatus(threadId, MessagePopupThreadStatus.NEW);

      const scheduledMessageId = params.related_ids?.length
        ? params.related_ids.find(
            (relatedId) => relatedId.type === SchemaSMSSharedEnums.RelatedType.RELATED_TYPE_SCHEDULED_MESSAGE_ID
          )?.id
        : undefined;
      if (scheduledMessageId) {
        scheduledThreadUpdates.remove({ threadId, groupId: locationId, smsId: scheduledMessageId });
      }

      if (scheduledMessageId || (user?.userID && params.weave_user_id !== user.userID)) {
        const newMessage = getSMSFromWebsocketEvent(params);
        threadUpdates.upsert({ sms: newMessage });
      }
    },
    [getSMSFromWebsocketEvent, threadUpdates.upsert, invalidateTaggedThreadQueries, updateThreadStatus]
  );

  const handlePopupThreadStatusUpdate = useCallback(
    (params: SmsMessageEventPayload['params']) => {
      invalidateInboxList();
      const actionStatusMap: Partial<Record<(typeof params)['action'], MessagePopupThreadStatus>> = {
        bulkMarkRead: MessagePopupThreadStatus.READ,
        bulkMarkUnread: MessagePopupThreadStatus.NEW,
        markedRead: MessagePopupThreadStatus.READ,
        markedUnread: MessagePopupThreadStatus.NEW,
      };

      const threadId = 'thread_ids' in params ? params.thread_ids?.[0] : params.thread_id;
      if (!threadId) return;
      const mappedStatus = actionStatusMap[params.action];

      if (mappedStatus) {
        updateThreadStatus(threadId, mappedStatus);
        return;
      }

      if (
        params.action === 'inboundThreadUpdate' ||
        params.action === 'readStatusChange' ||
        params.action === 'status'
      ) {
        updateThreadStatus(
          threadId,
          convertStringToMessagePopupThreadStatus(params.status, MessagePopupThreadStatus.READ)
        );
        return;
      }
    },
    [updateThreadStatus, invalidateInboxList]
  );

  const handleMessageStatusUpdate = useCallback(
    (params: SmsMessageStatusUpdatePayload['params']) => {
      if (!params.thread_ids.length) return;
      const threadId = params.thread_ids[0];

      // Check if threadId is an empty string, and do nothing if true (BE is being updated to send threadId instead of empty string)
      if (!threadId) return;
      const locationId = params.location_id;
      invalidateTaggedThreadQueries(threadId, locationId);

      const newStatus = convertStringToStatus(params.status, SchemaSMSSharedEnums.Status.STATUS_UNSPECIFIED);
      threadUpdates.shallowUpdate({
        threadId,
        locationId,
        vals: {
          id: params.uuid,
          status: newStatus === SchemaSMSSharedEnums.Status.STATUS_UNSPECIFIED ? undefined : newStatus,
        },
      });
    },
    [invalidateTaggedThreadQueries, threadUpdates.shallowUpdate]
  );

  const handleTagEvent = useCallback(
    (params: SmsTag2EventPayload['params']) => {
      if (!params.thread_ids || !params.thread_ids.length) return;
      if (user?.userID && params.weave_user_id === user.userID) return;
      const threadId = params.thread_ids[0];
      const locationId = params.location_id;

      if (!threadId) return;
      const { taggedThreads, standardThreads } = getThreadQueries(threadId, locationId);

      [...taggedThreads, ...standardThreads].forEach(([queryKey]) => {
        const parsedKeys = InboxQueries.queryKeys.parseGetThread(queryKey);
        if (!parsedKeys) return;
        const { threadId, locationId, taggedSmsId } = parsedKeys;
        updateMessageTags({
          method: params.action === 'smsTagApplied' ? 'apply' : 'dismiss',
          smsId: params.sms_id,
          tagId: params.tag_id,
          threadId,
          locationId,
          taggedSmsId,
          userId: params.weave_user_id ?? '',
        });
      });
      if (taggedThreads.length + standardThreads.length === 0) invalidateInboxList();
    },
    [user?.userID, updateMessageTags, getThreadQueries, invalidateInboxList]
  );

  const handleTypingEvent = useCallback(
    (params: SmsTypingEventPayload['params']) => {
      if (user?.userID && params.weave_user_id === user?.userID) return;

      setThreadTypingStatus({
        locationId: params.location_id,
        threadId: params.thread_id,
        userId: params.weave_user_id,
        firstName: params.first_name,
        lastName: params.last_name,
        isTyping: params.is_typing,
      });
    },
    [setThreadTypingStatus, user?.userID]
  );

  const websocketSubscriptionHandler: GetWebsocketEventHandler<'SMSNotification'> = useCallback(
    (payload) => {
      const { params } = payload;
      switch (params.action) {
        case 'inbound': {
          handleNewMessage(params);
          if (params.thread_ids[0])
            scheduledThreadUpdates.invalidateQuery({ threadId: params.thread_ids[0]!, groupId: params.location_id });
          const notification = {
            id: typeof payload.id === 'number' ? payload.id.toString() : payload.id,
            type: 'sms-message-new',
            payload: {
              body: params.body,
              number: params.patient_number || params.phone_mobile || t('Unknown'),
              personId: params.person_id,
              threadId: params.thread_ids[0] || '',
              departmentId: params.department_id,
              locationId: params.location_id,
              firstName: params.first_name,
              lastName: params.last_name,
            },
            timestamp: Date.now(),
            state: {
              paused: false,
              timeout: notificationSettings.durationMs,
              status: 'unread',
            },
            flags: {
              shouldAllowReply: hasSmsReply,
            },
          } satisfies GetWeavePopNotificationByType<'sms-message-new'>;
          setTimeout(() => {
            client.invalidateQueries([locationId, 'notification-history']);
          }, 5000);
          create(notification, {
            os: {
              title: t('New Message'),
              body: params.body,
            },
          });
          return;
        }
        case 'outbound':
          handleNewMessage(params);
          return;
        case 'status':
          handleMessageStatusUpdate(params);
          return;
        case 'smsTagApplied':
        case 'smsTagDismissed':
          handleTagEvent(params);
          return;
        case 'archived':
        case 'bulkMarkRead':
        case 'bulkMarkUnread':
        case 'markedRead':
        case 'markedUnread':
        case 'readStatusChange':
          handlePopupThreadStatusUpdate(params);
          return;
        case 'inboundThreadUpdate':
          handlePopupThreadStatusUpdate(params);
          return;
        case 'typing':
          handleTypingEvent(params);
          return;
        case 'updateActionableStatus':
        case 'tagAddedToThreads':
        case 'tagCreated':
        case 'tagDeleted':
        case 'tagRemovedFromThreads':
        case 'tagUpdated':
          return;
        default: {
          const _exhaustive: never = params;
          return _exhaustive;
        }
      }
    },
    [
      create,
      hasSmsReply,
      handleNewMessage,
      handleMessageStatusUpdate,
      handlePopupThreadStatusUpdate,
      handleTagEvent,
      handleTypingEvent,
      notificationSettings.durationMs,
    ]
  );

  return useWebsocketEventSubscription('SMSNotification', websocketSubscriptionHandler);
};
