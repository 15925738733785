import { useMemo, useState } from 'react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { AppointmentType } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { useQuery } from 'react-query';
import { ScheduleRequestsApi } from '@frontend/api-schedule';
import { ServiceProvidersApi } from '@frontend/api-service-providers';
import { useCalendarEvent } from '../../../../context/CalendarEventProvider';
import { useCalendarViewRefetchMethod } from '../../../../context/CalendarViewRefetchMethodContext';
import { CalendarEventsEnums } from '../../types';
import { CACHE_AND_STALE_TIME, NewAppointmentForm } from './NewAppointmentForm';
import {
  AppointmentAvailabilityFormValues,
  OnCreateCalendarEvent,
  OnDeleteCalendarEvent,
  OnUpdateCalendarEvent,
} from './types';

type NewAppointmentFormContainerProps = {
  eventType: CalendarEventsEnums;
  closeModal: () => void;
  onAppointmentSuccess?: (appointment?: Appointment) => void;
};

const { createAppointment, updateAppointment, deleteAppointment } = ServiceProvidersApi;
const { getNewApptOpenings, getApptTypes } = ScheduleRequestsApi;

export const NewAppointmentFormContainer = ({ closeModal, onAppointmentSuccess }: NewAppointmentFormContainerProps) => {
  const { providersList, selectedLocationIds } = useCalendarEvent();

  const { refetchAppointments } = useCalendarViewRefetchMethod();

  const [selectedLocationId, setSelectedLocationId] = useState<string>('');

  const [selectedFormValuesForAvailabilityApi, setSelectedFormValuesForAvailabilityApi] =
    useState<AppointmentAvailabilityFormValues>({} as AppointmentAvailabilityFormValues);

  const onUpdateAppointment = async ({
    personId,
    startDate,
    practitionerId,
    duration,
    type,
    locationId,
    workstationId,
    id,
  }: OnUpdateCalendarEvent) => {
    const updateAppointmentResponse = await updateAppointment({
      customerIds: [personId],
      start: startDate,
      providerIds: [practitionerId],
      duration: duration,
      type: type,
      locationIds: [locationId],
      workstationIds: workstationId ? [workstationId] : [],
      id: id,
      createdByLocationId: locationId,
    });

    onAppointmentSuccess?.(updateAppointmentResponse?.appointment);
  };

  const onCreateAppointment = async ({
    personId,
    startDate,
    practitionerId,
    duration,
    type,
    locationId,
    workstationId,
    person,
    practitionerName,
    sourceId,
  }: OnCreateCalendarEvent) => {
    const createAppointmentResponse = await createAppointment({
      appointment: {
        customerIds: [personId],
        start: startDate,
        providerIds: [practitionerId],
        duration: duration,
        type: type,
        locationIds: [locationId],
        workstationIds: workstationId ? [workstationId] : [locationId || ''], //For non integrated offices we do not workstations configured
        createdByLocationId: locationId,
        practitionerName,
        person,
        createdBySourceId: sourceId,
      },
    });

    onAppointmentSuccess?.(createAppointmentResponse?.appointment);
  };

  const onDeleteAppointment = async ({ createdByLocationId, id }: OnDeleteCalendarEvent) => {
    await deleteAppointment({
      createdByLocationId,
      id,
    });
  };

  const { data: appointmentTypes, refetch: refetchAppointmentTypes } = useQuery({
    queryKey: ['appointmentTypes', selectedLocationId],
    queryFn: () => getApptTypes(selectedLocationId),
    retry: 1,
    enabled: !!selectedLocationId,
    cacheTime: CACHE_AND_STALE_TIME, // 5 minute
    staleTime: CACHE_AND_STALE_TIME, // 5 minute
  });

  const appointmentTypesData = useMemo<AppointmentType[]>(() => {
    return (
      appointmentTypes?.map((type) => ({
        id: type.id,
        name: type.name,
        durationMinutes: type.durationMinutes,
        cadence: type.cadence ?? 0,
        locationId: type.locationId,
        maxOpeningsPermitted: type.maxOpeningsPermitted,
        minOpeningsPermitted: type.minOpeningsPermitted,
        requestBufferDuration: type.requestBufferDuration,
        nonIntegrated: type.nonIntegrated,
      })) || []
    );
  }, [appointmentTypes]);

  const { data: availableAppointmentTimes, isLoading: isAvailableAppointmentTimesLoading } = useQuery({
    queryKey: [
      'providerAppointmentOpenings',
      selectedFormValuesForAvailabilityApi.selectedPractitionerId,
      selectedFormValuesForAvailabilityApi.selectedLocationId,
      selectedFormValuesForAvailabilityApi.appointmentType,
      selectedFormValuesForAvailabilityApi.appointmentDate,
    ],
    queryFn: () =>
      (selectedFormValuesForAvailabilityApi.appointmentDate &&
        getNewApptOpenings(
          selectedFormValuesForAvailabilityApi.appointmentType,
          selectedFormValuesForAvailabilityApi.appointmentDate,
          selectedFormValuesForAvailabilityApi.selectedLocationId,
          selectedFormValuesForAvailabilityApi.selectedPractitionerId,
          'day'
        )) ||
      [],
    retry: 1,
    staleTime: CACHE_AND_STALE_TIME, // 5 minute
    cacheTime: CACHE_AND_STALE_TIME, // 5 minute
    enabled:
      !!selectedFormValuesForAvailabilityApi.appointmentDate &&
      !!selectedFormValuesForAvailabilityApi.appointmentType &&
      !!selectedFormValuesForAvailabilityApi.selectedLocationId &&
      !!selectedFormValuesForAvailabilityApi.selectedPractitionerId,
  });

  return (
    <NewAppointmentForm
      availableAppointmentTimes={availableAppointmentTimes ?? []}
      closeModal={closeModal}
      isAvailableAppointmentTimesLoading={isAvailableAppointmentTimesLoading}
      isScheduleV3={false}
      onCreateAppointment={onCreateAppointment}
      onUpdateAppointment={onUpdateAppointment}
      onDeleteAppointment={onDeleteAppointment}
      refetchAppointments={refetchAppointments}
      setSelectedFormValuesForAvailabilityApi={setSelectedFormValuesForAvailabilityApi}
      providersList={providersList}
      setSelectedLocationId={setSelectedLocationId}
      selectedLocationId={selectedLocationId}
      selectedLocationIds={selectedLocationIds}
      appointmentTypes={appointmentTypesData ?? []}
      refetchAppointmentTypes={refetchAppointmentTypes}
    />
  );
};
