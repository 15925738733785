import { formatCentsToCurrency } from '@frontend/currency';
import { formatDate } from '@frontend/date';
import { TFunction } from '@frontend/i18n';
import { CardBrand } from '@frontend/payments-card-brand';
import { paymentTypeMap } from '@frontend/payments-hooks';
import { StatusChip } from '@frontend/payments-status-chip';
import { TableColumnConfig } from '@frontend/design-system';
import { PaymentRow } from '../../types';
import { getPaymentMethod } from '../../utils';

export const generateColumns = (t: TFunction): TableColumnConfig<PaymentRow>[] => [
  {
    id: 'date',
    Header: t('Date', { ns: 'payments' }),
    accessor: (payment) => formatDate(payment.date, 'MMM D, YYYY'),
    disableSortBy: true,
    width: 120,
  },
  {
    id: 'time',
    Header: t('Time', { ns: 'payments' }),
    accessor: (payment) => formatDate(payment.date, 'h:mm A'),
    disableSortBy: true,
    width: 100,
  },
  {
    id: 'amount',
    Header: t('Amount', { ns: 'payments' }),
    accessor: (payment) => formatCentsToCurrency(payment.amount),
    disableSortBy: true,
    width: 100,
  },
  {
    id: 'origin',
    Header: t('Origin', { ns: 'payments' }),
    accessor: (payment) => (payment.origin ? paymentTypeMap[payment.origin] : '-'),
    disableSortBy: true,
    width: 120,
  },
  {
    id: 'method',
    Header: t('Method', { ns: 'payments' }),
    accessor: (payment) => payment,
    cellRenderer: (payment: PaymentRow) => {
      const paymentType = payment?.paymentType;
      const cardBrand = payment?.paymentDetails.brand;
      const last4 = payment?.paymentDetails.lastFour;
      return <CardBrand brand={getPaymentMethod(paymentType, cardBrand)} last4={last4} />;
    },
    disableSortBy: true,
    width: 120,
  },
  {
    id: 'user',
    Header: t('Office User', { ns: 'payments' }),
    accessor: ({ user }) => {
      return user || '-';
    },
    disableSortBy: true,
    width: 140,
  },
  {
    id: 'status',
    Header: t('Status', { ns: 'payments' }),
    accessor: (payment) => payment,
    cellRenderer: (payment) => <StatusChip status={payment?.status} maxWidth={'140'} />,
    disableSortBy: true,
    width: 180,
  },
];
