import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ContentLoader, Heading, Text } from '@frontend/design-system';
import { DemoChip } from '..';
import { usePracticeAnalyticsShallowStore } from '../practice/hooks';
import { ProductionSummary, ProductionSummaryConfig } from './production-types';

type SummaryStatsProps = {
  isLoading?: boolean;
  summaryStats?: ProductionSummary;
};

export const SummaryStats = ({ isLoading, summaryStats }: SummaryStatsProps) => {
  const { t } = useTranslation('analytics');
  const { showDemoChipAndBanner } = usePracticeAnalyticsShallowStore('showDemoChipAndBanner');

  return (
    <div css={styles.summary}>
      <div css={styles.summaryHeader}>
        <div className='header-wrapper'>
          {showDemoChipAndBanner && <DemoChip />}
          <Heading level={3}>{t('Yesterday')}</Heading>
        </div>
        <Text color='light' size='small'>
          {summaryStats?.date.format('dddd, MMM DD')}
        </Text>
      </div>
      <div css={styles.summaryItems}>
        {Object.entries(summaryStats || {}).map(([key, value]) => {
          if (key === 'date') {
            return null;
          }
          const { formattedValue, label } = value as ProductionSummaryConfig;
          return (
            <div css={styles.summaryItem} key={key}>
              <Text as='span' size='medium' weight='bold'>
                {formattedValue}
              </Text>
              <Text as='span' color='light' size='small'>
                {label}
              </Text>
            </div>
          );
        })}
      </div>
      <ContentLoader show={isLoading} size='small' />
    </div>
  );
};

const styles = {
  summary: css`
    border-radius: ${theme.borderRadius.medium};
    border: 1px solid ${theme.colors.neutral20};
    display: flex;
    flex-basis: 30%;
    flex-direction: column;
    flex-grow: 1;
    min-width: 400px;
    overflow: hidden;
    padding: ${theme.spacing(3)};
    position: relative;
  `,

  summaryHeader: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(2)};
    justify-content: space-between;
    margin-bottom: ${theme.spacing(2)};

    .header-wrapper {
      align-items: center;
      display: flex;
      gap: ${theme.spacing(0.5)};
    }
  `,

  summaryItems: css`
    align-items: end;
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    gap: ${theme.spacing(1, 2)};
  `,

  summaryItem: css`
    display: flex;
    flex-direction: column;
    width: calc(50% - ${theme.spacing(2)});
  `,
};
