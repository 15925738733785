import { useTranslation } from '@frontend/i18n';
import { PhoneSyncButton } from '@frontend/phone-sync';
import { theme } from '@frontend/theme';
import { Modal, PrimaryButton, Text } from '@frontend/design-system';
import { useDialpadEstablishConnectionControl } from '../providers/dialpad-connection-provider';

export const ConnectDevice = () => {
  const { t } = useTranslation('dialpad');
  const connectDeviceModalProps = useDialpadEstablishConnectionControl((ctx) => ctx.connectDeviceModalProps);
  const phoneSyncButtonProps = useDialpadEstablishConnectionControl((ctx) => ctx.phoneSyncButtonProps);
  const { onClose: closeConnectDeviceModal } = connectDeviceModalProps;
  const deviceSelectTriggerProps = useDialpadEstablishConnectionControl((ctx) => ctx.deviceSelectTriggerProps);

  const goToDeviceSelection = () => {
    deviceSelectTriggerProps.onClick();
    closeConnectDeviceModal();
  };

  return (
    <Modal {...connectDeviceModalProps} css={{ padding: theme.spacing(4, 1) }} maxWidth={600}>
      <Modal.Header textAlign='left' onClose={connectDeviceModalProps.onClose}>
        {t('No Device Connected')}
      </Modal.Header>
      <Modal.Body>
        <div
          css={{
            paddingTop: theme.spacing(1),
          }}
        >
          <Text>{t('Please either connect a device or pair a new one to enable the Dial Pad.')}</Text>
        </div>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'right',
            gap: theme.spacing(2),
            paddingTop: theme.spacing(4),
          }}
        >
          <PhoneSyncButton
            {...phoneSyncButtonProps}
            variant='secondary'
            onClick={closeConnectDeviceModal}
            btnLabel={t('Pair New Device')}
            trackingId='phn-portal-dialpad'
          />
          <PrimaryButton
            css={{ width: 'auto' }}
            size='large'
            onClick={goToDeviceSelection}
            trackingId='phn-portal-dialpad-btn-deviceConnection'
          >
            {t('Connect Device')}
          </PrimaryButton>
        </div>
      </Modal.Body>
    </Modal>
  );
};
