import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const modalStyle = css`
  min-width: min(900px, 90vw);
  min-height: 80vh;
`;

export const modalHeaderStyle = css`
  > * {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${theme.spacing(2)};
  }
`;
