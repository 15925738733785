import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

type OfficeTimezoneProps = {
  timezone: string;
};

export const OfficeTimezone = ({ timezone }: OfficeTimezoneProps) => {
  const { t } = useTranslation('scheduleGlobalSettings');
  return (
    <section css={timezoneStyles}>
      <Text size='large' weight='bold'>
        {t('Office Timezone')}
      </Text>
      <Text size='large'>{timezone}</Text>
    </section>
  );
};

const timezoneStyles = css({ display: 'flex', gap: theme.spacing(8), marginTop: theme.spacing(2) });
