import { css } from '@emotion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';

export const modalBody = css`
  @media (max-width: 699px) {
    width: 100%;
    margin: 0;
  }
`;

export const page = css`
  padding: ${theme.spacing(2, 0)};
`;

export const subtitle = css`
  padding: ${theme.spacing(0, 2, 2, 2)};
`;

export const buttonBar = css`
  width: ${breakpoints.medium.min}px;
  margin-top: ${theme.spacing(2)};
  margin-bottom: ${theme.spacing(1)};
  padding: ${theme.spacing(0, 4)};
  padding-top: inherit;

  @media (max-width: 699px) {
    width: 100%;
  }
`;

export const primaryButton = css`
  margin-left: ${theme.spacing(1)};
  width: calc(100% / 2 - ${theme.spacing(1)});
`;

export const secondaryButton = css`
  width: calc(100% / 2 - ${theme.spacing(1)});
  margin-right: ${theme.spacing(1)};
`;

export const singleButton = css`
  width: fit-content;
  margin-left: ${theme.spacing(2)};
`;
