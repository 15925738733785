import 'svg2pdf.js';
import { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import jsPDF from 'jspdf';
import { delay } from 'lodash-es';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { useAlert } from '@frontend/design-system';
import { weaveLogoBase64 } from '../atoms/weave-logo';

export type PDFDetails = {
  label: string;
  value: string;
}[];

type RenderSvg = {
  elementX: number;
  elementY: number;
  pdf: jsPDF;
  svgIndex: number;
  svgList: Element[];
};

type SaveAsPDF = {
  element: HTMLElement;
  fileName: string;
  pdfDetails: PDFDetails;

  cleanUp?: () => void;
};

type AfterEffects = {
  fileName: string;
  pdf: jsPDF;
  pdfHeight: number;
  pdfWidth: number;

  cleanUp?: () => void;
};

const chartTotalPadding = 24 * 2;
const headerHeight = 80;
const footerHeight = 30;
const headerAndFooterBGColor = theme.colors.neutral5;

const renderSvg = async (props: RenderSvg): Promise<string | undefined> => {
  const { elementX, elementY, pdf, svgIndex, svgList } = props;
  const svg = svgList[svgIndex];

  if (!svg) {
    return;
  }

  const { x, y, width, height } = svg.getBoundingClientRect();

  await pdf.svg(svg, { x: x - elementX, y: y - elementY + headerHeight, width, height });

  if (svgIndex < svgList.length - 1) {
    return renderSvg({ ...props, svgIndex: svgIndex + 1 });
  }

  return;
};

const saveFile = (pdf: jsPDF, fileName: string, cleanUp?: () => void) => {
  // Remove the last blank page from the pdf that jsPDF is adding automatically
  const pageCount = pdf.getNumberOfPages();
  if (pageCount > 1) {
    pdf.deletePage(pageCount);
  }
  pdf.save(`${fileName}.pdf`);
  cleanUp?.();
};

const drawHeader = (pdf: jsPDF, pdfWidth: number) => {
  pdf.setFillColor(headerAndFooterBGColor);
  pdf.rect(0, 0, pdfWidth, headerHeight, 'F');
  pdf.addImage(weaveLogoBase64, 'PNG', 0, 0, 250, 80);
};

const drawFooter = (pdf: jsPDF, pdfWidth: number, pdfHeight: number) => {
  const footerY = pdfHeight - footerHeight;
  const fontSize = 12;
  const textY = 16;
  // This do not need translation
  const footerText = 'Copyrights @ Weave - ';
  const footerLinkText = 'http://www.getweave.com';
  const fullFooterText = footerText + footerLinkText;
  const fullTextWidth = pdf.getTextWidth(fullFooterText);
  const textX = (pdfWidth - fullTextWidth) / 2;
  const footerTextWidth = pdf.getTextWidth(footerText);

  pdf.setFillColor(headerAndFooterBGColor);
  pdf.rect(0, footerY, pdfWidth, footerHeight, 'F');
  pdf.setFontSize(fontSize);
  pdf.text(footerText, textX + 6, footerY + textY); // 6 is a minor text placemenet adjustment
  pdf.textWithLink(footerLinkText, textX + footerTextWidth, footerY + textY, {
    url: footerLinkText,
  });
};

const createMetadataElement = (content: string, isLast?: boolean) => {
  const element = document.createElement('p');
  element.style.fontSize = theme.fontSize(12);
  element.style.color = theme.colors.neutral80;
  element.textContent = content;

  // For last elemenet, add some margin and a divider at the bottom
  if (isLast) {
    element.style.marginBottom = '20px';
    element.style.borderBottom = `1px solid ${theme.colors.neutral10}`;
    element.style.paddingBottom = '20px';
  }

  return element;
};

const cloneElement = (element: HTMLElement, pdfWidth: number, pdfDetails: PDFDetails): HTMLElement => {
  const clonedElement = element.cloneNode(true) as HTMLElement;

  clonedElement.style.position = 'absolute';
  clonedElement.style.top = '0';
  clonedElement.style.left = '0';
  clonedElement.style.width = `${pdfWidth}px`;
  clonedElement.style.overflow = 'visible';
  clonedElement.style.backgroundColor = 'white';
  clonedElement.style.zIndex = '-1';
  clonedElement.style.borderRadius = '0';

  // Prepand Today's date and time in the cloned element
  const dateTime = dayjs().format('DD MMM, YYYY');
  clonedElement.prepend(createMetadataElement(`Exported on: ${dateTime}`, true)); // No translation needed

  pdfDetails.forEach(({ label, value }) => {
    clonedElement.prepend(createMetadataElement(`${label}: ${value}`));
  });

  // Remove all buttons from the header actions
  const headerActions = clonedElement.getElementsByClassName('actions-wrapper')[0];
  if (headerActions) {
    Array.from(headerActions.children).forEach((child) => {
      if (child.tagName === 'BUTTON') {
        child.remove();
      }
    });
  }

  // Properly align the legends for pdf (jsPDF does not support flexbox propely, hence little overriding required)
  const legendsWrapper = clonedElement.getElementsByClassName('legends-wrapper')[0];
  if (legendsWrapper) {
    Array.from(legendsWrapper.children).forEach((child) => {
      const label = child.getElementsByClassName('label')[0] as HTMLElement;
      if (label) {
        label.style.marginBottom = '12px';
      }
    });
  }

  // Remove all the element with class no-pdf
  const noPdfElements = clonedElement.getElementsByClassName('no-pdf');
  Array.from(noPdfElements).forEach((element) => element.remove());

  document.body.appendChild(clonedElement);
  return clonedElement;
};

const saveAsPdf = async ({ cleanUp, element, fileName, pdfDetails }: SaveAsPDF) => {
  const chartView = element.getElementsByClassName('pdf-chart-view')[0];

  if (!chartView) {
    // Translation not needed as this is a developer error
    throw new Error('No chart view available to export.');
  }

  const pdfWidth = chartView.clientWidth + chartTotalPadding;
  const clonedElement = cloneElement(element, pdfWidth, pdfDetails);
  const { x: clonedElementX, y: clonedElementY, height: clonedElementHeight } = clonedElement.getBoundingClientRect();
  const pdfHeight = clonedElementHeight + headerHeight + footerHeight;

  const pdf = new jsPDF({
    format: [pdfWidth, pdfHeight],
    orientation: pdfWidth > pdfHeight ? 'l' : 'p',
    unit: 'px',
  });

  // Only include the chart svg, rest of SVGs tends to create rendering or JS issues specially with new DS icons
  const svgList = Array.from(clonedElement.getElementsByTagName('svg')).filter((svg) =>
    svg.classList.contains('recharts-surface')
  );

  try {
    await pdf.html(clonedElement, {
      autoPaging: true,
      callback: async () => {
        if (svgList.length) {
          await renderSvg({ elementX: clonedElementX, elementY: clonedElementY, pdf, svgIndex: 0, svgList });
        }
        afterRenderEffects({ cleanUp, pdf, fileName, pdfHeight, pdfWidth });
      },
      x: 0,
      html2canvas: {
        ignoreElements: (element) => {
          return element.tagName === 'svg' || element.classList.contains('loader');
        },
      },
      y: headerHeight,
    });
  } catch (error) {
    console.error('Error rendering PDF:', error);
  } finally {
    // Ensure the cloned element is removed from the DOM
    clonedElement.remove();
  }
};

const afterRenderEffects = ({ cleanUp, fileName, pdf, pdfHeight, pdfWidth }: AfterEffects) => {
  drawHeader(pdf, pdfWidth);
  drawFooter(pdf, pdfWidth, pdfHeight);
  saveFile(pdf, fileName, cleanUp);
};

export const useExportChartToPDF = (pdfDetails: PDFDetails) => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const [isExportingPDF, setIsExportingPDF] = useState<boolean | undefined>();

  const captureScreen = useCallback(
    async (element: HTMLElement, title: string) => {
      try {
        saveAsPdf({
          cleanUp: () => {
            setIsExportingPDF(false);
          },
          element,
          fileName: title,
          pdfDetails,
        });
      } catch (error) {
        if (error) {
          alert.error((error as { message: string }).message);
        } else {
          alert.error(t('Error exporting chart to PDF.'));
        }
        setIsExportingPDF(false);
      }
    },
    [pdfDetails]
  );

  const exportToPDF = useCallback(
    (title: string, element?: HTMLElement | null) => {
      if (!element) {
        alert.error(t('No content accessible to export.'));
        return;
      }

      setIsExportingPDF(true);
      // Give a little time to the ContentLoader to display loading effect properly before staring heavy lifting
      // Otherwise page looks like frozen
      delay(() => captureScreen(element, title), 500);
    },
    [pdfDetails]
  );

  return {
    exportToPDF,
    isExportingPDF,
  };
};
