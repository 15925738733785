import { FormEventHandler, Ref, forwardRef, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { saveAs } from 'file-saver';
import { Virtuoso } from 'react-virtuoso';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { ThreadSendingMediaItem } from '@frontend/integrated-messaging';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { MediaUploadPreview } from '@frontend/media-upload-preview';
import { ExtensiblePopup } from '@frontend/popup-bar';
import { useMutation } from '@frontend/react-query-helpers';
import { useFeatureFlagShallowStore, useHasFeatureFlag } from '@frontend/shared';
import { SuperTextAreaRef, SuperTextarea, SuperTextareaProps } from '@frontend/super-textarea';
import { theme } from '@frontend/theme';
import {
  ChatItem,
  EditIcon,
  IconButton,
  Modal,
  SendIcon,
  SpinningLoader,
  Text,
  TrashIcon,
  useFormField,
  useModalControl,
  ValidatorFieldState,
  FileUpload,
  DownloadIcon,
} from '@frontend/design-system';
import { useStrategy } from '../api/strategy';
import { useImageHandler } from '../hooks/use-images-handler';
import { trackingId } from '../tracking';
import { Chat, Message, Recipient } from '../types';
import { getUserFullName } from '../utils';
import { Recipients } from './chat-tab-recipients';
import { MessageReactions } from './message-reactions';
import { Reactions } from './reactions';

type Props = {
  availableRecipients: Recipient[];
  messages: Message[];
};

export const NewChatTabComponent = ({
  availableRecipients,
  messages,
  handleEditorChange,
  isPrivate,
  isGroupNameUnique,
}: Props & {
  handleEditorChange: () => void;
  isPrivate: boolean;
  isGroupNameUnique: (name: string) => boolean;
}) => {
  const { t } = useTranslation('chat');
  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { useCreateConversation, useHandleRecipientSelection } = useStrategy('chat');
  const createConversation = useCreateConversation();
  const handleRecipientSelection = useHandleRecipientSelection();
  const DEFAULT_NAME = t('Untitled Group');
  const nameField = useFormField({
    type: 'text',
    value: isPrivate ? '' : DEFAULT_NAME,
    required: true,
    validator: ({ value }: ValidatorFieldState<'text'>) =>
      isGroupNameUnique(value) ? t('This group name is taken. Give it a unique name.') : '',
    validateOnChange: true,
    touched: true,
  });
  const { getFlag } = useFeatureFlagShallowStore('getFlag');
  const isTeamChatMauReductionEnabled = getFlag('team-chat-mau-reduction');
  const mutation = useMutation({
    mutationFn: (chatId: Chat['id']) => {
      return http.post(`/team-chat/v1/user-status/conversation`, { conversationId: chatId, locationId });
    },
  });

  const handleRemoveRecipient = async (recipient: Recipient) => {
    const newRecipients = recipients.filter((rec) => rec !== recipient);
    setRecipients(newRecipients);
    setIsLoading(true);
    await handleRecipientSelection({ recipients: newRecipients, isPrivate });
    setIsLoading(false);
  };

  const handleAddRecipient = async (recipient: Recipient) => {
    const newRecipients = [...recipients, recipient];
    setRecipients(newRecipients);
    setIsLoading(true);
    await handleRecipientSelection({ recipients: newRecipients, isPrivate });
    setIsLoading(false);
  };

  const { locationId } = useLocationDataShallowStore('locationId');

  const handleEditorSubmit = async (text: string, images?: ThreadSendingMediaItem[]) => {
    // this is where a conversation id goes from 'new' to having a useful id
    const response = await createConversation({
      recipients,
      message: text,
      name: nameField.value,
      isPrivate,
      attachments: images?.reduce<File[]>((acc, image) => {
        if (image?.file) {
          acc.push(image.file);
        }
        return acc;
      }, []),
    });
    if (isTeamChatMauReductionEnabled && (response?.sid || response.id)) {
      mutation.mutate(response.sid ?? response.id);
    }
  };

  return (
    <ChatTabBase>
      <div>
        <Recipients
          availableRecipients={availableRecipients}
          selectedRecipients={recipients}
          handleRemoveRecipient={handleRemoveRecipient}
          handleAddRecipient={handleAddRecipient}
          // There is no need for these in new chats
          toggleEditGroup={() => {}}
          handleUpdateGroup={() => {}}
          handleLeaveGroup={() => {}}
          handleDeleteGroup={() => {}}
          handleCancelEditGroup={() => {}}
          field={nameField}
          isEditing={true}
          mode='create'
          isPrivate={isPrivate}
        />
      </div>
      <Thread messages={messages} isLoading={isLoading} />
      <Editor
        handleSubmit={handleEditorSubmit}
        handleChange={handleEditorChange}
        disableSend={isGroupNameUnique(nameField.value)}
      />
    </ChatTabBase>
  );
};

export const ChatTabComponent = ({
  recipients,
  name = '',
  messages,
  handleEditorChange,
  handleUpdateConversation,
  handleLeaveConversation,
  handleDeleteConversation,
  fetchPreviousMessages,
  typingRecipientIds,
  onFocus,
  onBlur,
  availableRecipients,
  isPrivate,
  isGroupNameUnique,
  chat,
}: {
  messages: Message[];
  name?: string;
  recipients: Recipient[];
  handleEditorChange: () => void;
  handleUpdateConversation: (recipients: Recipient[], name: string) => void;
  handleLeaveConversation: () => void;
  handleDeleteConversation: () => void;
  fetchPreviousMessages?: () => void;
  typingRecipientIds: string[];
  availableRecipients: Recipient[];
  onFocus?: () => void;
  onBlur?: () => void;
  isPrivate: boolean;
  isGroupNameUnique: (name: string) => boolean;
  chat: ExtensiblePopup<Chat>;
}) => {
  const { t } = useTranslation('chat');
  const [isFetching, setIsFetching] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const showRecipientsModule = recipients.length > 1 || !isPrivate;
  const typingRecipients = recipients.filter((rec) => typingRecipientIds.includes(rec.userID));
  const initialRecipients = useRef<Recipient[]>(recipients); // Save the initial recipients for canceling edit group
  const [selectedRecipients, setSelectedRecipients] = useState<Recipient[]>(recipients);
  const nameField = useFormField({
    type: 'text',
    value: name,
    validator: ({ value }: ValidatorFieldState<'text'>) => {
      return isGroupNameUnique(value) ? t('This group name is taken. Give it a unique name.') : '';
    },
    validateOnChange: true,
  });
  const { useSendMessage } = useStrategy('chat');

  const sendMessage = useSendMessage();

  // When recipients change, update the selected recipients
  // TODO: This is a temporary solution to keep the selected recipients in sync with the recipients
  useEffect(() => setSelectedRecipients(recipients), [recipients]);

  const handleSubmit = async (text: string, images?: ThreadSendingMediaItem[]) => {
    return sendMessage({
      message: text,
      conversationId: chat.id,
      attachments: images?.reduce<File[]>((acc, image) => {
        if (image?.file) {
          acc.push(image.file);
        }
        return acc;
      }, []),
    });
  };

  const handleRemoveRecipient = (recipient: Recipient) => {
    const newRecipients = selectedRecipients.filter((rec) => rec.userID !== recipient.userID);
    setSelectedRecipients(newRecipients);
  };

  const handleAddRecipient = (recipient: Recipient) => {
    const newRecipients = [...selectedRecipients, recipient];
    setSelectedRecipients(newRecipients);
  };

  const handleUpdate = () => {
    initialRecipients.current = selectedRecipients;
    handleUpdateConversation(selectedRecipients, nameField.value);
    setIsEditing(false);
  };

  const handleFetchPreviousMessages = async () => {
    setIsFetching(true);
    await fetchPreviousMessages?.();
    setIsFetching(false);
  };

  const handleCancelEditGroup = () => {
    setSelectedRecipients(initialRecipients.current);
    setIsEditing(false);
  };

  return (
    <ChatTabBase onFocus={onFocus} onBlur={onBlur}>
      {showRecipientsModule && (
        <Recipients
          availableRecipients={availableRecipients}
          selectedRecipients={selectedRecipients}
          handleRemoveRecipient={handleRemoveRecipient}
          handleAddRecipient={handleAddRecipient}
          toggleEditGroup={setIsEditing}
          handleUpdateGroup={handleUpdate}
          handleLeaveGroup={handleLeaveConversation}
          handleDeleteGroup={handleDeleteConversation}
          handleCancelEditGroup={handleCancelEditGroup}
          field={nameField}
          css={{ gridArea: 'recipients' }}
          isEditing={isEditing}
          mode='edit'
          isPrivate={isPrivate}
          disableSave={isGroupNameUnique(nameField.value)}
        />
      )}
      <Thread
        isFetching={isFetching}
        fetchPreviousMessages={handleFetchPreviousMessages}
        messages={messages}
        css={{ gridArea: 'thread' }}
      />
      {typingRecipients.length > 0 && (
        <TypingIndicator typingRecipients={typingRecipients} css={{ gridArea: 'typing' }} />
      )}
      <Editor css={{ gridArea: 'editor' }} handleSubmit={handleSubmit} handleChange={handleEditorChange} />
    </ChatTabBase>
  );
};

const TypingIndicator = ({ typingRecipients, ...rest }: { typingRecipients: Recipient[] }) => {
  const { t } = useTranslation('chat');

  const text =
    typingRecipients.length > 2
      ? t('Several people are typing...')
      : t('{{name}} is typing...', {
          name: typingRecipients.map((rec) => getUserFullName(rec)).join(', '),
          count: typingRecipients.length,
        });

  return (
    <Text
      size='small'
      as='span'
      css={{ background: theme.colors.primary50, color: theme.colors.white, padding: theme.spacing(0, 1) }}
      {...rest}
    >
      {text}
    </Text>
  );
};

// Save for later
// const useEphemeralMessages = () => {
//   const [ephemeralMessages, setEphemeralMessages] = useState<Message[]>([]);

//   const addEphemeralMessage = (message: Message) => {
//     setEphemeralMessages((prev) => [...prev, message]);
//   };

//   const removeEphemeralMessage = (messageId: string) => {
//     setEphemeralMessages((prev) => {
//       const index = prev.findIndex((message) => message.id === messageId);

//       if (index > -1) {
//         return [...prev.slice(0, index), ...prev.slice(index + 1)];
//       }
//       return prev;
//     });
//   };

//   const clearEphemeralMessages = () => {
//     setEphemeralMessages([]);
//   };
//   return { ephemeralMessages, addEphemeralMessage, removeEphemeralMessage, clearEphemeralMessages };
// };

export const ChatComponent = ({
  chat,
  availableRecipients,
  typingRecipientIds,
  fetchPreviousMessages,
  onFocus,
  onBlur,
}: {
  chat: ExtensiblePopup<Chat>;
  sender?: Recipient;
  availableRecipients: Recipient[];
  typingRecipientIds: string[];
  fetchPreviousMessages?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
}) => {
  const { locationId } = useLocationDataShallowStore('locationId');
  const { getFlag } = useFeatureFlagShallowStore('getFlag');
  const isTeamChatMauReductionEnabled = getFlag('team-chat-mau-reduction');
  const { useTypingStatus, useUpdateConversation, useLeaveConversation, useDeleteConversation, useUniqueGroupName } =
    useStrategy('chat');

  const typingStatus = useTypingStatus();
  const updateConversation = useUpdateConversation();
  const leaveConversation = useLeaveConversation();
  const deleteConversation = useDeleteConversation();
  const isGroupNameUnique = useUniqueGroupName();

  const handleEditorChange = () => {
    if (chat.id !== 'new') {
      typingStatus({ conversationId: chat.id });
    }
  };

  const mutation = useMutation({
    mutationFn: (chatId: Chat['id']) =>
      http.post(`/team-chat/v1/user-status/conversation`, { conversationId: chatId, locationId }),
  });

  const handleUpdateConversation = (recipients: Recipient[], name: string) => {
    updateConversation({ chat, recipients, friendlyName: name });
    // if the ff is enabled, and the user status is inactive or unknown, let the backend know that the user updated a conversation
    if (isTeamChatMauReductionEnabled) {
      mutation.mutate(chat.id);
    }
  };

  const handleLeaveConversation = () => {
    leaveConversation({ chat });
  };

  const handleDeleteConversation = () => {
    deleteConversation({ chat });
  };

  const messages = [...chat.messages];

  return (
    <>
      {chat.id === 'new' ? (
        <NewChatTabComponent
          availableRecipients={availableRecipients}
          messages={messages}
          handleEditorChange={handleEditorChange}
          isPrivate={chat.isPrivate}
          isGroupNameUnique={(value: string) => isGroupNameUnique(value, chat.id)}
        />
      ) : (
        <ChatTabComponent
          availableRecipients={availableRecipients}
          name={chat.name}
          messages={messages}
          recipients={chat.recipients}
          chat={chat}
          handleEditorChange={handleEditorChange}
          handleUpdateConversation={handleUpdateConversation}
          handleLeaveConversation={handleLeaveConversation}
          handleDeleteConversation={handleDeleteConversation}
          fetchPreviousMessages={fetchPreviousMessages}
          typingRecipientIds={typingRecipientIds}
          onFocus={onFocus}
          onBlur={onBlur}
          isPrivate={chat.isPrivate}
          isGroupNameUnique={(value: string) => isGroupNameUnique(value, chat.id)}
        />
      )}
    </>
  );
};

const TAB_HEIGHT = 500;
export const ChatTabBase = ({ ...props }) => {
  return (
    <div
      css={{
        '> :not(:last-child)': {
          borderBottom: `1px solid ${theme.colors.neutral20}`,
        },
        height: TAB_HEIGHT,
        display: 'grid',
        gridTemplateAreas: '"recipients" "thread" "typing" "editor"',
        gridTemplateRows: 'auto 1fr auto auto',
        background: theme.colors.white,
      }}
      {...props}
    />
  );
};

const ChatItemControlLayer = ({ message, showFirstUnread }: { message: Message; showFirstUnread: boolean }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showEmojiButton, setShowEmojiButton] = useState<boolean>(false);
  const { t } = useTranslation('chat');
  const { useUpdateMessage, useRemoveMessage, configuration } = useStrategy('chat');
  const updateMessage = useUpdateMessage();
  const removeMessage = useRemoveMessage();
  const { triggerProps, modalProps, closeModal } = useModalControl();
  const areReactionsEnabled = useHasFeatureFlag('team-chat-reactions');

  const contextActions = [
    {
      label: t('Edit Message'),
      Icon: EditIcon,
      onClick: () => {
        setIsEditing((prev) => !prev);
      },
    },
    {
      label: t('Delete Message'),
      Icon: TrashIcon,
      onClick: () => {
        triggerProps.onClick();
      },
    },
  ];

  const onEditorSubmit = (text: string, images?: ThreadSendingMediaItem[]) => {
    let removedImages: string[] = [];
    let addedImages: File[] = [];
    if (message?.attachments?.length) {
      removedImages =
        message.attachments.filter((attachment) => !images?.find((i) => i.id === attachment)) ?? undefined;
    }
    if (images?.length && message.attachments) {
      addedImages = images.reduce<File[]>((acc, { id, file }) => {
        if (!message.attachments?.includes(id) && file) {
          acc.push(file);
        }
        return acc;
      }, []);
    }
    return updateMessage({ message, body: text, removedImages, addedImages }).finally(() => setIsEditing(false));
  };

  let statusComponent = undefined;
  if (message.id.startsWith('eph-')) {
    statusComponent = (
      <Text size='small' color='light'>
        {t('Sending...')}
      </Text>
    );
  } else if (message.hasBeenUpdated) {
    statusComponent = (
      <Text size='small' color='light'>
        {t('Edited')}
      </Text>
    );
  }

  return (
    <div ref={ref} css={{ position: 'relative' }}>
      {showFirstUnread && (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: theme.spacing(1),
            position: 'absolute',
            width: '100%',
            top: `-${theme.spacing(2)}`,
          }}
        >
          <div style={{ background: theme.colors.primary50, height: 1, width: '100%' }}></div>
          <Text textAlign='right' color='primary' size='small'>
            {t('New')}
          </Text>
        </div>
      )}
      <ChatItem
        key={message.id}
        direction={message.direction}
        avatar={message.avatar}
        timestamp={message.timestamp}
        isFuture={false}
        /**
         * This is an extra feature that is not part of design
         * This will remind us to think about send status
         */
        statusComponent={statusComponent}
        onMouseOver={() => !!areReactionsEnabled && setShowEmojiButton(true)}
        onMouseOut={() => !!areReactionsEnabled && setShowEmojiButton(false)}
        css={css({ position: 'relative' })}
      >
        {isEditing && (
          <EditableBubble
            text={message.text}
            onCancel={() => setIsEditing(false)}
            onSubmit={onEditorSubmit}
            message={message}
          />
        )}
        {!!message.text && !isEditing && (
          <ChatItem.Bubble
            text={message.text}
            contextActions={message.direction === 'outbound' ? contextActions : undefined}
          />
        )}
        {configuration.supportsAttachments && message.attachments?.length && !isEditing && (
          <ul css={css({ display: 'flex', flexFlow: 'row wrap', alignItems: 'baseline', gap: theme.spacing(1) })}>
            {message.attachments.map((image) => (
              <ChatItem.Image
                src={image}
                css={css`
                  max-width: calc(50% - 4px);
                  max-height: 50%;
                  aspect-ratio: 1/1;
                  img {
                    aspect-ratio: 1/1;
                    object-fit: cover;
                  }
                `}
                onClickDownload={() => saveAs(image)}
                data-trackingid='chat-2.0-image-preview'
                downloadImageTrackingId='chat-2.0-image-download'
                key={image}
                contextActions={
                  message.direction === 'outbound'
                    ? [
                        {
                          label: t('Download Image'),
                          onClick: () => saveAs(image),
                          Icon: DownloadIcon,
                        },
                        ...contextActions,
                      ]
                    : undefined
                }
              />
            ))}
          </ul>
        )}
        {areReactionsEnabled && <Reactions showEmojiButton={showEmojiButton} message={message} />}
        {areReactionsEnabled && <MessageReactions message={message} />}
      </ChatItem>
      <Modal {...modalProps}>
        <Modal.Header>{t('Delete Message')}</Modal.Header>
        <Modal.Body>
          <div style={{ display: 'flex', flexDirection: 'column', gap: theme.spacing(2) }}>
            <Text>{t('Are you sure you want to delete this message?')}</Text>
            <Text
              css={{
                padding: theme.spacing(2),
                background: theme.colors.neutral5,
                borderRadius: theme.borderRadius.medium,
              }}
            >
              {message.text}
            </Text>
          </div>
        </Modal.Body>
        <Modal.Actions
          destructive
          primaryLabel={t('Delete')}
          onPrimaryClick={() => removeMessage({ message })}
          secondaryLabel={t('Cancel')}
          onSecondaryClick={() => {
            closeModal();
          }}
        />
      </Modal>
    </div>
  );
};

const EditableBubble = ({
  text,
  onCancel,
  onSubmit,
  message,
}: {
  text: string;
  onCancel: () => void;
  onSubmit: (text: string, images?: ThreadSendingMediaItem[]) => Promise<void>;
  message?: Message;
}) => {
  const ref = useRef<SuperTextAreaRef>(null);

  useEffect(() => {
    ref.current?.focus();
  }, []);

  return (
    <div
      css={{
        border: `2px solid ${theme.colors.neutral20}`,
        borderRadius: theme.borderRadius.small,
        outline: 'none',

        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),

        ':focus-within': {
          borderColor: theme.colors.primary50,
        },

        transition: 'border-color 100ms ease-in-out',
      }}
      tabIndex={0}
    >
      <Editor
        ref={ref}
        value={text}
        handleChange={() => {}}
        handleSubmit={onSubmit}
        isEdit={true}
        onCancel={onCancel}
        message={message}
      />
    </div>
  );
};

type ThreadProps = {
  messages: Message[];
  isLoading?: boolean;
  isFetching?: boolean;
};

const INITIAL_ITEM_INDEX = 10000;

const Thread = ({
  fetchPreviousMessages,
  messages,
  isLoading = false,
  isFetching = false,
  ...rest
}: ThreadProps & {
  fetchPreviousMessages?: () => void;
}) => {
  const { t } = useTranslation('chat');
  const [firstItemIndex, setFirstItemIndex] = useState(INITIAL_ITEM_INDEX);

  useEffect(() => {
    setFirstItemIndex(INITIAL_ITEM_INDEX - messages.length);
  }, [messages.length]);

  if (isLoading) {
    return (
      <div css={{ display: 'flex', height: '100%', alignItems: 'center', justifyContent: 'center' }} {...rest}>
        <SpinningLoader />
      </div>
    );
  }

  if (messages.length === 0) {
    return (
      <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }} {...rest}>
        <Text textAlign='center'>{t('No conversations yet')}</Text>
      </div>
    );
  }

  return (
    <div {...rest}>
      <Virtuoso
        followOutput='smooth'
        data={messages}
        style={{ height: '100%' }}
        initialTopMostItemIndex={INITIAL_ITEM_INDEX - 1}
        startReached={fetchPreviousMessages}
        firstItemIndex={firstItemIndex}
        components={{
          Header,
        }}
        context={{ isFetching, firstItemIndex }}
        itemContent={VirtuosoItem}
      />
    </div>
  );
};

const VirtuosoItem = (
  index: number,
  message: Message,
  { firstItemIndex }: { isFetching: boolean; firstItemIndex: number }
) => {
  return (
    /**
     * This div is a container to give padding to the ChatItem within Virtuoso.
     *
     * Ideally this would be set once around all the ChatItems, but Virtuoso needs to fill the container completely
     */
    <div
      style={{
        padding: theme.spacing(0, 2),
        paddingTop: index === firstItemIndex ? theme.spacing(2) : 0,
        paddingBottom: index === INITIAL_ITEM_INDEX - 1 ? theme.spacing(2) : 0,
      }}
    >
      {message?.type === 'system' ? (
        <div
          css={css({
            padding: theme.spacing(1, 0),
            textAlign: 'center',
            color: theme.colors.neutral50,
            fontSize: theme.spacing(1.5),
            fontWeight: theme.font.weight.regular,
          })}
        >
          {message.text}
        </div>
      ) : (
        <ChatItemControlLayer key={message.id} message={message} showFirstUnread={message.isFirstUnread} />
      )}
    </div>
  );
};

const Header = ({ context }: { context?: { isFetching: boolean } }) =>
  context?.isFetching ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: theme.spacing(1, 0),
      }}
    >
      <SpinningLoader size='xs' />
    </div>
  ) : null;

const EMOJI_PICKER_WIDTH = 325;
const Editor = forwardRef(
  (
    {
      handleSubmit,
      handleChange,
      value,
      isEdit,
      message,
      ...rest
    }: Omit<SuperTextareaProps, 'onChange' | 'onSubmit' | 'value'> & {
      handleSubmit: (text: string, images?: ThreadSendingMediaItem[]) => Promise<void>;
      handleChange: () => void;
      value?: string;
      message?: Message;
    },
    ref: Ref<SuperTextAreaRef>
  ) => {
    const [draft, setDraft] = useState(value ?? '');
    const [uploadImage, setUploadingImage] = useState<boolean>(false);
    const { t } = useTranslation('chat');
    const { configuration } = useStrategy('chat');
    const imageUploadModalControl = useModalControl();
    const { images, removeMediaItem, setImages, showUploadProgress } = useImageHandler(message?.attachments);
    const isImagesSupported = useHasFeatureFlag('team-chat-images');

    const onChange = (e: string) => {
      handleChange();
      setDraft(e);
    };

    return (
      <div css={css({ gridArea: 'editor' })}>
        {isImagesSupported && configuration.supportsAttachments && images && (
          <MediaUploadPreview
            media={images}
            removeMediaItem={removeMediaItem}
            inPopout
            {...(isEdit && { removeImageTrackingId: 'chat-2.0-image-remove-click' })}
          />
        )}
        <SuperTextarea
          hasSubmit={true}
          SubmitComponent={SubmitButton}
          onChange={onChange}
          onSubmit={(text: string) => {
            if (images?.length) {
              setUploadingImage(true);
              showUploadProgress();
            }
            handleSubmit(text, images).finally(() => {
              images?.length && setUploadingImage(false);
              images?.length && setImages(undefined);
            });
          }}
          value={draft}
          ref={ref}
          isEdit={isEdit}
          emojiPickerWidth={EMOJI_PICKER_WIDTH}
          css={[
            { border: 0 },
            isEdit && {
              '> div:first-of-type': {
                padding: 0,
              },
              textarea: {
                padding: theme.spacing(2),
              },
            },
          ]}
          onImageClick={
            configuration.supportsAttachments && isImagesSupported ? imageUploadModalControl.openModal : undefined
          }
          trackingIds={{ image: 'chat-2.0-image-button-click' }}
          sendOnEmpty={!!images?.length}
          {...rest}
          disableSend={uploadImage || rest.disableSend}
        />
        {isImagesSupported && configuration.supportsAttachments && (
          <Modal {...imageUploadModalControl.modalProps} maxWidth={600}>
            <Modal.Header textAlign='left' onClose={imageUploadModalControl.closeModal}>
              {t('Upload Image')}
            </Modal.Header>
            <Modal.Body>
              <FileUpload
                onFileUpload={(files: File[]) => {
                  setImages(files);
                  imageUploadModalControl.closeModal();
                }}
                acceptedFileType={['png', 'jpg', 'jpeg']}
                helperText={
                  <div css={css({ margin: theme.spacing(0, 3), textAlign: 'center' })}>
                    <Text>{t('Drop image here')}</Text>
                    <Text>{t('Accepted file formats: .png, .jpg')}</Text>
                    <Text>{t('Max file size: 10MB')}</Text>
                    <Text>{t('Max files allowed: 10')}</Text>
                  </div>
                }
                multiple
              />
            </Modal.Body>
          </Modal>
        )}
      </div>
    );
  }
);
Editor.displayName = 'Editor';

const SubmitButton = ({ disabled, onClick }: { disabled: boolean; onClick: FormEventHandler }) => {
  const { t } = useTranslation('chat');
  return (
    <IconButton
      disabled={disabled}
      onClick={onClick}
      label={t('Send')}
      trackingId={trackingId({ component: 'tab', context: 'send-message' })}
    >
      <SendIcon css={{ fill: theme.colors.primary50 }} />
    </IconButton>
  );
};
