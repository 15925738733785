import { useTranslation } from '@frontend/i18n';
import { DisconnectIconSmall, UpdateIcon } from '@frontend/design-system';
import { useSoftphoneCallState } from '../../providers/softphone-call-state-provider';
import { useSoftphoneSettings } from '../../providers/softphone-settings-provider';
import { useSoftphoneWidgetControl } from '../../providers/widget-control-provider';
import { Row } from '../generic/row/row';

export const SettingsFooter = () => {
  const { t } = useTranslation('softphone');
  const restart = useSoftphoneSettings((ctx) => ctx.restart);
  const turnOffSoftphone = useSoftphoneSettings((ctx) => ctx.turnOffSoftphone);
  const close = useSoftphoneWidgetControl((ctx) => ctx.close);
  const primaryCall = useSoftphoneCallState((ctx) => ctx.primaryCall);

  return (
    <li>
      <Row trackingId='softphone-settings-reload' fullWidth={false} onClick={restart} transparentBackground>
        <Row.Icon Icon={UpdateIcon} size={16} />
        <Row.Title weight='medium' title={t('Restart Softphone')} />
      </Row>
      <Row
        trackingId='softphone-settings-disconnect'
        disabled={!!primaryCall}
        fullWidth={false}
        onClick={() => {
          close();
          turnOffSoftphone();
        }}
        transparentBackground
      >
        <Row.Icon Icon={DisconnectIconSmall} size={16} />
        <Row.Title weight='medium' title={t('Disconnect Softphone')} />
      </Row>
    </li>
  );
};
