// TODO :: Update to use Schema

import { AnalyticsCommonTypes } from '../common';

// Enums

export enum AppointmentTypeEnum {
  APPOINTMENT_TYPE_UNKNOWN = 'APPOINTMENT_TYPE_UNKNOWN',
  APPOINTMENT_TYPE_IMAGING = 'APPOINTMENT_TYPE_IMAGING',
  APPOINTMENT_TYPE_COSMETIC = 'APPOINTMENT_TYPE_COSMETIC',
  APPOINTMENT_TYPE_ORTHODONTICS = 'APPOINTMENT_TYPE_ORTHODONTICS',
  APPOINTMENT_TYPE_SURGERY = 'APPOINTMENT_TYPE_SURGERY',
  APPOINTMENT_TYPE_PREVENTIVE = 'APPOINTMENT_TYPE_PREVENTIVE',
  APPOINTMENT_TYPE_HYGIENE = 'APPOINTMENT_TYPE_HYGIENE',
  APPOINTMENT_TYPE_RESTORATIVE = 'APPOINTMENT_TYPE_RESTORATIVE',
  APPOINTMENT_TYPE_EMERGENCY = 'APPOINTMENT_TYPE_EMERGENCY',
}

export enum CallDirectionEnum {
  DIRECTION_INBOUND = 'DIRECTION_INBOUND',
  DIRECTION_OUTBOUND = 'DIRECTION_OUTBOUND',
}

export enum CallStatusEnum {
  CALL_STATUS_COMPLETE = 'CALL_STATUS_COMPLETE',
  CALL_STATUS_ERROR = 'CALL_STATUS_ERROR',
  CALL_STATUS_FAILED = 'CALL_STATUS_FAILED',
  CALL_STATUS_INFERENCING = 'CALL_STATUS_INFERENCING',
  CALL_STATUS_PENDING = 'CALL_STATUS_PENDING',
  CALL_STATUS_SKIPPED = 'CALL_STATUS_SKIPPED',
  CALL_STATUS_TRANSCRIBING = 'CALL_STATUS_TRANSCRIBING',
}

export enum CategoryEnum {
  CATEGORY_UNKNOWN = 'CATEGORY_UNKNOWN',
  CATEGORY_SCHEDULING = 'CATEGORY_SCHEDULING',
  CATEGORY_EMERGENCY = 'CATEGORY_EMERGENCY',
  CATEGORY_MEDICATION = 'CATEGORY_MEDICATION',
  CATEGORY_COMPLAINT = 'CATEGORY_COMPLAINT',
  CATEGORY_BILLING = 'CATEGORY_BILLING',
  CATEGORY_VENDOR = 'CATEGORY_VENDOR',
  CATEGORY_OTHER = 'CATEGORY_OTHER',
}

export enum ContactTypeEnum {
  CONTACT_NEW_PATIENT = 'CONTACT_NEW_PATIENT',
  CONTACT_EXISTING_PATIENT = 'CONTACT_EXISTING_PATIENT',
  CONTACT_NOT_A_PATIENT = 'CONTACT_NOT_A_PATIENT',
  CONTACT_UNKNOWN = 'CONTACT_UNKNOWN',
}

export enum FilterTypeEnum {
  FILTER_BY_UNKNOWN = 0,
  FILTER_BY_DATE_RANGE = 1,
  FILTER_BY_LOCATION = 2,
  FILTER_BY_SENTIMENT = 3,
  FILTER_BY_CATEGORY = 4,
  FILTER_BY_APPOINTMENT_TYPE = 5,
  FILTER_BY_CALL_STATUS = 6,
  FILTER_BY_SCHEDULING_OUTCOME = 7,
  FILTER_BY_OPPORTUNITY = 8,
  FILTER_BY_OFFICE_USER = 9,
  FILTER_BY_OFFICE_NUMBER = 10,
  FILTER_BY_CALL_DIRECTION = 11,
  FILTER_BY_CONTACT_TYPE = 12,
  FILTER_BY_CALL_ID = 13,
}

export enum FollowupTaskFilterEnum {
  FILTER_BY_DATE_RANGE = 1,
  FILTER_BY_LOCATION = 2,
  FILTER_BY_OFFICE_USER = 3,
  FILTER_BY_CONTACT_TYPE = 4,
  FILTER_BY_TASK_TYPE = 5,
  FILTER_BY_TASK_STATUS = 6,
}

export enum FollowupSortFieldEnum {
  SORT_UNKNOWN = 0,
  SORT_LAST_CALL = 1,
  SORT_CALLS_COUNT = 2,
}

export enum RatingEnum {
  RATING_UNKNOWN = 0,
  RATING_POSITIVE = 1,
  RATING_NEGATIVE = 2,
}

export enum SchedulingOpportunityEnum {
  SCHEDULING_OPPORTUNITY_IDENTIFIED = 'SCHEDULING_OPPORTUNITY_IDENTIFIED',
  SCHEDULING_OPPORTUNITY_NOT_IDENTIFIED = 'SCHEDULING_OPPORTUNITY_NOT_IDENTIFIED',
}

export enum SchedulingOutcomeEnum {
  SCHEDULING_OUTCOME_UNKNOWN = 'SCHEDULING_OUTCOME_UNKNOWN',
  SCHEDULING_OUTCOME_SCHEDULED = 'SCHEDULING_OUTCOME_SCHEDULED',
  SCHEDULING_OUTCOME_UNSCHEDULED = 'SCHEDULING_OUTCOME_UNSCHEDULED',
}

export enum SearchTypeEnum {
  SEARCH_UNKNOWN = 0,
  SEARCH_BY_NAME = 1,
  SEARCH_BY_NUMBER = 2,
}

export enum SentimentEnum {
  SENTIMENT_UNKNOWN = 'SENTIMENT_UNKNOWN',
  SENTIMENT_NEGATIVE = 'SENTIMENT_NEGATIVE',
  SENTIMENT_NEUTRAL = 'SENTIMENT_NEUTRAL',
  SENTIMENT_POSITIVE = 'SENTIMENT_POSITIVE',
}

export enum SortTypeEnum {
  SORT_ASC = 1,
  SORT_DESC = 2,
}

export enum TaskFilterTypeEnum {
  FILTER_BY_UNKNOWN = 0,
  FILTER_BY_TASK_ID = 1,
  FILTER_BY_LOCATION = 2,
  FILTER_BY_SOURCE = 3,
  FILTER_BY_STATUS = 4,
  FILTER_BY_ASSIGNEE = 5,
  FILTER_BY_SOURCE_ID = 6,
}

export enum TaskStatusEnum {
  STATUS_UNKNOWN = 'STATUS_UNKNOWN',
  STATUS_TODO = 'STATUS_TODO',
  STATUS_IN_PROGRESS = 'STATUS_IN_PROGRESS',
  STATUS_COMPLETED = 'STATUS_COMPLETED',
  STATUS_DELETED = 'STATUS_DELETED',
}

export enum TaskTypeEnum {
  TYPE_SCHEDULING = 'TYPE_SCHEDULING',
  TYPE_WAITLIST = 'TYPE_WAITLIST',
  TYPE_PATIENT_CARE = 'TYPE_PATIENT_CARE',
  TYPE_INSURANCE = 'TYPE_INSURANCE',
  TYPE_BILLING = 'TYPE_BILLING',
  TYPE_OTHER = 'TYPE_OTHER',
  TYPE_UNKNOWN = 'TYPE_UNKNOWN',
}

//Types

export type ApiPayloadProps = {
  drillDownOptions?: DrillDownOptions;
  filters: Filters;
};

export type ApiSearchPayloadProps = {
  search_type: SearchTypeEnum;
  search: string;
};

type AppointmentTypeInfereces = {
  inferences: Partial<Record<AppointmentTypeEnum, number>>;
};

export type Call = {
  appointmentTypes?: AppointmentTypeEnum[];
  categories?: CategoryEnum[];
  contactType?: ContactTypeEnum;
  direction?: CallDirectionEnum;
  id: string;
  locationId: string;
  mosScore?: number;
  officeNumber?: OfficeNumber | null;
  officeUser?: OfficeUser | null;
  person?: Person;
  phoneNumber?: PhoneNumber;
  schedulingOpportunity?: boolean;
  schedulingOutcome?: boolean;
  sentiment?: SentimentEnum;
  startTime?: string;
  status?: CallStatusEnum;
  taskCount: number;
  taskTypes: TaskTypeEnum[];
};

export type CallMetadata = Omit<CallMetadataResponse, 'callMetadata'> & {
  callParticipantsMetadata: CallParticipantsMetadata;
  tasks: Task[];
};

export type CallMetadataResponse = {
  rating?: keyof typeof RatingEnum;
  summary: string;
  transcript: string;
  callMetadata: CallParticipantsMetadata;
};

export type CallsStats = {
  count?: number;
};

export type CallParticipantsMetadata = { caller: string; receptionist: string };

export type CallsResponse = {
  calls?: Call[];
  hasNextPage?: boolean;
  pageConfig: AnalyticsCommonTypes.PageConfig;
};

export type CallTaskListResponse = {
  tasks: Task[];
};

export type CallTaskParams = {
  locations?: string[];
  source?: TaskSource;
  callIds?: string[];
  includeMetaData?: boolean;
  sortType?: SortTypeEnum;
};

export type CallTakeawayAccordion = 'call-tasks' | 'call-summary' | 'call-analysis' | 'call-details';

type CategoryInfereces = {
  inferences: Partial<Record<CategoryEnum, number>>;
};

export type ContactTypeFilterEnum = Omit<ContactTypeEnum, ContactTypeEnum.CONTACT_UNKNOWN>;

export type DrillDownOptions = {
  index: FilterTypeEnum;
  key: string;
  value: string[];
};

export type FeedbackRequest = {
  allow_weave_to_contact?: boolean;
  call_id: string;
  comment?: string;
  rating: RatingEnum;
  user_id: string;
};

export type FilterPayload = {
  filter: {
    [key in FilterTypeEnum]?: {
      [subKey: string]: any;
    };
  };
  limit?: number;
  offset?: number;
  sort_type?: SortTypeEnum;
};

export type Filters = {
  appointmentTypes?: string[];
  callDirection?: CallDirectionEnum;
  contactTypes?: ContactTypeFilterEnum[];
  categories?: string[];
  endDate?: string;
  hideNonAnalyzedCalls?: boolean;
  locations?: string[];
  officeNumbers?: string[];
  officeUsers?: string[];
  schedulingOpportunity?: string;
  schedulingOutcomes?: SchedulingOutcomeEnum[];
  sentiments?: string[];
  startDate?: string;
};

export type FollowUp = {
  calls: Call[];
  totalTasks: number;
  totalCalls: number;
};

export type FollowUpItem = {
  allCalls: Call[];
  contact: Call & { contactId: string };
  callTaskMapping: Record<string, Task[]>;
  totalCalls: number;
  totalTasks: number;
};

export type FollowUpResponse = {
  followUps: FollowUp[];
  hasNextPage?: boolean;
  pageConfig: AnalyticsCommonTypes.PageConfig;
  totalFollowUps: number;
};

export type FollowUpStatsResponse = {
  stats: TaskStats;
};

export type FollowupTaskTypeFilters = {
  startDate?: string;
  endDate?: string;
  locations?: string[];
  officeUsers?: string[];
  contactTypes?: ContactTypeFilterEnum[];
  taskTypes?: TaskTypeEnum[];
  taskStatus?: TaskStatusEnum[];
};

export type GetCalls = {
  drillDownOptions?: DrillDownOptions;
  filters: Filters;
  pageConfig: AnalyticsCommonTypes.PageConfig;
  showOnlyUnscheduled?: boolean;
  searchQuery?: string;
  sortType?: SortTypeEnum;
};

export type GetFollowUpsParams = {
  filters: FollowupTaskTypeFilters;
  sortBy: FollowupSortFieldEnum;
  sortType?: SortTypeEnum;
  searchQuery?: string;
  pageConfig: AnalyticsCommonTypes.PageConfig;
};

export type GetFollowUpsStatsParams = {
  filters: FollowupTaskTypeFilters;
};

export type HighlightedCitation = {
  text: string;
  time: string;
};

type Location = {
  id: string;
  name: string;
  slug: string;
};

export type Locations = {
  locations: Location[];
};

export type LocationStat<T> = {
  [locationId: string]: T;
};

export type LocationsStats = {
  appointmentTypesByLocation: LocationStat<AppointmentTypeInfereces>;
  categoriesByLocation: LocationStat<CategoryInfereces>;
  opportunitiesByLocation: LocationStat<OpportunitiesByLocation>;
  sentimentsByLocation: LocationStat<SentimentInfereces>;
  taskTypesByLocation: LocationStat<TaskTypeInfereces>;
};

export type OpportunitiesByLocation = {
  totalOpportunities: number;
  unscheduledOpportunities: number;
};

export type Overview = {
  appointmentTypes: Partial<Record<AppointmentTypeEnum, number>> | null;
  categories: Partial<Record<CategoryEnum, number>> | null;
  sentiments: Partial<Record<SentimentEnum, number>> | null;
  summary: Summary;
  taskTypes: Partial<Record<TaskTypeEnum, number>> | null;
};

export type PatientProvidedInfo = {
  email?: string;
  address?: string;
  city?: string;
  state?: string;
  zipcode?: string;
  country?: string;
  ssn?: string;
  dob?: string;
  faxNumber?: string;
  dependentName?: string[];
  dependentPhone?: string[];
  dependentEmail?: string[];
  dependentDob?: string[];
  dependentSsn?: string[];
  insuranceProvider?: string;
  insurancePhoneNumber?: string;
  insuranceFax?: string;
  insuranceEmail?: string;
  insuranceMemberId?: string[];
};

export type Person = {
  firstName?: string;
  id: string;
  lastName?: string;
  preferredName?: string;
};

export type PhoneNumber = {
  countryCode?: number;
  nationalNumber?: string;
};

type SentimentInfereces = {
  inferences: Partial<Record<SentimentEnum, number>>;
};

export type SearchState = { query: string; value: string };

type Summary = {
  scheduledOpportunities?: number;
  totalOpportunities?: number;
};

export type Task = {
  assignee: string;
  citation: string;
  citationTime?: string;
  description: string;
  id: string;
  locationId: string;
  metadata: string;
  sourceId: string;
  status: TaskStatusEnum;
  title: string;
  type: TaskTypeEnum;
};

export type TaskStats = {
  [key in TaskStatusEnum]?: number;
};

export enum TaskSource {
  'CALL_INTELLIGENCE' = 'SOURCE_CALL_INTELLIGENCE',
}

type TaskTypeInfereces = {
  inferences: Partial<Record<TaskTypeEnum, number>>;
};

export type TaskUpdateRequest = {
  id: string;
  status: TaskStatusEnum;
};

type OfficeNumber = {
  countryCode: number;
  nationalNumber: string;
};

export type OfficeUser = {
  firstName?: string;
  lastName?: string;
  status?: string;
  userId?: string;
  userName?: string;
};
