import { css } from '@emotion/react';
import { Button, DialerInput, Divider, Page } from '@frontend/generic-dialpad-accessories';
import { useTranslation } from '@frontend/i18n';
import { useFeatureFlagShallowStore } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { SpinningLoader, Text } from '@frontend/design-system';
import { AutocompleteWraps } from '../components/autocomplete/auto-complete-wrap';
import { DeviceInformationWrapper } from '../components/device-information-wrap';
import { PageHeaderWraps } from '../components/generic/page-header-wraps';
import { HomeKeypadFooter } from '../components/home-keypad-footer';
import { KeypadContainer } from '../components/keypad-wrapper';
import { useDialer } from '../hooks/use-dialer';
import { useDialerAutocomplete } from '../hooks/use-dialer-autocomplete';
import { useSoftphoneCallState } from '../providers/softphone-call-state-provider';
import { useSoftphoneCallActions } from '../providers/softphone-call-state-provider/softphone-call-actions.provider';
import { useSoftphoneDialer } from '../providers/softphone-dialer-provider';
import { useSoftphoneDirectory } from '../providers/softphone-directory-provider';
import { useSoftphoneWidgetControl } from '../providers/widget-control-provider';

export const PageHomeKeypad = () => {
  const { t } = useTranslation('softphone');
  const currentDial = useSoftphoneDialer((ctx) => ctx.currentDial);
  const setCurrentDial = useSoftphoneDialer((ctx) => ctx.setCurrentDial);
  const isLoading = useSoftphoneDirectory((ctx) => ctx.isLoading);
  const placeCallFromDialer = useSoftphoneCallActions((ctx) => ctx.placeCallFromDialer);
  const placeCallDirect = useSoftphoneCallActions((ctx) => ctx.placeCallDirect);
  const reset = useSoftphoneDialer((ctx) => ctx.reset);
  const isPlaceCallLoading = useSoftphoneCallState((ctx) => ctx.isPlaceCallLoading);
  const isMobile = useSoftphoneWidgetControl((ctx) => ctx.isMobile);
  const close = useSoftphoneWidgetControl((ctx) => ctx?.close);
  const setIsFeedbackOpen = useSoftphoneWidgetControl((ctx) => ctx?.setIsFeedbackOpen);
  const { flagValues } = useFeatureFlagShallowStore('flagValues');
  /* Testing Purpose - move to devtools */
  const softphoneFeedbackTestingFF = flagValues['softphone-call-feedback'];

  const placeCall = (direct?: string) => {
    const cb = () => {
      reset();
      dialer.dispatch({ type: 'reset' });
    };
    return direct ? placeCallDirect(direct).then(cb) : placeCallFromDialer().then(cb);
  };

  const dialer = useDialer({
    onSubmit: placeCall,
  });

  const dialerInputProps = {
    currentDial,
    setCurrentDial,
    isDirectoryLoading: isLoading,
    ...dialer,
  };

  const autocompleteProps = useDialerAutocomplete({
    currentDial,
    dispatch: dialer.dispatch,
  });

  const autocompleteInputProps = {
    currentDial,
    inputState: dialer.inputState,
    isDirectoryLoading: isLoading,
    ...autocompleteProps,
  };

  const handleFeedbackPopup = () => {
    close();
    setIsFeedbackOpen(true);
  };

  return (
    <Page
      css={css`
        height: 100%;
      `}
    >
      <PageHeaderWraps>
        <Text color='white'> {t('New Call')}</Text>
        <DeviceInformationWrapper hideOutboundCallerInfo={isMobile} hideExtension />
      </PageHeaderWraps>
      <Divider />
      <form
        css={css`
          ${!isMobile && `padding: ${theme.spacing(1, 0)}`};
          flex: 1;
        `}
        onSubmit={(e) => {
          e.preventDefault();
          placeCall();
        }}
      >
        <DialerInput focusOnMount={false} {...dialerInputProps}>
          <AutocompleteWraps
            hideAutocompleteOnBlur={true}
            hideAutocompleteOnEmpty={false}
            {...autocompleteInputProps}
          />
        </DialerInput>
        <KeypadContainer onDialCharClick={(char) => dialer.dispatch({ type: 'add', payload: `${char}` })} />
        <Button.Round
          trackingId='softphone-modal-greencall'
          disabled={isPlaceCallLoading}
          iconName={!isPlaceCallLoading ? 'phone' : undefined}
          css={css`
            margin: auto;
          `}
          type='submit'
          color='green'
        >
          {isPlaceCallLoading && <SpinningLoader />}
        </Button.Round>
      </form>
      <Divider />
      <HomeKeypadFooter />
      {/* Testing Purpose - move to devtools */}
      {softphoneFeedbackTestingFF && <Button onClick={handleFeedbackPopup}>Show Feedback Popup</Button>}
    </Page>
  );
};
