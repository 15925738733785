import { Fragment, memo, useEffect, useMemo, useRef, useState } from 'react';
import { css } from '@emotion/react';
import {
  AreaChart as RechartAreaChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Area,
  Tooltip as RechartTooltip,
} from 'recharts';
import { AxisInterval } from 'recharts/types/util/types';
import { theme } from '@frontend/theme';
import { EmptyData, Tooltip } from '../atoms';
import { useChartContext } from '../chart.provider';
import { defaultFormatter, generateLegends, generateTooltipData } from '../helpers';
import { FormatValue } from '../types';
import { ActiveDot } from './active-dot';
import { generateGradientDefs } from './gradient-area';
import { Appearance, AreaChartData } from './types';
import { YAxisTick } from './y-axis-tick';

type MultiAxisClubIds = Record<string, string>;

type Props = {
  appearance?: Appearance;
  customLegendsIds?: string[];
  data?: AreaChartData;
  formatValue?: FormatValue | Record<string, FormatValue>;

  // This prop is used to club multiple lines into single axis for multi-unit (nomral value, currency etc) type charts
  // The lines that needs to be clubbed should have the same id
  multiAxisClubIds?: MultiAxisClubIds;
};

const getMaxLineValues = (data: AreaChartData, multiAxisClubIds?: MultiAxisClubIds) => {
  const maxValues = data.groups?.reduce((acc, { values }) => {
    const keys = Object.keys(values);
    return {
      ...acc,
      ...keys.reduce((acc2, key) => {
        const axisKey = multiAxisClubIds?.[key] || key;
        const max = Math.max(acc[axisKey] || 0, acc2[axisKey] || 0, values[key]);
        acc2[axisKey] = max;
        return acc2;
      }, {} as Record<string, number>),
    };
  }, {} as Record<string, number>);

  return maxValues;
};

const normalizeValue = (value: number, maxValue: number, maxRenderValue: number) => (value / maxValue) * maxRenderValue;

const denormalizeValue = (normalizedValue: number, maxValue: number, maxRenderValue: number) =>
  (normalizedValue / maxRenderValue) * maxValue;

const scrollerPadding = 10;
// Keeping a minimum of top margin to avoid clipping of the animated active dot
const defaultMargin = { top: 12, right: 40, bottom: 0, left: 0 };
const lineStrokeWidth = 3;

const axisLine = {
  stroke: theme.colors.neutral10,
  strokeDasharray: '1 1',
  strokeWidth: 1,
};

export const AreaChart = memo(
  ({ appearance = {}, customLegendsIds, data = { groups: [] }, formatValue, multiAxisClubIds }: Props) => {
    const {
      customTooltipData,
      customTooltipTitle,
      customXAxisTick,
      customXAxisTickFormat,
      customYAxisTick,
      customYAxisTickFormat,
      gradientOpacity,
      height = '280px',
      hideTooltip,
      margin,
      maxValue,
      showGridLines,
      showXAxis,
      showYAxis,
      width = '100%',
      wrapperStyles,
      xAxisTickWidth = 80,
    } = appearance;
    const { activeLegends, colors, commonTooltipLabel, emptyStateConfig, labels, setPrimaryLegendsList } =
      useChartContext();
    const scrollableWrapperRef = useRef<HTMLDivElement>(null);
    const maxValueRef = useRef<number>(0);
    const maxRenderValueRef = useRef<number>(0);
    const [isScrollable, setIsScrollable] = useState<boolean>(false);
    const [hoveredGroup, setHoveredGroup] = useState<string | null>(null);
    const [tooltipPosition, setTooltipPosition] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

    const updatedMargin = { ...defaultMargin, ...margin };

    const groupsCount = data.groups?.length;
    // Do not use minChartSize for any calculations, it is just for the chart container to have a minimum width
    const minChartSize =
      groupsCount * xAxisTickWidth +
      // For vertical layout, when y-axis is visible add additional 60px (approx value of inbuilt y-axis width)
      updatedMargin.left +
      updatedMargin.right +
      (showYAxis ? 60 : 0);

    const customValueTickBase = maxValue ? maxValue / 4 : undefined;
    const customValueTicks =
      maxValue && customValueTickBase
        ? [0, customValueTickBase, customValueTickBase * 2, customValueTickBase * 3, maxValue]
        : undefined;

    // These unique formatters are used to render multi units in the value axis
    const collectedUniqueFormatters = useMemo(() => {
      if (!formatValue || typeof formatValue === 'function') {
        if (!formatValue) {
          return [defaultFormatter];
        } else {
          return [formatValue];
        }
      } else {
        // Two keys might same same formatter, so we need to collect unique formatters
        return Object.values(formatValue).reduce((acc, formatter) => {
          if (!acc.includes(formatter)) {
            acc.push(formatter);
          }
          return acc;
        }, [] as FormatValue[]);
      }
    }, [formatValue]);

    const chartData = useMemo(() => {
      const groupedData: {
        name: string;
        [key: string]: number | string;
      }[] = [];

      // multiAxisClubIds is expected to be present when multiple types of data (eg. days count, production value etc) being shown in the same chart
      // In such cases, we need to normalize the values based on the max value of the respective axis
      if (multiAxisClubIds) {
        const maxLineValues = getMaxLineValues(data, multiAxisClubIds);
        const maxValuesArray = Object.values(maxLineValues);
        maxValueRef.current = Math.max(...maxValuesArray);
        maxRenderValueRef.current = Math.min(...maxValuesArray);

        data?.groups?.forEach(({ name, values }) => {
          const group = {
            name,
            ...Object.entries(values).reduce((acc, [id, value]) => {
              acc[id] = normalizeValue(value, maxLineValues[multiAxisClubIds?.[id] || id], maxRenderValueRef.current);
              return acc;
            }, {} as Record<string, number>),
          };
          groupedData.push(group);
        });
      } else {
        data?.groups?.forEach(({ name, values }) => {
          const group = { name, ...values };
          groupedData.push(group);
        });
      }

      return groupedData;
    }, [data, multiAxisClubIds]);

    const legendsAndTooltipData = useMemo(() => {
      if (!data?.groups?.length) {
        return;
      }

      return {
        legends: generateLegends(data),
        tooltipData: generateTooltipData({ colors, commonTooltipLabel, customTooltipData, data, formatValue, labels }),
      };
    }, [data]);

    useEffect(() => {
      const legends = customLegendsIds ?? legendsAndTooltipData?.legends;
      setPrimaryLegendsList(legends ?? []);
    }, [legendsAndTooltipData?.legends]);

    useEffect(() => {
      if (scrollableWrapperRef.current) {
        scrollableWrapperRef.current.scrollLeft = 0;
        setIsScrollable(scrollableWrapperRef.current.scrollWidth > scrollableWrapperRef.current.clientWidth);
      }
    }, [chartData]);

    useEffect(() => {
      // When scrolling, close the tooltip
      const handleScroll = () => {
        setHoveredGroup(null);
      };

      scrollableWrapperRef.current?.addEventListener('scroll', handleScroll);

      return () => {
        scrollableWrapperRef.current?.removeEventListener('scroll', handleScroll);
      };
    }, [scrollableWrapperRef]);

    return (
      <div
        css={[styles.scrollableWrapper, wrapperStyles]}
        ref={scrollableWrapperRef}
        style={{
          paddingBottom: isScrollable ? scrollerPadding : 0,
        }}
      >
        <div
          className='pdf-chart-view'
          style={{
            height,
            minWidth: minChartSize,
            width,
          }}
        >
          {data.groups.length ? (
            <ResponsiveContainer width='100%' height='100%' key={JSON.stringify(data.groups || {})}>
              <RechartAreaChart
                data={chartData}
                margin={updatedMargin}
                onMouseLeave={() => {
                  setHoveredGroup(null);
                }}
                onMouseMove={(state, event) => {
                  if (!hideTooltip) {
                    setHoveredGroup(state.activeLabel ?? null);
                    setTooltipPosition({
                      x: event.clientX,
                      y: event.clientY,
                    });
                  }
                }}
              >
                {showGridLines && <CartesianGrid stroke={theme.colors.neutral10} strokeDasharray='1 1' />}

                <XAxis
                  axisLine={axisLine}
                  dataKey='name'
                  fontSize={14}
                  hide={!showXAxis}
                  interval={'preserveStartEnd' as AxisInterval}
                  tick={
                    customXAxisTick
                      ? (props) => {
                          return customXAxisTick?.({
                            groupName: props.payload.value,
                            isHovered: props.payload.value === hoveredGroup,
                            labels,
                            x: props.x,
                            y: props.y,
                          });
                        }
                      : undefined
                  }
                  tickFormatter={customXAxisTickFormat}
                  tickLine={false}
                />
                <YAxis
                  allowDecimals={false}
                  axisLine={axisLine}
                  domain={maxValue ? [0, maxValue] : undefined}
                  fontSize={14}
                  hide={!showYAxis}
                  tickCount={5}
                  tickFormatter={customYAxisTickFormat}
                  tick={
                    customYAxisTick
                      ? (props) => {
                          return customYAxisTick?.({
                            groupName: props.payload.value,
                            isHovered: props.payload.value === hoveredGroup,
                            labels,
                            x: props.x,
                            y: props.y,
                          });
                        }
                      : multiAxisClubIds
                      ? (props) => (
                          <YAxisTick
                            {...props}
                            formatters={collectedUniqueFormatters}
                            subValue={(value) =>
                              denormalizeValue(value, maxValueRef.current, maxRenderValueRef.current)
                            }
                          />
                        )
                      : undefined
                  }
                  tickLine={false}
                  ticks={customValueTicks}
                />

                <RechartTooltip
                  content={() => {
                    // We have custom tooltip, so we don't need the default one
                    // But we still need to use rechart tooltip to render the cursor
                    return null;
                  }}
                  cursor={{
                    fill: theme.colors.neutral20,
                    strokeDasharray: '10 3',
                    strokeWidth: 2,
                  }}
                />

                {activeLegends.map((legend) => {
                  const color = colors[legend] || theme.colors.neutral50;
                  const defId = `def-${legend.replace(/[^a-zA-Z0-9]/g, '')}`;

                  return (
                    <Fragment key={legend}>
                      {generateGradientDefs(defId, color, gradientOpacity?.[legend])}
                      <Area
                        activeDot={<ActiveDot color={color} size={6} />}
                        dataKey={legend}
                        fill={`url(#${defId})`}
                        stroke={color}
                        strokeWidth={lineStrokeWidth}
                        type='monotone'
                      />
                    </Fragment>
                  );
                })}
              </RechartAreaChart>
            </ResponsiveContainer>
          ) : (
            <EmptyData emptyStateConfig={emptyStateConfig} />
          )}
        </div>
        {hoveredGroup && !hideTooltip && (
          <Tooltip
            data={legendsAndTooltipData?.tooltipData[hoveredGroup] || []}
            name={
              customTooltipTitle?.({
                groupName: hoveredGroup,
              }) || hoveredGroup
            }
            xPos={tooltipPosition.x}
            yPos={tooltipPosition.y}
          />
        )}
      </div>
    );
  }
);

AreaChart.displayName = 'AreaChart';

const styles = {
  scrollableWrapper: css`
    overflow: auto;
  `,
};
