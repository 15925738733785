import { FC, useEffect, useCallback } from 'react';
import { useQuery } from 'react-query';
import { FormsMediaManager, ProviderReview } from '@frontend/api-forms';
import { useTranslation } from '@frontend/i18n';
import { SpinningLoader, Text, TextButton, useAlert } from '@frontend/design-system';
import { base64toImage } from '../../../../../../../../../../shared/helpers';
import { useWritebackWidgetContext, useWritebackWidgetStore } from '../../../../../providers';
import { containerStyle } from './signature-preview.styles';

interface SignaturePreviewProps {
  signature: ProviderReview.Types.ProviderSignature;
}

const SignaturePreview: FC<SignaturePreviewProps> = ({ signature }) => {
  const { t } = useTranslation('forms');
  const alert = useAlert();
  const { locationId } = useWritebackWidgetContext();
  const { setIsUpdateSignatureActive } = useWritebackWidgetStore(['setIsUpdateSignatureActive']);
  const { data: signatureData, font_type, type } = signature;

  // Image ID is unique for each signature image, so we can use it as a key
  const { data: imageURL, isError: imageLoadFailed } = useQuery(
    signatureData,
    () => FormsMediaManager.API.fetchMedia(signatureData, locationId),
    {
      enabled: type === 'image',
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      cacheTime: 60 * 60 * 1000, // 1 hour
    }
  );

  const updateSignatureHandler = useCallback(() => {
    setIsUpdateSignatureActive(true);
  }, [setIsUpdateSignatureActive]);

  const showLoader = type === 'image' && !imageURL;

  useEffect(() => {
    if (imageLoadFailed) {
      alert.error(t('Failed to load signature image'));
    }
  }, [imageLoadFailed]);

  if (showLoader) {
    return (
      <div css={containerStyle}>
        <div className='sign-loader'>
          <SpinningLoader size='xl' />
          <Text size='medium'>{t('Loading Saved Signature')}</Text>
        </div>
      </div>
    );
  }

  return (
    <div css={containerStyle}>
      {type === 'image' && imageURL && <img src={base64toImage(imageURL.data)} width='300px' />}
      {type === 'text' && (
        <Text className='typed-sign' style={{ fontFamily: font_type }}>
          {signatureData}
        </Text>
      )}

      <TextButton className='update-sign' onClick={updateSignatureHandler}>
        {t('Update Signature')}
      </TextButton>
    </div>
  );
};

export default SignaturePreview;
