import { CallIntelligenceTypes } from '@frontend/api-analytics';
import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';

type TaskTypeFilter = CallIntelligenceTypes.TaskTypeEnum | 'all';

type UpdateCallTaskMappingParams = {
  callTaskMapping: Record<string, CallIntelligenceTypes.Task[]>;
  calculate: boolean;
  followUpId: string;
  shouldCleanUp?: boolean;
  taskType?: TaskTypeFilter;
};

type UpdateFollowUpStatsParams = {
  state: CallIntelFollowUpsStore;
  oldTasks: CallIntelligenceTypes.Task[];
  newTasks: CallIntelligenceTypes.Task[];
  taskType: TaskTypeFilter;
};

type UpdateTaskStatusParams = {
  callId: string;
  followUpId: string;
  newStatus: CallIntelligenceTypes.TaskStatusEnum;
  oldStatus: CallIntelligenceTypes.TaskStatusEnum;
  taskId: string;
};

// Helper method to update follow-up stats in the store
function updateFollowUpStats({ state, oldTasks, newTasks, taskType = 'all' }: UpdateFollowUpStatsParams) {
  const statusCountDiff: CallIntelligenceTypes.TaskStats = {
    [CallIntelligenceTypes.TaskStatusEnum.STATUS_TODO]: 0,
    [CallIntelligenceTypes.TaskStatusEnum.STATUS_IN_PROGRESS]: 0,
    [CallIntelligenceTypes.TaskStatusEnum.STATUS_COMPLETED]: 0,
  };

  oldTasks
    .filter((task) => taskType === 'all' || task.type === taskType)
    .forEach((task) => {
      statusCountDiff[task.status] = (statusCountDiff[task.status] || 0) - 1;
    });

  newTasks
    .filter((task) => taskType === 'all' || task.type === taskType)
    .forEach((task) => {
      statusCountDiff[task.status] = (statusCountDiff[task.status] || 0) + 1;
    });

  Object.keys(statusCountDiff).forEach((status) => {
    const key = status as CallIntelligenceTypes.TaskStatusEnum;
    state.taskStats[key] = Math.max((state.taskStats[key] || 0) + (statusCountDiff[key] || 0), 0);
  });
}

export interface CallIntelFollowUpsStore {
  followUps: CallIntelligenceTypes.FollowUpItem[];
  totalPatients: number;
  taskStats: CallIntelligenceTypes.TaskStats;

  reset: () => void;
  setFollowUps: (followUps: CallIntelligenceTypes.FollowUpItem[]) => void;
  setTotalPatients: (totalPatients: number) => void;
  setTaskStats: (taskStats: CallIntelligenceTypes.TaskStats) => void;
  updateCallTaskMapping: (params: UpdateCallTaskMappingParams) => void;
  updateTaskStatus: (params: UpdateTaskStatusParams) => void;
}

const initialState = {
  followUps: [] as CallIntelligenceTypes.FollowUpItem[],
  totalPatients: 0,
  taskStats: {
    [CallIntelligenceTypes.TaskStatusEnum.STATUS_TODO]: 0,
    [CallIntelligenceTypes.TaskStatusEnum.STATUS_IN_PROGRESS]: 0,
    [CallIntelligenceTypes.TaskStatusEnum.STATUS_COMPLETED]: 0,
  } as CallIntelligenceTypes.TaskStats,
};

const useCallIntelFollowUpsStore = createStoreWithSubscribe<CallIntelFollowUpsStore>(
  (set) => ({
    ...initialState,

    // Reset the store to its initial state
    reset: () => {
      set(
        () => ({
          ...initialState,
        }),
        false,
        'RESET_CALL_INTEL_FOLLOW_UPS_STORE'
      );
    },

    // Set the follow-ups data
    setFollowUps: (followUps) => {
      set(
        (state) => {
          state.followUps = followUps;
        },
        false,
        'SET_FOLLOW_UPS'
      );
    },

    // Set the total number of patients
    setTotalPatients: (totalPatients) => {
      set(
        (state) => {
          state.totalPatients = totalPatients;
        },
        false,
        'SET_TOTAL_PATIENTS'
      );
    },

    // Set the task stats
    setTaskStats: (taskStats) => {
      set(
        (state) => {
          state.taskStats = taskStats;
        },
        false,
        'SET_TASK_STATS'
      );
    },

    // Update the task mapping for a specific call and recalculate stats if necessary
    updateCallTaskMapping: ({
      callTaskMapping,
      calculate = false,
      followUpId,
      shouldCleanUp = false,
      taskType = 'all',
    }) => {
      set(
        (state) => {
          const followUp = state.followUps.find((f) => f.contact.contactId === followUpId);

          if (followUp) {
            const updatedCallTaskMapping = { ...followUp.callTaskMapping };
            let tasksRemoved = 0;

            if (shouldCleanUp) {
              // cleanup purpose is to remove calls, followups and updatedCallTaskMapping based on tasks
              Object.keys(callTaskMapping).forEach((callId) => {
                const tasks = callTaskMapping[callId];
                const oldTasks = updatedCallTaskMapping[callId] || [];

                if (tasks.length === 0) {
                  if (taskType === 'all') {
                    tasksRemoved += updatedCallTaskMapping[callId].length;
                  } else {
                    tasksRemoved += updatedCallTaskMapping[callId].filter((task) => task.type === taskType).length;
                  }
                  delete updatedCallTaskMapping[callId];
                  followUp.allCalls = followUp.allCalls.filter((call) => call.id !== callId);
                  followUp.totalCalls = Math.max(followUp.totalCalls - 1, 0);
                } else {
                  if (taskType === 'all') {
                    tasksRemoved += updatedCallTaskMapping[callId].length - tasks.length;
                  } else {
                    const oldTaskCountForType = updatedCallTaskMapping[callId].filter(
                      (task) => task.type === taskType
                    ).length;
                    const newTaskCountForType = tasks.filter((task) => task.type === taskType).length;
                    tasksRemoved += oldTaskCountForType - newTaskCountForType;
                  }
                  updatedCallTaskMapping[callId] = tasks;
                }
                //updateFollowUpStats is used to update the follow-up stats. It also handles updating stats in case of tasks deletion
                updateFollowUpStats({ state, oldTasks, newTasks: tasks, taskType });
              });

              followUp.totalTasks = Math.max(followUp.totalTasks - tasksRemoved, 0);

              if (Object.keys(updatedCallTaskMapping).length === 0) {
                state.followUps = state.followUps.filter((f) => f.contact.contactId !== followUpId);
                state.totalPatients = Math.max(state.totalPatients - 1, 0);
              } else {
                followUp.callTaskMapping = updatedCallTaskMapping;
              }
            } else {
              followUp.callTaskMapping = { ...updatedCallTaskMapping, ...callTaskMapping };
            }

            if (calculate) {
              Object.keys(callTaskMapping).forEach((callId) => {
                const tasks = callTaskMapping[callId];
                const oldTasks = updatedCallTaskMapping[callId] || [];
                updateFollowUpStats({ state, oldTasks, newTasks: tasks, taskType });
              });
            }
          }
        },
        false,
        'UPDATE_CALL_TASK_MAPPING'
      );
    },

    // Update single task status and adjust the task stats accordingly
    updateTaskStatus: ({ callId, followUpId, newStatus, oldStatus, taskId }) => {
      set(
        (state) => {
          const followUp = state.followUps.find((f) => f.contact.contactId === followUpId);

          if (followUp) {
            const tasks = followUp.callTaskMapping[callId] || [];
            const task = tasks.find((t) => t.id === taskId);
            if (task) {
              task.status = newStatus;

              if (oldStatus !== newStatus) {
                state.taskStats[oldStatus] = (state.taskStats[oldStatus] || 0) - 1;
                state.taskStats[newStatus] = (state.taskStats[newStatus] || 0) + 1;
              }
            }
          }
        },
        false,
        'UPDATE_TASK_STATUS'
      );
    },
  }),
  {
    name: 'CallIntelFollowUpsStore',
    trace: true,
  }
);

export const useCallIntelFollowUpsShallowStore = createShallowStore(useCallIntelFollowUpsStore);
