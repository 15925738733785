import { DataSourcesTypes } from '@frontend/api-data-sources';
import { PersonTypes } from '@frontend/api-person';
import { contextFactory } from '@frontend/design-system';
import { SourceTenantListItem } from '../../EventsPanel/ScheduleActions/ScheduleRequests/ScheduleRequestModal/types';
import { AddToListHandlerParamsType, FormFields, PatientSelectorScreenEnum, PersonSelectorFormValues } from './types';

export type ManagePersonListContextType = {
  shouldRenderNotes?: boolean;
  locationId: string;
  selectedPerson: PersonTypes.Person | null;
  personSelectorScreen?: PatientSelectorScreenEnum;
  defaultSearchValue?: string;
  setDefaultSearchValue?: (value: string) => void;
  isLoading?: boolean;
  closePopoverDialog: () => void;
  setPersonSelectorScreen: (screen: PatientSelectorScreenEnum) => void;
  setSelectedPerson: (person: PersonTypes.Person | null) => void;
  onAddToListHandler?: (personDetails: AddToListHandlerParamsType) => void;
  handleAddCustomerContact?: (personDetails: PersonSelectorFormValues) => void;
  setNotes?: (notes: string) => void;
  formFields: FormFields;
  dataSources?: DataSourcesTypes.DataSource[];
  clientLocationIds?: DataSourcesTypes.AccessDataResponse['ClientLocations'];
  addContactScreenTitle?: string;
  showDataSources?: boolean;
  addNewButtonTrackingId?: string;
  addToListButtonTrackingId?: string;
  cancelButtonTrackingId?: string;
  sourceTenantList?: SourceTenantListItem[];
  shouldAddAndLink?: boolean;
};

export const [ManagePersonListContext, useManagePersonListContext] = contextFactory<ManagePersonListContextType>();
