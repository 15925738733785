import { CalendarException } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { ScheduleRequestSource } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import { AppointmentType } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/appointment_type.pb';
import { AppointmentTypePractitioner } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/appointment_type_practitioner.pb';
import { DateSlots } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/calendar.pb';
import { Operatory } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/operatory.pb';
import { Practitioner } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/practitioner.pb';
import { Rule, Schedule } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/schedule.pb';
import { DayOfWeek as DayOfWeekSchema } from '@weave/schema-gen-ts/dist/shared/schedule/schedule.pb';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import durationPlugin from 'dayjs/plugin/duration';
import { QueryKey } from 'react-query';
import { RRule, Weekday, rrulestr } from 'rrule';
import { DataSourcesTypes } from '@frontend/api-data-sources';
import { ScheduleTypes } from '@frontend/api-schedule';
import { ScheduleAvailabilityTypes } from '@frontend/api-schedule-availability';
import env from '@frontend/env';
import { ScheduleAvailabilityHoursTypes } from '@frontend/schedule-availability-hours';
import { genUUIDV4 } from '@frontend/string';
import { sentry } from '@frontend/tracking';
import {
  AddEntryParamsType,
  ConversionType,
  ScheduleHours,
  ScheduleHoursSessionMap,
  daysMap,
  daysMapReverse,
} from './types';
import { PersonSelectorTypes } from './views/Calendar/components/PersonSelector';

dayjs.extend(durationPlugin);
dayjs.extend(customParseFormat);

export const CalendarEventsV3DateFormat = 'YYYY-MM-DDTHH:mm:ss';

type GetDayOfWeekReturnType = DayOfWeekSchema | '' | undefined;

export const getDayOfWeek = (date: string): GetDayOfWeekReturnType => {
  if (!date) return;

  // number of the day of the week from 0 (Sunday) to 6 (Saturday)
  const day = dayjs(date).isoWeekday();
  switch (day) {
    case 0:
      return DayOfWeekSchema.SUNDAY;
    case 1:
      return DayOfWeekSchema.MONDAY;
    case 2:
      return DayOfWeekSchema.TUESDAY;
    case 3:
      return DayOfWeekSchema.WEDNESDAY;
    case 4:
      return DayOfWeekSchema.THURSDAY;
    case 5:
      return DayOfWeekSchema.FRIDAY;
    case 6:
      return DayOfWeekSchema.SATURDAY;
    default:
      return '';
  }
};

export const formatDate = (dateStr: string): string => {
  const date = dayjs(dateStr);
  const today = dayjs();

  if (date.isSame(today, 'day')) {
    return `Today ${date.format('h:mm A')}`;
  } else if (date.isSame(today.subtract(1, 'day'), 'day')) {
    return `Yesterday ${date.format('h:mm A')}`;
  } else {
    return date.format('MMM D, h:mm A');
  }
};

const bookingLinkBaseURL = ['https://book2.weavedev.net', 'https://book2.getweave.com'];

export const validateIsWeaveBookingLink = (url: string): boolean => {
  try {
    const parsedURL = new URL(url);
    return bookingLinkBaseURL.includes(parsedURL.origin);
  } catch {
    sentry.warn({
      error: 'Invalid url provided for booking link',
      topic: 'schedule',
      addContext: {
        name: 'url details',
        context: {
          url,
        },
      },
    });
    return false;
  }
};

export const isValidBookingLink = (url: string, locationId: string): boolean => {
  return validateIsWeaveBookingLink(url) && url?.includes(locationId);
};

export const hasSameIds = (masterIds: string[], filterIds: string[]): boolean => {
  return masterIds.length === filterIds.length && masterIds.every((id) => filterIds.includes(id));
};

export const getIsIntegratedOffice = (syncApps: DataSourcesTypes.DataSource[]): boolean => {
  return syncApps?.some((syncApp) => syncApp.SourceType === 'Integration');
};

export const getBookingUrlWithSource = (bookingUrl: string, bookingSource: ScheduleRequestSource) => {
  const hasLocationPortalSlug = bookingUrl.includes('locationPortalSlug');
  return hasLocationPortalSlug ? `${bookingUrl}&source=${bookingSource}` : `${bookingUrl}?source=${bookingSource}`;
};

export const getValidScheduleRequestSourceEnum = (value: string): ScheduleRequestSource => {
  if (Object.values(ScheduleRequestSource).includes(value as ScheduleRequestSource)) {
    return value as ScheduleRequestSource;
  }
  return ScheduleRequestSource.WEBSITE;
};

export const getIsDevEnv = (): boolean => {
  return globalThis.localStorage.getItem('envType') === 'dev' || env?.BACKEND_API?.includes('https://api.weavedev.net');
};

//------------ RRules schedule availability helper methods and rrule to schedule hours and schedule hours to rrule conversion methods ------------

/**
 * @desc: Function is defined to get the system timezone
 * @returns timezone: string
 */
export const getSystemTzid = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

/**
 * @desc: Function is defined to get the rrule day of the week (Weekday) based on the day abbreviation
 * @param day : string - day abbreviation
 * @returns : Weekday
 */
const getRRuleDay = (day: string): Weekday => {
  switch (day) {
    case 'MO':
      return RRule.MO;
    case 'TU':
      return RRule.TU;
    case 'WE':
      return RRule.WE;
    case 'TH':
      return RRule.TH;
    case 'FR':
      return RRule.FR;
    case 'SA':
      return RRule.SA;
    case 'SU':
      return RRule.SU;
    default:
      return RRule.MO;
  }
};

/**
 * @desc: Function is defined to get the day of the week number based on the day abbreviation
 * @param day : string - day abbreviation
 * @returns : number
 */
const getWeekDayNumberByDay = (day: string): number => {
  const key = Object.keys(daysMapReverse).find((key) => daysMapReverse[Number(key)] === day);
  return key ? Number(key) : 0;
};

/**
 * @desc: Function is defined to add the session hours entry to the schedule hours map
 * @param Object : AddEntryParamsType
 */
const addSessionHoursEntry = ({
  scheduleHoursMap,
  startTime,
  endTime,
  duration,
  dayAbbreviation,
  type,
}: AddEntryParamsType) => {
  const key = `${startTime}-${endTime}-${type}`;
  if (!scheduleHoursMap.has(key)) {
    scheduleHoursMap.set(key, { days: [], startTime, endTime, duration, type });
  }
  scheduleHoursMap.get(key)?.days.push(dayAbbreviation);
};

/**
 * @desc: Function is defined to create the rrule object based on the session hours details
 * @param sessionHoursDetails
 * @returns : Object : { rrule: string, duration: number }
 */
const buildRRule = (sessionHoursDetails: ScheduleHoursSessionMap) => {
  const { days, startTime, duration } = sessionHoursDetails;
  const [hour, minute] = startTime.split(':');
  const rruleObj = new RRule({
    freq: RRule.WEEKLY,
    byweekday: days.map((day) => getRRuleDay(day)),
    byhour: Number(hour),
    byminute: Number(minute),
  });

  return { rrule: rruleObj.toString(), duration };
};

/**
 * @desc: Function is defined to adjust the day based on the UTC offset and time comparison
 * @param startTimeLocal : dayjs.Dayjs
 * @param day : string
 * @param conversionType : ConversionType
 * @returns : string - adjusted day name
 */
const getAdjustedDay = (startTimeLocal: dayjs.Dayjs, day: string, conversionType: ConversionType) => {
  // Convert the local start time to UTC and get the UTC offset
  const startTime = startTimeLocal.utc();
  const offset = startTimeLocal.utcOffset();

  // Determine the direction of the UTC offset (forward or backward)
  const isForwardOffset = offset > 0;
  const isBackwardOffset = offset < 0;

  // Check if the local time is before or after the corresponding UTC time
  const isLocalTimeBeforeUTC = startTimeLocal.format('HH:mm') < startTime.format('HH:mm');
  const isLocalTimeAfterUTC = startTimeLocal.format('HH:mm') > startTime.format('HH:mm');

  // Determine whether the day should be adjusted based on the offset direction and time comparison
  const shouldAdjustDay = isForwardOffset ? isLocalTimeBeforeUTC : isBackwardOffset ? isLocalTimeAfterUTC : false;

  // Get the weekday number for the given day, Sunday, and Monday
  const weekdayNumber = getWeekDayNumberByDay(day);
  const sunday = getWeekDayNumberByDay('sunday');
  const monday = getWeekDayNumberByDay('monday');

  // Check if the conversion type is for converting schedule hours to RRULE
  const isRRuleConversion = conversionType === 'schedule_hours_to_rrule';

  // Function to adjust the weekday number within the range of Monday to Sunday
  const getAdjustedWeekDayNumber = (dayOffset: number) => {
    const isBeyondSunday = dayOffset > sunday;
    const isBeforeMonday = dayOffset < monday;
    return isBeyondSunday ? monday : isBeforeMonday ? sunday : dayOffset;
  };

  if (shouldAdjustDay) {
    // Calculate the day offset based on the conversion type and UTC offset direction
    const dayOffset = isRRuleConversion ? weekdayNumber + (offset > 0 ? -1 : 1) : weekdayNumber + (offset > 0 ? 1 : -1);

    // Get the adjusted weekday number and return the corresponding day name
    const adjustedWeekDayNumber = getAdjustedWeekDayNumber(dayOffset);
    return daysMapReverse[adjustedWeekDayNumber];
  }

  return day;
};

export const calculateDuration = (startTime: string, endTime: string): number => {
  // Helper function to convert time string (HH:MM) to minutes
  const timeToMinutes = (time: string) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const startMinutes = timeToMinutes(startTime);
  const endMinutes = timeToMinutes(endTime);

  // Calculate the duration in minutes
  const duration = endMinutes - startMinutes;
  return duration;
};

/**
 * @desc: Function is defined to convert the schedule hours object to rrule object for working hours and breaks
 * @param schedule : ScheduleAvailabilityHoursTypes.ScheduleAvailabilityByDayOfWeek
 * @param timezone : string
 * @returns ScheduleHours
 */
export const convertScheduleHoursObjectToRRuleObject = (
  schedule: ScheduleAvailabilityHoursTypes.ScheduleAvailabilityByDayOfWeek,
  timezone?: string
): ScheduleHours => {
  // Initialize the working hours and breaks map
  const workingHoursMap = new Map<string, ScheduleHoursSessionMap>();
  const breaksMap = new Map<string, ScheduleHoursSessionMap>();

  const timeZone = timezone || getSystemTzid();

  for (const [day, schedules] of Object.entries(schedule)) {
    // Get the day abbreviation and iterate over the schedules
    schedules.forEach((schedule) => {
      const startTimeLocal = dayjs.tz(`${day} ${schedule.startTime}`, 'dddd HH:mm', timeZone);
      const endTimeLocal = dayjs.tz(`${day} ${schedule.endTime}`, 'dddd HH:mm', timeZone);
      const startTime = startTimeLocal.utc();
      const endTime = endTimeLocal.utc();

      const adjustedDate = getAdjustedDay(startTimeLocal, day, 'schedule_hours_to_rrule');
      const duration = endTime.diff(startTime, 'minutes');

      addSessionHoursEntry({
        dayAbbreviation: daysMap[adjustedDate],
        duration,
        endTime: endTime.format('HH:mm'),
        scheduleHoursMap: workingHoursMap,
        startTime: startTime.format('HH:mm'),
        type: 'working_hours',
      });

      if (schedule.breaks) {
        schedule.breaks.forEach((breakItem) => {
          const breakStartTimeLocal = dayjs.tz(`${day} ${breakItem.startTime}`, 'dddd HH:mm', timeZone);
          const breakEndTimeLocal = dayjs.tz(`${day} ${breakItem.endTime}`, 'dddd HH:mm', timeZone);
          const breakStart = breakStartTimeLocal.utc();
          const breakEnd = breakEndTimeLocal.utc();

          const adjustedDate = getAdjustedDay(breakStartTimeLocal, day, 'schedule_hours_to_rrule');
          const breakDuration = breakEnd.diff(breakStart, 'minutes');

          addSessionHoursEntry({
            dayAbbreviation: daysMap[adjustedDate],
            duration: breakDuration,
            endTime: breakEnd.format('HH:mm'),
            scheduleHoursMap: breaksMap,
            startTime: breakStart.format('HH:mm'),
            type: 'break_hours',
          });
        });
      }
    });
  }

  const workingHours = Array.from(workingHoursMap.values()).map(buildRRule);
  const breaks = Array.from(breaksMap.values()).map(buildRRule);

  return { workingHours, breaks };
};

/**
 * @desc: This method is used to convert the schedule hours from rrule format to schedule hours object
 * @param scheduleHoursInRRule: ScheduleHours
 * @returns: ScheduleAvailabilityHoursTypes.ScheduleAvailabilityByDayOfWeek
 */
export const convertFromRRuleScheduleHoursToScheduleHoursObject = (
  scheduleHoursInRRule: ScheduleHours,
  timezone?: string
): ScheduleAvailabilityHoursTypes.ScheduleAvailabilityByDayOfWeek => {
  const output: ScheduleAvailabilityHoursTypes.ScheduleAvailabilityByDayOfWeek = {};

  const timeZone = timezone || getSystemTzid();

  // Iterate over the working hours in the rrule format
  scheduleHoursInRRule?.workingHours?.forEach((item) => {
    const rule = RRule.fromString(item.rrule || '');
    const days = rule.options.byweekday;

    // Start and end time in UTC
    const startTimeUTC = dayjs.utc().hour(rule.options.byhour[0]).minute(rule.options.byminute[0]).second(0);
    const endTimeUTC = startTimeUTC.add(item.duration || 0, 'minutes');

    // Convert UTC time to local time
    const startTimeLocal = startTimeUTC.tz(timeZone);
    const endTimeLocal = endTimeUTC.tz(timeZone);

    days.forEach((day) => {
      const startDay = getAdjustedDay(startTimeLocal, daysMapReverse[day], 'rrule_to_schedule_hours');
      const endDay = getAdjustedDay(endTimeLocal, daysMapReverse[day], 'rrule_to_schedule_hours');

      const isStartTimeBeforeEndTime = startTimeLocal.format('HH:mm') < endTimeLocal.format('HH:mm');

      const startDayIndex = getWeekDayNumberByDay(startDay);
      const endDayIndex = getWeekDayNumberByDay(endDay);

      // Ensure startDay is initialized in output
      if (!output[startDay]) {
        output[startDay] = [];
      }

      // Ensure endDay is initialized in output
      if (!output[endDay]) {
        output[endDay] = [];
      }

      // startDay and endDay are the same then push the start and end time
      // else create 2 entries for start and end time for the respective days
      // and set the end time to 23:59 for 1st entry the start day to 00:00 for the 2nd day entry
      if (startDayIndex === endDayIndex || isStartTimeBeforeEndTime) {
        output[startDay].push({
          startTime: startTimeLocal.format('HH:mm'),
          endTime: endTimeLocal.format('HH:mm'),
        });
      } else {
        output[startDay].push({
          startTime: startTimeLocal.format('HH:mm'),
          endTime: '23:59',
        });
        output[endDay] = [];
        output[endDay].push({
          startTime: '00:00',
          endTime: endTimeLocal.format('HH:mm'),
        });
      }
    });
  });

  // Iterate over the breaks in the rrule format
  scheduleHoursInRRule?.breaks?.forEach((item) => {
    const rule = RRule.fromString(item.rrule || '');
    const days = rule.options.byweekday;

    // Start and end time in UTC
    const breakStartTimeUTC = dayjs.utc().hour(rule.options.byhour[0]).minute(rule.options.byminute[0]).second(0);
    const breakEndTimeUTC = breakStartTimeUTC.add(item.duration || 0, 'minutes');

    // Convert UTC time to local time
    const breakStartTimeLocal = breakStartTimeUTC.tz(timeZone);
    const breakEndTimeLocal = breakEndTimeUTC.tz(timeZone);

    days.forEach((day) => {
      const breakStartDay = getAdjustedDay(breakStartTimeLocal, daysMapReverse[day], 'rrule_to_schedule_hours');
      const breakEndDay = getAdjustedDay(breakEndTimeLocal, daysMapReverse[day], 'rrule_to_schedule_hours');

      const isBreakStartTimeBeforeEndTime = breakStartTimeLocal.format('HH:mm') < breakEndTimeLocal.format('HH:mm');

      const startDayIndex = getWeekDayNumberByDay(breakStartDay);
      const endDayIndex = getWeekDayNumberByDay(breakEndDay);

      // Ensure startDay is initialized in output
      if (!output[breakStartDay]) {
        output[breakStartDay] = [];
      }

      if (!output[breakEndDay]) {
        output[breakEndDay] = [];
      }

      output[breakStartDay][0].breaks = output[breakStartDay][0].breaks || [];
      output[breakEndDay][0].breaks = output[breakEndDay][0].breaks || [];

      // startDay and endDay are the same then push the start and end time
      // else create 2 entries for start and end time for the respective days
      // and set the end time to 23:59 for 1st entry the start day to 00:00 for the 2nd day entry
      if (startDayIndex === endDayIndex || isBreakStartTimeBeforeEndTime) {
        output[breakStartDay][0].breaks?.push({
          startTime: breakStartTimeLocal.format('HH:mm'),
          endTime: breakEndTimeLocal.format('HH:mm'),
        });
      } else {
        output[breakStartDay][0].breaks?.push({
          startTime: breakStartTimeLocal.format('HH:mm'),
          endTime: '23:59',
        });

        output[breakEndDay][0].breaks?.push({
          startTime: '00:00',
          endTime: breakEndTimeLocal.format('HH:mm'),
        });
      }
    });
  });

  return output;
};

/**
 * @desc: This method is used to get the sanitized schedule hours object from the schedule availability
 * @returns : ScheduleAvailabilityHoursTypes.ScheduleAvailabilityByDayOfWeek | ScheduleAvailabilityTypes.Schedule
 */
export const getSanitizedScheduleHoursData = (
  scheduleHoursData: ScheduleAvailabilityHoursTypes.ScheduleAvailabilityByDayOfWeek | ScheduleAvailabilityTypes.Schedule
): ScheduleAvailabilityHoursTypes.ScheduleAvailabilityByDayOfWeek | ScheduleAvailabilityTypes.Schedule => {
  const scheduleHours = { ...scheduleHoursData };

  for (const day in scheduleHours) {
    if (scheduleHours[day].length === 0) {
      delete scheduleHours[day];
    }
  }

  return scheduleHours;
};

export const getAppointmentTypesByPractitionerId = (
  practitionerId: string,
  appointmentTypePractitionerData: AppointmentTypePractitioner[],
  appointmentTypesData: AppointmentType[]
): AppointmentType[] => {
  const appointmentTypesPractitionersFilteredData = appointmentTypePractitionerData.filter(
    (appointmentTypPractitionerDetails) => {
      return appointmentTypPractitionerDetails.practitionerMeta.practitionerId === practitionerId;
    }
  );

  const appointmentTypesList = appointmentTypesData?.filter((appointmentType) => {
    if (!appointmentType?.details?.act) return false;
    return appointmentTypesPractitionersFilteredData.some(
      (appointmentTypePractitioner) =>
        appointmentTypePractitioner.appointmentTypeMeta.appointmentTypeId === appointmentType.id
    );
  });

  return appointmentTypesList || [];
};

export const getExceptionDatetimeFromRRrule = (rrule: string): { startDatetime?: Date; endDatetime?: Date } => {
  if (!rrule) return {};

  const rruleObj = rrulestr(rrule);
  const { dtstart, until } = rruleObj.options;

  return { startDatetime: dtstart, endDatetime: until || dtstart };
};

export const createRRruleForException = ({
  dateRange,
  timeRange,
}: {
  dateRange: [string, string];
  timeRange: [string, string];
}) => {
  let [startDate, endDate] = dateRange;
  const [startTime, endTime] = timeRange;

  // Keep startDate and endDate same when either of them is missing
  if (!startDate) {
    startDate = endDate;
  } else if (!endDate) {
    endDate = startDate;
  }

  const dtstart = dayjs(`${startDate} ${startTime}`).toDate();
  const until = dayjs(`${endDate || startDate} ${endTime}`).toDate();

  const rruleOptions = {
    freq: RRule.DAILY,
    dtstart,
    until,
  };

  return new RRule(rruleOptions).toString();
};

export const getFormattedExceptionDateRange = (startDate?: Date, endDate?: Date): string => {
  if (!startDate || !endDate) return '';

  let formattedDate = '';

  const startDateTime = dayjs(startDate);
  const endDateTime = dayjs(endDate);

  const hasSameStartEndDate = startDateTime.isSame(endDateTime, 'day');

  if (hasSameStartEndDate) {
    formattedDate = startDateTime.format('MMMM D, YYYY');
  } else {
    const isSameYear = startDateTime.isSame(endDateTime, 'year');
    const isSameMonth = startDateTime.isSame(endDateTime, 'month');

    if (!isSameYear) {
      formattedDate = `${startDateTime.format('MMMM D, YYYY')} - ${endDateTime.format('MMMM D, YYYY')}`;
    } else if (!isSameMonth) {
      formattedDate = `${startDateTime.format('MMMM D')} - ${endDateTime.format('MMMM D, YYYY')}`;
    } else {
      formattedDate = `${startDateTime.format('MMMM D')} - ${endDateTime.format('D, YYYY')}`;
    }
  }

  return formattedDate;
};

export const getFormattedExceptionTimeRange = (startDate?: Date, duration?: number): string => {
  if (!startDate || !duration) return '';

  let formattedTime = '';
  const startDateTime = dayjs(startDate);
  const endDateTime = startDateTime.add(duration, 'minute');

  const isEntireDay =
    startDateTime.hour() === 0 &&
    startDateTime.minute() === 0 &&
    endDateTime.hour() === 23 &&
    endDateTime.minute() === 59;

  if (isEntireDay) {
    formattedTime = 'All Day';
  } else {
    const startTime = startDateTime;
    const endTime = startTime.add(duration, 'minute');
    formattedTime = `${startTime.format('h:mm A')} - ${endTime.format('h:mm A')}`;
  }

  return formattedTime;
};

export const isUpcomingException = (date: Date) => {
  const today = dayjs();
  const endDate = dayjs(date);
  return today.isBefore(endDate) || today.isSame(endDate);
};

export const isPastException = (date: Date) => {
  const today = dayjs();
  const startDate = dayjs(date);
  return today.isAfter(startDate);
};

export const isCompletedException = (date?: Date) => {
  if (!date) return false;

  const today = dayjs();
  const endDate = dayjs(date);
  return endDate.isBefore(today);
};

// Generate 5-character ID for exception
export const generateExceptionId = () => {
  return genUUIDV4().slice(-5);
};

export const findOverrideIndexById = (overrides: Rule[], id: string) => {
  return overrides.findIndex((override) => override.id && override.id === id);
};

type ConvertToSystemTimezoneParamsType = {
  dateString: string;
  timeString: string;
  sourceTimezone: string;
};

export const convertToSystemTimezone = ({
  dateString,
  timeString,
  sourceTimezone,
}: ConvertToSystemTimezoneParamsType): dayjs.Dayjs => {
  if (!dateString || !timeString) return dayjs();

  const datetimeString = `${dateString} ${timeString}`;

  const dateInSourceTimezone = dayjs.tz(datetimeString, sourceTimezone);

  // Convert the date to the system's local timezone
  const dateInSystemTimezone = dateInSourceTimezone.local();

  return dateInSystemTimezone;
};

/**
 * Constructs a full name from provided name parts
 * @param {...(string | undefined)[]} args - The name parts (first name, middle name, last name).
 * @returns {string} The formatted full name.
 */
export const getFullName = (...args: (string | undefined)[]): string => {
  return args
    .filter((str) => str)
    .join(' ')
    .trim();
};

type GetPractitionerBreakTimeFromRRuleForSelectedDate = {
  rrule: string;
  duration: number;
  refDate: string;
};

export const getEntityBreakDateTimeFromRRuleForSelectedDate = ({
  rrule = '',
  duration = 0,
  refDate,
}: GetPractitionerBreakTimeFromRRuleForSelectedDate) => {
  const rule = rrulestr(rrule);
  const refDay = dayjs(refDate).get('day');
  const rruleDay = dayjs(rule.options.dtstart).get('day');

  // Start and end time in UTC
  const breakStartTimeUTC = dayjs.utc().hour(rule.options.byhour[0]).minute(rule.options.byminute[0]).second(0);
  const breakEndTimeUTC = breakStartTimeUTC.add(duration, 'minutes');

  // Check if recurring weekday includes selected calendar day
  if (rruleDay === refDay) {
    return { startDatetime: breakStartTimeUTC.toString(), endDatetime: breakEndTimeUTC.toString() };
  }

  return null;
};

// Transform V3 responses to match V2 fields for the review request form. This should be removed once component refactored to have separate V3 container
type TransformV3ResponsesForReviewRequest = {
  appointmentTypesDataV3?: AppointmentType[];
  practitionerDataV3?: Practitioner[];
  operatoriesDataV3?: Operatory[];
};

export const transformV3ResponsesForReviewRequest = ({
  appointmentTypesDataV3,
  practitionerDataV3,
  operatoriesDataV3,
}: TransformV3ResponsesForReviewRequest) => {
  const transformedAppointmentTypes =
    appointmentTypesDataV3?.map((appointmentType: AppointmentType) => {
      return {
        id: appointmentType.id,
        durationMinutes: appointmentType.details?.dur ?? 0,
        name: appointmentType.displayName || appointmentType.externalName || '',
        sourceId: appointmentType.sourceTenantId,
        externalId: appointmentType.externalId,
        amount: appointmentType.details?.amt,
        locationId: appointmentType.locationId,
      };
    }) || [];

  const transformedPractitioners = practitionerDataV3?.map((practitioner: Practitioner) => {
    return {
      id: practitioner.id,
      publicDisplayName: practitioner.displayName,
      firstName: practitioner.firstName,
      lastName: practitioner.lastName,
      sourceId: practitioner.sourceTenantId,
      calendarId: practitioner.calendarId,
      externalId: practitioner.externalId,
      locationId: practitioner.locationId,
      parentProviderId: practitioner.calendarId, // using the parentProviderId as calendarId field for transformation. //TODO: Need to update v2 Provider to have calendarId in the schema
    };
  });

  const transformedOperatories = operatoriesDataV3?.map((operatory: Operatory) => {
    return {
      id: operatory.id,
      workstationName: operatory.displayName || operatory.externalName || '',
      sourceId: operatory.sourceTenantId,
      locationId: operatory.locationId,
      externalId: operatory.externalId,
    };
  });

  return {
    transformedAppointmentTypes,
    transformedPractitioners,
    transformedOperatories,
  };
};

export const transformSchedulerV3EventInfoToCalendarEvent = (
  eventInfo: Schedule,
  eventId: string,
  providerId: string
): CalendarException => {
  const unavailableEvent = eventInfo.recurrenceRules?.override?.unAvailabilities?.find((event) => event.id === eventId);

  const { rrule } = unavailableEvent || {};

  if (rrule) {
    const { startDatetime, endDatetime } = getExceptionDatetimeFromRRrule(rrule);

    const startDateTimeObj = dayjs(startDatetime);
    const endDateTimeObj = dayjs(endDatetime);

    return {
      id: eventInfo.id,
      name: unavailableEvent?.name || '',
      isAvailable: false,
      locationId: eventInfo.locationId,
      startDateTime: startDateTimeObj.toDate().toISOString(),
      endDateTime: endDateTimeObj.toDate().toISOString(),
      providerId,
    };
  }

  return {
    id: eventId,
    name: '',
    isAvailable: false,
    locationId: eventInfo.locationId,
    startDateTime: '',
    endDateTime: '',
    providerId,
  };
};

const getTimezoneConvertedFormattedDate = (
  date: string,
  sourceDateFormat: string,
  targetDateFormat: string,
  timezone: string
) => {
  return dayjs.utc(date, sourceDateFormat).tz(timezone).format(targetDateFormat);
};

export const transformDateSlotsToOpeningBlocks = (
  dateSlots: DateSlots[],
  timezone: string
): ScheduleTypes.OpeningBlock[] => {
  return dateSlots.flatMap((dateSlot) =>
    (dateSlot.slots || [])
      .filter((slot) => slot.startTime && slot.endTime) // Ensure both startTime and endTime exist
      .map((slot) => {
        const sourceDateFormat = 'YYYY-MM-DD hh:mmA';
        const targetDateFormat = 'YYYY-MM-DDTHH:mm:ss';

        const startDateTime = getTimezoneConvertedFormattedDate(
          `${dateSlot.date} ${slot.startTime}`,
          sourceDateFormat,
          targetDateFormat,
          timezone
        );

        const endDateTime = getTimezoneConvertedFormattedDate(
          `${dateSlot.date} ${slot.endTime}`,
          sourceDateFormat,
          targetDateFormat,
          timezone
        );

        return {
          startTime: startDateTime,
          endTime: endDateTime,
        };
      })
  );
};

export const invalidateFilterForPersonSearch = (
  queryKey: QueryKey,
  formValues: PersonSelectorTypes.PersonSelectorFormValues,
  locationId?: string
) => {
  return (
    queryKey[2] === locationId &&
    ((queryKey.includes('person-search-by-phone') && queryKey[1] === formValues.phoneNumber) ||
      queryKey.includes('person-search-by-name'))
  );
};
