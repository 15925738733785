import { useControlledField, BaseFieldProps, useModalControl, ModalControlResponse } from '@frontend/design-system';
import ReviewRequired from './review-required.component';

interface UseReviewRequiredProps {
  value: boolean;
  onChangeReviewRequired: (newValue: boolean) => void;
}

interface UseReviewRequiredResults {
  reviewRequiredSwitchFieldProps: BaseFieldProps<boolean, '', HTMLInputElement>;
  ReviewRequired: typeof ReviewRequired;
  reviewRequiredModalControls: ModalControlResponse;
}

/**
 * @deprecated
 */
export const useReviewRequired = ({
  value,
  onChangeReviewRequired,
}: UseReviewRequiredProps): UseReviewRequiredResults => {
  const modalControls = useModalControl();

  const switchFieldProps = useControlledField({
    value,
    type: 'switch',
    onChange: (newValue) => {
      if (!newValue) {
        // Open the confirmation modal when the switch is turned off
        modalControls.openModal();
        return;
      }

      onChangeReviewRequired(newValue);
    },
  });

  return {
    reviewRequiredSwitchFieldProps: switchFieldProps,
    ReviewRequired,
    reviewRequiredModalControls: modalControls,
  };
};
