import { css } from '@emotion/react';
import { PageTitle } from '@frontend/components';
import { useMultiQueryUtils } from '@frontend/payments-hooks';
import { theme } from '@frontend/theme';
import { Chip } from '@frontend/design-system';
import { LocationCountChip } from '.';

export const PageTitleWithLocationChip = ({
  title,
  showLocationCount = false,
  hasMargin = false,
}: {
  title?: React.ReactNode;
  showLocationCount?: boolean;
  hasMargin?: boolean;
}) => {
  const { locationIds, locationId, getLocationName, isMultiLocationFeature } = useMultiQueryUtils();

  return (
    <div
      css={css`
        display: flex;
        flex-wrap: wrap-reverse;
        align-items: center;
        justify-content: start;
        gap: ${theme.spacing(0, 2)};
        ${hasMargin ? `margin-bottom: ${theme.spacing(2)};` : ''}
      `}
    >
      {typeof title === 'string' ? (
        <PageTitle
          title={title}
          css={css`
            display: inline;
          `}
        />
      ) : (
        { title }
      )}
      {showLocationCount ? (
        <LocationCountChip locationIds={locationIds} />
      ) : (
        isMultiLocationFeature && (
          <Chip.SingleChip
            variant='eggplant'
            css={css`
              width: auto;
              max-width: none;
            `}
          >
            {getLocationName(locationId)}
          </Chip.SingleChip>
        )
      )}
    </div>
  );
};
