import { useTranslation } from '@frontend/i18n';
import { DisplaySchedule, Schedule, Break, OpenHours } from './types';

export enum Weekday {
  sunday = 'sunday',
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
}

export const week = [
  Weekday.sunday,
  Weekday.monday,
  Weekday.tuesday,
  Weekday.wednesday,
  Weekday.thursday,
  Weekday.friday,
  Weekday.saturday,
];

export const TranslatedWeek = () => {
  const { t } = useTranslation('schedule');
  const weekdays = {
    [Weekday.sunday]: t('Sunday'),
    [Weekday.monday]: t('Monday'),
    [Weekday.tuesday]: t('Tuesday'),
    [Weekday.wednesday]: t('Wednesday'),
    [Weekday.thursday]: t('Thursday'),
    [Weekday.friday]: t('Friday'),
    [Weekday.saturday]: t('Saturday'),
  };
  return weekdays;
};

export const TranslatedWeekInitials = () => {
  const { t } = useTranslation('schedule');
  const weekdays = {
    [Weekday.sunday]: t('S'),
    [Weekday.monday]: t('M'),
    [Weekday.tuesday]: t('T'),
    [Weekday.wednesday]: t('W'),
    [Weekday.thursday]: t('Th'),
    [Weekday.friday]: t('F'),
    [Weekday.saturday]: t('S'),
  };
  return weekdays;
};

export const formatTimeString = (time?: string) => {
  return time?.slice(0, 5) || '';
};

export const getDefaultSchedule = (): DisplaySchedule[] => {
  return [
    {
      breaks: [],
      startTime: '09:00',
      endTime: '17:00',
    },
  ];
};

export const defaultSchedule: Schedule = {
  monday: getDefaultSchedule(),
  tuesday: getDefaultSchedule(),
  wednesday: getDefaultSchedule(),
  thursday: getDefaultSchedule(),
  friday: getDefaultSchedule(),
};

export const addBreakToDisplaySchedule = (schedule?: DisplaySchedule): DisplaySchedule => {
  const breaks = [...(schedule?.breaks || [])];
  const { endTime } = breaks.reduce(
    (p: any, c: any) => {
      if (c.endTime > p.endTime) {
        return c;
      }
      return p;
    },
    { startTime: '11:00', endTime: '12:00' }
  );
  const [endHours = '12', endMinutes = '00'] = endTime.split(':');

  const startTimeString = `${endHours}:${endMinutes}`;
  const endTimeString = `${+endHours + 1}:${endMinutes}`;

  breaks.push({
    startTime: startTimeString,
    endTime: endTimeString,
  });

  return {
    ...(schedule || { breaks: [], startTime: '', endTime: '' }),
    breaks,
  };
};

type OverlappingFn = {
  timeString: string;
  index: number;
  breaks: Break[];
};

export const checkOverlappingBreak = ({ timeString, index, breaks }: OverlappingFn) => {
  for (let idx = 0; idx < index; idx++) {
    if (idx !== index) {
      const brk = breaks[idx];
      const isWithinHours = timeString >= brk.startTime && timeString <= brk.endTime;
      if (isWithinHours) {
        return false;
      }
    }
  }
  return true;
};

export const withinOpenHours = ({ start, end, hours }: OpenHours) => {
  const [openTime, closeTime] = hours;
  return (start > openTime || openTime === start) && (end < closeTime || end === closeTime);
};
