export const lines = (id: string, mainColor: string, alternativeColor: string) => {
  return (
    <defs>
      <pattern id={id} width='20' height='20' patternTransform='rotate(135)' patternUnits='userSpaceOnUse'>
        <rect x='0' y='0' width='20' height='14' fill={mainColor} />
        <rect x='0' y='14' width='20' height='6' fill={alternativeColor} />
      </pattern>
    </defs>
  );
};
