import { PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { demoDataUtils } from '../../../utils';
import { requiredPatientInfoFields } from './shared-helpers';

const persons: PracticeAnalyticsTypes.PatientInfo[] = demoDataUtils
  .generateRandomUserNames(15)
  .map(({ firstName, lastName }, id) => {
    const aptType = demoDataUtils.generateRandomInt(1, 2) === 1 ? 'restorative' : 'hygiene';
    const nextApt = demoDataUtils.generateRandomDate(true);
    const hasNextAppointment = demoDataUtils.generateRandomInt(1, 3) === 1;
    const isPerio = demoDataUtils.generateRandomBoolean();
    let dueDate = '';

    if (isPerio) {
      // Some of the perio patients should have a due date in the past to generate overdue patients data
      dueDate = demoDataUtils.generateRandomDate(demoDataUtils.generateRandomBoolean());
    } else {
      dueDate = demoDataUtils.generateRandomDate(true);
    }

    return {
      ...requiredPatientInfoFields,
      age: demoDataUtils.generateRandomInt(1, 50),
      dueDate,
      firstAppointmentDate: demoDataUtils.generateRandomDate(),
      firstAppointmentType: aptType,
      FirstName: firstName,
      Gender: demoDataUtils.generateRandomGender(),
      id: `${id}`,
      isPerio,
      LastName: lastName,
      lastVisitDate: demoDataUtils.generateRandomDate(),
      MobilePhone: demoDataUtils.generateRandomPhoneNumber(),
      nextAppointmentDate: !hasNextAppointment ? '' : nextApt,
      nextHygieneAppointment: hasNextAppointment ? demoDataUtils.generateRandomDate(true) : '',
      nextVisit: demoDataUtils.generateRandomInt(1, 10) < 5 ? demoDataUtils.generateRandomDate(true) : '',
      productionAmount: demoDataUtils.generateRandomInt(200, 500),
    };
  });

const getMonthName = (month: number) => {
  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return monthNames[month - 1];
};

const padZero = (number: number) => {
  return number < 10 ? '0' + number : number.toString();
};

const generateHistoricalData = (startYear: number, startMonth: number, startDay: number, numWeeks: number) => {
  const historicalData = [];

  for (let i = 0; i < numWeeks; i++) {
    const date = new Date(startYear, startMonth - 1, startDay + i * 7); // Calculate date based on week index
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const week = Math.ceil((date.getDate() + 1) / 7); // Calculate week number based on date

    const label = `${getMonthName(month)}\\nWeek ${week}`;
    const timestamp = `${year}${padZero(month)}${padZero(date.getDate())}`;
    const total = Math.floor(Math.random() * (3000 - 1000 + 1)) + 1000; // Random value between 1000 and 3000

    historicalData.push({ total, label, timestamp });
  }

  return historicalData;
};

export const activePatients = (): PracticeAnalyticsTypes.ActivePatientsResponse => {
  const historicalData = generateHistoricalData(2024, 1, 1, 24);

  return {
    location: {
      activePatients: {
        historicalData,
        total: historicalData[historicalData.length - 1].total,
      },
    },
  };
};

export const activePatientsScheduled = (): PracticeAnalyticsTypes.ActivePatientsScheduledResponse => {
  return {
    location: {
      activePatientsScheduled: {
        benchmarks: [
          {
            value: 0.85,
            label: 'Top',
          },
          {
            value: 0.52,
            label: 'Avg.',
          },
        ],
        percentage: Math.random(),
      },
    },
  };
};

export const activePatientsDemographics = (): PracticeAnalyticsTypes.ActivePatientsDemographicsResponse => ({
  location: {
    activePatients: {
      total: 2170,
    },
    activePatientsScheduled: {
      percentage: 0.64,
    },
    patientDemographics: {
      buckets: [
        {
          label: '0-12',
          total: 120,
          subbuckets: [
            {
              label: 'Other',
              persons,
              total: 30,
            },
            {
              label: 'Male',
              persons,
              total: 60,
            },
            {
              label: 'Female',
              persons,
              total: 30,
            },
          ],
        },
        {
          label: '12-20',
          total: 400,
          subbuckets: [
            {
              label: 'Other',
              persons,
              total: 200,
            },
            {
              label: 'Male',
              persons,
              total: 100,
            },
            {
              label: 'Female',
              persons,
              total: 100,
            },
          ],
        },
        {
          label: '21-30',
          total: 250,
          subbuckets: [
            {
              label: 'Other',
              persons,
              total: 100,
            },
            {
              label: 'Male',
              persons,
              total: 80,
            },
            {
              label: 'Female',
              persons,
              total: 70,
            },
          ],
        },
        {
          label: '31-42',
          total: 20,
          subbuckets: [
            {
              label: 'Other',
              persons,
              total: 8,
            },
            {
              label: 'Male',
              persons,
              total: 6,
            },
            {
              label: 'Female',
              persons,
              total: 6,
            },
          ],
        },
        {
          label: '43-55',
          total: 443,
          subbuckets: [
            {
              label: 'Other',
              persons,
              total: 150,
            },
            {
              label: 'Male',
              persons,
              total: 200,
            },
            {
              label: 'Female',
              persons,
              total: 93,
            },
          ],
        },
        {
          label: '56-69',
          total: 150,
          subbuckets: [
            {
              label: 'Other',
              persons,
              total: 50,
            },
            {
              label: 'Male',
              persons,
              total: 80,
            },
            {
              label: 'Female',
              persons,
              total: 20,
            },
          ],
        },
        {
          label: '70+',
          total: 300,
          subbuckets: [
            {
              label: 'Other',
              persons,
              total: 100,
            },
            {
              label: 'Male',
              persons,
              total: 80,
            },
            {
              label: 'Female',
              persons,
              total: 120,
            },
          ],
        },
      ],
      details: {
        insuranceProviders: [
          {
            label: 'METLIFE',
            persons,
            total: 300,
          },
          {
            label: 'DELTA DENTAL',
            persons,
            total: 220,
          },
          {
            label: 'CIGNA',
            persons,
            total: 150,
          },
          {
            label: 'AETNA',
            persons,
            total: 450,
          },
          {
            label: 'HUMANA',
            persons,
            total: 180,
          },
          {
            label: 'No Insurance',
            persons,
            total: 190,
          },
          {
            label: 'Other',
            persons,
            total: 193,
          },
        ],
      },
    },
  },
});

export const activePatientsDemographicsScheduled =
  (): PracticeAnalyticsTypes.ActivePatientsDemographicsScheduledResponse => ({
    location: {
      activePatients: {
        total: 2170,
      },
      activePatientsScheduled: {
        percentage: 0.64,
      },
      unscheduledHygienePatients: {
        buckets: [
          {
            label: 'Not Due',
            persons,
            total: 146,
          },
          {
            label: '0-3 Months Over Due',
            persons,
            total: 292,
          },
          {
            label: '3-6 Months Over Due',
            persons,
            total: 215,
          },
          {
            label: '6-12 Months Over Due',
            persons,
            total: 98,
          },
          {
            label: '12+ Months Over Due',
            persons,
            total: 224,
          },
        ],
        totals: {
          patients: 975,
        },
      },
      unscheduledNonhygienePatients: {
        buckets: [
          {
            label: 'Last Visit 0-6 Months Ago',
            persons,
            total: 85,
          },
          {
            label: 'Last Visit 6-9 Months Ago',
            persons,
            total: 45,
          },
          {
            label: 'Last Visit 9-12 Months Ago',
            persons,
            total: 54,
          },
          {
            label: 'Last Visit 12-18 Months Ago',
            persons,
            total: 62,
          },
          {
            label: 'Last Visit 18+ Months Ago',
            persons,
            total: 98,
          },
        ],
        totals: {
          patients: 344,
        },
      },
    },
  });
