import { useCallback } from 'react';
import { useMutation, UseMutateAsyncFunction, useQueryClient } from 'react-query';
import { FormsQueryKeys, ProviderReview } from '@frontend/api-forms';
import { useAlert } from '@frontend/design-system';

interface UseRejectSubmissionResults {
  rejectSubmission: UseMutateAsyncFunction<any, unknown, Payload, unknown>;
  isMarkingAsRejected: boolean;
}

export const useRejectSubmission = (): UseRejectSubmissionResults => {
  const alert = useAlert();
  const queryClient = useQueryClient();

  const refetchSubmission = useCallback(
    (payload: Payload) => {
      return Promise.all([
        queryClient.invalidateQueries(FormsQueryKeys.formSubmissions),
        queryClient.invalidateQueries([FormsQueryKeys.formSubmission, payload.submissionId]),
      ]);
    },
    [queryClient]
  );

  const { mutateAsync, isLoading } = useMutation(rejectSubmission, {
    onSuccess: (res) => {
      if (!res.success) {
        alert.error('Failed to reject the submission!');
        return;
      }

      alert.success('Submission rejected successfully!');
    },
    onError: () => {
      alert.error('Failed to reject the submission!');
    },
    onSettled: (_res, _err, payload) => {
      refetchSubmission(payload);
    },
  });

  return {
    rejectSubmission: mutateAsync,
    isMarkingAsRejected: isLoading,
  };
};

interface Payload {
  submissionId: string;
  note: string;
  user: ProviderReview.Types.User;
  locationId: string;
}

async function rejectSubmission({ submissionId, note, user, locationId }: Payload) {
  const payload: ProviderReview.Types.RejectSubmissionPayload = {
    company_id: locationId,
    submission_id: submissionId,
    note,
    review_status: ProviderReview.Types.ReviewStatus.REJECTED,
    provider_details: {
      ...user,
    },
  };

  return ProviderReview.API.submitFormSubmissionReview(payload);
}
