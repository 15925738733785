import { useQuery } from 'react-query';
import { FormsQueryKeys, FormsDigitization } from '@frontend/api-forms';

export const useFormsPaymentStatus = (locationId: string, isEnabled: boolean) => {
  const { data, isLoading, isError } = useQuery(
    [FormsQueryKeys.digitization.paymentStatus, locationId],
    () => FormsDigitization.API.getFormsPaymentStatus(locationId),
    {
      enabled: isEnabled,
      retry: false,
      cacheTime: 0,
      staleTime: 0,
    }
  );

  return {
    paidForForms: data?.canUploadForms || false,
    isLoading,
    isError,
  };
};
