import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: ${theme.spacing(2)};

  .empty-packet-heading {
    color: ${theme.colors.text.subdued};
  }

  .empty-packet-placeholder {
    max-width: 400px;
    max-height: 400px;
  }
`;
