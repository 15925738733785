import { useMemo } from 'react';
import { css } from '@emotion/react';
import { motion } from 'framer-motion';
import { DepartmentsQueries, DepartmentsUtils } from '@frontend/api-departments';
import { TagsV2 } from '@frontend/api-tag';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Chip } from '@frontend/design-system';
import { useInboxFilterStatusLabels, useInboxFiltersShallowStore } from '../stores';
import { HiddenFilterKeys, InboxFilter } from '../types';

type InboxFiltersStatusProps = {
  hideFilters?: HiddenFilterKeys;
};

export const InboxFiltersStatus = ({ hideFilters }: InboxFiltersStatusProps) => {
  const { selectedLocationIds, selectedOrgId } = useAppScopeStore();
  const { inboxFilters, setInboxFilters } = useInboxFiltersShallowStore('inboxFilters', 'setInboxFilters');
  const statusLabels = useInboxFilterStatusLabels();
  const tagsListQuery = TagsV2.Queries.useListTagsQuery({
    request: { orgId: selectedOrgId, groupIds: selectedLocationIds },
  });
  const departmentsQueries = DepartmentsQueries.useListDefaultSMSQueries(
    selectedLocationIds.map((locationId) => ({ req: { locationId } }))
  );
  const departmentsQuery = DepartmentsUtils.reduceDefaultSMSQueriesToLocationIdMap(
    departmentsQueries,
    ({ smsNumbers }) => smsNumbers ?? []
  );
  const multiDepartments = Object.values(departmentsQuery.data ?? {}).flat() || [];
  const filtersToHide = useMemo(
    () =>
      Object.entries(inboxFilters).reduce<NonNullable<HiddenFilterKeys>>(
        (acc, [key, value]) => {
          if (acc.includes(key as InboxFilter)) return acc;
          if (!value.length) acc.push(key as InboxFilter);
          return acc;
        },
        [...(hideFilters ?? [])]
      ),
    [JSON.stringify(inboxFilters), JSON.stringify(hideFilters)]
  );
  const hasFilters = filtersToHide.length < Object.keys(inboxFilters).length;

  return (
    <motion.div
      initial={{
        padding: hasFilters ? theme.spacing(1) : theme.spacing(0, 1),
        height: hasFilters ? 'auto' : 0,
      }}
      animate={{
        height: hasFilters ? 'auto' : 0,
        padding: hasFilters ? theme.spacing(1) : theme.spacing(0, 1),
      }}
      css={{
        backgroundColor: theme.colors.neutral10,
        overflow: 'hidden',
        display: 'flex',
        gap: theme.spacing(1),
        flexWrap: 'wrap',
      }}
    >
      {!filtersToHide.includes('statuses') &&
        inboxFilters.statuses.map((status) => (
          <Chip.Removable
            key={status}
            css={filterChipStyles}
            onClick={() =>
              setInboxFilters({ ...inboxFilters, statuses: inboxFilters.statuses.filter((val) => val !== status) })
            }
          >
            {statusLabels[status]}
          </Chip.Removable>
        ))}
      {!filtersToHide.includes('tags') &&
        inboxFilters.tags.map((tagId) => {
          const tag = tagsListQuery.data?.tags.find((t) => t.id === tagId);
          if (!tag) return null;

          return (
            <Chip.Removable
              key={tagId}
              css={filterChipStyles}
              leftElement={<Icon name='label-small' />}
              onClick={() => setInboxFilters({ ...inboxFilters, tags: inboxFilters.tags.filter((id) => id !== tagId) })}
            >
              {tag.name}
            </Chip.Removable>
          );
        })}
      {!filtersToHide.includes('departments') &&
        inboxFilters.departments.map((deptId) => {
          const department = multiDepartments.find((d) => d.id === deptId);
          if (!department) return null;

          return (
            <Chip.Removable
              key={deptId}
              css={filterChipStyles}
              leftElement={<Icon name='department-small' />}
              onClick={() =>
                setInboxFilters({
                  ...inboxFilters,
                  departments: inboxFilters.departments.filter((id) => id !== deptId),
                })
              }
            >
              {department.name}
            </Chip.Removable>
          );
        })}
    </motion.div>
  );
};

const filterChipStyles = css({
  backgroundColor: theme.colors.primary50,
  color: theme.colors.white,
  width: 'max-content',
  maxWidth: 'none',
  svg: {
    color: theme.colors.white,
  },
});
