import { forwardRef, useMemo } from 'react';
import { Direction, Status } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { SMS } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/models.pb';
import { PersonHelpers } from '@frontend/api-person';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { UsersQueries } from '@frontend/api-users';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { Photos } from '@frontend/photos';
import { useTimestampFormatter } from '@frontend/timestamp-formatter';
import { ComponentProps } from '@frontend/types';
import { Avatar, AvatarProps, ChatItem } from '@frontend/design-system';

type AdditionalProps = Omit<
  ComponentProps<typeof ChatItem>,
  'direction' | 'avatar' | 'timestamp' | 'senderName' | 'error' | 'text' | 'textColor' | 'isSending' | 'children'
>;

type SMSThreadItemProps = {
  sms: SMS;
  avatarProps: Omit<AvatarProps, 'locationId' | 'personId' | 'isUser' | 'isWeave'>;
  media: { isLoading: boolean; src?: string }[];
  mediaMaxWidth?: string;
} & AdditionalProps;

export const SMSThreadItem = forwardRef<HTMLUListElement, SMSThreadItemProps>(
  ({ sms, avatarProps, media, mediaMaxWidth = '80%', highlightProps, ...rest }, ref) => {
    const { t } = useTranslation('thread-body');
    const user = getUser();
    const isInbound = sms.direction === Direction.DIRECTION_INBOUND;
    const formatTimestamp = useTimestampFormatter();
    const senderName = `${avatarProps.firstName ?? ''} ${avatarProps.lastName ?? ''}`.trim() || avatarProps.name;
    const isSending = sms.statusDetails === SMSDataV3.Types.OptimisticUpdateStatusDetails.SENDING;
    const isDeleting = sms.statusDetails === SMSDataV3.Types.OptimisticUpdateStatusDetails.DELETING;
    const hasError = sms.status === Status.STATUS_NOT_SENT || sms.status === Status.STATUS_ERROR;
    const resolvedTimestampText = isSending
      ? t('Sending...')
      : isDeleting
      ? t('Deleting...')
      : formatTimestamp(sms.createdAt);

    const locationUsers = UsersQueries.useGetMultiLocationsUsers({
      locationIds: [sms.locationId],
      params: { showChildren: true },
      opts: {
        enabled: !!sms.deletedAt && !!sms.deletedBy,
      },
    }).flatMap(({ data }) => data || []);

    const deleterName = useMemo(() => {
      if (!sms.deletedBy) return undefined;

      if (user && sms.deletedBy === user?.userID) {
        return PersonHelpers.getFullName({
          FirstName: user.firstName,
          LastName: user.lastName,
        });
      }

      const matchedUser = locationUsers.find((user) => user.UserID === sms.deletedBy);
      if (!matchedUser) return t('Unknown User');

      return PersonHelpers.getFullName({
        FirstName: matchedUser.FirstName,
        LastName: matchedUser.LastName,
      });
    }, [sms.deletedBy, user?.userID, JSON.stringify(locationUsers)]);

    return (
      <ChatItem
        direction={isInbound ? 'inbound' : 'outbound'}
        avatar={
          isInbound ? (
            <Photos.ContactProfilePhoto {...avatarProps} personId={sms.personId} locationId={sms.locationId} />
          ) : (
            <Avatar {...avatarProps} isUser isWeave={!!sms.autogeneratedBy} />
          )
        }
        timestamp={resolvedTimestampText}
        senderName={senderName}
        error={!isSending && hasError ? sms.statusDetails || t('Not Delivered') : undefined}
        text={
          deleterName
            ? t('This message was deleted on {{date}} by {{deleterName}}', {
                date: formatTimestamp(sms.deletedAt),
                deleterName,
              })
            : sms.body
        }
        textColor={deleterName ? 'light' : undefined}
        isSending={isSending}
        {...rest}
        ref={ref}
      >
        {media.map(({ isLoading, src }) => (
          <ChatItem.Image
            key={src}
            src={src}
            loadingText={isLoading ? t('Loading image...') : undefined}
            maxWidth={mediaMaxWidth}
          />
        ))}
      </ChatItem>
    );
  }
);
SMSThreadItem.displayName = 'SMSItem';
