import { Dispatch, SetStateAction } from 'react';
import { ScheduledSmsThread } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { ThreadDraft } from '@weave/schema-gen-ts/dist/schemas/sms/draft/v1/draft_service.pb';
import type { Tag as SmsTag } from '@weave/schema-gen-ts/dist/schemas/tag/shared/v1/models.pb';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import { MessagesTypes } from '@frontend/api-messaging';
import { RenderListItemProps } from '@frontend/components';
import { InboxType } from '@frontend/inbox-navigation';
import { ExtendedDepartment } from '../../../types';
import { UnifiedListItem } from './unified-list-item';

dayjs.extend(calendar);

type RendererProps = {
  type: InboxType;
  isBulkSelect: boolean;
  selectedThreads: Record<string, string[]>;
  setSelectedThreads: Dispatch<SetStateAction<Record<string, string[]>>>;
  isSelectAll: boolean;
  departmentsByLocation: Record<string, ExtendedDepartment[]>;
  locationTags: SmsTag[];
  scheduledThreadsMap: Record<string, ScheduledSmsThread[]>;
  drafts: ThreadDraft[];
};

export const InboxListItem =
  ({
    type,
    isBulkSelect,
    selectedThreads,
    setSelectedThreads,
    isSelectAll,
    departmentsByLocation,
    scheduledThreadsMap,
    drafts,
  }: RendererProps) =>
  ({ listItem }: RenderListItemProps<MessagesTypes.InboxListItem>) => {
    const departments_v1 = departmentsByLocation[listItem.locationId] ?? [];

    return (
      <UnifiedListItem
        thread={listItem}
        inboxType={type}
        isBulkSelect={isBulkSelect}
        selectedThreads={selectedThreads}
        setSelectedThreads={setSelectedThreads}
        isSelectAll={isSelectAll}
        departments={departments_v1}
        scheduledThreadsMap={scheduledThreadsMap}
        drafts={drafts}
        personObj={listItem.person}
        body={listItem.body}
        timestamp={listItem.createdAt}
      />
    );
  };
