import { ScheduledSms } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { RelatedID } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/models.pb';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { MediaQueries } from '@frontend/api-media';
import { MessagesHooks, MessagesTypes } from '@frontend/api-messaging';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { InboxType, useInboxNavigate } from '@frontend/inbox-navigation';
import { ThreadSendingMediaItem } from '@frontend/integrated-messaging';
import { MediaUploadPreview } from '@frontend/media-upload-preview';
import { SchemaManualSMSScheduledService } from '@frontend/schema';
import { sentry } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import { AlertManager, FileUpload, Modal, ModalControlResponse, Text, useAlert } from '@frontend/design-system';
import { MediaInteractionUtils } from '../../../utils';
import { SMSTypingIndicator } from '../../typing-indicator';
import { SMSSuperTextArea } from './sms-super-textarea';

type ThreadViewSendingAreaProps = {
  personPhone: string;
  groupId: string;
  threadId: string;
  departmentId?: string;
  person?: Person;
  isDragActive: boolean;
  threadMedia: ThreadSendingMediaItem[];
  uploadFiles: (files: File[]) => Promise<void>;
  clearMedia: () => void;
  removeMediaItem: (mediaId: string) => void;
  imageUploadModalControl: ModalControlResponse;
  disabled?: boolean;
  scrollbarWidth: number;
  personId?: string;
  scheduledMessageForEdit?: ScheduledSms;
  clearScheduledMessageForEdit: () => void;
  taggedSmsId?: string;
  isNew?: boolean;
  locationPhone: string;
  isLastMessageInbound: boolean;
  inboxType?: InboxType;
};

export const ThreadViewSendingArea = ({
  personPhone,
  groupId,
  threadId,
  departmentId,
  person,
  isDragActive,
  threadMedia,
  uploadFiles,
  clearMedia,
  removeMediaItem,
  imageUploadModalControl,
  disabled,
  scrollbarWidth,
  personId,
  scheduledMessageForEdit,
  clearScheduledMessageForEdit,
  taggedSmsId,
  isNew,
  locationPhone,
  isLastMessageInbound,
  inboxType,
}: ThreadViewSendingAreaProps) => {
  const { t } = useTranslation('inbox');
  const alert = useAlert();
  const user = getUser();
  const { navigateToThread, setIsNew } = useInboxNavigate();
  const scheduledMessageForEditMediaQueries = MediaQueries.useMmsMedia({
    mediaIds: scheduledMessageForEdit?.mediaIds ?? [],
    locationId: groupId,
  });
  const scheduledMessageForEditMedia = scheduledMessageForEditMediaQueries.reduce<ThreadSendingMediaItem[]>(
    (acc, { data }) => {
      if (!data) return acc;

      acc.push({
        id: data.mediaId,
        mediaObj: {
          mediaId: data.mediaId,
        },
        uploading: false,
        hasError: false,
        previewSrc: data.src,
      });
      return acc;
    },
    []
  );
  const { mutateAsync: sendMessage, isLoading } = MessagesHooks.useSendMessageMutation(
    {
      locationId: groupId,
    },
    {
      onError: () => {
        alert.error(t('Error sending message'));
      },
      onSuccess: () => {
        clearMedia();
      },
    }
  );

  const deleteScheduledMessage = async (msgId: string) => {
    try {
      if (!user) throw new Error('No msgId found');
      const deleteRes = await SchemaManualSMSScheduledService.Delete({
        messageId: msgId,
        locationId: groupId,
        deletedBy: user?.userID ?? '',
      });
      removeScheduledMessage({ threadId, groupId: deleteRes.locationId, smsId: deleteRes.messageId });
      clearScheduledMessageForEdit();
    } catch {
      alert.error(t('Error deleting scheduled message'));
    }
  };

  const scheduledMessageMutation = MessagesHooks.useScheduleMessageMutation({
    groupId,
    threadId,
    personPhone,
    personId,
    departmentId: departmentId ?? '',
    userId: user?.userID ?? '',
    onSuccess: (res) => {
      clearMedia();
      AlertManager.addAlert({
        type: 'success',
        message: t('Message Scheduled'),
        action: {
          label: t('Undo'),
          onClick: async () => deleteScheduledMessage(res?.scheduledSms?.id),
        },
      });
    },
    onError: () => {
      alert.error(
        scheduledMessageForEdit
          ? t('Error rescheduling message. Please try again.')
          : t('Error scheduling message. Please try again.')
      );
    },
  });

  const { remove: removeScheduledMessage } = MessagesHooks.useUpdateScheduledMessages();

  const showDropzone = isDragActive && !imageUploadModalControl.modalProps.show;
  const media: ThreadSendingMediaItem[] = scheduledMessageForEdit ? scheduledMessageForEditMedia : threadMedia;

  const { data: flags } = FeatureFlagQueries.useMultiFeatureFlagIsEnabledQuery({
    flagName: 'comm-platform-thread-naming',
    groupIds: [groupId],
  });
  const usePersonId = !flags?.[groupId];

  return (
    <>
      <div
        css={
          showDropzone
            ? { minHeight: '20vh', padding: theme.spacing(2), position: 'relative' }
            : { position: 'relative', background: scheduledMessageForEdit ? theme.colors.warning5 : '' }
        }
        onPaste={(e) => {
          const files = MediaInteractionUtils.getImagesFromPaste(e);
          uploadFiles(files);
        }}
      >
        {showDropzone && (
          <div
            css={{
              border: `1px dashed ${theme.colors.neutral40}`,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              borderRadius: theme.borderRadius.medium,
            }}
          >
            <Text>{t('Drop image here (PNG or JPG files only)')}</Text>
          </div>
        )}
        <SMSTypingIndicator locationId={groupId} threadId={threadId} scrollbarWidth={scrollbarWidth} />
        <MediaUploadPreview media={media} removeMediaItem={removeMediaItem} />
        <SMSSuperTextArea
          inboxType={inboxType}
          deleteScheduledMessage={deleteScheduledMessage}
          departmentId={departmentId}
          disabled={disabled}
          disableSend={isLoading || !groupId}
          onSend={async (message: string, relatedIds?: RelatedID[]) => {
            const response = await sendMessage({
              body: message,
              media: media.flatMap(({ mediaObj }) => (mediaObj?.mediaId ? [{ mediaId: mediaObj.mediaId }] : [])),
              createdBy: user?.userID ?? '',
              departmentId,
              locationId: groupId,
              personPhone,
              programSlugId: MessagesTypes.KnownProgramSlugIds.MANUAL_MESSAGES,
              personId,
              excludeSignature: !!scheduledMessageForEdit,
              relatedIds,
              threadId,
              targetSmsId: taggedSmsId,
            });
            if (!response) {
              alert.error(t('Error sending message'));
              return;
            }
            clearMedia();
            const responseThreadId = response.threadId;
            if (isNew) setIsNew(false);
            if (scheduledMessageForEdit) {
              deleteScheduledMessage(scheduledMessageForEdit?.id);
            }
            if (taggedSmsId)
              navigateToThread({
                threadId: responseThreadId ?? threadId,
                groupId,
                personId: usePersonId ? personId : undefined,
                personPhone,
                replace: true,
              });
            if (responseThreadId && threadId !== responseThreadId)
              navigateToThread({
                threadId: responseThreadId,
                groupId,
                personId: usePersonId ? personId : undefined,
                personPhone,
                replace: true,
              });
          }}
          openImageUploadModal={() => imageUploadModalControl.openModal()}
          personPhone={personPhone}
          threadId={threadId}
          groupId={groupId}
          onSchedule={async ({ message, scheduledTime, relatedIds, isPaused }) => {
            try {
              await scheduledMessageMutation.mutateAsync({
                message,
                scheduledTime,
                mediaIds: media.map((mediaItem) => mediaItem.mediaObj?.mediaId).filter(Boolean),
                messageIdToReplace: scheduledMessageForEdit?.id,
                relatedIds,
                pausable: isPaused,
              });
              clearMedia();
              if (scheduledMessageForEdit) clearScheduledMessageForEdit();
            } catch (err) {
              alert.error(t('Error scheduling message'));
              sentry.error({
                error: err,
                topic: 'messages',
                severityLevel: 'error',
                addContext: {
                  name: 'Schedule payload & scheduledMessageForEdit',
                  context: {
                    payload: {
                      message,
                      scheduledTime,
                    },
                    scheduledMessageForEdit,
                  },
                },
              });
            }
          }}
          disableScheduleMessage={isNew}
          scheduledMessageForEdit={scheduledMessageForEdit}
          onCancelEdit={() => clearScheduledMessageForEdit()}
          selectedPerson={person}
          hasMedia={!!threadMedia.length}
          locationPhone={locationPhone}
          canGenerateAiResponse={isLastMessageInbound}
        />
      </div>
      <Modal {...imageUploadModalControl.modalProps} maxWidth={600}>
        <Modal.Header textAlign='left'>{t('Upload Image')}</Modal.Header>
        <Modal.Body>
          <FileUpload
            onFileUpload={(files: File[]) => {
              uploadFiles(files);
              imageUploadModalControl.closeModal();
            }}
            acceptedFileType={['png', 'jpg', 'jpeg']}
            helperText={t('Drop image here (PNG or JPG files only)')}
            multiple
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
