import { useTranslation } from '@frontend/i18n';
import { Text } from '@frontend/design-system';
import { useGlobalSearchFeatureLabelMap } from '../config';
import { useGlobalSearch } from '../provider';
import { useGlobalSearchSection } from './global-search-section';

type GlobalSearchEmptyStateProps = {
  customMessage?: string;
};

export const GlobalSearchEmptyState = ({ customMessage }: GlobalSearchEmptyStateProps) => {
  const { t } = useTranslation('global-search');
  const { searchFor, viewAllFeature } = useGlobalSearch(['searchFor', 'viewAllFeature']);
  const labelMap = useGlobalSearchFeatureLabelMap();
  const { id } = useGlobalSearchSection();

  const isAll = searchFor === 'all' && !viewAllFeature;

  return (
    <Text
      id={isAll ? 'global-search-empty-state' : undefined}
      css={{ width: '100%' }}
      textAlign='center'
      color='light'
      size='medium'
    >
      {customMessage
        ? customMessage
        : t('No {{label}} match this search.', { label: labelMap[id]?.toLocaleLowerCase() })}
    </Text>
  );
};
