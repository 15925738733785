import dayjs from 'dayjs';
import { PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { demoDataUtils } from '../../../utils';
import { requiredPatientInfoFields } from './shared-helpers';

const hygieneReappointments = (count: number): PracticeAnalyticsTypes.HygieneFollowUpTotals => {
  const data = [];
  let totals = { patients: 0, rescheduledSameDay: 0, unscheduled: 0 };
  const startDate = dayjs().subtract(count, 'day').toDate();

  for (let i = 0; i < count; i++) {
    const date = new Date(startDate);
    date.setDate(startDate.getDate() + i);
    const patients = demoDataUtils.generateRandomInt(10, 30);
    const rescheduledSameDay = demoDataUtils.generateRandomInt(0, patients);
    const unscheduled = patients - rescheduledSameDay;

    data.push({
      date: date.toISOString().slice(0, 10),
      patients,
      rescheduledSameDay,
      unscheduled,
    });

    totals = {
      patients: totals.patients + patients,
      rescheduledSameDay: totals.rescheduledSameDay + rescheduledSameDay,
      unscheduled: totals.unscheduled + unscheduled,
    };
  }

  return {
    hygieneReappointments: data,
    percentageSameDay: totals.rescheduledSameDay / totals.patients,
    ...totals,
  };
};

const benchmarks = [
  {
    value: 0.9,
    label: 'Top Practice',
  },
];

const industryAvg = (totals: PracticeAnalyticsTypes.HygieneFollowUpTotals) => ({
  totals: {
    // Multiplier numbers are random numbers to make the percentage diff in demo data look more realistic
    hygieneFollowUpNotYetRescheduled: (totals.unscheduled || 0) * 0.9,
    hygieneFollowUpReappointmentSameDay: (totals.rescheduledSameDay || 0) * 0.75,
    hygieneFollowUpTotalVisits: (totals.patients || 0) * 0.8,
  },
});

const generateHygieneFollowUpPatientsDetails = (): PracticeAnalyticsTypes.PatientInfo[] =>
  demoDataUtils.generateRandomUserNames(15).map(({ firstName, lastName }, id) => {
    const aptType = demoDataUtils.generateRandomInt(1, 2) === 1 ? 'sameday' : 'unscheduled';
    const nextHygApt = aptType === 'sameday' ? demoDataUtils.generateRandomDate(true) : '';

    return {
      ...requiredPatientInfoFields,
      firstAppointmentDate: demoDataUtils.generateRandomDate(),
      firstAppointmentType: aptType,
      FirstName: firstName,
      hygieneAppointmentDate: demoDataUtils.generateRandomDate(),
      id: `${id}`,
      LastName: lastName,
      MobilePhone: demoDataUtils.generateRandomPhoneNumber(),
      nextAppointmentDate:
        demoDataUtils.generateRandomInt(1, 2) === 1 ? nextHygApt : demoDataUtils.generateRandomDate(true),
      nextHygieneAppointmentDate: aptType === 'sameday' ? nextHygApt : '',
      productionAmount: demoDataUtils.generateRandomInt(200, 500),
      scheduledSameDay: aptType === 'sameday',
    };
  });

const generateHygieneFollowUpData = (days: number): PracticeAnalyticsTypes.HygieneFollowUpResponse => {
  const totals = hygieneReappointments(days);
  return {
    location: {
      hygieneReappointment: {
        benchmarks,
        details: {
          patients: generateHygieneFollowUpPatientsDetails(),
        },
        totals,
      },
      industryAvg: industryAvg(totals),
    },
  };
};

export const hygieneFollowUp = () => ({
  day: generateHygieneFollowUpData(1),
  week: generateHygieneFollowUpData(7),
  month: generateHygieneFollowUpData(30),
  custom: generateHygieneFollowUpData(30),
});
