import { useTranslation } from '@frontend/i18n';
import { Button, Heading } from '@frontend/design-system';
import { DEFAULT_NEW_CONVERSATION } from '../../constants';
import { useTeamChatStore } from '../../providers';
import { containerStyles, textStyle } from './chat-title-bar.styles';

export const ChatTitleBar = () => {
  const { t } = useTranslation('team-chat');
  const { initializeNewConversation } = useTeamChatStore(['initializeNewConversation']);

  return (
    <section css={containerStyles}>
      {/* FIXME: Commenting the code so that it can be picked up later for adding chat settings */}
      {/* <NakedButton css={textContainerStyle}> */}
      <Heading css={textStyle} level={2}>
        {t('Team Chat')}
      </Heading>
      {/* <Icon name='caret-down-tiny' /> */}
      {/* </NakedButton> */}
      <Button
        iconName='draft'
        aria-label='Draft'
        onClick={() => initializeNewConversation(DEFAULT_NEW_CONVERSATION)}
        variant='secondary'
        size='large'
      />
    </section>
  );
};
