import { FC, useRef, useState } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { signupMultiForWeaveInsuranceVerification } from '@frontend/api-verify-signup';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { useMutation } from '@frontend/react-query-helpers';
import { useAlert, useModalControl } from '@frontend/design-system';
import { SCHEDULE_CALL_CHILLIPIPER_URL } from '../../../constants/self-upgrade';
import { FeatureSelfSubscribeModalProps } from '../../../types';
import { SelfUpgradeFailModal } from '../../feature-upgrade-action-modal/self-upgrade-modal/SelfUpgradeFailModal';
import { SelfUpgradeModal } from '../../feature-upgrade-action-modal/self-upgrade-modal/SelfUpgradeModal';
import { SelfUpgradeSuccessModal } from '../../feature-upgrade-action-modal/self-upgrade-modal/SelfUpgradeSuccessModal';

const WIV_PRICE = 69;
const FEATURE_ENUM = Feature.WEAVE_VERIFY;

export const InsuranceVerificationSelfSubscribeModal: FC<FeatureSelfSubscribeModalProps> = ({
  selfSubscribeEligibilityData,
  onUpgradeSuccess,
  ...modalProps
}) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'wiv-self-subscribe-modal' });
  const featureName = t('Weave Insurance Verification');

  const user = getUser();
  const alerts = useAlert();

  const [partialFailedLocationIds, setPartialFailedLocationIds] = useState<string[]>([]);
  const selectedLocationIdCountRef = useRef(0);

  const selfUpgradeSuccessModalControls = useModalControl();
  const selfUpgradeFailModalControls = useModalControl();

  const showFailedAlert = (partialFailedLocations: string[] = []) => {
    alerts.error({
      message: partialFailedLocations.length
        ? t('Upgrade failed for {{count}} location', { count: partialFailedLocations.length })
        : t('Upgrade failed'),
      action: {
        label: partialFailedLocations.length ? t('See Details') : t('Contact Support'),
        onClick: () => {
          setPartialFailedLocationIds(partialFailedLocations);
          selfUpgradeFailModalControls.openModal();
        },
      },
    });
  };

  const { mutate: subscribe, isLoading: isWeaveInsuranceSignupLoading } = useMutation({
    mutationFn: (request: { userId: string; locationIds: string[] }) =>
      signupMultiForWeaveInsuranceVerification(request),
    onSettled: modalProps.onClose,
    onSuccess: (response) => {
      const failedToUpgradeLocationIds: string[] =
        response.subscriptionResponse?.filter(({ error }) => !!error).map(({ locationId }) => locationId || '') || [];
      if (!failedToUpgradeLocationIds.length) {
        alerts.success({
          message: t('Success! You now have access to {{featureName}}', { featureName }),
        });
        selfUpgradeSuccessModalControls.openModal();
        return;
      } else if (failedToUpgradeLocationIds.length === selectedLocationIdCountRef.current) {
        showFailedAlert();
      } else {
        showFailedAlert(failedToUpgradeLocationIds);
      }
    },
    onError: () => {
      showFailedAlert();
    },
  });

  const handleUpgradeClick = (locationIds: string[]) => {
    selectedLocationIdCountRef.current = locationIds.length;
    subscribe({
      userId: user?.userID || '',
      locationIds,
    });
  };

  return (
    <>
      <SelfUpgradeModal
        featureEnum={FEATURE_ENUM}
        featureName={featureName}
        price={WIV_PRICE}
        subscriptionTerm='monthly'
        onUpgradeSuccess={onUpgradeSuccess}
        onboardingCallLink={SCHEDULE_CALL_CHILLIPIPER_URL}
        onUpgradeClick={handleUpgradeClick}
        selfSubscribeEligibilityData={selfSubscribeEligibilityData}
        isLoading={isWeaveInsuranceSignupLoading}
        {...modalProps}
      />

      <SelfUpgradeSuccessModal
        featureEnum={FEATURE_ENUM}
        featureName={featureName}
        onboardingCallUrl={SCHEDULE_CALL_CHILLIPIPER_URL}
        {...selfUpgradeSuccessModalControls.modalProps}
        onClose={() => {
          selfUpgradeSuccessModalControls.closeModal();
          onUpgradeSuccess();
        }}
      />

      <SelfUpgradeFailModal
        featureEnum={FEATURE_ENUM}
        partialFailedLocationIds={partialFailedLocationIds}
        {...selfUpgradeFailModalControls.modalProps}
        onClose={() => {
          partialFailedLocationIds.length > 0 && onUpgradeSuccess();
          selfUpgradeFailModalControls.closeModal();
        }}
      />
    </>
  );
};
