import { memo, useEffect, useRef, useState } from 'react';
import { FontWeight, Text, TextSize, styles, useTooltip } from '@frontend/design-system';

type Props = {
  size?: TextSize;
  value: string;
  weight?: FontWeight;
};

export const Name = memo(({ size, value, weight = 'bold' }: Props) => {
  const textRef = useRef<HTMLElement | null>(null);
  const [truncated, setTruncated] = useState<boolean>(false);

  const {
    Tooltip: Tooltip,
    tooltipProps,
    triggerProps,
  } = useTooltip({
    placement: 'top',
  });

  useEffect(() => {
    if (!textRef.current) return;

    const isTextTruncated = textRef.current?.scrollWidth > textRef.current?.clientWidth;
    setTruncated(isTextTruncated);
  }, [value, textRef.current]);

  return (
    <>
      <Text
        {...triggerProps}
        css={styles.truncate}
        ref={(node) => {
          textRef.current = node;
          triggerProps.ref(node);
        }}
        size={size}
        weight={weight}
      >
        {value}
      </Text>

      {truncated && (
        <Tooltip {...tooltipProps}>
          <Text size='medium' color='white'>
            {value}
          </Text>
        </Tooltip>
      )}
    </>
  );
});

Name.displayName = 'Name';
