import { FC, useMemo } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { theme } from '@frontend/theme';
import { NakedButton, Text } from '@frontend/design-system';
import { SelfUpgradeActionType } from '../../../constants/self-upgrade';
import { WivPromotionTrackingIds } from '../../../constants/tracking-ids';
import { useFeatureUpgradeDetailsModal } from '../../../hooks/useFeatureUpgradeDetailsModal';
import { useGetSelfUpgradeAction } from '../../../hooks/useGetSelfUpgradeAction';

export const WivPromotionAction: FC<{ onUpgradeSuccess: () => void; trackingPrefix: string }> = ({
  onUpgradeSuccess,
  trackingPrefix,
}) => {
  const { actionType, actionTextData, isLoading } = useGetSelfUpgradeAction();

  const { featureUpgradeDetailsModalControls, FeatureUpgradeDetailsModal } = useFeatureUpgradeDetailsModal({
    feature: Feature.WEAVE_VERIFY,
  });

  const actionButtonTrackingId = useMemo<string | undefined>(() => {
    switch (actionType) {
      case SelfUpgradeActionType.SCHEDULE_CALL:
        return WivPromotionTrackingIds.scheduleCallAction;
      case SelfUpgradeActionType.REQUEST_UPGRADE:
        return WivPromotionTrackingIds.requestUpgradeAction;
      case SelfUpgradeActionType.UPGRADE_NOW:
        return WivPromotionTrackingIds.upgradeAction;
      case SelfUpgradeActionType.FREE_TRIAL:
        return WivPromotionTrackingIds.freeTrialAction;
      default:
        return;
    }
  }, [actionType]);

  const handleActionSuccess = (result?: { actionType?: SelfUpgradeActionType }) => {
    if (
      !result?.actionType ||
      [SelfUpgradeActionType.UPGRADE_NOW, SelfUpgradeActionType.FREE_TRIAL].includes(result.actionType)
    ) {
      onUpgradeSuccess();
    }
  };

  if (isLoading) return null;

  return (
    <>
      <NakedButton
        onClick={featureUpgradeDetailsModalControls.openModal}
        trackingId={`${trackingPrefix}-${actionButtonTrackingId}`}
        css={{ height: theme.spacing(4), maxWidth: 'max-content' }}
      >
        <Text as='span' size='medium' weight='bold' color='primary'>
          {actionTextData.primaryButtonText}
        </Text>
      </NakedButton>
      {!!FeatureUpgradeDetailsModal && (
        <FeatureUpgradeDetailsModal
          modalControls={featureUpgradeDetailsModalControls}
          onActionSuccess={handleActionSuccess}
        />
      )}
    </>
  );
};
