import { FC, useMemo } from 'react';
import { FormsWritebacks } from '@frontend/api-forms';
import { useWritebackWidgetContext } from '../../../providers';
import WritebackControl from './writeback-control/writeback-control.component';

const EMPTY_WRITEBACK_STATUS_FALLBACK: FormsWritebacks.Types.WritebackStatus[] = [];

export const WritebackControls: FC = () => {
  const { submission } = useWritebackWidgetContext();

  const { writebackStatus = EMPTY_WRITEBACK_STATUS_FALLBACK } = submission;

  const writebackSettings = useMemo<FormsWritebacks.Types.WritebackStatus[]>(() => {
    return writebackStatus.sort((a, b) => {
      const x = a.settingName.toLowerCase();
      const y = b.settingName.toLowerCase();

      if (x < y) {
        return -1;
      }

      if (x > y) {
        return 1;
      }

      return 0;
    });
  }, [writebackStatus]);

  return (
    <>
      {writebackSettings.map((writebackSetting) => {
        return <WritebackControl key={writebackSetting.settingId} setting={writebackSetting} />;
      })}
    </>
  );
};
