import { useCallback } from 'react';
import { DepartmentsQueries } from '@frontend/api-departments';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { formatPhoneNumberE164 } from '@frontend/phone-numbers';
import { usePopoverMenu } from '@frontend/design-system';
import { DepartmentSelectorState } from '../components';

type UseDepartmentSelectorArgs = {
  groupId: string;
  departmentId?: string;
  alwaysShow?: boolean;
  personPhone?: string;
};

export const useDepartmentSelector = ({
  groupId,
  departmentId,
  alwaysShow = false,
  personPhone,
}: UseDepartmentSelectorArgs): DepartmentSelectorState => {
  const {
    data: { smsNumbers: departments },
  } = DepartmentsQueries.useListDefaultSMSQuery(
    {
      locationId: groupId,
    },
    {
      enabled: !!groupId,
    }
  );
  const departmentIds =
    departments?.reduce<string[]>((acc, { id }) => {
      if (id) acc.push(id);
      return acc;
    }, []) ?? [];
  const selectedDepartment = departments?.find(({ id }) => id === departmentId);

  const reduceThreadsListToDepartmentIds = useCallback((data: (typeof existingThreadsQuery)['data']) => {
    return (
      data?.pages.reduce<string[]>((acc, page) => {
        if (!!page.threads.length) acc.push(...page.threads.map(({ departmentId }) => departmentId));
        return acc;
      }, []) ?? []
    );
  }, []);

  const existingThreadsQuery = SMSDataV3.Queries.useListThreadsQuery({
    request: {
      locationId: groupId,
      groupIds: [groupId],
      threadLimit: 500,
      personPhones: personPhone ? [formatPhoneNumberE164(personPhone)] : [],
      departmentIds,
    },
    options: {
      enabled: !!personPhone && !!departments?.length,
    },
  });
  const departmentIdsWithActiveConversations = reduceThreadsListToDepartmentIds(existingThreadsQuery.data);
  const departmentIdsWithoutActiveConversations = existingThreadsQuery.data
    ? departmentIds.filter((id) => !departmentIdsWithActiveConversations.includes(id))
    : [];
  const archivedThreadsQuery = SMSDataV3.Queries.useListThreadsQuery({
    request: {
      locationId: groupId,
      groupIds: [groupId],
      threadLimit: 500,
      personPhones: personPhone ? [formatPhoneNumberE164(personPhone)] : [],
      departmentIds: departmentIdsWithoutActiveConversations,
      isArchived: true,
    },
    options: {
      enabled: !!personPhone && !!departmentIdsWithoutActiveConversations.length,
    },
  });
  const departmentIdsWithArchivedConversations = departmentIdsWithoutActiveConversations.length
    ? reduceThreadsListToDepartmentIds(archivedThreadsQuery.data)
    : [];
  const departmentIdsWithoutConversations = departmentIdsWithoutActiveConversations.filter(
    (id) => !departmentIdsWithArchivedConversations?.includes(id) && !departmentIdsWithActiveConversations.includes(id)
  );
  const showButton = alwaysShow || ((departments?.length ?? 0) > 1 && !!departmentId);

  const popoverState = usePopoverMenu({
    placement: 'bottom',
  });

  return {
    showButton,
    selectedDepartment,
    popoverState,
    personPhone,
    departments,
    departmentIdsWithArchivedConversations,
    departmentIdsWithActiveConversations,
    departmentIdsWithoutConversations,
  };
};
