import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  flex-basis: 40%;

  .drag-handle {
    cursor: move;
  }
`;

export const textFieldContainerStyle = css`
  flex: 1;
`;

export const textFieldStyle = css`
  width: 100%;
`;
