import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const emptyStateStyle = css`
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  gap: ${theme.spacing(3)};
  width: 250px;
  text-align: center;
`;
