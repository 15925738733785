import { FC } from 'react';
import { useTranslation } from '@frontend/i18n';
import {
  SwitchField,
  useTooltip,
  BaseFieldProps,
  Modal,
  Heading,
  Text,
  SecondaryButton,
  PrimaryButton,
  ModalControlResponse,
} from '@frontend/design-system';
import { LibraryListType } from '../../../library.types';
import { reviewRequiredContainerStyle, switchFieldStyle, reviewConfirmationModalStyle } from './review-required.styles';

interface ReviewRequiredProps {
  type: LibraryListType;
  switchFieldProps: BaseFieldProps<boolean, '', HTMLInputElement>;
  modalControls: ModalControlResponse;
  onConfirmDisableReview: () => void;
  shouldShowSwitch?: boolean;
  toggleReviewRequiredTrackingId: string;
  confirmationModalCancelTrackingId: string;
  confirmationModalConfirmTrackingId: string;
}

/**
 * @deprecated
 */
const ReviewRequired: FC<ReviewRequiredProps> = ({
  type,
  switchFieldProps,
  modalControls,
  onConfirmDisableReview,
  shouldShowSwitch = true,
  toggleReviewRequiredTrackingId,
  confirmationModalCancelTrackingId,
  confirmationModalConfirmTrackingId,
}) => {
  const { Tooltip, triggerProps, tooltipProps } = useTooltip({
    hoverDelay: 0,
    placement: 'bottom',
  });
  const { t } = useTranslation('forms', { keyPrefix: 'FORMS_PROVIDER_REVIEW' });

  const tooltipContent = type === LibraryListType.PACKETS ? t('TOOLTIP_PACKET') : t('TOOLTIP_FORM');

  const modalContent =
    type === LibraryListType.PACKETS
      ? {
          description: t('CONFIRMATION_MESSAGE_PACKET'),
          notes: {
            line1: t('MESSAGE_NOTE_LINE1_PACKET'),
            line2: t('MESSAGE_NOTE_LINE2_PACKET'),
          },
        }
      : {
          description: t('CONFIRMATION_MESSAGE_FORM'),
          notes: {
            line1: t('MESSAGE_NOTE_LINE1_FORM'),
            line2: t('MESSAGE_NOTE_LINE2_FORM'),
          },
        };

  return (
    <>
      {shouldShowSwitch && (
        <span {...triggerProps} css={reviewRequiredContainerStyle}>
          <SwitchField
            {...switchFieldProps}
            name='review-required'
            label={t('NEEDS_REVIEW')}
            labelPlacement='left'
            css={switchFieldStyle}
            trackingId={toggleReviewRequiredTrackingId}
          />
        </span>
      )}

      <Tooltip {...tooltipProps}>{tooltipContent}</Tooltip>

      <Modal {...modalControls.modalProps} css={reviewConfirmationModalStyle} minWidth={600}>
        <Heading level={2}>{t('MODAL_HEADER_TEXT')}</Heading>
        <Text>{modalContent.description}</Text>
        <ul>
          <li>{modalContent.notes.line1}</li>
          <li>{modalContent.notes.line2}</li>
        </ul>
        <Text>{t('MODAL_CONFIRMATION_TEXT')}</Text>
        <div className='action-btn'>
          <SecondaryButton
            onClick={modalControls.closeModal}
            size='tiny'
            trackingId={confirmationModalCancelTrackingId}
          >
            {t('CANCEL')}
          </SecondaryButton>
          <PrimaryButton onClick={onConfirmDisableReview} size='tiny' trackingId={confirmationModalConfirmTrackingId}>
            {t('DISABLE')}
          </PrimaryButton>
        </div>
      </Modal>
    </>
  );
};

export default ReviewRequired;
