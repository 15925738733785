import { SchemaCallGroupService } from '@frontend/schema';
import {
  CreateCallGroupTypes,
  ReadCallGroupTypes,
  ListCallGroupTypes,
  UpdateCallGroupTypes,
  AssignDeviceCallGroupTypes,
  UpdateDeviceCallGroupTypes,
  RemoveDevicesCallGroupTypes,
  DeleteCallGroupTypes,
  UsageCallGroupTypes,
} from './types';

export const listCallGroups = (req: ListCallGroupTypes['input']): Promise<ListCallGroupTypes['output']> => {
  return SchemaCallGroupService.List(req);
};

export const readCallGroup = (req: ReadCallGroupTypes['input']): Promise<ReadCallGroupTypes['output']> => {
  return SchemaCallGroupService.Read(req);
};

export const createCallGroup = (req: CreateCallGroupTypes['input']): Promise<CreateCallGroupTypes['output']> => {
  return SchemaCallGroupService.Create(req);
};

export const updateCallGroup = (req: UpdateCallGroupTypes['input']): Promise<UpdateCallGroupTypes['output']> => {
  return SchemaCallGroupService.Update(req);
};

export const assignDeviceToCallGroup = (
  req: AssignDeviceCallGroupTypes['input']
): Promise<AssignDeviceCallGroupTypes['output']> => {
  return SchemaCallGroupService.AssignDevice(req);
};

export const updateDeviceInCallGroup = (
  req: UpdateDeviceCallGroupTypes['input']
): Promise<UpdateDeviceCallGroupTypes['output']> => {
  return SchemaCallGroupService.UpdateDevice(req);
};

export const removeDevicesFromCallGroup = (
  req: RemoveDevicesCallGroupTypes['input']
): Promise<RemoveDevicesCallGroupTypes['output']> => {
  return SchemaCallGroupService.RemoveDevice(req);
};

export const deleteCallGroup = (req: DeleteCallGroupTypes['input']): Promise<DeleteCallGroupTypes['output']> => {
  return SchemaCallGroupService.Delete(req);
};

export const getCallGroupUsage = (req: UsageCallGroupTypes['input']): Promise<UsageCallGroupTypes['output']> => {
  return SchemaCallGroupService.Usage(req);
};
