import { Dispatch, SetStateAction, useMemo } from 'react';
import { Device, DeviceType } from '@weave/schema-gen-ts/dist/schemas/phone/user/v1/user_service.pb';
import { createContext, useContextSelector } from 'use-context-selector';
import { useQueryDevices } from '@frontend/api-phone-sync';
import { OutboundSelectedPhoneNumberProps, useOutboundCallerIdNumbers } from '@frontend/generic-dialpad-accessories';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import { useAppScopeStore } from '@frontend/scope';
import { DialpadConnectProvider } from './dialpad-connection-provider';
import { DialpadDialerProvider } from './dialpad-dialer-provider';
import { DialpadWidgetControlProvider } from './dialpad-widget-control-provider';

type DialpadManagerContextValue = {
  canShowDialpad: boolean;
  connectedDeskphone: Device[] | undefined;
  deviceName: string;
  extensionNumber: number | undefined;
  allDevices: Device[] | undefined;
  availableDevices: Device[] | undefined;
  hasAvailableDeskphones: boolean;
  isDeskphoneConnected: boolean;
  isSoftphoneDeviceConnected: boolean;
  availablePhoneNumbers: OutboundSelectedPhoneNumberProps[];
  setCurrentOutboundNumber: Dispatch<SetStateAction<OutboundSelectedPhoneNumberProps | undefined>>;
  currentOutboundPhoneNumber: OutboundSelectedPhoneNumberProps | undefined;
};

const DialpadManagerContext = createContext({} as DialpadManagerContextValue);

type DialpadManagerProps = {
  children: React.ReactNode;
};

export const DialpadManagerProvider = ({ children }: DialpadManagerProps) => {
  const { selectedLocationIds } = useAppScopeStore();
  const { phoneConfig } = usePhoneConfigShallowStore('phoneConfig');
  const phoneConfigTenantId = phoneConfig?.tenantId;
  const { data: devices } = useQueryDevices(selectedLocationIds);

  const connectedDeskphoneDevice = useMemo(() => {
    return devices?.filter(
      (device) =>
        device.sipProfileId === phoneConfig?.sipProfileId && device.deviceType === DeviceType.DEVICE_TYPE_DESK_PHONE
    );
  }, [devices, phoneConfig?.sipProfileId]);

  const isSoftphoneDeviceConnected = useMemo(() => {
    const softphoneDevices = devices?.filter(
      (device) =>
        device.sipProfileId === phoneConfig?.sipProfileId && device.deviceType === DeviceType.DEVICE_TYPE_SOFTPHONE
    );
    return softphoneDevices ? softphoneDevices.length > 0 : false;
  }, [devices, phoneConfig?.sipProfileId]);

  const availableDevices = devices?.filter((device) => device.availableInContext);

  const availableDeskphones =
    availableDevices?.filter((device) => device.deviceType === DeviceType.DEVICE_TYPE_DESK_PHONE) ?? [];

  const { numbers, selectedPhoneNumber, setSelectedPhoneNumber } = useOutboundCallerIdNumbers({
    phoneConfigTenantId: phoneConfigTenantId ?? '',
  });

  const value = useMemo(
    () => ({
      canShowDialpad: !!selectedLocationIds,
      connectedDeskphone: connectedDeskphoneDevice,
      deviceName: connectedDeskphoneDevice?.[0]?.name ?? '',
      extensionNumber: connectedDeskphoneDevice?.[0]?.extension,
      allDevices: devices,
      availableDevices: availableDevices,
      hasAvailableDeskphones: availableDeskphones?.length > 0,
      isDeskphoneConnected: connectedDeskphoneDevice ? connectedDeskphoneDevice.length > 0 : false,
      isSoftphoneDeviceConnected,
      availablePhoneNumbers: numbers ?? [],
      setCurrentOutboundNumber: setSelectedPhoneNumber,
      currentOutboundPhoneNumber: selectedPhoneNumber,
    }),
    [
      selectedLocationIds,
      connectedDeskphoneDevice,
      devices,
      availableDevices,
      availableDeskphones,
      isSoftphoneDeviceConnected,
      numbers,
      selectedPhoneNumber,
    ]
  );

  return (
    <DialpadManagerContext.Provider value={value}>
      <DialpadDialerProvider>
        <DialpadWidgetControlProvider>
          <DialpadConnectProvider>{children}</DialpadConnectProvider>
        </DialpadWidgetControlProvider>
      </DialpadDialerProvider>
    </DialpadManagerContext.Provider>
  );
};

export const useDialpadManagerClient = <T extends any>(selector: (value: DialpadManagerContextValue) => T) => {
  return useContextSelector(DialpadManagerContext, selector);
};
