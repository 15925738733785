import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Accordion, NotificationBadge, Text } from '@frontend/design-system';
import { LocationSelector } from './LocationSelector';

type MultiLocationSelectorProps = {
  locationIds: string[];
  setLocationIds: (locationIds: string[]) => void;
  defaultFilteredLocationIds: string[];
};

export const MultiLocationSelector = ({
  locationIds,
  setLocationIds,
  defaultFilteredLocationIds,
}: MultiLocationSelectorProps) => {
  const { t } = useTranslation('scheduleCalendarFilters');
  return (
    <Accordion size='large' showBoxShadow chevronSize={16}>
      <Accordion.Item value={'locations'} css={{ border: `1px solid ${theme.colors.neutral10}` }}>
        <Accordion.Header css={{ border: `1px solid ${theme.colors.neutral10}` }}>
          <Text size='medium' weight='bold' textAlign='left'>
            {t('Location')}
          </Text>
          <div css={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
            <NotificationBadge css={{ backgroundColor: theme.colors.primary50, marginRight: theme.spacing(0.5) }}>
              {locationIds.length}
            </NotificationBadge>
            <Text>{t('of {{count}}', { count: defaultFilteredLocationIds.length })}</Text>
          </div>
        </Accordion.Header>
        <Accordion.Body css={{ border: `1px solid ${theme.colors.neutral10}`, width: '100%' }}>
          {!locationIds?.length && (
            <Text css={{ marginBottom: theme.spacing(2) }} color='error' size='small'>
              {t('Required at least one location')}
            </Text>
          )}
          <Text size='small' color='subdued' css={{ marginBottom: theme.spacing(2) }}>
            {t('View up to 4 locations at once')}
          </Text>
          <LocationSelector locationIds={locationIds} updateLocationIds={setLocationIds} />
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};
