import { Dispatch, SetStateAction } from 'react';
import { ScheduledSmsThread } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { ThreadDraft } from '@weave/schema-gen-ts/dist/schemas/sms/draft/v1/draft_service.pb';
import { MessagesHooks, MessagesTypes, SchemaSMSSharedModels } from '@frontend/api-messaging';
import { ActionableListRow } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { InboxType, useInboxNavigate } from '@frontend/inbox-navigation';
import { convertStringToMessagePopupThreadStatus } from '@frontend/popup-bar';
import { useAppScopeStore } from '@frontend/scope';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { ComponentProps } from '@frontend/types';
import { theme } from '@frontend/theme';
import { useListItemActions, useThreadStatus, useUnifiedListItem } from '../../../hooks';
import { ExtendedDepartment } from '../../../types';
import { MessageStatus, MessageStatusIndicator } from '../../message-status-indicator';
import { InboxListItemContent, InboxListItemLead, InboxListItemTitle } from './components';
import { actionableListRowStyles } from './styles';
import { getUserFriendlyStatus } from './utils';

const leadingIcons = [MessageStatus.UNREAD, MessageStatus.UNREPLIED];
const getTrackingId = (inboxType: InboxType) => {
  switch (inboxType) {
    case InboxType.SCHEDULED:
      return `${InboxPrefixes.List}-scheduled-inbox-item`;
    case InboxType.DRAFTS:
      return `${InboxPrefixes.DraftList}-inbox-item`;
    default:
      return `${InboxPrefixes.List}-inbox-item`;
  }
};

type UnifiedListItemProps = {
  inboxType: InboxType;
  thread: ScheduledSmsThread | ThreadDraft | MessagesTypes.InboxListItem;
  isBulkSelect: boolean;
  isSelectAll: boolean;
  selectedThreads: Record<string, string[]>;
  setSelectedThreads: Dispatch<SetStateAction<Record<string, string[]>>>;
  departments: ExtendedDepartment[];
  scheduledThreadsMap?: Record<string, ScheduledSmsThread[]>;
  drafts?: ThreadDraft[];
  personObj?: SchemaSMSSharedModels.Person;
  body: string;
  timestamp: string;
  iconButtonOverride?: ComponentProps<typeof ActionableListRow>['iconButtonProps'];
};
export const UnifiedListItem = ({
  inboxType,
  thread,
  isBulkSelect,
  isSelectAll,
  selectedThreads,
  setSelectedThreads,
  departments,
  scheduledThreadsMap,
  drafts,
  personObj,
  body,
  timestamp,
  iconButtonOverride,
}: UnifiedListItemProps) => {
  const { t } = useTranslation('inbox');
  const { selectedLocationIds } = useAppScopeStore();
  const { closeThread } = useInboxNavigate();
  const invalidateInboxList = MessagesHooks.useInvalidateInboxList();
  const { locationId, personPhone } = thread;

  const { status, isRead, isNew, isBlocked, hasError, isBlockedQuery } = useThreadStatus({
    thread,
    drafts,
    inboxType,
    scheduledThreadsMap,
  });

  const {
    isSelected,
    department,
    modifyUnreadCount,
    chips,
    person,
    title,
    checkboxState,
    messageBodyPreviewText,
    messageStatusAlert,
    toggleActions,
    goToThread,
  } = useUnifiedListItem({
    thread,
    departments,
    personObj,
    isSelectAll,
    selectedThreadsState: [selectedThreads, setSelectedThreads],
    isBlocked,
    body,
    status,
    isRead,
    hasError,
    inboxType,
  });
  const { showDepartmentChip, showLocationChip, shouldRenderChipsRow } = chips;
  const [isCheckboxSelected, setCheckboxSelected] = checkboxState;
  const { onToggleRead, onToggleArchived } = toggleActions;

  const actions = useListItemActions({
    inboxType,
    isNew,
    hasError,
    isBlocked,
    locationId,
    personPhone,
    popOutConfig: {
      id: thread.threadId,
      name: title,
      type: 'message',
      meta: {
        threadId: thread.threadId,
        groupId: locationId,
        departmentId: department?.id,
        personPhone,
        personId: person?.personId,
        status: convertStringToMessagePopupThreadStatus(status ?? 'read'),
      },
      shouldCloseThread: isSelected,
    },
    onBlockToggleSuccess: (newVal) => {
      if (inboxType === InboxType.SCHEDULED) {
        isBlockedQuery.refetch();
      } else {
        if (!isRead && !hasError) {
          modifyUnreadCount(newVal ? 'decrement' : 'increment', selectedLocationIds);
        }
        if (newVal) {
          closeThread();
        }
        invalidateInboxList();
      }
    },
    onToggleRead,
  });

  const iconButtonProps: ComponentProps<typeof ActionableListRow>['iconButtonProps'] = iconButtonOverride
    ? iconButtonOverride
    : {
        label: inboxType === InboxType.ARCHIVED ? t('Unarchive') : t('Archive'),
        Icon: () => (
          <Icon
            name={inboxType === InboxType.ARCHIVED ? 'unarchive' : 'archive'}
            css={{ color: theme.colors.neutral70 }}
          />
        ),
        onClick: onToggleArchived,
        trackingId: `${InboxPrefixes.List}-archive-button`,
        showLabelOnHover: true,
        size: 'normal',
      };

  return (
    <ActionableListRow
      css={[
        actionableListRowStyles,
        isSelected && {
          '.inbox-item-chip': {
            backgroundColor: theme.colors.white,
          },
        },
      ]}
      Lead={
        <InboxListItemLead
          isBulkSelect={isBulkSelect}
          locationId={thread.locationId}
          firstName={person?.firstName}
          lastName={person?.lastName}
          personId={person?.personId}
          isCheckboxSelected={isCheckboxSelected}
          setCheckboxSelected={setCheckboxSelected}
        />
      }
      actions={inboxType !== InboxType.DRAFTS ? actions : undefined}
      Title={
        <InboxListItemTitle
          text={title}
          isBold={isNew}
          leadingIcon={leadingIcons.includes(status) && <MessageStatusIndicator status={status} />}
          trailingIcon={
            !leadingIcons.includes(status) && (
              <MessageStatusIndicator
                status={status}
                inline
                multiSchedule={
                  status === MessageStatus.MULTIPLE_SCHEDULED ? scheduledThreadsMap?.[thread.threadId] : undefined
                }
              />
            )
          }
          hoverText={getUserFriendlyStatus(status)}
        />
      }
      timestampOverride={inboxType !== InboxType.DRAFTS ? messageStatusAlert?.text : undefined}
      timestampOverrideProps={
        inboxType !== InboxType.DRAFTS && messageStatusAlert ? { ...messageStatusAlert } : undefined
      }
      iconButtonProps={iconButtonProps}
      Content={
        <InboxListItemContent
          locationId={locationId}
          threadNavData={{
            personPhone: thread.personPhone,
            threadId: thread.threadId,
            departmentId: thread.departmentId,
            personId: person?.personId,
          }}
          shouldRenderChipsRow={shouldRenderChipsRow}
          showLocationChip={showLocationChip}
          showDepartmentChip={showDepartmentChip}
          departmentName={department?.name}
          isBlocked={isBlocked}
          messageBodyPreviewText={messageBodyPreviewText}
          uniqueTags={'uniqueTags' in thread ? thread.uniqueTags : undefined}
        />
      }
      timestamp={timestamp}
      as='div'
      tabIndex={0}
      key={thread.threadId}
      id={thread.threadId}
      onClick={goToThread}
      isSelected={isSelected}
      data-trackingid={getTrackingId(inboxType)}
    />
  );
};
