import { WritableDraft } from 'immer/dist/internal';
import { createStoreWithSubscribe, createShallowStore } from '@frontend/store';

export type SlidePanelType =
  | 'callTakeaway'
  | 'contact'
  | 'contactForm'
  | 'customContactForm'
  | 'faxContact'
  | 'faxContactForm'
  | 'multipleContacts'
  | 'voicemailTranscription'
  | 'manageDevices';

export type SlidePanelMode = 'modal' | 'panel' | 'auto';
export interface SlidePanelStore<T = any> {
  show: boolean;
  mode: SlidePanelMode;
  panelType: SlidePanelType;
  setShow: (show: boolean, panelType?: SlidePanelType, context?: T) => void;
  setMode: (mode: SlidePanelMode) => void;
  context: T | undefined;
}

export const useSlidePanelStore = createStoreWithSubscribe<SlidePanelStore>(
  (set) => ({
    show: false,
    mode: 'auto',
    context: undefined,
    panelType: 'contact',
    setShow: (show, panelType = 'contact', context) => {
      set(
        (state) => {
          state.show = show;
          state.context = context;
        },
        false,
        'SET_SHOW_SLIDE_PANEL'
      );

      set(
        (state) => {
          state.panelType = panelType;
        },
        false,
        'SET_SLIDE_PANEL_TYPE'
      );
    },
    hideSlidePanel: () => {
      set(
        (state) => {
          state.show = false;
        },
        false,
        'HIDE_SLIDE_PANEL'
      );
    },
    setMode: (mode: SlidePanelMode) => {
      set(
        (state) => {
          state.mode = mode;
        },
        false,
        'SET_SLIDE_PANEL_MODE'
      );
    },
  }),
  { name: 'SlidePanelStore', trace: true }
);

export const useSlidePanelShallowStore = <T>(...properties: (keyof SlidePanelStore<T>)[]) =>
  createShallowStore<WritableDraft<SlidePanelStore<T>>>(useSlidePanelStore)(...properties);
