import { useState } from 'react';
import { css } from '@emotion/react';
import { FaxHooks } from '@frontend/api-fax';
import { useTranslation } from '@frontend/i18n';
import { SchemaFaxService } from '@frontend/schema';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import {
  ButtonBar,
  ContentLoader,
  Heading,
  IconButton,
  Modal,
  ModalControlModalProps,
  PrimaryButton,
  SecondaryButton,
  Text,
  XIcon,
  useAlert,
} from '@frontend/design-system';

type DeleteProps = {
  modalProps: ModalControlModalProps;
  contactIds: string[];
  locationId?: string;
};

export const DeleteContact = ({ modalProps, contactIds, locationId }: DeleteProps) => {
  const { t } = useTranslation('fax');
  const alert = useAlert();
  const invalidateFaxInboxList = FaxHooks.useInvalidateFaxInboxList();
  const [isLoading, setIsLoading] = useState(false);
  const { setShow } = useSlidePanelShallowStore('setShow');

  const deleteContact = async (contactIds: string[]) => {
    setIsLoading(true);
    try {
      await SchemaFaxService.DeleteContacts({
        contactIds,
        locationId,
      });
      setIsLoading(false);
      alert.success(t('Contact Deleted'));
      invalidateFaxInboxList();
      setShow(false);
      modalProps.onClose();
    } catch (error) {
      setIsLoading(false);
      alert.error(t('Failed to Delete Contact'));
      modalProps.onClose();
    }
  };

  return (
    <>
      {isLoading ? (
        <ContentLoader message={t('Deleting Contact')} show />
      ) : (
        <Modal
          css={css`
            width: 480px !important;
          `}
          {...modalProps}
          maxWidth={480}
        >
          <Modal.Body>
            <div
              css={css`
                display: flex;
                justify-content: space-between;
                margin-bottom: ${theme.spacing(2)};
              `}
            >
              <Heading level={2}>{t('Delete Contact')}</Heading>
              <IconButton label='close' onClick={() => modalProps.onClose()}>
                <XIcon />
              </IconButton>
            </div>

            <Text
              css={css`
                margin-bottom: ${theme.spacing(2)};
              `}
            >
              {t('Are you sure you want to delete this contact?')}
            </Text>
          </Modal.Body>
          <ButtonBar>
            <SecondaryButton
              css={css`
                width: 100px;
              `}
              size='large'
              onClick={modalProps.onClose}
            >
              {t('Cancel')}
            </SecondaryButton>
            <PrimaryButton
              css={css`
                border: 1px solid ${theme.colors.critical50};
                background-color: ${theme.colors.critical50};
                width: 100px;
              `}
              size='large'
              onClick={() => deleteContact(contactIds)}
            >
              {t('Delete')}
            </PrimaryButton>
          </ButtonBar>
        </Modal>
      )}
    </>
  );
};
