import { useCallback, useEffect, useRef, useState } from 'react';
import { getUser } from '@frontend/auth-helpers';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';

type Options = {
  downloadReferenceUrl: string;
  downloadUrl: string;
  fileExtension?: string;
  fileName: string;
  locationId: string;
  refreshInterval?: number;
};

export type WaitForDownload = {
  downloadKey: string;
  isDownloadReady?: boolean;
  options: Options;
  selectedLocationIds: string[];
};

export type WaitStateProps = Options & { isWaiting: boolean };

export type DownloadWaitState = Record<string, WaitStateProps>;

export type UseLargeDownloads = {
  downloadsWaitState: DownloadWaitState;
  waitForDownload: (args: WaitForDownload) => void;
};

const DEFAULT_REFRESH_INTERVAL = 10000;

// Use locationID and userID in the storage key to tag downloads for the logged in user and selected location(s)
const getStorageKey = (selectedLocationIds: string[], userID?: string) => {
  return `large-downloads-${selectedLocationIds.sort().join('-')}-${userID}`;
};

export const useLargeDownloads = (): UseLargeDownloads => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const user = getUser();
  const { selectedLocationIds } = useAppScopeStore();
  const refreshTimeouts = useRef<Record<string, any>>({});
  const [downloadsWaitState, setDownloadsWaitState] = useState<DownloadWaitState>({});

  const getPreviousState = useCallback((): DownloadWaitState => {
    const prevState = localStorage.getItem(getStorageKey(selectedLocationIds, user?.userID));
    return typeof prevState === 'string' ? JSON.parse(window.atob(prevState)) : {};
  }, [selectedLocationIds, user?.userID]);

  const resetLocalStorage = useCallback(
    (key: string) => {
      const storedDataObject = getPreviousState();
      if (storedDataObject[key]) {
        delete storedDataObject[key];
        localStorage.setItem(
          getStorageKey(selectedLocationIds, user?.userID),
          window.btoa(JSON.stringify(storedDataObject))
        );
      }
    },
    [selectedLocationIds, user?.userID]
  );

  const saveToDevice = (blob: Blob, options: Options) => {
    // Create a temporary link to download the file
    const downloadLink = document.createElement('a');
    downloadLink.download = `${options.fileName}.${options.fileExtension || blob.type.split('/')[1]}`;
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.style.opacity = '0';
    downloadLink.style.position = 'absolute';
    downloadLink.style.zIndex = '-1';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    alert.success(t('File prepared. Download will start shortly.'));
  };

  const invokeDownload = async (options: Options, callbacks?: { onSuccess?: () => void; onError?: () => void }) => {
    try {
      const response = await http.get<Blob>(options.downloadUrl, {
        headers: {
          'Location-Id': options.locationId,
        },
        responseType: 'blob',
      });
      if (response === null) {
        alert.warning(t('No data available to download'));
      } else {
        saveToDevice(response, options);
      }
      callbacks?.onSuccess?.();
    } catch (error) {
      alert.error(t('Unable to download the file. Please try again.'));
      callbacks?.onError?.();
    }
  };

  const waitForDownload = useCallback(
    ({ downloadKey, isDownloadReady, options, selectedLocationIds }: WaitForDownload) => {
      if (isDownloadReady) {
        invokeDownload(options, {
          onSuccess: () => resetLocalStorage(downloadKey),
          onError: () => resetLocalStorage(downloadKey),
        });
      } else {
        const { refreshInterval, ...rest } = options;
        const currStorageObject: DownloadWaitState = {
          ...getPreviousState(),
          [downloadKey]: {
            ...rest,
            isWaiting: !isDownloadReady,
            refreshInterval: refreshInterval || DEFAULT_REFRESH_INTERVAL,
          },
        };
        setDownloadsWaitState(currStorageObject);
        localStorage.setItem(
          getStorageKey(selectedLocationIds, user?.userID),
          window.btoa(JSON.stringify(currStorageObject))
        );
      }
    },
    []
  );

  const clearRefreshInterval = (key: string) => {
    if (refreshTimeouts.current[key]) {
      clearTimeout(refreshTimeouts.current[key]);
      delete refreshTimeouts.current[key];
    }
  };

  const resetRefreshIntervals = () => {
    Object.keys(refreshTimeouts.current).forEach((key) => {
      clearRefreshInterval(key);
    });
  };

  const invokeRefreshTimeout = (key: string) => {
    clearRefreshInterval(key);

    refreshTimeouts.current = {
      ...refreshTimeouts.current,
      [key]: setTimeout(() => validateDownloadReadiness(key), downloadsWaitState[key].refreshInterval),
    };
  };

  const validateDownloadReadiness = async (key: string) => {
    // Check the status of download readiness, recall invokeRefreshTimeout if download is not ready
    try {
      const { data = [] } = await http.get<{ data: { download: boolean; id: string }[] }>(
        downloadsWaitState[key].downloadReferenceUrl
      );

      const { download: isDownloadReady, id: mediaID } = data[0] || {};

      if (isDownloadReady) {
        const updatedState = {
          ...downloadsWaitState[key],
          downloadUrl: downloadsWaitState[key].downloadUrl.replace('<mediaID>', mediaID),
          isWaiting: false,
        };

        setDownloadsWaitState({
          ...downloadsWaitState,
          [key]: updatedState,
        });

        invokeDownload(updatedState, {
          onSuccess: () => resetLocalStorage(key),
          onError: () => resetLocalStorage(key),
        });
      } else {
        invokeRefreshTimeout(key);
      }
    } catch (error) {
      alert.error(t('Unable to verify the file download readiness state'));
      resetLocalStorage(key);
    }
  };

  // Run intervals to check the download readiness state
  useEffect(() => {
    Object.entries(downloadsWaitState).forEach(([key, value]) => {
      if (value.isWaiting) {
        invokeRefreshTimeout(key);
      } else if (typeof refreshTimeouts.current[key] !== 'undefined') {
        clearRefreshInterval(key);
      }
    });
  }, [downloadsWaitState]);

  useEffect(() => {
    // Check for the existing downloads on page restart/reload, this data will come form the local storage
    setDownloadsWaitState(getPreviousState());
  }, [selectedLocationIds]);

  useEffect(() => {
    return () => {
      // Cleanup on unmount
      resetRefreshIntervals();
    };
  }, []);

  return { downloadsWaitState, waitForDownload };
};
