import { i18next, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  ChecklistField,
  Modal,
  ModalControlModalProps,
  Text,
  TextareaField,
  useFormField,
} from '@frontend/design-system';
import { NegativeFeedbackPayload } from './feedback.component';

type FeedbackOptions =
  | 'Poor sound quality'
  | 'No sound'
  | 'Call dropped'
  | 'Slow response, app freezing'
  | 'Other (explain below)';

const options: Record<string, FeedbackOptions> = {
  POOR_SOUND_QUALITY: i18next.t('Poor sound quality', { ns: 'phone' }),
  NO_SOUND: i18next.t('No sound', { ns: 'phone' }),
  CALL_DROPPED: i18next.t('Call dropped', { ns: 'phone' }),
  SLOW_RESPONSE: i18next.t('Slow response, app freezing', { ns: 'phone' }),
  OTHER: i18next.t('Other (explain below)', { ns: 'phone' }),
};

type Props = ModalControlModalProps & {
  handleNegativeFeedback: ({ feedbackValues, message }: NegativeFeedbackPayload) => void;
};

export const FeedbackModal = ({ handleNegativeFeedback, ...modalProps }: Props) => {
  const { t } = useTranslation('phone');

  const feedbackFieldProps = useFormField({
    type: 'checklist',
    value: [''],
  });

  const messageFieldProps = useFormField({
    type: 'text',
  });

  const onSubmit = () => {
    handleNegativeFeedback({
      feedbackValues: feedbackFieldProps.value.filter((value) => value !== ''),
      message: messageFieldProps.value,
    });
  };

  return (
    <Modal {...modalProps} maxWidth={600} minWidth={600} mountTarget='#root'>
      <Modal.Header onClose={modalProps.onClose}>{t('Provide Additional Feedback')}</Modal.Header>
      <Modal.Body>
        <Text>{t('Please tell us what went wrong.')}</Text>
        <ChecklistField {...feedbackFieldProps} label='' name='softphone-feedback'>
          {Object.values(options).map((option) => (
            <ChecklistField.Option key={option} name={option}>
              {option}
            </ChecklistField.Option>
          ))}
        </ChecklistField>
        <div css={{ padding: theme.spacing(1.5, 0) }}>
          <TextareaField label={t('Feedback (Optional)')} name='softphone-call-feedback' {...messageFieldProps} />
        </div>
      </Modal.Body>
      <Modal.Actions disablePrimary={!feedbackFieldProps.value} primaryLabel={t('Submit')} onPrimaryClick={onSubmit} />
    </Modal>
  );
};
