import {
  createContext,
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ProviderReview, FormsSubmission, FormsWritebacks, FormsMediaManager } from '@frontend/api-forms';
import { PersonHelpers } from '@frontend/api-person';
import { getUser } from '@frontend/auth-helpers';
import { DigitalFormScopeHooks } from '@frontend/digital-forms-scope';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { useAlert, genUID } from '@frontend/design-system';
import { useWritebackRetry } from '../../shared/hooks';
import { useApproveSubmission, useRejectSubmission } from '../../shared/hooks/review-submission';
import useWritebackConfig, { UseWritebackConfigResults } from './writeback-config/useWritebackConfig';

export type { WritebackSettingsToggleActions } from './writeback-config/writeback-config.reducer';

interface ReviewSubmissionProviderProps {
  submission: FormsSubmission.Types.NormalizedSubmissionDetailResponse;
  submissionId: string;
  hasPMSIntegration: boolean;
  isWritebackCapableSubmission: boolean;
}

type ApproveAndSyncResult = 'failed' | 'success' | 'partial';

interface ReviewSubmissionProviderContext extends UseWritebackConfigResults, ReviewSubmissionProviderProps {
  previousSubmissionId: string;
  rejectionNote: string;
  acceptanceNote: string;
  isRetryingWriteback: boolean;
  isMarkingAsRejected: boolean;
  isMarkingAsApproved: boolean;
  isSignatureDefault: MutableRefObject<boolean>;
  setSignature: Dispatch<SetStateAction<ProviderReview.Types.ProviderSignatureResponse | null>>;
  storeSignature: (signature: ProviderReview.Types.RawProviderSignature, isDefault: boolean) => void;
  setRejectionNote: Dispatch<SetStateAction<string>>;
  setAcceptanceNote: Dispatch<SetStateAction<string>>;
  rejectSubmission: () => Promise<any>;
  triggerWritebacks: () => Promise<any>;
  approveAndSync: (userName?: string) => Promise<ApproveAndSyncResult>;
  hasAtLeastOneWritebackSettingSelected: () => boolean;
  isSyncingSubmission: (submissionId: string) => boolean;
}

const { useFormsACL } = DigitalFormScopeHooks;
const ReviewSubmissionContext = createContext<ReviewSubmissionProviderContext | undefined>(undefined);

/**
 * @deprecated
 */
export const ReviewSubmissionProvider: FC<React.PropsWithChildren<ReviewSubmissionProviderProps>> = ({
  children,
  ...otherProps
}) => {
  const { submissionId, hasPMSIntegration, isWritebackCapableSubmission } = otherProps;

  const alert = useAlert();
  const [rejectionNote, setRejectionNote] = useState<string>('');
  const [acceptanceNote, setAcceptanceNote] = useState<string>('');
  const [signature, setSignature] = useState<ProviderReview.Types.ProviderSignatureResponse | null>(null);
  const isSignatureDefault = useRef(false);
  const [previousSubmissionId, setPreviousSubmissionId] = useState('');
  const [submissionsUnderSync, setSubmissionsUnderSync] = useState<string[]>([]);

  const writebackConfigProps = useWritebackConfig();
  const { writebackSyncMode, writebackSettingsConfig, sourceId } = writebackConfigProps;

  const { rejectSubmission, isMarkingAsRejected } = useRejectSubmission();
  const { approveSubmission, isMarkingAsApproved } = useApproveSubmission();
  const { isRetryingWriteback, retryWriteback } = useWritebackRetry();
  const { locationId } = useLocationDataShallowStore('locationId');
  const { hasPermissionToManageProviders } = useFormsACL({ locationId });

  useEffect(() => {
    // reset the context
    setRejectionNote('');
    setAcceptanceNote('');

    if (!isSignatureDefault.current) {
      setSignature(null);
    }

    if (previousSubmissionId !== submissionId) {
      setPreviousSubmissionId(submissionId);
    }
  }, [submissionId]);

  const uploadSignature = async (signature: File): Promise<FormsMediaManager.Types.UploadedMedia> => {
    return await FormsMediaManager.API.uploadMedia(signature, locationId);
  };

  const getSignatureField = async (
    practitionerSignature: ProviderReview.Types.RawProviderSignature
  ): Promise<ProviderReview.Types.ProviderSignatureResponse> => {
    let signatureImageId;
    if (practitionerSignature && practitionerSignature.type === 'image') {
      const uploadedImage = await uploadSignature(practitionerSignature.data as File);
      if (uploadedImage.success) {
        signatureImageId = uploadedImage.fileId;
      } else {
        throw new Error();
      }
    }

    const signature: ProviderReview.Types.ProviderSignature = {
      data: signatureImageId || (practitionerSignature.data as string),
      timestamp: practitionerSignature.timestamp,
      type: practitionerSignature.type,
      font_type: practitionerSignature.font_type,
    };

    const fieldId = genUID();

    return {
      provider_esign: {
        [fieldId]: {
          id: fieldId,
          label: 'E-Signature (Provider)',
          meta: {
            displayName: 'Provider E-Signature',
            type: 'eSign',
          },
          required: true,
          value: JSON.stringify(signature),
        },
      },
    };
  };

  const getProviderUser = () => {
    const user = getUser();
    return {
      name: PersonHelpers.getFullName({ FirstName: user?.firstName, LastName: user?.lastName }),
      email: user?.username ?? '',
    };
  };

  const storeSignature = async (signature: ProviderReview.Types.RawProviderSignature, isDefault: boolean) => {
    const user = getProviderUser();
    const signatureField = await getSignatureField(signature);
    setSignature(signatureField);
    if (isDefault) {
      try {
        await ProviderReview.API.saveDefaultSignature({
          ...signatureField,
          provider_email: user.email as string,
        });
        alert.success('Successfully saved signature as default');
      } catch (error) {
        alert.error('Failed to save default signature');
      }
    }
  };

  async function approveThisSubmission(userName: string | undefined) {
    const user = getProviderUser();
    if (!signature || !user.email || !user.name) {
      return;
    }

    return approveSubmission({
      submissionId,
      note: acceptanceNote,
      signature,
      user: {
        ...user,
        ...(userName && { name: userName }),
      },
      locationId,
    });
  }

  async function rejectThisSubmission() {
    const user = getProviderUser();
    if (!user.email || !user.name) {
      return;
    }

    return rejectSubmission({
      submissionId,
      note: rejectionNote,
      user,
      locationId,
    });
  }

  function isSyncingSubmission(id: string) {
    return submissionsUnderSync.includes(id);
  }

  function markSubmissionAsUnderSync(id: string) {
    if (!isSyncingSubmission(id)) {
      setSubmissionsUnderSync((currentValue) => [...currentValue, id]);
    }
  }

  function unmarkSubmissionAsUnderSync(id: string) {
    setSubmissionsUnderSync((currentValue) => {
      const copyOfCurrentValue = [...currentValue];
      const index = copyOfCurrentValue.indexOf(id);

      if (index === -1) {
        return copyOfCurrentValue;
      }

      copyOfCurrentValue.splice(index, 1);
      return copyOfCurrentValue;
    });
  }

  async function triggerWritebacks() {
    const settingIdsToRetry: string[] = [];
    const submissionIdToSync = submissionId;

    for (const key in writebackSettingsConfig) {
      const wbSetting = writebackSettingsConfig[key as FormsWritebacks.Types.WritebackSettingName];
      if (wbSetting.value) {
        settingIdsToRetry.push(wbSetting.settingId);
      }
    }

    const payload: FormsWritebacks.Types.RetryWritebackPayload = {
      settingIds: settingIdsToRetry,
      submissionId: submissionIdToSync,
      mode: writebackSyncMode,
      sourceTenantId: sourceId,
      locationId,
    };

    markSubmissionAsUnderSync(submissionIdToSync);
    const response = await retryWriteback(payload);
    unmarkSubmissionAsUnderSync(submissionIdToSync);

    return response;
  }

  function shouldAutoWriteback() {
    if (!hasPMSIntegration || !hasAtLeastOneWritebackSettingSelected() || !isWritebackCapableSubmission) {
      return false;
    }

    const createPersonConfig = writebackSettingsConfig['Create Person'];
    const updatePersonConfig = writebackSettingsConfig['Update Person'];
    const uploadDocumentConfig = writebackSettingsConfig['Upload Document'];

    const isInManualMode =
      createPersonConfig.manualMode || updatePersonConfig.manualMode || uploadDocumentConfig.manualMode;

    const isCreatePersonInAutoMode =
      (createPersonConfig.statusCode === 'Pending' && createPersonConfig.autoMode) ||
      createPersonConfig.statusCode === 'Not Applicable';

    const isUpdatePersonInAutoMode =
      (updatePersonConfig.statusCode === 'Pending' && updatePersonConfig.autoMode) ||
      updatePersonConfig.statusCode === 'Not Applicable';

    const isUploadDocumentInAutoMode =
      (uploadDocumentConfig.statusCode === 'Pending' && uploadDocumentConfig.autoMode) ||
      uploadDocumentConfig.statusCode === 'Not Applicable';

    return (
      (isCreatePersonInAutoMode && isUpdatePersonInAutoMode && isUploadDocumentInAutoMode) ||
      (hasPermissionToManageProviders && isInManualMode)
    );
  }

  async function approveAndSync(userName?: string): Promise<ApproveAndSyncResult> {
    const isApproved = await approveThisSubmission(userName);

    if (shouldAutoWriteback()) {
      const writebackResponse = await triggerWritebacks();
      return writebackResponse.success ? 'success' : 'partial';
    }
    return isApproved.success ? 'success' : 'failed';
  }

  function hasAtLeastOneWritebackSettingSelected() {
    return (
      writebackSettingsConfig['Create Person'].value ||
      writebackSettingsConfig['Update Person'].value ||
      writebackSettingsConfig['Upload Document'].value
    );
  }

  const contextValue: ReviewSubmissionProviderContext = {
    ...otherProps,
    ...writebackConfigProps,
    previousSubmissionId,
    rejectionNote,
    acceptanceNote,
    isRetryingWriteback,
    isMarkingAsRejected,
    isMarkingAsApproved,
    isSignatureDefault,
    setSignature,
    storeSignature,
    setRejectionNote,
    setAcceptanceNote,
    rejectSubmission: rejectThisSubmission,
    triggerWritebacks,
    approveAndSync,
    hasAtLeastOneWritebackSettingSelected,
    isSyncingSubmission,
  };

  return <ReviewSubmissionContext.Provider value={contextValue}>{children}</ReviewSubmissionContext.Provider>;
};

export const useReviewSubmissionContext = (): ReviewSubmissionProviderContext => {
  const context = useContext(ReviewSubmissionContext);

  if (context === undefined) {
    throw new Error('useReviewSubmissionContext must be used within a <ReviewSubmissionProvider />.');
  }

  return context;
};
