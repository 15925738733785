import { ReactNode } from 'react';
import { css } from '@emotion/react';
import { Trans, i18next, useTranslation } from '@frontend/i18n';
import { SelectedLocationProvider, useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { theme } from '@frontend/theme';
import { Accordion, Info, SwitchField, Text, useFormField } from '@frontend/design-system';
import { useCanDoAction } from '../../../hooks';
import * as settingStyles from '../styles';
import { PaymentSettingsCard } from './payments-card';

interface PaymentSettingsGroupProps {
  children: ReactNode;
  show?: Record<string, boolean>;
  showManageAllToggle?: boolean;
  showAll?: boolean;
}

const styles = {
  paymentSettingsCard: css`
    padding: 0;
  `,
  accordianItem: css`
    margin-bottom: 0;
  `,
  toggleContainer: css`
    display: flex;
    justify-content: flex-end;
    gap: ${theme.spacing(3)};
    margin-bottom: ${theme.spacing(2)};
    flex-wrap: wrap-reverse;
  `,
  infoContainer: css`
    display: inline-flex;
    gap: ${theme.spacing(1)};
  `,
  infoText: css`
    flex-wrap: wrap;
  `,
  toggle: css`
    margin-left: auto;
  `,
};

const pluralizeLocationWord = (count: number) => ` ` + (count > 1 ? i18next.t(`locations`) : i18next.t(`location`));

/*
 * PaymentSettingsGroup is a component that wraps the PaymentSettingsCard and Accordion components.
 * It is used to group the payment settings by location.
 * It also provides a toggle to manage all locations at once.
 * @param showAll - If true, the component will show all locations, otherwise it will show only the locations that have payments enabled.
 */
export const PaymentSettingsGroup = ({
  children,
  show,
  showManageAllToggle = false,
  showAll = false,
}: PaymentSettingsGroupProps) => {
  const { t } = useTranslation();
  const { locationIds, getLocationName } = useMultiQueryUtils();
  const { allowedPaymentsLocations: allowedLocations } = useCanDoAction();
  const { merchantsData } = useMerchant();
  const manageAllProps = useFormField({ type: 'switch' });
  const hasLocationsWithRequiredRoles = allowedLocations.length !== 0;

  return (
    <>
      {showManageAllToggle && (
        <div css={[styles.toggleContainer, settingStyles.groupMaxWidth]}>
          {manageAllProps.value && (
            <div css={styles.infoContainer}>
              <Text css={styles.infoText}>
                {hasLocationsWithRequiredRoles ? (
                  t('Managing settings for {{locationCount}}', {
                    locationCount: allowedLocations.length,
                  }) + pluralizeLocationWord(allowedLocations.length)
                ) : (
                  <Trans>
                    You need to be a <strong>Payments Billing Manager</strong> or a <strong>Super Admin</strong> to
                    change these settings.
                  </Trans>
                )}
              </Text>
              {hasLocationsWithRequiredRoles && (
                <Info>{allowedLocations.map((l) => getLocationName(l)).join(', ')}</Info>
              )}
            </div>
          )}
          <SwitchField css={styles.toggle} label={t('Manage All')} name='manage-all' {...manageAllProps} />
        </div>
      )}
      {!!manageAllProps.value ? (
        hasLocationsWithRequiredRoles && (
          <SelectedLocationProvider locationId={allowedLocations}>
            <PaymentSettingsCard>{children}</PaymentSettingsCard>
          </SelectedLocationProvider>
        )
      ) : (
        <Accordion isMulti startingValue={[locationIds[0]]} variant='location'>
          {locationIds.map(
            (locationId) =>
              (!show || show?.[locationId]) &&
              (!!merchantsData[locationId]?.hasPayments || showAll) && (
                <SelectedLocationProvider key={locationId} locationId={locationId}>
                  <PaymentSettingsCard style={styles.paymentSettingsCard}>
                    <Accordion.Item value={locationId} style={{ margin: 0 }}>
                      <Accordion.Header>
                        <Accordion.Header.Location title={getLocationName(locationId) ?? ''} />
                      </Accordion.Header>
                      <Accordion.Body>{children}</Accordion.Body>
                    </Accordion.Item>
                  </PaymentSettingsCard>
                </SelectedLocationProvider>
              )
          )}
        </Accordion>
      )}
    </>
  );
};
