import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Checkbox } from '@frontend/design-system';

export interface OptionType {
  label: string;
  value: string;
}

interface DropdownOptionProps {
  option: OptionType;
  selected: boolean;
  onChange: (option: OptionType) => void;
}

export const DropdownOption = ({ option, selected, onChange }: DropdownOptionProps) => {
  const id = `checkbox-${option.value}`;
  return (
    <div
      tabIndex={0}
      role='option'
      aria-selected={selected}
      css={dropdownOptionStyle}
      onClick={() => onChange(option)}
      onKeyDown={(e) => {
        e.preventDefault();
        if (e.key === 'Tab') {
          // focus on search input
          const searchInput = document.querySelector('input[name="search-field"]') as HTMLInputElement;
          searchInput.focus();
        }
        if (e.key === 'Enter' || e.key === ' ') {
          onChange(option);
        } else if (e.key === 'ArrowDown') {
          const focusedElement = document.activeElement;
          const nextElement = focusedElement
            ?.closest('li')
            ?.nextElementSibling?.querySelector('[role="option"]') as HTMLElement;

          // Focus on the next sibling if available
          if (nextElement) {
            nextElement.focus();
          }
        } else if (e.key === 'ArrowUp') {
          const focusedElement = document.activeElement;
          const prevElement = focusedElement
            ?.closest('li')
            ?.previousElementSibling?.querySelector('[role="option"]') as HTMLElement;

          // Focus on the previous sibling if available
          if (prevElement) {
            prevElement.focus();
          }
        }
      }}
    >
      <Checkbox
        name={id}
        id={id}
        aria-invalid={false}
        value={selected}
        // passing empty function as a placeholder for the event handlers
        // as user interaction is handled by parent div
        onBlur={() => {}}
        onChange={() => {}}
        onFocus={() => {}}
        error={false}
        active={false}
        tabIndex={-1}
      />
      {option.label}
    </div>
  );
};

const dropdownOptionStyle = css({
  display: 'flex',
  margin: theme.spacing(1),
  padding: theme.spacing(1, 1),
  gap: theme.spacing(1),
  cursor: 'pointer',
  ':hover': {
    background: theme.colors.neutral5,
  },
});
