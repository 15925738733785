import { MutationOptions, useQueryClient, UseQueryOptions } from 'react-query';
import { useMutation, useQuery } from '@frontend/react-query-helpers';
import { SchemaPlatformLocationFeature } from '@frontend/schema';
import {
  FeatureNotificationActionRequest,
  FeatureNotificationActionResponse,
  CheckFeatureNotificationStatusRequest,
  CheckFeatureNotificationStatusResponse,
} from './types';

const HOUR_IN_MS = 1000 * 60 * 60;

export const FeatureNotificationQueryKeys = {
  base: ['feature-notification'],
  notificationFeatureStatus: (request: CheckFeatureNotificationStatusRequest) => [
    ...FeatureNotificationQueryKeys.base,
    'status',
    request.featureEnum,
    request.sourceEnum,
  ],
};

export const useGetFeatureNotificationStatus = (
  request: CheckFeatureNotificationStatusRequest,
  options?: UseQueryOptions<CheckFeatureNotificationStatusResponse>
) => {
  return useQuery({
    queryFn: async () => SchemaPlatformLocationFeature.CheckFeatureNotificationStatus(request),
    queryKey: FeatureNotificationQueryKeys.notificationFeatureStatus(request),
    cacheTime: 1 * HOUR_IN_MS,
    staleTime: 1 * HOUR_IN_MS,
    ...options,
    enabled: !!request.featureEnum && !!request.sourceEnum && (options?.enabled ?? true),
  });
};

export const useFeatureNotificationAction = (
  options?: MutationOptions<FeatureNotificationActionResponse, unknown, FeatureNotificationActionRequest>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (request: FeatureNotificationActionRequest) =>
      SchemaPlatformLocationFeature.CreateOrUpdateFeatureNotificationUserAction(request),
    ...options,
    onSuccess: (res, req, ...rest) => {
      queryClient.invalidateQueries({
        predicate: ({ queryKey }) => {
          return (
            !!req.featureEnum &&
            queryKey.includes(req.featureEnum) &&
            (req.sourceEnum ?? []).some((source) => queryKey.includes(source)) &&
            FeatureNotificationQueryKeys.base.every((part) => queryKey.includes(part))
          );
        },
      });
      options?.onSuccess?.(res, req, ...rest);
    },
  });
};
