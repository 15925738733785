import { useEffect } from 'react';
import { useTranslation } from '@frontend/i18n';
import { DepartmentsSchemaServiceV2, SchemaIO } from '@frontend/schema';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import {
  PopoverMenu,
  PopoverMenuItem,
  SearchField,
  TextButton,
  useFormField,
  Text,
  Chip,
  useAlert,
} from '@frontend/design-system';
import { useInboxFiltersShallowStore } from '../../../stores';
import { FilterItemPopoverCheckboxField } from '../filter-item-popover-checkbox-field';
import { GetItemPropsFn, GetMenuPropsFn } from '../types';

type DepartmentsFilterPopoverMenuProps = {
  getMenuProps: GetMenuPropsFn;
  getItemProps: GetItemPropsFn;
  toggleItems: (departmentIds: string[]) => void;
  selectedDepartments: string[];
  departmentsList: Exclude<
    SchemaIO<(typeof DepartmentsSchemaServiceV2)['ListDefaultSMS']>['output']['smsNumbers'],
    undefined
  >;
};

export const DepartmentsFilterPopoverMenu = ({
  getMenuProps,
  getItemProps,
  toggleItems,
  selectedDepartments,
  departmentsList,
}: DepartmentsFilterPopoverMenuProps) => {
  const { t } = useTranslation('inbox');
  const alert = useAlert();
  const { inboxFilters, setInboxFilters } = useInboxFiltersShallowStore('setInboxFilters');
  const searchField = useFormField({
    type: 'text',
  });
  const filteredDepartments = searchField.value
    ? departmentsList.filter((department) => department.name?.toLowerCase().includes(searchField.value.toLowerCase()))
    : departmentsList;

  const handleSelectAll = () => {
    toggleItems(
      filteredDepartments?.filter(({ id }) => !selectedDepartments.includes(id ?? '')).map(({ id }) => id ?? '') ?? []
    );
  };

  const handleClear = () => {
    toggleItems(
      filteredDepartments?.filter(({ id }) => selectedDepartments.includes(id ?? '')).map(({ id }) => id ?? '') ?? []
    );
  };

  const hasAllSelected = filteredDepartments?.every(({ id }) => selectedDepartments.includes(id ?? ''));
  const hasNoneSelected = filteredDepartments?.every(({ id }) => !selectedDepartments.includes(id ?? ''));

  useEffect(() => {
    const removedSelectedDepartments = selectedDepartments.filter(
      (deptId) => !departmentsList.some((dept) => deptId === dept.id)
    );
    if (removedSelectedDepartments.length) {
      toggleItems(removedSelectedDepartments);
      setInboxFilters({
        ...inboxFilters,
        departments: selectedDepartments.filter((deptId) => !removedSelectedDepartments.includes(deptId)),
      });
      alert.info(
        t('{{count}} departments have been removed from your filters, because they are no longer applicable.', {
          count: removedSelectedDepartments.length,
        })
      );
    }
  }, [JSON.stringify(departmentsList.map(({ id }) => id))]);

  return (
    <PopoverMenu {...getMenuProps()}>
      <PopoverMenuItem
        as='span'
        {...getItemProps({
          index: 0,
          disableCloseOnSelect: true,
          onFocusCapture: (e) => {
            e.preventDefault();
            e.stopPropagation();
            searchField.onFocus();
          },
          onBlurCapture: (e) => {
            e.preventDefault();
            e.stopPropagation();
            searchField.onBlur();
          },
        })}
        css={{
          maxWidth: 220,
          ':hover': {
            backgroundColor: 'inherit',
          },
          ':focus': {
            backgroundColor: 'inherit',
          },
        }}
      >
        <SearchField
          {...searchField}
          name='department-search'
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
        />
      </PopoverMenuItem>
      <PopoverMenuItem
        as='span'
        {...getItemProps({
          index: 1,
          disableCloseOnSelect: true,
        })}
        css={{
          marginTop: theme.spacing(1),
          padding: theme.spacing(1, 0.75),
          borderBottom: `1px solid ${theme.colors.neutral20}`,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          ':hover': {
            backgroundColor: 'inherit',
          },
          ':focus': {
            backgroundColor: 'inherit',
          },
        }}
      >
        <TextButton
          css={{
            color: theme.font.colors.primary,
            fontWeight: theme.font.weight.semibold,
          }}
          onClick={handleSelectAll}
          disabled={hasAllSelected}
          trackingId={`${InboxPrefixes.List}-filters-popover-departments-select-all`}
        >
          {t('Select All')}
        </TextButton>
        <TextButton
          css={{
            color: theme.font.colors.primary,
            fontWeight: theme.font.weight.semibold,
          }}
          onClick={handleClear}
          disabled={hasNoneSelected}
          trackingId={`${InboxPrefixes.List}-filters-popover-departments-clear`}
        >
          {t('Clear')}
        </TextButton>
      </PopoverMenuItem>
      <div
        css={{
          maxHeight: 240,
          overflowY: 'auto',
        }}
      >
        {filteredDepartments?.length ? (
          filteredDepartments.map(({ id, name }, index) => {
            if (!id || !name) return null;

            return (
              <PopoverMenuItem
                key={id}
                {...getItemProps({
                  index: index + 2,
                  disableCloseOnSelect: true,
                  onClickCapture: (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    toggleItems([id]);
                  },
                })}
                css={{
                  width: '100%',
                  cursor: 'pointer',
                  '*': {
                    cursor: 'pointer',
                  },
                }}
                trackingId={`${InboxPrefixes.List}-filters-popover-departments-select-department`}
              >
                <FilterItemPopoverCheckboxField
                  isChecked={selectedDepartments.includes(id)}
                  label={<Chip.Department css={{ fill: theme.colors.neutral50 }}>{name}</Chip.Department>}
                  name={name}
                />
              </PopoverMenuItem>
            );
          })
        ) : (
          <Text
            size='medium'
            textAlign='center'
            color='light'
            css={{
              padding: theme.spacing(1),
            }}
          >
            {t('No departments to display.')}
          </Text>
        )}
      </div>
    </PopoverMenu>
  );
};
