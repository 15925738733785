import { CustomizationFlagTypes, useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { isWeaveUser } from '@frontend/auth-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { useHasFeatureFlag } from '@frontend/shared';
import { usePracticeAnalyticsShallowStore } from '../components/practice/hooks';
import { featureFlags } from '../feature-flags';
import { useIsPAEligibleAccount } from './use-is-pa-eligible-account';

// This hook is used in the Analytics app to determine if the location should show demo data
export const useIsPADemoAccount = (): boolean | undefined => {
  const { flags } = useCustomizationFlagShallowStore('flags');
  const { isPAEligibleAccount } = useIsPAEligibleAccount();
  const { isDemoModal } = usePracticeAnalyticsShallowStore('isDemoModal');
  const { getSelectedLocationData, selectedLocationIdsWithParents } = useAppScopeStore();
  const isDemoFFEnabled = useHasFeatureFlag(featureFlags.practiceAnalyticsDemo);

  const isCustomerLocation = getSelectedLocationData()[selectedLocationIdsWithParents[0]]?.opsType === 'REGULAR';
  const isCFActive = flags.practiceanalytics?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE;
  const isCFPromote = flags.practiceanalytics?.state === CustomizationFlagTypes.CustomizationFlagState.PROMOTE;
  const isCFHide = !isCFActive && !isCFPromote;

  // If Demo modal is open then always show demo data
  if (isDemoModal) {
    return true;
  }

  if (isPAEligibleAccount && isCustomerLocation) {
    // If it's a real customer eligible location
    // If CF is HIDE, then weave users should see demo data
    // If CF is HIDE or PROMOTE, then customer should see demo data
    return (isCFHide && isWeaveUser()) || ((isCFHide || isCFPromote) && !isWeaveUser());
  }

  // If it's a test location used by weave
  if (!isCustomerLocation) {
    return isDemoFFEnabled;
  }

  return;
};
