import { useEffect, useRef } from 'react';
import { useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { getDecodedWeaveToken, getUser, isWeaveUser } from '@frontend/auth-helpers';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { initThirdPartyScriptReady, userIdentityChanged } from './app-lifecycle-subjects';

export const useThirdPartyScripts = () => {
  const { locationId } = useLocationDataShallowStore('locationId');
  const user = getUser();
  const { flags: customizationFlags } = useCustomizationFlagShallowStore('flags');

  const doWhenDocumentReady = (action: () => void) => {
    if (document.readyState === 'complete') {
      action();
    } else {
      window.addEventListener('load', () => {
        action();
      });
    }
  };

  useDidMount(() => {
    doWhenDocumentReady(initThirdPartyScriptReady.notify);
  });

  useEffect(() => {
    doWhenDocumentReady(() => {
      if (locationId !== '' && user) {
        userIdentityChanged.notify({
          user,
          decodedWeaveToken: getDecodedWeaveToken(),
          isWeaveUser: isWeaveUser(),
          locationId,
          customizationFlags: Object.values(customizationFlags),
        });
      }
    });
  }, [user, getDecodedWeaveToken, isWeaveUser, locationId, customizationFlags]);
};

function useDidMount(fn: () => void) {
  const once = useRef(true);

  useEffect(() => {
    if (once.current) {
      fn();
      once.current = false;
    }
  }, []);
}
