import { FC, useEffect, useRef } from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { useLocation } from '@tanstack/react-location';
import { FeatureNotificationTypes } from '@frontend/api-feature-notification';
import { theme } from '@frontend/theme';
import { OnlineSchedulingPromoToastCard } from './OnlineSchedulingPromoToastCard';

interface OnlineSchedulingAppointmentPromoCardProps {
  cardStyles?: Interpolation<Theme>;
  locationIds: string[];
  onClose: () => void;
}

export const OnlineSchedulingAppointmentPromoCard: FC<OnlineSchedulingAppointmentPromoCardProps> = ({
  cardStyles,
  locationIds,
  onClose,
}) => {
  const { current } = useLocation();
  const initialRoute = useRef('');

  useEffect(() => {
    // call on close if the user navigates away from the page
    if (initialRoute.current && initialRoute.current !== current.pathname) {
      onClose?.();
    } else {
      initialRoute.current = current.pathname;
    }
  }, [current.pathname]);

  // Hide promo card for new dashboard as there is a dedicated promo card for it
  if (current.pathname.startsWith('/home/dashboard')) {
    return null;
  }
  return (
    <OnlineSchedulingPromoToastCard
      locationIds={locationIds}
      source={FeatureNotificationTypes.FeatureNotificationSource.SOURCE_APPOINTMENT_TEASER_BANNER}
      styles={[{ position: 'fixed', bottom: theme.spacing(2), right: theme.spacing(8) }, cardStyles]}
      onActionSuccess={onClose}
    />
  );
};
