import { Empty } from '@weave/schema-gen-ts/dist/google/protobuf/empty.pb';
import { SetActiveDeviceResponse } from '@weave/schema-gen-ts/dist/schemas/phone/user/v1/user_service.pb';
import { ForgetDeviceResponse } from '@weave/schema-gen-ts/dist/schemas/phone/user/v1/user_service.pb';
import { UseMutateFunction, useQueryClient } from 'react-query';
import { PhoneSyncApi, queryPredicate, queryDevices, ActiveSipProfile } from '@frontend/api-phone-sync';
import { useTranslation } from '@frontend/i18n';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import { useMutation } from '@frontend/react-query-helpers';
import { SoftphoneStateInterface } from '@frontend/softphone-state';
import { TAB_ID } from '@frontend/tab-management';
import { useAlert } from '@frontend/design-system';

export const usePhoneSyncMutations: () => {
  connectDevice: UseMutateFunction<SetActiveDeviceResponse, unknown, { sipProfileId: string }, unknown>;
  disconnectDevice: UseMutateFunction<Empty, unknown, void, unknown>;
  forgetDevice: UseMutateFunction<ForgetDeviceResponse, unknown, { sipProfileId: string }, unknown>;
} = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const alerts = useAlert();
  const { broadcastConnected, broadcastDisconnected, activateTab, clearTabStore } = SoftphoneStateInterface;

  const { mutate: connectDevice } = useMutation(
    (item: { sipProfileId: string }) => {
      return PhoneSyncApi.connectDevice({ sipProfileId: item.sipProfileId });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: queryPredicate.listDevices,
        });
        broadcastConnected(TAB_ID);
        activateTab(TAB_ID);
        alerts.success(t('Device connected successfully'));
      },
      onError: () => {
        alerts.error(t('Device connection failed'));
      },
    }
  );

  const { mutate: disconnectDevice } = useMutation(
    () => {
      return PhoneSyncApi.disconnectDevice({});
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: queryPredicate.listDevices,
        });
        broadcastDisconnected(TAB_ID);
        activateTab('');
        clearTabStore();
        alerts.success(t('Device disconnected successfully'));
      },
      onError: () => {
        alerts.error(t('Device disconnection failed'));
      },
    }
  );

  const { mutate: forgetDevice } = useMutation(
    (payload: { sipProfileId: string }) => {
      return PhoneSyncApi.forgetDevice(payload);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: queryPredicate.listDevices,
        });
        alerts.success(t('Device forgotten successfully'));
      },
      onError: () => {
        alerts.error(t('Device update failed'));
      },
    }
  );

  return {
    connectDevice,
    disconnectDevice,
    forgetDevice,
  };
};

export const usePhonePairSuccessHandler = () => {
  const { setPhoneConfig, phoneConfig } = usePhoneConfigShallowStore('setPhoneConfig', 'phoneConfig');
  const { t } = useTranslation('phoneSync');
  const alerts = useAlert();
  const queryClient = useQueryClient();

  return (locationIds: string[]) =>
    async function phonePairSuccessHandler(activeSipProfile: ActiveSipProfile) {
      queryClient.invalidateQueries({
        predicate: queryPredicate.listDevices,
      });

      if (phoneConfig) {
        await PhoneSyncApi.disconnectDevice({});
      }

      if (activeSipProfile.connected) {
        const updatedDevices = await queryDevices(locationIds);
        const availableDevices = updatedDevices?.filter((device) => device.availableInContext);
        /**
         * We use the data from the list because it has more information than the activeSipProfile
         */
        const foundDevice = availableDevices?.find((device) => device.sipProfileId === activeSipProfile.sipProfileId);
        setPhoneConfig(foundDevice);
        alerts.success(t('Device paired and connected successfully'));
      } else if (activeSipProfile) {
        setPhoneConfig(undefined);
        alerts.success(t('Device paired successfully'));
      } else {
        alerts.error(t('Device pairing failed'));
      }
    };
};
