import { useEffect, useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { ListFieldProps, useFormField } from '@frontend/design-system';
import { useCalendarViewV3HeaderContext } from '../../../context/CalendarViewV3HeaderContext';
import { CalendarViewV3HeaderFilterSelector } from './CalendarViewV3HeaderFilterSelector';

type CalendarViewV3HeaderPractitionerFilterProps = {
  locationIds: string[];
  checkListProps: ListFieldProps;
  hasOnlyOneLocation: boolean;
};

export const CalendarViewV3HeaderPractitionerFilter = ({
  locationIds,
  checkListProps,
  hasOnlyOneLocation,
}: CalendarViewV3HeaderPractitionerFilterProps) => {
  const { t } = useTranslation('scheduleCalendarFilters');
  const searchPractitionerFieldProps = useFormField({ type: 'text' });

  const { practitionersList } = useCalendarViewV3HeaderContext();

  const filteredPractitionersListByLocation = useMemo(() => {
    if (locationIds.length === 0) {
      return practitionersList;
    }
    return practitionersList.filter((practitioner) => {
      return locationIds.includes(practitioner.locationId);
    });
  }, [practitionersList, locationIds]);

  const handleClear = () => {
    checkListProps.onChange({ value: [], name: 'practitioner filter' });
  };

  const handleSelectAll = () => {
    const filteredValueBySearch = searchPractitionerFieldProps.value
      ? filteredPractitionersListByLocation
          .filter((practitioner) =>
            practitioner.label?.toLowerCase()?.includes(searchPractitionerFieldProps.value?.toLowerCase())
          )
          .map((practitioner) => practitioner.id)
      : [];

    const allPractitionerList = !searchPractitionerFieldProps.value
      ? [...checkListProps.value, ...filteredPractitionersListByLocation.map((practitioner) => practitioner.id)]
      : [];

    const filteredValue = searchPractitionerFieldProps.value ? filteredValueBySearch : allPractitionerList;

    checkListProps.onChange({
      value: filteredValue,
      name: 'practitioner filter',
    });
  };

  // Clear practitioner filter if no practitioners are found
  useEffect(() => {
    if (!filteredPractitionersListByLocation.length && checkListProps.value.length) {
      checkListProps.onChange({ value: [], name: 'practitioner filter' });
    }
  }, [filteredPractitionersListByLocation, checkListProps.value]);

  return (
    <CalendarViewV3HeaderFilterSelector
      checkListProps={checkListProps}
      renderList={filteredPractitionersListByLocation}
      title={t('Provider')}
      emptyStatePlaceholder={t('No providers found')}
      handleClear={handleClear}
      searchFieldProps={searchPractitionerFieldProps}
      handleSelectAll={handleSelectAll}
      hasOnlyOneLocation={hasOnlyOneLocation}
      numberOfSelections={checkListProps.value.length}
      totalNumber={filteredPractitionersListByLocation.length}
      selectedLocations={locationIds}
    />
  );
};
