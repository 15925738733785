import { ComponentProps } from 'react';
import { motion } from 'framer-motion';
import { theme } from '@frontend/theme';
import { NakedUl } from '@frontend/design-system';
import { UseThreadMediaResponse } from '../hooks';
import { MediaPreviewListItem } from './media-preview-list-item';

const MotionUl = motion(NakedUl);

type MediaPreviewListProps = Pick<UseThreadMediaResponse, 'media' | 'removeMedia'> & {
  removeImageTrackingId?: string;
} & Partial<ComponentProps<typeof MotionUl>>;

export const MediaPreviewList = ({ media, removeMedia, removeImageTrackingId, ...rest }: MediaPreviewListProps) => {
  return (
    <MotionUl
      initial={{
        height: 0,
        padding: 0,
        borderTop: 'none',
      }}
      animate={{
        height: media.length ? 'min-content' : 0,
        padding: media.length ? theme.spacing(1) : 0,
        borderTop: media.length ? `1px solid ${theme.colors.neutral20}` : 'none',
      }}
      css={{
        display: 'flex',
        gap: theme.spacing(1),
        overflowX: 'auto',
        overflowY: 'hidden',
        maxHeight: '20%',
      }}
      {...rest}
    >
      {media.map((mediaItem) => {
        return (
          <MediaPreviewListItem
            key={mediaItem.localId}
            {...mediaItem}
            onRemove={() => removeMedia([mediaItem.localId])}
          />
        );
      })}
    </MotionUl>
  );
};
