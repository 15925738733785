import { FeedbackType } from '@weave/schema-gen-ts/dist/schemas/office-assistant/v1/office_assistant_service.pb';
import { SchemaSMSSharedEnums, SchemaSMSSharedModels } from '@frontend/api-messaging';
import { TagSelectionHooks } from '@frontend/tag-selection';
import { theme } from '@frontend/theme';

export const CONVERSATION_IMG_ID_PREFIX = 'media-id-';
export const EXPORT_IMG_ID_PREFIX = 'export-media-id-';

export const INBOX_BORDER_COLOR = theme.colors.neutral10;

export const TEXT_WRITEBACKS_RELATED_ID_TYPE = SchemaSMSSharedEnums.RelatedType.RELATED_TYPE_SMS_WRITEBACK_NOTE;
export const TEMP_WRITEBACKS_RELATED_ID: SchemaSMSSharedModels.RelatedID = {
  type: TEXT_WRITEBACKS_RELATED_ID_TYPE,
  id: 'temp-writeback-id',
};
export const NEW_THREAD_ID = 'new';
export const HIDE_DELETE_MODAL = 'hide-sms-delete-modal';

export const TYPING_INDICATOR_SPACING_FACTOR = 6;

export const INBOX_PANEL_WIDTH = 'min(100vw, 400px)';
export const PERSON_SEARCH_PAGE_SIZE = 20;

export const SMART_TAG_FEEDBACK_THRESHOLDS: Parameters<
  typeof TagSelectionHooks.useTagFeedbackFlow
>[0]['feedbackClickThreshold'] = {
  [FeedbackType.FEEDBACK_TYPE_BAD]: 3,
  [FeedbackType.FEEDBACK_TYPE_GOOD]: -1,
};

export const INBOX_PANEL_ID = 'inbox-panel';
export const THREAD_PANEL_ID = 'thread-panel';
