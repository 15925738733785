import { GetWeavePopNotificationByType } from '@frontend/types';
import { useCommonActionSubscriptions } from './actions/use-common-actions-subscriptions';
import { useNotificationActionSubscription } from './actions/use-notification-action-subscription';
import { useNotification } from './use-notification';

type Props = {
  onView: (notification: GetWeavePopNotificationByType<'sms-message-new'>) => void;
  onRespond: (notification: GetWeavePopNotificationByType<'sms-message-new'>, response: string) => void;
};
export const useSmsMessageNotification = ({ onView, onRespond }: Props) => {
  useCommonActionSubscriptions('sms-message-new');
  const { create, remove } = useNotification<'sms-message-new'>();

  useNotificationActionSubscription(
    'sms-message-new',
    'view',
    ({ notification }) => {
      onView(notification);
      remove(notification.id);
    },
    [onView, remove]
  );

  useNotificationActionSubscription(
    'sms-message-new',
    'respond',
    ({ notification, payload }) => {
      onRespond(notification, payload);
      //no need to remove here, as the notification will invoke timed-out after showing a 'sent' message for a few seconds
    },
    [onRespond]
  );

  return {
    create,
    remove,
  };
};
