import { useMemo, useState } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Tray, useFormField } from '@frontend/design-system';
import { useCalendarViewV3HeaderContext } from '../../../context/CalendarViewV3HeaderContext';
import { useCalendarViewV3HeaderFilterShallowStore } from '../../../stores/use-calendar-view-v3-header-filter-store';
import { hasSameIds } from '../../../utils';
import { MultiLocationSelector } from '../../../views/Calendar/components/HeaderBar/Filter/MultiLocationSelector';
import { CalendarViewV3HeaderAppointmentTypeFilter } from './CalendarViewV3HeaderAppointmentTypeFilter';
import { CalendarViewV3HeaderConfirmationStatusFilter } from './CalendarViewV3HeaderConfirmationStatusFilter';
import { CalendarViewV3HeaderPractitionerFilter } from './CalendarViewV3HeaderPractitionerFilter';

type CalendarViewV3HeaderFilterModalContentProps = {
  closeModal: () => void;
};

export const CalendarViewV3HeaderFilterModalContent = ({ closeModal }: CalendarViewV3HeaderFilterModalContentProps) => {
  const { t } = useTranslation('scheduleCalendarFilters');

  const {
    appointmentTypeList,
    practitionersList,
    defaultPractitionerIds,
    selectedMultiLocationIds,
    setSelectedFilteredLocationIds,
    selectedFilteredLocationIds,
  } = useCalendarViewV3HeaderContext();

  const { selectedFilters, setSelectedFilters, setHasActiveFilters } = useCalendarViewV3HeaderFilterShallowStore(
    'setHasActiveFilters',
    'selectedFilters',
    'setSelectedFilters'
  );

  const [locationIds, setLocationIds] = useState<string[]>(
    !!selectedFilteredLocationIds?.length ? selectedFilteredLocationIds : selectedMultiLocationIds
  );

  const hasOnlyOneLocation = selectedMultiLocationIds.length === 1;

  const filteredPractitionerList = useMemo(() => {
    return practitionersList
      .filter((practitioner) => {
        return locationIds.includes(practitioner.locationId);
      })
      .map((practitioner) => practitioner.id);
  }, [practitionersList, locationIds]);

  const practitionersCheckListField = useFormField(
    {
      type: 'checklist',
      value: selectedFilters?.practitionerIds?.length > 0 ? selectedFilters.practitionerIds : filteredPractitionerList,
      ...(selectedFilters?.practitionerIds?.length > 0 && { minRequired: 1 }),
    },
    [locationIds, practitionersList, filteredPractitionerList, selectedFilters]
  );

  const appointmentTypesCheckListField = useFormField(
    {
      type: 'checklist',
      value: selectedFilters?.appointmentTypeIds?.length > 0 ? selectedFilters.appointmentTypeIds : [],
    },
    [locationIds, appointmentTypeList, selectedFilters]
  );

  const confirmationStatusField = useFormField(
    {
      type: 'switch',
      value: selectedFilters.isUnconfirmedStatusOnly || false,
    },
    [selectedFilters.isUnconfirmedStatusOnly]
  );

  const applyFilters = () => {
    setSelectedFilters({
      appointmentTypeIds: appointmentTypesCheckListField.value,
      practitionerIds: practitionersCheckListField.value,
      isUnconfirmedStatusOnly: confirmationStatusField.value,
    });
    setSelectedFilteredLocationIds(locationIds);
    setHasActiveFilters(true);
    closeModal();
  };

  const resetFilters = () => {
    setHasActiveFilters(false);
    setSelectedFilters({
      appointmentTypeIds: [],
      practitionerIds: defaultPractitionerIds,
      isUnconfirmedStatusOnly: false,
    });
    setSelectedFilteredLocationIds(selectedMultiLocationIds);
    closeModal();
  };

  const IsFilterStateChanged = useMemo(() => {
    const isPractitionerFilterChanged = !hasSameIds(
      selectedFilters.practitionerIds || [],
      practitionersCheckListField.value
    );

    const isAppointmentTypeFilterChanged = !hasSameIds(
      selectedFilters.appointmentTypeIds || [],
      appointmentTypesCheckListField.value
    );

    const isLocationFilterChanged = !hasSameIds(
      locationIds,
      selectedFilteredLocationIds.length ? selectedFilteredLocationIds : selectedMultiLocationIds
    );

    const isConfirmationStatusFilterChanged =
      !!selectedFilters.isUnconfirmedStatusOnly !== confirmationStatusField.value;

    return (
      isPractitionerFilterChanged ||
      isAppointmentTypeFilterChanged ||
      isLocationFilterChanged ||
      isConfirmationStatusFilterChanged
    );
  }, [
    selectedFilters,
    confirmationStatusField.value,
    practitionersCheckListField.value,
    appointmentTypesCheckListField.value,
    locationIds,
    selectedFilteredLocationIds,
  ]);

  const hasErrorOrMissingLocation = hasOnlyOneLocation ? !!practitionersCheckListField.error : locationIds.length === 0;
  const disablePrimaryActionBtn = !IsFilterStateChanged || hasErrorOrMissingLocation;

  return (
    <>
      {!hasOnlyOneLocation && (
        <MultiLocationSelector
          locationIds={locationIds}
          setLocationIds={setLocationIds}
          defaultFilteredLocationIds={selectedMultiLocationIds}
        />
      )}
      <CalendarViewV3HeaderPractitionerFilter
        checkListProps={practitionersCheckListField}
        hasOnlyOneLocation={hasOnlyOneLocation}
        locationIds={locationIds}
      />
      <CalendarViewV3HeaderAppointmentTypeFilter
        checkListProps={appointmentTypesCheckListField}
        hasOnlyOneLocation={hasOnlyOneLocation}
        locationIds={locationIds}
      />
      <CalendarViewV3HeaderConfirmationStatusFilter confirmationStatusField={confirmationStatusField} />
      <Tray.Actions
        onPrimaryClick={applyFilters}
        disabledPrimary={disablePrimaryActionBtn}
        primaryLabel={t('Apply Filters')}
        onSecondaryClick={resetFilters}
        secondaryLabel={t('Reset')}
      />
    </>
  );
};
