import { PropsWithChildren, ReactNode, useState } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';

export type SubtitleProps = PropsWithChildren<{ subtitle?: string | ReactNode; moreSubtitle?: string }>;

export function Subtitle({ subtitle, moreSubtitle }: SubtitleProps) {
  return (
    <Text
      css={{
        gridArea: 'subtitle',
        margin: 0,
      }}
    >
      {typeof subtitle === 'string' ? (
        <ExpandText
          css={css`
            max-width: 700px;
          `}
          text={subtitle || ''}
          hiddenText={moreSubtitle}
        />
      ) : (
        subtitle
      )}
    </Text>
  );
}

interface ExpandTextProps {
  actionText?: string;
  className?: string;
  text: string | React.ReactNode;
  hiddenText?: string;
}

const mainStyles = css`
  display: inline-block;
  width: 100%;
`;

const expandStyles = css`
  color: ${theme.font.colors.primary};
  cursor: pointer;
  margin-left: 3px;
`;

export const ExpandText = ({ className, text, hiddenText }: ExpandTextProps) => {
  const [showHiddenText, setShowHiddenText] = useState(false);

  return (
    <span css={mainStyles} className={className}>
      {text}
      {!showHiddenText && hiddenText && (
        <span
          css={expandStyles}
          onClick={() => setShowHiddenText(true)}
          data-trackingid='nav-portal-sharedpageheader-btn-more'
        >
          {' '}
          more...
        </span>
      )}
      {showHiddenText && (
        <>
          {hiddenText}
          <span
            css={expandStyles}
            onClick={() => setShowHiddenText(false)}
            data-trackingid='nav-portal-sharedpageheader-btn-less'
          >
            {' '}
            ...less
          </span>
        </>
      )}
    </span>
  );
};
