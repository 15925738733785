import { css } from '@emotion/react';
import { useQueryClient } from 'react-query';
import { convertToCents } from '@frontend/currency';
import { getTodaysDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import {
  CollectPaymentModalBody,
  CollectPaymentModalSteps,
  useCollectPaymentModal,
} from '@frontend/payments-collection-flow';
import { useInvoiceShallowStore, useQueryPaginatedInvoices } from '@frontend/payments-invoice-controller';
import { MultiStepModal } from '@frontend/payments-multistep-modal';
import {
  CreateInvoiceForm,
  DropZoneOverlay,
  shouldShowModal,
  ThresholdModals,
  useCreateInvoiceForm,
  useCreatePaymentRequest,
} from '@frontend/person-invoice-create';
import { PersonInvoiceModalSteps } from '@frontend/person-invoice-payment';
import { pendo } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, useModalControl } from '@frontend/design-system';

type PaymentModalProps = ModalControlModalProps;

export const CreateAndCollectModal = ({ ...rest }: PaymentModalProps) => {
  const { t } = useTranslation('payments');

  return (
    <MultiStepModal
      modalProps={rest}
      closeModal={rest.onClose}
      initialStep={PersonInvoiceModalSteps.CreateInvoice}
      css={{ maxHeight: 750 }}
      maxWidth={parseInt(theme.spacing(82))}
    >
      <MultiStepModal.Step id={PersonInvoiceModalSteps.CreateInvoice} title={t('Create Invoice')} hideModalBody>
        <CreateInvoice />
      </MultiStepModal.Step>
      <CollectPaymentModalBody />
    </MultiStepModal>
  );
};

const styles = {
  modalBody: css`
    padding: ${theme.spacing(1, 0)};
  `,
};

const CreateInvoice = () => {
  const { t } = useTranslation('payments');
  const { setSelectedInvoiceId } = useInvoiceShallowStore('setSelectedInvoiceId');

  const queryClient = useQueryClient();
  const { invoicesQueryKey } = useQueryPaginatedInvoices();
  const {
    createFormProps,
    dropZoneProps,
    resetFields,
    attachment,
    selectedPerson: V2SelectedPerson,
    skipAttachmentAuth,
  } = useCreateInvoiceForm();
  const {
    form: { values, isComplete },
  } = createFormProps;
  const { createPaymentRequest, creatingPaymentRequest } = useCreatePaymentRequest({ locationId: values.locationId });
  const { goToStep: goToCollectionStep } = useCollectPaymentModal();
  const { modalProps: thresholdModalProps, triggerProps: thresholdTriggerProps } = useModalControl();

  const handleCreateInvoice = async () => {
    await createPaymentRequest({
      personId: values.personId!,
      amount: convertToCents(Number(values.amount)),
      attachment,
      mobileNumber: V2SelectedPerson?.MobilePhone,
      memo: values.memo,
      providerName: values.provider,
      skipAttachmentAuth,
    }).then((invoice) => {
      pendo.track('payments-create-invoice', {
        date: getTodaysDate('MM/DD/YYYY'),
        amount: Number(values.amount),
      });
      setSelectedInvoiceId(invoice.id);
      goToCollectionStep(CollectPaymentModalSteps.PaymentFlowList);
      queryClient.invalidateQueries(invoicesQueryKey);
    });
  };

  return (
    <>
      <DropZoneOverlay {...dropZoneProps}>
        <Modal.Body css={styles.modalBody}>
          <CreateInvoiceForm {...createFormProps} />
        </Modal.Body>
        <Modal.Actions
          primaryLabel={t('Create Invoice')}
          onPrimaryClick={
            shouldShowModal(createFormProps.form.values.amount) ? thresholdTriggerProps.onClick : handleCreateInvoice
          }
          disablePrimary={!isComplete || creatingPaymentRequest}
          onSecondaryClick={() => {
            resetFields();
            return true;
          }}
          secondaryLabel={t('Cancel')}
        />
      </DropZoneOverlay>
      <ThresholdModals
        {...thresholdModalProps}
        amount={createFormProps.form.values.amount}
        onConfirm={handleCreateInvoice}
      />
    </>
  );
};
