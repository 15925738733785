import { useEffect, useRef } from 'react';
import { getUser, isWeaveUser } from '@frontend/auth-helpers';
import { http } from '@frontend/fetch';
import { useAppScopeStore } from '@frontend/scope';
import { useTeamChatStore } from '../providers';
import { UserStatusMapping } from '../providers/types';

const USER_PRESENCE_UPDATE_INTERVAL = 60e3 * 3; // 3 minutes
const EXPIRATION_BUFFER = 30e3; // 30 seconds - need buffer for network delay in posting the update

export const USER_PRESENCE_CHECK_INTERVAL = USER_PRESENCE_UPDATE_INTERVAL / 2;

const setUserPresence = (online: boolean, locationId: string) => {
  return http.post<undefined, { online: boolean; expires_at: string }>(
    '/team-chat/v1/user-presence',
    {
      online,
      expires_at: new Date(Date.now() + USER_PRESENCE_UPDATE_INTERVAL + EXPIRATION_BUFFER).toISOString(),
    },
    {
      params: {
        location_id: locationId,
      },
    }
  );
};

export const useWeavePresence = (teamId: string) => {
  const { users, userStatus, batchSetUserStatus } = useTeamChatStore(['batchSetUserStatus', 'users', 'userStatus']);
  const userStatusRef = useRef<UserStatusMapping>({});
  const { selectedLocationIds } = useAppScopeStore();
  const user = getUser();
  const locationId = selectedLocationIds[0];

  const preventSetPresence = !teamId || !locationId || !user || isWeaveUser();

  useEffect(() => {
    if (preventSetPresence) {
      return;
    }

    setUserPresence(true, locationId);
  }, [teamId, preventSetPresence]);

  useEffect(() => {
    return () => {
      if (preventSetPresence) {
        return;
      }

      setUserPresence(false, locationId);
    };
  }, [teamId, preventSetPresence]);

  // start interval to update user presence
  useEffect(() => {
    if (preventSetPresence) return;

    const interval = setInterval(() => {
      setUserPresence(true, locationId);
    }, USER_PRESENCE_UPDATE_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [locationId, preventSetPresence]);

  useEffect(() => {
    // update user status ref so that we don't have to rely on stale state for presence
    userStatusRef.current = userStatus;
  }, [userStatus]);

  // Check for user presence that expires
  useEffect(() => {
    const interval = setInterval(() => {
      const userStatusMapping = userStatusRef.current;
      const updateUserStatus: UserStatusMapping = {};

      let isChanged = false;

      users.forEach((user) => {
        const { userID } = user;
        if (!userStatusMapping[userID].presence.expiresAt) return;

        const isOnline = userStatusMapping[userID].presence.online;

        const now = new Date();
        const expiration = new Date(userStatusMapping[userID].presence.expiresAt);

        if (isOnline && now > expiration) {
          userStatusMapping[user.userID] = {
            ...userStatusMapping[user.userID],
            presence: {
              ...userStatusMapping[userID].presence,
              online: false,
            },
          };

          isChanged = true;
        }
      });

      if (isChanged) {
        batchSetUserStatus(updateUserStatus);
      }
    }, USER_PRESENCE_CHECK_INTERVAL);

    return () => {
      clearInterval(interval);
    };
  }, [users]);

  useEffect(() => {
    const beforeUnloadListener = () => {
      if (!locationId || !user || user.type === 'weave') return;

      setUserPresence(false, locationId);
    };
    window.addEventListener('beforeunload', beforeUnloadListener);
    return () => {
      window.removeEventListener('beforeunload', beforeUnloadListener);
    };
  }, [locationId]);
};
