import { memo, useMemo } from 'react';
import { CalendarViewV3HeaderContext } from '../../context/CalendarViewV3HeaderContext';
import { CalendarViewV3HeaderContextType } from '../../types';
import { CalendarViewV3HeaderContainer } from './CalendarViewV3HeaderContainer';

export const CalendarViewV3Header = memo((props: CalendarViewV3HeaderContextType) => {
  const providerValue = useMemo<CalendarViewV3HeaderContextType>(() => props, [props]);

  return (
    <CalendarViewV3HeaderContext.Provider value={providerValue}>
      <CalendarViewV3HeaderContainer />
    </CalendarViewV3HeaderContext.Provider>
  );
});

CalendarViewV3Header.displayName = 'CalendarViewV3Header';
