import {
  MessageGroup,
  SuppressMessageRequest,
  SuppressMessageResponse,
  UnsuppressMessageRequest,
  UnsuppressMessageResponse,
} from '@weave/schema-gen-ts/dist/schemas/messaging/auto-worker/v1/service.pb';
import { QueryClient, UseMutationOptions, useMutation, useQueryClient } from 'react-query';
import { SchemaAutoWorker } from './service';
import { ScopedMessageRequestQueryKey } from './types';

type MutationContext = { previous: Record<string, MessageGroup> };

const handleMutate = (
  queryKeyToInvalidate: ScopedMessageRequestQueryKey,
  queryClient: QueryClient,
  messageId: string,
  suppressedAt?: string
): MutationContext => {
  queryClient.cancelQueries(queryKeyToInvalidate);
  const previous = queryClient.getQueryData<Record<string, MessageGroup>>(queryKeyToInvalidate) ?? {};
  queryClient.setQueryData<Record<string, MessageGroup>>(queryKeyToInvalidate, () => {
    const newData = { ...previous };
    for (const key in newData) {
      const messageIndex = newData[key].sentSending.findIndex((message) => message.messageId === messageId);
      if (messageIndex === -1) continue;
      newData[key].sentSending[messageIndex] = { ...newData[key].sentSending[messageIndex], suppressedAt };
    }
    return newData;
  });
  return { previous };
};

export const useSuppressMessage = (
  queryKeyToInvalidate: ScopedMessageRequestQueryKey,
  options: UseMutationOptions<SuppressMessageResponse, unknown, SuppressMessageRequest, unknown>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['SuppressMessage'],
    mutationFn: SchemaAutoWorker.SuppressMessage,
    onMutate: (request) => handleMutate(queryKeyToInvalidate, queryClient, request.messageId, new Date().toISOString()),
    ...options,
  });
};

export const useUnsuppressMessage = (
  queryKeyToInvalidate: ScopedMessageRequestQueryKey,
  options: UseMutationOptions<UnsuppressMessageResponse, unknown, UnsuppressMessageRequest, unknown>
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['UnsuppressMessage'],
    mutationFn: SchemaAutoWorker.UnsuppressMessage,
    onMutate: (request) => handleMutate(queryKeyToInvalidate, queryClient, request.messageId, undefined),
    ...options,
  });
};
