import { PhoneOverride } from '@weave/schema-gen-ts/dist/schemas/phone/override/v1/override.pb';
import { useMutation } from 'react-query';
import { DepartmentsTypes } from '@frontend/api-departments';
import { OverrideApi } from '@frontend/api-overrides';
import { Trans, useTranslation } from '@frontend/i18n';
import { useInvalidateQueries } from '@frontend/location-helpers';
import { useModalControl, ConfirmationModal, Text, useAlert } from '@frontend/design-system';
import { queryKeys } from '../../query-keys';

interface IOverrideSettingRemoveModal {
  override: PhoneOverride;
  department?: DepartmentsTypes.DepartmentModel;
  modalProps: ReturnType<typeof useModalControl>['modalProps'];
}

export const NewOverrideSettingRemoveModal = ({ override, department, modalProps }: IOverrideSettingRemoveModal) => {
  const { t } = useTranslation('phone', { keyPrefix: 'overrides' });
  const invalidateQueries = useInvalidateQueries();
  const alerts = useAlert();

  const { mutate: deleteOverride } = useMutation(
    () => {
      return OverrideApi.DeleteOverride({ phoneOverrideId: override.id }, { locationId: override.locationId });
    },
    {
      onSuccess: () => {
        invalidateQueries([...queryKeys.overrideList()]);
        invalidateQueries([...queryKeys.listDepartmentsWithSchedules()]);
        alerts.success(t('Override removed successfully'));
      },
      onError: () => {
        alerts.error(t('Override removal failed'));
      },
    }
  );

  return (
    <ConfirmationModal
      {...modalProps}
      onConfirm={() => deleteOverride()}
      title={t('Remove Active Override?')}
      message={
        !!department ? (
          <Trans t={t} departmentName={department.name}>
            Removing the override will return the{' '}
            <Text as='span' size='medium' weight='bold'>
              {department.name}
            </Text>{' '}
            department to its standard call configuration.
          </Trans>
        ) : (
          t('Removing the override will return this location to its standard call configuration.')
        )
      }
      cancelLabel={t('No, Cancel')}
      confirmLabel={t('Yes, Remove')}
      destructive
      cancelTrackingId='phone-portal-button-cancelRemoveOverride'
      confirmTrackingId='phone-portal-button-removeOverride'
    />
  );
};
