import { useEffect, useCallback, useMemo } from 'react';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters, useQuery, useQueryClient } from 'react-query';
import { FormsQueryKeys, validateLocations, FormsHashUtils } from '@frontend/api-forms';
import { useAppScopeStore } from '@frontend/scope';
import { generateQueryKey } from '../../utils';
import { useFormsLocationStore } from './store';
import { FormsLocationStore } from './types';

export type UseFormsLocationsResults = Pick<
  FormsLocationStore,
  'isValidatingLocations' | 'invalidFormsLocations' | 'validFormsLocations'
> & {
  refreshFormsLocationValidity: <TPageData>(
    options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined
  ) => Promise<
    QueryObserverResult<
      {
        hash: string;
        data: {
          isValidLocation: boolean;
          isUnauthorisedLocation: boolean;
          locationId: string;
        }[];
      },
      unknown
    >
  >;
  isValidFormsLocation: (locationId: string) => boolean;
};

export const useFormsLocations = (): UseFormsLocationsResults => {
  const queryClient = useQueryClient();

  const { selectedLocationIds } = useAppScopeStore();

  const {
    currentHash,
    setIsValidatingLocations,
    setValidFormsLocations,
    setInvalidFormsLocations,
    setCurrentHash,
    validFormsLocations,
    isValidatingLocations,
    invalidFormsLocations,
  } = useFormsLocationStore([
    'currentHash',
    'setIsValidatingLocations',
    'setValidFormsLocations',
    'setInvalidFormsLocations',
    'setCurrentHash',
    'validFormsLocations',
    'isValidatingLocations',
    'invalidFormsLocations',
  ]);

  const invalidateCache = useCallback(
    (hash: string, skipIfSameLocation = false) => {
      if (!hash) {
        return Promise.resolve();
      }

      const locIds = FormsHashUtils.getLocationIdsFromHash(hash);

      if (skipIfSameLocation) {
        if (selectedLocationIds.sort().join('') === locIds.sort().join('')) {
          return Promise.resolve();
        }
      }

      const queryKey = generateQueryKey(FormsQueryKeys.validateLocation, locIds);
      return queryClient.invalidateQueries(queryKey);
    },
    [queryClient, selectedLocationIds]
  );

  const isValidFormsLocation = useCallback(
    (locationId: string) => {
      return validFormsLocations.includes(locationId);
    },
    [validFormsLocations]
  );

  const {
    data,
    isError,
    isLoading,
    refetch: refreshFormsLocationValidity,
    isFetching,
    isRefetching,
    isStale,
  } = useQuery({
    queryFn: () => validateLocations(selectedLocationIds),
    queryKey: generateQueryKey(FormsQueryKeys.validateLocation, selectedLocationIds),
    enabled: selectedLocationIds.length > 0,
  });

  useEffect(() => {
    if (isLoading || isFetching) {
      setIsValidatingLocations(true);
    }
  }, [isLoading, isFetching]);

  useEffect(() => {
    if (!data || isRefetching || isLoading || isError || isFetching || isStale || currentHash === data.hash) {
      return;
    }

    // Invalidate the previous location(s) query cache when locations change
    if (currentHash && currentHash !== data.hash) {
      invalidateCache(currentHash, true);
    }

    const validLocations: string[] = [];
    const invalidLocations: string[] = [];

    data.data.forEach((location) => {
      if (location.isValidLocation) {
        validLocations.push(location.locationId);
      } else {
        invalidLocations.push(location.locationId);
      }
    });

    setValidFormsLocations(validLocations);
    setInvalidFormsLocations(invalidLocations);
    setCurrentHash(data.hash);
    setIsValidatingLocations(false);
  }, [data, isError, isLoading, isRefetching, isFetching, isStale, currentHash, invalidateCache]);

  const results = useMemo<UseFormsLocationsResults>(() => {
    return {
      refreshFormsLocationValidity,
      isValidFormsLocation,
      isValidatingLocations,
      invalidFormsLocations,
      validFormsLocations,
    };
  }, [
    refreshFormsLocationValidity,
    isValidFormsLocation,
    isValidatingLocations,
    invalidFormsLocations,
    validFormsLocations,
  ]);

  return results;
};
