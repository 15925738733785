import { PaymentPlanPaymentType } from '@frontend/api-payment-plans';
import { SchemaIO, SchemaPaymentsCommandsService } from '@frontend/schema';

export interface CreatePaymentIntentBody {
  amount: number;
  origin: string;
  paymentId?: string;
  paymentMethodId?: string;
  person?: {
    id?: string;
    firstName?: string;
    lastName?: string;
    homePhone?: string;
    mobilePhone?: string;
    email?: string;
  };
  receiptEmail?: string;
  cardholderName?: string;
  setupFutureUsage?: SetupFutureUsage | boolean | null;
  paymentMethodType?: PaymentMethodTypeEnum;
  paymentPlanPayment?: {
    planId: string;
    paymentType: PaymentPlanPaymentType;
  };
}

export interface PaymentIntent {
  clientSecret: string;
  id: string;
}

export interface PaymentClientSecret {
  paymentIntent: PaymentIntent;
  links: {
    method: string;
  };
}

export enum SetupFutureUsage {
  onSession = 'ON_SESSION',
  offSession = 'OFF_SESSION',
}

export enum SetupFutureUsageElementsProp {
  OnSession = 'on_session',
  OffSession = 'off_session',
}

export type CreatePaymentIntentResponse = {
  links: { self: string };
  paymentToken: string;
  paymentId?: string;
};

export interface CreatePaymentIntentResponseData {
  data: CreatePaymentIntentResponse;
}

export enum PaymentIntentStatus {
  Succeeded = 'succeeded',
}

export type PaymentIntentWithStatus = { status?: PaymentIntentStatus };

export type PaymentRequestLinks = {
  self: string;
  payment: string | null;
  payments: string;
  attachment: string | null;
  locationLogo: string;
  paymentMethods: string;
  processing: string | null;
};

export interface PaymentRequest {
  location: {
    id: string;
  };
  amount: number;
  links: PaymentRequestLinks;
  payment_intent?: PaymentIntentWithStatus;
  paymentId: string;
  person: {
    id: string;
    firstName: string;
    lastName: string;
    hasBirthDate: string;
  };
  requestId: string;
  onBehalfOf?: string;
  skipAttachmentAuth?: boolean;
}

export interface LocationPortalResponse {
  location: {
    name: string;
    id: string;
    email?: string;
    statementDescriptor?: string;
    phoneNumber?: string;
    address?: string;
  };
  links: {
    self?: string;
    attachment?: string;
    locationLogo?: string;
    payments?: string;
    paymentPortal?: string;
    payment?: string;
    paymentMethods?: string;
  };
  origin: string;
  onBehalfOf?: string;
  onlineBillPayEnabled: boolean;
}
export interface Payment {
  amount?: number;
  cardholderName?: string;
  receiptEmail?: string;
  setupFutureUsage?: boolean;
  paymentMethodType?: PaymentMethodTypeEnum;
}

export enum PaymentMethodTypeEnum {
  CARD = 'PAYMENT_METHOD_CARD',
  AFFIRM = 'PAYMENT_METHOD_AFFIRM',
  LINK = 'PAYMENT_METHOD_LINK',
  ACH = 'PAYMENT_METHOD_ACH',
}

export type ValidateACHTransactionBalanceRequest = SchemaIO<
  (typeof SchemaPaymentsCommandsService)['ValidateACHTransactionBalance']
>['input'] & {
  locationId: string;
};

export type ValidateACHTransactionBalanceResponse = SchemaIO<
  (typeof SchemaPaymentsCommandsService)['ValidateACHTransactionBalance']
>['output'];
