import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { Direction as CallDirection, CallStatus } from '@weave/schema-gen-ts/dist/shared/phone/v1/callrecord/enums.pb';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  Chip,
  IconProps as DesignSystemIconProps,
  ModalControlModalProps,
  TableFilters,
} from '@frontend/design-system';
import { useActiveLocationsMap } from '../../hooks/use-active-location-map';
import { CallRecordsFiltersType, DEFAULT_TIME_PERIOD } from '../../hooks/use-phone-config-state-store';
import { formatDate } from '../../utils';
import { CheckListSelector } from '../filter-selectors/check-list-selector';
import { CheckListPopoverSelector } from '../filter-selectors/checklist-popover-selector';
import { DatesRangeSelector } from '../filter-selectors/dates-range-selector';
import { filterSelectorError, LocationsSelector } from '../filter-selectors/locations-selectors';
import { RadioFilterSelector } from '../filter-selectors/radio-filter-selector';
import { TimePeriodPopoverSelector } from '../filter-selectors/time-period-select-popover';
import { TimePeriodSelector } from '../filter-selectors/time-period-selector';

interface FilterProps {
  defaultFilters: CallRecordsFiltersType;
  filters: CallRecordsFiltersType;
  isMulti: boolean;
  onChange: (args: CallRecordsFiltersType) => void;
}

type TableTrayFilterProps = {
  modalProps: ModalControlModalProps;
  setShowNotificationBadge: (showNotification: boolean) => void;
};

type IconProps = DesignSystemIconProps & {
  isFilterActive?: boolean;
};

const LocationIcon: React.FC<IconProps> = ({ isFilterActive }) => {
  return <Icon name='location-small' color={isFilterActive ? 'white' : 'default'} />;
};

const isDateEqual = (date1: string | undefined, date2: string | undefined) => {
  if (!date1 || !date2) {
    return false;
  }
  if (date1 === date2) {
    return true;
  }
  if (date1.length !== date2.length && date1.length >= 7 && date2.length >= 7) {
    return date1.slice(0, 7) === date2.slice(0, 7);
  }
  return false;
};

function isEmptyOrUndefined(str: string | undefined): boolean {
  return !str || str.trim() === '';
}

export const RecentCallsTrayFilter = ({
  defaultFilters,
  filters,
  modalProps,
  onChange,
  isMulti,
  setShowNotificationBadge,
}: FilterProps & TableTrayFilterProps) => {
  const { t } = useTranslation('calls', { keyPrefix: 'calls-filter' });

  const allLocationsIdToNameMap = useActiveLocationsMap();

  const [trayFilters, setTrayFilters] = useState<CallRecordsFiltersType>(defaultFilters);
  const [filterErrors, setFilterErrors] = useState<Record<string, boolean>>({});

  const hasFilterErrors = useMemo(() => Object.values(filterErrors).some((error) => error), [filterErrors]);

  const handleUpdateTrayFilters = (newFilters: Partial<CallRecordsFiltersType>) => {
    setTrayFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  const handleFilterErrors = (error: filterSelectorError) => {
    setFilterErrors((prevErrors) => ({ ...prevErrors, [error.name]: error.value }));
  };

  useEffect(() => {
    setTrayFilters({
      ...filters,
      locationIds: filters.locationIds,
      startDate: filters.startDate,
      endDate: filters.endDate,
    });
    hasFilterChanged(filters) ? setShowNotificationBadge(true) : setShowNotificationBadge(false);
  }, [filters.locationIds, filters.startDate, filters.endDate]);

  const hasFilterChanged = (filter: CallRecordsFiltersType): boolean => {
    if (
      filter.locationIds.length == defaultFilters.locationIds.length &&
      isDateEqual(filter.startDate, defaultFilters.startDate) &&
      isDateEqual(filter.endDate, defaultFilters.endDate) &&
      filter.status.length == 0 &&
      (isEmptyOrUndefined(filter.callDirection) || filter.callDirection == CallDirection.DIRECTION_UNKNOWN)
    ) {
      return false;
    }
    return true;
  };

  const handleApplyTableFilters = () => {
    hasFilterChanged(trayFilters) ? setShowNotificationBadge(true) : setShowNotificationBadge(false);
    onChange(trayFilters);
    modalProps?.onClose();
  };

  const handleFiltersReset = () => {
    onChange(defaultFilters);
    setShowNotificationBadge(false);
    modalProps?.onClose();
  };

  const callDirectionOptions = {
    [CallDirection.DIRECTION_INBOUND]: t('Inbound Calls'),
    [CallDirection.DIRECTION_OUTBOUND]: t('Outbound Calls'),
  };

  const callResult = {
    [CallStatus.CALL_STATUS_MISSED]: (
      <Chip isResponsive variant={'critical'}>
        {'Missed'}
      </Chip>
    ),
    [CallStatus.CALL_STATUS_ABANDONED]: (
      <Chip isResponsive variant={'warn'}>
        {'Abandoned'}
      </Chip>
    ),
    [CallStatus.CALL_STATUS_ANSWERED]: (
      <Chip isResponsive variant={'primary'}>
        {'Answered'}
      </Chip>
    ),
    [CallStatus.CALL_STATUS_FORWARDED]: (
      <Chip isResponsive variant={'eggplant'}>
        {'Forwarded'}
      </Chip>
    ),
  };

  const renderTableTrayFilters = () => (
    <TableFilters
      width='medium'
      {...modalProps}
      onApplyClick={handleApplyTableFilters}
      onClearAllClick={handleFiltersReset}
      disableApplyButton={hasFilterErrors}
    >
      {isMulti && (
        <TableFilters.Section sectionHeaderLabel={t('Filter')}>
          <div css={styles.traySection}>
            <LocationsSelector
              allLocationsOption={allLocationsIdToNameMap}
              onChange={(selLocationIds) => handleUpdateTrayFilters({ locationIds: selLocationIds })}
              onError={(err) => {
                handleFilterErrors(err);
              }}
              selectedLocationsValue={trayFilters.locationIds}
              trackingId='recent-calls-location-selector-table-filter'
            />
          </div>
        </TableFilters.Section>
      )}

      <TableFilters.Section sectionHeaderLabel={t('Date Range')}>
        <div css={styles.traySection}>
          <TimePeriodSelector
            defaultPeriod={DEFAULT_TIME_PERIOD}
            onChange={({ endDate, startDate }) => {
              handleUpdateTrayFilters({ startDate, endDate });
            }}
            startDate={trayFilters.startDate}
            endDate={trayFilters.endDate}
            trackingId='recent-calls-time-selector-table-filter'
          />
          <DatesRangeSelector
            onChange={({ endDate, startDate }) => {
              handleUpdateTrayFilters({ startDate: formatDate(startDate), endDate: formatDate(endDate) });
            }}
            startDate={trayFilters.startDate}
            endDate={trayFilters.endDate}
          />
        </div>
      </TableFilters.Section>

      <TableFilters.Section
        onClearClick={() => handleUpdateTrayFilters({ status: [] })}
        sectionHeaderLabel={t('Results')}
      >
        <div css={styles.traySection}>
          <CheckListSelector<CallStatus>
            label={t('Results')}
            onChange={(result) => {
              handleUpdateTrayFilters({ status: result });
            }}
            options={callResult}
            showLabel={false}
            value={trayFilters.status}
            trackingIdBase='recent-calls-result-selector-table-filter'
          />
        </div>
      </TableFilters.Section>

      <TableFilters.Section
        onClearClick={() => handleUpdateTrayFilters({ callDirection: CallDirection.DIRECTION_UNKNOWN })}
        sectionHeaderLabel={t('Call Direction')}
      >
        <div css={styles.traySection}>
          <RadioFilterSelector<CallDirection>
            label={t('Call Direction')}
            onChange={(direction) => {
              handleUpdateTrayFilters({ callDirection: direction });
            }}
            options={callDirectionOptions}
            showLabel={false}
            value={trayFilters.callDirection}
            withBorder={false}
            trackingId='recent-calls-call-direction-selector-table-filter'
          />
        </div>
      </TableFilters.Section>
    </TableFilters>
  );

  return renderTableTrayFilters();
};

export const RecentCallsChipFilter = ({ defaultFilters, filters, isMulti, onChange }: FilterProps) => {
  const { t } = useTranslation('calls', { keyPrefix: 'calls-filter' });

  const allLocationsIdToNameMap = useActiveLocationsMap();

  const renderChipFilters = () => (
    <>
      {isMulti && (
        <CheckListPopoverSelector<string>
          customStyles={{ chipDropdownFilter: { width: '100%' } }}
          label={t('Location')}
          onChange={(locations) => {
            onChange({ ...filters, locationIds: locations });
          }}
          options={allLocationsIdToNameMap}
          defaultValue={defaultFilters.locationIds}
          value={filters.locationIds}
          renderIcon={(isFilterActive) => <LocationIcon isFilterActive={isFilterActive} />}
          trackingId='recent-calls-location-selector-chip-filter'
        />
      )}
      <TimePeriodPopoverSelector
        defaultPeriod={DEFAULT_TIME_PERIOD}
        onChange={({ endDate, startDate }) => {
          onChange({ ...filters, startDate, endDate });
        }}
        startDate={filters.startDate}
        endDate={filters.endDate}
        trackingId='recent-calls-time-selector-chip-filter'
      />
    </>
  );

  return (
    <div css={styles.mainWrapper} style={{ flexGrow: 1, margin: 0 }}>
      {renderChipFilters()}
    </div>
  );
};

const styles = {
  heading: css`
    > label {
      font-weight: 700;
      font-size: ${theme.fontSize(20)};
    }
  `,
  mainWrapper: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(2)};
    margin-bottom: ${theme.spacing(3)};
    position: relative;
  `,

  traySection: css`
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
  `,
};
