import { UIEventHandler, useCallback, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { throttle } from 'lodash-es';
import { useTranslation } from '@frontend/i18n';
import { IntakePrefixes } from '@frontend/tracking-prefixes';
import { theme } from '@frontend/theme';
import { ContentLoader, Modal, ModalControlModalProps, Text, TextLink } from '@frontend/design-system';
import { useSelectIntakeFormMutation } from '../../../selectors/intake-form.selectors';

interface Props {
  modalProps: ModalControlModalProps;
  onConfirmClick: () => void;
}

const BOTTOM_SCROLL_HEIGHT = 16;

export const E911TermsConfirmModal = ({ modalProps, onConfirmClick }: Props) => {
  const { t } = useTranslation('onboarding');
  const [hasScrolledThroughTerms, setHasScrolledThroughTerms] = useState(false);
  const { isLoading: isUpdatingIntakeForm } = useSelectIntakeFormMutation();
  let throttledScroll: ReturnType<typeof throttle> | null = null;

  const throttleFunc = useCallback(
    (throttledScroll = throttle((event) => {
      const target = event.target;
      if (target) {
        const height = target.getBoundingClientRect().height;
        const targetScrollHeight = target.scrollHeight - BOTTOM_SCROLL_HEIGHT;
        if (targetScrollHeight <= target.scrollTop + height) {
          setHasScrolledThroughTerms(true);
        }
      }
    }, 300)),
    []
  );

  useEffect(() => {
    if (modalProps.show) {
      const modalEl = document.getElementById('legalTextModalBody');
      if (modalEl) {
        const targetScrollHeight = modalEl.scrollHeight - (BOTTOM_SCROLL_HEIGHT ?? 0);
        const height = modalEl.getBoundingClientRect().height;
        if (targetScrollHeight <= modalEl.scrollTop + height) {
          setHasScrolledThroughTerms(true);
        }
      }
    }
  }, [modalProps.show]);

  const handleScroll: UIEventHandler<HTMLElement> = (event) => {
    throttleFunc(event);
  };

  const handleCloseTermsModal = () => {
    modalProps.onClose();
    setHasScrolledThroughTerms(false);
  };

  useEffect(() => {
    return () => {
      if (throttledScroll) {
        throttledScroll.cancel();
      }
    };
  }, []);

  return (
    <Modal {...modalProps} maxWidth={800} onClose={handleCloseTermsModal}>
      <Modal.Header>{t('Emergency 9-1-1 Setup Terms and Conditions')}</Modal.Header>
      <Modal.Body onScroll={handleScroll} id='legalTextModalBody'>
        <Text textAlign='center' css={{ marginBottom: theme.spacing(1) }}>
          {t(
            'We want to make sure that you are aware of important differences in the way 9-1-1 service operates with a VoIP phone when compared with traditional telephone service. Here is what you need to keep in mind:'
          )}
        </Text>

        <ul css={confirmModalListStyle}>
          <Text as='li'>
            {t(
              'With VoIP phone service, your 9-1-1 call is forwarded to a third-party service provider that will automatically or manually route your call to the emergency response center.'
            )}
          </Text>
          <Text as='li'>
            {t(
              'Because you can move your VoIP phone between locations, you must immediately inform the emergency operator of your location and contact information any time you call 9-1-1.'
            )}
          </Text>
          <Text as='li'>
            {t(
              'Weave will attempt to provide the emergency operator with your service address, so please ensure that your information on file with us is always accurate and updated. If you are unable to provide your location to the emergency operator, emergency services will be dispatched to the address you have provided and may be misdirected to the wrong location.'
            )}
          </Text>
          <Text as='li'>
            {t(
              'VoIP phone service depends not only on your continued subscription for the service, but also on Internet connectivity and power to function. In the event of power, network, or Internet outages, or if your service is disconnected or suspended, you may experience a failure, disruption or delay in your 9-1-1 service. Do not disconnect your 9-1-1 call until you are told to do so by the emergency operator.'
            )}
          </Text>
          <Text as='li'>
            {t(
              'You must notify your staff and other users of your VoIP phone service of the nature and limitations of 9-1-1 emergency calls. Weave has provided stickers that can be placed on your phones for this purpose.'
            )}
          </Text>
          <Text as='li'>
            {t(
              'If you are not comfortable with the limitations of 9-1-1 emergency calls, we recommend that you terminate the VoIP services or consider an alternate means for accessing traditional 9-1-1 emergency services, such as a cellular telephone.'
            )}
          </Text>
        </ul>

        <Text textAlign='center' css={{ marginBottom: theme.spacing(3) }}>
          {t(
            'To review the 9-1-1 limitations, including 9-1-1 disclaimers and limitations of liability, and learn more about E9-1-1 service, visit'
          )}{' '}
          <TextLink to='https://www.getweave.com/legal/911' size='large' target='_blank' rel='noreferrer'>
            https://www.getweave.com/legal/911.
          </TextLink>
        </Text>

        <Text weight='bold' textAlign='center'>
          {t(
            'By proceeding you are agreeing that you have read and understand the 9-1-1 service limitations described in t he VoIP Terms of Service available at'
          )}{' '}
          <TextLink to='https://www.getweave.com/legal/voip' size='large' target='_blank' rel='noreferrer'>
            https://www.getweave.com/legal/voip.
          </TextLink>
        </Text>
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('I agree')}
        disablePrimary={isUpdatingIntakeForm || !hasScrolledThroughTerms}
        onSecondaryClick={handleCloseTermsModal}
        onPrimaryClick={onConfirmClick}
        secondaryTrackingId={`${IntakePrefixes.BusinessInformation}-e911-modal-cancel-btn`}
        primaryTrackingId={`${IntakePrefixes.BusinessInformation}-e911-modal-i-agree-btn`}
      />
      <ContentLoader show={isUpdatingIntakeForm} size='small' />
    </Modal>
  );
};

const confirmModalListStyle = css`
  margin: ${theme.spacing(3, 0, 1, 0)};
  li {
    margin-bottom: ${theme.spacing(2)};
  }
`;
