import { MessageType_Enum } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { NotificationType } from '@weave/schema-gen-ts/dist/shared/notification/notifications.pb';
import { MessagesHooks } from '@frontend/api-messaging';
import { useWebsocketEventSubscription } from '@frontend/websocket';

export const useSMSPreferenceEventSubscription = () => {
  const { update } = MessagesHooks.useUpdateCheckSMSPreference();

  return useWebsocketEventSubscription(NotificationType.MANUAL_MESSAGE_CONSENT, (payload) => {
    if (payload.method === NotificationType.MANUAL_MESSAGE_CONSENT) {
      const { consented, ...preferenceOptions } = payload.params;
      update(
        {
          messageType: MessageType_Enum.MESSAGING_MANUAL,
          ...preferenceOptions,
        },
        { consented }
      );
    }
  });
};
