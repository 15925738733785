import * as activePatientsSummary from './active-patients-summary';
import * as cancellations from './cancellations';
import * as hygieneFollowUp from './hygiene-follow-up';
import * as hygieneTreatmentPlan from './hygiene-treatment-plan';
import * as monthlyTrendData from './monthlyTrendData';
import * as morningHuddle from './morning-huddle';
import * as newPatients from './new-patients';
import * as opportunityList from './opportunity-list';
import * as practitionerAnalysis from './practitioner-analysis';
import * as recapturedPatients from './recaptured-patients';
import * as restorativeTreatmentPlan from './restorative-treatment-plan';

export const PracticeAnalyticsMockData = {
  ...activePatientsSummary,
  ...cancellations,
  ...hygieneFollowUp,
  ...hygieneTreatmentPlan,
  ...monthlyTrendData,
  ...morningHuddle,
  ...newPatients,
  ...opportunityList,
  ...practitionerAnalysis,
  ...recapturedPatients,
  ...restorativeTreatmentPlan,
};
