import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { ContactType_Enum, PersonStatus_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import { PersonTypes } from '@frontend/api-person';
import { titleCase } from '@frontend/string';
import { getPersonContactInfoByType } from './utils';

export const transformToLegacyPerson = (person: Person) => {
  return {
    PreferredName: person.preferredName ?? '',
    FirstName: person.firstName ?? '',
    LastName: person.lastName ?? '',
    PersonID: person.personId,
    ContactInfo: person.contactInfo,
    Notes: person.notes ?? '',
    SourceID: person.sourceId ?? '',
    MobilePhone: getPersonContactInfoByType({
      contactInfo: person?.contactInfo,
      contactType: ContactType_Enum.PHONE_MOBILE,
    }),
    Email: getPersonContactInfoByType({ contactInfo: person?.contactInfo, contactType: ContactType_Enum.EMAIL }),
    HomePhone: getPersonContactInfoByType({
      contactInfo: person?.contactInfo,
      contactType: ContactType_Enum.PHONE_HOME,
    }),
    PMID: person.personPmid ?? '',
    PMDisplayId: person.personDisplayPmid ?? '',
    Gender: person.gender ?? '',
    Status: titleCase(PersonStatus_Enum[person.status || PersonStatus_Enum.UNSPECIFIED]),
    Birthdate: person.birthdate ?? '',
  } as unknown as PersonTypes.Person;
};
