import { useCallback } from 'react';
import { CalendarEvent } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/calendar_event.pb';
import { Practitioner } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/practitioner.pb';
import { Schedule } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/schedule.pb';
import { uniqBy } from 'lodash-es';
import { ProviderEventType } from '../../components/calendar-view/types';
import { getFullName } from '../../utils';
import { useGetCalendarEventsV3 } from './use-get-calendar-events-v3';

type UseGetPractitionerDetailsForCalendarV3Params = {
  calendarEvents: CalendarEvent[];
  practitioners: Practitioner[];
  schedules: Schedule[];
};

export const useGetPractitionerDetailsForCalendarV3 = ({
  calendarEvents,
  practitioners,
  schedules,
}: UseGetPractitionerDetailsForCalendarV3Params) => {
  const { getPractitionerCalendarEvents, getEntityBreakEvents, getEntityExceptionEvents } = useGetCalendarEventsV3();

  const getPractitionerEventsForCalendarObject = useCallback(
    (locationId: string): ProviderEventType[] => {
      return (
        practitioners
          .filter(({ locationId: providerLocationId }) => providerLocationId === locationId)
          .map((practitioner) => {
            const practitionerId = practitioner.id ?? '';

            const calendarEventList = getPractitionerCalendarEvents({
              calendarEvents,
              locationId,
              practitionerId,
            });

            const breakEventList = getEntityBreakEvents({
              schedules,
              locationId,
              entityId: practitionerId,
            });

            const exceptionEventList = getEntityExceptionEvents({
              schedules,
              locationId,
              entityId: practitionerId,
            });

            return {
              name: getFullName(practitioner.firstName, practitioner.lastName),
              providerId: practitionerId,
              src: practitioner.displayName || '',
              events: uniqBy(
                [...calendarEventList, ...breakEventList, ...exceptionEventList],
                (event) => event.eventId
              ),
            };
          }) ?? []
      );
    },
    [calendarEvents, practitioners, schedules]
  );

  return { getPractitionerEventsForCalendarObject };
};
