import { FC, useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import dayjs, { Dayjs } from 'dayjs';
import { gql } from 'graphql-request';
import { MorningHuddleTypes, PracticeAnalyticsAggregations, PracticeAnalyticsApi } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  AssignUserIcon,
  CalendarIcon,
  ContentLoader,
  NakedUl,
  UnmarkIcon,
  UpdateIcon,
  UserManagementIcon,
  WellnessIcon,
  useAlert,
} from '@frontend/design-system';
import { LineSaperator } from '..';
import { queryKeys } from '../../query-keys';
import { convertToDateId, formatters } from '../../utils';
import { usePracticeAnalyticsShallowStore } from '../practice/hooks';
import { ProductionTypeCard } from './production-type-card';

const query = gql`
  query ($date: Int!) {
    location {
      id
      cancellations: missedAppointments {
        totals(start: $date, end: $date, step: DAILY) {
          cancelled
          cancelledUnscheduled
        }
      }
      hygieneReappointment {
        totals(start: $date, end: $date, step: DAILY) {
          patients
          percentageSameDay
          unscheduled
        }
      }
      hygieneTx: treatmentPlan(treatmentPlanType: HYGIENE) {
        totals(start: $date, end: $date, step: DAILY) {
          diagnosed
          diagnosedPercent
          unscheduled
          unscheduledTreatment
        }
      }
      newPatients {
        totals(start: $date, end: $date, step: DAILY) {
          patients
          production
          needsAppointment
          percentUnscheduled
        }
      }
      overdue: unscheduledPatients(type: ANY, overdueOnly: true) {
        totals {
          patients
        }
      }
      production {
        details(start: $date, end: $date, step: DAILY) {
          totalProductionPerVisit
          totalVisits
        }
        totals(start: $date, end: $date, step: DAILY) {
          completedProduction
          scheduledProduction
          sameDayProduction
        }
      }
      recaptured: rescheduledPatients {
        totals(start: $date, end: $date, step: DAILY) {
          patients
          production
        }
      }
      restorativeTx: treatmentPlan(treatmentPlanType: RESTORATIVE) {
        totals(start: $date, end: $date, step: DAILY) {
          diagnosed
          diagnosedPercent
          unscheduled
          unscheduledTreatment
        }
      }
      unscheduled: unscheduledPatients(type: ANY, overdueOnly: false) {
        totals {
          patients
        }
      }
    }
  }
`;

export type ProductionSummaryConfig = {
  formattedValue: string;
  label: string;
};

export type ProductionSummary = {
  completedProduction?: ProductionSummaryConfig;
  date: Dayjs;
  sameDayProduction?: ProductionSummaryConfig;
  scheduledProduction?: ProductionSummaryConfig;
  totalProductionPerVisit?: ProductionSummaryConfig;
  totalVisits?: ProductionSummaryConfig;
};

type LastDayProductionProps = {
  onLoadData?: (data: ProductionSummary) => void;
  onLoadingStatusChange?: (isLoading?: boolean) => void;
  reportDate: string;
};

export const ProductionTypes: FC<React.PropsWithChildren<LastDayProductionProps>> = ({
  onLoadData,
  onLoadingStatusChange,
  reportDate,
}) => {
  const { t } = useTranslation('analytics');
  const alert = useAlert();
  const { demoData, filters, isDemoAccount } = usePracticeAnalyticsShallowStore('demoData', 'filters', 'isDemoAccount');
  const [dayBefore, setDayBefore] = useState<Dayjs>(dayjs(reportDate).subtract(1, 'days'));

  const { data, isLoading } = useScopedQuery({
    queryKey: queryKeys.morningHuddle(
      `lastDayProduction-${isDemoAccount}-${dayBefore.format('YYYY-MM-DD')}-${filters.locations}`
    ),
    queryFn: () =>
      isDemoAccount || !filters.locations?.length
        ? null
        : PracticeAnalyticsApi.getPracticeAnalyticsRecords<MorningHuddleTypes.LastDayProductionResponse>({
            locationIds: filters.locations,
            queries: [query],
            variables: {
              date: convertToDateId(dayBefore),
            },
          }),
    onError: () => {
      alert.error(t("Couldn't load the dashboard data. Please try again."));
    },
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => (isDemoAccount ? demoData?.morningHuddleLastDayProduction : data),
    staleTime: 1000 * 60 * 5,
  });

  const aggregatedData = useMemo(() => {
    const updatedData = PracticeAnalyticsAggregations.morningHuddleProductionTypes(data, filters.locations?.length);

    onLoadData?.({
      date: dayBefore,
      // Maintain the following data order for the displaying them in the same sequence in the UI
      totalVisits: {
        formattedValue: formatters.value.format(updatedData.production.details.totalVisits),
        label: t('Visits'),
      },
      totalProductionPerVisit: {
        formattedValue: formatters.currency.format(updatedData.production.details.totalProductionPerVisit),
        label: t('Production Per Visit'),
      },
      completedProduction: {
        formattedValue: formatters.currency.format(updatedData.production.totals.completedProduction),
        label: t('Completed Production'),
      },
      scheduledProduction: {
        formattedValue: formatters.currency.format(updatedData.production.totals.scheduledProduction),
        label: t('Scheduled Production'),
      },
      sameDayProduction: {
        formattedValue: formatters.currency.format(updatedData.production.totals.sameDayProduction),
        label: t('Same Day Production'),
      },
    });

    return updatedData;
  }, [data]);

  useEffect(() => {
    onLoadingStatusChange?.(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setDayBefore(dayjs(reportDate).subtract(1, 'days'));
  }, [reportDate]);

  return (
    <section css={styles.wrapper}>
      <LineSaperator label={t('Production Type')} />
      <NakedUl css={styles.productionTypeList}>
        <ProductionTypeCard
          data={[
            {
              name: t('Patients'),
              value: formatters.value.format(aggregatedData.recaptured.totals.patients),
            },
            {
              name: t('Production'),
              value: formatters.currency.format(aggregatedData.recaptured.totals.production),
            },
          ]}
          Icon={AssignUserIcon}
          title={t('Recaptured')}
        />
        <ProductionTypeCard
          data={[
            {
              name: t('Diagnosed'),
              value: `${formatters.value.format(
                aggregatedData.restorativeTx.totals.diagnosed
              )} (${formatters.percent.format(aggregatedData.restorativeTx.totals.diagnosedPercent)})`,
            },
            {
              actionable:
                !!aggregatedData.restorativeTx.totals.unscheduled ||
                !!aggregatedData.restorativeTx.totals.unscheduledTreatment,
              name: t('Unscheduled'),
              value: `${formatters.value.format(
                aggregatedData.restorativeTx.totals.unscheduled
              )} (${formatters.currency.format(aggregatedData.restorativeTx.totals.unscheduledTreatment)})`,
            },
          ]}
          Icon={WellnessIcon}
          title={t('Restorative Treatment')}
        />
        <ProductionTypeCard
          data={[
            {
              name: t('Patients'),
              value: `${formatters.value.format(
                aggregatedData.newPatients.totals.patients
              )} (${formatters.currency.format(aggregatedData.newPatients.totals.production)})`,
            },
            {
              actionable: !!aggregatedData.newPatients.totals.needsAppointment,
              name: t('Not Rescheduled'),
              value: `${formatters.value.format(
                aggregatedData.newPatients.totals.needsAppointment
              )} (${formatters.percent.format(aggregatedData.newPatients.totals.percentUnscheduled)})`,
            },
          ]}
          Icon={UserManagementIcon}
          title={t('New Patients')}
        />
        <ProductionTypeCard
          data={[
            {
              name: t('Diagnosed'),
              value: `${formatters.value.format(
                aggregatedData.hygieneTx.totals.diagnosed
              )} (${formatters.percent.format(aggregatedData.hygieneTx.totals.diagnosedPercent)})`,
            },
            {
              actionable:
                !!aggregatedData.hygieneTx.totals.unscheduled || !!aggregatedData.hygieneTx.totals.unscheduledTreatment,
              name: t('Unscheduled'),
              value: `${formatters.value.format(
                aggregatedData.hygieneTx.totals.unscheduled
              )} (${formatters.currency.format(aggregatedData.hygieneTx.totals.unscheduledTreatment)})`,
            },
          ]}
          Icon={WellnessIcon}
          title={t('Hygiene Treatment')}
        />
        <ProductionTypeCard
          data={[
            {
              name: t('Follow-Ups'),
              value: `${formatters.value.format(
                aggregatedData.hygieneReappointment.totals.patients
              )} (${formatters.percent.format(aggregatedData.hygieneReappointment.totals.percentageSameDay)})`,
            },
            {
              actionable: !!aggregatedData.hygieneReappointment.totals.unscheduled,
              name: t('Not Rescheduled'),
              value: formatters.value.format(aggregatedData.hygieneReappointment.totals.unscheduled),
            },
          ]}
          Icon={UpdateIcon}
          title={t('Hygiene Follow-Up')}
        />
        <ProductionTypeCard
          data={[
            {
              actionable: !!aggregatedData.unscheduled.totals.patients,
              name: t('Patients'),
              value: formatters.value.format(aggregatedData.unscheduled.totals.patients),
            },
            {
              actionable: !!aggregatedData.overdue.totals.patients,
              name: t('Past Due'),
              value: formatters.value.format(aggregatedData.overdue.totals.patients),
            },
          ]}
          Icon={CalendarIcon}
          title={t('Unscheduled')}
        />
        <ProductionTypeCard
          data={[
            {
              actionable: !!aggregatedData.cancellations.totals.cancelled,
              name: t('Patients'),
              value: formatters.value.format(aggregatedData.cancellations.totals.cancelled),
            },
            {
              actionable: !!aggregatedData.cancellations.totals.cancelledUnscheduled,
              name: t('Not Rescheduled'),
              value: formatters.value.format(aggregatedData.cancellations.totals.cancelledUnscheduled),
            },
          ]}
          Icon={UnmarkIcon}
          title={t('Cancellations')}
        />
      </NakedUl>
      <ContentLoader show={isLoading} size='small' />
    </section>
  );
};

const styles = {
  wrapper: css`
    margin-bottom: ${theme.spacing(3)};
    position: relative;
  `,

  productionTypeList: css`
    gap: ${theme.spacing(3)};
    display: flex;
    flex-wrap: wrap;
  `,

  error: css`
    margin-top: ${theme.spacing(3)};
    text-transform: capitalize;
  `,
};
