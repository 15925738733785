import { Dispatch, SetStateAction, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { useQueryClient } from 'react-query';
import { DialQueries } from '@frontend/api-dialpad';
import { useTranslation } from '@frontend/i18n';
import { Icon, IconColorType } from '@frontend/icons';
import { usePhoneConfigStore } from '@frontend/phone-config';
import { useMutation } from '@frontend/react-query-helpers';
import { SchemaPhoneUserService } from '@frontend/schema';
import { theme } from '@frontend/theme';
import {
  CaretDownIcon,
  NakedButton,
  PopoverMenu,
  PopoverMenuItem,
  Text,
  TextLink,
  styles,
  usePopoverMenu,
  useTooltip,
} from '@frontend/design-system';
import { OutboundSelectedPhoneNumberProps } from '..';
import { formatPhoneNumber } from '../utils/phone-utils';

export const THEME_STYLES = {
  DARK: 'dark',
  LIGHT: 'light',
} as const;

type ThemeStyle = (typeof THEME_STYLES)[keyof typeof THEME_STYLES];

export type DeviceInformationProps = {
  deviceName: string;
  onChangeDeviceClick: () => void;
  changeDeviceText: string;
  showExtension: boolean;
  showOutboundCallerInfo?: boolean;
  extensionNumberText: string;
  currentPhoneNumberText: string | null;
  availablePhoneNumbers: { number: string; locationName: string }[];
  SelectOutboundIdMenuWrapper: React.FC<SelectOutboundIdMenuProps>;
  isPreferredNumber: boolean;
  themeStyle?: ThemeStyle;
  trackingId?: string;
};

const starIconSize = 12;

export const DeviceInformation = ({
  deviceName,
  onChangeDeviceClick,
  changeDeviceText,
  showExtension,
  extensionNumberText,
  currentPhoneNumberText,
  availablePhoneNumbers,
  SelectOutboundIdMenuWrapper,
  themeStyle = THEME_STYLES.DARK,
  showOutboundCallerInfo,
  isPreferredNumber,
  trackingId,
}: DeviceInformationProps) => {
  const { t } = useTranslation('phone');
  const showPreferredIcon = isPreferredNumber && availablePhoneNumbers.length > 1;
  const popoverMenuProps = usePopoverMenu({
    placement: 'bottom',
    middlewareOptions: {
      offset: ({ rects }) => {
        // this is a magic number we would need to figure out to be more dynamic to
        // 8 is a magic that matches the two css "gaps" we set below. 4 + 4.
        return {
          crossAxis: rects.reference.width / 2 - starIconSize - 8,
        };
      },
    },
  });
  const actionsTriggerProps = popoverMenuProps.getTriggerProps({
    onClick: (e) => {
      e.stopPropagation();
    },
  });

  const textColor = themeStyle === THEME_STYLES.DARK ? 'white' : 'default';

  return (
    <>
      <div
        css={css`
          display: flex;
          gap: ${theme.spacing(1)};
        `}
      >
        <Text
          as='div'
          weight='bold'
          color={textColor}
          css={css`
            line-height: normal;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: ${theme.spacing(21)};
          `}
        >
          {deviceName}
        </Text>
        <TextLink
          css={css`
            cursor: pointer;
          `}
          size='small'
          weight='bold'
          onClick={onChangeDeviceClick}
          trackingId={trackingId}
        >
          {changeDeviceText}
        </TextLink>
      </div>
      {showExtension && (
        <Text
          size='medium'
          css={css`
            color: ${theme.colors.neutral30};
          `}
        >
          {extensionNumberText}
        </Text>
      )}

      {showOutboundCallerInfo && (
        <div css={{ width: 'fit-content' }}>
          <Text
            size='medium'
            css={css`
              color: ${themeStyle === THEME_STYLES.DARK ? theme.colors.neutral30 : theme.colors.neutral50};
              display: flex;
              gap: ${theme.spacing(0.5)};
            `}
          >
            <Text
              size='medium'
              css={css`
                color: ${themeStyle === THEME_STYLES.DARK ? theme.colors.neutral30 : theme.colors.neutral50};
              `}
            >
              {t('Outbound Id')}:
            </Text>
            <div {...actionsTriggerProps} css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(0.5) }}>
              {showPreferredIcon && <Icon size={starIconSize} name='star-filled' color='warn' />}
              {currentPhoneNumberText}
              {availablePhoneNumbers.length > 1 && (
                <div
                  css={css`
                    cursor: pointer;
                  `}
                  data-trackingid='phones-generic-dialpad-modal-outboundID'
                >
                  <CaretDownIcon size={8} color={textColor} />
                </div>
              )}
            </div>
          </Text>
        </div>
      )}
      {availablePhoneNumbers.length > 1 && <SelectOutboundIdMenuWrapper {...popoverMenuProps} />}
    </>
  );
};

export type SelectOutboundIdMenuProps = {
  getMenuProps: ReturnType<typeof usePopoverMenu>['getMenuProps'];
  getItemProps: ReturnType<typeof usePopoverMenu>['getItemProps'];
  close: ReturnType<typeof usePopoverMenu>['close'];
  themeStyle?: 'dark' | 'light';
};

type SelectedOutboundPhoneNumberProps = {
  availablePhoneNumbers: OutboundSelectedPhoneNumberProps[];
  currentPhoneNumber: OutboundSelectedPhoneNumberProps | undefined;
  setCurrentPhoneNumber: Dispatch<SetStateAction<OutboundSelectedPhoneNumberProps | undefined>>;
};

type ActionItemProps = OutboundSelectedPhoneNumberProps & {
  onClick: () => void;
  onMouseOver: () => void;
  onMouseOut: () => void;
};

export const SelectOutboundIdMenu = ({
  getMenuProps,
  getItemProps,
  close: closePopover,
  availablePhoneNumbers,
  currentPhoneNumber,
  setCurrentPhoneNumber,
  themeStyle = 'dark',
}: SelectOutboundIdMenuProps & SelectedOutboundPhoneNumberProps) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [hoverIndex, setHoverIndex] = useState<number | null>(null);
  const actions: ActionItemProps[] = useMemo(
    () =>
      availablePhoneNumbers.map((availableNumber, i) => {
        if (currentPhoneNumber?.number === availableNumber.number) {
          setSelectedIndex(i);
        }

        return {
          ...availableNumber,
          onClick: () => {
            closePopover?.();
            setSelectedIndex(i);
            setCurrentPhoneNumber({ ...availableNumber });
          },
          onMouseOver: () => {
            setHoverIndex(i);
          },
          onMouseOut: () => {
            setHoverIndex(null);
          },
        };
      }),
    [availablePhoneNumbers]
  );

  const backgroundColor = themeStyle === 'dark' ? theme.colors.neutral80 : theme.colors.white;
  const textColor = themeStyle === 'dark' ? 'white' : 'default';
  const hoverFocusColor = themeStyle === 'dark' ? theme.colors.neutral70 : theme.colors.neutral10;

  return (
    <PopoverMenu
      {...getMenuProps()}
      initialFocus={selectedIndex}
      css={css`
        background-color: ${backgroundColor};
        button span {
          color: ${theme.colors.white};
        }
        button:hover {
          background-color: ${hoverFocusColor};
        }
        button:focus {
          background-color: ${hoverFocusColor};
        }
      `}
    >
      {actions.map(
        (
          { locationName, onClick, number, onMouseOver, onMouseOut, isPreferred, phoneNumberId }: ActionItemProps,
          index
        ) => (
          <PopoverMenuItem
            css={css`
              height: auto;
              display: grid;
              grid-template-areas: 'location icon' 'number icon';
              grid-template-columns: 1fr 24px;
              padding-top: ${theme.spacing(1)};
              padding-bottom: ${theme.spacing(1)};
              text-align: left;
              align-items: center;
              justify-content: space-between;
            `}
            key={number}
            {...getItemProps({
              index,
              onClick,
              onMouseOver,
              onMouseOut,
            })}
          >
            <Text as='div' color={textColor} weight='bold' css={[styles.truncate, { gridArea: 'location' }]}>
              {locationName}
            </Text>
            <Text as='div' color={textColor} css={{ gridArea: 'number' }}>
              {formatPhoneNumber(number ?? '')}
            </Text>
            <StarIcon
              isPreferred={isPreferred}
              phoneNumberId={phoneNumberId}
              textColor={textColor}
              setCurrentPhoneNumber={setCurrentPhoneNumber}
              currentPhoneNumber={currentPhoneNumber}
              hoverIndex={hoverIndex}
              index={index}
            />
          </PopoverMenuItem>
        )
      )}
    </PopoverMenu>
  );
};

type StarToolTip = {
  tooltipProps: ReturnType<typeof useTooltip>['tooltipProps'];
  Tooltip: ReturnType<typeof useTooltip>['Tooltip'];
  tooltipMessage: string;
};

const StarIcon = ({
  isPreferred,
  phoneNumberId,
  textColor,
  setCurrentPhoneNumber,
  currentPhoneNumber,
  hoverIndex,
  index,
}: {
  isPreferred: boolean;
  phoneNumberId: string;
  textColor: IconColorType | undefined;
  currentPhoneNumber: OutboundSelectedPhoneNumberProps | undefined;
  setCurrentPhoneNumber: Dispatch<SetStateAction<OutboundSelectedPhoneNumberProps | undefined>>;
  hoverIndex: number | null;
  index: number;
}) => {
  const { tooltipProps, triggerProps, Tooltip } = useTooltip();
  const queryClient = useQueryClient();
  const phoneConfigTenantId = usePhoneConfigStore((store) => store.phoneConfig?.tenantId);

  const tooltipMessage =
    !isPreferred && !!phoneNumberId ? 'Make preferred number' : isPreferred ? 'Preferred number' : 'Not available';

  const setPreferredNumber = useMutation({
    mutationFn: (phoneNumberId: string) => SchemaPhoneUserService.SetPreferredPhoneNumber({ phoneNumberId }),
    onSuccess: ({ phoneNumberId }) => {
      setCurrentPhoneNumber((prev) => {
        if (!prev) return;
        return {
          ...prev,
          isPreferred: phoneNumberId === currentPhoneNumber?.phoneNumberId ? true : false,
        };
      });
      queryClient.invalidateQueries(DialQueries.queryKeys.outboundNumbers(phoneConfigTenantId ?? ''));
    },
  });

  return (
    <>
      <div css={{ gridArea: 'icon' }} {...triggerProps}>
        {isPreferred && (
          <Icon
            name='star-filled'
            onClick={(e) => {
              // stop the menu from closing
              e.stopPropagation();
            }}
            color='warn'
          />
        )}
        {hoverIndex === index && !isPreferred && (
          <NakedButton
            disabled={!phoneNumberId}
            onClick={(e) => {
              e.stopPropagation();
              if (phoneNumberId) {
                setPreferredNumber.mutate(phoneNumberId);
              }
            }}
          >
            <Icon name='star' color={phoneNumberId ? textColor : 'disabled'} />
          </NakedButton>
        )}
      </div>
      <StarToolTip tooltipMessage={tooltipMessage} tooltipProps={tooltipProps} Tooltip={Tooltip} />
    </>
  );
};

const StarToolTip = ({ tooltipProps, Tooltip, tooltipMessage }: StarToolTip) => {
  return (
    <Tooltip {...tooltipProps}>
      <Text size='small' color='white'>
        {tooltipMessage}
      </Text>
    </Tooltip>
  );
};
