import { FC } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { FeatureNotificationTypes } from '@frontend/api-feature-notification';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Button, PopoverMenu, PopoverMenuItem, Text, usePopoverMenu } from '@frontend/design-system';
import { SelfUpgradeActionType } from '../../../constants/self-upgrade';
import { DismissActionButtonTrackingIdMap, UpgradeActionButtonTrackingIdMap } from '../../../constants/tracking-ids';
import { getActionIconName, getActionText, getNotificationDismissActionText } from '../../../self-upgrade.util';

interface PromoActionsProps {
  disabled?: boolean;
  dismissActions: FeatureNotificationTypes.FeatureNotificationAction[];
  featureEnum: Feature;
  primaryAction: SelfUpgradeActionType;
  source: FeatureNotificationTypes.FeatureNotificationSource;
  onDismissAction: (action: FeatureNotificationTypes.FeatureNotificationAction) => void;
  onUpgradeAction: () => void;
}

const getActionTrackingId = ({
  featureEnum,
  source,
  trackingId,
}: {
  featureEnum: Feature;
  source: FeatureNotificationTypes.FeatureNotificationSource;
  trackingId: string;
}) => {
  return `${featureEnum}-${source}-${trackingId}`;
};

export const PromoActions: FC<PromoActionsProps> = ({
  disabled,
  dismissActions,
  featureEnum,
  primaryAction,
  source,
  onDismissAction,
  onUpgradeAction,
}) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'online-scheduling-promo' });

  const { getTriggerProps, getItemProps, getMenuProps } = usePopoverMenu({
    placement: 'top-start',
  });

  return (
    <>
      <div css={{ display: 'flex', justifyContent: 'end', alignItems: 'center', gap: theme.spacing(1) }}>
        <Button
          variant='tertiary'
          color='primary'
          {...getTriggerProps()}
          disabled={disabled}
          trackingId={getActionTrackingId({
            featureEnum,
            source,
            trackingId: DismissActionButtonTrackingIdMap.DISMISS,
          })}
        >
          <Text color='light'>{t('Dismiss')}</Text>
        </Button>
        <Button
          variant='primary'
          onClick={onUpgradeAction}
          trackingId={getActionTrackingId({
            featureEnum,
            source,
            trackingId: UpgradeActionButtonTrackingIdMap[primaryAction],
          })}
          disabled={disabled}
        >
          <Icon name={getActionIconName(primaryAction)} size={16} />
          {getActionText(primaryAction).primaryButtonText}
        </Button>
      </div>
      <PopoverMenu {...getMenuProps()}>
        {dismissActions.map((dismissAction, index) => (
          <PopoverMenuItem
            key={dismissAction}
            {...getItemProps({
              index,
              onClick: () => onDismissAction(dismissAction),
            })}
            trackingId={getActionTrackingId({
              featureEnum,
              source,
              trackingId: DismissActionButtonTrackingIdMap[dismissAction],
            })}
          >
            {getNotificationDismissActionText(dismissAction)}
          </PopoverMenuItem>
        ))}
      </PopoverMenu>
    </>
  );
};
