import { CallIntelligenceTypes } from '@frontend/api-analytics';
import { useSlidePanelStore } from '@frontend/slide-panel';
import { createStoreWithSubscribe, createShallowStore } from '@frontend/store';

export interface CallTakeawayPanelStore {
  allCalls: CallIntelligenceTypes.Call[];
  defaultAccordion: CallIntelligenceTypes.CallTakeawayAccordion;
  nextCall?: CallIntelligenceTypes.Call | null;
  previousCall?: CallIntelligenceTypes.Call | null;
  isDemoAccount?: boolean;
  selectedCall: CallIntelligenceTypes.Call | null;
  selectedCallMetaData: CallIntelligenceTypes.CallMetadata | null;
  selectedDemoTasks: CallIntelligenceTypes.CallTaskListResponse | null;

  setAllCalls: (calls: CallIntelligenceTypes.Call[]) => void;
  setDefaultAccordion: (accordion: CallIntelligenceTypes.CallTakeawayAccordion) => void;
  setIsDemoAccount: (isDemoAccount?: boolean) => void;
  setSelectedCall: (call: CallIntelligenceTypes.Call | null) => void;
  setSelectedCallMetaData: (callMetaData: CallIntelligenceTypes.CallMetadata | null) => void;
  setSelectedDemoTasks: (tasks: CallIntelligenceTypes.Task[] | null) => void;

  handleNextClick: () => void;
  handlePreviousClick: () => void;
  reset: () => void;
}

export const useCallTakeawayPanelStore = createStoreWithSubscribe<CallTakeawayPanelStore>(
  (set, get) => ({
    allCalls: [],
    defaultAccordion: 'call-summary',
    nextCall: null,
    previousCall: null,
    isDemoAccount: undefined,
    selectedCall: null,
    selectedCallMetaData: null,
    selectedDemoTasks: null,

    setAllCalls: (calls) => {
      set(
        (state) => {
          state.allCalls = calls;
        },
        false,
        'SET_ALL_CALL_TAKEAWAY_CALLS'
      );
    },

    setDefaultAccordion: (accordion) => {
      set(
        (state) => {
          state.defaultAccordion = accordion;
        },
        false,
        'SET_DEFAULT_ACCORDION_TAKEAWAY_CALLS'
      );
    },

    setIsDemoAccount: (isDemoAccount) => {
      set(
        (state) => {
          state.isDemoAccount = isDemoAccount;
        },
        false,
        'SET_IS_CALL_TAKEAWAY_DEMO_ACCOUNT'
      );
    },

    setSelectedCall: (call) => {
      const { allCalls } = get();
      const selectedCallIndex = allCalls.findIndex((c) => c.id === call?.id);
      const nextCall = allCalls[selectedCallIndex + 1];
      const previousCall = allCalls[selectedCallIndex - 1];

      set(
        (state) => {
          state.nextCall = nextCall;
          state.previousCall = previousCall;
          state.selectedCall = call;
        },
        false,
        'SET_SELECTED_CALL_TAKEAWAY_CALL'
      );

      useSlidePanelStore.setState((state) => ({
        ...state,
        show: !!call,
        panelType: 'callTakeaway',
      }));
    },

    setSelectedCallMetaData: (callMetaData) => {
      set(
        (state) => {
          state.selectedCallMetaData = callMetaData;
        },
        false,
        'SET_SELECTED_CALL_META_DATA_TAKEAWAY_CALL'
      );
    },

    setSelectedDemoTasks: (tasks) => {
      set(
        (state) => {
          state.selectedDemoTasks = tasks ? { tasks } : null;
        },
        false,
        'SET_SELECTED_DEMO_TASKS'
      );
    },

    handleNextClick: () => {
      const { nextCall } = get();

      if (nextCall) {
        get().setSelectedCall(nextCall);
      }
    },

    handlePreviousClick: () => {
      const { previousCall } = get();

      if (previousCall) {
        get().setSelectedCall(previousCall);
      }
    },

    reset: () => {
      set(
        (state) => {
          state.selectedCallMetaData = null;
          state.selectedCall = null;
          state.defaultAccordion = 'call-summary';
        },
        false,
        'RESET_CALL_TAKEAWAY_PANEL_STORE'
      );

      useSlidePanelStore.setState((state) => ({
        ...state,
        show: false,
        panelType: 'callTakeaway',
      }));
    },
  }),
  {
    name: 'CallTakeawayPanelStore',
    trace: true,
  }
);

export const useCallTakeawayPanelShallowStore = createShallowStore(useCallTakeawayPanelStore);
