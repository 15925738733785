import { Sort_Enum } from '@weave/schema-gen-ts/dist/schemas/sms/draft/v1/draft_service.pb';

export enum InboxType {
  INBOX = 'inbox',
  ARCHIVED = 'archived',
  BLOCKED = 'blocked',
  SCHEDULED = 'scheduled',
  DRAFTS = 'drafts',
}

export type ThreadCreationRoute = {
  filter: InboxType;
  personId?: string;
  personPhone?: string;
  departmentId?: string;
  groupId?: string;
  draftSort?: Sort_Enum;
};

export type InboxThreadRoute = {
  filter: InboxType;
  groupId: string;
  threadId: string;
} & InboxThreadSearch;

export type InboxThreadSearch = {
  personPhone?: string;
  personId?: string;
  isNew?: boolean;
  departmentId?: string;
  smsId?: string;
  smsCreatedAt?: string;
  click?: number;
  draftSort?: Sort_Enum;
  isArchived?: boolean;
};
