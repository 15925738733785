import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Dot, Text, NakedButton, usePopoverDialog } from '@frontend/design-system';
import { useScheduleActionsAppointmentListInfoShallowStore } from '../../../../../../stores/use-schedule-actions-appointment-list-store';
import { ScheduleMassMessageFilterTrackingIds } from '../trackingIds';

type FilterButtonProps = {
  triggerProps: ReturnType<typeof usePopoverDialog>['getTriggerProps'];
};

export const FilterButton = ({ triggerProps }: FilterButtonProps) => {
  const { t } = useTranslation('scheduleCalendarActions');
  const isSmall = useMatchMedia({ maxWidth: breakpoints.small.min });

  const { hasActiveFilters } = useScheduleActionsAppointmentListInfoShallowStore('hasActiveFilters');

  return (
    <NakedButton
      {...triggerProps()}
      css={filterButtonStyles}
      trackingId={ScheduleMassMessageFilterTrackingIds.openFiltersButton}
    >
      <div css={{ position: 'relative', width: theme.spacing(3) }}>
        {hasActiveFilters && (
          <Dot
            color='primary'
            css={{
              position: 'absolute',
              top: theme.spacing(0.25),
              right: 0,
              width: theme.spacing(1),
              height: theme.spacing(1),
              border: 'none',
            }}
          />
        )}
        <Icon name='filter-alt' size={24} color='subdued' />
      </div>
      {!isSmall && (
        <Text size='large' weight='bold' css={{ marginLeft: theme.spacing(0.5) }} color='subdued'>
          {t('Filter')}
        </Text>
      )}
    </NakedButton>
  );
};

const filterButtonStyles = css({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.borderRadius.small,
  border: `1px solid ${theme.colors.neutral30}`,
  width: theme.spacing(12),
  height: theme.spacing(5),
  backgroundColor: theme.colors.white,
});
