import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { breakpoints, useMatchMedia } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { NoProvidersIllustration } from '../../images';

export const NoCalendarEventsIllustrationContainer = () => {
  const isSmall = useMatchMedia({ maxWidth: breakpoints.small.max });
  const isMedium = useMatchMedia({ minWidth: breakpoints.medium.min, maxWidth: breakpoints.large.max });
  const { t } = useTranslation('schedule');

  const isSmallerHeight = window?.innerHeight < 900;

  return (
    <section css={noCalendarEventsIllustrationContainerStyles(isSmall, isMedium)}>
      <NoProvidersIllustration width={isSmallerHeight ? 280 : 360} height={isSmallerHeight ? 320 : 360} />
      <Text color='light' textAlign='center' css={{ marginTop: theme.spacing(2) }}>
        {t('Your calendar is looking a bit empty!')}
      </Text>
    </section>
  );
};

const noCalendarEventsIllustrationContainerStyles = (isSmall: boolean, isMedium: boolean) =>
  css({
    zIndex: theme.zIndex.low,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: theme.spacing(55),
    position: 'absolute',
    top: '25%',
    left: isSmall && !isMedium ? '20%' : isMedium ? '35%' : '45%',
  });
