import dayjs from 'dayjs';
import { USERS_QUERY_LIMIT } from '../constants';
import { StreamInstance, StreamUser, StreamUserResponse, StreamWeavePresence, UserStatus } from '../types';
import { getTeamId, getFirstAndLastName } from '../utils';

const getUserPresence = (presence: StreamWeavePresence | undefined): boolean => {
  const { online, expiresAt } = presence || {};

  if (!online || !expiresAt) {
    return false;
  }
  const now = new Date();

  if (dayjs(now).isAfter(dayjs(expiresAt))) {
    return false;
  }
  return online;
};

export const getUserStatus = (user: StreamUserResponse): UserStatus => {
  const { weavePresence, userStatus } = user;
  return {
    presence: {
      online: getUserPresence(weavePresence),
      updatedAt: weavePresence?.updatedAt || '',
      expiresAt: weavePresence?.expiresAt || '',
    },
    status: {
      duration: userStatus?.statusDuration,
      expiry: userStatus?.statusExpiration,
      text: userStatus?.statusText,
    },
  };
};

export const formatUser = (user: StreamUserResponse): StreamUser => {
  // enforce the user object to have a name property
  const { name = ' ' } = user;
  const { firstName, lastName } = getFirstAndLastName(name);
  return {
    firstName,
    lastName,
    userID: user.id,
    status: getUserStatus(user),
    isDeactivated: !!user.deactivated_at,
  };
};

export const fetchUsers = async (client: StreamInstance, orgId: string) => {
  let hasMoreUsers = true;
  const uniqueUserIDs = new Set<string>();
  const uniqueUsers: StreamUser[] = [];
  let offset = 0;

  while (hasMoreUsers) {
    const { users } = await client.queryUsers(
      { teams: { $contains: getTeamId(orgId) } },
      { name: 1 },
      { presence: true, limit: USERS_QUERY_LIMIT, offset }
    );

    users.forEach((user) => {
      // during the migration, users which were not part of the location anymore were named as 'deleted user'
      // we are filtering out those users
      // This requires a permanent fix from the backend
      if (user.name !== 'deleted user' && user.id !== client.user?.id) {
        const formattedUser = formatUser(user);

        // We need to dedupe the users as it has been causing issues in the UI
        if (!uniqueUserIDs.has(formattedUser.userID)) {
          uniqueUserIDs.add(formattedUser.userID);
          uniqueUsers.push(formattedUser);
        }
      }
    });

    offset += USERS_QUERY_LIMIT;
    hasMoreUsers = users.length === USERS_QUERY_LIMIT;
  }

  return uniqueUsers;
};
