import { BuilderFieldDivisionTypes } from '../../types';

export const OTHER_FIELD_CATEGORIES: BuilderFieldDivisionTypes.OtherFieldsDivisionCategory[] = [
  {
    label: 'Generic Form Fields',
    fieldsOrder: [
      {
        key: 'eSign',
      },
      {
        key: 'cardCapture',
      },
      {
        key: 'checklist',
      },
      {
        key: 'checkbox',
      },
      {
        key: 'date',
      },
      {
        key: 'select',
      },
      {
        key: 'email',
      },
      {
        key: 'richText',
      },
      {
        key: 'textarea',
      },
      {
        key: 'text',
      },
    ],
  },
  {
    label: 'Survey Answers',
    fieldsOrder: [
      {
        key: 'radio',
      },
      {
        key: 'binaryDecision',
      },
    ],
  },
  {
    label: 'Contact Information',
    fieldsOrder: [
      {
        key: 'phone',
      },
      {
        key: 'postalCode',
      },
      {
        key: 'referralSource',
      },
      {
        key: 'ssn',
      },
    ],
  },
];
