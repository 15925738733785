import dayjs from 'dayjs';
import { CalendarEventsV3DateFormat } from '../../utils';

export const useGetV3CalendarStartAndEndDateTime = (calendarDate: string) => {
  const startDate = dayjs(calendarDate).startOf('day').utc();
  const endDate = startDate.add(1, 'day');

  return {
    startDateTime: startDate.format(CalendarEventsV3DateFormat),
    endDateTime: endDate.format(CalendarEventsV3DateFormat),
  };
};
