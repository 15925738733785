import { css } from '@emotion/react';
import { i18next } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { SwitchChipGroup, Text, useSwitchChipGroup } from '@frontend/design-system';
import { ExceptionTableFilter } from '../../types';

const filters = {
  [ExceptionTableFilter.Upcoming]: i18next.t('Upcoming', { ns: 'scheduleGlobalSettings' }),
  [ExceptionTableFilter.Past]: i18next.t('Past', { ns: 'scheduleGlobalSettings' }),
  [ExceptionTableFilter.All]: i18next.t('All Exceptions', { ns: 'scheduleGlobalSettings' }),
};

type PractitionerExceptionTableFiltersProps = {
  setValue: (value: ExceptionTableFilter) => void;
};

export const PractitionerExceptionTableFilter = ({ setValue }: PractitionerExceptionTableFiltersProps) => {
  const switchChipGroupProps = useSwitchChipGroup({
    onChange: (val) => setValue(val[0] as ExceptionTableFilter),
    defaultValue: [ExceptionTableFilter.All],
  });

  return (
    <>
      <SwitchChipGroup {...switchChipGroupProps} singleSelect css={customChipStyle()}>
        {Object.entries(filters).map(([filter, label]) => (
          <SwitchChipGroup.Option
            key={filter}
            value={label}
            className={`exception-status-filter-chip ${switchChipGroupProps.value.includes(filter) ? 'active' : ''}`}
          >
            <Text size='medium'>{label}</Text>
          </SwitchChipGroup.Option>
        ))}
      </SwitchChipGroup>
    </>
  );
};

const customChipStyle = () => css`
  .exception-status-filter-chip {
    background: ${theme.colors.white};
    border: 1px solid ${theme.colors.neutral50};

    :focus {
      outline: none;
    }

    &.active {
      border: 1px solid ${theme.colors.primary50};
      background: ${theme.colors.primary5};
    }
  }
`;
