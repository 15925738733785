import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { PhoneTreeApi } from '@frontend/api-phone-tree';
import { i18next } from '@frontend/i18n';
import { SkeletonLoader } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { SubTreePayload, InstructionFieldsProps } from '../types';
import { InstructionFields } from './base';

export const SubTreeInstructionField = ({
  onChange,
  option,
  phonetreeId,
}: {
  onChange: InstructionFieldsProps<SubTreePayload>['onChange'];
  option: SubTreePayload;
  phonetreeId?: string;
}) => {
  const { data = [], isLoading } = useQuery({
    queryKey: queryKeys.phoneTrees(),
    queryFn: () => PhoneTreeApi.list({}),
    select: (data) => {
      const phoneData = data?.data;
      if (!phoneData) {
        return [];
      }
      return phoneData
        .sort((a, b) => (!a.name || !b.name ? 0 : a.name.localeCompare(b.name)))
        .map((phoneTree) => ({
          value: phoneTree.ivrMenuId ?? '',
          label: phoneTree.name ?? '',
        }));
    },
    staleTime: 0,
  });
  const filteredPhoneTrees = data.filter((tree) => tree.value !== phonetreeId);

  useEffect(() => {
    if (!option.subTreeId && filteredPhoneTrees.length > 0) {
      onChange({ subTreeId: filteredPhoneTrees[0].value ?? '' });
    }
  }, [option.subTreeId, filteredPhoneTrees]);

  if (isLoading) {
    return <SkeletonLoader />;
  }

  return (
    <InstructionFields<SubTreePayload>
      onChange={onChange}
      schema={schema}
      customization={{
        optionGroups: {
          subTreeId: filteredPhoneTrees ?? [],
        },
        value: {
          subTreeId: option.subTreeId || (filteredPhoneTrees.length > 0 ? filteredPhoneTrees[0].value : ''),
        },
      }}
    />
  );
};

const schema = {
  type: 'subTree',
  fields: {
    subTreeId: {
      label: i18next.t('Phone Tree', { ns: 'phone' }),
      name: 'subTreeId',
      type: 'dropdown',
    },
  },
} as const;
