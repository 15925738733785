import { ReactNode } from 'react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useInboxNavigate } from '@frontend/inbox-navigation';
import { ThreadHeader } from '@frontend/thread-header';
import { IconButton } from '@frontend/design-system';

type ResponsiveHeaderWrapperProps = {
  showBackButton: boolean;
  children: ReactNode;
};

export const ResponsiveHeaderWrapper = ({ showBackButton, children }: ResponsiveHeaderWrapperProps) => {
  const { t } = useTranslation('inbox');
  const { closeThread } = useInboxNavigate();
  const { variant } = ThreadHeader.useContext();

  return (
    <ThreadHeader.Header>
      {variant !== 'horizontal' && (
        <IconButton
          label={t('Back')}
          onClick={() => closeThread()}
          css={
            showBackButton
              ? undefined
              : {
                  display: 'hidden',
                  opacity: 0,
                  tabIndex: -1,
                  cursor: 'default',
                }
          }
        >
          <Icon name='back' />
        </IconButton>
      )}
      {children}
    </ThreadHeader.Header>
  );
};
