import { AppointmentType } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/appointment_type.pb';
import { Operatory } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/operatory.pb';
import { Practitioner } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/practitioner.pb';
import {
  ReducedAppointmentType,
  ReducedOperatory,
  ReducedPractitioner,
} from './ScheduleRequests/hooks/useReviewRequest';

// Transform V3 responses to match V2 fields for the review request form. This should be removed once component refactored to have separate V3 container
type TransformV3ResponsesForReviewRequest = {
  appointmentTypesDataV3?: AppointmentType[];
  practitionerDataV3?: Practitioner[];
  operatoriesDataV3?: Operatory[];
};

type TransformedV3ResponsesForReviewRequest = {
  transformedAppointmentTypes: ReducedAppointmentType[];
  transformedPractitioners: ReducedPractitioner[];
  transformedOperatories: ReducedOperatory[];
};

export const transformV3ResponsesForReviewRequest = ({
  appointmentTypesDataV3,
  practitionerDataV3,
  operatoriesDataV3,
}: TransformV3ResponsesForReviewRequest): TransformedV3ResponsesForReviewRequest => {
  const transformedAppointmentTypes =
    appointmentTypesDataV3?.map((appointmentType: AppointmentType) => {
      return {
        id: appointmentType.id,
        durationMinutes: appointmentType.details?.dur ?? 0,
        name: appointmentType.displayName || appointmentType.externalName || '',
        sourceId: appointmentType.sourceTenantId,
        externalId: appointmentType.externalId,
        amount: appointmentType.details?.amt,
        locationId: appointmentType.locationId,
      };
    }) || [];

  const transformedPractitioners =
    practitionerDataV3?.map((practitioner: Practitioner) => {
      return {
        id: practitioner.id,
        publicDisplayName: practitioner.displayName,
        firstName: practitioner.firstName,
        lastName: practitioner.lastName,
        sourceId: practitioner.sourceTenantId,
        calendarId: practitioner.calendarId,
        externalId: practitioner.externalId,
        locationId: practitioner.locationId,
      };
    }) || [];

  const transformedOperatories =
    operatoriesDataV3?.map((operatory: Operatory) => {
      return {
        id: operatory.id,
        workstationName: operatory.displayName || operatory.externalName || '',
        sourceId: operatory.sourceTenantId,
        locationId: operatory.locationId,
        externalId: operatory.externalId,
      };
    }) || [];

  return {
    transformedAppointmentTypes,
    transformedPractitioners,
    transformedOperatories,
  };
};
