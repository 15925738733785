import { FC } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { Text, Button, ButtonProps } from '@frontend/design-system';

interface Props {
  size?: number;
  iconName?: ButtonProps['iconName'];
  trackingId?: string;
}

export const containerStyle = css`
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
`;

export const IconWithText: FC<React.PropsWithChildren<Props>> = ({
  children,
  size = 14,
  trackingId,
  iconName,
  ...rest
}) => {
  return (
    <div css={containerStyle} {...rest}>
      <Button
        variant='secondary'
        trackingId={trackingId}
        iconName={iconName}
        css={css`
          width: max-content;
          padding: ${theme.spacing(1)};
        `}
      >
        <Text
          css={css`
            margin-left: ${theme.spacing(0.5)};
            font-size: ${theme.fontSize(size)};
          `}
        >
          {children}
        </Text>
      </Button>
    </div>
  );
};
