import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { AutoRecallAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { TableFilters } from '@frontend/design-system';
import { trackingIds } from '../../tracking-ids';
import { DateRange, findDatesRange, getMatchingDateRangeKey } from '../../utils';
import { filtersStyles } from '../../views/common-styles';
import { CustomPeriodGroups, DatesRangeSelector, TimePeriodSelector } from '../filter-selectors';
import { FiltersTray } from '../filters-tray';
import { useAutoRecallShallowStore } from './hooks';

type Props = {
  isLoadingData?: boolean;
};

const DEFAULT_PERIOD = 'last-month';

const generateDatesRange = (count: number, range: 'day' | 'month', format: string, oneDayLimit?: boolean) =>
  [...Array(count).keys()].reduce((acc, index) => {
    // Plus 2 because we already have current and previous day/week/month
    const offset = index + 2;
    const key = dayjs().subtract(offset, range).format(format);
    acc[key] = findDatesRange({ offset, oneDayLimit, range });
    return acc;
  }, {} as Record<string, DateRange>);

const daysRangeMap = {
  today: findDatesRange({ range: 'day' }),
  yesterday: findDatesRange({ offset: 1, range: 'day' }),
  ...generateDatesRange(5, 'day', 'dddd', true),
};

const weeksRangeMap = {
  'this-week': findDatesRange({ range: 'week' }),
  'last-week': findDatesRange({ offset: 1, range: 'week' }),
};

const monthsRangeMap = {
  'this-month': findDatesRange({ range: 'month' }),
  'last-month': findDatesRange({ offset: 1, range: 'month' }),
  ...generateDatesRange(4, 'month', 'MMMM YYYY'),
};

const datesRangeMap = {
  ...daysRangeMap,
  ...weeksRangeMap,
  ...monthsRangeMap,
};

// These default filters helps in avoiding any infinite loops and in resetting filters state
const defaultFilters: AutoRecallAnalyticsTypes.Filters = datesRangeMap[DEFAULT_PERIOD];

export const AutoRecallFilters = ({ isLoadingData }: Props) => {
  const { t } = useTranslation('analytics');
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const locationId = selectedLocationIdsWithParents[0];

  const {
    filters,
    hasCustomFilters,
    locationId: autoRecallLocationId,
    setDefaultFilters,
    setFilterHintText,
    setFilters,
    setLocationId,
    resetStore,
  } = useAutoRecallShallowStore(
    'filters',
    'hasCustomFilters',
    'locationId',
    'setDefaultFilters',
    'setFilterHintText',
    'setFilters',
    'setLocationId',
    'resetStore'
  );

  const [trayFilters, setTrayFilters] = useState<AutoRecallAnalyticsTypes.Filters>({});

  const timeFilterLabels: Record<string, string> = {
    today: t('Today'),
    yesterday: t('Yesterday'),
    'this-week': t('This Week'),
    'last-week': t('Last Week'),
    'this-month': t('This Month'),
    'last-month': t('Last Month'),
  };

  const timePeriodGroups: CustomPeriodGroups = {
    datesMap: datesRangeMap,
    groups: [
      {
        label: t('Days'),
        period: Object.keys(daysRangeMap),
      },
      {
        label: t('Weeks'),
        period: Object.keys(weeksRangeMap),
      },
      {
        label: t('Months'),
        period: Object.keys(monthsRangeMap),
      },
    ],
    labels: timeFilterLabels,
  };

  const handleUpdateTrayFilters = (newFilters: Partial<AutoRecallAnalyticsTypes.Filters>) => {
    setTrayFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
  };

  const applyDefaultFilters = () => {
    // Also used for resetting filters whenever required
    setDefaultFilters(defaultFilters);
    setFilters(defaultFilters);
  };

  const applyActiveFiltersToTrayState = () => {
    handleUpdateTrayFilters(filters);
  };

  const handleApplyTrayFilters = () => {
    // This method will be invoked when user clicks on apply button in the tray
    setFilters(trayFilters);
  };

  useEffect(() => {
    // Update local state when main filters has changed
    applyActiveFiltersToTrayState();

    const timeFilter = getMatchingDateRangeKey({
      datesMap: datesRangeMap,
      endDate: filters.endDate,
      startDate: filters.startDate,
    });

    // This text is displayed in sub view pages and charts to indicate the currently applied filters
    setFilterHintText(
      timeFilterLabels[timeFilter || ''] ||
        `${dayjs(filters.startDate).format('DD MMM, YYYY')} - ${dayjs(filters.endDate).format('DD MMM, YYYY')}`
    );
  }, [filters]);

  useEffect(() => {
    // Avoid resetting when custom filters are already applied (user has navigated from sub page to the main page)
    if (!hasCustomFilters) {
      applyDefaultFilters();
    }
  }, [defaultFilters]);

  useEffect(() => {
    if (!autoRecallLocationId) {
      setLocationId(locationId);
    } else if (locationId !== autoRecallLocationId) {
      //  Reset when location is switched
      resetStore();
      setLocationId(locationId);
      applyDefaultFilters();
    }
  }, [autoRecallLocationId, locationId]);

  return (
    <FiltersTray
      disableTray={isLoadingData}
      headerLabel={t('Filter Auto Recall')}
      onApplyFilters={handleApplyTrayFilters}
      onResetFilters={applyDefaultFilters}
      onRevertLocalChanges={applyActiveFiltersToTrayState}
      showFilteredBadge={hasCustomFilters}
      trackingId={trackingIds.autoRecallAnalytics.filtersOpen}
    >
      <TableFilters.Section sectionHeaderLabel={t('Time')}>
        <div css={filtersStyles.traySection}>
          <TimePeriodSelector
            customPeriodGroups={timePeriodGroups}
            defaultPeriod={DEFAULT_PERIOD}
            endDate={trayFilters.endDate}
            onChange={({ endDate, startDate }) => {
              handleUpdateTrayFilters({ endDate, startDate });
            }}
            startDate={trayFilters.startDate}
          />
          <DatesRangeSelector
            endDate={trayFilters.endDate}
            onChange={({ endDate, startDate }) => handleUpdateTrayFilters({ endDate, startDate })}
            startDate={trayFilters.startDate}
          />
        </div>
      </TableFilters.Section>
    </FiltersTray>
  );
};
