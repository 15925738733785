import { css } from '@emotion/react';
import { IconName } from '@frontend/icons';
import { Button, type IconButtonProps } from '@frontend/design-system';
import { useShellTheme } from './store';

type TitleBarButtonProps = Omit<IconButtonProps, 'children' | 'label' | 'size'> & {
  label?: string;
  largeIcon?: boolean;
  icon: IconName;
  hasBorder?: boolean;
};
export const TitleBarButton = ({
  onClick,
  disabled,
  largeIcon,
  label,
  icon,
  hasBorder,
  ...rest
}: TitleBarButtonProps) => {
  const { colorObject } = useShellTheme('colorObject');

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      hoverLabel={label}
      iconName={icon}
      variant='secondary'
      size={largeIcon ? 'large' : 'small'}
      css={css`
        -webkit-app-region: no-drag;
        transition: 0.3s ease;
        min-width: 40px;
        min-height: 40px;
        border: ${hasBorder ? `1px solid ${colorObject.iconColor}` : 'none'};
        span > svg {
          font-weight: 400;
        }
        &[aria-disabled='false'] span > svg {
          color: ${colorObject.iconColor};
        }
        &[aria-disabled='true'] span > svg {
          opacity: 0.5;
          pointer-events: none;
        }
        :hover:not(:disabled) {
          background-color: ${colorObject.hover};
        }
        :focus:not(:disabled) {
          background-color: ${colorObject.hover};
        }
      `}
      aria-label={label}
      {...rest}
    />
  );
};
