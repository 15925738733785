import { CallIntelligenceTypes } from '@frontend/api-analytics';
import { demoDataUtils } from '../../../utils';
import {
  appointmentTypes,
  categories,
  getRandomContactType,
  getRandomDirection,
  getRandomElement,
  getRandomSentiment,
  officeUsers,
  phoneNumbers,
  taskTypes as allowedTaskTypes,
} from './call-recordings';

type GetFollowUpsExtraProps = {
  randomFollowUps: boolean;
};

type GetFollowUpsParams = CallIntelligenceTypes.GetFollowUpsParams & GetFollowUpsExtraProps;

type generateFollowUpsParams = {
  pageSize: number;
  randomFollowUps: boolean;
  taskTypes: CallIntelligenceTypes.TaskTypeEnum[];
};

type generateCallsParams = {
  personId: string;
  totalCalls: number;
  taskTypes: CallIntelligenceTypes.TaskTypeEnum[];
};

type generateRandomCallParams = {
  callId: string;
  personName: Name;
  personId: string;
  taskTypes: CallIntelligenceTypes.TaskTypeEnum[];
};

type Name = { FirstName: string; LastName: string };

// Utility function to get random unique elements from an array
const getRandomUniqueElements = <T>(array: T[], count: number): T[] => {
  const shuffled = array.slice().sort(() => 0.5 - Math.random());
  return shuffled.slice(0, count);
};

export const getFollowUps = (params: GetFollowUpsParams): CallIntelligenceTypes.FollowUpResponse => {
  const { filters, pageConfig, randomFollowUps } = params;
  const { taskTypes } = filters;

  let followUps: CallIntelligenceTypes.FollowUp[] = [];

  // If taskTypes is undefined or empty, generate follow-ups using allowedTaskTypes
  if (!taskTypes || taskTypes.length === 0) {
    followUps = generateFollowUps({ pageSize: pageConfig.pageSize, randomFollowUps, taskTypes: [] });
  } else {
    // Filter taskTypes to only include allowedTaskTypes
    const filteredTaskTypes = taskTypes.filter((taskType) => allowedTaskTypes.includes(taskType));

    // If filtered task types is not empty, generate follow-ups
    if (filteredTaskTypes.length) {
      followUps = generateFollowUps({ pageSize: pageConfig.pageSize, randomFollowUps, taskTypes: filteredTaskTypes });
    }
  }

  return {
    followUps,
    hasNextPage: false,
    pageConfig,
    totalFollowUps: followUps.length,
  };
};

const generateFollowUps = ({
  pageSize,
  randomFollowUps,
  taskTypes,
}: generateFollowUpsParams): CallIntelligenceTypes.FollowUp[] => {
  const numberOfFollowUps = randomFollowUps ? demoDataUtils.generateRandomInt(1, pageSize) : pageSize;

  return Array.from({ length: numberOfFollowUps }, (_, index) => {
    const personId = `person-${index + 1}`;
    return createFollowUp(personId, taskTypes);
  });
};

const createFollowUp = (
  personId: string,
  taskTypes: CallIntelligenceTypes.TaskTypeEnum[]
): CallIntelligenceTypes.FollowUp => {
  const totalCalls = demoDataUtils.generateRandomInt(1, 3); // Maximum 3 calls per follow-up
  const calls = generateCalls({ personId, totalCalls, taskTypes });

  return {
    calls,
    totalTasks: calls.reduce((sum, call) => sum + call.taskCount, 0),
    totalCalls,
  };
};

const generateUniqueNames = (count: number): Name[] => {
  const names: Name[] = [];
  const usedCombinations = new Set<string>();

  while (names.length < count) {
    const firstName = getRandomElement(demoDataUtils.firstNames);
    const lastName = getRandomElement(demoDataUtils.lastNames);
    const fullName = `${firstName} ${lastName}`;

    if (!usedCombinations.has(fullName)) {
      usedCombinations.add(fullName);
      names.push({ FirstName: firstName, LastName: lastName });
    }
  }

  return names;
};

const generateCalls = ({ personId, totalCalls, taskTypes }: generateCallsParams): CallIntelligenceTypes.Call[] => {
  const names = generateUniqueNames(totalCalls);

  return Array.from({ length: totalCalls }, (_, idx) =>
    generateRandomCall({ callId: `${personId}-call-${idx}`, personName: names[idx], personId, taskTypes })
  );
};

const generateRandomCall = ({
  callId,
  personName,
  personId,
  taskTypes,
}: generateRandomCallParams): CallIntelligenceTypes.Call => {
  const maxTasks = demoDataUtils.generateRandomInt(1, allowedTaskTypes.length);
  let uniqueTaskTypes = getRandomUniqueElements(allowedTaskTypes, maxTasks);

  if (taskTypes.length) {
    const randomFilteredTaskType = getRandomElement(taskTypes);
    uniqueTaskTypes = [
      randomFilteredTaskType,
      ...uniqueTaskTypes.filter((taskType) => taskType !== randomFilteredTaskType),
    ].slice(0, maxTasks);
  }

  if (taskTypes.length) {
    const randomFilteredTaskType = getRandomElement(taskTypes);
    uniqueTaskTypes = [
      randomFilteredTaskType,
      ...uniqueTaskTypes.filter((taskType) => taskType !== randomFilteredTaskType),
    ].slice(0, maxTasks);
  }

  const tasks = uniqueTaskTypes.map((taskType) => ({
    assignee: personName.FirstName,
    citation: `Task for ${taskType}`,
    description: `Description for ${taskType}`,
    id: `task-${callId}-${taskType}`,
    locationId: `location-${callId}`,
    metadata: '{}',
    sourceId: callId,
    status: getRandomElement([
      CallIntelligenceTypes.TaskStatusEnum.STATUS_TODO,
      CallIntelligenceTypes.TaskStatusEnum.STATUS_IN_PROGRESS,
      CallIntelligenceTypes.TaskStatusEnum.STATUS_COMPLETED,
    ]),
    title: `Title for ${taskType}`,
    type: taskType,
  }));

  return {
    appointmentTypes: [getRandomElement(appointmentTypes)],
    categories: [getRandomElement(categories)],
    contactType: getRandomContactType(),
    direction: getRandomDirection(),
    id: callId,
    locationId: `location-${callId}`,
    mosScore: demoDataUtils.generateRandomInt(1, 5),
    officeNumber: {
      countryCode: 1,
      nationalNumber: getRandomElement(phoneNumbers),
    },
    officeUser: getRandomElement(officeUsers),
    person: {
      firstName: personName.FirstName,
      id: personId,
      lastName: personName.LastName,
    },
    phoneNumber: {
      countryCode: 1,
      nationalNumber: '234567890',
    },
    schedulingOpportunity: true,
    schedulingOutcome: getRandomElement([true, false]),
    sentiment: getRandomSentiment(),
    startTime: demoDataUtils.getRandomDateAndTime(),
    status: CallIntelligenceTypes.CallStatusEnum.CALL_STATUS_COMPLETE,
    taskCount: taskTypes.length ? taskTypes.length : tasks.length,
    taskTypes: tasks.map((task) => task.type),
  };
};
