import { useEffect, useState } from 'react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { useAppScopeStore } from '@frontend/scope';
import { OnlineSchedulingAppointmentPromoCard } from '@frontend/self-upgrade';
import { useModalControl } from '@frontend/design-system';
import {
  useCalendarEventsConfigurationShallowStore,
  useCalendarEventsFormTypeShallowStore,
  useGetIsIntegratedOffice,
} from '../../../../hooks';
import { CalendarEventSelector } from '../../components/CalendarEventsSelector';
import { CalendarEventsEnums } from '../../types';
import { isUUID } from '../../utils';
import { DEFAULT_CONFIGURED_CALENDAR_EVENT_VALUE } from '../constant';
import { TrayContainer } from '../TrayContainer';
import { CalendarEventsModalContent } from './CalendarEventsModalContent';

export const CalendarEventsContainer = () => {
  const { selectedLocationIds } = useAppScopeStore();
  const { configuredCalendarEvent, setConfiguredCalendarEvent } = useCalendarEventsConfigurationShallowStore(
    'configuredCalendarEvent',
    'setConfiguredCalendarEvent'
  );
  const { isIntegratedOffice } = useGetIsIntegratedOffice();
  const { selectedForm, setSelectedForm } = useCalendarEventsFormTypeShallowStore('selectedForm', 'setSelectedForm');
  const calendarEventSelectionModalProps = useModalControl();
  const eventPanelModalProps = useModalControl({
    disableReturnFocus: true,
  });
  const { modalProps, openModal, closeModal } = eventPanelModalProps;

  const [locationIdsForOnlineSchedulePromo, setLocationIdsForOnlineSchedulePromo] = useState<string[]>([]);

  // useEffect to manage the modal or popover triggers
  useEffect(() => {
    const hasProviderDetails = configuredCalendarEvent?.providerId || configuredCalendarEvent?.providerName;

    const isValidEvent = configuredCalendarEvent?.isValid;

    const calendarEventType = configuredCalendarEvent?.calendarEventType ?? '';

    if (isValidEvent && !hasProviderDetails) {
      setSelectedForm(CalendarEventsEnums.OFFICE_HOURS_OUT_OF_OFFICE_EVENT);
      openModal();
    } else if (
      isValidEvent &&
      calendarEventType &&
      calendarEventType !== CalendarEventsEnums.OFFICE_HOURS_OUT_OF_OFFICE_EVENT
    ) {
      setSelectedForm(calendarEventType);
      openModal();
    } else if (configuredCalendarEvent && configuredCalendarEvent.isValid) {
      setSelectedForm('');
      calendarEventSelectionModalProps.openModal();
    }
  }, [configuredCalendarEvent]);

  useEffect(() => {
    if (selectedForm) {
      openModal();
    }
  }, [selectedForm]);

  useEffect(() => {
    if (selectedLocationIds) {
      handleEventsSelectionCancelButtonClick();
      closeModal();
    }
  }, [selectedLocationIds]);

  const onCalendarEventSelect = (event: CalendarEventsEnums) => {
    if (event) {
      setSelectedForm(event);
      openModal();
    }
  };

  const handleModalClose = () => {
    setSelectedForm('');
    closeModal();
    setConfiguredCalendarEvent(DEFAULT_CONFIGURED_CALENDAR_EVENT_VALUE);
  };

  const handleEventsSelectionCancelButtonClick = () => {
    setSelectedForm('');
    calendarEventSelectionModalProps.closeModal();
    setConfiguredCalendarEvent(DEFAULT_CONFIGURED_CALENDAR_EVENT_VALUE);
  };

  const handleAppointmentSuccess = (appointment?: Appointment) => {
    // Added 2s delay to avoid conflicts with the toast message
    setTimeout(() => {
      appointment?.clientLocationIds?.length && setLocationIdsForOnlineSchedulePromo(appointment.clientLocationIds);
    }, 2000);
  };

  const isValidProviderId = configuredCalendarEvent?.providerId && isUUID(configuredCalendarEvent.providerId);

  const shouldShowCalendarEventsPopover =
    !isIntegratedOffice &&
    ((!configuredCalendarEvent?.providerId && !configuredCalendarEvent?.locationId) || isValidProviderId);

  return (
    <>
      {shouldShowCalendarEventsPopover && (
        <CalendarEventSelector
          modalProps={calendarEventSelectionModalProps.modalProps}
          onCalendarEventSelect={onCalendarEventSelect}
          onModalClose={handleEventsSelectionCancelButtonClick}
        />
      )}

      <TrayContainer modalProps={modalProps} onModalClose={handleModalClose}>
        <CalendarEventsModalContent closeModal={handleModalClose} onAppointmentSuccess={handleAppointmentSuccess} />
      </TrayContainer>
      {!modalProps.show && (
        <OnlineSchedulingAppointmentPromoCard
          locationIds={locationIdsForOnlineSchedulePromo}
          onClose={() => setLocationIdsForOnlineSchedulePromo([])}
        />
      )}
    </>
  );
};
