import { useMemo } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { FeatureNotificationQueries, FeatureNotificationTypes } from '@frontend/api-feature-notification';
import { SelfUpgradeActionType } from '../constants/self-upgrade';
import { useFeatureSubscriptionConfig } from './useFeatureSubscriptionConfig';

interface UseFeaturePromoNotificationReturnType {
  isLoading: boolean;
  showNotification: boolean;
  primaryAction: SelfUpgradeActionType;
  dismissActions: FeatureNotificationTypes.FeatureNotificationAction[];
}

export const useFeaturePromoNotification = ({
  featureEnum,
  source,
  locationIds,
}: {
  featureEnum: Feature;
  source: FeatureNotificationTypes.FeatureNotificationSource;
  locationIds: string[];
}): UseFeaturePromoNotificationReturnType => {
  const {
    hasAdminAccessInAtleastOne,
    isLoading,
    isFreeTrialEligible,
    isFeatureActiveInAllLocations,
    isUpgradeEligible,
    selfUpgradeData,
  } = useFeatureSubscriptionConfig({
    feature: featureEnum,
    locationIds,
  });

  const { data, isLoading: isFeatureNotificationStatusLoading } =
    FeatureNotificationQueries.useGetFeatureNotificationStatus(
      {
        featureEnum,
        sourceEnum: source,
      },
      {
        enabled: !isLoading && (isUpgradeEligible || isFreeTrialEligible),
      }
    );

  const dismissActions = useMemo(() => {
    return (data?.dismissAction ?? [])?.filter(
      (dismissAction) => dismissAction !== FeatureNotificationTypes.FeatureNotificationAction.ACTION_UNKNOWN
    );
  }, [data?.dismissAction]);

  const isMultiUpgrade = selfUpgradeData?.length > 1;

  const primaryAction = useMemo(() => {
    if (!hasAdminAccessInAtleastOne && !isMultiUpgrade) {
      return SelfUpgradeActionType.REQUEST_UPGRADE;
    } else if (isFreeTrialEligible) {
      return SelfUpgradeActionType.FREE_TRIAL;
    } else if (isUpgradeEligible) {
      return SelfUpgradeActionType.UPGRADE_NOW;
    } else {
      return SelfUpgradeActionType.SCHEDULE_CALL;
    }
  }, [hasAdminAccessInAtleastOne, isFreeTrialEligible, isMultiUpgrade, isUpgradeEligible]);

  return {
    isLoading: isLoading || isFeatureNotificationStatusLoading,
    showNotification:
      !!dismissActions.length && !isFeatureActiveInAllLocations && (isFreeTrialEligible || isUpgradeEligible),
    primaryAction,
    dismissActions,
  };
};
