import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import type { ButtonStyleValues } from './button-theme';
import type { LegacyButtonSize } from './button-type';

// This function is used for all the buttons types.
// The circular buttons don't have a `size` prop, so when no `size` is passed
// we add styles for circular buttons.
export const buttonFocusStyle = (styleValues: Pick<ButtonStyleValues, 'focusRingColor' | 'borderRadius'>) => {
  return css`
    position: relative;

    :focus-visible {
      outline: none;
      box-shadow: 0 0 0 2px white, 0 0 0 4px ${styleValues.focusRingColor};
      // Outlines only respect the border radius in Chrome right now
      /* outline: 2px solid ${styleValues.focusRingColor};
      outline-offset: 2px; */
    }

    :focus:not(:active)::before,
    :focus-within:not(:active)::before {
      opacity: 1;
    }
  `;
};

export const baseButtonStyle = (
  styleValues: Pick<ButtonStyleValues, 'large' | 'small' | 'tiny' | 'borderRadius' | 'focusRingColor' | 'fontFamily'>,
  size: LegacyButtonSize = 'large'
) => css`
  width: ${size === 'tiny' ? 'auto' : '100%'};
  height: ${styleValues[size]?.height}px;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: ${styleValues.fontFamily};
  font-size: ${theme.fontSize(styleValues[size]?.fontSize)};
  line-height: ${styleValues[size]?.lineHeight};
  padding: ${styleValues[size]?.padding};
  position: relative;
  text-transform: capitalize;

  ${buttonFocusStyle({
    borderRadius: styleValues.borderRadius,
    focusRingColor: styleValues.focusRingColor,
  })};

  :disabled {
    cursor: default;
  }
`;
