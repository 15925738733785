import { useCallback } from 'react';
import { Direction, Status } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/enums.pb';
import { SMS } from '@weave/schema-gen-ts/dist/schemas/sms/shared/v1/models.pb';
import { InfiniteData } from 'react-query';
import { Compulsory } from 'ts-toolbelt/out/Object/Compulsory';
import { getUser } from '@frontend/auth-helpers';
import { SchemaQueryFilters, SchemaQueryKey, useSchemaQueryUpdaters } from '@frontend/react-query-helpers';
import { SchemaIO } from '@frontend/schema';
import { serviceName } from '../service';
import { InfiniteQueryEndpointName, KnownThreadStatus, QueryEndpointName, ServiceQueries } from '../types';

const getThreadStatusFromSMS = ({ direction, status }: Pick<SMS, 'direction' | 'status'>): KnownThreadStatus => {
  if (status === Status.STATUS_ERROR) return KnownThreadStatus.ERROR;

  if (direction === Direction.DIRECTION_OUTBOUND) return KnownThreadStatus.READ;
  return KnownThreadStatus.NEW;
};

export type SMSMutationContext = { originalSMS?: SMS; updatedSMS?: SMS };
type SMSTargetingProperties = Compulsory<Pick<SMS, 'id'>> & Partial<Pick<SMS, 'threadId' | 'locationId'>>;
type EndpointSMSUpdaterFn<EndpointName extends QueryEndpointName> = (args: {
  oldData: EndpointName extends InfiniteQueryEndpointName
    ? InfiniteData<SchemaIO<ServiceQueries[EndpointName]>['output']>
    : SchemaIO<ServiceQueries[EndpointName]>['output'];
  matchValues: SMSTargetingProperties;
  newValues: Partial<SMS> | ((oldSMS: SMS) => SMS);
}) => {
  newData: EndpointName extends InfiniteQueryEndpointName
    ? InfiniteData<SchemaIO<ServiceQueries[EndpointName]>['output']>
    : SchemaIO<ServiceQueries[EndpointName]>['output'];
} & SMSMutationContext;
type EndpointQueryFiltersFn<EndpointName extends QueryEndpointName, T extends object = Record<string, unknown>> = (
  args: {
    matchValues: SMSTargetingProperties;
  } & T
) => SchemaQueryFilters<ServiceQueries, EndpointName, EndpointName extends InfiniteQueryEndpointName ? true : false>;
type UpsertSMSFn = (args: { sms: SMS; skipInvalidation?: boolean }) => Omit<SMSMutationContext, 'originalSMS'>;
type UpdateSMSFn = (args: {
  matchValues: SMSTargetingProperties;
  newValues: Partial<SMS> | ((oldSMS: SMS) => SMS);
  skipInvalidation?: boolean;
}) => SMSMutationContext;
type DeleteSMSFn = (
  args: { matchValues: SMSTargetingProperties; forceSoftDelete?: boolean; skipInvalidation?: boolean } & Partial<
    Pick<SMS, 'deletedAt' | 'deletedBy' | 'statusDetails'>
  >
) => SMSMutationContext;

export const useSMSUpdaters = () => {
  const { updateQuery, getQueryKey, invalidateQueries, getQueriesData } =
    useSchemaQueryUpdaters<ServiceQueries>(serviceName);
  const user = getUser();

  /**
   * A function to update the `GetSMS` query data with the given match values and new values.
   * @param oldData The currently cached data for the `GetSMS` query.
   * @param matchValues The values to match the SMS to update.
   * @param newValues The new values to update the SMS with, or a function that updates an SMS.
   * @returns The updated data for the `GetSMS` query, and the original and updated SMS, if found.
   */
  const getSMSUpdater = useCallback<EndpointSMSUpdaterFn<'GetSMS'>>(({ oldData, newValues }) => {
    const newData = {
      ...oldData,
      sms: { ...oldData.sms, ...(typeof newValues === 'function' ? newValues(oldData.sms) : newValues) },
    };
    return { newData, originalSMS: oldData.sms, updatedSMS: newData.sms };
  }, []);
  /**
   * A function to get the query filters that will match on `GetSMS` queries that include the SMS associated with the given match values.
   * @param matchValues The values to match the SMS to.
   */
  const getGetSMSQueryFilters = useCallback<EndpointQueryFiltersFn<'GetSMS'>>(
    ({ matchValues }) => {
      return {
        queryKey: getQueryKey<'GetSMS'>({
          endpointName: 'GetSMS',
          request: { smsId: matchValues.id, locationId: matchValues.locationId },
        }),
        exact: false,
      };
    },
    [getQueryKey]
  );

  /**
   * A function to update an SMS in the `GetThread` query data with the given match values and new values.
   * @param oldData The currently cached data for the `GetThread` query.
   * @param matchValues The values to match the SMS to update.
   * @param newValues The new values to update the SMS with.
   * @returns The updated data for the `GetThread` query, and the original and updated SMS, if found.
   */
  const getThreadSMSUpdater = useCallback<EndpointSMSUpdaterFn<'GetThread'>>(({ oldData, matchValues, newValues }) => {
    const contextResult: SMSMutationContext = {};
    const newData: typeof oldData = {
      ...oldData,
      pages: oldData.pages.map((page) => {
        const pageHasSMS = page.thread.messages.some((sms) => sms.id === matchValues.id);
        if (!pageHasSMS) return page;

        return {
          ...page,
          thread: {
            ...page.thread,
            messages: page.thread.messages.map((sms) => {
              if (sms.id !== matchValues.id) return sms;
              if (!contextResult.originalSMS) contextResult.originalSMS = sms;
              const updatedSMS = { ...sms, ...(typeof newValues === 'function' ? newValues(sms) : newValues) };
              if (!contextResult.updatedSMS) contextResult.updatedSMS = updatedSMS;
              return updatedSMS;
            }),
          },
        };
      }),
    };

    return { newData, ...contextResult };
  }, []);
  /**
   * A function to get the query filters that will match on `GetThread` queries that include the SMS associated with the given match values.
   * @param matchValues The values to match the SMS to.
   */
  const getGetThreadQueryFilters = useCallback<EndpointQueryFiltersFn<'GetThread'>>(
    ({ matchValues }) => {
      return {
        queryKey:
          matchValues.threadId || matchValues.locationId
            ? getQueryKey<'GetThread'>({
                endpointName: 'GetThread',
                request: {
                  locationId: matchValues.locationId,
                  threadId: matchValues.threadId,
                },
              })
            : undefined,
        predicate: ({ queryKey, state }) => {
          if (matchValues.threadId && queryKey[2].threadId !== matchValues.threadId) return false;
          if (matchValues.locationId && queryKey[2].locationId !== matchValues.locationId) return false;

          const hasSMS = matchValues.id
            ? !!state.data?.pages.some((page) => page.thread?.messages.some((sms) => sms.id === matchValues.id))
            : true;

          return hasSMS;
        },
        exact: false,
      };
    },
    [getQueryKey]
  );

  /**
   * A function to get the query filters that will match on `ListThreads` queries that a given SMS could affect.
   * @param matchValues The values to match the SMS to.
   */
  const getListThreadsQueryFilters = useCallback<
    EndpointQueryFiltersFn<'ListThreads', { isAutomated?: boolean; departmentId?: string }>
  >(({ matchValues, isAutomated, departmentId }) => {
    const queryFilters: ReturnType<EndpointQueryFiltersFn<'ListThreads'>> = {
      predicate: ({ queryKey }) => {
        const request = queryKey[2];
        if (request.avoidLastAutomated === true && isAutomated !== undefined && isAutomated) return false;
        const matchesSingleLocationId = matchValues.locationId ? request.locationId === matchValues.locationId : true;
        const hasGroupId = matchValues.locationId ? request.groupIds?.includes(matchValues.locationId) ?? true : true;
        const hasDepartmentId =
          request.departmentIds && departmentId !== undefined && departmentId
            ? request.departmentIds.includes(departmentId)
            : true;

        const isLocationMatch = matchesSingleLocationId || hasGroupId;
        return isLocationMatch && hasDepartmentId;
      },
    };

    return queryFilters;
  }, []);

  const upsertSMS = useCallback<UpsertSMSFn>(
    ({ sms, skipInvalidation }) => {
      // GetSMS
      updateQuery<'GetSMS'>({
        endpointName: 'GetSMS',
        queryFilters: getGetSMSQueryFilters({
          matchValues: { id: sms.id, threadId: sms.threadId, locationId: sms.locationId },
        }),
        updater: (oldData) => ({
          ...oldData,
          sms,
        }),
      });

      // GetThread
      const queriesKeysToInvalidate: SchemaQueryKey<ServiceQueries, 'GetThread'>[] = [];
      const queryKeysToUpdate: SchemaQueryKey<ServiceQueries, 'GetThread'>[] = [];
      const getThreadQueryFilters = getGetThreadQueryFilters({
        matchValues: {
          id: '', // Leave this blank, because even if we have the sms id, we don't want to match on it, since it's being added to the thread
          threadId: sms.threadId,
          locationId: sms.locationId,
        },
      });
      updateQuery<'GetThread', true>({
        endpointName: 'GetThread',
        queryFilters: {
          ...getThreadQueryFilters,
          predicate: (query) => {
            const providedPredicateResult = getThreadQueryFilters.predicate?.(query);
            if (providedPredicateResult === false) return false;

            // run update if sms already exists
            const smsExists = query.state.data?.pages.some((page) =>
              page.thread.messages.some((message) => message.id === sms.id)
            );
            if (smsExists) {
              queryKeysToUpdate.push(query.queryKey);
              return false;
            }

            const request = query.queryKey[2];
            const queryIsBidirectionallyPaginated = request.taggedSmsId || request.taggedCreatedAt;
            if (!queryIsBidirectionallyPaginated) return true;

            const newestSMS = query.state.data?.pages[0]?.thread.messages[0];
            const newestSMSIsOlder = newestSMS && newestSMS.createdAt > sms.createdAt;

            // If the thread has newer messages than the SMS, we can insert the SMS in the thread
            if (!newestSMSIsOlder) return true;

            const newestPage = query.state.data?.pages[0];
            const allPages = query.state.data?.pages;
            const hasNewestPage =
              !!newestPage && !!allPages && query.options.getPreviousPageParam?.(newestPage, allPages) === undefined;

            // If the thread doesn't have the newest page, we can't insert the SMS in the thread
            if (!hasNewestPage) return false;
            return providedPredicateResult ?? true;
          },
        },
        updater: (oldData, queryKey) => {
          // Note: the first page is the newest, and the first message is the newest on any page.
          const { pageForNewSMSIndex, nextMessageIndex } = oldData.pages.reduce<{
            pageForNewSMSIndex: number;
            nextMessageIndex: number;
          }>(
            (acc, page, pageIndex) => {
              if (acc.pageForNewSMSIndex !== -1) return acc;
              const nextMessageIndex = page.thread.messages.findIndex(
                (existingSMS) => existingSMS.createdAt < sms.createdAt
              );
              if (nextMessageIndex !== -1) return { pageForNewSMSIndex: pageIndex, nextMessageIndex };
              return acc;
            },
            { pageForNewSMSIndex: -1, nextMessageIndex: -1 }
          );

          if (pageForNewSMSIndex === -1 || nextMessageIndex === -1) {
            queriesKeysToInvalidate.push(queryKey);
            return oldData;
          }

          return {
            ...oldData,
            // TODO: see if these changes should be moved to the shared updater function
            pages: oldData.pages.map((page, index) => {
              if (index !== pageForNewSMSIndex) return page;

              const messagesBeforeNewSMS = page.thread.messages.slice(0, nextMessageIndex);
              const messagesAfterNewSMS = page.thread.messages.slice(nextMessageIndex);
              const shouldUpdateThreadStatuses = messagesBeforeNewSMS.length === 0;

              const newThreadStatus = shouldUpdateThreadStatuses ? getThreadStatusFromSMS(sms) : page.thread.status;
              const newThreadIsRepliedStatus = shouldUpdateThreadStatuses
                ? sms.direction === Direction.DIRECTION_OUTBOUND && !!sms.autogeneratedBy
                : page.thread.isReplied;
              const updatedThreadTags =
                page.thread.uniqueTags?.map((tag) =>
                  sms.tags.includes(tag.tagId) && tag.smsCreatedAt < sms.createdAt
                    ? { ...tag, smsId: sms.id, smsCreatedAt: sms.createdAt }
                    : tag
                ) ?? [];
              const newThreadTags: typeof updatedThreadTags = sms.tags
                .filter((tag) => !updatedThreadTags.some((updatedTag) => updatedTag.tagId === tag))
                .map((tag) => ({ tagId: tag, smsId: sms.id, smsCreatedAt: sms.createdAt, appliedBy: sms.createdBy }));

              return {
                ...page,
                thread: {
                  ...page.thread,
                  status: newThreadStatus,
                  isReplied: newThreadIsRepliedStatus,
                  uniqueTags: newThreadTags,
                  messages: [...messagesBeforeNewSMS, sms, ...messagesAfterNewSMS],
                },
              };
            }),
          };
        },
      });
      if (!skipInvalidation)
        queriesKeysToInvalidate.forEach((queryKey) => {
          invalidateQueries<'GetThread', true>({
            endpointName: 'GetThread',
            queryFilters: {
              queryKey,
              exact: true,
            },
          });
        });
      queryKeysToUpdate.forEach((queryKey) => {
        updateQuery<'GetThread', true>({
          endpointName: 'GetThread',
          queryFilters: {
            queryKey,
            exact: true,
          },
          updater: (oldData) => {
            const result = getThreadSMSUpdater({
              oldData,
              matchValues: {
                id: sms.id,
                threadId: sms.threadId,
                locationId: sms.locationId,
              },
              newValues: sms,
            });

            return result.newData;
          },
        });
      });

      // ListThreads
      if (!skipInvalidation)
        invalidateQueries<'ListThreads', true>({
          endpointName: 'ListThreads',
          queryFilters: getListThreadsQueryFilters({
            matchValues: {
              id: '',
              threadId: sms.threadId,
              locationId: sms.locationId,
            },
            isAutomated: !!sms.autogeneratedBy,
            departmentId: sms.departmentId,
          }),
        });

      return { updatedSMS: sms };
    },
    [updateQuery, getGetSMSQueryFilters, getGetThreadQueryFilters, invalidateQueries, getListThreadsQueryFilters]
  );

  const updateSMS = useCallback<UpdateSMSFn>(
    ({ matchValues, newValues, skipInvalidation }) => {
      const contextResult: ReturnType<UpdateSMSFn> = {};

      /**
       * Helper function that will run the updaterFn for a given endpoint, and updates the contextResult with the original and updated SMS.
       * @returns The updated data for the endpoint.
       */
      const updaterWithUpdateContextResult = <EndpointName extends QueryEndpointName>({
        oldData,
        updaterFn,
      }: {
        oldData: EndpointName extends InfiniteQueryEndpointName
          ? InfiniteData<SchemaIO<ServiceQueries[EndpointName]>['output']>
          : SchemaIO<ServiceQueries[EndpointName]>['output'];
        updaterFn: EndpointSMSUpdaterFn<EndpointName>;
      }): EndpointName extends InfiniteQueryEndpointName
        ? InfiniteData<SchemaIO<ServiceQueries[EndpointName]>['output']>
        : SchemaIO<ServiceQueries[EndpointName]>['output'] => {
        const newContext = updaterFn({ matchValues, oldData, newValues });
        if (!contextResult.originalSMS) contextResult.originalSMS = newContext.originalSMS;
        if (!contextResult.updatedSMS) contextResult.updatedSMS = newContext.updatedSMS;
        return newContext.newData;
      };

      // GetSMS
      updateQuery<'GetSMS'>({
        endpointName: 'GetSMS',
        queryFilters: getGetSMSQueryFilters({ matchValues }),
        updater: (oldData) => updaterWithUpdateContextResult<'GetSMS'>({ oldData, updaterFn: getSMSUpdater }),
      });

      // GetThread
      updateQuery<'GetThread', true>({
        endpointName: 'GetThread',
        queryFilters: getGetThreadQueryFilters({ matchValues }),
        updater: (oldData) => updaterWithUpdateContextResult<'GetThread'>({ oldData, updaterFn: getThreadSMSUpdater }),
      });

      // ListThreads
      if (!skipInvalidation)
        invalidateQueries<'ListThreads', true>({
          endpointName: 'ListThreads',
          queryFilters: getListThreadsQueryFilters({ matchValues }),
        });

      return contextResult;
    },
    [
      getGetSMSQueryFilters,
      getSMSUpdater,
      getGetThreadQueryFilters,
      getThreadSMSUpdater,
      getListThreadsQueryFilters,
      updateQuery,
    ]
  );

  const deleteSMS = useCallback<DeleteSMSFn>(
    ({
      matchValues,
      forceSoftDelete = false,
      deletedAt: providedDeletedAt,
      deletedBy: providedDeletedBy,
      statusDetails,
      skipInvalidation,
    }) => {
      const updateNewValues: Parameters<typeof updateSMS>[0]['newValues'] = {
        deletedAt: providedDeletedAt || new Date().toISOString(),
        deletedBy: providedDeletedBy || user?.userID || '',
      };
      if (statusDetails) updateNewValues.statusDetails = statusDetails;
      if (forceSoftDelete) {
        return updateSMS({
          matchValues,
          newValues: updateNewValues,
          skipInvalidation,
        });
      }

      const contextResult: ReturnType<DeleteSMSFn> = {};

      // GetSMS
      if (!skipInvalidation)
        invalidateQueries({
          endpointName: 'GetSMS',
          queryFilters: getGetSMSQueryFilters({ matchValues }),
        });

      // GetThread
      const matchingQueries = getQueriesData<'GetThread', true>({
        endpointName: 'GetThread',
        queryFilters: getGetThreadQueryFilters({ matchValues }),
      });
      const { queriesToSoftDelete, queriesToFilter } = matchingQueries.reduce<{
        queriesToSoftDelete: typeof matchingQueries;
        queriesToFilter: typeof matchingQueries;
      }>(
        (acc, [queryKey, data]) => {
          if (queryKey[2].includeDeleted) {
            acc.queriesToSoftDelete.push([queryKey, data]);
            return acc;
          }
          acc.queriesToFilter.push([queryKey, data]);
          return acc;
        },
        {
          queriesToSoftDelete: [],
          queriesToFilter: [],
        }
      );
      queriesToSoftDelete.forEach(([queryKey]) => {
        updateQuery<'GetThread', true>({
          endpointName: 'GetThread',
          queryFilters: { queryKey, exact: true },
          updater: (oldData) => {
            const newContext = getThreadSMSUpdater({ oldData, matchValues, newValues: updateNewValues });
            if (!contextResult.originalSMS) contextResult.originalSMS = newContext.originalSMS;
            if (!contextResult.updatedSMS) contextResult.updatedSMS = newContext.updatedSMS;
            return newContext.newData;
          },
        });
      });
      queriesToFilter.forEach(([queryKey]) => {
        updateQuery<'GetThread', true>({
          endpointName: 'GetThread',
          queryFilters: {
            queryKey,
            exact: true,
          },
          updater: (oldData) => ({
            ...oldData,
            pages: oldData.pages.map((page) => {
              const threadHasSMS = page.thread.messages.some((sms) => sms.id === matchValues.id);
              if (!threadHasSMS) return page;

              return {
                ...page,
                thread: {
                  ...page.thread,
                  messages: page.thread.messages.filter((sms) => {
                    if (sms.id !== matchValues.id) return true;
                    if (!contextResult.originalSMS) contextResult.originalSMS = sms;
                    return false;
                  }),
                },
              };
            }),
          }),
        });
      });

      // ListThreads
      if (!skipInvalidation)
        invalidateQueries<'ListThreads', true>({
          endpointName: 'ListThreads',
          queryFilters: getListThreadsQueryFilters({ matchValues }),
        });

      return contextResult;
    },
    [
      updateSMS,
      invalidateQueries,
      getGetSMSQueryFilters,
      getQueriesData,
      getGetThreadQueryFilters,
      updateQuery,
      getListThreadsQueryFilters,
    ]
  );

  return {
    /**
     * A function to create an SMS on all relevant queries.
     * @param sms The SMS to create.
     * @param skipInvalidation (optional) Whether to skip invalidating queries. Defaults to `false`. This is useful for optimistic updates.
     * @returns An object with the updated SMS (meaning the created SMS).
     */
    upsertSMS,
    /**
     * A function to update an SMS on all relevant queries.
     * @param matchValues The values to match the SMS to update.
     * @param newValues The new values to update the SMS with, or a function that updates an SMS.
     * @param skipInvalidation (optional) Whether to skip invalidating queries. Defaults to `false`. This is useful for optimistic updates.
     * @returns An object with the original and updated SMS, if found.
     */
    updateSMS,
    /**
     * A function to delete an SMS on all relevant queries.
     * @param matchValues The values to match the SMS to delete.
     * @param forceSoftDelete (optional) Whether to force a soft delete or not. Defaults to `false`. If `false`,
     * queries that include deleted SMS will be soft-deleted, and queries that do not include deleted SMS will be
     * filtered to not include the deleted SMS.
     * @param deletedAt (optional) The date and time the SMS was deleted. Defaults to current time.
     * @param deletedBy (optional) The user who deleted the SMS. Defaults to the current user.
     * @param statusDetails (optional) The status details to set on the SMS. Defaults to `''`.
     * @param skipInvalidation (optional) Whether to skip invalidating queries. Defaults to `false`. This is useful for optimistic updates.
     * @returns An object with the original and updated SMS, if found. The updated SMS will only be returned if a soft delete occurs.
     */
    deleteSMS,
  };
};
