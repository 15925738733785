import { InvoiceFilterType, InvoiceFilterStatus } from '@frontend/api-invoices';
import { useInvoiceShallowStore } from '@frontend/payments-invoice-controller';
import { InvoiceRefundStatus } from '../../types';
import { DefaultFilters, useQuickFilters } from '../utils';

export type PaymentRequestFilterTypes = 'allInvoices' | 'paid' | 'refunded' | 'unpaid';

export const usePaymentRequestQuickFilter = () => {
  const { filter, setFilter } = useInvoiceShallowStore('filter', 'setFilter');
  const defaultFilters: DefaultFilters<PaymentRequestFilterTypes, InvoiceFilterType> = {
    allInvoices: {
      status: [],
    },
    paid: {
      status: [InvoiceFilterStatus.paid, InvoiceFilterStatus.partiallyPaid],
    },
    refunded: {
      status: [InvoiceRefundStatus.PartialRefund, InvoiceRefundStatus.Refunded],
    },
    unpaid: {
      status: [InvoiceFilterStatus.unpaid],
    },
  };

  const { isFilterActive, handleQuickFilter } = useQuickFilters({ defaultFilters, filter, setFilter });

  return { defaultFilters, isFilterActive, handleQuickFilter };
};
