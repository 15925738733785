import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Modal, Text, ModalControlModalProps, useModalControl } from '@frontend/design-system';

type OnlineSchedulingInfoIconProps = {
  isIntegratedOffice: boolean;
};

export const OnlineSchedulingInfoIcon = ({ isIntegratedOffice }: OnlineSchedulingInfoIconProps) => {
  const { triggerProps, modalProps } = useModalControl();

  return (
    <>
      <Icon size={16} name='info' {...triggerProps} />
      <OnlineSchedulingInfoIconModal isIntegratedOffice={isIntegratedOffice} modalControlProps={modalProps} />
    </>
  );
};

type OnlineSchedulingInfoIconModalProps = OnlineSchedulingInfoIconProps & {
  modalControlProps: ModalControlModalProps;
};

const OnlineSchedulingInfoIconModal = ({
  isIntegratedOffice,
  modalControlProps,
}: OnlineSchedulingInfoIconModalProps) => {
  const { t } = useTranslation('scheduleGlobalSettings');

  // TODO: Validate verbiage of non integrated before release
  return (
    <Modal {...modalControlProps} maxWidth={500}>
      <Modal.Header onClose={modalControlProps.onClose}>
        {isIntegratedOffice ? t('Online Scheduling') : t('Show on Calendar')}
      </Modal.Header>
      <Modal.Body css={{ marginTop: theme.spacing(2) }}>
        <Text>
          {isIntegratedOffice
            ? t(
                'This column controls which appointment types are available for clients to view and book through our online scheduling system.'
              )
            : t(
                'This column controls which appointment types are available for booking an appointment in the calendar view'
              )}
        </Text>
        <ul css={{ marginTop: theme.spacing(2) }}>
          <li>
            <div css={listStyles}>
              <Text weight='bold'>{t('ON:')}</Text>
              <Text>
                {isIntegratedOffice
                  ? t('Visible and bookable online')
                  : t('Appointment type will be available while creating appointment')}
              </Text>
            </div>
          </li>
          <li>
            <div css={listStyles}>
              <Text weight='bold'>{t('OFF:')}</Text>
              <Text>
                {isIntegratedOffice
                  ? t('Not available for online booking')
                  : t('Not available for booking appointment')}
              </Text>
            </div>
          </li>
        </ul>
      </Modal.Body>
    </Modal>
  );
};

const listStyles = css({
  display: 'flex',
  gap: theme.spacing(1),
});
