import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing(2)};
  padding: ${theme.spacing(2.5, 4)};

  .action-panel {
    display: flex;
    gap: ${theme.spacing(2)};
  }
`;
