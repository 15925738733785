import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import appConfig from '@frontend/env';
import { theme } from '@frontend/theme';
import { ContentLoader } from '@frontend/design-system';
import { CenteredSpinningLoader } from '../centered-spinning-loader/centered-spinning-loader.component';

interface FormPreviewProps {
  token: string;
  type: 'form' | 'packet' | 'template';
  hideFooterLinks?: boolean;
  disableReviewMode?: boolean;
  isReloading?: boolean;
}

const iframeStyle = css`
  width: 100%;
  max-width: 400px;
  height: 100%;
  border: none;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${theme.colors.white};
`;

export function getIframeStyle(isVisible: boolean) {
  if (!isVisible) {
    return [
      iframeStyle,
      css`
        display: none;
      `,
    ];
  }

  return iframeStyle;
}

/**
 * @deprecated
 */
export const FormPreview = ({ token, type, hideFooterLinks, disableReviewMode, isReloading }: FormPreviewProps) => {
  const { FORMS_EXPERIENCE_URL } = appConfig;
  const [isLoading, setIsLoading] = useState(true);
  const [url, setUrl] = useState('');

  useEffect(() => {
    if (isReloading) {
      setIsLoading(true);
    }
  }, [isReloading]);

  // Prepare the URL for the experience view
  useEffect(() => {
    if (token) {
      const url = new URL(`${FORMS_EXPERIENCE_URL}/form-preview`);
      url.searchParams.append('templateId', token);

      if (hideFooterLinks) {
        url.searchParams.append('hideFooterLinks', 'true');
      }

      if (disableReviewMode) {
        url.searchParams.append('disableReviewMode', 'true');
      }

      setUrl(url.toString());
    }
  }, [token, type, hideFooterLinks, disableReviewMode]);

  if (isReloading) {
    return <ContentLoader />;
  }

  return (
    <section
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      `}
    >
      {isLoading && (
        <div>
          <CenteredSpinningLoader />
        </div>
      )}

      <iframe src={url} css={getIframeStyle(!isLoading)} onLoad={() => setIsLoading(false)} />
    </section>
  );
};
