type SoftphoneTabMessageType =
  | {
      type: 'SOFTPHONE_TAB';
      action: 'REGISTER' | 'UNREGISTER' | 'ACTIVATE';
      payload: {
        tabId: string;
      };
    }
  | {
      type: 'SOFTPHONE_TAB';
      action: 'CLEAR';
    };

type SoftphoneStateMessageType =
  | {
      type: 'SOFTPHONE';
      action: 'CONNECTED' | 'DISCONNECTED';
      payload: {
        tabId: string;
      };
    }
  | {
      type: 'SOFTPHONE';
      action: 'ACTIVE_CALL';
      payload: {
        tabId: string;
        isActive: boolean;
      };
    };

/**
 * These functions update localStorage, which emits a storage event to all other tabs.
 */

const unregisterTab = (tabId: string) => {
  if (navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({
      type: 'SOFTPHONE_TAB',
      action: 'UNREGISTER',
      payload: {
        tabId,
      },
    } satisfies SoftphoneTabMessageType);
  }
};

const registerTab = (tabId: string) => {
  if (navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({
      type: 'SOFTPHONE_TAB',
      action: 'REGISTER',
      payload: {
        tabId,
      },
    } satisfies SoftphoneTabMessageType);
  }
};

const activateTab = (tabId: string) => {
  if (navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({
      type: 'SOFTPHONE_TAB',
      action: 'ACTIVATE',
      payload: {
        tabId,
      },
    } satisfies SoftphoneTabMessageType);
  }
};

const clearTabStore = () => {
  if (navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({
      type: 'SOFTPHONE_TAB',
      action: 'CLEAR',
    } satisfies SoftphoneTabMessageType);
  }
};

const broadcastConnected = (tabId: string) => {
  if (navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({
      type: 'SOFTPHONE',
      action: 'CONNECTED',
      payload: {
        tabId,
      },
    } satisfies SoftphoneStateMessageType);
  }
};

const broadcastDisconnected = (tabId: string) => {
  if (navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({
      type: 'SOFTPHONE',
      action: 'DISCONNECTED',
      payload: {
        tabId,
      },
    } satisfies SoftphoneStateMessageType);
  }
};

const broadcastActiveCall = (payload: { tabId: string; isActive: boolean }) => {
  if (navigator.serviceWorker.controller) {
    navigator.serviceWorker.controller.postMessage({
      type: 'SOFTPHONE',
      action: 'ACTIVE_CALL',
      payload,
    } satisfies SoftphoneStateMessageType);
  }
};

export const SoftphoneStateInterface = {
  broadcastConnected,
  broadcastDisconnected,
  broadcastActiveCall,

  registerTab,
  unregisterTab,
  activateTab,
  clearTabStore,
};
