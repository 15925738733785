import { PropsWithChildren } from 'react';
import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { PopoverMenu, usePopoverMenu } from '@frontend/design-system';
import { EmojiAction } from './emoji-action';
import { FlyoutMessageMenuActions } from './flyout-message-menu-actions';

interface ActionsBarPopoverProps {
  getMenuProps: ReturnType<typeof usePopoverMenu>['getMenuProps'];
}

export const ActionsBarPopover = ({ children, getMenuProps }: PropsWithChildren<ActionsBarPopoverProps>) => {
  return (
    // Overriding the onKeyDown function to allow searching (typing) in the emoji picker
    // TODO :: add support in design-system PopoverMenu if this breaks anything
    <PopoverMenu {...getMenuProps()} css={styles.popover} onKeyDown={undefined}>
      {children}
    </PopoverMenu>
  );
};

ActionsBarPopover.Emoji = EmojiAction;
ActionsBarPopover.FlyoutMenu = FlyoutMessageMenuActions;

const styles = {
  popover: css`
    border-radius: ${theme.borderRadius.small};
    border: 1px solid ${theme.colors.neutral20};
    display: flex;
    flex-direction: row;
    gap: ${theme.spacing(0.5)};
    margin-top: ${theme.spacing(4)};
    min-width: ${theme.spacing(5)};
    padding: ${theme.spacing(0.5)};
    box-shadow: none;
  `,
};
