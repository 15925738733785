import { Query } from 'react-query';
import {
  GetPractitionerByPractitionerIdApiType,
  ListAppointmentTypesApiType,
  ListAppointmentTypesMultiLocationApiType,
  ListAppointmentTypesPractitionersApiType,
  ListCalendarEventsParamsType,
  ListPractitionersApiType,
  ListPractitionersMultiLocationApiType,
} from './types';

export const AppointmentTypesQueryKeys = {
  getAppointmentTypeInfiniteList: (request: ListAppointmentTypesApiType['input']) => [
    'appointmentTypesInfiniteList',
    request,
  ],
  getAppointmentTypesInfiniteListForMultiLocation: (request: ListAppointmentTypesMultiLocationApiType['input']) => [
    'appointmentTypesInfiniteListMultiLocation',
    request,
  ],
  getAppointmentTypes: (locationId: string, page?: string, limit?: string, searchValue?: string) => [
    'appointmentTypes',
    locationId,
    page,
    limit,
    searchValue,
  ],
  getAppointmentTypesMultiLocation: (
    locationIds: string[],
    parentLocationId: string,
    page?: string,
    limit?: string,
    searchValue?: string
  ) => ['appointmentTypesMultiLocation', ...locationIds, parentLocationId, page, limit, searchValue],
};

export const PractitionerQueryKeys = {
  getPractitionerInfiniteList: (request: ListPractitionersApiType['input']) => ['practitionersInfiniteList', request],
  getPractitionerInfiniteListForMultiLocation: (request: ListPractitionersMultiLocationApiType['input']) => [
    'practitionersInfiniteListForMultiLocation',
    request,
  ],
  getPractitionersList: (request: ListPractitionersApiType['input']) => ['practitionerList', request],
  getPractitionersListForMultiLocation: (req: ListPractitionersMultiLocationApiType['input']) => [
    'practitionerListForMultiLocation',
    req,
  ],
  getPractitionerByPractitionerId: (request: GetPractitionerByPractitionerIdApiType['input']) => [
    'practitioner',
    request,
  ],
};

export const practitionerQueryPredicate = {
  getPractitionersList: (locationId?: string) => (query: Query) => {
    const key = PractitionerQueryKeys.getPractitionersList({ locationId: '' });
    if (query.queryKey[0] === key[0]) {
      const queryReq = query.queryKey[1] as ListPractitionersApiType['input'];
      return !locationId || queryReq.locationId === locationId;
    }
    return false;
  },
};

export const AppointmentTypesPractitionerQueryKeys = {
  getAppointmentTypePractitionersList: (req: ListAppointmentTypesPractitionersApiType['input']) => [
    'appointmentTypesPractitioners',
    req,
  ],
};

export const appointmentTypePractitionerQueryPredicate = {
  getAppointmentTypePractitionersList: (locationIds?: string[]) => (query: Query) => {
    const key = AppointmentTypesPractitionerQueryKeys.getAppointmentTypePractitionersList({ locationIds: [] });
    if (query.queryKey[0] === key[0]) {
      const queryReq = query.queryKey[1] as ListAppointmentTypesPractitionersApiType['input'];
      // remove if at-least one location is common
      return (
        !locationIds?.length ||
        !queryReq.locationIds.length ||
        queryReq.locationIds.some((id) => locationIds?.includes(id))
      );
    }
    return false;
  },
};

export const ScheduleEntriesQueryKeys = {
  getScheduleEntriesList: (locationId: string, entityIds: string[]) => ['scheduleEntries', locationId, ...entityIds],
};

export const CalendarEventsQueryKeys = {
  listCalendarEvents: ({
    limit,
    page,
    status,
    startDateTime,
    endDateTime,
    type,
    sortOrder,
  }: ListCalendarEventsParamsType) => [
    'calendarEvents',
    limit,
    page,
    status,
    type,
    startDateTime,
    endDateTime,
    sortOrder,
  ],
  listCalendarEventsMulti: ({
    startDateTime,
    endDateTime,
    locationIds,
    limit,
    page,
    sortOrder,
    status,
    type,
  }: ListCalendarEventsParamsType & { locationIds: string[] }) => [
    'calendarEventsMulti',
    ...locationIds,
    limit,
    page,
    status,
    startDateTime,
    endDateTime,
    sortOrder,
    type,
  ],
  listCalendarEventsByOwner: ({
    startDateTime,
    endDateTime,
    ownerId,
    limit,
    page,
    sortOrder,
  }: ListCalendarEventsParamsType & { ownerId: string }) => [
    'calendarEventsByOwner',
    ownerId,
    limit,
    page,
    startDateTime,
    endDateTime,
    sortOrder,
  ],
  getCalendarEvent: (eventId: string) => ['calendarEvents', eventId],
};
export const OperatoriesQueryKeys = {
  getOperatoriesList: (locationId: string, page?: string, limit?: string, searchValue?: string) => [
    'operatories',
    locationId,
    page,
    limit,
    searchValue,
  ],
  getOperatoriesListForMultiLocation: (
    locationIds: string[],
    parentLocationId: string,
    page?: string,
    limit?: string,
    searchValue?: string
  ) => ['operatories', ...locationIds, parentLocationId, page, limit, searchValue],
  getOperatoryByOperatoryId: (operatoryId: string) => ['operatories', operatoryId],
};
