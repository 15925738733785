import { FC, ReactNode, useCallback, useMemo } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Heading, NakedButton, Text } from '@frontend/design-system';
import { SCHEDULE_CALL_CHILLIPIPER_URL, SelfUpgradeActionType } from '../../constants/self-upgrade';
import { getFeatureWiseTrackingId, SelfUpgradeLandingPageTrackingIds } from '../../constants/tracking-ids';
import { useGetSelfUpgradeAction } from '../../hooks/useGetSelfUpgradeAction';
import { useFeatureSubscription } from '../../providers/FeatureSubscriptionProvider';
import { FeaturePromotionMode, FeaturePromotionPageBaseProps } from '../../types';

interface Props extends FeaturePromotionPageBaseProps {
  title: string | ReactNode;
  description: string | ReactNode;
  media?: { src: string; alt: string };
}

export const FeaturePromotionBasePage: FC<Props> = ({
  title,
  description,
  isDemoAvailable,
  media,
  mode = 'fullPage',
  handleOpenUpgradeModal,
  handleViewDemo,
}) => {
  const { feature } = useFeatureSubscription();
  const { actionType, actionIconName, actionTextData, isLoading } = useGetSelfUpgradeAction();
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'self-upgrade-actions' });

  const handlePromoActionClick = useCallback(() => {
    switch (actionType) {
      case SelfUpgradeActionType.SCHEDULE_CALL:
        window.open(SCHEDULE_CALL_CHILLIPIPER_URL, '_blank');
        break;
      case SelfUpgradeActionType.REQUEST_UPGRADE:
      case SelfUpgradeActionType.UPGRADE_NOW:
      case SelfUpgradeActionType.FREE_TRIAL:
        handleOpenUpgradeModal();
        break;
      default:
        return;
    }
  }, [actionType]);

  const actionButtonTrackingId = useMemo<string | undefined>(() => {
    switch (actionType) {
      case SelfUpgradeActionType.SCHEDULE_CALL:
        return getFeatureWiseTrackingId(feature, SelfUpgradeLandingPageTrackingIds.scheduleCallAction);
      case SelfUpgradeActionType.REQUEST_UPGRADE:
        return getFeatureWiseTrackingId(feature, SelfUpgradeLandingPageTrackingIds.requestUpgradeAction);
      case SelfUpgradeActionType.UPGRADE_NOW:
        return getFeatureWiseTrackingId(feature, SelfUpgradeLandingPageTrackingIds.upgradeAction);
      case SelfUpgradeActionType.FREE_TRIAL:
        return getFeatureWiseTrackingId(feature, SelfUpgradeLandingPageTrackingIds.freeTrialAction);
      default:
        return;
    }
  }, [actionType, feature]);

  return (
    <div css={layoutStyles(mode)}>
      {typeof title === 'string' ? <Heading level={2}>{title}</Heading> : title}
      {typeof description === 'string' ? (
        <Text size={'large'} textAlign='center' css={{ maxWidth: 512, padding: theme.spacing(0, 3) }}>
          {description}
        </Text>
      ) : (
        description
      )}
      <div css={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: theme.spacing(1) }}>
        {!isLoading && !!actionTextData?.primaryButtonText && (
          <>
            <NakedButton trackingId={actionButtonTrackingId} css={actionButtonStyles} onClick={handlePromoActionClick}>
              <Icon name={actionIconName} size={16} color='white' />
              <Text as='span' color='white' weight='bold'>
                {actionTextData?.primaryButtonText}
              </Text>
            </NakedButton>
            {actionTextData?.actionInfoText && (
              <Text color='light' size='medium'>
                {actionTextData?.actionInfoText}
              </Text>
            )}
          </>
        )}
        {isDemoAvailable && (
          <NakedButton
            trackingId={getFeatureWiseTrackingId(feature, SelfUpgradeLandingPageTrackingIds.viewDemoAction)}
            css={{ marginTop: theme.spacing(1) }}
            onClick={handleViewDemo}
          >
            <Text color='primary' weight='bold' size='medium'>
              {t('View Demo')}
            </Text>
          </NakedButton>
        )}
      </div>
      {!!media?.src && <img src={media.src} css={mediaStyles} alt={media?.alt} />}
    </div>
  );
};

const layoutStyles = (mode: FeaturePromotionMode) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),
    padding: mode === 'fullPage' ? theme.spacing(8, 0) : 0,
  });

const mediaStyles = css({
  height: 'auto',
  width: 700,
  maxWidth: '100%',
  padding: theme.spacing(0, 3),
  borderRadius: theme.borderRadius.medium,
});
const actionButtonStyles = css({
  background: theme.colors.primary50,
  height: theme.spacing(4),
  padding: theme.spacing(0, 1.5),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(0.5),
  borderRadius: theme.borderRadius.small,
});
