import { PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { demoDataUtils } from '../../../utils';
import { generateHistoricalData, requiredPatientInfoFields, sumHistoricalDataPatients } from './shared-helpers';

const dayValues = [18];
const historicalDataDay = generateHistoricalData(dayValues);

const weekValues = [10, 9, 9, 0, 0, 15, 13];
const historicalDataWeek = generateHistoricalData(weekValues);

const monthValues = [
  7, 42, 15, 31, 20, 39, 47, 16, 12, 26, 4, 23, 29, 48, 33, 41, 19, 38, 49, 6, 9, 22, 36, 27, 30, 13, 25, 11, 5, 45,
];
const historicalDataMonth = generateHistoricalData(monthValues);

const sumProductions = (data: PracticeAnalyticsTypes.HistoricalDataTotal[]) =>
  data.reduce((acc, cur) => acc + (cur.production || 0), 0);

const getAverageLeadTime = (data: PracticeAnalyticsTypes.HistoricalDataTotal[]) =>
  Math.round(data.reduce((acc, cur) => acc + (cur.leadTime || 0), 0) / data.length);

const generateRecapturedPatientsDetails = (): PracticeAnalyticsTypes.PatientInfo[] =>
  demoDataUtils.generateRandomUserNames(15).map(({ firstName, lastName }, id) => ({
    ...requiredPatientInfoFields,
    FirstName: firstName,
    id: `${id}`,
    LastName: lastName,
    MobilePhone: demoDataUtils.generateRandomPhoneNumber(),
    nextAppointmentDate: demoDataUtils.generateRandomDate(true),
    nextAppointmentScheduledDate: demoDataUtils.generateRandomDate(true),
    productionAmount: demoDataUtils.generateRandomInt(220, 1000),
  }));

const generateRecapturedPatientsData = (
  historicalData: PracticeAnalyticsTypes.HistoricalDataTotal[]
): PracticeAnalyticsTypes.RecapturedPatientsTrend => ({
  location: {
    recapturedPatients: {
      historicalData: historicalData,
      details: {
        patients: generateRecapturedPatientsDetails(),
      },
      totals: {
        leadTime: getAverageLeadTime(historicalData),
        patients: sumHistoricalDataPatients(historicalData),
        production: sumProductions(historicalData),
      },
    },
  },
});

export const recapturedPatients = () => ({
  day: generateRecapturedPatientsData(historicalDataDay),
  week: generateRecapturedPatientsData(historicalDataWeek),
  month: generateRecapturedPatientsData(historicalDataMonth),
  custom: generateRecapturedPatientsData(historicalDataMonth),
});
