import { useCallback, useState } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { InvoiceModel, InvoiceSearchParams, InvoiceStatus, limitDateRange } from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import { useInvoiceShallowStore, useExportInvoicesQuery } from '@frontend/payments-invoice-controller';
import { debounce } from '@frontend/timer';
import { theme } from '@frontend/theme';
import {
  ButtonBar,
  DeleteIcon,
  ExternalLinkIcon,
  Heading,
  MarkIcon,
  Modal,
  PrimaryButton,
  PrintIcon,
  RefundIcon,
  SecondaryButton,
  Text,
  UnmarkIcon,
  useModalControl,
  Table,
  ExportIcon,
  ContentLoader,
  useAlert,
} from '@frontend/design-system';
import {
  usePrintReceipt,
  useCancelInvoice,
  useCanDoAction,
  useToggleInvoiceRecorded,
  useGenerateColumns,
  useNavAlert,
} from '../../../hooks';
import { PaymentsPageVariant } from '../../../providers';
import { PaymentsNavAlerts, PaymentsTableInstances, calcAvailableToRefund, getLatestRefund } from '../../../utils';
import { RefundModal } from '../../Refunds';
import { FilterExportWarning, InvoiceExportModal, useInvoiceExportModalControl } from '../InvoiceExport';
import { InvoiceFilter } from '../InvoiceFilter';
import { generateColumns } from './generate-columns';

type InvoiceListPageProps = {
  invoices: InvoiceModel[];
  loading: boolean;
  hasMore: boolean;
  fetchPreviousPage: () => void;
  fetchNextPage: () => void;
  variant?: PaymentsPageVariant;
};

const FIELD_TO_ORDER: { [key: string]: InvoiceSearchParams['order'] } = {
  billedAmount: 'billedAmount',
  billedAt: 'billedAt',
  paidAmount: 'paidAmount',
  paidAt: 'paidAt',
};

export const InvoiceList = ({
  invoices,
  loading,
  hasMore,
  fetchPreviousPage,
  fetchNextPage,
  variant = 'portal',
}: InvoiceListPageProps) => {
  const { t } = useTranslation('payments');
  const navigate = useNavigate();
  const { canExport } = useCanDoAction();
  const isPaymentsAppVariant = variant === 'payments-app';

  useNavAlert(PaymentsNavAlerts.InvalidInvoice, 'Please select a valid invoice from the list.');

  //INVOICE PROVIDER STATE
  const { currentPage, numRows, setNumRows, setOrder, filter, resetCurrentPage } = useInvoiceShallowStore(
    'currentPage',
    'numRows',
    'setNumRows',
    'setOrder',
    'filter',
    'resetCurrentPage'
  );

  // INVOICE DATA
  const columns = useGenerateColumns<InvoiceModel>(generateColumns);

  // REFUNDS
  const alerts = useAlert();
  const { canRefund } = useCanDoAction();
  const { modalProps, triggerProps } = useModalControl({ disableReturnFocus: true });
  const { onClick: openModal } = triggerProps;
  const [refundInvoice, setRefundInvoice] = useState<InvoiceModel>();
  const issueRefund = (invoice: InvoiceModel) => {
    if (!canRefund) {
      alerts.error(t(`You don't have permission to perform a refund`));
      return;
    }
    setRefundInvoice(invoice);
    openModal();
  };
  // CANCEL MODAL
  const confirmCancel = useModalControl();
  const [invoiceToCancel, setInvoiceToCancel] = useState<InvoiceModel | undefined>();
  const openCancel = (invoice: InvoiceModel) => {
    setInvoiceToCancel(invoice);
    confirmCancel.openModal();
  };

  const openPaymentLink = (invoice: InvoiceModel) => {
    const paymentLink = invoice.links.payment;
    if (paymentLink) window.open(paymentLink, '_blank');
  };

  // QUICK ACTIONS
  const { toggleRecorded } = useToggleInvoiceRecorded();
  const printReceipt = usePrintReceipt();
  const { cancelInvoice } = useCancelInvoice();
  const { exportInvoices, isExporting } = useExportInvoicesQuery();

  const { showInvoiceExportWarning, ...invoiceModalProps } = useInvoiceExportModalControl();

  const handleExportInvoicesClick = useCallback(
    debounce(async () => {
      if (canExport) {
        const warnings: FilterExportWarning = {};

        if (filter.person) {
          warnings.searchParams = true;
        }
        // see if data will be truncated
        if (filter.dateRange && limitDateRange([filter.dateRange.start, filter.dateRange.end]).limited) {
          warnings.truncateDateRange = true;
        }
        // when other filters are applied with out a date range, they are truncated to the
        // default MAX range (3 months) because the result will show for all time
        else if (!filter.dateRange && Object.keys(filter).filter((value) => value !== 'dateRange').length > 0) {
          warnings.truncateDateRange = true;
        }

        // show warnings if any
        if (Object.keys(warnings).length > 0) {
          showInvoiceExportWarning(warnings);
        } else {
          exportInvoices();
        }
      } else {
        alerts.error(t('Payment admin role required'));
      }
    }, 1000),
    [filter]
  );

  const isPaidInvoice = (data: InvoiceModel) =>
    data.status === InvoiceStatus.Paid ||
    data.status === InvoiceStatus.PartiallyPaid ||
    data.payment?.status === 'PENDING' ||
    data.payment?.status === 'SUCCEEDED';

  return (
    <>
      <Table
        globalTrackingId='pay-portal-invoicelist'
        tableStyle={css`
          min-height: 500px;
          height: 100%;
        `}
        colConfig={columns}
        // TODO: adding this .slice is a bandaid fix for now
        // the v1/search/invoices endpoint does not indicate if there are NO next or previous links
        data={invoices.slice(0, numRows)}
        styleConfig={{
          columns: [
            {
              id: '*',
              cellStyler: css`
                padding: ${theme.spacing(1, 1.5)};
              `,
              headerStyler: css`
                padding: ${theme.spacing(2, 1.5)};
              `,
            },
          ],
        }}
        emptyStateConfig={{
          type: 'payments',
          header: t('No data to display'),
        }}
        hasFilterColumns
        hasResizeColumns
        hasResponsiveColWidths
        tableInstanceId={
          isPaymentsAppVariant ? PaymentsTableInstances.PaymentRequests : PaymentsTableInstances.Invoices
        }
        manualSortBy
        manualFilters
        manualFiltersRender={(modalProps, setShowNotification) => (
          <InvoiceFilter modalProps={modalProps} setShowNotification={setShowNotification} />
        )}
        isLoading={loading}
        loadingRowsCount={numRows}
        onSortChange={(res) => {
          resetCurrentPage();
          if (!res.length) {
            setOrder('-paidAt');
            return;
          }
          const { id, value } = res[0];
          if (id) {
            const order = FIELD_TO_ORDER[id];
            // fallback to -billedAt if field is unsupported
            setOrder(
              order
                ? (`${value === 'asc' ? '' : '-'}${FIELD_TO_ORDER[id]}` as InvoiceSearchParams['order'])
                : '-billedAt'
            );
          } else {
            setOrder('-billedAt');
          }
        }}
        tableActions={[
          {
            label: t('Export'),
            Icon: ExportIcon,
            onClick: handleExportInvoicesClick,
            disabled: !canExport,
          },
        ]}
        rowActions={{
          onRowClick: (invoice: InvoiceModel) => {
            navigate({
              to: isPaymentsAppVariant
                ? `/payments/invoices/${invoice.id}`
                : `/portal/payments/invoices/${invoice.id}/invoices`,
            });
          },
          shouldHover: true,
          actions: [
            // PAYMENT ACTIONS
            {
              label: t('Refund'),
              Icon: RefundIcon,
              onClick: issueRefund,
              hide: (data: InvoiceModel) => !isPaidInvoice(data) || calcAvailableToRefund(data) === 0,
              trackingId: 'pay-portal-invoicelist-btn-refund',
            },
            {
              label: t('Print Receipt'),
              Icon: PrintIcon,
              hide: (data: InvoiceModel) =>
                !(
                  data.status === InvoiceStatus.Paid ||
                  data.status === InvoiceStatus.PartiallyPaid ||
                  data.payment?.status === 'SUCCEEDED'
                ),
              onClick: (data: InvoiceModel) =>
                printReceipt(data, {
                  receiptType: data.payment?.hasRefund ? 'refund' : 'payment',
                  refundId: getLatestRefund(data)?.stripeId,
                }),
              trackingId: 'pay-portal-invoicelist-btn-print',
            },
            {
              label: t('Mark Recorded'),
              Icon: MarkIcon,
              hide: (data: InvoiceModel) =>
                !!data.payment?.recordedAt ||
                !(
                  data.status === InvoiceStatus.Paid ||
                  data.status === InvoiceStatus.PartiallyPaid ||
                  data.payment?.status === 'SUCCEEDED'
                ),
              onClick: (data: InvoiceModel) =>
                toggleRecorded({ data, paymentId: data.payment?.paymentId, recordedAt: data.payment?.recordedAt }),
              trackingId: 'pay-portal-invoicelist-btn-recorded',
            },
            {
              label: t('Mark Unrecorded'),
              Icon: UnmarkIcon,
              hide: (data: InvoiceModel) => !data.payment?.recordedAt,
              onClick: (data: InvoiceModel) =>
                toggleRecorded({ data, paymentId: data.payment?.paymentId, recordedAt: data.payment?.recordedAt }),
              trackingId: 'pay-portal-invoicelist-btn-unrecorded',
            },
            // PAYMENT ACTIONS
            {
              label: t('Cancel Invoice'),
              Icon: DeleteIcon,
              hide: (data: InvoiceModel) => isPaidInvoice(data),
              onClick: (data: InvoiceModel) => openCancel(data),
              trackingId: 'pay-portal-invoicelist-btn-cancel',
            },
            {
              label: t('Payment Link'),
              Icon: ExternalLinkIcon,
              hide: (data: InvoiceModel) => !data.links?.payment || isPaidInvoice(data),
              onClick: (data: InvoiceModel) => openPaymentLink(data),
              trackingId: 'pay-portal-invoicelist-link-payment',
            },
          ],
        }}
        isPaginated
        manualPaginationConfig={{
          page: currentPage,
          hasNext: hasMore,
          hasPrevious: currentPage !== 1,
          onNumRowsChange: (num) => {
            setNumRows(num);
            resetCurrentPage();
          },
          defaultRowsPerPage: numRows,
          rowsPerPageOptions: [10, 25, 50, 75, 100],
          handleChange: (prevOrNext: 'prev' | 'next') => {
            if (prevOrNext === 'prev') fetchPreviousPage();
            if (prevOrNext === 'next') fetchNextPage();
          },
        }}
      />
      <Modal
        {...confirmCancel.modalProps}
        maxWidth={382}
        css={css`
          border-radius: ${theme.borderRadius.small};
        `}
      >
        <Modal.Header>
          <Heading
            level={1}
            textAlign='center'
            css={css`
              margin-bottom: 0rem;
            `}
          >
            {t('Confirm Cancel Invoice')}
          </Heading>
        </Modal.Header>
        <Modal.Body
          css={css`
            overflow: visible;
          `}
        >
          <Text
            textAlign='center'
            css={css`
              margin-bottom: 1.5rem;
            `}
          >
            {t(
              'Canceling this invoice will delete it completely from your invoice reports. Do you still want to cancel this invoice?'
            )}
          </Text>
          <ButtonBar
            css={css`
              padding: ${theme.spacing(0, 1.5)};
            `}
          >
            <SecondaryButton onClick={confirmCancel.closeModal}>{t('Go Back')}</SecondaryButton>
            <PrimaryButton
              destructive
              onClick={() => {
                if (invoiceToCancel) {
                  cancelInvoice(invoiceToCancel);
                }
                confirmCancel.closeModal();
              }}
            >
              {t('Cancel Invoice')}
            </PrimaryButton>
          </ButtonBar>
        </Modal.Body>
      </Modal>
      {refundInvoice && <RefundModal invoice={refundInvoice} {...modalProps} />}
      <InvoiceExportModal {...invoiceModalProps} onConfirm={exportInvoices} />
      {isExporting && <ContentLoader show={true} message='Preparing Export...' />}
    </>
  );
};
