import { useState } from 'react';
import { css } from '@emotion/react';
import { Page } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { Tabs } from '@frontend/design-system';
import { AnalyticsPageViewTabs, DemoDataBanner, InfoTipPopover, NWXNavigationModal, PageHeader } from '../components';
import { AppointmentAnalyticsFilters, AppointmentChartsView, AppointmentTableView } from '../components/appointment';
import { useAppointmentAnalyticsShallowStore, useIsAppointmentDemoAccount } from '../components/appointment/hooks';
import { useIsMobile } from '../hooks';
import { trackingIds } from '../tracking-ids';
import { pageStyles } from './common-styles';

const DEFAULT_VIEW = 'charts-view';

export const AppointmentAnalytics = () => {
  const { t } = useTranslation('analytics');
  const { filterHintText } = useAppointmentAnalyticsShallowStore('filterHintText');
  const [activeTab, setActiveTab] = useState<string>(DEFAULT_VIEW);
  const [isFetchingChartsData, setIsFetchingChartsData] = useState<boolean>(false);
  const [isFetchingTableData, setIsFetchingTableData] = useState<boolean>(false);
  const isMobile = useIsMobile();
  const { isDemoAccount } = useIsAppointmentDemoAccount();

  return (
    <>
      <NWXNavigationModal />
      {isDemoAccount && (
        <DemoDataBanner title={t("You're taking Appointment Analytics for a test drive with demo data!")} />
      )}
      <Tabs initialTab={DEFAULT_VIEW} onChange={setActiveTab}>
        <Page
          action={
            !isMobile && (
              <AnalyticsPageViewTabs
                renderContent={
                  <AppointmentAnalyticsFilters isLoadingData={isFetchingChartsData || isFetchingTableData} />
                }
                trackingIds={trackingIds.appointmentAnalytics}
              />
            )
          }
          css={styles.page}
          subtitle={t('Showing results for {{filtersInfo}}', {
            filtersInfo: filterHintText,
          })}
          title={
            <PageHeader
              title={t('Appointment Analytics')}
              infoTip={
                <InfoTipPopover>
                  {t(
                    'A structured record of appointments at your selected locations. Data is fetched from your PMS/EHR system.'
                  )}
                </InfoTipPopover>
              }
            />
          }
        >
          {isMobile && (
            <AnalyticsPageViewTabs
              renderContent={
                <AppointmentAnalyticsFilters isLoadingData={isFetchingChartsData || isFetchingTableData} />
              }
              trackingIds={trackingIds.appointmentAnalytics}
            />
          )}
          <div css={pageStyles.panelsWrapper}>
            <Tabs.Panel controller='table-view' css={pageStyles.tabPanel} id='table-view-panel'>
              <AppointmentTableView onFetchStateChange={setIsFetchingTableData} />
            </Tabs.Panel>
            <Tabs.Panel controller='charts-view' css={pageStyles.tabPanel} id='charts-view-panel'>
              {activeTab === 'charts-view' && <AppointmentChartsView onFetchStateChange={setIsFetchingChartsData} />}
            </Tabs.Panel>
          </div>
        </Page>
      </Tabs>
    </>
  );
};

const styles = {
  page: css`
    max-width: 100%;
  `,
};
