import { FC } from 'react';
import { Button } from '@frontend/design-system';
import { useTeamChatStore } from '../../providers';
import { trayTopBarStyles, rotateCollapseButton, buttonSvgStyles } from './tray-top-bar.styles';

interface TrayTopBarProps {
  onClose: () => void;
}

export const TrayTopBar: FC<TrayTopBarProps> = ({ onClose }) => {
  const { isChatListExpanded, setIsChatListExpanded, activeConversation, isNewConversation, resetActiveConversation } =
    useTeamChatStore([
      'isChatListExpanded',
      'activeConversation',
      'setIsChatListExpanded',
      'isNewConversation',
      'resetActiveConversation',
    ]);

  const onClickExpand = () => {
    // collapse the chat list only when a conversation is selected either a new or an old one
    setIsChatListExpanded(!isChatListExpanded);
  };

  const onClosePanel = () => {
    if (isNewConversation) {
      resetActiveConversation();
    }
    onClose();
  };

  return (
    <header css={[trayTopBarStyles, !activeConversation && { justifyContent: 'flex-end' }]}>
      {activeConversation && (
        <Button
          iconName='collapse-right'
          onClick={onClickExpand}
          css={[{ svg: { width: '20px', height: '20px' } }, !isChatListExpanded && rotateCollapseButton]}
          variant='secondary'
        />
      )}
      <Button iconName='x' aria-label='close' variant='secondary' onClick={onClosePanel} css={buttonSvgStyles} />
    </header>
  );
};
