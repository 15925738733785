import { FC, PropsWithChildren, ReactNode, useEffect } from 'react';
import { css, Interpolation, Theme } from '@emotion/react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { useTranslation } from '@frontend/i18n';
import { ContentLoader, useModalControl } from '@frontend/design-system';
import { useFeaturePromotionModal } from '../hooks/useFeaturePromotionModal';
import { useFeaturePromotionPage } from '../hooks/useFeaturePromotionPage';
import { useFeatureSubscriptionConfig } from '../hooks/useFeatureSubscriptionConfig';
import { useFeatureUpgradeDetailsModal } from '../hooks/useFeatureUpgradeDetailsModal';
import { FeatureSubscriptionContext } from '../providers/FeatureSubscriptionProvider';
import { FeatureDemoModal } from './feature-demo-modal/FeatureDemoModal';
import { FeatureMultiLocationUpgradeAlert } from './FeatureMultiLocationUpgradeAlert';

interface FeatureSubscriptionGuardProps {
  feature: Feature;
  locationIds?: string[];
  demoModalContent?: ReactNode;
  demoModalBodyStyles?: Interpolation<Theme>;
  hideMultiUpgradeAlert?: boolean;
  onDemoModalOpen?: () => void;
  onDemoModalClose?: () => void;
}

export const FeatureSubscriptionGuard: FC<PropsWithChildren<FeatureSubscriptionGuardProps>> = ({
  children,
  demoModalContent,
  demoModalBodyStyles,
  feature,
  hideMultiUpgradeAlert = false,
  locationIds,
  onDemoModalOpen,
  onDemoModalClose,
}) => {
  const featureSubscriptionConfig = useFeatureSubscriptionConfig({ feature, locationIds });
  const { isFeatureActiveInAllLocations, isFeatureHiddenInAllLocations, isLoading } = featureSubscriptionConfig;
  const { featurePromotionModalControls, FeaturePromotionModal } = useFeaturePromotionModal();
  const { FeatureUpgradeDetailsModal, featureUpgradeDetailsModalControls } = useFeatureUpgradeDetailsModal({ feature });
  const { FeaturePromotionPage } = useFeaturePromotionPage(feature);
  const demoModalControls = useModalControl();

  const { t } = useTranslation('featurePromotion', { keyPrefix: 'feature-subscription-guard' });

  useEffect(() => {
    // Close all modals if unmounted
    return () => {
      featurePromotionModalControls.closeModal();
      featureUpgradeDetailsModalControls.closeModal();
      demoModalControls.closeModal();
    };
  }, []);

  if (isFeatureActiveInAllLocations && !isLoading) {
    return <>{children}</>;
  }

  return (
    <FeatureSubscriptionContext.Provider value={featureSubscriptionConfig}>
      <ContentLoader show={isLoading} message={t('Checking Subscription')} />
      {isFeatureHiddenInAllLocations && !!FeaturePromotionPage && (
        <FeaturePromotionPage
          mode='fullPage'
          handleOpenUpgradeModal={featureUpgradeDetailsModalControls.openModal}
          isDemoAvailable={!!demoModalContent}
          handleViewDemo={() => {
            demoModalControls.openModal();
            onDemoModalOpen?.();
          }}
        />
      )}
      {!isFeatureHiddenInAllLocations && (
        <div css={multiLocationAlertLayoutStyles}>
          {!hideMultiUpgradeAlert && (
            <FeatureMultiLocationUpgradeAlert
              feature={feature}
              onUpgradeActionClick={
                !!FeatureUpgradeDetailsModal
                  ? featureUpgradeDetailsModalControls.openModal
                  : featurePromotionModalControls.openModal
              }
            />
          )}
          {children}
        </div>
      )}
      <FeaturePromotionModal
        feature={feature}
        modalControls={featurePromotionModalControls}
        handleOpenUpgradeModal={featureUpgradeDetailsModalControls.openModal}
      />
      {!!FeatureUpgradeDetailsModal && (
        <FeatureUpgradeDetailsModal
          modalControls={featureUpgradeDetailsModalControls}
          onActionSuccess={() => {
            featureUpgradeDetailsModalControls.closeModal();
          }}
        />
      )}
      {!!demoModalContent && (
        <FeatureDemoModal
          onUpgradeAction={featureUpgradeDetailsModalControls.openModal}
          {...demoModalControls.modalProps}
          onClose={() => {
            onDemoModalClose?.();
            demoModalControls.closeModal();
          }}
          contentStyles={demoModalBodyStyles}
        >
          {demoModalContent}
        </FeatureDemoModal>
      )}
    </FeatureSubscriptionContext.Provider>
  );
};

const multiLocationAlertLayoutStyles = css({
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  height: '100%',
});
