import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  padding: ${theme.spacing(0, 2)};

  &:not(:last-of-type) {
    margin-bottom: ${theme.spacing(2)};
  }
`;

export const headerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${theme.spacing(2)};
`;

export const errorTextStyle = css`
  margin-top: ${theme.spacing(0.5)};
`;

interface DropdownFieldStyleConfig {
  isInvalid: boolean;
}

export function getDropdownFieldStyle({ isInvalid }: DropdownFieldStyleConfig) {
  if (isInvalid) {
    return css`
      border-color: ${theme.colors.critical50};
    `;
  }

  return null;
}
