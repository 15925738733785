import { FC } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { useTranslation } from '@frontend/i18n';
import { FeatureSelfSubscribeModalProps } from '../../../types';
import { SelfUpgradeModal } from '../../feature-upgrade-action-modal/self-upgrade-modal/SelfUpgradeModal';
import { PRACTICE_ANALYTICS_ONBOARDING_URL, PRACTICE_ANALYTICS_PRICE } from './constants';

export const PracticeAnalyticsSelfSubscribeModal: FC<FeatureSelfSubscribeModalProps> = ({
  selfSubscribeEligibilityData,
  onUpgradeSuccess,
  ...modalProps
}) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'practice-analytics-details-modal' });
  const featureName = t('Practice Analytics');

  return (
    <SelfUpgradeModal
      featureEnum={Feature.PRACTICE_ANALYTICS}
      featureName={featureName}
      price={PRACTICE_ANALYTICS_PRICE}
      subscriptionTerm='monthly'
      onUpgradeSuccess={onUpgradeSuccess}
      onboardingCallLink={PRACTICE_ANALYTICS_ONBOARDING_URL}
      selfSubscribeEligibilityData={selfSubscribeEligibilityData}
      {...modalProps}
    />
  );
};
