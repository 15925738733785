import { Link, LinkProps, Search } from '@tanstack/react-location';
import { useInterRouter } from './inter-router';
import { SettingsRoutes } from './settings-routes';
import type { PathSegments, RouteParams } from './types';
import { useConstructSettingsLabel } from './use-construct-settings-label';

type BaseNavigateArgs<T extends SettingsRoutes> = Omit<LinkProps, 'to' | 'params'> & {
  to: T;
  children: React.ReactNode;
  search?: Search<unknown>;
  replace?: boolean;
  trackingId?: string;
};

type NavigateArgs<T extends SettingsRoutes> = PathSegments<T> extends never
  ? BaseNavigateArgs<T>
  : BaseNavigateArgs<T> & { params?: RouteParams<T> };

export const SettingsLink = <T extends SettingsRoutes>({
  children,
  onClick,
  to,
  replace,
  trackingId,
  ...rest
}: NavigateArgs<T>) => {
  const { setSettingsRouterPathHistory, setSettingsRouterPath, settingsRouterPath } = useInterRouter([
    'setSettingsRouterPathHistory',
    'setSettingsRouterPath',
    'settingsRouterPath',
  ]);
  const constructLabel = useConstructSettingsLabel();

  let newPath: string = to;

  if ('params' in rest && rest.params) {
    for (const [key, value] of Object.entries<string>(rest.params as RouteParams<T>)) {
      newPath = newPath.replace(`:${key}`, value);
    }
  }

  const params = 'params' in rest ? (rest.params as RouteParams<T>) : {};

  return (
    <Link
      onClick={(e) => {
        if (rest.disabled) return;
        if (settingsRouterPath.path === newPath) return;
        if (newPath) {
          setSettingsRouterPathHistory({
            path: newPath,
            raw: to,
            label: constructLabel(to, params),
            search: rest.search,
            replace,
          });
          setSettingsRouterPath({ path: newPath, raw: to, search: rest.search, replace });
        }
        onClick?.(e);
      }}
      to={rest.disabled ? undefined : newPath}
      replace={replace}
      data-trackingid={trackingId}
      {...rest}
    >
      {children}
    </Link>
  );
};
