import { css } from '@emotion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { BackIcon, Heading, IconButton } from '@frontend/design-system';

type Props = {
  title: string;
  showBackBtn?: boolean;
  onBackClick?: () => void;
};

export const PageTitle = ({ title, showBackBtn = false, onBackClick }: Props) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {showBackBtn && (
        <IconButton
          label='Back'
          css={css`
            margin-right: ${theme.spacing(1)};
          `}
        >
          <BackIcon
            css={css`
              cursor: pointer;
            `}
            onClick={onBackClick}
          />
        </IconButton>
      )}
      <Heading
        level={1}
        css={{
          minWidth: 0,
          margin: 0,
          [`@container (max-width:  ${breakpoints.small.max}px)`]: {
            fontSize: theme.fontSize(24),
          },
        }}
      >
        {title}
      </Heading>
    </div>
  );
};
