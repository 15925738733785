import { useTranslation } from '@frontend/i18n';
import { useInvoicePerson } from '@frontend/payments-collection-flow';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { useHasTTPAuth } from '@frontend/person-invoice-create';
import { Heading, Text, TextLink, WarningIndicatorSmall, useTooltip } from '@frontend/design-system';
import { useViewAttachment } from '../../hooks';
import { styles, attachmentStyles } from './styles';

export const AttachmentSection = () => {
  const { t } = useTranslation('payments');
  const { invoice } = useSelectedInvoice();
  const person = useInvoicePerson(invoice);
  const { tooltipProps, triggerProps, Tooltip } = useTooltip({ placement: 'top', trigger: 'click' });
  const { viewAttachment } = useViewAttachment();
  const { enabled: ttpAuthEnabled } = useHasTTPAuth({ locationId: invoice?.locationId });

  const attachmentName = invoice?.attachments?.[0].filename ?? '';
  const attachmentLink = invoice?.links?.attachment;

  return (
    <section css={styles.card}>
      <div css={styles.marginBottom}>
        <div css={attachmentStyles.attachmentHeading}>
          <Heading level={3}>{t('Attachments')}</Heading>
          {!person?.Birthdate && ttpAuthEnabled && invoice?.attachments && (
            <div css={attachmentStyles.warningIndicator} {...triggerProps}>
              <WarningIndicatorSmall />
              <Tooltip {...tooltipProps} color='white'>
                <Text size='medium' color='white'>
                  {t(
                    'Attachment will be hidden from customer. Customer birthday is missing which is required for authentication.'
                  )}
                </Text>
              </Tooltip>
            </div>
          )}
        </div>
        <Text size='small' color='light'>
          {t('Visible to the customer')}
        </Text>
      </div>
      {invoice?.hasAttachment && attachmentLink ? (
        <div css={attachmentStyles.attachmentNameRow}>
          <TextLink css={attachmentStyles.uploadAttachmentLink} onClick={() => viewAttachment(attachmentLink)}>
            {attachmentName}
          </TextLink>
        </div>
      ) : (
        <Text>{t('No attachments to display')}</Text>
      )}
    </section>
  );
};
