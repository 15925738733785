import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const modalStyles = css`
  min-width: min(90%, 650px);
`;

export const modalBodyStyles = css`
  margin-top: ${theme.spacing(1)};
`;

export const descriptionStyles = css`
  margin-bottom: ${theme.spacing(2)};
`;
