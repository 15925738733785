import { FC } from 'react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { FeatureSelfSubscribeModalProps } from '../../../types';
import { SelfUpgradeModal } from '../../feature-upgrade-action-modal/self-upgrade-modal/SelfUpgradeModal';
import { ONLINE_SCHEDULING_FEATURE_NAME, ONLINE_SCHEDULING_ONBOARDING_URL, ONLINE_SCHEDULING_PRICE } from './constants';

export const OnlineSchedulingSelfSubscribeModal: FC<FeatureSelfSubscribeModalProps> = ({
  selfSubscribeEligibilityData,
  onUpgradeSuccess,
  ...modalProps
}) => {
  return (
    <SelfUpgradeModal
      featureEnum={Feature.ONLINE_SCHEDULING}
      featureName={ONLINE_SCHEDULING_FEATURE_NAME}
      price={ONLINE_SCHEDULING_PRICE}
      subscriptionTerm='monthly'
      onUpgradeSuccess={onUpgradeSuccess}
      onboardingCallLink={ONLINE_SCHEDULING_ONBOARDING_URL}
      selfSubscribeEligibilityData={selfSubscribeEligibilityData}
      {...modalProps}
    />
  );
};
