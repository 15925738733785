import { TagsV2 } from '@frontend/api-tag';
import { useWebsocketCallQueueEventSubscription, useWebsocketDeskPhoneEventSubscription } from '@frontend/calls-v2';
import { useScheduledSmsWSSubscription, useSmsMessageWSSubscription } from '@frontend/inbox';
import { useSoftphoneInnerManager } from '@frontend/weave-softphone-manager';
import { useSMSPreferenceEventSubscription, useSMSTagNotificationSubscription } from './messaging';
import { useWebsocketFaxEventSubscription } from './use-fax-event-subscription';
import { useWebsocketFollowupEventSubscription } from './use-followup-event-subscription';
import { useWebsocketFormEventSubscription } from './use-form-event-subscription';
import { useWebsocketPaymentsEventSubscription } from './use-payments-event-subscription';
import { useWebsocketReviewEventSubscription } from './use-review-event-subscription';
import { useWebsocketScheduleRequestEventSubscription } from './use-schedule-request-event-subscription';

export const useAllWebsocketEventSubscriptions = async () => {
  const hasSoftphone = useSoftphoneInnerManager((ctx) => ctx?.isConnected);
  useWebsocketFaxEventSubscription();
  useWebsocketFollowupEventSubscription();
  useWebsocketFormEventSubscription();
  useWebsocketDeskPhoneEventSubscription({ enabled: !hasSoftphone });
  useWebsocketCallQueueEventSubscription();
  useWebsocketReviewEventSubscription();
  useSmsMessageWSSubscription();
  useSMSPreferenceEventSubscription();
  useScheduledSmsWSSubscription();
  useWebsocketScheduleRequestEventSubscription();
  useWebsocketPaymentsEventSubscription();
  TagsV2.useTagsWSSubscriptions();
  useSMSTagNotificationSubscription();
};
