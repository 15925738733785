import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { kebabCase } from 'lodash-es';
import { SalesDevApi } from '@frontend/api-sales-dev';
import { SubscriptionsQueries, SubscriptionsTypes } from '@frontend/api-subscription';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { useLocalizedQuery, useLocationDataShallowStore } from '@frontend/location-helpers';
import { IconButton, Modal, SpinningLoader, XIcon, useModalControl } from '@frontend/design-system';
import { SubscribeFailureModal } from './SubscribeFailureModal';
import { SubscribeSuccessModal } from './SubscribeSuccessModal';
import { subscribeTrackingIds as ids } from './trackingIds';

interface SubscribeModalControllerProps {
  onModalCloseClick: () => void;
  onSuccessCloseClick: () => void;
  onScheduleOnboardingCall?: () => void;
  feature: SubscriptionsTypes.Feature;
  productServiceName: string;
  isLoading?: boolean;
  additionalAddons?: SubscriptionsQueries.SchemaSubscribeRequest['additionalLines'];
}

const MODAL_MAX_WIDTH = 550;

const enum MODAL_STATE {
  Initial,
  Success,
  Failure,
}

export const SubscribeModalController = ({
  onModalCloseClick,
  onSuccessCloseClick,
  feature,
  productServiceName,
  isLoading: parentIsLoading,
  additionalAddons,
  onScheduleOnboardingCall,
}: SubscribeModalControllerProps) => {
  const { t } = useTranslation('subscribe', { keyPrefix: 'modalContainer' });
  const { locationData } = useLocationDataShallowStore('locationData');
  const { mutateAsync: subscribe, isLoading: subscribeIsLoading } = SubscriptionsQueries.useSubscribe();
  const [modalState, setModalState] = useState<MODAL_STATE>(MODAL_STATE.Initial);
  const [hasOverdueBalance, setHasOverdueBalance] = useState(false);
  const subscribeTermsModalProps = useModalControl();
  const user = getUser();

  const featureTrackingIdPrefix = `${kebabCase(feature)}${ids.base}`;

  const {
    data: invoiceBalanceData,
    isLoading: invoiceBalanceIsLoading,
    isError: isInvoiceBalanceError,
  } = useLocalizedQuery({
    queryKey: 'invoiceBalance',
    queryFn: () => {
      return SalesDevApi.getInvoiceBalance();
    },
    retry: 1,
    staleTime: 0,
    cacheTime: 0,
  });

  const isLoading = subscribeIsLoading || invoiceBalanceIsLoading || parentIsLoading;

  const handleModalCloseClick = () => {
    onModalCloseClick?.();
  };

  const handleOutsideClick = () => {
    // Do not allow outside click to close modal when awaiting response from backend.
    // Since we are not showing alerts on the top right corner for success or failure but
    // are instead showing success/failure modals, we don't want to allow the user to
    // accidentally close the modal before they see the success/failure modal.
    if (isLoading) return;
    handleModalCloseClick();
  };

  const handleSubscribe = () => {
    subscribe({
      coreFeatureEnum: feature,
      slug: locationData?.Slug ?? '',
      additionalLines: additionalAddons,
      userDetails: {
        firstName: user?.firstName ?? '',
        lastName: user?.lastName ?? '',
        email: user?.username ?? '',
        phone: user?.mobileNumber ?? '',
      },
    })
      .then(() => {
        setModalState(MODAL_STATE.Success);
      })
      .catch((err) => {
        console.error(err);
        setModalState(MODAL_STATE.Failure);
      });
  };

  useEffect(() => {
    const balance = Number(invoiceBalanceData?.current ?? 0);
    const hasBalance = balance > 0;
    if (hasBalance || isInvoiceBalanceError) {
      setModalState(MODAL_STATE.Failure);

      if (hasBalance) {
        setHasOverdueBalance(true);
      }
      return;
    }

    if (!invoiceBalanceIsLoading) {
      handleSubscribe();
    }
  }, [isInvoiceBalanceError, invoiceBalanceData, invoiceBalanceIsLoading]);

  const getCloseIconTrackingId = () => {
    if (modalState === MODAL_STATE.Success) {
      return `${featureTrackingIdPrefix}${ids.success}${ids.closeIconBtn}`;
    }

    if (modalState === MODAL_STATE.Failure) {
      if (hasOverdueBalance) {
        return `${featureTrackingIdPrefix}${ids.invoiceBalanceFailure}${ids.closeIconBtn}`;
      }
      return `${featureTrackingIdPrefix}${ids.failure}${ids.closeIconBtn}`;
    }

    return '';
  };

  return (
    <Modal {...subscribeTermsModalProps.modalProps} maxWidth={MODAL_MAX_WIDTH} show={true} onClose={handleOutsideClick}>
      <Modal.Header>
        <IconButton
          trackingId={getCloseIconTrackingId()}
          label={t('Modal Close Icon')}
          css={cancelIconStyles}
          onClick={handleModalCloseClick}
        >
          <XIcon />
        </IconButton>
      </Modal.Header>

      {isLoading ? (
        <div>
          <SpinningLoader
            css={{ height: 400, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
          />
        </div>
      ) : (
        <>
          {modalState === MODAL_STATE.Failure && (
            <SubscribeFailureModal
              onOkayButtonClick={handleModalCloseClick}
              hasOverdueBalance={hasOverdueBalance}
              productServiceName={productServiceName}
              trackingIdPrefix={featureTrackingIdPrefix}
            />
          )}

          {modalState === MODAL_STATE.Success && (
            <SubscribeSuccessModal
              feature={feature}
              productServiceName={productServiceName}
              onOkayButtonClick={onSuccessCloseClick}
              trackingIdPrefix={featureTrackingIdPrefix}
              onScheduleOnboardingCall={onScheduleOnboardingCall}
            />
          )}
        </>
      )}
    </Modal>
  );
};

const cancelIconStyles = css({
  position: 'absolute',
  top: 10,
  right: 10,
  zIndex: 10,
});
