import { css } from '@emotion/react';
import { ListSchedulesResponse } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/schedule.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Alert } from '@frontend/design-system';
import { useGetOfficeHoursV2OrV3Data, useGetUpdateOfficeHoursMethod } from '../../hooks';
import { OfficeHoursSettings } from './OfficeHoursSettings';

type OfficeHoursSettingsProps = {
  locationId: string;
};

export const OfficeHoursGlobalSettings = ({ locationId }: OfficeHoursSettingsProps) => {
  const { t } = useTranslation('schedule');

  const {
    isError: officeHoursFetchError,
    isLoading,
    officeHoursData,
    refetch: refetchOfficeHoursData,
    shouldRenderV3,
    officeHoursDataV3,
    timezone,
  } = useGetOfficeHoursV2OrV3Data({ locationId });

  const { isLoading: isSavingOfficeHours, onSave } = useGetUpdateOfficeHoursMethod({
    locationId,
    officeHoursDataV3: officeHoursDataV3 || ({} as ListSchedulesResponse),
    refetchOfficeHours: refetchOfficeHoursData,
    shouldRenderV3,
    timezone,
  });

  return (
    <div css={officeHoursContainerStyles}>
      {officeHoursFetchError && (
        <Alert type='error'>{t('Unable to fetch Office Hours. Please try again later.')}</Alert>
      )}
      <OfficeHoursSettings
        locationId={locationId}
        isLoading={isLoading || isSavingOfficeHours}
        officeHoursData={officeHoursData}
        refetchOfficeHours={refetchOfficeHoursData}
        onSave={onSave}
      />
    </div>
  );
};

const officeHoursContainerStyles = css({
  boxShadow: theme.shadows.light,
  backgroundColor: theme.colors.white,
});
