import { useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import dayjs from 'dayjs';
import { ScheduleTypes } from '@frontend/api-schedule';
import { ServiceProvidersApi, ServiceProvidersConstants } from '@frontend/api-service-providers';
import { EmptyStates } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  Accordion,
  DatePickerField,
  Heading,
  ListRow,
  NakedButton,
  NakedUl,
  SpinningLoader,
  Text,
  useFormField,
} from '@frontend/design-system';
import { queryKeys } from '../../../../../../query-keys';
import { useScheduleActionsContainerMethod } from '../../../../../context/ScheduleActionsContainerContext';
import {
  useGetCalendarEventsV3Data,
  useGetSchedulerV3FeatureFlagDetails,
  useGetCombinedProvidersList,
  useGetV3CalendarStartAndEndDateTime,
  useGetProvidersListForMultipleLocationIds,
  useAppointmentsInfoShallowStore,
  useGetPractitionersV3Data,
} from '../../../../../hooks';
import { useScheduleActionsAppointmentListInfoShallowStore } from '../../../../../stores/use-schedule-actions-appointment-list-store';
import {
  convertV3CalendarEventToAppointmentDetails,
  convertV3PractitionerToProvider,
} from '../../../../CalendarV3/helpers';
import { PatientListRow } from '../../../components/PatientListRow';
import { FiltersContainer } from './Filters/FilterContainer';
import { ScheduleMassMessageTrackingIds } from './trackingIds';

export const ScheduleMassMessages = () => {
  const todayDate = dayjs().format('MM/DD/YYYY').toString();
  const { selectedLocationIds, selectedParentsIds } = useAppScopeStore();

  const { isScheduleV3FlagEnabled } = useGetSchedulerV3FeatureFlagDetails();

  const { setAppointmentList, appointmentList, setDefaultAppointmentList, statusFilter, setDefaultProviderList } =
    useScheduleActionsAppointmentListInfoShallowStore(
      'setAppointmentList',
      'appointmentList',
      'setDefaultAppointmentList',
      'statusFilter',
      'setDefaultProviderList'
    );

  const { selectedDate: scheduleActionsDate, setSelectedDate } = useScheduleActionsContainerMethod();

  const { selectedDate: calendarViewDate } = useAppointmentsInfoShallowStore('selectedDate');

  const selectedDate = scheduleActionsDate || calendarViewDate;
  const calendarProps = useFormField({
    type: 'datePicker',
    value: selectedDate || (todayDate as string),
  });

  const isValidDate = calendarProps?.value && !calendarProps.error;

  useEffect(() => {
    if (isValidDate) {
      const formattedDate = dayjs(String(calendarProps.value)).format('MM/DD/YYYY').toString();
      setSelectedDate(formattedDate);
    }
  }, [calendarProps?.value, isValidDate]);

  const [startDate, endDate] = useMemo(() => {
    const startDateInUTC = dayjs(String(calendarProps.value)).utc().toISOString();
    const endDateInUTC = dayjs(String(calendarProps.value)).utc().add(1, 'day').toISOString();
    return [startDateInUTC, endDateInUTC];
  }, [calendarProps?.value]);

  const { data: appointmentsData, isLoading: isAppointmentsDataLoading } = useLocalizedQuery({
    queryKey: queryKeys.appointments(selectedLocationIds, startDate, endDate),
    queryFn: () =>
      ServiceProvidersApi.listAppointments({
        between: { start: startDate, end: endDate },
        equals: { locationIds: selectedLocationIds },
      }),
    retry: 1,
    cacheTime: 10 * ServiceProvidersConstants.ONE_MINUTE_IN_MILLISECONDS, // 10 mins
    staleTime: 10 * ServiceProvidersConstants.ONE_MINUTE_IN_MILLISECONDS, // 10 mins
    enabled: !!selectedLocationIds && !!startDate && !!endDate && !isScheduleV3FlagEnabled,
  });

  const { startDateTime: calendarEventsStartDate, endDateTime: calendarEventsEndDate } =
    useGetV3CalendarStartAndEndDateTime(dayjs(String(calendarProps.value)).toString());

  const { data: calendarEventsData, isLoading: isLoadingCalendarEvents } = useGetCalendarEventsV3Data({
    parentLocationId: selectedParentsIds[0],
    isMultiLocation: !!selectedLocationIds.length,
    calendarEventsParams: {
      startDateTime: calendarEventsStartDate,
      endDateTime: calendarEventsEndDate,
      page: 1,
      limit: 50,
    },
    selectedLocationIds,
    selectedLocationId: selectedLocationIds[0],
    enabled: isScheduleV3FlagEnabled,
  });

  // Convert calendarEvents to appointments format to display in appointments list and store in ScheduleActionsAppointmentListInfo store
  useEffect(() => {
    const isScheduleV3 = !!calendarEventsData?.events?.length && isScheduleV3FlagEnabled;
    const appointmentsList = isScheduleV3
      ? convertV3CalendarEventToAppointmentDetails(calendarEventsData?.events)
      : appointmentsData?.appointments;

    !statusFilter && setAppointmentList(appointmentsList ?? []);
    setDefaultAppointmentList(appointmentsList ?? []);
  }, [appointmentsData, calendarEventsData?.events, isScheduleV3FlagEnabled]);

  // get and set the provider list from the store
  const { data: providers } = useGetProvidersListForMultipleLocationIds(
    { locationIds: selectedLocationIds },
    !isScheduleV3FlagEnabled
  );

  const { data: practitionerData } = useGetPractitionersV3Data({
    isMultiLocation: !!selectedParentsIds.length,
    selectedLocationId: selectedLocationIds[0],
    selectedLocationIds: selectedLocationIds,
    parentLocationId: selectedParentsIds[0] || '',
    pageConfig: {
      page: 1,
      limit: 50,
      searchValue: '',
    },
  });

  // get the full provider list, including the providers that have appointments but no official provider created. This is to ensure that all providers are displayed in the provider filter, and all appointments can be filtered by their assigned provider.
  const fullProviderList = useGetCombinedProvidersList(
    appointmentsData ?? {},
    (providers ?? []) as ScheduleTypes.Provider[]
  );

  // set the default provider list in the store for v2
  useEffect(() => {
    if (!isScheduleV3FlagEnabled) {
      setDefaultProviderList(fullProviderList);
    }
  }, [fullProviderList, isScheduleV3FlagEnabled]);

  // set the default provider list in the store for v3 by converting v3 practitioners to providers
  useEffect(() => {
    if (isScheduleV3FlagEnabled) {
      const providersList = convertV3PractitionerToProvider(practitionerData?.practitioners || []);
      setDefaultProviderList(providersList);
    }
  }, [practitionerData?.practitioners, isScheduleV3FlagEnabled]);

  if (isAppointmentsDataLoading || isLoadingCalendarEvents) {
    return <SpinningLoader />;
  }

  if (!appointmentList) return null;

  return (
    <>
      <div css={{ display: 'flex', gap: 8, marginBottom: theme.spacing(2), alignItems: 'flex-start' }}>
        <DatePickerField
          data-trackingid={ScheduleMassMessageTrackingIds.datePickerField}
          {...calendarProps}
          css={{ width: '190px', backgroundColor: theme.colors.white }}
          name=''
          label={''}
        />

        <FiltersContainer />
      </div>
      {selectedLocationIds.length === 1 ? (
        <SingleLocationView locationId={selectedLocationIds[0]} appointments={appointmentList || []} />
      ) : (
        <MultiLocationView appointments={appointmentList || []} />
      )}
    </>
  );
};

type SingleLocationViewProps = {
  appointments: Appointment[] | undefined;
  locationId: string;
};

const SingleLocationView = ({ appointments, locationId }: SingleLocationViewProps) => {
  const { t } = useTranslation('scheduleCalendarActions');
  const { setHideTabs, setSelectedLocationId } = useScheduleActionsContainerMethod();

  return (
    <>
      <ListRow
        css={css`
          padding: ${theme.spacing(2)};
          background-color: white;
        `}
      >
        <ListRow.Content>
          <Heading level={3}>{t('{{count}} Patients', { count: appointments?.length })}</Heading>
        </ListRow.Content>
        <ListRow.Trail css={contactPatientsStyles}>
          <NakedButton
            id='contact-patients-btn'
            trackingId={ScheduleMassMessageTrackingIds.contactPatientsBtn}
            disabled={!appointments?.length}
            onClick={() => {
              setSelectedLocationId(locationId);
              setHideTabs(true);
            }}
          >
            {t('Contact Patients')}
          </NakedButton>
        </ListRow.Trail>
      </ListRow>
      <NakedUl
        css={css`
          overflow: auto;
          max-height: 450px;
          background-color: white;
        `}
      >
        {!!appointments?.length ? (
          <>
            {appointments.map((appt) => {
              return (
                <PatientListRow
                  appointments={appointments}
                  key={appt.id}
                  appt={appt}
                  shouldShowAppointmentModal
                  locationId={locationId}
                />
              );
            })}
          </>
        ) : (
          <EmptyStates emptyStateConfig={{ type: 'schedule' }} />
        )}
      </NakedUl>
    </>
  );
};

type MultiLocationViewProps = {
  appointments: Appointment[] | undefined;
};

const MultiLocationView = ({ appointments }: MultiLocationViewProps) => {
  const { getLocationName, selectedLocationIds } = useAppScopeStore();

  const multiLocationGrouped = useMemo(() => {
    return appointments?.reduce((acc, obj) => {
      if (!obj.locationId) return acc;
      if (!acc[obj.locationId]) {
        acc[obj.locationId] = [];
      }
      acc[obj.locationId].push(obj);
      return acc;
    }, {} as { [key: string]: Appointment[] });
  }, [appointments]);

  const numOfAppointments = (locationId: string) => Object.keys(multiLocationGrouped?.[locationId] ?? '').length;
  return (
    <>
      <Accordion variant='location' size='large' showBoxShadow chevronSize={16}>
        {selectedLocationIds?.map((locationId: string) => (
          <Accordion.Item key={locationId} value={locationId}>
            <Accordion.Header disabled={!numOfAppointments(locationId)} css={{ height: '52px' }}>
              <Accordion.Header.Location title={getLocationName(locationId) ?? 'Default'} />
              <Text color={!numOfAppointments(locationId) ? 'disabled' : 'default'} css={{ marginLeft: 'auto' }}>
                {numOfAppointments(locationId)}
              </Text>
            </Accordion.Header>
            <Accordion.Body css={{ padding: 0 }}>
              <SingleLocationView locationId={locationId} appointments={multiLocationGrouped?.[locationId]} />
            </Accordion.Body>
          </Accordion.Item>
        ))}
      </Accordion>
    </>
  );
};

const contactPatientsStyles = css({
  '#contact-patients-btn:hover': {
    backgroundColor: `${theme.colors.primary5} !important`,
  },
  '#contact-patients-btn': {
    color: theme.colors.primary50,
    fontWeight: theme.font.weight.bold,
    padding: theme.spacing(1, 0.5),
    borderRadius: theme.borderRadius.small,
  },
  '#contact-patients-btn:disabled': {
    color: theme.colors.neutral20,
    cursor: 'not-allowed',
  },
  '#contact-patients-btn:active': {
    color: theme.colors.primary70,
    backgroundColor: `${theme.colors.primary5} !important`,
  },
});
