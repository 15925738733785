import { InboxFiltersButton, InboxStatusFiltersText, InboxFilter } from '@frontend/inbox-filters';
import { theme } from '@frontend/theme';

export const MessagesFilters = () => {
  const hideFilters: InboxFilter[] = ['statuses'];

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'end',
        flexGrow: 1,
        gap: theme.spacing(1),
      }}
    >
      <InboxStatusFiltersText hideFilters={hideFilters} />
      <InboxFiltersButton hideFilters={hideFilters} />
    </div>
  );
};
