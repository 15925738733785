import { useEffect } from 'react';
import { css } from '@emotion/react';
import { ScheduleType } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/schedule.pb';
import { isEqual } from 'lodash-es';
import { SchedulerV3Queries } from '@frontend/api-scheduler-v3';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useScheduleAvailabilityHours } from '@frontend/schedule-availability-hours';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import {
  ButtonBar,
  Modal,
  PrimaryButton,
  SecondaryButton,
  SpinningLoader,
  Stepper,
  Text,
  styles,
  useAlert,
} from '@frontend/design-system';
import { useAddPractitionerV3Context } from '../../../context/AddPractitionerV3Context';
import { useManagePractitionerHelperShallowStore } from '../../../stores/use-manage-practitioners-helper-store';
import { convertScheduleHoursObjectToRRuleObject, getSystemTzid } from '../../../utils';
import { OfficeTimezone } from '../../../views/ManagePractitionersV3/PractitionerDetails/OfficeTimezone';

type AddPractitionerStepperSecondStepProps = {
  isCreatePractitionerError: boolean;
};

export const AddPractitionerStepperSecondStep = ({
  isCreatePractitionerError,
}: AddPractitionerStepperSecondStepProps) => {
  const alert = useAlert();
  const { t } = useTranslation('schedule');

  const { onClose, locationId, practitionerDetails } = useAddPractitionerV3Context();

  const { accessibleLocationData } = useAppScopeStore();

  const locationData = accessibleLocationData?.[locationId];

  const { refetchPractitioners } = useManagePractitionerHelperShallowStore('refetchPractitioners');

  const { updatedScheduleAvailability, ScheduleAvailabilityHours, isError } = useScheduleAvailabilityHours({
    initialScheduleAvailability: {},
    closedOfficeText: t('Out of Office'),
    timeRangeJoiningText: t('to'),
  });

  const { mutateAsync: createScheduleEntries, isLoading: isLoadingCreateScheduleEntries } =
    SchedulerV3Queries.useCreateScheduleEntries(locationId);

  const onCreateScheduleEntries = () => {
    const scheduleEntries = convertScheduleHoursObjectToRRuleObject(
      updatedScheduleAvailability,
      locationData?.timezone
    );
    createScheduleEntries({
      id: practitionerDetails?.id,
      locationId,
      type: ScheduleType.PRACTITIONER,
      recurrenceRules: {
        workingHours: scheduleEntries.workingHours,
        breaks: scheduleEntries.breaks,
      },
    })
      .then(() => {
        alert.success(t('Provider schedule created successfully'));
        refetchPractitioners?.();
        onClose();
      })
      .catch(() => {
        alert.error(t('Failed to create Provider schedule'));
      });
  };

  const onCancel = () => {
    onClose();
    refetchPractitioners?.();
  };

  useEffect(() => {
    if (isCreatePractitionerError) {
      onClose();
    }
  }, [isCreatePractitionerError]);

  const shouldDisableSave = isError || isEqual(updatedScheduleAvailability, {});

  if (!practitionerDetails?.id || isLoadingCreateScheduleEntries)
    return (
      <div css={[styles.flexCenter, { height: '80vh' }]}>
        <SpinningLoader />
      </div>
    );
  return (
    <>
      <Modal.Header onClose={onClose}>{t('Provider Details')}</Modal.Header>
      <Modal.Body>
        <Text color='light'>
          {t("Set {{name}}'s regular weekly office hours. You can change this schedule at any time in settings.", {
            name: practitionerDetails?.firstName || '',
          })}
        </Text>
        <Stepper.Content css={{ marginTop: theme.spacing(3) }}>
          <OfficeTimezone timezone={locationData?.timezone || getSystemTzid()} />
          <div css={{ marginTop: theme.spacing(2) }}>{ScheduleAvailabilityHours}</div>
        </Stepper.Content>

        <Stepper.ButtonBar css={stepperButtonBarStyle}>
          <Stepper.PreviousButton>
            <Icon name='back' />
            <Text
              css={css`
                margin-left: ${theme.spacing(1)};
              `}
              color='primary'
              weight='bold'
            >
              {t('Back')}
            </Text>
          </Stepper.PreviousButton>
          <ButtonBar>
            <SecondaryButton css={buttonStyle} onClick={onCancel}>
              {t('Cancel')}
            </SecondaryButton>
            <PrimaryButton css={buttonStyle} onClick={onCreateScheduleEntries} disabled={shouldDisableSave}>
              {t('Save')}
            </PrimaryButton>
          </ButtonBar>
        </Stepper.ButtonBar>
      </Modal.Body>
    </>
  );
};

export const stepperButtonBarStyle = css`
  display: flex;
  margin-top: ${theme.spacing(3)};
  justify-content: space-between;
  align-items: center;

  button {
    height: ${theme.spacing(5)};
  }

  & > button:first-of-type {
    margin-left: 0;
    width: fit-content;
    border: 0;
    color: ${theme.colors.primary50};
  }
`;

const buttonStyle = css({
  width: 'fit-content',
});
