import { memo, useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { DropdownField, SpinningLoader, Text, TextButton, VolumeIcon, useFormField } from '@frontend/design-system';
import { useSoftphoneAudio } from '../../providers/softphone-audio-provider';
import { useSoftphoneMediaDevices } from '../../providers/softphone-media-devices-provider';

export const AudioSettings = () => {
  const { t } = useTranslation('softphone');
  const inputSources = useSoftphoneMediaDevices((ctx) => ctx?.inputSources);
  const currInputSource = useSoftphoneMediaDevices((ctx) => ctx?.currInputSource);
  const setCurrInputSource = useSoftphoneMediaDevices((ctx) => ctx?.setCurrInputSource);

  const outputSources = useSoftphoneMediaDevices((ctx) => ctx?.outputSources);
  const currOutputSource = useSoftphoneMediaDevices((ctx) => ctx?.currOutputSource);
  const setCurrOutputSource = useSoftphoneMediaDevices((ctx) => ctx?.setCurrOutputSource);

  const refreshSources = useSoftphoneMediaDevices((ctx) => ctx?.refreshSources);
  const isMediaSourcesLoading = useSoftphoneMediaDevices((ctx) => ctx?.isMediaSourcesLoading);
  const play = useSoftphoneAudio((ctx) => ctx.play);

  return (
    <li>
      <Text className='settings-li-title' as='h4' weight='bold' color='white'>
        {t('Audio Settings')}
      </Text>
      {!isMediaSourcesLoading ? (
        <div
          css={css`
            display: flex;
            align-items: center;
            width: 100%;
            margin-bottom: ${theme.spacing(2)};
          `}
        >
          <DeviceSelector
            sources={inputSources}
            currSource={currInputSource}
            onChange={(source) => {
              setCurrInputSource?.(source);
            }}
            label={t('Microphone')}
          />
          <div
            css={css`
              margin-left: ${theme.spacing(2)};
              width: 48px;
              text-align: center;
            `}
          >
            <VolumeDisplay source={currInputSource} />
            <label>{t('Input')}</label>
          </div>
        </div>
      ) : (
        <TextButton
          disabled={isMediaSourcesLoading}
          css={css`
            background: transparent;
            color: ${theme.colors.primary40};
            font-weight: 600;
            border: 0;
            font-size: 16px;
            cursor: pointer;
            display: flex;
            align-items: center;
            margin-right: ${theme.spacing(0.5)};
            &:hover:not(:disabled) {
              background-color: ${theme.colors.neutral70};
            }
          `}
          onClick={() => {
            refreshSources?.();
          }}
        >
          {t('Give Microphone Access')}
          {!isMediaSourcesLoading && <SpinningLoader size='small' />}
        </TextButton>
      )}
      {!isMediaSourcesLoading && (
        <>
          {outputSources?.length ? (
            <div
              css={css`
                display: flex;
                align-items: center;
                width: 100%;
              `}
            >
              <DeviceSelector
                sources={outputSources}
                currSource={currOutputSource}
                onChange={(source) => {
                  setCurrOutputSource?.(source);
                }}
                label={t('Speakers')}
              />

              <button
                data-trackingid='softphone-settings-audiotest'
                title={t('Test Speaker Audio')}
                onClick={() => {
                  play.speaker();
                }}
                css={css`
                  border-radius: 50px;
                  background: transparent;
                  border: none;
                  display: inline-flex;
                  align-items: center;
                  justify-content: center;
                  vertical-align: middle;
                  align-items: center;
                  transition: transform 0.2s ease-out;
                  cursor: pointer;
                  padding: 0;
                  margin-left: ${theme.spacing(2)};
                `}
              >
                <VolumeIcon size={16} color='white' />
              </button>
              <label
                css={css`
                  margin-left: ${theme.spacing(1)};
                `}
              >
                {t('Test')}
              </label>
            </div>
          ) : (
            <em>{t('System Default Speaker Device')} </em>
          )}
        </>
      )}
    </li>
  );
};

type DeviceSelectorProps = {
  sources: MediaDeviceInfo[] | undefined;
  currSource: MediaDeviceInfo | undefined;
  onChange: (device: MediaDeviceInfo) => void;
  label?: string;
};
const DeviceSelector = ({ sources, currSource, onChange, label }: DeviceSelectorProps) => {
  const deviceFieldProps = useFormField(
    {
      value: currSource?.deviceId,
      type: 'dropdown',
    },
    []
  );

  useEffect(() => {
    const source = sources?.find((src) => src.deviceId === deviceFieldProps.value);
    if (source) {
      onChange(source);
    }
  }, [sources, deviceFieldProps.value]);

  return (
    <DropdownField
      dropdownListStyle={dropdownListStyles}
      css={containerStyles}
      label={label}
      name='media-device-selector'
      {...deviceFieldProps}
    >
      {sources?.map((src) => (
        <DropdownField.Option trackingId='softphone-settings-audio' key={src.deviceId} value={src.deviceId}>
          {src.label}
        </DropdownField.Option>
      ))}
    </DropdownField>
  );
};

const dropdownListStyles = css`
  box-shadow: 0px 4px 4px rgba(5, 5, 5, 0.75);
  border-radius: 0px 0px 4px 4px;
  background-color: ${theme.colors.neutral80};

  ul {
    max-height: ${theme.spacing(22)};
  }

  li {
    background-color: transparent;
    span {
      color: ${theme.colors.white};
    }
    &:hover {
      background-color: ${theme.colors.neutral70};
    }
  }
`;

const containerStyles = css`
  label {
    color: ${theme.colors.neutral30};

    &:before {
      background: ${theme.colors.neutral90};
    }
  }

  svg {
    color: ${theme.colors.white};
  }

  border-radius: ${theme.borderRadius.small};
  padding: ${theme.spacing(1)};
  padding-right: ${theme.spacing(2)};
  width: 242px;
  height: ${theme.spacing(5)};
  background: transparent;
  color: ${theme.colors.white};
  font-size: ${theme.spacing(2)};
  border: 1px solid ${theme.colors.neutral30};
`;

type VolumeDisplayProps = {
  source: MediaDeviceInfo | undefined;
};

const VolumeDisplay = memo(({ source: microphone }: VolumeDisplayProps) => {
  const meter = useRef<HTMLMeterElement>(null);
  const interval = useRef<ReturnType<typeof setInterval>>();
  const [stream, setStream] = useState<MediaStream>();

  /**
   * This effect is responsible for setting up and tearing down the microphone stream
   */
  useEffect(() => {
    if (!stream) {
      navigator.mediaDevices
        .getUserMedia({ audio: { deviceId: microphone ? { exact: microphone.deviceId } : undefined } })
        .then((str) => {
          setStream(str);
        });
    }

    return () => {
      stream?.getTracks().forEach((track) => track.stop());
    };
  }, [microphone, stream]);

  /**
   * This effect is responsible for setting up the volume meter
   */
  useEffect(() => {
    if (stream) {
      clearInterval(interval.current);
      const audioContext = new AudioContext();
      const mediaStreamAudioSourceNode = audioContext.createMediaStreamSource(stream);
      const analyserNode = audioContext.createAnalyser();
      mediaStreamAudioSourceNode.connect(analyserNode);

      const pcmData = new Float32Array(analyserNode.fftSize);
      const showVolumeMeter = () => {
        analyserNode.getFloatTimeDomainData(pcmData);
        let sumSquares = 0.0;
        for (const amplitude of pcmData) {
          sumSquares += amplitude * amplitude;
        }
        const downwardThreshold = 1.5;
        if (meter && meter.current) {
          let volume = Math.log(Math.sqrt(sumSquares / pcmData.length) * 100 + 1) * 30;
          if (volume > 1) {
            //this helps display that there is at least some audio coming in, even if it's faint
            volume += 10;
          }
          if (volume > meter.current.value) {
            meter.current.value = volume;
          } else if (meter.current.value - volume < downwardThreshold) {
            meter.current.value = volume;
          } else {
            //this slows down the downward movement, and gives it a smoother look
            meter.current.value -= downwardThreshold;
          }
        }
      };

      interval.current = setInterval(() => {
        showVolumeMeter();
      }, 30);
    }

    return () => {
      clearInterval(interval.current);
    };
  }, [stream]);

  return (
    <meter
      css={css`
        width: 100%;
      `}
      min={0}
      max={100}
      ref={meter}
    ></meter>
  );
});
