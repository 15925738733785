import { ReactNode, SyntheticEvent, useMemo } from 'react';
import { Channel } from '@weave/schema-gen-ts/dist/schemas/comm-preference/shared/v1/enums.pb';
import { MessageType_Enum } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { ValidNumberStatus } from '@weave/schema-gen-ts/dist/schemas/sms/number/v1/number_service.pb';
import { DepartmentsQueries, DepartmentsUtils } from '@frontend/api-departments';
import { CommPreferenceQueries } from '@frontend/api-messaging';
import { PersonHelpers, PersonsV3 } from '@frontend/api-person';
import { SMSNumberV1 } from '@frontend/api-sms-number';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import {
  useMessagePopupBarManager,
  MessagePopupThreadStatus,
  convertStringToMessagePopupThreadStatus,
} from '@frontend/popup-bar';
import { SchemaSMSService } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { ModalControlTriggerProps, useModalControl, useAlert } from '@frontend/design-system';
import { ThreadSelectionModal } from './thread-selection-modal/thread-selection-modal';
import { ThreadSelectionContext } from './types';
import { getNewThreadIdString } from './utils';

type UseMessageActionArgs = {
  context: ThreadSelectionContext;
  targetSmsData?: {
    id: string;
    createdAt: string;
  };
  onClose?: () => void;
};

/**
 * To use this hook properly, provide as much context as possible:
 * - If this action is associated with a contact or person, provide it in the `person` prop, or at least the `personId`.
 * - If this action is associated with a specific phone number, or to provide a default phone number selection, provide it in the `phoneNumber` prop.
 * - If this action is associated with a specific locationId, only departments/outbound numbers from that location will be available to select.
 * - If this action is associated with a specific department or outbound sms number, provide the associated departmentId in the `departmentId` prop.
 */
export const useMessageAction = ({
  context,
  targetSmsData,
  onClose,
}: UseMessageActionArgs): {
  triggerProps?: ModalControlTriggerProps;
  Modal: ReactNode;
  disabled: boolean;
  disabledDetails: string;
} => {
  const { t } = useTranslation('inbox');
  const alert = useAlert();
  const { addPopup, popupList, removePopup } = useMessagePopupBarManager();
  const { triggerProps, modalProps } = useModalControl();
  const personQuery = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    { locationIds: [context.locationId], personId: context.personId ?? '' },
    { enabled: !context.person && !!context.personId }
  );
  const { selectedLocationIds } = useAppScopeStore();

  const person = context.person || PersonsV3.PersonHelpers.convertPersonV3ToPerson(personQuery.data);
  // since `convertPersonV3ToPerson` returns an object with all fields, regardless of whether or not there is data, we need to check if the person actually exists
  const hasPerson = !!person.PersonID;
  const { validPhones: personValidPhones, isLoading: personPhoneValidityIsLoading } =
    SMSNumberV1.Hooks.usePersonSMSNumbersValidity({
      personId: person.PersonID,
      groupIds: context.locationId ? [context.locationId] : selectedLocationIds,
    });
  const { data: personPhoneIsValid } = SMSNumberV1.Queries.useGetValidityQuery({
    request: {
      phoneNumber: context.phoneNumber ?? '',
    },
    options: { enabled: !hasPerson && !!context.phoneNumber },
    fallbackDataOnError: {
      isValid: ValidNumberStatus.VALID_NUMBER_STATUS_VALID,
    },
  });
  const validNumberCount = context.phoneNumber ? (personPhoneIsValid ? 1 : 0) : personValidPhones.length;
  const phonesForPreferences = context.phoneNumber
    ? [context.phoneNumber]
    : personValidPhones.map(({ number }) => number) ?? [];
  const optedOutPreferences = CommPreferenceQueries.useCheckSMSPreferences(
    phonesForPreferences?.map((phone) => ({
      request: {
        userChannelAddress: phone,
        channel: Channel.CHANNEL_SMS,
        locationId: context.locationId,
        messageType: MessageType_Enum.MESSAGING_MANUAL,
      },
    }))
  );
  const allValidPhonesAreOptedOut = optedOutPreferences.every((pref) => !pref.data?.consented);
  const departmentsQueries = DepartmentsQueries.useListDefaultSMSQueries([{ req: { locationId: context.locationId } }]);
  const { data: departmentsData, isLoading: departmentsIsLoading } =
    DepartmentsUtils.reduceDefaultSMSQueriesToLocationIdMap(departmentsQueries, ({ smsNumbers }) => smsNumbers ?? []);
  const departments = departmentsData?.[context.locationId] ?? [];

  const handleClick = (e?: SyntheticEvent<Element, Event>) => {
    const onlyValidPhone = hasPerson
      ? personValidPhones.length === 1
        ? personValidPhones[0].number
        : undefined
      : undefined;
    const providedPhoneNumber = context.phoneNumber;
    const resolvedPhoneNumber = providedPhoneNumber || onlyValidPhone;
    const onlyDepartmentId = departments.length === 1 ? departments[0].id : undefined;

    // Don't use modal if there is no further input needed from user
    if (resolvedPhoneNumber && (context.departmentId || onlyDepartmentId || !departments.length)) {
      handleSelect({
        personPhone: resolvedPhoneNumber,
        departmentId: context.departmentId || onlyDepartmentId || '',
        groupId: context.locationId,
      });
      e?.stopPropagation();
    } else {
      triggerProps.onClick(e);
    }
  };

  const handleSelect = async ({
    personPhone,
    departmentId,
    groupId,
  }: {
    personPhone: string;
    departmentId: string;
    groupId: string;
  }) => {
    if (!groupId) {
      console.warn('No groupId provided for message action.');
    }
    try {
      const threadIdLookup = await SchemaSMSService.LookupThreadId({
        personPhone,
        departmentId,
        locationId: groupId,
        calculateMissing: true,
      });
      const threadRes = threadIdLookup.threadId
        ? await SchemaSMSService.GetThread({
            threadId: threadIdLookup.threadId,
            locationId: groupId,
            messageLimit: 1,
          }).catch(() => {
            return undefined;
          })
        : undefined;
      const resolvedThreadId = threadRes?.thread.id || threadIdLookup.threadId;
      const existingPopup = popupList.find(
        (popup) => popup.id === resolvedThreadId || popup.meta.threadId === resolvedThreadId
      );
      const newTargetSmsData = {
        targetSmsId: targetSmsData?.id ?? '',
        targetSmsCreatedAt: targetSmsData?.createdAt ?? '',
        targetSmsClickCount: targetSmsData?.id ? 1 : 0,
      };

      if (targetSmsData && existingPopup?.meta.smsId === targetSmsData?.id) {
        newTargetSmsData.targetSmsClickCount = (existingPopup.meta.click ?? 0) + 1;
      } else if (targetSmsData && existingPopup && existingPopup?.meta.smsId !== targetSmsData.id) {
        removePopup(existingPopup.id);
      }

      addPopup({
        id: resolvedThreadId || getNewThreadIdString({ personPhone, departmentId }),
        name: hasPerson ? PersonHelpers.getFullName(person) : formatPhoneNumber(personPhone),
        type: 'message',
        meta: threadRes
          ? {
              groupId: threadRes.thread.locationId,
              threadId: threadRes.thread.id,
              personPhone,
              personId: context.personId || person.PersonID,
              isNew: false,
              departmentId: threadRes.thread.departmentId,
              smsId: targetSmsData?.id,
              smsCreatedAt: targetSmsData?.createdAt,
              click: targetSmsData?.id ? 1 : 0,
              status: convertStringToMessagePopupThreadStatus(threadRes?.thread.status ?? 'read'),
            }
          : {
              groupId: context.locationId,
              threadId: resolvedThreadId,
              personPhone,
              personId: context.personId || person.PersonID,
              isNew: true,
              departmentId,
              smsId: targetSmsData?.id,
              smsCreatedAt: targetSmsData?.createdAt,
              click: targetSmsData?.id ? 1 : 0,
              status: MessagePopupThreadStatus.READ,
            },
      });
      handleClose();
    } catch {
      alert.error({ message: t('Something went wrong. Please try again.') });
    }
    return Promise.resolve();
  };

  const handleClose = () => {
    modalProps.onClose();
    onClose?.();
  };

  const disabledDetails = useMemo<string>(() => {
    if (allValidPhonesAreOptedOut) return t('Contact opted out');
    if (hasPerson && personPhoneValidityIsLoading) return '';
    if (validNumberCount === 0) {
      return hasPerson ? t('This contact has no textable phone numbers.') : t('This phone number is not textable.');
    }
    return '';
  }, [hasPerson, validNumberCount, personPhoneValidityIsLoading, allValidPhonesAreOptedOut]);

  return {
    triggerProps: !!disabledDetails
      ? undefined
      : {
          ...triggerProps,
          onClick: handleClick,
        },
    Modal: (
      <ThreadSelectionModal
        {...modalProps}
        onClose={handleClose}
        context={context}
        onSelect={handleSelect}
        departments={departments}
        validPhones={personValidPhones}
        isLoading={personPhoneValidityIsLoading || departmentsIsLoading}
      />
    ),
    disabled: !!disabledDetails || personPhoneValidityIsLoading || departmentsIsLoading,
    disabledDetails,
  };
};
