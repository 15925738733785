// URLS
export const URLs = {
  CALL_INTEL_BASE: '/analytics/call-intelligence',
  CALL_INTEL_MAIN_PAGE: '/analytics/call-intelligence/main',
  CALL_INTEL_SUBSCRIBE: '/portal/subscriptions/call-intelligence',
  CALL_INTEL_TALK_TO_SALES: 'https://www.getweave.com/campaign/call-intelligence/',

  MESSAGING_ANALYTICS_FEEDBACK:
    'https://feedback.getweave.com/forums/952282-analytics/suggestions/46461094-message-analytics-in-weave-portal-website',
  MESSAGING_ANALYTICS_HELP: 'https://www.weavehelp.com/weavehelp/article/using-messaging-analytics',

  PHONE_ANALYTICS_FEEDBACK:
    'https://feedback.getweave.com/forums/938685-weave-portal/suggestions/45636907-call-analytics',
  PHONE_ANALYTICS_HELP: 'https://www.weavehelp.com/weavehelp/article/using-phone-analytics',
  PHONE_ANALYTICS_VIDEO_ID: 'fq3b1i62az',

  PRACTICE_ANALYTICS_FEEDBACK:
    'https://feedback.getweave.com/forums/952282-analytics/suggestions/47770865-practice-analytics',
  PRACTICE_ANALYTICS_HELP: 'https://www.weavehelp.com/weavehelp/article/using-practice-analytics',
  PRACTICE_ANALYTICS_SUBSCRIBE: '/portal/subscriptions/practice-analytics',

  // Analytics Action URLs
  BULK_MESSAGES: '/portal/messages/bulk',
  QUICK_FILL: 'https://www.weavehelp.com/hc/en-us/articles/360061238691',

  SCHEDULE_CALL_CHILIPIPER_URL: 'https://getweave.chilipiper.com/book/self-upgrade',
};

// Fixed values
export const DEFAULT_DAY = 'last-14-days';
export const DEFAULT_TIME_FILTER_PA = 'last-30-days';
export const DEFAULT_HOURS = 'all-hours';
export const HOURLY_INSIGHTS_AFTER_TIME = 19;
export const HOURLY_INSIGHTS_BEFORE_TIME = 7;
