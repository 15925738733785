import { useQueryClient, useMutation, UseMutateFunction } from 'react-query';
import { FormsQueryKeys, FormsSubmission } from '@frontend/api-forms';

interface ArchiveFormParams {
  submissionId: string;
  archive: boolean;
}

interface UseFormArchiveResults {
  updateArchiveStatus: UseMutateFunction<any, unknown, ArchiveFormParams, unknown>;
  isUpdating: boolean;
  isUpdateSuccess: boolean;
  isUpdateError: boolean;
}

export function useFormArchive(): UseFormArchiveResults {
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess, isError } = useMutation(
    ({ submissionId, archive }: ArchiveFormParams) => {
      return FormsSubmission.API.updateArchiveStatus({ submissionIds: [submissionId], archive });
    },
    {
      onSuccess: () => {
        return Promise.all([
          queryClient.invalidateQueries(FormsQueryKeys.formSubmissions),
          queryClient.invalidateQueries(FormsQueryKeys.formSubmission),
          queryClient.invalidateQueries(FormsQueryKeys.submissionCategoryCount),
        ]);
      },
    }
  );

  return {
    updateArchiveStatus: mutate,
    isUpdateSuccess: isSuccess,
    isUpdating: isLoading,
    isUpdateError: isError,
  };
}
