import { useEffect, useMemo } from 'react';
import { useQueryClient } from 'react-query';
import { ScheduleQueries } from '@frontend/api-schedule';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { useAlert } from '@frontend/design-system';
import { ReviewAppointmentRequestForm } from '../../../../../../components/ReviewAppointmentRequest';
import { getIsIntegratedOffice, invalidateFilterForPersonSearch } from '../../../../../../utils';
import { PersonSelector, PersonSelectorTypes } from '../../../../components/PersonSelector';
import { PersonSelectorFormValues } from '../../../../components/PersonSelector/types';
import { useScheduleRequestModalContext } from '../Context/ScheduleRequestModalContext';
import { useScheduleRequestModalFormContext } from '../Context/ScheduleRequestModalFormContext';
import { ScheduleRequestsTrackingIds } from '../trackingIds';
import {
  convertToServerDate,
  getFullName,
  getScheduleRequestFullName,
  getShouldRenderSourceDropDown,
  getShouldRenderWorkstationsDropdown,
} from '../utils';

export const ScheduleRequestModalForm = () => {
  const queryClient = useQueryClient();
  const alert = useAlert();
  const { t } = useTranslation('scheduleCalendarRequest');
  const { selectedScheduleRequest } = useScheduleRequestModalContext();

  const {
    personSelectorDialogProps,
    manageScheduleRequestFormDetails,
    manageScheduleRequestModalDetails,
    selectedPerson,
    personSelectorProps,
    setTemplate,
  } = useScheduleRequestModalFormContext();

  const {
    access,
    isLoading,
    isSyncAppDetailsLoading,
    providers,
    workstations,
    appointmentTypes,
    selectedAppointmentDetails,
    syncAppDataSources,
    tags,
  } = manageScheduleRequestModalDetails;

  const isIntegratedOffice = getIsIntegratedOffice(syncAppDataSources);
  const shouldRenderDataSourceDropdown = getShouldRenderSourceDropDown(syncAppDataSources ?? []);

  const {
    scheduleRequestApprovalForm: { values, seedValues, getFieldProps, isComplete },
    customDateField,
    customTimeField,
  } = manageScheduleRequestFormDetails;

  const { mutateAsync: addWritebackCustomer, isLoading: isLoadingAddWritebackCustomer } =
    ScheduleQueries.useAddWritebackCustomer();

  const { mutateAsync: addCustomContact, isLoading: isAddingCustomContact } = ScheduleQueries.useMutateAddCustomContact(
    selectedScheduleRequest.locationId ?? ''
  );

  useEffect(() => {
    const sourceId = selectedPerson?.SourceID || syncAppDataSources?.[0]?.SourceID;
    seedValues({
      ...values,
      source: sourceId ?? '',
      appointmentType: selectedAppointmentDetails.appointmentId,
    });
  }, [syncAppDataSources?.[0]?.SourceID, selectedAppointmentDetails.appointmentId, selectedPerson?.SourceID]);

  const [filteredProviderList, filteredWorkstationList] = useMemo(() => {
    const filteredProviders = isIntegratedOffice
      ? providers?.filter((provider) => provider.sourceId === values.source)
      : providers;
    const filteredWorkstations = workstations?.filter((workstation) => workstation.sourceId === values.source);
    return [filteredProviders, filteredWorkstations];
  }, [values.source, providers, workstations, isIntegratedOffice]);

  useEffect(() => {
    if (filteredWorkstationList.length === 1 && isIntegratedOffice) {
      seedValues({
        ...values,
        workstation: filteredWorkstationList[0]?.id,
      });
    }
  }, [filteredWorkstationList, isIntegratedOffice]);

  const shouldRenderWorkstationDropdown = getShouldRenderWorkstationsDropdown(
    filteredWorkstationList,
    isIntegratedOffice
  );

  const disabledForm = !selectedPerson?.PersonID;

  const handleAddCustomContact = async (formValues: PersonSelectorFormValues) => {
    return await addCustomContact({
      first_name: formValues?.firstName ?? '',
      last_name: formValues?.lastName ?? '',
      phone_mobile: formValues?.phoneNumber ?? '',
      birthdate: formValues?.dateOfBirth ? convertToServerDate(formValues?.dateOfBirth) : '',
      email: formValues?.email ?? '',
      gender: formValues?.gender ?? '',
    });
  };

  const handleAddWritebackContact = async (formValues: PersonSelectorFormValues) => {
    const clientLocationId =
      formValues?.clientLocationId ||
      access?.ClientLocations?.find((clientLocation) => clientLocation?.SourceID === formValues?.source)
        ?.ClientLocationID;
    return await addWritebackCustomer({
      ClientLocationID: clientLocationId ?? '',
      LocationID: selectedScheduleRequest?.locationId ?? '',
      SourceID: formValues?.source ?? '',
      Person: {
        FirstName: formValues?.firstName ?? '',
        LastName: formValues?.lastName ?? '',
        MobilePhone: formValues?.phoneNumber ?? '',
        Email: formValues?.email ?? '',
        Gender: formValues?.gender ?? '',
        Birthdate: formValues?.dateOfBirth ? convertToServerDate(formValues?.dateOfBirth) : '',
        Address1: selectedScheduleRequest?.schedulee?.address?.street ?? '',
        City: selectedScheduleRequest?.schedulee?.address?.city ?? '',
        State: selectedScheduleRequest?.schedulee?.address?.state ?? '',
        Address2: selectedScheduleRequest?.schedulee?.address?.unit ?? '',
        PostalCode: selectedScheduleRequest?.schedulee?.address?.postalCode ?? '',
        HomePhone: '',
        MaritalStatus: '',
        MiddleName: '',
        PreferredName: '',
        ReferralSource: '',
        SSN: '',
        WorkPhone: '',
      },
    });
  };

  const handleAddCustomerContact = async (formValues: PersonSelectorFormValues) => {
    try {
      const isCustomContactSource =
        syncAppDataSources?.find((integration) => integration.SourceID === formValues.source)?.SourceType ===
        'CustomContact';

      const response =
        isIntegratedOffice && !isCustomContactSource
          ? await handleAddWritebackContact(formValues)
          : await handleAddCustomContact(formValues);
      if (response) {
        alert.success(t('Customer successfully created.'));
        queryClient.invalidateQueries({
          predicate: ({ queryKey }) =>
            invalidateFilterForPersonSearch(queryKey, formValues, selectedScheduleRequest.locationId),
        });
        personSelectorProps.setPersonSelectorScreen(PersonSelectorTypes.PatientSelectorScreenEnum.PERSON_SEARCH);
      }
    } catch (e) {
      console.error(e);
      alert.error(t('We were unable to create the customer.'));
    }
  };

  const fullName = selectedPerson ? getFullName(selectedPerson) : getScheduleRequestFullName(selectedScheduleRequest);

  return (
    <>
      <ReviewAppointmentRequestForm
        appointmentTypes={appointmentTypes}
        isLoading={isLoading || isSyncAppDetailsLoading}
        dateField={customDateField}
        timeField={customTimeField}
        disabledForm={disabledForm}
        getFieldProps={getFieldProps}
        patientName={fullName}
        patientPhone={formatPhoneNumber(selectedPerson?.MobilePhone ?? selectedScheduleRequest?.schedulee?.phoneNumber)}
        personDialogProps={personSelectorDialogProps}
        providerList={filteredProviderList}
        selectedScheduleRequest={selectedScheduleRequest}
        showDataSourceDropdown={shouldRenderDataSourceDropdown}
        showWorkstationDropdown={shouldRenderWorkstationDropdown}
        syncAppDataSources={syncAppDataSources}
        values={values}
        workstationList={filteredWorkstationList}
        templateTags={tags}
        isReadOnlyTemplate={!isComplete || disabledForm}
        onTemplateChange={(template: string) => setTemplate(template)}
      />
      <PersonSelector
        shouldAddAndLink={false} // TODO: To be updated later after finalizing the flow
        addContactScreenTitle={t('Add Patient')}
        defaultSearchValue={selectedScheduleRequest?.schedulee?.phoneNumber ?? ''}
        locationId={selectedScheduleRequest.locationId ?? ''}
        handleAddCustomerContact={handleAddCustomerContact}
        isLoading={isLoadingAddWritebackCustomer || isAddingCustomContact}
        personSelectorDialogProps={personSelectorDialogProps}
        selectedPerson={selectedPerson}
        showDataSources={true}
        addNewButtonTrackingId={ScheduleRequestsTrackingIds.personSelectorAddNewPersonBtn}
        addToListButtonTrackingId={ScheduleRequestsTrackingIds.personSelectorAddToListBtn}
        {...personSelectorProps}
      />
    </>
  );
};
