import { FC } from 'react';
import { FormsSubmission } from '@frontend/api-forms';
import { ModalControlModalProps } from '@frontend/design-system';
import CustomModal from '../custom-modal.component';
import ModalActionBar from '../modal-action-bar/modal-action-bar.component';
import Attachment from './attachment/attachment.component';
import { containerStyle, innerContainerStyle } from './attachment-details-modal.styles';

interface AttachmentDetailsModalProps {
  title: string;
  firstName: string;
  lastName: string;
  attachmentsToShow: FormsSubmission.Types.AttachmentData[];
  modalProps: ModalControlModalProps;
  onShowForm?: (formId: string) => void;
}

const AttachmentDetailsModal: FC<React.PropsWithChildren<AttachmentDetailsModalProps>> = ({
  attachmentsToShow,
  modalProps,
  onShowForm,
  ...rest
}) => {
  if (attachmentsToShow.length === 0) {
    return null;
  }

  function showFormHandler() {
    if (!attachmentsToShow.length) {
      return;
    }

    const formId = attachmentsToShow[0].formId;

    if (!formId || !onShowForm) {
      return;
    }

    onShowForm(formId);
  }

  return (
    <CustomModal
      modalProps={modalProps}
      modalDataOptions={
        <ModalActionBar isDownloading={false} modalType='attachment' onOpenAnotherModal={showFormHandler} />
      }
      secondaryButtonText='Cancel'
      onSecondaryButtonClick={() => {
        modalProps.onClose();
      }}
      maxWidth='978px'
      {...rest}
    >
      <div css={containerStyle}>
        <div css={innerContainerStyle}>
          {attachmentsToShow.map((attachmentToShow) => (
            <Attachment key={attachmentToShow.id} attachment={attachmentToShow} />
          ))}
        </div>
      </div>
    </CustomModal>
  );
};

export default AttachmentDetailsModal;
