import { OktaAuth } from '@okta/okta-auth-js';
import { VerifyAuthResponse } from '@weave/schema-gen-ts/dist/schemas/auth-api/v3/auth.pb';
import { getWeaveToken, isWeaveTokenActive, isTimeExpired, OktaConfig, SignInMethod } from '@frontend/auth-helpers';
import { sentry } from '@frontend/tracking';
import { AuthFlow, ClientTokens } from './AuthFlow';

export class OktaClient implements AuthFlow {
  private oktaClient: OktaAuth;
  constructor(config: OktaConfig) {
    this.oktaClient = new OktaAuth(config);
  }

  public async signIn(): Promise<VerifyAuthResponse> {
    return this.oktaClient.signInWithRedirect().then(() => {
      return {};
    });
  }

  /**
   * The okta token determines if the user has access to the app.
   * This is only applicable if we're authenticating with okta.
   */
  private isOktaTokenActive(): boolean {
    /**
     * We need to check against the access token (8 hour expiration), not any other token
     */
    const token = this.oktaClient.tokenManager.getTokensSync();
    if (token.accessToken) {
      return !isTimeExpired(token.accessToken?.expiresAt);
    }
    return false;
  }

  getSignInMethod(): Promise<SignInMethod> {
    return Promise.resolve('okta');
  }

  getTokens(): Promise<ClientTokens> {
    const oktaTokens = this.oktaClient.tokenManager.getTokensSync();
    const weaveToken = getWeaveToken();
    const tokens: ClientTokens = {
      accessToken: oktaTokens.accessToken?.accessToken,
      IDToken: oktaTokens.idToken?.idToken,
      weaveToken: weaveToken,
    };
    return Promise.resolve(tokens);
  }

  async handleCallback(): Promise<ClientTokens> {
    return await this.oktaClient.token
      .parseFromUrl()
      .then((res) => {
        const tokens = res.tokens;
        this.oktaClient.tokenManager.setTokens(tokens);
        return {
          accessToken: tokens.accessToken?.accessToken,
          IDToken: tokens.idToken?.idToken,
        };
      })
      .catch((err) => {
        sentry.error({
          topic: 'auth',
          error: err,
          addContext: {
            name: 'auth',
            context: {
              errMessage: 'parsing token from url',
            },
          },
        });
        if (err?.errorSummary === 'The JWT expired and is no longer valid') {
          throw new Error(`${err}. Please ensure your computer clock is on the correct time and then Start Over.`);
        } else {
          throw new Error(err);
        }
      });
  }

  isUserAuthenticated(): boolean {
    const oktaToken = this.oktaClient.tokenManager.getSync('accessToken');
    return oktaToken ? this.isOktaTokenActive() && isWeaveTokenActive() : isWeaveTokenActive();
  }

  /**
   * Okta sign out method will redirect the browser
   */
  public async signOut({ logoutRedirect }: { logoutRedirect?: string } = {}): Promise<void> {
    const tokens = this.oktaClient.tokenManager.getTokensSync();
    if (tokens.idToken) {
      return this.oktaClient
        .signOut({
          clearTokensBeforeRedirect: true,
          idToken: tokens.idToken,
          postLogoutRedirectUri: logoutRedirect ?? `${window.origin}/sign-in`,
        })
        .then(() => {
          return new Promise<void>(() => {}); // return a promise that never resolves
        })
        .catch(() => {
          // if we get here, then the user's previous session was not found in okta (signed out already)
          return;
        });
    } else {
      window.location.href = logoutRedirect ?? `${window.origin}/sign-in`;
    }
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, 30000); // 30 seconds
    }); // return a promise that never resolves
  }
}
