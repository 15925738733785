import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, useModalControl } from '@frontend/design-system';
import { CreatePaymentRequestModal } from '../../PaymentRequests';

const styles = {
  createButton: css`
    width: auto;
    padding-left: ${theme.spacing(1)};
  `,
  plusIcon: css`
    margin-right: ${theme.spacing(1)};
  `,
};

export type NewInvoiceActionProps = {
  trackingId?: string;
};

export const NewInvoiceAction = ({ trackingId }: NewInvoiceActionProps) => {
  const { t } = useTranslation('payments');
  const { modalProps: createModalProps, triggerProps: createModalTriggerProps } = useModalControl();

  return (
    <>
      <Button
        trackingId={trackingId}
        iconName='plus'
        variant='primary'
        size='large'
        css={styles.createButton}
        {...createModalTriggerProps}
      >
        {t('New Invoice')}
      </Button>
      <CreatePaymentRequestModal {...createModalProps} />
    </>
  );
};
