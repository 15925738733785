import { FC, useEffect } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { useTranslation } from '@frontend/i18n';
import { DateRangeField, useFormField } from '@frontend/design-system';

interface Props {
  disabled?: boolean;
  endDate?: string;
  onChange: ({ startDate, endDate }: { startDate: string; endDate: string }) => void;
  startDate?: string;
}

export const DatesRangeSelector: FC<React.PropsWithChildren<Props>> = ({ disabled, endDate, onChange, startDate }) => {
  const { t } = useTranslation('calls', { keyPrefix: 'calls-filter' });

  const datesRangeProps = useFormField(
    {
      type: 'dateRange',
      value: startDate && endDate ? [dayjs(startDate).format('MM/DD/YYYY'), dayjs(endDate).format('MM/DD/YYYY')] : [],
      maxDate: dayjs(new Date()).format('YYYY-MM-DD'),
    },
    [endDate, startDate]
  );

  useEffect(() => {
    const dates = datesRangeProps.value;

    if (dates[0] && dates[1] && dayjs(dates[0]).isValid() && dayjs(dates[1]).isValid()) {
      onChange({
        endDate: dates[1],
        startDate: dates[0],
      });
    }
  }, [datesRangeProps.value]);

  return (
    <DateRangeField {...datesRangeProps} css={styles} disabled={disabled} label={t('Pick your dates')} name='dates' />
  );
};

const styles = css`
  > div {
    min-width: auto;
  }
`;
