import { CallTakeawayPanel, CallTakeawayPanelTray } from '@frontend/analytics';
import { MultiContactsPanel, TranscriptionPanel, ManageDevices } from '@frontend/calls-v2';
import { ContactPanel } from '@frontend/contact-panel';
import { ContactForm, CustomContactForm } from '@frontend/create-contact-panel';
import { FaxContactFormPanel } from '@frontend/fax';
import { SlidePanelType } from '@frontend/slide-panel';
import { WIDTH_DIMS } from '@frontend/design-system';

// The components should be defined in the shared package
export const slidePanels: Partial<
  Record<
    SlidePanelType,
    Record<'modal' | 'panel', JSX.Element> & { cleanupFn?: () => void; size?: keyof typeof WIDTH_DIMS }
  >
> = {
  callTakeaway: {
    modal: <CallTakeawayPanelTray />,
    panel: <CallTakeawayPanel />,
    cleanupFn: CallTakeawayPanel.cleanup,
    size: 'medium',
  },
  contact: {
    modal: <ContactPanel.ContactPanel Component={ContactPanel.ContactPanelTrayComponent} />,
    panel: <ContactPanel.ContactPanel Component={ContactPanel.ContactPanelComponent} />,
    cleanupFn: ContactPanel.ContactPanel.cleanup,
    size: 'smallXL',
  },
  contactForm: {
    modal: <ContactForm />,
    panel: <ContactForm />,
  },
  customContactForm: {
    modal: <CustomContactForm />,
    panel: <CustomContactForm />,
  },
  faxContactForm: {
    modal: <FaxContactFormPanel />,
    panel: <FaxContactFormPanel />,
  },
  multipleContacts: {
    modal: <MultiContactsPanel />,
    panel: <MultiContactsPanel />,
    cleanupFn: MultiContactsPanel.cleanup,
    size: 'smallXL',
  },
  voicemailTranscription: {
    modal: <TranscriptionPanel />,
    panel: <TranscriptionPanel />,
    size: 'medium',
  },
  manageDevices: {
    modal: <ManageDevices />,
    panel: <ManageDevices />,
    size: 'medium',
  },
};
