import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  margin: ${theme.spacing(1, 0)};
`;

export const popoverDialogStyle = css`
  border-radius: ${theme.borderRadius.small};
  padding: 0;
  max-height: 500px;
  overflow: auto;
`;

export const listItemStyle = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  padding: ${theme.spacing(1, 2)};
  padding-inline-end: ${theme.spacing(4)};
  cursor: pointer;

  &:hover {
    background-color: ${theme.colors.neutral5};
  }
`;
