import { ScheduledSms } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { useTranslation } from '@frontend/i18n';
import { useTimestampFormatter } from '@frontend/timestamp-formatter';
import { theme } from '@frontend/theme';
import { Text, NakedButton, useTooltip } from '@frontend/design-system';

type ScheduledMessageListItemProps = {
  message: ScheduledSms;
  onClick: () => void;
};

export const ScheduledMessageListItem = ({ message, onClick }: ScheduledMessageListItemProps) => {
  const { t } = useTranslation('thread-sending-area');
  const timestampFormatter = useTimestampFormatter();

  const { Tooltip, tooltipProps, triggerProps } = useTooltip({
    placement: 'top',
    trigger: 'hover',
    hoverDelay: 1000,
  });

  return (
    <li>
      <NakedButton
        onClick={onClick}
        css={{
          borderRadius: theme.borderRadius.small,
          border: `1px solid ${theme.colors.neutral20}`,
          backgroundColor: theme.colors.white,
          ':hover, :focus': {
            backgroundColor: theme.colors.primary5,
          },
          padding: theme.spacing(1),
          width: '100%',
          align: 'left',
        }}
        {...triggerProps}
      >
        <Text
          weight='bold'
          size='medium'
          css={{
            textAlign: 'left',
            marginBottom: theme.spacing(1),
            color: message.status === OutboundMessageStatus.PAUSED ? theme.font.colors.light : theme.colors.warning60,
          }}
        >
          {timestampFormatter(message.sendAt)}
          {message.status === OutboundMessageStatus.PAUSED && (
            <span css={{ fontWeight: 'normal' }}>{t(' (paused)')}</span>
          )}
        </Text>
        <Text textAlign='left'>{message.body}</Text>
        {!!message.mediaIds.length && (
          <Text
            textAlign='left'
            size='small'
            color='light'
            css={{
              fontStyle: 'italic',
            }}
          >
            {t('{{count}} attachments', { count: message.mediaIds.length })}
          </Text>
        )}
      </NakedButton>
      <Tooltip {...tooltipProps}>{t('Edit scheduled message')}</Tooltip>
    </li>
  );
};
