import { FC, useEffect } from 'react';
import { css } from '@emotion/react';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { Modal } from '@frontend/design-system';
import { useSteps } from '../hooks';
import { useSendFormsContext, useSendFormsStore } from '../providers';
import { SendFormStep } from '../types';
import { modalStyle } from './send-forms-modal.styles';
import SendFormsSteps from './steps/send-forms-steps.component';

const SendFormsModal: FC = () => {
  const { sendFormsModalControls } = useSendFormsContext();
  const { currentStep, temporarilyHideModal, setTemporarilyHideModal } = useSendFormsStore([
    'currentStep',
    'temporarilyHideModal',
    'setTemporarilyHideModal',
  ]);
  const { isLastStep } = useSteps();
  const { isOpen: isSettingsOpen } = useSettingsNavigate();
  const isModalOpen = sendFormsModalControls.modalProps.show;

  const dynamicModalStyle = css`
    ${modalStyle};
    ${(currentStep === SendFormStep.SELECT_CONTACT || currentStep === SendFormStep.SELECT_DOCUMENT) &&
    'height: min(90vh, 504px);'}
    ${isLastStep() && 'height: min(90vh, 650px);'}
  `;

  /**
   * This is used to close the modal when the user navigates away from the settings page.
   * And resume the modal when the user navigates back from the settings page.
   */
  useEffect(() => {
    if (isSettingsOpen && isModalOpen) {
      sendFormsModalControls.closeModal();
      setTemporarilyHideModal(true);
    }

    if (!isSettingsOpen && temporarilyHideModal) {
      setTemporarilyHideModal(false);
      sendFormsModalControls.openModal();
    }
  }, [isSettingsOpen, temporarilyHideModal, isModalOpen]);

  return (
    <Modal
      {...sendFormsModalControls.modalProps}
      css={dynamicModalStyle}
      disableCloseOnEscape
      disableCloseOnOverlayClick
    >
      <SendFormsSteps />
    </Modal>
  );
};

export default SendFormsModal;
