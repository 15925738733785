import { useEffect, useMemo } from 'react';
import { EventStatus } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/calendar_event.pb';
import dayjs from 'dayjs';
import { ScheduleQueries } from '@frontend/api-schedule';
import { SchedulerV3Queries } from '@frontend/api-scheduler-v3';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import { usePopoverMenu, useAlert, formatDate } from '@frontend/design-system';
import { useGetDataSourcesForLocation } from '../../../hooks/scheduler-v3/use-get-data-source-for-location';
import { useAppointmentEventCardShallowStore } from '../../../stores/use-appointment-event-card-store';
import { useAppointmentStatusShallowStore } from '../../schedule-calendar-components/appointment-event-card/appointmentStatusStore';
import { AppointmentStatusChip } from './AppointmentStatusChip';
import { getAppointmentStatusList, getLatestUpdatedAppointmentStatus } from './helpers';
import {
  AppointmentStatusV3Enum,
  APPOINTMENT_STATUS_MAPPING_V3,
  AppointmentStatusType,
  WeaveAppointmentStatus,
} from './types';

type EventStatusProps = {
  locationId: string;
  appointmentId: string;
};

export const EventCardAppointmentStatusChip = ({ locationId, appointmentId }: EventStatusProps) => {
  const alert = useAlert();
  const { t } = useTranslation('scheduleCalendarEvents');

  const { appointmentStatus, setAppointmentStatus, resetAppointmentStatus } = useAppointmentStatusShallowStore(
    'appointmentStatus',
    'setAppointmentStatus',
    'resetAppointmentStatus'
  );

  const {
    getTriggerProps: getPopoverTriggerProps,
    getMenuProps: getPopoverMenuProps,
    close: closePopover,
  } = usePopoverMenu({ placement: 'bottom-start' });

  const { selectedParentsIds } = useAppScopeStore();

  const { isIntegratedOffice } = useAppointmentEventCardShallowStore('isIntegratedOffice');

  const { mutateAsync: appointmentStatusWriteback, isLoading: isLoadingAppointmentStatusWriteback } =
    SchedulerV3Queries.useAppointmentStatusWriteback();

  const { mutateAsync: updateCalendarEventStatus, isLoading: isLoadingUpdateCalendarEvent } =
    SchedulerV3Queries.useUpdateCalendarEventStatus();

  const {
    data: appointmentDetails,
    isLoading: isLoadingAppointmentDetails,
    refetch: refetchAppointment,
  } = SchedulerV3Queries.useGetCalendarEvent(appointmentId, { enabled: !!appointmentId });

  const { data: dataSourcesData, isLoading: isLoadingDataSources } = useGetDataSourcesForLocation({
    locationId,
    isEnabled: isIntegratedOffice,
  });

  const { data: sourceTenantsData, isLoading: isLoadingSourceTenants } = SchedulerV3Queries.useListSourceTenants({
    locationId,
    isEnabled: isIntegratedOffice,
  });

  const { data: appointmentStatusData, isLoading: isLoadingAppointmentStatusData } =
    ScheduleQueries.useGetAppointmentStatuses(locationId);

  const isWritebackEnabled = useMemo(
    () =>
      dataSourcesData?.dataSources?.some((dataSource) =>
        dataSource.integration?.capabilities?.find(
          (capability) => capability.dataType === 'APPOINTMENT_STATUS' && capability.operation === 'LIST'
        )
      ),
    [dataSourcesData]
  );

  const appointmentStatusList = getAppointmentStatusList({
    appointmentStatusData: appointmentStatusData,
    appointmentDetails: appointmentDetails?.event,
    sourceTenants: sourceTenantsData?.sources,
    isIntegrated: isIntegratedOffice,
  });

  const currentStatusObj = useMemo(() => {
    const attendeeStatus = appointmentDetails?.event?.attendeeStatus as keyof typeof AppointmentStatusV3Enum;

    return (
      appointmentStatusList?.find((status) => status.key === attendeeStatus) ||
      APPOINTMENT_STATUS_MAPPING_V3.get(attendeeStatus as AppointmentStatusV3Enum)
    );
  }, [appointmentStatusList, appointmentDetails]);

  const resetAppointmentStatusStore = () => {
    refetchAppointment();
    resetAppointmentStatus();
  };

  const statusChip = useMemo(() => {
    let chip = currentStatusObj;
    if (appointmentStatus.length) {
      const existsInStore = appointmentStatus.findLast((status: AppointmentStatusType) => status.id === appointmentId);
      if (existsInStore) {
        const canUpdate = getLatestUpdatedAppointmentStatus(existsInStore);
        if (canUpdate) {
          resetAppointmentStatusStore();
          chip = currentStatusObj;
        } else {
          chip = existsInStore;
        }
      }
    }
    return chip;
  }, [appointmentId, appointmentStatus, currentStatusObj]);

  const handleWriteback = async (selectedStatus: AppointmentStatusType) => {
    const { key, value, text, iconName, variant } = selectedStatus;

    const weaveStatus = WeaveAppointmentStatus[key as keyof typeof AppointmentStatusV3Enum];

    const payload = {
      locationId,
      sourceTenantId: appointmentDetails?.event?.sourceTenantId ?? '',
      appointmentExternalId: appointmentDetails?.event?.details?.pReId ?? '',
      ...(selectedParentsIds?.length && { parentLocationId: selectedParentsIds[0] }),
      ...(value && { externalStatusId: value }),
      ...(weaveStatus && { weaveStatus }),
    };

    try {
      await appointmentStatusWriteback(payload);
      setAppointmentStatus({
        key,
        text,
        iconName,
        variant,
        id: appointmentId,
        updatedAt: formatDate(dayjs()),
      });
      alert.success(t('Appointment status updated. It might take a few minutes to reflect'));
    } catch (error) {
      alert.error(t('Error updating appointment status'));
    } finally {
      closePopover();
    }
  };

  const handleStatusChange = async (selectedStatus: AppointmentStatusType) => {
    const { key, text, iconName, variant } = selectedStatus;
    const eventId = appointmentDetails?.event?.id || '';

    try {
      await updateCalendarEventStatus({
        eventId,
        status: selectedStatus.key as EventStatus,
      });

      setAppointmentStatus({
        key,
        text,
        iconName,
        variant,
        id: appointmentId,
        updatedAt: formatDate(dayjs()),
      });

      alert.success(t('Appointment status updated'));
    } catch (error) {
      alert.error(t('Error updating appointment status'));
    } finally {
      closePopover();
    }
  };

  useEffect(() => {
    if (appointmentStatus.length) {
      resetAppointmentStatusStore();
    }
  }, [appointmentStatus.length]);

  const isLoadingStatus =
    isLoadingUpdateCalendarEvent ||
    (!isIntegratedOffice &&
      (isLoadingDataSources ||
        isLoadingAppointmentDetails ||
        isLoadingSourceTenants ||
        isLoadingAppointmentStatusWriteback));

  const isLoadingMenuList = isLoadingAppointmentStatusData;

  if (!statusChip) {
    return null;
  }

  if (isIntegratedOffice) {
    return (
      <AppointmentStatusChip
        isEditable={Boolean(isIntegratedOffice && isWritebackEnabled)}
        isLoadingStatus={isLoadingStatus}
        isLoadingMenuList={isLoadingMenuList}
        appointmentStatusList={appointmentStatusList}
        statusChip={statusChip}
        clickHandler={handleWriteback}
        getPopoverTriggerProps={getPopoverTriggerProps}
        getPopoverMenuProps={getPopoverMenuProps}
      />
    );
  }

  return (
    <AppointmentStatusChip
      isEditable
      isLoadingStatus={isLoadingStatus}
      isLoadingMenuList={isLoadingMenuList}
      appointmentStatusList={appointmentStatusList}
      statusChip={statusChip}
      clickHandler={handleStatusChange}
      getPopoverTriggerProps={getPopoverTriggerProps}
      getPopoverMenuProps={getPopoverMenuProps}
    />
  );
};
