import { Practitioner } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/practitioner.pb';
import { contextFactory } from '@frontend/design-system';

export type AddPractitionerV3ContextType = {
  locationId: string;
  parentLocationId: string;
  practitionerDetails: Practitioner;
  setPractitionerDetails: (practitionerDetails: Practitioner) => void;
  onClose: () => void;
};

export const [AddPractitionerV3Context, useAddPractitionerV3Context] = contextFactory<AddPractitionerV3ContextType>(
  'useAddPractitionerV3Context must be used within a AddPractitionerV3Context'
);
