import { useCallback, useMemo } from 'react';
import { useSoftphoneCallState } from '..';
import {
  EstablishedCall,
  IncomingCall,
  OutgoingCall,
  isEstablishedCall,
  isIncomingCall,
  isOutgoingCall,
} from '../../../types';
import { useSoftphoneAudio } from '../../softphone-audio-provider';
import { useSoftphoneClient } from '../../softphone-client-provider';
import { useSoftphoneEventsEmitter } from '../../softphone-events-provider';

/**
 *
 * Triggers the client hangup action, but does not update the call state.
 * Defers state updates to the 'handleHangup' handler assigned to the client delegate.
 */
export const useEndCall = () => {
  const calls = useSoftphoneCallState((ctx) => ctx.calls);
  const establishedCalls = useSoftphoneCallState((ctx) => ctx.establishedCalls);
  const client = useSoftphoneClient((ctx) => ctx.client);
  const terminateCall = useSoftphoneCallState((ctx) => ctx.terminateCall);
  const setIsMuted = useSoftphoneCallState((ctx) => ctx.setIsMuted);
  const { emit } = useSoftphoneEventsEmitter();
  const play = useSoftphoneAudio((ctx) => ctx.play);

  const endCall = useCallback(
    async <T extends EstablishedCall | OutgoingCall | IncomingCall>(call: T) => {
      const terminatedCall = terminateCall(call, 'hangup');
      setIsMuted(false);
      play.hangup();
      if (isOutgoingCall(call)) {
        emit('outgoing-call.canceled', call);
        await call.inviter.cancel({
          reasonPhrase: 'Call ended by user',
        });
      } else if (isEstablishedCall(call)) {
        emit('active-call.terminated', call);
        await call.session.bye({});
      } else if (isIncomingCall(call)) {
        emit('incoming-call.rejected', call);
        await call.invitation.reject({
          reasonPhrase: 'Call ended by user',
          statusCode: 603,
        });
      }
      return terminatedCall;
    },
    [client, calls]
  );

  const endAllCalls = useCallback(async () => {
    return Promise.all(establishedCalls.map((call) => endCall(call))).then((res) => {
      emit('conference-call.ended', establishedCalls);
      return res;
    });
  }, [endCall, establishedCalls]);

  return useMemo(
    () => ({
      endCall,
      endAllCalls,
    }),
    [endCall, endAllCalls]
  );
};
