import { DataSourcesApi } from '@frontend/api-data-sources';
import { useQuery } from '@frontend/react-query-helpers';
import { getIsIntegratedOffice } from '../utils';

const CACHE_AND_STALE_TIME = 1000 * 60 * 5; // 5 minute

type UseGetIntegrationDetailsParamType = {
  selectedLocationId: string;
  parentLocationId?: string;
  enabled?: boolean;
};

export const useGetIntegrationDetails = ({
  selectedLocationId,
  parentLocationId = '',
  enabled = false,
}: UseGetIntegrationDetailsParamType) => {
  const integrationQueryDetails = useQuery({
    queryKey: ['integrations', parentLocationId, selectedLocationId],
    queryFn: async () => {
      try {
        const { SyncApps } = await DataSourcesApi.getAccessibleIntegrations(parentLocationId || selectedLocationId);
        return SyncApps || [];
      } catch {
        // If there's a parent location id, then if the user doesn't have access to the parent location the above call with fail and we should try with the child location id
        if (parentLocationId) {
          const { SyncApps } = await DataSourcesApi.getAccessibleIntegrations(selectedLocationId);
          return SyncApps || [];
        }
        return [];
      }
    },
    enabled: !!selectedLocationId && enabled,
    staleTime: CACHE_AND_STALE_TIME, // 5 minute
    cacheTime: CACHE_AND_STALE_TIME, // 5 minute
  });

  const isIntegratedOffice = getIsIntegratedOffice(integrationQueryDetails?.data ?? []);

  return { isIntegratedOffice, ...integrationQueryDetails };
};
