import { Dispatch, SetStateAction, useCallback } from 'react';
import { css } from '@emotion/react';
import { Button } from '@frontend/generic-dialpad-accessories';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, useAlert, useModalControl, useTooltip } from '@frontend/design-system';
import { useSoftphoneCallState } from '../../providers/softphone-call-state-provider';
import { useSoftphoneEventsEmitter } from '../../providers/softphone-events-provider';
import { FeedbackEvents } from '../../providers/softphone-metrics-provider';
import { FeedbackModal } from './feedback.modal';

export type NegativeFeedbackPayload = { feedbackValues: string[]; message: string };
type Props = ReturnType<typeof useTooltip> & {
  setOpen: Dispatch<SetStateAction<boolean>>;
  setClearDismissTimeout: Dispatch<SetStateAction<boolean>>;
};
export const SoftphoneFeedbackCallRating = ({ setOpen, setClearDismissTimeout, ...props }: Props) => {
  const { t } = useTranslation('phone');
  const { emit } = useSoftphoneEventsEmitter();
  const alerts = useAlert();
  const { modalProps, openModal } = useModalControl();
  const feedbackCallId = useSoftphoneCallState((ctx) => ctx?.feedbackCallId);
  const { Tooltip, tooltipProps, isOpen } = props;

  const sucessAlert = () => alerts.success(t('Thank you for your feedback!'));

  const handlePositiveFeedback = () => {
    sucessAlert();
    emit('call.feedback_requested', { callRating: 'positive', callID: feedbackCallId });
    setOpen(false);
    setClearDismissTimeout(true);
  };

  const handleDismiss = useCallback(() => {
    setOpen(false);
    setClearDismissTimeout(true);
    emit('call.feedback_requested', {
      callRating: 'dismiss',
      callID: feedbackCallId,
    });
    if (modalProps.show) {
      modalProps.onClose();
    }
  }, [modalProps.show, feedbackCallId]);

  const handleOpenModal = () => {
    openModal();
    setOpen(false);
  };

  const handleNegativeFeedback = useCallback(
    ({ feedbackValues, message }: NegativeFeedbackPayload) => {
      const feedback: FeedbackEvents = {
        callRating: 'negative',
      };
      if (feedbackValues.includes('Poor sound quality')) {
        feedback.callFeedbackPoorSoundQuality = true;
      }
      if (feedbackValues.includes('No sound')) {
        feedback.callFeedbackNoSound = true;
      }
      if (feedbackValues.includes('Call dropped')) {
        feedback.callFeedbackCallDropped = true;
      }
      if (feedbackValues.includes('Slow response, app freezing')) {
        feedback.callFeedbackAppUnresponsive = true;
      }
      if (!!message || feedbackValues.includes('Other (explain below)')) {
        feedback.callFeedbackOther = true;
      }
      if (!!message) {
        feedback.callFeedback = message;
      }
      emit('call.feedback_requested', {
        callID: feedbackCallId,
        ...feedback,
      });
      sucessAlert();
      modalProps.onClose();
      setClearDismissTimeout(true);
    },
    [feedbackCallId]
  );

  return (
    <>
      <Tooltip {...tooltipProps} isOpen={isOpen} hasInteractiveContent>
        <FeedbackPopup
          handleDismiss={handleDismiss}
          handleOpenModal={handleOpenModal}
          handlePositiveFeedback={handlePositiveFeedback}
        />
      </Tooltip>
      <FeedbackModal {...modalProps} handleNegativeFeedback={handleNegativeFeedback} onClose={handleDismiss} />
    </>
  );
};

type FeedbackPopupProps = {
  handleDismiss: () => void;
  handlePositiveFeedback: () => void;
  handleOpenModal: () => void;
};

const FeedbackPopup = ({ handleDismiss, handlePositiveFeedback, handleOpenModal }: FeedbackPopupProps) => {
  const { t } = useTranslation('phone');

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: 1fr auto;
        gap: ${theme.spacing(2, 1)};
        padding: ${theme.spacing(1)};
      `}
    >
      <Text size='medium' color='white' weight='regular'>
        {t('How was your experience with this call')}?
      </Text>
      <Icon
        css={{ margin: '2px 0', cursor: 'pointer' }}
        onClick={handleDismiss}
        name='x-small'
        color='white'
        aria-label='Dismiss Softphone Feedback'
      />
      <div css={{ display: 'flex', gap: theme.spacing(1), margin: 'auto' }}>
        <Button onClick={handlePositiveFeedback} css={buttonStyle}>
          <Text
            css={css`
              font-size: 24px;
            `}
          >
            👍
          </Text>
        </Button>
        <Button onClick={handleOpenModal} css={buttonStyle}>
          <Text
            css={css`
              font-size: 24px;
            `}
          >
            👎
          </Text>
        </Button>
      </div>
    </div>
  );
};

const buttonStyle = css`
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;
