import { useEffect, useMemo } from 'react';
import dayjs from 'dayjs';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { DatePickerField, FormFieldActionTypes, RadioField, useForm } from '@frontend/design-system';
import { DateTimeSelectionHandlerKeys } from '../types';
import { getInitialDateCategory, getNextMonday, getToday, getTomorrow } from './utils';

const DATE_VALIDATOR_FORMAT = 'YYYY-MM-DD';
const DATE_VALUE_FORMAT = 'MM/DD/YYYY';
type DateFiltersProps = {
  onDateTimeSelection: (key: DateTimeSelectionHandlerKeys, value: string) => void;
  initialValue: {
    startDate: string;
    endDate: string;
  };
};
export const DateFilters = ({ onDateTimeSelection, initialValue }: DateFiltersProps) => {
  const { t } = useTranslation('inbox');
  const { startDate, endDate } = initialValue;

  const initialRadioSelection = useMemo(() => {
    if (!startDate || !endDate) return '';
    return getInitialDateCategory(startDate, endDate);
  }, [startDate, endDate]);

  const setDateRange = (date: string) => {
    onDateTimeSelection('startDate', date);
    onDateTimeSelection('endDate', date);
  };

  const { getFieldProps, reset, formProps } = useForm({
    fields: {
      radio: { type: 'radio', value: initialRadioSelection },
      startDate: {
        type: 'datePicker',
        required: true,
        value: initialRadioSelection === 'custom' ? formatDate(startDate, DATE_VALUE_FORMAT) : '',
        validator: ({ value }, formValues) => {
          if (!formValues.endDate) return '';
          const startDate = dayjs(value, 'MM/DD/YYYY').startOf('day');
          const endDate = dayjs(formValues.endDate.value, 'MM/DD/YYYY').startOf('day');

          return startDate.isAfter(endDate) ? t('Start date must be before the end date') : '';
        },
      },
      endDate: {
        type: 'datePicker',
        required: true,
        value: initialRadioSelection === 'custom' ? formatDate(endDate, DATE_VALUE_FORMAT) : '',
        validator: ({ value }, formValues) => {
          if (!formValues.startDate) return '';
          const startDate = dayjs(formValues.startDate.value, 'MM/DD/YYYY').startOf('day');
          const endDate = dayjs(value, 'MM/DD/YYYY').startOf('day');
          return endDate.isBefore(startDate) ? t('End date must be after the start date') : '';
        },
      },
    },
    fieldStateReducer: (state, action) => {
      if (action.type !== FormFieldActionTypes.Update) return state;

      if (action.payload.name === 'startDate' || action.payload.name === 'endDate') {
        state.radio.value = 'custom';
      }

      switch (state?.radio.value) {
        case 'today':
          setDateRange(getToday());
          break;
        case 'tomorrow':
          setDateRange(getTomorrow());
          break;
        case 'monday':
          setDateRange(getNextMonday());
          break;
        case 'custom':
          if (state.startDate.value && state.endDate.value && !state.endDate.error) {
            onDateTimeSelection('startDate', formatDate(state.startDate.value, DATE_VALIDATOR_FORMAT));
            onDateTimeSelection('endDate', formatDate(state.endDate.value, DATE_VALIDATOR_FORMAT));
          }
          break;
        default:
          break;
      }
      return state;
    },
  });

  useEffect(() => {
    if (!startDate && !endDate) {
      reset();
    }
  }, [startDate, endDate]);

  return (
    <div data-testid='inbox-date-filters-box' css={{ padding: theme.spacing(0, 2) }}>
      <RadioField {...getFieldProps('radio')} label=''>
        <RadioField.Radio value='today'>{t('Today')}</RadioField.Radio>
        <RadioField.Radio value='tomorrow'>{t('Tomorrow')}</RadioField.Radio>
        <RadioField.Radio value='monday'>{t('Next Monday')}</RadioField.Radio>
        <RadioField.Radio value='custom'>
          <form
            {...formProps}
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(1),
            }}
          >
            <DatePickerField {...getFieldProps('startDate')} label={t('Start Date')} />
            <DatePickerField {...getFieldProps('endDate')} label={t('End Date')} />
          </form>
        </RadioField.Radio>
      </RadioField>
    </div>
  );
};
