import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Button, ButtonBar } from '@frontend/design-system';
import { useTableFilter } from './table-filter-provider';

type TableFilterActionsProps = {
  onApply: () => void;
  onCancel?: () => void;
  trackingId?: string;
};

export const TableFilterActions = ({
  onCancel,
  onApply,
  trackingId = 'shrd-portal-flyout',
  ...rest
}: TableFilterActionsProps) => {
  const { popoverProps } = useTableFilter();
  const { t } = useTranslation('payments');

  const handleCancel = () => {
    popoverProps.close();
    if (typeof onCancel === 'function') {
      onCancel();
    }
  };

  const handleApply = () => {
    popoverProps.close();
    if (typeof onApply === 'function') {
      onApply();
    }
  };

  return (
    <ButtonBar
      css={css`
        padding: ${theme.spacing(2, 3, 3)};
      `}
      {...rest}
    >
      <Button variant='secondary' onClick={handleCancel} data-trackingid={`${trackingId}-btn-cancel`}>
        {t('Cancel')}
      </Button>
      <Button onClick={handleApply} data-trackingid={`${trackingId}-btn-apply`}>
        {t('Apply')}
      </Button>
    </ButtonBar>
  );
};
