import { Source } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/source_tenant.pb';
import { ScheduleQueries } from '@frontend/api-schedule';
import { SchedulerV3Queries } from '@frontend/api-scheduler-v3';
import { useQuery } from '@frontend/react-query-helpers';
import { SchemaIntegrationsService } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { SourceTenantListItem } from '../../views/Calendar/EventsPanel/ScheduleActions/ScheduleRequests/ScheduleRequestModal/types';

type ManageAppointmentWritebackArgs = {
  isEnabled?: boolean;
  locationId: string;
};

const getSourceTenantList = (sourceTenants: Source[] = []) => {
  return sourceTenants.reduce<SourceTenantListItem[]>((list, source) => {
    const sites = source.sites?.length
      ? source.sites.map((site) => ({
          parentSourceTenantId: source.sourceTenantId,
          sourceTenantId: site.sourceTenantId,
          sourceId: source.id,
          name: site.name,
          pmsName: source.pmsName,
        }))
      : [
          {
            parentSourceTenantId: source.sourceTenantId,
            sourceTenantId: source.sourceTenantId,
            sourceId: source.id,
            name: source.sourceName,
            pmsName: source.pmsName,
          },
        ];

    return list.concat(sites);
  }, []);
};

export const useManageAppointmentWriteback = ({ isEnabled = false, locationId }: ManageAppointmentWritebackArgs) => {
  const { selectedParentsIds } = useAppScopeStore();
  const sourceLocationId = selectedParentsIds?.[0] || locationId;

  const {
    mutateAsync: createAppointmentWriteback,
    isLoading: isLoadingCreateWritebackAppointment,
    isError: isCreateAppointmentWritebackError,
  } = SchedulerV3Queries.useAppointmentWriteback();

  const { data: dataSources, isLoading: isLoadingDataSources } = useQuery({
    queryKey: [locationId, 'data-sources'],
    queryFn: () => SchemaIntegrationsService.ListDataSourcesByLocationID({ locationId: sourceLocationId }),
    enabled: !!sourceLocationId && isEnabled,
  });

  const { data: sourceTenantsData, isLoading: isLoadingSourceTenants } = SchedulerV3Queries.useListSourceTenants({
    locationId: sourceLocationId,
    isEnabled: !!sourceLocationId,
  });

  const sourceTenantList = getSourceTenantList(sourceTenantsData?.sources);

  const { data: appointmentStatusData, isLoading: isLoadingAppointmentStatusData } =
    ScheduleQueries.useGetAppointmentStatuses(sourceLocationId);

  const checkWritebackCapability = (sourceTenantId: string) => {
    const sourceId = sourceTenantList.find((source) => source.sourceTenantId === sourceTenantId)?.sourceId;
    const dataSourceObj = dataSources?.dataSources?.find((dataSource) => dataSource.sourceId === sourceId);
    const hasWritebackCapability = dataSourceObj?.integration?.capabilities?.find(
      (capability) => capability.dataType === 'APPOINTMENT' && capability.operation === 'CREATE'
    );
    return !!hasWritebackCapability;
  };

  const getAppointmentExternalStatusForSourceTenant = (sourceTenantId: string) => {
    if (!sourceTenantId || !appointmentStatusData) return;

    const sourceId = sourceTenantList.find((source) => source.sourceTenantId === sourceTenantId)?.sourceId;
    const source = appointmentStatusData?.mappings?.find((mapping) => mapping.sourceId === sourceId);
    const confimedMappingObject = source?.mappings.find((sourceMap) => sourceMap.key === 'Confirmed');
    return confimedMappingObject?.value?.[0]?.value;
  };

  return {
    isLoadingDataSources,
    isLoadingSourceTenants,
    isLoadingCreateWritebackAppointment,
    isCreateAppointmentWritebackError,
    isLoadingAppointmentStatusData,
    sourceTenantList,
    createAppointmentWriteback,
    checkWritebackCapability,
    getAppointmentExternalStatusForSourceTenant,
  };
};
