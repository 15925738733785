import { css } from '@emotion/react';
import { Button, Page } from '@frontend/generic-dialpad-accessories';
import { Divider } from '@frontend/generic-dialpad-accessories';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Heading, Text, useAlert } from '@frontend/design-system';
import { CallInformation } from '../components/call-information';
import { PageHeaderWraps } from '../components/generic/page-header-wraps';
import { HeldCall } from '../components/hold/held-call';
import { useCallerInfo } from '../hooks/use-caller-info';
import { useSoftphoneCallState } from '../providers/softphone-call-state-provider';
import { useSoftphoneCallActions } from '../providers/softphone-call-state-provider/softphone-call-actions.provider';
import { useSoftphoneRouter } from '../providers/softphone-router-provider';
import { isEstablishedCall, isTerminatedCall } from '../types';

export const PageTransferHome = () => {
  const { t } = useTranslation('softphone');
  const primaryCall = useSoftphoneCallState((ctx) => ctx.primaryCall);
  const setPrimaryCall = useSoftphoneCallState((ctx) => ctx.setPrimaryCall);
  const { toggleHold } = useSoftphoneCallActions((ctx) => ctx);
  const currentTransfer = useSoftphoneCallState((ctx) => ctx.currentTransfer);
  const setCurrentRoute = useSoftphoneRouter((ctx) => ctx.setAttendedTransferRoute);
  const mergeCalls = useSoftphoneCallActions((ctx) => ctx.mergeCalls);
  const { confirmWarmTransfer } = useSoftphoneCallActions((ctx) => ctx);
  const alert = useAlert();

  const disabled = !primaryCall || !isEstablishedCall(primaryCall) || isTerminatedCall(primaryCall);
  const establishedCalls = useSoftphoneCallState((ctx) => ctx.establishedCalls);
  const callInfo = useCallerInfo({
    uri: primaryCall?.remoteParty.uri ?? '',
    displayName: primaryCall?.remoteParty.displayName,
  });

  const otherHeldCalls = establishedCalls.filter(
    (call) =>
      !!call.onHoldSince &&
      primaryCall?.id !== call.id &&
      call.id !== currentTransfer?.transferTarget?.id &&
      currentTransfer?.initialCall?.id !== call.id
  );
  const onHoldForTransfer =
    currentTransfer?.initialCall.id === primaryCall?.id
      ? currentTransfer?.transferTarget
      : currentTransfer?.initialCall;

  const swap = () => {
    if (!onHoldForTransfer || !isEstablishedCall(onHoldForTransfer)) {
      return;
    }
    if (!primaryCall || !isEstablishedCall(primaryCall)) {
      return;
    }

    toggleHold(primaryCall, true);
    toggleHold(onHoldForTransfer, false);
    setPrimaryCall(onHoldForTransfer.id);
  };

  if (!currentTransfer?.initialCall || !currentTransfer?.transferTarget) {
    return <Heading color='white'>{t('No Transfer Target')}</Heading>;
  }
  return (
    <Page>
      <PageHeaderWraps>
        <CallInformation
          hideCallDuration
          displayName={primaryCall?.remoteParty.displayName}
          uri={primaryCall?.remoteParty.uri ?? ''}
        />
      </PageHeaderWraps>
      <Page.Body>
        <ul
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            margin: ${theme.spacing(3, 0)};
            gap: ${theme.spacing(3)};
            padding: 0;
            list-style-type: none;
          `}
        >
          <li>
            <Button.Round
              disabled={disabled || !isEstablishedCall(currentTransfer?.transferTarget) || !!otherHeldCalls?.length}
              onClick={() => {
                if (isEstablishedCall(currentTransfer?.transferTarget)) {
                  mergeCalls([currentTransfer?.initialCall, currentTransfer?.transferTarget]);
                } else {
                  alert.error(t('Invalid transfer destination'));
                }
              }}
              size='medium'
              iconName={'merge'}
              label={t('Merge Calls')}
              trackingId='softphone-active-mergecalls'
            />
          </li>
          <li>
            <Button.Round
              disabled={disabled}
              onClick={() => setCurrentRoute('keypad')}
              size='medium'
              iconName={'dial'}
              label={t('Keypad')}
            ></Button.Round>
          </li>
          <li>
            <Button.Round
              disabled={disabled}
              onClick={() => {
                confirmWarmTransfer(callInfo.title);
              }}
              color='blue'
              size='medium'
              iconName={'call-forward'}
              label={t('Transfer')}
              trackingId='softphone-transfer-completetransfer'
            ></Button.Round>
          </li>
        </ul>
        {onHoldForTransfer && isEstablishedCall(onHoldForTransfer) && (
          <>
            <Divider />
            <Text
              color='white'
              css={css`
                margin-bottom: ${theme.spacing(1)};
              `}
            >
              {t('On Hold For Warm Transfer')}
            </Text>
            <HeldCall call={onHoldForTransfer} onClick={() => swap()}></HeldCall>
          </>
        )}
      </Page.Body>
      <Page.Footer>
        {!!otherHeldCalls.length && (
          <>
            <Divider />
            <Text
              css={css`
                margin-bottom: ${theme.spacing(1)};
              `}
              color='white'
            >
              {t('On Local Hold')}
            </Text>
            {otherHeldCalls.map((call) => (
              <HeldCall key={call.id} call={call} disabled={!!onHoldForTransfer}></HeldCall>
            ))}
          </>
        )}
      </Page.Footer>
    </Page>
  );
};
