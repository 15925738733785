import { FormPacket } from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';

interface CheckForChangesPayload {
  packetNameRef: string;
  packetName: string;
  formsInPacketRef: FormPacket[];
  formsInPacket: FormPacket[];
}

export function checkForUnsavedChanges({
  formsInPacket,
  formsInPacketRef,
  packetName,
  packetNameRef,
}: CheckForChangesPayload): boolean {
  // Check if the forms were added or removed
  if (formsInPacket.length !== formsInPacketRef.length) {
    return true;
  }

  // Check if the form order has changed
  for (let i = 0; i < formsInPacket.length; i++) {
    if (formsInPacket[i].id !== formsInPacketRef[i].id) {
      return true;
    }
  }

  // Check if the packet name has changed
  if (packetName.toLowerCase() !== packetNameRef.toLowerCase()) {
    return true;
  }

  return false;
}
