import { css } from '@emotion/react';
import { Icon, IconName } from '@frontend/icons';
import { useBreakpoint } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { NakedButton, PopoverMenu, usePopoverMenu } from '@frontend/design-system';
import { colorSelectionObject, iconsList } from './constant';
import { OperatoryAvatar } from './OperatoryAvatar';
import { OperatoryColorIconList } from './OperatoryColorIconList';
import { OperatoryIconList } from './OperatoryIconList';

export type OperatoryIconConfig = { iconName: IconName; color: string };

export type OperatoryIconSelectorState = OperatoryIconConfig & {
  isSelected: boolean;
};

type OperatoryIconSelectorProps = {
  operatoryIconConfig: OperatoryIconConfig;
  onOperatoryIconChange: (iconConfig: OperatoryIconConfig) => void;
};

export const OperatoryIconSelector = ({ operatoryIconConfig, onOperatoryIconChange }: OperatoryIconSelectorProps) => {
  const size = useBreakpoint();
  const isLargeDevice = size === 'large';

  const iconColor = colorSelectionObject.find((color) => color.color === operatoryIconConfig.color)?.color;

  const { getMenuProps, getTriggerProps, isOpen } = usePopoverMenu({
    placement: 'bottom-start',
  });

  const handleIconColorSelection = (config: Omit<OperatoryIconSelectorState, 'iconName'>) => {
    onOperatoryIconChange({
      ...operatoryIconConfig,
      color: config.color,
    });
  };

  const handleIconSelection = (config: OperatoryIconSelectorState) => {
    onOperatoryIconChange({
      ...operatoryIconConfig,
      iconName: config.iconName,
    });
  };

  return (
    <div css={{ marginTop: theme.spacing(0.5), maxWidth: theme.spacing(10) }}>
      <NakedButton {...getTriggerProps()} css={buttonStyles}>
        <OperatoryAvatar
          iconName={operatoryIconConfig.iconName}
          color={operatoryIconConfig.color}
          height={28}
          width={30}
        />
        <Icon color='light' name={isOpen ? 'caret-up-small' : 'caret-down-small'} />
      </NakedButton>
      <PopoverMenu
        css={{ maxWidth: theme.spacing(isLargeDevice ? 45 : 31), padding: theme.spacing(2) }}
        {...getMenuProps()}
      >
        <div css={iconColorListContainer}>
          {colorSelectionObject.map(({ color, selectionColor }) => (
            <OperatoryColorIconList
              key={color}
              selectionColor={selectionColor}
              color={color}
              isSelected={iconColor === color}
              onClick={handleIconColorSelection}
            />
          ))}
        </div>
        <div css={iconListContainer}>
          {iconsList.map((iconName) => (
            <OperatoryIconList
              key={iconName}
              iconName={iconName}
              color={operatoryIconConfig.color}
              isSelected={operatoryIconConfig.iconName === iconName}
              onClick={handleIconSelection}
            />
          ))}
        </div>
      </PopoverMenu>
    </div>
  );
};

const iconColorListContainer = css({
  display: 'flex',
  flexWrap: 'wrap',
  borderBottom: `1px solid ${theme.colors.neutral20}`,
  paddingBottom: theme.spacing(1),
});

const iconListContainer = css({
  display: 'flex',
  flexWrap: 'wrap',
  paddingTop: theme.spacing(1),
});

const buttonStyles = css`
  align-items: center;
  border-radius: ${theme.borderRadius.small};
  border: 1px solid ${theme.colors.neutral30};
  display: flex;
  gap: ${theme.spacing(0.5)};
  justify-content: space-between;
  padding: ${theme.spacing(0.6, 1)};
  width: 100%;

  &:hover {
    background-color: ${theme.colors.neutral5};
  }
`;
