import { Sort_Enum } from '@weave/schema-gen-ts/dist/schemas/sms/draft/v1/draft_service.pb';
import { UseMutationOptions, useQueryClient } from 'react-query';
import { useMutation } from '@frontend/react-query-helpers';
import { queryKeys } from './queries';
import { SchemaSMSDraftService } from './service';
import { DeleteIO, ListDraftsCountIO, ListDraftsIO, UpsertIO } from './types';

export const mutationKeys = {
  baseKey: ['sms-draft'],
  upsertDraft: () => [...mutationKeys.baseKey, 'upsert'],
  deleteDraft: () => [...mutationKeys.baseKey, 'delete'],
};

type MutationContext<C = unknown> = {
  myContext?: string;
  optionContext?: C;
};

export type UseUpsertDraftMutationOptions<E = unknown, C = unknown> = UseMutationOptions<
  UpsertIO['output'],
  E,
  UpsertIO['input'],
  C | undefined
>;
export const useUpsertDraftMutation = <E = unknown, C = unknown>({
  onMutate,
  onSuccess,
  onError,
  onSettled,
  ...options
}: UseUpsertDraftMutationOptions<E, C> = {}) => {
  const queryClient = useQueryClient();

  return useMutation<UpsertIO['output'], E, UpsertIO['input'], MutationContext<C>>({
    mutationKey: mutationKeys.upsertDraft(),
    mutationFn: (req) => SchemaSMSDraftService.Upsert(req),
    ...options,
    onMutate: async (req) => {
      return {
        myContext: '',
        optionContext: await onMutate?.(req),
      };
    },
    onSuccess: (res, req, context) => {
      // Update GetDraft cache
      const getDraftKey = queryKeys.getDraft({
        orgId: req.orgId,
        threadId: req.threadId,
        userId: req.userId,
      });
      queryClient.setQueriesData(
        {
          queryKey: getDraftKey,
          exact: false,
          predicate: ({ queryKey }) => {
            if (Array.isArray(queryKey)) {
              return queryKey.some((item) => typeof item === 'object' && item['threadId'] === res.threadId);
            }
            return false;
          },
        },
        { draftId: res.draftId, draft: res.draft }
      );

      const listDraftsKey = queryKeys.listDrafts({
        orgId: req.orgId,
        userId: req.userId,
      });

      // get drafts from queryClient
      const existingDrafts = queryClient.getQueriesData<ListDraftsIO['output']>({
        exact: false,
        queryKey: listDraftsKey,
      });
      const threadHasDraft = existingDrafts?.reduce(
        (acc, [, oldDrafts]) => acc || oldDrafts?.drafts.some((draft) => draft.threadId === req.threadId),
        false
      );

      // update drafts count if it's a new draft
      if (!threadHasDraft) {
        const listDraftsCountKey = queryKeys.listDraftsCount({
          orgId: req.orgId,
          userId: req.userId,
        });
        queryClient.setQueriesData<ListDraftsCountIO['output']>(
          { exact: false, queryKey: listDraftsCountKey },
          (oldData) => ({
            count: (oldData?.count ?? 0) + 1,
          })
        );
      }

      // Helper function to update ListDrafts cache
      const updateListDraftsCache = (sortOrder?: Sort_Enum) => {
        const listDraftKey = queryKeys.listDrafts({
          orgId: req.orgId,
          userId: req.userId,
          sort: sortOrder,
        });

        queryClient.setQueriesData<ListDraftsIO['output'] | undefined>(
          {
            exact: false,
            queryKey: listDraftKey,
          },
          (oldData: ListDraftsIO['output'] | undefined) => {
            if (!oldData) return undefined;
            const newDraft: ListDraftsIO['output']['drafts'][number] = {
              ...res,
              threadId: req.threadId,
            };

            const oldList = oldData?.drafts.filter((draft) => draft.threadId !== req.threadId);

            return {
              ...oldData,
              drafts: sortOrder === Sort_Enum.ASC ? [...oldList, newDraft] : [newDraft, ...oldList],
            };
          }
        );
      };

      // Update ListDrafts cache for ascending sort
      updateListDraftsCache(Sort_Enum.ASC);
      // Update ListDrafts cache for descending sort
      updateListDraftsCache(Sort_Enum.DESC);
      // Update ListDrafts cache for default sort
      updateListDraftsCache();

      // Honor provided onSuccess
      onSuccess?.(res, req, context?.optionContext);
    },
    onError: (error, req, context) => onError?.(error, req, context?.optionContext),
    onSettled: (res, error, req, context) => onSettled?.(res, error, req, context?.optionContext),
  });
};

export type UseDeleteDraftMutationOptions<E = unknown, C = unknown> = UseMutationOptions<
  DeleteIO['output'],
  E,
  DeleteIO['input'],
  C
>;
export const useDeleteDraftMutation = <E = unknown, C = unknown>(options?: UseDeleteDraftMutationOptions<E, C>) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: mutationKeys.deleteDraft(),
    mutationFn: (req) => SchemaSMSDraftService.Delete(req),
    onMutate: async (req) => {
      return options?.onMutate?.(req);
    },
    onSuccess: (res, req, ...rest) => {
      // Update GetDraft cache
      const getDraftKey = queryKeys.getDraft({
        orgId: req.orgId,
        threadId: req.threadId,
        userId: req.userId,
      });
      queryClient.invalidateQueries({ queryKey: getDraftKey, exact: false });

      // Update ListDrafts cache
      const listDraftsKey = queryKeys.listDrafts({
        orgId: req.orgId,
        userId: req.userId,
      });
      queryClient.setQueriesData<ListDraftsIO['output'] | undefined>(
        {
          exact: false,
          queryKey: listDraftsKey,
        },
        (oldData: ListDraftsIO['output'] | undefined) => {
          if (!oldData) return undefined;
          return {
            ...oldData,
            drafts: oldData?.drafts.filter(({ threadId }) => threadId !== req.threadId) ?? [],
          };
        }
      );

      const listDraftsCountKey = queryKeys.listDraftsCount({
        orgId: req.orgId,
        userId: req.userId,
      });
      queryClient.setQueriesData<ListDraftsCountIO['output']>(
        { exact: false, queryKey: listDraftsCountKey },
        (oldData) => ({
          count: (oldData?.count ?? 1) - 1,
        })
      );

      // Honor provided onSuccess
      options?.onSuccess?.(res, req, ...rest);
    },
    ...options,
  });
};
