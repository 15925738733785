import { PersonHelpers, PersonTypes } from '@frontend/api-person';
import { PhoneSyncApi, useQueryDevices } from '@frontend/api-phone-sync';
import { useTranslation } from '@frontend/i18n';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import {
  DeviceSelectModal,
  PhoneSyncButton,
  PhoneSyncModal,
  usePhonePairSuccessHandler,
  usePhoneSyncControl,
} from '@frontend/phone-sync';
import { useAppScopeStore } from '@frontend/scope';
import { Modal, ModalControlResponse, SpinningLoader } from '@frontend/design-system';
import { ContactNumberSelection } from './contact-number-selection';
import { NoDevicePairedModal } from './no-device-paired';

type ClickToCallSelectionProps = {
  person: PersonTypes.Person | undefined;
  personNumber: string;
  modal: ModalControlResponse;
  isLoading: boolean;
  isSpecificOutboundProvided: boolean;
};

export const ClickToCallSelection = ({
  person,
  personNumber,
  modal,
  isLoading,
  isSpecificOutboundProvided,
}: ClickToCallSelectionProps) => {
  const multipleContactNumbers = PersonHelpers.getAllPhoneNumbers(person) ?? [];
  const requiresContactNumberSelection = !personNumber && !!person && !(multipleContactNumbers.length === 1);

  const { t } = useTranslation('phone-call');
  const { selectedLocationIds } = useAppScopeStore();
  const { phoneConfig } = usePhoneConfigShallowStore('phoneConfig', 'setPhoneConfig');

  const { modalProps, buttonProps } = usePhoneSyncControl();
  const { data: devices } = useQueryDevices(selectedLocationIds);

  const availableDevices = devices?.filter((device) => device.availableInContext) ?? [];
  const onSyncSuccess = usePhonePairSuccessHandler()(selectedLocationIds);

  return (
    <div>
      {isLoading ? (
        <Modal {...modal.modalProps} maxWidth={400}>
          <div css={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <SpinningLoader />
          </div>
        </Modal>
      ) : (
        <>
          {!phoneConfig && availableDevices && availableDevices.length > 0 && (
            <>
              <DeviceSelectModal
                variant='click-to-call'
                deviceSelectModal={modal}
                closeModal={modal.closeModal}
                locationIds={selectedLocationIds}
                pairDeviceButton={
                  <PhoneSyncButton {...buttonProps} variant='secondary' onClick={modal.modalProps.onClose} />
                }
              />
              <PhoneSyncModal
                {...modalProps}
                locationIds={selectedLocationIds}
                onSyncSuccess={onSyncSuccess}
                syncFn={PhoneSyncApi.pairDevice}
              />
            </>
          )}

          {!phoneConfig && availableDevices?.length === 0 && (
            <>
              <NoDevicePairedModal
                modalProps={modal.modalProps}
                pairDeviceButton={
                  <PhoneSyncButton {...buttonProps} btnLabel={t('Pair Phone')} onClick={modal.modalProps.onClose} />
                }
              />
              <PhoneSyncModal
                {...modalProps}
                locationIds={selectedLocationIds}
                onSyncSuccess={onSyncSuccess}
                syncFn={PhoneSyncApi.pairDevice}
              />
            </>
          )}
          {!isSpecificOutboundProvided && !!phoneConfig && requiresContactNumberSelection && (
            <ContactNumberSelection person={person} modalProps={modal.modalProps} />
          )}
        </>
      )}
    </div>
  );
};
