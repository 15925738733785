import { PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { demoDataUtils } from '../../../utils';

const procedures = [
  {
    adaCode: 'D1234',
    date: demoDataUtils.generateRandomDate(true),
    procedureAmount: 462,
  },
  {
    adaCode: 'D8753',
    date: demoDataUtils.generateRandomDate(true),
    procedureAmount: 122,
  },
  {
    adaCode: 'D4342',
    date: demoDataUtils.generateRandomDate(true),
    procedureAmount: 753,
  },
  {
    adaCode: 'D7472',
    date: demoDataUtils.generateRandomDate(true),
    procedureAmount: 246,
  },
  {
    adaCode: 'D1768',
    date: demoDataUtils.generateRandomDate(true),
    procedureAmount: 422,
  },
  {
    adaCode: 'D9624',
    date: demoDataUtils.generateRandomDate(true),
    procedureAmount: 67,
  },
  {
    adaCode: 'D4437',
    date: demoDataUtils.generateRandomDate(true),
    procedureAmount: 287,
  },
  {
    adaCode: 'D0983',
    date: demoDataUtils.generateRandomDate(true),
    procedureAmount: 844,
  },
  {
    adaCode: 'D1972',
    date: demoDataUtils.generateRandomDate(true),
    procedureAmount: 645,
  },
  {
    adaCode: 'D2221',
    date: demoDataUtils.generateRandomDate(true),
    procedureAmount: 129,
  },
];

export const getMockProcedures = (setUnscheduled?: boolean): PracticeAnalyticsTypes.Procedure[] => {
  const randomProcedures = Array.from({ length: demoDataUtils.generateRandomInt(1, 5) }, () =>
    demoDataUtils.generateRandomInt(0, procedures.length - 1)
  );
  const mockProcedures = setUnscheduled
    ? randomProcedures.map((idx) => {
        // Remove date from the procedure
        return {
          ...procedures[idx],
          date: undefined,
        };
      })
    : randomProcedures.map((idx) => procedures[idx]);

  return mockProcedures;
};
