import { FC } from 'react';
import { FormsSubmission } from '@frontend/api-forms';
import { Icon } from '@frontend/icons';
import { Text, IconButton, SpinningLoader, useTooltip } from '@frontend/design-system';
import { pendoTags } from '../../../../../../shared/constants';
import { useFormAttachment } from '../../../../hooks/useFormAttachment';
import {
  attachmentIconAndNameContainerStyle,
  attachmentListItemStyle,
  attachmentNameStyle,
  downloadIconButtonStyle,
  attachmentIconStyle,
} from './attachment-item.styles';

interface AttachmentItemProps {
  attachment: FormsSubmission.Types.AttachmentData;
  onSelectAttachment: (attachment: FormsSubmission.Types.AttachmentData) => void;
}

const AttachmentItem: FC<React.PropsWithChildren<AttachmentItemProps>> = ({ attachment, onSelectAttachment }) => {
  const { isLoading, downloadImageAs } = useFormAttachment(attachment.id, false);

  const { triggerProps, tooltipProps: labelProps, Tooltip: HoverLabel } = useTooltip({ placement: 'top' });

  function getFileName() {
    const suffix = attachment.side ? `(${attachment.side})` : '';
    let name = 'Attachment';

    if (attachment.id) {
      name = attachment.id;
    }

    return `${name} ${suffix}`.trim();
  }

  function downloadHandler() {
    downloadImageAs(getFileName());
  }

  return (
    <div key={attachment.id} css={attachmentListItemStyle} data-trackingid={pendoTags.viewAttachmentsPreview}>
      <div css={attachmentIconAndNameContainerStyle} onClick={() => onSelectAttachment(attachment)}>
        <Icon name='attachment' css={attachmentIconStyle} />

        <Text size='medium' css={attachmentNameStyle}>
          {`${attachment.name || ''} ${attachment.side ? `(${attachment.side})` : ''}`}
        </Text>
      </div>

      {isLoading ? (
        <SpinningLoader size='small' />
      ) : (
        <>
          <IconButton label='Download' {...triggerProps} onClick={downloadHandler} css={downloadIconButtonStyle}>
            <Icon name='download' />
          </IconButton>

          <HoverLabel {...labelProps}>Download Attachment</HoverLabel>
        </>
      )}
    </div>
  );
};

export default AttachmentItem;
