import { useMemo } from 'react';
import { NotificationType } from '@weave/schema-gen-ts/dist/shared/notification/notifications.pb';
import { authnClient } from '@frontend/auth';
import { getInitialParams } from '@frontend/env';
import { http } from '@frontend/fetch';
import { useAppScopeStore } from '@frontend/scope';
import { WebsocketEventPayload, useWebSocket, useWebsocketEventPublisher } from '@frontend/websocket';

export const useWeaveWebsocketConnection = () => {
  const publisher = useWebsocketEventPublisher();
  const { selectedLocationIds } = useAppScopeStore();
  const { websocketApi } = getInitialParams();

  const onMessage = useMemo(
    () => (payload: WebsocketEventPayload) => {
      // As soon as we've gotten all feature teams to publish and consume all notification with the schema defined notification types,
      //     we can remove the switch statement and replace it with:
      // if (payload.method in NotificationType) {
      //   // If the method is a known notification type, emit it as an event
      //   return publisher.emit(payload.method, payload);
      // }
      // // Otherwise, log a warning and return
      // console.warn('Received an unregistered websocket event', payload);
      // return payload;

      switch (payload.method) {
        case 'FaxNotification':
        case 'FollowupNotification':
        case 'FormsNotification':
        case 'NotificationAPI':
        case 'PhoneSystemEventsLegacy':
        case 'PhoneSystemEventsV2':
        case 'PhoneCallQueueEvents':
        case 'ReviewsNotification':
        case 'RunNetworkAudit':
        case 'RunPendoGuide':
        case 'SMSConsentResponse':
        case 'SMSNotification':
        case 'ScheduledSMSNotification':
        case 'CreateTagNotification':
        case 'DeleteTagNotification':
        case 'UpdateTagNotification':
        case 'WeaveAnnouncements':
        case 'CHAT_MESSAGE':
        case NotificationType.CALL_QUEUES: // These are all the notification types defined in schema
        case NotificationType.CHAT_MESSAGE:
        case NotificationType.FAX:
        case NotificationType.FOLLOW_UP:
        case NotificationType.FORMS:
        case NotificationType.PHONE_CALL:
        case NotificationType.PHONE_CALL_V2:
        case NotificationType.REVIEWS:
        case NotificationType.SCHEDULE_REQUEST:
        case NotificationType.SMS:
        case NotificationType.SMS_TAG:
        case NotificationType.SMS_TAG_V2:
        case NotificationType.VOICEMAIL_TRANSCRIPTION:
        case NotificationType.PAYMENTS:
        case NotificationType.MANUAL_MESSAGE_CONSENT:
          return publisher.emit(payload.method, payload);
        default: {
          //ensure all cases are handled. If we miss one, this ':never' here will trigger a ts error
          console.warn('Received an unregistered websocket event', payload);
          const _exhaustive: never = payload;
          return _exhaustive;
        }
      }
    },
    [publisher]
  );

  return useWebSocket({
    onMessage,
    selectedLocationIds,
    refreshAuthToken: async () => {
      const token = await authnClient.refreshWeaveToken();
      http.setAuthorizationHeader(token);
      return token;
    },
    websocketApi,
  });
};
