import { useMemo } from 'react';
import dayjs from 'dayjs';
import { AnalyticsCommonApi } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { queryKeys } from '../../../query-keys';
import { usePracticeAnalyticsShallowStore } from './use-practice-analytics-store';

type Request = {
  endTime?: string;
  patientIds: string[];
  startTime?: string;
};

type UsePatientsLastContacted = {
  isLoading: boolean;
  lastContactedDates: Record<string, string>;
};

export const usePatientsLastContacted = ({
  endTime,
  patientIds = [],
  startTime,
}: Request): UsePatientsLastContacted => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const { filters, isDemoAccount } = usePracticeAnalyticsShallowStore('filters', 'isDemoAccount');

  const queryKey = useMemo(() => {
    const sortedPatientIds = patientIds.sort().join('-');

    return queryKeys.practiceAnalyticsTables(
      `patients-last-contacted-${sortedPatientIds}-${startTime ?? ''}-${endTime ?? ''}-${isDemoAccount ?? ''}`
    );
  }, [endTime, isDemoAccount, patientIds, startTime]);

  const { data, isLoading } = useScopedQuery({
    queryKey,
    queryFn: () =>
      AnalyticsCommonApi.getLastContactedDates({
        end_time: dayjs(endTime ?? filters.endDate).format('YYYY-MM-DDT23:59:59.999'),
        person_id: patientIds,
        start_time: dayjs(startTime ?? filters.startDate).format('YYYY-MM-DDT00:00:00.000'),
      }),
    onError: () => {
      alert.error(t("Couldn't load the patient's last contacted dates. Please try again."));
    },
    enabled: !isDemoAccount && !!patientIds.length,
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const lastContactedDates = useMemo(
    () =>
      data?.reduce((acc, dataObject) => {
        const { last_contacted, person_id } = dataObject || {};
        return !person_id ? acc : { ...acc, [person_id]: last_contacted || '' };
      }, {}) ?? [],
    [data]
  );

  const demoDates = useMemo(() => {
    // Generate random dates within last 30 days
    const randomDate = () =>
      dayjs()
        .subtract(Math.floor(Math.random() * 30), 'day')
        .format('YYYY-MM-DD');
    return patientIds.reduce((acc, id) => ({ ...acc, [id]: randomDate() }), {});
  }, [isDemoAccount, patientIds.length]);

  return {
    isLoading,
    lastContactedDates: isDemoAccount ? demoDates : lastContactedDates,
  };
};
