import { useQueryClient, useMutation } from 'react-query';
import PhoneTreeAPI from '@frontend/api-phone-trees';
import { queryKeys } from '../../query-keys';
import { PhoneTreeCreatePayload, PhoneTreeDialOptionState, PhoneTreeUpdateState } from './types';

export const usePhoneTreeMutations = (phoneTenantId: string) => {
  const queryClient = useQueryClient();

  const create = useMutation({
    mutationFn: (payload: PhoneTreeCreatePayload) => {
      return PhoneTreeAPI.Create(payload);
    },
    onSettled: () => {
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.listPhoneTrees()]);
    },
  });

  const update = useMutation({
    mutationFn: (payload: PhoneTreeUpdateState) => {
      return PhoneTreeAPI.Update(payload);
    },
    onSettled: (_data, _err, payload) => {
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.listPhoneTrees()]);
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.readPhoneTree(payload.phoneTreeId)]);
    },
  });

  const updateDialOptions = useMutation({
    mutationFn: (payload: PhoneTreeDialOptionState) => {
      return PhoneTreeAPI.UpdateDialOptions(payload);
    },
    onSettled: (_data, _err, payload) => {
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.listPhoneTrees()]);
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.readPhoneTree(payload.phoneTreeId)]);
    },
  });

  const remove = useMutation({
    mutationFn: (payload: { phoneTreeId: string }) => {
      return PhoneTreeAPI.Delete(payload);
    },
    onSettled: (_data, _err, payload) => {
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.listPhoneTrees()]);
      queryClient.invalidateQueries([phoneTenantId, ...queryKeys.settings.readPhoneTree(payload.phoneTreeId)]);
    },
  });

  return {
    create,
    update,
    updateDialOptions,
    remove,
  };
};
