import { useCallback } from 'react';
import { useMutation } from 'react-query';
import { FormsMessaging } from '@frontend/api-forms';
import { useAlert } from '@frontend/design-system';
import { useSendFormsStore, useSendFormsContext } from '../providers';
import { convertToHTML } from './utils/emailHelper';

export interface useSendDocumentResult {
  sendDocument: () => void;
}

export const useSendDocument = (): useSendDocumentResult => {
  const { email, emailSubject, emailBody, firstName, clearStore } = useSendFormsStore([
    'email',
    'emailSubject',
    'emailBody',
    'firstName',
    'clearStore',
  ]);
  const { sendFormsModalControls, origin } = useSendFormsContext();
  const alert = useAlert();

  const { mutateAsync: _sendEmail } = useMutation(FormsMessaging.API.sendEmail, {
    onSuccess: () => {
      alert.success(`Forms sent to ${firstName} successfully`);
      sendFormsModalControls.closeModal();
      clearStore(origin);
    },
    onError: (error) => {
      console.error('Failed to send email', error);
      alert.error('Failed to send email');
    },
  });

  const sendDocument = useCallback(() => {
    if (email !== '') {
      _sendEmail({
        recipient: email,
        subject: emailSubject,
        html: convertToHTML(emailBody),
      });
    }
  }, [email, emailSubject, emailBody, _sendEmail]);

  return { sendDocument };
};
