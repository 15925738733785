import { Span, Event } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-call/v1/call_pops.pb';
import { PhoneCallsService } from '@frontend/schema';
import { spanNameGenerators } from './span-identifiers';
import { Trace, TempoTracingOptions } from './types';

const parentSpanNameKey = 'parentSpanName';
const defaultTempoTracingOptions: TempoTracingOptions = {
  parentSpanName: undefined,
  spanAttributes: [],
  timeoutInSeconds: 120,
  endTraceOnTimeout: false,
  timeoutEvent: undefined,
};

const findLastFinishTimestampFromChildren = (childSpans: Span[], defaultTimestamp: string) => {
  if (!childSpans || childSpans.length === 0) {
    return defaultTimestamp;
  }
  let lastFinishTimestamp: null | string = null;
  childSpans.forEach((childSpan) => {
    if (!childSpan.finishTimestamp) {
      return;
    }
    if (!lastFinishTimestamp) {
      lastFinishTimestamp = childSpan.finishTimestamp;
      return;
    }
    if (childSpan.finishTimestamp > lastFinishTimestamp) {
      lastFinishTimestamp = childSpan.finishTimestamp;
    }
  });
  if (!!lastFinishTimestamp) {
    return lastFinishTimestamp;
  }
  return defaultTimestamp;
};

class TempoTracing {
  static #instance: TempoTracing;
  constructor() {
    if (!!TempoTracing.#instance) {
      return TempoTracing.#instance;
    }
    TempoTracing.#instance = this;
  }

  spanNameGenerators = spanNameGenerators;

  #activeTraces = new Map<string, Trace>();

  setupTimeout(traceId: string, timeoutInSeconds: number) {
    return setTimeout(() => {
      const trace = this.#activeTraces.get(traceId);
      if (!trace) {
        return;
      }
      // Optionally end the trace and send along the span information so far
      if (trace.endTraceOnTimeout) {
        if (trace.timeoutEvents.length > 0) {
          trace.timeoutEvents.forEach((spanEvent) => {
            this.addEvent(traceId, spanEvent.spanName, spanEvent.event);
          });
        }
        this.endTrace(traceId);
      }
      // Here's the most important piece - the clean up
      this.#activeTraces.delete(traceId);
    }, timeoutInSeconds * 1000);
  }

  /**
   * This puts in place a trace that all spans can be tracked under.
   * It can safely be called multiple times for the same trace and will add new spans to the existing trace.
   * Include a parentSpanName to indicate that this span is a child of another span.
   **/
  continueTrace(traceId: string, spanName: string, options?: TempoTracingOptions) {
    const { parentSpanName, timeoutInSeconds, endTraceOnTimeout, timeoutEvent } = {
      ...defaultTempoTracingOptions,
      ...options,
    };
    let { spanAttributes } = { ...defaultTempoTracingOptions, ...options };
    if (!spanAttributes) {
      spanAttributes = [];
    }
    if (!!parentSpanName) {
      if (!spanAttributes.find((attr) => attr.key === parentSpanNameKey)) {
        spanAttributes.push({ key: parentSpanNameKey, value: parentSpanName });
      }
    }

    // If the trace already exists, look it up and add a new span to it
    const existingTrace = this.#activeTraces.get(traceId);
    if (!!existingTrace) {
      this.addSpan(traceId, { name: spanName, startTimestamp: new Date().toISOString(), events: [], spanAttributes });
      // Add to the timeout conditions: whether to end the trace or not and add any new events
      existingTrace.endTraceOnTimeout = existingTrace.endTraceOnTimeout || endTraceOnTimeout || false;
      if (timeoutEvent) {
        existingTrace.timeoutEvents.push({ spanName, event: timeoutEvent });
      }
      // If there's already a timeout timer in place, leave it, otherwise we need to set it up
      if (!!timeoutInSeconds && !existingTrace.timeoutId) {
        existingTrace.timeoutId = this.setupTimeout(traceId, timeoutInSeconds);
      }
      return;
    }

    // Otherwise create a new trace (no existing trace)
    const newTrace = {
      id: traceId,
      spans: [],
      timeoutEvents: [],
      endTraceOnTimeout: endTraceOnTimeout || false,
    } as Trace;
    this.#activeTraces.set(traceId, newTrace);
    this.addSpan(traceId, { name: spanName, startTimestamp: new Date().toISOString(), events: [], spanAttributes });

    // Make sure we clean up any and all traces that were started but were possibly abandoned
    if (!!timeoutInSeconds) {
      newTrace.timeoutId = this.setupTimeout(traceId, timeoutInSeconds);
    }
  }

  /**
   * Use this when you are unable to ensure child spans have built setup before the closing parent span.
   * One use-case is the WS events, where they are emitted without waiting for handlers to finish.
   * @param traceId
   * @param spanName
   * @param delayInSeconds
   * @param finishTimestamp
   */
  endSpanWithDelaySkipChildren(traceId: string, spanName: string, delayInSeconds: number, finishTimestamp?: string) {
    if (!finishTimestamp) {
      finishTimestamp = new Date().toISOString();
    }
    setTimeout(() => {
      // End this span, but don't end child spans
      this.endSpan(traceId, spanName, finishTimestamp, true);
    }, delayInSeconds * 1000);
  }

  /**
   * Some parent spans may be waiting on other sibling spans to finish, so we should only update parents that have already finished.
   * @param trace
   * @param originSpan
   * @param finishTimestamp
   * @returns
   */
  updateParentFinishTimes(trace: Trace, originSpan: Span, finishTimestamp: string) {
    let currentSpan: undefined | Span = originSpan;
    // We only want to update the finish times on parent spans that have already finished
    while (!!currentSpan && !!currentSpan.finishTimestamp) {
      if (currentSpan.finishTimestamp < finishTimestamp) {
        currentSpan.finishTimestamp = finishTimestamp;
      }
      const parentSpanName: string =
        currentSpan.spanAttributes?.find((attr) => attr.key === parentSpanNameKey)?.value || '';
      if (!parentSpanName) {
        return;
      }
      currentSpan = trace.spans.find((span) => span.name === parentSpanName);
    }
  }

  endSpan(traceId: string, spanName: string, finishTimestamp?: string, skipEndChildSpans?: boolean) {
    const trace = this.#activeTraces.get(traceId);
    if (!trace) {
      return;
    }

    const span = trace.spans.find((span) => span.name === spanName);
    if (!span) {
      return;
    }

    if (!finishTimestamp) {
      finishTimestamp = new Date().toISOString();
    }
    span.finishTimestamp = finishTimestamp;

    // recursively update parent spans with latest finish timestamp if they are already finished
    this.updateParentFinishTimes(trace, span, finishTimestamp);

    // recursively end all child spans (but don't recurse back upwards), and ensure if this is a parent span, it correctly has the last finish time of all its children
    const childSpans = trace.spans.filter(
      (childSpan) => childSpan.spanAttributes?.find((attr) => attr.key === parentSpanNameKey)?.value === spanName
    );
    // If this is a parent span, it should have the last finish time of all its children
    span.finishTimestamp = findLastFinishTimestampFromChildren(childSpans, span.finishTimestamp);

    // End all child spans (unless skip is indicated)
    if (!skipEndChildSpans) {
      childSpans.forEach((childSpan) => {
        if (!childSpan.finishTimestamp) {
          this.endSpan(traceId, childSpan.name, span.finishTimestamp);
        }
      });
    }

    // if all spans are finished, automatically end the trace
    if (trace.spans.every((span) => !!span.finishTimestamp)) {
      this.endTrace(traceId);
    }
  }

  endTrace(traceId: string) {
    const trace = this.#activeTraces.get(traceId);
    if (!trace) {
      return;
    }
    // Delete it immediately to prevent duplicate "endTrace" calls
    this.#activeTraces.delete(traceId);
    if (trace.timeoutId) {
      clearTimeout(trace.timeoutId);
    }

    // Double check that all spans are finished, if not, set finish time to now
    trace.spans.forEach((span) => {
      if (!span.finishTimestamp) {
        span.finishTimestamp = new Date().toISOString();
      }
    });

    PhoneCallsService.CollectSpans({ traceId, spans: trace.spans });
  }

  addSpan(traceId: string, span: Span) {
    const trace = this.#activeTraces.get(traceId);
    if (!trace) {
      return;
    }

    const existingSpan = trace.spans.find((s) => s.name === span.name);
    if (!existingSpan) {
      trace.spans.push(span);
    } else {
      span.events.forEach((e) => existingSpan.events.push(e));
      span.spanAttributes?.forEach((sa) => existingSpan.spanAttributes?.push(sa));
    }
  }

  addEvent(traceId: string, spanName: string, event: Event) {
    const trace = this.#activeTraces.get(traceId);
    if (!trace) {
      return;
    }

    const span = trace.spans.find((span) => span.name === spanName);
    if (!span) {
      return;
    }

    if (!event.timestamp) {
      event.timestamp = new Date().toISOString();
    }

    span.events.push(event);
  }
}

const TempoTracingLib = new TempoTracing();
Object.freeze(TempoTracingLib);
export default TempoTracingLib;
