import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { BusinessInfoTypes } from '@frontend/api-business-information';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  ButtonBar,
  CheckboxField,
  ErrorBadgeIcon,
  FormFieldActionTypes,
  Modal,
  ModalControlModalProps,
  PrimaryButton,
  RadioField,
  SecondaryButton,
  Text,
  TextButton,
  TextLink,
  useForm,
  useFormField,
} from '@frontend/design-system';

type EinSuggestionModalProps = ModalControlModalProps & {
  searchResults: BusinessInfoTypes.EINSearchRecord[];
  isReverseSearch: boolean;
  onConfirm: (selectedEinSuggestion: BusinessInfoTypes.EINSearchRecord) => void;
  onContinue: () => void;
  hasLocationWritePermission?: boolean;
  isNewBrand?: boolean;
};

export const EinSuggestionModal = ({
  searchResults,
  isReverseSearch,
  onConfirm,
  onContinue,
  show,
  hasLocationWritePermission,
  isNewBrand,
  ...rest
}: EinSuggestionModalProps) => {
  const { t } = useTranslation('business-info');
  const modalCheckboxProps = useFormField({ type: 'checkbox' });
  const [showContactSupport, setShowContactSupport] = useState(false);
  const { getFieldProps, reset } = useForm({
    fields: {
      searchResultsMatchRadio: {
        type: 'radio',
        required: true,
      },
    },
    fieldStateReducer: (state, action) => {
      if (action.type === FormFieldActionTypes.Update) {
        return {
          ...state,
          searchResultsMatchRadio: {
            ...state.searchResultsMatchRadio,
            value: action.payload.value,
          },
        };
      }
      return null;
    },
  });
  const hasMultipleMatches = searchResults.length > 1;
  const subtitleString = hasMultipleMatches
    ? t(
        'The legal business name and address you entered do not match any public EIN record. Please select a suggestion below to proceed:'
      )
    : t(
        'The legal business name and address you entered do not match any public EIN record. Would you like to proceed with our suggestion?'
      );

  const getFormattedSearchResult = (resultItem: BusinessInfoTypes.EINSearchRecord, centered?: boolean) => {
    const lastLineStr =
      (resultItem.city ? resultItem.city + ', ' : '') +
      (resultItem.state ? resultItem.state + '. ' : '') +
      (resultItem.zipCode
        ? resultItem.zipCode.length > 5
          ? resultItem.zipCode.slice(0, 5) + '-' + resultItem.zipCode.slice(5)
          : resultItem.zipCode
        : '');

    return (
      <div
        css={
          centered &&
          css`
            text-align: center;
          `
        }
      >
        {resultItem.companyName && <Text weight='bold'>{resultItem.companyName}</Text>}
        {resultItem.address && <Text>{resultItem.address}</Text>}
        {resultItem.address2 && <Text>{resultItem.address2}</Text>}
        {lastLineStr && <Text>{lastLineStr}</Text>}
        {isReverseSearch && resultItem.ein && (
          <Text>
            <b>{'EIN: '}</b>
            {`${resultItem.ein.slice(0, 2)}-${resultItem.ein.slice(2)}`}
          </Text>
        )}
      </div>
    );
  };

  const handleIncorrectSuggestionClick = () => {
    if (hasLocationWritePermission && !isNewBrand) {
      onContinue();
      return;
    }
    setShowContactSupport(true);
  };

  useEffect(() => {
    if (!show) {
      modalCheckboxProps.value = false;
      setShowContactSupport(false);
      reset();
    }
  }, [show]);

  return show && searchResults.length ? (
    <Modal {...rest} show css={modalStyles}>
      <Modal.Header css={modalHeaderStyles}>
        <span css={modalTitleStyles}>
          <ErrorBadgeIcon color='error' />
          {t('EIN Validation Failed')}
        </span>
        {!showContactSupport && <Text weight='regular'>{subtitleString}</Text>}
      </Modal.Header>
      {showContactSupport ? (
        <>
          <Modal.Body css={modalHeaderStyles}>
            <Text>
              {t(
                'In order to finish your registration, please send a copy of your IRS SS-4 confirmation letter or Letter 147C to '
              )}
              <TextLink href='mailto:documents@getweave.com?subject=10DLC Registration Issue - No correct suggestions'>
                documents@getweave.com
              </TextLink>
              .
            </Text>
            <Text>
              {t('For information on how to find your IRS SS-4 confirmation letter or Letter 147C, read more ')}
              <TextLink
                rel='noreferrer'
                target='_blank'
                href='https://www.weavehelp.com/hc/en-us/articles/4405882188955-Text-Messaging-Brand-Registration-FAQ#where-can-i-find-my-ein--0-7'
              >
                here
              </TextLink>
              .
            </Text>
          </Modal.Body>
          <ButtonBar css={buttonBarStyles}>
            <SecondaryButton onClick={() => setShowContactSupport(false)}>{t('Go back')}</SecondaryButton>
            <PrimaryButton onClick={() => rest.onClose()}>{t('Done')}</PrimaryButton>
          </ButtonBar>
        </>
      ) : (
        <>
          <Modal.Body>
            {hasMultipleMatches ? (
              <RadioField {...getFieldProps('searchResultsMatchRadio')} name='suggestionRadio'>
                {searchResults.map((resultItem, i) => {
                  return (
                    <RadioField.Radio value={i.toString()} key={JSON.stringify(resultItem)}>
                      {getFormattedSearchResult(resultItem)}
                    </RadioField.Radio>
                  );
                })}
              </RadioField>
            ) : (
              <div>{getFormattedSearchResult(searchResults[0], true)}</div>
            )}
          </Modal.Body>
          {!hasMultipleMatches && (
            <CheckboxField
              {...modalCheckboxProps}
              name='verified-ein'
              label={t('Proceed with this suggestion')}
              css={verificationCheckboxStyles}
            />
          )}
          {isReverseSearch && hasMultipleMatches && getFieldProps('searchResultsMatchRadio').value && (
            <TextButton
              css={clearSelectionButtonStyles}
              onClick={() => reset()}
              data-test-id='ein-modal-clear-selection'
            >
              {t('Clear selection')}
            </TextButton>
          )}
          <ButtonBar css={buttonBarStyles}>
            {isReverseSearch && (
              <PrimaryButton
                onClick={() => {
                  onContinue();
                  rest.onClose();
                }}
                destructive={true}
                size='small'
                disabled={
                  hasMultipleMatches ? !!getFieldProps('searchResultsMatchRadio').value : modalCheckboxProps.value
                }
                data-test-id='ein-modal-continue-anyway-button'
              >
                {t('Continue Anyway')}
              </PrimaryButton>
            )}
            {!isReverseSearch && (
              <SecondaryButton
                size='small'
                disabled={modalCheckboxProps.value}
                css={modalCheckboxProps.value ? disabledSecondaryButtonStyles : undefined}
                onClick={handleIncorrectSuggestionClick}
              >
                {hasMultipleMatches ? t('Suggestions are incorrect') : t('Suggestion is incorrect')}
              </SecondaryButton>
            )}
            <PrimaryButton
              size='small'
              type='submit'
              disabled={
                hasMultipleMatches ? !getFieldProps('searchResultsMatchRadio').value : !modalCheckboxProps.value
              }
              data-test-id='ein-modal-update-and-resubmit'
              onClick={() => {
                onConfirm(
                  hasMultipleMatches
                    ? searchResults[parseInt(getFieldProps('searchResultsMatchRadio').value)]
                    : searchResults[0]
                );
                rest.onClose();
              }}
            >
              {t('Update and Resubmit')}
            </PrimaryButton>
          </ButtonBar>
        </>
      )}
    </Modal>
  ) : null;
};

const modalHeaderStyles = css`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(1)};
`;

const modalTitleStyles = css`
  display: flex;
  gap: ${theme.spacing(1)};
  align-items: center;
  justify-content: center;
`;

const verificationCheckboxStyles = css`
  margin: ${theme.spacing(1)} auto;
`;

const clearSelectionButtonStyles = css`
  color: ${theme.colors.primary50};
  margin: ${theme.spacing(1)} auto 0 auto;
`;

const buttonBarStyles = css`
  padding-bottom: 0;
`;

const modalStyles = css`
  max-height: 750px;
  min-width: 435px;
`;

const disabledSecondaryButtonStyles = css`
  color: ${theme.colors.neutral30};
`;
