import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { getLocationData } from '@frontend/api-location';
import { isWeaveUser } from '@frontend/auth-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { useWeaveQueryLocations } from './location-pickers';

export const usePrivilegedQueryLocations = () => {
  const isAWeaveUser = useMemo(() => isWeaveUser(), []);
  const [processed, setProcessed] = useState(false);
  const {
    setAccessibleLocationData,
    setAccessibleLocationIds,
    selectedOrgId,
    setOrgIdMap,
    setOrgIds,
    setSelectedOrgId,
    setSelectedParentsIds,
    selectedLocationIds,
  } = useAppScopeStore();
  const { orgLocations, selectOrgLocations, accessibleLocationData } = useWeaveQueryLocations({
    orgId: selectedOrgId,
    enabled: isAWeaveUser && !processed,
  });

  const { data } = useQuery({
    queryKey: ['data-location-org', selectedLocationIds[0]],
    queryFn: () => getLocationData({ locationId: selectedLocationIds[0] }),
    enabled: !selectedOrgId && !!selectedLocationIds[0] && isAWeaveUser,
  });

  /**
   * This is for the case where the user is a weave user and it doesn't have an org selected
   * This is for cases when localStorages is cleared.
   */
  useEffect(() => {
    if (!isAWeaveUser || selectedOrgId || !data) return;

    if (data?.orgId) {
      setSelectedOrgId(data.orgId);
    }
  }, [data]);

  useEffect(() => {
    if (!selectedOrgId || !isAWeaveUser || !orgLocations) return;

    setAccessibleLocationData(accessibleLocationData);
    setAccessibleLocationIds(Object.keys(accessibleLocationData));
    setSelectedParentsIds(selectOrgLocations?.parents.map((parent) => parent.locationID));
    setOrgIds([selectedOrgId]);
    setOrgIdMap({ [selectedOrgId]: selectOrgLocations });
    setProcessed(true);
  }, [orgLocations, selectOrgLocations, accessibleLocationData, isAWeaveUser, selectedOrgId]);

  return { isInitialized: processed };
};
