import React, { forwardRef, useRef } from 'react';
import { css } from '@emotion/react';
import composeRefs from '@seznam/compose-react-refs';
import { kebabCase } from 'lodash-es';
import { onlyText } from 'react-children-utilities';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  NakedButton,
  PolymorphicComponentPropWithRef,
  PolymorphicRef,
  Text,
  TextLink,
  useScrollShadow,
} from '@frontend/design-system';
import { DashboardTrackingIds } from './constants';
import type { DashboardConfigIds } from './dashboard-config';
import { useDashboard } from './provider';

export type DashboardModuleProps = {
  id: DashboardConfigIds;
  moduleWidth?: 'full' | 'half' | 'quarter';
  title?: React.ReactNode;
  action?: ModuleActionProps;
  children: React.ReactNode;
  noStretch?: boolean;
};

const moduleWidths = {
  full: 1,
  half: 2,
  quarter: 3,
};

export const Module = ({
  id,
  title,
  action,
  children,
  moduleWidth = 'half',
  noStretch,
  ...props
}: DashboardModuleProps) => {
  const { getWidthSpace } = useDashboard(['getWidthSpace']);

  const space = getWidthSpace(id);

  return (
    <section
      css={css`
        padding: ${theme.spacing(2)};
        box-shadow: ${theme.shadows.light};
        background-color: ${theme.colors.white};
        border-radius: ${theme.borderRadius.medium};
        flex: ${noStretch ? 0 : 1};
        flex-basis: calc(100% / ${space ?? moduleWidths[moduleWidth]} - ${theme.spacing(2)});
        min-width: 300px;
        display: flex;
        flex-direction: column;
      `}
      {...props}
    >
      {children}
    </section>
  );
};

type ModuleActionProps = Omit<React.ComponentProps<typeof TextLink>, 'children' | 'as'> & {
  label: React.ReactNode;
};

export const ModuleAction = forwardRef<HTMLButtonElement | HTMLAnchorElement, ModuleActionProps>(
  ({ onClick, label, ...rest }, ref) => (
    <TextLink
      trackingId={DashboardTrackingIds.moduleAction(kebabCase(onlyText(label)))}
      size='medium'
      weight='bold'
      css={css`
        white-space: nowrap;
        text-decoration: none;
      `}
      onClick={onClick}
      ref={ref}
      {...rest}
    >
      {label}
    </TextLink>
  )
);

type ModuleHeaderProps = {
  title?: React.ReactNode;
  action?: ModuleActionProps;
};

export const ModuleHeader = ({ title, action }: ModuleHeaderProps) => (
  <header
    css={css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `}
  >
    {title && (
      <Text as='h3' weight='bold' size='large'>
        {title}
      </Text>
    )}
    {action && <ModuleAction {...action} />}
  </header>
);

type ModuleContentProps<T extends React.ElementType> = PolymorphicComponentPropWithRef<T>;

type ModuleContentComponent = <C extends React.ElementType = 'div'>(props: ModuleContentProps<C>) => React.ReactNode;

export const ModuleContent: ModuleContentComponent = forwardRef(
  <T extends React.ElementType = 'div'>(
    { children, as, containerRef, ...rest }: ModuleContentProps<T>,
    ref: PolymorphicRef<T>
  ) => {
    const Component = as || 'div';

    return (
      <Component
        css={css`
          flex: 1;
        `}
        ref={ref}
        {...rest}
      >
        {children}
      </Component>
    );
  }
);

export const ModuleFooter = <T extends React.ElementType = 'footer'>({
  children,
  as,
  ...rest
}: ModuleContentProps<T>) => {
  const Component = as || 'footer';

  return <Component {...rest}>{children}</Component>;
};

type ScrollableContainerProps = {
  children: React.ReactNode;
  title?: string;
  fullHeight?: boolean;
} & React.ComponentPropsWithoutRef<'div'>;

const ScrollableContainer = ({ children, title, fullHeight, ...rest }: ScrollableContainerProps) => {
  const moduleRef = useRef<HTMLDivElement | null>(null);
  const { scrollRef, isLeft, isRight } = useScrollShadow();

  return (
    <div
      css={[
        css`
          position: relative;
        `,
        fullHeight &&
          css`
            height: 100%;
            display: flex;
            flex-direction: column;
          `,
      ]}
    >
      {title && (
        <Text size='small' color='subdued'>
          {title}
        </Text>
      )}
      <div
        ref={composeRefs(scrollRef, moduleRef)}
        css={css`
          display: flex;
          position: relative;
          gap: ${theme.spacing(2)};
          overflow-x: auto;
          margin: ${theme.spacing(0, -2, 0, -2)};
          padding: ${theme.spacing(2)};
          flex: 1;
        `}
        {...rest}
      >
        {children}
      </div>
      {!isRight && <ScrollButton direction='right' onClick={() => handleButtonScroll('right', moduleRef.current)} />}
      {!isLeft && <ScrollButton direction='left' onClick={() => handleButtonScroll('left', moduleRef.current)} />}
    </div>
  );
};

type ScrollButton = {
  direction: 'right' | 'left';
  onClick: () => void;
};

const ScrollButton = ({ direction, onClick }: ScrollButton) => {
  return (
    <NakedButton
      onClick={onClick}
      css={[
        css`
          padding: ${theme.spacing(0.5, 0)};
          border-radius: ${theme.borderRadius.small};
          box-shadow: ${theme.shadows.heavy};
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          background-color: ${theme.colors.white};
        `,
        direction === 'right'
          ? css`
              right: ${theme.spacing(-2)};
            `
          : css`
              left: ${theme.spacing(-2)};
            `,
      ]}
    >
      {direction === 'right' ? <Icon name='caret-right' /> : <Icon name='caret-left' />}
    </NakedButton>
  );
};

const defaultPadding = 16;
export const handleButtonScroll = (direction: 'left' | 'right', element: HTMLElement | null) => {
  if (!element) return;

  const { clientWidth } = element;
  const scrollLeft = element.scrollLeft;

  if (direction === 'left') {
    element.scrollTo({
      left: scrollLeft - clientWidth + defaultPadding,
      behavior: 'smooth',
    });
  }

  if (direction === 'right') {
    element.scrollTo({
      left: scrollLeft + clientWidth - defaultPadding,
      behavior: 'smooth',
    });
  }
};

Module.Header = ModuleHeader;
Module.Content = ModuleContent;
Module.Footer = ModuleFooter;
Module.Scrollable = ScrollableContainer;
