import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  flex-wrap: wrap;
  gap: ${theme.spacing(4)};
  margin-top: ${theme.spacing(1)};
`;

export const buttonContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing(2)};
  margin-top: ${theme.spacing(2)};
`;

export const textContainerStyle = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${theme.spacing(2)};
`;
