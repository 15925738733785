import { Task_Window } from '@weave/schema-gen-ts/dist/schemas/messaging/auto-worker/v1/models.pb';
import {
  CountMessagesResponse,
  ForecastCountMessagesResponse,
  ForecastRuleRequest,
  ForecastRuleResponse,
  MessageCountGroup,
  MessageGroup,
  MessagesRequest,
} from '@weave/schema-gen-ts/dist/schemas/messaging/auto-worker/v1/service.pb';
import { UseQueryOptions, useQuery } from 'react-query';
import { formatDate } from '@frontend/date';
import { SchemaAutoWorker } from './service';
import { MessagesRequestQueryKey, ScopedMessageRequestQueryKey } from './types';

export const queryKeys = {
  base: 'auto-worker',
  countMessages: (request: MessagesRequest) => [queryKeys.base, 'count-messages', request],
  forecastCountMessages: (request: MessagesRequest) => [queryKeys.base, 'forecast-count-messages', request],
  forecastMessages: (request: MessagesRequest) => [queryKeys.base, 'forecast-messages', request],
  forecastRule: (request: ForecastRuleRequest) => [queryKeys.base, 'forecast-rule', request],
  listMessages: (request: MessagesRequest) => [queryKeys.base, 'list-messages', request],
};

const STALE_TIME = 1000 * 60 * 30; // 30 minutes

const defaultOptions = {
  retry: false,
};

const getSimplifiedWindow = (window?: Task_Window) => {
  if (!window) return;
  const windowStart = formatDate(window.windowStart, 'YYYY-MM-DD');
  const windowEnd = formatDate(window.windowEnd, 'YYYY-MM-DD');
  return {
    windowStart,
    windowEnd,
  };
};

export const useForecastRule = (
  request: ForecastRuleRequest,
  options: UseQueryOptions<
    ForecastRuleResponse,
    unknown,
    ForecastRuleResponse['sentMessageCount'],
    (string | ForecastRuleRequest)[]
  >
) =>
  useQuery({
    ...defaultOptions,
    queryKey: queryKeys.forecastRule({ ...request, window: getSimplifiedWindow(request.window) }),
    queryFn: () => SchemaAutoWorker.ForecastRule(request),
    select: (response) => response.sentMessageCount,
    ...options,
  });

export const useForecastCountMessages = (
  request: MessagesRequest,
  options: UseQueryOptions<
    ForecastCountMessagesResponse,
    unknown,
    Record<string, MessageCountGroup> | undefined,
    MessagesRequestQueryKey
  >
) =>
  useQuery({
    ...defaultOptions,
    queryKey: queryKeys.forecastCountMessages({ ...request, window: getSimplifiedWindow(request.window) }),
    queryFn: () => SchemaAutoWorker.ForecastCountMessages(request),
    select: (response) => response.messageCountGroups,
    enabled: !!request.ruleIds?.length && !!request.orgId,
    staleTime: STALE_TIME,
    ...options,
  });

export const useCountMessages = (
  request: MessagesRequest,
  options: UseQueryOptions<CountMessagesResponse, unknown, Record<string, MessageCountGroup>, MessagesRequestQueryKey>
) =>
  useQuery({
    ...defaultOptions,
    queryKey: queryKeys.countMessages({ ...request, window: getSimplifiedWindow(request.window) }),
    queryFn: () => SchemaAutoWorker.CountMessages(request),
    select: (response) => response.messageCountGroups ?? {},
    enabled: !!request.ruleIds?.length && !!request.orgId,
    staleTime: STALE_TIME,
    ...options,
  });

export const useForecastMessages = <T>(
  request: MessagesRequest,
  options: UseQueryOptions<Record<string, MessageGroup>, unknown, T, ScopedMessageRequestQueryKey>
) => {
  const queryKey = queryKeys.forecastMessages(request);
  return {
    ...useQuery({
      ...defaultOptions,
      queryKey,
      queryFn: async () => {
        const response = await SchemaAutoWorker.ForecastMessages(request);
        return response.messageGroups ?? {};
      },
      ...options,
    }),
    queryKey,
  };
};

export const useListMessages = <T>(
  request: MessagesRequest,
  options: UseQueryOptions<Promise<Record<string, MessageGroup>>, unknown, T, ScopedMessageRequestQueryKey>
) => {
  const queryKey = queryKeys.listMessages(request);

  return {
    ...useQuery({
      ...defaultOptions,
      queryKey,
      queryFn: async () => {
        const response = await SchemaAutoWorker.ListMessages(request);
        return response.messageGroups ?? {};
      },
      ...options,
    }),
    queryKey,
  };
};
