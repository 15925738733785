import { PropsWithChildren, ReactNode } from 'react';
import { css } from '@emotion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { SpinningLoader } from '@frontend/design-system';
import { Body } from './atoms/body';
import { Header, HeaderProps } from './atoms/header';
import { Action } from './atoms/header/action';
import { Breadcrumbs } from './atoms/header/breadcrumbs';
import { Heading } from './atoms/header/heading';
import { LocationChip } from './atoms/header/location-chip';
import { SettingsBreadcrumbs } from './atoms/header/settings-breadcrumbs';
import { SettingsLocationChip } from './atoms/header/settings-location-chip';
import { Subtitle } from './atoms/header/subtitle';
import { Title } from './atoms/header/title';

type MaxWidthProp = string | number | null;

export type PageCompoundProps = PropsWithChildren<{
  children?: ReactNode;
  action?: ReactNode;
  className?: string;
  id?: string;
  loading?: boolean;
  maxWidth?: MaxWidthProp;
  style?: Record<string, any>;
  bgColor?: string;
}>;

const maxWidthStyle = (val: PageCompoundProps['maxWidth']) => {
  if (val === null) {
    return null;
  } else if (typeof val === 'number') {
    return css`
      max-width: ${val}px;
    `;
  } else {
    return css`
      max-width: ${val};
    `;
  }
};

const page = (maxWidth: MaxWidthProp, bgColor: string) => {
  return css`
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    min-height: 100%;
    container-type: inline-size;
    opacity: 0;
    animation: fadeIn ease 0.15s;
    animation-fill-mode: forwards;
    display: grid;
    grid-template-areas: 'header' 'content';
    grid-template-rows: auto 1fr;
    grid-template-columns: 100%;
    background-color: ${bgColor};
    width: 100%;
    font-size: ${theme.font.size.medium};
    padding: ${theme.spacing(4)};
    ${maxWidthStyle(maxWidth) ?? 'max-width: 1000px;'}

    @media (max-width: ${breakpoints.small.max}px) {
      padding: ${theme.spacing(3)};
    }
  `;
};

function PageCompound({
  children,
  className,
  id,
  loading,
  maxWidth = 1400,
  bgColor = theme.colors.white,
  style,
}: PageCompoundProps) {
  if (loading) {
    return (
      <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <SpinningLoader size='xl' />
      </div>
    );
  }

  return (
    <div css={page(maxWidth, bgColor)} className={className} id={id} style={style}>
      {children}
    </div>
  );
}

interface HeaderNamespace extends React.FC<HeaderProps> {
  Action: typeof Action;
  Breadcrumbs: typeof Breadcrumbs;
  Heading: typeof Heading;
  LocationChip: typeof LocationChip;
  SettingsBreadcrumbs: typeof SettingsBreadcrumbs;
  SettingsLocationChip: typeof SettingsLocationChip;
  Subtitle: typeof Subtitle;
  Title: typeof Title;
}

interface PageNamespace extends React.FC<PageCompoundProps> {
  Header: HeaderNamespace;
  Body: typeof Body;
}

const HeaderNamespace = Header as HeaderNamespace;

HeaderNamespace.Action = Action;
HeaderNamespace.Breadcrumbs = Breadcrumbs;
HeaderNamespace.Heading = Heading;
HeaderNamespace.LocationChip = LocationChip;
HeaderNamespace.SettingsBreadcrumbs = SettingsBreadcrumbs;
HeaderNamespace.SettingsLocationChip = SettingsLocationChip;
HeaderNamespace.Subtitle = Subtitle;
HeaderNamespace.Title = Title;

const PageNamespace = PageCompound as PageNamespace;

PageNamespace.Header = HeaderNamespace;
PageNamespace.Body = Body;

export { PageNamespace as Page };
