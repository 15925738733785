import { FC, memo, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { AreaChartData, BarChartData, Chart, sequentialChartColors } from '@frontend/charts';
import { i18next, useTranslation } from '@frontend/i18n';
import { useHasFeatureFlag } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { featureFlags } from '../../feature-flags';
import { useLocations } from '../../hooks';
import {
  calculateArrayAverage,
  calculateNumberAverage,
  ExportUtils,
  formatHourlyInsights,
  formatters,
  getHourlyInsightsLabel,
  hourlyInsightsLimiter,
  sumArrayIndexes,
  sumArrayItems,
} from '../../utils';
import { DemoChip } from '../demo-chip';
import { DropDownSelector } from '../filter-selectors';
import { InfoTipPopover } from '../info-tip-popover';
import { PhoneExtensionChart } from './charts';
import { usePhoneAnalyticsShallowStore } from './hooks';

interface Props {
  colors: Record<string, string>;
  generalChartsData: PhoneAnalyticsTypes.ChartsData;
  hourlyChartsData: PhoneAnalyticsTypes.TimeBoundInsights;
  isHourlyInsights?: boolean;
  isMultiLocations?: boolean;
  isLoading?: boolean;
  isOpenHoursOnly?: boolean;
}

const MAX_HOURLY_INSIGHTS_PRIMARY_LOCATIONS_COUNT = 4;

const defaultHourlyTotals = {
  total: 0,
  incoming: 0,
  incomingMissed: 0,
  incomingMissedOpenOffice: 0,
  incomingMissedClosedOffice: 0,
  incomingAnswered: 0,
  incomingAbandoned: 0,
  outgoing: 0,
  outgoingAnswered: 0,
  outgoingAbandoned: 0,
};

const defaultChartAppearance = {
  collectiveTooltip: true,
  margin: { left: 20 },
  showGridLines: true,
  showXAxis: true,
  showYAxis: true,
};

// Sums up the total calls for the locations and filter out locations with most calls on hourly records
const filterLocationsWithMaxHourlyCalls = (hourlyChartsData: PhoneAnalyticsTypes.TimeBoundCalls, count: number) => {
  const sortedLocationsCallsCount = Object.entries(hourlyChartsData.total)
    .map(([key, { total }]) => [key, total.reduce((sum, cur) => sum + cur, 0)])
    .sort((a, b) => (b[1] as number) - (a[1] as number));
  return {
    filtered: sortedLocationsCallsCount.map((value) => value[0]).slice(0, count) as string[],
    remaining: sortedLocationsCallsCount
      .map((value) => value[0])
      .slice(count, sortedLocationsCallsCount.length) as string[],
  };
};

const labels: Record<string, string> = {
  abandoned: i18next.t('Abandoned', { ns: 'analytics' }),
  answered: i18next.t('Answered', { ns: 'analytics' }),
  incoming: i18next.t('Incoming', { ns: 'analytics' }),
  missed: i18next.t('Missed', { ns: 'analytics' }),
  outgoing: i18next.t('Outgoing', { ns: 'analytics' }),
  total: i18next.t('Total', { ns: 'analytics' }),
  weaveAverage: i18next.t('Weave Average', { ns: 'analytics' }),
};

export const CallAnalyticsPanel: FC<React.PropsWithChildren<Props>> = ({
  colors,
  generalChartsData,
  hourlyChartsData,
  isHourlyInsights,
  isLoading,
  isMultiLocations,
  isOpenHoursOnly,
}) => {
  const { t } = useTranslation('analytics');
  const { filters, filterHintText, isDemoAccount } = usePhoneAnalyticsShallowStore(
    'filters',
    'filterHintText',
    'isDemoAccount'
  );
  const isPhoneExtensionChartEnabled = useHasFeatureFlag(featureFlags.phoneAnalyticsExtension);
  const { locations } = useLocations();

  const [selectedHourlyCallsInsights, setSelectedHourlyCallsInsights] =
    useState<PhoneAnalyticsTypes.HourlyTimeBoundCalls>('total');
  const [hourlyTotal, setHourlyTotal] =
    useState<Record<PhoneAnalyticsTypes.HourlyTimeBoundCalls, number | undefined>>(defaultHourlyTotals);

  const handleHourlyTotalUpdate = (update: Partial<Record<PhoneAnalyticsTypes.HourlyTimeBoundCalls, number>>) => {
    setHourlyTotal((prev) => ({ ...prev, ...update }));
  };

  const hourlyInsightsCallTypeOptions = [
    { id: 'total', label: t('Total Calls'), level: 1 },
    { id: 'incoming', label: t('Incoming'), level: 1 },
    { id: 'incomingMissed', label: t('Missed'), level: 2 },
    { id: 'incomingMissedOpenOffice', label: t('Open Office'), level: 3 },
    { id: 'incomingMissedClosedOffice', label: t('Closed Office'), level: 3 },
    { id: 'incomingAnswered', label: t('Answered'), level: 2 },
    { id: 'incomingAbandoned', label: t('Abandoned'), level: 2 },
    { id: 'outgoing', label: t('Outgoing'), level: 1 },
    { id: 'outgoingAnswered', label: t('Answered'), level: 2 },
    { id: 'outgoingAbandoned', label: t('Abandoned'), level: 2 },
  ];

  const hourlyDataConstants = useMemo(() => {
    const { filtered = [], remaining = [] } = filterLocationsWithMaxHourlyCalls(
      hourlyChartsData.calls,
      MAX_HOURLY_INSIGHTS_PRIMARY_LOCATIONS_COUNT
    );

    return [...filtered, ...remaining].reduce(
      (final, name, i) => {
        return {
          ...final,
          colors: { ...final.colors, [name]: sequentialChartColors[i] },
          labels: { ...final.labels, [name]: name },
        };
      },
      { colors: {}, labels: {} }
    );
  }, [hourlyChartsData.calls]);

  const callsAnswerRate: BarChartData = useMemo(() => {
    if (isHourlyInsights) {
      // In case of hourly insights, there will be only one object for the given date
      const { answered } = Object.values(generalChartsData.totalIncomingCalls)[0] || {};
      const { incoming } = Object.values(generalChartsData.totalCalls)[0] || {};
      return {
        groups: calculateArrayAverage(
          hourlyInsightsLimiter(answered as number[]),
          hourlyInsightsLimiter(incoming as number[]),
          true
        ).map((avg, i) => ({ name: getHourlyInsightsLabel(i), values: { incoming: avg } })),
      };
    } else {
      // Calculate combined weave average only when multiple locations or multiple dates are selected
      let combinedWeaveAverage = 0;
      if (isMultiLocations) {
        const values = Object.values(generalChartsData.weaveAverages.callAnswerRate);
        combinedWeaveAverage = calculateNumberAverage(
          values.reduce((sum, cur) => sum + cur, 0),
          values.length
        );
      }

      return {
        areaKeys: ['weaveAverage'],
        groups: Object.entries(generalChartsData.totalIncomingCalls).map(([key, { answered }]) => ({
          name: key,
          values: {
            incoming: calculateNumberAverage(
              answered as number,
              generalChartsData.totalCalls[key].incoming as number,
              true
            ),
            weaveAverage: combinedWeaveAverage || generalChartsData.weaveAverages.callAnswerRate[key],
          },
        })),
      };
    }
  }, [generalChartsData.totalIncomingCalls, generalChartsData.totalCalls, generalChartsData.weaveAverages]);

  const totalCallsVolume: BarChartData = useMemo(() => {
    if (isHourlyInsights) {
      // In case of hourly insights, there will be only one object for the given date
      const { incoming, outgoing, total } = Object.values(generalChartsData.totalCalls)[0] || {};
      const limitedIncoming = hourlyInsightsLimiter(incoming as number[]);
      const limitedOutgoing = hourlyInsightsLimiter(outgoing as number[]);
      const limitedTotal = hourlyInsightsLimiter(total as number[]);
      return {
        groups: limitedTotal.map((value, i) => ({
          name: getHourlyInsightsLabel(i),
          values: {
            incoming: limitedIncoming[i],
            outgoing: limitedOutgoing[i],
            total: value,
          },
        })),
      };
    } else {
      return {
        groups: Object.entries(generalChartsData.totalCalls).map(([key, { incoming, outgoing, total }]) => ({
          name: key,
          values: { incoming: incoming as number, outgoing: outgoing as number, total: total as number },
        })),
      };
    }
  }, [generalChartsData.totalCalls]);

  const totalIncomingCallsVolume: BarChartData = useMemo(() => {
    if (isHourlyInsights) {
      // In case of hourly insights, there will be only one object for the given date
      const { abandoned, answered, missed } = Object.values(generalChartsData.totalIncomingCalls)[0] || {};
      const limitedAbandoned = hourlyInsightsLimiter(abandoned as number[]);
      const limitedAnswered = hourlyInsightsLimiter(answered as number[]);
      const limitedMissed = hourlyInsightsLimiter(missed as number[]);
      return {
        groups: limitedAbandoned.map((value, i) => ({
          name: getHourlyInsightsLabel(i),
          values: {
            abandoned: value,
            answered: limitedAnswered[i],
            missed: limitedMissed[i],
          },
        })),
      };
    } else {
      return {
        groups: Object.entries(generalChartsData.totalIncomingCalls).map(([key, { abandoned, answered, missed }]) => ({
          name: key,
          values: {
            abandoned: abandoned as number,
            answered: answered as number,
            missed: missed as number,
          },
        })),
      };
    }
  }, [generalChartsData.totalIncomingCalls]);

  const hourlyIncomingCalls = useMemo(() => {
    const limitedCalls = Object.entries(hourlyChartsData.calls.incoming).reduce(
      (final, [key, value]) => ({
        ...final,
        [key]: {
          abandoned: hourlyInsightsLimiter(value.abandoned),
          answered: hourlyInsightsLimiter(value.answered),
          missed: {
            openOffice: hourlyInsightsLimiter(value.missed.openOffice),
            closedOffice: hourlyInsightsLimiter(value.missed.closedOffice),
            total: hourlyInsightsLimiter(value.missed.total),
          },
        },
      }),
      {} as Record<string, PhoneAnalyticsTypes.TimeBoundIncomingCalls>
    );
    const collectedCalls = Object.values(limitedCalls).reduce(
      (final, { abandoned, answered, missed }) => ({
        abandoned: [...(final.abandoned || []), abandoned],
        answered: [...(final.answered || []), answered],
        missed: [...(final.missed || []), missed.total],
        missedOpenOffice: [...(final.missedOpenOffice || []), missed.openOffice],
        missedClosedOffice: [...(final.missedClosedOffice || []), missed.closedOffice],
      }),
      { abandoned: [], answered: [], missed: [], missedOpenOffice: [], missedClosedOffice: [] } as {
        abandoned: number[][];
        answered: number[][];
        missed: number[][];
        missedOpenOffice: number[][];
        missedClosedOffice: number[][];
      }
    );
    handleHourlyTotalUpdate({
      incomingAbandoned: sumArrayItems(sumArrayIndexes({ arrays: collectedCalls.abandoned })),
      incomingAnswered: sumArrayItems(sumArrayIndexes({ arrays: collectedCalls.answered })),
      incomingMissed: sumArrayItems(sumArrayIndexes({ arrays: collectedCalls.missed })),
      incomingMissedOpenOffice: sumArrayItems(sumArrayIndexes({ arrays: collectedCalls.missedOpenOffice })),
      incomingMissedClosedOffice: sumArrayItems(sumArrayIndexes({ arrays: collectedCalls.missedClosedOffice })),
    });
    return Object.entries(limitedCalls).reduce(
      (final, [key, value]) => {
        const { abandoned, answered, missed } = value as PhoneAnalyticsTypes.TimeBoundIncomingCalls;
        final.incomingAbandoned.groups = formatHourlyInsights(key, abandoned, final.incomingAbandoned);
        final.incomingAnswered.groups = formatHourlyInsights(key, answered, final.incomingAnswered);
        final.incomingMissed.groups = formatHourlyInsights(key, missed.total, final.incomingMissed);
        final.incomingMissedOpenOffice.groups = formatHourlyInsights(
          key,
          missed.openOffice,
          final.incomingMissedOpenOffice
        );
        final.incomingMissedClosedOffice.groups = formatHourlyInsights(
          key,
          missed.closedOffice,
          final.incomingMissedClosedOffice
        );

        return final;
      },
      {
        incomingAbandoned: { groups: [] } as AreaChartData,
        incomingAnswered: { groups: [] } as AreaChartData,
        incomingMissed: { groups: [] } as AreaChartData,
        incomingMissedOpenOffice: { groups: [] } as AreaChartData,
        incomingMissedClosedOffice: { groups: [] } as AreaChartData,
      }
    );
  }, [hourlyChartsData.calls.incoming]);

  const hourlyOutgoingCalls = useMemo(() => {
    const limitedCalls = Object.entries(hourlyChartsData.calls.outgoing).reduce(
      (final, [key, value]) => ({
        ...final,
        [key]: { abandoned: hourlyInsightsLimiter(value.abandoned), answered: hourlyInsightsLimiter(value.answered) },
      }),
      {} as Record<string, PhoneAnalyticsTypes.TimeBoundOutgoingCalls>
    );
    const collectedCalls = Object.values(limitedCalls).reduce(
      (final, { abandoned, answered }) => ({
        abandoned: [...(final.abandoned || []), abandoned],
        answered: [...(final.answered || []), answered],
      }),
      { abandoned: [], answered: [] } as { abandoned: number[][]; answered: number[][] }
    );
    handleHourlyTotalUpdate({
      outgoingAbandoned: sumArrayItems(sumArrayIndexes({ arrays: collectedCalls.abandoned })),
      outgoingAnswered: sumArrayItems(sumArrayIndexes({ arrays: collectedCalls.answered })),
    });
    return Object.entries(limitedCalls).reduce(
      (final, [key, value]) => {
        const { abandoned, answered } = value as PhoneAnalyticsTypes.TimeBoundOutgoingCalls;
        final.outgoingAbandoned.groups = formatHourlyInsights(key, abandoned, final.outgoingAbandoned);
        final.outgoingAnswered.groups = formatHourlyInsights(key, answered, final.outgoingAnswered);

        return final;
      },
      {
        outgoingAbandoned: { groups: [] } as AreaChartData,
        outgoingAnswered: { groups: [] } as AreaChartData,
      }
    );
  }, [hourlyChartsData.calls.outgoing]);

  const hourlyTotalCalls = useMemo(() => {
    const limitedCalls = Object.entries(hourlyChartsData.calls.total).reduce(
      (final, [key, value]) => ({
        ...final,
        [key]: {
          incoming: hourlyInsightsLimiter(value.incoming),
          outgoing: hourlyInsightsLimiter(value.outgoing),
          total: hourlyInsightsLimiter(value.total),
        },
      }),
      {} as Record<string, PhoneAnalyticsTypes.TimeBoundTotalCalls>
    );
    const collectedCalls = Object.values(limitedCalls).reduce(
      (final, { incoming, outgoing, total }) => ({
        incoming: [...(final.incoming || []), incoming],
        outgoing: [...(final.outgoing || []), outgoing],
        total: [...(final.total || []), total],
      }),
      { incoming: [], outgoing: [], total: [] } as { incoming: number[][]; outgoing: number[][]; total: number[][] }
    );
    handleHourlyTotalUpdate({
      incoming: sumArrayItems(sumArrayIndexes({ arrays: collectedCalls.incoming })),
      outgoing: sumArrayItems(sumArrayIndexes({ arrays: collectedCalls.outgoing })),
      total: sumArrayItems(sumArrayIndexes({ arrays: collectedCalls.total })),
    });
    return Object.entries(limitedCalls).reduce(
      (final, [key, value]) => {
        const { incoming, outgoing, total } = value as PhoneAnalyticsTypes.TimeBoundTotalCalls;
        final.incoming.groups = formatHourlyInsights(key, incoming, final.incoming);
        final.outgoing.groups = formatHourlyInsights(key, outgoing, final.outgoing);
        final.total.groups = formatHourlyInsights(key, total, final.total);
        return final;
      },
      {
        incoming: { groups: [] } as AreaChartData,
        outgoing: { groups: [] } as AreaChartData,
        total: { groups: [] } as AreaChartData,
      }
    );
  }, [hourlyChartsData.calls.total]);

  const exportPdfProps = useMemo(
    () => ExportUtils.exportChartToPdfProps(filters.LocationID || [], locations),
    [filters.LocationID, locations]
  );

  return (
    <>
      <Chart colors={colors} isLoading={isLoading} labels={labels}>
        <Chart.Header
          {...exportPdfProps}
          infoTip={<InfoTipPopover>{t('Percentage of total incoming calls answered by the location')}</InfoTipPopover>}
          leftElement={isDemoAccount && <DemoChip />}
          subtitle={filterHintText}
          title={t('Call Answer Rate')}
        />
        <Chart.Legends />
        <Chart.BarChart
          appearance={{
            ...defaultChartAppearance,
            customYAxisTickFormat: formatters.percent.appendPercent,
          }}
          data={callsAnswerRate}
          formatValue={formatters.percent.appendPercent}
        />
      </Chart>

      <Chart colors={colors} isLoading={isLoading} labels={labels}>
        <Chart.Header
          {...exportPdfProps}
          infoTip={<InfoTipPopover>{t('Total number of incoming and outgoing calls')}</InfoTipPopover>}
          leftElement={isDemoAccount && <DemoChip />}
          subtitle={filterHintText}
          title={t('Total Call Volume')}
        />
        <Chart.Legends />
        <Chart.BarChart appearance={defaultChartAppearance} data={totalCallsVolume} />
      </Chart>

      <Chart colors={colors} isLoading={isLoading} labels={labels}>
        <Chart.Header
          {...exportPdfProps}
          infoTip={<InfoTipPopover>{t('Tells the end result of the incoming calls')}</InfoTipPopover>}
          leftElement={isDemoAccount && <DemoChip />}
          subtitle={filterHintText}
          title={t('Incoming Call Breakdown')}
        />
        <Chart.Legends />
        <Chart.BarChart appearance={defaultChartAppearance} data={totalIncomingCallsVolume} />
      </Chart>

      {isPhoneExtensionChartEnabled && <PhoneExtensionChart />}

      {!isOpenHoursOnly && (
        <Chart colors={hourlyDataConstants.colors} isLoading={isLoading} labels={hourlyDataConstants.labels}>
          <Chart.Header
            allowExportToPdf
            bottomElement={
              <HourlyInsightsSelector
                hourlyInsightsCallTypeOptions={hourlyInsightsCallTypeOptions}
                selectedHourlyCallsInsights={selectedHourlyCallsInsights}
                setSelectedHourlyCallsInsights={setSelectedHourlyCallsInsights}
                total={hourlyTotal[selectedHourlyCallsInsights]}
              />
            }
            infoTip={
              <InfoTipPopover>
                {t(
                  'It shows the hour-wise spread of call volume during the day. Data is grouped as per the local time at the location when the call occurred.'
                )}
              </InfoTipPopover>
            }
            leftElement={isDemoAccount && <DemoChip />}
            pdfDetails={[
              { label: t('Total Calls'), value: formatters.value.format(hourlyTotal[selectedHourlyCallsInsights]) },
              ...exportPdfProps.pdfDetails,
            ]}
            subtitle={filterHintText}
            title={t('Hourly Insights')}
          />
          {(filters.LocationID || []).length > 1 && <Chart.Legends />}
          <Chart.AreaChart
            appearance={defaultChartAppearance}
            data={{ ...hourlyTotalCalls, ...hourlyIncomingCalls, ...hourlyOutgoingCalls }[selectedHourlyCallsInsights]}
          />
        </Chart>
      )}
    </>
  );
};

type HourlyInsightsSelectorProps = {
  hourlyInsightsCallTypeOptions: { id: string; label: string; level: number }[];
  selectedHourlyCallsInsights: PhoneAnalyticsTypes.HourlyTimeBoundCalls;
  setSelectedHourlyCallsInsights: (value: PhoneAnalyticsTypes.HourlyTimeBoundCalls) => void;
  total?: number;
};

const HourlyInsightsSelector = memo(
  ({
    hourlyInsightsCallTypeOptions,
    selectedHourlyCallsInsights,
    setSelectedHourlyCallsInsights,
    total,
  }: HourlyInsightsSelectorProps) => {
    const { t } = useTranslation('analytics');

    return (
      <div className='no-pdf' css={styles.hourlyInsightsWrapper}>
        <Text className='totals'>{t('Total Calls: {{total}}', { total })}</Text>
        <DropDownSelector
          label={t('Call Type')}
          onChange={(value) =>
            value && setSelectedHourlyCallsInsights(value as PhoneAnalyticsTypes.HourlyTimeBoundCalls)
          }
          options={hourlyInsightsCallTypeOptions}
          value={selectedHourlyCallsInsights}
        />
      </div>
    );
  }
);

HourlyInsightsSelector.displayName = 'HourlyInsightsSelector';

const styles = {
  hourlyInsightsWrapper: css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: ${theme.spacing(1, 0)};

    .totals {
      font-weight: ${theme.font.weight.semibold};
    }
  `,
};
