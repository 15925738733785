import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { useCalendarEventsFormTypeShallowStore, useGetSchedulerV3FeatureFlagDetails } from '../../../../hooks';
import { CalendarEventsEnums } from '../../types';
import { NewAppointmentFormContainer } from './NewAppointmentFormContainer';
import { NewAppointmentFormContainerV3 } from './NewAppointmentFormContainerV3';
import { OutOfOfficeEventFormContainer } from './OutofOfficeEventFormContainer';
import { OutOfOfficeEventFormContainerV3 } from './OutofOfficeEventFormContainerV3';

type CalendarEventsModalContentProps = {
  closeModal: () => void;
  onAppointmentSuccess?: (appointment?: Appointment) => void;
};

export const CalendarEventsModalContent = ({ closeModal, onAppointmentSuccess }: CalendarEventsModalContentProps) => {
  const { selectedForm } = useCalendarEventsFormTypeShallowStore('selectedForm');
  const isNewAppointmentEvent = selectedForm === CalendarEventsEnums.APPOINTMENT_EVENT;

  const { isScheduleV3FlagEnabled } = useGetSchedulerV3FeatureFlagDetails();

  const { setSelectedForm } = useCalendarEventsFormTypeShallowStore('setSelectedForm');
  const handleCloseModal = () => {
    setSelectedForm('');
    closeModal();
  };

  return (
    <>
      {isNewAppointmentEvent &&
        (isScheduleV3FlagEnabled ? (
          <NewAppointmentFormContainerV3
            eventType={selectedForm}
            closeModal={handleCloseModal}
            onAppointmentSuccess={onAppointmentSuccess}
          />
        ) : (
          <NewAppointmentFormContainer
            eventType={selectedForm}
            closeModal={handleCloseModal}
            onAppointmentSuccess={onAppointmentSuccess}
          />
        ))}
      {!isNewAppointmentEvent &&
        selectedForm &&
        (isScheduleV3FlagEnabled ? (
          <OutOfOfficeEventFormContainerV3 closeModal={handleCloseModal} eventType={selectedForm} />
        ) : (
          <OutOfOfficeEventFormContainer closeModal={handleCloseModal} eventType={selectedForm} />
        ))}
    </>
  );
};
