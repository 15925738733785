import { useEffect, useState } from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { Trans, useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, animated, useDebouncedValue, useFadeTransition } from '@frontend/design-system';
import { TYPING_INDICATOR_SPACING_FACTOR } from '../../constants';
import { useTypingIndicatorStore } from '../../stores';

type SMSTypingIndicatorProps = { threadId: string; locationId: string } & (
  | {
      floating?: false;
      scrollbarWidth: number;
    }
  | {
      floating: true;
      scrollbarWidth?: undefined;
    }
);

export const SMSTypingIndicator = ({ scrollbarWidth, threadId, locationId, floating }: SMSTypingIndicatorProps) => {
  const { t } = useTranslation('inbox');
  const { getThreadTypingUsers } = useTypingIndicatorStore();
  const typingUsers = getThreadTypingUsers({ locationId, threadId });
  const height = theme.spacing(TYPING_INDICATOR_SPACING_FACTOR);
  const show = typingUsers.length > 0;
  const [firstName, setFirstName] = useState(typingUsers.at(0)?.firstName ?? '');
  const debouncedShow = useDebouncedValue(show, 500);

  const transitions = useFadeTransition(show);

  useEffect(() => {
    if (typingUsers.length > 0) {
      setFirstName(typingUsers.at(0)?.firstName ?? '');
    }
  }, [JSON.stringify(typingUsers)]);

  useEffect(() => {
    if (!debouncedShow) {
      setFirstName('');
    }
  }, [debouncedShow]);
  const remainingCount = Math.max(typingUsers.length - 1, 0);

  const backgroundColor = `${theme.colors.white}BF`;
  const spanStyles: Interpolation<Theme> = {
    left: 0,
    right: floating ? 0 : scrollbarWidth,
    height,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: floating ? undefined : backgroundColor,
    position: 'absolute',
    top: `-${height}`,
    zIndex: theme.zIndex.highest,
  };
  const textStyles: Interpolation<Theme> = floating
    ? {
        padding: theme.spacing(1, 2),
        borderRadius: theme.borderRadius.medium,
        backgroundColor,
      }
    : undefined;

  return (
    <>
      {transitions.map(({ item, key, props }) =>
        item && threadId ? (
          <animated.span css={spanStyles} key={key} style={props}>
            {!!remainingCount ? (
              <Trans t={t} firstName={firstName} count={remainingCount}>
                <Text css={textStyles}>
                  {{ firstName }} and {{ remainingCount }} others are typing...
                </Text>
              </Trans>
            ) : (
              <Trans t={t} firstName={firstName}>
                <Text css={textStyles}>{{ firstName }} is typing...</Text>
              </Trans>
            )}
          </animated.span>
        ) : null
      )}
    </>
  );
};
