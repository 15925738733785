import dayjs from 'dayjs';
import { PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { demoDataUtils } from '../../../utils';
import { generateHistoricalData, requiredPatientInfoFields, sumHistoricalDataPatients } from './shared-helpers';

const dayValues = [9];
const historicalDataDay = generateHistoricalData(dayValues);

const weekValues = [12, 37, 25, 5, 48, 9, 31];
const historicalDataWeek = generateHistoricalData(weekValues);

const monthValues = [
  19, 34, 10, 42, 27, 7, 48, 3, 21, 15, 38, 6, 28, 17, 31, 9, 46, 25, 2, 41, 22, 13, 36, 5, 45, 8, 30, 11, 43, 16,
];
const historicalDataMonth = generateHistoricalData(monthValues);

const productionPerDay = (count: number): PracticeAnalyticsTypes.ProductionPerDay[] => {
  const production = [];
  const startDate = dayjs().subtract(count, 'day').toDate();

  for (let i = 0; i < count; i++) {
    const date = new Date(startDate);
    date.setDate(startDate.getDate() + i);

    production.push({
      amount: demoDataUtils.generateRandomInt(10, 100),
      date: date.toISOString().slice(0, 10),
    });
  }

  return production;
};

const sumProductionData = (data: PracticeAnalyticsTypes.ProductionPerDay[]) =>
  data.reduce((acc, cur) => acc + cur.amount, 0);

const completedDayProduction = productionPerDay(1);
const completedWeekProduction = productionPerDay(7);
const completedMonthProduction = productionPerDay(30);

const generateNewPatientsDetails = (): PracticeAnalyticsTypes.PatientInfo[] =>
  demoDataUtils.generateRandomUserNames(15).map(({ firstName, lastName }, id) => {
    const aptType = demoDataUtils.generateRandomInt(1, 2) === 1 ? 'restorative' : 'hygiene';
    const nextApt = demoDataUtils.generateRandomDate(true);
    const hasNextAppointment = demoDataUtils.generateRandomInt(1, 3) === 1;

    return {
      ...requiredPatientInfoFields,
      firstAppointmentDate: demoDataUtils.generateRandomDate(),
      firstAppointmentType: aptType,
      FirstName: firstName,
      id: `${id}`,
      LastName: lastName,
      MobilePhone: demoDataUtils.generateRandomPhoneNumber(),
      nextAppointmentDate: !hasNextAppointment ? '' : nextApt,
      nextHygieneAppointment: hasNextAppointment
        ? aptType === 'hygiene'
          ? nextApt
          : demoDataUtils.generateRandomDate(true)
        : '',
      productionAmount: demoDataUtils.generateRandomInt(200, 500),
    };
  });

const generateNewPatientsData = (
  days: number,
  historicalData: PracticeAnalyticsTypes.HistoricalDataTotal[],
  needsAppointment: number,
  production: PracticeAnalyticsTypes.ProductionPerDay[]
): PracticeAnalyticsTypes.NewPatientsTrend => ({
  location: {
    newPatients: {
      details: {
        patients: generateNewPatientsDetails(),
      },
      historicalData,
      totals: {
        needsAppointment,
        patients: sumHistoricalDataPatients(historicalData),
        production: sumProductionData(production),
        productionPerDay: production,
        unscheduledProductionPerDay: productionPerDay(days),
      },
    },
  },
});

export const newPatients = () => ({
  day: generateNewPatientsData(1, historicalDataDay, 3, completedDayProduction),
  week: generateNewPatientsData(7, historicalDataWeek, 6, completedWeekProduction),
  month: generateNewPatientsData(30, historicalDataMonth, 17, completedMonthProduction),
  custom: generateNewPatientsData(30, historicalDataMonth, 17, completedMonthProduction),
});
