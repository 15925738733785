import { useEffect, useRef, useCallback } from 'react';
import { DigitalFormsQueries } from '@frontend/api-digital-forms';
import { generateFileName, removeDownloadLink, savePDF } from '../../helpers';

interface DownloadPDFProps {
  submissionId: string;
  pdfName: string;
  companyId: string;
}

export const useDownloadPDF = ({ submissionId, pdfName, companyId }: DownloadPDFProps) => {
  const downloadLinkRef = useRef<HTMLAnchorElement>();
  const { data, isLoading, refetch } = DigitalFormsQueries.useGetSubmissionPDF(
    {
      companyId,
      submissionId,
    },
    { enabled: false }
  );

  const cleanUpDownloadLink = useCallback(() => {
    if (downloadLinkRef.current) {
      removeDownloadLink(downloadLinkRef.current);
      downloadLinkRef.current = undefined;
    }
  }, []);

  useEffect(() => {
    return cleanUpDownloadLink;
  }, [cleanUpDownloadLink]);

  const downloadPDF = useCallback(async () => {
    cleanUpDownloadLink();

    if (isLoading) return;

    let file = data?.file;
    if (!file) {
      const response = await refetch();
      file = response.data?.file;
    }

    if (file) {
      downloadLinkRef.current = savePDF({
        base64Data: file,
        fileName: generateFileName(pdfName),
      });
    }
  }, [cleanUpDownloadLink, refetch, pdfName, data]);

  return {
    downloadPDF,
    isLoading,
  };
};
