import dayjs from 'dayjs';
import { SMSAnalyticsTypes } from '@frontend/api-analytics';
import { PickerLocation } from '@frontend/scope';
import { demoDataUtils } from '../../../utils/demo-data-utils';

const breakTotalMessages = (totalMessages: number, count: number) => {
  const parts = [];
  let remaining = totalMessages;

  for (let i = 0; i < count - 1; i++) {
    // Generate a random number between 0 and remaining and make sure the value is not in decimals
    const part = Math.floor(Math.random() * remaining);
    parts.push(part);
    remaining -= part;
  }

  // The last part is whatever remains to ensure sum is exact
  parts.push(remaining);

  return parts;
};

const generateTotalMessages = () => {
  // Generate the total messages value between 1000 and 3000
  const totalMessages = Math.floor(Math.random() * 2000) + 1000;
  const totalOutgoingMessages = Math.floor(Math.random() * totalMessages);
  const totalIncomingMessages = totalMessages - totalOutgoingMessages;

  const totalSMS = Math.floor(Math.random() * totalMessages);
  const totalMMS = totalMessages - totalSMS;

  const totalPatients = Math.floor(Math.random() * totalMessages);
  const totalUnknown = totalMessages - totalPatients;

  // Break down the totalMessages into 12 categories
  const categories = breakTotalMessages(totalMessages, 12);

  return {
    total_messages: totalMessages,
    total_outgoing_messages: totalOutgoingMessages,
    total_incoming_messages: totalIncomingMessages,
    total_sms: totalSMS,
    total_mms: totalMMS,
    total_unknown: totalUnknown,
    total_patients: totalPatients,
    total_category_apt_rem: categories[0],
    total_category_birthday: categories[1],
    total_category_manual_messages: categories[2],
    total_category_marketing_messages: categories[3],
    total_category_messaging_preferences: categories[4],
    total_category_missed_call: categories[5],
    total_category_payment_reminders: categories[6],
    total_category_product_ready: categories[7],
    total_category_recall: categories[8],
    total_category_review_requests: categories[9],
    total_category_save_the_date: categories[10],
    total_category_blank: categories[11],
  };
};

export const dailyData = (
  locationIds: string[],
  locationData: Record<string, PickerLocation>
): SMSAnalyticsTypes.SMSChartsData[] => {
  const dates = demoDataUtils.generateConsecutiveDates(new Date(), 14);

  return dates.map((date) => {
    const locationId = locationIds[Math.floor(Math.random() * locationIds.length)];
    const formattedDate = `${dayjs(date).format('YYYY-MM-DD')}T00:00:00Z`;

    return {
      id: demoDataUtils.generateRandomUUID(),
      location_id: locationId,
      location_name: locationData[locationId]?.name || '',
      phone_number: demoDataUtils.generateRandomPhoneNumber(),
      start_date: formattedDate,
      ...generateTotalMessages(),
    };
  });
};

const zeroArray = new Array(4).fill(0);

const prefillZeros = (data: number[]) => [...zeroArray, ...data, ...zeroArray];

export const hourlyData = (
  locationIds: string[],
  locationData: Record<string, PickerLocation>
): SMSAnalyticsTypes.SMSChartsData[] => {
  // Hourly data is applicable for only single date range location
  const locationId = locationIds[0];
  const yesterday = dayjs().subtract(1, 'day');
  const formattedDate = `${yesterday.format('YYYY-MM-DD')}T00:00:00Z`;

  const data = new Array(16).fill(0).reduce(
    (acc) => {
      const totalMessages = generateTotalMessages();

      acc.total_messages.push(totalMessages.total_messages);
      acc.total_outgoing_messages.push(totalMessages.total_outgoing_messages);
      acc.total_incoming_messages.push(totalMessages.total_incoming_messages);
      acc.total_sms.push(totalMessages.total_sms);
      acc.total_mms.push(totalMessages.total_mms);
      acc.total_unknown.push(totalMessages.total_unknown);
      acc.total_patients.push(totalMessages.total_patients);
      acc.total_category_apt_rem.push(totalMessages.total_category_apt_rem);
      acc.total_category_birthday.push(totalMessages.total_category_birthday);
      acc.total_category_manual_messages.push(totalMessages.total_category_manual_messages);
      acc.total_category_marketing_messages.push(totalMessages.total_category_marketing_messages);
      acc.total_category_messaging_preferences.push(totalMessages.total_category_messaging_preferences);
      acc.total_category_missed_call.push(totalMessages.total_category_missed_call);
      acc.total_category_payment_reminders.push(totalMessages.total_category_payment_reminders);
      acc.total_category_product_ready.push(totalMessages.total_category_product_ready);
      acc.total_category_recall.push(totalMessages.total_category_recall);
      acc.total_category_review_requests.push(totalMessages.total_category_review_requests);
      acc.total_category_save_the_date.push(totalMessages.total_category_save_the_date);
      acc.total_category_blank.push(totalMessages.total_category_blank);

      return acc;
    },
    {
      total_messages: [],
      total_outgoing_messages: [],
      total_incoming_messages: [],
      total_sms: [],
      total_mms: [],
      total_unknown: [],
      total_patients: [],
      total_category_apt_rem: [],
      total_category_birthday: [],
      total_category_manual_messages: [],
      total_category_marketing_messages: [],
      total_category_messaging_preferences: [],
      total_category_missed_call: [],
      total_category_payment_reminders: [],
      total_category_product_ready: [],
      total_category_recall: [],
      total_category_review_requests: [],
      total_category_save_the_date: [],
      total_category_blank: [],
    }
  );

  const demoData = [
    {
      id: demoDataUtils.generateRandomUUID(),
      location_id: locationId,
      location_name: locationData[locationId]?.name || '',
      phone_number: demoDataUtils.generateRandomPhoneNumber(),
      start_date: formattedDate,
      total_messages: prefillZeros(data.total_messages),
      total_outgoing_messages: prefillZeros(data.total_outgoing_messages),
      total_incoming_messages: prefillZeros(data.total_incoming_messages),
      total_sms: prefillZeros(data.total_sms),
      total_mms: prefillZeros(data.total_mms),
      total_unknown: prefillZeros(data.total_unknown),
      total_patients: prefillZeros(data.total_patients),
      total_category_apt_rem: prefillZeros(data.total_category_apt_rem),
      total_category_birthday: prefillZeros(data.total_category_birthday),
      total_category_manual_messages: prefillZeros(data.total_category_manual_messages),
      total_category_marketing_messages: prefillZeros(data.total_category_marketing_messages),
      total_category_messaging_preferences: prefillZeros(data.total_category_messaging_preferences),
      total_category_missed_call: prefillZeros(data.total_category_missed_call),
      total_category_payment_reminders: prefillZeros(data.total_category_payment_reminders),
      total_category_product_ready: prefillZeros(data.total_category_product_ready),
      total_category_recall: prefillZeros(data.total_category_recall),
      total_category_review_requests: prefillZeros(data.total_category_review_requests),
      total_category_save_the_date: prefillZeros(data.total_category_save_the_date),
      total_category_blank: prefillZeros(data.total_category_blank),
    },
  ];

  return demoData;
};
