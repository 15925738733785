import { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react';
import {
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  closestCenter,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { SortableContext, arrayMove, rectSortingStrategy, sortableKeyboardCoordinates } from '@dnd-kit/sortable';
import { useNavigate } from '@tanstack/react-location';
import { FormsLibrary, FormsQueryKeys } from '@frontend/api-forms';
import { formatDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useQuery } from '@frontend/react-query-helpers';
import {
  ConfirmationModal,
  ContentLoader,
  Heading,
  Modal,
  PrimaryButton,
  Table,
  Text,
  TextButton,
  useModalControl,
  useAlert,
} from '@frontend/design-system';
import { EmptyPacketsListPlaceholder } from '../../../../../../assets/placeholders';
import { pendoTags } from '../../../../../../index';
import { useScopeLocation } from '../../../../../../shared/hooks';
import { convertUTCtoISO } from '../../../../../../shared/utils';
import { useLibraryContext } from '../../../../context';
import { NEW_PACKET_ID, NEW_PACKET_NAME } from '../../constants';
import { validateFormOrPacketName } from '../../utils';
import { PacketTile } from './packet-tile';
import { modalBodyStyle, packetViewFooterStyle, packetsViewStyle } from './packet-view.style';
import {
  Tile1Preview,
  Tile2Preview,
  Tile3Preview,
  Tile4Preview,
  Tile5Preview,
  Tile6Preview,
} from './tile-placeholders';

const _getTilePreview = (index: number) => {
  switch (index % 6) {
    case 0:
      return Tile1Preview;
    case 1:
      return Tile2Preview;
    case 2:
      return Tile3Preview;
    case 3:
      return Tile4Preview;
    case 4:
      return Tile5Preview;
    case 5:
      return Tile6Preview;
    default:
      return Tile1Preview;
  }
};

interface PacketViewProps {
  packetName: string;
  reviewRequired: boolean;
  packet: FormsLibrary.Types.PacketDataV2;
  hasChange: boolean;
  initialReviewRequired: boolean;
  updatePacket: Dispatch<SetStateAction<FormsLibrary.Types.PacketDataV2 | undefined>>;
  checkForChanges: (packet: FormsLibrary.Types.PacketDataV2) => void;
  onChangesSaved: (packet: FormsLibrary.Types.PacketDataV2) => void;
}
const PacketView: FC<PacketViewProps> = ({
  packet,
  packetName,
  hasChange,
  initialReviewRequired,
  updatePacket,
  checkForChanges,
  onChangesSaved,
}) => {
  const previousPacketId = useRef<string>(packet.id);
  const tilePreview = useRef<Record<string, string>>({});
  const {
    packetsListV2Props: { infiniteQueryParams, updatePacket: updatePacketApi, createPacket },
  } = useLibraryContext();
  const formToBeRemoved = useRef<string>();

  const isLoadingPacketsList = infiniteQueryParams.isLoading;

  const navigate = useNavigate();

  const { t } = useTranslation('forms', { keyPrefix: 'FORMS_APP_LIBRARY' });

  const {
    modalProps: tableModalProps,
    triggerProps: tableTriggerProps,
    closeModal: closeTableModal,
  } = useModalControl();

  const {
    modalProps: deleteConfirmationModalProps,
    openModal: openDeleteConfirmationModal,
    closeModal: closeDeleteConfirmationModal,
  } = useModalControl();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const { locationId } = useScopeLocation();
  const [areFormsSet, updateAreFormsSet] = useState(true);
  const [forms, setForms] = useState<FormsLibrary.Types.FormTemplateDataV2[]>([]);

  const packetFormsKv = useRef<Record<string, boolean>>({});
  const selectedForms = useRef<FormsLibrary.Types.FormTemplateDataV2[]>([]); // forms to be added to the packet

  const alert = useAlert();

  const { data, isLoading: isLoadingForms } = useQuery({
    queryFn: () => FormsLibrary.API.fetchFormsListV2({ limit: 100, locationIds: [locationId] }).then((res) => res.rows),
    queryKey: [FormsQueryKeys.library.allForms, locationId],
    enabled: tableModalProps.show,
  });

  useEffect(() => {
    if (!isLoadingPacketsList && packet) {
      if (packet.id !== previousPacketId.current) {
        previousPacketId.current = packet.id;
        tilePreview.current = {};
      }
      evaluateAllowedForm(packet);
    }
  }, [packet.id, isLoadingPacketsList]);

  useEffect(() => {
    if (!isLoadingForms && data) {
      updateAreFormsSet(false);
      setForms(data.filter((form) => !packetFormsKv.current[form.id]));
    }
  }, [data, isLoadingForms]);

  const evaluateAllowedForm = (packet: FormsLibrary.Types.PacketDataV2) => {
    const mapper: Record<string, boolean> = {};

    (packet?.forms || []).forEach((form) => {
      mapper[form.id] = true;
    });

    packetFormsKv.current = mapper;

    if (data && !isLoadingForms) {
      setForms(data.filter((form) => !packetFormsKv.current[form.id]));
    }
  };

  // what if a packet is not found?

  const onTileClick = (formId: string) => {
    navigate({
      search: (prev) => {
        return {
          ...prev,
          formId,
        };
      },
    });
  };

  const onFormListUpdate = (updatedPacket: FormsLibrary.Types.PacketData) => {
    evaluateAllowedForm(updatedPacket);
    updatePacket(updatedPacket);
    checkForChanges(updatedPacket);
  };

  const onAddForms = () => {
    if (selectedForms.current.length === 0) {
      alert.error(t('NO_FORMS_SELECTED_ERROR'));
      return;
    }

    if (packet) {
      const updatedPacket = {
        ...packet,
        forms: [
          ...(packet.forms || []),
          ...selectedForms.current.map(({ reviewRequired: _, ...rest }) => {
            return {
              ...rest,
              companyId: locationId,
            };
          }),
        ],
      };

      onFormListUpdate(updatedPacket);
      closeTableModal();
    }
  };

  const onRemoveForm = (formId: string) => {
    formToBeRemoved.current = formId;
    openDeleteConfirmationModal();
  };

  const removeForm = () => {
    const formId = formToBeRemoved.current;
    if (packet && formId) {
      const updatedPacket = {
        ...packet,
        forms: (packet.forms || []).filter((form) => form.id !== formId),
      };

      onFormListUpdate(updatedPacket);
    }
  };

  const onSaveChanges = async () => {
    if (packet) {
      if ((packet.forms || []).length === 0) {
        alert.error(t('PACKET_WITH_NO_FORM_ERROR'));
        return;
      }

      if (packetName === NEW_PACKET_NAME) {
        alert.error({
          message: `${t('INVALID_PACKET_NAME_ERROR')} ${NEW_PACKET_NAME}`,
          autoDismissAfter: 5000,
        });
        return;
      }

      const { hasError, errorMessage } = validateFormOrPacketName(packetName, 'Packet');

      if (hasError) {
        alert.error(errorMessage);
        return;
      }

      if (packet.id === NEW_PACKET_ID) {
        createPacket({
          forms_ids: (packet.forms || []).map((form) => form.id),
          locationId,
          name: packetName,
        });
      } else {
        updatePacketApi({
          isReviewRequiredUpdated: initialReviewRequired !== packet.reviewRequired,
          locationId,
          packet: {
            ...packet,
            name: packetName.trim(),
          },
        });
      }

      onChangesSaved(packet);
    }
  };

  const getTilePreview = (name: string, index: number) => {
    if (!tilePreview.current[name]) {
      tilePreview.current[name] = _getTilePreview(index);
    }
    return tilePreview.current[name];
  };

  const onDradEnd = (e: DragEndEvent) => {
    const { active, over } = e;

    if (!packet || !over?.id || active.id === over.id) {
      return;
    }

    const prev = packet.forms || [];
    const oldIndex = prev.findIndex((p) => p.id === active.id);
    const newIndex = prev.findIndex((p) => p.id === over?.id);
    const updatedFormsOrder = arrayMove(prev, oldIndex, newIndex);

    const updatedPacket = { ...packet, forms: updatedFormsOrder };

    updatePacket(updatedPacket);
    checkForChanges(updatedPacket);
  };

  if (isLoadingPacketsList) {
    return <ContentLoader show />;
  }

  return (
    <>
      <section css={packetsViewStyle((packet?.forms || []).length)}>
        {(packet?.forms || []).length > 0 ? (
          <>
            <header className='tiles-header'>
              <Heading level={3}>Forms</Heading>
              <TextButton
                className='add-more-forms'
                {...tableTriggerProps}
                trackingId={pendoTags.library.packets.addMoreFormsToPacket}
              >
                <Icon name='plus-small' />
                <Text as='span' color='primary' weight='semibold'>
                  {t('ADD_MORE')}
                </Text>
              </TextButton>
            </header>
            <section className='form-tiles'>
              <DndContext sensors={sensors} onDragEnd={onDradEnd} collisionDetection={closestCenter}>
                <SortableContext items={(packet?.forms || []).map((item) => item.id)} strategy={rectSortingStrategy}>
                  {(packet?.forms || []).map((form, index) => (
                    <PacketTile
                      key={form.id}
                      preview={getTilePreview(form.name, index)}
                      form={form}
                      onRemoveForm={onRemoveForm}
                      onTileClick={onTileClick}
                    />
                  ))}
                </SortableContext>
              </DndContext>
            </section>
          </>
        ) : (
          <section className='empty-packet'>
            <EmptyPacketsListPlaceholder className='empty-packet-placeholder' />
            <Heading level={3} className='empty-packet-heading'>
              {t('EMPTY_PACKET_MESSAGE')}
            </Heading>
            <PrimaryButton size='tiny' {...tableTriggerProps}>
              {t('SELECT_FORMS')}
            </PrimaryButton>
          </section>
        )}

        <Modal
          {...tableModalProps}
          disableCloseOnOverlayClick
          minWidth={800}
          onClose={() => {
            selectedForms.current = [];
          }}
        >
          <Modal.Header onClose={closeTableModal}>{t('ADD_FORMS_TO_PACKET_MESSAGE')}</Modal.Header>
          <Modal.Body css={modalBodyStyle}>
            <Table
              isLoading={areFormsSet}
              colConfig={[
                {
                  Header: t('FORM_TITLE'),
                  accessor: 'name',
                  id: 'name',
                },
                {
                  Header: t('CREATED_ON'),
                  accessor: 'dateTime',
                  id: 'dateTime',
                  sortType: 'number',
                  cellRenderer: (dateTime) => {
                    return <Text>{formatDate(new Date(convertUTCtoISO(dateTime)), 'MMM DD YYYY, hh:mm A')} </Text>;
                  },
                },
              ]}
              data={forms}
              hasGlobalSearch
              isSelectable
              uniqueRowId={(data) => data.id}
              rowSelectionConfig={{
                hideBulkSelection: true,
                onSelectionToggle: (_, selectedData) => {
                  selectedForms.current = selectedData;
                },
              }}
            ></Table>
          </Modal.Body>
          <Modal.Actions
            primaryLabel={t('ADD_TO_PACKET')}
            primaryTrackingId={pendoTags.library.packets.addFormToPacket}
            secondaryLabel={t('CANCEL')}
            onSecondaryClick={closeTableModal}
            onPrimaryClick={onAddForms}
            disablePrimary={forms.length === 0}
            disableSecondary={forms.length === 0}
          />
        </Modal>
      </section>
      <ConfirmationModal
        {...deleteConfirmationModalProps}
        title={t('REMOVE_FORM')}
        message={t('REMOVE_FORM_CONFIRMATION')}
        onConfirm={removeForm}
        onCancel={closeDeleteConfirmationModal}
        destructive
      />
      {hasChange && (
        <footer css={packetViewFooterStyle}>
          <PrimaryButton
            className='save-changes-btn'
            size='tiny'
            onClick={onSaveChanges}
            trackingId={pendoTags.library.packets.savePacket}
          >
            {t('SAVE')}
          </PrimaryButton>
        </footer>
      )}
    </>
  );
};

export default PacketView;
