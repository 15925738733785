import { useNavigate } from '@tanstack/react-location';
import {
  useInfiniteQuery,
  UseInfiniteQueryResult,
  useMutation,
  InfiniteData,
  useQueryClient,
  UseMutateAsyncFunction,
} from 'react-query';
import { FormsQueryKeys, FormsLibrary } from '@frontend/api-forms';
import { useDigitalFormsLocationsContext } from '@frontend/digital-forms-scope';
import { useDebouncedValue, useAlert } from '@frontend/design-system';
import { DEBOUNCE_INTERVAL, routes } from '../../constants';

interface UseFormsListV2Props {
  locationIds?: string[];
  enabled?: boolean;
  searchKey?: string;
}

interface UseFormsListV2Results {
  infiniteQueryParams: UseInfiniteQueryResult<FormsLibrary.Types.ModifiedFormsListResponseV2, unknown>;
  updateReviewRequiredForForm: UseMutateAsyncFunction<
    unknown,
    unknown,
    FormsLibrary.Types.ToggleReviewRequiredPayload,
    void
  >;
  isUpdatingReviewRequiredFlagForForm: boolean;
  isDeletingForm: boolean;
  deleteForm: (data: FormsLibrary.Types.DeleteFormPayload) => void;
}

export const useFormsListV2 = (
  { locationIds = [], enabled = true, searchKey = '' }: UseFormsListV2Props = {
    locationIds: [],
    enabled: true,
    searchKey: '',
  }
): UseFormsListV2Results => {
  const alert = useAlert();
  const debouncedSearchTerm = useDebouncedValue(searchKey, DEBOUNCE_INTERVAL);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { validFormsLocations } = useDigitalFormsLocationsContext();
  const _locationIds = locationIds.length > 0 ? locationIds : validFormsLocations;

  const QUERY_KEY = [FormsQueryKeys.library.formsV2, _locationIds.join('|'), debouncedSearchTerm];

  const infiniteQueryParams = useInfiniteQuery({
    queryKey: QUERY_KEY,
    queryFn: ({ pageParam = { search: debouncedSearchTerm } }) =>
      FormsLibrary.API.fetchFormsListV2({
        ...pageParam,
        locationIds: _locationIds,
        search: debouncedSearchTerm,
      }),
    enabled: _locationIds.length > 0 && enabled,
    retry: 0,
    staleTime: 1000,
    refetchOnMount: true,
    getNextPageParam: (lastPage) => {
      if (!lastPage || lastPage.pageMetadata.total === '0') {
        return undefined;
      }

      const currentPageNumber =
        typeof lastPage.pageMetadata.page === 'string'
          ? parseInt(lastPage.pageMetadata.page, 10)
          : lastPage.pageMetadata.page;

      const lastPageNumber =
        typeof lastPage.pageMetadata.lastPage === 'string'
          ? parseInt(lastPage.pageMetadata.lastPage, 10)
          : lastPage.pageMetadata.lastPage;

      return currentPageNumber < lastPageNumber
        ? {
            page: currentPageNumber + 1,
            search: debouncedSearchTerm,
          }
        : undefined;
    },
  });

  const { mutateAsync: deleteForm, isLoading: isDeletingForm } = useMutation(FormsLibrary.API.deleteForm, {
    onSuccess: (_, { formId }) => {
      queryClient.setQueryData(
        QUERY_KEY,
        (data: InfiniteData<FormsLibrary.Types.ModifiedFormsListResponseV2> | undefined) => {
          const newPages = (data?.pages || []).map((page) => {
            const newRows = page.rows.filter((row) => row.id !== formId);
            return {
              ...page,
              rows: newRows,
            };
          });

          return {
            pages: newPages,
            pageParams: data?.pageParams || [],
          };
        }
      );
      alert.success('Successfully deleted form.');
      navigate({
        to: routes.library.forms,
        replace: true,
      });
    },
    onError: () => {
      alert.error('Failed to delete form.');
    },
  });

  const { mutateAsync: updateReviewRequired, isLoading: isUpdatingReviewRequiredFlag } = useMutation(
    FormsLibrary.API.updateReviewRequiredForForm,
    {
      onMutate: ({ documentId, reviewRequired }) => {
        queryClient.setQueryData(
          QUERY_KEY,
          (data: InfiniteData<FormsLibrary.Types.ModifiedFormsListResponseV2> | undefined) => {
            const newPages = toggleReviewRequired(data, documentId, reviewRequired);

            return {
              pages: newPages,
              pageParams: data?.pageParams ?? [],
            };
          }
        );
      },
      onSuccess: (_, { reviewRequired }) => {
        if (reviewRequired) {
          alert.success('Successfully enabled Provider Review for this form');
        } else {
          alert.success('Successfully disabled Provider Review for this form');
        }
      },
      onError: (_, { documentId, reviewRequired }) => {
        queryClient.setQueryData(
          QUERY_KEY,
          (data: InfiniteData<FormsLibrary.Types.ModifiedFormsListResponseV2> | undefined) => {
            const newPages = toggleReviewRequired(data, documentId, !reviewRequired);
            return {
              pages: newPages,
              pageParams: data?.pageParams ?? [],
            };
          }
        );
        alert.error('Failed to save Review Required changes.');
      },
    }
  );

  return {
    infiniteQueryParams,
    updateReviewRequiredForForm: updateReviewRequired,
    isUpdatingReviewRequiredFlagForForm: isUpdatingReviewRequiredFlag,
    isDeletingForm: isDeletingForm,
    deleteForm,
  };
};

function toggleReviewRequired(
  infiniteData: InfiniteData<FormsLibrary.Types.ModifiedFormsListResponseV2> | undefined,
  token: string,
  reviewRequired: boolean
) {
  const newPages = [...(infiniteData?.pages ?? [])];

  for (let page = 0; page < newPages.length; page++) {
    let found = false;

    for (let form = 0; form < newPages[page].rows.length; form++) {
      if (newPages[page].rows[form].id === token) {
        newPages[page].rows[form].reviewRequired = reviewRequired;
        found = true;
        break;
      }
    }

    if (found) break;
  }

  return newPages;
}
