import dayjs from 'dayjs';
import { PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { PickerLocation } from '@frontend/scope';
import { demoDataUtils } from '../../../utils/demo-data-utils';

export const dailyData = (
  locationIds: string[],
  locationData: Record<string, PickerLocation>
): PhoneAnalyticsTypes.PhoneReportChart[] => {
  const dates = demoDataUtils.generateConsecutiveDates(new Date(), 14);

  return dates.map((date) => {
    const locationId = locationIds[Math.floor(Math.random() * locationIds.length)];
    const formattedDate = `${dayjs(date).format('YYYY-MM-DD')}T00:00:00Z`;

    const totalInboundAnsweredCalls = Math.floor(Math.random() * 100);
    const totalInboundMissedCalls = Math.floor(Math.random() * 100);
    const totalInboundMissedOOOCalls = Math.floor(Math.random() * totalInboundMissedCalls);
    const totalInboundAbandonedCalls = Math.floor(Math.random() * 100);
    const totalInboundCalls = totalInboundAnsweredCalls + totalInboundMissedCalls + totalInboundAbandonedCalls;

    const totalInboundCallsPatient = Math.floor(Math.random() * totalInboundCalls);
    const totalInboundCallsUnknown = totalInboundCalls - totalInboundCallsPatient;

    const totalInboundCallDuration = totalInboundCalls > 0 ? Math.floor(Math.random() * 50000) : 0;

    const totalInboundCallDurationPatient =
      totalInboundCallsPatient > 0 ? Math.floor(Math.random() * totalInboundCallDuration) : 0;
    const totalInboundCallDurationUnknown =
      totalInboundCallsUnknown > 0 ? totalInboundCallDuration - totalInboundCallDurationPatient : 0;

    const totalInboundAnsweredCallsDuration =
      totalInboundAnsweredCalls > 0 ? Math.floor(Math.random() * totalInboundCallDuration) : 0;
    const totalInboundMissedCallsDuration =
      totalInboundMissedCalls > 0
        ? Math.floor(Math.random() * (totalInboundCallDuration - totalInboundAnsweredCallsDuration))
        : 0;
    const totalInboundAbandonedCallsDuration =
      totalInboundAbandonedCalls > 0
        ? totalInboundCallDuration - totalInboundAnsweredCallsDuration - totalInboundMissedCallsDuration
        : 0;

    const totalInboundAnsweredCallsPatients = Math.floor(Math.random() * totalInboundAnsweredCalls);
    const totalInboundAnsweredCallsUnknown = totalInboundAnsweredCalls - totalInboundAnsweredCallsPatients;
    const totalInboundAnsweredCallsDurationPatients =
      totalInboundAnsweredCallsPatients > 0 ? Math.floor(Math.random() * totalInboundAnsweredCallsDuration) : 0;
    const totalInboundAnsweredCallsDurationUnknown =
      totalInboundAnsweredCallsUnknown > 0
        ? totalInboundAnsweredCallsDuration - totalInboundAnsweredCallsDurationPatients
        : 0;

    const totalInboundMissedCallsPatients = Math.floor(Math.random() * totalInboundMissedCalls);
    const totalInboundMissedCallsUnknown = totalInboundMissedCalls - totalInboundMissedCallsPatients;
    const totalInboundMissedCallsDurationPatients =
      totalInboundMissedCallsPatients > 0 ? Math.floor(Math.random() * totalInboundMissedCallsDuration) : 0;
    const totalInboundMissedCallsDurationUnknown =
      totalInboundMissedCallsUnknown > 0
        ? totalInboundMissedCallsDuration - totalInboundMissedCallsDurationPatients
        : 0;

    const totalInboundAbandonedCallsPatients = Math.floor(Math.random() * totalInboundAbandonedCalls);
    const totalInboundAbandonedCallsUnknown = totalInboundAbandonedCalls - totalInboundAbandonedCallsPatients;
    const totalInboundAbandonedCallsDurationPatients =
      totalInboundAbandonedCallsPatients > 0 ? Math.floor(Math.random() * totalInboundAbandonedCallsDuration) : 0;
    const totalInboundAbandonedCallsDurationUnknown =
      totalInboundAbandonedCallsUnknown > 0
        ? totalInboundAbandonedCallsDuration - totalInboundAbandonedCallsDurationPatients
        : 0;

    const totalOutboundCalls = Math.floor(Math.random() * 700);
    const totalOutboundCallsPatient = Math.floor(Math.random() * totalOutboundCalls);
    const totalOutboundCallsUnknown = totalOutboundCalls - totalOutboundCallsPatient;

    const totalOutboundCallDuration = totalOutboundCalls > 0 ? Math.floor(Math.random() * 50000) : 0;
    const totalOutboundCallDurationPatient =
      totalOutboundCallsPatient > 0 ? Math.floor(Math.random() * totalOutboundCallDuration) : 0;
    const totalOutboundCallDurationUnknown =
      totalOutboundCallsUnknown > 0 ? totalOutboundCallDuration - totalOutboundCallDurationPatient : 0;

    const totalOutboundAnsweredCalls = Math.floor(Math.random() * totalOutboundCalls);
    const totalOutboundAnsweredCallsPatients = Math.floor(Math.random() * totalOutboundAnsweredCalls);
    const totalOutboundAnsweredCallsUnknown = totalOutboundAnsweredCalls - totalOutboundAnsweredCallsPatients;
    const totalOutboundAnsweredCallsDuration =
      totalOutboundAnsweredCalls > 0 ? Math.floor(Math.random() * totalOutboundCallDuration) : 0;
    const totalOutboundAnsweredCallsDurationPatients =
      totalOutboundAnsweredCallsPatients > 0 ? Math.floor(Math.random() * totalOutboundAnsweredCallsDuration) : 0;
    const totalOutboundAnsweredCallsDurationUnknown =
      totalOutboundAnsweredCallsUnknown > 0
        ? totalOutboundAnsweredCallsDuration - totalOutboundAnsweredCallsDurationPatients
        : 0;

    const totalOutboundAbandonedCalls = Math.floor(Math.random() * (totalOutboundCalls - totalOutboundAnsweredCalls));
    const totalOutboundAbandonedCallsPatients = Math.floor(Math.random() * totalOutboundAbandonedCalls);
    const totalOutboundAbandonedCallsUnknown = totalOutboundAbandonedCalls - totalOutboundAbandonedCallsPatients;
    const totalOutboundAbandonedCallsDuration =
      totalOutboundAbandonedCalls > 0
        ? Math.floor(Math.random() * (totalOutboundCallDuration - totalOutboundAnsweredCallsDuration))
        : 0;
    const totalOutboundAbandonedCallsDurationPatients =
      totalOutboundAbandonedCallsPatients > 0 ? Math.floor(Math.random() * totalOutboundAbandonedCallsDuration) : 0;
    const totalOutboundAbandonedCallsDurationUnknown =
      totalOutboundAbandonedCallsUnknown > 0
        ? totalOutboundAbandonedCallsDuration - totalOutboundAbandonedCallsDurationPatients
        : 0;

    return {
      EndDate: formattedDate,
      ID: demoDataUtils.generateRandomUUID(),
      InboundAbandonedCallsRatio: totalInboundAbandonedCalls / totalInboundCalls,
      InboundAnsweredCallsRatio: totalInboundAnsweredCalls / totalInboundCalls,
      InboundCallDurationMoreThan10Min: totalInboundCallDuration > 30000 ? Math.floor(Math.random() * 2) : 0,
      InboundMissedCallsRatio: totalInboundMissedCalls / totalInboundCalls,
      IndustryCallAnswerRate: 68.775444,
      LocationID: locationId,
      LocationName: locationData[locationId]?.name || '',
      OutboundCallDurationMoreThan10Min: totalOutboundCalls > 0 ? Math.floor(Math.random() * totalOutboundCalls) : 0,
      PhoneNumber: demoDataUtils.generateRandomPhoneNumber(),
      StartDate: formattedDate,
      TotalCallDuration: totalInboundCallDuration + totalOutboundCallDuration,
      TotalInboundAbandonedCalls: totalInboundAbandonedCalls,
      TotalInboundAbandonedCallsDuration: totalInboundAbandonedCallsDuration,
      TotalInboundAbandonedCallsDurationPatients: totalInboundAbandonedCallsDurationPatients,
      TotalInboundAbandonedCallsDurationUnknown: totalInboundAbandonedCallsDurationUnknown,
      TotalInboundAbandonedCallsPatients: totalInboundAbandonedCallsPatients,
      TotalInboundAbandonedCallsUnknown: totalInboundAbandonedCallsUnknown,
      TotalInboundAnsweredCalls: totalInboundAnsweredCalls,
      TotalInboundAnsweredCallsDuration: totalInboundAnsweredCallsDuration,
      TotalInboundAnsweredCallsDurationPatients: totalInboundAnsweredCallsDurationPatients,
      TotalInboundAnsweredCallsDurationUnknown: totalInboundAnsweredCallsDurationUnknown,
      TotalInboundAnsweredCallsPatients: totalInboundAnsweredCallsPatients,
      TotalInboundAnsweredCallsUnknown: totalInboundAnsweredCallsUnknown,
      TotalInboundCallDuration: totalInboundCallDuration,
      TotalInboundCallDurationPatient: totalInboundCallDurationPatient,
      TotalInboundCallDurationUnknown: totalInboundCallDurationUnknown,
      TotalInboundCalls: totalInboundCalls,
      TotalInboundCallsPatient: totalInboundCallsPatient,
      TotalInboundCallsUnknown: totalInboundCallsUnknown,
      TotalInboundMissedCalls: totalInboundMissedCalls,
      TotalInboundMissedCallsDuration: totalInboundMissedCallsDuration,
      TotalInboundMissedCallsDurationPatients: totalInboundMissedCallsDurationPatients,
      TotalInboundMissedCallsDurationUnknown: totalInboundMissedCallsDurationUnknown,
      TotalInboundMissedCallsPatients: totalInboundMissedCallsPatients,
      TotalInboundMissedCallsUnknown: totalInboundMissedCallsUnknown,
      TotalInboundMissedOOOCalls: totalInboundMissedOOOCalls,
      TotalOutboundAbandonedCalls: totalOutboundAbandonedCalls,
      TotalOutboundAbandonedCallsDuration: totalOutboundAbandonedCallsDuration,
      TotalOutboundAbandonedCallsDurationPatients: totalOutboundAbandonedCallsDurationPatients,
      TotalOutboundAbandonedCallsDurationUnknown: totalOutboundAbandonedCallsDurationUnknown,
      TotalOutboundAbandonedCallsPatients: totalOutboundAbandonedCallsPatients,
      TotalOutboundAbandonedCallsUnknown: totalOutboundAbandonedCallsUnknown,
      TotalOutboundAnsweredCalls: totalOutboundAnsweredCalls,
      TotalOutboundAnsweredCallsDuration: totalOutboundAnsweredCallsDuration,
      TotalOutboundAnsweredCallsDurationPatients: totalOutboundAnsweredCallsDurationPatients,
      TotalOutboundAnsweredCallsDurationUnknown: totalOutboundAnsweredCallsDurationUnknown,
      TotalOutboundAnsweredCallsPatients: totalOutboundAnsweredCallsPatients,
      TotalOutboundAnsweredCallsUnknown: totalOutboundAnsweredCallsUnknown,
      TotalOutboundCallDuration: totalOutboundCallDuration,
      TotalOutboundCallDurationPatient: totalOutboundCallDurationPatient,
      TotalOutboundCallDurationUnknown: totalOutboundCallDurationUnknown,
      TotalOutboundCalls: totalOutboundCalls,
      TotalOutboundCallsPatient: totalOutboundCallsPatient,
      TotalOutboundCallsUnknown: totalOutboundCallsUnknown,
      WeaveCallAnswerRate: 68.81955,
    };
  });
};

const zeroArray = new Array(4).fill(0);

const prefillZeros = (data: number[]) => [...zeroArray, ...data, ...zeroArray];

export const hourlyData = (
  locationIds: string[],
  locationData: Record<string, PickerLocation>
): PhoneAnalyticsTypes.PhoneReportChart[] => {
  // Hourly data is applicable for only single date range location
  const locationId = locationIds[0];
  const yesterday = dayjs().subtract(1, 'day');
  const formattedDate = `${yesterday.format('YYYY-MM-DD')}T00:00:00Z`;

  const data = new Array(16).fill(0).reduce(
    (acc) => {
      // Inbound calls
      const totalInboundCalls = Math.floor(Math.random() * 50);
      const totalInboundCallsPatient = Math.floor(Math.random() * totalInboundCalls);
      const totalInboundCallsUnknown = totalInboundCalls - totalInboundCallsPatient;
      const totalInboundCallDuration = totalInboundCalls ? Math.floor(Math.random() * 50000) : 0;
      const totalInboundCallDurationPatient = totalInboundCallsPatient
        ? Math.floor(Math.random() * totalInboundCallDuration)
        : 0;
      const totalInboundCallDurationUnknown = totalInboundCallsUnknown
        ? totalInboundCallDuration - totalInboundCallDurationPatient
        : 0;
      const inboundCallDurationMoreThan10Min = totalInboundCallDuration > 30000 ? Math.floor(Math.random() * 3) : 0;

      // Inbound answered calls
      const totalInboundAnsweredCalls = Math.floor(Math.random() * totalInboundCalls);
      const totalInboundAnsweredCallsPatients = Math.floor(Math.random() * totalInboundAnsweredCalls);
      const totalInboundAnsweredCallsUnknown = totalInboundAnsweredCalls - totalInboundAnsweredCallsPatients;
      const totalInboundAnsweredCallsDuration = totalInboundAnsweredCalls
        ? Math.floor(Math.random() * totalInboundCallDuration)
        : 0;
      const totalInboundAnsweredCallsDurationPatients = totalInboundAnsweredCallsPatients
        ? Math.floor(Math.random() * totalInboundAnsweredCallsDuration)
        : 0;
      const totalInboundAnsweredCallsDurationUnknown = totalInboundAnsweredCallsUnknown
        ? totalInboundAnsweredCallsDuration - totalInboundAnsweredCallsDurationPatients
        : 0;

      // Inbound missed calls
      const totalInboundMissedCalls = Math.floor(Math.random() * (totalInboundCalls - totalInboundAnsweredCalls));
      const totalInboundMissedCallsPatients = Math.floor(Math.random() * totalInboundMissedCalls);
      const totalInboundMissedCallsUnknown = totalInboundMissedCalls - totalInboundMissedCallsPatients;
      const totalInboundMissedOOOCalls = Math.floor(Math.random() * totalInboundMissedCalls);
      const totalInboundMissedCallsDuration = totalInboundMissedCalls
        ? Math.floor(Math.random() * (totalInboundCallDuration - totalInboundAnsweredCallsDuration))
        : 0;
      const totalInboundMissedCallsDurationPatients = totalInboundMissedCallsPatients
        ? Math.floor(Math.random() * totalInboundMissedCallsDuration)
        : 0;
      const totalInboundMissedCallsDurationUnknown = totalInboundMissedCallsUnknown
        ? totalInboundMissedCallsDuration - totalInboundMissedCallsDurationPatients
        : 0;

      // Inbound abandoned calls
      const totalInboundAbandonedCalls = Math.floor(
        Math.random() * (totalInboundCalls - totalInboundAnsweredCalls - totalInboundMissedCalls)
      );
      const totalInboundAbandonedCallsPatients = Math.floor(Math.random() * totalInboundAbandonedCalls);
      const totalInboundAbandonedCallsUnknown = totalInboundAbandonedCalls - totalInboundAbandonedCallsPatients;
      const totalInboundAbandonedCallsDuration = totalInboundAbandonedCalls
        ? Math.floor(
            Math.random() *
              (totalInboundCallDuration - totalInboundAnsweredCallsDuration - totalInboundMissedCallsDuration)
          )
        : 0;
      const totalInboundAbandonedCallsDurationPatients = totalInboundAbandonedCallsPatients
        ? Math.floor(Math.random() * totalInboundAbandonedCallsDuration)
        : 0;
      const totalInboundAbandonedCallsDurationUnknown = totalInboundAbandonedCallsUnknown
        ? totalInboundAbandonedCallsDuration - totalInboundAbandonedCallsDurationPatients
        : 0;

      // Outbound calls
      const totalOutboundCalls = Math.floor(Math.random() * 50);
      const totalOutboundCallsPatient = Math.floor(Math.random() * totalOutboundCalls);
      const totalOutboundCallsUnknown = totalOutboundCalls - totalOutboundCallsPatient;
      const totalOutboundCallDuration = totalOutboundCalls ? Math.floor(Math.random() * 50000) : 0;
      const totalOutboundCallDurationPatient = totalOutboundCallsPatient
        ? Math.floor(Math.random() * totalOutboundCallDuration)
        : 0;
      const totalOutboundCallDurationUnknown = totalOutboundCallsUnknown
        ? totalOutboundCallDuration - totalOutboundCallDurationPatient
        : 0;
      const outboundCallDurationMoreThan10Min = totalOutboundCallDuration > 30000 ? Math.floor(Math.random() * 2) : 0;

      // Outbound answered calls
      const totalOutboundAnsweredCalls = Math.floor(Math.random() * totalOutboundCalls);
      const totalOutboundAnsweredCallsPatients = Math.floor(Math.random() * totalOutboundAnsweredCalls);
      const totalOutboundAnsweredCallsUnknown = totalOutboundAnsweredCalls - totalOutboundAnsweredCallsPatients;
      const totalOutboundAnsweredCallsDuration = totalOutboundAnsweredCalls
        ? Math.floor(Math.random() * totalOutboundCallDuration)
        : 0;
      const totalOutboundAnsweredCallsDurationPatients = totalOutboundAnsweredCallsPatients
        ? Math.floor(Math.random() * totalOutboundAnsweredCallsDuration)
        : 0;
      const totalOutboundAnsweredCallsDurationUnknown = totalOutboundAnsweredCallsUnknown
        ? totalOutboundAnsweredCallsDuration - totalOutboundAnsweredCallsDurationPatients
        : 0;

      // Outbound abandoned calls
      const totalOutboundAbandonedCalls = Math.floor(Math.random() * (totalOutboundCalls - totalOutboundAnsweredCalls));
      const totalOutboundAbandonedCallsPatients = Math.floor(Math.random() * totalOutboundAbandonedCalls);
      const totalOutboundAbandonedCallsUnknown = totalOutboundAbandonedCalls - totalOutboundAbandonedCallsPatients;
      const totalOutboundAbandonedCallsDuration = totalOutboundAbandonedCalls
        ? Math.floor(Math.random() * (totalOutboundCallDuration - totalOutboundAnsweredCallsDuration))
        : 0;
      const totalOutboundAbandonedCallsDurationPatients = totalOutboundAbandonedCallsPatients
        ? Math.floor(Math.random() * totalOutboundAbandonedCallsDuration)
        : 0;
      const totalOutboundAbandonedCallsDurationUnknown = totalOutboundAbandonedCallsUnknown
        ? totalOutboundAbandonedCallsDuration - totalOutboundAbandonedCallsDurationPatients
        : 0;

      const totalCallDuration = totalInboundCallDuration + totalOutboundCallDuration;

      acc.totalInboundCalls.push(totalInboundCalls);
      acc.totalInboundCallsPatient.push(totalInboundCallsPatient);
      acc.totalInboundCallsUnknown.push(totalInboundCallsUnknown);
      acc.totalInboundCallDuration.push(totalInboundCallDuration);
      acc.totalInboundCallDurationPatient.push(totalInboundCallDurationPatient);
      acc.totalInboundCallDurationUnknown.push(totalInboundCallDurationUnknown);
      acc.inboundCallDurationMoreThan10Min.push(inboundCallDurationMoreThan10Min);

      acc.totalInboundAnsweredCalls.push(totalInboundAnsweredCalls);
      acc.totalInboundAnsweredCallsPatients.push(totalInboundAnsweredCallsPatients);
      acc.totalInboundAnsweredCallsUnknown.push(totalInboundAnsweredCallsUnknown);
      acc.totalInboundAnsweredCallsDuration.push(totalInboundAnsweredCallsDuration);
      acc.totalInboundAnsweredCallsDurationPatients.push(totalInboundAnsweredCallsDurationPatients);
      acc.totalInboundAnsweredCallsDurationUnknown.push(totalInboundAnsweredCallsDurationUnknown);

      acc.totalInboundMissedCalls.push(totalInboundMissedCalls);
      acc.totalInboundMissedCallsPatients.push(totalInboundMissedCallsPatients);
      acc.totalInboundMissedCallsUnknown.push(totalInboundMissedCallsUnknown);
      acc.totalInboundMissedOOOCalls.push(totalInboundMissedOOOCalls);
      acc.totalInboundMissedCallsDuration.push(totalInboundMissedCallsDuration);
      acc.totalInboundMissedCallsDurationPatients.push(totalInboundMissedCallsDurationPatients);
      acc.totalInboundMissedCallsDurationUnknown.push(totalInboundMissedCallsDurationUnknown);

      acc.totalInboundAbandonedCalls.push(totalInboundAbandonedCalls);
      acc.totalInboundAbandonedCallsPatients.push(totalInboundAbandonedCallsPatients);
      acc.totalInboundAbandonedCallsUnknown.push(totalInboundAbandonedCallsUnknown);
      acc.totalInboundAbandonedCallsDuration.push(totalInboundAbandonedCallsDuration);
      acc.totalInboundAbandonedCallsDurationPatients.push(totalInboundAbandonedCallsDurationPatients);
      acc.totalInboundAbandonedCallsDurationUnknown.push(totalInboundAbandonedCallsDurationUnknown);

      acc.totalOutboundCalls.push(totalOutboundCalls);
      acc.totalOutboundCallsPatient.push(totalOutboundCallsPatient);
      acc.totalOutboundCallsUnknown.push(totalOutboundCallsUnknown);
      acc.totalOutboundCallDuration.push(totalOutboundCallDuration);
      acc.totalOutboundCallDurationPatient.push(totalOutboundCallDurationPatient);
      acc.totalOutboundCallDurationUnknown.push(totalOutboundCallDurationUnknown);
      acc.outboundCallDurationMoreThan10Min.push(outboundCallDurationMoreThan10Min);

      acc.totalOutboundAnsweredCalls.push(totalOutboundAnsweredCalls);
      acc.totalOutboundAnsweredCallsPatients.push(totalOutboundAnsweredCallsPatients);
      acc.totalOutboundAnsweredCallsUnknown.push(totalOutboundAnsweredCallsUnknown);
      acc.totalOutboundAnsweredCallsDuration.push(totalOutboundAnsweredCallsDuration);
      acc.totalOutboundAnsweredCallsDurationPatients.push(totalOutboundAnsweredCallsDurationPatients);
      acc.totalOutboundAnsweredCallsDurationUnknown.push(totalOutboundAnsweredCallsDurationUnknown);

      acc.totalOutboundAbandonedCalls.push(totalOutboundAbandonedCalls);
      acc.totalOutboundAbandonedCallsPatients.push(totalOutboundAbandonedCallsPatients);
      acc.totalOutboundAbandonedCallsUnknown.push(totalOutboundAbandonedCallsUnknown);
      acc.totalOutboundAbandonedCallsDuration.push(totalOutboundAbandonedCallsDuration);
      acc.totalOutboundAbandonedCallsDurationPatients.push(totalOutboundAbandonedCallsDurationPatients);
      acc.totalOutboundAbandonedCallsDurationUnknown.push(totalOutboundAbandonedCallsDurationUnknown);

      acc.totalCallDuration.push(totalCallDuration);

      return acc;
    },
    {
      totalInboundCalls: [],
      totalInboundCallsPatient: [],
      totalInboundCallsUnknown: [],
      totalInboundCallDuration: [],
      totalInboundCallDurationPatient: [],
      totalInboundCallDurationUnknown: [],
      totalInboundAnsweredCalls: [],
      totalInboundAnsweredCallsPatients: [],
      totalInboundAnsweredCallsUnknown: [],
      totalInboundAnsweredCallsDuration: [],
      totalInboundAnsweredCallsDurationPatients: [],
      totalInboundAnsweredCallsDurationUnknown: [],
      totalInboundMissedCalls: [],
      totalInboundMissedCallsPatients: [],
      totalInboundMissedCallsUnknown: [],
      totalInboundMissedOOOCalls: [],
      totalInboundMissedCallsDuration: [],
      totalInboundMissedCallsDurationPatients: [],
      totalInboundMissedCallsDurationUnknown: [],
      totalInboundAbandonedCalls: [],
      totalInboundAbandonedCallsPatients: [],
      totalInboundAbandonedCallsUnknown: [],
      totalInboundAbandonedCallsDuration: [],
      totalInboundAbandonedCallsDurationPatients: [],
      totalInboundAbandonedCallsDurationUnknown: [],
      inboundCallDurationMoreThan10Min: [],

      totalOutboundCalls: [],
      totalOutboundCallsPatient: [],
      totalOutboundCallsUnknown: [],
      totalOutboundCallDuration: [],
      totalOutboundCallDurationPatient: [],
      totalOutboundCallDurationUnknown: [],
      totalOutboundAnsweredCalls: [],
      totalOutboundAnsweredCallsPatients: [],
      totalOutboundAnsweredCallsUnknown: [],
      totalOutboundAnsweredCallsDuration: [],
      totalOutboundAnsweredCallsDurationPatients: [],
      totalOutboundAnsweredCallsDurationUnknown: [],
      totalOutboundAbandonedCalls: [],
      totalOutboundAbandonedCallsPatients: [],
      totalOutboundAbandonedCallsUnknown: [],
      totalOutboundAbandonedCallsDuration: [],
      totalOutboundAbandonedCallsDurationPatients: [],
      totalOutboundAbandonedCallsDurationUnknown: [],
      outboundCallDurationMoreThan10Min: [],

      totalCallDuration: [],
    }
  );

  const demoData = [
    {
      EndDate: formattedDate,
      ID: demoDataUtils.generateRandomUUID(),
      InboundCallDurationMoreThan10Min: prefillZeros(data.inboundCallDurationMoreThan10Min),
      LocationID: locationId,
      LocationName: locationData[locationId]?.name || '',
      OutboundCallDurationMoreThan10Min: prefillZeros(data.outboundCallDurationMoreThan10Min),
      PhoneNumber: demoDataUtils.generateRandomPhoneNumber(),
      StartDate: formattedDate,
      TotalCallDuration: prefillZeros(data.totalCallDuration),
      TotalInboundAbandonedCalls: prefillZeros(data.totalInboundAbandonedCalls),
      TotalInboundAbandonedCallsDuration: prefillZeros(data.totalInboundAbandonedCallsDuration),
      TotalInboundAbandonedCallsDurationPatients: prefillZeros(data.totalInboundAbandonedCallsDurationPatients),
      TotalInboundAbandonedCallsDurationUnknown: prefillZeros(data.totalInboundAbandonedCallsDurationUnknown),
      TotalInboundAbandonedCallsPatients: prefillZeros(data.totalInboundAbandonedCallsPatients),
      TotalInboundAbandonedCallsUnknown: prefillZeros(data.totalInboundAbandonedCallsUnknown),
      TotalInboundAnsweredCalls: prefillZeros(data.totalInboundAnsweredCalls),
      TotalInboundAnsweredCallsDuration: prefillZeros(data.totalInboundAnsweredCallsDuration),
      TotalInboundAnsweredCallsDurationPatients: prefillZeros(data.totalInboundAnsweredCallsDurationPatients),
      TotalInboundAnsweredCallsDurationUnknown: prefillZeros(data.totalInboundAnsweredCallsDurationUnknown),
      TotalInboundAnsweredCallsPatients: prefillZeros(data.totalInboundAnsweredCallsPatients),
      TotalInboundAnsweredCallsUnknown: prefillZeros(data.totalInboundAnsweredCallsUnknown),
      TotalInboundCallDuration: prefillZeros(data.totalInboundCallDuration),
      TotalInboundCallDurationPatient: prefillZeros(data.totalInboundCallDurationPatient),
      TotalInboundCallDurationUnknown: prefillZeros(data.totalInboundCallDurationUnknown),
      TotalInboundCalls: prefillZeros(data.totalInboundCalls),
      TotalInboundCallsPatient: prefillZeros(data.totalInboundCallsPatient),
      TotalInboundCallsUnknown: prefillZeros(data.totalInboundCallsUnknown),
      TotalInboundMissedCalls: prefillZeros(data.totalInboundMissedCalls),
      TotalInboundMissedCallsDuration: prefillZeros(data.totalInboundMissedCallsDuration),
      TotalInboundMissedCallsDurationPatients: prefillZeros(data.totalInboundMissedCallsDurationPatients),
      TotalInboundMissedCallsDurationUnknown: prefillZeros(data.totalInboundMissedCallsDurationUnknown),
      TotalInboundMissedCallsPatients: prefillZeros(data.totalInboundMissedCallsPatients),
      TotalInboundMissedCallsUnknown: prefillZeros(data.totalInboundMissedCallsUnknown),
      TotalInboundMissedOOOCalls: prefillZeros(data.totalInboundMissedOOOCalls),
      TotalOutboundAbandonedCalls: prefillZeros(data.totalOutboundAbandonedCalls),
      TotalOutboundAbandonedCallsDuration: prefillZeros(data.totalOutboundAbandonedCallsDuration),
      TotalOutboundAbandonedCallsDurationPatients: prefillZeros(data.totalOutboundAbandonedCallsDurationPatients),
      TotalOutboundAbandonedCallsDurationUnknown: prefillZeros(data.totalOutboundAbandonedCallsDurationUnknown),
      TotalOutboundAbandonedCallsPatients: prefillZeros(data.totalOutboundAbandonedCallsPatients),
      TotalOutboundAbandonedCallsUnknown: prefillZeros(data.totalOutboundAbandonedCallsUnknown),
      TotalOutboundAnsweredCalls: prefillZeros(data.totalOutboundAnsweredCalls),
      TotalOutboundAnsweredCallsDuration: prefillZeros(data.totalOutboundAnsweredCallsDuration),
      TotalOutboundAnsweredCallsDurationPatients: prefillZeros(data.totalOutboundAnsweredCallsDurationPatients),
      TotalOutboundAnsweredCallsDurationUnknown: prefillZeros(data.totalOutboundAnsweredCallsDurationUnknown),
      TotalOutboundAnsweredCallsPatients: prefillZeros(data.totalOutboundAnsweredCallsPatients),
      TotalOutboundAnsweredCallsUnknown: prefillZeros(data.totalOutboundAnsweredCallsUnknown),
      TotalOutboundCallDuration: prefillZeros(data.totalOutboundCallDuration),
      TotalOutboundCallDurationPatient: prefillZeros(data.totalOutboundCallDurationPatient),
      TotalOutboundCallDurationUnknown: prefillZeros(data.totalOutboundCallDurationUnknown),
      TotalOutboundCalls: prefillZeros(data.totalOutboundCalls),
      TotalOutboundCallsPatient: prefillZeros(data.totalOutboundCallsPatient),
      TotalOutboundCallsUnknown: prefillZeros(data.totalOutboundCallsUnknown),
    },
  ];

  return demoData;
};

const blankArray = new Array(16).fill(0);
const generateExtensionsCalls = (
  dates: ReturnType<typeof demoDataUtils.generateConsecutiveDates>,
  extensions: ReturnType<typeof demoDataUtils.generateRandomUserNames>
) => {
  return extensions.map((extension) => {
    return {
      Aggregates: dates.map((date) => {
        // Keep some values absolute zero so charts look more realistic
        const data = Math.random() > 0.5 ? blankArray : blankArray.map(() => Math.floor(Math.random() * 5));

        return {
          Date: dayjs(date).format('YYYY-MM-DD'),
          Values: prefillZeros(data),
        };
      }),
      Extention: `Dr. ${extension.firstName} ${extension.lastName}`,
    };
  });
};

export const extensionsData = (locationIds: string[]): PhoneAnalyticsTypes.ExtensionsRecordResponse => {
  const dates = demoDataUtils.generateConsecutiveDates(new Date(), 14);

  const demoData: PhoneAnalyticsTypes.ExtensionsRecordResponse['LocationIds'] = locationIds.map((id) => {
    const extensions = demoDataUtils.generateRandomUserNames(5);

    return {
      CallAnswered: generateExtensionsCalls(dates, extensions),
      CallPlaced: generateExtensionsCalls(dates, extensions),
      Id: id,
    };
  });

  return {
    LocationIds: demoData,
  };
};
