import { ReactNode, Ref } from 'react';
import { theme } from '@frontend/theme';
import { Heading, Text, TextLink } from '@frontend/design-system';

export const SettingsCard = ({
  title,
  description,
  children,
  action,
  condensed,
}: {
  title?: string;
  description?: string;
  children: ReactNode;
  action?: {
    label: string;
    disabled?: boolean;
    onClick: () => void;
    trackingId: string;
    ref: Ref<HTMLButtonElement>;
  };
  condensed?: boolean;
}) => {
  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),

        borderRadius: theme.borderRadius.medium,
        padding: theme.spacing(3),
        boxShadow: theme.shadows.floating,

        background: theme.colors.white,
      }}
    >
      {(title || description || action) && (
        <header
          style={{
            display: 'grid',
            gridTemplateAreas: '"title action" "description ."',
            gridTemplateColumns: '1fr auto',
          }}
        >
          {title && (
            <Heading level={condensed ? 3 : 2} css={{ margin: 0, gridArea: 'title' }}>
              {title}
            </Heading>
          )}
          {description && <Text css={{ gridArea: 'description' }}>{description}</Text>}
          {action && (
            <TextLink
              size='large'
              weight='bold'
              css={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                gap: theme.spacing(1),
                gridArea: 'action',
              }}
              ref={action.ref}
              onClick={action.onClick}
              trackingId={action.trackingId}
              disabled={action.disabled}
            >
              {action.label}
            </TextLink>
          )}
        </header>
      )}
      {children}
    </div>
  );
};

export const SettingsSection = ({ children, className }: { children: ReactNode; className?: string }) => {
  return (
    <section
      css={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        width: 320,
      }}
      className={className}
    >
      {children}
    </section>
  );
};
