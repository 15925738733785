import { useEffect, useMemo, useRef } from 'react';
import {
  EventType,
  TaskEvent,
} from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-tasks/onboarding_tasks.pb';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess';
import { isToday } from 'date-fns';
import { useIntakeFormShallowStore } from '@frontend/api-intake-form';
import { OnboardingModulesQueries, OnboardingModulesTypes } from '@frontend/api-onboarding-modules';
import { getUser, hasSchemaACL } from '@frontend/auth-helpers';
import { useMatchMedia, breakpoints } from '@frontend/responsiveness';
import { useAppScopeStore } from '@frontend/scope';
import { OnboardingModulesUiTypes } from '@frontend/shared';
import { getTourGuideInfo } from '..';
import { useTourGuideShallowStore, useTourGuideStore } from '../../hooks';
import { GuideNameEnum } from '../../types';

type AdvanceTourGuideOptions = {
  hasIncompleteTasks: boolean;
  setLandingPageTab: (data: OnboardingModulesUiTypes.LandingPageTab) => void;
};

export enum StepIndexNames {
  OpenDialog = 0,
  OnboardingToolTip = 1,
  TrainingToolTip = 2,
  DownloadSoftwareToolTip = 3,
  LogoToolTip = 4,
  MaxDismissalTooltip = 5,
}

const isStepIndex = (stepIndex: number | undefined, name: StepIndexNames) => {
  return stepIndex === name;
};

function handleStartFirstTimeUserExperienceGuide(hasBeenDismissedPreviously: boolean) {
  const guideInfo = getTourGuideInfo(GuideNameEnum.PortalWelcomeExperienceSetup);
  const steps = guideInfo?.getSteps() ?? [];

  if (steps.length && import.meta.env.MODE !== 'test') {
    useTourGuideStore.getState().startGuide({
      steps,
      guideName: GuideNameEnum.PortalWelcomeExperienceSetup,
      auditInfo: {
        moduleName: 'Stand Alone',
        guideVersion: guideInfo!.version,
        taskType: OnboardingModulesTypes.TaskType.User,
        taskId: OnboardingModulesUiTypes.UserTask.PortalWelcomeExperienceTask,
      },
      additionalGuideData: { hasBeenDismissedPreviously },
      skipLocalStorage: true,
    });
  }
}

export const useControlPortalWelcomeExperience = ({
  setLandingPageTab,
  hasIncompleteTasks,
}: AdvanceTourGuideOptions) => {
  const { stepIndex, isRunning } = useTourGuideShallowStore('stepIndex', 'isRunning');
  const { isShowIntakeForm } = useIntakeFormShallowStore('isShowIntakeForm');
  const user = getUser();
  const isMobile = useMatchMedia({ maxWidth: breakpoints.xsmall.max });

  const { accessibleLocationData, selectedLocationIdsWithParents } = useAppScopeStore();
  const locationId = selectedLocationIdsWithParents[0];
  const locationData = accessibleLocationData[selectedLocationIdsWithParents[0]];
  const isParentLocation = !locationData?.parentId && !!locationData?.children?.length;

  const isAdmin = hasSchemaACL(locationId, Permission.USER_WRITE);
  const guideHasRunOnceRef = useRef(false);

  const {
    data: userTasks,
    isLoading: isUserTasksLoading,
    isFetched: isUserTasksFetched,
  } = OnboardingModulesQueries.useFetchUserTasksQuery({
    enabled: isAdmin,
  });
  const {
    data: userTasksEvents,
    isLoading: isUserTasksEventsLoading,
    isFetched: isUserTasksEventsFetched,
  } = OnboardingModulesQueries.useFetchUserEventsQuery(
    {
      userId: user?.userID,
      taskId: OnboardingModulesUiTypes.UserTask.PortalWelcomeExperienceTask,
      locationId,
    },
    {
      enabled: isAdmin,
    }
  );

  const { hasBeenDismissedPreviously, hasBeenDismissedToday } = useMemo<{
    hasBeenDismissedPreviously: boolean;
    hasBeenDismissedToday: boolean;
  }>(() => {
    const userDismissedEvents: TaskEvent[] = (userTasksEvents ?? []).filter(
      ({ eventType }) => eventType === EventType.EVENT_GUIDE_DISMISS
    );
    return {
      hasBeenDismissedPreviously: !!userDismissedEvents?.length,
      hasBeenDismissedToday: userDismissedEvents.some((data) => isToday(new Date(data.createdAt))),
    };
  }, [userTasksEvents]);

  const isDataReady = useMemo(() => {
    return isUserTasksFetched && isUserTasksEventsFetched && !isUserTasksLoading && !isUserTasksEventsLoading;
  }, [isUserTasksFetched, isUserTasksEventsFetched, isUserTasksLoading, isUserTasksEventsLoading]);

  const showPortalWelcomeModal = useMemo(() => {
    const userHasCompletedTask = userTasks?.some(
      (taskStatus) =>
        !!taskStatus.completedAt && taskStatus.taskId === OnboardingModulesTypes.TaskIds.PortalWelcomeExperience
    );

    const showPortalWelcomeModal =
      isDataReady && !userHasCompletedTask && !isParentLocation && !hasBeenDismissedToday && !isShowIntakeForm;

    return showPortalWelcomeModal;
  }, [userTasks, isParentLocation, hasBeenDismissedToday, isShowIntakeForm, isDataReady]);

  useEffect(() => {
    if (showPortalWelcomeModal && !guideHasRunOnceRef.current) {
      guideHasRunOnceRef.current = true;
      handleStartFirstTimeUserExperienceGuide(hasBeenDismissedPreviously);
    }
  }, [showPortalWelcomeModal, hasBeenDismissedPreviously]);

  useEffect(() => {
    if (isStepIndex(stepIndex, StepIndexNames.TrainingToolTip)) {
      setLandingPageTab(OnboardingModulesUiTypes.LandingPageTab.Training);
    } else if (isStepIndex(stepIndex, StepIndexNames.OnboardingToolTip)) {
      setLandingPageTab(OnboardingModulesUiTypes.LandingPageTab.Onboarding);
    } else if (isMobile && isStepIndex(stepIndex, StepIndexNames.DownloadSoftwareToolTip)) {
      setLandingPageTab(OnboardingModulesUiTypes.LandingPageTab.Onboarding);
    } else if (
      isStepIndex(stepIndex, StepIndexNames.MaxDismissalTooltip) ||
      isStepIndex(stepIndex, StepIndexNames.LogoToolTip)
    ) {
      setLandingPageTab(
        hasIncompleteTasks
          ? OnboardingModulesUiTypes.LandingPageTab.Onboarding
          : OnboardingModulesUiTypes.LandingPageTab.Training
      );
    }
  }, [stepIndex, isRunning]);
};
