import React from 'react';
import { useTranslation } from '@frontend/i18n';
import { SwitchChipGroup } from '@frontend/design-system';
import { PaymentRequestFilterTypes, usePaymentRequestQuickFilter } from '../../../hooks';
import { quickFilterStyles } from '../../../styles';

export const QuickFilters = () => {
  const { t } = useTranslation('payments');
  const { isFilterActive, handleQuickFilter } = usePaymentRequestQuickFilter();

  const buttonValues: PaymentRequestFilterTypes[] = ['allInvoices', 'paid', 'refunded', 'unpaid'];

  const selectedValues = React.useMemo(() => {
    return buttonValues.filter((v) => isFilterActive(v));
  }, [isFilterActive]);

  const buttonsConfig = [
    {
      value: 'allInvoices',
      label: t('All Invoices'),
    },
    {
      value: 'paid',
      label: t('Paid'),
    },
    {
      value: 'refunded',
      label: t('Refunded'),
    },
    {
      value: 'unpaid',
      label: t('Outstanding'),
    },
  ];

  const handleOptionChange = (vs: string[] | ((prevState: string[]) => string[])) => {
    if (typeof vs === 'function') {
      vs = vs(selectedValues);
    }

    if (Array.isArray(vs)) {
      if (vs.length) {
        vs.forEach((v) => handleQuickFilter(v as PaymentRequestFilterTypes));
      } else {
        selectedValues.forEach((v) => handleQuickFilter(v));
      }
    }
  };

  return (
    <SwitchChipGroup css={quickFilterStyles.buttonContainer} value={selectedValues} setValue={handleOptionChange}>
      {buttonsConfig.map(({ label, value }) => {
        return (
          <SwitchChipGroup.Option trackingId={`pay-portal-invoices-quickfilters-${value}`} key={value} value={value}>
            {label}
          </SwitchChipGroup.Option>
        );
      })}
    </SwitchChipGroup>
  );
};
