import { AdaptoActions } from '@frontend/adapto-actions';
import { useBulkListContext } from '@frontend/bulk-list-provider';
import { useTranslation } from '@frontend/i18n';
import { InboxPrefixes } from '@frontend/tracking-prefixes';
import { BulkSelectionActionType } from '../../../types';

type ExportConversationActionProps = {
  setBulkSelectionType: (type: BulkSelectionActionType) => void;
};

export const ExportConversationAction = ({ setBulkSelectionType }: ExportConversationActionProps) => {
  const { t } = useTranslation('inbox');
  const { openSelection } = useBulkListContext();

  return (
    <AdaptoActions.Action
      label={t('Export Conversation')}
      icon='export'
      onClick={() => {
        setBulkSelectionType(BulkSelectionActionType.EXPORT_CONVERSATION);
        openSelection();
      }}
      trackingId={`${InboxPrefixes.Thread}-export-button`}
    />
  );
};
