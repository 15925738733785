import { useState } from 'react';
import { MediaApi, MediaTypes } from '@frontend/api-media';
import { useTranslation } from '@frontend/i18n';
import { useMutation } from '@frontend/react-query-helpers';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, getInitials, useAlert } from '@frontend/design-system';
import { AvatarEditor } from '../../../components/Avatar';
import { OnSaveSpinningLoader } from '../../../components/OnSaveSpinningLoader';
import { useManagePractitionerProfilePhoto } from '../../../context/ManagePractitionerProfilePhotoContext';

type PractitionerPhotoUploadModalProps = {
  modalProps: ModalControlModalProps;
};

export const PractitionerPhotoUploadModal = ({ modalProps }: PractitionerPhotoUploadModalProps) => {
  const { practitionerDetails, profilePhotoSrc, name, setProfilePhotoSrc, updatePractitionerDetails } =
    useManagePractitionerProfilePhoto();
  const alert = useAlert();
  const { t } = useTranslation('schedule');
  const [file, setFile] = useState<File>();

  const locationId = practitionerDetails?.locationId;
  const imageSrc = profilePhotoSrc ?? practitionerDetails?.profile?.picUrl ?? '';

  const { mutateAsync: uploadMedia, isLoading } = useMutation({
    mutationFn: (req: MediaTypes.UploadMediaPayload) => MediaApi.uploadMedia(req, locationId),
  });

  const handleOnSave = async () => {
    try {
      if (file) {
        const response = await uploadMedia({
          data: file,
          filename: file.name,
          type: MediaTypes.MediaUploadTypes.Gallery,
        });
        setProfilePhotoSrc(response.PublicURL);
        // Update practitioner details with profile photo if practitioner details exist
        if (practitionerDetails && practitionerDetails?.id) await updatePractitionerDetails(response.PublicURL);
        alert.success(t('Profile photo uploaded successfully'));
        modalProps.onClose();
      }
    } catch (err) {
      alert.error(t('Failed to upload profile photo'));
    }
  };

  const handleOnDelete = async () => {
    setFile(undefined);
    setProfilePhotoSrc('');
    // Update practitioner details with no profile photo if practitioner details exist
    if (practitionerDetails && practitionerDetails?.id) {
      await updatePractitionerDetails('');
      alert.success(t('Profile photo removed successfully'));
    }
    modalProps.onClose();
  };

  return (
    <Modal {...modalProps} maxWidth={600} onClose={modalProps.onClose}>
      <Modal.Header>{t('Upload Profile Photo for {{name}}', { name })}</Modal.Header>
      <Modal.Body>
        <AvatarEditor
          initials={getInitials(name)}
          uploadImageLabel={t('Select Profile Image')}
          removeImageLabel={t('Remove Profile Image')}
          alt={name}
          src={imageSrc}
          onDelete={handleOnDelete}
          onChange={(file: File) => {
            setFile(file);
          }}
        />
      </Modal.Body>
      {isLoading ? (
        <OnSaveSpinningLoader css={{ padding: theme.spacing(1, 3, 0, 0) }} />
      ) : (
        <Modal.Actions
          secondaryLabel={t('Cancel')}
          primaryLabel={t('Set Profile Image')}
          onPrimaryClick={handleOnSave}
          onSecondaryClick={modalProps.onClose}
        />
      )}
    </Modal>
  );
};
