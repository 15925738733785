import { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';
import { InvoiceModel, PaymentRefund, getInvoiceQueryKey, updateInvoiceQueryCache } from '@frontend/api-invoices';
import { RefundError, RefundReason, RefundRequestParams, requestRefund } from '@frontend/api-refunds';
import { getUser } from '@frontend/auth-helpers';
import { http } from '@frontend/fetch';
import { useMerchant, useMultiQueryUtils } from '@frontend/payments-hooks';
import { useAlert } from '@frontend/design-system';

type RefundState = {
  amount: number;
  paymentId?: string;
  reason?: typeof RefundReason;
  requested: boolean;
};

interface Params {
  invoice: InvoiceModel;
  closeModal: () => void;
}

export const useRefundRequest = ({ invoice, closeModal }: Params) => {
  const alert = useAlert();
  const { paymentsUrl } = useMerchant();
  const user = getUser();
  const queryClient = useQueryClient();
  const { locationId } = useMultiQueryUtils();

  const [error, setError] = useState<RefundError | null>(null);
  const [requestTimedOut, setRequestTimedOut] = useState(false);
  const [refundState, setRefundState] = useState<RefundState>({
    amount: 0,
    requested: false,
  });

  const reset = () => {
    setError(null);
    setRequestTimedOut(false);
    setRefundState({ amount: 0, requested: false });
  };

  const refund = useCallback(
    async (params: RefundRequestParams) => {
      if (!paymentsUrl) {
        const errorMsg = 'Tried to do a refund before payment url was set';
        setError({ error: { message: errorMsg } });
        console.error(errorMsg);
        return;
      }

      try {
        // optimistic update
        const optimisticRefund: PaymentRefund = {
          amount: params.amount,
          createdAt: new Date().toISOString(),
          failureReason: '',
          id: 'optimistic-refund-id',
          paymentId: params.paymentId ?? 'no-id',
          refundedBy: user?.userID ?? 'no-user-id',
          status: 'SUCCEEDED',
          stripeId: 'optimistic-stripe-id',
        };

        updateInvoiceQueryCache(
          queryClient,
          {
            ...invoice,
            payment: {
              ...invoice?.payment,
              hasRefund: true,
              refunds: [...(invoice?.payment?.refunds ?? []), optimisticRefund],
            },
          } as InvoiceModel,
          getInvoiceQueryKey(locationId, invoice.id)
        );

        // do the refund
        await requestRefund(paymentsUrl, params);

        // mark cache as stale
        queryClient.invalidateQueries({
          predicate: (query) =>
            !!(query.queryKey.includes('invoices') || (invoice.id && query.queryKey.includes(invoice.id))),
        });
      } catch (error) {
        if (http.isHttpError(error)) setError(error.data as RefundError);
        if (http.isHttpError(error) && [408, 504].includes(error.status)) setRequestTimedOut(true);
        else {
          alert.error('Refund failed. Please try again.');
          closeModal();
        }
        updateInvoiceQueryCache(queryClient, invoice, getInvoiceQueryKey(locationId, invoice.id));
      } finally {
        setRefundState({
          amount: params.amount,
          requested: true,
        });
      }
    },
    [paymentsUrl, invoice]
  );

  return {
    error,
    refund,
    reset,
    refundState,
    requestTimedOut,
  };
};
