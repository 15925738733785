import { isEqual } from 'lodash-es';
import { PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';
import { usePracticeAnalyticsDemoData } from './use-practice-analytics-demo-data';

interface PracticeAnalyticsStore {
  defaultFilters: PracticeAnalyticsTypes.Filters;
  demoData: ReturnType<typeof usePracticeAnalyticsDemoData>;
  filterHintText: string;
  filters: PracticeAnalyticsTypes.Filters;
  hasCustomFilters: boolean;
  isDemoAccount?: boolean;
  isDemoModal?: boolean;
  isPageReady?: boolean;
  locationIds: string[];
  showDemoChipAndBanner?: boolean;

  setDefaultFilters: (defaultFilters: Partial<PracticeAnalyticsTypes.Filters>) => void;
  setDemoData: (demoData: ReturnType<typeof usePracticeAnalyticsDemoData>) => void;
  setFilterHintText: (filterHintText: string) => void;
  setFilters: (filters: Partial<PracticeAnalyticsTypes.Filters>) => void;
  setIsDemoAccount: (isDemoAccount?: boolean) => void;
  setIsDemoModal: (isDemoModal?: boolean) => void;
  setIsPageReady: (isPageReady: boolean) => void;
  setLocationIds: (locationIds: string[]) => void;

  resetStore: () => void;
}

const initialState = {
  defaultFilters: {},
  demoData: null,
  filterHintText: '',
  filters: {},
  hasCustomFilters: false,
  isDemoAccount: undefined,
  isDemoModal: false,
  isPageReady: true,
  locationIds: [],
  showDemoChipAndBanner: false,
};

const usePracticeAnalyticsStore = createStoreWithSubscribe<PracticeAnalyticsStore>(
  (set, get) => ({
    ...initialState,
    setDefaultFilters: (defaultFilters) => {
      set({ defaultFilters });
    },
    setDemoData: (demoData) => {
      set({ demoData });
    },
    setFilterHintText: (filterHintText) => {
      set({ filterHintText });
    },
    setFilters: (filters) => {
      const { defaultFilters, filters: currentFilters } = get();
      const newFilters: PracticeAnalyticsTypes.Filters = { ...currentFilters, ...filters };

      set({
        filters: newFilters,
        hasCustomFilters: Object.keys(newFilters).some(
          (key) =>
            !isEqual(
              newFilters[key as keyof PracticeAnalyticsTypes.Filters],
              defaultFilters[key as keyof PracticeAnalyticsTypes.Filters]
            )
        ),
      });
    },
    setIsDemoAccount: (isDemoAccount) => {
      set({
        isDemoAccount,
        showDemoChipAndBanner: !get().isDemoModal && isDemoAccount,
      });
    },
    setIsDemoModal: (isDemoModal) => {
      set({
        isDemoModal,
        showDemoChipAndBanner: !isDemoModal && get().isDemoAccount,
      });
    },
    setIsPageReady: (isPageReady) => {
      set({ isPageReady });
    },
    setLocationIds: (locationIds) => {
      set({ locationIds });
    },

    resetStore: () => {
      set({
        ...initialState,
      });
    },
  }),
  {
    name: 'PracticeAnalyticsStore',
    trace: true,
  }
);

export const usePracticeAnalyticsShallowStore = createShallowStore<PracticeAnalyticsStore>(usePracticeAnalyticsStore);
