import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { useFormBuilderStore } from '../../../../provider';
import { DivisionCategory } from '../../../../types/field-divisions';

interface UseFilteredCategoriesProps<T> {
  categories: T[];
}

interface UseFilteredCategoriesResults<T> {
  filteredCategories: T[];
  setCompleteCategories: Dispatch<SetStateAction<T[]>>;
}

export const useFilteredCategories = <T extends DivisionCategory>({
  categories,
}: UseFilteredCategoriesProps<T>): UseFilteredCategoriesResults<T> => {
  // Used to hold the complete set of categories for filtering
  const [completeCategories, setCompleteCategories] = useState<T[]>(categories);
  const [filteredCategories, setFilteredCategories] = useState<T[]>(categories);
  const { searchFieldTerm } = useFormBuilderStore(['searchFieldTerm']);

  useEffect(() => {
    if (searchFieldTerm.trim() === '') {
      setFilteredCategories(completeCategories);
      return;
    }

    const filteredCategories = completeCategories.map((category) => {
      const filteredFields = category.fieldsOrder.filter((field) =>
        field.key.toLowerCase().includes(searchFieldTerm.toLowerCase())
      );
      return {
        ...category,
        fieldsOrder: filteredFields,
      };
    });

    setFilteredCategories(filteredCategories);
  }, [searchFieldTerm, completeCategories]);

  return {
    filteredCategories,
    setCompleteCategories,
  };
};
