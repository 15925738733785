import { useRef } from 'react';

type CancelledPaymentIntent = { readerId: string | undefined; paymentIntentId: string; paymentCanceled: boolean };

export const useCanceledIntentRef = () => {
  const cancelledPaymentIntent = useRef<CancelledPaymentIntent>();

  const isCancelationStarted = (readerId?: string, paymentIntentId?: string) => {
    const cancelledReaderId = cancelledPaymentIntent.current?.readerId;
    const cancelledPaymentIntentId = cancelledPaymentIntent.current?.paymentIntentId;
    if (
      readerId &&
      paymentIntentId &&
      cancelledReaderId &&
      cancelledPaymentIntentId &&
      cancelledReaderId === readerId &&
      cancelledPaymentIntentId === paymentIntentId
    ) {
      return true;
    }
    return false;
  };

  const setCancelationStarted = (readerId: string | undefined, paymentIntentId: string) => {
    cancelledPaymentIntent.current = {
      readerId,
      paymentIntentId,
      paymentCanceled: false,
    };
  };

  const setCancelationCompleted = () => {
    if (cancelledPaymentIntent.current) {
      cancelledPaymentIntent.current = {
        ...cancelledPaymentIntent.current,
        paymentCanceled: true,
      };
    }
  };

  const isIntentCanceled = (paymentIntentId: string) =>
    paymentIntentId &&
    cancelledPaymentIntent.current &&
    cancelledPaymentIntent.current.paymentIntentId === paymentIntentId;

  return {
    setCancelationStarted,
    setCancelationCompleted,
    isCancelationStarted,
    isIntentCanceled,
  };
};
