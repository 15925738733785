import { useNavigate } from '@tanstack/react-location';
import { DashboardWidget, DashboardWidgetFC, useDashboardWidget } from '@frontend/grid-dashboard';
import { useTranslation } from '@frontend/i18n';

/**
 * @dashboard-widget
 *
 * id: add-message
 * title: New Text
 * description: Create and send a new message with just one click.
 * icon: message-small
 */

export const AddMessageWidget: DashboardWidgetFC = () => {
  const { t } = useTranslation('messages');
  const { widgetTrackingId } = useDashboardWidget();
  const navigate = useNavigate();

  return (
    <DashboardWidget.ActionButton
      iconName='kiosk'
      // TODO: Milind change title based on outbound message count when BE api is ready
      title={t('Send message')}
      onClick={() => navigate({ to: '/messages/inbox/new' })}
      trackingId={widgetTrackingId('action')}
    />
  );
};

AddMessageWidget.config = {
  size: 'tiny-slim',
  feature: 'messaging',
};
