import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { SelectedLocationProvider } from '@frontend/payments-hooks';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { MultiStepModal } from '@frontend/payments-multistep-modal';
import { theme } from '@frontend/theme';
import { ContentLoader, ModalControlModalProps } from '@frontend/design-system';
import { CollectPaymentModalBody } from './collect-payment-body';
import { CollectPaymentModalSteps } from './hooks';

interface CollectPaymentModalProps {
  modalProps: ModalControlModalProps;
  closeModal: () => void;
  invoiceId?: string;
  skipToStep?: CollectPaymentModalSteps;
}

export const CollectPaymentModal = ({ modalProps, closeModal, invoiceId, skipToStep }: CollectPaymentModalProps) => {
  const { invoice, isLoading } = useSelectedInvoice(invoiceId);

  FeatureFlagQueries.useAggregateFeatureFlagQuery({
    flagName: 'payments:nwx:server-driven-terminals',
    locationIds: [invoice?.locationId ?? ''],
  });

  if (!invoice || isLoading) return <ContentLoader show={true} />;
  return (
    <SelectedLocationProvider locationId={invoice.locationId}>
      <MultiStepModal
        modalProps={modalProps}
        closeModal={closeModal}
        initialStep={skipToStep || CollectPaymentModalSteps.PaymentFlowList}
        maxWidth={parseInt(theme.spacing(82))}
      >
        <CollectPaymentModalBody />
      </MultiStepModal>
    </SelectedLocationProvider>
  );
};
