import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { OnboardingEvent } from '@weave/schema-gen-ts/dist/shared/insys/activation/onboarding.pb';
import { useQueryClient } from 'react-query';
import urlRegex from 'url-regex';
import { BusinessInfoApi, BusinessInfoTypes, BusinessInfoQueries } from '@frontend/api-business-information';
import { LocationsApi } from '@frontend/api-locations';
import { OnboardingModulesApi } from '@frontend/api-onboarding-modules';
import { SuccessGraphic } from '@frontend/assets';
import { CoreACLs, hasACL } from '@frontend/auth-helpers';
import { Trans, useTranslation } from '@frontend/i18n';
import { useLocationDataShallowStore } from '@frontend/location-helpers';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { useQuery } from '@frontend/react-query-helpers';
import { titleCase } from '@frontend/string';
import { sentry } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import {
  AlertInvertIcon,
  ClockIcon,
  ConfirmBadgeIcon,
  ErrorBadgeIcon,
  Icon,
  PrimaryButton,
  SpinningLoader,
  Text,
  TextLink,
  WarningBadgeIcon,
  useAlert,
} from '@frontend/design-system';
import { BusinessInfoForm } from './business-information-form';

const WEAVE_BRAND_ID = 'B6LGYLE';
const UPDATE_KEYS_FOR_BRAND_MATCHING: (keyof Omit<
  BusinessInfoTypes.UpdateTCRBrandRequest,
  'reverify' | 'overrideLock'
>)[] = [
  'city',
  'companyName',
  'country',
  'displayName',
  'ein',
  'einIssuingCountry',
  'email',
  'firstName',
  'lastName',
  'phone',
  'postalCode',
  'state',
  'street',
  'vertical',
  'website',
];

const formatPostalCode = (country: BusinessInfoTypes.Country, postalCode: string) => {
  if (postalCode.length < 6 || country !== BusinessInfoTypes.Country.COUNTRY_US) return postalCode;
  return postalCode.slice(0, 5) + '-' + postalCode.slice(5);
};

enum VerificationStatus {
  NEW = 'new',
  VERIFIED = 'verified',
  UNVERIFIED = 'unverified',
  PENDING = 'pending',
}

enum CurrentPageView {
  FORM = 'form',
  BRAND = 'brand',
  LOADING = 'loading',
  SUCCESS = 'success',
  FAILED = 'failed',
  LOCK = 'lock',
  VENDOR_ERROR = 'vendor error',
  PENDING = 'pending',
}

interface BusinessInformationTenDLCProps {
  locationId: string;
  initialValues?: Partial<BusinessInfoTypes.BusinessInfoFormValues>;
  // NOTE: this event is used by onboarding intake-form to enable/disable button of intake-form
  // next button in intake-form enabled if
  // - VerifiedPage visible (isVerifiedPageVisible=true) or
  // - LockedPage visible (isLockedPageVisible=true) or
  // - form submit attempts were more than 2
  onFormStatusChange?: (status: {
    isVerifiedPageVisible: boolean;
    isLockedPageVisible: boolean;
    formSubmitAttempts: number;
  }) => void;
  debug?: BusinessInfoTypes.TenDLCDebugConfig;
  isDemoMode?: boolean;
}

export const BusinessInformationTenDLC = ({
  locationId,
  initialValues,
  onFormStatusChange,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  debug,
  isDemoMode,
}: BusinessInformationTenDLCProps) => {
  // Current session data
  const { isMultiLocation } = useLocationDataShallowStore('isMultiLocation');
  const queryClient = useQueryClient();

  const { t } = useTranslation('business-info');
  const genericAlertErrorMessage = t('Something went wrong with the form submission. Please try again.');
  const { data, refetch } = useQuery({
    queryKey: [locationId, 'users', 'businessInfo'],
    enabled: !!locationId,
    queryFn: async () => {
      if (isDemoMode || (debug?.debugEnabled && debug?.useNewBrand && formSubmitAttempts === 0))
        return Promise.resolve({ locationId, tcrBrand: {} as BusinessInfoTypes.TCRBrand });
      const trueLocation = await BusinessInfoApi.getLocationTcrBrand({ locationId });
      if (debug?.debugEnabled && debug.rejectEin && trueLocation.tcrBrand?.brandId)
        return Promise.resolve({
          locationId: trueLocation.locationId,
          tcrBrand: {
            ...trueLocation.tcrBrand,
            identityStatus: BusinessInfoTypes.TCRBrandIdentityStatus.TCR_BRAND_IDENTITY_STATUS_UNVERIFIED,
          },
        });
      return trueLocation;
    },
    onSuccess: ({ tcrBrand }) => updatePageView(tcrBrand),
    refetchOnWindowFocus: false,
  });
  const { data: familyLocationTcrBrands } = useQuery({
    queryKey: [locationId, 'familyBrands', 'locationTcrBrands'],
    enabled: !!locationId && isMultiLocation,
    queryFn: async () => {
      const familyLocations = await LocationsApi.getFamilyLocations(locationId);
      const locationTcrBrands = await Promise.all(
        familyLocations
          .filter((location) => location.LocationID !== locationId)
          .map((familyLocation) => BusinessInfoApi.getLocationTcrBrand({ locationId: familyLocation.LocationID }))
      );
      return locationTcrBrands;
    },
    refetchOnWindowFocus: false,
  });

  // State
  const alert = useAlert();
  const [formValues, setFormValues] = useState<Partial<BusinessInfoTypes.BusinessInfoFormValues> | undefined>(
    initialValues
  );
  const [hasTcrBrand, setHasTcrBrand] = useState(data && data.tcrBrand ? !!data.tcrBrand.brandId : false);
  const [verificationStatus, setVerificationStatus] = useState<VerificationStatus>(VerificationStatus.NEW);
  const [currentPageView, setCurrentPageView] = useState<CurrentPageView>(CurrentPageView.LOADING);
  const [formSubmitAttempts, setFormSubmitAttempts] = useState(0);

  // Computed fields
  const hasLocationWritePermission = hasACL(locationId, CoreACLs.WRITE_LOCATIONS);
  const isVerifiedPageVisible =
    (verificationStatus === VerificationStatus.VERIFIED && currentPageView === CurrentPageView.BRAND) ||
    currentPageView === CurrentPageView.PENDING;
  const isLockedPageVisible = currentPageView === CurrentPageView.LOCK && !hasLocationWritePermission;

  // Functions
  const identityStatusIsVerified = (brand?: BusinessInfoTypes.TCRBrand): boolean => {
    if (!brand?.identityStatus) {
      return false;
    } else if (brand.entityType === BusinessInfoTypes.EntityType.ENTITY_TYPE_SOLE_PROPRIETOR) {
      // temporarily ignore identityStatus for SP brands until we implement full SP verification flow.
      return true;
    }

    return (
      brand.identityStatus === BusinessInfoTypes.TCRBrandIdentityStatus.TCR_BRAND_IDENTITY_STATUS_VERIFIED ||
      brand.identityStatus === BusinessInfoTypes.TCRBrandIdentityStatus.TCR_BRAND_IDENTITY_STATUS_VETTED_VERIFIED ||
      brand.identityStatus === BusinessInfoTypes.TCRBrandIdentityStatus.TCR_BRAND_IDENTITY_STATUS_SELF_DECLARED
    );
  };

  const brandUpdateShouldBeReverified = (changedValues: Partial<BusinessInfoTypes.BusinessInfoFormValues>) => {
    if (!data?.tcrBrand) return false;
    const companyNameOrAddressIsUpdated = !!(
      changedValues.companyName ||
      (changedValues.street && changedValues.street !== data.tcrBrand.street) ||
      changedValues.city ||
      changedValues.state ||
      changedValues.postalCode
    );
    return !identityStatusIsVerified(data?.tcrBrand) && companyNameOrAddressIsUpdated;
  };

  const handleDebugModeForEndpointResponse = <
    T extends
      | BusinessInfoTypes.RegisterPrivateProfitBrandResponse
      | BusinessInfoTypes.RegisterSoleProprietorBrandResponse
      | BusinessInfoTypes.UpdateTCRBrandResponse
  >(
    response: T
  ): T => {
    if (debug?.debugEnabled && debug.rejectEin)
      return {
        brand: {
          ...response,
          identityStatus: BusinessInfoTypes.TCRBrandIdentityStatus.TCR_BRAND_IDENTITY_STATUS_UNVERIFIED,
        },
      } as typeof response;
    return response;
  };

  const handleApiPost = (
    submittedValues: BusinessInfoTypes.BusinessInfoFormValues,
    changedValues: Partial<BusinessInfoTypes.BusinessInfoFormValues>
  ) => {
    const inferredEntityType = submittedValues.soleProprietor
      ? BusinessInfoTypes.EntityType.ENTITY_TYPE_SOLE_PROPRIETOR
      : BusinessInfoTypes.EntityType.ENTITY_TYPE_PRIVATE_PROFIT;
    const isSwitchingFromSoleProprietorToPrivateProfit =
      changedValues &&
      'soleProprietor' in changedValues &&
      !changedValues.soleProprietor &&
      data?.tcrBrand?.entityType === BusinessInfoTypes.EntityType.ENTITY_TYPE_SOLE_PROPRIETOR;

    const postType = hasTcrBrand
      ? isSwitchingFromSoleProprietorToPrivateProfit
        ? BusinessInfoTypes.EntityType.ENTITY_TYPE_PRIVATE_PROFIT
        : undefined
      : inferredEntityType;
    setCurrentPageView(CurrentPageView.LOADING);

    if (postType === BusinessInfoTypes.EntityType.ENTITY_TYPE_PRIVATE_PROFIT) {
      BusinessInfoApi.postPrivateProfitAsync({
        companyName: submittedValues.companyName,
        displayName: submittedValues.displayName!,
        ein: submittedValues.ein!,
        einIssuingCountry: submittedValues.country,
        phone: `+1${submittedValues.phone}`,
        street: `${submittedValues.street} ${submittedValues.street2 ?? ''}`.trim(),
        city: submittedValues.city,
        state: submittedValues.state,
        postalCode: formatPostalCode(submittedValues.country, submittedValues.postalCode),
        country: submittedValues.country,
        email: submittedValues.email,
        website: submittedValues.website,
        vertical: submittedValues.vertical,
        locationId,
        // Use tcrStaging: true when testing to avoid creating TCR records and incurring costs
        tcrStaging: debug?.debugEnabled || submittedValues.country === BusinessInfoTypes.Country.COUNTRY_CA,
      })
        .then((response) => {
          if (!response.brand?.brandId) {
            sentry.error({
              error: 'Received no brandId from the RegisterPrivateProfitAsync endpoint',
              topic: 'messages',
            });
            alert.error({
              message: t('Something went wrong with the form submission. Please try again.'),
            });
            return;
          }

          // If the brand registration response comes back as UNSPECIFIED, use the async flow instead
          if (
            response.brand &&
            (response.brand.identityStatus === undefined ||
              response.brand.identityStatus ===
                BusinessInfoTypes.TCRBrandIdentityStatus.TCR_BRAND_IDENTITY_STATUS_UNSPECIFIED)
          ) {
            BusinessInfoApi.putAssignLocationTcrBrand({
              locationId,
              brandId: response.brand.brandId,
            })
              .catch((err) => {
                console.error(err);
              })
              .finally(() => {
                setTimeout(() => {
                  queryClient.invalidateQueries([
                    locationId,
                    BusinessInfoQueries.queryKeys.getLocationTcrBrand(locationId),
                  ]);
                }, 120000);
                handleRegistrationResponse(response);
              });
          } else {
            // Associate created tcrBrand with the current locationId
            BusinessInfoApi.putConnectLocationTcrBrand({
              locationId,
              brandId: response?.brand?.brandId,
            })
              .catch((err) => {
                console.error(err);
              })
              .finally(() => {
                handleRegistrationResponse(response);
              });
          }
        })
        .catch(handleRegistrationError);
    } else if (postType === BusinessInfoTypes.EntityType.ENTITY_TYPE_SOLE_PROPRIETOR) {
      if (!submittedValues.firstName || !submittedValues.lastName) {
        alert.warning(t('First name and last name are required'));
        return;
      }
      BusinessInfoApi.postSoleProprietor({
        firstName: submittedValues.firstName,
        lastName: submittedValues.lastName,
        companyName: submittedValues.companyName,
        phone: `+1${submittedValues.phone}`,
        street: `${submittedValues.street} ${submittedValues.street2 ?? ''}`.trim(),
        city: submittedValues.city,
        state: submittedValues.state,
        postalCode: formatPostalCode(submittedValues.country, submittedValues.postalCode),
        country: submittedValues.country,
        email: submittedValues.email,
        website: submittedValues.website,
        vertical: submittedValues.vertical,
        locationId,
        // Use tcrStaging: true when testing to avoid creating TCR records and incurring costs
        tcrStaging: true,
      })
        .then((response) => {
          if (!response.brand?.brandId) {
            sentry.error({
              error: 'Received no brandId from the RegisterPrivateProfitAsync endpoint',
              topic: 'messages',
            });
            alert.error({
              message: t('Something went wrong with the form submission. Please try again.'),
            });
            return;
          }

          // Associate created tcrBrand with the current locationId (maybe should be handled by post action in the first place in order to have a more elegant way to determine when the data should be refetched?)
          BusinessInfoApi.putConnectLocationTcrBrand({
            locationId,
            brandId: response?.brand?.brandId,
          })
            .catch((err) => {
              console.error(err);
            })
            .finally(() => {
              handleRegistrationResponse(response);
            });
        })
        .catch(handleRegistrationError);
    } else {
      if (!data?.tcrBrand?.brandId) {
        alert.error(t('An error occurred in retreiving the brandId. Please refresh the page and try again.'));
        return;
      }
      const combinedStreet = `${(changedValues.street || formValues?.street) ?? ''} ${
        (changedValues.street2 || formValues?.street2) ?? ''
      }`.trim();
      const resolvedCountry = changedValues.country ?? submittedValues.country;

      const updatePayload: BusinessInfoTypes.UpdateTCRBrandRequest = {
        brandId: data.tcrBrand.brandId,
        reverify: brandUpdateShouldBeReverified(changedValues),
        firstName: changedValues.firstName,
        lastName: changedValues.lastName,
        companyName: changedValues.companyName,
        displayName: changedValues.displayName || submittedValues.displayName,
        ein: changedValues.ein,
        country: changedValues.country,
        einIssuingCountry: changedValues.country,
        phone: changedValues.phone,
        street: combinedStreet !== data.tcrBrand.street ? combinedStreet : undefined,
        city: changedValues.city,
        state: changedValues.state,
        postalCode: changedValues.postalCode ? formatPostalCode(resolvedCountry, changedValues.postalCode) : undefined,
        email: changedValues.email,
        website: changedValues.website,
        vertical: changedValues.vertical,
        overrideLock: hasLocationWritePermission,
        tcrStaging: data.tcrBrand.tcrStaging,
      };

      const countOfTrulyChangedValues = data.tcrBrand
        ? UPDATE_KEYS_FOR_BRAND_MATCHING.reduce(
            (prev, key) => (data.tcrBrand![key] !== updatePayload[key] ? prev + 1 : prev),
            0
          )
        : 0;

      if (!countOfTrulyChangedValues) {
        alert.warning(t('Cannot update when no values have changed.'));
        setCurrentPageView(CurrentPageView.FORM);
        return;
      }

      BusinessInfoApi.updateBusinessInfo(updatePayload)
        .then((response) => {
          if (!response.brand?.brandId) {
            sentry.error({
              error: 'Received no brandId from the RegisterPrivateProfitAsync endpoint',
              topic: 'messages',
            });
            alert.error({
              message: t('Something went wrong with the form submission. Please try again.'),
            });
            return;
          }

          BusinessInfoApi.putConnectLocationTcrBrand({
            locationId,
            brandId: response?.brand?.brandId,
          })
            .catch((err) => {
              console.error(err);
            })
            .finally(() => {
              handleRegistrationResponse(response);
            });
        })
        .catch(handleRegistrationError);
    }
  };

  const handleFamilyLocationEinMatch = async (brand: BusinessInfoTypes.TCRBrand) => {
    if (brand.brandId) {
      try {
        await BusinessInfoApi.putConnectLocationTcrBrand({
          locationId,
          brandId: brand.brandId,
        });
      } catch (err) {
        console.error(err);
      }
      if (identityStatusIsVerified(brand)) {
        setVerificationStatus(VerificationStatus.VERIFIED);
        setCurrentPageView(CurrentPageView.SUCCESS);
      } else {
        setVerificationStatus(VerificationStatus.UNVERIFIED);
        setCurrentPageView(CurrentPageView.FAILED);
      }
      setFormValues(undefined);
    } else {
      alert.error(genericAlertErrorMessage);
    }
  };

  const handleFormSubmit = async (
    formValues: BusinessInfoTypes.BusinessInfoFormValues,
    changedValues: Partial<BusinessInfoTypes.BusinessInfoFormValues>
  ) => {
    if (isDemoMode) {
      setVerificationStatus(VerificationStatus.VERIFIED);
      setCurrentPageView(CurrentPageView.SUCCESS);
      return;
    }
    setFormSubmitAttempts((count) => count + 1);
    setFormValues(formValues);
    handleApiPost(formValues, changedValues);
  };

  useEffect(() => {
    if (currentPageView === CurrentPageView.FAILED) {
      if (data && data.tcrBrand && data.tcrBrand.brandId) {
        BusinessInfoApi.getLockStatus({ brandId: data.tcrBrand.brandId })
          .then((res) => {
            if (res.locked && !hasLocationWritePermission) {
              setCurrentPageView(CurrentPageView.LOCK);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    }
  }, [currentPageView]);

  useEffect(() => {
    onFormStatusChange?.({
      formSubmitAttempts,
      isVerifiedPageVisible,
      isLockedPageVisible,
    });
  }, [isVerifiedPageVisible, isLockedPageVisible, formSubmitAttempts]);

  useEffect(() => {
    if (debug?.useNewBrand) {
      setFormSubmitAttempts(0);
      setHasTcrBrand(false);
    }
    refetch();
  }, [debug]);

  const publishOnboardingEventForEINTaskComplete = async () => {
    await OnboardingModulesApi.publishOnboardingEvent({
      slug: '', // TODO: update this when we have a slug
      locationId: locationId,
      onboardingEvent: OnboardingEvent.ONBOARDING_EVENT_BUSINESS_INFORMATION_TASK_COMPLETED,
    });
  };

  const handleRegistrationResponse = (
    response:
      | BusinessInfoTypes.RegisterPrivateProfitBrandResponse
      | BusinessInfoTypes.RegisterSoleProprietorBrandResponse
      | BusinessInfoTypes.UpdateTCRBrandResponse
  ) => {
    refetch();

    const resolvedResponse = handleDebugModeForEndpointResponse(response);

    if (debug?.debugEnabled && debug.rejectEin) {
      setVerificationStatus(VerificationStatus.UNVERIFIED);
      setCurrentPageView(CurrentPageView.FAILED);
    }
    if (resolvedResponse.brand?.identityStatus) {
      if (identityStatusIsVerified(resolvedResponse.brand)) {
        publishOnboardingEventForEINTaskComplete();
        setVerificationStatus(VerificationStatus.VERIFIED);
        setCurrentPageView(CurrentPageView.SUCCESS);
      } else if (
        resolvedResponse.brand &&
        resolvedResponse.brand.entityType === BusinessInfoTypes.EntityType.ENTITY_TYPE_PRIVATE_PROFIT &&
        (resolvedResponse.brand.identityStatus === undefined ||
          resolvedResponse.brand.identityStatus ===
            BusinessInfoTypes.TCRBrandIdentityStatus.TCR_BRAND_IDENTITY_STATUS_UNSPECIFIED)
      ) {
        setVerificationStatus(VerificationStatus.PENDING);
        setCurrentPageView(CurrentPageView.PENDING);
      } else {
        setVerificationStatus(VerificationStatus.UNVERIFIED);
        setCurrentPageView(CurrentPageView.FAILED);
      }
    } else {
      setVerificationStatus(VerificationStatus.PENDING);
      setCurrentPageView(CurrentPageView.PENDING);
    }
    setFormValues(undefined);
  };

  const handleRegistrationError = (err: any) => {
    if (
      err.data &&
      typeof err.data === 'string' &&
      (err.data.includes('brand registration is temporarily unavailable, please try again later') ||
        err.data.includes('brand vetting is temporarily unavailable, please try again later'))
    ) {
      setCurrentPageView(CurrentPageView.VENDOR_ERROR);
    } else {
      console.error(err);
      alert.error(genericAlertErrorMessage);
      setCurrentPageView(CurrentPageView.FORM);
    }
  };

  const updatePageView = async (tcrBrand?: BusinessInfoTypes.TCRBrand) => {
    if (!tcrBrand) {
      return;
    }

    if (!Object.keys(tcrBrand).length || !tcrBrand.brandId) {
      setVerificationStatus(VerificationStatus.NEW);
      if (formSubmitAttempts === 0) setCurrentPageView(CurrentPageView.FORM);

      return;
    }
    setHasTcrBrand(true);

    const lockStatus = await BusinessInfoApi.getLockStatus({ brandId: tcrBrand.brandId });

    if (lockStatus.locked && !hasLocationWritePermission) {
      setVerificationStatus(VerificationStatus.UNVERIFIED);
      setCurrentPageView(CurrentPageView.LOCK);
    } else if (identityStatusIsVerified(tcrBrand)) {
      setVerificationStatus(VerificationStatus.VERIFIED);
      if (formSubmitAttempts === 0) setCurrentPageView(CurrentPageView.BRAND);
    } else if (
      tcrBrand.entityType === BusinessInfoTypes.EntityType.ENTITY_TYPE_PRIVATE_PROFIT &&
      (tcrBrand.identityStatus === undefined ||
        tcrBrand.identityStatus === BusinessInfoTypes.TCRBrandIdentityStatus.TCR_BRAND_IDENTITY_STATUS_UNSPECIFIED)
    ) {
      setVerificationStatus(VerificationStatus.PENDING);
      setCurrentPageView(CurrentPageView.PENDING);
    } else {
      setVerificationStatus(VerificationStatus.UNVERIFIED);
      if (formSubmitAttempts === 0) setCurrentPageView(CurrentPageView.FORM);
    }
  };

  // Show lock state: ACL access will override lock state
  if (isLockedPageVisible) {
    return (
      <div css={styles.locked}>
        <AlertInvertIcon color='warn' size={100} />
        <Trans t={t}>
          <Text textAlign='center'>
            You have exceeded the maximum number of failed attempts. Please find your IRS SS-4 confirmation letter or
            your Letter 147C, then contact support at{' '}
            <TextLink to='mailto:documents@getweave.com?subject=10DLC Registration Issue - Max Failed Attempts'>
              documents@getweave.com
            </TextLink>{' '}
            or call <TextLink to='tel:8885795668'>888-579-5668</TextLink> to finish your registration.
          </Text>
          <Text weight='semibold' textAlign='center'>
            For information on how to find your IRS SS-4 confirmation letter or Letter 147C, read more{' '}
            <TextLink
              rel='noreferrer'
              target='_blank'
              href='https://www.weavehelp.com/hc/en-us/articles/4405882188955-Text-Messaging-Brand-Registration-FAQ#where-can-i-find-my-ein--0-7'
            >
              here
            </TextLink>
            .
          </Text>
        </Trans>
      </div>
    );
  }

  // Brands that use the TCR staging environment automatically have a "verified" status, but are not actually verified
  // Only private profit brands that are US brands are actually "verified"
  const isTrulyVerified =
    data?.tcrBrand?.entityType === BusinessInfoTypes.EntityType.ENTITY_TYPE_PRIVATE_PROFIT &&
    data?.tcrBrand?.country === BusinessInfoTypes.Country.COUNTRY_US &&
    (data?.tcrBrand?.identityStatus === BusinessInfoTypes.TCRBrandIdentityStatus.TCR_BRAND_IDENTITY_STATUS_VERIFIED ||
      data?.tcrBrand?.identityStatus ===
        BusinessInfoTypes.TCRBrandIdentityStatus.TCR_BRAND_IDENTITY_STATUS_VETTED_VERIFIED);

  const isMissingWebsite = !data?.tcrBrand?.website;

  // temporary prompt to show some businesses to update their business website
  // will be removed after they are changed
  const isValidBusinessWebsite = () => {
    if (!data?.tcrBrand?.website) return false;
    const forbiddenDomains = ['google.com', 'yahoo.com', 'hotmail.com', 'gmail.com'];
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const lowerCaseWebsite = data?.tcrBrand?.website?.toLowerCase();

    return (
      !forbiddenDomains.some((domain) => lowerCaseWebsite.includes(domain)) &&
      !emailRegex.test(lowerCaseWebsite) &&
      urlRegex({ exact: true, strict: false }).test(lowerCaseWebsite)
    );
  };

  const invalidWebsitePrompt = isMissingWebsite
    ? 'This business is missing a website'
    : 'This business website is invalid';

  const isWeaveBrand = data?.tcrBrand?.brandId && data.tcrBrand.brandId === WEAVE_BRAND_ID;
  const brandIsShared =
    data?.tcrBrand?.brandId &&
    (isWeaveBrand ||
      (familyLocationTcrBrands &&
        familyLocationTcrBrands
          .map((locationTcrBrand) => locationTcrBrand.tcrBrand?.brandId)
          .includes(data.tcrBrand.brandId)));
  const websiteIncludesHttp =
    data?.tcrBrand?.website &&
    (data.tcrBrand.website.includes('http://') || data.tcrBrand.website.includes('https://'));

  // Return various layout states
  switch (currentPageView) {
    case CurrentPageView.LOADING:
      return (
        <div css={styles.loading}>
          <SpinningLoader size='xl' />
          {formSubmitAttempts > 0 && <Text>{t('Verifying submitted information...')}</Text>}
        </div>
      );
    case CurrentPageView.SUCCESS:
      return (
        <div css={styles.success.wrapper}>
          <Icon size={250}>
            <SuccessGraphic />
          </Icon>
          <Text weight='bold' css={styles.success.header}>
            {t('Your information was successfully verified')}
          </Text>
          <PrimaryButton
            size='tiny'
            trackingId='portal-business-info_success-btn'
            onClick={() => {
              refetch();
              setCurrentPageView(CurrentPageView.BRAND);
            }}
            data-test-id='success-return-to-form'
          >
            {t('Return to form')}
          </PrimaryButton>
        </div>
      );
    case CurrentPageView.PENDING:
      return (
        <div css={styles.failed.wrapper}>
          <ClockIcon size={100} color='warn' />
          <div css={styles.failed.message}>
            <Text weight='bold' css={styles.failed.header} data-test-id='pending-state-text'>
              {t('Your business information has been submitted for verification.')}
            </Text>
            <Text>
              {t(
                'This process can take up to 24 hours. Please check this page later to ensure your registration is successful.'
              )}
            </Text>
          </div>
        </div>
      );
    case CurrentPageView.FAILED:
      return (
        <div css={styles.failed.wrapper}>
          <ErrorBadgeIcon size={100} color='error' />
          <div css={styles.failed.message}>
            <Text weight='bold' css={styles.failed.header}>
              {t('Your business information could not be verified.')}
            </Text>
            <Text>
              <Trans t={t}>
                Ensure that the information you have entered <i>exactly</i> matches the legal business name, address,
                and EIN found on your IRS SS-4 confirmation letter or Letter 147C. For information on how to find these,
                see
              </Trans>
              <TextLink
                rel='noreferrer'
                target='_blank'
                href='https://www.weavehelp.com/hc/en-us/articles/4405882188955-Text-Messaging-Brand-Registration-FAQ#where-can-i-find-my-ein--0-7'
              >
                {' this article.'}
              </TextLink>
            </Text>
          </div>
          <PrimaryButton
            size='tiny'
            trackingId='portal-business-info_failed-btn'
            onClick={() => {
              refetch();
              setCurrentPageView(CurrentPageView.FORM);
            }}
          >
            {t('Return to form')}
          </PrimaryButton>
        </div>
      );
    case CurrentPageView.VENDOR_ERROR:
      return (
        <div css={styles.failed.wrapper}>
          <ErrorBadgeIcon size={100} color='error' />
          <div css={styles.failed.message}>
            <Text weight='bold' css={styles.failed.header}>
              {t('We encountered an issue verifying your business information.')}
            </Text>
            <Text css={styles.failed.subhead}>
              {t(
                'One of our partners is currently experiencing issues that prevent us from verifying your business information. Please try again later.'
              )}
            </Text>
          </div>
          <PrimaryButton
            size='tiny'
            trackingId='portal-business-info_error-btn'
            onClick={() => {
              refetch();
              setCurrentPageView(CurrentPageView.FORM);
            }}
          >
            {t('Return to form')}
          </PrimaryButton>
        </div>
      );
    case CurrentPageView.FORM:
      return (
        <BusinessInfoForm
          info={data?.tcrBrand || {}}
          isDemoMode={isDemoMode}
          isVerified={identityStatusIsVerified(data?.tcrBrand)}
          previousValues={formValues}
          familyLocationTcrBrands={familyLocationTcrBrands}
          handleSubmit={handleFormSubmit}
          handleCancel={() => setCurrentPageView(CurrentPageView.BRAND)}
          handleFamilyLocationEinMatch={handleFamilyLocationEinMatch}
          hasLocationWritePermission={hasLocationWritePermission}
        />
      );
    default:
      return (
        <section css={styles.information.wrapper}>
          <div css={styles.information.infoSection}>
            <div
              css={styles.information.infoStatus}
              // This tracking Id was added so as to have a way to target this with Pendo.
              // Pendo will check for the existence of this UI element with this Id to
              // determine if this form has been completed when starting the pendo guide
              // that is initiated through one of the onboarding tasks in the root portal
              // page.
              data-trackingid={'portal-business-info_registered-div'}
            >
              {isMissingWebsite || !isValidBusinessWebsite() ? (
                <>
                  <WarningBadgeIcon color='warn' />
                  <Trans t={t} invalidWebsitePrompt={invalidWebsitePrompt}>
                    <Text>
                      {{ invalidWebsitePrompt }}. Please update the form and provide a{' '}
                      <TextLink
                        rel='noreferrer'
                        target='_blank'
                        href='https://www.weavehelp.com/hc/en-us/articles/4405882188955-Text-Messaging-Brand-Registration-FAQ#:~:text=How%20can%20I%20make%20my%20website%2010DLC%20compliant%3F'
                      >
                        compliant website
                      </TextLink>{' '}
                      to avoid potential disruption to texting services. If this business has no dedicated website,
                      please provide a social media page for the business instead.
                    </Text>
                  </Trans>
                </>
              ) : (
                <>
                  <ConfirmBadgeIcon color='success' />
                  <Text>
                    {(isTrulyVerified ? t('Verified! ') : '') +
                      (brandIsShared
                        ? t(
                            'This information is used by more than one associated office location. No further action is necessary.'
                          )
                        : t('No further action is necessary.'))}
                  </Text>
                </>
              )}
            </div>
          </div>
          <div css={styles.information.infoSection}>
            <div css={styles.information.updateButtonRow}>
              <Text weight='bold'>{t('Legal Business Information')}</Text>
              {!isWeaveBrand && (
                <PrimaryButton
                  css={css({ width: 'auto' })}
                  onClick={() => setCurrentPageView(CurrentPageView.FORM)}
                  data-test-id='update-form-button'
                >
                  {t('Update Form')}
                </PrimaryButton>
              )}
            </div>
            <div css={styles.information.infoBox}>
              <Text weight='bold' data-test-id='main-name'>
                {data?.tcrBrand?.entityType === BusinessInfoTypes.EntityType.ENTITY_TYPE_SOLE_PROPRIETOR
                  ? data?.tcrBrand?.displayName
                  : data?.tcrBrand?.companyName}
              </Text>
              <Text data-test-id='street'>{data?.tcrBrand?.street}</Text>
              <Text data-test-id='city-state'>{`${data?.tcrBrand?.city}, ${data?.tcrBrand?.state}`}</Text>
              <Text data-test-id='postalCode-country'>{`${data?.tcrBrand?.postalCode} ${
                data?.tcrBrand?.country === BusinessInfoTypes.Country.COUNTRY_US ? 'USA' : 'Canada'
              }`}</Text>
            </div>
          </div>
          <div css={styles.information.infoSection}>
            <Text css={styles.information.infoTitle} weight='bold'>
              {data?.tcrBrand?.country === BusinessInfoTypes.Country.COUNTRY_US
                ? t('Taxpayer Identification Number (EIN)')
                : t('Canadian Revenue Agency Business Number (BN)')}
            </Text>
            <div css={styles.information.infoBox}>
              <Text data-test-id='ein'>
                {data?.tcrBrand?.entityType === BusinessInfoTypes.EntityType.ENTITY_TYPE_SOLE_PROPRIETOR
                  ? t('I operate my business as a sole proprietor.')
                  : data?.tcrBrand?.country === BusinessInfoTypes.Country.COUNTRY_US
                  ? `${data?.tcrBrand?.ein?.slice(0, 2)}-${data?.tcrBrand?.ein?.slice(2)}`
                  : data?.tcrBrand?.ein}
              </Text>
            </div>
          </div>
          <div css={styles.information.infoSection}>
            <Text css={styles.information.infoTitle} weight='bold'>
              {t('Additional Information')}
            </Text>
            <div css={styles.information.infoBox}>
              {data?.tcrBrand?.displayName !== data?.tcrBrand?.companyName &&
                data?.tcrBrand?.entityType === BusinessInfoTypes.EntityType.ENTITY_TYPE_PRIVATE_PROFIT && (
                  <Text weight='bold' data-test-id='secondary-name'>
                    {t('DBA: ')}
                    {data?.tcrBrand?.displayName}
                  </Text>
                )}
              {(data?.tcrBrand?.firstName || data?.tcrBrand?.lastName) && (
                <Text weight='bold' data-test-id='firstName-lastName'>
                  {`${data?.tcrBrand?.firstName ?? ''} ${data?.tcrBrand?.lastName ?? ''}`.trim()}
                </Text>
              )}
              {data?.tcrBrand?.email && <Text data-test-id='email'>{data?.tcrBrand?.email}</Text>}
              {data?.tcrBrand?.phone && <Text data-test-id='phone'>{formatPhoneNumber(data?.tcrBrand?.phone)}</Text>}
              {data?.tcrBrand?.vertical && (
                <Text data-test-id='vertical'>
                  {titleCase(data?.tcrBrand?.vertical.replace('TCR_VERTICAL_', '').replace(/_/g, ' '))}
                </Text>
              )}
              {data?.tcrBrand?.website && (
                <Text data-test-id='website'>
                  <TextLink
                    rel='noreferrer'
                    target='_blank'
                    to={`${websiteIncludesHttp ? data.tcrBrand.website : `//${data.tcrBrand.website}`}`}
                  >
                    {data?.tcrBrand?.website}
                  </TextLink>
                </Text>
              )}
            </div>
          </div>
        </section>
      );
  }
};

const styles = {
  locked: css`
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${theme.spacing(2)};
  `,
  loading: css`
    max-width: 100%;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: ${theme.spacing(2)};
    margin-top: 100px;
  `,
  success: {
    wrapper: css`
      max-width: 100%;
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(2)};
      margin-top: 50px;
    `,
    header: css`
      font-size: 1.5em;
    `,
  },
  failed: {
    wrapper: css`
      max-width: 100%;
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing(2)};
      margin-top: 50px;
    `,
    header: css`
      font-size: 1.5em;
      text-align: center;
      max-width: 700px;
    `,
    subhead: css`
      margin: ${theme.spacing(1)} 0;
    `,
    message: css`
      max-width: 700px;
      text-align: center;
    `,
  },
  information: {
    wrapper: css`
      max-width: 500px;
      margin-top: ${`-${theme.spacing(2)}`};
    `,
    updateButtonRow: css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: ${theme.spacing(2)};
    `,
    infoSection: css`
      margin-bottom: ${theme.spacing(3)};
      max-width: 500px;
    `,
    infoTitle: css`
      margin-bottom: ${theme.spacing(2)};
    `,
    infoBox: css`
      padding: ${theme.spacing(2)} ${theme.spacing(3)};
      border: 1px solid ${theme.colors.neutral20};
      border-radius: ${theme.borderRadius.medium};
      margin-top: ${theme.spacing(1)};
    `,
    infoStatus: css`
      display: flex;
      align-items: center;
      gap: ${theme.spacing(2)};
    `,
  },
};
