import { http } from '@frontend/fetch';
import { PhoneCallsService, PhoneRecordsSchemaService } from '@frontend/schema';
import { CallLogsResponse, CollectSpans, PhoneCallsPayload, GetHydratedCallRecords, GetUnreadCount } from './types';

export const phoneCallLogsUrl = '/desktop/v1/phones/calls_logs';

export const defaultDepartmentId = '00000000-0000-0000-0000-000000000000';

export const getPhoneCallLogs = async (payload: PhoneCallsPayload, url?: string) => {
  const { limit = 0, skip = 0, departmentIds = [], status = [], number = '' } = payload;
  const departmentIdsParams = departmentIds.length ? `&departmentID=${departmentIds.join('&departmentID=')}` : '';
  const statusParams = status.length ? `&status=${status.join('&status=')}` : '';
  const numberParams = number ? `&number=${number}` : '';

  const { data, meta } = await http.get<CallLogsResponse>(
    url ||
      `${phoneCallLogsUrl}?limit=${limit}&order_by=-start_dt&skip=${skip}${departmentIdsParams}${statusParams}${numberParams}`
  );

  return { data, meta };
};

export const getMissedCallLogs = async (payload: PhoneCallsPayload, url?: string) => {
  const { limit = 0, skip = 0, departmentIds = [] } = payload;
  const departmentIdsParams = departmentIds.length ? `&departmentID=${departmentIds.join('&departmentID=')}` : '';

  const { data, meta } = await http.get<CallLogsResponse>(
    url || `${phoneCallLogsUrl}/missed?limit=${limit}&order_by=-start_dt&skip=${skip}${departmentIdsParams}`
  );

  return { data, meta };
};

export const getMultiCallRecords = (req: GetHydratedCallRecords['input']) => {
  return PhoneRecordsSchemaService.GetHydratedCallRecords(req);
};

export const getUnreadCount = (req: GetUnreadCount['input']) => {
  return PhoneRecordsSchemaService.CountUnreadVoicemails(req);
};

export const logCallPopSpans = (req: CollectSpans['input']) => {
  return PhoneCallsService.CollectSpans(req);
};
