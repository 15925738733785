import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: ${theme.colors.white};

  > li {
    list-style: none;
  }
`;
