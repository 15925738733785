import { useMatch, useNavigate, useSearch } from '@tanstack/react-location';
import { Optional } from 'ts-toolbelt/out/Object/Optional';
import { ThreadCreationRoute, InboxThreadRoute, InboxType, InboxThreadSearch } from './types';

type BaseNavigateArgs = {
  replace?: boolean;
};

export type InboxThreadNavigateArgs = BaseNavigateArgs & Optional<InboxThreadRoute, 'filter'>;

export type InboxThreadCreationNavigateArgs = BaseNavigateArgs & Optional<ThreadCreationRoute, 'filter'>;

export type CloseThreadNavigateArgs = BaseNavigateArgs & {
  filter?: InboxType;
};

const convertStringToInboxType = (val: string): InboxType => {
  switch (val) {
    case InboxType.ARCHIVED:
      return InboxType.ARCHIVED;
    case InboxType.BLOCKED:
      return InboxType.BLOCKED;
    case InboxType.SCHEDULED:
      return InboxType.SCHEDULED;
    case InboxType.DRAFTS:
      return InboxType.DRAFTS;
    default:
      return InboxType.INBOX;
  }
};

export const useInboxNavigate = () => {
  const navigate = useNavigate();
  const {
    params: { filter },
  } = useMatch();
  const currentSearch = useSearch<{ Search: InboxThreadSearch }>();
  const defaultFilter = convertStringToInboxType(filter ?? '');

  const navigateToThread = ({ groupId, threadId, filter, replace, ...search }: InboxThreadNavigateArgs) => {
    let resolvedClickCount = search.click;
    if (search.smsId) {
      if (search.smsId === currentSearch.smsId) resolvedClickCount = (currentSearch.click ?? 0) + 1;
      else resolvedClickCount = 1;
    }
    navigate({
      to: `/messages/${filter || defaultFilter}/${groupId}/${threadId}`,
      search: {
        ...search,
        click: resolvedClickCount,
      },
      replace,
    });
  };

  const navigateToThreadCreation = ({
    filter,
    replace,
    ...search
  }: InboxThreadCreationNavigateArgs | undefined = {}) => {
    navigate({
      to: `/messages/${filter || defaultFilter}/new`,
      search: { ...search },
      replace,
    });
  };

  const closeThread = ({ filter, replace }: CloseThreadNavigateArgs = {}) => {
    navigate({ to: `/messages/${filter || defaultFilter}`, replace });
  };

  /**
   * Intended to be used when the user has an existing thread open.
   */
  const updatePersonId = (personId: string) => {
    navigate({ search: { ...currentSearch, personId } });
  };

  const incrementSmsClick = () => {
    navigate({ search: { ...currentSearch, click: currentSearch.click ? Number(currentSearch.click) + 1 : 1 } });
  };

  const setIsNew = (isNew: boolean) => {
    navigate({ search: { ...currentSearch, isNew } });
  };

  const setIsArchived = (isArchived: boolean) => {
    navigate({ search: { ...currentSearch, isArchived } });
  };

  return {
    navigateToThread,
    navigateToThreadCreation,
    closeThread,
    updatePersonId,
    incrementSmsClick,
    setIsNew,
    setIsArchived,
  };
};
