import { FC, ReactNode } from 'react';
import { css } from '@emotion/react';
import { Icon, IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Heading } from '@frontend/design-system';

interface FeatureCardProps {
  title: string;
  icon: IconName;
  children: ReactNode;
}

export const FeatureCard: FC<FeatureCardProps> = ({ title, icon, children }) => {
  return (
    <div css={featureCardStyles}>
      <div css={iconContainerStyles}>
        <Icon name={icon} size={18} />
      </div>
      <div css={featureCardContentStyles}>
        <Heading level={3}>{title}</Heading>
        {children}
      </div>
    </div>
  );
};

const iconContainerStyles = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: theme.spacing(3),
  height: theme.spacing(3),
  flexShrink: 0,
  marginTop: theme.spacing(0.25),
  borderRadius: theme.borderRadius.medium,
  backgroundColor: theme.colors.secondary.seaweed5,
});

const featureCardStyles = css({
  display: 'flex',
  borderRadius: theme.spacing(2),
  padding: theme.spacing(4, 5),
  gap: theme.spacing(2),
  backgroundColor: theme.colors.white,
});

const featureCardContentStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
});
