import { ReactNode } from 'react';
import { SerializedStyles, css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { RadioField, useControlledField } from '@frontend/design-system';

export type RadioFilterSelectorProps<T> = {
  disabled?: boolean;
  label: string;
  onChange: (selected: T) => void;
  options: Record<string, string> | Record<string, ReactNode>;
  radioStyle?: SerializedStyles;
  showLabel?: boolean;
  subContent?: Record<string, ReactNode>;
  trackingId?: string;
  value?: string;
  withBorder?: boolean;
};

export function RadioFilterSelector<T>({
  disabled,
  label,
  onChange,
  options,
  radioStyle,
  showLabel = true,
  subContent,
  trackingId,
  value,
  withBorder = true,
}: RadioFilterSelectorProps<T>) {
  const radiolistProps = useControlledField({
    onChange: (value) => {
      onChange?.(value);
    },
    type: 'radio',
    value,
  });

  return (
    <RadioField {...radiolistProps} css={mainStyles(showLabel)} disabled={disabled} label={label} name={label}>
      {Object.entries(options).map(([key, displayValue]) => (
        <div className='radio-content' css={[withBorder ? borderCardStyles : cardStyles, radioStyle]} key={key}>
          <RadioField.Radio
            className='radio-option'
            trackingId={`${trackingId}-${key.toLowerCase().replaceAll('_', '-')}`}
            value={key}
          >
            {displayValue}
          </RadioField.Radio>
          {subContent && subContent[key] && <div css={subContentStyles}>{subContent[key]}</div>}
        </div>
      ))}
    </RadioField>
  );
}

const mainStyles = (showLabel?: boolean) => css`
  > label {
    display: ${showLabel ? 'block' : 'none'};
  }

  .radio-content {
    > div {
      align-items: center;
    }

    > div:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const subContentStyles = css`
  border-top: 1px solid ${theme.colors.neutral20};
  padding: ${theme.spacing(2, 3, 0)};
`;

const borderCardStyles = css`
  border-radius: ${theme.borderRadius.medium};
  border: 1px solid ${theme.colors.neutral20};
  padding: ${theme.spacing(2)};

  &:not(:last-of-type) {
    margin-bottom: ${theme.spacing(1)};
  }
`;

const cardStyles = css`
  &:not(:last-of-type) {
    margin-bottom: ${theme.spacing(2)};
  }
`;
