import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  flex: 1;

  .field-wrapper {
    border: none;
    padding-left: 0;

    input {
      text-overflow: ellipsis;
      font-weight: ${theme.font.weight.bold};
      font-size: ${theme.font.size.h2};
    }

    &:has(:is(input):focus) {
      box-shadow: 0px 0px 0px 2px ${theme.colors.primary[50]};
    }
  }
`;
