import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { useHasPhoneSystemAccess } from '@frontend/phone-config';
import { useAppScopeStore } from '@frontend/scope';
import { useFeatureFlagShallowStore } from '@frontend/shared';
import { useShell } from '@frontend/shell-utils';
import { SettingsRoutes } from './settings-routes';
import type { RouteParams } from './types';

type LabelTypeFunction<K extends SettingsRoutes> = (params: RouteParams<K>) => string | Promise<string>;

type DynamicRoutes = {
  [K in SettingsRoutes]: {
    label: LabelTypeFunction<K> | string;
    show?: boolean;
  };
};

export type AllPathsMap = Partial<DynamicRoutes>;

function useSettingsPathLabelMap() {
  const { t } = useTranslation('settingsNav');
  const { getLocationName, selectedLocationIds } = useAppScopeStore();
  const { flagValues } = useFeatureFlagShallowStore('flagValues');
  const hasPhoneFullAccess = hasSchemaACL(selectedLocationIds[0], Permission.PHONE_FULL_ACCESS);
  const hasPhoneSystemAccess = useHasPhoneSystemAccess();
  const hasPhoneAdminAccess = hasPhoneFullAccess && hasPhoneSystemAccess;

  const { isShell, featureAvailability } = useShell();
  const hasShellThemeEnabled = isShell && featureAvailability?.has('shell-theme');

  const hasNewPhoneTreeAccess = flagValues['nwx-phone-tree'] && hasPhoneAdminAccess;

  const hasDomainVerifyAccess = flagValues['domain-verify-settings'];

  const hasImageGalleryAccess = flagValues['image-gallery-in-settings'];

  const pathLabelMap: AllPathsMap = {
    '/organization': { label: t('Organization') },
    '/organization/users': { label: t('Organization Users') },
    '/messaging': { label: t('Messaging') /* AUTO-GENERATED */ },
    '/personal': { label: t('Personal Settings') },
    '/personal/phone/device-connection': {
      label: t('Device Connection') /* AUTO-GENERATED */,
      show: hasPhoneSystemAccess,
    },
    '/tags': { label: t('Tags') /* AUTO-GENERATED */ },
    '/tags/create': { label: t('New Tag') /* AUTO-GENERATED */ },
    '/tags/edit/:tagId': { label: t('Edit Tag') /* AUTO-GENERATED */ },
    '/tags/duplicate/:tagId': {
      label: t('Duplicate Tag') /* AUTO-GENERATED */,
    },
    '/tags/quick-create': { label: t('New Tag') /* AUTO-GENERATED */ },
    '/digital-forms': { label: t('Forms') /* AUTO-GENERATED */ },
    '/payments': { label: t('Payments') /* AUTO-GENERATED */ },
    '/payments/general-settings': {
      label: t('General Settings') /* AUTO-GENERATED */,
    },
    '/payments/online-bill-pay': {
      label: t('Online Bill Pay') /* AUTO-GENERATED */,
    },
    '/payments/payment-methods': {
      label: t('Payment Methods') /* AUTO-GENERATED */,
    },
    '/payments/public-business-information': {
      label: t('Public Business Information') /* AUTO-GENERATED */,
    },
    '/phone/call-blocking': {
      label: t('Call Blocking') /* AUTO-GENERATED */,
      show: hasPhoneSystemAccess,
    },
    '/phone/call-groups': {
      label: t('Call Groups') /* AUTO-GENERATED */,
      show: hasPhoneAdminAccess,
    },
    '/phone/call-recording': {
      label: t('Call Recording') /* AUTO-GENERATED */,
      show: hasPhoneSystemAccess,
    },
    '/phone': {
      label: t('Phone') /* AUTO-GENERATED */,
      show: hasPhoneSystemAccess,
    },
    '/messaging/templates': { label: t('Templates') /* AUTO-GENERATED */ },
    '/messaging/templates/create': {
      label: t('New Template') /* AUTO-GENERATED */,
    },
    '/messaging/templates/duplicate/:templateId': {
      label: t('Duplicate Template') /* AUTO-GENERATED */,
    },
    '/messaging/templates/edit/:templateId': {
      label: t('Edit Template') /* AUTO-GENERATED */,
    },
    '/messaging/templates/inbox-edit/:templateId': {
      label: t('Edit Manual Template') /* AUTO-GENERATED */,
    },
    '/phone/overrides': {
      label: t('Overrides') /* AUTO-GENERATED */,
      show: hasPhoneSystemAccess,
    },
    '/messaging/templates/import': {
      label: t('Import Templates') /* AUTO-GENERATED */,
    },
    '/payments/terminals': {
      label: t('General Settings') /* AUTO-GENERATED */,
    },
    '/phone/devices': {
      label: t('Device Settings') /* AUTO-GENERATED */,
      show: hasPhoneSystemAccess,
    },
    '/phone/office-hours': {
      label: t('Office-Hours') /* AUTO-GENERATED */,
      show: hasPhoneSystemAccess,
    },
    '/email': { label: t('Email') /* AUTO-GENERATED */ },
    '/fax': { label: t('Fax') /* AUTO-GENERATED */ },
    '/reviews': { label: t('Reviews') /* AUTO-GENERATED */ },
    '/organization/billing-information': {
      label: t('Billing Information') /* AUTO-GENERATED */,
    },
    '/organization/invoice-history': {
      label: t('Invoice History') /* AUTO-GENERATED */,
    },
    '/organization/payment-methods': {
      label: t('Payment Methods') /* AUTO-GENERATED */,
    },
    '/organization/invoice-history/:locationId': {
      label: (params) => getLocationName(params.locationId) || params.locationId,
    },
    '/personal/diagnostics': {
      label: t('Device & App Diagnostics') /* AUTO-GENERATED */,
    },
    '/messaging/mark-confirmation-as-read': {
      label: t('Mark Confirmed') /* AUTO-GENERATED */,
      show: hasPhoneSystemAccess,
    },
    '/schedule': { label: t('Schedule') /* AUTO-GENERATED */ },
    '/schedule/appointment-types': {
      label: t('Appointment Types') /* AUTO-GENERATED */,
    },
    '/schedule/message-templates': {
      label: t('Message Templates') /* AUTO-GENERATED */,
    },
    '/schedule/office-hours': { label: t('Office Hours') /* AUTO-GENERATED */ },
    '/schedule/message-templates/appointment-confirmed/:locationId': {
      label: t('Appointment Confirmed') /* AUTO-GENERATED */,
    },
    '/schedule/message-templates/request-received/:locationId': {
      label: t('Request Received') /* AUTO-GENERATED */,
    },
    '/schedule/custom-fields': {
      label: t('Custom Fields') /* AUTO-GENERATED */,
    },
    '/organization/phone-numbers': {
      label: t('Phone Numbers and Porting') /* AUTO-GENERATED */,
    },
    '/organization/phone-numbers/port-request': {
      label: t('Submit Port Request') /* AUTO-GENERATED */,
    },
    '/personal/customize-app': {
      label: t('Customize Your App') /* AUTO-GENERATED */,
      show: !!hasShellThemeEnabled,
    },
    '/messaging/auto-message-inbox-visibility': {
      label: t('Auto-Message Inbox Visibility') /* AUTO-GENERATED */,
      show: hasPhoneSystemAccess,
    },
    '/phone/line-keys': {
      label: t('Line Keys') /* AUTO-GENERATED */,
      show: hasPhoneSystemAccess,
    },
    '/subscriptions': {
      label: t('Subscriptions') /* AUTO-GENERATED */,
    },
    '/subscriptions/feature/:id': {
      label: t('Feature Subscriptions') /* AUTO-GENERATED */,
    },
    '/subscriptions/manage-subscription': {
      label: t('Manage Subscription') /* AUTO-GENERATED */,
    },
    '/schedule/appointment-types/:locationId': {
      label: (params) => getLocationName(params.locationId) || params.locationId,
    },
    '/schedule/manage-providers/:locationId': {
      label: (params) => getLocationName(params.locationId) || params.locationId,
    },
    '/schedule/manage-providers': {
      label: t('Manage Providers') /* AUTO-GENERATED */,
    },
    '/schedule/manage-providers/:locationId/edit/:providerId': {
      label: t('Edit Provider'),
    },
    '/insurance': {
      label: t('Insurance'),
    },
    '/insurance/automatic-verification': {
      label: t('Automatic Verification'),
    },
    '/insurance/automatic-verification/:locationId': {
      label: (params) => getLocationName(params.locationId) || params.locationId,
    },

    '/personal/notifications/:type': {
      label: (params) => {
        const map: Record<string, string> = {
          'chat-message-new': t('Chat'),
          'fax-new': t('Fax'),
          'form-submission-new': t('Forms'),
          'review-new': t('Reviews'),
          'sms-message-new': t('SMS Messages'),
          payments: t('Payments'),
          'sms-tag': t('Tags'),
        };
        return map[params.type];
      },
    },
    '/phone/phone-tree': {
      label: t('Phone Trees') /* AUTO-GENERATED */,
      show: hasPhoneSystemAccess && hasNewPhoneTreeAccess,
    },
    '/phone/phone-tree/:id': { label: t('Phone Tree') /* AUTO-GENERATED */ },
    '/phone/call-queues/:id': {
      label: t('Call Queue'),
      show: hasPhoneAdminAccess /* AUTO-GENERATED */,
    },
    '/phone/call-queues': {
      label: t('Call Queues'),
      show: hasPhoneAdminAccess /* AUTO-GENERATED */,
    },
    '/phone/voicemail-box/:id': {
      label: t('Voicemail Box') /* AUTO-GENERATED */,
    },
    '/phone/voicemail-box': {
      label: t('Voicemail Boxes') /* AUTO-GENERATED */,
    },
    '/personal/notifications/position-and-dismissal': {
      label: t('Position and Dismissal') /* AUTO-GENERATED */,
    },
    '/messaging/show-unreplied-message-status': {
      label: t('Unreplied Message Status Visiblity') /* AUTO-GENERATED */,
    },
    '/personal/preferences/accessibility': {
      label: t('Accessibility') /* AUTO-GENERATED */,
    },
    '/phone/hold-music': { label: t('Hold Music') /* AUTO-GENERATED */ },
    '/email/domain-verify': {
      label: t('Domain Verification') /* AUTO-GENERATED */,
      show: hasDomainVerifyAccess,
    },
    '/phone/audio-library': { label: t('Audio Library') /* AUTO-GENERATED */ },
    '/schedule/treatment-rooms': {
      label: t('Treatment Rooms') /* AUTO-GENERATED */,
    },
    '/schedule/treatment-rooms/:locationId': {
      label: (params) => getLocationName(params.locationId) || params.locationId,
    },
    '/payments/account-info': {
      label: t('Account Info and Status') /* AUTO-GENERATED */,
    },
    '/messaging/auto-messages': {
      label: t('Auto-Messages') /* AUTO-GENERATED */,
    },
    '/messaging/auto-messages/:ruleId': {
      label: t('Auto-Messages Rule') /* AUTO-GENERATED */,
    },
    '/image-gallery': {
      label: t('Image Gallery') /* AUTO-GENERATED */,
      show: hasImageGalleryAccess,
    },
    '/contacts': { label: t('Contacts') /* AUTO-GENERATED */ },
    '/contacts/manage-csv': { label: t('Manage CSVs') /* AUTO-GENERATED */ },
    '/contacts/file-mapping': { label: t('File Mapping') /* AUTO-GENERATED */ },
    '/organization/brand': { label: t('Brand Details') /* AUTO-GENERATED */ },
    '/contacts/review-upload': {
      label: t('Review Your Upload') /* AUTO-GENERATED */,
    },
  };

  return pathLabelMap;
}

export { useSettingsPathLabelMap };
