import { useQuery } from 'react-query';
import { SchemaIntegrationsService } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';

type UseGetDataSourcesForLocationArgs = {
  locationId: string;
  isEnabled?: boolean;
};

export const useGetDataSourcesForLocation = ({ locationId, isEnabled = false }: UseGetDataSourcesForLocationArgs) => {
  const { selectedParentsIds } = useAppScopeStore();
  const dataSourceLocationId = selectedParentsIds?.[0] || locationId || '';

  return useQuery({
    queryKey: [locationId, 'data-sources'],
    queryFn: () => SchemaIntegrationsService.ListDataSourcesByLocationID({ locationId: dataSourceLocationId }),
    enabled: !!dataSourceLocationId && isEnabled,
  });
};
