import dayjs from 'dayjs';
import { MorningHuddleTypes } from '@frontend/api-analytics';

const generateProductionData = (days: number, startDate: string) => {
  const data = [];

  for (let i = 0; i < days; i++) {
    const date = new Date(startDate);
    date.setDate(date.getDate() + i);

    if (dayjs(date).isValid()) {
      const timestamp = date.toISOString().slice(0, 10).replace(/-/g, '');
      const label = date.toLocaleDateString('en-US', { weekday: 'short' });
      const production = Math.floor(Math.random() * 20000);

      data.push({ timestamp, label, production });
    }
  }

  return data;
};

export const morningHuddleProductionMTD = (): MorningHuddleTypes.MonthProductionResponse => ({
  location: {
    id: '1',
    lmtdProduction: {
      totals: {
        completedProduction: 209912,
      },
    },
    mtdProduction: {
      projections: {
        totalScheduled: 21854,
      },
      totals: {
        completedProduction: 192827,
      },
    },
  },
});

export const morningHuddleLastDayProduction = (): MorningHuddleTypes.LastDayProductionResponse => ({
  location: {
    cancellations: {
      totals: {
        cancelled: 2,
        cancelledUnscheduled: 1,
      },
    },
    hygieneReappointment: {
      totals: {
        patients: 4,
        percentageSameDay: 0.75,
        unscheduled: 1,
      },
    },
    hygieneTx: {
      totals: {
        diagnosed: 7,
        diagnosedPercent: 0.27,
        unscheduled: 7,
        unscheduledTreatment: 2535,
      },
    },
    id: '1',
    newPatients: {
      totals: {
        needsAppointment: 0,
        patients: 1,
        percentUnscheduled: 0,
        production: 505,
      },
    },
    overdue: {
      totals: {
        patients: 299,
      },
    },
    pmType: '',
    production: {
      totals: {
        completedProduction: 7905,
        sameDayProduction: 124,
        scheduledProduction: 25277,
      },
      details: {
        totalProductionPerVisit: 988,
        totalVisits: 8,
      },
    },
    recaptured: {
      totals: {
        patients: 74,
        production: 47116,
      },
    },
    restorativeTx: {
      totals: {
        diagnosed: 4,
        diagnosedPercent: 0.42,
        unscheduled: 3,
        unscheduledTreatment: 14950,
      },
    },
    unscheduled: {
      totals: {
        patients: 979,
      },
    },
  },
});

export const morningHuddleScheduledProduction = (
  startDate: string
): MorningHuddleTypes.ScheduledProductionResponse => ({
  location: {
    avgProduction: {
      totals: {
        averageCompletedProductionPerDay: 9836.9,
      },
    },
    id: '1',
    production: {
      projections: {
        details: generateProductionData(7, startDate),
      },
    },
  },
});
