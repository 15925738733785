import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  align-items: center;
  border: 1px solid ${theme.colors.neutral30};
  border-radius: ${theme.borderRadius.small};
  flex: 1;
`;

export const textStyle = css`
  border-right: 1px solid ${theme.colors.neutral30};
  padding: ${theme.spacing(1, 1.5)};
  flex: 1;
`;

export const iconStyle = css`
  margin: ${theme.spacing(1, 1.5)};
`;
