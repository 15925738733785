import { css } from '@emotion/react';
import { ScheduleAvailabilityUtils } from '@frontend/api-schedule-availability';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { daysOfWeek } from '../../constants';
import { useScheduleAvailabilityHoursContext } from '../../context/ScheduleAvailabilityHoursContext';

const { TranslatedWeekInitials } = ScheduleAvailabilityUtils;

export const DaysOfTheWeekSelector = () => {
  const { selectedDays, onToggleDay } = useScheduleAvailabilityHoursContext();
  return (
    <section css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
      {daysOfWeek.map((day) => {
        const isSelected = selectedDays?.includes(day);
        return (
          <div css={daySelectorStyles(isSelected)} key={day} onClick={() => onToggleDay(day)}>
            <Text weight='bold' color={isSelected ? 'default' : 'light'}>
              {TranslatedWeekInitials()[day]}
            </Text>
          </div>
        );
      })}
    </section>
  );
};

const daySelectorStyles = (isSelected: boolean) =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: theme.spacing(6),
    height: theme.spacing(6),
    borderRadius: theme.borderRadius.full,
    border: isSelected ? `2px solid ${theme.colors.primary50}` : `1px solid ${theme.colors.neutral50}`,
    cursor: 'pointer',
  });
