import { css } from '@emotion/react';
import { useMatchRoute } from '@tanstack/react-location';
import { PageTitle } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { useSelectedInvoice } from '@frontend/payments-invoice-controller';
import { StatusChip } from '@frontend/payments-status-chip';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { useModalControl } from '@frontend/design-system';
import { PaymentsPage, PaymentRequestDetailsPage, PaymentRequestActions } from '../../../components';

const styles = {
  chipStyle: css`
    margin-left: ${theme.spacing(1)};
    @container (max-width: ${breakpoints.small.max}px) {
      margin-left: 0;
      margin-top: ${theme.spacing(1)};
    }
  `,
};

export const PaymentRequestDetails = ({ invoiceId }: { invoiceId: string }) => {
  const { t } = useTranslation('payments');
  const matchRoute = useMatchRoute();
  const isRefunds = matchRoute({ to: '/payments/refunds/:invoiceId' });
  const { invoice } = useSelectedInvoice(invoiceId);
  const collectModalControl = useModalControl();

  return (
    <PaymentsPage
      title={
        <>
          <PageTitle title={t('Payment Request')} />
          {invoice && <StatusChip status={invoice.status} css={styles.chipStyle} />}
        </>
      }
      variant='payments-app'
      maxWidth={parseInt(theme.spacing(130))}
      breadcrumbs={
        !isRefunds
          ? [
              {
                label: t('Invoice History'),
                to: '/payments/invoices',
              },
              { label: t('Payment Details') },
            ]
          : [
              {
                label: t('Refunds History'),
                to: '/payments/refunds',
              },
              { label: t('Refund Details') },
            ]
      }
      action={invoice && <PaymentRequestActions invoice={invoice} collectModalControl={collectModalControl} />}
    >
      <PaymentRequestDetailsPage selectedInvoiceId={invoiceId} />
    </PaymentsPage>
  );
};
