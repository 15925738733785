import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Page } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { Tabs } from '@frontend/design-system';
import {
  AnalyticsPageViewTabs,
  DemoDataBanner,
  HelpMenu,
  InfoTipPopover,
  NWXNavigationModal,
  PageHeader,
} from '../components';
import { SMSReportTable, SMSReportCharts, MessagingAnalyticsFilters } from '../components/sms';
import {
  useIsMessagingAnalyticsDemoAccount,
  useMessagingAnalyticsDemoData,
  useMessagingAnalyticsShallowStore,
} from '../components/sms/hooks';
import { URLs } from '../constants';
import { useIsMobile } from '../hooks';
import { trackingIds } from '../tracking-ids';
import { pageStyles } from './common-styles';

const DEFAULT_VIEW = 'charts-view';

export const MessagingAnalytics = () => {
  const { t } = useTranslation('analytics');
  const { filterHintText, setDemoData, setIsDemoAccount } = useMessagingAnalyticsShallowStore(
    'filterHintText',
    'setDemoData',
    'setIsDemoAccount'
  );
  const [activeTab, setActiveTab] = useState<string>(DEFAULT_VIEW);
  const [isFetchingTableData, setIsFetchingTableData] = useState<boolean>(false);
  const [isFetchingChartsData, setIsFetchingChartsData] = useState<boolean>();
  const isMobile = useIsMobile();
  const isDemoAccount = useIsMessagingAnalyticsDemoAccount();
  const demoData = useMessagingAnalyticsDemoData();

  const pageViewTabsRenderer = () => (
    <>
      <MessagingAnalyticsFilters
        isLoadingData={isFetchingChartsData || isFetchingTableData}
        isTableView={activeTab === 'table-view'}
      />
      <HelpMenu feedbackUrl={URLs.MESSAGING_ANALYTICS_FEEDBACK} helpUrl={URLs.MESSAGING_ANALYTICS_HELP} />
    </>
  );

  useEffect(() => {
    setDemoData(demoData);
  }, [demoData]);

  useEffect(() => {
    setIsDemoAccount(isDemoAccount);
  }, [isDemoAccount]);

  return (
    <>
      <NWXNavigationModal />
      {isDemoAccount && (
        <DemoDataBanner title={t("You're taking Messaging Analytics for a test drive with demo data!")} />
      )}
      <Tabs initialTab={DEFAULT_VIEW} onChange={setActiveTab}>
        <Page
          action={
            !isMobile && (
              <AnalyticsPageViewTabs
                renderContent={pageViewTabsRenderer()}
                trackingIds={trackingIds.messagingAnalytics}
              />
            )
          }
          css={styles.page}
          subtitle={t('Showing results for {{filtersInfo}}', {
            filtersInfo: filterHintText,
          })}
          title={
            <PageHeader
              title={t('Message Analytics')}
              infoTip={
                <InfoTipPopover>
                  {t('Structured record of the messages sent or received by your office.')}
                </InfoTipPopover>
              }
            />
          }
        >
          {isMobile && (
            <AnalyticsPageViewTabs
              renderContent={pageViewTabsRenderer()}
              trackingIds={trackingIds.messagingAnalytics}
            />
          )}
          <div css={pageStyles.panelsWrapper}>
            <Tabs.Panel controller='table-view' css={pageStyles.tabPanel} id='table-view-panel'>
              <SMSReportTable onFetchStateChange={setIsFetchingTableData} />
            </Tabs.Panel>
            <Tabs.Panel controller='charts-view' css={pageStyles.tabPanel} id='charts-view-panel'>
              <SMSReportCharts onFetchStateChange={setIsFetchingChartsData} />
            </Tabs.Panel>
          </div>
        </Page>
      </Tabs>
    </>
  );
};

const styles = {
  page: css`
    max-width: 100%;
  `,
};
