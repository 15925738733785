import { FC, PropsWithChildren, ReactNode, useCallback } from 'react';
import { css } from '@emotion/react';
import { FloatingNode, FloatingPortal, useFloatingNodeId } from '@floating-ui/react';
import { ErrorBoundary } from '@sentry/react';
import { Navigate, useRouter } from '@tanstack/react-location';
import {
  CreateTaskEventRequest,
  EventType,
} from '@weave/schema-gen-ts/dist/schemas/insys/onboarding/v1/onboarding-tasks/onboarding_tasks.pb';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { useIntakeFormShallowStore } from '@frontend/api-intake-form';
import { OnboardingModulesApi, OnboardingModulesTypes } from '@frontend/api-onboarding-modules';
import { SkipLinks } from '@frontend/accessibility';
import { LargeDownloads, largeDownloadsRef } from '@frontend/analytics';
import { hasSchemaACL } from '@frontend/auth-helpers';
import {
  TwilioChatComponent,
  twilioStrategy,
  ChatStrategyProvider,
  ChatTitleBar,
  streamStrategy,
  StreamChatComponent,
  useChatStatusShallowStore,
} from '@frontend/chat';
import { PageFrame } from '@frontend/components';
import { DialpadManagerProvider } from '@frontend/dialpad';
import { InboxChatComponent, InboxTitleBar } from '@frontend/inbox';
import { NotificationProvider, NotificationQueue } from '@frontend/notifications';
import { useCallPopDemo } from '@frontend/phone';
import { CallPopNotification } from '@frontend/pop';
import { PopupBarManagerProvider, PopupBarManager, ExtensiblePopup } from '@frontend/popup-bar';
import { useHasFeatureFlag } from '@frontend/shared';
import { TaskTraySidePanel } from '@frontend/task-tray';
import { TeamChatProvider, TeamChatWrapper, ChatPanel } from '@frontend/team-chat';
import { TourGuideComponent } from '@frontend/tour-guide';
import { WeaveLoader } from '@frontend/weave-loader';
import { SoftphoneManager } from '@frontend/weave-softphone-manager';
import { ContentLoader } from '@frontend/design-system';
import { GlobalNav, IntakeFormNav, NavContextProvider } from '../layout';
import Locations from '../pages/portal/locations';
import { useRoutes } from '../providers/routes.context';
import { WebsocketSubscriptionsProvider } from '../websocket-subscriptions/websocket-subscriptions-provider';
import { CommandPalette } from './command-palette';
import { DevTools } from './devtools';
import { InfoModal } from './info-modal';
import { ResponsiveSlidePanel } from './responsive-slide-panel';
import { SalesDemoFeatureModal } from './sales-demo-feature-modal';
import { SettingsModal } from './settings/settings-modal';
import { StackErrorPage } from './stack-error-page';
import { TopBar } from './top-bar';
import { RibbonManager } from './top-bar-ribbons/ribbon-manager';
import { AppStateType, useInitializeApp } from './use-initialize-app';

const ConditionalPageFrame: FC<PropsWithChildren<{ hasPageFrame: boolean }>> = ({ children, hasPageFrame }) =>
  hasPageFrame ? <PageFrame>{children}</PageFrame> : <>{children}</>;

const Nav = () => {
  const { isShowIntakeForm } = useIntakeFormShallowStore('isShowIntakeForm');

  return isShowIntakeForm ? <IntakeFormNav /> : <GlobalNav />;
};

export const AuthenticatedAppWrapper = ({ children }: { children: ReactNode }) => {
  const router = useRouter();
  const isWeaveApp = !router.state.location.pathname.startsWith('/portal');
  const appState = useInitializeApp();

  return (
    <AuthenticatedApp isWeaveApp={isWeaveApp} appState={appState}>
      {children}
    </AuthenticatedApp>
  );
};

const AuthenticatedApp = ({
  children,
  appState,
  isWeaveApp,
}: {
  children: ReactNode;
  appState: AppStateType;
  isWeaveApp: boolean;
}) => {
  if (appState.state === 'LOADING') {
    return <WeaveLoader />;
  } else if (appState.state === 'ONBOARDING') {
    return (
      <>
        <ContentLoader show />
        <Navigate to={appState.meta.onboardingRedirectUrl} replace />
      </>
    );
  }

  /**
   * Before this point, the actual app (Providers and content) has not been rendered yet.
   * Once we have initialized location data, we can proceed with rendering the app.
   */

  let content = null;
  const isTeamChatV2Enabled = useHasFeatureFlag('team-chat-2.0');

  if (appState.state === 'INITIALIZED') {
    content = (
      <ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag('topic', 'errorBoundary');
          scope.setLevel('fatal');
        }}
        fallback={(props) => <StackErrorPage {...props} />}
      >
        {children}
      </ErrorBoundary>
    );
  } else if (appState.state === 'LOCATION_SELECTION') {
    content = <Locations />;
  }

  return (
    <>
      <AppProviders>
        <TopBarContainer />
        <RibbonManager />
        <div
          id='main-container'
          style={{
            display: 'flex',
            overflow: 'hidden',
            height: '100%',
            position: 'relative',
          }}
        >
          <div
            id='app-container'
            style={{
              width: '100%',
              display: 'flex',
              overflow: 'hidden',
              height: '100%',
              position: 'relative',
            }}
          >
            <Nav />
            <ConditionalPageFrame hasPageFrame={isWeaveApp}>
              <Main>{content}</Main>
            </ConditionalPageFrame>
            <ResponsiveSlidePanel />
            <TaskTraySidePanel />
            {isTeamChatV2Enabled && <ChatPanel />}
          </div>
        </div>
      </AppProviders>
      <Miscellaneous isWeaveApp={isWeaveApp} />
      <Settings appState={appState} />
    </>
  );
};

const TopBarContainer = () => {
  return <TopBar PopupBarManager={PopupBarManager} ChatProviders={ChatProviders} />;
};

const AppProviders = ({ children }: { children: ReactNode }) => {
  const { setShouldPreventRefresh } = useRoutes();
  const isStreamChatEnabled = useHasFeatureFlag('team-chat-use-stream');
  const { setPopupStatus } = useChatStatusShallowStore('setPopupStatus');
  const showTools = hasSchemaACL('weave', Permission.DEV_TOOLS) || import.meta.env.MODE === 'development';

  const onActiveCall = useCallback(() => {
    setShouldPreventRefresh(true);
  }, []);
  const onAllCallsEnded = useCallback(() => {
    setShouldPreventRefresh(false);
  }, []);

  const onPopupBarChange = ({ popupList, activePopup }: { popupList: ExtensiblePopup[]; activePopup: string[] }) => {
    const hasActiveChatPopup =
      activePopup[0] && popupList.some((popup) => popup.type === 'chat' && activePopup[0].includes(popup.id));
    setPopupStatus(hasActiveChatPopup ? 'open' : 'closed');
  };

  return (
    <NotificationProvider>
      <DialpadManagerProvider>
        <SoftphoneManager onAllCallsEnded={onAllCallsEnded} onCallIsActive={onActiveCall}>
          <PopupBarManagerProvider
            components={{
              chat: {
                Content: isStreamChatEnabled ? StreamChatComponent : TwilioChatComponent,
                TitleBar: ChatTitleBar,
              },
              message: { Content: InboxChatComponent, TitleBar: InboxTitleBar },
            }}
            onChange={onPopupBarChange}
          >
            <WebsocketSubscriptionsProvider>
              <SkipLinks />
              <NavContextProvider>
                {/* Adding a provider here so that new chat panel could have a provider. But the old chat
                  provider continue using the ChatStrategyProvider. When we migrate to the new chat we will
                  remove the old chat strategy provider. Hence conditionally using the providers. */}
                <ChatProviderV2>{children}</ChatProviderV2>
              </NavContextProvider>
            </WebsocketSubscriptionsProvider>
          </PopupBarManagerProvider>
        </SoftphoneManager>
      </DialpadManagerProvider>
      <NotificationGutter>
        <CallPopNotification />
        <NotificationQueue />
      </NotificationGutter>
      {showTools && <DevTools />}
    </NotificationProvider>
  );
};

const Miscellaneous = ({ isWeaveApp }: { isWeaveApp: boolean }) => {
  const showTools = hasSchemaACL('weave', Permission.DEV_TOOLS) || import.meta.env.MODE === 'development';

  const tourGuideActionHandler = useCallback(
    (eventInfo: CreateTaskEventRequest, taskType?: OnboardingModulesTypes.TaskType) => {
      const isCompleteTaskEvent = eventInfo.eventType === EventType.EVENT_COMPLETE_TASK;
      const isLocationBasedTask = taskType === OnboardingModulesTypes.TaskType.Location;

      if (isCompleteTaskEvent && isLocationBasedTask) {
        OnboardingModulesApi.markLocationTaskCompleted(
          eventInfo.taskId,
          OnboardingModulesTypes.TaskCompletionType.Modules
        );
      }

      OnboardingModulesApi.sendTaskEvent(eventInfo);
    },
    []
  );

  return (
    <>
      {showTools && <CommandPalette />}
      <LargeDownloads ref={largeDownloadsRef} />
      <InfoModal />
      <TourGuideComponent.TourGuide onAction={tourGuideActionHandler} />
      {isWeaveApp && <SalesDemoFeatureModal />}
    </>
  );
};

const Settings = ({ appState }: { appState: AppStateType }) => {
  return appState.state === 'INITIALIZED' ? <SettingsModal /> : null;
};

const Main = ({ children }: { children: ReactNode }) => {
  useCallPopDemo();

  return (
    <main
      id='main-content'
      tabIndex={0}
      css={css`
        width: 100%;
        overflow-y: auto;

        // min-width: 0 prevents inner flex components from causing this to overflow its bounds
        // https://stackoverflow.com/questions/36230944/prevent-flex-items-from-overflowing-a-container
        min-width: 0;
      `}
    >
      {children}
    </main>
  );
};

const NotificationGutter = ({ children }: { children: ReactNode }) => {
  const nodeId = useFloatingNodeId();
  return (
    <FloatingNode id={nodeId}>
      <FloatingPortal>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'fixed',
            right: 0,
            top: 0,
          }}
        >
          {children}
        </div>
      </FloatingPortal>
    </FloatingNode>
  );
};

const ChatProviders = ({ children }: { children: ReactNode }) => {
  const isStreamChatEnabled = useHasFeatureFlag('team-chat-use-stream');
  const isChatEnabled = useHasFeatureFlag('new-chat-experience');
  const isTeamChatV2Enabled = useHasFeatureFlag('team-chat-2.0');

  return (
    <ChatStrategyProvider
      enabled={isChatEnabled && !isTeamChatV2Enabled}
      strategy={{
        ...(isStreamChatEnabled ? streamStrategy : twilioStrategy),
      }}
    >
      {children}
    </ChatStrategyProvider>
  );
};

const ChatProviderV2 = ({ children }: { children: ReactNode }) => {
  const isTeamChatV2Enabled = useHasFeatureFlag('team-chat-2.0');
  if (!isTeamChatV2Enabled) {
    return <TeamChatProvider>{children}</TeamChatProvider>;
  }

  return (
    <TeamChatProvider>
      <TeamChatWrapper>{children}</TeamChatWrapper>
    </TeamChatProvider>
  );
};
