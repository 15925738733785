import { SMSAnalyticsTypes } from '@frontend/api-analytics';
import { PickerLocation } from '@frontend/scope';
import { demoDataUtils } from '../../../utils/demo-data-utils';

const directions = ['Inbound', 'Outbound'];
const sourceTypes = ['current_patient', 'new_patient'];
const status = ['STATUS_RECEIVED', 'STATUS_DELIVERED'];
const contentType = ['SMS', 'MMS'];

export const data = (
  locationIds: string[],
  locationData: Record<string, PickerLocation>
): SMSAnalyticsTypes.SMSReportResponse => {
  // Generate 25 dates for today with differnt and random time
  const dates = Array.from({ length: 25 }, () => {
    const date = new Date();
    date.setHours(Math.floor(Math.random() * 23));
    date.setMinutes(Math.floor(Math.random() * 59));
    date.setSeconds(Math.floor(Math.random() * 59));

    return date.toISOString();
  });

  const locationId = locationIds[Math.floor(Math.random() * locationIds.length)];

  return {
    data: dates.map((date) => {
      const randomPersons = demoDataUtils.generateRandomUserNames(2);
      const sender = randomPersons[0];
      const receiver = randomPersons[1];
      const readSeconds = Math.floor(Math.random() * 1000);

      return {
        content_type: contentType[Math.floor(Math.random() * contentType.length)],
        created_at: date,
        direction: directions[Math.floor(Math.random() * directions.length)],
        id: demoDataUtils.generateRandomUUID(),
        location_id: locationId,
        location_name: locationData[locationId]?.name || '',
        message_category: 'na',
        message_created_by: '',
        message_read_at: new Date(date).setSeconds(new Date(date).getSeconds() + readSeconds).toString(),
        message_start_time: date,
        patient_id: demoDataUtils.generateRandomNumberString(4),
        patient_name: `${sender.firstName} ${sender.lastName}`,
        person_name: '',
        phone_number: demoDataUtils.generateRandomPhoneNumber(),
        receiver_name: `${receiver.firstName} ${receiver.lastName}`,
        receiver_number: demoDataUtils.generateRandomPhoneNumber(),
        sender_name: `${sender.firstName} ${sender.lastName}`,
        sender_number: demoDataUtils.generateRandomPhoneNumber(),
        sms_body: "This is a demo message. When you use a non-demo location, you'll see the real message here.",
        source_type: sourceTypes[Math.floor(Math.random() * sourceTypes.length)],
        status_details: '',
        status: status[Math.floor(Math.random() * status.length)],
        time_zone: 'US/Central',
        updated_at: '',
      };
    }),
    meta: {
      links: {},
    },
  };
};
