import { useRef, useState } from 'react';
import { InputState, Action, ReturnUseDialerProps } from '@frontend/generic-dialpad-accessories';
import { useDialpadDialer } from '../providers/dialpad-dialer-provider';

const initialInputState: InputState = { blurred: false, error: null, focused: false };

export type UseDialerProps = {
  onSubmit: (direct?: string) => Promise<any>;
};

export const useDialer = ({ onSubmit }: UseDialerProps): ReturnUseDialerProps => {
  const currentDial = useDialpadDialer((ctx) => ctx.currentDial);
  const setCurrentDial = useDialpadDialer((ctx) => ctx.setCurrentDial);
  const reset = useDialpadDialer((ctx) => ctx.reset);

  const [inputState, setInputState] = useState(initialInputState);

  const inputRef = useRef<HTMLInputElement>(null);

  const dispatch = (action: Action) => {
    switch (action.type) {
      case 'set': {
        if (!action.payload) {
          reset();
        } else {
          setCurrentDial(action.payload);
        }
        break;
      }
      case 'delete': {
        const input = inputRef.current as HTMLInputElement;
        const { selectionStart, selectionEnd } = input;
        if (
          selectionStart !== null &&
          selectionEnd !== null &&
          selectionStart !== selectionEnd &&
          input.value !== null
        ) {
          const value = input.value;
          const newValue = value.slice(0, selectionStart) + value.slice(selectionEnd);
          setCurrentDial(newValue);
        } else {
          setCurrentDial((prev) => prev?.substring(0, prev ? prev.length - 1 : 0));
        }
        break;
      }
      case 'add': {
        setCurrentDial((prev) => (prev ? prev + action.payload : action.payload));
        break;
      }
      case 'reset': {
        reset();
        setInputState((prev) => ({ ...prev, blurred: true, focused: true, error: null }));
        inputRef.current?.focus();
        break;
      }
      case 'focus': {
        setInputState((prev) => ({ ...prev, blurred: false, focused: true, error: null }));
        break;
      }
    }
  };

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      onSubmit(currentDial);
    } else if (e.key === 'Clear') {
      dispatch({ type: 'reset' });
    } else if (e.key === 'Backspace') {
      e.preventDefault();
      dispatch({ type: 'delete' });
    }
  };

  return {
    inputState,
    inputRef,
    dispatch,
    handleKeyDown,
  };
};
