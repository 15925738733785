import { NotificationType } from '@weave/schema-gen-ts/dist/shared/notification/notifications.pb';
import { getUser } from '@frontend/auth-helpers';
import { useChatNotification, useNotificationSettingsShallowStore } from '@frontend/notifications';
import { useAppScopeStore } from '@frontend/scope';
import { useWebsocketEventSubscription } from '@frontend/websocket';
import { OPEN_TEAM_CHAT_EVENT } from '../constants';
import { useTeamChatStore } from '../providers';
import { getStreamUserId } from '../utils';

export const useNotifications = () => {
  const { notificationSettings } = useNotificationSettingsShallowStore('notificationSettings');
  const { isConnected, conversations, setActiveConversation, setActiveChannelId } = useTeamChatStore([
    'isConnected',
    'conversations',
    'setActiveConversation',
    'setActiveChannelId',
  ]);
  const { selectedOrgId } = useAppScopeStore();
  const user = getUser();

  const openTeamChatSideTrayEvent = new CustomEvent(OPEN_TEAM_CHAT_EVENT);

  const streamUserID: string | null = !user ? null : getStreamUserId({ orgId: selectedOrgId, userId: user.userID });

  const { create, remove } = useChatNotification({
    onView: async (notification) => {
      if (notification.payload.provider !== 'stream') {
        return;
      }
      const channelId = notification.payload.channelId;
      const conversation = [...conversations.dm, ...conversations.groups, ...conversations.unread].find(
        (conversation) => conversation.channelId === channelId
      );

      // TODO: if conversation is not found, fetch it from stream
      if (conversation) {
        setActiveConversation(conversation);
      } else {
        setActiveChannelId(channelId);
      }
      window.dispatchEvent(openTeamChatSideTrayEvent);
      remove(notification.id);
    },
  });

  // Weave's WS subscription for chat messages
  useWebsocketEventSubscription(NotificationType.CHAT_MESSAGE, (payload: any) => {
    const { message, channel_id, created_at } = payload.params;
    if (streamUserID && !isConnected && message?.user?.id !== streamUserID) {
      create({
        id: message?.id ?? '',
        payload: {
          message: message?.text ?? '',
          authorName: message?.user?.name ?? '',
          provider: 'stream',
          channelId: channel_id ?? '',
        },
        timestamp: new Date(created_at ?? '').toLocaleString(),
        type: 'chat-message-new',
        state: {
          paused: false,
          timeout: notificationSettings.durationMs,
          status: 'unread',
        },
      });
    }
  });
};
