import { SchedulerServiceV3 } from '@frontend/schema';
import {
  AppointmentStatusWriteBackApiType,
  AppointmentWriteBackApiType,
  AssignAppointmentTypesToPractitionerApiType,
  AssignPractitionersToAppointmentTypeApiType,
  CreateAppointmentTypeApiType,
  CreateCalendarEventApiType,
  CreatePractitionerApiType,
  CreateScheduleEntriesApiType,
  DeleteAppointmentTypesApiType,
  DeleteCalendarEventsApiType,
  DeletePractitionersApiType,
  GetCalendarEventApiType,
  GetOperatoryApiType,
  GetPractitionerByPractitionerIdApiType,
  ListAppointmentTypesApiType,
  ListAppointmentTypesMultiLocationApiType,
  ListAppointmentTypesPractitionersApiType,
  ListCalendarAvailabilitiesApiType,
  ListCalendarEventsApiType,
  ListCalendarEventsByOwnerApiType,
  ListCalendarEventsMultiApiType,
  ListOperatoriesApiType,
  ListOperatoriesMultiLocationApiType,
  ListPractitionersApiType,
  ListPractitionersMultiLocationApiType,
  ListScheduleEntriesApiType,
  ListSourceTenantsApiType,
  PersonWriteBackApiType,
  UnassignAppointmentTypesToPractitionerApiType,
  UnassignPractitionersToAppointmentTypeApiType,
  UpdateAppointmentTypeApiType,
  UpdateCalendarEventApiType,
  UpdateOperatoryApiType,
  UpdateCalendarEventStatusApiType,
  UpdatePractitionerApiType,
  UpdateScheduleEntriesApiType,
} from './types';

export const getAppointmentTypes = async (
  req: ListAppointmentTypesApiType['input']
): Promise<ListAppointmentTypesApiType['output']> => SchedulerServiceV3.ListAppointmentTypes(req);

export const updateAppointmentType = async (
  req: UpdateAppointmentTypeApiType['input']
): Promise<UpdateAppointmentTypeApiType['output']> => SchedulerServiceV3.UpdateAppointmentType(req);

export const createAppointmentType = async (
  req: CreateAppointmentTypeApiType['input']
): Promise<CreateAppointmentTypeApiType['output']> => SchedulerServiceV3.CreateAppointmentType(req);

export const deleteAppointmentTypes = async (
  req: DeleteAppointmentTypesApiType['input']
): Promise<DeleteAppointmentTypesApiType['output']> => SchedulerServiceV3.DeleteAppointmentTypes(req);

export const listPractitioners = async (
  req: ListPractitionersApiType['input']
): Promise<ListPractitionersApiType['output']> => SchedulerServiceV3.ListPractitioners(req);

export const listPractitionersForMultiLocation = async (
  req: ListPractitionersMultiLocationApiType['input']
): Promise<ListPractitionersMultiLocationApiType['output']> => SchedulerServiceV3.ListPractitionersMulti(req);

export const listAppointmentTypesPractitioners = async (
  req: ListAppointmentTypesPractitionersApiType['input']
): Promise<ListAppointmentTypesPractitionersApiType['output']> =>
  SchedulerServiceV3.ListAppointmentTypesPractitioners(req);

export const assignPractitionersToAppointmentType = async (
  req: AssignPractitionersToAppointmentTypeApiType['input']
): Promise<AssignPractitionersToAppointmentTypeApiType['output']> =>
  SchedulerServiceV3.AssignPractitionersToAppointmentType(req);

export const unassignPractitionersToAppointmentType = async (
  req: UnassignPractitionersToAppointmentTypeApiType['input']
): Promise<UnassignPractitionersToAppointmentTypeApiType['output']> =>
  SchedulerServiceV3.UnAssignPractitionersToAppointmentType(req);

export const assignAppointmentTypeToPractitioner = async (
  req: AssignAppointmentTypesToPractitionerApiType['input']
): Promise<AssignAppointmentTypesToPractitionerApiType['output']> =>
  SchedulerServiceV3.AssignAppointmentTypesToPractitioner(req);

export const unassignAppointmentTypeToPractitioner = async (
  req: UnassignAppointmentTypesToPractitionerApiType['input']
): Promise<UnassignAppointmentTypesToPractitionerApiType['output']> =>
  SchedulerServiceV3.UnAssignAppointmentTypesToPractitioner(req);

export const updatePractitioner = async (
  req: UpdatePractitionerApiType['input']
): Promise<UpdatePractitionerApiType['output']> => SchedulerServiceV3.UpdatePractitioner(req);

export const createPractitioner = async (
  req: CreatePractitionerApiType['input']
): Promise<CreatePractitionerApiType['output']> => SchedulerServiceV3.CreatePractitioner(req);

export const deletePractitioners = async (
  req: DeletePractitionersApiType['input']
): Promise<DeletePractitionersApiType['output']> => SchedulerServiceV3.DeletePractitioners(req);

export const getAppointmentTypesForMultiLocation = async (
  req: ListAppointmentTypesMultiLocationApiType['input']
): Promise<ListAppointmentTypesMultiLocationApiType['output']> => SchedulerServiceV3.ListAppointmentTypesMulti(req);

export const listScheduleEntries = async (
  req: ListScheduleEntriesApiType['input']
): Promise<ListScheduleEntriesApiType['output']> => SchedulerServiceV3.ListSchedules(req);

export const createSchedulerEntries = async (
  req: CreateScheduleEntriesApiType['input']
): Promise<CreateScheduleEntriesApiType['output']> => SchedulerServiceV3.CreateSchedule(req);

export const updateSchedulerEntries = async (
  req: UpdateScheduleEntriesApiType['input']
): Promise<UpdateScheduleEntriesApiType['output']> => SchedulerServiceV3.UpdateSchedule(req);

export const getPractitionerByPractitionerId = async (
  req: GetPractitionerByPractitionerIdApiType['input']
): Promise<GetPractitionerByPractitionerIdApiType['output']> => SchedulerServiceV3.GetPractitioner(req);

// Calendar Events
export const listCalendarEvents = async (
  req: ListCalendarEventsApiType['input']
): Promise<ListCalendarEventsApiType['output']> => SchedulerServiceV3.ListCalendarEvents(req);

export const listCalendarEventsMulti = async (
  req: ListCalendarEventsMultiApiType['input']
): Promise<ListCalendarEventsMultiApiType['output']> => SchedulerServiceV3.ListCalendarEventsMulti(req);

export const listCalendarEventsByOwner = async (
  req: ListCalendarEventsByOwnerApiType['input']
): Promise<ListCalendarEventsByOwnerApiType['output']> => SchedulerServiceV3.ListCalendarEventsByOwner(req);

export const getCalendarEvent = async (
  req: GetCalendarEventApiType['input']
): Promise<GetCalendarEventApiType['output']> => SchedulerServiceV3.GetCalendarEvent(req);

export const createCalendarEvent = async (
  req: CreateCalendarEventApiType['input']
): Promise<CreateCalendarEventApiType['output']> => SchedulerServiceV3.CreateCalendarEvent(req);

export const updateCalendarEvent = async (
  req: UpdateCalendarEventApiType['input']
): Promise<UpdateCalendarEventApiType['output']> => SchedulerServiceV3.UpdateCalendarEvent(req);

export const deleteCalendarEvents = async (
  req: DeleteCalendarEventsApiType['input']
): Promise<DeleteCalendarEventsApiType['output']> => SchedulerServiceV3.DeleteCalendarEvents(req);

export const updateCalendarEventStatus = async (
  req: UpdateCalendarEventStatusApiType['input']
): Promise<UpdateCalendarEventStatusApiType['output']> => SchedulerServiceV3.UpdateCalendarEventStatus(req);

export const listOperatories = async (
  req: ListOperatoriesApiType['input']
): Promise<ListOperatoriesApiType['output']> => SchedulerServiceV3.ListOperatories(req);

export const listOperatoriesForMultiLocation = async (
  req: ListOperatoriesMultiLocationApiType['input']
): Promise<ListOperatoriesMultiLocationApiType['output']> => SchedulerServiceV3.ListOperatoriesMulti(req);

export const updateOperatory = async (
  req: UpdateOperatoryApiType['input']
): Promise<UpdateOperatoryApiType['output']> => SchedulerServiceV3.UpdateOperatory(req);

export const getOperatory = async (req: GetOperatoryApiType['input']): Promise<GetOperatoryApiType['output']> =>
  SchedulerServiceV3.GetOperatory(req);

export const getListCalendarAvailabilities = async (
  req: ListCalendarAvailabilitiesApiType['input']
): Promise<ListCalendarAvailabilitiesApiType['output']> => SchedulerServiceV3.ListCalendarAvailabilities(req);
export const appointmentWriteback = async (
  req: AppointmentWriteBackApiType['input']
): Promise<AppointmentWriteBackApiType['output']> => SchedulerServiceV3.AppointmentWriteBack(req);

export const appointmentStatusWriteback = async (
  req: AppointmentStatusWriteBackApiType['input']
): Promise<AppointmentStatusWriteBackApiType['output']> => SchedulerServiceV3.AppointmentStatusWriteBack(req);

export const personWriteback = async (
  req: PersonWriteBackApiType['input']
): Promise<PersonWriteBackApiType['output']> => SchedulerServiceV3.PersonWriteBack(req);

export const listSourceTenants = async (
  req: ListSourceTenantsApiType['input']
): Promise<ListSourceTenantsApiType['output']> => SchedulerServiceV3.ListSourceTenants(req);
