import { HTMLAttributes } from 'react';
import { css } from '@emotion/react';
import { SpinningLoader } from '@frontend/design-system';

export const OnSaveSpinningLoader = ({ ...rest }: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div css={onSaveSpinningLoaderStyles} {...rest}>
      <SpinningLoader />
    </div>
  );
};

const onSaveSpinningLoaderStyles = css({
  display: 'flex',
  justifyContent: 'end',
});
