import { useEffect, useRef, useState } from 'react';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { MessagesHooks, MessagesTypes } from '@frontend/api-messaging';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { TagsUtils, TagsV2 } from '@frontend/api-tag';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useInboxNavigate } from '@frontend/inbox-navigation';
import { useAppScopeStore } from '@frontend/scope';
import { sentry } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import { IconButton, ListRow, useAlert } from '@frontend/design-system';
import { ThreadTag } from './thread-tag';

type InboxListItemTagsProps = {
  uniqueTags: MessagesTypes.InboxListItem['uniqueTags'];
  threadNavData: {
    personPhone: string;
    threadId: string;
    groupId: string;
    departmentId?: string;
    personId?: string;
  };
};

export const InboxListItemTags = ({ uniqueTags = [], threadNavData }: InboxListItemTagsProps) => {
  const { t } = useTranslation('inbox');
  const { selectedLocationIds, selectedOrgId } = useAppScopeStore();
  const [isExpanded, setIsExpanded] = useState(false);
  const { navigateToThread } = useInboxNavigate();
  const [canExpand, setCanExpand] = useState(false);
  const { threadId, groupId, personId, personPhone } = threadNavData;
  const alert = useAlert();
  const invalidateInboxList = MessagesHooks.useInvalidateInboxList();
  const { invalidateQuery: invalidateThread } = MessagesHooks.useUpdateThread();

  const dismissTag = SMSDataV3.Mutations.useDismissTagInThreadMutation({
    options: {
      onSuccess: () => {
        alert.info({ message: t('Tag removed.') });
        invalidateInboxList();
        invalidateThread({
          threadId,
          locationId: groupId,
        });
      },
      onError: (error) => {
        alert.error(t('Error removing tag. Please try again.'));
        sentry.log({
          message: 'Error removing tag after no undo',
          category: 'messages',
          type: 'error',
          data: {
            error,
          },
        });
      },
    },
  });

  const { data: flags } = FeatureFlagQueries.useMultiFeatureFlagIsEnabledQuery({
    flagName: 'comm-platform-thread-naming',
    groupIds: [groupId],
  });
  const usePersonId = !flags?.[groupId];

  const ref = useRef<HTMLDivElement>(null);

  const { data: tags = [] } = TagsV2.Queries.useListTagsQuery({
    request: {
      groupIds: selectedLocationIds,
      orgId: selectedOrgId,
    },
    options: {
      enabled: !!uniqueTags.length,
      select: (data) => {
        const enabledTags = TagsUtils.filterDisabledTags(data.tags);
        const matchingTags = enabledTags.filter((tag) => uniqueTags.some((uniqueTag) => uniqueTag.tagId === tag.id));
        return TagsUtils.sortTagsAlphabetically(matchingTags);
      },
    },
  });
  const { data: ffMap } = FeatureFlagQueries.useMultiFeatureFlagIsEnabledQuery(
    {
      flagName: 'nwx:smart-tags',
      groupIds: [groupId],
    },
    {
      placeholderData: {
        [groupId]: false,
      },
    }
  );
  const mappedUniqueTags: { uniqueTag: NonNullable<typeof uniqueTags>[number]; tag?: (typeof tags)[number] }[] =
    uniqueTags.map((uniqueTag) => ({ uniqueTag, tag: tags.find((tag) => tag.id === uniqueTag.tagId) }));
  const filteredUniqueTags = ffMap?.[groupId]
    ? mappedUniqueTags
    : mappedUniqueTags.filter(({ tag }) => !tag?.isDefaultTag && !tag?.smartTagId);

  const tagGap = theme.spacing(1);
  const tagContainerRightPadding = theme.spacing(4);

  useEffect(() => {
    if (ref.current) {
      const tagContainerWidth = ref.current.clientWidth - Number(tagContainerRightPadding.replace('px', ''));

      const totalTagWidth = Array.from(ref.current.children).reduce((acc, child, index, arr) => {
        // +2 for the border width on each side
        acc += child.clientWidth + 2;
        if (index !== arr.length - 1) acc += Number(tagGap.replace('px', ''));
        return acc;
      }, 0);

      if (tagContainerWidth < totalTagWidth && !isExpanded) setCanExpand(true);
      else if (tagContainerWidth >= totalTagWidth) setCanExpand(false);
    }
  }, [uniqueTags.length, ref.current]);

  const onTagRemoveClick = ({ tagId }: { tagId: string }) => {
    dismissTag.mutate({
      groupId,
      tagId,
      threadId,
    });
  };

  if (!uniqueTags.length) return null;

  return (
    <ListRow.Content.TertiaryTitle
      css={[
        { display: 'flex', alignItems: 'center', justifyContent: 'space-between' },
        isExpanded && { flexDirection: 'column' },
      ]}
      as='span'
    >
      <div
        ref={ref}
        css={[
          {
            position: 'relative',
            display: 'flex',
            overflow: 'hidden',
            gap: tagGap,
            flexWrap: 'wrap',
            transition: 'max-height 0.3s ease-in-out, padding-bottom 0.3s ease-in-out',
            width: '100%',
            maxHeight: '35px',
            justifyContent: 'start',
            padding: 4,
            paddingRight: tagContainerRightPadding,
            marginTop: theme.spacing(1),
          },
          isExpanded && { maxHeight: '200px', paddingBottom: theme.spacing(3) },
        ]}
      >
        {filteredUniqueTags.map(({ uniqueTag, tag }) => (
          <ThreadTag
            key={uniqueTag.tagId}
            tagId={uniqueTag.tagId}
            tag={tag}
            isAutoApplied={!uniqueTag.appliedBy}
            onClick={(e) => {
              e.stopPropagation();
              navigateToThread({
                threadId,
                groupId,
                personId: usePersonId ? personId : undefined,
                personPhone,
                smsId: uniqueTag.smsId,
                smsCreatedAt: uniqueTag.smsCreatedAt,
                replace: true,
              });
            }}
            isRemovable
            onRemoveClick={() => onTagRemoveClick({ tagId: uniqueTag.tagId })}
          />
        ))}
        {canExpand && (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setIsExpanded((prev) => !prev);
            }}
            label={isExpanded ? t('See more tags') : t('See less tags')}
            size='small'
            css={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              ':hover:not(:disabled)': { background: theme.colors.white },
            }}
          >
            <Icon
              name='caret-down-small'
              color='light'
              css={{
                transform: isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 0.2s ease-in-out',
              }}
            />
          </IconButton>
        )}
      </div>
    </ListRow.Content.TertiaryTitle>
  );
};
