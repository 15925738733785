import { useLayoutEffect } from 'react';
import { VoiceMailboxApi } from '@frontend/api-voicemail-boxes';
import { VoicemailTypes } from '@frontend/api-voicemails';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import { Accordion, Button, Heading, IconButton, SecondaryButton, XIcon } from '@frontend/design-system';
import { transcriptionTrackingId } from '../../pendo-tracking';
import { queryKeys } from '../../query-keys';
import {
  CallDetailsSectionBody,
  ContactSection,
  TagsSectionBody,
  TranscriptSectionBody,
} from './transcription-panel-sections';
import { TranscriptionContext } from './types';
import { getTranscriptionUniqueKey } from './utils';

export const TranscriptionPanel = () => {
  const { t } = useTranslation('calls');
  const { setShow, context } = useSlidePanelShallowStore<TranscriptionContext>('setShow', 'context');
  const currentRowIndex =
    context?.rows.findIndex((row) => getTranscriptionUniqueKey(row) === context.currentRowId) ?? 0;
  const row = context?.rows[currentRowIndex];
  const transcriptionUniqueKey = getTranscriptionUniqueKey(row);
  const sectionFocus = context?.sectionFocus ?? ['transcript'];

  const { data: transcription } = useScopedQuery({
    queryKey: queryKeys.voicemailTranscript(row?.channelId ?? ''),
    queryFn: async () => {
      const response = await VoiceMailboxApi.getTranscription(row?.channelId ?? '');
      return response;
    },
    enabled: !!row,
  });

  /**
   * We rely on the row index here for many things, which works fine until the context is disconnected from the current voicemail table instance.
   *
   * This panel is triggered by the table, and receives context from certain actions performed in the table. However, actions related to pagination do not
   * give us access to the table instance, so we cannot update the panel context with the new rows. To prevent issues, we will close the panel when one of
   * these "unsyncable" actions are performed.
   *
   * As long as the rows in the context is synced correctly, relying on row index will work as expected.
   */

  useLayoutEffect(() => {
    /** The scroll effect performs better with useLayoutEffect */
    if (row) {
      context.toggleRowSelected(transcriptionUniqueKey, true);
      const rowElement = document.querySelectorAll(`#${context?.instanceId} [role=row]`)[currentRowIndex];
      rowElement?.scrollIntoView({ behavior: 'smooth' });
    }

    return () => {
      if (context && row) {
        context.toggleRowSelected(transcriptionUniqueKey, false);
      }
    };
  }, [row]);

  if (!context) {
    return null;
  }

  return (
    <div style={{ display: 'grid', gridTemplateRows: 'auto 1fr auto', height: '100%' }}>
      <header
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: theme.spacing(4, 4, 2, 4),
          borderBottom: `1px solid ${theme.colors.neutral20}`,
        }}
      >
        <Heading level={2} css={{ margin: 0 }}>
          {t('Voicemail Transcript')}
        </Heading>
        <IconButton
          label={t('Close panel')}
          onClick={() => {
            setShow(false);
          }}
        >
          <XIcon />
        </IconButton>
      </header>
      {row && (
        <div style={{ overflow: 'auto' }}>
          <ContactSection
            contact={{ id: row.contactId, name: row.contactName, number: row.contactNumber }}
            locationId={row.locationId ?? ''}
            mediaFilePath={row.mediaFilePath}
            date={row.dateTime}
            message={row}
            updateReadStatus={async (markIsRead: boolean) => {
              const res = await context.updateReadStatus({
                index: currentRowIndex,
                status: markIsRead
                  ? VoicemailTypes.ViewStatus.VIEW_STATUS_READ
                  : VoicemailTypes.ViewStatus.VIEW_STATUS_UNREAD,
                voicemailId: row.channelId,
              });
              return res;
            }}
            deleteVoicemail={() => context.deleteVoicemail({ index: currentRowIndex, voicemailId: row.channelId })}
            openForwardVoicemailModal={() => {
              context.openForwardVoicemailModal({
                currentVoicemailBoxId: row.mailboxId,
                mediaId: row.mediaId,
                mediaPath: row.mediaFilePath,
                currentVoicemailBoxName: row.voiceMailBoxName,
                vmId: row.voicemailId,
                rowIndex: currentRowIndex,
              });
            }}
          />

          <Accordion variant='blank' startingValue={sectionFocus} isMulti>
            <Accordion.Item title={t('Transcript')} value='transcript'>
              <Accordion.Header css={{ borderBottom: 'none', padding: theme.spacing(0, 2) }} title={t('Transcript')} />
              <Accordion.Body css={{ padding: theme.spacing(0, 4, 2, 4) }}>
                <TranscriptSectionBody
                  message={row}
                  transcription={transcription}
                  mediaFilePath={row.mediaFilePath}
                  updateReadStatus={async () => {
                    const res = await context.updateReadStatus({
                      index: currentRowIndex,
                      status: VoicemailTypes.ViewStatus.VIEW_STATUS_READ,
                      voicemailId: row.channelId,
                    });

                    return res;
                  }}
                />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item title={t('Call Details')} value='details'>
              <Accordion.Header
                css={{ borderBottom: 'none', padding: theme.spacing(0, 2) }}
                title={t('Call Details')}
              />
              <Accordion.Body css={{ padding: theme.spacing(0, 4, 2, 4) }}>
                <CallDetailsSectionBody mailboxName={row.voiceMailBoxName} date={row.dateTime} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item title={t('Tags')} value='tags'>
              <Accordion.Header css={{ borderBottom: 'none', padding: theme.spacing(0, 2) }} title={t('Tags')} />
              <Accordion.Body css={{ padding: theme.spacing(0, 4, 2, 4) }}>
                <TagsSectionBody
                  tags={row.tags}
                  voicemailId={row.voicemailId}
                  voicemailsQueryKey={context.voicemailsQueryKey}
                />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      )}
      <div
        style={{
          display: 'flex',
          gap: theme.spacing(1),
          padding: theme.spacing(2, 4, 4),
          justifyContent: 'end',
          borderTop: `1px solid ${theme.colors.neutral20}`,
        }}
      >
        <Button
          trackingId={transcriptionTrackingId({ subComponent: 'panel', context: 'delete' })}
          size='large'
          destructive
          variant='tertiary'
          iconName='trash'
          onClick={() => {
            context.deleteVoicemail({ index: currentRowIndex, voicemailId: row?.channelId });
            if (currentRowIndex + 1 >= context?.rows.length) {
              setShow(false);
              return;
            }
            setShow(true, 'voicemailTranscription', {
              updateReadStatus: context.updateReadStatus,
              deleteVoicemail: context.deleteVoicemail,
              instanceId: context?.instanceId,
              rows: context?.rows,
              toggleRowSelected: context.toggleRowSelected,
              currentRowId: getTranscriptionUniqueKey(context.rows[currentRowIndex + 1]),
              voicemailsQueryKey: context.voicemailsQueryKey,
              openForwardVoicemailModal: context.openForwardVoicemailModal,
            });
          }}
        >
          {t('Delete Voicemail')}
        </Button>

        <SecondaryButton
          trackingId={transcriptionTrackingId({ subComponent: 'panel', context: 'previous' })}
          size='large'
          css={{ width: 'auto', marginLeft: 'auto' }}
          disabled={currentRowIndex - 1 < 0}
          onClick={() => {
            setShow(true, 'voicemailTranscription', {
              updateReadStatus: context.updateReadStatus,
              deleteVoicemail: context.deleteVoicemail,
              instanceId: context?.instanceId,
              rows: context?.rows,
              toggleRowSelected: context.toggleRowSelected,
              currentRowId: getTranscriptionUniqueKey(context.rows[currentRowIndex - 1]),
              voicemailsQueryKey: context.voicemailsQueryKey,
              openForwardVoicemailModal: context.openForwardVoicemailModal,
            });
          }}
        >
          {t('Previous')}
        </SecondaryButton>

        <SecondaryButton
          trackingId={transcriptionTrackingId({ subComponent: 'panel', context: 'next' })}
          size='large'
          css={{ width: 'auto' }}
          disabled={currentRowIndex + 1 >= context?.rows.length}
          onClick={() => {
            setShow(true, 'voicemailTranscription', {
              updateReadStatus: context.updateReadStatus,
              deleteVoicemail: context.deleteVoicemail,
              instanceId: context?.instanceId,
              rows: context?.rows,
              toggleRowSelected: context.toggleRowSelected,
              currentRowId: getTranscriptionUniqueKey(context.rows[currentRowIndex + 1]),
              voicemailsQueryKey: context.voicemailsQueryKey,
              openForwardVoicemailModal: context.openForwardVoicemailModal,
            });
          }}
        >
          {t('Next')}
        </SecondaryButton>
      </div>
    </div>
  );
};
