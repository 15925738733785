import { useEffect } from 'react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, TimeField, useFormField } from '@frontend/design-system';
import { DateTimeSelectionHandlerKeys } from '../types';

type TimeFiltersProps = {
  onDateTimeSelection: (key: DateTimeSelectionHandlerKeys, value: string) => void;
  initialValue: {
    startTime: string;
    endTime: string;
  };
};
export const TimeFilters = ({ onDateTimeSelection, initialValue }: TimeFiltersProps) => {
  const { t } = useTranslation('inbox');
  const { startTime, endTime } = initialValue;

  const startTimeFieldProps = useFormField(
    {
      type: 'time',
      value: startTime,
    },
    [startTime]
  );
  const endTimeFieldProps = useFormField(
    {
      type: 'time',
      value: endTime,
    },
    [endTime]
  );

  useEffect(() => {
    const removeSeconds = (time: string) => {
      const lastColonIndex = time.lastIndexOf(':');
      const timeWithoutSeconds = time.substring(0, lastColonIndex);
      return timeWithoutSeconds;
    };

    if (startTimeFieldProps.value && endTimeFieldProps.value) {
      onDateTimeSelection('startTime', removeSeconds(startTimeFieldProps.value));
      onDateTimeSelection('endTime', removeSeconds(endTimeFieldProps.value));
    }
  }, [startTimeFieldProps.value, endTimeFieldProps.value]);

  return (
    <div data-testid='inbox-time-filters-box' css={{ padding: theme.spacing(0, 2) }}>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: theme.spacing(1),
        }}
      >
        <TimeField {...startTimeFieldProps} name='startTime' label={t('Start Time')} interval={60} />
        <Text>{t('to')}</Text>
        <TimeField {...endTimeFieldProps} name='endTime' label={t('End Time')} interval={60} />
      </div>
    </div>
  );
};
