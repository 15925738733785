import { FC, HTMLAttributes, MouseEventHandler } from 'react';
import { css } from '@emotion/react';
import { ResultType, ThreadResult } from '@weave/schema-gen-ts/dist/schemas/sms/search/v2/sms_search_service.pb';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { PersonsV3 } from '@frontend/api-person';
import { Icon } from '@frontend/icons';
import { useInboxNavigate } from '@frontend/inbox-navigation';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { Photos } from '@frontend/photos';
import { theme } from '@frontend/theme';
import { NakedButton, Text } from '@frontend/design-system';
import { TextWithMatches } from './matching-text';
import { StringMatch, convertStringToMatchedWords, getPhoneMatches } from './utils';

export type FlattenedMessageSearchResult = Omit<ThreadResult, 'messages'> & {
  message: ThreadResult['messages'][number];
  resultType: ThreadResult['resultType'];
};

type MessageSearchResultProps = FlattenedMessageSearchResult & {
  searchValue: string;
  onSelect: MouseEventHandler<HTMLButtonElement>;
  hideBottomBorder?: boolean;
};

export const MessageSearchResult: FC<HTMLAttributes<HTMLLIElement> & MessageSearchResultProps> = ({
  person,
  personPhone,
  threadId,
  locationId: groupId,
  message,
  resultType,
  searchValue,
  onSelect,
  hideBottomBorder,
  ...rest
}) => {
  const searchWords = searchValue.trim().split(' ');
  const personName = person ? PersonsV3.PersonHelpers.getFullName(person) : '';
  const titleMatches: StringMatch[] =
    person && resultType === ResultType.RESULT_TYPE_PERSON
      ? convertStringToMatchedWords(personName || formatPhoneNumber(personPhone), searchWords)
      : [{ str: personName || formatPhoneNumber(personPhone), isMatch: false }];
  const messageBodyMatches =
    resultType === ResultType.RESULT_TYPE_THREAD
      ? convertStringToMatchedWords(message.fragment, searchWords)
      : [{ str: message.fragment, isMatch: false }];

  const { navigateToThread } = useInboxNavigate();

  const phoneNumbers = getPhoneMatches(searchValue, person?.contactInfo);

  const { data: flags } = FeatureFlagQueries.useMultiFeatureFlagIsEnabledQuery({
    flagName: 'comm-platform-thread-naming',
    groupIds: [groupId],
  });
  const usePersonId = !flags?.[groupId];

  return (
    <li css={{ width: '100%' }} {...rest}>
      <NakedButton
        css={[
          {
            display: 'flex',
            gap: theme.spacing(2),
            padding: theme.spacing(1),
            minWidth: 0,
            width: '100%',
            alignItems: 'center',
            transition: 'background-color 250ms ease-in-out',
            overflow: 'clip',
            ':hover': {
              backgroundColor: theme.colors.neutral5,
            },
          },
          !hideBottomBorder && {
            borderBottom: `1px solid ${theme.colors.neutral10}`,
          },
        ]}
        onClick={(e) => {
          navigateToThread({
            threadId,
            groupId,
            personId: usePersonId ? person?.personId : undefined,
            personPhone,
            smsId: message.smsId,
            smsCreatedAt: message.timestamp,
            replace: true,
          });
          onSelect(e);
        }}
      >
        <Photos.ContactProfilePhoto personId={person?.personId ?? ''} name={personName} />
        <div
          css={{
            width: '100%',
            minWidth: 0,
          }}
        >
          <TextWithMatches text={titleMatches} textAlign='left' />

          {!!phoneNumbers.length && (
            <div
              css={css`
                margin-bottom: ${theme.spacing(0.25)};
              `}
            >
              {phoneNumbers.map((phone) => (
                <Text
                  key={phone.phoneNumber}
                  size='small'
                  weight='bold'
                  textAlign='left'
                  css={css`
                    display: flex;
                    align-items: center;
                    gap: ${theme.spacing(0.25)};
                  `}
                >
                  <Icon name={phone.iconName} size={16} />
                  <span>{phone.phoneNumber}</span>
                </Text>
              ))}
            </div>
          )}
          <TextWithMatches
            text={messageBodyMatches}
            css={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden', width: '100%' }}
            color='light'
            textAlign='left'
            size='small'
          />
        </div>
      </NakedButton>
    </li>
  );
};
