import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const rotateCollapseButton = css({
  transform: `rotate(180deg)`,
  transition: 'transform 0.3s',
});

export const trayTopBarStyles = css({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  padding: theme.spacing(1, 2),
  borderBottom: `1px solid ${theme.colors.neutral10}`,
});

export const buttonSvgStyles = css({
  svg: { width: '20px', height: '20px' },
});
