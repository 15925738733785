import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { NakedButton, Text, useTooltip } from '@frontend/design-system';

export const AddBreakButton = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation('schedule');
  const { tooltipProps, triggerProps, Tooltip } = useTooltip();
  return (
    <>
      <NakedButton {...triggerProps} css={addBreakButtonStyles} onClick={onClick}>
        <Icon size={8} name='plus-small' />
      </NakedButton>

      <Tooltip {...tooltipProps}>
        <Text as='span' color='white'>
          {t('Add Break')}
        </Text>
      </Tooltip>
    </>
  );
};

const addBreakButtonStyles = css({
  borderRadius: theme.borderRadius.full,
  border: `thin solid ${theme.colors.neutral50}`,
  marginLeft: theme.spacing(2),
  width: 16,
  height: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
