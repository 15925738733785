import { FC } from 'react';
import { Accordion } from '@frontend/design-system';
import { BuilderFieldDivisionCategories } from '../../constants';
import { useFormBuilderStore } from '../../provider';
import { PrimaryFieldCatrgoryLabel } from '../../types/field-divisions';
import { containerStyle } from './builder-division-fields.styles';
import { NonSyncableMedicalHistoryFields, OtherFields, PrimaryFields, SectionTemplates } from './categories';
import BuilderFieldSearchBox from './field-search-box/field-search-box.component';

const DEFAULT_PRIMARY_FIELD_OPEN_CATEGORIES: Array<PrimaryFieldCatrgoryLabel> = ['Personal Information'];
const DEFAULT_OPEN_CATEGORIES = [
  ...DEFAULT_PRIMARY_FIELD_OPEN_CATEGORIES,
  BuilderFieldDivisionCategories.OTHER_FIELD_CATEGORIES[0].label,
  BuilderFieldDivisionCategories.GENERIC_MEDICAL_HISTORY_FIELD_CATEGORIES[0].label,
];

export const BuilderDivisionFields: FC = () => {
  const { activeBuilderDivision } = useFormBuilderStore(['activeBuilderDivision']);

  return (
    <section css={containerStyle}>
      <BuilderFieldSearchBox />

      <Accordion variant='blank' isMulti startingValue={DEFAULT_OPEN_CATEGORIES}>
        {activeBuilderDivision === 'Primary Fields' && <PrimaryFields />}
        {activeBuilderDivision === 'Elements' && <OtherFields />}
        {activeBuilderDivision === 'Medical History' && <NonSyncableMedicalHistoryFields />}
        {activeBuilderDivision === 'Section Templates' && <SectionTemplates />}
      </Accordion>
    </section>
  );
};
