import React from 'react';
import { css } from '@emotion/react';
import { PersonHelpers, PersonQueries, PersonTypes } from '@frontend/api-person';
import { AvatarCropper, readFileAsDataUrl } from '@frontend/avatar-image-cropper';
import { useTranslation } from '@frontend/i18n';
import { Photos, usePhotoStore } from '@frontend/photos';
import { useContactPanelStore } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { Avatar, Heading, Text, SkeletonLoader, styles, CopyableText } from '@frontend/design-system';
import { ContactActions } from '../contact-actions';
import { HouseholdGuardianBadge } from '../contact-details-sections/sections';
import { patronAvatarStyle, panelHeaderContainer, fadeInAnimation } from '../contact-panel.styles';

type CustomPanelHeaderProps = {
  person: PersonTypes.Person;
  locationId?: string;
} & Omit<React.ComponentProps<typeof Avatar>, 'src' | 'size'>;

export const ContactPanelHeader = ({ person, locationId, children, ...rest }: CustomPanelHeaderProps) => {
  const { householdId } = useContactPanelStore();
  const { t } = useTranslation('contacts');
  const isCustomContact = PersonTypes.PersonSourceType.CustomContact === person.SourceType;
  const setPhotoRecord = usePhotoStore((state) => state.setPhotoRecord);
  const { mutateAsync: uploadMutation, isLoading: uploadLoading } = PersonQueries.useUploadPersonImage();

  return (
    <div css={panelHeaderContainer}>
      <SkeletonLoader shape='circle' width={64} height={64} css={patronAvatarStyle}>
        {isCustomContact ? (
          <AvatarCropper
            PersonID={person.PersonID}
            FirstName={person.FirstName}
            LastName={person.LastName}
            onCropComplete={(croppedBlob) => {
              const personId = person.PersonID;
              if (!personId) {
                throw new Error('no person id');
              }
              readFileAsDataUrl(croppedBlob, (photoUrl) => setPhotoRecord(personId, photoUrl));
              uploadMutation({ personId: person.PersonID, image: croppedBlob });
            }}
            loading={uploadLoading}
          />
        ) : (
          <Photos.ProfilePhoto
            disableClick
            size='xl'
            css={[patronAvatarStyle, fadeInAnimation]}
            name={`${PersonHelpers.getFullName({
              FirstName: person.FirstName,
              LastName: person.LastName,
            })}${householdId === person.PMID ? ' (HoH)' : ''}`}
            personId={person.PersonID}
            {...rest}
          >
            {children}
            {householdId === person.PMID && <HouseholdGuardianBadge />}
          </Photos.ProfilePhoto>
        )}
      </SkeletonLoader>
      <section
        css={css`
          margin-left: ${theme.spacing(2)};
        `}
      >
        <CopyableText
          trackingId='contact-panel-header-copy-id'
          tooltipSuccessText={t('ID copied')}
          tooltipText={t('Copy ID')}
          css={[
            styles.truncate,
            css`
              max-width: 80px;
            `,
          ]}
          text={person.PMDisplayID}
          size='small'
          color='subdued'
        >
          #{person.PMDisplayID}
        </CopyableText>
        <Heading level={2} css={fadeInAnimation}>
          <SkeletonLoader isText width={180}>
            <span>{person.FirstName}</span>{' '}
            <span
              css={css`
                font-weight: ${theme.font.weight.regular};
              `}
            >
              {person.LastName}
            </span>
          </SkeletonLoader>
        </Heading>
        <ContactAdditionalDetails person={person} />
        <ContactActions person={person} locationId={locationId} />
      </section>
    </div>
  );
};

const ContactAdditionalDetails = ({ person }: { person: PersonTypes.Person }) => {
  const age = PersonHelpers.getAge(person);
  const { t } = useTranslation('contacts');
  if (person.SourceType === undefined) {
    return null;
  }
  return (
    <SkeletonLoader
      css={css`
        margin-top: ${theme.spacing(1)};
      `}
      isText
      width={100}
    >
      <div
        css={css`
          display: flex;
          gap: ${theme.spacing(2)};
        `}
      >
        {!!person.Status && (
          <Text size='medium' color='light' title={person.Status}>
            {person.Status}
          </Text>
        )}
        {!!age && (
          <Text size='medium' color='light'>
            {t('{{age}} y/o', { age })}
          </Text>
        )}
        {!!person.Gender && (
          <Text size='medium' color='light'>
            {person.Gender}
          </Text>
        )}
      </div>
    </SkeletonLoader>
  );
};
