import React, { ElementType, FC, useState } from 'react';
import dayjs from 'dayjs';
import calendar from 'dayjs/plugin/calendar';
import { useTranslation } from '@frontend/i18n';
import { useTimestampFormatter } from '@frontend/timestamp-formatter';
import { ComponentProps } from '@frontend/types';
import { theme } from '@frontend/theme';
import {
  IconButton,
  ListRow,
  ListRowProps,
  MoreIcon,
  PopoverMenu,
  PopoverMenuItem,
  usePopoverMenu,
  Text,
  IconProps,
  FontColorType,
  FontWeight,
} from '@frontend/design-system';

dayjs.extend(calendar);

export type StatusAlert = {
  text: string;
  color?: FontColorType;
  weight?: FontWeight;
  customColor?: string;
};

export type VariantRowProps<C extends React.ElementType = 'li'> = ListRowProps<C> & {
  Lead: JSX.Element;
  Title: JSX.Element;
  Content?: JSX.Element;
  timestamp?: string;
  timestampOverride?: string;
  isExpanded?: boolean;
  ExpandedContent?: JSX.Element;
  trackingId?: string;
  actions?: {
    Icon: PopoverMenuItem['Icon'];
    label: string;
    action: () => void;
  }[];
  iconButtonProps?: VariantIconButtonProps['iconButtonProps'];
  timestampOverrideProps?: StatusAlert | null;
};

export const ActionableListRow = <C extends React.ElementType = 'li'>({
  Lead,
  Title,
  Content,
  isExpanded,
  ExpandedContent,
  timestamp,
  timestampOverride,
  timestampOverrideProps,
  actions,
  as,
  iconButtonProps,
  trackingId,
  ...rest
}: VariantRowProps<C>) => {
  const [isHovering, setIsHovering] = useState(false);

  const { getTriggerProps, getMenuProps, getItemProps, isOpen } = usePopoverMenu({
    placement: 'bottom-end',
    middlewareOptions: {
      offset: {
        alignmentAxis: 0,
        mainAxis: 0,
      },
    },
  });
  const formatTimestamp = useTimestampFormatter();

  return (
    <ListRow
      trackingId={trackingId}
      // Something is weird here with TS
      as={as as ElementType}
      css={{ gridTemplateColumns: 'auto 1fr' }}
      onMouseEnter={() => {
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        if (!isOpen) {
          setIsHovering(false);
        }
      }}
      {...rest}
    >
      {Lead}
      <ListRow.Content css={{ display: 'grid', gridTemplateRows: 'repeat(auto-fit, minmax(24px, auto))' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', minWidth: 0 }}>
          {Title}
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div
              style={{
                position: 'absolute',
                right: theme.spacing(3),
                width: '60px',
                display: 'flex',
                justifyContent: 'end',
                alignItems: 'center',
                visibility: isHovering ? 'visible' : 'hidden',
                opacity: isHovering ? 1 : 0,
                transition: 'all 200ms ease-in-out',
              }}
            >
              {iconButtonProps && <VariantIconButton iconButtonProps={iconButtonProps} />}
              {actions && (
                <VariantMenu
                  actions={actions}
                  getMenuProps={getMenuProps}
                  getItemProps={getItemProps}
                  getTriggerProps={getTriggerProps}
                />
              )}
            </div>
            <Text
              size='small'
              color={timestampOverrideProps ? timestampOverrideProps.color : 'light'}
              weight={timestampOverrideProps ? timestampOverrideProps.weight : 'regular'}
              css={{
                flexShrink: 0,
                width: 'max-content',
                visibility: isHovering ? 'hidden' : 'visible',
                opacity: isHovering ? 0 : 1,
                transition: 'all 200ms ease-in-out',
                color: timestampOverrideProps ? timestampOverrideProps.customColor : '',
              }}
            >
              {timestampOverride || formatTimestamp(timestamp)}
            </Text>
          </div>
        </div>
        {Content}
        {isExpanded && ExpandedContent}
      </ListRow.Content>
    </ListRow>
  );
};

type VariantMenuProps = {
  actions: {
    Icon: PopoverMenuItem['Icon'];
    label: string;
    action: () => void;
    trackingId?: string;
  }[];
  getItemProps: ReturnType<typeof usePopoverMenu<HTMLElement>>['getItemProps'];
  getMenuProps: ReturnType<typeof usePopoverMenu<HTMLElement>>['getMenuProps'];
  getTriggerProps: ReturnType<typeof usePopoverMenu<HTMLElement>>['getTriggerProps'];
};

export const VariantMenu = ({ actions, getItemProps, getTriggerProps, getMenuProps }: VariantMenuProps) => {
  const { t } = useTranslation('base');
  const menuProps = getMenuProps();

  const triggerProps = getTriggerProps({
    onClick: (e) => {
      e.stopPropagation();
    },
  });

  return (
    <>
      <IconButton
        css={{ height: 24, ':hover:not(:disabled)': { background: 'none', color: theme.colors.primary50 } }}
        size='small'
        {...triggerProps}
        label={t('More actions')}
      >
        <MoreIcon />
      </IconButton>

      <PopoverMenu {...menuProps}>
        {actions.map(({ Icon, label, action, trackingId }, index) => {
          return (
            <PopoverMenuItem
              Icon={Icon}
              {...getItemProps({
                index,
                onClick: (e) => {
                  e.stopPropagation();
                  action();
                },
              })}
              key={label}
              trackingId={trackingId}
            >
              {label}
            </PopoverMenuItem>
          );
        })}
      </PopoverMenu>
    </>
  );
};

type VariantIconButtonProps = {
  iconButtonProps: {
    label: string;
    onClick: () => void;
    Icon: FC<React.PropsWithChildren<IconProps>>;
    trackingId?: string;
    showLabelOnHover?: boolean;
    size?: ComponentProps<typeof IconButton>['size'];
    className?: string;
  };
};

const VariantIconButton = ({ iconButtonProps }: VariantIconButtonProps) => {
  const { label, onClick, Icon, trackingId, showLabelOnHover, size, className } = iconButtonProps;

  return (
    <IconButton
      className={className ?? ''}
      css={{ height: 24, ':hover:not(:disabled)': { background: 'none', color: theme.colors.primary50 } }}
      size={size ?? 'small'}
      label={label}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      trackingId={trackingId}
      showLabelOnHover={showLabelOnHover}
    >
      <Icon />
    </IconButton>
  );
};
