import { FC, PropsWithChildren, useMemo } from 'react';
import { css, Interpolation, Theme } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, NakedButton, Text } from '@frontend/design-system';
import { SelfUpgradeActionType } from '../../constants/self-upgrade';
import { DemoModalTrackingIds, getFeatureWiseTrackingId } from '../../constants/tracking-ids';
import { useGetSelfUpgradeAction } from '../../hooks/useGetSelfUpgradeAction';
import { useFeatureSubscription } from '../../providers/FeatureSubscriptionProvider';
import { FeatureUpgradeIcon } from '../feature-upgrade-icon/FeatureUpgradeIcon';

interface FeatureDemoModalProps extends ModalControlModalProps {
  onUpgradeAction?: () => void;
  contentStyles?: Interpolation<Theme>;
}
export const FeatureDemoModal: FC<PropsWithChildren<FeatureDemoModalProps>> = ({
  children,
  contentStyles,
  onUpgradeAction,
  ...modalProps
}) => {
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'demo-modal' });
  const { feature } = useFeatureSubscription();
  const { actionType, actionTextData, isLoading } = useGetSelfUpgradeAction();

  const actionButtonTrackingId = useMemo<string | undefined>(() => {
    switch (actionType) {
      case SelfUpgradeActionType.SCHEDULE_CALL:
        return getFeatureWiseTrackingId(feature, DemoModalTrackingIds.scheduleCallAction);
      case SelfUpgradeActionType.REQUEST_UPGRADE:
        return getFeatureWiseTrackingId(feature, DemoModalTrackingIds.requestUpgradeAction);
      case SelfUpgradeActionType.UPGRADE_NOW:
        return getFeatureWiseTrackingId(feature, DemoModalTrackingIds.upgradeAction);
      case SelfUpgradeActionType.FREE_TRIAL:
        return getFeatureWiseTrackingId(feature, DemoModalTrackingIds.freeTrialAction);
      default:
        return;
    }
  }, [actionType, feature]);

  const handleUpgradeAction = () => {
    onUpgradeAction?.();
    modalProps.onClose();
  };

  return (
    <Modal {...modalProps} maxWidth={1200} css={demoModalStyles}>
      <Modal.Header
        onClose={modalProps.onClose}
        closeBtnTrackingId={getFeatureWiseTrackingId(feature, DemoModalTrackingIds.dismissAction)}
        css={headerStyles}
      >
        <div css={{ display: 'flex', alignItems: 'center', gap: theme.spacing(1) }}>
          <FeatureUpgradeIcon css={{ aspectRatio: 1, width: 20 }} />
          <Text weight='bold' size='large'>
            {t('This is a demo using sample data.')}
          </Text>
          {!isLoading && actionType !== SelfUpgradeActionType.NONE && (
            <NakedButton trackingId={actionButtonTrackingId} onClick={handleUpgradeAction}>
              <Text as='span' color='primary' weight='bold' size='medium'>
                {actionTextData?.primaryButtonText ?? ''}
              </Text>
            </NakedButton>
          )}
        </div>
      </Modal.Header>
      <Modal.Body css={contentStyles}>{children}</Modal.Body>
    </Modal>
  );
};

const demoModalStyles = css({
  padding: 0,
  overflow: 'hidden',
  '.wv-modal-header': {
    padding: theme.spacing(1, 2),
  },
  '.wv-modal-flex-content': {
    padding: theme.spacing(0),
    margin: theme.spacing(0),
  },
});

const headerStyles = css({
  backgroundColor: theme.colors.secondary.seaweed5,
  borderBottom: `1px solid ${theme.colors.secondary.seaweed40}`,
});
