import { useMemo, useState } from 'react';
import { Appointment } from '@weave/schema-gen-ts/dist/schemas/schedule/calendar-events/v1/calendar_events.pb';
import { AppointmentType } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import {
  EventLocationType,
  EventStatus,
  EventType,
} from '@weave/schema-gen-ts/dist/schemas/schedule/v3/calendar_event.pb';
import dayjs from 'dayjs';
import { SchedulerV3Queries, SchedulerV3API } from '@frontend/api-scheduler-v3';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useCalendarEvent } from '../../../../context/CalendarEventProvider';
import { useCalendarEventV3Context } from '../../../../context/CalendarEventsV3Context';
import { useGetAppointmentTypesV3Data, useSchedulingLocationInfo } from '../../../../hooks';
import {
  getSystemTzid,
  transformDateSlotsToOpeningBlocks,
  transformV3ResponsesForReviewRequest,
} from '../../../../utils';
import { CalendarEventsEnums } from '../../types';
import { NewAppointmentForm } from './NewAppointmentForm';
import {
  AppointmentAvailabilityFormValues,
  OnCreateCalendarEvent,
  OnDeleteCalendarEvent,
  OnUpdateCalendarEvent,
} from './types';

type NewAppointmentFormContainerV3Props = {
  eventType: CalendarEventsEnums;
  closeModal: () => void;
  onAppointmentSuccess?: (appointment?: Appointment) => void;
};

export const NewAppointmentFormContainerV3 = ({
  closeModal,
  onAppointmentSuccess,
}: NewAppointmentFormContainerV3Props) => {
  const { providersList } = useCalendarEvent();
  const { selectedLocationIds, isMultiLocation, parentLocationId } = useSchedulingLocationInfo();

  const { refetchCalendarEvents } = useCalendarEventV3Context();

  const [selectedLocationId, setSelectedLocationId] = useState<string>('');

  const [selectedFormValuesForAvailabilityApi, setSelectedFormValuesForAvailabilityApi] =
    useState<AppointmentAvailabilityFormValues>({} as AppointmentAvailabilityFormValues);

  const { data: appointmentTypesV3, refetch: refetchAppointmentTypes } = useGetAppointmentTypesV3Data({
    isMultiLocation,
    parentLocationId,
    selectedLocationId: selectedLocationIds[0],
  });

  const appointmentTypesData = useMemo(
    () =>
      transformV3ResponsesForReviewRequest({
        appointmentTypesDataV3: appointmentTypesV3?.appointmentTypes,
      }).transformedAppointmentTypes as AppointmentType[],
    [appointmentTypesV3]
  );

  const { mutateAsync: createCalendarEvent } = SchedulerV3Queries.useCreateCalendarEvent();

  const { mutateAsync: updateCalendarEvent } = SchedulerV3Queries.useUpdateCalendarEvent();

  const { mutateAsync: deleteCalendarEvent } = SchedulerV3Queries.useDeleteCalendarEvent();

  const getUTCTime = (startDate: string, duration: number) => {
    const startDateObj = dayjs(startDate).utc();
    const endDate = dayjs(startDateObj).add(duration, 'minutes');

    const startTime = startDateObj.format('HH:mm:ss');
    const endTime = endDate.format('HH:mm:ss');

    return {
      startDateString: startDateObj.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      startTime,
      endTime,
    };
  };

  const availabilityApiParams = useMemo(() => {
    if (selectedFormValuesForAvailabilityApi.appointmentDate && selectedFormValuesForAvailabilityApi.duration) {
      const startDateTime = dayjs(selectedFormValuesForAvailabilityApi.appointmentDate)
        .startOf('day')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss');

      const endDateTime = dayjs(selectedFormValuesForAvailabilityApi.appointmentDate)
        .add(1, 'day')
        .startOf('day')
        .utc()
        .format('YYYY-MM-DDTHH:mm:ss');

      return {
        startDate: startDateTime,
        endDate: endDateTime,
      };
    }
    return {
      startDate: '',
      endDate: '',
    };
  }, [selectedFormValuesForAvailabilityApi.startDate, selectedFormValuesForAvailabilityApi.duration]);

  const { data: availabilitiesDetails, isLoading: isAvailableAppointmentTimesLoading } = useLocalizedQuery({
    queryKey: [
      'getCalendarAvailabilities',
      availabilityApiParams.endDate,
      availabilityApiParams.startDate,
      selectedFormValuesForAvailabilityApi.duration,
      selectedFormValuesForAvailabilityApi.selectedPractitionerCalendarId,
    ],
    queryFn: () =>
      SchedulerV3API.getListCalendarAvailabilities({
        calendarId: selectedFormValuesForAvailabilityApi.selectedPractitionerCalendarId || '',
        endDateTime: availabilityApiParams.endDate || '',
        startDateTime: availabilityApiParams.startDate || '',
        durationMinutes: selectedFormValuesForAvailabilityApi.duration,
      }),
    enabled:
      !!availabilityApiParams.startDate &&
      !!availabilityApiParams.endDate &&
      !!selectedFormValuesForAvailabilityApi.duration &&
      !!selectedFormValuesForAvailabilityApi.selectedPractitionerCalendarId,
  });

  const availableAppointmentTimes = useMemo(() => {
    if (!availabilitiesDetails) return [];
    if (!availabilitiesDetails.availableSlots) return [];

    return transformDateSlotsToOpeningBlocks(availabilitiesDetails.availableSlots, getSystemTzid());
  }, [availabilitiesDetails]);

  const onUpdateAppointment = async ({
    personId,
    startDate,
    practitionerId,
    duration,
    id,
    practitionerCalendarId,
    referenceId,
    practitionerName,
    appointmentTypeName,
    appointmentTypeId,
  }: OnUpdateCalendarEvent) => {
    const { startDateString, endDate, endTime, startTime } = getUTCTime(startDate, duration);

    const timezone = getSystemTzid();

    const updatedCalendarEvent = await updateCalendarEvent({
      attendeeId: personId,
      attendeeStatus: EventStatus.CONFIRMED,
      eventType: EventType.APPOINTMENT,
      locationType: EventLocationType.OFFICE,
      organizerId: practitionerId,
      organizerCalendarId: practitionerCalendarId || '',
      startDate: startDateString,
      startTime,
      endDate,
      endTime,
      startTimezone: timezone,
      endTimezone: timezone,
      eventId: id,
      referenceId: referenceId || '',
      referenceTypeId: appointmentTypeId || '',
      details: {
        dur: duration,
        orNa: practitionerName,
        rTyNa: appointmentTypeName,
      },
    });

    onAppointmentSuccess?.({
      clientLocationIds: updatedCalendarEvent?.event?.sourceTenantId
        ? [updatedCalendarEvent?.event?.sourceTenantId]
        : [],
      clientLocationId: updatedCalendarEvent?.event?.sourceTenantId || '',
      id: updatedCalendarEvent?.event?.id || '',
      locationId: updatedCalendarEvent?.event?.locationId || '',
    });
  };

  const onCreateAppointment = async ({
    personId,
    startDate,
    practitionerId,
    duration,
    practitionerCalendarId,
    locationId,
    sourceId,
    appointmentTypeId,
    practitionerName,
    appointmentTypeName,
  }: OnCreateCalendarEvent) => {
    const { startDateString, endDate, endTime, startTime } = getUTCTime(startDate, duration);

    const timezone = getSystemTzid();

    const appointmentResponse = await createCalendarEvent({
      attendeeId: personId,
      attendeeStatus: EventStatus.CONFIRMED,
      eventType: EventType.APPOINTMENT,
      locationType: EventLocationType.OFFICE,
      organizerId: practitionerId,
      organizerCalendarId: practitionerCalendarId || '',
      startDate: startDateString,
      startTime,
      endDate,
      endTime,
      startTimezone: timezone,
      endTimezone: timezone,
      locationId,
      referenceTypeId: appointmentTypeId || '',
      details: {
        dur: duration,
        orNa: practitionerName,
        rTyNa: appointmentTypeName,
      },
      sourceTenantId: sourceId || locationId, // Using sourceId or locationId as sourceTenantId because non integrated offices do not have sourceTenantId
    });

    onAppointmentSuccess?.({
      clientLocationIds: appointmentResponse?.event?.sourceTenantId ? [appointmentResponse?.event?.sourceTenantId] : [],
      clientLocationId: appointmentResponse?.event?.sourceTenantId || '',
      id: appointmentResponse?.event?.id || '',
      locationId: appointmentResponse?.event?.locationId || '',
    });
  };

  const onDeleteAppointment = async ({ id }: OnDeleteCalendarEvent) => {
    await deleteCalendarEvent({
      eventIds: [id],
    });
  };

  const refetchAppointments = () => {
    if (refetchCalendarEvents) {
      refetchCalendarEvents();
    }
  };

  return (
    <NewAppointmentForm
      availableAppointmentTimes={availableAppointmentTimes ?? []}
      closeModal={closeModal}
      isAvailableAppointmentTimesLoading={isAvailableAppointmentTimesLoading}
      isScheduleV3={false}
      onCreateAppointment={onCreateAppointment}
      onUpdateAppointment={onUpdateAppointment}
      onDeleteAppointment={onDeleteAppointment}
      refetchAppointments={refetchAppointments}
      setSelectedFormValuesForAvailabilityApi={setSelectedFormValuesForAvailabilityApi}
      providersList={providersList}
      setSelectedLocationId={setSelectedLocationId}
      selectedLocationId={selectedLocationId}
      selectedLocationIds={selectedLocationIds}
      appointmentTypes={appointmentTypesData ?? []}
      refetchAppointmentTypes={refetchAppointmentTypes}
    />
  );
};
