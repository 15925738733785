import { FC, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { isEqual } from 'lodash-es';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { CheckIcon, MultiselectField, useFormField } from '@frontend/design-system';

export type LocationStringRecord = Record<string, string>;

export type filterSelectorError = {
  name: string;
  value: boolean;
};

interface Props {
  disabled?: boolean;
  allLocationsOption: LocationStringRecord;
  selectedLocationsValue?: string[];
  onChange: (args: string[]) => void;
  onError?: (error: filterSelectorError) => void;
  trackingId?: string;
}

export const LocationsSelector: FC<React.PropsWithChildren<Props>> = ({
  disabled,
  allLocationsOption,
  onChange,
  onError,
  trackingId = 'location-selector',
  selectedLocationsValue,
}) => {
  const { t } = useTranslation('calls', { keyPrefix: 'calls-filter' });
  const [allLocationsMapSorted, setAllLocationsMapSorted] = useState<Record<string, string>[]>([]);

  const locationsFieldProps = useFormField(
    {
      required: true,
      touched: true,
      type: 'multiselect',
      value: selectedLocationsValue,
    },
    [selectedLocationsValue]
  );

  const toggleSelectAllLocations = () => {
    onChange(selectedLocationsValue?.length === allLocationsMapSorted.length ? [] : Object.keys(allLocationsOption));
  };

  useEffect(() => {
    // Minimum one location has to be always selected
    const hasLength = !!locationsFieldProps.value.length;
    onError?.({
      name: 'location',
      value: !hasLength,
    });
    const sortedSelectedLocationsValue = selectedLocationsValue?.slice().sort();
    if (!hasLength || isEqual(locationsFieldProps.value.sort(), sortedSelectedLocationsValue)) {
      return;
    }
    onChange(locationsFieldProps.value);
  }, [locationsFieldProps.value]);

  useEffect(() => {
    const sortedLocations: any[] = [
      ...Object.entries(allLocationsOption).sort((a, b) => {
        if (a[1] < b[1]) {
          return -1;
        }
        if (a[1] > b[1]) {
          return 1;
        }
        return 0;
      }),
    ];

    setAllLocationsMapSorted(sortedLocations);
  }, [allLocationsOption]);

  return (
    <div css={styles.wrapperStyles}>
      <MultiselectField
        {...locationsFieldProps}
        className='multi-select'
        disabled={disabled}
        label={t('Select Locations')}
        name='locations'
        data-trackindid={trackingId}
      >
        {allLocationsMapSorted.length > 1 ? (
          <div css={styles.selectAllLocationsStyles} onClick={toggleSelectAllLocations} role='button'>
            {t('Select All')} {selectedLocationsValue?.length === allLocationsMapSorted.length && <CheckIcon />}
          </div>
        ) : null}
        {allLocationsMapSorted.map((location, index) => (
          <MultiselectField.Option
            key={location[0]}
            trackingId={`${trackingId}-option-${index + 1}`}
            value={location[0]}
          >
            {location[1]}
          </MultiselectField.Option>
        ))}
      </MultiselectField>
    </div>
  );
};

const styles = {
  wrapperStyles: css`
    position: relative;

    .multi-select {
      > div:first-of-type {
        > p:last-of-type {
          font-size: ${theme.fontSize(12)};
        }
      }
    }
  `,

  selectAllLocationsStyles: css`
    align-items: center;
    color: ${theme.colors.primary};
    display: flex;
    font-size: ${theme.fontSize(16)};
    height: ${theme.spacing(5)};
    justify-content: space-between;
    padding: ${theme.spacing(0, 2)};
    width: 100%;

    svg {
      fill: ${theme.colors.text.interactive};
    }

    &:hover {
      background-color: ${theme.colors.neutral5};
    }
  `,
};
