import { useCallback } from 'react';
import { FormsMediaManager, ProviderReview } from '@frontend/api-forms';
import { useTranslation } from '@frontend/i18n';
import { genUID, useAlert } from '@frontend/design-system';
import { useWritebackWidgetStore, useWritebackWidgetContext } from '../../../providers';
import { getProviderUser } from '../provider-review.utils';

interface UseProviderSignatureResults {
  storeSignature: (
    signature: ProviderReview.Types.RawProviderSignature,
    isDefault: boolean
  ) => Promise<{
    success: boolean;
  } | null>;
}

export const useProviderSignature = (): UseProviderSignatureResults => {
  const { t } = useTranslation('forms');
  const alert = useAlert();
  const { locationId } = useWritebackWidgetContext();
  const { setSignature } = useWritebackWidgetStore(['setSignature']);

  const storeSignature = useCallback(
    async (
      signature: ProviderReview.Types.RawProviderSignature,
      isDefault: boolean
    ): Promise<{
      success: boolean;
    } | null> => {
      const user = getProviderUser();
      const signatureField = await getSignatureField(signature, locationId);
      setSignature(signatureField);

      if (isDefault) {
        try {
          await ProviderReview.API.saveDefaultSignature({
            ...signatureField,
            provider_email: user.email as string,
          });

          alert.success(t('Successfully saved signature as default'));
          return { success: true };
        } catch (error) {
          alert.error(t('Failed to save default signature'));
          return { success: false };
        }
      }

      return null;
    },
    [locationId]
  );

  return {
    storeSignature,
  };
};

async function getSignatureField(
  practitionerSignature: ProviderReview.Types.RawProviderSignature,
  locationId: string
): Promise<ProviderReview.Types.ProviderSignatureResponse> {
  let signatureImageId;

  if (practitionerSignature && practitionerSignature.type === 'image') {
    const uploadedImage = await FormsMediaManager.API.uploadMedia(practitionerSignature.data as File, locationId);

    if (uploadedImage.success) {
      signatureImageId = uploadedImage.fileId;
    } else {
      throw new Error();
    }
  }

  const fieldId = genUID();
  const signature: ProviderReview.Types.ProviderSignature = {
    data: signatureImageId || (practitionerSignature.data as string),
    timestamp: practitionerSignature.timestamp,
    type: practitionerSignature.type,
    font_type: practitionerSignature.font_type,
  };

  return {
    provider_esign: {
      [fieldId]: {
        id: fieldId,
        label: 'E-Signature (Provider)',
        meta: {
          displayName: 'Provider E-Signature',
          type: 'eSign',
        },
        required: true,
        value: JSON.stringify(signature),
      },
    },
  };
}
