import { createProvider } from '@frontend/store';
import { FormsLibraryV2Store } from './types';

export const { Provider: FormsLibraryV2StoreProvider, useStore: useFormsLibraryV2Store } =
  createProvider<FormsLibraryV2Store>()((set) => ({
    noFormsFound: false,
    setNoFormsFound: (value: boolean) => set({ noFormsFound: value }),

    isLoadingForms: false,
    setIsLoadingForms: (value: boolean) => set({ isLoadingForms: value }),

    noPacketsFound: false,
    setNoPacketsFound: (value: boolean) => set({ noPacketsFound: value }),

    isLoadingPackets: false,
    setIsLoadingPackets: (value: boolean) => set({ isLoadingPackets: value }),
  }));
