import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { ManualSmsScheduledV1 } from '@frontend/api-manual-scheduled-sms';
import { queryKeys } from '../inbox-queries';
import { useInvalidateUnreadCount } from './use-invalidate-unread-count';

export const useInvalidateInboxList = () => {
  const queryClient = useQueryClient();
  const invalidateUnreadCount = useInvalidateUnreadCount();
  const { invalidateQueries } = ManualSmsScheduledV1._QueryUpdaters.useQueryUpdaters();
  return useCallback(() => {
    const keys = queryKeys.inboxListBase;
    queryClient.invalidateQueries({ queryKey: keys, exact: false });
    invalidateUnreadCount();
    invalidateQueries({ endpointName: 'ListThreadsCount' });
  }, [invalidateUnreadCount]);
};
