import {
  DeviceInformation,
  SelectOutboundIdMenu,
  SelectOutboundIdMenuProps,
} from '@frontend/generic-dialpad-accessories';
import { useTranslation } from '@frontend/i18n';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { useSoftphoneClient } from '../providers/softphone-client-provider';
import { useSoftphoneSettings } from '../providers/softphone-settings-provider';
import { useSoftphoneWidgetControl } from '../providers/widget-control-provider';
import { formatPhoneNumber } from '../utils/formatting-utils';

interface WrapperProps {
  hideExtension?: boolean;
  hideOutboundCallerInfo?: boolean;
}

export const DeviceInformationWrapper = ({ hideExtension, hideOutboundCallerInfo }: WrapperProps) => {
  const { t } = useTranslation('softphone');
  const deviceName = useSoftphoneClient((ctx) => ctx.deviceName);
  const extensionNumber = useSoftphoneClient((ctx) => ctx.extensionNumber);
  const currentPhoneNumber = useSoftphoneSettings((ctx) => ctx.selectedOutboundPhoneNumber);
  const availablePhoneNumbers = useSoftphoneSettings((ctx) => ctx.availablePhoneNumbers);
  const { navigate } = useSettingsNavigate();
  const close = useSoftphoneWidgetControl((ctx) => ctx.close);

  const deviceInfoProps = {
    deviceName: t('{{deviceName}}', { deviceName }),
    onChangeDeviceClick: () => {
      navigate({ to: '/personal/phone/device-connection' });
      close();
    },
    changeDeviceText: t('Change Device'),
    showExtension: !hideExtension,
    extensionNumberText: t('Extension {{extensionNumber}}', { extensionNumber }),
    currentPhoneNumberText: formatPhoneNumber(currentPhoneNumber?.number ?? ''),
    isPreferredNumber: currentPhoneNumber?.isPreferred ?? false,
    availablePhoneNumbers,
    SelectOutboundIdMenuWrapper,
    showOutboundCallerInfo: !hideOutboundCallerInfo,
    trackingId: 'softphone-modal-changedevice',
  };

  return <DeviceInformation {...deviceInfoProps} />;
};

const SelectOutboundIdMenuWrapper = (popoverMenuProps: SelectOutboundIdMenuProps) => {
  const availablePhoneNumbers = useSoftphoneSettings((ctx) => ctx.availablePhoneNumbers);
  const setCurrentPhoneNumber = useSoftphoneSettings((ctx) => ctx.setCurrentPhoneNumber);
  const currentPhoneNumber = useSoftphoneSettings((ctx) => ctx.selectedOutboundPhoneNumber);
  return (
    <SelectOutboundIdMenu
      availablePhoneNumbers={availablePhoneNumbers}
      setCurrentPhoneNumber={setCurrentPhoneNumber}
      currentPhoneNumber={currentPhoneNumber}
      {...popoverMenuProps}
    />
  );
};
