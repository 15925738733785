import { css } from '@emotion/react';
import { PersonHelpers, PersonTypes } from '@frontend/api-person';
import { useDialpadDialer, useDialpadWidgetControl } from '@frontend/dialpad';
import { useTranslation } from '@frontend/i18n';
import { removeNonDigits } from '@frontend/phone-numbers';
import { useSoftphoneDialer, useSoftphoneWidgetControl } from '@frontend/softphone2';
import { useSoftphoneInnerManager } from '@frontend/weave-softphone-manager';
import { theme } from '@frontend/theme';
import { DropdownField, Modal, ModalControlModalProps, Text, useFormField } from '@frontend/design-system';

interface ContactNumberSelectionModalProps {
  person?: PersonTypes.Person;
  modalProps: ModalControlModalProps;
}

export const getContactLabelValue = (contact: { number: string; label: string }) => {
  return `${contact?.number ?? ''} ${contact?.label ? `(${contact.label})` : ''}`;
};

export const ContactNumberSelection = ({ person, modalProps }: ContactNumberSelectionModalProps) => {
  const { t } = useTranslation('phone-call');
  const personName = PersonHelpers.getFullName(person);

  const softphoneIsConnected = useSoftphoneInnerManager((ctx) => ctx.isConnected);

  const setSoftphoneDialer = useSoftphoneDialer((ctx) => ctx.setCurrentDial);
  const setDialpadDialer = useDialpadDialer((ctx) => ctx.setCurrentDial);
  const openDialpad = useDialpadWidgetControl((ctx) => ctx.open);
  const openSoftphone = useSoftphoneWidgetControl((ctx) => ctx.open);

  const multipleContacts = PersonHelpers.getAllPhoneNumbers(person) ?? [];
  const defaultContact = multipleContacts?.find((phone) => !!phone.number);

  const fieldProps = useFormField(
    {
      type: 'dropdown',
      value: defaultContact ? getContactLabelValue(defaultContact) : '',
    },
    []
  );

  return (
    <Modal {...modalProps} maxWidth={400}>
      <Modal.Header onClose={modalProps.onClose}>{t('Select Contact Number')}</Modal.Header>
      <Modal.Body>
        <section css={styles.attribute}>
          <Text as='span'>{t('Select the contact number you want to call.')}</Text>
        </section>

        <section css={styles.attribute}>
          <Text color='light' size='small' css={styles.heading}>
            {t('Contact')}
          </Text>
          <Text size='medium' css={styles.descStyles}>
            {personName}
          </Text>
        </section>

        <section css={styles.attribute}>
          <DropdownField {...fieldProps} css={styles.dropdownField} label={t('Contact Number')} name='contactNumber'>
            {multipleContacts?.map((phone) => {
              return (
                <DropdownField.OptionGroup key={phone?.number} label={phone?.label ?? ''}>
                  <DropdownField.Option
                    key={phone?.number}
                    value={getContactLabelValue(phone)}
                    displayValue={getContactLabelValue(phone)}
                  >
                    {phone?.number ?? ''}
                  </DropdownField.Option>
                </DropdownField.OptionGroup>
              );
            })}
          </DropdownField>
        </section>
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('Dial')}
        onPrimaryClick={() => {
          const dialNumber = removeNonDigits(fieldProps.value);
          if (softphoneIsConnected) {
            setSoftphoneDialer(dialNumber);
            openSoftphone?.();
          } else {
            setDialpadDialer(dialNumber);
            openDialpad?.();
          }
          modalProps.onClose();
        }}
        secondaryLabel={t('Cancel')}
        onSecondaryClick={modalProps.onClose}
      />
    </Modal>
  );
};

const styles = {
  heading: css`
    text-transform: capitalize;
    color: ${theme.colors.neutral50};
    font-size: ${theme.fontSize(12)};
    margin-bottom: ${theme.spacing(0.5)};
    line-height: ${theme.spacing(2)};
  `,

  attribute: css`
    margin-top: ${theme.spacing(2)};
    max-width: 400px;

    @media (max-width: 400px) {
      max-width: auto;
    }
  `,

  descStyles: css`
    line-height: ${theme.spacing(2)};
  `,

  dropdownField: css`
    margin-top: ${theme.spacing(3)};
  `,
};
