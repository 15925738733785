import dayjs from 'dayjs';
import { QuoteTypes } from '@frontend/api-quote';
import { i18next } from '@frontend/i18n';
import { CurrencyType } from '@frontend/shared';
import { createShallowStore, createStoreWithSubscribe } from '@frontend/store';
import {
  DisclaimerName,
  DigitalFormsDisclaimerProducts,
  IQuoteOneTimeChargesCardInfo,
  IQuoteOneTimeChargesItem,
  IQuoteRecurringChargesCardInfo,
  IQuoteRecurringChargesItem,
  LineItemType,
  QuoteEnum,
  DiscountTypeEnum,
} from './types';

interface QuoteInfoStore {
  quoteInfo?: QuoteTypes.QuoteSummary;
  selectedShippingAddress?: QuoteTypes.ShippingAddress;
  eSignature: string;
  notes: string;
  ccData: QuoteTypes.CCData;
  shouldShowDigitalFormsDisclaimer: boolean;
  dueTodayAmount: number;
  bundleDiscount: number;
  isQuotePresigned: boolean;
  disclaimers: Record<DisclaimerName, boolean | undefined>;
  isAmendmentQuote: boolean;
  isNewQuoteAnnual: boolean;
  isOldQuoteAnnual: boolean;
  isDisplayNewQuoteUI: boolean;
  currencyCode: CurrencyType;
  oneTimeChargesCardInfo: IQuoteOneTimeChargesCardInfo;
  recurringChargesCardInfo: IQuoteRecurringChargesCardInfo;
  expirationDate: string;
  hasCallIntelService: boolean;

  setDisclaimer: (name: DisclaimerName, value: boolean) => void;
  setCCData: (value: QuoteTypes.CCData) => void;
  setSelectedShippingAddress: (value: QuoteTypes.ShippingAddress) => void;
  setESignature: (value: string) => void;
  setQuoteInfo: (quoteData: QuoteTypes.QuoteSummary) => void;
}

const { PRODUCT_FAMILY_PHONES, PRODUCT_FAMILY_ADD_ON, PRODUCT_FAMILY_CORE_PRODUCTS, PRODUCT_FAMILY_SOFTWARE } =
  QuoteTypes.ProductFamily;

// sort line items to move phone items to bottom
export const sortPhoneItemsToBottom = <T extends QuoteTypes.QuotePhone | QuoteTypes.QuoteService>(
  lineItems?: T[]
): T[] =>
  lineItems?.sort((a, b) => {
    if (a.productFamily === PRODUCT_FAMILY_PHONES && b.productFamily !== PRODUCT_FAMILY_PHONES) return 1;
    if (a.productFamily !== PRODUCT_FAMILY_PHONES && b.productFamily === PRODUCT_FAMILY_PHONES) return -1;
    return 0;
  }) ?? [];

const { UPGRADE, DOWNGRADE, EXPIRED, TERMINATED } = QuoteTypes.AmendmentQuoteLineStatus;

const isHiddenItemForAmendmentQuote = (status?: QuoteTypes.AmendmentQuoteLineStatus) =>
  status === EXPIRED || status === TERMINATED;

export const useQuoteInfoStore = createStoreWithSubscribe<QuoteInfoStore>(
  (set, get) => ({
    ccData: {},
    notes: '',
    eSignature: '',
    shouldShowDigitalFormsDisclaimer: false,
    dueTodayAmount: 0,
    bundleDiscount: 0,
    isQuotePresigned: false,
    disclaimers: {
      'charge-agreement': false,
      'call-intel-agreement': false,
      'digital-forms-agreement': false,
      'subscription-agreement': false,
      'terms-agreement': false,
      'satellite-agreement': undefined,
    },
    isAmendmentQuote: false,
    isNewQuoteAnnual: false,
    isOldQuoteAnnual: false,
    isDisplayNewQuoteUI: false,
    currencyCode: 'USD',
    oneTimeChargesCardInfo: {
      items: [],
      totalPrice: 0,
      totalNetPrice: 0,
      totalDiscount: 0,
    },
    recurringChargesCardInfo: {
      items: [],
      totalPrice: 0,
      totalNetPrice: 0,
      totalDiscount: 0,
      discountItemsTotal: 0,
      temporaryDiscountTotal: 0,
    },
    expirationDate: '',
    hasCallIntelService: false,

    setDisclaimer: (name, value) => {
      const disclaimers = get().disclaimers;
      set({ disclaimers: { ...disclaimers, [name]: value } });
    },
    setCCData: (value: QuoteTypes.CCData) => set({ ccData: value }),
    setSelectedShippingAddress: (value) => set({ selectedShippingAddress: value }),
    setESignature: (value) => set({ eSignature: value }),
    setQuoteInfo: (quoteData) => {
      const isQuotePresigned = !!quoteData?.meta?.signatureNameProvided;
      const isAmendmentQuote = quoteData?.meta?.quoteType === QuoteEnum.AMENDMENT;
      const isNewQuoteAnnual = quoteData?.meta?.billingFrequency === QuoteTypes.BillingFrequency.ANNUAL;
      const isOldQuoteAnnual = quoteData?.oldQuoteRecord?.billingFrequency === QuoteTypes.BillingFrequency.ANNUAL;
      const isMonthlyQuote = quoteData?.meta?.billingFrequency === QuoteTypes.BillingFrequency.MONTHLY;
      const bundleStructure = quoteData?.meta?.bundleStructure;
      const isDisplayNewQuoteUI =
        bundleStructure === QuoteTypes.BundleStructure.NEW_BUNDLE ||
        bundleStructure === QuoteTypes.BundleStructure.OLD_BUNDLE_WITH_NEW_DISCOUNT_STRUCTURE;

      const hasDigitalFormService = false;

      const isSatelliteLocation = quoteData.account?.accountRecordType === 'Satellite';

      const hasFormDigitizationFee = (quoteData?.phones || []).some(
        (serviceDetails) =>
          serviceDetails.name === DigitalFormsDisclaimerProducts.FORMS_DIGITIZATION_FEE ||
          serviceDetails.name === DigitalFormsDisclaimerProducts.DIGITAL_FORMS_IMPLEMENTATION_FEE
      );

      const hasCallIntelService = quoteData?.services?.some((serviceItem) => {
        return (
          !!serviceItem?.quantity &&
          !!serviceItem.name &&
          ['WeaveCore - Ultimate', 'WeavePlus - Ultimate', 'Call Intelligence'].includes(serviceItem.name)
        );
      });

      const shouldShowDigitalFormsDisclaimer = hasDigitalFormService || hasFormDigitizationFee;

      const upfrontPaymentPhone = (quoteData?.phones || []).find((phone) => phone.name === 'Upfront Payment');
      const dueTodayAmount = upfrontPaymentPhone
        ? (upfrontPaymentPhone.productNetPrice || 0) * (upfrontPaymentPhone.quantity || 0)
        : 0;
      const currencyCode = (quoteData?.services?.[0]?.currencyCode as CurrencyType) || 'USD';

      let totalPrice = 0,
        totalNetPrice = 0,
        totalDiscount = 0;

      const phoneItems = sortPhoneItemsToBottom(quoteData?.phones);
      const oneTimeCharges: IQuoteOneTimeChargesItem[] = phoneItems.reduce<IQuoteOneTimeChargesItem[]>((acc, item) => {
        if (isAmendmentQuote && isHiddenItemForAmendmentQuote(item.status)) {
          return acc;
        }
        totalPrice += item.totalPrice || 0;
        totalDiscount += item.totalDiscount || 0;
        totalNetPrice += item.totalNetPrice || 0;
        acc.push({
          type: LineItemType.OneTimeCharge,
          name: item.name || '',
          quantity: item.quantity || 0,
          price: item.productListPrice || 0,
          discount: item.unitDiscount || 0,
          netPrice: item.unitNetPrice || 0,
          subTotalPrice: item.totalPrice || 0,
          subTotalDiscount: item.totalDiscount || 0,
          subTotalNetPrice: item.totalNetPrice || 0,
          included: !!item.included,
        });
        return acc;
      }, []);
      const oneTimeChargesCardInfo: IQuoteOneTimeChargesCardInfo = {
        items: oneTimeCharges,
        totalPrice,
        totalDiscount,
        totalNetPrice,
      };

      totalPrice = 0;
      totalNetPrice = 0;
      totalDiscount = 0;
      let bundleDiscount = 0;
      const freeMonthNotes: string[] = [];
      let bundleFreeMonths = 0;

      const serviceItems = sortPhoneItemsToBottom(quoteData?.services);
      const recurringCharges: IQuoteRecurringChargesItem[] = serviceItems.reduce((acc, item) => {
        if (isAmendmentQuote && (isHiddenItemForAmendmentQuote(item.status) || item.existingLine)) {
          return acc;
        }
        const isUpgradedLineItem = item.status === UPGRADE;
        const isDowngradedLineItem = item.status === DOWNGRADE;
        const isOldActiveItem = item.totalPrice === 0 && item.totalDiscount === 0;
        // For amendment quote, we need to use effectiveRr for old items,
        // which are present previously & currently also active (and for those we get it's totalPrice, totalDiscount & totalNetPrice as zero)
        const isUseEffectiveRr = isAmendmentQuote && (isOldActiveItem || isUpgradedLineItem || isDowngradedLineItem);
        const isUseEffectiveRrForSubTotalFields = isUseEffectiveRr || (isAmendmentQuote && isNewQuoteAnnual);

        const price = (isUseEffectiveRr ? item.unitNetPrice : item.productListPrice) || 0;
        const discount = (isUseEffectiveRr ? 0 : item.unitDiscount) || 0;

        const subTotalPrice = (isUseEffectiveRrForSubTotalFields ? item.effectiveRr : item.totalPrice) || 0;
        const subTotalDiscount = (isUseEffectiveRrForSubTotalFields ? 0 : item.totalDiscount) || 0;
        const subTotalNetPrice = (isUseEffectiveRrForSubTotalFields ? item.effectiveRr : item.totalNetPrice) || 0;

        totalPrice += subTotalPrice || 0;
        totalDiscount += subTotalDiscount || 0;
        totalNetPrice += subTotalNetPrice || 0;

        acc.push({
          type: LineItemType.RecurringCharge,
          name: item.name || '',
          quantity: item.quantity || 0,
          subscriptionTerm: item.subscriptionPeriodInMonths || 0,
          price: price,
          discount: discount,
          netPrice: item.unitNetPrice || 0,
          subTotalPrice,
          subTotalDiscount,
          subTotalNetPrice,
          included: !!item.included,
          hasFreeMonths: !!item?.effectiveFreeMonths,
        });

        if (!!item?.freeMonthsAmount && !!item?.effectiveFreeMonths) {
          const freeMonthsAmount = isNewQuoteAnnual
            ? Math.abs(item.freeMonthsAmount)
            : Math.abs(item.freeMonthsAmount / item.effectiveFreeMonths);

          // prepare free months notes string
          switch (item?.productFamily) {
            case PRODUCT_FAMILY_CORE_PRODUCTS:
              bundleFreeMonths = item.effectiveFreeMonths;
              freeMonthNotes.push(
                i18next.t('{{itemName}} - {{count}} Free Month', {
                  ns: 'salesDev',
                  itemName: 'Bundle Discount',
                  count: item.effectiveFreeMonths,
                })
              );
              break;
            case PRODUCT_FAMILY_ADD_ON:
            case PRODUCT_FAMILY_SOFTWARE:
              if (item.effectiveFreeMonths !== bundleFreeMonths)
                freeMonthNotes.push(
                  i18next.t('{{itemName}} - {{count}} Free Month', {
                    ns: 'salesDev',
                    itemName: item.name,
                    count: item.effectiveFreeMonths,
                  })
                );
              break;
            default:
              break;
          }

          acc.push({
            type: LineItemType.FreeMonthDiscount,
            name: i18next.t('Discount - Free Month', { ns: 'salesDev' }),
            quantity: 0,
            subscriptionTerm: item.effectiveFreeMonths || 0,
            price: 0,
            discount: 0,
            netPrice: 0,
            subTotalPrice: -freeMonthsAmount,
            subTotalDiscount: 0,
            subTotalNetPrice: 0,
            included: false,
          });
          bundleDiscount += freeMonthsAmount;
          totalDiscount += freeMonthsAmount;
          totalNetPrice -= freeMonthsAmount;
        }
        return acc;
      }, [] as IQuoteRecurringChargesItem[]);
      let discountItemsTotal = 0;

      // temporary discount are used to manage all the discount of type limited
      let temporaryDiscountTotal = 0;
      quoteData?.discounts?.forEach((item) => {
        if (!isAmendmentQuote || !isHiddenItemForAmendmentQuote(item.status)) {
          if (isAmendmentQuote && item.existingLine) {
            return;
          }

          const isOldActiveItem = item.totalPrice === 0 && item.totalDiscount === 0;
          const isUseEffectiveRr = isAmendmentQuote && (isOldActiveItem || isNewQuoteAnnual);
          const isUseUnitNetPrice = isMonthlyQuote && item.type === DiscountTypeEnum.LIMITED;

          const getSubTotalPrice = () => {
            if (isUseEffectiveRr) return item.effectiveRr;
            else if (isUseUnitNetPrice) return item.unitNetPrice;
            return item.totalPrice;
          };

          const getSubTotalDiscount = () => {
            if (isUseEffectiveRr) return 0;
            return item.totalDiscount;
          };

          const getSubTotalNetPrice = () => {
            if (isUseEffectiveRr) return item.effectiveRr;
            else if (isUseUnitNetPrice) return item.unitNetPrice;
            return item.totalNetPrice;
          };

          const price = isUseEffectiveRr ? item.unitNetPrice : item.productListPrice;
          const subTotalPrice = getSubTotalPrice() || 0;
          const subTotalDiscount = getSubTotalDiscount() || 0;
          const subTotalNetPrice = getSubTotalNetPrice() || 0;
          const itemDiscount = subTotalPrice;
          discountItemsTotal += Math.abs(itemDiscount);
          if (item.type === DiscountTypeEnum.LIMITED) {
            temporaryDiscountTotal += Math.abs(itemDiscount);
          }

          recurringCharges.push({
            type: LineItemType.Discount,
            name: item.name || '',
            // TODO: Milind remove any after confirming quote response
            quantity: (item as any).quantity ?? 1,
            subscriptionTerm: item.numberOfSubscriptionPeriods ? item.numberOfSubscriptionPeriods : 1,
            price: price || 0,
            discount: item.unitDiscount || 0,
            netPrice: item.unitNetPrice || 0,
            subTotalPrice,
            subTotalDiscount,
            subTotalNetPrice,
            included: false,
          });
        }
      });

      const recurringChargesCardInfo: IQuoteRecurringChargesCardInfo = {
        items: recurringCharges,
        totalPrice,
        totalNetPrice: totalNetPrice - discountItemsTotal,
        totalDiscount: totalDiscount + discountItemsTotal,
        discountItemsTotal,
        temporaryDiscountTotal,
      };

      set({
        notes: `${freeMonthNotes.join('\n')}${quoteData?.meta?.notes ? `\n${quoteData?.meta?.notes}` : ''}`,
        shouldShowDigitalFormsDisclaimer,
        bundleDiscount,
        dueTodayAmount,
        isQuotePresigned,
        currencyCode,
        isAmendmentQuote,
        isNewQuoteAnnual,
        isOldQuoteAnnual,
        isDisplayNewQuoteUI,
        oneTimeChargesCardInfo,
        recurringChargesCardInfo,
        expirationDate: dayjs(quoteData?.meta?.expirationDate).format('MM/DD/YY'),
        quoteInfo: quoteData,
        eSignature: quoteData?.meta?.signatureNameProvided || '',
        selectedShippingAddress: quoteData?.meta?.shippingAddress,
        disclaimers: {
          'call-intel-agreement': !hasCallIntelService || isQuotePresigned,
          'charge-agreement': isQuotePresigned,
          'terms-agreement': isQuotePresigned,
          'digital-forms-agreement': !shouldShowDigitalFormsDisclaimer || isQuotePresigned,
          'satellite-agreement': !isSatelliteLocation || isQuotePresigned,
        },
        hasCallIntelService,
      });
    },
  }),
  {
    name: 'QuoteInfoStore',
    trace: true,
  }
);

export const useQuoteInfoShallowStore = createShallowStore<QuoteInfoStore>(useQuoteInfoStore);
