import { useMemo } from 'react';
import { css } from '@emotion/react';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { GlobalSearch, useGlobalSearch, GlobalSearchTrackingIds } from '@frontend/global-search';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore } from '@frontend/scope';
import {
  StaticSettingsRoutes,
  settingsRouteLookup,
  useSettingsNavigate,
  useSettingsSearchAccessRoutePaths,
} from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { NakedButton } from '@frontend/design-system';
import SettingsSearchItem from '../../layout/settings-nav/settings-search';

const defaultMaxResults = 4;

export const SettingsGlobalSearchModule = () => {
  const { t } = useTranslation('global-search');
  const { navigate } = useSettingsNavigate();
  const { singleLocationId } = useAppScopeStore();
  const hasPhonefullAccess = hasSchemaACL(singleLocationId, Permission.PHONE_FULL_ACCESS);

  const { debouncedSearchTerm, viewAllFeature, close, addSearch } = useGlobalSearch([
    'debouncedSearchTerm',
    'viewAllFeature',
    'close',
    'addSearch',
  ]);

  const searchedRoutePathsPrefiltered = useMemo(() => {
    // Lookup paths based on the search term
    const paths = settingsRouteLookup.searchKeyword(debouncedSearchTerm).map((item) => item.item);

    // Filter paths based on access
    return paths.filter((path) => {
      if (path.path === '/settings/phone/call-recording/') {
        return hasPhonefullAccess;
      }
      return true; // Include all other paths
    });
  }, [debouncedSearchTerm, hasPhonefullAccess]);

  const searchedRoutePaths = useSettingsSearchAccessRoutePaths(searchedRoutePathsPrefiltered);

  const maxResults =
    viewAllFeature === 'settings' ? searchedRoutePaths : searchedRoutePaths.slice(0, defaultMaxResults);

  return (
    <GlobalSearch.Section.Content
      hasResults={!!searchedRoutePaths.length}
      showViewAll={searchedRoutePaths.length > defaultMaxResults}
      count={`${searchedRoutePaths.length}`}
    >
      <div
        css={css`
          display: flex;
          flex-wrap: wrap;
          gap: ${theme.spacing(1)};
        `}
      >
        {maxResults.length ? (
          maxResults.map((settingsItem) => {
            const isPortal = settingsItem.path.startsWith('/portal');
            const extraProps = isPortal
              ? { as: 'a', href: settingsItem.path.replace(/\/+$/, ''), target: '_blank' }
              : {};

            const Component = isPortal ? 'a' : NakedButton;

            return (
              <Component
                key={settingsItem.path}
                data-trackingid={GlobalSearchTrackingIds.searchResultItem('settings')}
                onClick={() => {
                  addSearch(debouncedSearchTerm, 'settings');
                  if (!settingsItem.path.startsWith('/portal')) {
                    navigate({ to: settingsItem.path.slice(0, -1) as StaticSettingsRoutes });
                  }
                  close();
                }}
                css={css`
                  display: block;
                  border: 1px solid ${theme.colors.neutral10};
                  border-radius: ${theme.borderRadius.small};
                  padding: ${theme.spacing(2)};
                  min-width: 234px;
                  flex: 1;
                  text-decoration: none;
                  transition: background-color 0.3s ease;
                  background-color: ${theme.colors.white};
                  color: initial;
                  :hover {
                    background-color: ${theme.colors.neutral5};
                  }
                  span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    flex: 1;
                    display: inline;
                    text-align: start;
                  }
                `}
                {...extraProps}
              >
                <SettingsSearchItem item={settingsItem} />
              </Component>
            );
          })
        ) : (
          <GlobalSearch.EmptyState customMessage={t('No settings path matches this search.')} />
        )}
      </div>
    </GlobalSearch.Section.Content>
  );
};
