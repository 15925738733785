import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useGetLocationSettingsDetails, useSetLocationSettingsDetails } from '@frontend/insurance-verification';
import { useAppScopeStore } from '@frontend/scope';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import {
  useFormField,
  Text,
  SwitchField,
  DropdownField,
  ButtonBar,
  Button,
  SpinningLoader,
  useAlert,
} from '@frontend/design-system';

type ManageInsuranceSettingsProps = {
  locationId: string;
};

const numberOfDaysOption = Array.from({ length: 14 }, (_, i) => i + 1);

export const ManageInsuranceSettings = ({ locationId }: ManageInsuranceSettingsProps) => {
  const alert = useAlert();
  const { t } = useTranslation('insuranceGlobalSettings');
  const { selectedParentsIds } = useAppScopeStore();
  const { navigate: settingsNavigate } = useSettingsNavigate();

  const {
    data: locationSettingsData,
    refetch: refetchLocationSettingsData,
    isLoading: isLoadingLocationSettings,
  } = useGetLocationSettingsDetails(locationId);

  const { mutateAsync: setLocationSettingsDetails, isLoading: isSavingLocationSettings } =
    useSetLocationSettingsDetails(locationId);

  const isAutoVerificationSupported = locationSettingsData?.supportAutoVerification;

  const autoVerificationFieldProps = useFormField(
    {
      type: 'switch',
      value: isAutoVerificationSupported ? locationSettingsData?.isAutoVerification : false,
    },
    [locationSettingsData?.isAutoVerification, isAutoVerificationSupported]
  );

  const isAutoVerificationEnabled = autoVerificationFieldProps.value;

  const shouldDisableDaysSelection = isAutoVerificationSupported && !isAutoVerificationEnabled;

  const numberOfDaysFieldProps = useFormField(
    {
      type: 'dropdown',
      value: locationSettingsData?.autoVerificationWindowDays
        ? locationSettingsData?.autoVerificationWindowDays?.toString()
        : '1',
    },
    [locationSettingsData?.autoVerificationWindowDays]
  );

  const onSave = async () => {
    try {
      await setLocationSettingsDetails({
        isAutoVerification: autoVerificationFieldProps.value,
        autoVerificationWindowDays: parseInt(numberOfDaysFieldProps.value),
        locations: [locationId],
      });
      alert.success(t('Settings saved successfully'));
      refetchLocationSettingsData();
    } catch (err) {
      console.error(err);
      alert.error(t('Failed to save settings'));
    }
  };

  const onCancel = () => {
    settingsNavigate({
      to: selectedParentsIds.length ? '/insurance/automatic-verification' : '/insurance',
    });
  };

  return (
    <>
      {!isAutoVerificationSupported && !isLoadingLocationSettings && <NoPMSSupportAlert />}

      <section css={cardContainerStyles}>
        <div css={containerStyles}>
          <Text weight='bold' size='large' color={!isAutoVerificationSupported ? 'disabled' : 'default'}>
            {t('Enable Automatic Verification')}
          </Text>
          {isLoadingLocationSettings ? (
            <SpinningLoader size='xs' />
          ) : (
            <SwitchField
              disabled={!isAutoVerificationSupported}
              {...autoVerificationFieldProps}
              label=''
              name={t('Enable Automatic Verification')}
            />
          )}
        </div>
        <div css={containerStyles}>
          <div>
            <Text
              color={!isAutoVerificationSupported || shouldDisableDaysSelection ? 'disabled' : 'default'}
              weight='bold'
              size='large'
            >
              {t('Verification Schedule')}
            </Text>
            <Text
              size='small'
              color={!isAutoVerificationSupported || shouldDisableDaysSelection ? 'disabled' : 'subdued'}
            >
              {t('Select how many days before each appointment you want to run verification')}
            </Text>
          </div>
          {isLoadingLocationSettings ? (
            <SpinningLoader size='small' />
          ) : (
            <DropdownField
              disabled={!isAutoVerificationSupported || shouldDisableDaysSelection}
              {...numberOfDaysFieldProps}
              label={t('Days')}
              name={t('Days')}
            >
              {numberOfDaysOption.map((option) => (
                <DropdownField.Option key={option} value={option.toString()}>
                  {option}
                </DropdownField.Option>
              ))}
            </DropdownField>
          )}
        </div>
        <ButtonBar css={{ justifyContent: 'start' }}>
          <Button
            loading={isSavingLocationSettings}
            disabled={!isAutoVerificationSupported}
            type='button'
            variant='primary'
            onClick={onSave}
          >
            {t('Save')}
          </Button>
          <Button onClick={onCancel} type='button' variant='secondary'>
            {t('Cancel')}
          </Button>
        </ButtonBar>
      </section>
    </>
  );
};

const NoPMSSupportAlert = () => {
  const { t } = useTranslation('insuranceGlobalSettings');

  return (
    <section css={alertContainerStyles}>
      <Icon size={24} name='warning-badge-small' color='warn' />
      <section>
        <Text size='large' weight='bold'>
          {t('Automatic verification is unavailable')}
        </Text>
        <Text size='medium'>
          {t(
            "Automatic verification isn't ready yet, but you can still verify insurance on the spot. We'll let you know when your PMS is compatible with automatic verifications!"
          )}
        </Text>
      </section>
    </section>
  );
};

const alertContainerStyles = css({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  background: theme.colors.warning5,
  boxShadow: theme.shadows.light,
  marginBottom: theme.spacing(3),
});

const cardContainerStyles = css({
  background: theme.colors.white,
  borderRadius: theme.borderRadius.small,
});

const containerStyles = css({
  padding: theme.spacing(1.5, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: `1px solid ${theme.colors.neutral20}`,
});
