import { i18next } from '@frontend/i18n';

/**
 * This function processes the relevant data for exporting
 */

type ProcessExportableData<T> = {
  columns: string[];
  data: T[];
  deriveExportValue: (args: { column: string; data: T }) => number | string;

  sortColumn?: keyof T;
  sortDirection?: 'asc' | 'desc';
};

export const processExportableData = <T>({
  columns,
  data,
  deriveExportValue,
  sortColumn,
  sortDirection = 'desc',
}: ProcessExportableData<T>): Partial<T>[] => {
  const sortedData = sortColumn
    ? data.sort((a, b) => {
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];

        if (valueA === valueB) {
          return 0;
        }

        if (!valueA) {
          return sortDirection === 'asc' ? -1 : 1;
        }

        if (!valueB) {
          return sortDirection === 'asc' ? 1 : -1;
        }

        if (sortDirection === 'asc') {
          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB);
          }
          return valueA > valueB ? 1 : -1;
        } else {
          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueB.localeCompare(valueA);
          }
          return valueA < valueB ? 1 : -1;
        }
      })
    : data;

  // Process each row
  return sortedData.map((patient) => {
    // Process each column
    return columns.reduce((acc, column) => {
      return {
        ...acc,
        [column]:
          deriveExportValue?.({
            column,
            data: patient,
          }) ?? patient[column as keyof T],
      };
    }, {} as Partial<T>);
  });
};

export const exportChartToPdfProps = (locationIds: string[], locations: Record<string, string>) => {
  const locationNames = locationIds.map((id) => locations[id]) ?? [];

  return {
    allowExportToPdf: true,
    pdfDetails: [
      {
        label:
          locationNames.length > 1
            ? i18next.t('Locations', { ns: 'analytics' })
            : i18next.t('Location', { ns: 'analytics' }),
        value: locationNames.join(', '),
      },
    ],
  };
};
