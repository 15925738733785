import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { http } from '@frontend/fetch';
import { validateUUIDV4 } from '@frontend/string';
import { CallIntelligenceTypes } from '.';

dayjs.extend(utc);
dayjs.extend(timezone);

const baseUrlV2 = 'call-intelligence/v2';

const generateCommonFilterPayload = (filters: CallIntelligenceTypes.Filters): CallIntelligenceTypes.FilterPayload => {
  // Get the current timezone offset in minutes
  const timezoneOffsetMinutes = dayjs().utcOffset();
  const start = dayjs(filters.startDate)
    .startOf('day')
    .add(-timezoneOffsetMinutes, 'minute')
    .format('YYYY-MM-DD HH:mm:ss.SSS');

  const end = dayjs(filters.endDate)
    .endOf('day')
    .add(-timezoneOffsetMinutes, 'minute')
    .format('YYYY-MM-DD HH:mm:ss.SSS');

  return {
    filter: {
      [CallIntelligenceTypes.FilterTypeEnum.FILTER_BY_DATE_RANGE]: {
        date_range: {
          end,
          start,
        },
      },
      [CallIntelligenceTypes.FilterTypeEnum.FILTER_BY_LOCATION]: {
        locations: filters.locations,
      },
    },
  };
};

const generateCallsFilterPayload = (filters: CallIntelligenceTypes.Filters) => {
  const {
    appointmentTypes,
    callDirection,
    categories,
    contactTypes,
    officeNumbers,
    officeUsers,
    schedulingOutcomes,
    schedulingOpportunity,
    sentiments,
  } = filters;
  const payload = generateCommonFilterPayload(filters);
  payload.filter[CallIntelligenceTypes.FilterTypeEnum.FILTER_BY_CALL_STATUS] = {
    call_status: filters.hideNonAnalyzedCalls
      ? // As we need to hide calls which are not analyzed, we need to exclude failed and skipped calls
        Object.values(CallIntelligenceTypes.CallStatusEnum).filter(
          (status) =>
            status !== CallIntelligenceTypes.CallStatusEnum.CALL_STATUS_FAILED &&
            status !== CallIntelligenceTypes.CallStatusEnum.CALL_STATUS_ERROR &&
            status !== CallIntelligenceTypes.CallStatusEnum.CALL_STATUS_SKIPPED
        )
      : Object.values(CallIntelligenceTypes.CallStatusEnum),
  };

  if (appointmentTypes?.length) {
    payload.filter[CallIntelligenceTypes.FilterTypeEnum.FILTER_BY_APPOINTMENT_TYPE] = {
      appointment_types: appointmentTypes,
    };
  }

  if (callDirection) {
    payload.filter[CallIntelligenceTypes.FilterTypeEnum.FILTER_BY_CALL_DIRECTION] = {
      call_direction: [callDirection],
    };
  }

  if (categories?.length) {
    payload.filter[CallIntelligenceTypes.FilterTypeEnum.FILTER_BY_CATEGORY] = {
      categories,
    };
  }

  if (contactTypes?.length) {
    payload.filter[CallIntelligenceTypes.FilterTypeEnum.FILTER_BY_CONTACT_TYPE] = {
      contact_type: contactTypes,
    };
  }

  if (sentiments?.length) {
    payload.filter[CallIntelligenceTypes.FilterTypeEnum.FILTER_BY_SENTIMENT] = {
      sentiments,
    };
  }

  // If a value is selected for schedulingOpportunity, then only "opportunity" should be included in request
  if (schedulingOpportunity) {
    payload.filter[CallIntelligenceTypes.FilterTypeEnum.FILTER_BY_OPPORTUNITY] = {
      opportunity:
        schedulingOpportunity === CallIntelligenceTypes.SchedulingOpportunityEnum.SCHEDULING_OPPORTUNITY_IDENTIFIED,
    };
  }

  // When both options are or none is checked, we don't send any value to the api
  if (schedulingOutcomes?.length === 1) {
    payload.filter[CallIntelligenceTypes.FilterTypeEnum.FILTER_BY_SCHEDULING_OUTCOME] = {
      scheduling_outcome:
        schedulingOutcomes[0] === CallIntelligenceTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_SCHEDULED,
    };
  }

  if (officeNumbers?.length) {
    payload.filter[CallIntelligenceTypes.FilterTypeEnum.FILTER_BY_OFFICE_NUMBER] = {
      office_number: officeNumbers,
    };
  }

  if (officeUsers?.length) {
    payload.filter[CallIntelligenceTypes.FilterTypeEnum.FILTER_BY_OFFICE_USER] = {
      office_user_id: officeUsers,
    };
  }

  return payload;
};

const generateFollowupsFilterPayload = (filters: CallIntelligenceTypes.FollowupTaskTypeFilters) => {
  const { officeUsers, contactTypes, taskTypes, taskStatus } = filters;
  const payload = generateCommonFilterPayload(filters);

  if (officeUsers?.length) {
    payload.filter[CallIntelligenceTypes.FollowupTaskFilterEnum.FILTER_BY_OFFICE_USER] = {
      office_user_id: officeUsers,
    };
  }

  if (contactTypes?.length) {
    payload.filter[CallIntelligenceTypes.FollowupTaskFilterEnum.FILTER_BY_CONTACT_TYPE] = {
      contact_type: contactTypes,
    };
  }

  if (taskTypes?.length) {
    payload.filter[CallIntelligenceTypes.FollowupTaskFilterEnum.FILTER_BY_TASK_TYPE] = {
      task_type: taskTypes,
    };
  }

  if (taskStatus?.length) {
    payload.filter[CallIntelligenceTypes.FollowupTaskFilterEnum.FILTER_BY_TASK_STATUS] = {
      task_status: taskStatus,
    };
  }

  return payload;
};

const generateDrillDownPayload = (drillDownOptions: CallIntelligenceTypes.DrillDownOptions) => {
  const { index, key, value } = drillDownOptions;

  return {
    [index]: {
      [key]: value,
    },
  };
};

const generateChartsPayload = ({ drillDownOptions, filters }: CallIntelligenceTypes.ApiPayloadProps) => {
  const payload = generateCommonFilterPayload(filters);

  if (filters.contactTypes?.length) {
    payload.filter[CallIntelligenceTypes.FilterTypeEnum.FILTER_BY_CONTACT_TYPE] = {
      contact_type: filters.contactTypes,
    };
  }

  payload.filter = {
    ...payload.filter,
    [CallIntelligenceTypes.FilterTypeEnum.FILTER_BY_CALL_STATUS]: {
      call_status: [CallIntelligenceTypes.CallStatusEnum.CALL_STATUS_COMPLETE],
    },
  };

  if (drillDownOptions) {
    payload.filter = {
      ...payload.filter,
      ...generateDrillDownPayload(drillDownOptions),
    };
  }

  return payload;
};

const generateSearchPayload = (searchQuery: string): CallIntelligenceTypes.ApiSearchPayloadProps => {
  let searchType: CallIntelligenceTypes.SearchTypeEnum = CallIntelligenceTypes.SearchTypeEnum.SEARCH_UNKNOWN;

  if (/^\d+$/.test(searchQuery)) {
    searchType = CallIntelligenceTypes.SearchTypeEnum.SEARCH_BY_NUMBER;
  } else if (/[a-zA-Z0-9]/.test(searchQuery)) {
    searchType = CallIntelligenceTypes.SearchTypeEnum.SEARCH_BY_NAME;
  }

  return {
    search_type: searchType,
    search: searchQuery,
  };
};

export const getCalls = async ({
  drillDownOptions,
  filters,
  pageConfig,
  showOnlyUnscheduled,
  searchQuery,
  sortType,
}: CallIntelligenceTypes.GetCalls) => {
  if (!filters.locations?.length || !filters.startDate || !filters.endDate) {
    return;
  }

  let isUUID = false;

  if (searchQuery) {
    isUUID = validateUUIDV4(searchQuery);
  }

  const payload = {
    ...generateCallsFilterPayload(filters),
    limit: pageConfig.pageSize + 1, // Manipulating limit to know if there is more data to fetch
    offset: (pageConfig.pageNumber - 1) * pageConfig.pageSize,
    sort_type: sortType ?? CallIntelligenceTypes.SortTypeEnum.SORT_DESC,
    ...(searchQuery && !isUUID && { search_query: generateSearchPayload(searchQuery) }),
  };

  if (drillDownOptions) {
    payload.filter = {
      ...payload.filter,
      ...generateDrillDownPayload(drillDownOptions),
    };
  }

  if (showOnlyUnscheduled) {
    payload.filter = {
      ...payload.filter,
      [CallIntelligenceTypes.FilterTypeEnum.FILTER_BY_OPPORTUNITY]: {
        opportunity: true,
      },
      [CallIntelligenceTypes.FilterTypeEnum.FILTER_BY_SCHEDULING_OUTCOME]: {
        scheduling_outcome: false,
      },
    };
  }

  if (isUUID) {
    payload.filter = {
      ...payload.filter,
      [CallIntelligenceTypes.FilterTypeEnum.FILTER_BY_CALL_ID]: {
        call_id: [searchQuery],
      },
    };
  }

  const data = await http.post<CallIntelligenceTypes.CallsResponse>(`${baseUrlV2}/calls`, payload);

  return {
    calls: data.calls?.slice(0, pageConfig.pageSize),
    hasNextPage: (data.calls || []).length > pageConfig.pageSize,
    pageConfig,
  };
};

export const getCallMetaData = (callId: string, locationId: string) =>
  http.get<CallIntelligenceTypes.CallMetadataResponse>(`${baseUrlV2}/call/${callId}/metadata?locationId=${locationId}`);

export const downloadCallRecording = (locationId: string, callId: string, isDemoAccount?: boolean): Promise<Blob> =>
  http.get<Blob>(
    isDemoAccount ? `${baseUrlV2}/demo/call-recording` : `${baseUrlV2}/calls/${locationId}/${callId}/recording`,
    {
      responseType: 'blob',
    }
  );

export const getOverviewDetails = ({ drillDownOptions, filters }: CallIntelligenceTypes.ApiPayloadProps) => {
  if (!filters.locations?.length || !filters.startDate || !filters.endDate) {
    return;
  }

  return http.post<CallIntelligenceTypes.Overview>(
    `${baseUrlV2}/overview`,
    generateChartsPayload({ drillDownOptions, filters })
  );
};

export const getCallTasks = (callTaskParams: CallIntelligenceTypes.CallTaskParams) => {
  let payload = { filter: {}, include_metadata: false, sort_type: 2 };

  payload.filter = {
    ...payload.filter,
    [CallIntelligenceTypes.TaskFilterTypeEnum.FILTER_BY_LOCATION]: {
      location_id: callTaskParams.locations,
    },

    [CallIntelligenceTypes.TaskFilterTypeEnum.FILTER_BY_SOURCE]: {
      source: callTaskParams.source || CallIntelligenceTypes.TaskSource.CALL_INTELLIGENCE,
    },

    [CallIntelligenceTypes.TaskFilterTypeEnum.FILTER_BY_SOURCE_ID]: {
      source_id: callTaskParams.callIds,
    },
  };

  if (callTaskParams.includeMetaData) {
    payload = { ...payload, include_metadata: callTaskParams.includeMetaData };
  }

  if (callTaskParams.sortType) {
    payload = { ...payload, sort_type: callTaskParams.sortType };
  }

  return http.post<CallIntelligenceTypes.CallTaskListResponse>(`task-center/v1/tasks/list`, payload);
};

export const getAllStats = ({ filters }: CallIntelligenceTypes.GetFollowUpsStatsParams) => {
  if (!filters.locations?.length || !filters.startDate || !filters.endDate) {
    return;
  }
  const payload = {
    ...generateFollowupsFilterPayload(filters),
  };
  return http.post<CallIntelligenceTypes.FollowUpStatsResponse>(`${baseUrlV2}/calls/followups/stats`, payload);
};

export const getFollowUps = async ({
  filters,
  pageConfig,
  sortBy,
  sortType,
  searchQuery,
}: CallIntelligenceTypes.GetFollowUpsParams) => {
  if (!filters.locations?.length || !filters.startDate || !filters.endDate) {
    return;
  }

  let isUUID = false;

  if (searchQuery) {
    isUUID = validateUUIDV4(searchQuery);
  }

  const payload = {
    ...generateFollowupsFilterPayload(filters),
    limit: pageConfig.pageSize + 1,
    offset: (pageConfig.pageNumber - 1) * pageConfig.pageSize,
    sort_by: sortBy,
    sort_type: sortType ?? CallIntelligenceTypes.SortTypeEnum.SORT_DESC,
    ...(searchQuery && !isUUID && { search_query: generateSearchPayload(searchQuery) }),
  };

  const data = await http.post<CallIntelligenceTypes.FollowUpResponse>(`${baseUrlV2}/calls/followups`, payload);

  return {
    followUps: data.followUps.slice(0, pageConfig.pageSize),
    hasNextPage: (data.followUps || []).length > pageConfig.pageSize,
    pageConfig,
    totalFollowUps: data.totalFollowUps,
  };
};

export const getStatsByLocations = ({ drillDownOptions, filters }: CallIntelligenceTypes.ApiPayloadProps) => {
  if (!filters.locations?.length || !filters.startDate || !filters.endDate) {
    return;
  }

  return http.post<CallIntelligenceTypes.LocationsStats>(
    `${baseUrlV2}/locations`,
    generateChartsPayload({ drillDownOptions, filters })
  );
};

export const getCallsStats = (filters: CallIntelligenceTypes.Filters, searchQuery?: string) => {
  if (!filters.locations?.length || !filters.startDate || !filters.endDate) {
    return;
  }

  let isUUID = false;

  if (searchQuery) {
    isUUID = validateUUIDV4(searchQuery);
  }

  const payload = {
    ...generateCallsFilterPayload(filters),
    ...(searchQuery && !isUUID && { search_query: generateSearchPayload(searchQuery) }),
  };

  payload.filter = {
    ...payload.filter,
    // Override the call status filter to only include failed, error and skipped calls
    [CallIntelligenceTypes.FilterTypeEnum.FILTER_BY_CALL_STATUS]: {
      call_status: [
        CallIntelligenceTypes.CallStatusEnum.CALL_STATUS_ERROR,
        CallIntelligenceTypes.CallStatusEnum.CALL_STATUS_FAILED,
        CallIntelligenceTypes.CallStatusEnum.CALL_STATUS_SKIPPED,
      ],
    },
  };

  if (isUUID) {
    payload.filter = {
      ...payload.filter,
      [CallIntelligenceTypes.FilterTypeEnum.FILTER_BY_CALL_ID]: {
        call_id: [searchQuery],
      },
    };
  }

  return http.post<CallIntelligenceTypes.CallsStats>(`${baseUrlV2}/calls/stats`, payload);
};

export const getLocations = () => http.get<CallIntelligenceTypes.Locations>(`${baseUrlV2}/location/siblings`);

export const submitFeedback = (request: CallIntelligenceTypes.FeedbackRequest) =>
  http.post(`${baseUrlV2}/feedback`, request);

export const updateTask = async (taskParams: CallIntelligenceTypes.TaskUpdateRequest) => {
  const { id: taskId, ...taskPayload } = taskParams;

  return http.patch(`task-center/v1/tasks/${taskId}`, taskPayload);
};

export const deleteTask = async (taskId: string) => {
  return http.delete(`task-center/v1/tasks/${taskId}`);
};

export const noopMutationFn = async (value: any) => Promise.resolve(value);
