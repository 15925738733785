import { css } from '@emotion/react';
import { OpeningRequest, ScheduleRequest } from '@weave/schema-gen-ts/dist/schemas/schedule/api/v2/api.pb';
import { Provider, Workstation } from '@weave/schema-gen-ts/dist/schemas/schedule/settings/v2/settings.pb';
import dayjs from 'dayjs';
import { AppointmentTypesTypes } from '@frontend/api-appointment-types';
import { DataSourcesTypes } from '@frontend/api-data-sources';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import {
  Avatar,
  ContentLoader,
  DatePickerField,
  DropdownField,
  FormRow,
  MessageTemplate,
  NakedButton,
  TagType,
  Text,
  TimeField,
  UsePopoverDialogResponse,
} from '@frontend/design-system';
import { defaultTemplate } from '../../views/Calendar/EventsPanel/ScheduleActions/ScheduleRequests/constant';
import { useManageScheduleRequestModalForm } from '../../views/Calendar/EventsPanel/ScheduleActions/ScheduleRequests/hooks/useManageScheduleRequestModalForm';
import {
  ReducedAppointmentType,
  ReducedOperatory,
  ReducedPractitioner,
} from '../../views/Calendar/EventsPanel/ScheduleActions/ScheduleRequests/hooks/useReviewRequest';
import { SourceTenantListItem } from '../../views/Calendar/EventsPanel/ScheduleActions/ScheduleRequests/ScheduleRequestModal/types';
import { ScheduleRequestsTrackingIds } from '../../views/Calendar/EventsPanel/ScheduleActions/ScheduleRequests/trackingIds';
import { getFormattedAppointmentType } from '../../views/Calendar/EventsPanel/ScheduleActions/ScheduleRequests/utils';

type ReviewAppointmentRequestFormProps = {
  appointmentTypes: AppointmentTypesTypes.AppointmentType[] | ReducedAppointmentType[];
  isLoading: boolean;
  patientName: string;
  patientPhone: string;
  personDialogProps: UsePopoverDialogResponse<HTMLButtonElement, false, HTMLDialogElement>;
  disabledForm: boolean;
  getFieldProps: ReturnType<typeof useManageScheduleRequestModalForm>['scheduleRequestApprovalForm']['getFieldProps'];
  values: ReturnType<typeof useManageScheduleRequestModalForm>['scheduleRequestApprovalForm']['values'];
  dateField: ReturnType<typeof useManageScheduleRequestModalForm>['customDateField'];
  timeField: ReturnType<typeof useManageScheduleRequestModalForm>['customTimeField'];
  selectedScheduleRequest: ScheduleRequest;
  showDataSourceDropdown?: boolean;
  showSourceLocationDropdown?: boolean;
  syncAppDataSources: DataSourcesTypes.DataSource[];
  providerList: Provider[] | ReducedPractitioner[];
  workstationList: Workstation[] | ReducedOperatory[];
  sourceTenantList?: SourceTenantListItem[];
  showWorkstationDropdown: boolean;
  onTemplateChange: (template: string) => void;
  templateTags: TagType[];
  isReadOnlyTemplate: boolean;
};

export const ReviewAppointmentRequestForm = ({
  isLoading,
  patientName,
  patientPhone,
  personDialogProps,
  disabledForm,
  getFieldProps,
  appointmentTypes,
  selectedScheduleRequest,
  values,
  dateField,
  timeField,
  showDataSourceDropdown,
  showSourceLocationDropdown,
  syncAppDataSources,
  providerList,
  workstationList,
  sourceTenantList,
  showWorkstationDropdown,
  onTemplateChange,
  templateTags,
  isReadOnlyTemplate,
}: ReviewAppointmentRequestFormProps) => {
  const { t } = useTranslation('scheduleCalendarRequest');

  return (
    <>
      <section css={sectionStyle}>
        <ContentLoader show={isLoading} />
        <div css={headerStyles}>
          <Avatar name={patientName} />
          <section>
            <Text weight='bold' size='large'>
              {patientName}
            </Text>
            <Text size='small' color='light'>
              {patientPhone}
            </Text>
          </section>
          <NakedButton
            trackingId={ScheduleRequestsTrackingIds.linkPatientBtn}
            css={linkPatientStyles}
            {...personDialogProps.getTriggerProps()}
          >
            <Icon color='primary' name='user-small' />
            <Text as='span' color='primary' weight='bold'>
              {t('Link Patient')}
            </Text>
          </NakedButton>
        </div>
        <form css={{ marginTop: theme.spacing(3) }}>
          <FormRow cols={[50, 50]}>
            <DropdownField
              css={fieldStyles}
              disabled={disabledForm}
              label={t('Appointment Type')}
              {...getFieldProps('appointmentType')}
            >
              {appointmentTypes?.map((item: AppointmentTypesTypes.AppointmentType | ReducedAppointmentType) => (
                <DropdownField.Option value={item?.id} key={item?.id}>
                  {getFormattedAppointmentType(item)}
                </DropdownField.Option>
              ))}
            </DropdownField>
            <DropdownField
              css={fieldStyles}
              disabled={disabledForm}
              label={t('Date & Time')}
              {...getFieldProps('dateAndTime')}
            >
              {selectedScheduleRequest?.requestedOpenings?.map((item: OpeningRequest) => (
                <DropdownField.Option value={item?.dateTime ?? ''} key={item?.dateTime ?? ''}>
                  {dayjs(item.dateTime).format('MMM DD @ hh:mm a')}
                </DropdownField.Option>
              ))}
              <DropdownField.Option value='custom'>Custom</DropdownField.Option>
            </DropdownField>
          </FormRow>
          {values.dateAndTime === 'custom' && (
            <FormRow cols={[50, 50]}>
              <DatePickerField name='customDate' label='Custom Date' css={fieldStyles} {...dateField} />
              <TimeField name='customTime' label='Custom Time' css={fieldStyles} {...timeField} />
            </FormRow>
          )}
          <FormRow cols={[50, 50]}>
            {showSourceLocationDropdown && (
              <DropdownField
                css={fieldStyles}
                disabled={disabledForm}
                label={t('Location')}
                {...getFieldProps('source')}
              >
                {sourceTenantList?.map(({ sourceTenantId = '', name, pmsName }) => (
                  <DropdownField.Option value={sourceTenantId} key={name}>
                    <Text as='span' css={{ marginRight: theme.spacing(1) }}>
                      {name}
                    </Text>
                    <Text as='span' color='light' size='small'>
                      {pmsName}
                    </Text>
                  </DropdownField.Option>
                ))}
              </DropdownField>
            )}
            {showDataSourceDropdown && (
              <DropdownField
                css={fieldStyles}
                disabled={disabledForm}
                label={t('Select Source')}
                {...getFieldProps('source')}
              >
                {syncAppDataSources?.map(({ SourceID, SourceName, PracticeManagementSystem }) => (
                  <DropdownField.Option value={SourceID} key={SourceID}>
                    <Text as='span' css={{ marginRight: theme.spacing(1) }}>
                      {SourceName}
                    </Text>
                    <Text as='span' color='light' size='small'>
                      {PracticeManagementSystem}
                    </Text>
                  </DropdownField.Option>
                ))}
              </DropdownField>
            )}

            <DropdownField
              css={fieldStyles}
              disabled={disabledForm}
              label={t('Select Provider')}
              {...getFieldProps('provider')}
            >
              {providerList.map(({ id, firstName, lastName }) => (
                <DropdownField.Option value={id ?? ''} key={id}>
                  {firstName} {lastName}
                </DropdownField.Option>
              ))}
            </DropdownField>
          </FormRow>
          {showWorkstationDropdown && (
            <FormRow>
              <DropdownField disabled={disabledForm} label={t('Workstation')} {...getFieldProps('workstation')}>
                {workstationList.map(({ id, workstationName }) => (
                  <DropdownField.Option value={id ?? ''} key={id}>
                    {workstationName}
                  </DropdownField.Option>
                ))}
              </DropdownField>
            </FormRow>
          )}
        </form>
        <MessageTemplate
          initialTemplate={defaultTemplate}
          tags={templateTags}
          draggableTags={true}
          onChange={onTemplateChange}
          readOnly={isReadOnlyTemplate}
        >
          <MessageTemplate.Editor />
          <Text weight='light' color='light'>
            {t('You can also drag and drop, or click a tag below, to add your message')}
          </Text>
          <MessageTemplate.TagList />
        </MessageTemplate>
      </section>
    </>
  );
};

const sectionStyle = css`
  background-color: ${theme.colors.white};
  border-radius: ${theme.borderRadius.small};
  padding: ${theme.spacing(2)};
  margin: ${theme.spacing(1)};
`;

const headerStyles = css`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${theme.colors.neutral10};
  border-top: 1px solid ${theme.colors.neutral10};
  padding: ${theme.spacing(2)};

  & > section {
    margin-left: ${theme.spacing(2)};
  }
`;

const linkPatientStyles = css`
  display: flex;
  align-items: center;
  width: 130px;
  margin-left: auto;
  cursor: pointer;

  & > svg {
    margin-right: ${theme.spacing(0.5)};
  }
`;

const fieldStyles = css`
  width: 192;
`;
