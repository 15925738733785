import * as callRecordings from './call-recordings';
import * as followUp from './follow-up';
import * as locationComparison from './location-comparison';
import * as overview from './overview';

export const CallIntelMockData = {
  ...callRecordings,
  ...followUp,
  ...locationComparison,
  ...overview,
};
