import { useState } from 'react';
import { css } from '@emotion/react';
import { motion, AnimatePresence } from 'framer-motion';
import { useTranslation } from '@frontend/i18n';
import { useModalControl, Modal } from '@frontend/design-system';
import { MIN_MODAL_WIDTH, Steps, CHAT_LIST_WIDTH_COLLAPSED, CHAT_AREA_WIDTH } from '../../../constants';
import { useTeamChatStore } from '../../../providers';
import { ChatTitleBar } from '../../chat-title-bar/chat-title-bar';
import { NewChannelModal } from '../../common';
import { ChatCategory } from '../chat-category/chat-category';
import { containerStyle, chatsContainerStyle } from './chat-list-view.styles';

// This width is specific to the create channel modal, hence defined here
const CREATE_CHANNEL_MODAL_WIDTH = 500;

export const ChatListView = () => {
  const [step, setStep] = useState<Steps>(Steps.One);
  const { t } = useTranslation('team-chat');
  const { conversations, activeConversation, initializeNewConversation, isChatListExpanded, activeChannelId } =
    useTeamChatStore([
      'conversations',
      'activeConversation',
      'initializeNewConversation',
      'setActiveConversation',
      'isChatListExpanded',
      'activeChannelId',
    ]);
  const { modalProps, openModal, closeModal } = useModalControl();

  const width =
    activeConversation || activeChannelId ? (isChatListExpanded ? CHAT_LIST_WIDTH_COLLAPSED : 0) : CHAT_AREA_WIDTH;

  return (
    <AnimatePresence>
      <motion.section
        layout
        css={containerStyle}
        initial={{ opacity: 0, width }} // Initial state of the div
        animate={{
          opacity: 1,
          width,
        }}
        exit={{ opacity: 0, width: 0 }}
        transition={{ duration: 0.3, type: 'keyframes' }}
      >
        <ChatTitleBar />
        <div css={chatsContainerStyle}>
          {!!conversations.unread.length && <ChatCategory title={t('Unread')} chats={conversations.unread} />}
          <ChatCategory
            title={t('Channels')}
            chats={conversations.groups}
            addNewConversation={{ title: t('Add channel'), onClick: openModal }}
          />
          <ChatCategory
            title={t('Direct Messages')}
            chats={conversations.dm}
            addNewConversation={{ title: t('Add direct message'), onClick: () => initializeNewConversation({}) }}
          />
          <Modal
            {...modalProps}
            minWidth={step === Steps.One ? CREATE_CHANNEL_MODAL_WIDTH : MIN_MODAL_WIDTH}
            disableCloseOnOverlayClick
            disableCloseOnEscape
          >
            <Modal.Header
              onClose={() => {
                setStep(Steps.One);
                closeModal();
              }}
            >
              {t('Create Channel')}
            </Modal.Header>
            <Modal.Body css={css({ padding: '0 !important' })}>
              <NewChannelModal modalProps={modalProps} step={step} setStep={setStep} />
            </Modal.Body>
          </Modal>
        </div>
      </motion.section>
    </AnimatePresence>
  );
};
