import { css } from '@emotion/react';
import { isEqual } from 'lodash-es';
import { ScheduleAvailabilityTypes } from '@frontend/api-schedule-availability';
import { useTranslation } from '@frontend/i18n';
import { ScheduleAvailabilityHoursTypes, useScheduleAvailabilityHours } from '@frontend/schedule-availability-hours';
import { theme } from '@frontend/theme';
import { ButtonBar, ConfirmationModal, PrimaryButton, SecondaryButton, useModalControl } from '@frontend/design-system';
import { Timezone } from './Timezone';

type OfficeHoursSettingsProps = {
  locationId: string;
  officeHoursData: ScheduleAvailabilityTypes.Schedule | undefined;
  onSave: (updatedScheduleAvailability: ScheduleAvailabilityHoursTypes.ScheduleAvailabilityByDayOfWeek) => void;
  refetchOfficeHours: () => void;
  isLoading: boolean;
};

export const OfficeHoursSettings = ({
  locationId,
  officeHoursData,
  refetchOfficeHours,
  isLoading,
  onSave,
}: OfficeHoursSettingsProps) => {
  const { t } = useTranslation('schedule');

  const { modalProps: cancelModalProps, triggerProps: cancelTriggerProps } = useModalControl();

  const { updatedScheduleAvailability, ScheduleAvailabilityHours, isError, resetScheduleAvailability } =
    useScheduleAvailabilityHours({
      initialScheduleAvailability: officeHoursData || {},
      isLoading: isLoading,
      closedOfficeText: t('Closed'),
      timeRangeJoiningText: t('to'),
    });

  const handleCancel = (e?: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e?.stopPropagation();
    cancelTriggerProps.onClick();
  };

  const handleConfirmCancel = () => {
    if (!isEqual(updatedScheduleAvailability, officeHoursData)) {
      resetScheduleAvailability();
    }
    refetchOfficeHours();
  };

  const handleSave = () => {
    if (updatedScheduleAvailability) {
      onSave(updatedScheduleAvailability);
    }
  };

  const shouldDisableSave = isError || isLoading || isEqual(updatedScheduleAvailability, officeHoursData);

  return (
    <>
      <Timezone locationId={locationId} />
      <div css={{ padding: theme.spacing(2) }}>{ScheduleAvailabilityHours}</div>

      <ButtonBar css={buttonBarStyles}>
        <PrimaryButton disabled={shouldDisableSave} onClick={handleSave}>
          {t('Save')}
        </PrimaryButton>
        <SecondaryButton onClick={(e) => handleCancel(e)}>{t('Cancel')}</SecondaryButton>
      </ButtonBar>
      <ConfirmationModal
        {...cancelModalProps}
        title={t('Unsaved Changes')}
        destructive
        message={t('Your changes will not be saved. Are you sure you want to discard your changes?')}
        confirmLabel={t('Discard')}
        cancelLabel={t('Keep Editing')}
        onCancel={cancelModalProps.onClose}
        onConfirm={handleConfirmCancel}
      />
    </>
  );
};

const buttonBarStyles = css({
  borderTop: `thin solid ${theme.colors.neutral20}`,
  borderLeftBottomRadius: theme.borderRadius.small,
  borderRightBottomRadius: theme.borderRadius.small,
  justifyContent: 'flex-start',
  '> button': { width: 'fit-content' },
});
