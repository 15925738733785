import { useCallback, useEffect, useRef, useState } from 'react';
import { FeatureFlagQueries } from '@frontend/api-feature-flags';
import { MessagesHooks } from '@frontend/api-messaging';
import { PersonsV3 } from '@frontend/api-person';
import { SMSDataV3 } from '@frontend/api-sms-data';
import { useMessageAction } from '@frontend/contact-actions-send-message';
import { useTranslation } from '@frontend/i18n';
import { useInboxNavigate } from '@frontend/inbox-navigation';
import { ContextContactListItem, ThreadHeader } from '@frontend/thread-header';
import { sentry } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import { useAlert, useMultiPanelStore } from '@frontend/design-system';
import { INBOX_PANEL_ID } from '../../../constants';
import { BulkSelectionActionType } from '../../../types';
import { BulkSelectionButtons } from './bulk-selection-buttons';
import { ResponsiveHeaderWrapper } from './responsive-header-wrapper';
import { ResponsiveThreadActions } from './responsive-thread-actions';

type InboxThreadHeaderProps = {
  groupId: string;
  threadId: string;
  personPhone: string;
  personId?: string;
  departmentId?: string;
  isArchived: boolean;
  isBlocked: boolean;
  isNew: boolean;
  bulkSelectionType?: BulkSelectionActionType;
  setBulkSelectionType: (type?: BulkSelectionActionType) => void;
  canSmsWriteback: boolean;
  targetSmsData?: Parameters<typeof useMessageAction>[0]['targetSmsData'];
};

export const InboxThreadHeader = ({
  groupId,
  threadId,
  personPhone,
  personId: providedPersonId,
  departmentId,
  isArchived,
  isBlocked,
  isNew,
  bulkSelectionType,
  setBulkSelectionType,
  canSmsWriteback,
  targetSmsData,
}: InboxThreadHeaderProps) => {
  const { t } = useTranslation('inbox');
  const alert = useAlert();
  const { navigateToThread, updatePersonId } = useInboxNavigate();
  const invalidateInboxList = MessagesHooks.useInvalidateInboxList();
  const { handlePersonPhoneSelection, handleDepartmentSelection } = SMSDataV3.Hooks.useThreadSelection({
    groupId,
    currentThreadId: threadId,
    selection: {
      personPhone,
      departmentId,
    },
    onSelectionChange: ({ threadId, newSelection, isNewThread }) => {
      navigateToThread({
        threadId,
        groupId,
        personPhone: newSelection.personPhone,
        departmentId: newSelection.departmentId,
        replace: true,
        isNew: isNewThread,
        personId: providedPersonId,
      });
    },
    onError: ({ err, selectionType, context }) => {
      alert.error(t('Error fetching selected thread.'));
      sentry.error({
        error: `Error looking up thread id from ${selectionType} selection`,
        topic: 'messages',
        addContext: {
          name: 'Selection context',
          context: {
            ...context,
            error: JSON.stringify(err),
          },
        },
      });
    },
  });

  const { panelInfo } = useMultiPanelStore(['panelInfo']);
  const inboxPanelIsHidden = panelInfo.find(({ id }) => id === INBOX_PANEL_ID)?.isHidden;
  const headerVariant = inboxPanelIsHidden ? 'vertical' : 'responsive';
  const { data: flags } = FeatureFlagQueries.useMultiFeatureFlagIsEnabledQuery({
    flagName: 'comm-platform-thread-naming',
    groupIds: [groupId],
  });
  const threadNamingIsEnabled = !!flags?.[groupId];

  const {
    associatedContacts,
    primaryContactId,
    refetch: refetchAssociatedContacts,
  } = PersonsV3.PersonHooks.useAssociatedContacts({
    phoneNumber: personPhone,
    locationId: groupId,
    enabled: threadNamingIsEnabled,
  });
  const personId = threadNamingIsEnabled ? providedPersonId || primaryContactId : providedPersonId;
  const person = associatedContacts.find((contact) => contact.personId === personId);
  const [showContextContact, setShowContextContact] = useState(false);

  const { data: fetchedPerson } = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    {
      locationIds: [groupId],
      personId: personId ?? '',
    },
    {
      enabled: !!personId && !threadNamingIsEnabled,
    }
  );

  useEffect(() => {
    if (providedPersonId && primaryContactId && primaryContactId !== providedPersonId && threadNamingIsEnabled) {
      setShowContextContact(true);
    } else if (providedPersonId && primaryContactId && primaryContactId === providedPersonId) {
      setShowContextContact(false);
    }
  }, [primaryContactId, threadNamingIsEnabled]);

  useEffect(() => {
    if (bulkSelectionType) setShowContextContact(false);
  }, [bulkSelectionType]);

  const handleContactCreation = useCallback(async () => {
    const timeouts: NodeJS.Timeout[] = [];

    const clearTimeouts = () => {
      timeouts.forEach((timeout) => clearTimeout(timeout));
    };

    const addTimeout = (ms: number) =>
      setTimeout(async () => {
        const { associatedContacts } = await refetchAssociatedContacts('associatedContacts');
        if (!!associatedContacts?.length) {
          invalidateInboxList();
          clearTimeouts();
        }
      }, ms);

    // PersonsV3 has some lag when creating a contact. So we will refetch every 500ms until we get a response, or have fetched 3 times.
    timeouts.push(addTimeout(500), addTimeout(1000), addTimeout(1500));
  }, []);

  const associatedContactsButtonRef = useRef<HTMLButtonElement>();

  return (
    <ThreadHeader
      groupId={groupId}
      personPhone={personPhone ?? ''}
      outboundPhone={{ departmentId: departmentId ?? '' }}
      onPersonPhoneChange={(newPersonPhone) => handlePersonPhoneSelection(newPersonPhone, true)}
      onOutboundPhoneChange={({ departmentId }) => handleDepartmentSelection(departmentId, true)}
      variant={headerVariant}
      contextPersonId={providedPersonId}
      primaryContact={threadNamingIsEnabled ? person : fetchedPerson}
      associatedContacts={threadNamingIsEnabled ? undefined : fetchedPerson ? [fetchedPerson] : []}
    >
      <ResponsiveHeaderWrapper showBackButton={!!inboxPanelIsHidden}>
        <ThreadHeader.Title onCreateContact={handleContactCreation}>
          <span
            css={{
              display: 'flex',
              gap: theme.spacing(1),
              alignItems: 'center',
            }}
          >
            {threadNamingIsEnabled && (
              <ThreadHeader.AssociatedContactsButton
                ref={(node) => (associatedContactsButtonRef.current = node ?? undefined)}
                onClick={(isOpen, e) => {
                  e.stopPropagation();
                  setShowContextContact(false);
                  if (!isOpen) associatedContactsButtonRef.current?.blur();
                }}
              />
            )}
            <ThreadHeader.Info />
          </span>
        </ThreadHeader.Title>
        <ThreadHeader.AssociatedContactsDrawer
          key='associated-contacts-drawer'
          onSelect={() => {
            updatePersonId('');
            // Wait for personsV3 to update before refetching inbox list
            setTimeout(() => {
              invalidateInboxList();
            }, 300);
          }}
          closeButtonRef={associatedContactsButtonRef}
        />
        <ThreadHeader.Drawer
          key='bulk-selection-drawer'
          open={!!bulkSelectionType}
          onClose={() => setBulkSelectionType(undefined)}
          hideCloseButton
          closeOnClickOutside={false}
        >
          <BulkSelectionButtons />
        </ThreadHeader.Drawer>
        {threadNamingIsEnabled && !!personId && (
          <ThreadHeader.Drawer
            key='context-contact-drawer'
            open={showContextContact}
            onClose={() => setShowContextContact(false)}
            hideCloseButton
            closeOnClickOutside={false}
          >
            <ContextContactListItem
              personId={personId}
              person={associatedContacts.find((contact) => contact.personId === personId)}
              groupIds={[groupId]}
              onClose={() => setShowContextContact(false)}
            />
          </ThreadHeader.Drawer>
        )}
        <ResponsiveThreadActions
          threadId={threadId}
          groupId={groupId}
          personPhone={personPhone}
          outboundPhone={{ departmentId: departmentId ?? '' }}
          personId={personId}
          person={person}
          isArchived={isArchived}
          isBlocked={isBlocked}
          isNew={isNew}
          handleContactCreation={handleContactCreation}
          setBulkSelectionType={setBulkSelectionType}
          canSmsWriteback={canSmsWriteback}
          targetSmsData={targetSmsData}
        />
      </ResponsiveHeaderWrapper>
      <ThreadHeader.SubHeader>
        <ThreadHeader.PersonPhoneSelector />
        <ThreadHeader.DepartmentSelector />
      </ThreadHeader.SubHeader>
    </ThreadHeader>
  );
};
