import { useEffect, FC, PropsWithChildren, useState, useCallback } from 'react';
import { StreamChat } from 'stream-chat';
import { useAppScopeStore } from '@frontend/scope';
import { useNotifications, useTeamChatClientEvents, useTeamChatConnect, useWeavePresence } from '../hooks';
import { fetchUsers, getChannels, getTeamId } from '../utils';
import { useTeamChatStore } from './team-chat.store';

export const TeamChatWrapper: FC<PropsWithChildren> = ({ children }) => {
  const { isTrayOpen, initialize, setIsConnected } = useTeamChatStore(['isTrayOpen', 'initialize', 'setIsConnected']);
  const { selectedOrgId } = useAppScopeStore();
  const { isTokenReady, connect } = useTeamChatConnect();
  const [__, setFailedToInitialize] = useState(false);

  useWeavePresence(getTeamId(selectedOrgId));
  useTeamChatClientEvents();
  useNotifications();

  const loadData = useCallback(
    async (client: StreamChat) => {
      try {
        const [users, conversations] = await Promise.all([
          fetchUsers(client, selectedOrgId),
          getChannels(client, selectedOrgId),
        ]);

        // TODO: initialization should be done in a single call
        initialize({
          conversations,
          users,
          isInitializing: false,
          userStatus: users.reduce((acc, user) => ({ ...acc, [user.userID]: user.status }), {}),
        });
      } catch (error) {
        // TODO: if client fails to initialize then show a retry button on the UI to trigger the initialization again
        setFailedToInitialize(true);
      }
    },
    [selectedOrgId, isTrayOpen, initialize]
  );

  useEffect(() => {
    let streamClient: StreamChat | null = null;

    if (isTokenReady && !!selectedOrgId) {
      connect(selectedOrgId).then(({ client }) => {
        streamClient = client;
        if (!!client) {
          loadData(client);
        }
      });
    }

    return () => {
      if (!!streamClient?.user) {
        streamClient.disconnectUser();
        setIsConnected(false);
      }
    };
  }, [isTokenReady, selectedOrgId]);

  return children;
};
