import { createShallowStore, createStore } from '@frontend/store';

export type ManagePractitionersHelperStore = {
  isOnlineSchedulingEnabled: boolean;
  refetchPractitioners: () => void;
  setRefetchPractitioners: (refetchPractitioners: () => void) => void;
  setIsOnlineSchedulingEnabled: (isOnlineSchedulingEnabled: boolean) => void;
  refetchPractitionerAppointmentTypes: () => void;
  setRefetchPractitionerAppointmentTypes: (refetchPractitionerAppointmentTypes: () => void) => void;
};

export const useManagePractitionersHelperStore = createStore<ManagePractitionersHelperStore>(
  (set) => ({
    isOnlineSchedulingEnabled: false,
    refetchPractitioners: () => {},
    setRefetchPractitioners: (refetchPractitioners: () => void) => set({ refetchPractitioners }),
    setIsOnlineSchedulingEnabled: (isOnlineSchedulingEnabled: boolean) => set({ isOnlineSchedulingEnabled }),
    refetchPractitionerAppointmentTypes: () => {},
    setRefetchPractitionerAppointmentTypes: (refetchPractitionerAppointmentTypes: () => void) =>
      set({ refetchPractitionerAppointmentTypes }),
  }),
  {
    name: 'managePractitionerHelper',
  }
);

export const useManagePractitionerHelperShallowStore = createShallowStore<ManagePractitionersHelperStore>(
  useManagePractitionersHelperStore
);
