import { FieldTypes, FieldConditionTypes } from '../types';

export function removeConditionEffectsFromField(targetField: FieldTypes.Field): FieldTypes.Field {
  return {
    ...targetField,
    hidden: false,
    disabled: false,
  };
}

interface ModifyFieldPayload {
  targetField: FieldTypes.Field;
  newFieldCondition: FieldConditionTypes.ConditionOperation;
}

export function modifyFieldBasedOnCondition({ targetField, newFieldCondition }: ModifyFieldPayload): FieldTypes.Field {
  let modifiedField = { ...targetField };

  // Revert the effects of the current condition
  modifiedField = removeConditionEffectsFromField(modifiedField);

  // Apply the new condition
  switch (newFieldCondition) {
    case 'show':
      modifiedField.hidden = true;
      break;

    case 'enable':
      modifiedField.disabled = true;
      break;
  }

  return modifiedField;
}

interface RemoveConditionByIdPayload {
  sourceFieldId: string;
  conditionId: string;
  fields: Record<string, FieldTypes.Field>;
  conditions: Record<string, FieldConditionTypes.FormCondition>;
}

interface RemoveConditionResults {
  updatedFields: Record<string, FieldTypes.Field>;
  updatedConditions: Record<string, FieldConditionTypes.FormCondition>;
}

/**
 * Used to remove a condition from a field.
 */
export function removeConditionById({
  sourceFieldId,
  conditionId,
  fields,
  conditions,
}: RemoveConditionByIdPayload): RemoveConditionResults {
  const conditionToRemove = conditions[conditionId];
  if (!conditionToRemove) {
    return {
      updatedFields: fields,
      updatedConditions: conditions,
    };
  }

  const updatedConditions = { ...conditions };
  const updatedFields = { ...fields };

  // Remove condition ID from the source field
  const fieldUpate = { ...fields[sourceFieldId] };
  fieldUpate.conditionIds = (fieldUpate.conditionIds || []).filter((id) => id !== conditionId);

  // Revert the effects of the condition on the target fields
  const actionsInCondition = conditionToRemove.actions || [];
  for (const action of actionsInCondition) {
    const targetFieldId = action.target;
    if (!targetFieldId) {
      continue;
    }

    const targetField = updatedFields[targetFieldId];
    if (!targetField) {
      continue;
    }

    updatedFields[targetFieldId] = removeConditionEffectsFromField(targetField);
  }

  // Remove the condition
  delete updatedConditions[conditionId];

  return {
    updatedFields: {
      ...updatedFields,
      [sourceFieldId]: fieldUpate,
    },
    updatedConditions,
  };
}

interface RemoveConditionPayload {
  sourceFieldId: string;
  conditionIds: string[];
  fields: Record<string, FieldTypes.Field>;
  conditions: Record<string, FieldConditionTypes.FormCondition>;
}

/**
 * Used to remove multiple conditions from a field.
 */
export function removeConditions({
  sourceFieldId,
  conditionIds,
  fields,
  conditions,
}: RemoveConditionPayload): RemoveConditionResults {
  let updatedConditions = { ...conditions };
  let updatedFields = { ...fields };

  for (const conditionId of conditionIds) {
    const result = removeConditionById({
      sourceFieldId,
      conditionId,
      fields: updatedFields,
      conditions: updatedConditions,
    });

    updatedFields = result.updatedFields;
    updatedConditions = result.updatedConditions;
  }

  return {
    updatedFields,
    updatedConditions,
  };
}

/**
 * Used to remove a target field from a condition.
 */
export function removeTargetIdFromCondition(
  condition: FieldConditionTypes.FormCondition,
  targetId: string
): FieldConditionTypes.FormCondition {
  const updatedCondition = { ...condition };
  updatedCondition.actions = updatedCondition.actions.filter((action) => action.target !== targetId);

  return updatedCondition;
}

interface RemoveTargetIdFromConditionPayload {
  conditions: Record<string, FieldConditionTypes.FormCondition>;
  targetId: string;
}

interface RemoveTargetIdFromConditionResults {
  updatedConditions: Record<string, FieldConditionTypes.FormCondition>;
}

/**
 * Used to remove a target field from multiple conditions.
 */
export function removeTargetIdFromConditions({
  conditions,
  targetId,
}: RemoveTargetIdFromConditionPayload): RemoveTargetIdFromConditionResults {
  const updatedConditions = { ...conditions };
  for (const conditionId in updatedConditions) {
    updatedConditions[conditionId] = removeTargetIdFromCondition(updatedConditions[conditionId], targetId);
  }

  return {
    updatedConditions,
  };
}
