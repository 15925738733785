import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { ClockIcon, DropdownField, useControlledField } from '@frontend/design-system';
import { useTimePeriodFilterLabels } from '../../hooks/use-time-period-filter-label';
import { DateRange, defaultDateRangeMap, getMatchingDateRangeKey } from '../../utils';

type PeriodFilter =
  | 'today'
  | 'yesterday'
  | 'last-7-days'
  | 'last-14-days'
  | 'last-30-days'
  | 'last-60-days'
  | 'last-90-days'
  | 'last-1-year';

export type DatesMap = Record<PeriodFilter | string, DateRange>;

export type TimePeriodChange<T> = (args: { endDate: string; startDate: string; selectedPeriod?: T }) => void;

type Group = {
  label?: string;
  period: (PeriodFilter | string)[];
};

export type CustomPeriodGroups = {
  // datesMap is a record of {[period]: [startDate, endDate]}
  datesMap: DatesMap;
  // groups is an array of groups of periods
  groups: Group[];
  // labels is a record of {[period]: label} pairs
  labels: Record<string, string>;
};

type Props<T> = {
  controlledSelectedPeriod?: T | string;
  customPeriodGroups?: CustomPeriodGroups;
  defaultPeriod?: PeriodFilter;
  disabled?: boolean;
  endDate?: string;
  onChange: TimePeriodChange<T>;
  startDate?: string;
  trackingId?: string;
};

const genericDays: PeriodFilter[] = [
  'today',
  'yesterday',
  'last-7-days',
  'last-14-days',
  'last-30-days',
  'last-60-days',
  'last-90-days',
  'last-1-year',
];

type Any = any; // Just getting the linter off my back

export const TimePeriodSelector = <T extends Any>({
  controlledSelectedPeriod,
  customPeriodGroups,
  defaultPeriod = 'last-14-days',
  disabled,
  endDate,
  onChange,
  startDate,
  trackingId = 'time-selector',
}: Props<T>) => {
  const { t } = useTranslation('analytics');
  const filterLabels = useTimePeriodFilterLabels();
  const [selectedPeriod, setSelectedPeriod] = useState<PeriodFilter | string | undefined>(
    startDate || endDate ? undefined : defaultPeriod
  );
  const groups: Group[] = customPeriodGroups?.groups || [
    {
      period: genericDays,
    },
  ];

  const datesMap: DatesMap = customPeriodGroups?.datesMap || defaultDateRangeMap;

  const daysFieldProps = useControlledField({
    onChange: (value) => {
      setSelectedPeriod(value);
    },
    type: 'dropdown',
    value: selectedPeriod,
  });

  const labels: Record<PeriodFilter | string, string> = customPeriodGroups?.labels ?? filterLabels;

  const handleChange = () => {
    if (selectedPeriod && datesMap[selectedPeriod]) {
      const { startDate, endDate } = datesMap[selectedPeriod];
      onChange({
        endDate,
        selectedPeriod: selectedPeriod as T,
        startDate,
      });
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      // In certain scenarios, two date ranges might coincide, such as the past today and the upcoming today.
      // In such instances, the selected period could unintentionally be overridden by the first matching date object in the datesMap.
      // To mitigate this issue, controlledSelectedPeriod can be employed by managing a controlled state outside of this component.
      if (controlledSelectedPeriod) {
        setSelectedPeriod(controlledSelectedPeriod as string);
      } else {
        setSelectedPeriod(getMatchingDateRangeKey({ datesMap, endDate, startDate }));
      }
    }
  }, [startDate, endDate]);

  useEffect(handleChange, [selectedPeriod]);

  return (
    <DropdownField
      {...daysFieldProps}
      css={styles}
      data-trackingid={trackingId}
      disabled={disabled}
      icon={ClockIcon}
      label={t('Select Time Period')}
      name='days'
      placeholder={t('Select Time Period')}
    >
      {groups.map(({ label, period }) => {
        if (label) {
          // Groupped options
          return (
            <DropdownField.OptionGroup key={label} label={label}>
              {period.map((period) => (
                <DropdownField.Option key={period} data-trackingid={`${trackingId}-${period}`} value={period}>
                  {labels[period] || period}
                </DropdownField.Option>
              ))}
            </DropdownField.OptionGroup>
          );
        } else {
          // Ungroupped options
          return period.map((period) => (
            <DropdownField.Option key={period} data-trackingid={`${trackingId}-${period}`} value={period}>
              {labels[period] || period}
            </DropdownField.Option>
          ));
        }
      })}
    </DropdownField>
  );
};

const styles = css`
  font-size: ${theme.fontSize(14)};
  min-width: 200px;
`;
