import { ReactNode, useLayoutEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { BackIcon, IconButton, Modal, ModalControlModalProps, Stepper } from '@frontend/design-system';
import { ModalStep } from './modal-step';
import { MultiStepModalContext } from './use-multi-step-modal';

type StepStatus = 'completed' | 'active' | 'inactive' | 'currActive';

type StepperInfo = { id: string; title: string };

interface MultiStepModalProps {
  modalProps: ModalControlModalProps;
  closeModal: () => void;
  children: ReactNode;
  initialStep: string;
  step?: string;
  startFromInitial?: boolean;
  maxWidth?: number;
  stepper?: StepperInfo[];
  hideBackButton?: boolean;
}

export const hrStyle = (color: string = theme.colors.neutral10) => css`
  width: auto;
  border: 0;
  border-top: 1px solid ${color};
  margin-top: 0;
  margin-bottom: 0;
`;
const styles = {
  modal: (stepper: StepperInfo[] | undefined) => css`
    width: 100%;
    ${stepper &&
    `display: flex;
    flex-direction: row;`}
    @media (min-width: ${breakpoints.small.min}px) {
      width: 100%;
    }
  `,
  modalContent: (stepper: StepperInfo[] | undefined) => css`
    flex: 1;
    ${stepper &&
    `border-left: 1px solid ${theme.colors.neutral60}; 
    padding-top: ${theme.spacing(3)}; 
    padding-bottom: ${theme.spacing(3)};
    margin-top: ${theme.spacing(-3)};
    margin-bottom: ${theme.spacing(-3)};`}
    overflow-y: auto;
  `,
  stepper: css`
    margin-left: ${theme.spacing(4)};
    margin-top: ${theme.spacing(-1)};
  `,
  stepperCard: css`
    border: none;
    margin-left: ${theme.spacing(2)};
    padding: ${theme.spacing(1)};
    padding-left: ${theme.spacing(2)};
    align-items: center;
    display: flex;
  `,
  stepperTitle: css`
    & h2.step-content-title {
      font-size: ${theme.font.size.large};
      width: 100%;
      white-space: normal;
      overflow: visible;
      height: auto;
    }
  `,
  modalHeaderContainer: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(2, 1)};
    margin: ${theme.spacing(3, 3)};
    margin-top: 0px;
  `,
  modalHeader: css`
    display: flex;
    justify-content: space-between;
    flex: 1;
    margin-right: ${theme.spacing(-3)};
    margin-left: ${theme.spacing(-3)};
  `,
  backIcon: css`
    flex-grow: 0;
    padding: 0;
  `,
  hrStyle: css`
    width: auto;
    border: 0;
    border-top: 1px solid ${theme.colors.neutral20};
    margin-top: 0;
    margin-bottom: 0;
    margin: ${theme.spacing(0, 0, 2, 0)};
  `,
};

export const MultiStepModal = ({
  modalProps,
  closeModal,
  children,
  initialStep,
  startFromInitial = true,
  maxWidth = parseInt(theme.spacing(70)),
  stepper,
  hideBackButton = false,
  ...rest
}: MultiStepModalProps) => {
  const [activeStep, setActiveStep] = useState(initialStep);
  const [title, setTitle] = useState('');
  const [steps, setSteps] = useState([initialStep]);
  const [disableCloseOnOverlayClick, setDisableCloseOnOverlayClick] = useState(false);
  const [disableCloseOnEscape, setDisableCloseOnEscape] = useState(false);
  const [hideHeader, setHideHeader] = useState(false);

  const { show } = modalProps;

  const goToStep = (step: string) => {
    setActiveStep(step);
    setSteps([...steps, step]);
  };

  const resetSteps = (stepToResetTo: string = initialStep) => {
    if (typeof stepToResetTo !== 'string') stepToResetTo = initialStep;
    setActiveStep(stepToResetTo);
    setSteps([stepToResetTo]);
  };

  useLayoutEffect(() => {
    if (show && startFromInitial) {
      setActiveStep(initialStep);
      setSteps([initialStep]);
    }
  }, [show, startFromInitial, initialStep]);

  const goBack = () => {
    const newSteps = steps.slice(0, -1);
    const prevStep = newSteps[newSteps.length - 1];
    setSteps(newSteps);
    setActiveStep(prevStep);
  };

  const value = useMemo(
    () => ({
      activeStep,
      goToStep,
      title,
      setTitle,
      steps,
      setSteps,
      goBack,
      closeModal,
      resetSteps,
      disableCloseOnOverlayClick,
      setDisableCloseOnOverlayClick,
      disableCloseOnEscape,
      setDisableCloseOnEscape,
      setHideHeader,
    }),
    [activeStep, title, steps, disableCloseOnOverlayClick, disableCloseOnEscape, hideHeader]
  );

  const uniqueStepperSteps = useMemo(
    () =>
      stepper?.reduce((final, step) => {
        return { ...final, [step.title]: [...(final[step.title] ?? []), step.id] };
      }, {} as Record<string, string[]>),
    [stepper]
  );

  const isCompletedStep = (stepTitle: string) =>
    !!uniqueStepperSteps &&
    uniqueStepperSteps[stepTitle].some((stepId) => steps.includes(stepId)) &&
    !uniqueStepperSteps[stepTitle].includes(steps[steps.length - 1]);

  const isActiveStep = (stepTitle: string) =>
    !!uniqueStepperSteps && uniqueStepperSteps[stepTitle].includes(steps[steps.length - 1]);

  const stepStatus = useMemo(
    () =>
      Object.keys(uniqueStepperSteps ?? {}).reduce(
        (final, stepTitle, index) =>
          ({
            ...final,
            [index + 1]: isCompletedStep(stepTitle) ? 'completed' : isActiveStep(stepTitle) ? 'active' : 'inactive',
          } as Record<number, StepStatus>),
        {} as Record<number, StepStatus>
      ),
    [uniqueStepperSteps, steps]
  );

  return (
    <MultiStepModalContext.Provider value={value}>
      <Modal
        {...modalProps}
        maxWidth={maxWidth}
        css={styles.modal(stepper)}
        disableCloseOnOverlayClick={disableCloseOnOverlayClick}
        disableCloseOnEscape={disableCloseOnEscape}
        {...rest}
      >
        {stepper && uniqueStepperSteps && (
          <Stepper css={styles.stepper} maxWidth={parseFloat(theme.spacing(17.5))} stepStatus={stepStatus}>
            {Object.keys(uniqueStepperSteps).map((stepTitle) => (
              <Stepper.Card css={styles.stepperCard} key={stepTitle} preventDefaultOnClick>
                <Stepper.Title css={styles.stepperTitle}>{stepTitle}</Stepper.Title>
              </Stepper.Card>
            ))}
          </Stepper>
        )}
        {stepper ? (
          <div css={styles.modalContent(stepper)}>
            {!hideHeader && (
              <MultiStepModalHeader
                hideBackButton={hideBackButton}
                steps={steps}
                goBack={goBack}
                title={title}
                closeModal={closeModal}
              />
            )}
            {children}
          </div>
        ) : (
          <>
            {!hideHeader && (
              <MultiStepModalHeader
                hideBackButton={hideBackButton}
                steps={steps}
                goBack={goBack}
                title={title}
                closeModal={closeModal}
              />
            )}
            {children}
          </>
        )}
      </Modal>
    </MultiStepModalContext.Provider>
  );
};

interface MultiStepModalHeaderProps {
  hideBackButton?: boolean;
  steps: string[];
  goBack: () => void;
  title: string;
  closeModal: () => void;
}

const MultiStepModalHeader = ({ hideBackButton, steps, goBack, title, closeModal }: MultiStepModalHeaderProps) => {
  return (
    <div css={styles.modalHeaderContainer}>
      {!hideBackButton && steps.length > 1 && (
        <IconButton onClick={goBack} label='' css={styles.backIcon}>
          <BackIcon />
        </IconButton>
      )}
      <Modal.Header css={styles.modalHeader} onClose={closeModal}>
        {title}
      </Modal.Header>
    </div>
  );
};

MultiStepModal.Step = ModalStep;
