import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const listContainerStyle = css`
  display: flex;
  flex-direction: column;
  width: 400px;

  header {
    display: flex;
    align-items: center;
    padding: ${theme.spacing(2, 2, 1)};
    border-bottom: 1px solid ${theme.colors.neutral20};
    justify-content: space-between;
    gap: ${theme.spacing(2)};
  }
`;
