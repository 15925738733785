import { SchedulerV3API, SchedulerV3QueryKeys, SchedulerV3Types } from '@frontend/api-scheduler-v3';
import { useLocalizedQuery } from '@frontend/location-helpers';

type UseGetOperatoryV3DataParamsType = {
  isMultiLocation: boolean;
  selectedLocationId: string;
  selectedLocationIds: string[];
  parentLocationId: string;
  debouncedSearchValue?: string;
  pageConfig?: SchedulerV3Types.PageConfig;
  isEnabled?: boolean;
};

export const useGetOperatoryV3Data = ({
  isMultiLocation,
  selectedLocationId,
  debouncedSearchValue,
  pageConfig,
  selectedLocationIds,
  parentLocationId,
  isEnabled = true,
}: UseGetOperatoryV3DataParamsType) => {
  const isMultiLocationApiCall = !!parentLocationId && isMultiLocation;

  const {
    data: operatoriesSingleLocationData,
    isLoading: isLoadingOperatoriesData,
    refetch: refetchOperatories,
    isFetching: isFetchingOperatoriesData,
  } = useLocalizedQuery({
    queryKey: SchedulerV3QueryKeys.OperatoriesQueryKeys.getOperatoriesList(
      selectedLocationId,
      pageConfig?.page.toString(),
      pageConfig?.limit.toString(),
      debouncedSearchValue
    ),

    queryFn: () =>
      SchedulerV3API.listOperatories({
        ...(pageConfig?.limit && { limit: pageConfig?.limit }),
        ...(pageConfig?.page && { page: pageConfig?.page }),
        ...(debouncedSearchValue && { name: debouncedSearchValue }),
        locationId: selectedLocationId,
      }),
    enabled: !isMultiLocationApiCall && isEnabled,
  });

  const {
    data: operatoriesMultiData,
    isLoading: isLoadingOperatoriesMultiData,
    refetch: refetchOperatoriesMultiTypes,
    isFetching: isFetchingOperatoriesMultiData,
  } = useLocalizedQuery({
    queryKey: SchedulerV3QueryKeys.OperatoriesQueryKeys.getOperatoriesListForMultiLocation(
      selectedLocationIds || [selectedLocationId],
      parentLocationId || '',
      pageConfig?.page.toString(),
      pageConfig?.limit.toString(),
      debouncedSearchValue
    ),

    queryFn: () =>
      SchedulerV3API.listOperatoriesForMultiLocation({
        ...(pageConfig?.limit && { limit: pageConfig?.limit }),
        ...(pageConfig?.page && { page: pageConfig?.page }),
        ...(debouncedSearchValue && { name: debouncedSearchValue }),
        locationsIds: selectedLocationIds || [selectedLocationId],
        parentLocationId: parentLocationId || '',
      }),
    enabled: isMultiLocationApiCall && isEnabled,
  });

  if (isMultiLocationApiCall) {
    return {
      data: operatoriesMultiData,
      isLoading: isLoadingOperatoriesMultiData,
      refetch: refetchOperatoriesMultiTypes,
      isFetching: isFetchingOperatoriesData || isFetchingOperatoriesMultiData,
    };
  }

  return {
    data: operatoriesSingleLocationData,
    isLoading: isLoadingOperatoriesData,
    refetch: refetchOperatories,
    isFetching: isFetchingOperatoriesData || isFetchingOperatoriesMultiData,
  };
};
