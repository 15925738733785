import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Contact } from '@weave/schema-gen-ts/dist/schemas/fax/v1/fax.pb';
import { FaxApi, FaxHooks, FaxQueries, FaxTypes } from '@frontend/api-fax';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useSlidePanelShallowStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import {
  ButtonBar,
  ContentLoader,
  FormRow,
  Heading,
  IconButton,
  PhoneField,
  PrimaryButton,
  SecondaryButton,
  TextField,
  useAlert,
  useForm,
  useModalControl,
} from '@frontend/design-system';
import { ContactImage } from './contact-image';
import { DeleteContact } from './delete-contact';

export const FaxContactFormPanel = () => {
  const { t } = useTranslation('fax');
  const alert = useAlert();
  const invalidateFaxInboxList = FaxHooks.useInvalidateFaxInboxList();
  const { modalProps: deleteModalProps, openModal: openDeleteModal } = useModalControl();
  const [profileMediaId, setProfileMediaId] = useState('');
  const [userProfile, setUserProfile] = useState<File>();
  const { setShow, context } = useSlidePanelShallowStore<{
    contact?: Contact;
    action: FaxTypes.ContactAction;
    contactId?: string;
    locationId?: string;
  }>('setShow', 'context');
  const invalidateFaxContact = FaxHooks.useInvalidateFaxContact();

  const { data: contactInfo } = FaxQueries.useGetFaxContact(context?.contactId ?? '', context?.locationId ?? '', {
    enabled: !!context?.contactId && !!context?.locationId,
  });

  const currentMediaId = (context?.contact?.profileMediaId || contactInfo?.profileMediaId) ?? '';

  const { mutate: upsertContact, isLoading } = FaxQueries.useUpsertFaxContact({
    onSuccess: () => {
      if (context?.action === FaxTypes.ContactAction.ADD) {
        alert.success(t('Contact Created'));
      } else {
        alert.success(t('Contact Updated'));
      }
      setShow(false);
      invalidateFaxContact((context?.contact?.id || context?.contactId) ?? '');
      invalidateFaxInboxList();
    },
    onError: () => {
      if (context?.action === FaxTypes.ContactAction.ADD) {
        alert.error(t('Failed to Create Contact'));
      } else {
        alert.error(t('Failed to Update Contact'));
      }
    },
  });

  const { getFieldProps, formProps, seedValues, reset, isComplete } = useForm({
    fields: {
      name: { type: 'text' },
      secondName: { type: 'text' },
      faxNumber: { required: true, type: 'phone' },
      phoneNumber: { type: 'phone' },
    },
    onSubmit: async (values) => {
      let mediaId = profileMediaId ?? '';
      if (userProfile) {
        try {
          const res = await FaxApi.UploadContactMedia(userProfile, context?.locationId ?? '');
          mediaId = res?.mediaId || mediaId;
          setProfileMediaId(mediaId);
        } catch (error) {
          console.error('Error uploading contact media:', error);
        }
      }
      const contactData = {
        name: values.name ?? '',
        secondName: values.secondName ?? '',
        faxNumber: values.faxNumber ?? '',
        phoneNumber: values.phoneNumber ?? '',
        profileMediaId: mediaId,
        locationId: context?.locationId ?? '',
      };

      upsertContact({
        id: (context?.contact?.id || context?.contactId) ?? '',
        ...contactData,
      });
    },
  });

  useEffect(() => {
    reset();
    seedValues({
      name: (context?.contact?.name || contactInfo?.name) ?? '',
      secondName: (context?.contact?.secondName || contactInfo?.secondName) ?? '',
      faxNumber: (context?.contact?.faxNumber || contactInfo?.faxNumber) ?? '',
      phoneNumber: (context?.contact?.phoneNumber || contactInfo?.phoneNumber) ?? '',
    });
  }, [context, contactInfo]);

  if (isLoading) {
    return (
      <ContentLoader
        message={context?.action === FaxTypes.ContactAction.ADD ? t('Adding Contact') : t('Updating Contact')}
        show
      />
    );
  }

  return (
    <div css={panelStyles}>
      <div css={panelHeaderStyle}>
        <Heading level={2}>
          {context?.action === FaxTypes.ContactAction.ADD ? t('Add Contact') : t('Edit Contact')}
        </Heading>
        <IconButton label='Close' className='close-btn' onClick={() => setShow(false)}>
          <Icon name='x' />
        </IconButton>
      </div>
      <form {...formProps}>
        <ContactImage
          setUserProfile={setUserProfile}
          currentMediaId={context?.action === FaxTypes.ContactAction.ADD ? '' : currentMediaId}
          locationId={context?.locationId ?? ''}
        />
        <FormRow>
          <TextField {...getFieldProps('name')} label={t('Name')} />
        </FormRow>
        <FormRow>
          <TextField {...getFieldProps('secondName')} label={t('Second Name')} />
        </FormRow>
        <FormRow>
          <PhoneField {...getFieldProps('faxNumber')} label={t('Fax Number')} />
        </FormRow>
        {/* <FormRow> */}
        {/*   <PhoneField {...getFieldProps('phoneNumber')} label={t('Phone Number')} /> */}
        {/* </FormRow> */}
        <div className='form-data'>
          <ButtonBar>
            {context?.action === FaxTypes.ContactAction.EDIT && (
              <SecondaryButton onClick={openDeleteModal}>{t('Delete')}</SecondaryButton>
            )}
            <PrimaryButton type='submit' disabled={!isComplete}>
              {t('Save Contact')}
            </PrimaryButton>
          </ButtonBar>
        </div>
      </form>
      <DeleteContact
        modalProps={deleteModalProps}
        contactIds={[(context?.contact?.id || context?.contactId) ?? '']}
        locationId={context?.locationId}
      />
    </div>
  );
};

export const panelStyles = css`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: ${theme.spacing(2)};
`;

export const panelHeaderStyle = css`
  display: flex;
  align-items: center;
  padding: ${theme.spacing(1, 2)};
  border-bottom: 1px solid ${theme.colors.neutral20};

  .close-btn {
    margin-left: auto;
  }
`;
