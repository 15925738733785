import { FC, useEffect, useState } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { BuilderTypes } from '@frontend/forms-builder-engine';
import { useTranslation } from '@frontend/i18n';
import { Accordion, Text } from '@frontend/design-system';
import { DroppableType } from '../../../../constants';
import { BuilderFieldDivisionTypes } from '../../../../types';
import { getOtherFieldDraggableId, getPrimaryFieldDraggableId, getFieldCategoryDroppableId } from '../../../../utils';
import DraggableField from '../draggable-field/draggable-field.component';
import { builderFieldsStyle, textStyle } from './categories-layout.styles';

interface PrimaryFieldCategoriesProps {
  categories: BuilderFieldDivisionTypes.PrimaryFieldsDivisionCategory[];
  fields: BuilderTypes.BuilderFormElementTypes.PrimaryFields.ParsedFields;
  type: 'primary';
  usedFields: BuilderTypes.BuilderFormElementTypes.PrimaryFields.UsedPrimaryFields;
}

interface OtherFieldCategoriesProps {
  categories: BuilderFieldDivisionTypes.OtherFieldsDivisionCategory[];
  fields: BuilderTypes.BuilderFormElementTypes.OtherFields.CombinedOtherFields;
  type: 'other';
}

type CategoriesLayoutProps = PrimaryFieldCategoriesProps | OtherFieldCategoriesProps;

const CategoriesLayout: FC<CategoriesLayoutProps> = (props) => {
  const { t } = useTranslation('forms');
  const [isEmpty, setIsEmpty] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    for (const category of props.categories) {
      if (category.fieldsOrder.length > 0) {
        setIsEmpty(false);
        setIsReady(true);
        return;
      }
    }

    setIsEmpty(true);
    setIsReady(true);
  }, [props.categories]);

  if (!isReady) {
    return null;
  }

  if (isEmpty) {
    return (
      <Text textAlign='center' color='subdued' css={textStyle}>
        {t('No matching results found!')}
      </Text>
    );
  }

  return (
    <>
      {props.categories.map((category) => {
        if (category.fieldsOrder.length === 0) {
          return null;
        }

        return (
          <Accordion.Item value={category.label} key={category.label}>
            <Accordion.Header title={category.label} />
            <Accordion.Body>
              <Droppable
                droppableId={getFieldCategoryDroppableId(category.label)}
                isDropDisabled
                type={DroppableType.FIELD}
              >
                {(provided) => (
                  <ul css={builderFieldsStyle} {...provided.droppableProps} ref={provided.innerRef}>
                    {category.fieldsOrder.map((field, fieldOrderIndex) => {
                      let displayName = '';
                      let draggableId = '';
                      let isAlreadyUsed = false;
                      const isSyncable = field.syncable || false;

                      if (props.type === 'primary') {
                        const fieldKey =
                          field.key as keyof BuilderTypes.BuilderFormElementTypes.PrimaryFields.ParsedFields;
                        const fieldData = props.fields[fieldKey];
                        displayName = fieldData.meta.displayName;
                        draggableId = getPrimaryFieldDraggableId(fieldKey);
                        isAlreadyUsed = !!props.usedFields[fieldKey];
                      } else {
                        const fieldKey =
                          field.key as keyof BuilderTypes.BuilderFormElementTypes.OtherFields.CombinedOtherFields;
                        const fieldData = props.fields[fieldKey];
                        displayName = fieldData.meta.displayName;
                        draggableId = getOtherFieldDraggableId(fieldKey);
                      }

                      const isSubdued = !isSyncable;

                      return (
                        <DraggableField
                          displayName={displayName}
                          index={fieldOrderIndex}
                          draggableId={draggableId}
                          key={field.key}
                          appearance={isAlreadyUsed ? 'disabled' : isSubdued ? 'subdued' : 'default'}
                        />
                      );
                    })}
                  </ul>
                )}
              </Droppable>
            </Accordion.Body>
          </Accordion.Item>
        );
      })}
    </>
  );
};

export default CategoriesLayout;
