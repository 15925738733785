import { StreamUser } from '../types';

interface GenerateStreamUserParams {
  orgId: string;
  userId: string;
}

export const getTeamId = (orgId: string) => `ORG_ID_${orgId}`;

export const getStreamUserId = ({ orgId, userId }: GenerateStreamUserParams) => `ORG_ID_${orgId}_${userId}`;

export const getFullName = (user: StreamUser) => `${user.firstName} ${user.lastName}`;

export const getFirstAndLastName = (name: string) => {
  // Just in case to handle bad data
  if (!name || !name.includes(' ')) {
    return { firstName: name ?? '', lastName: '' };
  }

  const [firstName, lastName] = name.split(' ');
  return { firstName, lastName };
};
