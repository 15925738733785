import { createProvider } from '@frontend/store';
import { FieldConditionConstants } from '../constants';
import { FieldTypes, SectionTypes, FieldConditionTypes, BuilderFormElementTypes } from '../types';
import { genUID, FieldUtils, ConditionUtils } from '../utils';
import { BuilderEngineState, BuilderEngineStore } from './types';

const RESET_TIMEOUT = 100;
const DEFAULT_BUILDER_STATE: BuilderEngineState = {
  assetId: '',
  formId: '',
  formTitle: '',
  sharedLocationIds: [],
  sectionOrder: [],
  sections: {},
  invalidSectionIds: {},
  fields: {},
  orderedFields: [],
  conditions: {},
  invalidConditions: {},
  triggerChangeDetectionForConditions: false,
};

export const { Provider: BuilderEngineStoreProvider, useStore: useBuilderEngineStore } =
  createProvider<BuilderEngineStore>()((set, get) => ({
    ...DEFAULT_BUILDER_STATE,

    initialize: (payload, reset = true) => {
      if (reset) {
        set({
          ...DEFAULT_BUILDER_STATE,
          ...payload,
        });
      }

      set(() => payload);
    },

    resetBuilderEngineState: () => set(DEFAULT_BUILDER_STATE),
    setAssetId: (assetId) => set(() => ({ assetId })),
    setFormId: (formId) => set(() => ({ formId })),
    setFormTitle: (title) => set(() => ({ formTitle: title })),

    setSharedLocationIds: (value) => set(() => ({ sharedLocationIds: [...value] })),
    toggleSharedLocationId: (locationId) =>
      set((state) => {
        const index = state.sharedLocationIds.indexOf(locationId);
        if (index === -1) {
          return {
            sharedLocationIds: [...state.sharedLocationIds, locationId],
          };
        }

        const newSharedLocationIds = [...state.sharedLocationIds];
        newSharedLocationIds.splice(index, 1);

        return {
          sharedLocationIds: newSharedLocationIds,
        };
      }),

    // ============ Section Actions ============

    createSection: (title = '') =>
      set((state) => {
        const newSectionId = genUID();

        return {
          sectionOrder: [...state.sectionOrder, newSectionId],
          sections: {
            ...state.sections,
            [newSectionId]: {
              id: newSectionId,
              title,
              fields: [],
            },
          },
        };
      }),

    deleteSection: (sectionId, index) => {
      const removedPrimaryFieldKeys: BuilderFormElementTypes.PrimaryFields.PrimaryFieldKey[] = [];
      let removedSectionTemplateKey: BuilderFormElementTypes.SectionTemplates.TemplateKey | undefined = undefined;

      set((state) => {
        const newSectionOrder = index
          ? state.sectionOrder.splice(index, 1)
          : state.sectionOrder.filter((id) => id !== sectionId);

        const newSections = { ...state.sections };
        let newFields = { ...state.fields };
        let newConditions = { ...state.conditions };
        const sectionToDelete = newSections[sectionId];

        if (sectionToDelete.sectionTemplateKey) {
          removedSectionTemplateKey = sectionToDelete.sectionTemplateKey;
        }

        sectionToDelete.fields.forEach((fieldId) => {
          const primaryFieldKey = state.fields[fieldId].meta.dataKey;

          if (primaryFieldKey) {
            // Collect primary field keys that were removed from the section.
            removedPrimaryFieldKeys.push(primaryFieldKey);
          }

          const fieldToRemove = newFields[fieldId];
          const conditionIdsToRemove = [...(fieldToRemove.conditionIds || [])];

          // Remove all the conditions associated with the field.
          const sourceConditionsResult = ConditionUtils.removeConditions({
            sourceFieldId: fieldId,
            conditionIds: conditionIdsToRemove,
            fields: newFields,
            conditions: newConditions,
          });

          newFields = sourceConditionsResult.updatedFields;
          newConditions = sourceConditionsResult.updatedConditions;

          // Update conditions that target this field
          const targetConditionsResult = ConditionUtils.removeTargetIdFromConditions({
            conditions: newConditions,
            targetId: fieldId,
          });

          newConditions = targetConditionsResult.updatedConditions;

          // Remove field from fields
          delete newFields[fieldId];
        });

        // Remove section
        delete newSections[sectionId];

        return {
          sectionOrder: newSectionOrder,
          sections: newSections,
          fields: newFields,
          conditions: newConditions,
          triggerChangeDetectionForConditions: true,
        };
      });

      // Reset the triggerChangeDetectionForConditions flag after a short delay.
      setTimeout(() => {
        set(() => ({
          triggerChangeDetectionForConditions: false,
        }));
      }, RESET_TIMEOUT);

      return {
        removedPrimaryFieldKeys,
        removedSectionTemplateKey,
      };
    },

    updateSection: (section) =>
      set((state) => ({
        sections: {
          ...state.sections,
          [section.id]: section,
        },
      })),

    updateSectionTitle: (sectionId, title) =>
      set((state) => {
        const section = state.sections[sectionId];

        return {
          sections: {
            ...state.sections,
            [sectionId]: {
              ...section,
              title,
            },
          },
        };
      }),

    cloneSection: (sectionId: string) => {
      const newSectionId = genUID();

      set((state) => {
        const fieldIdsForNewSection: string[] = [];
        let newFields = { ...state.fields };
        const section = state.sections[sectionId];

        for (const fieldId of section.fields) {
          const field = state.fields[fieldId];

          if (field.meta.dataKey) {
            // We don't want to clone the primary fields.
            continue;
          }

          const newFieldId = genUID();
          fieldIdsForNewSection.push(newFieldId);

          newFields = {
            ...newFields,
            [newFieldId]: {
              ...field,
              id: newFieldId,
              conditionIds: [], // Conditions should not be copied.
            },
          };
        }

        const newSection: SectionTypes.Section = {
          id: newSectionId,
          fields: fieldIdsForNewSection,
          title: `${state.sections[sectionId].title} (copy)`,
        };
        const newSections = { ...state.sections, [newSectionId]: newSection };
        const newSectionOrder = [...state.sectionOrder, newSectionId];

        return {
          sectionOrder: newSectionOrder,
          sections: newSections,
          fields: newFields,
        };
      });

      return newSectionId;
    },

    moveSection: (sectionId: string, direction: 'up' | 'down') =>
      set((state) => {
        const sectionOrder = [...state.sectionOrder];
        const currentIndex = sectionOrder.indexOf(sectionId);

        if (direction === 'up') {
          if (currentIndex === 0) {
            return state;
          }

          sectionOrder.splice(currentIndex, 1);
          sectionOrder.splice(currentIndex - 1, 0, sectionId);
        } else {
          if (currentIndex === sectionOrder.length - 1) {
            return state;
          }

          sectionOrder.splice(currentIndex, 1);
          sectionOrder.splice(currentIndex + 1, 0, sectionId);
        }

        return {
          sectionOrder,
        };
      }),

    isSectionCloneable: (sectionId: string) => {
      const section = get().sections[sectionId];
      return section.fields.length > 0;
    },

    addSectionTemplate: ({ fields, placementIndex, title, sectionTemplateKey }) => {
      const fieldsToAdd = fields.map(FieldUtils.generateFieldToAdd);
      const newSectionId = genUID();
      const newSection: SectionTypes.Section = {
        id: newSectionId,
        fields: fieldsToAdd.map((field) => field.id),
        title,
        sectionTemplateKey,
      };

      set((state) => {
        const newSections = { ...state.sections, [newSectionId]: newSection };
        const newSectionOrder = [...state.sectionOrder];
        const newConditions = { ...state.conditions };
        newSectionOrder.splice(placementIndex, 0, newSectionId);

        const newFields = fieldsToAdd.reduce(
          (acc, field) => {
            acc[field.id] = field;
            return acc;
          },
          { ...state.fields }
        );

        // Add conditions for the fields in the "allergies" template.
        if (sectionTemplateKey === 'allergies') {
          const mainFieldId = fieldsToAdd[0].id;
          const conditionId = genUID();

          // Create a condition that shows all the other fields when the main field is answered.
          const conditionForAllergies: FieldConditionTypes.FormCondition = {
            id: conditionId,
            operator: 'and',
            terms: [
              {
                field: mainFieldId,
                check: 'equals',
                value: 'Yes',
              },
            ],
            actions: [],
          };

          for (const field of fieldsToAdd) {
            // Add condition ID to the main field
            if (field.id === mainFieldId) {
              field.conditionIds = [conditionId];
              continue;
            }

            // Hide all the fields except the main field
            field.hidden = true;

            // Add all the fields (except the main) to the condition actions
            conditionForAllergies.actions.push({
              operation: 'show',
              target: field.id,
              type: 'fields',
            });
          }

          newConditions[conditionId] = conditionForAllergies;
        }

        return {
          sectionOrder: newSectionOrder,
          sections: newSections,
          fields: newFields,
          conditions: newConditions,
        };
      });

      return newSectionId;
    },

    setInvalidSectionIds: (invalidSectionIds) => set(() => ({ invalidSectionIds })),

    // ============ Field Actions ============

    addField: ({ field, placementIndex, sectionId }) =>
      set((state) => {
        const section = state.sections[sectionId];
        const fieldIdsInSection = [...section.fields];
        const fieldToAdd = FieldUtils.generateFieldToAdd(field);
        const newFieldId = fieldToAdd.id;
        fieldIdsInSection.splice(placementIndex, 0, newFieldId);

        return {
          sections: {
            ...state.sections,
            [sectionId]: {
              ...section,
              fields: fieldIdsInSection,
            },
          },
          fields: {
            ...state.fields,
            [newFieldId]: fieldToAdd,
          },
        };
      }),

    updateFieldTitle: (fieldId, title) =>
      set((state) => {
        const field = state.fields[fieldId];

        return {
          fields: {
            ...state.fields,
            [fieldId]: {
              ...field,
              label: title,
            },
          },
        };
      }),

    markFieldAsRequired: (fieldId, isRequired) =>
      set((state) => {
        const field = state.fields[fieldId];

        return {
          fields: {
            ...state.fields,
            [fieldId]: {
              ...field,
              required: isRequired,
            },
          },
        };
      }),

    addFieldOption: (fieldId) =>
      set((state) => {
        const field = state.fields[fieldId] as FieldTypes.BaseOptionsField;
        const currentFieldOptions = field.options || [];
        const newOptionId = genUID();

        return {
          fields: {
            ...state.fields,
            [fieldId]: {
              ...field,
              options: [
                ...currentFieldOptions,
                {
                  label: '',
                  value: newOptionId,
                },
              ],
            },
          },
        };
      }),

    updateFieldOptionLabel: (fieldId, optionIndex, label) =>
      set((state) => {
        const field = state.fields[fieldId] as FieldTypes.BaseOptionsField;
        const currentFieldOptions = field.options || [];
        const updatedOptions = [...currentFieldOptions];
        updatedOptions[optionIndex] = {
          ...updatedOptions[optionIndex],
          label,
        };

        return {
          fields: {
            ...state.fields,
            [fieldId]: {
              ...field,
              options: updatedOptions,
            },
          },
        };
      }),

    removeFieldOption: (fieldId, optionIndex) =>
      set((state) => {
        const field = state.fields[fieldId] as FieldTypes.BaseOptionsField;
        const currentFieldOptions = field.options || [];
        const updatedOptions = currentFieldOptions.filter((_, index) => index !== optionIndex);

        return {
          fields: {
            ...state.fields,
            [fieldId]: {
              ...field,
              options: updatedOptions,
            },
          },
        };
      }),

    deleteField: (fieldId) => {
      let removedPrimaryFieldKey: string | undefined = undefined;

      set((state) => {
        const field = state.fields[fieldId];
        removedPrimaryFieldKey = field.meta.dataKey;

        const newSections = { ...state.sections };

        for (const sectionId of state.sectionOrder) {
          const section = { ...state.sections[sectionId] };

          if (section.fields.includes(fieldId)) {
            section.fields = section.fields.filter((id) => id !== fieldId);
            newSections[sectionId] = section;
            break;
          }
        }

        let newFields = { ...state.fields };
        let newConditions = { ...state.conditions };
        const fieldToRemove = newFields[fieldId];
        const conditionIdsToRemove = [...(fieldToRemove.conditionIds || [])];

        // Remove all the conditions associated with the field.
        const sourceConditionsResult = ConditionUtils.removeConditions({
          sourceFieldId: fieldId,
          conditionIds: conditionIdsToRemove,
          fields: newFields,
          conditions: newConditions,
        });

        newFields = sourceConditionsResult.updatedFields;
        newConditions = sourceConditionsResult.updatedConditions;

        // Update conditions that target this field
        const targetConditionsResult = ConditionUtils.removeTargetIdFromConditions({
          conditions: newConditions,
          targetId: fieldId,
        });
        newConditions = targetConditionsResult.updatedConditions;

        delete newFields[fieldId];

        return {
          sections: newSections,
          fields: newFields,
          conditions: newConditions,
          triggerChangeDetectionForConditions: true,
        };
      });

      // Reset the triggerChangeDetectionForConditions flag after a short delay.
      setTimeout(() => {
        set(() => ({
          triggerChangeDetectionForConditions: false,
        }));
      }, RESET_TIMEOUT);

      return {
        removedPrimaryFieldKey,
      };
    },

    isFieldCloneable: (fieldId: string) => {
      const field = get().fields[fieldId];
      return !field.meta.dataKey;
    },

    updateRichTextFieldValue: (fieldId, value) =>
      set((state) => {
        const field = state.fields[fieldId] as FieldTypes.BaseField;
        return {
          fields: {
            ...state.fields,
            [fieldId]: {
              ...field,
              value,
            },
          },
        };
      }),

    moveField: ({ fieldId, newSectionId, oldSectionId, placementIndex }) => {
      if (newSectionId === oldSectionId) {
        // If the field is moved within the same section, update the field order.
        set((state) => {
          const section = state.sections[newSectionId];
          const fieldIdsInSection = [...section.fields];
          const fieldIndex = fieldIdsInSection.indexOf(fieldId);
          fieldIdsInSection.splice(fieldIndex, 1);
          fieldIdsInSection.splice(placementIndex, 0, fieldId);

          return {
            sections: {
              ...state.sections,
              [newSectionId]: {
                ...section,
                fields: fieldIdsInSection,
              },
            },
          };
        });

        return;
      }

      // If the field is moved to a different section, remove it from the old section and add it to the new section.
      set((state) => {
        const oldSection = state.sections[oldSectionId];
        const newSection = state.sections[newSectionId];
        const oldFieldIds = [...oldSection.fields];
        const newFieldIds = [...newSection.fields];

        const oldFieldIndex = oldFieldIds.indexOf(fieldId);
        oldFieldIds.splice(oldFieldIndex, 1);

        newFieldIds.splice(placementIndex, 0, fieldId);

        return {
          sections: {
            ...state.sections,
            [oldSectionId]: {
              ...oldSection,
              fields: oldFieldIds,
            },
            [newSectionId]: {
              ...newSection,
              fields: newFieldIds,
            },
          },
        };
      });
    },

    cloneField: (fieldId: string, sectionId: string) => {
      const newFieldId = genUID();

      set((state) => {
        const section = state.sections[sectionId];
        const field = state.fields[fieldId];
        const newField: FieldTypes.Field = {
          ...field,
          id: newFieldId,
          label: `${field.label} (copy)`,
          conditionIds: [], // Conditions should not be copied.
        };

        const newFields = {
          ...state.fields,
          [newFieldId]: newField,
        };

        // Place the cloned field right after the original field.
        const fieldIndex = section.fields.indexOf(fieldId);
        const newFieldsOrder = [...section.fields];
        newFieldsOrder.splice(fieldIndex + 1, 0, newFieldId);

        const newSection = {
          ...section,
          fields: newFieldsOrder,
        };

        return {
          sections: {
            ...state.sections,
            [sectionId]: newSection,
          },
          fields: newFields,
        };
      });

      return newFieldId;
    },

    updateOrderedFields: (orderedFields) =>
      set(() => ({
        orderedFields,
      })),

    // ============ Condition Actions ============

    addCondition: ({ sourceFieldId, targetFieldId }) => {
      const newConditionId = genUID();

      set((state) => {
        const modifiedState = { ...state };
        const newCondition: FieldConditionTypes.FormCondition = {
          id: newConditionId,
          operator: 'and',
          terms: [
            {
              field: sourceFieldId,
              check: 'answered',
              value: '',
            },
          ],
          actions: [],
        };

        if (targetFieldId) {
          newCondition.actions.push({
            operation: FieldConditionConstants.DEFAULT_CONDITION_FOR_TARGETED_FIELDS,
            target: targetFieldId,
            type: 'fields',
          });

          const targetField = state.fields[targetFieldId];
          if (targetField) {
            modifiedState.fields[targetFieldId] = ConditionUtils.modifyFieldBasedOnCondition({
              targetField,
              newFieldCondition: FieldConditionConstants.DEFAULT_CONDITION_FOR_TARGETED_FIELDS,
            });
          }
        }

        return {
          ...modifiedState,
          conditions: {
            ...state.conditions,
            [newConditionId]: newCondition,
          },
          fields: {
            ...modifiedState.fields,
            [sourceFieldId]: {
              ...modifiedState.fields[sourceFieldId],
              conditionIds: [...(state.fields[sourceFieldId].conditionIds || []), newConditionId],
            },
          },
        };
      });

      return newConditionId;
    },

    canAddCondition: () => {
      const state = get();
      const fields = state.fields;
      let numberOfFields = 0;

      for (const _ in fields) {
        numberOfFields += 1;

        if (numberOfFields > 1) {
          return true;
        }
      }

      return false;
    },

    deleteCondition: (conditionId, fieldId) =>
      set((state) => {
        const { updatedConditions, updatedFields } = ConditionUtils.removeConditionById({
          sourceFieldId: fieldId,
          conditionId,
          fields: state.fields,
          conditions: state.conditions,
        });

        return {
          conditions: updatedConditions,
          fields: updatedFields,
        };
      }),

    setInvalidConditions: (invalidConditions) => set(() => ({ invalidConditions })),

    updateSourceFieldCondition: ({ conditionId, value }) =>
      set((state) => {
        const condition = state.conditions[conditionId];
        const updatedCondition = {
          ...condition,
          terms: [
            {
              ...condition.terms[0],
              check: value,
            },
          ],
        };

        return {
          conditions: {
            ...state.conditions,
            [conditionId]: updatedCondition,
          },
        };
      }),

    updateSourceFieldConditionValue: ({ conditionId, value }) =>
      set((state) => {
        const condition = state.conditions[conditionId];
        const updatedCondition = {
          ...condition,
          terms: [
            {
              ...condition.terms[0],
              value,
            },
          ],
        };

        return {
          conditions: {
            ...state.conditions,
            [conditionId]: updatedCondition,
          },
        };
      }),

    updateConditionForTargetedFields: ({ conditionId, value }) =>
      set((state) => {
        const condition = state.conditions[conditionId];
        const modifiedActions = [...condition.actions];
        const modifiedFields = { ...state.fields };

        if (modifiedActions.length === 0) {
          // If there are no actions, create a new action without a target field ID.
          modifiedActions.push({
            operation: value,
            target: '',
            type: 'fields',
          });
        } else {
          for (const action of modifiedActions) {
            action.operation = value;
            const targetFieldId = action.target;
            const targetField = modifiedFields[targetFieldId];

            if (!targetField) {
              continue;
            }

            // Update the target field based on the new condition
            modifiedFields[targetFieldId] = ConditionUtils.modifyFieldBasedOnCondition({
              targetField,
              newFieldCondition: value,
            });
          }
        }

        const modifiedCondition = {
          ...condition,
          actions: modifiedActions,
        };

        return {
          conditions: {
            ...state.conditions,
            [conditionId]: modifiedCondition,
          },
          fields: modifiedFields,
        };
      }),

    updateTargetedFieldsForCondition: ({ conditionId, targetedFieldIds }) => {
      let shouldTriggerChangeDetection = false;

      set((state) => {
        const condition = state.conditions[conditionId];
        const modifiedFields = { ...state.fields };
        let modifiedActions = [...condition.actions];
        const conditionForTargetedFields =
          modifiedActions.length > 0
            ? modifiedActions[0].operation
            : FieldConditionConstants.DEFAULT_CONDITION_FOR_TARGETED_FIELDS;

        if (modifiedActions.length === 0) {
          /**
           * The UI field that tracks the condition for the targeted fields should be
           * updated with the new value that was set as the default in this flow.
           */
          shouldTriggerChangeDetection = true;
        }

        // Revert the effects of the current condition on the target fields
        for (const action of modifiedActions) {
          const targetFieldId = action.target;
          const targetField = modifiedFields[targetFieldId];
          if (!targetField) {
            continue;
          }

          modifiedFields[targetFieldId] = ConditionUtils.removeConditionEffectsFromField(targetField);
        }

        /**
         * `targetedFieldIds` contains the field IDs that should be targeted by the condition.
         * Clear out all the existing target fields and add the new ones with the specified condition
         * for the targeted fields.
         */
        modifiedActions = [];

        for (const targetFieldId of targetedFieldIds) {
          const targetField = modifiedFields[targetFieldId];
          if (!targetField) {
            continue;
          }

          // Update the target field based on the new condition
          modifiedFields[targetFieldId] = ConditionUtils.modifyFieldBasedOnCondition({
            targetField,
            newFieldCondition: conditionForTargetedFields,
          });

          modifiedActions.push({
            operation: conditionForTargetedFields,
            target: targetFieldId,
            type: 'fields',
          });
        }

        if (modifiedActions.length === 0) {
          // If there are no actions, create a new action without a target field ID.
          modifiedActions.push({
            operation: conditionForTargetedFields,
            target: '',
            type: 'fields',
          });
        }

        return {
          conditions: {
            ...state.conditions,
            [conditionId]: {
              ...condition,
              actions: modifiedActions,
            },
          },
          fields: modifiedFields,
          triggerChangeDetectionForConditions: shouldTriggerChangeDetection,
        };
      });

      if (shouldTriggerChangeDetection) {
        // Reset the triggerChangeDetectionForConditions flag after a short delay.
        setTimeout(() => {
          set(() => ({
            triggerChangeDetectionForConditions: false,
          }));
        }, RESET_TIMEOUT);
      }
    },

    getState: () => {
      const state = get();

      return {
        assetId: state.assetId,
        formId: state.formId,
        formTitle: state.formTitle,
        sharedLocationIds: state.sharedLocationIds,
        sectionOrder: state.sectionOrder,
        sections: state.sections,
        fields: state.fields,
        orderedFields: state.orderedFields,
        conditions: state.conditions,
      };
    },
  }));
