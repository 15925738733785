import { FC, useMemo, useRef } from 'react';
import { css } from '@emotion/react';
import { Channel } from 'stream-chat';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { Text, useFormField, TextField, useAlert, useDebouncedValue } from '@frontend/design-system';
import { useTeamChatStore } from '../../../providers';
import { StreamUser } from '../../../types';
import { getFullName } from '../../../utils';
import { ChatAvatar, ButtonWithLoader } from '../../common';

interface AddMembersProps {
  channel?: Channel;
  members: StreamUser[];
  currentUser: StreamUser | null;
}

export const AddMembers: FC<AddMembersProps> = ({ channel, members, currentUser }) => {
  const textFieldProps = useFormField({
    value: '',
    type: 'text',
  });
  const { t } = useTranslation('team-chat');
  const { users } = useTeamChatStore(['users']);
  const { error } = useAlert();
  const memberRef = useRef<Set<string>>(new Set(members.map((member) => member.userID)));

  const searchText = useDebouncedValue(textFieldProps.value);

  const { filteredUsers, memberIDs }: { filteredUsers: StreamUser[]; memberIDs: Set<string> } = useMemo(() => {
    let filteredUsers: StreamUser[];
    const input = searchText.trim();

    // The users are filtered using the members that are already added to the channel
    // When a member is added, we do not want to filter the user out
    // Instead, we want to show the user with an added Text
    if (!input && !memberRef.current.has) {
      filteredUsers = users;
    } else {
      filteredUsers = users.filter((user) => {
        if (memberRef.current.has(user.userID)) {
          return false;
        }
        const fullName = getFullName(user).toLowerCase();
        return fullName.includes(input.toLowerCase());
      });
    }

    const memberIDs: Set<string> = new Set(members.map((member) => member.userID));

    return { filteredUsers, memberIDs };
  }, [users, searchText, members]);

  const addMember = async (user: StreamUser) => {
    try {
      // FIXME: Adding this condition to avoid the error, will revisit it once we fix the issue channel undefined in all
      // other components
      if (channel && currentUser) {
        const text = `${getFullName(currentUser)} added ${getFullName(user)}`;
        await channel.addMembers([user.userID], { text });
      }
    } catch (err) {
      error(t('Failed to add member'));
    }
  };

  return (
    <>
      <TextField
        name=''
        label=''
        startAdornment={<Icon name='search' />}
        {...textFieldProps}
        placeholder={t('Search members')}
      />
      <div css={userListContainerStyle}>
        {filteredUsers.map((user) => {
          const isMemberAdded = memberIDs.has(user.userID);
          return (
            <div css={userContainerStyle} key={user.userID}>
              <ChatAvatar members={[user]} />
              <Text>{getFullName(user)}</Text>
              {isMemberAdded && (
                <Text css={marginLeftAuto} color='success'>
                  {t('Added')}
                </Text>
              )}
              {!isMemberAdded && (
                <ButtonWithLoader
                  css={[marginLeftAuto, buttonStyle]}
                  onClick={() => addMember(user)}
                  title={<Text color='white'>{t('Add')}</Text>}
                />
              )}
            </div>
          );
        })}
      </div>
    </>
  );
};

const userListContainerStyle = css({
  overflowY: 'scroll',
  overflowX: 'hidden',
  height: '288px',
  boxShadow: theme.shadows.light,
  borderBottomLeftRadius: theme.borderRadius.medium,
  borderBottomRightRadius: theme.borderRadius.medium,
  marginBottom: theme.spacing(1),
});

const userContainerStyle = css({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  padding: theme.spacing(1.5, 2),
  borderBottom: `1px solid ${theme.colors.neutral10}`,
});

const marginLeftAuto = css({ marginLeft: 'auto' });
const buttonStyle = css({ width: 'fit-content' });
