import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';

dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

type GetInitialDateCategoryReturnType = 'today' | 'tomorrow' | 'monday' | 'custom';
export const getInitialDateCategory = (startDate: string, endDate: string): GetInitialDateCategoryReturnType => {
  const initialStartTimestamp = dayjs(startDate);
  const initialEndTimestamp = dayjs(endDate);
  const today = dayjs();
  const nextMonday = today.day(1 + 7); // Get the next Monday

  if (initialStartTimestamp.isToday() && initialEndTimestamp.isToday()) return 'today';
  if (initialStartTimestamp.isTomorrow() && initialEndTimestamp.isTomorrow()) return 'tomorrow';
  if (initialStartTimestamp.isSame(nextMonday, 'day') && initialEndTimestamp.isSame(nextMonday, 'day')) return 'monday';
  return 'custom';
};

const DATE_VALIDATOR_FORMAT = 'YYYY-MM-DD';
export const getToday = () => {
  return dayjs().format(DATE_VALIDATOR_FORMAT);
};

export const getTomorrow = () => {
  return dayjs().add(1, 'day').format(DATE_VALIDATOR_FORMAT);
};

export const getNextMonday = () => {
  const today = dayjs();
  const nextMonday = today.day() === 1 ? today.add(7, 'day') : today.day(8); // If today is Monday, get the next Monday, otherwise get the upcoming Monday
  return nextMonday.format(DATE_VALIDATOR_FORMAT);
};
