import React, { PropsWithChildren } from 'react';
import { useNavigate } from '@tanstack/react-location';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Button, Heading } from '@frontend/design-system';

export type TitleProps = PropsWithChildren<{
  title?: string | React.ReactNode;
  showBackBtn?: boolean;
  customBackUrl?: string;
  onBackClick?: () => void;
}>;

export const Title = ({ title, children, showBackBtn, customBackUrl, onBackClick }: TitleProps) => {
  const navigate = useNavigate();
  const handleBackClick = React.useMemo(() => {
    let handler: () => void = () => history.go(-1);
    if (onBackClick) {
      handler = onBackClick;
    } else if (customBackUrl) {
      handler = () => navigate({ to: customBackUrl });
    }
    return handler;
  }, [onBackClick, customBackUrl]);

  return (
    <div
      css={{
        gridArea: 'title',
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        flexWrap: 'wrap',

        [`@container (max-width:  ${breakpoints.xsmall.max}px)`]: {
          alignItems: 'start',
          gap: 0,
          flexDirection: 'column',
        },
      }}
    >
      {title && typeof title === 'string' ? (
        <PageTitle title={title} showBackBtn={showBackBtn && history.length > 0} onBackClick={handleBackClick} />
      ) : (
        title
      )}
      {children}
    </div>
  );
};

type PageTitleProps = {
  title: string;
  showBackBtn?: boolean;
  onBackClick?: () => void;
};

export const PageTitle = ({ title, showBackBtn = false, onBackClick }: PageTitleProps) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {showBackBtn && (
        <Button variant='tertiary' iconName='back' onClick={onBackClick}>
          {<></>}
        </Button>
      )}
      <Heading
        level={1}
        css={{
          minWidth: 0,
          margin: 0,
          [`@container (max-width:  ${breakpoints.small.max}px)`]: {
            fontSize: theme.fontSize(24),
          },
        }}
      >
        {title}
      </Heading>
    </div>
  );
};
