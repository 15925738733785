import { useCallback } from 'react';
import { EventType } from '@weave/schema-gen-ts/dist/schemas/phone-exp/phone-call/v1/call_pops.pb';
import { DataSourcesHooks } from '@frontend/api-data-sources';
import { PersonHelpers } from '@frontend/api-person';
import { useIncomingCallNotification } from '@frontend/notifications';
import { CallPopInterface, useCallPopStateSync } from '@frontend/pop';
import TempoTracing from '@frontend/tempo-tracing';
import { GetWeavePopNotificationByType } from '@frontend/types';
import { GetWebsocketEventHandler, useWebsocketEventSubscription } from '@frontend/websocket';
import { useCallQueueMetricsStore } from './components/call-queue-stats/use-metrics-store';

type Props = { enabled: boolean };
export const useWebsocketDeskPhoneEventSubscription = ({ enabled }: Props) => {
  const { create } = useIncomingCallNotification();
  const { notifications, addNotification } = useCallPopStateSync();
  const { demoSourceIds } = DataSourcesHooks.useDemoLocationSourceIdsShallowStore('demoSourceIds');

  const handlerV2 = useCallback<GetWebsocketEventHandler<'PhoneSystemEventsV2'>>(
    (payload) => {
      const event = payload.params.event;
      if (!!payload.trace_id) {
        TempoTracing.continueTrace(payload.trace_id, TempoTracing.spanNameGenerators.callPopDesktopSpan(event), {
          parentSpanName: TempoTracing.spanNameGenerators.websocketSpan(),
        });
      }
      if (!enabled) {
        if (!!payload.trace_id && event === 'inbound_call') {
          TempoTracing.addEvent(payload.trace_id, TempoTracing.spanNameGenerators.callPopDesktopSpan(event), {
            eventMessage: 'Call pop notifications for deskphone are disabled',
            eventType: EventType.EVENT_TYPE_INFO,
            timestamp: new Date().toISOString(),
          });
          TempoTracing.endSpan(payload.trace_id, TempoTracing.spanNameGenerators.callPopDesktopSpan(event));
        }
        return;
      }
      switch (event) {
        case 'inbound_call': {
          if (!!payload.trace_id) {
            TempoTracing.addEvent(payload.trace_id, TempoTracing.spanNameGenerators.callPopDesktopSpan(event), {
              eventMessage: 'Showing call pop',
              eventType: EventType.EVENT_TYPE_INFO,
              timestamp: new Date().toISOString(),
            });
          }
          addNotification({
            id: payload.params.channel_id,
            timestamp: Date.now(),
            payload: {
              type: 'default',
              callerContext: payload.params.caller_context,
              recipientLocationName: payload.params.recipient_location_name,
              headOfHousehold: payload.params.call_pop_head_of_household,
              contacts: payload.params.contact_matches
                ? payload.params.contact_matches
                    .filter((contact) => (demoSourceIds.length > 0 ? demoSourceIds.includes(contact.source_id) : true))
                    .map((contact) => ({
                      personId: contact.person_id,
                      matchedLocationId: contact.weave_locations_matched[0]?.location_id ?? '',
                      callerName: PersonHelpers.getFullName({
                        FirstName: contact.first_name,
                        LastName: contact.last_name,
                      }),
                      callerNumber: payload.params.caller_id_number,
                      recipientLocationName: payload.params.recipient_location_name,
                      gender: contact.gender,
                      birthdate: contact.birthdate.seconds,
                      source: contact.data_source_name,
                      patientId: contact.patient_id,
                      householdId: contact.household_id,
                    }))
                : [
                    {
                      personId: '',
                      patientId: '',
                      householdId: '',
                      // An empty caller name will show "Unknown Caller" in the call pop
                      callerName: '',
                      callerNumber: payload.params.caller_id_number,
                      recipientLocationName: payload.params.recipient_location_name,
                      gender: '',
                      birthdate: 0,
                      matchedLocationId: payload.params.recipient_location_id ?? '',
                      source: '',
                    },
                  ],
            },
          });
          return;
        }
        case 'outbound_call':
        case 'answer_call':
        case 'hangup_call':
          if (!!payload.trace_id) {
            TempoTracing.addEvent(payload.trace_id, TempoTracing.spanNameGenerators.callPopDesktopSpan(event), {
              eventMessage: `Call pop dismissed with action: ${event}`,
              eventType: EventType.EVENT_TYPE_INFO,
              timestamp: new Date().toISOString(),
            });
          }
          // Remove the call pop for the call that just ended
          CallPopInterface.dismiss(payload.params.channel_id);

          create({
            id: payload.id,
            location: payload.params.recipient_location_name,
            timestamp: new Date().toDateString(),
            type: 'missed-call',
            payload: {
              callerName: payload.params.caller_id_name,
              callerNumber: payload.params.caller_id_number,
              recipientLocationName: payload.params.recipient_location_name,
            },
            state: {
              paused: true, //initially paused. timer will begin when call ends
              timeout: 0,
              status: 'unread',
            },
          } satisfies GetWeavePopNotificationByType<'missed-call'>);
          if (!!payload.trace_id) {
            TempoTracing.endSpan(payload.trace_id, TempoTracing.spanNameGenerators.callPopDesktopSpan(event));
          }
          return;
        default: {
          const _exhaustive = event;
          return _exhaustive;
        }
      }
    },
    [notifications, addNotification, enabled]
  );

  useWebsocketEventSubscription('PhoneSystemEventsV2', handlerV2);
};

export const useWebsocketCallQueueEventSubscription = () => {
  const { metrics, setMetrics } = useCallQueueMetricsStore();
  useWebsocketEventSubscription('PhoneCallQueueEvents', (payload) => {
    const event = payload.params;

    setMetrics({
      [event.queue_id]: {
        callCount: event.call_count,
        averageWaitTimeInSeconds: event.average_wait_time_in_seconds,
        waitTimesInSeconds: event.wait_times_in_seconds,
        name: metrics?.[event.queue_id]?.name ?? '',
        locationIds: metrics?.[event.queue_id]?.locationIds ?? [],
      },
    });
  });
};
