import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export type HeaderProps = React.PropsWithChildren<{ breadcrumbs?: React.ReactNode }>;

const header = css`
  margin-bottom: ${theme.spacing(4)};
  grid-area: header;
`;

export function Header({ children }: HeaderProps) {
  return (
    <header css={header} className='page-header'>
      {children}
    </header>
  );
}
