import { memo } from 'react';
import { css } from '@emotion/react';
import { humanizeDateTime } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { Text, styles as dsStyles } from '@frontend/design-system';

interface Props {
  name: string;
  date: string;
}

export const Author = memo(({ date, name }: Props) => {
  return (
    <div css={styles.wrapper}>
      <Text css={dsStyles.truncate} weight='bold'>
        {name}
      </Text>
      <Text as='time' color='subdued' size='medium'>
        {humanizeDateTime(date)}
      </Text>
    </div>
  );
});

Author.displayName = 'Author';

const styles = {
  wrapper: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(1)};
    width: fit-content;
  `,
};
