import { FC, useEffect } from 'react';
import { BuilderFieldDivisionCategories } from '../../../constants';
import { useFormBuilderStore } from '../../../provider';
import { PrimaryFieldsDivisionCategory } from '../../../types/field-divisions';
import CategoriesLayout from './categories-layout/categories-layout.component';
import { useFilteredCategories } from './hooks';

export const PrimaryFields: FC = () => {
  const { filteredCategories, setCompleteCategories } = useFilteredCategories<PrimaryFieldsDivisionCategory>({
    categories: [],
  });
  const { primaryFieldElements, usedPrimaryFieldElements, shouldShowMedicalHistoryFields } = useFormBuilderStore([
    'primaryFieldElements',
    'usedPrimaryFieldElements',
    'shouldShowMedicalHistoryFields',
  ]);

  // Initialize the filtered categories based on the medical history fields visibility.
  useEffect(() => {
    if (shouldShowMedicalHistoryFields) {
      setCompleteCategories(BuilderFieldDivisionCategories.PRIMARY_FIELD_CATEGORIES_INCLUDING_MEDICAL_HISTORY);
      return;
    }

    setCompleteCategories(BuilderFieldDivisionCategories.PRIMARY_FIELD_CATEGORIES);
  }, [shouldShowMedicalHistoryFields]);

  if (!primaryFieldElements) {
    return null;
  }

  return (
    <CategoriesLayout
      categories={filteredCategories}
      fields={primaryFieldElements}
      type='primary'
      usedFields={usedPrimaryFieldElements}
    />
  );
};
