import { useMemo } from 'react';
import { DeviceType } from '@weave/schema-gen-ts/dist/schemas/phone/user/v1/user_service.pb';
import { locationApiQueryKeys } from '@frontend/api-location';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import { useQuery } from '@frontend/react-query-helpers';
import { attachLocationTypes, useAppScopeStore } from '@frontend/scope';
import { extractParentLocations, transformLocationData, queryOrgLocationsWithTenantIds } from './utils';

export const useWeaveQueryLocations = ({ orgId, enabled = true }: { orgId: string; enabled?: boolean }) => {
  const queryKey = locationApiQueryKeys.orgLocations(orgId);
  const { data: orgLocations, isLoading } = useQuery({
    queryKey,
    queryFn: () => queryOrgLocationsWithTenantIds(orgId),
    enabled: !!orgId && enabled,
  });

  const selectOrgLocations = useMemo(() => extractParentLocations(orgLocations), [orgLocations]);

  const accessibleLocationData = useMemo(
    () => attachLocationTypes(transformLocationData(orgLocations)),
    [orgLocations]
  );

  return { orgLocations, isLoading, selectOrgLocations, accessibleLocationData };
};

export const usePhoneConfigInfo = ({
  phoneConfig,
  accessibleLocationData,
}: {
  phoneConfig: ReturnType<typeof usePhoneConfigShallowStore>['phoneConfig'];
  accessibleLocationData: ReturnType<typeof useAppScopeStore>['accessibleLocationData'];
}) => {
  return useMemo(() => {
    if (!phoneConfig?.locationId) return;
    const location = accessibleLocationData[phoneConfig.locationId];
    if (!location) return;

    const isSoftphone = phoneConfig.deviceType === DeviceType.DEVICE_TYPE_SOFTPHONE;

    let org = { ...location };

    // If we are in a databeta location, update the org to be the parent org. (parentId === orgId)
    if (location.locationType === 'databeta' && location.parentId) {
      org = accessibleLocationData[location.parentId];
    }

    return {
      orgId: org.id,
      locationId: location.locationID,
      isSoftphone,
    };
  }, [phoneConfig, accessibleLocationData]);
};
