import { useCallback } from 'react';
import { NotificationType } from '@weave/schema-gen-ts/dist/shared/notification/notifications.pb';
import { useInboxNavigate } from '@frontend/inbox-navigation';
import { useNotificationSettingsShallowStore, useSmsTagNotification } from '@frontend/notifications';
import { GetWebsocketEventHandler, useWebsocketEventSubscription } from '@frontend/websocket';

export const useSMSTagNotificationSubscription = () => {
  const { navigateToThread } = useInboxNavigate();
  const { create } = useSmsTagNotification({
    onView: (event) => {
      navigateToThread({
        threadId: event.payload.threadId,
        groupId: event.payload.groupId,
        personId: event.payload.personId,
        smsId: event.payload.smsId,
        smsCreatedAt: event.payload.smsCreatedAt,
      });
    },
  });
  const { notificationSettings } = useNotificationSettingsShallowStore('notificationSettings');

  const eventHandler = useCallback<GetWebsocketEventHandler<NotificationType.SMS_TAG_V2>>(
    (event) => {
      const {
        params: { action },
      } = event;

      switch (action) {
        case 'tagApplied':
          create({
            id: String(event.id),
            type: 'sms-tag',
            payload: event.params,
            timestamp: Date.now(),
            state: {
              paused: false,
              timeout: notificationSettings.durationMs,
              status: 'unread',
            },
          });
      }
    },
    [notificationSettings.durationMs, create]
  );

  return useWebsocketEventSubscription(NotificationType.SMS_TAG_V2, eventHandler);
};
