import { PageRequest } from '@weave/schema-gen-ts/dist/schemas/forms-digital/weave_digital_forms.pb';
import { useInfiniteQuery, useQuery } from 'react-query';
import { Compulsory } from 'ts-toolbelt/out/Object/Compulsory';
import { LimitedSchemaInfiniteQueryOptions, LimitedSchemaQueryOptions } from '@frontend/react-query-helpers';
import { SchemaDigitalForms } from '../../service';
import {
  GetPacketListIO,
  GetPacketListV2IO,
  GetPacketListV3IO,
  GetPacketDetailsIO,
  GetFormPDFAuthedIO,
} from '../../types';
import { defaultOptions } from '../config';
import { requestKeys } from '../keys';

/**
 * @param companyId - AKA locationId
 */
export const usePacketList = <E = unknown, T = GetPacketListIO['output']>(
  req: Compulsory<GetPacketListIO['input'], 'companyId'>,
  options?: LimitedSchemaQueryOptions<GetPacketListIO, E, T>
) => {
  const queryKey = requestKeys.packetList(req);
  const query = useQuery({
    queryKey,
    queryFn: () => SchemaDigitalForms.GetPacketList(req, { headers: { 'Location-Id': req.companyId } }),
    ...defaultOptions,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};

/**
 * @param companyIds - AKA locationIds
 * @deprecated Use `usePacketListV3` instead
 */
export const usePacketListV2 = <E = unknown, T = GetPacketListV2IO['output']>(
  req: Compulsory<GetPacketListV2IO['input'], 'companyIds' | 'pageRequest'>,
  options?: LimitedSchemaInfiniteQueryOptions<GetPacketListV2IO, E, T>
) => {
  const queryKey = requestKeys.packetListV2(req);
  const query = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }: { pageParam?: PageRequest }) =>
      SchemaDigitalForms.GetPackets({
        ...req,
        pageRequest: pageParam || req.pageRequest,
      }),
    getNextPageParam: (lastPage): PageRequest => {
      const page = lastPage.pageMetadata?.page;
      return {
        pageNumber: page ? parseInt(page, 10) : undefined,
        pageLimit: req.pageRequest.pageLimit,
      };
    },
    ...defaultOptions,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};

type GetPacketsListV3Request = Compulsory<GetPacketListV3IO['input'], 'orgRequest' | 'pageRequest'>;
type ModifiedPacketsListV3Response = Omit<GetPacketListV3IO['output'], 'data'> & {
  rows: GetPacketListV3IO['output']['data'];
};

/**
 * Fetches the list of packets in the organization and returns the response
 * in a format that is compatible with the InfinitePaginatedList component.
 */
async function getPacketsListV3(req: GetPacketsListV3Request): Promise<ModifiedPacketsListV3Response> {
  const response = await SchemaDigitalForms.GetPacketsV3(req);
  return {
    pageMetadata: response.pageMetadata,
    rows: response.data ?? [], // This is required for the InfinitePaginatedList component to work.
  };
}

/**
 * Fetches the list of packets in the organization.
 */
export const usePacketListV3 = <E = unknown, T = ModifiedPacketsListV3Response>(
  req: GetPacketsListV3Request,
  options?: LimitedSchemaInfiniteQueryOptions<GetPacketListV3IO, E, T>
) => {
  const queryKey = requestKeys.packetListV3(req);
  const query = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }: { pageParam?: PageRequest }) =>
      getPacketsListV3({ ...req, pageRequest: pageParam || req.pageRequest }),
    getNextPageParam: (lastPage): PageRequest | undefined => {
      const page = parseInt(lastPage.pageMetadata?.page || '0', 10);
      const lastPageNumber = parseInt(lastPage.pageMetadata?.lastPage || '0', 10);

      if (page >= lastPageNumber) {
        return undefined;
      }

      return {
        pageNumber: page + 1,
        pageLimit: req.pageRequest.pageLimit,
      };
    },
    ...defaultOptions,
    retry: false,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useGetPacketDetails = <E = unknown, T = GetPacketDetailsIO['output']>(
  req: Compulsory<GetPacketDetailsIO['input'], 'packetId' | 'orgId'>,
  options?: LimitedSchemaQueryOptions<GetPacketDetailsIO, E, T>
) => {
  const queryKey = requestKeys.packetDetails(req);
  const query = useQuery({
    queryKey,
    queryFn: () => SchemaDigitalForms.GetPacketDetails(req),
    ...defaultOptions,
    retry: false,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};

export const useGetPacketPDF = <E = unknown, T = GetFormPDFAuthedIO['output']>(
  req: Compulsory<GetFormPDFAuthedIO['input'], 'packetId' | 'companyId'>,
  options?: LimitedSchemaQueryOptions<GetFormPDFAuthedIO, E, T>
) => {
  const queryKey = requestKeys.packetPDF(req);
  const query = useQuery({
    queryKey,
    queryFn: () => SchemaDigitalForms.GetFormPDFAuthed(req),
    ...defaultOptions,
    ...options,
  });

  return {
    queryKey,
    ...query,
  };
};
