import { useCallIntelShallowStore } from './use-call-intel-store';

export const useCallIntelDemoFlags = () => {
  const { isDemoAccount, isDemoModal } = useCallIntelShallowStore('isDemoAccount', 'isDemoModal');
  return {
    isDemoAccount,
    isDemoModal,
    showDemoChipAndBanner: isDemoAccount && !isDemoModal,
  };
};
