import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { queryPredicate } from '@frontend/api-phone-sync';
import { useAppSettingsShallowStore } from '@frontend/app-settings-store';
import { SoftphoneStateInterface } from '@frontend/softphone-state';
import { TAB_ID } from '@frontend/tab-management';

type SoftphoneTabEventType = {
  type: 'SOFTPHONE_TAB';
  payload: { activeId: string; tabIds: string[]; hasActiveCalls: boolean };
};

type SoftphoneStateEventType = {
  type: 'SOFTPHONE';
  payload:
    | { action: 'CONNECTED' | 'DISCONNECTED'; tabId: string }
    | { action: 'ACTIVE_CALL'; tabId: string; isActive: boolean };
};

export const useSoftphoneTabManager = () => {
  const [activeTab, setActiveTab] = useState('');
  const { hasActiveSoftphoneCalls, setHasActiveSoftphoneCalls } = useAppSettingsShallowStore(
    'hasActiveSoftphoneCalls',
    'setHasActiveSoftphoneCalls'
  );
  const queryClient = useQueryClient();
  const { registerTab, unregisterTab, clearTabStore, activateTab } = SoftphoneStateInterface;
  useEffect(() => {
    registerTab(TAB_ID);
    if (!activeTab) {
      setActiveTab(TAB_ID);
    }

    return () => {
      unregisterTab(TAB_ID);
    };
  }, []);

  useEffect(() => {
    const update = (event: MessageEvent<SoftphoneTabEventType | SoftphoneStateEventType>) => {
      if (event.data.type === 'SOFTPHONE_TAB') {
        setActiveTab(event.data.payload.activeId);
      }

      if (event.data.type === 'SOFTPHONE' && TAB_ID !== event.data.payload.tabId) {
        /**
         * Tell other tabs how to respond to the connect/disconnect event
         */

        if (event.data.payload.action === 'DISCONNECTED' || event.data.payload.action === 'CONNECTED') {
          queryClient.invalidateQueries({
            predicate: queryPredicate.listDevices,
          });
        }

        if (event.data.payload.action === 'ACTIVE_CALL') {
          setHasActiveSoftphoneCalls(event.data.payload.isActive);
        }
      }
    };

    const beforeUnloadFn = (e: BeforeUnloadEvent) => {
      unregisterTab(TAB_ID);
      if (hasActiveSoftphoneCalls) {
        e.preventDefault();
        e.returnValue = true;
      }
    };

    window.addEventListener('beforeunload', beforeUnloadFn);
    navigator.serviceWorker.addEventListener('message', update);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadFn);
      navigator.serviceWorker.removeEventListener('message', update);
    };
  }, [hasActiveSoftphoneCalls, setHasActiveSoftphoneCalls]);

  const useThisTab = () => {
    activateTab(TAB_ID);
  };

  return { useThisTab, unregisterTab, activeTab, isActiveTab: activeTab === TAB_ID, clearTabStore };
};
