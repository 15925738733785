import { memo } from 'react';
import { css } from '@emotion/react';
import { useLocation } from '@tanstack/react-location';
import dayjs from 'dayjs';
import { AnalyticsCommonTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { Photos } from '@frontend/photos';
import { useContactPanelStore } from '@frontend/shared';
import { useSlidePanelStore } from '@frontend/slide-panel';
import { theme } from '@frontend/theme';
import { Text, styles as dsStyles } from '@frontend/design-system';
import { useCallTakeawayPanelShallowStore } from './call-intelligence/hooks';
import { LocationChip, StatusIcon } from '.';

export const UserCard = memo(
  ({
    backLinkAction,
    firstName = '',
    lastName = '',
    locationId,
    locationName,
    openProfileOnClick = true,
    phoneNumber,
    rightElement,
    showOnlyName,
    style,
    time,
    userId,
    ...rest
  }: AnalyticsCommonTypes.UserCard) => {
    const { t } = useTranslation('analytics');
    const { setSelectedCall } = useCallTakeawayPanelShallowStore('setSelectedCall');
    const { setShow } = useSlidePanelStore();
    const { setPersonId } = useContactPanelStore();
    const { current } = useLocation();

    const fullName = `${firstName ?? ''} ${lastName ?? ''}`.trim() || null;
    const isWeaveApp = !current.pathname.startsWith('/portal');
    const isClickable = isWeaveApp && openProfileOnClick && !!userId;
    const hasNameOrNumber = !!fullName || !!phoneNumber;

    return (
      <div css={styles.mainWrapper} style={style} {...rest}>
        <div
          css={styles.contactWrapper}
          onClick={() => {
            if (isClickable) {
              if (typeof backLinkAction === 'function') {
                setPersonId(userId, false, locationId);
                setShow(true, 'contact', {
                  backlink: backLinkAction,
                });
              } else {
                // Using set show to hide the previous panel so we can remove the backlink
                setShow(false, 'contact');
                setSelectedCall(null);
                setPersonId(userId, true, locationId);
              }
            }
          }}
          role={isClickable ? 'button' : undefined}
          style={{ cursor: isClickable ? 'pointer' : 'default' }}
        >
          <Photos.ContactProfilePhoto
            disableClick={!userId}
            locationId={locationId}
            name={fullName || t('Unknown')}
            personId={userId || ''}
          />
          <div style={{ width: `calc(100% - ${theme.spacing(6)})` }}>
            <div className='name-wrapper'>
              <Text
                className='name-text'
                color={hasNameOrNumber ? 'default' : 'subdued'}
                css={dsStyles.truncate}
                style={{ flex: 'none', maxWidth: rightElement ? `calc(100% - 16px)` : '100%' }}
              >
                {fullName ? (
                  <>
                    {!!firstName && (
                      <Text as='span' weight='bold'>
                        {`${firstName} `}
                      </Text>
                    )}
                    {lastName ?? ''}
                  </>
                ) : phoneNumber ? (
                  formatPhoneNumber(phoneNumber)
                ) : (
                  t('Unavailable Data')
                )}
              </Text>
              <StatusIcon statusIcon={rightElement} />
            </div>

            {!showOnlyName && (
              <Text color='light' size='medium'>
                {phoneNumber ? formatPhoneNumber(phoneNumber) : t('Unavailable Data')}
              </Text>
            )}
            {time && <Text size='medium'>{dayjs(time).format('MMM D, YYYY, h:mm A')}</Text>}
          </div>
        </div>
        <LocationChip locationName={locationName} />
      </div>
    );
  }
);

UserCard.displayName = 'UserCard';

const styles = {
  mainWrapper: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(1)};
    justify-content: space-between;
    max-width: 100%;
  `,

  contactWrapper: css`
    align-items: center;
    display: flex;
    gap: ${theme.spacing(1)};
    max-width: 100%;

    .avatar-wraper {
      align-items: center;
      background-color: ${theme.colors.neutral30};
      border-radius: ${theme.borderRadius.full};
      color: ${theme.colors.white};
      display: flex;
      justify-content: center;
      overflow: hidden;

      span {
        color: inherit;
        font-weight: ${theme.font.weight.bold};
      }
    }

    .name-wrapper {
      align-items: center;
      display: flex;
      gap: ${theme.spacing(1)};
      position: relative;
    }
  `,
};
