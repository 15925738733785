import { css } from '@emotion/react';
import { Button, Divider, Page } from '@frontend/generic-dialpad-accessories';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { CallInformation } from '../components/call-information';
import { PageHeaderWraps } from '../components/generic/page-header-wraps';
import { HeldCall } from '../components/hold/held-call';
import { useSoftphoneCallState } from '../providers/softphone-call-state-provider';
import { useSoftphoneRouter } from '../providers/softphone-router-provider';

export const PageMergedHome = () => {
  const { t } = useTranslation('softphone');
  const mergedCallGroup = useSoftphoneCallState((ctx) => ctx.mergedCallGroup);
  const primaryCall = useSoftphoneCallState((ctx) => ctx.primaryCall);
  const establishedCalls = useSoftphoneCallState((ctx) => ctx.establishedCalls);
  const mergedCallIds = useSoftphoneCallState((ctx) => ctx.mergedCallIds);

  const route = useSoftphoneRouter((ctx) => ctx.setGroupCallRoute);
  const otherHeldCalls = establishedCalls.filter(
    (call) => !!call.onHoldSince && primaryCall?.id !== call.id && !mergedCallIds?.includes(call.id)
  );

  return (
    <Page>
      <PageHeaderWraps
        css={css`
          display: block;
        `}
      >
        {mergedCallGroup?.map((call) => {
          return <CallInformation {...call.remoteParty} hideSubtitle hideCallDuration />;
        })}
      </PageHeaderWraps>
      <Page.Body>
        <ul
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            margin: ${theme.spacing(3, 0)};
            gap: ${theme.spacing(3)};
            padding: 0;
            list-style-type: none;
          `}
        >
          <li>
            <Button.Round
              trackingId='softphone-multicall-managecall'
              onClick={() => route('calls-list')}
              size='medium'
              iconName={'user'}
              label={t('Manage')}
            />
          </li>
          <li>
            <Button.Round onClick={() => route('keypad')} size='medium' iconName={'dial'} label={t('Keypad')} />
          </li>
        </ul>
      </Page.Body>
      <Page.Footer>
        {!!otherHeldCalls.length && (
          <>
            <Divider />
            <Text
              color='white'
              css={css`
                margin-bottom: ${theme.spacing(1)};
              `}
            >
              {t('On Local Hold')}
            </Text>
            {otherHeldCalls.map((call) => (
              <HeldCall key={call.id} call={call}></HeldCall>
            ))}
          </>
        )}
      </Page.Footer>
    </Page>
  );
};
