import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { CustomizationFlagTypes, useCustomizationFlagShallowStore } from '@frontend/api-customization-flags';
import { getUser, hasSchemaACL } from '@frontend/auth-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { useIsCallIntelDemoAccount } from '../components/call-intelligence/hooks';

export const useShowCallIntelNav = () => {
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const { flags } = useCustomizationFlagShallowStore('flags');
  const hasCallIntelAclAccess = hasSchemaACL(selectedLocationIdsWithParents[0], Permission.CALL_INTELLIGENCE_READ);

  const isCallIntelActive =
    flags.callintelligence?.state === CustomizationFlagTypes.CustomizationFlagState.ACTIVE ||
    flags.callintelligence?.state === CustomizationFlagTypes.CustomizationFlagState.PROMOTE;

  const isDemoAccount = useIsCallIntelDemoAccount();
  const isWeaveDemoUser = getUser()?.username.toLowerCase().includes('@demoweave') || false;

  return (hasCallIntelAclAccess && isCallIntelActive) || isDemoAccount || isWeaveDemoUser;
};
