import { ComponentProps, forwardRef, ReactNode, Ref, useMemo } from 'react';
import { css } from '@emotion/react';
import { ScheduledSms } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { OutboundMessageStatus } from '@weave/schema-gen-ts/dist/schemas/messaging/shared/v1/enums.pb';
import { DropzoneState } from 'react-dropzone';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text, AutoGrowTextarea, KeyNames, ButtonVariants } from '@frontend/design-system';
import { UseThreadMediaResponse } from '../hooks';
import { EditScheduledMessageForm, SendingAreaForm } from '../types';
import { SendButton, SendButtonActions } from './buttons';
import { MediaPreviewList } from './media-preview-list';
import { ScheduledMessageList } from './scheduled-messages';

export type ThreadSendingAreaState = {
  formState: SendingAreaForm;
  editScheduledMessageFormState: EditScheduledMessageForm;
  disabled: boolean;
  dropzoneState: DropzoneState;
  scheduledMessages: ScheduledSms[];
  onSelectScheduledMessage: (messageId?: string) => void;
  selectedScheduledMessage?: ScheduledSms;
  ref: Ref<HTMLTextAreaElement>;
  textareaId: string;
  showSignature: boolean;
} & SendButtonActions &
  Omit<UseThreadMediaResponse, 'resetLocalMedia'>;

type ThreadSendingAreaProps = Omit<ThreadSendingAreaState, 'ref'> & {
  autoGrowTextareaProps?: Pick<ComponentProps<typeof AutoGrowTextarea>, 'minRows' | 'maxRows' | 'disabled'>;
  children?: ReactNode;
  canSchedule?: boolean;
  sendButtonVariant?: ButtonVariants;
  sendButtonPosition?: 'top' | 'bottom';
};

export const ThreadSendingArea = forwardRef<HTMLTextAreaElement, ThreadSendingAreaProps>(
  (
    {
      formState,
      editScheduledMessageFormState,
      media,
      removeMedia,
      addMedia,
      disabled,
      onSend,
      onSendScheduled,
      onSchedule,
      onReschedule,
      onDeleteScheduledMessage,
      autoGrowTextareaProps,
      children,
      canSchedule = true,
      dropzoneState,
      scheduledMessages,
      onSelectScheduledMessage,
      selectedScheduledMessage,
      textareaId,
      sendButtonVariant,
      sendButtonPosition = 'bottom',
      showSignature,
    },
    ref
  ) => {
    const { t } = useTranslation('thread-sending-area');

    const textareaFieldProps = selectedScheduledMessage
      ? editScheduledMessageFormState.getFieldProps('body')
      : formState.getFieldProps('body');

    const formEditingStyles = useMemo(() => {
      switch (selectedScheduledMessage?.status) {
        case OutboundMessageStatus.SCHEDULED:
          return css({
            backgroundColor: theme.colors.warning5,
            '*:not([role="divider"])': {
              backgroundColor: theme.colors.warning5,
            },
            'button[aria-disabled="true"], button[aria-disabled="false"], button:hover:not(&[aria-disabled="true"])': {
              '*': {
                transition: 'background-color 0ms',
              },
              transition: 'background-color 0ms',
              backgroundColor: theme.colors.warning5,
              ':hover, :focus': {
                '*': {
                  backgroundColor: theme.colors.warning10,
                },
                backgroundColor: theme.colors.warning10,
              },
            },
          });
        case OutboundMessageStatus.PAUSED:
          return css({
            backgroundColor: theme.colors.neutral5,
            '*:not([role="divider"])': {
              backgroundColor: theme.colors.neutral5,
            },
            'button[aria-disabled="true"], button[aria-disabled="false"], button:hover:not(&[aria-disabled="true"])': {
              '*': {
                transition: 'background-color 0ms',
              },
              transition: 'background-color 0ms',
              backgroundColor: theme.colors.neutral5,
              ':hover, :focus': {
                '*': {
                  backgroundColor: theme.colors.neutral10,
                },
                backgroundColor: theme.colors.neutral10,
              },
            },
          });
        default:
          return undefined;
      }
    }, [selectedScheduledMessage?.status]);

    return (
      <>
        <ScheduledMessageList
          scheduledMessages={scheduledMessages}
          onSelectScheduledMessage={onSelectScheduledMessage}
          selectedScheduledMessage={selectedScheduledMessage}
        />
        <MediaPreviewList
          media={media}
          removeMedia={removeMedia}
          css={{
            backgroundColor: selectedScheduledMessage ? theme.colors.warning5 : undefined,
          }}
        />
        <form
          css={[
            {
              position: 'relative',
              width: '100%',
              borderTop: `1px solid ${theme.colors.neutral20}`,
              padding: theme.spacing(2, 1, 1),
            },
            formEditingStyles,
          ]}
          onKeyDown={(e) => {
            if (!e.shiftKey && e.key === KeyNames.Enter) {
              if (selectedScheduledMessage) {
                editScheduledMessageFormState.formProps.onSubmit(e);
                return;
              }
              formState.formProps.onSubmit(e);
            }
          }}
          {...(selectedScheduledMessage ? editScheduledMessageFormState.formProps : formState.formProps)}
        >
          <div
            css={{
              display: 'flex',
              width: '100%',
              alignItems: 'start',
            }}
          >
            <AutoGrowTextarea
              ref={ref}
              css={{
                border: 'none',
                outline: 'none',
                resize: 'none',
                width: '100%',
                fontSize: theme.fontSize(16),
                color: theme.colors.neutral70,
                flexGrow: 1,
              }}
              placeholder={t('Type something...')}
              {...textareaFieldProps}
              {...autoGrowTextareaProps}
              minRows={autoGrowTextareaProps?.minRows ?? 4 - (formState.values.signature ? 1 : 0)}
              maxRows={autoGrowTextareaProps?.maxRows ?? 4 - (formState.values.signature ? 1 : 0)}
              disabled={disabled}
              id={textareaId}
              onPaste={(e) => {
                const clipboardItems = e.clipboardData?.items;

                if (!clipboardItems) return;

                const files = Array.from(clipboardItems).reduce<File[]>((acc, curr) => {
                  if (curr.type.includes('image')) {
                    const file = curr.getAsFile();
                    if (file) acc.push(file);
                  }
                  return acc;
                }, []);

                e.preventDefault();
                addMedia(files);
              }}
            />
            {sendButtonPosition === 'top' && (
              <SendButton
                canSchedule={canSchedule}
                disabled={selectedScheduledMessage ? !editScheduledMessageFormState.isComplete : !formState.isComplete}
                variant={sendButtonVariant}
                onSend={onSend}
                onSendScheduled={onSendScheduled}
                onSchedule={onSchedule}
                onReschedule={onReschedule}
                onDeleteScheduledMessage={onDeleteScheduledMessage}
                scheduledMessageForEdit={selectedScheduledMessage}
              />
            )}
          </div>
          {showSignature && (
            <input
              css={{
                border: 'none',
                outline: 'none',
                color: theme.font.colors.light,
                width: '100%',
                textOverflow: 'ellipsis',
              }}
              {...formState.getFieldProps('signature')}
            />
          )}
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: sendButtonPosition === 'top' ? 'start' : children ? 'space-between' : 'end',
              paddingTop: theme.spacing(1),
            }}
          >
            {children}
            {sendButtonPosition === 'bottom' && (
              <SendButton
                canSchedule={canSchedule}
                disabled={selectedScheduledMessage ? !editScheduledMessageFormState.isComplete : !formState.isComplete}
                variant={sendButtonVariant}
                onSend={onSend}
                onSendScheduled={onSendScheduled}
                onSchedule={onSchedule}
                onReschedule={onReschedule}
                onDeleteScheduledMessage={onDeleteScheduledMessage}
                scheduledMessageForEdit={selectedScheduledMessage}
              />
            )}
          </div>
          {!!dropzoneState.isDragActive && (
            <div
              css={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                padding: theme.spacing(1),
                backgroundColor: theme.colors.white,
              }}
            >
              <div
                css={{
                  height: '100%',
                  borderRadius: theme.borderRadius.small,
                  border: `1px solid ${theme.colors.neutral20}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: theme.spacing(1),
                }}
              >
                <Text css={{ textAlign: 'center' }} color='light'>
                  {t('Drop image here (PNG or JPG files only)')}
                </Text>
              </div>
            </div>
          )}
        </form>
        <input {...dropzoneState.getInputProps()} css={{ display: 'none' }} />
      </>
    );
  }
);
ThreadSendingArea.displayName = 'ThreadSendingArea';
