import { css } from '@emotion/react';
import { Icon, IconName } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { OperatoryIconSelectorState } from './OperatoryIconSelector';

type OperatoryIconListProps = {
  color: string;
  iconName: IconName;
  isSelected: boolean;
  onClick: (config: OperatoryIconSelectorState) => void;
};

export const OperatoryIconList = ({ iconName, color, isSelected, onClick }: OperatoryIconListProps) => {
  const handleIconSelection = () => {
    onClick({
      iconName,
      isSelected: !isSelected,
      color,
    });
  };

  return (
    <div css={iconSelectionContainer(isSelected)}>
      <div onClick={handleIconSelection} css={iconSelectionStyle(isSelected, color)}>
        <Icon color='default' name={iconName} />
      </div>
    </div>
  );
};

const iconSelectionStyle = (isSelected: boolean, selectionColor: string) =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    borderRadius: theme.borderRadius.small,
    padding: theme.spacing(0.5),
    ...(isSelected && {
      background: selectionColor,
    }),
  });

const iconSelectionContainer = (isSelected: boolean) =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: theme.borderRadius.medium,
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    ...(isSelected && {
      border: `2px solid ${theme.colors.primary50}`,
    }),
  });
