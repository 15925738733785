import { FC } from 'react';
import { FormsSourceTenants } from '@frontend/api-forms';
import { Icon } from '@frontend/icons';
import { Text, usePopoverDialog, PopoverDialog, Chip } from '@frontend/design-system';
import { useWritebackWidgetStore } from '../../../../../providers';
import { popoverDialogStyle } from '../../writeback-destination.styles';
import ChildList from './child-list/child-list.component';
import { containerStyle } from './parent-list-item.styles';

interface ParentListItemProps {
  sourceTenant: FormsSourceTenants.Types.ModifiedSourceTenant;
}

const ParentListItem: FC<ParentListItemProps> = ({ sourceTenant }) => {
  const { sourceId } = useWritebackWidgetStore(['sourceId']);

  const { getDialogProps: getChildDialogProps, getTriggerProps: getChildDialogTriggerProps } = usePopoverDialog({
    placement: 'right',
  });

  const { id, sourceName, sites = [] } = sourceTenant;

  if (sites.length === 0) {
    return <Text as='span'>{sourceName}</Text>;
  }

  const shouldShowChildSelectionCount = sites.length > 0 && sourceId === id;

  return (
    <>
      <Text as='span' {...getChildDialogTriggerProps()} css={containerStyle}>
        {sourceName}{' '}
        <span className='inner-container'>
          {shouldShowChildSelectionCount && <Chip variant='primary'>1</Chip>}
          <Icon name='caret-right-tiny' color='subdued' />
        </span>
      </Text>

      <PopoverDialog {...getChildDialogProps()} css={popoverDialogStyle}>
        <ChildList sites={sites} sourceId={id} />
      </PopoverDialog>
    </>
  );
};

export default ParentListItem;
