import { css } from '@emotion/react';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { OperatoryIconSelectorState } from './OperatoryIconSelector';

type OperatoryColorIconListProps = {
  color: string;
  selectionColor: string;
  isSelected: boolean;
  onClick: (config: Omit<OperatoryIconSelectorState, 'iconName'>) => void;
};

export const OperatoryColorIconList = ({ color, isSelected, selectionColor, onClick }: OperatoryColorIconListProps) => {
  const handleColorSelection = () => {
    onClick({
      color,
      isSelected: !isSelected,
    });
  };

  return (
    <div css={colorIconContainer(isSelected)}>
      <div onClick={handleColorSelection} css={colorIconStyle(selectionColor)}>
        {isSelected && <Icon width={12} height={12} color='white' name='check-small' />}
      </div>
    </div>
  );
};

const colorIconContainer = (isSelected: boolean) =>
  css({
    width: theme.spacing(4.5),
    height: theme.spacing(4.5),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: theme.borderRadius.full,
    ...(isSelected && {
      border: `2px solid ${theme.colors.primary50}`,
    }),
  });

const colorIconStyle = (color: string) =>
  css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: color,
    width: theme.spacing(3.5),
    height: theme.spacing(3.5),
    borderRadius: theme.borderRadius.full,
  });
