/* TODO: fix the eslint errors here */
/* eslint-disable */
/**
 * A list of apps that use pendo tracking.
 * These names should resemble the repo's name as much as it makes sense.
 */
type Apps =
  | 'frontend/weave'
  | 'platform-webapp-customer'
  | 'desktop-client'
  | 'desktop-client-notifications'
  | 'forms-portal-ui'
  | 'payments-webapp-portal'
  | 'anonymous-users'
  | 'frontend/weave-pay';

/**
 * Pendo's types are relatively loose because they don't know what you will want to track.
 * The purpose of WeavePendoPayload is to make the types strict for our use case
 * and help this package's consumer know what options the functions accept
 */
type WeavePendoPayload = {
  accountId?: string;
  apiKey?: string;
  excludeAllText?: boolean;
  visitorApp: Apps;
  visitorClientVersion?: string;
  visitorFeatureFlags?: any;
  visitorId?: string;
  visitorUsername?: string;
  visitorJobTitles?: any;
  visitorLanguage?: string;
  visitorRole?: string;
  visitorShellVersion?: string;
  visitorShellFlavor?: string;
  visitorIsShell?: boolean;
  browserWidth?: number;
  browserHeight?: number;
  accountName?: string;
  accountStatus?: string;
  officeType?: string;
  productBundle?: string;
  beta?: boolean;
  pmsList?: string;
  customizationFlagsActive?: string[];
  customizationFlagsHidden?: string[];
  customizationFlagsTrial?: string[];
};

type Pendo = {
  identify: (
    props: Pick<
      WeavePendoPayload,
      | 'visitorId'
      | 'visitorRole'
      | 'visitorLanguage'
      | 'accountId'
      | 'visitorApp'
      | 'visitorUsername'
      | 'browserWidth'
      | 'browserHeight'
      | 'accountName'
      | 'accountStatus'
      | 'officeType'
      | 'productBundle'
      | 'beta'
      | 'pmsList'
      | 'customizationFlagsActive'
      | 'customizationFlagsHidden'
      | 'customizationFlagsTrial'
    >
  ) => void;
  /**
   * initWithCallback is copied code from the pendo.script method.
   * since pendo does not provide an event to reliably inform you when it has finished loading,
   * we pulled out the relevant code and added a `load` eventListener that accepts a callback function.
   * this way we can run `pendo.initialize()` after the script has finished loading.
   */
  initWithCallback: (apiKey: string, callbackFunction: () => void) => void;
  initWithScript: (args: {
    apiKey: string;
    domTag?: string;
    scriptElementId?: string;
    runInitialize?: boolean;
  }) => void;
  initialize: (props: WeavePendoPayload) => void;
  isReady: pendo.Pendo['isReady'];
  loadGuides: pendo.Pendo['loadGuides'];
  script: (apiKey: string) => string;
  showGuideByName: pendo.Pendo['showGuideByName'];
  showGuideById: pendo.Pendo['showGuideById'];
  startGuides: pendo.Pendo['startGuides'];
  track: pendo.Pendo['track'];
  /**
   * dom selectors used to customize the behavior of the resource center,
   * so that the third list item triggers the ada chat bot immediately
   */
  domSelectors: { triggerId: string; resourceCenterThirdListItemId: string };
  updateOptions: (props: Omit<WeavePendoPayload, 'accountId' | 'apiKey' | 'excludeAllText'>) => void;
};

/**
 * Because there are several options for pendo configuration, it's possible that
 * some fields will go unused; for example `shellVersion` is unique to the desktop client.
 * We don't want to track unused values in pendo if we don't need to. This function makes sure only
 * truthy values get passed into pendo.
 */
const getTruthyValues = (obj: Record<string, any>) => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (!!value) return { ...acc, [key]: value };
    return acc;
  }, {});
};

/**
 * One stop shop for all things pendo in weave apps.
 * Validate pendo installed correctly by running pendo.validateInstall() in the developer console
 */
export const pendo: Pendo = {
  identify: ({
    accountId = '',
    visitorApp,
    visitorId = '',
    visitorLanguage = 'en_US',
    visitorRole = '',
    visitorUsername = '',
    browserWidth = 0,
    browserHeight = 0,
    accountName = '',
    accountStatus = '',
    officeType = '',
    productBundle = '',
    beta = false,
    pmsList = '',
    customizationFlagsActive,
    customizationFlagsHidden,
    customizationFlagsTrial,
  }) => {
    window?.pendo?.identify({
      visitor: getTruthyValues({
        id: visitorId,
        app: visitorApp,
        language: visitorLanguage,
        role: visitorRole,
        username: visitorUsername,
        browserWidth,
        browserHeight,
      }),
      account: getTruthyValues({
        id: accountId,
        accountName: accountName,
        accountStatus: accountStatus,
        officeType: officeType,
        productBundle: productBundle,
        beta: beta,
        pmsList: pmsList,
        customizationFlagsActive,
        customizationFlagsHidden,
        customizationFlagsTrial,
      }),
    });
  },
  initWithScript: ({ apiKey, domTag = 'body', scriptElementId = 'pendo-loader', runInitialize = false }) => {
    const scriptElement = document.createElement('script');
    scriptElement.innerHTML = pendo.script(apiKey);
    scriptElement.id = scriptElementId;
    const tag = document.getElementsByTagName(domTag)?.[0];
    tag?.appendChild(scriptElement);
    if (runInitialize) window?.pendo?.initialize({ excludeAllText: true });
  },
  initialize: ({
    accountId = '',
    apiKey = '',
    excludeAllText = true,
    visitorApp,
    visitorClientVersion = '',
    visitorFeatureFlags = undefined,
    visitorId = '',
    visitorJobTitles = undefined,
    visitorLanguage = 'en_US',
    visitorRole = '',
    visitorShellVersion = '',
    visitorShellFlavor = '',
    visitorIsShell = false,
    browserWidth = 0,
    browserHeight = 0,
  }) => {
    window?.pendo?.initialize({
      apiKey,
      excludeAllText,
      // `visitor` may be expanded over time to track more user information
      visitor: getTruthyValues({
        app: visitorApp,
        clientVersion: visitorClientVersion,
        featureFlags: visitorFeatureFlags,
        id: visitorId,
        jobTitles: visitorJobTitles,
        language: visitorLanguage,
        role: visitorRole,
        shellVersion: visitorShellVersion,
        isShell: visitorIsShell,
        shellFlavor: visitorShellFlavor,
        browserWidth,
        browserHeight,
      }),
      account: {
        id: accountId,
      },
    });
  },
  isReady: () => window?.pendo?.isReady() || false,
  loadGuides: () => {
    return window?.pendo?.loadGuides();
  },
  /**
   * initWithCallback is copied code from the pendo.script method.
   * since pendo does not provide an event to reliably inform you when it has finished loading,
   * we pulled out the relevant code and added a `load` eventListener that accepts a callback function.
   * this way we can run `pendo.initialize()` after the script has finished loading.
   */
  initWithCallback: (apiKey, callbackFunction) => {
    (function (p, e, n, d, o) {
      // @ts-ignore
      let v, w, x, y, z;
      // @ts-ignore
      o = p[d] = p[d] || {};
      // @ts-ignore
      o._q = [];
      v = ['initialize', 'identify', 'updateOptions', 'pageLoad'];
      for (w = 0, x = v.length; w < x; ++w)
        (function (m) {
          // @ts-ignore
          o[m] =
            // @ts-ignore
            o[m] ||
            function () {
              // @ts-ignore
              o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
            };
        })(v[w]);
      y = e.createElement(n);
      // @ts-ignore
      y.async = !0;
      y.addEventListener('load', callbackFunction);
      // @ts-ignore
      y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
      z = e.getElementsByTagName(n)[0];
      // @ts-ignore
      z.parentNode.insertBefore(y, z);
    })(window, document, 'script', 'pendo');
  },
  script: (apiKey) => `
    (function (apiKey) {
      (function (p, e, n, d, o) {
        var v, w, x, y, z; o = p[d] = p[d] || {}; o._q = [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad']; for (w = 0, x = v.length; w < x; ++w)(function (m) {
          o[m] = o[m] || function () { o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0))); };
        })(v[w]);
        y = e.createElement(n); y.async = !0; y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
        z = e.getElementsByTagName(n)[0]; z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');
    })('${apiKey}');
  `,
  showGuideByName: (name) => {
    window?.pendo?.showGuideByName(name);
  },
  showGuideById: (id) => {
    window?.pendo?.showGuideById(id);
  },
  startGuides: () => {
    window?.pendo?.loadGuides();
  },
  track: (trackType, metadata) => {
    window?.pendo?.track?.(trackType, metadata);
  },
  // these dom selectors are used to customize the behavior of the resource center,
  // so that the third list item triggers the ada chat bot immediately
  domSelectors: {
    triggerId: '_pendo-badge_Fl_aptzXI1_eyDXK5bRWq3wTl28',
    resourceCenterThirdListItemId: 'pendo-list-item-3147eb61-3',
  },
  updateOptions: ({
    visitorClientVersion = '',
    visitorFeatureFlags = undefined,
    visitorId = '',
    visitorJobTitles = undefined,
    visitorLanguage = 'en_US',
    visitorRole = '',
    visitorShellVersion = '',
  }) => {
    // updateOptions only tracks visitor options
    window?.pendo?.updateOptions({
      visitor: getTruthyValues({
        clientVersion: visitorClientVersion,
        featureFlags: visitorFeatureFlags,
        id: visitorId,
        jobTitles: visitorJobTitles,
        language: visitorLanguage,
        role: visitorRole,
        shellVersion: visitorShellVersion,
      }),
    });
  },
};
