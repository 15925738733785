import { FC, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { AnalyticsCommonTypes, AppointmentAnalyticsApi, AppointmentAnalyticsTypes } from '@frontend/api-analytics';
import { BarChartAppearance, Chart, sequentialChartColors } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { useAlert } from '@frontend/design-system';
import { useLocations } from '../../hooks';
import { queryKeys } from '../../query-keys';
import { defaultDateRangeMap, formatDateByTimezone, formatters } from '../../utils';
import { DemoChip } from '../demo-chip';
import { useAppointmentAnalyticsShallowStore, useAppointmentDemoData } from './hooks';

interface Props {
  onFetchStateChange: (isFetching: boolean) => void;
}

const defaultChartsData: AppointmentAnalyticsTypes.ChartsData = {
  allAppointmentCounts: {},
  patientsTypeCounts: {},
  pmsStatusCounts: {},
  weaveStatusCounts: {},
};

const weaveStatusColors = {
  'In Office': theme.colors.primary50,
  'No Show': theme.colors.critical50,
  Attempted: theme.colors.secondary.eggplant60,
  Canceled: theme.colors.critical30,
  Completed: theme.colors.success30,
  Confirmed: theme.colors.secondary.seaweed30,
  Unconfirmed: theme.colors.warning50,
  Unknown: theme.colors.neutral40,
};

const generatePMSGroupsData = (
  pmsData: Record<string, Record<string, number>>,
  labels: AnalyticsCommonTypes.StringRecord
) => {
  return Object.entries(pmsData).map(([name, value]) => {
    // The value tends to have less data points than the sibling values
    // We need to make sure that they are equal to avoid chart render issues
    const missingKeys = Object.keys(labels).reduce((acc, key) => (value[key] ? acc : { ...acc, [key]: 0 }), {});

    return {
      name,
      values: {
        ...Object.entries(value).reduce((acc, [name, value]) => ({ ...acc, [name]: value }), {}),
        ...missingKeys,
      },
    };
  });
};

export const AppointmentChartsView: FC<React.PropsWithChildren<Props>> = ({ onFetchStateChange }) => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const { filters } = useAppointmentAnalyticsShallowStore('filters');
  const queryString = useMemo(() => `${JSON.stringify(filters)}`, [filters]);
  const { locations } = useLocations();
  const { appointmentsDemoData, isDemoAccount } = useAppointmentDemoData();

  const { data, isFetching } = useScopedQuery({
    queryKey: queryKeys.appointmentAnalyticsCharts(`${queryString}-${isDemoAccount}-general-data`),
    queryFn: () =>
      isDemoAccount ? null : AppointmentAnalyticsApi.getAppointmentChartsData(filters, defaultDateRangeMap),
    onError: () => {
      alert.error(t("Couldn't load the appointment dashboard data. Please try again."));
    },
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      return isDemoAccount ? appointmentsDemoData?.chartsData : data;
    },
  });

  const getChartLabelText = (locationId: string, date: string) =>
    filters.LocationID?.length === 1 ? formatDateByTimezone(date) : locations[locationId];

  const generalizedData = useMemo(
    () =>
      data
        ? data.reduce((final, { aggregateData, AppointmentDate, LocationID }) => {
            const label = getChartLabelText(LocationID, AppointmentDate);
            const { PatientType, PMSStatus = {}, TotalApts, WeaveStatus = {} } = aggregateData;
            return {
              ...final,
              allAppointmentCounts: {
                ...final.allAppointmentCounts,
                [label]: (final.allAppointmentCounts[label] || 0) + TotalApts,
              },
              patientsTypeCounts: {
                ...final.patientsTypeCounts,
                [label]: {
                  ...final.patientsTypeCounts[label],
                  existingPatient:
                    (final.patientsTypeCounts[label]?.Existing_Patient || 0) + (PatientType?.Existing_Patient || 0),
                  newPatient: (final.patientsTypeCounts[label]?.New_Patient || 0) + (PatientType?.New_Patient || 0),
                  retained: (final.patientsTypeCounts[label]?.Retained || 0) + (PatientType?.Retained || 0),
                },
              },
              pmsStatusCounts: {
                ...final.pmsStatusCounts,
                [label]: {
                  ...final.pmsStatusCounts[label],
                  ...Object.entries(PMSStatus).reduce(
                    (f, [key, value]) => ({
                      ...f,
                      [key.trim() || 'other']: (final.pmsStatusCounts[label]?.[key] || 0) + value,
                    }),
                    {}
                  ),
                },
              },
              weaveStatusCounts: {
                ...final.weaveStatusCounts,
                [label]: {
                  ...final.weaveStatusCounts[label],
                  ...Object.entries(WeaveStatus).reduce(
                    (f, [key, value]) => ({ ...f, [key]: (final.weaveStatusCounts[label]?.[key] || 0) + value }),
                    {}
                  ),
                },
              },
            };
          }, defaultChartsData)
        : defaultChartsData,
    [data]
  );

  const allAppointmentsVolume = useMemo(
    () => ({
      groups: Object.entries(generalizedData.allAppointmentCounts).map(([name, value]) => ({
        name,
        values: {
          total: value,
        },
      })),
    }),
    [generalizedData.allAppointmentCounts]
  );

  const pmsStatusVolume = useMemo(() => {
    const labels = Object.values(generalizedData.pmsStatusCounts).reduce((acc, value) => {
      Object.keys(value).forEach((key) => {
        // Remove multiple consecutive spaces from the key
        const label = key.replace(/\s+/g, ' ');
        if (!acc[key]) {
          acc[key] = label;
        }
      });
      return acc;
    }, {} as AnalyticsCommonTypes.StringRecord);

    if ('other' in labels) {
      labels['other'] = t('Other');
    }

    return {
      data: {
        groups: generatePMSGroupsData(generalizedData.pmsStatusCounts, labels),
      },
      labels,
    };
  }, [generalizedData.pmsStatusCounts]);

  const pmsStatusConstants = useMemo(() => {
    return {
      colors: Object.keys(pmsStatusVolume.labels).reduce(
        (acc, cur, index) => ({ ...acc, [cur]: sequentialChartColors[index] ?? theme.colors.neutral40 }),
        {}
      ),
      labels: pmsStatusVolume.labels,
    };
  }, [pmsStatusVolume]);

  const weaveStatusVolume = useMemo(() => {
    const labels = Object.values(generalizedData.weaveStatusCounts).reduce((acc, value) => {
      Object.keys(value).forEach((key) => {
        // Remove multiple consecutive spaces from the key
        const label = key.replace(/\s+/g, ' ');
        if (!acc[key]) {
          acc[key] = label;
        }
      });
      return acc;
    }, {} as AnalyticsCommonTypes.StringRecord);

    return {
      data: {
        groups: generatePMSGroupsData(generalizedData.weaveStatusCounts, labels),
      },
    };
  }, [generalizedData.weaveStatusCounts]);

  const patientsTypeVolume = useMemo(
    () => ({
      groups: Object.entries(generalizedData.patientsTypeCounts).map(([name, value]) => ({
        name,
        values: Object.entries(value).reduce(
          (acc, [name, value]) => ({
            ...acc,
            [name]: value,
          }),
          {}
        ),
      })),
    }),
    [generalizedData.patientsTypeCounts]
  );

  const commonChartAppearanceProps: BarChartAppearance = useMemo(
    () => ({
      collectiveTooltip: true,
      showGridLines: true,
      showXAxis: true,
      showYAxis: true,
    }),
    []
  );

  const demoChip = useMemo(() => (isDemoAccount ? <DemoChip /> : null), [isDemoAccount]);

  useEffect(() => {
    onFetchStateChange(isFetching);
  }, [isFetching]);

  return (
    <div css={styles.wrapper}>
      <Chart colors={{ total: theme.colors.secondary.seaweed30 }} isLoading={isFetching} labels={{ total: t('Total') }}>
        <Chart.Header leftElement={demoChip} title={t('All Appointments Count')} trackingIdHelper='all-appointments' />
        <Chart.BarChart
          appearance={commonChartAppearanceProps}
          data={allAppointmentsVolume}
          formatValue={formatters.value.format}
        />
      </Chart>

      <Chart
        colors={{ ...pmsStatusConstants.colors, ...weaveStatusColors, other: theme.colors.neutral40 }}
        isLoading={isFetching}
        labels={{ ...pmsStatusConstants.labels, other: t('Other') }}
      >
        <Chart.Header
          leftElement={demoChip}
          title={t('Appointment Status Count - PMS')}
          trackingIdHelper='appointment-status-pms'
        />
        <Chart.Legends />
        <Chart.BarChart
          appearance={commonChartAppearanceProps}
          data={pmsStatusVolume.data}
          formatValue={formatters.value.format}
        />
      </Chart>

      <Chart
        colors={weaveStatusColors}
        isLoading={isFetching}
        labels={{
          'In Office': t('In Office'),
          'No Show': t('No Show'),
          Attempted: t('Attempted'),
          Canceled: t('Canceled'),
          Completed: t('Completed'),
          Confirmed: t('Confirmed'),
          unconfirmed: t('Unconfirmed'),
          Unconfirmed: t('Unconfirmed'),
          Unknown: t('Unknown'),
        }}
      >
        <Chart.Header
          leftElement={demoChip}
          title={t('Appointment Status Count - Weave')}
          trackingIdHelper='appointment-status-weave'
        />
        <Chart.Legends />
        <Chart.BarChart
          appearance={commonChartAppearanceProps}
          data={weaveStatusVolume.data}
          formatValue={formatters.value.format}
        />
      </Chart>

      <Chart
        colors={{
          existingPatient: theme.colors.primary50,
          newPatient: theme.colors.secondary.eggplant60,
          retained: theme.colors.secondary.seaweed30,
        }}
        isLoading={isFetching}
        labels={{
          existingPatient: t('Existing Patients'),
          newPatient: t('New Patients'),
          retained: t('Retained Patients'),
        }}
      >
        <Chart.Header leftElement={demoChip} title={t('Patient Type')} trackingIdHelper='patient-type' />
        <Chart.Legends />
        <Chart.BarChart
          appearance={commonChartAppearanceProps}
          data={patientsTypeVolume}
          formatValue={formatters.value.format}
        />
      </Chart>
    </div>
  );
};

const styles = {
  wrapper: css`
    height: 100%;
    overflow: auto;
  `,
};
