import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { createGetSpecificChildren } from '@frontend/design-system';
import { ActionProps } from './action';

const heading = (hasAction: boolean) => {
  return [
    {
      display: 'grid',
      gridTemplateRows: 'auto auto',
      alignItems: 'center',
      gap: theme.spacing(1),
    },
    hasAction
      ? {
          gridTemplateAreas: '"title actions" "subtitle subtitle"',
          gridTemplateColumns: 'minmax(150px, 1fr) auto',
        }
      : {
          gridTemplateAreas: '"title title" "subtitle subtitle"',
          gridTemplateColumns: '[title] auto [subtitle] auto',
        },
    {
      [`@container (max-width:  ${breakpoints.xsmall.max}px)`]: {
        gridTemplateAreas: '"title" "subtitle" "actions"',
        gridTemplateRows: 'auto auto auto',
        gridTemplateColumns: 'auto',
      },
    },
  ];
};

export type HeadingProps = React.PropsWithChildren<object>;

export function Heading({ children }: HeadingProps) {
  const getChild = createGetSpecificChildren<'Action', ActionProps>(children, false);

  const hasAction = getChild('Action').length > 0;

  return <div css={heading(hasAction)}>{children}</div>;
}
