import { useMemo } from 'react';
import { UpdateDepartmentCallRouteRequest } from '@weave/schema-gen-ts/dist/schemas/phone-exp/departments/v2/department.pb';
import { useQueryClient, UseMutationOptions } from 'react-query';
import { DepartmentsApi, DepartmentsTypes } from '@frontend/api-departments';
import { ScheduleApi, ScheduleTypes } from '@frontend/api-schedule';
import { useMutation } from '@frontend/react-query-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { queryKeys } from '../../../../query-keys';

export const useUpdateVoicemailOverride = (
  departmentId: string,
  options: UseMutationOptions<unknown, unknown, DepartmentsTypes.UpdateVMOTypes['input']>
) => {
  const queryClient = useQueryClient();
  const { singleLocationId: locationId } = useAppScopeStore();

  return useMutation(
    (vmData: DepartmentsTypes.UpdateVMOTypes['input']) => DepartmentsApi.updateVoicemailOverride(vmData),
    {
      ...options,
      onSuccess: (response, vmData, context) => {
        const vmOverrideKey = [locationId, ...queryKeys.departmentVMOverride(departmentId)];
        queryClient.setQueryData(vmOverrideKey, () => {
          return { vmoSetting: response.vmoSettings };
        });
        if (options?.onSuccess) {
          options.onSuccess(response, vmData, context);
        }
      },
    }
  );
};

export const useUpdateCallForwardingOverride = (
  departmentId: string,
  options: UseMutationOptions<unknown, unknown, DepartmentsTypes.UpdateFWDTypes['input']>
) => {
  const queryClient = useQueryClient();
  const { singleLocationId: locationId } = useAppScopeStore();

  return useMutation(
    (data: DepartmentsTypes.UpdateFWDTypes['input']) => DepartmentsApi.updateCallForwardingOverride(data),
    {
      ...options,
      onSuccess: (response, data, context) => {
        const callForwardingKey = [locationId, ...queryKeys.departmentForwardingOverride(departmentId)];
        queryClient.setQueryData<DepartmentsTypes.UpdateFWDTypes['input'] | undefined>(
          callForwardingKey,
          (cachedData) => {
            return { ...cachedData, ...data };
          }
        );
        if (options?.onSuccess) {
          options.onSuccess(response, data, context);
        }
      },
    }
  );
};

export const useUpdateScheduleWithRouting = (
  departmentId: string,
  options?: UseMutationOptions<unknown, unknown, ScheduleTypes.ScheduleWithRouting>
) => {
  const { selectedLocationIds } = useAppScopeStore();
  const locationId = useMemo(() => selectedLocationIds[0], [selectedLocationIds]);

  const queryClient = useQueryClient();

  return useMutation(
    (schedule: ScheduleTypes.ScheduleWithRouting | undefined) => {
      if (!schedule) {
        throw new Error('post request failed: Schedule cannot be undefined');
      }
      const { name, rules, type, ...rest } = schedule;
      return ScheduleApi.updateScheduleWithRouting({
        ...rest,
        departmentId: departmentId,
      } as UpdateDepartmentCallRouteRequest);
    },
    {
      ...options,
      onSuccess: (response, schedule, context) => {
        const departmentschedulesKey = [locationId, ...queryKeys.listDepartmentSchedules(departmentId)];

        // Invalidate so that department list updates
        queryClient.invalidateQueries(departmentschedulesKey);

        if (options?.onSuccess) {
          options.onSuccess(response, schedule, context);
        }
      },
    }
  );
};
