import { css } from '@emotion/react';
import { sendInvoiceReceipt } from '@frontend/api-invoices';
import { useTranslation } from '@frontend/i18n';
import { useMerchant } from '@frontend/payments-hooks';
import {
  ListField,
  Modal,
  useFormField,
  useModalControl,
  useModalLoadingState,
  useAlert,
} from '@frontend/design-system';
import { useSendRefundReceipt } from '../receipt-data-fetching';
import { BaseReceiptModalProps } from '../ViewReceiptModal';

type SendReceiptModalProps = ReturnType<typeof useModalControl>['modalProps'] & BaseReceiptModalProps;

export const SendReceiptModal = ({ invoice, receiptType, refundId, ...props }: SendReceiptModalProps) => {
  const { t } = useTranslation('payments');
  return (
    <Modal {...props}>
      <Modal.Header>{t('Send Receipt Email')}</Modal.Header>
      <EmailListBody invoice={invoice} closeModal={props.onClose} receiptType={receiptType} refundId={refundId} />
    </Modal>
  );
};

type EmailListBodyProps = {
  closeModal: () => void;
} & BaseReceiptModalProps;
const EmailListBody = ({ closeModal, invoice, refundId, receiptType }: EmailListBodyProps) => {
  const { t } = useTranslation('payments');
  //FIELDS
  const fieldProps = useFormField({
    type: 'list',
    maxAllowed: 3,
    minRequired: 1,
  });

  //REQUEST
  const { paymentsUrl } = useMerchant();
  const alert = useAlert();
  const { setLoading } = useModalLoadingState();

  //REFUND RECEIPT
  const { sendRefundReceipt } = useSendRefundReceipt(invoice.payment?.paymentId, refundId);

  const sendReceipts = async () => {
    if (!paymentsUrl) {
      console.error(t('Tried to send receipt, but payments url is not set'));
      return;
    }
    try {
      setLoading(true);
      if (receiptType === 'refund') {
        await Promise.all(fieldProps.value.map((email) => sendRefundReceipt(email)));
      } else {
        await sendInvoiceReceipt(paymentsUrl, invoice.id, fieldProps.value);
      }
      setLoading(false);
      closeModal();
      alert.success(t('Receipt has been sent'));
    } catch (err: any) {
      setLoading(false);
      closeModal();
      alert.error(t('Error sending receipt'));
    }
  };

  return (
    <Modal.Body
      css={css`
        overflow: visible;
      `}
    >
      <ListField
        name='receipt-email-list'
        label={t('Add an email')}
        helperText={t('Note: Only 3 email addressees may be added.')}
        {...fieldProps}
        fieldType='email'
      />
      <Modal.Actions
        onSecondaryClick={closeModal}
        onPrimaryClick={sendReceipts}
        disablePrimary={!fieldProps.value.length}
        primaryLabel={t('Send')}
      />
    </Modal.Body>
  );
};
