import { ReactNode, forwardRef, useEffect, useState } from 'react';
import { useBulkListContext } from '@frontend/bulk-list-provider';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Checkbox, useControlledField, useAlert } from '@frontend/design-system';

type MessageBubbleRowWrapperProps = {
  children: ReactNode;
  id: string;
  optedOut: boolean;
  disableSelection?: boolean;
  hasMedia?: boolean;
  highlight?: boolean;
};

export const MessageBubbleRowWrapper = forwardRef<HTMLDivElement, MessageBubbleRowWrapperProps>(
  ({ children, id, optedOut, disableSelection, hasMedia, highlight = false }, ref) => {
    const { t } = useTranslation('inbox');
    const alert = useAlert();
    const { isActive: selectionIsActive, toggleItemSelection, selectedItems, hasSelectedAll } = useBulkListContext();
    const isSelected = !disableSelection && (hasSelectedAll || selectedItems.includes(id));
    const checkboxProps = useControlledField({
      type: 'checkbox',
      value: !!isSelected,
      onChange: () => handleSelectionToggle(),
      hidden: !selectionIsActive,
    });
    const fixedCheckboxProps = {
      ...checkboxProps,
      // We have to cast the touched prop to a string because it gets passed to the DOM which logs an error if it's a boolean
      touched: checkboxProps.touched ? 'true' : 'false',
    };
    const [showHighlight, setShowHighlight] = useState(false);
    const [addTopPadding, setAddTopPadding] = useState(highlight);

    const handleSelectionToggle = () => {
      if (!disableSelection) toggleItemSelection(id);
    };

    const flashHighlight = (removeTopPadding?: boolean) => {
      setShowHighlight(true);
      setTimeout(() => {
        setShowHighlight(false);
        if (removeTopPadding) {
          setTimeout(() => {
            setAddTopPadding(false);
          }, 300);
        }
      }, 1000);
    };

    useEffect(() => {
      if (highlight) {
        setTimeout(() => {
          flashHighlight();
        }, 1000);
      }
    }, [highlight]);

    return (
      <div
        ref={ref}
        id={id}
        css={[
          {
            padding: theme.spacing(0, 2),
            display: 'flex',
            alignItems: 'center',
            transition: 'background-color 300ms ease-in-out, gap 300ms ease-in-out',
            opacity: optedOut ? 0.5 : 1,
          },
          selectionIsActive &&
            !disableSelection && {
              gap: theme.spacing(2),
              cursor: 'pointer',
              paddingTop: theme.spacing(3),
              marginTop: theme.spacing(-3),
              ':hover': { backgroundColor: theme.colors.neutral10 },
            },
          !selectionIsActive &&
            highlight && [
              { transition: 'background-color 300ms ease-in-out' },
              showHighlight && {
                backgroundColor: theme.colors.warning5,
              },
              addTopPadding && {
                paddingTop: theme.spacing(3),
                marginTop: theme.spacing(-3),
              },
            ],
        ]}
        onClick={
          disableSelection || !selectionIsActive
            ? undefined
            : () => {
                if (hasMedia && !isSelected) {
                  alert.warning(t('The images in this message will not be sent to your PMS.'));
                }
                handleSelectionToggle();
              }
        }
      >
        <div
          css={[
            {
              marginBottom: theme.spacing(3),
              transition: 'transform 300ms ease-in-out, width 300ms ease-in-out',
              transform: `translateX(0)`,
              width: theme.spacing(3),
              overflow: 'hidden',
            },
            !selectionIsActive && { transform: `translateX(-${theme.spacing(4)})`, width: 0 },
          ]}
        >
          <Checkbox
            name={id}
            css={{ transform: `translateY(${theme.spacing(3)})` }}
            {...fixedCheckboxProps}
            error={false}
            disabled={disableSelection}
          />
        </div>
        {children}
      </div>
    );
  }
);
