import { useEffect } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { getTodaysDate } from '@frontend/date';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { DatePickerField, IconButton, SecondaryButton, useFormField } from '@frontend/design-system';
import { useCalendarViewV3HeaderFilterShallowStore } from '../../stores/use-calendar-view-v3-header-filter-store';

const DATE_FORMAT = 'MM/DD/YYYY';

type DateSelectionProps = {
  width?: number;
};

export const CalendarViewHeaderDateSelector = ({ width = 240 }: DateSelectionProps) => {
  const { t } = useTranslation('schedule');

  const { selectedCalendarDate, setSelectedCalendarDate } = useCalendarViewV3HeaderFilterShallowStore(
    'selectedCalendarDate',
    'setSelectedCalendarDate'
  );

  const dateField = useFormField(
    {
      type: 'datePicker',
      value: selectedCalendarDate,
    },
    [selectedCalendarDate]
  );

  // useEffect to set the selected date in the store when the dateField value changes
  useEffect(() => {
    if (dateField.value) {
      setSelectedCalendarDate(dateField.value);
    }
  }, [dateField.value]);

  const handleTodayDayClick = () => {
    setSelectedCalendarDate(getTodaysDate(DATE_FORMAT));
  };

  const handlePreviousDayClick = () => {
    setSelectedCalendarDate(dayjs(String(selectedCalendarDate)).subtract(1, 'day').format(DATE_FORMAT));
  };

  const handleNextDayClick = () => {
    setSelectedCalendarDate(dayjs(String(selectedCalendarDate)).add(1, 'day').format(DATE_FORMAT));
  };

  return (
    <>
      <div css={{ width }}>
        <div css={{ display: 'flex', justifyContent: 'space-between' }}>
          <SecondaryButton
            css={todayButtonStyles}
            onClick={handleTodayDayClick}
            disabled={dayjs(String(selectedCalendarDate)).isSame(dayjs(getTodaysDate('MM/DD/YYYY')))}
          >
            {t('Today')}
          </SecondaryButton>
          <div css={{ display: 'flex', justifyContent: 'row' }}>
            <IconButton
              showLabelOnHover
              label={t('Previous Day')}
              css={previousDayButtonStyles}
              onClick={handlePreviousDayClick}
            >
              <Icon name='caret-left' size={24} />
            </IconButton>
            <IconButton showLabelOnHover label={t('Next Day')} css={nextDayButtonStyles} onClick={handleNextDayClick}>
              <Icon name='caret-right' size={24} />
            </IconButton>
          </div>
        </div>

        <DatePickerField {...dateField} name='date' label='' css={{ height: theme.spacing(8), fontWeight: 'bold' }} />
      </div>
    </>
  );
};

const todayButtonStyles = css({
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.colors.neutral30}`,
  fontWeight: 'bold',
  marginBottom: theme.spacing(1),
  width: 'fit-content',
});

const previousDayButtonStyles = css({
  height: theme.spacing(4),
  width: theme.spacing(4),
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.colors.neutral30}`,
  fontWeight: 'bold',
  margin: theme.spacing(0, 1, 1, 0),
});

const nextDayButtonStyles = css({
  height: theme.spacing(4),
  width: theme.spacing(4),
  backgroundColor: theme.colors.white,
  border: `1px solid ${theme.colors.neutral30}`,
  fontWeight: 'bold',
});
