import { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Practitioner } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/practitioner.pb';
import { i18next } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Modal, ModalControlModalProps, Stepper } from '@frontend/design-system';
import { useAddPractitionerV3Context } from '../../context/AddPractitionerV3Context';
import { AddPractitionerStepperFirstStep } from './AddPractitionerStepper/AddPractitionerStepperFirstStep';
import { AddPractitionerStepperSecondStep } from './AddPractitionerStepper/AddPractitionerStepperSecondStep';

type AddPractitionerModalProps = {
  modalProps: ModalControlModalProps;
};

const stepTitleText = {
  1: i18next.t('Provider Details'),
  2: i18next.t('Working Hours'),
};

const modalWidth = 900;

export const AddPractitionerModal = ({ modalProps }: AddPractitionerModalProps) => {
  const { setPractitionerDetails } = useAddPractitionerV3Context();
  const [isCreatePractitionerError, setIsCreatePractitionerError] = useState(false);

  useEffect(() => {
    return () => {
      setPractitionerDetails({} as Practitioner);
      setIsCreatePractitionerError(false);
    };
  }, []);

  return (
    <Modal {...modalProps} css={{ padding: 0 }} minWidth={modalWidth} maxWidth={modalWidth}>
      <Modal.Body>
        <Stepper
          stepTitleText={stepTitleText}
          isVerticalSingleView
          css={css`
            width: 100%;
            max-width: 830px;
            .step-container .step-card {
              border-left: 1px solid ${theme.colors.neutral20};
            }
            .stepper-vertical {
              padding: ${theme.spacing(0.5)} ${theme.spacing(3)};
            }
          `}
        >
          <Stepper.Card>
            <AddPractitionerStepperFirstStep setIsCreatePractitionerError={setIsCreatePractitionerError} />
          </Stepper.Card>
          <Stepper.Card>
            <AddPractitionerStepperSecondStep isCreatePractitionerError={isCreatePractitionerError} />
          </Stepper.Card>
        </Stepper>
      </Modal.Body>
    </Modal>
  );
};
