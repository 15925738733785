import { memo } from 'react';
import { CallIntelLocationComparisonCharts, CallIntelSummaryCharts, CallIntelSummaryChartsProps } from '..';
import { useCallIntelShallowStore } from '../hooks';

export const CallIntelOverviewPanel = memo((props: CallIntelSummaryChartsProps) => {
  const { activeMultiView = 'summary', filters } = useCallIntelShallowStore('activeMultiView', 'filters');

  return (
    <>
      {activeMultiView === 'summary' ? (
        <CallIntelSummaryCharts {...props} />
      ) : (
        <CallIntelLocationComparisonCharts
          {...props}
          betweenLocations={activeMultiView === 'betweenLocations'}
          key={JSON.stringify(filters)}
        />
      )}
    </>
  );
});

CallIntelOverviewPanel.displayName = 'CallIntelOverviewPanel';
