import { css } from '@emotion/react';
import { isFunction } from 'lodash-es';
import { PaymentType } from '@frontend/api-invoices';
import { PaymentMethod } from '@frontend/api-payment-plans';
import { useTranslation } from '@frontend/i18n';
import { CardBrand, getCardBrand, getExpirationDate } from '@frontend/payments-card-brand';
import { prettify } from '@frontend/string';
import { theme } from '@frontend/theme';
import { Text, TextLink, useTooltip } from '@frontend/design-system';

const styles = {
  cardInfoItem: (isPmSelected = false, isDisabled = false, variant = 'normal', isInvalid = false) => css`
    display: flex;
    flex-flow: row;
    min-width: ${theme.spacing(44)};
    border: ${isInvalid
      ? `1px solid ${theme.colors.critical20}`
      : isDisabled
      ? `1px solid ${theme.colors.neutral20}`
      : isPmSelected
      ? `2px solid ${theme.colors.primary50}`
      : variant === 'light'
      ? `1px solid ${theme.colors.neutral30}`
      : `1px solid ${theme.colors.neutral50}`};
    border-radius: ${theme.borderRadius.small};
    height: 80px;
    padding: ${theme.spacing(1, 2)};
    align-items: center;
    gap: ${theme.spacing(2)};
    cursor: pointer;
    justify-content: space-between;
  `,
  cardInfoContainer: css`
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: ${theme.spacing(2)};
  `,
  cardInfo: (isPmSelected = false, isDisabled = false) => css`
    p {
      color: ${isDisabled ? theme.colors.neutral20 : isPmSelected ? theme.colors.primary60 : theme.font.colors.default};
    }
  `,
  cardDetails: css`
    display: flex;
    flex: row;
    gap: ${theme.spacing(1)};
  `,
  accountName: css`
    text-transform: capitalize;
  `,
  cardBrand: (isDisabled = false) => css`
    ${isDisabled && `opacity: 0.2;`}
  `,
  changeButton: css`
    margin-right: ${theme.spacing(1)};
  `,
  errorMsg: css`
    margin-top: ${theme.spacing(1)};
  `,
};

export const PaymentMethodItem = ({
  paymentMethod: pm,
  disableACHPaymentMethods,
  selectedPM,
  setSelectedPM,
  achDisabledMessage,
  onChange,
  variant = 'normal',
  showError = false,
  trackingId = 'paymentmethoditem',
}: {
  paymentMethod: PaymentMethod;
  disableACHPaymentMethods: boolean;
  selectedPM?: PaymentMethod;
  setSelectedPM?: (paymentMethod: PaymentMethod) => void;
  achDisabledMessage: string;
  onChange?: () => void;
  variant?: 'normal' | 'light';
  showError?: boolean;
  trackingId?: string;
}) => {
  const { t } = useTranslation('payments');
  const { Tooltip, tooltipProps, triggerProps } = useTooltip();

  const isSelected = selectedPM?.id === pm.id;
  const isDisabled = disableACHPaymentMethods && pm.type === 'us_bank_account';
  const isInvalid = showError && isDisabled;
  const last4 = pm.type === 'us_bank_account' ? pm?.bankAccount?.last4 : pm?.card?.last4;
  const cardOrAccountName =
    pm.type === 'us_bank_account'
      ? pm?.bankAccount.bankName.toLowerCase()
      : prettify(pm?.card?.brand, {
          outputCase: 'Sentence case',
        });
  const cardBrand = pm.type === 'us_bank_account' ? PaymentType.ACH : getCardBrand(pm.card.brand);

  return (
    <div>
      <div
        key={pm.id}
        css={styles.cardInfoItem(isSelected, isDisabled, variant, isInvalid)}
        onClick={() => !isDisabled && setSelectedPM?.(pm)}
        {...triggerProps}
      >
        <div css={styles.cardInfoContainer}>
          <CardBrand brand={cardBrand} style={styles.cardBrand(isDisabled)} />
          <div css={styles.cardInfo(isSelected, isDisabled)}>
            <Text weight={variant === 'light' ? 'semibold' : 'bold'} css={styles.accountName}>
              {cardOrAccountName}
            </Text>
            <div css={styles.cardDetails}>
              <Text weight={variant === 'light' ? 'semibold' : 'bold'}>{`*${last4}`}</Text>
              {pm.type === 'card' && (
                <Text weight={variant === 'light' ? 'light' : 'medium'}>
                  {getExpirationDate(pm.card.expYear, pm.card.expMonth)}
                </Text>
              )}
            </div>
          </div>
        </div>
        {isDisabled && <Tooltip {...tooltipProps}>{achDisabledMessage}</Tooltip>}

        {isFunction(onChange) && (
          <TextLink trackingId={`${trackingId}-btn-change`} onClick={onChange} css={styles.changeButton}>
            {t('Change')}
          </TextLink>
        )}
      </div>
      {isInvalid && (
        <Text color='error' weight='light' size='small' css={styles.errorMsg}>
          {achDisabledMessage}
        </Text>
      )}
    </div>
  );
};
