import { css } from '@emotion/react';
import { Trans, useTranslation } from '@frontend/i18n';
import { useMerchant } from '@frontend/payments-hooks';
import { theme } from '@frontend/theme';
import { Text, TextLink } from '@frontend/design-system';
import { PayoutsPausingStatus, useGetPayoutsPausingStatus } from '../../merchant-status';

export const ContactPayments = () => {
  const { t } = useTranslation('payments', { keyPrefix: 'settings' });
  const { payoutsEnabled: payouts, chargesEnabled: charges, stripeId } = useMerchant();
  const { message: payoutsPausingMessage, payoutsPausingStatus } = useGetPayoutsPausingStatus();
  if (payouts && charges && payoutsPausingStatus !== PayoutsPausingStatus.Error) {
    return null;
  }
  let message = '';
  let mailLink = '';

  if (payoutsPausingStatus === PayoutsPausingStatus.Error) {
    message = payoutsPausingMessage;
  } else if (payouts && !charges) {
    message = t('Your Stripe account needs more verification and some payment features are temporarily paused.');
    mailLink = `mailto:payments@getweave.com?subject=${t('Inactive Payments on {{stripeId}}', { stripeId })}`;
  } else if (!payouts && charges) {
    message = t('You are currently unable to receive payouts to your bank account.');
    mailLink = `mailto:payments@getweave.com?subject=${t('Inactive Payouts on {{stripeId}}', { stripeId })}`;
  } else if (!payouts && !charges) {
    message = t(
      'Your Stripe account needs more verification. Payouts and some payment features are temporarily paused.'
    );
    mailLink = `mailto:payments@getweave.com?subject=${t('Inactive Payments and Payouts on {{stripeId}}', {
      stripeId,
    })}`;
  }

  return (
    <div
      css={css`
        padding: ${theme.spacing(1)};
        background-color: ${theme.colors.neutral5};
        margin-bottom: ${theme.spacing(2)};
        p {
          margin-bottom: ${theme.spacing(1)};
        }
        p:last-child {
          margin: 0;
        }
      `}
    >
      <Text size='medium'>
        <Trans t={t}>
          {{ message }} Please follow the steps outlined{' '}
          <TextLink
            to='https://www.weavehelp.com/troubleshooting-payments#inactive-payments-payouts'
            target='_blank'
            rel='noopener noreferrer'
          >
            here.
          </TextLink>
        </Trans>
      </Text>
      <Text size='medium'>
        <Trans t={t}>
          If you are still experiencing issues, contact{' '}
          <TextLink to={mailLink} rel='noopener noreferrer'>
            Weave Payments
          </TextLink>
          .
        </Trans>
      </Text>
    </div>
  );
};
