import { ListThreadsCountIO } from '../types';
import { useManualSmsScheduledV1Queries } from './use-manual-sms-scheduled-v1-queries';
import {
  UseManualSmsScheduledV1QueryEndpointArgs,
  useManualSmsScheduledV1Query,
} from './use-manual-sms-scheduled-v1-query';

/**
 * A hook that returns a query for the `ListThreadsCount` endpoint.
 * @param request The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param fallbackDataOnError (optional) The fallback data to use when an error occurs. This will not be persisted to the cache.
 * @param requestKeysToOmitFromQueryKey (optional) - An array of keys to omit from the query key. This is useful for cases where the request object contains keys that cause issues
 * when included in the query key, such as the current timestamp.
 */
export const useListThreadsCountQuery = <E = unknown, D = ListThreadsCountIO['output']>(
  args: UseManualSmsScheduledV1QueryEndpointArgs<'ListThreadsCount', E, D>
) =>
  useManualSmsScheduledV1Query({
    endpointName: 'ListThreadsCount',
    ...args,
  });

/**
 * A hook that returns an array of queries for the `ListThreadsCount` endpoint.
 * It takes an array of objects with the following properties:
 * @param request The request object to pass to the query.
 * @param options (optional) The options to pass to `useQuery`.
 * @param httpOptions (optional) The http options to pass to the schema function.
 * @param fallbackDataOnError (optional) The fallback data to use when an error occurs. This will not be persisted to the cache.
 * @param requestKeysToOmitFromQueryKey (optional) - An array of keys to omit from the query key. This is useful for cases where the request object contains keys that cause issues
 * when included in the query key, such as the current timestamp.
 */
export const useListThreadsCountQueries = <E = unknown, D = ListThreadsCountIO['output']>(
  argsArr: UseManualSmsScheduledV1QueryEndpointArgs<'ListThreadsCount', E, D>[]
) =>
  useManualSmsScheduledV1Queries(
    argsArr.map((args) => ({
      endpointName: 'ListThreadsCount',
      ...args,
    }))
  );
