export const queryKeys = {
  base: ['schedule'] as const,
  apptReqConfig: (locationId: string) => [...queryKeys.base, 'apptReqConfigData', locationId],
  confirmationTemplate: (locationId?: string) => [
    ...queryKeys.base,
    locationId || '',
    'textTemplates',
    'confirmationTemplate',
  ],
  officeClosedTimes: (locationId: string) => [...queryKeys.base, locationId, 'officeClosedTimes'],
  onlineSchedulingRequestTemplate: (locationId?: string) => [
    ...queryKeys.base,
    locationId || '',
    'textTemplates',
    'onlineSchedulingRequestTemplate',
  ],
  textConnectTemplates: () => [...queryKeys.base, 'textConnectTemplates'],
  wellnessForms: () => [...queryKeys.base, 'wellnessForms'],
  officeExceptions: (locationIds?: string[]) => [...queryKeys.base, 'officeExceptions', ...(locationIds ?? [])],
  providerUnavailability: (selectedProviderId: string, locationId: string) => [
    ...queryKeys.base,
    'providerUnavailability',
    selectedProviderId,
    locationId,
  ],
  providerExceptions: (selectedProviderId?: string) => [...queryKeys.base, 'providerExceptions', selectedProviderId],
  quickFillRecipients: (searchTerm?: string, pageNumber?: number, pageToken?: number, locationId?: string) => [
    ...queryKeys.base,
    'scheduleQuickFillList',
    searchTerm,
    pageNumber,
    pageToken,
    locationId,
  ],
  quickFillTemplate: (selectedTime?: string) => [...queryKeys.base, 'scheduleQuickFillListTemplate', selectedTime],
  appointments: (locationIds?: string[], startDate?: string, endDate?: string) => [
    ...queryKeys.base,
    'scheduleAppointments',
    ...(locationIds ?? []),
    startDate,
    endDate,
  ],
  appointmentsMulti: (locationIds?: string[]) => [...queryKeys.base, 'scheduleAppointments', ...(locationIds ?? [])],
  providersList: (locationIds?: string[]) => [...queryKeys.base, 'scheduleProvidersList', ...(locationIds ?? [])],
  officeHours: (locationId?: string) => [...queryKeys.base, 'scheduleOfficeHours', locationId],
  officeHoursForMultipleLocationIds: (locationIds: string[]) => [
    ...queryKeys.base,
    'scheduleOfficeHours',
    ...locationIds,
  ],
  appointmentTypes: (locationId: string) => [...queryKeys.base, 'scheduleAppointmentTypes', locationId],
};
