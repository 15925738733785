import { css } from '@emotion/react';
import { Button, DialerInput, Divider, KeypadButtons, Page } from '@frontend/generic-dialpad-accessories';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import { AutocompleteWraps } from '../components/autocomplete/auto-complete-wrap';
import { PageHeaderWraps } from '../components/generic/page-header-wraps';
import { SettingsRow } from '../components/settings-row';
import { CallDuration } from '../hooks/use-call-duration';
import { useCallerInfo } from '../hooks/use-caller-info';
import { useDialer } from '../hooks/use-dialer';
import { useDialerAutocomplete } from '../hooks/use-dialer-autocomplete';
import { useSoftphoneCallState } from '../providers/softphone-call-state-provider';
import { useSoftphoneCallActions } from '../providers/softphone-call-state-provider/softphone-call-actions.provider';
import { useSoftphoneDialer } from '../providers/softphone-dialer-provider';
import { useSoftphoneDirectory } from '../providers/softphone-directory-provider';
import { useSoftphoneRouter } from '../providers/softphone-router-provider';
import { useSoftphoneWidgetControl } from '../providers/widget-control-provider';
import { isOutgoingCall } from '../types';

export const PageCallAddCall = () => {
  const placeCallFromDialer = useSoftphoneCallActions((ctx) => ctx.placeCallFromDialer);
  const placeCallDirect = useSoftphoneCallActions((ctx) => ctx.placeCallDirect);
  const currentDial = useSoftphoneDialer((ctx) => ctx.currentDial);
  const setCurrentDial = useSoftphoneDialer((ctx) => ctx.setCurrentDial);
  const isLoading = useSoftphoneDirectory((ctx) => ctx.isLoading);
  const primaryCall = useSoftphoneCallState((ctx) => ctx.primaryCall);
  const navigate = useSoftphoneRouter((ctx) => ctx.setSingleCallRoute);
  const { t } = useTranslation('softphone');
  const callerInfo = useCallerInfo({
    uri: primaryCall?.remoteParty.uri ?? '',
    displayName: primaryCall?.remoteParty.displayName,
  });
  const primaryCallStartTime =
    primaryCall && isOutgoingCall(primaryCall) ? primaryCall.placedAt : primaryCall?.establishedAt;
  const isMobile = useSoftphoneWidgetControl((ctx) => ctx.isMobile);

  if (!primaryCall) {
    return null;
  }

  const placeCall = (direct?: string) => {
    const cb = () => {
      dialer.dispatch({ type: 'reset' });
      navigate('home');
    };
    return direct ? placeCallDirect(direct).then(cb) : placeCallFromDialer().then(cb);
  };

  const dialer = useDialer({
    onSubmit: placeCall,
  });

  const autocompleteProps = useDialerAutocomplete({
    currentDial,
    dispatch: dialer.dispatch,
  });

  const autocompleteInputProps = {
    currentDial,
    inputState: dialer.inputState,
    isDirectoryLoading: isLoading,
    ...autocompleteProps,
  };

  const dialerInputProps = {
    currentDial,
    setCurrentDial,
    ...dialer,
  };

  return (
    <Page>
      <PageHeaderWraps hasUnderline={true} hasBackButton={!!isMobile}>
        {t('Add New Call')}
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
          `}
        >
          <Text weight='bold' color='white'>
            {callerInfo.title}
          </Text>
          <CallDuration connectedAt={primaryCallStartTime} />
        </div>
      </PageHeaderWraps>
      <Page.Body>
        <form onSubmit={(e) => e.preventDefault()}>
          <DialerInput focusOnMount={false} {...dialerInputProps}>
            <AutocompleteWraps
              hideAutocompleteOnBlur={true}
              hideAutocompleteOnEmpty={false}
              {...autocompleteInputProps}
            />
          </DialerInput>
          <KeypadButtons onDialCharClick={(char) => dialer.dispatch({ type: 'add', payload: `${char}` })} />
          <Button.Round
            disabled={false}
            iconName='phone'
            css={css`
              margin: auto;
              margin-bottom: ${theme.spacing(1)};
            `}
            type='submit'
            color='green'
          ></Button.Round>
        </form>
      </Page.Body>
      <Page.Footer>
        <Divider />
        <SettingsRow />
      </Page.Footer>
    </Page>
  );
};
