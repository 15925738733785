import dayjs from 'dayjs';
import { PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { demoDataUtils } from '../../../utils';

export const requiredPatientInfoFields = {
  Birthdate: '',
  Email: '',
  Gender: '',
  HomePhone: '',
  MobilePhone: '',
  nextAppointmentDate: '',
  PMID: '',
  PreferredName: '',
  WorkPhone: '',
};

export const generateHistoricalData = (totals: number[]): PracticeAnalyticsTypes.HistoricalDataTotal[] => {
  const startDate = dayjs().subtract(totals.length, 'day').toDate();

  return totals.map((_, idx) => {
    const date = new Date(startDate);
    date.setDate(startDate.getDate() + idx);

    return {
      label: '',
      leadTime: demoDataUtils.generateRandomInt(0, 10),
      production: demoDataUtils.generateRandomInt(100, 1000),
      timestamp: dayjs(date).format('YYYYMMDD'),
      total: totals[idx],
    };
  });
};

export const sumHistoricalDataPatients = (data: PracticeAnalyticsTypes.HistoricalDataTotal[]) =>
  data.reduce((acc, cur) => acc + cur.total, 0);
