import { gql } from 'graphql-request';
import { PracticeAnalyticsApi, PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { useScopedQuery } from '@frontend/scope';
import { useAlert } from '@frontend/design-system';
import { getPracticeAnalyticsQueryVariables } from '../components/practice/helpers';
import { queryKeys } from '../query-keys';
import { useIsPADemoAccount } from './use-is-pa-demo-account';

type QueryProps = {
  hygieneFollowUp?: boolean;
  hygieneTreatmentPlan?: boolean;
  restorativeTreatmentPlan?: boolean;
};

interface UseGetOpportunityListDetails {
  filters: PracticeAnalyticsTypes.Filters;

  disabled?: boolean;
  queryProps?: QueryProps;
}

const patients = (additionalDetails?: string) => `
  patients {
    id
    productionAmount
    FirstName: firstName
    LastName: lastName
    MobilePhone: mobilePhone
    HomePhone: homePhone
    WorkPhone: workPhone
    Email: email
    ${additionalDetails ?? ''}
  }
`;

const cancelledPatientsQuery = gql`
  query ($start: Int!, $end: Int!, $step: TimeStep!) {
    location {
      missedAppointments {
        details(start: $start, end: $end, step: $step) {
          ${patients()}
        }
      }
    }
  }
`;

const hygieneFollowUpQuery = gql`
  query ($start: Int!, $end: Int!, $step: TimeStep!) {
    location {
      hygieneReappointment {
        details(start: $start, end: $end, step: $step) {
          ${patients('nextHygieneAppointmentDate')}
        }
      }
    }
  }
`;

const hygieneTreatmentPlanQuery = gql`
  query ($start: Int!, $end: Int!, $step: TimeStep!) {
    location {
      hygieneTreatmentPlan: treatmentPlan(treatmentPlanType: HYGIENE) {
        details(start: $start, end: $end, step: $step) {
          ${patients(`
            procedures {
              adaCode
              date
              procedureAmount
            }`)}
        }
      }
    }
  }
`;

const restorativeTreatmentPlanQuery = gql`
  query ($start: Int!, $end: Int!, $step: TimeStep!) {
    location {
      restorativeTreatmentPlan: treatmentPlan(treatmentPlanType: RESTORATIVE) {
        details(start: $start, end: $end, step: $step) {
          ${patients(`
            procedures {
              adaCode
              date
              procedureAmount
            }`)}
        }
      }
    }
  }
`;

const newPatientsQuery = gql`
  query ($start: Int!, $end: Int!, $step: TimeStep!) {
    location {
      newPatients {
        details(start: $start, end: $end, step: $step) {
          ${patients('nextAppointmentDate')}
        }
      }
    }
  }
`;

export const useGetOpportunityListDetails = ({ disabled, filters, queryProps }: UseGetOpportunityListDetails) => {
  const alert = useAlert();
  const { t } = useTranslation('analytics');
  const isDemoAccount = useIsPADemoAccount();
  const variables = getPracticeAnalyticsQueryVariables(filters);

  // We need to individually query each opportunity list to reduce the chances of request timeout
  // So please do not merge these queries into one
  const queries = queryProps
    ? Object.entries(queryProps).reduce<string[]>((acc, [key, value]) => {
        if (value) {
          acc.push(key);
        }
        return acc;
      }, [])
    : [
        cancelledPatientsQuery,
        hygieneFollowUpQuery,
        hygieneTreatmentPlanQuery,
        newPatientsQuery,
        restorativeTreatmentPlanQuery,
      ];

  return useScopedQuery({
    queryKey: queryKeys.practiceAnalyticsCharts(
      `opportunities-list-details-${isDemoAccount}-${JSON.stringify(filters)}`
    ),
    queryFn: () =>
      PracticeAnalyticsApi.getPracticeAnalyticsRecords<PracticeAnalyticsTypes.OpportunityList>({
        locationIds: filters.locations ?? [],
        queries,
        variables,
      }),
    onError: () => {
      alert.error(t("Couldn't load the patients details. Please try again."));
    },
    enabled: !disabled && !!filters.locations?.length,
    retry: false,
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });
};
