import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  flex-direction: row-reverse;
  padding: ${theme.spacing(2, 4)};
  background-color: ${theme.colors.white};
  box-shadow: ${theme.shadows.light};
`;
