import { css } from '@emotion/react';
import { DepartmentsApi } from '@frontend/api-departments';
import { OverrideApi } from '@frontend/api-overrides';
import { useTranslation } from '@frontend/i18n';
import { useQuery } from '@frontend/react-query-helpers';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Heading, Text } from '@frontend/design-system';
import { queryKeys } from '../../../../query-keys';
import { OldOverrideSettingContent } from '../../../override/override-settings.new';

export const OverrideSettingsContainer = ({ departmentId }: { departmentId: string }) => {
  const { t } = useTranslation('phone', { keyPrefix: 'departments' });
  const { singleLocationId: locationId } = useAppScopeStore();

  const { data: department } = useQuery({
    queryKey: [locationId, ...queryKeys.department(departmentId)],
    queryFn: async () => {
      return DepartmentsApi.readDepartment({ departmentId }).then((res) => res.department);
    },
  });

  const { data: override } = useQuery({
    queryKey: [locationId, ...queryKeys.overrideList()],
    queryFn: async () => {
      const listOverridesResponse = await OverrideApi.listOverride({ locationIds: [locationId] }, { locationId });
      return listOverridesResponse.phoneOverrides ?? [];
    },
    select: (data) => data.find((item) => item.locationId === locationId && item.departmentId === departmentId),
  });

  return (
    <section
      css={css`
        margin-top: ${theme.spacing(6)};
      `}
    >
      <Heading level={2}>{t('Override')}</Heading>
      <Text
        color='light'
        css={css`
          margin-bottom: ${theme.spacing(3)};
        `}
      >
        {t(
          'Overrides enable you to quickly and easily reroute your call configuration to secure phone coverage during breaks and holidays.'
        )}
      </Text>
      <OldOverrideSettingContent override={override} department={department} />
    </section>
  );
};
