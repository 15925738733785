import { useModalControl } from '@frontend/design-system';
import { CalendarViewV3HeaderFilterButton } from './CalendarViewV3HeaderFilterButton';
import { CalendarViewV3HeaderFilterModal } from './CalendarViewV3HeaderFilterModal';

export const CalendarViewV3HeaderFilter = () => {
  const modalProps = useModalControl({
    disableReturnFocus: true,
  });

  return (
    <>
      <CalendarViewV3HeaderFilterButton openModal={modalProps.openModal} />
      <CalendarViewV3HeaderFilterModal modalProps={modalProps} />
    </>
  );
};
