import { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/react';
import { useLocation, useNavigate } from '@tanstack/react-location';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { Page } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { Icon } from '@frontend/icons';
import { useAppScopeStore } from '@frontend/scope';
import { theme } from '@frontend/theme';
import { Heading, SecondaryButton, useModalControl } from '@frontend/design-system';
import { DemoChip, FeedbackButton } from '../components';
import {
  CallIntelDemoBanner,
  CallIntelFilters,
  CallIntelLocationComparisonCharts,
  CallRecordsTable,
  ExploreLocationsTray,
} from '../components/call-intelligence';
import { CallIntelMockData } from '../components/call-intelligence/demo-data';
import {
  CallIntelSubViewId,
  CallIntelSubViewType,
  MultiLocationsView,
  useCallIntelLocations,
  useCallIntelShallowStore,
  useIsCallIntelDemoAccount,
} from '../components/call-intelligence/hooks';
import {
  OpportunitiesBySentimentsSubView,
  OpportunitiesByTypeSubView,
} from '../components/call-intelligence/sub-views';
import { URLs } from '../constants';
import { trackingIds } from '../tracking-ids';
import { callIntelligenceUtils } from '../utils';
import { callIntelStyles, filtersStyles, stickyTabsStyles } from './common-styles';

export const CallIntelSubView = () => {
  const { t } = useTranslation('analytics');
  const stickyTabsRef = useRef<HTMLDivElement | null>(null);
  const { modalProps, triggerProps } = useModalControl();
  const { selectedOrgId: locationId } = useAppScopeStore();
  const {
    locationId: callIntelLocationId,
    dataLabels,
    defaultFilters,
    filterHintText,
    filters,
    isDemoModal,
    setFilters,
    subView,
    setIsDemoAccount,
  } = useCallIntelShallowStore(
    'locationId',
    'dataLabels',
    'defaultFilters',
    'filterHintText',
    'filters',
    'isDemoModal',
    'setFilters',
    'setIsDemoAccount',
    'subView'
  );
  const navigate = useNavigate();
  const { current } = useLocation();
  const isDemoAccount = useIsCallIntelDemoAccount();
  const { isMultiLocation, locations } = useCallIntelLocations({
    demoLocations: isDemoAccount ? CallIntelMockData.dummyLocationNames() : undefined,
  });
  const [filtersReady, setFiltersReady] = useState<boolean>(false);
  const [activeMultiView, setActiveMultiView] = useState<MultiLocationsView>('summary');
  const [isLoadingChartsData, setIsLoadingChartsData] = useState<boolean>(false);
  const pageId = subView.id as CallIntelSubViewId;
  const pageType = subView.type;
  const drillDownFilters = callIntelligenceUtils.getDrillDownFilterKeys(subView);

  const getSubView = () => {
    switch (pageType) {
      case 'appointment-type':
      case 'category':
        return {
          overview: (
            <OpportunitiesByTypeSubView metricsFor={metricsFor} onChangeLoadingState={setIsLoadingChartsData} />
          ),
        };

      case 'sentiment':
        return {
          overview: (
            <OpportunitiesBySentimentsSubView
              metricsFor={metricsFor}
              onChangeLoadingState={setIsLoadingChartsData}
              sentimentCode={pageId}
            />
          ),
        };

      default:
        return null;
    }
  };

  const getPageTypeTitle = (pageType?: CallIntelSubViewType) => {
    switch (pageType) {
      case 'appointment-type':
        return t('Appointment Type');
      case 'category':
        return t('Category');
      case 'sentiment':
        return t('Customer Sentiment');
      default:
        return '';
    }
  };

  const getPageIdLabel = () =>
    pageType === 'appointment-type' ? dataLabels.appointmentTypes?.[pageId] : dataLabels.categories?.[pageId];

  const getPageSubtitleText = () =>
    `${
      isMultiLocation && filters.locations?.length
        ? filters.locations.length === Object.keys(locations).length
          ? t('all locations, ')
          : filters.locations.length === 1
          ? t('1 location, ')
          : t('{{count}} locations, ', { count: filters.locations.length })
        : ''
    }${filterHintText}`;

  const metricsFor = pageType === 'sentiment' ? dataLabels.sentiments?.[pageId] : getPageIdLabel();

  useEffect(() => {
    // TODO :: This is temporary and need to be updated by allowing passing ref to the Page component
    const scrollableParent = stickyTabsRef.current?.parentElement?.parentElement?.parentElement;

    const handleScroll = () => {
      if (scrollableParent && stickyTabsRef.current) {
        const { top: stickyTop } = stickyTabsRef.current.getBoundingClientRect();
        const { top: pageTop } = scrollableParent.getBoundingClientRect();

        if (pageTop === stickyTop) {
          stickyTabsRef.current.classList.add('sticky-shadow');
        } else {
          stickyTabsRef.current.classList.remove('sticky-shadow');
        }
      }
    };

    scrollableParent?.addEventListener('scroll', handleScroll);

    return () => {
      scrollableParent?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // Navigate to the overveiw page when main location changed while viewing drill down page
    if (callIntelLocationId && locationId !== callIntelLocationId) {
      navigate({ to: URLs.CALL_INTEL_MAIN_PAGE });
    }
  }, [locationId, callIntelLocationId]);

  useEffect(() => {
    if (!pageId || !pageType) {
      navigate({ to: URLs.CALL_INTEL_MAIN_PAGE });
    }

    // In case of multi location comparison chart click, the search param will have the clicked location id
    // and the filters state will be updated accordingly
    const searchLocationId = current.search['locationId'] as string;

    // Format drill down filters and apply them to the filters state for visual purpose
    // Consider the defaultFilters so older filters are not retained except for date and location filters
    const filtersMod = {
      ...defaultFilters,
      endDate: filters.endDate,
      locations: searchLocationId ? [searchLocationId] : filters.locations,
      startDate: filters.startDate,
      contactTypes: filters.contactTypes,
    };
    const { key, value } = drillDownFilters || {};

    switch (key) {
      case 'appointment_types':
        filtersMod.appointmentTypes = value;
        break;

      case 'categories':
        filtersMod.categories = value;
        break;

      case 'sentiments':
        filtersMod.sentiments = value;
        break;

      default:
        break;
    }

    setFilters(filtersMod);

    // Using it to avoid multiple api calls on initial load
    setFiltersReady(true);
  }, []);

  useEffect(() => {
    setIsDemoAccount(isDemoAccount);
  }, [isDemoAccount]);

  return (
    <>
      <CallIntelDemoBanner />
      <Page
        action={
          <FeedbackButton
            productCode={Feature.CALL_INTELLIGENCE}
            trackingId={trackingIds.callIntel.generalFeedbackDrillDownPage}
          />
        }
        breadcrumbs={[
          {
            label: isDemoModal ? '' : t('Analytics'),
            to: '/analytics/dashboard',
          },
          {
            label: t('Call Intelligence'),
            to: URLs.CALL_INTEL_MAIN_PAGE,
          },
          {
            label: `${metricsFor} ${getPageTypeTitle(pageType)}`,
          },
        ].filter(({ label }) => !!label)}
        css={styles.page}
        subtitle={t('Showing results for {{time}}', { time: getPageSubtitleText() })}
        title={pageType === 'sentiment' ? dataLabels.sentimentsWithEmoji?.[pageId] : getPageIdLabel()}
      >
        <div
          css={[filtersStyles.mainWrapper, stickyTabsStyles]}
          ref={stickyTabsRef}
          style={{ margin: theme.spacing(-2, 0, 1), paddingTop: theme.spacing(2) }}
        >
          <CallIntelFilters
            activeTab='overview'
            hideFilters={['officeUsers']}
            isDemoAccount={isDemoAccount}
            isLoadingData={isLoadingChartsData}
            onlyChipFilters
          />

          {isMultiLocation && (
            <div css={callIntelStyles.multiComparison}>
              <SecondaryButton
                {...triggerProps}
                size='large'
                style={{ fontSize: theme.fontSize(14), textWrap: 'nowrap' }}
              >
                <span className='icon'>
                  <Icon color='light' name='locations' />
                </span>
                {t('Explore Location Data')}
              </SecondaryButton>
              <ExploreLocationsTray {...modalProps} onChange={setActiveMultiView} value={activeMultiView} />
            </div>
          )}
        </div>

        {filtersReady && (
          <>
            {activeMultiView === 'summary' ? (
              getSubView()?.overview
            ) : (
              <CallIntelLocationComparisonCharts
                betweenLocations={activeMultiView === 'betweenLocations'}
                drillDownFilters={drillDownFilters}
                internalPage
                key={JSON.stringify(filters)}
                onChangeLoadingState={setIsLoadingChartsData}
              />
            )}

            <Heading css={styles.tableHeader} level={3}>
              {isDemoAccount && <DemoChip />}
              {t('{{name}} Call Recordings', { name: metricsFor })}
            </Heading>
            <CallRecordsTable drillDownFilters={drillDownFilters} isDemoAccount={isDemoAccount} marginTop={16} />
          </>
        )}
      </Page>
    </>
  );
};

const styles = {
  page: css`
    max-width: 100%;
    min-height: auto;
    height: auto;
  `,

  chartsTabPanel: css`
    padding: ${theme.spacing(1, 0)};
  `,

  recordsTablePanel: css`
    flex-grow: 1;
    padding-top: ${theme.spacing(1)};
  `,

  tableHeader: css`
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(0.5)};
    margin-top: ${theme.spacing(5)};

    > span {
      font-size: inherit;
    }
  `,
};
