import { createShallowStore, createStore } from '@frontend/store';

export type AppointmentTypesHelperStore = {
  shouldShowSchedulePaymentsIntegration: boolean;
  isOnlineSchedulingEnabled: boolean;
  refetchAppointmentTypes: () => void;
  setShouldShowSchedulePaymentsIntegration: (shouldShowSchedulePaymentsIntegration: boolean) => void;
  setRetchAppointmentTypes: (refetchAppointmentTypes: () => void) => void;
  setIsOnlineSchedulingEnabled: (isOnlineSchedulingEnabled: boolean) => void;
};

export const useAppointmentTypesHelperStore = createStore<AppointmentTypesHelperStore>(
  (set) => ({
    shouldShowSchedulePaymentsIntegration: false,
    isOnlineSchedulingEnabled: false,
    refetchAppointmentTypes: () => {},
    setShouldShowSchedulePaymentsIntegration: (shouldShowSchedulePaymentsIntegration: boolean) =>
      set({ shouldShowSchedulePaymentsIntegration }),
    setRetchAppointmentTypes: (refetchAppointmentTypes: () => void) => set({ refetchAppointmentTypes }),
    setIsOnlineSchedulingEnabled: (isOnlineSchedulingEnabled: boolean) => set({ isOnlineSchedulingEnabled }),
  }),
  {
    name: 'appointmentTypeHelper',
  }
);

export const useAppointmentTypesHelperShallowStore =
  createShallowStore<AppointmentTypesHelperStore>(useAppointmentTypesHelperStore);
