import { StatusType } from '@weave/schema-gen-ts/dist/schemas/auth-api/v3/auth.pb';
import { InfiniteData } from 'react-query';
import { CallIntelligenceTypes } from '@frontend/api-analytics';
import { demoDataUtils } from '../../../utils';

type CallRecordings = {
  appointmentType?: CallIntelligenceTypes.AppointmentTypeEnum;
  category?: CallIntelligenceTypes.CategoryEnum;
  count: number;
  sentiment?: CallIntelligenceTypes.SentimentEnum;
  showOnlyUnscheduled?: boolean;
};

// Constants
export const appointmentTypes: CallIntelligenceTypes.AppointmentTypeEnum[] = [
  CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_COSMETIC,
  CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_EMERGENCY,
  CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_HYGIENE,
  CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_IMAGING,
  CallIntelligenceTypes.AppointmentTypeEnum.APPOINTMENT_TYPE_ORTHODONTICS,
];

export const categories: CallIntelligenceTypes.CategoryEnum[] = [
  CallIntelligenceTypes.CategoryEnum.CATEGORY_BILLING,
  CallIntelligenceTypes.CategoryEnum.CATEGORY_EMERGENCY,
  CallIntelligenceTypes.CategoryEnum.CATEGORY_COMPLAINT,
  CallIntelligenceTypes.CategoryEnum.CATEGORY_SCHEDULING,
];

const taskDetailsMapping: Partial<
  Record<CallIntelligenceTypes.TaskTypeEnum, { title: string; description: string; citation: string }>
> = {
  [CallIntelligenceTypes.TaskTypeEnum.TYPE_PATIENT_CARE]: {
    title: 'Send new patient paperwork via email.',
    description:
      'Send the new patient paperwork to Bob David via email for him to complete before his appointment on 07/12/2024 at 11:30 AM. Follow up if the paperwork is not received by the end of the day.',
    citation:
      "I will also send you the new patient paperwork via email for you to complete before your appointment. We'll follow up if we don't receive it by the end of the day.",
  },
  [CallIntelligenceTypes.TaskTypeEnum.TYPE_BILLING]: {
    title: 'Process payment after the call.',
    description:
      "Process Bob David's $100 deposit for his consultation and extraction appointment. Contact him via email or phone to confirm the payment once it's processed.",
    citation:
      "Since our payment system is currently down, I'll go ahead and create a task to process your payment after the call. I will reach out via email or phone to confirm the payment once it's processed.",
  },
  [CallIntelligenceTypes.TaskTypeEnum.TYPE_SCHEDULING]: {
    title: 'Send appointment confirmation text.',
    description:
      'Once the texting application is working, send Bob David a confirmation text message with his appointment date, time, and a link to fill out his registration forms.',
    citation:
      'As soon as the texting application comes up, I will send you a confirmation text of the appointment date, time, and the link to fill out your registration forms.',
  },
};

export const officeUsers: CallIntelligenceTypes.OfficeUser[] = demoDataUtils
  .generateRandomUserNames(20)
  .map((name, id) => ({
    ...name,
    status: StatusType.ACTIVE,
    userId: `demo-${id}`,
  }));

export const phoneNumbers: string[] = [
  '1234567890',
  '2345678901',
  '3456789012',
  '4567890123',
  '5678901234',
  '6789012345',
  '7890123456',
  '8901234567',
  '9012345678',
  '0123456789',
];

export const taskTypes: CallIntelligenceTypes.TaskTypeEnum[] = [
  CallIntelligenceTypes.TaskTypeEnum.TYPE_PATIENT_CARE,
  CallIntelligenceTypes.TaskTypeEnum.TYPE_BILLING,
  CallIntelligenceTypes.TaskTypeEnum.TYPE_SCHEDULING,
];

// Utility Functions
export const getRandomElement = <T>(array: T[]): T => array[Math.floor(Math.random() * array.length)];

export const getRandomSentiment = (): CallIntelligenceTypes.SentimentEnum =>
  getRandomElement([
    CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEGATIVE,
    CallIntelligenceTypes.SentimentEnum.SENTIMENT_NEUTRAL,
    CallIntelligenceTypes.SentimentEnum.SENTIMENT_POSITIVE,
    CallIntelligenceTypes.SentimentEnum.SENTIMENT_UNKNOWN,
  ]);

export const getRandomContactType = (): CallIntelligenceTypes.ContactTypeEnum =>
  getRandomElement([
    CallIntelligenceTypes.ContactTypeEnum.CONTACT_EXISTING_PATIENT,
    CallIntelligenceTypes.ContactTypeEnum.CONTACT_NEW_PATIENT,
  ]);

export const getRandomDirection = (): CallIntelligenceTypes.CallDirectionEnum =>
  getRandomElement([
    CallIntelligenceTypes.CallDirectionEnum.DIRECTION_INBOUND,
    CallIntelligenceTypes.CallDirectionEnum.DIRECTION_OUTBOUND,
  ]);

const getRandomTaskTypes = (count: number): CallIntelligenceTypes.TaskTypeEnum[] =>
  [...taskTypes].sort(() => 0.5 - Math.random()).slice(0, count);

const getRandomTaskStatus = (): CallIntelligenceTypes.TaskStatusEnum =>
  getRandomElement([
    CallIntelligenceTypes.TaskStatusEnum.STATUS_TODO,
    CallIntelligenceTypes.TaskStatusEnum.STATUS_IN_PROGRESS,
    CallIntelligenceTypes.TaskStatusEnum.STATUS_COMPLETED,
  ]);

const getRandomTasks = (
  taskTypes: CallIntelligenceTypes.TaskTypeEnum[],
  callId: string
): CallIntelligenceTypes.Task[] => {
  const defaultTaskDetails = {
    title: '',
    description: '',
    citation: '',
  };

  return taskTypes.map((taskType, idx) => {
    const taskDetails = taskDetailsMapping[taskType] || defaultTaskDetails;
    return {
      assignee: '',
      citation: '',
      description: taskDetails.description,
      id: `demo-${idx}`,
      locationId: '',
      metadata: JSON.stringify({ citation: taskDetails.citation }),
      sourceId: callId,
      status: getRandomTaskStatus(),
      title: taskDetails.title,
      type: taskType,
    };
  });
};

// Demo Data Function
export const callRecordings = ({
  appointmentType,
  category,
  count,
  sentiment,
  showOnlyUnscheduled,
}: CallRecordings): InfiniteData<CallIntelligenceTypes.CallsResponse> => {
  return {
    pages: [
      {
        calls: demoDataUtils.generateRandomUserNames(count).map((name, id) => {
          const schedulingOpportunity = Math.random() > 0.5;
          const taskCount = Math.floor(Math.random() * taskTypes.length) + 1;

          return {
            appointmentTypes: [appointmentType ?? getRandomElement(appointmentTypes)],
            categories: [category ?? getRandomElement(categories)],
            contactType: getRandomContactType(),
            direction: getRandomDirection(),
            id: `demo-${id}`,
            locationId: '001',
            mosScore: Math.floor(Math.random() * 6),
            officeNumber: {
              countryCode: 1,
              nationalNumber: getRandomElement(phoneNumbers),
            },
            officeUser: getRandomElement(officeUsers),
            person: {
              ...name,
              id: `demo-${id}`,
            },
            phoneNumber: {
              countryCode: 1,
              nationalNumber: '234567890',
            },
            schedulingOpportunity: true,
            schedulingOutcome: showOnlyUnscheduled ? false : schedulingOpportunity ? Math.random() > 0.5 : false,
            sentiment: sentiment ?? getRandomSentiment(),
            startTime: demoDataUtils.getRandomDateAndTime(),
            status: CallIntelligenceTypes.CallStatusEnum.CALL_STATUS_COMPLETE,
            taskCount,
            taskTypes: getRandomTaskTypes(taskCount),
          };
        }),
        pageConfig: {
          pageNumber: 1,
          pageSize: 10,
        },
      },
    ],
    pageParams: [],
  };
};

export const callMetadataResponse = (): CallIntelligenceTypes.CallMetadataResponse => {
  return {
    summary:
      "[PERSON_NAME], a patient referred by Dr. Mary Johnson, called Generic Organization to schedule an appointment for a tooth extraction (tooth #28) and a potential implant. The patient shared the referral documents and insurance details via text but the texting system was down. The staff member, Joan, was unable to access the information and proceeded to gather the necessary details over the phone. [PERSON_NAME] confirmed that an implant was likely needed in the future. Joan checked the availability and scheduled an appointment with Dr. Karl for July 12th at 11:30 AM, requiring a $100 deposit. [PERSON_NAME] agreed to the deposit and mentioned that Dr. Johnson had already referred her to Generic Organization around September 20th, 2023. Joan requested [PERSON_NAME] to send the referral via email as they didn't have it in their system. [PERSON_NAME] also mentioned that Dr. Johnson might have shared her x-rays, but Joan couldn't find any records. She updated [PERSON_NAME]’s information, including her date of birth, name (Bob David), phone number, and dental insurance details (Delta Dental of Massachusetts). Joan informed [PERSON_NAME] that she would send a confirmation text, appointment details, and registration forms once the texting system was functional. New patient paperwork would also be sent via email, and they would follow up if not received by the end of the day. [PERSON_NAME] confirmed the appointment time and agreed to complete the necessary paperwork. Joan assured her that she would confirm the payment processing and send a receipt via email.",
    transcript: `
    [office] <0.001 --> 0.066> Thank you for calling Generic Organization. Your call is important to us. Please choose from one of the following options. If you are a patient looking to schedule an appointment, please press one. For questions regarding your account, please press two. If you are calling from a dental practice, please press three. For all other questions, please press four. Press seven to repeat this message. Please hold for a moment while we connect you to a team member who can assist you. Thank you for calling Generic Organization. This is Joan. Can I help you? Okay. Which tooth needs to be removed? 
    [caller] <1.066 --> 1.380> Hi, Joan. Good morning. This is [PERSON_NAME] here. I just got a reference from Dr. Mary Johnson, and this is regarding a tooth extraction. Oh, no, that's difficult. I really don't know. It says some 28 something, so I don't know what exactly that means. But I've shared the referral document and my insurance provider details via text message to the number that was provided. 
    [office] <1.380 --> 1.520> Okay, yeah, unfortunately, our text messaging is down at this moment, so I can't access it. So I will, unfortunately, have to ask you a bunch of questions. 
    [caller] <1.520 --> 1.520> Sure. 
    [office] <1.520 --> 1.560> Because I can't access that. Hold on for just a second. 
    [caller] <1.560 --> 2.110> So in that case, just give me a minute. Let me get my referral and the insurance details. 
    [office] <2.110 --> 2.430> I don't know. I don't know. 
    [caller] <2.430 --> 2.670> Hello? 
    [office] <2.670 --> 2.740> Yes. 
    [caller] <2.740 --> 2.930> Yeah. 
    [office] <2.930 --> 3.020> Okay, so do you have the tooth number? 
    [caller] <3.020 --> 3.070> Just a minute. Yeah, please tell me. What exactly do you want to know? 
    [office] <3.070 --> 3.070> Which tooth needs to be removed? 
    [caller] <3.070 --> 3.070> It says 28. 
    [office] <3.070 --> 3.150> Okay, number 28. And are they going to do an implant in that area sometime in the future? 
    [caller] <3.150 --> 3.250> Yeah, I think so. What Mary Johnson has written, she has suggested extraction and implant. 
    [office] <3.250 --> 3.410> Okay. And you wanted to schedule a consultation or do you want to do the extraction and the bone graft in the same visit? 
    [caller] <3.410 --> 3.580> I actually want to do the extraction because it has started paining now. So, I really want to get this done. 
    [office] <3.580 --> 4.060> Okay, let me see what I have available. 
    [caller] <4.060 --> 4.280> Yeah. 
    [office] <4.280 --> 4.440> So I have Friday, July 12th available with Dr. Karl at 1130 with an 1115 check-in. 
    [caller] <4.440 --> 4.520> I am fine. That seems fine. Do you have anything earlier than Friday? 
    [office] <4.520 --> 4.570> That's the first available. 
    [caller] <4.570 --> 4.660> That is the first available. All right. In that case, maybe the 1130 appointment sounds good. 
    [office] <4.660 --> 4.800> Okay. And when we schedule a consultation and extraction appointment in the same visit, it does require a deposit of $100. That $100 is used to reserve the appointment. When you come in for the appointment, it's applied to your estimated copayment, so it's deducted from the amount that you would owe. Or if you fail to give a 48-hour notice for a cancellation and reschedule, it would be used as a broken appointment fee. 
    [caller] <4.800 --> 5.190> That's fine. We will get this $100 done. No problem. 
    [office] <5.190 --> 5.290> Since our payment system is currently down, I'll go ahead and create a task to process your payment after the call. I will reach out via email or phone to confirm the payment once it's processed. 
    [caller] <5.290 --> 5.320> Joan, I just want to know one more thing. 
    [office] <5.320 --> 5.330> Yes? [
    caller] <5.330 --> 5.510> Mary Johnson had referred me to this Generic Organization somewhere around 9-20-23. Do you need a fresh prescription for this or is the current one still valid? 
    [office] <5.510 --> 5.560> You can go ahead and send that to us. Oh, you said you already texted it to us. 
    [caller] <5.560 --> 6.000> Yeah, I had texted it. So, how do I send it to you now? 
    [office] <6.000 --> 6.030> You can email it to us. Please do so as soon as possible, and I'll make a note to follow up if we don't receive it by the end of the day. 
    [caller] <6.030 --> 6.050> Fine, I'll email it to you. 
    [office] <6.050 --> 6.050> Okay. 
    [caller] <6.050 --> 6.160> I also believe she has already shared my x-rays with your office. That's what the prescription says. 
    [office] <6.160 --> 6.210> Okay, let me see if you're in my system. What is your date of birth? 
    [caller] <6.210 --> 6.300> 12-13-1986. 
    [office] <6.300 --> 6.730> No, I don't have anything in here with that date of birth. Okay, let's change that. Nope, that's still not coming up. If the radiographs are almost a year old, we'll need to take new ones. May I get your last name, please? 
    [caller] <6.730 --> 7.070> Yeah. It's David, D-A-V-I-D. 
    [office] <7.070 --> 7.140> D-A-V-I-D. And your first name? 
    [caller] <7.140 --> 7.180> Bob, B-O-B. 
    [office] <7.180 --> 7.310> And your telephone number, please? And is there dental insurance that we will be filing for you? 
    [caller] <7.310 --> 8.240> Yeah, I have my dental insurance details. If you need anything, tell me. 
    [office] <8.240 --> 8.300> Is that through your employer? Okay. And what is her first name? 
    [caller] <8.300 --> 8.330> No, it's through my wife's employer. Her name is Sally, S-A-L-L-Y. 
    [office] <8.330 --> 8.420> Same last name? 
    [caller] <8.420 --> 8.450> No, the last name is different. It's Smith. I'll spell it for you. S-M-I-T-H. 
    [office] <8.450 --> 8.560> And what is her date of birth? 
    [caller] <8.560 --> 9.000> It would be 5, 8, 1989. 
    [office] <9.000 --> 9.100> And what is the name of her insurance? 
    [caller] <9.100 --> 9.140> Delta Dental. 
    [office] <9.140 --> 9.190> Of California? 
    [caller] <9.190 --> 9.230> I am assuming it is California because there is nothing written here. 
    [office] <9.230 --> 9.290> Okay, and what is the ID number or social security number of your spouse? 
    [caller] <9.290 --> 9.320> The ID number, you mean to say the subscriber number? 
    [office] <9.320 --> 9.340> Yes. 
    [caller] <9.340 --> 9.430> It's 111-000-1111. Yeah. 
    [office] <9.430 --> 9.430> Okay, that does not look like a Delta Dental of California ID number. Are you looking at the card right now? On the back should be a mailing address. Can you tell me what state it goes to? 
    [caller] <9.430 --> 9.580> It goes to Massachusetts. 
    [office] <9.580 --> 10.080> Okay, so it's Delta Dental of Massachusetts. Let me update that. Okay, and tooth number 28. 
    [caller] <10.080 --> 10.360> Yeah. 
    [office] <10.360 --> 13.440> As soon as the texting application comes up, I will send you a confirmation text of the appointment date, time, and the link to fill out your registration forms. 
    [caller] <13.440 --> 14.010> Okay. 
    [office] <14.010 --> 14.110> I will also send you the new patient paperwork via email for you to complete before your appointment. We'll follow up if we don't receive it by the end of the day. 
    [caller] <14.110 --> 14.480> Sounds good. 
    [office] <14.480 --> 15.140> Great! I'll make sure to confirm the payment processing after the call and send you the receipt via email. Thank you for your patience. 
    [caller] <15.140 --> 15.160> Thank you, Joan. 
    [office] <15.160 --> 15.450> You're welcome. Have a great day!
  `,
    callMetadata: {
      caller: JSON.stringify({
        date_of_birth: '12/13/1986',
        insurance: {
          name: 'Delta Dental of Massachusetts',
          phone: null,
          fax: null,
          email: null,
          insurance_ids: ['111-000-1111'],
        },
        dependents: [],
      }),
      receptionist: JSON.stringify({}),
    },
  };
};

export const generateTasksForCalls = (
  calls: CallIntelligenceTypes.Call[]
): CallIntelligenceTypes.CallTaskListResponse => {
  const tasks = calls.flatMap((call) => {
    const { id: callId, taskTypes } = call;
    return getRandomTasks(taskTypes, callId);
  });

  return { tasks };
};

export const callTaskListResponse = (
  taskCount: number,
  taskTypes: CallIntelligenceTypes.TaskTypeEnum[],
  callId: string
): CallIntelligenceTypes.CallTaskListResponse => {
  return {
    tasks: taskCount > 0 ? getRandomTasks(taskTypes, callId) : [],
  };
};

export const getFilteredCallRecordings = (
  callRecordings: InfiniteData<CallIntelligenceTypes.CallsResponse>,
  filters: CallIntelligenceTypes.Filters
): InfiniteData<CallIntelligenceTypes.CallsResponse> => {
  if (!callRecordings) return callRecordings;

  const filterCall = (call: CallIntelligenceTypes.Call): boolean => {
    const contactType =
      filters?.contactTypes?.length && call.contactType ? filters.contactTypes.includes(call.contactType) : true;

    const officeUsers =
      filters?.officeUsers?.length && call.officeUser?.userId
        ? filters.officeUsers.includes(call.officeUser?.userId)
        : true;

    const schedulingOutcome = filters?.schedulingOutcomes?.length
      ? (filters?.schedulingOutcomes?.includes(
          CallIntelligenceTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_SCHEDULED
        ) &&
          call.schedulingOutcome === true) ||
        (filters?.schedulingOutcomes?.includes(
          CallIntelligenceTypes.SchedulingOutcomeEnum.SCHEDULING_OUTCOME_UNSCHEDULED
        ) &&
          call.schedulingOutcome === false)
      : true;

    return contactType && officeUsers && schedulingOutcome;
  };

  return {
    pages: callRecordings.pages.map((page) => ({
      calls: page.calls?.filter(filterCall),
      pageConfig: { ...page.pageConfig },
    })),
    pageParams: callRecordings.pageParams,
  };
};
