import { css } from '@emotion/react';
import { theme } from '@frontend/theme';
import { builderFieldsStyle } from '../categories-layout/categories-layout.styles';

export const sectionTemplateFieldsStyle = [
  builderFieldsStyle,
  css`
    padding: ${theme.spacing(2)};
  `,
];

export const textStyle = css`
  margin-top: ${theme.spacing(2)};
`;
