import { contextFactory } from '@frontend/design-system';
import { DayOfWeek, DisplayScheduleAvailabilityHour } from '../types';

export type ScheduleAvailabilityHoursContextType = {
  selectedDays: DayOfWeek[];
  closedOfficeText?: string;
  timeRangeJoiningText?: string;
  onToggleDay: (day: DayOfWeek) => void;
  onUpdateDay: (day: DayOfWeek, scheduleAvailabilityHours: DisplayScheduleAvailabilityHour, index: number) => void;
  updateErrorsByDay: (day: string, hasError: boolean) => void;
};

export const [ScheduleAvailabilityHoursContext, useScheduleAvailabilityHoursContext] =
  contextFactory<ScheduleAvailabilityHoursContextType>();
