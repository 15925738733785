import { InvoiceFilterType, InvoiceSearchParams } from './types';

export type FilterKeys = InvoiceFilterType & {
  currentPage: number;
  numRows: number;
  order: InvoiceSearchParams['order'];
};

export const getInvoiceListQueryKey = (
  locationId: string | undefined,
  filterParams: FilterKeys
): [string | undefined, string, FilterKeys] => [locationId, 'invoices', filterParams];

export const getInvoiceQueryKey = (locationId: string, invoiceId: string): [string, string, string] => [
  locationId,
  'invoice',
  invoiceId,
];
