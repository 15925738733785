import { ReactNode, SyntheticEvent } from 'react';
import { PersonAPI, PersonHelpers } from '@frontend/api-person';
import { useDialpadDialer, useDialpadManagerClient, useDialpadWidgetControl } from '@frontend/dialpad';
import { useNumbersToLocationMap } from '@frontend/generic-dialpad-accessories';
import { useTranslation } from '@frontend/i18n';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import { removeNonDigits, sanitizePhoneNumber } from '@frontend/phone-numbers';
import { useSoftphoneDialer, useSoftphoneSettings, useSoftphoneWidgetControl } from '@frontend/softphone2';
import { useSoftphoneInnerManager } from '@frontend/weave-softphone-manager';
import { ModalControlTriggerProps, useModalControl } from '@frontend/design-system';
import { ClickToCallSelection } from './selection-modal/click-to-call-selection';
import { CallSelectionContext } from './types';

const queryKeys = {
  base: ['calls'],
  outboundNumbers: (tenantId: string) => [...queryKeys.base, 'outboundVoiceNumbers', tenantId],
};

type UsePhoneCallActionArgs = {
  context: CallSelectionContext;
};
// Case 1: if personNumber is passed in the context, that means we don't need to give options to select from contact numbers
// Case 2: if person data is passed from person profile or contact page, then provide options to select from various contact numbers
// Case 3: if person Id is passed, then fetch the personData and provide options to select from various contact numbers
// Also, if outbound numbers are multiple then show the modal where we provide options to select the outbound numbers

export const usePhoneCallAction = ({
  context,
}: UsePhoneCallActionArgs): {
  triggerProps?: ModalControlTriggerProps;
  closeModal: () => void;
  Modal: ReactNode;
  disabled: boolean;
  disabledDetails: string;
} => {
  const { t } = useTranslation('phone-call');
  const modal = useModalControl();
  const { phoneConfig } = usePhoneConfigShallowStore('phoneConfig');

  let disabledDetails = '';
  const softphoneIsConnected = useSoftphoneInnerManager((ctx) => ctx.isConnected);

  const setSoftphoneDialer = useSoftphoneDialer((ctx) => ctx.setCurrentDial);
  const setSoftphoneOutboundNumber = useSoftphoneSettings((ctx) => ctx.setCurrentPhoneNumber);
  const currentSoftphoneOutboundNumber = useSoftphoneSettings((ctx) => ctx.selectedOutboundPhoneNumber);
  const openSoftphone = useSoftphoneWidgetControl((ctx) => ctx.open);

  const setDialpadDialer = useDialpadDialer((ctx) => ctx.setCurrentDial);
  const setDialpadOutboundNumber = useDialpadManagerClient((ctx) => ctx.setCurrentOutboundNumber);
  const currentDialpadOutboundNumber = useDialpadManagerClient((ctx) => ctx.currentOutboundPhoneNumber);
  const openDialpad = useDialpadWidgetControl((ctx) => ctx.open);
  const outboundNumbers = useDialpadManagerClient((ctx) => ctx.availablePhoneNumbers);

  const { data: personData, isLoading: isPersonDataLoading } = PersonAPI.usePersonExtended(
    {
      personId: context?.personId ?? '',
      locationId: context?.locationId ?? '',
    },
    {
      enabled: !context?.person && !!context?.personId,
    }
  );

  const person = context.person || personData;

  const multipleContactNumbers = PersonHelpers.getAllPhoneNumbers(context?.person) ?? [];
  const requiresContactNumberSelection = !context.phoneNumber && !!person && !(multipleContactNumbers.length === 1);

  const numberToLocationMap = useNumbersToLocationMap(outboundNumbers);

  const isSpecificOutboundProvided =
    context?.outboundNumber && sanitizePhoneNumber(context.outboundNumber.replace('+1', '')) in numberToLocationMap;

  const handleClick = (e: SyntheticEvent | undefined) => {
    e?.stopPropagation();
    if (!!phoneConfig && !requiresContactNumberSelection) {
      const dialNumber = removeNonDigits(context.phoneNumber || multipleContactNumbers[0].number);
      if (softphoneIsConnected) {
        const outboundNumber = isSpecificOutboundProvided
          ? outboundNumbers.find((item) => item.number === context.outboundNumber)
          : currentSoftphoneOutboundNumber;
        setSoftphoneDialer(dialNumber);
        setSoftphoneOutboundNumber(outboundNumber);
        openSoftphone?.();
      } else {
        const outboundNumber = isSpecificOutboundProvided
          ? outboundNumbers.find((item) => item.number === context.outboundNumber)
          : currentDialpadOutboundNumber;
        setDialpadDialer(dialNumber);
        setDialpadOutboundNumber(outboundNumber);
        openDialpad?.();
      }
      modal.closeModal();
    } else {
      modal.triggerProps.onClick();
    }
  };

  if (!context?.personId && !context?.phoneNumber && (!context.person || multipleContactNumbers.length === 0)) {
    disabledDetails = t('This contact has no associated phone numbers.');
  }

  return {
    triggerProps: !!disabledDetails
      ? undefined
      : {
          ...modal.triggerProps,
          onClick: handleClick,
        },
    closeModal: modal.closeModal,
    Modal: (
      <ClickToCallSelection
        person={person}
        personNumber={context?.phoneNumber ?? ''}
        isSpecificOutboundProvided={!!isSpecificOutboundProvided}
        modal={modal}
        isLoading={isPersonDataLoading}
      />
    ),
    disabled: !!disabledDetails,
    disabledDetails: disabledDetails,
  };
};
