import { FC } from 'react';
import { LibraryListType } from '../../../../library.types';
import { ExperienceView, PreviewFileType } from '../experience-view';
import { containerStyle } from './body.styles';

interface LibraryItemDetailsBodyProps {
  token: string;
  type: LibraryListType;
  locationId: string;
}

/**
 * @deprecated
 */
const LibraryItemDetailsBody: FC<LibraryItemDetailsBodyProps> = ({ token, type, locationId }) => {
  let previewFileType: PreviewFileType | undefined = undefined;

  switch (type) {
    case LibraryListType.FORMS:
      previewFileType = 'form';
      break;

    case LibraryListType.PACKETS:
      previewFileType = 'packet';
      break;
  }

  if (!previewFileType) {
    return null;
  }

  return (
    <section css={containerStyle}>
      <div className='inner-container'>
        {<ExperienceView key={token} token={token} type={previewFileType} locationId={locationId} />}
      </div>
    </section>
  );
};

export default LibraryItemDetailsBody;
