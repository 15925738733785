import { Dispatch, SetStateAction, useMemo } from 'react';
import { ScheduledSmsThread } from '@weave/schema-gen-ts/dist/schemas/messaging/scheduled/shared/v1/models.pb';
import { Person } from '@weave/schema-gen-ts/dist/schemas/persons/v3/persons.pb';
import { ThreadDraft } from '@weave/schema-gen-ts/dist/schemas/sms/draft/v1/draft_service.pb';
import { SetDataOptions } from 'react-query';
import { InboxQueries, MessagesHooks, MessagesTypes, SchemaSMSSharedModels } from '@frontend/api-messaging';
import { PersonHelpers, PersonsV3 } from '@frontend/api-person';
import { getUser } from '@frontend/auth-helpers';
import { StatusAlert } from '@frontend/components';
import { useTranslation } from '@frontend/i18n';
import { InboxType, useInboxNavigate } from '@frontend/inbox-navigation';
import { useNotificationContext } from '@frontend/notifications';
import { formatPhoneNumber } from '@frontend/phone-numbers';
import { SchemaSMSService } from '@frontend/schema';
import { useAppScopeStore } from '@frontend/scope';
import { useTimestampFormatter } from '@frontend/timestamp-formatter';
import { sentry } from '@frontend/tracking';
import { theme } from '@frontend/theme';
import { useAlert } from '@frontend/design-system';
import { MessageStatus } from '../components/message-status-indicator';
import { ExtendedDepartment } from '../types';
import { useChildMatch } from './use-child-match';

type SelectedThreads = Record<string, string[]>;
type SetSelectedThreads = Dispatch<SetStateAction<Record<string, string[]>>>;
type UseUnifiedListItemProps = {
  thread: ScheduledSmsThread | ThreadDraft | MessagesTypes.InboxListItem;
  departments: ExtendedDepartment[];
  personObj?: SchemaSMSSharedModels.Person;
  isSelectAll: boolean;
  selectedThreadsState: [SelectedThreads, SetSelectedThreads];
  isBlocked: boolean;
  body: string;
  scheduledThreadsMap?: Record<string, ScheduledSmsThread[]>;
  status: MessageStatus;
  isRead: boolean;
  hasError: boolean;
  inboxType: InboxType;
};
export const useUnifiedListItem = ({
  thread,
  departments,
  personObj,
  isSelectAll,
  selectedThreadsState,
  isBlocked,
  body,
  scheduledThreadsMap,
  status,
  isRead,
  hasError,
  inboxType,
}: UseUnifiedListItemProps): ReturnUseUnifiedListItem => {
  const user = getUser();
  const alert = useAlert();
  const { t } = useTranslation('inbox');
  const { selectedLocationIds } = useAppScopeStore();
  const { navigateToThread, closeThread } = useInboxNavigate();
  const formatTimestamp = useTimestampFormatter();

  const invalidateInboxList = MessagesHooks.useInvalidateInboxList();
  const modifyUnreadCount = InboxQueries.useModifyUnreadCount();
  const notificationTrayContext = useNotificationContext();

  const childMatch = useChildMatch('/:groupId/:threadId');
  const currentThreadId = childMatch?.params['threadId'];
  const { threadId, departmentId, locationId, personPhone } = thread;
  const [selectedThreads, setSelectedThreads] = selectedThreadsState;

  const { data: personApiData } = PersonsV3.PersonQueries.useGetPersonLegacyQuery(
    {
      personId: 'personId' in thread ? thread.personId : '',
      locationIds: selectedLocationIds,
    },
    { enabled: !personObj && !!('personId' in thread && thread.personId) }
  );

  const person = personObj ?? personApiData;

  const personName = person
    ? PersonHelpers.getFullName({
        FirstName: person?.firstName,
        PreferredName: person?.preferredName,
        LastName: person?.lastName,
      })
    : '';
  const title = personName || formatPhoneNumber(personPhone);

  const isSelected = threadId === currentThreadId;
  const department = departments.find((dept) => dept.id === departmentId);

  const showDepartmentChip = departments.length > 1 && !!department;
  const showLocationChip = selectedLocationIds.length > 1;
  const shouldRenderChipsRow = showLocationChip || showDepartmentChip;

  const messageStatusAlert: StatusAlert | null = useMemo(() => {
    switch (status) {
      case MessageStatus.DRAFT:
        return { text: t('Draft'), color: 'light', weight: 'regular' };
      case MessageStatus.ERROR:
        return { text: t('Not Delivered'), color: 'error', weight: 'bold' };
      case MessageStatus.SCHEDULED: {
        if (scheduledThreadsMap?.[thread.threadId]?.length) {
          return {
            text: formatTimestamp(scheduledThreadsMap[thread.threadId]?.[0]?.sendAt),
            customColor: theme.colors.warning60,
            weight: 'bold',
          };
        } else {
          return {
            text: 'sendAt' in thread ? formatTimestamp(thread.sendAt) : t('Scheduled'),
            customColor: theme.colors.warning60,
            weight: 'bold',
          };
        }
      }
      case MessageStatus.PAUSED_SCHEDULED:
        return { text: t('Paused'), customColor: theme.colors.neutral50 };
      default:
        return null;
    }
  }, [status]);

  const onToggleRead = async () => {
    if (isRead) {
      await SchemaSMSService.SetThreadsStatusNew({
        locationId: locationId,
        threadIds: [thread.threadId],
        userId: user?.userID ?? '',
      })
        .then(() => {
          alert.success(t('Marked as unread'));
          modifyUnreadCount('increment', selectedLocationIds);
        })
        .catch(() => {
          alert.error(t('Error marking as unread'));
        });
    } else {
      await SchemaSMSService.SetThreadsStatusRead({
        locationId: locationId,
        threadIds: [thread.threadId],
        userId: user?.userID ?? '',
      })
        .then(() => {
          alert.success(t('Marked as read'));
          modifyUnreadCount('decrement', selectedLocationIds);
        })
        .catch(() => {
          alert.error(t('Error marking as read'));
        });
    }
    invalidateInboxList();
  };

  const onToggleArchived = async () => {
    if (inboxType === InboxType.ARCHIVED) {
      try {
        await SchemaSMSService.SetThreadsUnarchived({
          locationId,
          userId: user?.userID ?? '',
          threadIds: [thread.threadId],
        });
        alert.success(t('Thread unarchived'));
        invalidateInboxList();
      } catch {
        alert.error(t('Error unarchiving thread'));
      }
    } else {
      try {
        await SchemaSMSService.SetThreadsArchived({
          locationId,
          userId: user?.userID ?? '',
          threadIds: [thread.threadId],
        });
        alert.success(t('Thread archived'));
        if (!isRead && !hasError) {
          modifyUnreadCount('decrement', selectedLocationIds);
        }
        invalidateInboxList();
      } catch {
        alert.error(t('Error archiving thread'));
      }
      closeThread();
    }
  };

  const goToThread = async () => {
    navigateToThread({
      threadId: thread.threadId,
      groupId: thread.locationId,
      personId: person?.personId,
      personPhone: thread.personPhone,
      replace: true,
    });
    if (!hasError && user?.userID)
      try {
        await SchemaSMSService.SetThreadsStatusRead({
          locationId,
          threadIds: [thread.threadId],
          userId: user?.userID ?? '',
        });
      } catch (error) {
        sentry.warn({
          error,
          topic: 'messages',
          addContext: {
            name: 'Request Context (InboxListItem onClick)',
            context: {
              request: {
                locationId,
                threadIds: [thread.threadId],
                userId: user?.userID ?? '',
              },
              errorMessage: JSON.stringify(error),
              thread,
            },
          },
        });
      }
    if (!isRead && !hasError) {
      modifyUnreadCount('decrement', selectedLocationIds);
    }
    invalidateInboxList();
    notificationTrayContext.markThreadNotificationsAsRead(thread.threadId, thread.locationId);
  };

  const messageBodyPreviewText = isBlocked
    ? t('Blocked')
    : body ||
      (thread.numMedia ? `${thread.numMedia} ${t('Attachment', { count: thread.numMedia })}` : t('Nothing to show'));

  const isCheckboxSelected = isSelectAll
    ? !selectedThreads[locationId]?.includes(threadId)
    : !!selectedThreads[locationId]?.includes(threadId);

  const setCheckboxSelected = (selected: boolean) => {
    let threads: string[] = [];
    const filteredThreads = selectedThreads[locationId]?.filter((threadId) => threadId !== thread.threadId) ?? [];
    const updatedThreads = [...(selectedThreads[locationId] ?? []), thread.threadId];

    if (isSelectAll) {
      threads = selected ? filteredThreads : updatedThreads;
    } else {
      threads = selected ? updatedThreads : filteredThreads;
    }

    setSelectedThreads((prev) => ({ ...prev, [locationId]: threads }));
  };

  return {
    isSelected,
    department,
    modifyUnreadCount,
    chips: { showDepartmentChip, showLocationChip, shouldRenderChipsRow },
    person,
    title,
    checkboxState: [isCheckboxSelected, setCheckboxSelected],
    messageBodyPreviewText,
    messageStatusAlert,
    toggleActions: {
      onToggleRead,
      onToggleArchived,
    },
    goToThread,
  };
};

type ReturnUseUnifiedListItem = {
  isSelected: boolean;
  department?: ExtendedDepartment;
  modifyUnreadCount: (type: 'increment' | 'decrement', groupIds?: string[], options?: SetDataOptions) => void;
  chips: {
    showDepartmentChip: boolean;
    showLocationChip: boolean;
    shouldRenderChipsRow: boolean;
  };
  person?: SchemaSMSSharedModels.Person | Person;
  title: string;
  checkboxState: [boolean, (selected: boolean) => void];
  messageBodyPreviewText: string;
  messageStatusAlert: StatusAlert | null;
  toggleActions: {
    onToggleRead: () => Promise<void>;
    onToggleArchived: () => void;
  };
  goToThread: () => void;
};
