import { forwardRef } from 'react';
import { TagsUtils } from '@frontend/api-tag';
import { useTranslation } from '@frontend/i18n';
import { SchemaIO, SchemaTagService } from '@frontend/schema';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { Chip, PopoverDialog, Text } from '@frontend/design-system';
import { PopoverDialogProps } from '../types';
import { TagSelectionPopoverTextButton } from './tag-selection-popover-text-button';

type Tag = SchemaIO<(typeof SchemaTagService)['ListTags']>['output']['tags'][number];
export type TagSelectionPopoverProps = {
  tagsList: Tag[];
  onTagSelect: (tag: Tag) => Promise<void>;
  onTagCreate?: (initName?: string) => void;
  closePopover: () => void;
  trackingIds?: {
    selectTag?: string;
    createTag?: string;
    createNamedTag?: string;
    openTagSettings?: string;
  };
  showTagCreationOption?: boolean;
  searchValue?: string;
};
type PopoverProps = Omit<PopoverDialogProps, 'ref' | 'children'> & TagSelectionPopoverProps;

/**
 * A popover to display a list of tags and allow the user to select one.
 * This should primarily be used in combination with the `useTagSelectionPopover` hook, which combines the `usePopoverDialog` hook with other tag data.
 * @param tagsList - A list of tags to display in the popover. If also providing `searchValue`, this list will be filtered by the search value.
 * @param onTagSelect - A callback function that is called when a tag is selected.
 * @param onTagCreate (optional) - A callback function that is called when the tag creation option is selected. This should usually be used to navigate to the tag creation flow.
 * The popover will be closed after this function is invoked. If not provided, the default behavior is to navigate to the tag creation flow.
 * @param closePopover - A callback function to close the popover. This will be invoked when a tag is selected or when settings is opened.
 * @param trackingIds (optional) - trackingIds to be passed to various elements in the tag selection popover.
 * @param showTagCreationOption (optional) - A boolean to determine if the tag creation option should be shown. This should usually be determined by the TAGS_MANAGE ACL. Defaults to false.
 * @param searchValue (optional) - The search value to filter tags by. This will also populate the initial name for tag creation flows.
 * @param ...rest - Additional props to pass into the `PopoverDialog` component. This should be retrieved using the `usePopoverDialog` hook or `useTagSelectionPopover` hook.
 */
export const TagSelectionPopover = forwardRef<HTMLDialogElement, PopoverProps>(
  (
    { tagsList, onTagSelect, onTagCreate, closePopover, trackingIds, searchValue, showTagCreationOption, ...rest },
    ref
  ) => {
    const { t } = useTranslation('tag-context-menu');
    const { navigate: settingsNavigate } = useSettingsNavigate();

    return (
      <PopoverDialog
        css={{
          overflow: 'hidden',
          maxWidth: 300,
          borderRadius: theme.borderRadius.medium,
        }}
        {...rest}
        ref={ref}
      >
        <div
          css={{
            display: 'flex',
            flexWrap: 'wrap',
            gap: theme.spacing(1),
            paddingBottom: theme.spacing(2),
            maxHeight: 'min(400px, 85vh)',
            overflowY: 'auto',
          }}
        >
          {!!tagsList.length ? (
            tagsList.map((tag) => (
              <Chip.Tag
                key={tag.id}
                size='large'
                color={TagsUtils.convertStringToTagColor(tag.color)}
                onClick={async () => {
                  await onTagSelect(tag);
                  closePopover();
                }}
                trackingId={trackingIds?.selectTag}
              >
                {tag.name}
              </Chip.Tag>
            ))
          ) : (
            <Text css={{ width: '100%', paddingBottom: theme.spacing(1) }} textAlign='center' color='light'>
              {searchValue ? t('No matching tags.') : t('No available tags.')}
            </Text>
          )}
        </div>
        {showTagCreationOption && (
          <TagSelectionPopoverTextButton
            onClick={() => {
              if (onTagCreate) {
                onTagCreate(searchValue);
              } else {
                settingsNavigate({
                  to: '/tags/quick-create',
                  context: { name: searchValue ? encodeURIComponent(searchValue) : '' },
                });
              }
              closePopover();
            }}
            label={searchValue ? t("Create '{{searchValue}}' Tag", { searchValue }) : t('Create Tag')}
            trackingId={searchValue ? trackingIds?.createNamedTag ?? trackingIds?.createTag : trackingIds?.createTag}
          />
        )}
        <TagSelectionPopoverTextButton
          onClick={() => {
            settingsNavigate({ to: '/tags' });
            closePopover();
          }}
          label={t('Tag Settings')}
          trackingId={trackingIds?.openTagSettings}
        />
      </PopoverDialog>
    );
  }
);
TagSelectionPopover.displayName = 'TagSelectionPopover';
