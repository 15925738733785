import { FC, useState } from 'react';
import { css } from '@emotion/react';
import { SubscriptionsQueries } from '@frontend/api-subscription';
import { getUser } from '@frontend/auth-helpers';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import {
  ContentLoader,
  Modal,
  ModalControlResponse,
  Text,
  TextareaField,
  useControlledField,
  useAlert,
} from '@frontend/design-system';
import { getFeatureWiseTrackingId, RequestUpgradeModalTrackingIds } from '../../constants/tracking-ids';
import { useFeatureSubscription } from '../../providers/FeatureSubscriptionProvider';

interface RequestUpgradeModalProps {
  modalControls: ModalControlResponse;
  onActionSuccess: () => void;
}

const NOTE_MAX_LENGTH = 500;

export const RequestUpgradeModal: FC<RequestUpgradeModalProps> = ({ onActionSuccess, modalControls }) => {
  const { feature, selectedLocationIds } = useFeatureSubscription();
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'request-upgrade' });
  const alerts = useAlert();

  const [notes, setNotes] = useState('');
  const user = getUser();

  const { mutate: notifyAdmin, isLoading } = SubscriptionsQueries.useNotifyAdminForUpgrade({
    mutationKey: [selectedLocationIds?.[0], feature, 'notify-admin'],
    onSuccess: () => {
      alerts.success(t('Your admin will be notified of your request by email'));
      onActionSuccess();
    },
    onError: () => {
      alerts.error(t('Failed to notify admin'));
    },
  });

  const noteField = useControlledField({
    onChange: (text) => setNotes(text.slice(0, NOTE_MAX_LENGTH)),
    type: 'text',
    value: notes,
  });

  const handleAction = () => {
    const requestData: SubscriptionsQueries.SchemaNotifyAdminForUpgradeRequest = {
      locationId: selectedLocationIds?.[0] || '',
      featureEnum: feature,
      notes,
      firstName: user?.firstName,
    };
    notifyAdmin(requestData);
  };

  return (
    <Modal {...modalControls.modalProps} minWidth={600}>
      <ContentLoader show={isLoading} />
      <Modal.Header
        onClose={modalControls.closeModal}
        closeBtnTrackingId={getFeatureWiseTrackingId(feature, RequestUpgradeModalTrackingIds.dismissAction)}
      >
        {t('Upgrade Request')}
      </Modal.Header>
      <Modal.Body css={modalBodyStyles}>
        <Text size='medium'>{t('Add a note to your admin about why you want access to this feature.')}</Text>
        <TextareaField
          {...noteField}
          label={t('Note')}
          name={'note'}
          helperText={`${notes.length}/${NOTE_MAX_LENGTH}`}
        />
      </Modal.Body>
      <Modal.Actions
        primaryLabel={t('Request Upgrade')}
        onPrimaryClick={handleAction}
        disablePrimary={!notes.length}
        primaryTrackingId={getFeatureWiseTrackingId(feature, RequestUpgradeModalTrackingIds.requestUpgradeAction)}
      />
    </Modal>
  );
};

const modalBodyStyles = css({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  position: 'relative',
  padding: theme.spacing(2, 0),
});
