import { useTranslation } from '@frontend/i18n';

export const useTimePeriodFilterLabels = () => {
  const { t } = useTranslation('calls', { keyPrefix: 'calls-filter' });

  const labels: Record<string, string> = {
    today: t('Today'),
    yesterday: t('Yesterday'),
    'last-7-days': t('Last 7 Days'),
    'last-14-days': t('Last 14 Days'),
    'last-30-days': t('Last 30 Days'),
    'last-60-days': t('Last 60 Days'),
    'last-90-days': t('Last 90 Days'),
    'last-1-year': t('Last 1 Year'),
  };

  return labels;
};
