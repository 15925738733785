import { Invitation } from 'sip.js/lib/api/invitation';
import { Inviter } from 'sip.js/lib/api/inviter';
import { Session } from 'sip.js/lib/api/session';

export type RemoteParty = {
  displayName: string | undefined;
  uri: string;
};

export type URIType = 'phone' | 'address' | 'park' | 'invalid';

export type ContextualCallInfo = Partial<Pick<OutgoingCall, 'onHoldSince' | 'remoteParty'>>;

type BaseCall = {
  id: string;
  remoteParty: RemoteParty;
  onHoldSince: Date | undefined;
};

export type IncomingCall = BaseCall & {
  invitation: Invitation;
  receivedAt: Date;
};

export type OutgoingCall = BaseCall & {
  inviter: Inviter;
  placedAt: Date;
};

type BaseEstablishedCall = {
  establishedAt: Date;
  session: Session;
};

export type EstablishedIncomingCall = IncomingCall & BaseEstablishedCall;
export type EstablishedOutgoingCall = OutgoingCall & BaseEstablishedCall;
export type EstablishedCall = EstablishedIncomingCall | EstablishedOutgoingCall;

export type TerminatedCall = (IncomingCall | OutgoingCall | EstablishedCall) & {
  terminatedAt: Date;
  terminationReason: string;
};

export type AnyCall = IncomingCall | OutgoingCall | EstablishedCall | TerminatedCall;
export type Transfer = { initialCall: EstablishedCall; transferTarget: EstablishedCall | OutgoingCall };
export type TransferIds = { initialCallId: string; transferTargetId: string };
export type MergedCallGroup = (EstablishedCall | TerminatedCall)[];

export const isIncomingCall = (call: AnyCall): call is IncomingCall => {
  return 'invitation' in call && 'receivedAt' in call && !('establishedAt' in call);
};
export const isOutgoingCall = (call: AnyCall): call is OutgoingCall => {
  return 'inviter' in call && 'placedAt' in call && !('establishedAt' in call);
};

//technically a terminated call is also established, but we're generally interested in if it's not terminated for this check
export const isEstablishedCall = (call: AnyCall): call is EstablishedCall => {
  return 'establishedAt' in call && !('terminatedAt' in call);
};
export const isTerminatedCall = (call: AnyCall): call is TerminatedCall => {
  return 'terminatedAt' in call;
};
