import { useMemo } from 'react';
import { ListSchedulesResponse } from '@weave/schema-gen-ts/dist/schemas/schedule/v3/schedule.pb';
import { OfficeHoursApi } from '@frontend/api-office-hours';
import { ScheduleAvailabilityTypes } from '@frontend/api-schedule-availability';
import { SchedulerV3Queries } from '@frontend/api-scheduler-v3';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { ScheduleAvailabilityHoursTypes } from '@frontend/schedule-availability-hours';
import { useAppScopeStore } from '@frontend/scope';
import { queryKeys } from '../../query-keys';
import { useGetSchedulerV3FeatureFlagDetails } from '../hooks';
import { ScheduleHours } from '../types';
import { convertFromRRuleScheduleHoursToScheduleHoursObject, getSystemTzid } from '../utils';

type UseGetOfficeHoursDataParamType = {
  locationId: string;
};

type UseGetOfficeHoursDataReturnType = {
  officeHoursData: ScheduleAvailabilityHoursTypes.ScheduleAvailabilityByDayOfWeek | ScheduleAvailabilityTypes.Schedule;
  refetch: () => void;
  isError: boolean;
  shouldRenderV3: boolean;
  timezone: string;
  isLoading: boolean;
  officeHoursDataV3?: ListSchedulesResponse;
};

export const useGetOfficeHoursV2OrV3Data = ({
  locationId,
}: UseGetOfficeHoursDataParamType): UseGetOfficeHoursDataReturnType => {
  const { accessibleLocationData } = useAppScopeStore();
  const locationData = accessibleLocationData[locationId];

  const { isScheduleV3FlagEnabled, isLoading: isLoadingV3FeatureFlags } = useGetSchedulerV3FeatureFlagDetails();

  const shouldRenderV3 = isScheduleV3FlagEnabled && !isLoadingV3FeatureFlags;

  const {
    isLoading: isLoadingOfficeHours,
    isError: officeHoursFetchError,
    isFetching: isFetchingOfficeHours,
    refetch: refetchOfficeHoursData,
    data: officeHoursData,
  } = useLocalizedQuery({
    queryKey: queryKeys.officeClosedTimes(locationId),
    queryFn: () => OfficeHoursApi.getOfficeHours(locationId),
    retry: 1,
    enabled: shouldRenderV3 === false && !!locationId,
  });

  const {
    data: officeHoursDataV3,
    isLoading: isLoadingOfficeHoursV3,
    isFetching: isFetchingOfficeHoursV3,
    isError: officeHoursFetchErrorV3,
    refetch: refetchOfficeHoursV3,
  } = SchedulerV3Queries.useListScheduleEntries({
    locationId,
    entityIds: [locationId],
    opts: {
      enabled: !!locationId && shouldRenderV3,
    },
  });

  const officeHoursV3 = useMemo(() => {
    const officeHoursData: ScheduleHours = {
      workingHours: officeHoursDataV3?.schedules[0]?.recurrenceRules?.workingHours,
      breaks: officeHoursDataV3?.schedules[0]?.recurrenceRules?.breaks,
    };

    return convertFromRRuleScheduleHoursToScheduleHoursObject(officeHoursData, locationData?.timezone);
  }, [
    officeHoursDataV3?.schedules[0]?.recurrenceRules?.workingHours,
    officeHoursDataV3?.schedules[0]?.recurrenceRules?.breaks,
    locationData?.timezone,
  ]);

  if (shouldRenderV3) {
    return {
      officeHoursData: officeHoursV3,
      refetch: refetchOfficeHoursV3,
      isError: officeHoursFetchErrorV3,
      shouldRenderV3,
      officeHoursDataV3: officeHoursDataV3 || ({} as ListSchedulesResponse),
      timezone: locationData?.timezone || getSystemTzid(),
      isLoading: isLoadingOfficeHoursV3 || isFetchingOfficeHoursV3,
    };
  }

  return {
    officeHoursData: officeHoursData || {},
    refetch: refetchOfficeHoursData,
    isError: officeHoursFetchError,
    shouldRenderV3,
    timezone: locationData?.timezone || getSystemTzid(),
    isLoading: isLoadingOfficeHours || isFetchingOfficeHours,
  };
};
