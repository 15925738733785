import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const headerStyle = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.spacing(2)};

  .add-more-forms {
    display: inline-flex;
    align-items: center;
    gap: ${theme.spacing(1)};
    color: ${theme.colors.text.interactive};
  }
`;

export const containerStyle = css`
  overflow: auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, 230px);
  grid-gap: ${theme.spacing(2, 4)};
`;
