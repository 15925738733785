import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const modalStyle = css`
  height: 80vh;
  min-width: min(400px, 90vw);
`;

export const headerStyle = css`
  h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${theme.spacing(2)};
  }
`;

export const previewContainerStyle = css`
  display: flex;
  height: 100%;
`;
