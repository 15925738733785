import { IconName, Icon } from '@frontend/icons';
import { theme } from '@frontend/theme';
import { PopoverMenuItem, Text } from '@frontend/design-system';
import { GetTriggerPropsFn } from './types';

type InboxFilterPopoverSubmenuItemProps = {
  getSubmenuTriggerProps: GetTriggerPropsFn;
  iconName?: IconName;
  label: string;
  count?: number;
  divider?: 'top' | 'bottom';
  trackingId?: string;
};

export const InboxFiltersPopoverSubmenuItem = ({
  getSubmenuTriggerProps,
  iconName,
  label,
  count,
  divider,
  trackingId,
}: InboxFilterPopoverSubmenuItemProps) => (
  <PopoverMenuItem
    css={[
      {
        justifyContent: 'space-between',
        width: '100%',
        cursor: 'pointer',
      },
      !!divider && {
        [divider === 'top' ? 'borderTop' : 'borderBottom']: `1px solid ${theme.colors.neutral20}`,
      },
    ]}
    {...getSubmenuTriggerProps()}
    trackingId={trackingId}
  >
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      }}
    >
      {iconName && (
        <Icon
          name={iconName}
          css={{
            color: theme.colors.neutral50,
          }}
        />
      )}
      <Text>{label}</Text>
    </div>
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(1),
      }}
    >
      {!!count && (
        <Text
          css={{
            color: theme.colors.primary70,
            padding: theme.spacing(0.25, 0.5),
            borderRadius: theme.borderRadius.small,
            backgroundColor: theme.colors.primary10,
          }}
          size='small'
        >
          {count}
        </Text>
      )}
      <Icon
        name='alt-caret-right-tiny'
        css={{
          color: theme.colors.neutral50,
        }}
      />
    </div>
  </PopoverMenuItem>
);
