import { memo, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { useNavigate } from '@tanstack/react-location';
import { Permission } from '@weave/schema-gen-ts/dist/shared/waccess/acls.pb';
import { AnalyticsCommonTypes, PhoneAnalyticsApi, PhoneAnalyticsTypes } from '@frontend/api-analytics';
import { hasSchemaACL } from '@frontend/auth-helpers';
import { Chart } from '@frontend/charts';
import { useTranslation } from '@frontend/i18n';
import { useAppScopeStore, useScopedQuery } from '@frontend/scope';
import { useHasFeatureFlag } from '@frontend/shared';
import { theme } from '@frontend/theme';
import { AnalyticsTrackingIds } from '../..';
import { featureFlags } from '../../feature-flags';
import { queryKeys } from '../../query-keys';
import { appendTime, calculateNumberAverage, formatters } from '../../utils';
import { DemoChip } from '../demo-chip';
import { useIsPhoneAnalyticsDemoAccount, usePhoneAnalyticsDemoData } from '../phone/hooks';
import { Insights } from './insights';

type Props = {
  data?: PhoneAnalyticsTypes.PhoneReportChart[];
  filters?: AnalyticsCommonTypes.AnalyticsSummaryFilters;
  isLoading?: boolean;
  onLoading?: (isLoading: boolean) => void;
};

export const PhoneIncomingCategoriesOnDashboard = memo(({ data, filters, isLoading, onLoading }: Props) => {
  const { t } = useTranslation('analytics');
  const navigate = useNavigate();
  const queryString = useMemo(() => `${JSON.stringify({ ...filters })}`, [filters]);
  const phoneAnalyticsEnabled = useHasFeatureFlag(featureFlags.phoneAnalytics);
  const { selectedLocationIdsWithParents } = useAppScopeStore();
  const isDemoAccount = useIsPhoneAnalyticsDemoAccount();
  const demoData = usePhoneAnalyticsDemoData();
  const locationId = selectedLocationIdsWithParents[0];
  const hasAclAccess =
    hasSchemaACL(locationId, Permission.ANALYTICS_READ_PHONE) || hasSchemaACL(locationId, Permission.ANALYTICS_READ);

  const { data: phoneData, isFetching: isLoadingPhoneData } = useScopedQuery({
    queryKey: queryKeys.analyticsDashboard(`${queryString}-homepage-phone-summary-${isDemoAccount}`),
    queryFn: () =>
      filters && !data && !isDemoAccount
        ? PhoneAnalyticsApi.getPhoneReportingCharts({
            EndDate: appendTime(filters.endDate),
            LocationID: filters.locationIds,
            StartDate: appendTime(filters.startDate),
            TimeZone: filters.timeZone,
          })
        : null,
    retry: false,
    refetchOnWindowFocus: false,
    select: (data) => {
      return isDemoAccount ? demoData?.dailyData : data;
    },
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const incomingCallsCategories = useMemo(
    () =>
      (data || phoneData || []).reduce(
        (
          acc,
          { TotalInboundAbandonedCalls, TotalInboundAnsweredCalls, TotalInboundCalls, TotalInboundMissedCalls }
        ) => ({
          abandoned: (acc.abandoned || 0) + (TotalInboundAbandonedCalls as number),
          answered: (acc.answered || 0) + (TotalInboundAnsweredCalls as number),
          missed: (acc.missed || 0) + (TotalInboundMissedCalls as number),
          total: (acc.total || 0) + (TotalInboundCalls as number),
        }),
        {} as Record<string, number>
      ),
    [data, phoneData]
  );

  const forwardedCalls =
    incomingCallsCategories.total -
    (incomingCallsCategories.abandoned + incomingCallsCategories.missed + incomingCallsCategories.answered);

  useEffect(() => {
    onLoading?.(isLoadingPhoneData);
  }, [isLoadingPhoneData]);

  if (!phoneAnalyticsEnabled) {
    return null;
  }

  return (
    <Chart
      colors={{
        abandoned: theme.colors.warning50,
        answered: theme.colors.primary40,
        forwarded: theme.colors.secondary.seaweed30,
        missed: theme.colors.critical30,
      }}
      commonTooltipLabel={t('Calls')}
      isLoading={isLoading || isLoadingPhoneData}
      labels={{
        abandoned: t('Abandoned'),
        answered: t('Answered'),
        forwarded: t('Forwarded'),
        missed: t('Missed'),
        total: t('Total'),
      }}
    >
      <Chart.Header
        actions={[
          {
            disabled: !hasAclAccess,
            hoverText: hasAclAccess ? undefined : t('This action is only allowed by an account admin.'),
            label: t('Show More'),
            onClick: () => {
              navigate({
                to: '/analytics/phone',
              });
            },
            trackingId: AnalyticsTrackingIds.analytics.showMorePhoneAnalytics,
          },
        ]}
        leftElement={isDemoAccount && <DemoChip />}
        title={t('Incoming Phone Calls')}
      />
      <Chart.HorizontalContainer css={styles.chartWrapper} alignItems='center'>
        <Chart.PieChart
          data={{
            centerMetric: incomingCallsCategories.total,
            groups: [
              {
                name: 'forwarded',
                value: forwardedCalls,
              },
              {
                name: 'abandoned',
                value: incomingCallsCategories.abandoned,
              },
              {
                name: 'missed',
                value: incomingCallsCategories.missed,
              },
              {
                name: 'answered',
                value: incomingCallsCategories.answered,
              },
            ],
          }}
        />
        {!!incomingCallsCategories.total && (
          <Chart.Legends
            layout='vertical'
            values={{
              answered: formatters.value.format(incomingCallsCategories.answered),
              forwarded: formatters.value.format(forwardedCalls),
              missed: formatters.value.format(incomingCallsCategories.missed),
              abandoned: formatters.value.format(incomingCallsCategories.abandoned),
            }}
          />
        )}
      </Chart.HorizontalContainer>
      {!!incomingCallsCategories.total && (
        <Insights
          title={t('Call Answer Rate')}
          value={`${(
            calculateNumberAverage(incomingCallsCategories.answered, incomingCallsCategories.total) * 100 || 0
          ).toFixed(2)}%`}
        />
      )}
    </Chart>
  );
});

PhoneIncomingCategoriesOnDashboard.displayName = 'PhoneIncomingCategoriesOnDashboard';

const styles = {
  chartWrapper: css`
    flex-direction: row;
  `,
};
