import { Dispatch, useEffect } from 'react';
import { css } from '@emotion/react';
import { cloneDeep } from 'lodash-es';
import { CallQueueApi } from '@frontend/api-call-queue-legacy';
import { useTranslation } from '@frontend/i18n';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { theme } from '@frontend/theme';
import { DropdownField, Heading, Text, useFormField } from '@frontend/design-system';
import { queryKeys } from '../../../../query-keys';
import { alphabeticalSort } from '../../../../utils/phone-utils';
import { FallbackInstructionSet } from './fallback-instruction-set';
import { CallQueueInstructionSet, InstructionReducerAction } from './routing-reducers';

type RoutingCallQueueFormProps = {
  instructions: CallQueueInstructionSet;
  dispatchState: Dispatch<InstructionReducerAction>;
};

export const RoutingCallQueueForm: React.FC<React.PropsWithChildren<RoutingCallQueueFormProps>> = ({
  instructions,
  dispatchState,
}) => {
  const { t } = useTranslation('phone', { keyPrefix: 'departments' });
  const { data: callQueues } = useLocalizedQuery({
    queryKey: queryKeys.callQueues(),
    queryFn: CallQueueApi.list,
    select: (data) => data.sort((a, b) => alphabeticalSort(a.Name, b.Name)),
  });

  const callQueueId = instructions[0]?.callQueue?.callQueueId || callQueues?.[0]?.ID || '';

  const callQueuesProps = useFormField({ type: 'dropdown', value: callQueueId }, [callQueueId]);

  const { data: callQueueData } = useLocalizedQuery({
    queryKey: queryKeys.callQueue(callQueueId),
    queryFn: () => {
      return CallQueueApi.get(callQueueId);
    },
    enabled: !!callQueueId,
  });

  const MaxWaitTime = Math.floor(parseInt(callQueueData?.MaxWaitTime ?? '0') / 1000 / 60);

  useEffect(() => {
    const newInstructionSet = cloneDeep(instructions) as CallQueueInstructionSet;
    newInstructionSet[0] = {
      ...newInstructionSet[0],
      callQueue: {
        callQueueId: callQueuesProps.value,
      },
    };
    dispatchState({ type: 'call-queue-update', payload: newInstructionSet });
  }, [callQueuesProps.value]);

  return (
    <>
      <article>
        <Heading
          level={2}
          css={css`
            margin-bottom: ${theme.spacing(0)};
          `}
        >
          {t('Call Queue Ring Settings')}
        </Heading>
        <Text
          color='light'
          css={css`
            margin-bottom: ${theme.spacing(4)};
          `}
        >
          {t(
            'Choose a call queue and a fallback option which will occur after the max caller time (set in call queue settings).'
          )}
        </Text>
        <div
          css={css`
            display: flex;
          `}
        >
          <DropdownField
            {...callQueuesProps}
            label={t('Select a Call Queue')}
            helperText={t('Choose a call queue to route to')}
            placeholder={t('Select a Call Queue')}
            name='CallQueues'
            css={css`
              width: ${theme.spacing(38)};
            `}
          >
            {callQueues?.map((callQueue) => {
              if (callQueue?.ID) {
                return (
                  <DropdownField.Option key={callQueue.ID} value={callQueue.ID} searchValue={callQueue.Name}>
                    {callQueue.Name}
                  </DropdownField.Option>
                );
              }
              return null;
            })}
          </DropdownField>
          {!!callQueueId && (
            <Text
              color='light'
              css={css`
                display: flex;
                justify-content: center;
                margin-left: ${theme.spacing(3)};
                margin-top: ${theme.spacing(1)};
              `}
            >
              {t('Max Caller Hold Time: {{MaxWaitTime}}m', { MaxWaitTime })}
            </Text>
          )}
        </div>
      </article>

      <FallbackInstructionSet instructions={instructions} dispatch={dispatchState} />
    </>
  );
};
