import { ContactInfo } from '@weave/schema-gen-ts/dist/schemas/persons/v3/contact_info.pb';
import { ContactType_Enum } from '@weave/schema-gen-ts/dist/shared/persons/v3/enums.pb';
import { format, parse } from 'date-fns';
import dayjs from 'dayjs';
import { ExceptionTypes } from '@frontend/api-schedule-exceptions';
import { ExceptionEventParams } from './types';

export const getIsEndTimeBeforeStartTime = (
  startDate: string,
  startTime: string,
  endDate: string,
  endTime: string
): boolean => {
  if (!startTime?.trim() || !endTime?.trim()) return false;

  try {
    // Convert time strings to dayjs objects
    const startTimeObj = dayjs(startDate);
    const endTimeObj = dayjs(endDate);

    const [startTimeHour, startTimeMinute, startTimeSecond] = startTime.split(':');
    const [endTimeHour, endTimeMinute, endTimeSecond] = endTime.split(':');

    const startDateTimeObj = startTimeObj
      .hour(Number(startTimeHour))
      .minute(Number(startTimeMinute))
      .second(Number(startTimeSecond));

    const endDateTimeObj = endTimeObj
      .hour(Number(endTimeHour))
      .minute(Number(endTimeMinute))
      .second(Number(endTimeSecond));

    // Return a boolean value indicating whether the end time is smaller than the start time
    return endDateTimeObj.isBefore(startDateTimeObj) || (startDate === endDate && startTime === endTime);
  } catch (e) {
    return false;
  }
};

export const getExceptionReqObject = ({
  name,
  startDate,
  startTime,
  endDate,
  endTime,
  isAvailable,
  id,
}: ExceptionEventParams): ExceptionTypes.Exception => {
  const startDateString = dayjs(startDate).format('YYYY-MM-DD');
  const endDateString = dayjs(endDate).format('YYYY-MM-DD');

  return {
    id: id || '',
    name: name || '',
    isAvailable: isAvailable,
    startDateTime: dayjs(`${startDateString} ${startTime}`).format(),
    endDateTime: dayjs(`${endDateString} ${endTime}`).format(),
  };
};

export const is12hrFormat = (timeStr: string) => {
  // Regular expression for matching 12-hour time format (hh:mm am/pm)
  const pattern = /^(1[0-2]|0?[1-9]):[0-5][0-9] [APMapm]{2}$/;

  // Check if the provided string matches the pattern
  return pattern.test(timeStr);
};

export const convertTo24HourFormat = (time12Hour: string) => {
  if (!time12Hour) return '';

  if (!is12hrFormat(time12Hour)) {
    try {
      return format(parse(time12Hour, 'HH:mm', new Date()), 'HH:mm:ss');
    } catch (e) {
      return time12Hour;
    }
  }

  // Parse the input time in 12-hour format
  const parsedTime = parse(time12Hour, 'hh:mm aa', new Date());

  // Format the parsed time in 24-hour format
  const convertedTime = format(parsedTime, 'HH:mm:ss');

  return convertedTime;
};

export const isUUID = (id: string): boolean => {
  if (!id) return false;
  const uuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return uuidRegex.test(id);
};

export const containsNoAlphabet = (str: string) => !/[a-zA-Z]/.test(str);

export const sanitizePhoneNumber = (txt: string) => txt.replace(/\D/g, '');

type GetPersonContactInfoByTypeArgs = {
  contactInfo?: ContactInfo[];
  contactType: ContactType_Enum;
};

/**
 * Retrieves the contact information of a person based on the specified contact type.
 *
 * @param {GetPersonContactInfoByTypeArgs} args - The arguments object.
 * @param {Array} args.contactInfo - An array of contact information objects.
 * @param {string} args.contactType - The type of contact information to retrieve (e.g., 'email', 'phone').
 * @returns {string} - The destination (value) of the contact information.
 */
export const getPersonContactInfoByType = ({ contactInfo, contactType }: GetPersonContactInfoByTypeArgs) =>
  contactInfo?.find((contactInfoItem) => contactInfoItem.type === contactType)?.destination || '';
