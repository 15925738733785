import { PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { demoDataUtils } from '../../../utils';
import { getMockProcedures } from './procedures';
import { requiredPatientInfoFields } from './shared-helpers';

export const hygieneTreatmentPlanPatientsDetails = (): PracticeAnalyticsTypes.PatientInfo[] =>
  demoDataUtils.generateRandomUserNames(15).map(({ firstName, lastName }, id) => {
    const mockProcedures = getMockProcedures(demoDataUtils.generateRandomBoolean());
    const amount = mockProcedures.reduce((acc: number, cur) => acc + cur.procedureAmount, 0);

    return {
      ...requiredPatientInfoFields,
      diagnosed: demoDataUtils.generateRandomDate(),
      FirstName: firstName,
      id: `${id}`,
      LastName: lastName,
      MobilePhone: demoDataUtils.generateRandomPhoneNumber(),
      procedures: mockProcedures,
      productionAmount: amount,
      providerFirstName: '',
      providerLastName: '',
      providers: '',
    };
  });

export const hygieneTreatmentPlan = (): PracticeAnalyticsTypes.HygieneTreatmentPlanResponse => {
  const totalAcceptedHygiene = demoDataUtils.generateRandomInt(150, 180);

  return {
    location: {
      hygieneTreatmentPlan: {
        benchmarks: [
          {
            value: 0.38,
            label: 'Top Diagnosed',
          },
          {
            value: 0.75,
            label: 'Top Accepted',
          },
        ],
        details: {
          patients: hygieneTreatmentPlanPatientsDetails(),
        },
        totals: {
          accepted: demoDataUtils.generateRandomInt(50, 100),
          acceptedPercent: Math.random() * (0.6 - 0.8) + 0.8,
          diagnosed: demoDataUtils.generateRandomInt(100, 200),
          diagnosedPercent: Math.random() * (0.5 - 0.2) + 0.2,
          qualifiedVisits: demoDataUtils.generateRandomInt(200, 300),
          unscheduledTreatment: demoDataUtils.generateRandomInt(500, 1000),
        },
      },
      industryAvg: {
        totals: {
          totalAcceptedHygiene,
          totalDiagnosedHygiene: totalAcceptedHygiene + demoDataUtils.generateRandomInt(10, 30),
          totalUnscheduledTreatmentHygiene: demoDataUtils.generateRandomInt(500, 1000),
        },
      },
    },
  };
};
