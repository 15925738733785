import { useState } from 'react';
import { Device, DeviceInclude_Enum } from '@weave/schema-gen-ts/dist/schemas/phone/devices/v2/devices.pb';
import { ValidationSeverity_Enum } from '@weave/schema-gen-ts/dist/schemas/phone-devices/lines/line_api.pb';
import { useQueryClient } from 'react-query';
import { DevicesApi, DevicesQueries, DevicesQueryKey } from '@frontend/api-devices';
import { queryKeys as phoneSyncQueryKeys } from '@frontend/api-phone-sync';
import SipProfileAPI from '@frontend/api-sip-profile';
import { useTranslation } from '@frontend/i18n';
import { usePhoneConfigShallowStore } from '@frontend/phone-config';
import { useMutation, useQuery } from '@frontend/react-query-helpers';
import { useSettingsNavigate } from '@frontend/settings-routing';
import { ContentLoader, Modal, ModalControlModalProps, useAlert } from '@frontend/design-system';
import { queryKeys } from '../../../../query-keys';
import { usePhoneSettingsShallowStore } from '../../../../store/settings';
import { AdditionalChecks } from './additional-checks';
import { RequiredError } from './required-error';

type Props = {
  modalProps: ModalControlModalProps;
  device: Device | undefined;
  locationIds: string[];
};

export const DestroySoftphoneModal = ({ modalProps, device, locationIds }: Props) => {
  const { t } = useTranslation('phone', { keyPrefix: 'destroy-softphone' });
  const [confirmedChecklist, setConfirmedChecklist] = useState(false);
  const voicemails = DevicesQueries.useValidateDestroyQuery(
    {
      id: device?.deviceId ?? '',
    },
    { locationId: locationIds[0] }
  );
  const alerts = useAlert();
  const { navigate } = useSettingsNavigate();
  const queryClient = useQueryClient();
  const { settingsTenantLocation } = usePhoneSettingsShallowStore('settingsTenantLocation');
  const { setPhoneConfig, phoneConfig } = usePhoneConfigShallowStore('setPhoneConfig', 'phoneConfig');

  const { data: devices } = useQuery({
    queryKey: [settingsTenantLocation?.phoneTenantId, ...queryKeys.settings.listAvailableDevices()],
    queryFn: () =>
      SipProfileAPI.List({ tenantId: settingsTenantLocation?.phoneTenantId }).then((res) => res.sipProfiles),
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (id: string) => {
      return DevicesApi.DestroyLine({ id });
    },
    onSuccess: () => {
      alerts.success(t('Successfully destroyed {{deviceName}}', { deviceName: device?.name }));
      queryClient.invalidateQueries([
        locationIds,
        ...DevicesQueryKey.queryKeys.listDevices([
          DeviceInclude_Enum.CALL_GROUPS,
          DeviceInclude_Enum.E911_ADDRESS,
          DeviceInclude_Enum.REGISTRATION,
        ]),
      ]);
      queryClient.invalidateQueries({
        predicate: (query) => {
          const key = phoneSyncQueryKeys.listDevices().at(-1);
          return key ? query.queryKey.includes(key) : false;
        },
      });
      navigate({ to: '/phone/devices' });
      const deviceIsConnected = devices?.find((d) => d.device?.deviceId === device?.deviceId);
      if (deviceIsConnected?.sipProfileId === phoneConfig?.sipProfileId) {
        setPhoneConfig(undefined);
      }
    },
  });

  const severity = voicemails.data?.severity;
  const isSeverityFatal = severity === ValidationSeverity_Enum['FATAL'];
  const isRequiredErrorShowing =
    severity === ValidationSeverity_Enum['FATAL'] || severity === ValidationSeverity_Enum['ERROR'];

  const filteredErrors = voicemails.data?.errors.filter((error) => error.severity === ValidationSeverity_Enum['ERROR']);

  return (
    <Modal maxWidth={800} {...modalProps}>
      <ContentLoader show={voicemails.isLoading || isLoading} />
      <Modal.Header onClose={modalProps.onClose}>
        {t('Destroy {{deviceName}}', { deviceName: device?.name })}
      </Modal.Header>
      <Modal.Body>
        {isRequiredErrorShowing && (
          <RequiredError isSeverityFatal={isSeverityFatal} errors={filteredErrors} deviceName={device?.name ?? ''} />
        )}
        {!isSeverityFatal && (
          <AdditionalChecks
            deviceName={device?.name ?? ''}
            isRequiredErrorShowing={isRequiredErrorShowing}
            setConfirmedChecklist={setConfirmedChecklist}
          />
        )}
      </Modal.Body>
      {!isSeverityFatal && (
        <Modal.Actions
          onSecondaryClick={modalProps.onClose}
          secondaryLabel={t('Cancel')}
          primaryLabel={t('Destroy')}
          disablePrimary={!confirmedChecklist || isRequiredErrorShowing}
          onPrimaryClick={() => mutate(device?.deviceId ?? '')}
          destructive
          helperText={!isRequiredErrorShowing ? t('Please acknowledge the criteria before destroying') : ''}
          secondaryTrackingId='softphone-destroy-cancel'
          primaryTrackingId='softphone-destroy-confirm'
        />
      )}
    </Modal>
  );
};
