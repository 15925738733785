import { UseMutationOptions } from 'react-query';
import appConfig from '@frontend/env';
import { http } from '@frontend/fetch';
import { useMutation } from '@frontend/react-query-helpers';
import { SchemaPaymentsCommandsService } from '@frontend/schema';
import {
  PaymentClientSecret,
  CreatePaymentIntentBody,
  CreatePaymentIntentResponse,
  PaymentRequest,
  LocationPortalResponse,
  ValidateACHTransactionBalanceResponse,
  ValidateACHTransactionBalanceRequest,
} from './types';

const { PAY_BACKEND_API } = appConfig;

export const getPaymentRequest = (requestId: string) =>
  http.getData<PaymentRequest>(`${PAY_BACKEND_API}/payments/requests/${requestId}`, {
    skipValidation: true,
    headers: { accept: 'application/json' },
  });

type CreateOBPPaymentIntentProps = Omit<CreatePaymentIntentProps, 'url'> & {
  id: string;
  captchaToken: string;
};
export const createOBPPaymentIntent = ({ id, requestBody, captchaToken }: CreateOBPPaymentIntentProps) =>
  createPaymentIntent({
    url: `${PAY_BACKEND_API}/payments/v2/portal/${id}/payments`,
    requestBody,
    captchaToken,
  });

type CreatePaymentIntentProps = {
  url: string;
  requestBody: CreatePaymentIntentBody;
  captchaToken?: string;
};

export const createPaymentIntent = ({ url, requestBody, captchaToken }: CreatePaymentIntentProps) =>
  http.post<CreatePaymentIntentResponse, CreatePaymentIntentBody>(url, requestBody, {
    skipValidation: true,
    headers: {
      'Content-Type': 'application/json',
      ...(!!captchaToken && { 'X-Weave-Recaptcha-Response-Token': captchaToken }),
    },
  });

export const getPaymentClientSecretWithAuthToken = (location: string, authToken: string) =>
  http.getData<PaymentClientSecret>(location, {
    skipValidation: true,
    headers: { accept: 'application/json', 'Content-Type': 'application/json', Authorization: `Bearer ${authToken}` },
  });

export const getPaymentClientSecret = (location: string, url: string) =>
  http.getData<PaymentClientSecret>(
    url,
    addLocationHeader(location, {
      accept: 'application/json',
      'Content-Type': 'application/json',
    })
  );

export const validateBirthday = (url: string, birthday: string) =>
  http.post<unknown>(
    url,
    { data: { birthday } },
    { skipValidation: true, headers: { 'Content-Type': 'application/json' } }
  );

const LOCATION_ID_HEADER = 'Location-Id';
const addLocationHeader = (locationId: string, headers: Record<string, string> = {}) => ({
  headers: { [LOCATION_ID_HEADER]: locationId, ...headers },
});

// Get location details for Location Portal
export const getLocationPortalDetails = (locationSlug: string) =>
  http.getData<LocationPortalResponse>(`${PAY_BACKEND_API}/payments/portal/${locationSlug}`, {
    skipValidation: true,
  });

export const makePayment = (
  paymentsUrl: string,
  planDetails: CreatePaymentIntentBody,
  locationId: string,
  invoiceId?: string
) => {
  const makePaymentUrl = invoiceId
    ? `${paymentsUrl}/v2/portal/requests/${invoiceId}/payments`
    : `${paymentsUrl}/v2/locations/${locationId}/payments`;
  return http.post<CreatePaymentIntentResponse>(makePaymentUrl, { ...planDetails }, addLocationHeader(locationId));
};

export const useValidateACHTransactionBalance = (
  options?: UseMutationOptions<ValidateACHTransactionBalanceResponse, unknown, ValidateACHTransactionBalanceRequest>
) => {
  return useMutation({
    mutationFn: (request) => SchemaPaymentsCommandsService.ValidateACHTransactionBalance(request),
    ...options,
  });
};
