import { FC } from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { Feature } from '@weave/schema-gen-ts/dist/shared/feature/location_feature.pb';
import { useTranslation } from '@frontend/i18n';
import { theme } from '@frontend/theme';
import { Text } from '@frontend/design-system';
import wivPromotionIcon from '../../../assets/wiv-promotion-icon.svg';
import { useFeatureSubscriptionConfig } from '../../../hooks/useFeatureSubscriptionConfig';
import { FeatureSubscriptionContext } from '../../../providers/FeatureSubscriptionProvider';
import { WivPromotionAction } from './WivPromotionAction';

interface WivPromotionCardProps {
  cardStyles?: Interpolation<Theme>;
  onUpgradeSuccess: () => void;
  locationIds: string[];
  source: 'contacts' | 'appointments';
}
export const WivPromotionCard: FC<WivPromotionCardProps> = ({ cardStyles, locationIds, onUpgradeSuccess, source }) => {
  const featureSubscriptionConfig = useFeatureSubscriptionConfig({ feature: Feature.WEAVE_VERIFY, locationIds });
  const { t } = useTranslation('featurePromotion', { keyPrefix: 'wiv-promotion' });

  return (
    <FeatureSubscriptionContext.Provider value={featureSubscriptionConfig}>
      <div css={[{ display: 'flex', gap: theme.spacing(1), alignItems: 'center' }, cardStyles]}>
        <img src={wivPromotionIcon} height={theme.spacing(6)} width={theme.spacing(6)} />
        <div css={{ display: 'flex', flexDirection: 'column' }}>
          <Text size='large' color='light'>
            {t('Save time by using Weave insurance verification.')}
          </Text>
          <WivPromotionAction trackingPrefix={source} onUpgradeSuccess={onUpgradeSuccess} />
        </div>
      </div>
    </FeatureSubscriptionContext.Provider>
  );
};
