import { memo } from 'react';
import { css } from '@emotion/react';
import { PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { useTranslation } from '@frontend/i18n';
import { Icon, IconName } from '@frontend/icons';
import { breakpoints } from '@frontend/responsiveness';
import { theme } from '@frontend/theme';
import { Chip, ChipVariants, NakedButton, Text } from '@frontend/design-system';
import { trackingIds } from '../../tracking-ids';
import { formatters } from '../../utils';

export interface UnscheduledOpportunities {
  cancelledPatients: PracticeAnalyticsTypes.PatientInfo[];
  hygieneFollowUp: PracticeAnalyticsTypes.PatientInfo[];
  hygieneTreatment: PracticeAnalyticsTypes.PatientInfo[];
  newPatients: PracticeAnalyticsTypes.PatientInfo[];
  restorativeTreatment: PracticeAnalyticsTypes.PatientInfo[];
}

interface OpportunityCardProps {
  iconConfig: {
    bgColor: string;
    borderColor: string;
    name?: IconName;
    variant: ChipVariants;
  };
  onClick: () => void;
  subtitle: string;
  title: string;
  value: string;

  isSelected?: boolean;
  trackingId?: string;
}

interface OpportunityCardsProps {
  onChange: (value: keyof UnscheduledOpportunities) => void;
  unscheduledOpportunities: UnscheduledOpportunities;

  selectedOpportunities?: (keyof UnscheduledOpportunities)[] | null;
}

const OpportunityCard = memo(
  ({ iconConfig, isSelected, onClick, subtitle, title, trackingId, value }: OpportunityCardProps) => {
    return (
      <NakedButton
        className={isSelected ? 'selected' : ''}
        css={styles.opportunityCard}
        onClick={onClick}
        trackingId={trackingId}
      >
        <div>
          <Text color='subdued'>{title}</Text>
          <Text weight='bold' style={{ fontSize: theme.fontSize(20) }}>
            {value}
          </Text>
          <Text size='small'>{subtitle}</Text>
        </div>
        <Chip
          variant={iconConfig.variant}
          style={{
            backgroundColor: iconConfig.bgColor,
            border: `1px solid ${iconConfig.borderColor}`,
            borderRadius: theme.borderRadius.medium,
            height: theme.spacing(5),
            minWidth: theme.spacing(5),
          }}
        >
          <Icon name={iconConfig.name ?? 'user'} />
        </Chip>
      </NakedButton>
    );
  }
);

export const OpportunityCards = memo(
  ({ onChange, selectedOpportunities, unscheduledOpportunities }: OpportunityCardsProps) => {
    const { t } = useTranslation('analytics');

    return (
      <div css={styles.mainWrapper}>
        <OpportunityCard
          iconConfig={{
            bgColor: theme.colors.secondary.seaweed5,
            borderColor: theme.colors.secondary.seaweed50,
            variant: 'seaweed',
          }}
          isSelected={selectedOpportunities?.includes('hygieneTreatment')}
          onClick={() => onChange('hygieneTreatment')}
          subtitle={t('Unscheduled')}
          title={t('Hygiene Treatment')}
          trackingId={trackingIds.practiceAnalytics.opportunityCardHygieneTreatment}
          value={formatters.value.format(unscheduledOpportunities.hygieneTreatment.length)}
        />
        <OpportunityCard
          iconConfig={{
            bgColor: theme.colors.secondary.eggplant5,
            borderColor: theme.colors.secondary.eggplant50,
            variant: 'eggplant',
          }}
          isSelected={selectedOpportunities?.includes('restorativeTreatment')}
          onClick={() => onChange('restorativeTreatment')}
          subtitle={t('Unscheduled')}
          title={t('Restorative Treatment')}
          trackingId={trackingIds.practiceAnalytics.opportunityCardRestorativeTreatment}
          value={formatters.value.format(unscheduledOpportunities.restorativeTreatment.length)}
        />
        <OpportunityCard
          iconConfig={{
            bgColor: theme.colors.primary5,
            borderColor: theme.colors.primary50,
            variant: 'primary',
          }}
          isSelected={selectedOpportunities?.includes('hygieneFollowUp')}
          onClick={() => onChange('hygieneFollowUp')}
          subtitle={t('Unscheduled')}
          title={t('Hygiene Follow-Up')}
          trackingId={trackingIds.practiceAnalytics.opportunityCardHygieneFollowUp}
          value={formatters.value.format(unscheduledOpportunities.hygieneFollowUp.length)}
        />
        <OpportunityCard
          iconConfig={{
            bgColor: theme.colors.tangerine5,
            borderColor: theme.colors.tangerine50,
            name: 'assign-user',
            variant: 'tangerine',
          }}
          isSelected={selectedOpportunities?.includes('newPatients')}
          onClick={() => onChange('newPatients')}
          subtitle={t('Unscheduled')}
          title={t('New Patients')}
          trackingId={trackingIds.practiceAnalytics.opportunityCardNewPatients}
          value={formatters.value.format(unscheduledOpportunities.newPatients.length)}
        />
        <OpportunityCard
          iconConfig={{
            bgColor: theme.colors.critical5,
            borderColor: theme.colors.critical50,
            name: 'phone-missed',
            variant: 'critical',
          }}
          isSelected={selectedOpportunities?.includes('cancelledPatients')}
          onClick={() => onChange('cancelledPatients')}
          subtitle={t('Cancelled')}
          title={t('Cancelled Patients')}
          trackingId={trackingIds.practiceAnalytics.opportunityCardCancelledPatients}
          value={formatters.value.format(unscheduledOpportunities.cancelledPatients.length)}
        />
      </div>
    );
  }
);

OpportunityCard.displayName = 'OpportunityCard';
OpportunityCards.displayName = 'OpportunityCards';

const styles = {
  mainWrapper: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(3)};
    margin: ${theme.spacing(3, 0)};
  `,

  opportunityCard: css`
    align-items: center;
    border-radius: ${theme.borderRadius.medium};
    border: 1px solid ${theme.colors.neutral20};
    cursor: pointer;
    display: flex;
    flex: 1;
    gap: ${theme.spacing(1)};
    justify-content: space-between;
    min-width: 180px;
    overflow: hidden;
    padding: ${theme.spacing(3)};
    position: relative;
    text-align: start;

    @media screen and (min-width: ${breakpoints.medium.min}px) {
      min-width: 240px;
    }

    @media screen and (min-width: 1400px) {
      min-width: 160px;
    }

    &:hover {
      background-color: ${theme.colors.neutral5};
    }

    &.selected {
      background-color: ${theme.colors.primary5};
      border-color: ${theme.colors.primary50};
    }
  `,
};
