import { FC } from 'react';
import { BuilderFieldDivisionCategories } from '../../../constants';
import { useFormBuilderStore } from '../../../provider';
import CategoriesLayout from './categories-layout/categories-layout.component';
import { useFilteredCategories } from './hooks';

export const OtherFields: FC = () => {
  const { filteredCategories } = useFilteredCategories({
    categories: BuilderFieldDivisionCategories.OTHER_FIELD_CATEGORIES,
  });
  const { otherFieldElements } = useFormBuilderStore(['otherFieldElements']);

  if (!otherFieldElements) {
    return null;
  }

  return <CategoriesLayout categories={filteredCategories} fields={otherFieldElements} type='other' />;
};
