import { useMemo } from 'react';
import { useTranslation } from '@frontend/i18n';
import { InboxType } from '@frontend/inbox-navigation';
import { createShallowStore, createStoreWithPersistAndSubscribe } from '@frontend/store';
import { HiddenFilterKeys, InboxFilter, InboxFilters, InboxStatus } from '../types';

export const DEFAULT_INBOX_FILTER_VALUE: InboxFilters = {
  statuses: [],
  tags: [],
  departments: [],
  startDate: '',
  endDate: '',
  startTime: '',
  endTime: '',
};

export interface InboxFiltersStore {
  inboxFilters: InboxFilters;
  setInboxFilters: (val: InboxFilters) => void;
  clearInboxFilters: () => void;
}

export const useInboxFiltersStore = createStoreWithPersistAndSubscribe<InboxFiltersStore>(
  (set) => ({
    inboxFilters: DEFAULT_INBOX_FILTER_VALUE,
    setInboxFilters: (args: InboxFilters) =>
      set(
        (state) => {
          state.inboxFilters = { ...DEFAULT_INBOX_FILTER_VALUE, ...state.inboxFilters, ...args };
        },
        false,
        'SET_INBOX_FILTERS'
      ),
    clearInboxFilters: () => {
      set(
        (state) => {
          state.inboxFilters = DEFAULT_INBOX_FILTER_VALUE;
        },
        false,
        'CLEAR_INBOX_FILTERS'
      );
    },
  }),
  { name: 'InboxFiltersStorage', version: 2 },
  { name: 'InboxFilters', trace: true }
);

export const useInboxFiltersShallowStore = createShallowStore(useInboxFiltersStore);

const sortState = (state: Partial<InboxFilters>): Partial<InboxFilters> =>
  Object.fromEntries(Object.entries(state).map(([key, value]) => [key, [...value].sort()])) as Partial<InboxFilters>;

export const inboxFiltersAreEqual = (a: Partial<InboxFilters>, b: Partial<InboxFilters>): boolean => {
  const sortedA = sortState(a);
  const sortedB = sortState(b);
  return JSON.stringify(sortedA) === JSON.stringify(sortedB);
};

export const useInboxFilterStatusLabels = (): Record<InboxStatus, string> => {
  const { t } = useTranslation('inbox');
  return {
    [InboxStatus.UNREAD]: t('Unread'),
    [InboxStatus.UNREPLIED]: t('Unreplied'),
    [InboxStatus.READ]: t('Read'),
    [InboxStatus.REPLIED]: t('Replied'),
    [InboxStatus.ERROR]: t('Error'),
  };
};

const inboxFiltersKeysToHiddenFiltersKeyMapping = {
  statuses: 'statuses',
  tags: 'tags',
  departments: 'departments',
  startDate: 'date',
  endDate: 'date',
  startTime: 'time',
  endTime: 'time',
};

export const useInboxTypeAndFiltersMapping = (inboxType?: InboxType) => {
  const { inboxFilters } = useInboxFiltersShallowStore('inboxFilters', 'setInboxFilters');

  let hiddenFilters: HiddenFilterKeys;
  if (inboxType === InboxType.DRAFTS) {
    hiddenFilters = ['statuses', 'tags', 'date', 'time'];
  } else if (inboxType === InboxType.SCHEDULED) {
    hiddenFilters = ['time'];
  } else {
    hiddenFilters = ['date', 'time'];
  }

  const hasFilters = useMemo(() => {
    const visibleFilters = Object.values(inboxFiltersKeysToHiddenFiltersKeyMapping).filter(
      (key) => !hiddenFilters?.includes(key as InboxFilter)
    );
    return visibleFilters.some((key) => {
      if (key !== 'date' && key !== 'time') {
        const value = inboxFilters[key as InboxFilter];
        return Array.isArray(value) ? value.length > 0 : !!value;
      } else if (key === 'date') {
        return !!inboxFilters.startDate || !!inboxFilters.endDate;
      } else {
        return !!inboxFilters.startTime || !!inboxFilters.endTime;
      }
    });
  }, [JSON.stringify(inboxFilters)]);

  return { hiddenFilters, hasFilters };
};
