import { PMSPerson, PMSVertical, Pet } from '@weave/schema-gen-ts/dist/schemas/sms/writeback/v1/writeback_service.pb';
import { DepartmentsTypes } from '@frontend/api-departments';
import { SchemaSMSSharedModels } from '@frontend/api-messaging';
import { PersonTypes } from '@frontend/api-person';
import { InboxType } from '@frontend/inbox-navigation';
import { SchemaIO, SchemaSMSWritebackService } from '@frontend/schema';
import { PickRequired } from '@frontend/types';

export enum BulkSelectionActionType {
  EXPORT_CONVERSATION = 'EXPORT_CONVERSATION',
  TEXT_WRITEBACK = 'TEXT_WRITEBACK',
}

export type InboxFiltersV0 = {
  readStatus: 'read' | 'unread' | '';
  deliveryStatus: 'delivered' | 'undelivered' | '';
  repliedStatus: 'replied' | 'unreplied' | '';
  tags: string[];
  departments: string[];
};

export type SelectedThreadInfo = {
  id?: string;
  departmentId?: string;
  person?: PersonTypes.Person;
  personId?: string;
  personPhone?: string;
};

export type PMSOption = {
  label: string;
  id: string;
  vertical: PMSVertical;
  personOptions: PMSPerson[];
  petOptions: Pet[];
};

export type WritebackPayloadMap = Record<string, WritebackPayload>;

export type WritebackPayload = Omit<
  SchemaIO<(typeof SchemaSMSWritebackService)['WritebackMessages']>['input'],
  'locationId' | 'weaveUserId' | 'smsIds'
>;

export enum SMSConversationStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  NEW = 'NEW',
}

export type ExtendedDepartment = DepartmentsTypes.SmsDepartmentModel & {
  threadStatus?: SMSConversationStatus;
  threadId?: string;
};

export enum LocalSendingStatus {
  SENDING = 'sending',
  SENT = 'sent',
  FAILED = 'failed',
}

/**
 * A wrapper for the Schema SMS type that makes all relevant properties for displaying an SMS required.
 * All other properties are optional.
 */
export type ThreadSMS = Omit<PickRequired<SchemaSMSSharedModels.SMS, 'id' | 'createdAt' | 'createdBy'>, 'media'> &
  Partial<{
    media: Partial<SchemaSMSSharedModels.Media>[];
  }>;

export type SentMessage = ThreadSMS & { localSendingStatus?: LocalSendingStatus };

export enum SearchContainer {
  Contacts = 'Contacts',
  Messages = 'Messages',
}

export type AcceptableSortInboxTypes = InboxType.DRAFTS | InboxType.SCHEDULED;
