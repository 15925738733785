import React from 'react';
import { css, SerializedStyles } from '@emotion/react';
import { http } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import { useMerchant } from '@frontend/payments-hooks';
import { theme } from '@frontend/theme';
import { ContentLoader, ExternalLinkIcon, Heading, Text, TextLink, useAlert } from '@frontend/design-system';
import { PaymentProcessorResponse } from '../../../types';
import { ContactPayments } from '../molecules';
import { CreateMerchant } from '../organisms';

type MerchantStatusProps = {
  style?: SerializedStyles;
};

type PaymentProcessorDataResponse = {
  data: PaymentProcessorResponse;
};

export const merchantStatusStyles = {
  title: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
    gap: ${theme.spacing(2, 2)};
  `,
  accountInfoContainer: css`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.spacing(1, 5)};

    &:not(:last-of-type) {
      margin-bottom: ${theme.spacing(2)};
    }
  `,
  accountInfo: css`
    display: flex;
    flex-direction: column;
    margin: 0;
  `,
};

export const MerchantStatus = ({ style }: MerchantStatusProps) => {
  const { t } = useTranslation('payments', { keyPrefix: 'settings' });
  const { merchant, paymentsUrl, activeProcessor, payoutsEnabled, chargesEnabled, hasPayments, merchantFetched } =
    useMerchant();
  const alert = useAlert();

  const merchantName = merchant?.name;
  const linkToDashboard = async (event: React.MouseEvent) => {
    event.preventDefault();

    if (activeProcessor) {
      try {
        const { data } = await http.get<PaymentProcessorDataResponse>(
          `${paymentsUrl}/processors/${activeProcessor.id}`
        );
        if (data.links.dashboard) {
          window.open(data.links.dashboard, '_blank');
        } else {
          alert.error(t('Payment Admin role is required'));
        }
      } catch {
        alert.error(t('Error getting dashboard link'));
      }
    }
  };

  return (
    <div css={style}>
      <ContentLoader show={!merchantFetched} message={t('Loading Merchant Information')} />
      {merchantFetched &&
        (hasPayments ? (
          <>
            <div css={merchantStatusStyles.title}>
              <Text color='light' css={{ margin: 0 }}>
                {t('Merchant Account')}
              </Text>
              {activeProcessor && (
                <TextLink
                  size='large'
                  onClick={linkToDashboard}
                  data-trackingid='pay-portal-settings-link-stripedash'
                  css={css`
                    display: flex;
                    flex-flow: row;
                    align-items: center;
                  `}
                >
                  {t('Stripe Dashboard')}
                  <ExternalLinkIcon
                    color='primary'
                    size={16}
                    css={css`
                      margin-left: ${theme.spacing(0.5)};
                    `}
                  />
                </TextLink>
              )}
            </div>
            <Heading
              as='p'
              level={3}
              css={css`
                margin: ${theme.spacing(0, 0, 2)};
              `}
            >
              {activeProcessor?.displayName || merchantName}
            </Heading>
            <div css={merchantStatusStyles.accountInfoContainer}>
              <StatusText label={t('Payment Status')} status={chargesEnabled} trackingId='paymentstatus' />
              <StatusText label={t('Payout Status')} status={payoutsEnabled} trackingId='payoutstatus' />
            </div>
            <ContactPayments />
            <div css={merchantStatusStyles.accountInfoContainer}>
              <div css={merchantStatusStyles.accountInfo}>
                <Text size='medium' color='light'>
                  {t('Account ID')}
                </Text>
                <Text weight='bold' as='span'>
                  {activeProcessor?.stripeId}
                </Text>
              </div>
              <div css={merchantStatusStyles.accountInfo}>
                <Text size='medium' color='light'>
                  {t('Account Type')}
                </Text>
                <Text weight='bold' as='span'>
                  {activeProcessor?.stripeAccountType}
                </Text>
              </div>
            </div>
          </>
        ) : (
          <CreateMerchant />
        ))}
    </div>
  );
};

type StatusTextProps = {
  label: string;
  status: boolean;
  trackingId: string;
  count?: number;
};
export const StatusText = ({ label, status, trackingId, count }: StatusTextProps) => {
  const { t } = useTranslation('payments', { keyPrefix: 'settings' });
  return (
    <div css={merchantStatusStyles.accountInfo}>
      <Text color='light' size='medium'>
        {label}
      </Text>
      <Text
        weight='bold'
        color={status ? 'success' : 'error'}
        data-trackingid={`pay-portal-settings-label-${trackingId}`}
      >
        {!!count && count + ' '}
        {status ? t('Active') : t('Inactive')}
      </Text>
    </div>
  );
};
