import { useEffect, useMemo, useState } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import {
  PaymentMethod,
  PaymentPlanDetailsResponse,
  PaymentPlanFrequency,
  updatePaymentPlan,
  PaymentPlanUpdateReviewResponse,
  reviewUpdatePaymentPlan,
  PaymentPlanStatus,
  PaymentPlanConfirmationStatus,
} from '@frontend/api-payment-plans';
import { HttpError } from '@frontend/fetch';
import { useTranslation } from '@frontend/i18n';
import {
  PaymentMethodName,
  getPaymentMethodName,
  useGetPatientPaymentMethods,
  usePaymentPlanCardOnFile,
} from '@frontend/payments-card-on-file';
import { useMerchant } from '@frontend/payments-hooks';
import { PaymentsFlowType, StripeElementsWrapper } from '@frontend/payments-stripe-wrapper';
import { theme } from '@frontend/theme';
import {
  DatePickerField,
  DropdownField,
  EmailField,
  Modal,
  MoneyField,
  NumberField,
  Text,
  TextButton,
  TextField,
  TextareaField,
  UpdateIcon,
  useForm,
  useModalControl,
  useAlert,
} from '@frontend/design-system';
import { usePaymentPlanShallowStore } from '../../providers';
import {
  convertToCents,
  createFutureISODateTimeInUTC,
  formatCentsToCurrency,
  isValidUnixDate,
  isoDateToDisplayInUTC,
  isoDateToDisplayLocalString,
  planFrequencyLabel,
  termOptions,
  toCurrency,
} from '../../utils';
import { PaymentPlanPaymentSummary } from '../PaymentPlanPaymentSummary';
import { EditBillingMethodModal } from './EditBillingMethodModal';
import { PlanChangesModal } from './PlanChangesModal';

const styles = {
  saveBtnErrorMsg: css`
    margin: ${theme.spacing(1, 3)};
    max-width: ${theme.spacing(50)};
  `,
};

export const EditPaymentPlanModal = ({
  planDetails,
  onClose,
  type = 'edit',
}: {
  type?: 'edit' | 'resume';
  planDetails: PaymentPlanDetailsResponse;
  onClose: () => void;
}) => {
  const { t } = useTranslation('payments');
  const alert = useAlert();
  const { paymentsUrl } = useMerchant();
  const { setWorkflowId } = usePaymentPlanShallowStore('setWorkflowId');
  const { paymentMethods } = useGetPatientPaymentMethods(planDetails.person.id);
  const { modalProps: addCardModalProps, triggerProps: addCardTriggerProps } = useModalControl();
  const { modalProps: confirmChangesModalProps, triggerProps: confirmChangesTriggerProps } = useModalControl();
  const initalPaymentMethodId = planDetails?.paymentMethod.paymentMethodId;
  const [patientPaymentMethodId, setPatientPaymentMethodId] = useState(initalPaymentMethodId);
  const [reviewUpdates, setReviewUpdates] = useState<PaymentPlanDetailsResponse>();

  const isEditOperation = type === 'edit';
  const nextPaymentAt = planDetails?.schedule?.nextPaymentAt;
  dayjs.extend(isSameOrAfter);
  const today = dayjs().utc(true).toString();
  const nextPaymentAtDate =
    nextPaymentAt && isValidUnixDate(nextPaymentAt) && dayjs(nextPaymentAt).isSameOrAfter(today)
      ? isoDateToDisplayInUTC(nextPaymentAt)
      : isoDateToDisplayInUTC(today);

  const { getFieldProps, reset, values, changedValues } = useForm({
    fields: {
      planName: {
        type: 'text',
        value: planDetails.name,
      },
      customerEmailId: {
        type: 'email',
        value: '',
      },
      planAmount: {
        type: 'money',
        min: 0.5,
        value: toCurrency(planDetails.schedule.amount.toString()),
      },
      frequency: {
        type: 'dropdown',
        value: planDetails.schedule.frequency,
        required: true,
      },
      paymentTerm: {
        type: 'number',
        value: (planDetails?.schedule.paymentTerm ?? 0).toString(),
        required: true,
        min: 1,
        max: 60,
      },
      paymentDate: {
        type: 'datePicker',
        value: nextPaymentAtDate,
      },
      paymentMethod: {
        type: 'dropdown',
        value: initalPaymentMethodId,
      },
      memo: {
        type: 'text',
      },
    },
    computeChangedValues: true,
  });

  const selectedPaymentMethod = paymentMethods?.find((pm) => pm.id === patientPaymentMethodId);

  useEffect(() => {
    if (values.paymentMethod) setPatientPaymentMethodId(values.paymentMethod);
  }, [values?.paymentMethod]);

  const changedFields = useMemo(
    () =>
      changedValues
        ? Object.keys(changedValues).filter((key) => !['customerEmailId', 'planName', 'memo'].includes(key))
        : [],
    [changedValues]
  );
  const noFieldsModified =
    changedFields.length === 0 &&
    getFieldProps('paymentDate').value !== isoDateToDisplayInUTC(today) &&
    !changedFields.includes('paymentDate');
  const onlyBillingMethodChanged = useMemo(
    () => changedFields.includes('paymentMethod') && changedFields.length === 1,
    [changedFields]
  );
  const confirmationRequiringFieldsModified = !noFieldsModified && !onlyBillingMethodChanged;
  const requireEmail =
    confirmationRequiringFieldsModified ||
    !isEditOperation ||
    planDetails?.confirmationDetails.confirmationStatus !== PaymentPlanConfirmationStatus.Accept;

  const getTermPayment = () => Number(values?.planAmount) / Number(values?.paymentTerm) || 0;

  const {
    editBillingMethodProps,
    paymentMethodItemProps: { disableACHPaymentMethods },
  } = usePaymentPlanCardOnFile({
    paymentAmount: convertToCents(getTermPayment()),
    patientId: planDetails.person.id,
    patientEmail: planDetails.person.email || '',
    paymentFrequency: values.frequency as PaymentPlanFrequency,
  });

  const disableSelectedACHPaymentMethod = selectedPaymentMethod?.type === 'us_bank_account' && disableACHPaymentMethods;

  const disableSaveBtn =
    noFieldsModified || (requireEmail && !values.customerEmailId) || disableSelectedACHPaymentMethod;

  const handleSetBillingMethod = async (selectedPaymentMethod: PaymentMethod) => {
    setPatientPaymentMethodId(selectedPaymentMethod.id);
    addCardModalProps.onClose();
  };

  const handleUpdatePaymentPlan = async () => {
    try {
      const {
        data: { workflowId },
      } = await updatePaymentPlan(paymentsUrl, planDetails.id, {
        amount: convertToCents(Number(values.planAmount)),
        frequency: values.frequency as PaymentPlanFrequency,
        paymentMethodId: patientPaymentMethodId,
        ...(requireEmail
          ? {
              customerEmailId: values.customerEmailId,
              status: PaymentPlanStatus.Paused,
              confirmationStatus: PaymentPlanConfirmationStatus.Pending,
              expireLink: false,
            }
          : {}),
        paymentTerm: Number(values.paymentTerm),
        ...(values.paymentDate ? { startAt: createFutureISODateTimeInUTC(values.paymentDate || '') } : {}),
        notes: values.memo,
      });
      setWorkflowId(workflowId);
      confirmChangesModalProps.onClose();
      onClose();
      alert.success(t('Plan updated successfully!'));
    } catch (err) {
      console.log('update error: ', err);
      alert.error(t('Error updating payment plan. Please try again.'));
    }
  };

  const getUpdateReview = async () => {
    try {
      const updates: PaymentPlanUpdateReviewResponse = await reviewUpdatePaymentPlan(paymentsUrl, planDetails.id, {
        amount: convertToCents(Number(values.planAmount)),
        frequency: values.frequency as PaymentPlanFrequency,
        paymentMethodId: patientPaymentMethodId,
        paymentTerm: Number(values.paymentTerm),
        ...(values.paymentDate ? { startAt: createFutureISODateTimeInUTC(values.paymentDate || '') } : {}),
        notes: values.memo,
      });

      setReviewUpdates(updates.newPlan);
      confirmChangesTriggerProps.onClick();
    } catch (err) {
      if (err instanceof HttpError) alert.error(err.message);
      console.log('update review error: ', err);
    }
  };

  return (
    <>
      <Modal.Header textAlign='left'>
        {isEditOperation ? t('Edit Payment Plan') : t('Resume Payment Plan')}
      </Modal.Header>
      <Modal.Body>
        <>
          <div
            css={css`
              display: flex;
              flex-direction: row;
              min-width: ${theme.spacing(50)};
            `}
          >
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: ${theme.spacing(3)};
              `}
            >
              <div
                css={css`
                  display: flex;
                  flex-flow: row;
                  justify-content: space-between;
                  align-items: center;
                `}
              >
                <Text
                  weight='bold'
                  css={css`
                    font-size: ${theme.fontSize(18)};
                  `}
                >
                  {t('New')}
                </Text>
                <TextButton
                  onClick={() => reset()}
                  icon={UpdateIcon}
                  color={theme.colors.primary50}
                  css={css`
                    color: ${theme.colors.primary50};
                    svg {
                      height: ${theme.fontSize(16)};
                      width: ${theme.fontSize(16)};
                      margin-right: ${theme.spacing(0.5)};
                    }
                  `}
                >
                  {t('Reset')}
                </TextButton>
              </div>
              <TextField {...getFieldProps('planName')} label={t('Payment Plan Name')} />
              <MoneyField {...getFieldProps('planAmount')} label={t('Original Balance')} />

              <DropdownField label={t('Payment Cadence *')} {...getFieldProps('frequency')}>
                {termOptions.map((term) => (
                  <DropdownField.Option key={term.value} value={term.value}>
                    {term.displayValue}
                  </DropdownField.Option>
                ))}
              </DropdownField>
              <NumberField label={t('Number of Payments *')} {...getFieldProps('paymentTerm')} />
              <DatePickerField label={t('Next Payment Due')} {...getFieldProps('paymentDate')} />
              <DropdownField label={t('Billing Method')} {...getFieldProps('paymentMethod')}>
                {paymentMethods?.map((pm) => {
                  const optionDisabled = pm.type === 'us_bank_account' && disableACHPaymentMethods;
                  return (
                    <DropdownField.Option key={pm.id} value={pm.id} disabled={optionDisabled}>
                      {getPaymentMethodName(pm)}
                    </DropdownField.Option>
                  );
                })}
                <DropdownField.Option value='' {...addCardTriggerProps}>
                  {t('Add new card')}
                </DropdownField.Option>
              </DropdownField>
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: ${theme.spacing(3)};
                margin-left: ${theme.spacing(2)};
              `}
            >
              <div
                css={css`
                  display: flex;
                  align-items: start;
                  height: ${theme.spacing(3.75)};
                `}
              >
                <Text
                  weight='bold'
                  css={css`
                    font-size: ${theme.fontSize(18)};
                  `}
                >
                  {t('Previous')}
                </Text>
              </div>
              <Text
                css={css`
                  margin: ${theme.spacing(1, 0)};
                `}
              >
                {planDetails?.name}
              </Text>
              <Text
                css={css`
                  margin: ${theme.spacing(1, 0)};
                `}
              >
                {formatCentsToCurrency(planDetails.schedule.amount)}
              </Text>
              <Text
                css={css`
                  margin: ${theme.spacing(1, 0)};
                `}
              >
                {termOptions.find((option) => planDetails.schedule.frequency === option.value)?.displayValue}
              </Text>
              <Text
                css={css`
                  margin: ${theme.spacing(1, 0)};
                `}
              >
                {planDetails.schedule.paymentTerm}{' '}
                {planFrequencyLabel(planDetails.schedule.frequency, planDetails.schedule.paymentTerm)}
              </Text>
              <Text
                css={css`
                  margin: ${theme.spacing(1, 0)};
                `}
              >
                {isoDateToDisplayLocalString(planDetails.schedule.nextPaymentAt || '')}
              </Text>
              <div
                css={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: start;
                  align-items: center;
                  gap: ${theme.spacing(1)};
                  margin-top: ${theme.spacing(1)};
                `}
              >
                <PaymentMethodName paymentMethod={planDetails.paymentMethod} showCardBrand />
              </div>
            </div>
          </div>
          <div
            css={css`
              margin-top: ${theme.spacing(2)};
            `}
          >
            <TextareaField {...getFieldProps('memo')} label={t('Reason for changes')} />
          </div>
          <div
            css={css`
              margin-top: ${theme.spacing(2)};
            `}
          >
            <PaymentPlanPaymentSummary monthly={getTermPayment()} date={values.paymentDate} />
          </div>
          {requireEmail && (
            <div>
              <Text css={{ margin: theme.spacing(2, 0) }}>Send confirmation email to</Text>
              <EmailField
                {...getFieldProps('customerEmailId')}
                required={requireEmail}
                placeholder={planDetails?.person?.email}
                label={t('Email *')}
              />
            </div>
          )}
        </>
      </Modal.Body>
      <Modal.Actions
        css={{ button: { width: 'fit-content' }, justifyContent: 'end' }}
        onPrimaryClick={getUpdateReview}
        primaryLabel={isEditOperation ? t('Save') : t('Resume')}
        disablePrimary={disableSaveBtn}
        onSecondaryClick={onClose}
      />
      {disableSaveBtn && (
        <Text textAlign='right' css={styles.saveBtnErrorMsg} color='error' size='medium'>
          {disableSelectedACHPaymentMethod
            ? editBillingMethodProps.achDisabledMessage
            : noFieldsModified
            ? t('No fields modified')
            : t('Please enter the confirmation email')}
        </Text>
      )}
      <StripeElementsWrapper type={PaymentsFlowType.AddCardOnFile}>
        <Modal {...addCardModalProps} minWidth={600} maxWidth={600}>
          <EditBillingMethodModal
            handleSetBillingMethod={handleSetBillingMethod}
            onClose={addCardModalProps.onClose}
            {...editBillingMethodProps}
          />
        </Modal>
      </StripeElementsWrapper>
      <Modal {...confirmChangesModalProps} maxWidth={600}>
        <PlanChangesModal
          fromData={planDetails}
          toData={reviewUpdates}
          changeReason={values.memo}
          handleUpdatePaymentPlan={handleUpdatePaymentPlan}
          onClose={confirmChangesModalProps.onClose}
          viewType='confirm'
        />
      </Modal>
    </>
  );
};
