import { PracticeAnalyticsTypes } from '@frontend/api-analytics';
import { demoDataUtils } from '../../../utils';
import { requiredPatientInfoFields } from './shared-helpers';

const cancellationsPatientsDetails = (): PracticeAnalyticsTypes.PatientInfo[] =>
  demoDataUtils.generateRandomUserNames(15).map(({ firstName, lastName }, id) => {
    const aptType = demoDataUtils.generateRandomInt(1, 2) === 1 ? 'restorative' : 'hygiene';
    const nextApt = demoDataUtils.generateRandomDate(true);
    const hasRescheduled = demoDataUtils.generateRandomInt(1, 3) === 1;

    return {
      ...requiredPatientInfoFields,
      cancelledAppointmentDate: demoDataUtils.generateRandomDate(),
      cancelledAppointmentType: aptType,
      FirstName: firstName,
      id: `${id}`,
      LastName: lastName,
      nextAppointmentDate: !hasRescheduled && demoDataUtils.generateRandomInt(0, 3) === 1 ? '' : nextApt,
      productionAmount: demoDataUtils.generateRandomInt(200, 500),
      rescheduledAppointmentDate: hasRescheduled ? null : nextApt,
    };
  });

export const cancellations = (): PracticeAnalyticsTypes.CancellationsResponse => {
  const totalPersonsCount = demoDataUtils.generateRandomInt(200, 300);
  const cancelled = demoDataUtils.generateRandomInt(40, 70);
  const rescheduled = demoDataUtils.generateRandomInt(0, 40);
  const cancelledUnscheduled = cancelled - rescheduled;

  const totalProductionRisk = demoDataUtils.generateRandomInt(2000, 3000);
  const recapturedProduction = rescheduled ? demoDataUtils.generateRandomInt(500, 1000) : 0;
  const unscheduledOpportunity = totalProductionRisk - recapturedProduction;

  return {
    location: {
      missedAppointments: {
        benchmarks: [
          {
            value: 0.09,
            label: 'Top',
          },
          {
            value: 0.15,
            label: 'Avg.',
          },
        ],
        details: {
          patients: cancellationsPatientsDetails(),
        },
        totals: {
          cancelled,
          cancelledUnscheduled,
          percentage: Math.random(),
          recapturedProduction,
          rescheduled,
          totalPersonsCount,
          totalProduction: totalProductionRisk + 3000, // Increase by 3000 to make it more realistic
          totalProductionRisk,
          unscheduledOpportunity,
        },
      },
    },
  };
};
