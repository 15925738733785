import { AppLink } from '@frontend/settings-routing';
import { theme } from '@frontend/theme';
import { Breadcrumbs as DesignBreadcrumbs } from '@frontend/design-system';
import { DefaultBreadcrumbsObj } from '../../types';

export type BreadcrumbsProps = { breadcrumbs?: DefaultBreadcrumbsObj[] };

export function Breadcrumbs({ breadcrumbs }: BreadcrumbsProps) {
  if (!breadcrumbs) {
    return null;
  }

  return (
    <DesignBreadcrumbs css={{ marginBottom: theme.spacing(1) }}>
      {breadcrumbs.map(({ to, label, ...rest }) => (
        <DesignBreadcrumbs.Path key={label} as={AppLink} to={to} {...rest}>
          {label}
        </DesignBreadcrumbs.Path>
      ))}
    </DesignBreadcrumbs>
  );
}
