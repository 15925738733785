import { FaxApi } from '@frontend/api-fax';
import { shell } from '@frontend/shell-utils';

const clearUrl = (url: string): void => {
  window.URL.revokeObjectURL(url);
};

const getPDFName = ({ id, filename }: { id: string; filename: string }) => {
  const name = filename.split('/').pop();
  return name?.replace(`${id}-`, '') ?? '';
};

export const generateFaxDeliveryReport = async (faxId: string, locationId: string) => {
  return await FaxApi.getFaxDeliveryReport(faxId, locationId);
};

const generateBlob = async (
  mediaId: string,
  locationId: string,
  isFax: boolean,
  attachDeliveryConfirmation: boolean
) => {
  return await FaxApi.getFaxMedia(mediaId, locationId, !!isFax, attachDeliveryConfirmation);
};

export const downloadPDF = async (
  mediaItem: { faxId: string; filename: string },
  locationId: string,
  isFax: boolean,
  generateDeliveryReport?: boolean
) => {
  const link = window.document.createElement('a');
  link.style.display = 'none';
  if (generateDeliveryReport) {
    await generateFaxDeliveryReport(mediaItem.faxId, locationId);
  }
  const blob = await generateBlob(mediaItem.faxId, locationId, isFax, generateDeliveryReport ?? false);
  link.href = window.URL.createObjectURL(blob);
  link.download = getPDFName({ id: mediaItem?.faxId, filename: mediaItem?.filename ?? '' });
  window.document.body.appendChild(link);
  link.click();
  clearUrl(link.href);
  window.document.body.removeChild(link);
};

export const downloadDeliveryReport = async (faxId: string, locationId: string) => {
  const link = window.document.createElement('a');
  link.style.display = 'none';
  const { deliveryReportMediaId } = await generateFaxDeliveryReport(faxId, locationId);
  const blob = await generateBlob(deliveryReportMediaId, locationId, false, false);
  link.href = window.URL.createObjectURL(blob);
  link.download = getPDFName({ id: deliveryReportMediaId, filename: 'FaxDeliveryReport.pdf' });
  window.document.body.appendChild(link);
  link.click();
  clearUrl(link.href);
  window.document.body.removeChild(link);
};

export const previewPDF = async (
  mediaItem: { faxId: string; filename: string },
  locationId: string,
  isFax: boolean,
  generateDeliveryReport?: boolean
) => {
  if (generateDeliveryReport) {
    await generateFaxDeliveryReport(mediaItem.faxId, locationId);
  }
  const blob = await generateBlob(mediaItem.faxId, locationId, isFax, generateDeliveryReport ?? false);
  const url = window.URL.createObjectURL(blob);
  if (shell.isShell) {
    shell.emit?.('window:open', { url });
  } else {
    window.open(url, '_blank');
  }
  clearUrl(url);
};

export const previewReport = async (faxId: string, locationId: string) => {
  const { deliveryReportMediaId } = await generateFaxDeliveryReport(faxId, locationId);
  const blob = await generateBlob(deliveryReportMediaId, locationId, false, false);
  const url = window.URL.createObjectURL(blob);
  if (shell.isShell) {
    shell.emit?.('window:open', { url });
  } else {
    window.open(url, '_blank');
  }
  clearUrl(url);
};
