import { useQueryClient } from 'react-query';
import { InvoiceModel, updateInvoiceQueryCache, getInvoiceQueryKey } from '@frontend/api-invoices';
import { PaymentQueries } from '@frontend/api-payments';
import { useMultiQueryUtils } from '@frontend/payments-hooks';
import { useTogglePaymentRecorded } from './use-toggle-payment-recorded';

export const useToggleInvoiceRecorded = () => {
  const queryClient = useQueryClient();
  const { invalidateUnrecordedCount } = PaymentQueries.usePaymentsInvalidation();
  const { locationId } = useMultiQueryUtils();

  const { toggleRecorded, ...toggleRecordedInfo } = useTogglePaymentRecorded<InvoiceModel>({
    onMutate: ({ data: invoice, paymentId }) => {
      if (!invoice) return;

      queryClient.cancelQueries(getInvoiceQueryKey(locationId, invoice.id));

      const newPayments = invoice.payments?.map((payment) => {
        if (payment.paymentId === paymentId) {
          return { ...payment, recordedAt: invoice.payment?.recordedAt ? undefined : new Date().toISOString() };
        } else {
          return payment;
        }
      });
      const newInvoice: InvoiceModel = {
        ...invoice,
        ...(invoice.payment && invoice.payment.paymentId === paymentId
          ? {
              payment: {
                ...invoice.payment,
                recordedAt: invoice.payment?.recordedAt ? undefined : new Date().toISOString(),
              },
            }
          : {}),
        ...(invoice.payments
          ? {
              payments: newPayments,
            }
          : {}),
      };

      updateInvoiceQueryCache(queryClient, newInvoice, getInvoiceQueryKey(locationId, newInvoice.id));
      return () => updateInvoiceQueryCache(queryClient, invoice, getInvoiceQueryKey(locationId, invoice.id));
    },
    getSuccessMsg: ({ recordedAt }) => `Invoice marked ${recordedAt ? 'unrecorded' : 'recorded'}`,
    onSuccess: invalidateUnrecordedCount,
  });

  return {
    toggleRecorded,
    ...toggleRecordedInfo,
  };
};
