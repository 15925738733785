import { BuilderFieldDivisionTypes } from '../../types';

export const GENERIC_MEDICAL_HISTORY_FIELD_CATEGORIES: BuilderFieldDivisionTypes.OtherFieldsDivisionCategory[] = [
  {
    label: 'Medical History',
    fieldsOrder: [
      {
        key: 'staticAllergies',
      },
      {
        key: 'medicalConditions',
      },
      {
        key: 'femaleOnlyConditions',
      },
    ],
  },
];
