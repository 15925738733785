import { UseQueryOptions, useQueryClient } from 'react-query';
import { useLocalizedQuery } from '@frontend/location-helpers';
import { useMutation, useQuery } from '@frontend/react-query-helpers';
import { useAppScopeStore } from '@frontend/scope';
import {
  assignAppointmentTypeToPractitioner,
  assignPractitionersToAppointmentType,
  createAppointmentType,
  deleteAppointmentTypes,
  getAppointmentTypes,
  getAppointmentTypesForMultiLocation,
  listAppointmentTypesPractitioners,
  listPractitioners,
  unassignAppointmentTypeToPractitioner,
  listPractitionersForMultiLocation,
  unassignPractitionersToAppointmentType,
  updateAppointmentType,
  updatePractitioner,
  listScheduleEntries,
  createSchedulerEntries,
  updateSchedulerEntries,
  createPractitioner,
  getPractitionerByPractitionerId,
  getCalendarEvent,
  createCalendarEvent,
  updateCalendarEvent,
  deleteCalendarEvents,
  updateOperatory,
  getOperatory,
  deletePractitioners,
  appointmentWriteback,
  appointmentStatusWriteback,
  listSourceTenants,
  updateCalendarEventStatus,
  personWriteback,
} from './api';
import {
  AppointmentTypesPractitionerQueryKeys,
  AppointmentTypesQueryKeys,
  CalendarEventsQueryKeys,
  OperatoriesQueryKeys,
  PractitionerQueryKeys,
  ScheduleEntriesQueryKeys,
  appointmentTypePractitionerQueryPredicate,
  practitionerQueryPredicate,
} from './query-keys';
import {
  GetCalendarEventApiType,
  GetOperatoryApiType,
  GetPractitionerByPractitionerIdApiType,
  ListAppointmentTypesApiType,
  ListAppointmentTypesMultiLocationApiType,
  ListAppointmentTypesPractitionersApiType,
  ListPractitionersApiType,
  ListPractitionersMultiLocationApiType,
  ListScheduleEntriesApiType,
  PersonWriteBackApiType,
  UpdateCalendarEventStatusApiType,
  AppointmentStatusWriteBackApiType,
  AppointmentWriteBackApiType,
  CreateCalendarEventApiType,
} from './types';

export const useGetAppointmentTypes = (opts?: UseQueryOptions<ListAppointmentTypesApiType['output']>) => {
  const { selectedLocationIds } = useAppScopeStore();
  return useQuery({
    queryKey: AppointmentTypesQueryKeys.getAppointmentTypes(selectedLocationIds[0]),
    queryFn: () => getAppointmentTypes({ locationId: selectedLocationIds[0] }),
    ...opts,
  });
};

export const useUpdateAppointmentType = (currentPage = 1, currentLimit = 10) => {
  const { selectedLocationIds } = useAppScopeStore();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateAppointmentType,
    onSuccess: () => {
      queryClient.invalidateQueries(
        AppointmentTypesQueryKeys.getAppointmentTypes(
          selectedLocationIds[0],
          currentPage.toString(),
          currentLimit.toString()
        )
      );
    },
    onError: () => {
      console.error('Failed to update appointment type');
    },
  });
};

export const useCreateAppointmentType = () => {
  const { selectedLocationIds } = useAppScopeStore();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createAppointmentType,
    onSuccess: () => {
      queryClient.invalidateQueries(AppointmentTypesQueryKeys.getAppointmentTypes(selectedLocationIds[0]));
    },
    onError: () => {
      console.error('Failed to create appointment type');
    },
  });
};

export const useDeleteAppointmentTypes = (currentPage = 1, currentLimit = 10) => {
  const { selectedLocationIds } = useAppScopeStore();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: deleteAppointmentTypes,
    onSuccess: () => {
      queryClient.invalidateQueries(
        AppointmentTypesQueryKeys.getAppointmentTypes(
          selectedLocationIds[0],
          currentPage.toString(),
          currentLimit.toString()
        )
      );
    },
    onError: () => {
      console.error('Failed to delete appointment type');
    },
  });
};

export const useArchiveAppointmentType = (currentPage = 1, currentLimit = 10) => {
  const { selectedLocationIds } = useAppScopeStore();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateAppointmentType,
    onSuccess: () => {
      queryClient.invalidateQueries(
        AppointmentTypesQueryKeys.getAppointmentTypes(
          selectedLocationIds[0],
          currentPage.toString(),
          currentLimit.toString()
        )
      );
    },
    onError: () => {
      console.error('Failed to archive appointment type');
    },
  });
};

export const useGetPractitioners = (
  request: ListPractitionersApiType['input'],
  opts?: UseQueryOptions<ListPractitionersApiType['output']>
) =>
  useQuery({
    queryKey: PractitionerQueryKeys.getPractitionersList(request),
    queryFn: () => listPractitioners(request),
    ...opts,
    enabled: !!request.locationId && (opts?.enabled ?? true),
  });

export const useGetPractitionerByPractitionerId = (
  req: GetPractitionerByPractitionerIdApiType['input'],
  opts?: UseQueryOptions<GetPractitionerByPractitionerIdApiType['output']>
) => {
  return useQuery({
    queryKey: PractitionerQueryKeys.getPractitionerByPractitionerId(req),
    queryFn: () => getPractitionerByPractitionerId(req),
    ...opts,
    enabled: !!req.practitionerId && (opts?.enabled ?? true),
  });
};

export const useGetPractitionersForMulti = (
  req: ListPractitionersMultiLocationApiType['input'],
  opts?: UseQueryOptions<ListPractitionersMultiLocationApiType['output']>
) =>
  useQuery({
    queryKey: PractitionerQueryKeys.getPractitionersListForMultiLocation(req),
    queryFn: () => listPractitionersForMultiLocation(req),
    ...opts,
    enabled: !!req.parentLocationId && !!req.locationIds.length && (opts?.enabled ?? true),
  });

export const useGetAppointmentTypesPractitioners = (
  req: ListAppointmentTypesPractitionersApiType['input'],
  opts?: UseQueryOptions<ListAppointmentTypesPractitionersApiType['output']>
) => {
  return useQuery({
    queryKey: AppointmentTypesPractitionerQueryKeys.getAppointmentTypePractitionersList(req),
    queryFn: () => listAppointmentTypesPractitioners(req),
    ...opts,
  });
};

export const useAssignPractitionersToAppointmentType = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: assignPractitionersToAppointmentType,
    onSuccess: (_, { locationId }) => {
      queryClient.invalidateQueries({
        predicate: appointmentTypePractitionerQueryPredicate.getAppointmentTypePractitionersList([locationId]),
      });
    },
    onError: () => {
      console.error('Failed to assign practitioners to appointment type');
    },
  });
};

export const useUnAssignPractitionersToAppointmentType = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: unassignPractitionersToAppointmentType,
    onSuccess: (_, { locationId }) => {
      queryClient.invalidateQueries({
        predicate: appointmentTypePractitionerQueryPredicate.getAppointmentTypePractitionersList([locationId]),
      });
    },
    onError: () => {
      console.error('Failed to unassign practitioners to appointment type');
    },
  });
};

export const useAssignAppointmentsTypeToPractitioner = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: assignAppointmentTypeToPractitioner,
    onSuccess: (_, { locationId }) => {
      queryClient.invalidateQueries({
        predicate: appointmentTypePractitionerQueryPredicate.getAppointmentTypePractitionersList([locationId]),
      });
    },
    onError: () => {
      console.error('Failed to assign appointment type to practitioner');
    },
  });
};

export const useUnAssignAppointmentsTypeToPractitioner = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: unassignAppointmentTypeToPractitioner,
    onSuccess: (_, { locationId }) => {
      queryClient.invalidateQueries({
        predicate: appointmentTypePractitionerQueryPredicate.getAppointmentTypePractitionersList([locationId]),
      });
    },
    onError: () => {
      console.error('Failed to unassign appointment type to practitioner');
    },
  });
};

export const useUpdatePractitioner = (locationId: string) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updatePractitioner,
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: practitionerQueryPredicate.getPractitionersList(locationId),
      });
    },
    onError: () => {
      console.error('Failed to update appointment type');
    },
  });
};

export const useCreatePractitioner = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createPractitioner,
    onSuccess: ({ practitioner }) => {
      queryClient.invalidateQueries({
        predicate: practitionerQueryPredicate.getPractitionersList(practitioner.locationId),
      });
    },
    onError: () => {
      console.error('Failed to update appointment type');
    },
  });
};

export const useDeletePractitioners = () => {
  return useMutation({
    mutationFn: deletePractitioners,
    onError: () => {
      console.error('Failed to delete practitioners');
    },
  });
};

export const useGetAppointmentTypesForMultiLocation = (
  opts?: UseQueryOptions<ListAppointmentTypesMultiLocationApiType['output']>
) => {
  const { selectedLocationIds, selectedParentsIds } = useAppScopeStore();
  return useQuery({
    queryKey: AppointmentTypesQueryKeys.getAppointmentTypesMultiLocation(selectedLocationIds, selectedParentsIds[0]),
    queryFn: () =>
      getAppointmentTypesForMultiLocation({
        locationsIds: selectedLocationIds,
        parentLocationId: selectedParentsIds[0],
      }),
    ...opts,
  });
};

type UseListScheduleEntriesQueryParams = {
  locationId: string;
  entityIds: string[];
  opts?: UseQueryOptions<ListScheduleEntriesApiType['output']>;
};
export const useListScheduleEntries = ({ locationId, entityIds, opts }: UseListScheduleEntriesQueryParams) => {
  return useQuery({
    queryKey: ScheduleEntriesQueryKeys.getScheduleEntriesList(locationId, entityIds),
    queryFn: () => listScheduleEntries({ locationId, ids: entityIds }),
    ...opts,
  });
};

export const useCreateScheduleEntries = (locationId: string, entityIds?: string[]) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createSchedulerEntries,
    onSuccess: () => {
      queryClient.invalidateQueries(ScheduleEntriesQueryKeys.getScheduleEntriesList(locationId, entityIds || []));
    },
  });
};

export const useUpdateScheduleEntries = (locationId: string, entityIds: string[]) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateSchedulerEntries,
    onSuccess: () => {
      queryClient.invalidateQueries(ScheduleEntriesQueryKeys.getScheduleEntriesList(locationId, entityIds));
    },
  });
};

// Calendar Events
export const useGetCalendarEvent = (eventId: string, opts?: UseQueryOptions<GetCalendarEventApiType['output']>) => {
  return useLocalizedQuery({
    queryFn: () => getCalendarEvent({ eventId }),
    queryKey: CalendarEventsQueryKeys.getCalendarEvent(eventId),
    ...opts,
  });
};

export const useCreateCalendarEvent = () => {
  return useMutation({
    mutationFn: (payload: CreateCalendarEventApiType['input']) => createCalendarEvent(payload),
    onError(error) {
      console.error('an error occurred while creating calendar event', error);
    },
  });
};

export const useUpdateCalendarEvent = () => {
  return useMutation({
    mutationFn: updateCalendarEvent,
  });
};

export const useDeleteCalendarEvent = () => {
  return useMutation({
    mutationFn: deleteCalendarEvents,
  });
};

export const useUpdateOperatory = () => {
  return useMutation({
    mutationFn: updateOperatory,
  });
};

export const useGetOperatoryByOperatorId = (
  operatoryId: string,
  opts?: UseQueryOptions<GetOperatoryApiType['output']>
) => {
  return useLocalizedQuery({
    queryFn: () => getOperatory({ operatoryId }),
    queryKey: OperatoriesQueryKeys.getOperatoryByOperatoryId(operatoryId),
    ...opts,
  });
};

export const useUpdateCalendarEventStatus = () => {
  return useMutation({
    mutationFn: (req: UpdateCalendarEventStatusApiType['input']) => updateCalendarEventStatus(req),
    onError(error) {
      console.error('an error occurred while adding updating status', error);
    },
  });
};

export const useAppointmentWriteback = () => {
  return useMutation({
    mutationFn: (req: AppointmentWriteBackApiType['input']) => appointmentWriteback(req),
    onError(error) {
      console.error('an error occurred while adding appointment writeback', error);
    },
  });
};

export const useAppointmentStatusWriteback = () => {
  return useMutation({
    mutationFn: (req: AppointmentStatusWriteBackApiType['input']) => appointmentStatusWriteback(req),
    onError(error) {
      console.error('an error occurred while adding appointment status writeback', error);
    },
  });
};

export const usePersonWriteback = () => {
  return useMutation({
    mutationFn: (req: PersonWriteBackApiType['input']) => personWriteback(req),
    onError(error) {
      console.error('an error occurred during person writeback', error);
    },
  });
};

type UseListSourceTenantsArgs = {
  locationId: string;
  isEnabled?: boolean;
};

export const useListSourceTenants = ({ locationId, isEnabled = false }: UseListSourceTenantsArgs) => {
  return useQuery({
    queryKey: [locationId, 'source-tenants'],
    queryFn: () => listSourceTenants({ locationId }),
    enabled: !!locationId && isEnabled,
  });
};
