import { css } from '@emotion/react';
import { useTranslation } from '@frontend/i18n';
import { useQueryAllInvoices } from '@frontend/payments-invoice-controller';
import { theme } from '@frontend/theme';
import { Button, SpinningLoader } from '@frontend/design-system';

type InvoicePageActionsProps = {
  onRefreshClick: () => void;
  showPrint: boolean;
  handlePrint: () => void;
};

export const InvoicePageActions = ({ onRefreshClick, showPrint, handlePrint, ...rest }: InvoicePageActionsProps) => {
  const { loading } = useQueryAllInvoices({ enable: showPrint });
  const { t } = useTranslation('payments');

  return (
    <div
      css={css`
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        margin: ${theme.spacing(2, 0)};
        width: 100%;
        gap: ${theme.spacing(2)};
      `}
      {...rest}
    >
      {loading && <SpinningLoader size='small' />}
      <Button
        trackingId='pay-portal-invoices-btn-print'
        iconName='print'
        onClick={handlePrint}
        loading={loading}
        variant='tertiary'
        size='large'
        css={css`
          margin-left: 0;
        `}
      >
        {t('Print')}
      </Button>
      <Button
        variant='tertiary'
        trackingId='pay-portal-invoices-btn-refresh'
        iconName='update'
        size='large'
        onClick={() => onRefreshClick()}
      >
        {t('Refresh')}
      </Button>
    </div>
  );
};
