import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const containerStyle = css`
  display: flex;
  flex-direction: column;
`;

export const buttonStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  border: none;
  border-radius: ${theme.borderRadius.medium};
  cursor: pointer;
  background-color: ${theme.colors.primary5};
  margin-bottom: ${theme.spacing(2)};

  &:hover {
    box-shadow: ${theme.shadows.floating};
  }
`;
