import { useState, useRef, useCallback } from 'react';
import { FileRejection } from 'react-dropzone';
import { FormsMediaManager } from '@frontend/api-forms';
import { genUID } from '@frontend/design-system';
import { ATTACH_DOCUMENTS } from '../../constants';
import { Attachment, AttachmentErrorCodes } from '../../types/form-packet-selector.types';
import FormAttachments, { FormAttachmentsRequiredProps } from './form-attachments.component';

interface UploadedFile {
  fileId: string;
  mediaId: string;
}

interface FileAbortController {
  fileId: string;
  controller: AbortController;
}

interface UseAttachmentsProps {
  locationId: string;
  initialAcceptedFiles?: Attachment[];
  initialRejectedFiles?: FileRejection[];
}

interface UseFormAttachmentsResults {
  FormAttachments: typeof FormAttachments;
  formAttachmentProps: FormAttachmentsRequiredProps;
  isUploadingFiles: boolean;
  abortAllFileUploads: () => void;
  acceptedFiles: Attachment[];
  rejectedFiles: FileRejection[];
}

const { MAX_FILE_COUNT } = ATTACH_DOCUMENTS;
const REJECTION_ERROR_TIMEOUT = 15000;

export const useFormAttachments = ({
  locationId,
  initialAcceptedFiles = [],
  initialRejectedFiles = [],
}: UseAttachmentsProps): UseFormAttachmentsResults => {
  const [files, setFiles] = useState<Attachment[]>(initialAcceptedFiles);
  const [rejectedFiles, setRejectedFiles] = useState<FileRejection[]>(initialRejectedFiles);
  const abortControllerRefs = useRef<FileAbortController[]>([]);
  const rejectionErrorTimeoutRef = useRef<NodeJS.Timeout>();

  const clearErrorMessageTimeout = useCallback(() => {
    const timeoutRef = rejectionErrorTimeoutRef.current;

    if (timeoutRef) {
      clearTimeout(timeoutRef);
    }
  }, []);

  const updateClearErrorMessageTimeout = useCallback(() => {
    rejectionErrorTimeoutRef.current = setTimeout(() => {
      setRejectedFiles([]);
    }, REJECTION_ERROR_TIMEOUT);
  }, []);

  const addNewRejectedFile = useCallback(
    (newRejectedFiles: FileRejection[]) => {
      clearErrorMessageTimeout();

      setRejectedFiles((currentRejectedFiles) => {
        return [...currentRejectedFiles, ...newRejectedFiles];
      });

      updateClearErrorMessageTimeout();
    },
    [clearErrorMessageTimeout, updateClearErrorMessageTimeout]
  );

  const updateRejectedFiles = useCallback(
    (rejectedFiles: FileRejection[]) => {
      clearErrorMessageTimeout();
      setRejectedFiles(rejectedFiles);
      updateClearErrorMessageTimeout();
    },
    [clearErrorMessageTimeout, updateClearErrorMessageTimeout]
  );

  const clearAbortControllers = useCallback((fileIds: string[]) => {
    abortControllerRefs.current = abortControllerRefs.current.filter(
      (controller) => !fileIds.includes(controller.fileId)
    );
  }, []);

  const markAsUploadSuccess = useCallback((attachedFiles: UploadedFile[]) => {
    setFiles((currentFiles) => {
      return currentFiles.map((file) => {
        const fileToMarkAsUploaded = attachedFiles.find((attachedFile) => attachedFile.fileId === file.id);

        if (fileToMarkAsUploaded) {
          return {
            ...file,
            isUploading: false,
            isUploaded: true,
            mediaUploadId: fileToMarkAsUploaded.mediaId,
            hasUploadError: false,
          };
        }

        return file;
      });
    });
  }, []);

  const markAsUploadFailed = useCallback(
    (fileIds: string[]) => {
      if (fileIds.length === 0) {
        return;
      }

      setFiles((currentFiles) => {
        const newRejectedFiles: FileRejection[] = [];

        for (const fileId of fileIds) {
          const file = currentFiles.find((file) => file.id === fileId);

          if (file) {
            newRejectedFiles.push({
              file: file.file,
              errors: [
                {
                  code: AttachmentErrorCodes.UPLOAD_FAILED,
                  message: 'File upload failed',
                },
              ],
            });
          }
        }

        addNewRejectedFile(newRejectedFiles);
        return currentFiles.filter((file) => {
          return !fileIds.includes(file.id);
        });
      });
    },
    [addNewRejectedFile]
  );

  const upload = useCallback(
    async (files: Attachment[], locationId: string) => {
      const uploads: Array<Promise<FormsMediaManager.Types.UploadedMedia>> = [];
      const fileIdsToUpload: string[] = [];

      for (const file of files) {
        const abortController = new AbortController();
        abortControllerRefs.current.push({
          fileId: file.id,
          controller: abortController,
        });

        fileIdsToUpload.push(file.id);
        uploads.push(FormsMediaManager.API.uploadMedia(file.file, locationId, { signal: abortController.signal }));
      }

      const uploadedFiles: UploadedFile[] = [];
      const failedFileIds: string[] = [];
      const responses = await Promise.allSettled(uploads);

      responses.forEach((response, index) => {
        switch (response.status) {
          case 'rejected': {
            const fileId = fileIdsToUpload[index];
            if (fileId) {
              failedFileIds.push(fileId);
            }
            break;
          }

          case 'fulfilled': {
            const data = response.value;
            const fileId = fileIdsToUpload[index];
            if (fileId) {
              if (data.success && data.fileId) {
                uploadedFiles.push({
                  fileId,
                  mediaId: data.fileId,
                });
              } else {
                failedFileIds.push(fileId);
              }
            }
            break;
          }
        }
      });

      clearAbortControllers(fileIdsToUpload);
      markAsUploadSuccess(uploadedFiles);
      markAsUploadFailed(failedFileIds);
    },
    [clearAbortControllers, markAsUploadFailed, markAsUploadSuccess]
  );

  const attachFiles = useCallback(
    (newFiles: File[]) => {
      if (files.length + newFiles.length > MAX_FILE_COUNT) {
        const rejectedFiles: FileRejection[] = newFiles.map((file) => ({
          file,
          errors: [
            {
              code: AttachmentErrorCodes.TOO_MANY_FILES,
              message: `You can only attach up to ${MAX_FILE_COUNT} files.`,
            },
          ],
        }));

        addNewRejectedFile(rejectedFiles);
        return;
      }

      const filesToAdd: Attachment[] = [];

      for (const file of newFiles) {
        const attachment: Attachment = {
          id: genUID(),
          mediaUploadId: '',
          file,
          isUploaded: false,
          isUploading: true,
          eSignRequired: true,
          hasUploadError: false,
        };

        filesToAdd.push(attachment);
      }

      setFiles((currentFiles) => {
        return [...currentFiles, ...filesToAdd];
      });

      upload(filesToAdd, locationId);
    },
    [files, addNewRejectedFile, upload, locationId]
  );

  const removeFile = useCallback(
    (fileId: string) => {
      const file = files.find((file) => file.id === fileId);

      if (!file) {
        return;
      }

      if (file.isUploaded) {
        FormsMediaManager.API.deleteMedia(file.mediaUploadId);
      }

      setFiles((currentFiles) => {
        return currentFiles.filter((file) => file.id !== fileId);
      });
    },
    [files]
  );

  const abortFileUpload = useCallback(
    (fileId: string) => {
      const index = abortControllerRefs.current.findIndex((controller) => controller.fileId === fileId);

      if (index !== -1) {
        abortControllerRefs.current[index]?.controller.abort();
        abortControllerRefs.current.splice(index, 1);
        removeFile(fileId);
      }
    },
    [removeFile]
  );

  const removeFiles = useCallback((fileIds: string[]) => {
    setFiles((currentFiles) => {
      return currentFiles.filter((file) => !fileIds.includes(file.id));
    });
  }, []);

  const abortAllFileUploads = useCallback(() => {
    const abortedFileIds: string[] = [];

    abortControllerRefs.current.forEach((controller) => {
      controller.controller.abort();
      abortedFileIds.push(controller.fileId);
    });

    abortControllerRefs.current = [];
    removeFiles(abortedFileIds);
  }, [removeFiles]);

  const updateSignatureRequirementStatus = useCallback((fileId: string, status: boolean) => {
    setFiles((currentFiles) => {
      return currentFiles.map((file) => {
        if (file.id === fileId) {
          return {
            ...file,
            eSignRequired: status,
          };
        }

        return file;
      });
    });
  }, []);

  return {
    FormAttachments,
    formAttachmentProps: {
      attachments: files,
      rejectedFiles,
      onAcceptFiles: attachFiles,
      onRejectFiles: updateRejectedFiles,
      onAbortFileUpload: abortFileUpload,
      onRemoveAttachment: removeFile,
      onToggleSignatureRequirement: updateSignatureRequirementStatus,
    },
    isUploadingFiles: files.some((file) => file.isUploading),
    abortAllFileUploads,
    acceptedFiles: files,
    rejectedFiles,
  };
};
