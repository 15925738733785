import { css } from '@emotion/react';
import { theme } from '@frontend/theme';

export const buttonStyles = css`
  display: flex;
  align-items: center;
  gap: ${theme.spacing(1)};
  background-color: ${theme.colors.neutral10};
  padding: ${theme.spacing(1)};
  border-radius: ${theme.borderRadius.small};
`;
